import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { FormGroup, InputGroup, Input } from 'reactstrap';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';

import { Intl } from 'components';
import InputGroupAddon from '../InputGroupAddon';

const PhoneInput = React.memo( ( {
  label, value, inputOnChange, inputName, size = 'md', onChange, prependIconClass, prependIcon,
  appendIconClass, appendIcon, formGroupClass, intl, translateValues, isInvalid, replacePrefix,
  focused, meta, ...rest
} ) => {
  const formatValue = ( val ) => ( formatIncompletePhoneNumber( val )
    ? formatIncompletePhoneNumber( val ) : val );

  return (
    <FormGroup className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}>
      {label
      && (
        <label className="form-control-label">
          <Intl id={label} />
        </label>
      )}
      <InputGroup className={classnames( 'input-group-merge', { focused } )}>
        {prependIcon || prependIconClass ? (
          <InputGroupAddon
            addonType="prepend"
            isInvalid={isInvalid}
            icon={prependIcon}
            iconClass={prependIconClass}
          />
        ) : null}

        <Input
          {...rest}
          placeholder={rest.placeholder ? intl.formatMessage( { id: rest.placeholder } ) : null}
          className={classnames( {
            'is-invalid': isInvalid,
            'form-control-lg': size === 'lg',
            'form-control-sm': size === 'sm',
          } )}
          value={value ? formatValue( value ) : ''}
          onChange={( e ) => {
            let val = e.target.value;
            if ( val && val[0] !== '+' ) {
              val = `+${val}`;
            }
            if ( inputOnChange ) inputOnChange( val );
            if ( onChange ) onChange( val );
          }}
          data-cy={`input-${inputName}`}
        />

        {appendIcon || appendIconClass ? (
          <InputGroupAddon
            addonType="append"
            isInvalid={isInvalid}
            icon={appendIcon}
            iconClass={appendIconClass}
          />
        ) : null}
      </InputGroup>

      {isInvalid
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={translateValues} />
        </div>
      )}
    </FormGroup>
  );
} );

export default injectIntl( PhoneInput );
