import api from 'config/Api/Api';

import { formatFormDataBody } from 'utils';

const baseEndpoint = ( taskId ) => `/tasks/${taskId}/images`;

export default {
  saveImage( taskId, data ) {
    return api.post( baseEndpoint( taskId ), formatFormDataBody( data ) );
  },

  deleteImage( taskId, id ) {
    return api.delete( `${baseEndpoint( taskId )}/${id}` );
  },
};
