import React from 'react';
import _isEmpty from 'lodash/isEmpty';

import {
  ModalForm, TextField, Select, Datepicker, Dropzone,
} from 'components';
import { required } from 'config/InputErrors';
import countries from 'country-list';
import { Row, Col } from 'reactstrap';

const GuestModalForm = ( {
  modalOpened, handleClose, onSubmit, selectedGuest, loadingGuest,
} ) => (
  <ModalForm
    initialValues={selectedGuest}
    title={_isEmpty( selectedGuest ) ? 'newGuest' : 'editGuest'}
    showErrorsInFooter
    isLoading={loadingGuest}
    open={modalOpened}
    onClose={handleClose}
    bodyClassName="overflow-visible"
    onSubmit={onSubmit}
    body={( ) => (
      <>
        <Row>
          <Col xs={12} lg={4}>
            <Dropzone
              field="selfie"
              formGroupClass="dz-profile-img"
              label="selfie"
              validate={required}
              placeholder={(
                <>
                  <i className="fa fa-file fa-4x mb-4" />
                </>
                )}
            />
          </Col>
          <Col lg={8}>
            <TextField
              label="firstName"
              field="name"
              validate={required}
            />
            <TextField
              label="lastName"
              field="firstLastname"
              validate={required}
            />
            <TextField
              label="secondLastname"
              field="secondLastname"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Select
              field="sex"
              label="sex"
              isClearable
              labelClass="font-weight-bold mr-2"
              translateOptions
              options={[
                { id: 'F', name: 'female' },
                { id: 'M', name: 'male' },
              ]}
              validate={required}
            />
          </Col>
          <Col>
            <Datepicker
              field="birthdate"
              label="birthdate"
              dateFormat="DD/MM/YYYY"
              utc
              validate={required}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              field="documentType"
              label="documentType"
              isClearable
              labelClass="font-weight-bold mr-2"
              translateOptions
              options={[
                { id: 'ID', name: 'dni' },
                { id: 'NIE', name: 'nie' },
                { id: 'PASSPORT', name: 'passport' },
              ]}
              validate={required}
            />
          </Col>
          <Col>
            <TextField
              label="documentNumber"
              field="documentNumber"
              validate={required}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              field="nationality"
              label="nationality"
              isClearable
              labelClass="font-weight-bold mr-2"
              translateOptions
              idKey="code"
              options={countries.getData()}
              validate={required}
            />
          </Col>
          <Col>
            <Datepicker
              field="expeditionDate"
              label="expeditionDate"
              dateFormat="DD/MM/YYYY"
              utc
              validate={required}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <Dropzone
              field="frontalId"
              formGroupClass="dz-profile-img"
              label="frontalId"
              validate={required}
              placeholder={(
                <>
                  <i className="fa fa-file fa-4x mb-4" />
                </>
                )}
            />
          </Col>
          <Col xs={12} lg={4}>
            <Dropzone
              field="reverseId"
              formGroupClass="dz-profile-img"
              label="reverseId"
              placeholder={(
                <>
                  <i className="fa fa-file fa-4x mb-4" />
                </>
                )}
            />
          </Col>
          <Col xs={12} lg={4}>
            <Dropzone
              field="signature"
              validate={required}
              formGroupClass="dz-profile-img"
              label="signature"
              placeholder={(
                <>
                  <i className="fa fa-file fa-4x mb-4" />
                </>
                )}
            />
          </Col>
        </Row>
      </>
    )}
  />
);

export default GuestModalForm;
