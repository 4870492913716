import React from 'react';
import { Field } from 'react-final-form';

import AsyncSelectInput from '../utils/AsyncSelectInput';

const AsyncSelectField = React.memo( ( {
  field, validate, value, defaultValue, name, ...rest
} ) => {
  if ( field ) {
    return (
      <Field name={field} validate={validate} type="select">
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && !!meta.submitError );

          return (
            <AsyncSelectInput
              {...input}
              {...rest}
              isInvalid={isInvalid}
              inputName={input.name}
              value={input.value || defaultValue}
              inputOnChange={input.onChange}
              meta={meta}
            />
          );
        }}
      </Field>
    );
  }

  return (
    <AsyncSelectInput
      {...rest}
      inputName={name}
      value={value}
    />
  );
} );

export default AsyncSelectField;
