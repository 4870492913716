import React, { useCallback } from 'react';
import SettingsActions from 'store/reducers/Settings';
import NotificationsPMService from 'api/NotificationsPM';
import _ from 'lodash';
import { handlePromise } from 'utils';
import { connect } from 'react-redux';
import RespondNotificationView from 'views/AccomodationOwner/Edit/notifications/Respond/index';
import { formatMessage } from './utils';

const RespondNotification = ( {
  customerId, pmId, toggleLoading, toggleErrorAlert, toggleInfoAlert,
  open, onClose, messageToRespond,
} ) => {
  const submitNotification = useCallback( async ( values ) => {
    toggleLoading( true );
    const [errors, response] = await handlePromise(
      NotificationsPMService.sendOwnerNotification( pmId, customerId, values ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return toggleErrorAlert( errors );
    }
    toggleLoading( false );
    toggleInfoAlert( 'notifySuccesfull' );
    onClose();
  }, [toggleLoading, customerId, onClose, pmId, toggleErrorAlert, toggleInfoAlert] );
  return (
    <RespondNotificationView
      open={open}
      onClose={onClose}
      preloadedData={formatMessage( messageToRespond )}
      onSubmit={submitNotification}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  pmId: _.get( user, 'user.propertyManager.id', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( RespondNotification );
