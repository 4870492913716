import React from 'react';
import {
  LocalTable,
  AddBtn,
  TableActionsBtns,
  Intl,
  CanAccess,
  TableEditBtn,
} from 'components';
import { canModify } from 'config/Ability';
import moment from 'moment';

export default React.memo( ( {
  data, isLoading, toggleEditModal, onDelete, onDownload,
} ) => (
  <LocalTable
    data={data}
    isLoading={isLoading}
    titleContent={(
      <h3 className="m-0"><Intl id="recordsList" /></h3>
    )}
    rightContent={(
      <CanAccess I="edit" a="accommodation">
        <AddBtn
          title="newRecord"
          onClick={() => {
            toggleEditModal( null );
          }}
        />
      </CanAccess>
    )}
    containerClassName="card-sec-bottom"
    columns={[
      {
        Header: 'id',
        accessor: 'id',
        Cell: ( { cell } ) => (
          <TableEditBtn
            title={cell.value}
            onClick={() => {
              toggleEditModal( cell.value );
            }}
          />
        ),
      },
      {
        Header: 'description',
        accessor: 'description',
        Cell: ( { cell } ) => <Intl id={cell.value} />,
      },
      {
        Header: 'createdOn',
        accessor: 'created',
        Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
      },
      {
        Header: 'createdBy',
        accessor: 'createdBy',
        Cell: ( { cell } ) => ( `${cell.value.fistName} ${cell.value.lastName}` ),
      },
      {
        Header: 'updatedOn',
        accessor: 'updated',
        Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
      },
      {
        Header: 'updatedBy',
        accessor: 'updatedBy',
        Cell: ( { cell } ) => ( `${cell.value.fistName || ''} ${cell.value.lastName || ''}` ),
      },
      canModify( 'accommodation' )
        ? {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  label: 'download',
                  onClick: ( ) => onDownload( cell.value ),
                },
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ]}
            />
          ),
        }
        : {
          id: 'id',
          Header: null,
          maxWidth: 1,
          disableSorting: true,
        },
    ]}
  />
) );
