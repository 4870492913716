/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Drawer from 'rc-drawer';
import { Intl } from 'components';
import { NavLink } from 'react-router-dom';
import Notification from './Notification';
// import Pagination from './Pagination';

const NotificationCenter = ( {
  data, loading, open, onClose, unreadNotifications, onReadAll, onReadNotification,
} ) => (
  <Drawer
    open={open}
    placement="right"
    handler={false}
    level={null}
    onClose={onClose}
    width={360}
  >
    <div className="notification-center">
      <div className="notification-center__content">
        <div className="notification-center__header">
          <div className="d-flex justify-content-between align-items-center px-4 py-3">
            <div>
              <h4 className="uppercase m-0">
                <i
                  className="fa fa-bell mr-2"
                />
                <Intl id="notificationCenter" />
              </h4>
            </div>
            <div>
              <div className="notification-center__close-button" onClick={onClose}>
                <i className="app-icon-close" />
              </div>
            </div>
          </div>
          <hr className="m-0" />
          {unreadNotifications ? (
            <>
              <div className="d-flex justify-content-between align-items-center p-2">
                <div>
                  <span className="text-muted">
                    {`${unreadNotifications && unreadNotifications > 9 ? '+9' : unreadNotifications}`}
                    {' '}
                    <Intl id="pendings" />
                  </span>
                </div>
                <div>
                  <span className="text-muted">
                    <i
                      className="fa fa-check mr-1"
                    />
                    <a
                      href="/"
                      onClick={( e ) => {
                        e.preventDefault();
                        onReadAll();
                      }}
                    >
                      <Intl id="markAllAsProcessed" />
                    </a>
                  </span>
                </div>
              </div>
              <hr className="m-0" />
            </>
          ) : null}
        </div>
        <div className="notification-center__body">

          {data.data ? (
            <>
              <div className="notification-center__list">
                {data.data.map( ( item ) => (
                  <Notification item={item} key={item.id} onReadNotification={onReadNotification} />
                ) )}
              </div>
              {/* <Pagination
                  pageIndex={pageIndex}
                  gotoPage={gotoPage}
                  totalElements={data.elementsTotal || 0}
                  elementsInPage={data.elementsInPage || 10}
                /> */}
              <div className="text-center p-3">
                <NavLink
                  to="/notifications"
                  className="px-4 btn btn-primary btn-sm"
                  onClick={onClose}
                >
                  Ver todas
                </NavLink>
              </div>

            </>
          ) : (
            <div className="notification-center__not_notific">
              <Intl id="withoutNotificationsForNow" />
            </div>
          )}
        </div>
        {loading ? (
          <div className="notification-center__loading" />
        ) : null}
      </div>
    </div>
  </Drawer>
);

export default NotificationCenter;
