import React from 'react';
import { Container } from 'reactstrap';
import classnames from 'classnames';

import { ResourceEditForm } from 'components';

export default React.memo( ( {
  data, entityId, isLoading, options, parentPath, isNomadsAdmin, onSubmit,
} ) => (
  <Container
    fluid
    className={classnames( 'mt-5', { 'app-component-loading': isLoading } )}
  >
    <ResourceEditForm
      entityId={entityId}
      data={data}
      options={options}
      title="myProfile"
      parentPath={parentPath}
      isNomadsAdmin={isNomadsAdmin}
      onSubmit={onSubmit}
      rolesEnabled={false}
    />
  </Container>
) );
