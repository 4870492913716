import React, { useContext } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useDelete } from 'hooks';
import { getBusinnesOpportunities, deleteBusinnesOpportunity } from 'api/CRM/Contact';
import BusinessAllView from 'views/CRM/Business/Dashboard/BusinnessAll';
import { BusinnesCRMContext } from '../context';

const BusinessAll = ( {
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const {
    filters,
    reloadFlag,
    onReload,
  } = useContext( BusinnesCRMContext );

  const [data, dataLoading] = useFetch( {
    promise: ( ) => {
      const searchTerm = filters.keyword ? { businessName: filters.keyword } : {};
      const filterCopy = { ...filters };
      delete filterCopy.keyword;

      return getBusinnesOpportunities( { ...filterCopy, ...searchTerm } );
    },
    initialState: { data: [], elementsTotal: 0, elementsPerPage: 0 },
    toggleErrorAlert,
    deps: [filters, reloadFlag],
    reInit: true,
  } );

  const onDeleteBusinnesOpportunity = useDelete( {
    promise: deleteBusinnesOpportunity,
    callback: () => {
      onReload();
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <BusinessAllView
      data={data}
      isLoading={dataLoading}
      onDeleteBusiness={onDeleteBusinnesOpportunity}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( BusinessAll );
