import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TravelReportEditView from 'views/TravelReport/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import TravelReportAuthority from 'api/TravelReportAuthority';

const Edit = ( {
  travelReportId,
  modalOpened,
  onReload,
  onCloseModal,
  toggleErrorAlert,
  toggleInfoAlert,
  toggleLoading,
} ) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    conditional: modalOpened,
    reInit: true,
    deps: [modalOpened],
  } );

  const [states, statesLoading] = useFetch( {
    initialState: [],
    promise: () => StateService.getStates( selectedCountry.iso ),
    toggleErrorAlert,
    conditional: modalOpened && !!selectedCountry,
    reInit: true,
    deps: [modalOpened, selectedCountry],
  } );

  const [cities, citiesLoading] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( selectedCountry.iso, selectedState.iso ),
    toggleErrorAlert,
    conditional: modalOpened && !!selectedCountry && !!selectedState,
    reInit: true,
    deps: [modalOpened, selectedCountry, selectedState],
  } );

  const handleOnChangeCountry = useCallback(
    ( id ) => {
      setSelectedCountry( _.find( countries, { id } ) );
      setSelectedState();
    },
    [countries],
  );

  const handleOnChangeState = useCallback(
    ( id ) => setSelectedState( _.find( states, { id } ) ),
    [states],
  );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => TravelReportAuthority.getTravelReportAuthority( travelReportId ),
    format: ( dataToFormat ) => {
      const country = _.find( countries, {
        id: _.get( dataToFormat, 'country.id', '' ),
      } );
      if ( country ) {
        setSelectedState();
        setSelectedCountry( country );
      }
      const state = _.find( states, {
        id: _.get( dataToFormat, 'state.id', '' ),
      } );
      if ( state ) {
        setSelectedState( state );
      }
      return dataToFormat;
    },
    toggleErrorAlert,
    conditional: !!travelReportId && modalOpened,
    deps: [travelReportId, modalOpened, countries, states],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( travelReportId
      ? TravelReportAuthority.updateTravelReportAuthorit(
        travelReportId,
        dataToSend,
      )
      : TravelReportAuthority.saveTravelReportAuthority( dataToSend ) ),
    format: ( toFormat ) => ( travelReportId ? toFormat : ( {
      ...toFormat,
      level: 'property_manager',
    } ) ),
    toggleLoading,
    toggleInfoAlert,
    translations: true,
    deps: [travelReportId],
    callback: onReload,
  } );

  return (
    <TravelReportEditView
      travelReportId={travelReportId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
      options={{ cities }}
      countries={countries}
      isLoadingCountries={countriesLoading}
      states={states}
      isLoadingStates={statesLoading}
      cities={cities}
      isLoadingCities={citiesLoading}
      onChangeCountry={handleOnChangeCountry}
      onChangeState={handleOnChangeState}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Edit );
