import api from 'config/Api/Api';

const baseEndpoint = '/master-keys';

export default {
  getMasterKeys( params ) {
    return api.get( baseEndpoint, params );
  },

  getMasterKey( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveMasterKey( data ) {
    return api.post( baseEndpoint, data );
  },

  updateMasterKey( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteMasterKey( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
