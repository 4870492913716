import React from 'react';
// import { createCanBoundTo } from "@casl/react";
import { canModify, canList, can } from 'config/Ability';

// export default createCanBoundTo( ability );

const getCan = ( I, a ) => {
  const IBasic = ['edit', 'view', 'delete', 'add'];
  if ( IBasic.includes( I ) ) {
    return canModify( a );
  }
  if ( I === 'list' ) {
    return canList( a );
  }
  return can( I, a );
};

export default ( { I = 'edit', a, children } ) => {
  const canI = getCan( I, a );

  return canI ? <>{typeof children === 'function' ? children( canI ) : children}</> : null;
};
