import React, { useEffect, useState } from 'react';
import {
  Row, Col, UncontrolledTooltip, Button,
} from 'reactstrap';
import moment from 'moment';
import classnames from 'classnames';
import _ from 'lodash';
import {
  AsyncTable, AddBtn, TableGenericFilter, TableToggler, Intl, Checkbox, DetailCard,
  TableEditBtn, TableActionsBtns, ResourceAvatar, TableSelectFilter, DaterangePickerBtn, CanAccess,
  TaskDownload,
} from 'components';

import { taskPriorities, taskStatuses, taskTypes as typeOfTaskTypes } from 'config/Constants';
import { canModify } from 'config/Ability';
import { getResourceName } from 'utils';
import getStatusColor from 'config/Constants/StatusColor';

const statusColor = getStatusColor( 'tasks' );

export default React.memo( ( {
  dataToExport, csvLinkRef, disabledBulkEdit,
  onFetchData, onOpenEditModal, onDelete, onDateChanged, onFetchProperties, onFetchTeams,
  data, isLoading, initialDates, defaultFilters, setFiltersValues,
  selectedRows, bulkErrors, onOpenBulkDeleteModal,
  onFetchAccommodations, onFetchTagsPredefined,
  onOpenBulkEditModal, onToggleRowChecked, onToggleAllRowsChecked,
  onDownloadData, onTodaysTasks, onFetchEmployees, taskTypes, isLoadingTaskTypes, Cities, zones,
  onOpenIncidenceEditModal, isOwner,
} ) => {
  const canViewEditDelete = canModify( 'task' );
  const [showErrors, setShowErrors] = useState( true );

  useEffect( () => {
    if ( !showErrors ) setShowErrors( true );
  }, [bulkErrors] ); //eslint-disable-line

  return (
    <>
      <div className="py-2 pr-4 border-bottom">
        <Row className="justify-content-end">
          <Col xs="auto">
            <Button
              color="primary"
              className="mt-2 mt-md-0 ml-2 mr-0"
              onClick={onTodaysTasks}
            >
              <i className="th-icon-calendar mr-2" style={{ position: 'relative', top: 3 }} />
              <Intl id="today" />
            </Button>

            <DaterangePickerBtn
              position="right"
              initialValue={initialDates}
              key={initialDates}
              onChange={onDateChanged}
            />
            <CanAccess I="edit" a="task">
              <Button
                color="primary"
                className="mt-2 mt-md-0 ml-2 mr-0"
                disabled={disabledBulkEdit}
                onClick={onOpenBulkEditModal}
                id="bulkAssigmentButton"
              >
                <i className="fa fa-edit mr-1" />
                <Intl id="bulkAssigment" />
              </Button>
              {disabledBulkEdit ? (
                <UncontrolledTooltip
                  delay={0}
                  target="bulkAssigmentButton"
                >
                  <Intl id="bulkAssigmentTooltip" />
                </UncontrolledTooltip>
              ) : null}
              <Button
                color="danger"
                className="mt-2 mt-md-0 ml-2 mr-0"
                disabled={!selectedRows.length}
                onClick={onOpenBulkDeleteModal}
              >
                <i className="fa fa-edit mr-1" />
                <Intl id="bulkDelete" />
              </Button>
              <TaskDownload
                data={dataToExport}
                csvLinkRef={csvLinkRef}
                onDownloadData={onDownloadData}
              />
            </CanAccess>
          </Col>
        </Row>
      </div>

      {bulkErrors && bulkErrors.length && showErrors
        ? (
          <Row className="mb-3">
            <Col md={12}>
              <DetailCard
                classNames={{
                  header: 'py-2 justify-content-between d-flex align-items-center',
                }}
                header={(
                  <>
                    <Intl id="errors" />
                    <i
                      className="app-icon-close text-sm cursor-pointer"
                      onClick={() => setShowErrors( false )}
                    />
                  </>
                  )}
              >
                {_.map( bulkErrors, ( item ) => {
                  const task = _.find( data.data, { id: item.id } );
                  return (
                    <div key={item.id} className="mt-2">
                      {task ? task.name : ''}
                      <div className={classnames( 'error', { 'ml-2': !!task } )}>
                        {_.map( item.errors, ( error, index ) => <div key={index}>{error}</div> )}
                      </div>
                    </div>
                  );
                } )}
              </DetailCard>
            </Col>
          </Row>
        )
        : null}

      <AsyncTable
        id="tasks"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        defaultFilters={defaultFilters}
        customFiltersOpened={defaultFilters && ( defaultFilters.priority || defaultFilters.status
            || defaultFilters.taskType || defaultFilters.type || defaultFilters.property
            || defaultFilters.accommodation )}
        rightContent={(
          <CanAccess I="add" a="task">
            {!isOwner && <AddBtn title="newTask" onClick={onOpenEditModal} />}
            {/* <AddBtn title="newIncidence" onClick={onOpenIncidenceEditModal} /> */}
          </CanAccess>
          )}
        onFetchData={onFetchData}
        customFilters={( { filters, setFilter } ) => (
          <Row>
            <Col md={8} lg={4}>
              <TableGenericFilter
                filters={filters}
                setFilter={( key, value ) => {
                  setFilter( key, value );
                  setFiltersValues( { ...filters, [key]: value } );
                }}
              />
            </Col>
            <TableToggler />
          </Row>
        )}
        customToggleFilters={( { filters, setFilter: setFilterOriginal } ) => {
          const setFilter = ( key, value ) => {
            setFilterOriginal( key, value );
            setFiltersValues( { ...filters, [key]: value } );
          };

          return (
            <>
              <Row>
                <Col md={8} lg={3} className="pb-3">
                  <TableSelectFilter
                    name="priority"
                    label="priority"
                    placeholder="all"
                    filters={filters}
                    translateOptions
                    isClearable
                    options={_.map( taskPriorities, ( priority ) => ( {
                      id: priority.id,
                      name: priority.name,
                      icon: <i className={classnames( priority.iconClass, 'text-2xsm' )} />,
                    } ) )}
                    setFilter={setFilter}
                  />
                </Col>
                <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
                  <TableSelectFilter
                    name="status"
                    label="status"
                    placeholder="all"
                    filters={filters}
                    translateOptions
                    isClearable
                    options={taskStatuses}
                    setFilter={setFilter}
                  />
                </Col>
                <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
                  <TableSelectFilter
                    name="taskType"
                    label="category"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    setFilter={setFilter}
                    options={taskTypes}
                    optgroup
                    isLoading={isLoadingTaskTypes}
                  />
                </Col>
                <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
                  <TableSelectFilter
                    name="type"
                    label="type"
                    placeholder="all"
                    filters={filters}
                    translateOptions
                    isClearable
                    options={typeOfTaskTypes}
                    setFilter={setFilter}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={8} lg={3}>
                  <TableSelectFilter
                    name="property"
                    label="property"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    setFilter={setFilter}
                    defaultOptions
                    loadOptions={onFetchProperties}
                  />
                </Col>
                <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
                  <TableSelectFilter
                    name="accommodation"
                    label="accommodation"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    defaultOptions
                    loadOptions={onFetchAccommodations}
                    setFilter={setFilter}
                  />
                </Col>
                <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
                  <TableSelectFilter
                    name="team"
                    label="team"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    defaultOptions
                    // labelKey={getResourceName}
                    loadOptions={onFetchTeams}
                    setFilter={setFilter}
                  />
                </Col>
                <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
                  <TableSelectFilter
                    name="assigned"
                    label="assigned"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    defaultOptions
                    labelKey={getResourceName}
                    loadOptions={onFetchEmployees}
                    setFilter={setFilter}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={8} lg={3}>
                  <TableSelectFilter
                    name="city"
                    label="city"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    defaultOptions
                    setFilter={setFilter}
                    options={Cities}
                  />
                </Col>
                <Col md={8} lg={3}>
                  <TableSelectFilter
                    enableReinitialize
                    isDisabled={zones.length === 0}
                    name="zone"
                    label="zone"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    options={zones}
                    setFilter={setFilter}
                  />
                </Col>
                <Col md={8} lg={6} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
                  <TableSelectFilter
                    name="tags"
                    label="tags"
                    placeholder="all"
                    filters={{
                      ...filters,
                      tags: filters.tags ? filters.tags.map(
                        ( tag ) => ( { value: tag, label: tag } ),
                      ) : [],
                    }}
                    multiple
                    isClearable
                    setFilter={( key, value ) => {
                      setFilter( key, ( value || [] ).map( ( t ) => t.value ) );
                    }}
                    defaultOptions
                    loadOptions={onFetchTagsPredefined}
                    formatFn={( dataTagsPredefined ) => (
                      ( dataTagsPredefined || [] ).map(
                        ( item ) => ( { id: item, name: item } ),
                      ) )}
                  />
                </Col>
              </Row>
            </>
          );
        }}
        getRowProps={( row ) => ( { className: row.original.type === 'issue' ? 'bg-light-red' : '' } )}
        columns={[
          {
            Header: <Checkbox
              name="header"
              formGroupClass="m-0"
              value={data.data && data.data.length && data.data.length === selectedRows.length}
              onChange={onToggleAllRowsChecked}
            />,
            disableSorting: true,
            id: 'check',
            accessor: 'id',
            maxWidth: 45,
            Cell: ( { cell } ) => (
              <Checkbox
                name={cell.value}
                formGroupClass="m-0"
                value={selectedRows.indexOf( cell.value ) !== -1}
                onChange={( value ) => onToggleRowChecked( cell.value, value )}
              />
            ),
          },
          {
            Header: null,
            accessor: 'reporter.roles',
            maxWidth: 30,
            disableSorting: true,
            Cell: ( { cell } ) => {
              const isEmployye = _.find(
                cell.value, ( rol ) => rol === 'ROLE_EMPLOYEE'
                    || rol === 'ROLE_NOMADS_ADMIN'
                    || rol === 'ROLE_ADMIN',
              );
              return !isEmployye ? (
                <span>
                  <i
                    className="fas fa-exclamation-circle text-warning m-2"
                    id={`tooltip-reported-${cell.row.original.id}`}
                  />
                  <UncontrolledTooltip
                    delay={0}
                    target={`tooltip-reported-${cell.row.original.id}`}
                  >
                    <Intl id="reportedByGuest" />
                  </UncontrolledTooltip>
                </span>
              ) : null;
            },
          },
          {
            Header: null,
            accessor: 'priority',
            maxWidth: 30,
            disableSorting: true,
            Cell: ( { cell } ) => {
              const priority = _.find( taskPriorities, { id: cell.value } );
              return priority ? (
                <span>
                  <i
                    className={classnames( priority.iconClass, 'text-2xsm' )}
                    id={`tooltip-supervisor-${cell.row.original.id}`}
                  />
                  <UncontrolledTooltip
                    delay={0}
                    target={`tooltip-supervisor-${cell.row.original.id}`}
                  >
                    <Intl id={priority.name} />
                  </UncontrolledTooltip>
                </span>
              ) : null;
            },
          },
          {
            Header: '#',
            accessor: 'number',
            disableSorting: true,
            Cell: ( { cell } ) => {
              const title = `${cell.row.original.id.slice( cell.row.original.id.length - 6, cell.row.original.id.length )}`;
              return (
                canViewEditDelete && !isOwner ? (
                  <TableEditBtn
                    className="text-uppercase"
                    title={title}
                    onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
                  />
                ) : <span className="text-uppercase">{title}</span>
              );
            },
          },
          {
            Header: 'aptoOrPlace',
            accessor: 'accommodation.name',
            id: 'accommodationName',
            Cell: ( { cell } ) => {
              if ( cell.row.original?.place?.id ) {
                return (
                  <div style={{ lineHeight: 1.2 }}>
                    <div className="text-uppercase">{cell.row.original?.place?.name}</div>
                    <strong className="text-uppercase">
                      (
                      <Intl id="place" />
                      )
                    </strong>
                  </div>
                );
              }
              return (
                <span className="text-uppercase">{cell.value}</span>
              );
            },
          },
          {
            Header: 'description',
            accessor: 'description',
            disableSorting: true,
          },
          {
            Header: 'category',
            accessor: 'taskType.name',
            disableSorting: true,
          },
          {
            Header: 'scheduledFor',
            accessor: 'date',
            Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
          },
          {
            Header: 'reported',
            accessor: 'created',
            disableSorting: true,
            Cell: ( { cell } ) => (
              <div>
                <ResourceAvatar className="mb-1" data={cell.row.original.reporter} />
                <span>{( cell.value ? moment( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' )}</span>
              </div>
            ),
          },
          {
            Header: 'status',
            accessor: 'status',
            disableSorting: true,
            Cell: ( { cell } ) => {
              const status = _.find( taskStatuses, { id: cell.value } );
              return status && status.name ? <span className={`badge badge-${statusColor( status.name )} badge-pill`}><Intl id={status.name} /></span> : null;
            },
          },
          {
            Header: 'assignedTo',
            accessor: 'assigned',
            disableSorting: true,
            Cell: ( { cell } ) => (
              <ResourceAvatar data={_.get( cell.value, `[${cell.value.length - 1}].employee[0]` )} />
            ),
          },
          canViewEditDelete && !isOwner
            ? {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => {
                const isIncidence = cell.row.original.type === 'issue';
                const actions = [];
                actions.push( {
                  label: 'edit',
                  onClick: () => ( isIncidence
                    ? onOpenIncidenceEditModal( { id: cell.value } )
                    : onOpenEditModal( { id: cell.value } ) ),
                } );

                actions.push( {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                } );
                return (
                  <TableActionsBtns actions={actions} />
                );
              },
            } : {
              id: 'id', Header: null, maxWidth: 1, disableSorting: true,
            },
        ]}
      />
    </>
  );
} );
