import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import SettingsActions from 'store/reducers/Settings';
import PropertyManagerService from 'api/PropertyManager';
import { formatDataToSend, handlePromise } from 'utils';
import EditChargesView from 'views/PropertyManager/Edit/Finance/Charges/edit';
import { useFetch } from 'hooks';

const EditChargesContainer = ( {
  pmData,
  elementToEdit,
  onClose,
  onReloadData,
  toggleInfoAlert,
  toggleErrorAlert,
  toggleLoading,
} ) => {
  const [feeOptions, feesLoading] = useFetch( {
    promise: () => PropertyManagerService.getFeeList( pmData.id, {
      page: 1,
      elementsPerPage: 10,
    } ),
    format: ( dataToFormat ) => ( dataToFormat?.data || [] ).map( ( key ) => ( {
      id: key.id,
      name: key.periodicity,
    } ) ).filter( ( { name } ) => name === 'once' ),
    deps: [pmData.id],
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const datesSend = formatDataToSend( formData, form );

      let deadline = '';
      if ( datesSend.deadline ) {
        deadline = typeof datesSend.deadline === 'number'
          ? moment.utc( datesSend.deadline ).format( 'YYYY-MM-DD' )
          : datesSend.deadline.format( 'YYYY-MM-DD' );
      }

      const dataToSend = {
        ...datesSend,
        deadline,
      };

      const [dataErrors, dataResponse] = await handlePromise(
        elementToEdit
          ? PropertyManagerService.putCharge(
            pmData.id,
            elementToEdit.id,
            dataToSend,
          )
          : PropertyManagerService.postCharge( pmData.id, dataToSend ),
      );

      toggleLoading( false );

      if ( !dataResponse.ok ) {
        toggleErrorAlert();
        return dataErrors;
      }

      toggleInfoAlert( 'dataSaved' );
      onReloadData();
    },
    [
      pmData,
      elementToEdit,
      onReloadData,
      toggleErrorAlert,
      toggleInfoAlert,
      toggleLoading,
    ],
  );

  return (
    <EditChargesView
      elementToEdit={elementToEdit}
      submitForm={submitForm}
      onClose={onClose}
      feeOptions={feeOptions}
      feesLoading={feesLoading}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( EditChargesContainer );
