import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TeamEditView from 'views/Team/New';
import SettingsActions from 'store/reducers/Settings';
import EmployeeService from 'api/Employee';
import PropertyService from 'api/Property';
import TaskTypeService from 'api/TaskType';
import TeamService from 'api/Team';
import { useSubmit, useFetch } from 'hooks';

const TeamEdit = ( {
  teamId, propertyManager, modalOpened,
  onCloseModal, onReload, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => TeamService.getTeam( teamId ),
    format: ( dataToFormat ) => {
      const citiesGroup = _.groupBy(
        dataToFormat.zones,
        'propertyManagerCity.id',
      );
      const cities = _.map( _.values( citiesGroup ), ( cityGroup ) => {
        const propertyManagerCity = _.get(
          cityGroup,
          '[0].propertyManagerCity',
        );
        return {
          value: propertyManagerCity.id,
          label: propertyManagerCity.city.name,
        };
      } );
      const zones = _.map( _.values( citiesGroup ),
        ( zoneGroup ) => _.map( zoneGroup, ( zone ) => zone.id ) );
      return {
        ...dataToFormat,
        taskTypes: _.map( _.get( dataToFormat, 'taskTypes', [] ), ( item ) => _.get( item, 'id' ) ),
        manager: {
          value: dataToFormat.manager.id,
          label: `${dataToFormat.manager.firstName || ''} ${
            dataToFormat.manager.lastName || ''
          }`,
        },
        admins: _.map( dataToFormat.admins, ( item ) => ( {
          value: item.id,
          label: `${item.firstName || ''} ${item.lastName || ''}`,
        } ) ),
        members: _.map( dataToFormat.members, ( item ) => ( {
          value: item.id,
          label: `${item.firstName || ''} ${item.lastName || ''}`,
        } ) ),
        cities,
        zones,
        properties: _.map( dataToFormat.properties, ( item ) => ( {
          value: item.id,
          label: item.name,
        } ) ),
      };
    },
    toggleErrorAlert,
    conditional: modalOpened && !!teamId,
    deps: [modalOpened, teamId],
    reInit: true,
  } );

  const fetchEmployees = useCallback( ( query ) => EmployeeService.getEmployees( {
    propertyManager: propertyManager.id,
    elementsPerPage: 10,
    keyword: query,
    employee: true,
  } ), [propertyManager] );

  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( teamId
      ? TeamService.updateTeam( teamId, dataToSend )
      : TeamService.saveTeam( dataToSend ) ),
    format: ( dataToSend ) => {
      const _dataToSend = { ...dataToSend };
      return {
        ..._dataToSend,
        master: dataToSend.master ? 'true' : 'false',
        taskInsideShift: dataToSend.taskInsideShift ? 'true' : 'false',
        cities: dataToSend.cities.map( ( c ) => c.value ),
        zones: _.flatten( dataToSend.zones ),
      };
    },
    toggleLoading,
    toggleInfoAlert,
    deps: [teamId, onReload],
    callback: onReload,
  } );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  return (
    <TeamEditView
      data={data}
      teamId={teamId}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
      onFetchEmployees={fetchEmployees}
      onFetchProperties={fetchProperties}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( TeamEdit );
