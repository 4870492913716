import TaskService from 'api/Task';

const loadTasks = async ( params ) => {
  const { data: loadedTasks } = await TaskService.getTasks( {
    ...params,
    type: 'task',
  } );
  const { data: loadedPreventive } = await TaskService.getTasks( {
    ...params,
    type: 'preventive',
  } );
  return {
    ok: true,
    data: {
      data: [
        ...loadedTasks.data,
        ...loadedPreventive.data,
      ].sort( ( a, b ) => b.date - a.date ),
      elementsInPage:
        loadedTasks.elementsInPage + loadedPreventive.elementsInPage,
      elementsTotal: loadedTasks.elementsTotal + loadedPreventive.elementsTotal,
    },
    status: 200,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { loadTasks };
