import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import {
  AsyncTable,
  AddBtn,
  SimpleHeader,
  TableGenericFilter,
  TableActionsBtns,
  Intl,
} from 'components';

export default React.memo(
  ( {
    data, isLoading, onFetchData, onOpenEditModal, onDelete,
  } ) => (
    <>
      <SimpleHeader title="travelReport" />
      <Container fluid>
        <AsyncTable
          id="travelReport"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          rightContent={
            <AddBtn title="newTravelReport" onClick={onOpenEditModal} />
          }
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter filters={filters} setFilter={setFilter} />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'country',
              accessor: 'country.name',
            },
            {
              Header: 'state',
              accessor: 'state.name',
            },
            {
              Header: 'city',
              accessor: 'city.name',
            },
            {
              Header: 'type',
              accessor: 'type',
              Cell: ( { cell } ) => <Intl id={cell.value} />,
            },
            {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => (
                <TableActionsBtns
                  actions={[
                    {
                      isDelete: true,
                      onClick: ( cb ) => onDelete( cell.value, cb ),
                    },
                    {
                      label: 'edit',
                      onClick: () => onOpenEditModal( { id: cell.value } ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </Container>
    </>
  ),
);
