import React, { useState, useEffect } from 'react';
import {
  Container, Card,
} from 'reactstrap';
import classnames from 'classnames';
import {
  HorizontalMenu,
  SimpleHeader,
} from 'components';
import { Redirect, Route, Switch } from 'react-router';
import Actions from 'containers/Communications/Operative/Actions';
import Settings from 'containers/Communications/Operative/Settings';

const tabs = [
  {
    name: 'actions',
    path: 'actions',
    component: Actions,
  },
  {
    name: 'settings',
    path: 'settings',
    component: Settings,
  },
];

const OperativeView = ( { isLoading, match } ) => {
  const [currentTab, setCurrentTab] = useState( tabs[0].name );

  const getRoutes = ( routesList ) => routesList.map( ( route ) => {
    if ( route.component ) {
      return (
        <Route
          exact={route.exact}
          path={`${match.url}/${route.path}`}
          render={( props ) => (
            <route.component
              {...props}
              baseUrl={`${match.url}/${route.path}`}
            />
          )}
          key={route.layout + route.path}
        />
      );
    }
    return null;
  } );

  useEffect( () => {
    const p = window.location.href.split( '?' )[0].split( 'operative/' )[1].split( '/' )[0];

    tabs.forEach( ( t ) => {
      if ( t.path === p ) {
        setCurrentTab( t.name );
      }
    } );
  }, [] );

  return (
    <>
      <SimpleHeader
        title="operative"
      />
      <Container
        fluid
        className={classnames( 'mt-1', { 'app-component-loading': isLoading } )}
      >
        <Card className="card-sec-top border-bottom">
          <HorizontalMenu
            tabs={tabs}
            selected={currentTab}
            onChangeTab={setCurrentTab}
            pathLayout={`${match.url}/`}
          />

          <Switch>
            {getRoutes( tabs )}
            <Redirect from="*" to={`${match.url}/${tabs[0].path}`} />
          </Switch>
        </Card>

      </Container>
    </>
  );
};

export default OperativeView;
