import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import NotificationOwnersView from 'views/AccomodationOwner/Edit/notifications';
import SettingsActions from 'store/reducers/Settings';
import NotificationsAccOwnerService from 'api/NotificationsAccOwner';
import { useFetchTableData } from 'hooks';
import SendNotification from './Send';
import RespondNotification from './Respond';

const NotificationOwners = ( { match, toggleErrorAlert, isAdmin } ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const accOwnerId = match.params.id;
  const [showSendModal, setShowSendModal] = useState( false );
  const [showRespondModal, setShowRespondModal] = useState( false );
  const [messageToRespond, setMessageToRespond] = useState( );

  const openSendModal = () => setShowSendModal( true );
  const closeSendModal = () => setShowSendModal( false );

  const openRespondModal = () => setShowRespondModal( true );
  const closeRespondModal = () => setShowRespondModal( false );

  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => NotificationsAccOwnerService.getNotifications( accOwnerId, params ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const reload = () => {
    setDataSubmitted( new Date().valueOf() );
  };

  const handleRespond = ( message ) => {
    setMessageToRespond( message );
    openRespondModal();
  };

  return (
    <>
      <NotificationOwnersView
        isAdmin={isAdmin}
        fetchData={fetchData}
        dataTable={dataTable}
        dataLoading={dataLoading}
        onAddButton={openSendModal}
        onRespondButton={handleRespond}
      />
      <SendNotification
        customerId={accOwnerId}
        reload={reload}
        open={showSendModal}
        onClose={closeSendModal}
      />
      <RespondNotification
        customerId={accOwnerId}
        open={showRespondModal}
        messageToRespond={messageToRespond}
        onClose={closeRespondModal}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userId: _.get( user, 'user.id', {} ),
  isAdmin: _.get( user, 'mainRole', '' ) === 'ROLE_ADMIN',
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( NotificationOwners );
