import api from 'config/Api/Api';

const baseEndpoint = ( bookedServiceId ) => `/booked-services/${bookedServiceId}/items`;

export default {
  getBookedServiceItems( bookedServiceId, params ) {
    return api.get( baseEndpoint( bookedServiceId ), params );
  },
  getBookedServiceItem( bookedServiceId, id ) {
    return api.get( `${baseEndpoint( bookedServiceId )}/${id}` );
  },
  saveBookedServiceItem( bookedServiceId, data ) {
    return api.post( baseEndpoint( bookedServiceId ), data );
  },

  updateBookedServiceItem( bookedServiceId, id, data ) {
    return api.put( `${baseEndpoint( bookedServiceId )}/${id}`, data );
  },
  deleteBookedServiceItem( bookedServiceId, id ) {
    return api.delete( `${baseEndpoint( bookedServiceId )}/${id}` );
  },
};
