import { useSubmit } from 'hooks';
import React from 'react';
import SettingsActions from 'store/reducers/Settings';
import SeasonEditView from 'views/PropertyManager/Edit/Rates/EditDrawer/RateSeasons/Edit';

import { connect } from 'react-redux';
import _ from 'lodash';
import { createWeekSeasonRate } from './utils';

const SeasonEdit = ( {
  rateId,
  data,
  events,
  open,
  onClose,
  onReloadData,
  toggleLoading,
  toggleInfoAlert,
} ) => {
  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( createWeekSeasonRate( events, dataToSend ) ),
    format: ( dataToSend ) => ( {
      rateId,
      seasons: [
        {
          ...dataToSend,
          lastNight: new Date( dataToSend.lastNight ).toISOString(),
          startDate: new Date( dataToSend.startDate ).toISOString(),
        },
      ],
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: onReloadData,
    deps: [rateId, events],
  } );

  return (
    <SeasonEditView
      fixDates
      data={data}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( SeasonEdit );
