import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const DELETE_DISCOUNT = gql`
  mutation DeleteDiscount($discountId: ID!) {
    deleteDiscount(discountId: $discountId) {
      ... on BaseError {
        message
      }
      ... on MutationDeleteDiscountSuccess {
        data {
          success
        }
      }
    }
  }
`;

const deleteDiscount = async ( discountId ) => {
  const { data } = await client.query( {
    query: DELETE_DISCOUNT,
    variables: {
      discountId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteDiscount.message ) {
    return {
      ok: false,
      message: data.deleteDiscount.message,
    };
  }
  const deleteSuccessful = data.deleteDiscount.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteDiscount;
