import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { canModify } from 'config/Ability';
import {
  AsyncTable, AddBtn, SimpleHeader, TableGenericFilter, TableActionsBtns, Intl,
} from 'components';

export default React.memo( ( {
  data, isLoading, defaultFilters, parentPath, onFetchData, onDelete,
} ) => (
  <>
    <SimpleHeader title="services" />
    <Container fluid>
      <AsyncTable
        id="services"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        rightContent={canModify( 'services' ) ? <AddBtn title="newService" route={`${parentPath}/new`} /> : null}
        onFetchData={onFetchData}
        customFilters={( { filters, setFilter } ) => (
          <Row>
            <Col md={8} lg={4}>
              <TableGenericFilter
                filters={filters}
                setFilter={setFilter}
              />
            </Col>
          </Row>
        )}
        columns={[
          {
            Header: 'name',
            accessor: 'name',
            Cell: ( { cell } ) => (
              <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                {cell.value}
              </Link>
            ),
          },
          {
            Header: 'subtitle',
            accessor: 'subtitle',
          },
          {
            Header: 'requiresTimeSlot',
            accessor: 'requiresTimeSlot',
            Cell: ( { cell } ) => (
              cell.value ? <Intl id="yes" /> : <Intl id="no" />
            ),
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </Container>
  </>
) );
