import React, { useState, useCallback } from 'react';
import EditNotifications from 'containers/Booking/Edit/Notifications/Edit';
import {
  Intl,
  DetailCard,
  LocalTable,
  AddBtn,
} from 'components';
import { Row, Col } from 'reactstrap';

import moment from 'moment';

export default React.memo( ( {
  data, isLoading, bookingData, onReload,
} ) => {
  const [openEditNotifications, setOpenEditNotifications] = useState( false );
  const [notificationsToEditId, setNotificationsToEditId] = useState( null );

  const toggleEditNotifications = useCallback( ( notificationId ) => {
    setNotificationsToEditId( notificationId );
    setOpenEditNotifications( ( v ) => !v );
  }, [] );

  return (
    <>
      <DetailCard
        classNames={{ header: 'py-2 border-0', body: 'pt-0 pb-2 px-0', card: 'card-sec-bottom' }}
        header={(
          <Row className="justify-content-stretch align-items-center">
            <Col>
              <h3 className="m-0">
                <Intl id="notifications" />
              </h3>
            </Col>
            <Col className="text-right">
              <AddBtn
                title="newNotification"
                onClick={() => { toggleEditNotifications( null ); }}
              />
            </Col>
          </Row>

      )}
        isLoading={isLoading}
      >
        <LocalTable
          onlyTable
          manualPagination
          data={data.data}
          columns={[
            {
              Header: 'notification',
              accessor: 'communicationTemplate.title',
              Cell: ( { cell } ) => (
                <a
                  href="/"
                  onClick={( e ) => {
                    e.preventDefault();
                    toggleEditNotifications( cell.row.original.id );
                  }}
                >
                  {cell.value}
                </a>
              ),
            },
            {
              Header: 'date',
              accessor: 'executeAfter',
              Cell: ( { cell } ) => ( cell.value
                ? `${moment( cell.value ).format( 'MMM DD, YYYY H:mm' )}`
                : '' ),
            },
            {
              Header: 'language',
              accessor: 'bookedAccommodation.owner.locale',
            },
            {
              Header: 'to',
              accessor: 'bookedAccommodation.owner.email',
            },
            {
              Header: 'sent',
              accessor: 'executed',
              Cell: ( { cell } ) => ( cell.value
                ? <Intl id="yes" />
                : <Intl id="no" /> ),
            },
          ]}
        />
      </DetailCard>
      <EditNotifications
        open={openEditNotifications}
        bookingData={bookingData}
        notificationId={notificationsToEditId}
        onClose={() => { toggleEditNotifications( null ); }}
        onReload={onReload}
      />
    </>
  );
} );
