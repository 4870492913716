import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import ContactsView from 'views/CRM/Contact/Dashboard/Contacts';
import { useDelete, useFetchTableData } from 'hooks';
import { handlePromise } from 'utils';
import { getContacts, deleteContact, toggleContactActivation } from 'api/CRM/Contact';

const Contacts = ( {
  path, setRecordsCount, onReloadContacts, reloadContacts, toggleLoading, toggleErrorAlert,
} ) => {
  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getContacts( { ...params } ),
    toggleErrorAlert,
    deps: [onReloadContacts],

  } );

  useEffect( () => {
    setRecordsCount( data?.elementsTotal || 0 );
  }, [setRecordsCount, data] );

  const onDeleteContact = useDelete( {
    promise: deleteContact,
    callback: () => {
      reloadContacts();
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const toggleActivation = useCallback( async ( id, value ) => {
    toggleLoading( true );

    const [errors, response] = await handlePromise(
      toggleContactActivation( id, value ),
    );

    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );
    reloadContacts();
  }, [toggleErrorAlert, toggleLoading, reloadContacts] );

  return (
    <ContactsView
      path={path}
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      onDeleteContact={onDeleteContact}
      toggleActivation={toggleActivation}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( Contacts );
