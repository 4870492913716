import React, { useState } from 'react';
import { Field } from 'react-final-form';

import DateMultiPickerInput from '../utils/DateMultiPickerInput';

const Datepicker = React.memo( ( {
  field, validate, value, name, position, ...rest
} ) => {
  const [focused, setFocused] = useState( false );

  if ( field ) {
    return (
      <Field name={field} validate={validate}>
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && meta.submitError );

          return (
            <DateMultiPickerInput
              {...input}
              {...rest}
              position={position}
              value={input.value || ''}
              inputOnChange={input.onChange}
              inputName={input.name}
              focused={meta.active}
              meta={meta}
              isInvalid={isInvalid}
            />
          );
        }}
      </Field>
    );
  }
  return (
    <DateMultiPickerInput
      {...rest}
      position={position}
      value={value || ''}
      inputName={name}
      focused={focused}
      onFocus={() => setFocused( true )}
      onBlur={() => setFocused( false )}
    />
  );
} );

export default Datepicker;
