import React, { useMemo } from 'react';
import moment from 'moment';
import { Intl } from 'components';
import { Button } from 'reactstrap';

const prefixToViewPDF = 'https://docs.google.com/viewer?url=';

const ViewContract = ( { contractToView, toggleModalView } ) => {
  const data = useMemo( () => {
    if ( !contractToView ) {
      return null;
    }

    const {
      id, contract: src, dateFrom, dateTill,
    } = contractToView;
    const type = src.toLowerCase().indexOf( '.pdf' ) > 0 ? 'pdf' : 'img';

    return {
      id,
      src,
      type,
      from: moment( dateFrom ).format( 'DD/MM/YYYY' ),
      till: moment( dateTill ).format( 'DD/MM/YYYY' ),
    };
  }, [contractToView] );

  return data ? (
    <div>
      <h3 className="">
        <Intl id="contract" />
        {' '}
        {data.id}
      </h3>
      <hr className="mt-0" />
      <p>
        <Intl id="from" />
        {' '}
        <strong>{data.from}</strong>
        {' '}
        <Intl id="till" />
        {' '}
        <strong>{data.till}</strong>
        .
      </p>
      <div className="text-center">
        <div className="mb-3">
          {data.type === 'pdf' ? (
            <div className="dz-preview-pdf">
              <div className="dz-preview-pdf_inner">PDF</div>
            </div>
          ) : (
            <img
              className="dz-preview-img"
              src={data.src}
              alt=""
            />
          )}
        </div>
        <Button href={( data.type === 'pdf' ? prefixToViewPDF : '' ) + data.src} target="_blank" color="primary" size="sm" className="px-5">
          <Intl id="openInNewTab" />
        </Button>
      </div>
      <div className="text-center">
        <hr className="mb-3" />
        <Button color="primary" outline onClick={toggleModalView}>
          <Intl id="close" />
        </Button>
      </div>
    </div>
  ) : null;
};

export default ViewContract;

/*  */
