import React from 'react';
import { connect } from 'react-redux';

import TaskTypeNewView from 'views/TaskType/Parent/New';
import TaskTypeService from 'api/TaskType';
import SettingsActions from 'store/reducers/Settings';
import { useSubmit } from 'hooks';

const TaskTypeNew = ( {
  modalOpened,
  onReload,
  onCloseModal,
  toggleInfoAlert,
  toggleLoading,
} ) => {
  const submitForm = useSubmit( {
    promise: TaskTypeService.saveTaskType,
    format: ( dataToFormat ) => ( { ...dataToFormat, enabled: 'true' } ),
    toggleLoading,
    toggleInfoAlert,
    translations: true,
    callback: onReload,
  } );

  return (
    <TaskTypeNewView
      data={{}}
      isLoading={false}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( TaskTypeNew );
