import api from 'config/Api/Api';

const baseEndpoint = 'predefined-notification';

export default {
  getNotifications( params ) {
    return api.get( baseEndpoint, params );
  },
  getNotification( id, params ) {
    return api.get( `${baseEndpoint}/${id}`, params );
  },
  saveNotification( data ) {
    return api.post( baseEndpoint, data );
  },
  updateNotification( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  deleteNotification( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
