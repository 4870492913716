import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import classnames from 'classnames';

import {
  SimpleHeader,
  Intl,
  LocalTable,
  DetailCard,
  TableEditBtn,
  AddBtn, TableActionsBtns,
} from 'components';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { currencyFormat, formatFloat } from '../../../utils';
import { bookedServiceItemStatus } from '../../../config/Constants';

export default React.memo( ( {
  data, items, isLoading, parentPath, onOpenEditItemModal, onDeleteItem, userCurrency,
} ) => (
  <>
    <SimpleHeader
      parentLinks={[{ name: 'services', href: parentPath }]}
    />

    <Container
      fluid
      className={classnames( { 'app-component-loading': isLoading } )}
    >
      <DetailCard
        classNames={{
          header: 'py-2 justify-content-between d-flex align-items-center',
        }}
        header={(
          <>
            <div>
              <span className="text-uppercase text-gray-1"><Intl id="reservationNumber" /></span>
              <span className="text-primary text-uppercase">
                {data.id ? ` #${data.id.slice( data.id.length - 6, data.id.length )}` : ''}
              </span>
            </div>
          </>
            )}
      >
        <Row>
          <Col md={4}>
            <p className="font-weight-500 m-0"><Intl id="service" /></p>
            <p className="text-gray-2 text-uppercase m-0">
              {data.service ? data.service.name : ''}
            </p>

            <p className="font-weight-500 m-0 mt-2"><Intl id="booking" /></p>
            <p className="text-gray-2">
              {data.booking ? ` #${data.booking.id.slice( data.booking.id.length - 6, data.booking.id.length )}` : ''}
            </p>

            <p className="font-weight-500 m-0 mt-2"><Intl id="customer" /></p>
            <p className="text-gray-2">
              {data.owner ? data.owner.name : ''}
            </p>
          </Col>
          <Col md={4}>
            <p className="font-weight-500 m-0"><Intl id="date" /></p>
            <p className="text-gray-2 m-0">
              {data.date ? `${moment.utc( data.date ).format( 'ddd DD MMM YYYY' )}` : ''}
            </p>

            <p className="font-weight-500 m-0 mt-2"><Intl id="bookedAt" /></p>
            <p className="text-gray-2 m-0">
              {data.date ? `${moment.utc( data.created ).format( 'ddd DD MMM YYYY' )}` : ''}
            </p>

            <p className="font-weight-500 m-0 mt-2"><Intl id="cost" /></p>
            <p className="text-gray-2">
              {data.cost ? numeral( formatFloat( data.cost ) ).format( currencyFormat( userCurrency ) ) : ''}
            </p>
          </Col>

          <Col md={4}>
            <p className="font-weight-500 m-0"><Intl id="status" /></p>
            <p className="text-gray-2 text-uppercase m-0">
              {data.status ? <Intl id={data.status} /> : ''}
            </p>
          </Col>
        </Row>
      </DetailCard>

      <DetailCard
        classNames={{ header: 'py-2', body: 'pt-4', card: 'mt-4' }}
        className="mt-4"
        header={(
          <Row className="align-items-center">
            <Col md={9}>
              <span className="text-uppercase text-gray-1"><Intl id="items" /></span>
            </Col>
            <Col md={3} className="text-right">
              <AddBtn title="newItem" onClick={onOpenEditItemModal} />
            </Col>
          </Row>
            )}
      >
        <LocalTable
          onlyTable
          manualPagination
          data={items}
          columns={[
            {
              Header: 'name',
              accessor: 'item.name',
              Cell: ( { cell } ) => (
                <TableEditBtn
                  title={cell.value}
                  onClick={() => onOpenEditItemModal( cell.row.original )}
                />
              ),
            },
            {
              Header: 'price',
              accessor: 'price',
              Cell: ( { cell } ) => ( cell.value ? numeral( formatFloat( cell.value ) ).format( currencyFormat( userCurrency ) ) : '' ),
            },
            {
              Header: 'quantity',
              accessor: 'quantity',
              Cell: ( { cell } ) => ( cell.value ? cell.value : '' ),
            },
            {
              Header: 'total',
              accessor: 'amount',
              Cell: ( { cell } ) => ( cell.value ? numeral( formatFloat( cell.value ) ).format( currencyFormat( userCurrency ) ) : '' ),
            },
            {
              Header: 'notes',
              accessor: 'notesCustomer',
              Cell: ( { cell } ) => ( cell.value ? cell.value : '' ),
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => {
                const status = _.find( bookedServiceItemStatus, { id: cell.value } );
                return status
                  ? <span className={status.textClass}><Intl id={status.name} /></span> : null;
              },
            },
            {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => (
                <TableActionsBtns
                  actions={[
                    {
                      isDelete: true,
                      onClick: ( cb ) => onDeleteItem( cell.value, cb ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </DetailCard>
    </Container>
  </>
) );
