import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const GET_SEASON_DISCOUNT = gql`
  query SeasonDiscount($input: DetailSeasonDiscountInput!) {
    seasonDiscount(input: $input) {
      ... on QuerySeasonDiscountSuccess {
        data {
          discountId
          discountType
          discountValue
          id
          isActive
          lastNight
          requirements {
            earlyBookingDiscountBefore {
              bookingsMadeBefore
              numberOfNights
              onlyBookings
            }
            earlyBookinhDiscountBeforeNumNight {
              bookingsMadeUntilDaysBefore
              numberOfNights
              onlyBookings
            }
            lastMinuteDiscount {
              bookingsMadeWithLessThan
              numberOfNights
              onlyBookings
            }
            longStayDiscount {
              onlyBookings
              numberOfNights
            }
            shortStaySuplement {
              numberOfNights
              onlyBookings
            }
          }
          startDate
          type
        }
      }
    }
  }
`;

const getSeasonDiscount = async ( seasonDiscountId ) => {
  const { data } = await client.query( {
    query: GET_SEASON_DISCOUNT,
    variables: {
      input: {
        seasonDiscountId,
      },
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.seasonDiscount.message ) {
    return {
      ok: false,
      message: data.seasonDiscount.message,
    };
  }
  const seasonDiscount = data.seasonDiscount.data;
  return {
    ok: true,
    status: 200,
    data: { data: seasonDiscount },
  };
};

export default getSeasonDiscount;
