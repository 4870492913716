import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  AsyncTable, AddBtn, SimpleHeader, TableGenericFilter, TableActionsBtns, Intl, TableSelectFilter,
} from 'components';

export default React.memo( ( {
  data, isLoading, defaultFilters, parentPath,
  onFetchData, onDelete, onFetchPoiCategories,
} ) => (
  <>
    <SimpleHeader title="pointsOfInterest" />
    <Container fluid>
      <AsyncTable
        id="PointsOfInterest"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        rightContent={<div className="mt-3"><AddBtn title="newPointOfInterest" route={`${parentPath}/new`} /></div>}
        onFetchData={onFetchData}
        customFilters={( { filters, setFilter } ) => (
          <Row className="align-items-end">
            <Col md={8} lg={4}>
              <TableGenericFilter
                filters={filters}
                setFilter={setFilter}
              />
            </Col>
            <Col md={6} lg={2} className="pl-0 pt-2">
              <TableSelectFilter
                name="category"
                label="category"
                placeholder="all"
                filters={filters}
                isClearable
                defaultOptions
                loadOptions={onFetchPoiCategories}
                setFilter={setFilter}
              />
            </Col>
            <Col md={6} lg={2} className="pl-0 pt-2">
              <TableSelectFilter
                name="enabled"
                label="enabled"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                setFilter={setFilter}
                options={[
                  {
                    name: 'yes',
                    id: 'true',
                  },
                  {
                    name: 'no',
                    id: 'false',
                  },
                ]}
              />
            </Col>
          </Row>
        )}
        columns={[
          {
            Header: 'name',
            accessor: 'name',
            Cell: ( { cell } ) => (
              <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                {cell.value}
              </Link>
            ),
          },
          {
            Header: 'address',
            accessor: 'address',
          },
          {
            Header: 'city',
            accessor: 'city',
            Cell: ( { cell } ) => ( cell.value.name ),
          },
          {
            Header: 'category',
            accessor: 'category',
            Cell: ( { cell } ) => ( cell.value.name ),
          },
          {
            Header: 'website',
            accessor: 'website',
            Cell: ( { cell } ) => ( cell.value ? <a href={cell.value} target="_blank" rel="noopener noreferrer"><i>{cell.value}</i></a> : '-' ),
          },
          {
            Header: 'enabled',
            accessor: 'enabled',
            Cell: ( { cell } ) => ( <Intl id={cell.value ? 'yes' : 'no'} /> ),
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
              />
            ),
          },
          /*
          {
            Header: 'name',
            accessor: 'name',
            Cell: ( { cell } ) => (
              <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                {cell.value}
              </Link>
            ),
          },
          {
            Header: 'subtitle',
            accessor: 'subtitle',
          },
          {
            Header: 'requiresTimeSlot',
            accessor: 'requiresTimeSlot',
            Cell: ( { cell } ) => (
              cell.value ? <Intl id="yes" /> : <Intl id="no" />
            ),
          },

          */
        ]}
      />
    </Container>
  </>
) );
