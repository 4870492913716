/* eslint-disable implicit-arrow-linebreak */
import React /* , { useEffect, useState } */ from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import {
  TextField,
  Select,
  ModalForm,
  Datepicker,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    entityId,
    isLoading,
    preloadedData,
    open,
    onClose,
    onSubmit,
    permitOptions,
    zIndex,
  } ) =>
    (
      <ModalForm
        title={entityId ? 'editPermit' : 'newPermit'}
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        size="xl"
        bodyClassName="overflow-visible"
        bodyStyle={{ minHeight: 330 }}
        onSubmit={onSubmit}
        onClose={onClose}
        initialValues={preloadedData}
        zIndex={zIndex}
        body={() => (
          <>
            <Row>
              <Col md={6}>
                <Datepicker
                  field="from"
                  label="from"
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  utc
                  labelClass="font-weight-bold"
                  validate={required}
                  startDateField={{ value: moment().subtract( '1', 'day' ) }}
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="till"
                  label="till"
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  utc
                  labelClass="font-weight-bold"
                  validate={required}
                  startDateField={{ value: moment().subtract( '1', 'day' ) }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Select
                  field="employeePermitsSettings"
                  label="category"
                  labelClass="font-weight-bold"
                  validate={required}
                  options={permitOptions}
                  translateOptions
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <TextField
                  field="description"
                  label="description"
                  type="textarea"
                  labelClass="font-weight-bold"
                  rows={3}
                  validate={required}
                />
              </Col>
            </Row>

          </>
        )}
      />
    )
  ,
);
