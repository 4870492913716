import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import PropertyManagerView from 'views/PropertyManager/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import PropertyManagerService from 'api/PropertyManager';
import PropertyManagerNew from '../New';

const PropertyManagerDashboard = ( { toggleErrorAlert } ) => {
  const [newModalOpened, setNewModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const toggleNewModal = useCallback( () => {
    setNewModalOpened( !newModalOpened );
  }, [newModalOpened] );

  const reloadData = useCallback( () => {
    toggleNewModal();
    setDataSubmitted( new Date().valueOf() );
  }, [toggleNewModal] );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: PropertyManagerService.getPropertyManagers,
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  return (
    <>
      <PropertyManagerView
        isLoading={dataLoading}
        data={data}
        onFetchData={fetchData}
        onOpenNewModal={toggleNewModal}
      />

      <PropertyManagerNew
        modalOpened={newModalOpened}
        onCloseModal={toggleNewModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( PropertyManagerDashboard );
