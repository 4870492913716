import React from 'react';
import moment from 'moment';

import {
  AsyncTable, CanAccess, TableActionsBtns, AddBtn, Intl,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  data, isLoading, onFetchData, onOpenEditModal,
} ) => {
  const canEdit = canModify( 'task' );

  return (
    <AsyncTable
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      disableSorting
      containerClassName="shadow-none"
      rightContent={<CanAccess I="edit" a="task"><AddBtn title="newRequest" onClick={onOpenEditModal} /></CanAccess>}
      onFetchData={onFetchData}
      columns={[
        {
          Header: 'date',
          accessor: 'date',
          Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
        },
        {
          Header: <span className="text-transform-none"><Intl id="customerComments" /></span>,
          accessor: 'customerComments',
        }, {
          Header: <span className="text-transform-none"><Intl id="comments" /></span>,
          accessor: 'comments',
        },
        {
          Header: 'status',
          accessor: 'status',
          Cell: ( { cell } ) => ( cell.value ? <Intl id={cell.value} /> : '' ),
        },
        canEdit
          ? {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns actions={[{
                onClick: () => onOpenEditModal( { id: cell.value } ),
                label: 'edit',
              }]}
              />
            ),
          } : {
            id: '_id', Header: null, maxWidth: 1, disableSorting: true,
          },
      ]}
    />
  );
} );
