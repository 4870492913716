import React from 'react';
import { Link } from 'react-router-dom';
import {
  LocalTable,
} from 'components';
import defaultAccommodation from 'assets/img/defaults/accommodation.png';

const AccomodationsClientEditView = ( { data, isLoading } ) => (
  <div className="pt-4">
    <LocalTable
      onlyTable
      manualPagination
      data={data}
      isLoading={isLoading}
      disableSorting
      columns={[
        {
          Header: ' ',
          accessor: 'mainImage',
          Cell: ( { cell } ) => (
            <Link to={`/pms/accommodations/edit/${cell.row.original.id}`}>
              {cell.value ? (
                <img
                  src={cell.value}
                  alt={cell.row.original.name}
                  width="120"
                />
              ) : (
                <img
                  src={defaultAccommodation}
                  alt={cell.row.original.name}
                  width="120"
                />
              )}
            </Link>
          ),
        },
        {
          Header: 'name',
          accessor: 'name',
          Cell: ( { cell } ) => (
            <Link to={`/pms/accommodations/edit/${cell.row.original.id}`}>{cell.value}</Link>
          ),
        },
        {
          Header: 'type',
          accessor: 'roomType.name',
        },
        {
          Header: 'accommodation.block',
          accessor: 'block',
        },
        {
          Header: 'floor',
          accessor: 'floor',
        },
        {
          Header: 'door',
          accessor: 'number',
        },
        {
          Header: 'bedrooms',
          accessor: 'rooms',
        },
        {
          Header: 'bathrooms',
          accessor: 'bathrooms',
        },
      ]}
    />
  </div>
);

export default AccomodationsClientEditView;
