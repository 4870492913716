import React, { useCallback } from 'react';
import { useFetchTableData } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import DiscountSeasonsView from 'views/PropertyManager/Edit/Discount/EditDrawer/Seasons/List';
import { connect } from 'react-redux';
import { getAllSeasonsDiscount } from 'api/Discounts/SeasonDiscount';
import SeasonEdit from './Edit';

const List = ( {
  open,
  discountId,
  seasonId,
  onOpenNewModal,
  onOpenEditItemModal,
  dataSubmitted,
  visible,
  onDeleteItem,
  onCloseModal,
  onReloadData,
  toggleErrorAlert,
} ) => {
  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getAllSeasonsDiscount( {
      discountId,
      ...params,
    } ),
    conditional: !!discountId,
    toggleErrorAlert,
    deps: [discountId, dataSubmitted],
  } );

  const reloadData = useCallback( () => {
    onReloadData();
    onCloseModal();
  }, [onReloadData, onCloseModal] );

  return (
    <>
      <DiscountSeasonsView
        visible={visible}
        data={data}
        onFetchData={fetchData}
        isDataLoading={dataLoading}
        onDeleteItem={onDeleteItem}
        onOpenNewModal={onOpenNewModal}
        onOpenEditItemModal={onOpenEditItemModal}
        onClose={onCloseModal}
      />
      <SeasonEdit
        discountId={discountId}
        seasonId={seasonId}
        open={open}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( List );
