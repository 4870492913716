import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { v4 as uuidv4 } from 'uuid';

const CREATE_RATE = gql`
  mutation CreateRate($input: CreateRateInput!) {
  createRate(input: $input) {
    ... on MutationCreateRateSuccess {
      data {
        success
        newRate {
          id
        }
      }
    }
    ... on BaseError {
      message
    }
  }
}
`;

const createRate = async ( input ) => {
  const id = uuidv4();
  const { data } = await client.query( {
    query: CREATE_RATE,
    variables: {
      input: { ...input, id },
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.createRate.message ) {
    return {
      ok: false,
      message: data.createRate.message,
    };
  }
  const createSuccessful = data.createRate.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
    data: { ...data.createRate.data.newRate },
  };
};

export default createRate;
