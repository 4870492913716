import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  AsyncTable,
  AddBtn,
  SimpleHeader, TableGenericFilter,
  CanAccess,
  TableActionsBtns,
  TableCellList,
  // Intl,
} from 'components';
import { canModify, canView } from 'config/Ability';

const AccomodationOwnerDashboard = React.memo(
  ( {
    data,
    parentPath,
    defaultFilters,
    onFetchData,
    onDelete,
    isLoading,
  } ) => {
    const canViewProps = canView( 'property_owner' );
    const canModifyProp = canModify( 'property_owner' );

    return (
      <>
        <SimpleHeader title="propertyOwners" />
        <Container fluid>
          <AsyncTable
            isLoading={isLoading}
            id="resources"
            data={data.data}
            total={data.elementsTotal}
            disableSorting
            defaultFilters={{
              ...defaultFilters,
            }}
            rightContent={(
              <CanAccess I="add" a="property_owner">
                <AddBtn title="newPropertyOwner" route={`${parentPath}/new`} />
              </CanAccess>
            )}
            onFetchData={onFetchData}
            customFilters={( { filters, setFilter } ) => (
              <Row>
                <Col md={8} lg={4}>
                  <TableGenericFilter filters={filters} setFilter={setFilter} />
                </Col>
              </Row>
            )}
            columns={[
              {
                Header: 'name',
                accessor: 'legalName',
                Cell: ( { cell } ) => ( canViewProps || canModifyProp ? (
                  <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                    {cell.row.original.legalName}
                  </Link>
                ) : (
                  <span>
                    {cell.row.original.legalName}
                  </span>
                ) ),
              },
              {
                Header: 'properties',
                accessor: 'accommodations',
                Cell: ( { cell } ) => <TableCellList list={cell.value} limit={6} />,

                // (
                //   <>
                //     {
                //       cell.row.original.accommodations
                //         .map( ( acc ) => <div key={acc.id}>{acc.name}</div> )
                //     }
                //   </>
                // ),
              },
              {
                Header: 'propertyOwnerUsers',
                accessor: 'customers',
                Cell: ( { cell } ) => (
                  <TableCellList
                    list={cell.value}
                    limit={6}
                    render={( customer ) => <div className="small" key={customer.id}>{`${customer.firstName} ${customer.lastName}`}</div>}
                  />
                ),
              },
              /*  {
                Header: 'identificationType',
                accessor: 'identificationType',
                Cell: ( { cell } ) => <Intl id={cell.value} />,
              },
              {
                Header: 'identificationNumber',
                accessor: 'identificationNumber',
              }, */
              {
                Header: 'address',
                accessor: 'address',
              },

              canModifyProp ? {
                accessor: 'id',
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        isDelete: true,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      },
                    ]}
                  />
                ),
              } : { accessor: 'id', Cell: ( ) => '-' },
            ]}
          />
        </Container>
      </>
    );
  },
);

export default AccomodationOwnerDashboard;
