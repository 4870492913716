/* eslint-disable import/prefer-default-export */
import TeamService from 'api/Team';
import moment from 'moment';

const loadTeams = async ( params ) => {
  const _params = { ...params };
  delete _params.date;
  const { data: loadedTeams } = await TeamService.getTeams( _params );

  const loadedTeamShifts = await Promise.all(
    loadedTeams.data.map( async ( item ) => {
      try {
        const { data: employeesOnShift } = await TeamService.getEmployees( {
          date: moment( params.date ).format( 'YYYY-MM-DD' ),
          team: item.id,
          page: 1,
          elementsPerPage: 20,
        } );
        return {
          ...item,
          members: employeesOnShift.data,
        };
      } catch ( _ ) {
        return item;
      }
    } ),
  );
  return {
    ...loadedTeams,
    data: loadedTeamShifts,
  };
};

export { loadTeams };
