/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
const parallel = async ( tasks, callback ) => {
  if ( typeof tasks !== 'object' || tasks === null ) {
    return callback( new Error( 'First argument must be an object with function values' ) );
  }
  if ( typeof tasks === 'object' ) {
    const keysTasks = Object.keys( tasks );
    const promises = Object.entries( tasks ).map( ( [nombre, funcion] ) => new Promise( ( resolve, reject ) => {
      try {
        const resultado = funcion();
        resolve( resultado );
      } catch ( error ) {
        reject( error );
      }
    } ) );
    Promise.all( promises )
      .then( ( results ) => {
        const finalResult = {};
        results.forEach( ( result, index ) => {
          finalResult[keysTasks[index]] = result;
        } );
        callback( null, finalResult );
      } )
      .catch( ( error ) => callback( error ) );
  } else {
    const promises = tasks.map( ( task ) => new Promise( ( resolve, reject ) => {
      try {
        const taskResult = task();
        resolve( taskResult );
      } catch ( error ) {
        reject( error );
      }
    } ) );
    Promise.all( promises )
      .then( ( results ) => callback( null, results ) )
      .catch( ( error ) => callback( error ) );
  }
};

export default parallel;
