import api from 'config/Api/Api';

const baseEndpoint = '/booked-services';

export default {
  getBookedServices( params ) {
    return api.get( baseEndpoint, params );
  },
  getBookedService( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  saveBookedService( data ) {
    return api.post( baseEndpoint, data );
  },

  updateBookedService( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  deleteBookedService( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
