import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/settings/customerApp';

export default {
  getCustomerApp( params ) {
    return api.get( baseEndpoint, params );
  },

  updateCustomerApp( data ) {
    return api.put( baseEndpoint, data );
  },

  updateCustomerAppImage( data ) {
    return api.post( `${baseEndpoint}/image`, formatFormDataBody( data ) );
  },

  getTranslation( id ) {
    return api.get( `/property-managers/translation/customerApp/${id}` );
  },

  getTranslationEventModule( id ) {
    return api.get( `/property-managers/translation/eventModule/${id}` );
  },
  getTranslationBasicModule( id ) {
    return api.get( `property-managers/translation/basicModule/${id}` );
  },
};
