import _ from 'lodash';
import useFetch from 'hooks/useFetch';
import PropertyService from 'api/Property';
import AccommodationService from 'api/Accommodation';
import { useState, useCallback, useEffect } from 'react';

// UTILS
const getUniqueId = () => new Date().valueOf();

const getIndexByIdKey = ( list, idKey ) => list.findIndex( ( object ) => object.idKey === idKey );

const getChanges = ( list, properties, accommodations ) => {
  const cities = [];
  let zones = [];

  list.forEach( ( row ) => {
    if ( row.city ) {
      cities.push( row.city );
      zones = zones.concat( row.zones );
    }
  } );

  return {
    cities,
    zones,
    properties,
    accommodations,
  };
};

/// ////////////////////////////////////

const useLimitByLocation = ( onChange, dataTask ) => {
  const [list, setList] = useState( [] );

  const [zonesSelected, setZonesSelected] = useState( [] );
  const [propertiesKeyword, setPropertiesKeyword] = useState( '' );
  const [selectedProperties, setSelectedProperties] = useState( [] );
  const [accommodationsKeyword, setAccommodationsKeyword] = useState( '' );
  const [selectedAccommodations, setSelectedAccommodations] = useState( [] );

  /* PROPERTIES */
  const [properties, isLoadingProperties] = useFetch( {
    initialState: [],
    promise: () => PropertyService.getProperties( {
      elementsPerPage: 15,
      zone: zonesSelected[zonesSelected.length - 1],
      keyword: propertiesKeyword || null,
    } ),
    format: ( dataToFormat ) => _.get( dataToFormat, 'data', [] ),
    conditional: zonesSelected.length > 0,
    deps: [zonesSelected, propertiesKeyword],
    // toggleErrorAlert,
  } );
  /* END PROPERTIES */

  /* ACCOMMODATIONS */
  const [accommodations, isLoadingAccommodations] = useFetch( {
    initialState: [],
    promise: () => AccommodationService.getAllAccommodationsAll( {
      elementsPerPage: 50,
      zone: zonesSelected[zonesSelected.length - 1],
      keyword: accommodationsKeyword || null,
    } ),
    format: ( dataToFormat ) => _.get( dataToFormat, 'data', [] ),
    conditional: zonesSelected.length > 0,
    deps: [zonesSelected, accommodationsKeyword],
    // toggleErrorAlert,
  } );

  /* END ACCOMMODATIONS */

  const addRow = useCallback( () => {
    const newList = [...list];
    newList.push( {
      idKey: `${getUniqueId()}${newList.length}`,
      city: '',
      zones: [],
    } );
    setList( newList );
  }, [list] );

  const onChangeRow = useCallback(
    ( idKey, prop, objData ) => {
      const newList = [...list];
      const index = getIndexByIdKey( newList, idKey );

      if ( prop === 'city' ) {
        newList[index].city = objData?.value;
      }
      if ( prop === 'zones' ) {
        newList[index].zones = objData;
      }
      setList( newList );
      onChange( getChanges( newList, selectedProperties, selectedAccommodations ) );
    },
    [list, selectedProperties, selectedAccommodations, onChange],
  );

  const deleteRow = useCallback(
    ( idKey ) => {
      const newList = [...list];
      const index = getIndexByIdKey( newList, idKey );
      newList.splice( index, 1 );
      setList( newList );
      onChange( getChanges( newList, selectedProperties, selectedAccommodations ) );
    },
    [list, selectedProperties, selectedAccommodations, onChange],
  );

  const onChangeProperties = useCallback(
    ( newSelectedProperties ) => {
      setSelectedProperties( newSelectedProperties );
      onChange( getChanges( list, newSelectedProperties, selectedAccommodations ) );
    },
    [list, selectedAccommodations, onChange],
  );

  const onChangeAccommodations = useCallback(
    ( newSelectedAccommodations ) => {
      setSelectedAccommodations( newSelectedAccommodations );
      onChange( getChanges( list, selectedProperties, newSelectedAccommodations ) );
    },
    [list, selectedProperties, onChange],
  );

  /* ROWS BI DATA TASK */
  useEffect( () => {
    if ( dataTask && dataTask.zones && dataTask.zones.length ) {
      const citiesPool = {};
      dataTask.zones.forEach( ( zone ) => {
        const zoneId = zone.id;
        const cityId = zone?.propertyManagerCity?.id || null;
        const cityLabel = zone?.propertyManagerCity?.city?.name || null;

        if ( cityId ) {
          if ( !citiesPool[cityId] ) {
            citiesPool[cityId] = {
              cityLabel,
              zones: [],
            };
          }
          citiesPool[cityId].zones.push( zoneId );
        }
      } );
      const newList = Object.entries( citiesPool ).map( ( [cityId, { cityLabel, zones }], k ) => ( {
        idKey: `${getUniqueId()}${k}`,
        city: cityId,
        zones,
        originalData: {
          city: { id: cityId, label: cityLabel },
          zones,
        },
      } ) );
      setList( newList );
    }
  }, [dataTask] );

  return {
    list,
    addRow,
    onChangeRow,
    deleteRow,
    onFetchProperties: () => {},
    isLoadingProperties,
    setZonesSelected,
    properties,
    onChangeProperties,
    setPropertiesKeyword,
    isLoadingAccommodations,
    accommodations,
    onChangeAccommodations,
    setAccommodationsKeyword,
  };
};

export default useLimitByLocation;
