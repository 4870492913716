/* eslint-disable import/prefer-default-export */
import { addAccommodationsRate, deleteAccomodations } from 'api/Rates/Accomodations';

const updateAccomodationsRate = async ( oldIds, dataToSend ) => {
  if ( oldIds.length ) {
    const { ok: okDelete, message: error } = await deleteAccomodations( oldIds );
    if ( !okDelete ) {
      throw new Error( error );
    }
  }
  const { ok, message } = await addAccommodationsRate( dataToSend );
  if ( !ok ) {
    throw new Error( message );
  }
  return {
    ok, message,
  };
};

export { updateAccomodationsRate };
