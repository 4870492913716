import React, { useContext } from 'react';
import { BusinnesCRMContext } from 'containers/CRM/Business/Dashboard/context';
import classnames from 'classnames';
import BusinnessFilters from './Filters';
import ViewKanbanBusiness from './Kanban';
import ViewListBusiness from './Table';

const BusinessAllView = ( { data, isLoading, onDeleteBusiness } ) => {
  const {
    selectedView,
  } = useContext( BusinnesCRMContext );

  let content = null;

  switch ( selectedView ) {
    case 2:
      content = <ViewKanbanBusiness data={data} onDeleteBusiness={onDeleteBusiness} />;
      break;
    default:
      //
      content = <ViewListBusiness data={data} onDeleteBusiness={onDeleteBusiness} />;
  }

  return (
    <div className={classnames( 'position-relative', { 'app-component-loading': isLoading } )}>
      <BusinnessFilters />
      {content}
    </div>
  );
};

export default BusinessAllView;
