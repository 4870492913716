import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form,
  FormActionsBtns,
  Select,
  TextField,
  Intl,
} from 'components';
import { required } from 'config/InputErrors';

const EditRegionView = ( {
  elementToEdit, countries, countriesLoading, submitForm, onClose,
} ) => (
  <>
    <h3>
      <Intl id={elementToEdit ? 'editRegion' : 'newRegion'} />
      {' '}
      {elementToEdit?.id || ''}
    </h3>
    <hr className="mt-0" />
    <Form
      onSubmit={submitForm}
      showErrorsInFooter
      initialValues={elementToEdit}
      body={() => (
        <>
          <Row className="mb-4">
            <Col md={7}>
              <TextField
                field="name"
                label="name"
                type="text"
                validate={required}
              />
            </Col>

          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <TextField
                field="description"
                label="description"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <Select
                field="countries"
                label="countries"
                options={countries}
                isLoading={countriesLoading}
                validate={required}
                multiple
              />
            </Col>

          </Row>

        </>
      )}
      footer={<FormActionsBtns onClickCancel={onClose} />}
    />
  </>
);

export default EditRegionView;
