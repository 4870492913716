import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Card, CardBody, CardHeader, TabContent, TabPane, Row, Col, Spinner,
  Nav, NavItem, NavLink, CardFooter, Button,
} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import TimeAgo from 'react-timeago';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  TextField, Separator, Intl, CanAccess,
} from 'components';
import { timeagoFormatter, getInitials as getResourceInitials, storage } from 'utils';
import { animateScroll } from 'react-scroll';
import formatBookinglId from 'utils/bookingId';
import { can } from 'config/Ability';

const initialTab = ( haveCustomerApp ) => {
  if ( haveCustomerApp && can( 'customers', 'chat' ) ) {
    return '1';
  }
  if ( can( 'operations', 'chat' ) ) {
    return '2';
  }
  return '0';
};

const ChannelsList = React.memo( ( {
  channels = [], hasMoreChannels, userId, operationChannels, hasMoreOperationChannels,
  selectedChannelId, onFetchBookingChannels, onFetchOperationChannels, onChangeChannel,
  onFilterData, onOpenCreateChannelModal, userBookingReference,
} ) => {
  const [filter, setFilter] = useState();
  const appStorage = JSON.parse( storage.get( 'persist:nomads' ) );
  const userStorage = appStorage && JSON.parse( appStorage.user );
  const haveCustomerApp = userStorage && userStorage.settings
    ? userStorage.settings.haveCustomerApp
    : true;

  const [activeTab, setActiveTab] = useState( initialTab( haveCustomerApp ) );

  const getInitials = ( channel ) => {
    if ( _.get( channel, 'booking.owner.firstName' ) ) {
      let text = channel.booking.owner.firstName[0];
      if ( channel.booking.owner.lastName ) text += channel.booking.owner.lastName[0];
      return text;
    }
    return '';
  };

  const getSingleParticipant = ( channel ) => {
    const participant = _.find( channel.participants, ( item ) => item.id !== userId );
    if ( participant ) {
      return participant.profilePicture
        ? <img src={participant.profilePicture} alt="..." /> : getResourceInitials( participant );
    }
    return '';
  };

  const getShortId = ( channel ) => {
    if ( _.get( channel, 'booking.id' ) ) {
      return formatBookinglId( { ...channel.booking, userBookingReference } );
    }
    return channel.name ? `#${channel.name.slice( channel.name.length - 6, channel.name.length )}` : '';
  };

  const selectCustomersTab = useCallback( () => {
    if ( activeTab !== '1' ) {
      if ( channels.length ) onChangeChannel( channels[0]._id );
      setActiveTab( '1' );
    }
  }, [channels, activeTab, onChangeChannel] );

  const selectOperationsTab = useCallback( () => {
    if ( activeTab !== '2' ) {
      if ( operationChannels.length ) onChangeChannel( operationChannels[0]._id );
      setActiveTab( '2' );
    }
  }, [operationChannels, activeTab, onChangeChannel] );

  const filterData = ( filterValue ) => {
    // if ( e.key === 'Enter' ) {
    animateScroll.scrollToTop( {
      containerId: 'channelsContainer',
      duration: 0,
    } );
    setTimeout( () => {
      setActiveTab( initialTab( haveCustomerApp ) );
      onFilterData( filterValue );
    }, 50 );
    // }
  };

  const debounceFilter = useCallback(
    _.debounce(
      filterData,
      500,
    ), [],
  );

  const handleChange = ( v ) => {
    setFilter( v );
    debounceFilter( v );
  };

  return (
    <Card className="chat-container chat-channels">
      <CardHeader className="p-0">
        <TextField
          value={filter}
          onChange={handleChange}
          name="chat-filter"
          size="lg"
          placeholder="filterChats"
          formGroupClass="m-0 chat-channels-filter"
          appendIconClass="fa-search"
          // onKeyDown={debounceFilter}
        />
        { haveCustomerApp && (
        <Nav justified className="chat-channels-tabs">
          <CanAccess I="customers" a="chat">
            <NavItem>
              <NavLink
                className={classnames( { 'text-darker': activeTab === '1' } )}
                onClick={selectCustomersTab}
              >
                <Intl id="customers" />
              </NavLink>
            </NavItem>
          </CanAccess>
          <CanAccess I="operations" a="chat">
            <NavItem>
              <NavLink
                className={classnames( { 'text-darker': activeTab === '2' } )}
                onClick={selectOperationsTab}
              >
                <Intl id="operations" />
              </NavLink>
            </NavItem>
          </CanAccess>
        </Nav>
        )}
      </CardHeader>

      <CardBody
        className="p-0 h-100 overflow-auto"
      >
        <TabContent activeTab={activeTab} className="h-100">
          { haveCustomerApp && (
          <TabPane tabId="1" className="h-100 overflow-auto" id="channelsContainer">
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              loadMore={( page ) => onFetchBookingChannels( { page } )}
              hasMore={hasMoreChannels}
              loader={<div key="loading" className="text-center py-2"><Spinner color="primary" size="sm" /></div>}
              useWindow={false}
            >
              {_.map( channels, ( item ) => (
                <div
                  className={classnames( 'chat-channels-item cursor-pointer', {
                    'chat-channels-item-border-blue': item.type === 'blue',
                    'chat-channels-item-border-red': item.type === 'red',
                    'chat-channels-item-active': item._id === selectedChannelId,
                  } )}
                  onClick={() => onChangeChannel( item._id )}
                  key={item._id}
                >
                  <div className="chat-channels-item-content">
                    <Row className="align-items-center">
                      {item.lastUpdate
                        ? (
                          <Col xs={12} className="text-md-right">
                            <span className="text-darker text-xsm">
                              <TimeAgo
                                date={moment.utc( item.lastUpdate ).local()}
                                formatter={timeagoFormatter}
                              />
                            </span>
                          </Col>
                        ) : null}
                    </Row>

                    <Row className="align-items-center">
                      <Col sm="auto" className="pr-0 text-center text-sm-left">
                        <div className="avatar rounded-circle">
                          {getInitials( item )}
                        </div>
                      </Col>
                      <Col xs={9} sm className="p-1">
                        <p className="text-darker text-sm font-weight-500 m-0 text-uppercase">
                          {getShortId( item )}
                          {' '}
                        </p>
                        {item.booking && item.booking.owner
                          ? (
                            <p className="text-2xsm font-weight-500 m-0 text-uppercase">
                              {item.booking.owner.firstName}
                              {' '}
                              {item.booking.owner.lastName}
                            </p>
                          ) : ''}
                      </Col>
                      <Col xs="auto" className="text-right">
                        {( item.booking && moment.utc( item.booking.till ).diff( moment.utc().startOf( 'day' ) ) < 0 )
                          ? <Separator leftIsActive rightIsActive /> : null}
                        {( item.booking && moment.utc( item.booking.from ).diff( moment.utc().startOf( 'day' ) ) > 0 )
                          ? <Separator leftIsActive={false} rightIsActive={false} /> : null}
                        {( item.booking && moment.utc( item.booking.from ).diff( moment.utc().startOf( 'day' ) ) <= 0
                          && moment.utc( item.booking.till ).diff( moment.utc().startOf( 'day' ) ) >= 0 )
                          ? <Separator leftIsActive rightIsActive={false} /> : null}
                      </Col>
                    </Row>
                  </div>
                </div>
              ) )}
            </InfiniteScroll>
          </TabPane>
          )}

          <TabPane tabId="2" className="h-100 overflow-auto">
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              loadMore={( page ) => onFetchOperationChannels( { page } )}
              hasMore={hasMoreOperationChannels}
              loader={<div key="loading" className="text-center py-2"><Spinner color="primary" size="sm" /></div>}
              useWindow={false}
            >
              {_.map( operationChannels, ( item ) => (
                <div
                  className={classnames( 'cursor-pointer', {
                    'chat-channels-item-active': item._id === selectedChannelId,
                  } )}
                  onClick={() => onChangeChannel( item._id )}
                  key={item._id}
                >
                  <div className="chat-channels-item-content">
                    <Row className="align-items-center">
                      <Col sm="auto" className="pr-0 text-center text-sm-left">
                        <div className="avatar rounded-circle">
                          {!item.participants || item.participants.length > 2
                            ? <i className="app-icon-multiple-users" /> : getSingleParticipant( item )}
                        </div>
                      </Col>
                      <Col xs={9} sm className="p-1">
                        <p className="text-darker text-sm font-weight-500 m-0 text-uppercase">
                          {item.name}
                        </p>
                        {item.lastUpdate
                          ? (
                            <p className="text-darker text-xsm m-0">
                              <TimeAgo
                                date={moment.utc( item.lastUpdate ).local()}
                                formatter={timeagoFormatter}
                              />
                            </p>
                          ) : null}
                      </Col>
                    </Row>
                  </div>
                </div>
              ) )}
            </InfiniteScroll>
          </TabPane>
        </TabContent>
      </CardBody>

      {activeTab === '2' && (
      <CanAccess I="manage" a="chat">
        <CardFooter className="chat-create-channel">
          <Button outline onClick={onOpenCreateChannelModal}>
            <span className="ml-2"><Intl id="createChannel" /></span>
          </Button>
        </CardFooter>
      </CanAccess>
      )}
    </Card>
  );
} );

const mapStateToProps = ( { user } ) => ( {
  userId: _.get( user, 'user.id' ),
} );

export default connect( mapStateToProps, null )( ChannelsList );
