import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form,
  FormActionsBtns,
  Select,
  TextField,
  Intl,
  Switch,
} from 'components';
import { required } from 'config/InputErrors';
import { periodicities, types, billingDates } from 'config/Constants/Fees';

const EditFeeView = ( {
  elementToEdit, submitForm, onClose, enabled, setEnabled,
} ) => {
  const [periodicity, setPeriodicity] = useState( elementToEdit?.periodicity || null );

  return (
    <>
      <h3>
        <Intl id={elementToEdit ? 'editFee' : 'newFee'} />
        {' '}
        {elementToEdit?.id || ''}
      </h3>
      <hr className="mt-0" />
      <Form
        onSubmit={submitForm}
        showErrorsInFooter
        initialValues={elementToEdit}
        body={() => (
          <>
            <Row className="mb-4">
              <Col md={8}>
                <TextField
                  field="name"
                  label="name"
                  type="text"
                  validate={required}
                />
              </Col>
              <Col md={3}>
                <TextField
                  field="cost"
                  label="cost"
                  type="number"
                  validate={required}
                />

              </Col>
              <Col md={12}>
                <TextField
                  field="description"
                  label="description"
                  type="text"
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={4}>
                <Select
                  field="type"
                  label="type"
                  options={types}
                  validate={required}
                  translateOptions
                />
              </Col>
              <Col md={4}>
                <Select
                  field="periodicity"
                  label="periodicity"
                  options={periodicities}
                  validate={required}
                  translateOptions
                  onChange={setPeriodicity}
                />
              </Col>
              {periodicity ? (
                <Col md={4}>
                  <Select
                    field="billingDate"
                    label="fees.billingDate"
                    options={billingDates[periodicity]}
                    validate={required}
                    translateOptions
                  />
                </Col>
              ) : null}
            </Row>
            <Row>

              <Col md={6}>
                <TextField
                  field="delayAllowedToPayInDays"
                  label="fees.delayAllowedToPayInDays"
                  type="number"
                  validate={required}
                />
              </Col>
              <Col md={6}>
                <Switch
                  label="enabled"
                  value={enabled}
                  onChange={( v ) => {
                    setEnabled( v.target.checked );
                  }}
                />
              </Col>

            </Row>

          </>
        )}
        footer={<FormActionsBtns onClickCancel={onClose} />}
      />
    </>
  );
};

export default EditFeeView;
