import React, { useState, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
// import { handlePromise } from 'utils';
import { getEmails, deleteEmail } from 'api/CRM/Contact';
import ContactEmailView from 'views/CRM/Contact/Edit/Email';
import { ContactEditContext } from '../context';

const ContactEmail = ( {
  toggleLoading, toggleErrorAlert,
} ) => {
  const {
    contactId,
  } = useContext( ContactEditContext );

  const [emailToRead, setEmailToRead] = useState( null );

  const [emailReaderModalOpened, setEmailReaderModalOpened] = useState( false );

  const [emailEditModalOpened, setEmailEditModalOpened] = useState( false );

  const [
    onReloadEmails,
    setOnReloadEmails,
  ] = useState( new Date().valueOf() );

  const reloadEmails = useCallback( () => {
    setEmailToRead( null );
    setOnReloadEmails( new Date().valueOf() );
  }, [] );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => {
      const searchText = params.keyword ? { subject: params.keyword } : {};
      return getEmails( { ...params, ...searchText, contactId } );
    },
    toggleErrorAlert,
    conditional: !!contactId,
    deps: [contactId, onReloadEmails],
    // deps: [onReloadContacts],
  } );

  const onDeleteEmail = useDelete( {
    promise: deleteEmail,
    callback: () => {
      reloadEmails();
    },
    toggleLoading,
    toggleErrorAlert,
  } );
  //

  const toggleEmailEditModal = useCallback( ( ) => {
    setEmailEditModalOpened( ( v ) => !v );
  }, [] );

  const toggleEmailReaderModal = useCallback( ( ) => {
    setEmailReaderModalOpened( ( v ) => !v );
  }, [] );
  const onReadEmail = useCallback( ( dataEmail ) => {
    setEmailToRead( dataEmail );
    toggleEmailReaderModal();
  }, [toggleEmailReaderModal] );

  return (
    <ContactEmailView
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      onDeleteEmail={onDeleteEmail}
      emailReaderModalOpened={emailReaderModalOpened}
      toggleEmailReaderModal={toggleEmailReaderModal}
      emailToRead={emailToRead}
      onReadEmail={onReadEmail}
      reloadEmails={reloadEmails}
      emailEditModalOpened={emailEditModalOpened}
      toggleEmailEditModal={toggleEmailEditModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( ContactEmail );
