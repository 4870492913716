import React, { useContext } from 'react';
// import classnames from 'classnames';
import { Container, Card } from 'reactstrap';
import {
  SimpleHeader, HorizontalMenu,
} from 'components';
import classnames from 'classnames';
import General from 'containers/CRM/Contact/Edit/General';
import Activity from 'containers/CRM/Contact/Edit/Activity';
import Business from 'containers/CRM/Contact/Edit/Business';
import Tasks from 'containers/CRM/Contact/Edit/Tasks';
import Email from 'containers/CRM/Contact/Edit/Email';
import Notes from 'containers/CRM/Contact/Edit/Notes';
import Calls from 'containers/CRM/Contact/Edit/Calls';
import { ContactEditContext } from 'containers/CRM/Contact/Edit/context';

const ContactEditor = ( ) => {
  const {
    path, tabsList, selectedTab, setSelectedTab,
    loading,
  } = useContext( ContactEditContext );

  let content = null;

  switch ( selectedTab ) {
    case 'crm.contactInfo.general':
      content = <General />;
      break;
    case 'crm.contactInfo.activity':
      content = <Activity />;
      break;
    case 'crm.contactInfo.business':
      content = <Business />;
      break;
    case 'crm.contactInfo.tasks':
      content = <Tasks />;
      break;
    case 'crm.contactInfo.mail':
      content = <Email />;
      break;
    case 'crm.contactInfo.notes':
      content = <Notes />;
      break;
    case 'crm.contactInfo.calls':
      content = <Calls />;
      break;
    default:
    //
  }

  return (
    <>
      <SimpleHeader
        title="crm.contactInfo"
        backLink={{ name: 'crm.contacts', href: path }}
        /* className={classnames( {
          'app-component-loading': isLoading,
        } )} */
      />
      <Container
        fluid

      >
        <Card className={classnames( 'card-sec-top border-bottom', {
          'app-component-loading': loading,
        } )}
        >
          <HorizontalMenu
            tabs={tabsList}
            selected={selectedTab}
            onChangeTab={( index ) => setSelectedTab( index )}
          />
          <div className="border-top">{content}</div>
        </Card>

      </Container>
    </>
  );
};

export default ContactEditor;
