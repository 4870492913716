import React from 'react';
import {
  Row, Col, Badge,
  Button,
  // Badge
} from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable,
  DaterangePickerBtn,
  TableActionsBtns,
  TableGenericFilter,
  TableSelectFilter,
  TableToggler,
  Intl,
} from 'components';
import ContactEmailStatus from 'config/Constants/ContactEmailStatus';
import EmailReader from 'containers/CRM/Contact/Edit/Email/Reader';
import EmailEditor from 'containers/CRM/Contact/Edit/Email/New';

const ContactEmailView = ( {
  data,
  isLoading,
  onFetchData,
  onDeleteEmail,
  emailReaderModalOpened,
  toggleEmailReaderModal,
  emailToRead,
  onReadEmail,
  reloadEmails,
  emailEditModalOpened,
  toggleEmailEditModal,
} ) => (
  <>
    <AsyncTable
      id="ContactBusiness"
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      onFetchData={onFetchData}
      rightContent={<Button color="primary" onClick={toggleEmailEditModal}><Intl id="crm.emails.new" /></Button>}
      customFilters={( { filters, setFilter } ) => (
        <Row>
          <Col md={8} lg={4}>
            <TableGenericFilter filters={filters} setFilter={setFilter} />
          </Col>
          <TableToggler />
        </Row>
      )}
      customToggleFilters={( { filters, setFilter, setMultipleFilters } ) => (
        <Row className="ml-0">
          <Col md={5} lg={2} className="pl-0 py-2">
            <TableSelectFilter
              name="status"
              label="status"
              placeholder="all"
              filters={filters}
              isClearable
              options={ContactEmailStatus}
              translateOptions
              // defaultOptions
              // loadOptions={onFetchProperties}
              setFilter={setFilter}
            />
          </Col>
          <Col md={6} lg={4} className="pl-0 py-2">
            <DaterangePickerBtn
              position="left"
              label="crm.businnesStatuses.dateBetween"
              initialValue={moment.range( filters.from || moment().subtract( 30, 'days' ), filters.till || moment()/* .add( 7, 'days' ) */ )}
              key={filters.from + filters.till}
              input
              onChange={( values ) => {
                setMultipleFilters( {
                  from: moment( values.start ).format( 'YYYY-MM-DD' ),
                  till: moment( values.end ).format( 'YYYY-MM-DD' ),
                } );
              }}
            />
          </Col>

        </Row>
      )}
      columns={[

        {
          Header: 'crm.emails.subject',
          accessor: 'subject',
          Cell: ( { cell } ) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href="#"
              onClick={( e ) => {
                e.preventDefault();
                onReadEmail( cell.row.original );
              }}
            >
              {cell.value}
            </a>
          ),
        },
        {
          Header: 'crm.emails.sentDate',
          accessor: 'createdAt',
          Cell: ( { cell } ) => ( cell.value

            ? moment( cell.value ).format( 'YYYY-MM-DD' )
            : '-' ),
        },
        {
          Header: 'status',
          accessor: 'status',
          Cell: ( { cell } ) => (
            <Badge color={cell.value === 'sent' ? 'success' : 'danger'} pill>
              <Intl id={`crm.emailStatus.${cell.value}`} />
            </Badge>
          ),
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  isDelete: true,
                  onClick: () => {
                    onDeleteEmail( cell.row.original.id );
                  },
                },
              ]}
            />
          ),
        },
      ]}
    />
    <EmailReader
      open={emailReaderModalOpened}
      onClose={toggleEmailReaderModal}
      dataEmail={emailToRead}
      toggleEmailEditModal={toggleEmailEditModal}
    />
    <EmailEditor
      open={emailEditModalOpened}
      onClose={toggleEmailEditModal}
      onReloadData={reloadEmails}
    />
  </>
);

export default ContactEmailView;
