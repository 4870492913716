import api from 'config/Api/Api';

const baseEndpoint = ( checklistId ) => `/checklist/${checklistId}/item`;

export default {
  getChecklistItems( checklistId, params ) {
    return api.get( baseEndpoint( checklistId ), params );
  },

  getChecklistItem( checklistId, id ) {
    return api.get( `${baseEndpoint( checklistId )}/${id}` );
  },

  saveChecklistItem( checklistId, data ) {
    return api.post( `${baseEndpoint( checklistId )}`, data );
  },

  updateChecklistItem( checklistId, id, data ) {
    return api.put( `${baseEndpoint( checklistId )}/${id}`, data );
  },

  deleteChecklistItem( checklistId, id ) {
    return api.delete( `${baseEndpoint( checklistId )}/${id}` );
  },
};
