import api from 'config/Api/Api';

const baseEndpoint = '/task-periodic';

export default {
  getTasks( params ) {
    return api.get( baseEndpoint, params );
  },

  getTask( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveTask( data ) {
    return api.post( baseEndpoint, data );
  },

  updateTask( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  assignTask( id, resourceId ) {
    return api.post( `${baseEndpoint}/${id}/assign/${resourceId}`, {} );
  },

  deleteTask( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
