import React from 'react';
import {
  Row, Col, Button,
} from 'reactstrap';
import _ from 'lodash';
import {
  TextField,
  Select,
  ModalForm,
  Datepicker,
  Intl,
  RichTextField,
} from 'components';
import { required } from 'config/InputErrors';

const EditNotification = ( {
  data,
  onSubmit,
  onClose,
  notificationId,
  open,
  templates,
  isLoadingTemplates,
  onPreview,
  onChangeTemplete,
  translations,
  tranlationsLoading,
} ) => {
  const setValForm = ( [field, value], state, { changeValue } ) => {
    changeValue( state, field, () => value );
  };

  const tnsOptions = _.keys( translations || {} ).map( ( tns ) => ( {
    label: tns,
    id: tns,
  } ) );

  return (
    <ModalForm
      customTitle={notificationId ? (
        <>
          <Intl id="editNotification" />
          {' '}
          {notificationId}
        </>
      ) : ( <Intl id="newNotification" /> )}
      showErrorsInFooter
      open={open}
      size="xl"
      bodyClassName="overflow-visible"
      bodyStyle={{ minHeight: 200 }}
      onSubmit={onSubmit}
      onClose={onClose}
      mutators={{ setValForm }}
      initialValues={{
        ...data,
        communicationTemplate: data.communicationTemplate
        && data.communicationTemplate.id
          ? data.communicationTemplate.id : undefined,
      }}
      body={( form ) => {
        const templateId = _.get( form.getState(), 'values.communicationTemplate', '' );
        const template = templates.filter( ( t ) => t.id === templateId )[0] || null;
        const type = template && template.type ? template.type : null;

        const isEmailType = type === 'email' || type === 'email_push';
        const isPushType = type === 'push' || type === 'email_push';

        return (
          <>
            <Row className="">
              {!notificationId && (
              <Col md={4}>
                <Select
                  field="communicationTemplate"
                  label="template"
                  labelKey="title"
                  labelClass="font-weight-bold"
                  options={templates}
                  isLoading={isLoadingTemplates}
                  validate={required}
                  onChange={( tplId ) => {
                    onChangeTemplete( tplId );
                    const tpl = templates.filter( ( t ) => t.id === tplId )[0] || null;
                    if ( tpl && tpl.bodyEmail ) {
                      form.mutators.setValForm( 'bodyEmail', tpl.bodyEmail );
                    }
                    if ( tpl && tpl.bodyPush ) {
                      form.mutators.setValForm( 'bodyPush', tpl.bodyPush );
                    }
                  }}
                />
              </Col>
              )}
              { !notificationId && tnsOptions.length > 0 && !tranlationsLoading && (
              <Col md={4}>
                <Select
                  label="translations"
                  labelKey="label"
                  labelClass="font-weight-bold"
                  options={tnsOptions}
                  isLoading={tranlationsLoading}
                  onChange={( tns ) => {
                    if ( translations[tns].bodyEmail ) {
                      form.mutators.setValForm( 'bodyEmail', translations[tns].bodyEmail );
                    }
                    if ( translations[tns].bodyPush ) {
                      form.mutators.setValForm( 'bodyPush', translations[tns].bodyPush );
                    }
                  }}
                />
              </Col>
              )}
              { isEmailType && (
                <Col md={12} className="text-right mt-4">
                  <Button
                    title="emailPreview"
                    color="primary"
                    size="sm"
                    onClick={() => onPreview( form.getState() )}
                  >
                    <Intl id="emailPreview" />
                  </Button>
                </Col>
              )}
            </Row>
            {isEmailType && (
              <Row className="mt-3">
                <Col>
                  <RichTextField
                    field="bodyEmail"
                    height="400"
                    validate={required}
                  />
                </Col>
              </Row>
            )}
            <Row>
              {isPushType && (
              <Col md={6}>
                <TextField
                  field="bodyPush"
                  label="pushNotification"
                  labelClass="font-weight-bold"
                  validate={required}
                />
              </Col>
              )}
              {!notificationId && ( isEmailType || isPushType ) ? (
                <Col md={6}>
                  <Datepicker
                    field="executeAfter"
                    label="date"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    utc
                  />
                </Col>
              ) : null}
            </Row>
          </>
        );
      }}
    />
  );
};
export default EditNotification;
