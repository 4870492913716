import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { v4 as uuidv4 } from 'uuid';

const ADD_SEASON_DISCOUNT = gql`
  mutation AddSeasonDiscount($input: CreateSeasonDiscountInput!) {
    addSeasonDiscount(input: $input) {
      ... on BaseError {
        message
      }
      ... on MutationAddSeasonDiscountSuccess {
        data {
          success
        }
      }
    }
  }
`;

const createSeasonDiscount = async ( input ) => {
  const { data } = await client.query( {
    query: ADD_SEASON_DISCOUNT,
    variables: {
      input: {
        ...input,
        seasons: input.seasons.map( ( item ) => ( {
          ...item,
          id: uuidv4(),
        } ) ),
      },
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.addSeasonDiscount.message ) {
    return {
      ok: false,
      message: data.addSeasonDiscount.message,
    };
  }
  const createSuccessful = data.addSeasonDiscount.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
  };
};

export default createSeasonDiscount;
