import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import NotificationsDashboardView from 'views/Notifications/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import { useFetchTableData } from 'hooks';
import { handlePromise } from 'utils';
import NotificationService from 'api/NotificationCenter';
import { history } from 'store';

const NotificationsDashboard = ( {
  taskReloadedAt,
  toggleErrorAlert,
  toggleLoading,
  updateFilters,
  setFiltersError,
  searchParams,
} ) => {
  const urlSearchParams = new URLSearchParams( searchParams );

  const filterToDate = urlSearchParams.get( 'till' ) || moment().startOf( 'day' );
  const filterFromDate = urlSearchParams.get( 'from' ) || moment( filterToDate ).clone().subtract( '1', 'week' );

  const dateRange = useRef( moment.range( filterFromDate, filterToDate ) );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const showLoading = useRef( true );
  const isInitialMount = useRef( true );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => NotificationService.getNotifications( {
      ...params,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
      // accommodation: _.get( params, 'accommodation.value' )
      //   ? params.accommodation.value
      //   : undefined,
    } ),
    callback: ( params ) => {
      initialReloadError.current = false;
      showLoading.current = true;
      updateFilters( 'roomExpenses', {
        keyword: params.keyword,
        // accommodation: params.accommodation
        //   ? {
        //     value: params.accommodation.value,
        //     label: params.accommodation.label,
        //   }
        //   : null,
        from: dateRange.current.start
          ? dateRange.current.start.format( 'YYYY-MM-DD' )
          : undefined,
        till: dateRange.current.end
          ? dateRange.current.end.format( 'YYYY-MM-DD' )
          : undefined,
      } );

      const search = new URLSearchParams(
        _.omitBy(
          {
            ...params,
            from: dateRange.current.start
              ? dateRange.current.start.format( 'YYYY-MM-DD' )
              : undefined,
            till: dateRange.current.end
              ? dateRange.current.end.format( 'YYYY-MM-DD' )
              : undefined,
            // accommodation_value: _.get( params, 'accommodation.value' ),
            // accommodation_label: _.get( params, 'accommodation.label' ),
            // accommodation: null,
          },
          _.isNil,
        ),
      ).toString();
      if ( search ) {
        history.replace( { search: `?${search}` } );
      }
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        dateRange.current = moment.range(
          moment().startOf( 'day' ).subtract( '1', 'week' ),
          moment().startOf( 'day' ),

        );
        setFiltersError( 'tasks' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      showLoading.current = false;
      setDataSubmitted( new Date().valueOf() );
    }
  }, [taskReloadedAt] );

  const updateDateRangeParams = useCallback( ( params ) => {
    dateRange.current = params;
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  /// ///////////////////////////////

  const [isAllProcessed, setIsAllProcessed] = useState( false );

  useEffect( () => {
    if ( data && data.data ) {
      const total = data.data.length;
      const processed = data.data.filter( ( n ) => n.status === 'processed' ).length;

      if ( total === processed ) {
        setIsAllProcessed( true );
      } else {
        setIsAllProcessed( false );
      }
    } else {
      setIsAllProcessed( false );
    }
  }, [data] );

  const updateNotification = useCallback( async ( id ) => {
    toggleLoading( true );

    let idsToUpdate = [];

    if ( id === 'allVisibles' ) {
      if ( data && data.data ) {
        idsToUpdate = data.data.filter( ( n ) => n.status !== 'processed' ).map( ( n ) => n.id );
      }
    } else {
      idsToUpdate.push( id );
    }

    const [errors, response] = await handlePromise(
      NotificationService.updateProcessedNotifications( idsToUpdate ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      toggleErrorAlert( errors );
      return errors;
    }
    toggleLoading( false );
    setDataSubmitted( new Date().valueOf() );
  }, [toggleErrorAlert, toggleLoading, data] );

  /// //////////////////////////////

  const urlFilters = () => {
    const filters = {
      keyword: urlSearchParams.get( 'keyword' ),
      priority: urlSearchParams.get( 'priority' ),
      status: urlSearchParams.get( 'status' ),
      type: urlSearchParams.get( 'type' ),
      taskType: urlSearchParams.get( 'taskType' ),
      property:
        urlSearchParams.get( 'property_value' )
        && urlSearchParams.get( 'property_label' )
          ? {
            value: urlSearchParams.get( 'property_value' ),
            label: urlSearchParams.get( 'property_label' ),
          }
          : null,
      accommodation:
        urlSearchParams.get( 'accommodation_value' )
        && urlSearchParams.get( 'accommodation_label' )
          ? {
            value: urlSearchParams.get( 'accommodation_value' ),
            label: urlSearchParams.get( 'accommodation_label' ),
          }
          : null,
      assigned:
        urlSearchParams.get( 'assigned_value' )
        && urlSearchParams.get( 'assigned_label' )
          ? {
            value: urlSearchParams.get( 'assigned_value' ),
            label: urlSearchParams.get( 'assigned_label' ),
          }
          : null,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
    };
    return filters;
  };

  return (
    <NotificationsDashboardView
      data={data}
      isLoading={dataLoading && showLoading.current}
      initialDates={dateRange.current}
      defaultFilters={urlFilters()}
      onFetchData={fetchData}
      onDateChanged={updateDateRangeParams}
      isAllProcessed={isAllProcessed}
      //
      onUpdateNotification={updateNotification}
    />
  );
};

const mapStateToProps = ( { task, router, user } ) => ( {
  taskReloadedAt: task.reloadedAt,
  searchParams: _.get( router, 'location.search', '' ),
  propertyManager: _.get( user, 'user.propertyManager.id', {} ),
} );

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( NotificationsDashboard );
