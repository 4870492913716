import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable,
  DaterangePickerBtn,
  CanAccess,
  AddBtn,
  TableActionsBtns,
  Intl,
  Switch,
  Select,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    isLoading,
    onEditRoomExpense,
    defaultFilters,
    onFetchData,
    onDelete,
    selectedRows,
    onToggleRowChecked,
    onToggleAllRowsChecked,
    applyToOptions,
    onApplyToChange,
  } ) => (
    <>
      <AsyncTable
        id="bookings"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        onFetchData={onFetchData}
        customFilters={( { filters, setMultipleFilters } ) => (
          <Row className="align-items-center">
            <Col xs="auto">
              <div style={{ width: 360 }}>
                <DaterangePickerBtn
                  position="left"
                  label="expensesBetween"
                  initialValue={moment.range(
                    filters.from || moment().subtract( 1, 'months' ),
                    filters.till || moment(),
                  )}
                  key={filters.from + filters.till}
                  input
                  onChange={( values ) => {
                    setMultipleFilters( {
                      from: moment( values.start ).format( 'YYYY-MM-DD' ),
                      till: moment( values.end ).format( 'YYYY-MM-DD' ),
                    } );
                  }}
                />
              </div>
            </Col>
            {data && data.totalExpenses ? (
              <Col xs="auto" className="pt-2">
                <h4 className="pt-4">
                  <span className="text-normal">
                    <Intl id="total" />
                    :
                  </span>
                  {` ${data.totalExpenses}`}
                  &euro;
                </h4>
              </Col>
            ) : null}
          </Row>
        )}
        rightContent={(
          <CanAccess I="edit" a="accommodation">
            <AddBtn title="newExpense" onClick={onEditRoomExpense} />
          </CanAccess>
        )}
        columns={[
          {
            Header: (
              <div className="text-left">
                <span
                  className="d-inline-block"
                  style={{ position: 'relative', top: 2 }}
                >
                  <Switch
                    name="header"
                    disabled={!canModify( 'accommodation' )}
                    formGroupClass="m-0 pl-3 d-inline-block"
                    checked={
                      data.data
                      && data.data.length
                      && canModify( 'accommodation' )
                      && data.data.length
                        === Object.entries( selectedRows ).filter(
                          ( [, value] ) => value,
                        ).length
                    }
                    hideSideLabels
                    onChange={onToggleAllRowsChecked}
                  />
                </span>
                <span className="align-top ml-1 d-inline-block">
                  <Intl id="invoiced" />
                </span>
              </div>
            ),
            id: 'check',
            accessor: 'id',
            maxWidth: 155,
            Cell: ( { cell } ) => {
              const title = `${cell.row.original.id.slice(
                cell.row.original.id.length - 6,
                cell.row.original.id.length,
              )}`;
              return (
                <div className="text-left">
                  <Switch
                    disabled={!canModify( 'accommodation' )}
                    name={cell.value}
                    formGroupClass="m-0 pl-3 d-inline-block"
                    checked={selectedRows[cell.value]}
                    hideSideLabels
                    onChange={( e ) => {
                      onToggleRowChecked( cell.value, e.target.checked );
                    }}
                  />
                  <b className="text-uppercase pl-2">{title}</b>
                </div>
              );
            },
          },
          {
            Header: 'name',
            accessor: 'id',
            Cell: ( { cell } ) => (
              <a
                href="/"
                onClick={( e ) => {
                  e.preventDefault();
                  onEditRoomExpense( cell.value );
                }}
              >
                {cell.row.original.name}
              </a>
            ),
          },
          {
            Header: 'description',
            accessor: 'description',
          },
          {
            Header: 'date',
            accessor: 'date',
            Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY' ) : '' ),
          },
          {
            Header: 'applyTo',
            accessor: 'applyTo',
            maxWidth: 200,
            Cell: ( { cell } ) => (
              <div className="text-left">
                <Select
                  placeholder="none"
                  isDisabled={cell.row.original.invoiced || !canModify( 'accommodation' )}
                  translateOptions
                  value={cell.value}
                  isClearable
                  options={applyToOptions}
                  onChange={( value ) => {
                    onApplyToChange( value, cell.row.values.check );
                  }}
                />
              </div>
            ),
          },
          {
            Header: 'cost',
            accessor: 'cost',
          },

          {
            accessor: 'id',
            maxWidth: 100,
            Cell: ( { cell } ) => {
              const actions = [
                {
                  label: 'edit',
                  onClick: () => {
                    onEditRoomExpense( cell.value );
                  },
                },
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ];

              return <TableActionsBtns actions={actions} />;
            },
          },
        ]}
      />
    </>
  ),
);
