import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EditView from 'views/PropertyManager/Edit/SmartLockers/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import SmartLockerService from 'api/Settings/SmartLocker';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import PropertyService from 'api/Property';

const Dashboard = ( {
  smartLockerSettingId,
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
  onReload,
  modalOpened,
  onCloseModal,
} ) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    conditional: modalOpened,
    deps: [modalOpened],
  } );

  const handleOnChangeCountry = useCallback(
    ( id ) => {
      setSelectedCountry( _.find( countries, { id } ) );
      setSelectedState();
    },
    [countries],
  );

  const [states, statesLoading] = useFetch( {
    initialState: [],
    promise: () => StateService.getStates( selectedCountry.iso ),
    toggleErrorAlert,
    conditional: modalOpened && !!selectedCountry,
    deps: [modalOpened, selectedCountry],
  } );

  const handleOnChangeState = useCallback(
    ( id ) => {
      setSelectedState( _.find( states, { id } ) );
    },
    [states],
  );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => SmartLockerService.getSmartLockerSetting( smartLockerSettingId ),
    format: ( dataToFormat ) => {
      const countryId = _.get( dataToFormat, 'country.id' );
      if ( countryId ) {
        setSelectedCountry( _.find( countries, { id: countryId } ) );
      }
      const stateId = _.get( dataToFormat, 'state.id' );
      if ( countryId ) {
        setSelectedState( _.find( states, { id: stateId } ) );
      }
      return dataToFormat;
    },
    reInit: true,
    toggleErrorAlert,
    conditional: modalOpened && !!smartLockerSettingId,
    deps: [modalOpened, smartLockerSettingId],
  } );

  const [cities, citiesLoading] = useFetch( {
    initialState: [],
    promise: () => ( !!selectedCountry && !!selectedState
      ? CityService.getCities( selectedCountry.iso, selectedState.iso )
      : new Promise( ( resolver ) => resolver( {
        ok: true,
        data: [],
      } ) ) ),
    toggleErrorAlert,
    conditional: modalOpened,
    deps: [modalOpened, selectedCountry, selectedState],
  } );

  const [properties, propertiesLoading] = useFetch( {
    initialState: [],
    promise: PropertyService.getProperties,
    format: ( dataToFormat ) => _.get( dataToFormat, 'data', [] ),
    toggleErrorAlert,
    conditional: modalOpened,
    deps: [modalOpened],
  } );

  const submit = useSubmit( {
    promise: ( dataToSend ) => {
      if ( smartLockerSettingId ) {
        return SmartLockerService.updateSmartLockerSetting(
          smartLockerSettingId,
          dataToSend,
        );
      }
      return SmartLockerService.saveSmartLockerSetting( dataToSend );
    },
    toggleLoading,
    toggleInfoAlert,
    callback: onReload,
    deps: [smartLockerSettingId],
  } );

  return (
    <EditView
      data={data}
      isLoading={dataLoading}
      entityId={smartLockerSettingId}
      open={modalOpened}
      onSubmit={submit}
      onClose={onCloseModal}
      countries={countries}
      isLoadingCountries={countriesLoading}
      onChangeCountry={handleOnChangeCountry}
      states={states}
      isLoadingStates={statesLoading}
      onChangeState={handleOnChangeState}
      cities={cities}
      isLoadingCities={citiesLoading}
      properties={properties}
      isLoadingProperties={propertiesLoading}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
