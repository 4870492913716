import React from 'react';
import {
  Row, Col, Button,
} from 'reactstrap';
import { FieldArray } from 'react-final-form-arrays';

import {
  TextField,
  Intl,
  DropzoneFile,
} from 'components';

export default React.memo( () => (
  <FieldArray name="catalogs">
    {( { fields } ) => (
      <>
        {fields.map( ( catalog, index ) => (
          <Row key={catalog} className="mb-2">
            <Col xs="auto" className="pt-2" onClick={() => fields.remove( index )}>
              <i className="app-icon-close text-sm cursor-pointer px-2" />
            </Col>
            <Col md={4} className="pl-0">
              <TextField
                field={`${catalog}.name`}
                placeholder="name"
                formGroupClass="m-0"
              />
            </Col>
            <Col md="auto">
              <DropzoneFile
                field={`${catalog}.file`}
                accept="application/pdf"
                formGroupClass="m-0"
              />
            </Col>
          </Row>
        ) )}

        <Button
          color="link"
          className="pl-0"
          type="button"
          onClick={() => fields.push( { name: '' } )}
        >
          <i className="fa fa-plus mr-1" />
          <Intl id="addCatalog" />
        </Button>
      </>
    )}
  </FieldArray>
) );
