import React from 'react';
import PipelinesCRMContextProvider from './context';
import DashboardPipelines from './Dashboard';

const Pipelines = () => (
  <PipelinesCRMContextProvider>
    <DashboardPipelines />
  </PipelinesCRMContextProvider>
);

export default Pipelines;
