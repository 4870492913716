import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
// import async from 'async';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';

import EditView from 'views/Communications/Client/PredefinedNotifications/Edit';
import { useFetch, useDelete, useSubmit } from 'hooks';
import CommunicationService from 'api/CommunicationTemplates';
import CityService from 'api/Settings/City';
import ZoneService from 'api/Settings/Zone';
import PropertyService from 'api/Property';
import AccommodationService from 'api/Accommodation';
import NotificationService from 'api/PredefinedNotification';
import BookingTypeService from 'api/BookingType';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';
import { parallel } from 'utils/async';

const Notification = ( {
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
  parentPath,
  match,
  history,
  propertyManager,
} ) => {
  const predefinedNotificationId = _get( match, 'params.id' );
  const [zones, setZones] = useState( [] );
  const fetchZones = useCallback(
    ( citiesIds ) => {
      toggleLoading( true );
      parallel(
        citiesIds.map( ( cityId ) => async () => {
          const [errors, response, responseData] = await handlePromise(
            ZoneService.getZones( cityId, {
              propertyManager: propertyManager.id,
            } ),
          );
          return !response.ok ? errors : responseData;
        } ),
        ( error, results ) => {
          toggleLoading( false );
          if ( error ) {
            return toggleErrorAlert( error );
          }
          const formatedResults = _reduce(
            results,
            ( acu, { data: _data } ) => [...acu, ..._data],
            [],
          );
          setZones( formatedResults );
        },
      );
    },
    [propertyManager.id, toggleErrorAlert, toggleLoading],
  );

  const [cities, citiesLoading] = useFetch( {
    initialState: {},
    promise: () => CityService.getCities( { propertyManager: propertyManager.id } ),
    format: ( dataToFormat ) => _map( _get( dataToFormat, 'data', [] ), ( { id, city } ) => ( {
      id: city.id,
      value: id,
      name: city.name,
    } ) ),
    toggleErrorAlert,
  } );

  const onChangeCities = useCallback(
    ( citiesIds = [] ) => {
      fetchZones(
        _map(
          _filter( cities, ( { id } ) => citiesIds.includes( id ) ),
          'value',
        ),
      );
    },
    [cities, fetchZones],
  );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => NotificationService.getNotification( predefinedNotificationId ),
    format: ( dataToFormat ) => {
      const formatOptions = ( field ) => _map(
        _get( dataToFormat, field, [] ),
        ( { id, name } ) => ( {
          value: id,
          label: name,
        } ),
      );
      const citiesIds = _map( _get( dataToFormat, 'cities', [] ), 'id' );
      const zonesIds = _map( _get( dataToFormat, 'zones', [] ), 'id' );
      onChangeCities( citiesIds );
      const formatedData = {
        ...dataToFormat,
        cities: citiesIds,
        zones: zonesIds,
        properties: formatOptions( 'properties' ),
        accommodations: formatOptions( 'accommodations' ),
      };
      return formatedData;
    },
    toggleErrorAlert,
    conditional: !!predefinedNotificationId,
    deps: [predefinedNotificationId, cities],
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( predefinedNotificationId
      ? NotificationService.updateNotification(
        predefinedNotificationId,
        dataToSend,
      )
      : NotificationService.saveNotification( dataToSend ) ),
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      extraRules: _get( dataToFormat, 'extraRules', [] ).map(
        ( extraRule ) => ( extraRule.rule === 'booking_type'
          ? ( {
            rule: 'booking_type',
            bookingType: _get( extraRule, 'bookingType.id',
              extraRule.bookingType ),
          } )
          : extraRule ),
      ),
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: () => history.push( parentPath ),
  } );

  const [templates, templatesLoading] = useFetch( {
    initialState: [],
    promise: ( params ) => CommunicationService.getTemplates( { ...params, elementsPerPage: 500 } ),
    format: ( dataToFormat ) => _get( dataToFormat, 'data', [] ),
    toggleErrorAlert,
  } );

  const [bookingTypesOptions] = useFetch( {
    initialState: [],
    promise: BookingTypeService.getTypes,
    format: ( dataToFormat ) => _map( _get( dataToFormat, 'data', [] ),
      ( item ) => ( {
        name: item.name,
        id: item.id,
      } ) ),
    toggleErrorAlert,
  } );

  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 10,
      keyword: query,
      propertyManager: propertyManager.id,
    } ),
    [propertyManager.id],
  );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
      propertyManager: propertyManager.id,
    } ),
    [propertyManager.id],
  );

  const deleteNotification = useDelete( {
    promise: NotificationService.deleteNotification,
    callback: () => history.push( parentPath ),
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <EditView
      data={data}
      isLoading={dataLoading}
      onSubmit={submitForm}
      parentPath={parentPath}
      onDelete={deleteNotification}
      entityId={predefinedNotificationId}
      onFetchProperties={fetchProperties}
      onFetchAccommodations={fetchAccommodations}
      onChangeCities={onChangeCities}
      zones={zones}
      cities={cities}
      isLoadingCities={citiesLoading}
      templates={templates}
      isLoadingTemplates={templatesLoading}
      bookingTypesOptions={bookingTypesOptions}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( mapStateToProps, mapDispatchToProps )( Notification );
