import React, { useContext } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import EmailEditorView from 'views/CRM/Contact/Edit/Email/New';
import { useSubmit } from 'hooks';
import { createEmail } from 'api/CRM/Contact';
import { ContactEditContext } from '../../context';

const EmailEditor = ( {
  sender, open, onClose, onReloadData, toggleLoading,
  toggleInfoAlert,
} ) => {
  const {
    contactId,
  } = useContext( ContactEditContext );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => createEmail( { ...dataToSend, contactId } ),
    toggleLoading,
    toggleInfoAlert,
    callback: ( ) => {
      onClose( );
      if ( onReloadData ) onReloadData();
    },
    deps: [contactId],
  } );

  return (
    <EmailEditorView
      data={{ sender }}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

const mapStateToProps = ( { user } ) => ( { sender: user?.user?.user?.email || '' } );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( mapStateToProps, mapDispatchToProps )( EmailEditor );
