import React from 'react';
import {
  Row, Col,
} from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable, TableToggler, DaterangePickerBtn,
  TableSelectFilter, Intl, BadgeModal, Badge,
} from 'components';
import { Link } from 'react-router-dom';
import { getResourceName } from 'utils';
import formatBookinglId from 'utils/bookingId';
import { canEditViewDelete } from 'config/Ability';
import { notificationStatusColors } from 'config/Constants';

const filterColumnMd = 6;
const filterColumnLg = 3;

const Dashboard = React.memo(
  ( {
    data,
    isLoading,
    onFetchData,
    defaultFilters,
    parentPath,
    onFetchCustomers,
    userBookingReference,
  } ) => {
    const { canEdit } = canEditViewDelete( 'settings_custom_fields' );

    return (
      <>
        <AsyncTable
          id="predefinedNotifications"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          customFiltersOpened={defaultFilters
            && ( defaultFilters.tillStart || defaultFilters.tillEnd
              || defaultFilters.accommodation || defaultFilters.status )}
          onFetchData={onFetchData}
          customFilters={( { filters, setMultipleFilters } ) => (
            <Row>
              <Col md={8} lg={4}>
                <DaterangePickerBtn
                  position="left"
                  label="date"
                  initialValue={moment.range( filters.from || moment(), filters.till || moment().add( 1, 'month' ) )}
                  key={filters.from + filters.till}
                  input
                  onChange={( values ) => {
                    setMultipleFilters( {
                      from: moment( values.start ).format( 'YYYY-MM-DD' ),
                      till: moment( values.end ).format( 'YYYY-MM-DD' ),
                    } );
                  }}
                />
              </Col>
              <TableToggler />
            </Row>
          )}
          customToggleFilters={( { filters, setFilter } ) => (
            <Row className="ml-0">
              <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 pt-2">
                <TableSelectFilter
                  name="to"
                  label="addressee"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  defaultOptions
                  labelKey={getResourceName}
                  loadOptions={onFetchCustomers}
                  setFilter={setFilter}
                />
              </Col>

              <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 pt-2">
                <TableSelectFilter
                  name="executed"
                  label="status"
                  placeholder="all"
                  filters={filters}
                  translateOptions
                  isClearable
                  setFilter={setFilter}
                  options={[
                    {
                      name: 'sendedNotification',
                      id: 'true',
                    },
                    {
                      name: 'notSendedNotification',
                      id: 'false',
                    },
                  ]}
                />
              </Col>
            </Row>

          )}
          columns={[
            {
              Header: 'notification',
              accessor: 'communicationTemplate.title',
              Cell: ( { cell } ) => ( canEdit && !cell.row.original.executed ? (
                <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                  {cell.value}
                </Link>
              ) : (
                cell.value
              ) ),
            },
            {
              Header: 'booking',
              accessor: 'bookedAccommodation',
              Cell: ( { cell } ) => (
                <Link to={`/bookings/list/${cell.value.id}`}>
                  {formatBookinglId( { ...cell.value, userBookingReference } )}
                </Link>
              ),
            },
            {
              Header: 'date',
              accessor: 'executeAfter',
              Cell: ( { cell } ) => ( cell.value
                ? `${moment( cell.value ).format( 'MMM DD, YYYY H:mm' )}`
                : '' ),
            },
            {
              Header: 'language',
              accessor: 'bookedAccommodation.owner.locale',
            },
            {
              Header: 'to',
              accessor: 'bookedAccommodation.owner.email',
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => {
                if ( !cell.value ) {
                  return '-';
                }
                const { eventsData } = cell.row.original;
                const statusList = eventsData && eventsData.length
                  ? eventsData.map( ( ev, k ) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={k}>
                      <Row className="small">
                        <Col>
                          {moment( ev.statusAt ).format( 'MMM DD, YYYY H:mm' )}
                        </Col>
                        <Col>
                          <Badge
                            value={ev.status}
                            colors={notificationStatusColors}
                          />
                        </Col>
                      </Row>
                      <hr className="my-1" />
                    </div>
                  ) ) : null;

                return (
                  <BadgeModal
                    value={cell.value}
                    title="statusHistorial"
                    colors={notificationStatusColors}
                  >
                    {statusList}
                  </BadgeModal>
                );
              },
            },
            {
              Header: 'sent',
              accessor: 'executed',
              Cell: ( { cell } ) => ( cell.value
                ? <Intl id="yes" />
                : <Intl id="no" /> ),
            },
          ]}
        />
      </>
    );
  },
);

export default Dashboard;
