import api from 'config/Api/Api';

const baseEndpoint = '/tasks';

export default {
  getAccessRequests( params ) {
    return api.get( `${baseEndpoint}/access-requests`, params );
  },
  getAccessRequest( taskId, id ) {
    return api.get( `${baseEndpoint}/${taskId}/access-request/${id}` );
  },
  saveAccessRequest( taskId, data ) {
    return api.post( `${baseEndpoint}/${taskId}/access-request`, data );
  },
  updateAccessRequest( taskId, id, data ) {
    return api.put( `${baseEndpoint}/${taskId}/access-request/${id}`, data );
  },
  deleteAccessRequest( taskId, id ) {
    return api.delete( `${baseEndpoint}/${taskId}/access-request/${id}` );
  },
};
