import React from 'react';
import classnames from 'classnames';

const TimeLine = ( { dateRange } ) => {
  if ( dateRange.length === 0 ) return null;
  return (
    <tbody>
      <tr>
        <th className="dc_date-sticky dc_date-start dc_title">
          {`${dateRange[0].month} ${dateRange[0].year}`}
        </th>
        {dateRange.map(
          ( {
            date, month, year,
            isStartMonth,
          } ) => {
            if ( isStartMonth ) {
              return (
                <th className="dc_date-sticky dc_title" key={date}>
                  {`${month} ${year}`}
                </th>
              );
            }
            return <th label="Empty" className="dc_data-empty" key={date} />;
          },
        )}
      </tr>
      <tr>
        <th label="Empty" className="dc_sidebar_cell dc_bg-white" />
        {dateRange.map(
          ( {
            dayOfWeek, day, isWeekend, date,
          } ) => (
            <th
              key={date}
              className={classnames( 'text-center flex-grow-1', {
                'dc_bg-primary': isWeekend,
              } )}
            >
              {dayOfWeek}
              <br />
              {day}
            </th>
          ),
        )}
      </tr>
    </tbody>
  );
};

export default TimeLine;
