const permissions = [
  {
    name: 'vacations',
    enable: false,
    allowedToAccumulate: false,
    daysPerYear: 1,
  },
  {
    name: 'medicalLeave',
    enable: false,
    allowedToAccumulate: false,
  },
  {
    name: 'personalAffairs',
    enable: false,
    allowedToAccumulate: false,
    daysPerYear: 1,
  },
  {
    name: 'unjustified',
    enable: false,
    allowedToAccumulate: false,
    daysPerYear: 1,
  },
  {
    name: 'notAvailable',
    enable: false,
    allowedToAccumulate: false,
    daysPerYear: 1,
  },
];

export default permissions;
