import React, { useCallback, useState } from 'react';
import { useFetchTableData } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import AccommodationsView from 'views/PropertyManager/Edit/Occupancies/EditDrawer/Accomodations';
import { connect } from 'react-redux';
import { getAllAccommodationsOccupancy } from 'api/Occupancy/Accomodations';
import AccommodationsAddModal from './Edit';

const OccupancySeasons = ( { occupancyId, onReloadOccupancies, toggleErrorAlert } ) => {
  const [modalOpen, setModalOpen] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const onOpenModal = () => {
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getAllAccommodationsOccupancy( {
      occupancyId,
      ...params,
    } ),
    toggleErrorAlert,
    deps: [occupancyId, dataSubmitted],
    conditional: Boolean( occupancyId ),
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
    onReloadOccupancies();
    onCloseModal();
  }, [onReloadOccupancies] );

  return (
    <>
      <AccommodationsView
        isDataLoading={dataLoading}
        data={data}
        onFetchData={fetchData}
        onDeleteItem={() => {}}
        onOpenEditItemModal={onOpenModal}
      />
      <AccommodationsAddModal
        occupancyId={occupancyId}
        data={data}
        open={modalOpen}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( OccupancySeasons );
