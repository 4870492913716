import React from 'react';
import classnames from 'classnames';
import Drawer from 'rc-drawer';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import { Field } from 'react-final-form';

import {
  AsyncSelect,
  AsyncTable,
  Form,
  Checkbox,
  TableGenericFilter,
  TextField,
  FormActionsBtns,
  TableSelectFilter,
} from 'components';
import { required } from 'config/InputErrors';
import { getResourceName } from 'utils';
import { roles } from 'config/Constants';

export default React.memo( ( {
  isLoading, open, dataTable, tableLoading, isNomadsAdmin, options,
  onFetchEmployeesTable, onFetchEmployees, onSubmit, onClose,
} ) => {
  const nomadsAdminRolesArray = [roles.nomadsAdmin, roles.nomadsAdminReadOnly,
    roles.admin, roles.adminReadOnly, ...options.roles];
  const adminRolesArray = [roles.admin, roles.adminReadOnly, ...options.roles];

  const getRoles = ( rolesArray ) => _.chain( rolesArray )
    .filter( ( role ) => role.id !== 'ROLE_USER' && role.id !== 'ROLE_EMPLOYEE' )
    .map( 'name' )
    .join( ', ' )
    .value();

  return (
    <Drawer
      open={open}
      placement="right"
      handler={false}
      maskClosable={false}
      level={null}
      onClose={onClose}
    >
      <div className={classnames( 'p-4', { 'app-component-loading': isLoading } )}>
        <Row>
          <Col className="text-right">
            <i className="app-icon-close cursor-pointer py-2 pl-2" onClick={onClose} />
          </Col>
        </Row>
        <Form
          showErrorsInFooter
          onSubmit={onSubmit}
          footer={<FormActionsBtns onClickCancel={onClose} marginContainer={false} />}
          body={() => (
            <>
              <Row>
                <Col md={6}>
                  <TextField
                    field="name"
                    label="name"
                    validate={required}
                  />
                </Col>
                <Col md={6}>
                  <AsyncSelect
                    field="administrators"
                    label="administrators"
                    labelKey={getResourceName}
                    defaultOptions
                    validate={required}
                    multiple
                    loadOptions={onFetchEmployees}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <TextField
                    field="description"
                    label="description"
                    type="textarea"
                    rows={1}
                    validate={required}
                  />
                </Col>
              </Row>

              <Field name="participants">
                {( { input } ) => (
                  <AsyncTable
                    data={dataTable.data}
                    total={dataTable.elementsTotal}
                    isLoading={tableLoading}
                    disableSorting
                    onFetchData={onFetchEmployeesTable}
                    customFilters={( { filters, setFilter } ) => (
                      <Row>
                        <Col md={8} lg={5}>
                          <TableGenericFilter
                            filters={filters}
                            setFilter={setFilter}
                          />
                        </Col>
                        <Col md={8} lg={5} className="pl-lg-0 pt-1 pt-lg-0">
                          <TableSelectFilter
                            name="roles"
                            placeholder="roles"
                            multiple
                            filters={filters}
                            setFilter={setFilter}
                            options={isNomadsAdmin ? nomadsAdminRolesArray : adminRolesArray}
                          />
                        </Col>
                      </Row>
                    )}
                    columns={[
                      {
                        Header: <Checkbox
                          name="header"
                          formGroupClass="m-0"
                          value={dataTable.data && dataTable.data.length
                              && !_.difference( _.map( dataTable.data, 'id' ), input.value ).length}
                          onChange={( value ) => {
                            const newParticipants = input.value ? [...input.value] : [];
                            _.map( dataTable.data, ( item ) => {
                              const index = newParticipants.indexOf( item.id );
                              if ( value ) {
                                if ( index === -1 ) newParticipants.push( item.id );
                              } else if ( index !== -1 ) newParticipants.splice( index, 1 );
                            } );
                            input.onChange( newParticipants );
                          }}
                        />,
                        id: 'check',
                        accessor: 'id',
                        maxWidth: 45,
                        Cell: ( { cell } ) => (
                          <Checkbox
                            name={cell.value}
                            formGroupClass="m-0"
                            value={input.value.indexOf( cell.value ) !== -1}
                            onChange={( value ) => {
                              const newParticipants = input.value ? [...input.value] : [];
                              if ( value ) newParticipants.push( cell.value );
                              else {
                                const index = newParticipants.indexOf( cell.value );
                                if ( index !== -1 ) newParticipants.splice( index, 1 );
                              }
                              input.onChange( newParticipants );
                            }}
                          />
                        ),
                      },
                      {
                        Header: 'name',
                        accessor: 'firstName',
                        Cell: ( { cell } ) => (
                          <span>
                            {cell.value}
                            {' '}
                            {cell.row.original.lastName}
                          </span>
                        ),
                      },
                      {
                        Header: 'role',
                        accessor: 'roles',
                        Cell: ( { cell } ) => getRoles( cell.value ),
                      },
                    ]}
                  />
                )}
              </Field>
            </>
          )}
        />
      </div>
    </Drawer>
  );
} );
