import api from 'config/Api/Api';

const baseEndpoint = '/places';

export default {
  getList( params ) {
    return api.get( baseEndpoint, params );
  },

  getItem( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveItem( data ) {
    return api.post( baseEndpoint, data );
  },

  updateItem( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteItem( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
