const currencies = {
  EUR: new Intl.NumberFormat( 'es-ES', { style: 'currency', currency: 'EUR' } ),
};
const defaultCurrency = new Intl.NumberFormat( 'en-US', { style: 'currency', currency: 'USD' } );

// eslint-disable-next-line import/prefer-default-export
export const toValue = ( obj, prop, type = '%', currency ) => {
  const num = obj && obj[prop] ? obj[prop] : 0;

  const currencyFormatter = currencies[currency] || defaultCurrency;

  switch ( type ) {
    case '%':
      return `${num} %`;
    default:
      //
  }

  return currencyFormatter.format( num );
};
