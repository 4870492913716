import React from 'react';
import _ from 'lodash';
import {
  Row, Col, Container,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  AsyncTable, AddBtn, SimpleHeader, UserAvatar, ResourceAvatar,
  TableGenericFilter, TableActionsBtns,
} from 'components';
import { getInitials } from 'utils';
import ability, { canView, canModify } from 'config/Ability';

const TeamDashboard = React.memo( ( {
  data, isLoading, parentPath, defaultFilters,
  onDelete, onFetchData, onOpenEditModal,
} ) => {
  const canViewProp = canView( 'team' );
  const canEditDelete = canModify( 'team' );

  return (
    <>
      <SimpleHeader
        title="teams"
      />
      <Container fluid>
        <AsyncTable
          id="teams"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={ability.can( 'manage', 'team' ) ? ( <AddBtn title="newTeam" onClick={onOpenEditModal} /> ) : null}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter
                  filters={filters}
                  setFilter={setFilter}
                />
              </Col>

              {/* <TableToggler /> */}
            </Row>
          )}
          /* customToggleFilters={( { filters, setFilter } ) => (
             <Row>
               <Col md={8} lg={4}>
                 <TableSelectFilter
                   options={options.zones}
                   multiple
                   name="zone"
                   label="zone"
                   placeholder="chooseZone"
                   filters={filters}
                   isClearable
                   setFilter={setFilter}
                 />
               </Col>
               <Col md={8} lg={4} className="pl-lg-0 pt-1 pt-lg-0">
                 <TableSelectFilter
                   loadOptions={onFetchManagers}
                   labelKey={( item ) => `${item.firstName || ''} ${item.lastName || ''}`}
                   defaultOptions
                   name="manager"
                   label="manager"
                   placeholder="chooseManager"
                   filters={filters}
                   isClearable
                   setFilter={setFilter}
                 />
               </Col>
             </Row>
           )} */
          columns={[
            {
              Header: 'name',
              accessor: 'name',
              Cell: ( { cell } ) => (
                canEditDelete || canViewProp
                  ? (
                    <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                      {cell.value}
                      {' '}
                      {cell.row.original.lastName || ''}
                    </Link>
                  )
                  : (
                    <span>
                      {cell.value}
                      {' '}
                      {cell.row.original.lastName || ''}
                    </span>
                  )
              ),
            },
            {
              Header: 'manager',
              accessor: 'manager',
              Cell: ( { cell } ) => (
                <ResourceAvatar data={cell.value} />
              ),
            },
            {
              Header: 'supervisors',
              accessor: 'admins',
              Cell: ( { cell } ) => (
                cell.value
                  ? _.map( cell.value, ( item, index ) => ( [
                    index > 0 && index % 2 === 0 && <br key={`tooltip-supervisor-${cell.row.index}-${item.id}`} />,
                    <ResourceAvatar
                      data={item}
                      className="mr-3 mb-1"
                      inline
                      key={`tooltip-supervisor-${cell.row.index}-${item.id}1`}
                    />,
                  ] ) ) : ''
              ),
            },
            {
              Header: 'members',
              accessor: 'members',
              Cell: ( { cell } ) => (
                cell.value
                  ? _.map( cell.value,
                    ( item, index ) => ( [
                      index > 0 && index % 10 === 0 && <br key={`tooltip-members-${cell.row.index}-${item.id}`} />,
                      <UserAvatar
                        avatar={item.profilePicture}
                        initials={getInitials( item )}
                        hasTooltip
                        inline
                        tooltipContent={`${item.firstName || ''} ${item.lastName || ''}`}
                        className="mr-2"
                        target={`tooltip-members-${cell.row.index}-${item.id}`}
                        key={`tooltip-supervisor-${cell.row.index}-${item.id}1`}
                      />,
                    ] ) ) : ''
              ),
            },
            {
              Header: 'cities',
              accessor: 'zones',
              Cell: ( { cell } ) => {
                const citiesGroup = _.groupBy(
                  cell.value,
                  'propertyManagerCity.id',
                );
                const cities = _.map( _.values( citiesGroup ), ( cityGroup ) => {
                  const propertyManagerCity = _.get(
                    cityGroup,
                    '[0].propertyManagerCity',
                  );
                  return {
                    value: propertyManagerCity.id,
                    label: propertyManagerCity.city.name,
                  };
                } );
                return _.map( cities, 'label' ).join( ', ' );
              },
            },
            {
              Header: 'zones',
              accessor: 'zones',
              Cell: ( { cell } ) => _.map( cell.value, 'name' ).join( ', ' ),
            },
            {
              Header: 'properties',
              accessor: 'properties',
              Cell: ( { cell } ) => _.map( cell.value, 'name' ).join( ', ' ),
            },
            // {
            //   Header: 'taskInsideShiftS',
            //   accessor: 'taskInsideShift',
            //   Cell: ( { cell } ) => <Intl id={cell.value ? 'yes' : 'no'} />,
            // },
            // {
            //   Header: 'teamType',
            //   accessor: 'teamType',
            //   Cell: ( { cell } ) => <Intl id={cell.value ? 'yes' : 'no'} />,
            // },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => {
                  const actions = [];

                  actions.push( {
                    onClick: () => onOpenEditModal( { id: cell.value } ),
                    label: 'edit',
                  } );

                  actions.push( {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  } );

                  return (
                    <TableActionsBtns actions={actions} />
                  );
                },
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );

export default TeamDashboard;
