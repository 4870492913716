import React from 'react';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import { Intl } from 'components';

const CheckboxInput = React.memo( ( {
  label, value, inputName, inputOnChange, onChange, formGroupClass,
  translateValues, translate = true, meta, isInvalid, formId = '',
} ) => (
  <FormGroup className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}>
    <div className="custom-control custom-checkbox custom-checkbox-white">
      <input
        className="custom-control-input"
        checked={value}
        id={`${formId}-${inputName}`}
        type="checkbox"
        onChange={( e ) => {
          if ( inputOnChange ) inputOnChange( e.target.checked );
          if ( onChange ) onChange( e.target.checked );
        }}
        data-cy={`input-${inputName}`}
      />

      <label
        className="custom-control-label"
        htmlFor={`${formId}-${inputName}`}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {label ? ( translate ? <Intl id={label} /> : label ) : null}
      </label>

    </div>

    {isInvalid
    && (
      <div className="invalid-feedback">
        <Intl id={meta.error || meta.submitError} values={translateValues} />
      </div>
    )}
  </FormGroup>
) );

export default CheckboxInput;
