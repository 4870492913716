import api from 'config/Api/Api';
// import { formatFormDataBody } from 'utils';

const baseEndpoint = '/smart-locker-setting';

export default {
  getSmartLockerSettings( params ) {
    return api.get( baseEndpoint, params );
  },
  getSmartLockerSetting( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  saveSmartLockerSetting( data ) {
    return api.post( baseEndpoint, data );
  },
  updateSmartLockerSetting( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  deleteSmartLockerSetting( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
