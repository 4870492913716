import React from 'react';
import {
  Intl, DetailCard, Form, TextField, FormActionsBtns,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( { data, onSubmit } ) => (
  <DetailCard
    classNames={{ header: 'py-3', body: 'pt-4', card: 'card-sec-bottom' }}
    header={(
      <h3 className="m-0"><Intl id="observations" /></h3>
    )}
  >
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      showErrorsInFooter
      body={() => (
        <TextField
          field="observations"
          type="textarea"
          rows={4}
          validate={required}
        />
      )}
      footer={(
        <FormActionsBtns />
      )}
    />
  </DetailCard>
) );
