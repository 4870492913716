import React from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable, AddBtn, SimpleHeader, TableActionsBtns,
  CanAccess, TableSelectFilter, TableCellList,
} from 'components';
import ability, { canEditViewDelete } from 'config/Ability';
import { getResourceName } from 'utils';

export default React.memo( ( {
  data, isLoading, defaultFilters, onFetchData,
  onOpenEditModal, onDelete,
  onFetchEmployees, onFetchProperties,
  onFetchAccommodations,
} ) => {
  const { canDelete } = canEditViewDelete( 'master_key' );

  return (
    <>
      <SimpleHeader title="masterKeys" />
      <Container fluid>
        <AsyncTable
          id="masterKeys"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={(
            <CanAccess I="add" a="master_key">
              <div className="pt-3">
                <AddBtn
                  title="newMasterKey"
                  onClick={onOpenEditModal}
                />
              </div>
            </CanAccess>
)}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={3} lg={3}>
                <TableSelectFilter
                  name="customer"
                  label="resources"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  defaultOptions
                  labelKey={getResourceName}
                  loadOptions={onFetchEmployees}
                  setFilter={setFilter}
                />
              </Col>
              <Col md={3} lg={3}>
                <TableSelectFilter
                  name="properties"
                  label="properties"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  defaultOptions
                  setFilter={setFilter}
                  loadOptions={onFetchProperties}
                />
              </Col>
              <Col md={3} lg={3}>
                <TableSelectFilter
                  name="accommodations"
                  label="accommodations"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  defaultOptions
                  setFilter={setFilter}
                  loadOptions={onFetchAccommodations}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <>
                  {ability.can( 'view', 'booking' )
                    ? (
                      <a
                        href="/"
                        className="text-uppercase"
                        onClick={( e ) => {
                          e.preventDefault();
                          onOpenEditModal( { id: cell.row.original.id } );
                        }}
                      >
                        {cell.row.original.id}
                      </a>
                    ) : (
                      <span>
                        {cell.row.original.id}
                      </span>
                    )}
                </>
              ),
            },
            {
              Header: 'resource',
              accessor: 'customer',
              Cell: ( { cell } ) => (
                cell.value.name
              ),
            },
            {
              Header: 'properties',
              accessor: 'properties',
              Cell: ( { cell } ) => <TableCellList list={cell.value} limit={5} />,
            },
            {
              Header: 'accommodations',
              accessor: 'accommodations',
              Cell: ( { cell } ) => <TableCellList list={cell.value} limit={5} />,
            },
            {
              Header: 'from',
              accessor: 'from',
              Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
            },
            {
              Header: 'till',
              accessor: 'till',
              Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
            },
            canDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        isDelete: true,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      },
                    ]}
                  />
                ),
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );
