import React, { useCallback, useRef, useState } from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { ConfirmationModal, Intl } from 'components';

export default React.memo( ( { actions, modalTitle } ) => {
  const [deleteModal, setDeleteModal] = useState( false );
  const deleteCb = useRef( null );
  const toggleDeleteModal = useCallback( ( cb ) => {
    deleteCb.current = cb;
    setDeleteModal( ( state ) => !state );
  }, [] );

  const action = ( {
    link, label, isDelete, onClick, ...rest
  } ) => (
    link ? (
      <Link to={link} {...rest} data-cy-link={link} className="dropdown-item">
        <Intl id={label} />
      </Link>
    ) : (
      <span
        className="cursor-pointer dropdown-item"
        data-cy="table-delete-btn"
        onClick={isDelete ? () => toggleDeleteModal( onClick ) : onClick}
        {...rest}
      >
        <Intl id={isDelete ? label || 'delete' : label} />
      </span>
    )
  );

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle className="btn btn-link mr-0" data-cy="table-actions" color="" tag="button" type="button">
          <i className="fa fa-ellipsis-h" />
        </DropdownToggle>

        <DropdownMenu right className="dropdown-actions text-center fadeInLeftBig">
          {actions ? _.map( actions, ( {
            link, label, onClick, isEdit, isDelete, ...rest
          }, index ) => (

            <div key={index}>
              {index > 0 && <DropdownItem divider className="m-1" />}
              {isEdit
                ? (
                  <Link to={link} data-cy="table-edit-btn" data-cy-link={link} className="dropdown-item">
                    <Intl id={label || 'edit'} />
                  </Link>
                ) : action( {
                  link, label, isDelete, onClick, ...rest,
                } )}
            </div>
          ) ) : null}
        </DropdownMenu>
      </UncontrolledDropdown>

      <ConfirmationModal
        title={modalTitle}
        open={deleteModal}
        onConfirm={() => deleteCb.current( toggleDeleteModal )}
        onClose={toggleDeleteModal}
      />
    </>
  );
} );
