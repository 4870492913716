import React from 'react';

import { TextField, Datepicker, Checkbox } from 'components';
import { composeValidators, required, integer } from 'config/InputErrors';

export default React.memo( ( { field, ...rest } ) => {
  switch ( field.dataType ) {
    case 'int':
      return (
        <TextField
          type="number"
          field={field.name}
          label={field.displayName}
          validate={
            field.required ? composeValidators( required, integer ) : integer
          }
          translate={false}
          {...rest}
        />
      );
    case 'float':
      return (
        <TextField
          type="number"
          field={field.name}
          label={field.displayName}
          validate={field.required ? required : null}
          translate={false}
          {...rest}
        />
      );
    case 'bool':
      return (
        <Checkbox
          field={field.name}
          label={field.displayName}
          validate={field.required ? required : null}
          translate={false}
          {...rest}
        />
      );
    case 'date':
      return (
        <Datepicker
          field={field.name}
          label={field.displayName}
          dateFormat="DD/MM/YYYY"
          validate={field.required ? required : null}
          utc
          translate={false}
          {...rest}
        />
      );
    case 'datetime':
      return (
        <Datepicker
          field={field.name}
          label={field.displayName}
          dateFormat="DD/MM/YYYY"
          validate={field.required ? required : null}
          timeFormat="H:mm"
          utc
          translate={false}
          {...rest}
        />
      );
    default:
      return (
        <TextField
          field={field.name}
          label={field.displayName}
          validate={field.required ? required : null}
          translate={false}
          {...rest}
        />
      );
  }
} );
