import api from 'config/Api/Api';

const baseEndpoint = '/incidences';

export default {
  getIncidences( params = {} ) {
    return api.get( baseEndpoint, { ...params } );
  },

  getIncidence( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveIncidence( data ) {
    return api.post( baseEndpoint, data );
  },

  updateIncidence( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  addIncidenceTask( id, taskId ) {
    return api.post( `${baseEndpoint}/${id}/add-task/${taskId}`, {} );
  },

  getIncidenceTasks( id, params ) {
    return api.get( `${baseEndpoint}/${id}/list-tasks`, params );
  },

  removeIncidenceTask( id, taskId ) {
    return api.delete( `${baseEndpoint}/${id}/remove-task/${taskId}` );
  },

  deleteIncidence( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
