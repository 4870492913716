import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TemplatesEditView from 'views/Communications/Client/Templates/Edit';
import { useFetch } from 'hooks';
import CommunicationService from 'api/CommunicationTemplates';
import TranslationService from 'api/Translation';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import ModalPreview from '../Preview';

const Templates = ( {
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
  parentPath,
  match,
  history,
} ) => {
  const templateId = _.get( match, 'params.id' );
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => CommunicationService.getTemplate( templateId ),
    translationPromise: ( responsePromise ) => {
      const id = _.get( responsePromise, 'id', '' );
      return TranslationService.getCommunicationTemplateTranslations( id );
    },
    toggleErrorAlert,
    conditional: !!templateId,
    deps: [templateId],
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const { banner } = formData;
      const translation = true;
      const dataToSend = formatDataToSend( formData, form, translation );

      const [errors, response, responseData] = await handlePromise(
        templateId
          ? CommunicationService.updateTemplate( templateId, dataToSend )
          : CommunicationService.saveTemplate( dataToSend ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }
      if ( typeof banner === 'object' ) {
        const [imageErrors, imageResponse] = await handlePromise(
          CommunicationService.updateBanner( responseData.id, {
            banner,
            name: banner.name,
          } ),
        );
        if ( !imageResponse.ok ) {
          toggleLoading( false );
          return imageErrors;
        }
      }
      toggleLoading( false );
      history.push( parentPath );
      return toggleInfoAlert( 'dataSaved' );
    },
    [templateId, toggleInfoAlert, toggleLoading, history, parentPath],
  );

  const [openModal, setOpenModal] = useState( false );
  const toggleModal = () => setOpenModal( ( state ) => !state );

  const [dataTempalte, setDataTempalte] = useState( {} );
  const showModalTemplate = ( { values } ) => {
    toggleModal();
    setDataTempalte( values );
  };

  return (
    <>
      <TemplatesEditView
        data={data}
        isLoading={dataLoading}
        onSubmit={submitForm}
        parentPath={parentPath}
        onPreview={showModalTemplate}
        entityId={templateId}
      />
      <ModalPreview
        open={openModal}
        onClose={toggleModal}
        dataTempalte={dataTempalte}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( null, mapDispatchToProps )( Templates );
