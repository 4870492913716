import api from 'config/Api/Api';

const baseEndpoint = '/property-owners';

export default {
  getNotifications( accOwnerId, params ) {
    return api.get( `${baseEndpoint}/${accOwnerId}/all-notifications/owner`, params );
  },

  getNotification( id ) {
    return api.get( `${baseEndpoint}/${id}/notifications` );
  },

  sendNotification( accOwnerId, data ) {
    return api.post( `${baseEndpoint}/${accOwnerId}/send-notification/owner`, data );
  },
};
