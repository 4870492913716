import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient( {
  uri: process.env.REACT_APP_CRM_API_URL,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
} );

export default client;
