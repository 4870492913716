import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import ServiceItemDashboardView from 'views/Service/Item/Dashboard';
import { useDelete, useFetch } from 'hooks';
import ServiceItemService from 'api/ServiceItem';
import SettingsActions from 'store/reducers/Settings';
import ServiceItemEdit from '../Edit';

const ServiceItemDashboard = ( {
  entityId, parentPath, toggleLoading, toggleErrorAlert,
} ) => {
  const toEditId = useRef( null );
  const [editModalOpened, setEditModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [data, dataLoading] = useFetch( {
    initialState: { data: [] },
    promise: () => ServiceItemService.getServiceItems(
      entityId, { elementsPerPage: 1000 },
    ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const closeEditModal = useCallback( () => {
    setEditModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( editModalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [editModalOpened, closeEditModal] );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setEditModalOpened( true );
  }, [] );

  const deleteEntity = useDelete( {
    promise: ( id ) => ServiceItemService.deleteServiceItem( entityId, id ),
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <ServiceItemDashboardView
        data={data}
        isLoading={dataLoading}
        parentPath={parentPath}
        entityId={entityId}
        onOpenEditModal={openEditModal}
        onDelete={deleteEntity}
      />

      <ServiceItemEdit
        serviceId={entityId}
        entityId={toEditId.current}
        modalOpened={editModalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ServiceItemDashboard );
