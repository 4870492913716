import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import BookingNewView from 'views/Property/RoomType/Accommodation/Edit/Bookings/New';
import BookingService from 'api/Booking';
import AccommodationService from 'api/Accommodation';
import CustomerService from 'api/Customer';
import PropertyService from 'api/Property';
import {

  formatDataToSend, handlePromise,
} from 'utils';

const NewBooking = ( {
  modalOpen, onReloadData, onCloseModal, toggleLoading,
  toggleInfoAlert, // accommodationId
} ) => {
  const [currentRoomType, setCurrentRoomType] = useState( '' );
  const [newCustomerId, setNewCustomerId] = useState( null );
  const submitForm = useCallback( async ( formData, form, extraServices ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );

    const formattedData = {
      accommodation: dataToSend.accommodation,
      capacity: dataToSend.capacity,
      from: dataToSend.from ? dataToSend.from.format( 'YYYY-MM-DD HH:mm:ss' ) : dataToSend.from,
      till: dataToSend.till ? dataToSend.till.format( 'YYYY-MM-DD HH:mm:ss' ) : dataToSend.till,
      ota: dataToSend.ota,
      requestSource: 'manager',
      roomType: currentRoomType,
      checkInTime: dataToSend.checkInTime ? dataToSend.checkInTime.format( 'HH:mm:ss' ) : dataToSend.checkInTime,
      checkOutTime: dataToSend.checkOutTime ? dataToSend.checkOutTime.format( 'HH:mm:ss' ) : dataToSend.checkOutTime,
      departureTime: dataToSend.departureTime ? dataToSend.departureTime.format( 'HH:mm:ss' ) : dataToSend.departureTime,
      expectedArrival: dataToSend.expectedArrival ? dataToSend.expectedArrival.format( 'HH:mm:ss' ) : dataToSend.expectedArrival,
      status: dataToSend.status,
      checkinComplete: dataToSend.checkinComplete,
      observations: dataToSend.observations,
      externalId: dataToSend.externalId,
      localizator: dataToSend.localizator,
      owner: newCustomerId || dataToSend.owner,
      price: dataToSend.price,
      rentalPrice: dataToSend.rentalPrice,
      currency: dataToSend.currency,
      commission: dataToSend.commission,
      ownerCommission: dataToSend.ownerCommission,
      extraServices,
    };
    const [errors, response/* , bookingData */] = await handlePromise(
      BookingService.saveBooking( formattedData ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( !dataToSend.images ) {
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      return onReloadData();
    }

    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
    onReloadData();
  }, [currentRoomType, newCustomerId, onReloadData, toggleInfoAlert, toggleLoading] );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 50,
      keyword: query,
    } ),
    [],
  );
  const fetchCustomers = useCallback(
    ( query ) => CustomerService.getCustomers( {
      elementsPerPage: 500,
      keyword: query,
    } ),
    [],
  );
  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 500,
      keyword: query,
    } ),
    [],
  );

  return (
    <BookingNewView
      open={modalOpen}
      onSubmit={submitForm}
      onClose={onCloseModal}
      toggleLoading={toggleLoading}
      onFetchAccommodations={fetchAccommodations}
      onFetchCustomers={fetchCustomers}
      onFetchProperties={fetchProperties}
      onSetCurrentRoomType={setCurrentRoomType}
      onSetNewCustomerId={setNewCustomerId}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( NewBooking );
