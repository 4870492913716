import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import classnames from 'classnames';

import {
  TextField,
  Select,
  ImagesDropzoneIndex,
  Intl,
  AsyncSelect,
  TranslationLayout,
  Form,
  SimpleHeader,
  EditCard,
  FormActionsBtns,
  Button,
} from 'components';
import { required, validUrl } from 'config/InputErrors';

export default React.memo(
  ( {
    data,
    dataFromRSS,
    entityId,
    isLoading,
    onSubmit,
    onAddImage,
    onDeleteImage,
    onFetchCategories,
    onFetchCities,
    parentPath,
    onNewPostFromRSS,
    regions, regionsLoading,
  } ) => (
    <>
      <SimpleHeader parentLinks={[{ name: 'posts', href: parentPath }]} />
      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <EditCard title={entityId ? 'editPost' : 'newPost'}>
          {!entityId && (
            <div className="d-flex justify-content-end mb-3">
              <Button
                title="newFromRSS"
                color="info"
                onClick={onNewPostFromRSS}
              />
            </div>
          )}

          <Form
            initialValues={{ ...data, ...dataFromRSS }}
            showErrorsInFooter
            isLoading={isLoading}
            onSubmit={onSubmit}
            body={( form ) => (
              <TranslationLayout
                translationFields={( locale ) => (
                  <>
                    <Row>
                      <Col md={6}>
                        <TextField
                          field={`translations.${locale}.title`}
                          label="title"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <TextField
                          field={`translations.${locale}.body`}
                          label="description"
                          type="textarea"
                          rows={4}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <TextField
                          field={`translations.${locale}.link`}
                          label="link"
                          validate={validUrl}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              >
                <Row>
                  <Col md={6}>
                    <TextField
                      field="title"
                      label="title"
                      validate={required}
                    />
                  </Col>
                  <Col md={6}>
                    <AsyncSelect
                      field="category"
                      label="category"
                      defaultOptions
                      isClearable
                      validate={required}
                      loadOptions={onFetchCategories}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <AsyncSelect
                      field="city"
                      label="city"
                      defaultOptions
                      isClearable
                      loadOptions={onFetchCities}
                      labelKey={( option ) => option.city.name}
                      idKey="city.id"
                    />
                  </Col>
                  <Col md={6}>
                    <Select
                      field="region"
                      label="region"
                      options={regions}
                      isLoading={regionsLoading}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <TextField
                      field="body"
                      label="description"
                      type="textarea"
                      rows={4}
                      validate={required}
                    />
                  </Col>
                  <Col sm={5}>
                    <Select
                      field="status"
                      label="status"
                      options={[
                        { id: 'draft', name: 'Draft' },
                        { id: 'published', name: 'Published' },
                        { id: 'archived', name: 'Archived' },
                      ]}
                      validate={required}
                    />
                  </Col>
                  <Col sm={7}>
                    <TextField
                      field="link"
                      label="link"
                      validate={validUrl}
                    />
                  </Col>
                </Row>

                <p className="mb-1 form-control-label">
                  <Intl id="images" />
                </p>
                <ImagesDropzoneIndex
                  form={form}
                  onAddImage={onAddImage}
                  onDeleteImage={onDeleteImage}
                />
              </TranslationLayout>
            )}
            footer={<FormActionsBtns cancelTo={parentPath} />}
          />
        </EditCard>
      </Container>
    </>
  ),
);
