import React from 'react';
import { Form } from 'components';
import { TabContent, TabPane } from 'reactstrap';

export default React.memo( ( {
  data, onSubmit, currentTab, formRef, tabs,
} ) => {
  const renderTabs = ( tabsList, extraProps ) => tabsList.map( ( tab ) => {
    if ( tab.component ) {
      return (
        <TabPane tabId={tab.name} key={tab.name}>
          <tab.component {...extraProps} />
        </TabPane>
      );
    }
    return null;
  } );
  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      showErrorsInFooter
      body={( form ) => (
        <TabContent activeTab={currentTab}>
          {renderTabs( tabs, { form } )}
        </TabContent>
      )}
      footer={(
        <button ref={formRef} className="invisible" type="submit">
          hidden
        </button>
      )}
    />
  );
} );
