/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Row, Col } from 'reactstrap';

import { Intl } from 'components';
import logo from 'assets/img/brand/logo-threeangular-footer.svg';

export default () => (
  <Row className="auth-footer align-items-center justify-content-between">
    <Col className="text-gray-8 text-sm">
      <img alt="ThreeAngular" src={logo} className="mr-3" style={{ height: 20 }} />
      ©
      {' '}
      {new Date().getFullYear()}
      {' '}
      ThreeAngular Software
    </Col>
    <Col className="text-right">
      <a href="https://nomadssoftware.com/tems-conditions" target="_blank" rel="noopener noreferrer" className="text-gray-8 text-sm">
        <Intl id="termsService" />
      </a>
      <a href="https://nomadssoftware.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-gray-8 text-sm ml-5">
        <Intl id="privacyPolicy" />
      </a>
    </Col>
  </Row>
);
