import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import ItemEditView from 'views/Booking/Edit/Service/Item/Edit';
import ServiceItemService from 'api/ServiceItem';
import SettingsActions from 'store/reducers/Settings';

const ItemEdit = ( {
  data,
  itemId,
  modalOpened,
  onCloseModal,
  serviceId,
  onSubmit,
} ) => {
  const fetchServiceItems = useCallback(
    ( query ) => ServiceItemService
      .getServiceItems( serviceId, {
        elementsPerPage: 10,
        keyword: query,
      } ),
    [serviceId],
  );

  return (
    <ItemEditView
      data={data}
      itemId={itemId}
      isLoading={false}
      open={modalOpened}
      onSubmit={onSubmit}
      onClose={onCloseModal}
      onFetchServiceItems={fetchServiceItems}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ItemEdit );
