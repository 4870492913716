import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import CustomerEditView from 'views/Customer/Edit';
import SettingsActions from 'store/reducers/Settings';
import CustomerService from 'api/Customer';
import { useFetch } from 'hooks';
import { formatDataPhone } from 'utils';

const CustomerEdit = ( {
  parentPath, match, location, toggleErrorAlert,
} ) => {
  const entityId = match.params.id;
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [data, loadingData] = useFetch( {
    promise: () => CustomerService.getCustomer( entityId ),
    format: ( dataToFormat ) => formatDataPhone( dataToFormat, ['phoneNumber'] ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const reloadData = useCallback( () => setDataSubmitted( new Date().valueOf() ), [] );

  return (
    <CustomerEditView
      data={data}
      isLoading={loadingData}
      pathname={location.pathname}
      pathLayout={match.url}
      parentPath={parentPath}
      entityId={entityId}
      onReload={reloadData}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( CustomerEdit );
