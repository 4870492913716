/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-trailing-spaces */
import api from 'config/Api/Api';

const pluginsEndpoint = '/plugins';
const keysEndpoint = '/smart-locker/keys';
const taskKeysEndpoint = '/smart-locker/openDoor/employee';
const boookingKeysEndpoint = '/smart-locker/openDoor';
const configuredPluginsEndpoints = '/smart-locker/smartLocker/accommodation';

export default {
  getPlugins: async () => api.get( `${pluginsEndpoint}` ),
  
  getConfiguredPlugins: async ( accommodationId ) =>
    api.get( `${configuredPluginsEndpoints}/${accommodationId}` ),

  getTaskKeys: async ( taskId ) => api.get( `${keysEndpoint}/task/${taskId}` ),

  configurePlugin: async ( propertyId, roomTypeId, accommodationId, dataToSend ) => 
    api.put( 
      `/properties/${propertyId}/room-types/${roomTypeId}/accommodations/${accommodationId}`,
      dataToSend,
    ),

  getBookingKeys: async ( bookedAccomodationId ) =>
    api.get( `${keysEndpoint}/bookedAccommodation/${bookedAccomodationId}` ),

  openTaskLocker: async ( { taskId, smartLockerid } ) =>
    api.post( `${taskKeysEndpoint}/${taskId}?smartLockerId=${smartLockerid}`, {} ),

  openBookingLocker: async ( { bookedAccommodation, smartLockerid } ) =>
    api.post(
      `${boookingKeysEndpoint}/${bookedAccommodation}?smartLockerId=${smartLockerid}`,
      {},
    ),
};
