import React from 'react';
import {
  Container,
  Modal,
} from 'reactstrap';
import {
  AsyncTable, AddBtn, TableActionsBtns,
  SimpleHeader,
} from 'components';
import EditRegionContainer from 'containers/Regions/edit';

const RegionsView = ( {
  fetchData,
  dataTable,
  dataLoading,
  defaultFilters,
  reload,
  onDelete,
  toggleModalEditor,
  modalAddOpen,
  elementToEdit,
} ) => (
  <>
    <SimpleHeader
      title="regions"
    />
    <Container fluid>
      <AsyncTable
        id="regionsTable"
        data={dataTable.data}
        total={dataTable.elementsTotal}
        isLoading={dataLoading}
        rightContent={(
          <AddBtn
            title="newRegion"
            onClick={() => {
              toggleModalEditor( true, null );
            }}
          />
      )}
        disableSorting
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
        columns={[
          {
            Header: '#',
            accessor: 'id',
            Cell: ( { cell } ) => ( <a href="/" onClick={( e ) => { e.preventDefault(); toggleModalEditor( true, cell.row.original ); }}>{cell.value.slice( 0, 6 )}</a> ),
          },
          {
            Header: 'name',
            accessor: 'name',
          },
          {
            Header: 'description',
            accessor: 'description',
          },
          {
            Header: 'countries',
            accessor: 'countries',
            Cell: ( { cell } ) => (
              <ul className="text-xs mb-0 pl-3">
                { ( cell.value || [] ).map( ( country ) => (
                  <li className="" key={country?.id}>
                    {country?.name || ''}
                  </li>
                ) ) }
              </ul>
            ),
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    label: 'edit',
                    // onClick: ( ) => onEdit( data ),
                    onClick: () => { toggleModalEditor( true, cell.row.original ); },
                  },
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </Container>
    <Modal
      isOpen={modalAddOpen}
      toggle={() => {
        toggleModalEditor( false, null );
      }}
      className="modal-dialog-centered"
      size="lg"
    >
      <div className="modal-body">
        <EditRegionContainer
          elementToEdit={elementToEdit}
          onClose={() => {
            toggleModalEditor( false, null );
          }}
          onReloadData={() => {
            toggleModalEditor( false, null );
            reload();
          }}
        />
      </div>
    </Modal>
  </>
);

export default RegionsView;
