import React from 'react';
import { Modal } from 'reactstrap';
import classnames from 'classnames';

import { Intl } from 'components';

export default React.memo( ( {
  title, translateTitle = true, open, children, footer, isLoading, name,
  size = 'lg', bodyClassName, onClose,
} ) => (
  <Modal
    className="modal-dialog-centered app-modal"
    size={size}
    isOpen={open}
    toggle={onClose}
    contentClassName={classnames( {
      'app-component-loading': isLoading,
    } )}
    data-cy={`modal-${name}`}
  >
    <div className="modal-header">
      <h6 className="modal-title" id="modal-title-default">
        {translateTitle ? <Intl id={title} /> : title }
      </h6>
      <button
        aria-label="Close"
        className="close text-sm"
        data-dismiss="modal"
        type="button"
        onClick={onClose}
        data-cy="modal-close-btn"
      >
        <i className="app-icon-close" />
      </button>
    </div>
    <div className={classnames( 'modal-body py-0', bodyClassName )}>
      {children}
    </div>

    {footer
      ? (
        <div className="modal-footer">
          {footer}
        </div>
      ) : null}
  </Modal>
) );
