import React from 'react';
import classnames from 'classnames';

export default React.memo( ( { className, title, onClick } ) => (
  <span
    className={classnames( 'text-black text-bold cursor-pointer', className )}
    onClick={() => onClick()}
    data-cy="table-edit-btn"
  >
    {title}
  </span>
) );
