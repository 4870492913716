import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  TextField,
  EditCard,
  Switch,
  Intl,
  Dropzone,
  TranslationLayout,
} from 'components';

export default React.memo( () => (
  <EditCard title="customerApp.appliancesTutorials.title" cardClass="card-sec-bottom">
    <TranslationLayout
      translationFields={( locale ) => (
        <Row>
          <Col md={6}>
            <TextField
              field={`translations.${locale}.appliancesTutorials.title`}
              label="customerApp.basicModule.title"
            />
          </Col>
          <Col md={6}>
            <TextField
              field={`translations.${locale}.appliancesTutorials.subtitle`}
              label="customerApp.basicModule.subtitle"
            />
          </Col>
        </Row>
      )}
    >
      <Row>
        <Col lg>
          <Row>
            <Col md="auto">
              <Dropzone
                field="appliancesTutorials.banner"
                formGroupClass="dz-profile-img"
                label="customerApp.basicModule.banner"
                placeholder={(
                  <>
                    <i className="fa fa-file fa-4x mb-4" />
                    <p>
                      <Intl id="dropzoneFileLimitMsg" />
                    </p>
                  </>
                )}
              />
            </Col>
            <Col>
              <Row>
                <Col md={3}>
                  <Switch
                    field="appliancesTutorials.enabled"
                    label="customerApp.basicModule.enabled"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <TextField
                    field="appliancesTutorials.title"
                    label="customerApp.basicModule.title"
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    field="appliancesTutorials.subtitle"
                    label="customerApp.basicModule.subtitle"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </TranslationLayout>
  </EditCard>
) );
