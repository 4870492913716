import React from 'react';
import Pagination from 'components/Pagination';
import classnames from 'classnames';
import { Intl } from 'components';
import CardActivity from './CardActivity';

const ContactActivityView = ( {
  data, isLoading, page, setPage, elementsPerPage,
} ) => (
  <div
    className={classnames( 'p-4', {
      'app-component-loading': isLoading,
    } )}
    style={{ maxWidth: 820 }}
  >
    {data.data.map( ( month, k ) => (
      <div key={month?.label || k}>
        <h4 className="text-capitalize">{month?.label}</h4>
        {month?.list.map( ( activity, i ) => (
          <CardActivity key={activity?.id || i} data={activity} />
        ) )}
      </div>
    ) )}
    {!data.data.length && !isLoading ? <div className="text-center pb-3 mb-2 border-bottom"><Intl id="noTableData" /></div> : null}
    <Pagination
      page={page}
      setPage={setPage}
      elementsPerPage={elementsPerPage}
      elementsTotal={data.elementsTotal}
    />
  </div>
);

export default ContactActivityView;
