import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PlacesEditViewGeneral from 'views/Places/Edit/General';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import PlacesService from 'api/Places';
import { history } from 'store';
import CityService from 'api/Settings/City';
import PropertyService from 'api/Property';
import useFetch from 'hooks/useFetch';

const PlacesGeneralEdit = ( {
  entityId, data, parentPath, toggleErrorAlert,
  toggleInfoAlert, propertyManager,
} ) => {
  const [loadingData, setLoadingData] = useState( false );

  const submitForm = useCallback( async ( formData, form ) => {
    setLoadingData( true );

    const formattedData = formatDataToSend( formData, form );
    delete formattedData.city;

    const [errors, response] = await handlePromise(
      entityId ? PlacesService.updateItem( entityId, formattedData )
        : PlacesService.saveItem( formattedData ),
    );

    setLoadingData( false );
    if ( !response.ok ) {
      return errors;
    }

    toggleInfoAlert( 'dataSaved' );
    return history.push( parentPath );
  }, [entityId, parentPath, toggleInfoAlert, setLoadingData] );

  /** cities,zones ******************* */

  const [selectedCity, setSelectedCity] = useState( null );

  useEffect( () => {
    if ( data?.zone?.city ) {
      setSelectedCity( data.zone.city );
    }
  }, [data] );

  const [cities, citiesLoading] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        name: item.city.name,
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );
  const [zones, zonesLoading] = useFetch( {
    initialState: [],
    promise: () => CityService.getZones( cities.find(
      ( { id } ) => id === selectedCity,
    )?.id, {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        name: item.name,
      } ) );
      return dataFormated;
    },
    conditional: !!selectedCity && cities.length > 0,
    toggleErrorAlert,
    deps: [selectedCity, cities],
  } );
  /** END cities, zones ******************* */

  /* Properties */
  const [properties, propertiesLoading] = useFetch( {
    initialState: [],
    promise: () => PropertyService.getProperties( {
      elementsPerPage: 500,
    } ),
    format: ( dataToFormat ) => dataToFormat?.data || [],
    toggleErrorAlert,
  } );
  /** END properties */

  return (
    <PlacesEditViewGeneral
      entityId={entityId}
      isLoading={loadingData}
      data={{
        ...( data || {} ),
        ...( selectedCity ? { city: selectedCity } : {} ),
      }}
      parentPath={parentPath}
      //
      onSubmit={submitForm}
      citiesLoading={citiesLoading}
      cities={cities}
      zonesLoading={zonesLoading}
      zones={zones}
      setSelectedCity={setSelectedCity}
      properties={properties}
      propertiesLoading={propertiesLoading}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager.id', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( mapStateToProps, mapDispatchToProps )( PlacesGeneralEdit );
