import React from 'react';
import { Row, Col } from 'reactstrap';

import { AsyncSelect, ModalForm } from 'components';
import { required } from 'config/InputErrors';
import { getResourceName } from 'utils';

export default React.memo( ( {
  participantType, open, onClose, onSubmit, onFetchEmployees,
} ) => (
  <ModalForm
    title={participantType === 'admin' ? 'addAdmin' : 'addMember'}
    showErrorsInFooter
    open={open}
    onSubmit={onSubmit}
    onClose={onClose}
    size="md"
    bodyStyle={{ minHeight: 73 }}
    bodyClassName="overflow-visible"
    body={() => (
      <Row>
        <Col md={9}>
          <AsyncSelect
            field="participant"
            labelKey={getResourceName}
            defaultOptions
            validate={required}
            loadOptions={onFetchEmployees}
          />
        </Col>
      </Row>
    )}
  />
) );
