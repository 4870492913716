import React, { useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import RegisterOfAuthoritiesDashboardView from 'views/RegisterOfAuthorities/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import { useFetchTableData } from 'hooks';
import RegisterOfAuthoritiesService from 'api/RegisterOfAuthoritiesService';

const RegisterOfAuthoritiesDashboard = ( {
  defaultFilters, match,
  updateFilters, setFiltersError,
  toggleErrorAlert,
  userBookingReference,
} ) => {
  const today = moment().startOf( 'day' );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( {
      from,
      till, ...params
    } ) => RegisterOfAuthoritiesService.getRegistersOfAuthorities(
      {
        ...params,
        fromStart: from || today.clone().format( 'YYYY-MM-DD' ),
        tillEnd: till || today.clone().add( 1, 'month' ).format( 'YYYY-MM-DD' ),
      },
    ),
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters(
        'registerOfAuthorities', {
          from: params.from,
          till: params.till,
        },
      );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'registerOfAuthorities' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
  } );

  return (
    <RegisterOfAuthoritiesDashboardView
      data={data}
      parentPath={match.url}
      isLoading={dataLoading}
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
      userBookingReference={userBookingReference}
    />
  );
};

const mapStateToProps = ( { filters, user } ) => ( {
  defaultFilters: _.get( filters, 'filters.registerOfAuthorities' ) || {},
  userBookingReference: _.get( user, 'settings.bookingReference' ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( RegisterOfAuthoritiesDashboard );
