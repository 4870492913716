import api from 'config/Api/Api';

const baseEndpoint = 'translation';

export default {
  getCmsTranslations( id, entity ) {
    return api.get( `cms/${baseEndpoint}/${entity}/${id}` );
  },

  getTaskTypeTranslations( id, entity ) {
    return api.get( `operation/${baseEndpoint}/${entity}/${id}` );
  },

  getSettingsTranslations( id, entity ) {
    return api.get( `settings/${baseEndpoint}/${entity}/${id}` );
  },

  getCommunityRulesTranslations( id, entity ) {
    return api.get( `pms/${baseEndpoint}/${entity}/${id}` );
  },

  getServiceTranslations( id, entity ) {
    return api.get( `services/${baseEndpoint}/${entity}/${id}` );
  },

  getAmenityTranslations( id ) {
    return api.get( `pms/${baseEndpoint}/amenity/${id}` );
  },
  getcustomFieldTranslations( id ) {
    return api.get( `property-managers/${baseEndpoint}/customField/${id}` );
  },
  getEmailSettingsTranslations( id ) {
    return api.get( `property-managers/${baseEndpoint}/emailSettings/${id}` );
  },
  getChatSettingsTranslations( id ) {
    return api.get( `property-managers/${baseEndpoint}/chatSettings/${id}` );
  },
  getCommunicationTemplateTranslations( id ) {
    return api.get(
      `communications/${baseEndpoint}/communicationTemplate/${id}`,
    );
  },
  getNotificationTranslations( id ) {
    return api.get(
      `communications/${baseEndpoint}/scheduled-notification/${id}`,
    );
  },
  getContractSettingTranslations( id ) {
    return api.get( `property-managers/translation/contractSettings/${id}` );
  },
  getPropertyTranslations( id ) {
    return api.get( `pms/${baseEndpoint}/property/${id}` );
  },
  getAppSurvivalTips( id ) {
    return api.get( `cms/translation/appSurvivalTip/${id}` );
  },
};
