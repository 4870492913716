import api from 'config/Api/Api';

const baseEndpoint = '/property-managers';

export default {
  getChatSettings( propertyManagerId ) {
    return api.get( `${baseEndpoint}/${propertyManagerId}/chat-settings` );
  },
  updateChatSettings( propertyManagerId, data ) {
    return api.put( `${baseEndpoint}/${propertyManagerId}/chat-settings`, data );
  },
};
