import React from 'react';

import { TextField, ModalForm, TranslationLayout } from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  categoryId, data, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    initialValues={data}
    title={categoryId ? 'editCategory' : 'newCategory'}
    showErrorsInFooter
    isLoading={isLoading}
    open={open}
    size="sm"
    onClose={onClose}
    onSubmit={canModify( 'cms' ) ? onSubmit : () => {}}
    formEnabled={canModify( 'cms' )}
  >
    <TranslationLayout
      translationFields={( locale ) => (
        <TextField
          field={`translations.${locale}.name`}
          label="name"
        />
      )}
    >
      <TextField
        field="name"
        label="name"
        validate={required}
      />
    </TranslationLayout>
  </ModalForm>
) );
