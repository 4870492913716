import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form,
  TextField,
  FormActionsBtns,
  Select,
  AsyncSelect,
  Button,
  Switch,
  Intl,
} from 'components';
import { email, required, composeValidators } from 'config/InputErrors';
import { billingTypes, taxesTypes, transactionCodes } from 'config/Constants/InvoiceAndTax';
import identificationTypes from 'config/Constants/IdentificationType';

export default React.memo(
  ( {
    data,
    entityId,
    parentPath,
    title,
    formEnabled = true,
    onSubmit,
    onDelete,
    enableDelete = false,
    countriesLoading,
    statesLoading,
    citiesLoading,
    options,
    onChangeCountry,
    onChangeState,
    onFetchAccommodations,
    onFetchPropertyUsers,
  } ) => (
    <div className="p-4">
      <Row className="align-items-center mb-5">
        <Col>
          <h3 className="m-0">
            <Intl id={title} />
          </h3>
        </Col>
        {entityId && enableDelete && (
          <Col xs="auto">
            <Button title="delete" color="danger" onClick={onDelete} />
          </Col>
        )}
      </Row>

      <Form
        initialValues={
          entityId
            ? {
              ...data,
              // CIF_NIF: data['cif-nif'],
            }
            : {}
        }
        onSubmit={onSubmit}
        showErrorsInFooter
        body={( form ) => (
          <>
            <Row>
              <Col md={7}>
                <div className="pr-4">
                  <Row className="mb-3">
                    <Col md={12}>
                      <TextField
                        field="legalName"
                        label="name"
                        validate={required}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={5}>
                      <Select
                        field="identificationType"
                        label="identificationType"
                        // disabled={entityId}
                        validate={required}
                        options={identificationTypes}
                        translateOptions
                      />
                    </Col>
                    <Col md={5}>
                      <TextField
                        field="identificationNumber"
                        label="identificationNumber"
                        // disabled={entityId}
                        validate={required}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <TextField
                        field="address"
                        label="address"
                        validate={required}
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        field="postalCode"
                        label="postalCode"
                        validate={required}
                      />
                    </Col>
                    <Col md={3}>
                      <Switch
                        field="legalPerson"
                        label="legalPerson"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={4}>
                      <Select
                        field="country"
                        label="country"
                        isLoading={countriesLoading}
                        options={options.countries}
                        validate={entityId ? () => null : required}
                        onChange={( countryId ) => {
                          form.change( 'state', null );
                          form.change( 'city', null );
                          onChangeCountry( countryId );
                        }}
                      />
                    </Col>

                    <Col md={4}>
                      <Select
                        field="state"
                        label="state"
                        isLoading={statesLoading}
                        options={options.states}
                        validate={entityId ? () => null : required}
                        onChange={( stateId ) => {
                          form.change( 'city', null );
                          if ( form.getFieldState( 'country' ) ) {
                            const countryId = form.getFieldState( 'country' )
                              .value.id
                              ? form.getFieldState( 'country' ).value.id
                              : form.getFieldState( 'country' ).value;
                            onChangeState( stateId, countryId );
                          }
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <Select
                        field="city"
                        label="city"
                        isLoading={citiesLoading}
                        options={options.cities}
                        validate={entityId ? () => null : required}
                      />
                    </Col>
                  </Row>
                  <hr className="mb-3" />
                  <h3 className="mb-3">
                    <Intl id="TaxesAndInvoices" />
                  </h3>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Select
                        field="billingType"
                        label="billingType"
                        options={billingTypes}
                        validate={required}
                        translateOptions
                      />
                    </Col>
                    <Col md={6}>
                      <Select
                        field="taxesType"
                        label="taxesType"
                        options={taxesTypes}
                        validate={required}
                        translateOptions
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Select
                        field="transactionCode"
                        label="transactionCode"
                        options={transactionCodes}
                        validate={required}
                        translateOptions
                      />
                    </Col>
                    <Col md={6}>
                      <Switch
                        field="allowIssuingInvoices"
                        label="allowIssuingInvoices"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <Row>
                  <Col
                    md={12}
                    className="mb-3"
                    style={{ position: 'relative', zIndex: '9999' }}
                  >
                    <AsyncSelect
                      field="accommodations"
                      label="properties"
                      multiple
                      defaultOptions
                      validate={entityId ? () => null : required}
                      loadOptions={onFetchAccommodations}
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <AsyncSelect
                      style={{ position: 'static' }}
                      field="customers"
                      label="propertyOwnerUsers"
                      multiple
                      defaultOptions
                      validate={entityId ? () => null : required}
                      labelKey={( op ) => `${op.firstName} ${op.lastName}`}
                      loadOptions={onFetchPropertyUsers}
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <TextField
                      field="email"
                      label="propertyOwnerEmail"
                      validate={composeValidators( required, email )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Switch
                      field="activeNotifications"
                      label="propertyOwnerActivateCommunications"
                    />
                  </Col>
                  <Col md={6}>
                    <Switch
                      field="autoReport"
                      label="propertyOwnerActivateAutomaticReports"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        footer={formEnabled ? <FormActionsBtns cancelTo={parentPath} /> : null}
      />
    </div>
  ),
);
