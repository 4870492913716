import React from 'react';
import { connect } from 'react-redux';

import CategoryEditView from 'views/Service/Category/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import ServiceCategoryService from 'api/ServiceCategory';
import TranslationService from 'api/Translation';

const CategoryEdit = ( {
  serviceId, entityId, modalOpened, onCloseModal, onReload,
  toggleLoading, toggleErrorAlert, toggleInfoAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ServiceCategoryService.getServiceCategory( serviceId, entityId ),
    translationPromise: () => TranslationService.getServiceTranslations( entityId, 'serviceCategory' ),
    toggleErrorAlert,
    conditional: !!entityId && modalOpened,
    deps: [entityId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( entityId
      ? ServiceCategoryService.updateServiceCategory( serviceId, entityId, dataToSend )
      : ServiceCategoryService.saveServiceCategory( serviceId, dataToSend ) ),
    toggleLoading,
    toggleInfoAlert,
    deps: [entityId],
    translations: true,
    callback: onReload,
  } );

  return (
    <CategoryEditView
      entityId={entityId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CategoryEdit );
