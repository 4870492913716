import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AutoassignTaskEditView from 'views/AutoassignTask/Edit';
import TaskTypeService from 'api/TaskType';
import AutoassingTasksService from 'api/AutoassignTasks';
import PropertyService from 'api/Property';
import AccommodationService from 'api/Accommodation';
import EmployeeService from 'api/Employee';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import {
  formatDataToSend, formatArrayToSelectValues, handlePromise, getResourceName,
} from 'utils';

const AutoassignTaskEdit = ( {
  taskId, modalOpened, onReload, onCloseModal, toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => AutoassingTasksService.getTask( taskId ),
    format: ( dataToFormat ) => {
      if ( !taskId ) return dataToFormat;
      const formattedData = { ...dataToFormat };
      if ( _.get( formattedData, 'taskType.id' ) ) {
        formattedData.taskType = {
          value: formattedData.taskType.id,
          label: formattedData.taskType.name,
        };
      }
      if ( _.get( formattedData, 'employee.id' ) ) {
        formattedData.employee = {
          value: formattedData.employee.id,
          label: getResourceName( formattedData.employee ),
        };
      }

      formattedData.accommodations = formatArrayToSelectValues(
        dataToFormat.accommodations,
      );

      formattedData.properties = formatArrayToSelectValues(
        dataToFormat.properties,
      );

      return formattedData;
    },
    toggleErrorAlert,
    conditional: !!taskId && modalOpened,
    deps: [taskId, modalOpened],
    reInit: true,
  } );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const fetchProperties = useCallback( ( query ) => PropertyService.getProperties( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ), [],
  );

  const fetchEmployees = useCallback( ( query ) => EmployeeService.getEmployees( {
    elementsPerPage: 10,
    keyword: query,
    employee: true,
  } ), [] );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );
    delete dataToSend.service;

    const [errors, response] = await handlePromise( taskId
      ? AutoassingTasksService.updateTask( taskId, dataToSend )
      : AutoassingTasksService.saveTask( dataToSend ) );

    if ( !response.ok ) return errors;
    if ( !taskId ) {
      toggleLoading( false );
      onReload();
      return toggleInfoAlert( 'dataSaved' );
    }
  }, [taskId, toggleInfoAlert, toggleLoading, onReload] );

  return (
    <AutoassignTaskEditView
      taskId={taskId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
      onFetchProperties={fetchProperties}
      onFetchAccommodations={fetchAccommodations}
      onFetchEmployees={fetchEmployees}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( AutoassignTaskEdit );
