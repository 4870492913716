import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, ModalForm, AsyncSelect, Select,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import { executionPoint, target, bookingType } from 'config/Constants';
import SwitchField from 'components/Form/Switch';
import { bookingTypeAvantio } from 'config/Constants/AutogenerateTask';
import LimitByLocations from './limitByLocation';

export default React.memo(
  ( {
    propertyManager,
    taskId,
    data,
    isLoading,
    open,
    onClose,
    onSubmit,
    taskTypes,
    isLoadingTaskTypes,
    onFetchServices,
    onChangeTarget,
    targetActive,
    onChangeWhen,
    whenActive,
    onChangeService,
    services,
    onFetchExtras,
    isAvantioActive,
    onForTheDayOf,
    forTheDayOf,
    setLimitByLocation,
  } ) => (
    <ModalForm
      initialValues={data}
      title={taskId ? 'editRule' : 'newRule'}
      showErrorsInFooter
      isLoading={isLoading}
      bodyClassName="overflow-visible"
      open={open}
      onClose={onClose}
      onSubmit={canModify( 'task_type' ) ? onSubmit : () => {}}
      formEnabled={canModify( 'task_type' )}
    >
      <Row>
        <Col md={6}>
          <Select
            field="taskType"
            label="taskType"
            options={taskTypes}
            isLoading={isLoadingTaskTypes}
            optgroup
          />
        </Col>
        <Col md={6}>
          <TextField
            field="description"
            label="description"
            type="text"
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Select
            field="executionPoint"
            label="when"
            validate={required}
            translateOptions
            options={executionPoint}
            onChange={onChangeWhen}
          />
        </Col>
        {whenActive && whenActive !== 'instant' && (
          <Col md={2}>
            <TextField field="whenPeriod" label="days" type="number" />
          </Col>
        )}
        <Col md={3}>
          <Select
            field="target"
            label="target"
            validate={required}
            translateOptions
            options={target}
            onChange={onChangeTarget}
          />
        </Col>
        <Col md={4}>
          {targetActive && targetActive === 'task' && (
            <Select
              field="targetReference"
              label="ofACategory"
              options={taskTypes}
              isLoading={isLoadingTaskTypes}
              optgroup
            />
          )}
          {targetActive && targetActive === 'bookedService' && (
            <AsyncSelect
              field="service"
              label="of"
              defaultOptions
              loadOptions={onFetchServices}
              onChange={onChangeService}
            />
          )}
          {targetActive && targetActive === 'bookedAccommodation' && (
            <Select
              field="targetReference"
              label="forTheDayOf"
              validate={required}
              translateOptions
              options={isAvantioActive ? bookingTypeAvantio : bookingType}
              onChange={onForTheDayOf}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {targetActive && targetActive === 'bookedService' && services && (
            <Select
              label="services"
              field="targetReference"
              options={services}
            />
          )}
        </Col>
      </Row>
      {isAvantioActive && targetActive === 'bookedAccommodation' && (
        <>
          {forTheDayOf === 'period' && (
            <Row>
              <Col md={3}>
                <TextField
                  field="duration"
                  label="durationMin"
                  type="number"
                  validate={required}
                />
              </Col>
              <Col md={3}>
                <TextField
                  field="durationMax"
                  label="durationMax"
                  type="number"
                />
              </Col>
              <Col md={6}>
                <TextField
                  field="period"
                  label="period"
                  type="number"
                  validate={required}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={3}>
              <AsyncSelect
                field="extra"
                label="extra"
                defaultOptions
                loadOptions={onFetchExtras}
                idKey="extraId"
                labelKey="extraName"
                isClearable
              />
            </Col>
            <Col>
              <SwitchField field="owner" label="owner" />
            </Col>
          </Row>

        </>
      )}
      <LimitByLocations
        propertyManager={propertyManager}
        onChange={setLimitByLocation}
        dataTask={data}
      />
    </ModalForm>
  ),
);
