import api from 'config/Api/Api';

const baseEndpoint = '/posts/rss';

export default {
  getRSSsources( params ) {
    return api.get( baseEndpoint, params );
  },
  getRSSsource( id, params ) {
    return api.get( `${baseEndpoint}/${id}`, params );
  },
};
