import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import filterBuilder, { sortByBuilder } from './filterBuilder';
import getPropertyManager from '../utils/getPropertyManager';

const GET_BUSINESS_OPPORTUNITIES = gql`
query GetBusinessOpportunities($limit: Int!, $page: Int!, $propertyManager: String!, $filters: BusinessOpportunityFiltersInput!, $orderBy: [BusinessOpportunityOrderByInput!]!) {
  BusinessOpportunities(page: $page, limit: $limit, propertyManager: $propertyManager, filters: $filters, orderBy: $orderBy) {
    ... on BaseError {
      message
    }
    ... on QueryBusinessOpportunitiesSuccess {     
      data {
        elementsPerPage
        total
        data {
          businessName
          businessStage
          closeDate
          contactId
          createdAt
          createdBy
          id
          state
          updatedAt
          contact {
            name
            id
          }
          pipeline {
            name
            id
          }
        }
      }
    }
  }
}
`;

const getBusinnesOpportunities = async ( { page, elementsPerPage, ...rest } ) => {
  const filters = filterBuilder( rest, [
    'businessName',
    'businessStage',
    'state',
    'closeDateFrom',
    'closeDateTill',
    'from',
    'till',
    'contactId',
    'createdBy',
    'pipelineId',
  ] );
  const orderBy = sortByBuilder( rest );

  const { data } = await client.query( {
    query: GET_BUSINESS_OPPORTUNITIES,
    variables: {
      page: page || 1, // pageCurrent,
      limit: elementsPerPage || 10,
      filters,
      orderBy,
      propertyManager: getPropertyManager(),
    },
    fetchPolicy: 'no-cache',
  } );

  const businessOpportunities = data.BusinessOpportunities.data.data || [];
  const elementsTotal = data.BusinessOpportunities.data.total || 0;

  if ( data.BusinessOpportunities.message ) {
    return {
      ok: false,
      message: data.BusinessOpportunities.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: { data: businessOpportunities, elementsTotal, elementsPerPage },
  };
};

export default getBusinnesOpportunities;
