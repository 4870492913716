/* eslint-disable no-nested-ternary */
import React from 'react';
import classnames from 'classnames';
import { AccomodationOwnerEditForm } from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    entityId,
    isLoading,
    parentPath,
    onSubmit,
    onDelete,
    countriesLoading,
    statesLoading,
    citiesLoading,
    options,
    onChangeCountry,
    onChangeState,
    onFetchAccommodations,
    onFetchPropertyUsers,
  } ) => {
    const canEdit = canModify( 'property_owner' );

    return (
      <div className={classnames( { 'app-component-loading': isLoading } )}>
        <AccomodationOwnerEditForm
          entityId={entityId}
          data={data}
          title={entityId
            ? canEdit
              ? 'editPropertyOwner' : 'propertyOwner' : 'newPropertyOwner'}
          parentPath={parentPath}
          formEnabled={canEdit}
          onSubmit={canEdit ? onSubmit : () => {}}
          enableDelete={canEdit}
          onDelete={onDelete}
          countriesLoading={countriesLoading}
          statesLoading={statesLoading}
          citiesLoading={citiesLoading}
          options={options}
          onChangeCountry={onChangeCountry}
          onChangeState={onChangeState}
          onFetchAccommodations={onFetchAccommodations}
          onFetchPropertyUsers={onFetchPropertyUsers}
        />
      </div>
    );
  },
);
