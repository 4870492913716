import React, { useState } from 'react';
import moment from 'moment';
import {
  Button,
} from 'reactstrap';
import {
  AsyncTable, EditCard, Intl, TableActionsBtns,
} from 'components';
import { canModify } from 'config/Ability';
import Statistics from 'containers/Resource/Edit/Permits/Statistics';
// import ViewShift from './view';

const PermitsView = ( {
  registers,
  settings,
  fetchData,
  dataTable,
  dataLoading,
  onDelete,
} ) => {
  const canEditDelete = canModify( 'resources' );
  const [showDetail, setShowDetail] = useState( false );
  const toggleDetail = () => setShowDetail( !showDetail );
  return (
    <>
      <EditCard cardClass="card-sec-bottom">
        <AsyncTable
          id="resourceShifts"
          data={dataTable.data}
          total={dataTable.elementsTotal}
          isLoading={dataLoading}
          rightContent={(
            <div className="d-flex align-items-center flex-wrap mt-1 mb-1">
              <Button
                color={showDetail ? 'secondaryColor' : 'primary'}
                className="mt-md-0 ml-2 mr-0"
                onClick={toggleDetail}
              >
                <i className="fas fa-file-invoice mr-1" />
                {showDetail
                  ? <Intl id="hideReport" />
                  : <Intl id="showReport" />}
              </Button>
            </div>
          )}
          endContent={(
            <Statistics
              showDetail={showDetail}
              registers={registers}
              settings={settings}
            />
          )}
          disableSorting
          defaultFilters={{}}
          onFetchData={fetchData}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <>
                  <div>
                    {cell.value.slice( 0, 8 )}
                  </div>
                </>
              ),
            },
            {
              Header: 'employee',
              accessor: 'employee',
              Cell: ( { cell } ) => (
                cell.value.name
              ),
            },
            {
              Header: 'from',
              accessor: 'from',
              Cell: ( { cell } ) => (
                cell.value ? moment.utc( cell.value ).format( 'ddd DD/MM/YYYY' ) : ''
              ),
            },
            {
              Header: 'till',
              accessor: 'till',
              Cell: ( { cell } ) => (
                cell.value ? moment.utc( cell.value ).format( 'ddd DD/MM/YYYY' ) : ''
              ),
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => {
                if ( cell.value === 'accepted'
                ) {
                  return <span className="shift-status  shift-status-accepted"><Intl id="shiftAccepted" /></span>;
                }

                if ( cell.value === 'in_demand' ) {
                  return <span className="shift-status  shift-status-pending"><Intl id="shiftPending" /></span>;
                }

                if ( cell.value === 'rejected' ) {
                  return <span className="shift-status  shift-status-rejected"><Intl id="shiftRejected" /></span>;
                }

                return null;
              },
            },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => {
                  const actions = [];
                  actions.push( {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  } );

                  return (
                    <TableActionsBtns actions={actions} />
                  );
                },
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </EditCard>
    </>
  );
};

export default PermitsView;
