import React from 'react';
import { Field } from 'react-final-form';

const HiddenField = React.memo( ( {
  field, value,
} ) => (
  <Field name={field}>
    {( { input } ) => (
      <input
        type="hidden"
        value={value}
        {...input}
      />
    )}
  </Field>
) );

export default HiddenField;
