import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import {
  Modal, Badge,
} from 'reactstrap';
import {
  AsyncTable, AddBtn, Intl, TableActionsBtns,
} from 'components';
import EditChargesContainer from 'containers/PropertyManager/Edit/Finance/Charges/edit';
import { currencyFormat } from 'utils';

const PropertyManagerChargesView = ( {
  pmData,
  fetchData,
  dataTable,
  dataLoading,
  defaultFilters,
  userCurrency,
  reload,
  onDelete,
  toggleModalEditor,
  modalAddOpen,
  elementToEdit,
} ) => (
  <>
    <AsyncTable
      id="feesTable"
      data={dataTable.data}
      total={dataTable.elementsTotal}
      isLoading={dataLoading}
      rightContent={(
        <AddBtn
          title="newCharge"
          onClick={() => {
            toggleModalEditor( true, null );
          }}
        />
      )}
      disableSorting
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
      columns={[
        {
          Header: '#',
          accessor: 'id',
          Cell: ( { cell } ) => ( <a href="/" onClick={( e ) => { e.preventDefault(); toggleModalEditor( true, cell.row.original ); }}>{cell.value.slice( 0, 6 )}</a> ),
        },
        {
          Header: 'description',
          accessor: 'description',
        },
        {
          Header: 'amount',
          accessor: 'amount',
          Cell: ( { cell } ) => ( cell.value ? numeral( ( cell.value ) ).format( currencyFormat( userCurrency ) ) : '' ),
        },
        {
          Header: 'amount',
          accessor: 'amount',
          Cell: ( { cell } ) => ( cell.value ? numeral( ( cell.value ) ).format( currencyFormat( userCurrency ) ) : '' ),
        },
        {
          Header: 'date',
          accessor: 'deadline',
          Cell: ( { cell } ) => ( cell.value

            ? moment( cell.value ).format( 'YYYY-MM-DD' )
            : '' ),
        },
        {
          Header: 'status',
          accessor: 'status',
          Cell: ( { cell } ) => {
            if ( cell.value === 'pending' ) return <Badge color="green-3"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'paid' ) return <Badge color="green-2"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'denied' ) return <Badge color="red-1"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'partial_refunded' ) return <Badge color="warning"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'refunded' ) return <Badge color="info"><Intl id={cell.value} /></Badge>;
            return '';
          },
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  label: 'edit',
                  // onClick: ( ) => onEdit( data ),
                  onClick: () => { toggleModalEditor( true, cell.row.original ); },
                },
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ]}
            />
          ),
        },
      ]}
    />

    <Modal
      isOpen={modalAddOpen}
      toggle={() => {
        toggleModalEditor( false, null );
      }}
      className="modal-dialog-centered"
      size="lg"
    >
      <div className="modal-body">
        <EditChargesContainer
          pmData={pmData}
          elementToEdit={elementToEdit}
          onClose={() => {
            toggleModalEditor( false, null );
          }}
          onReloadData={() => {
            toggleModalEditor( false, null );
            reload();
          }}
        />
      </div>
    </Modal>
  </>
);

export default PropertyManagerChargesView;
