import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const GET_SEASON_OCCUPANCY = gql`
  query SeasonOccupancy($input: DetailSeasonOccupancyInput!) {
    seasonOccupancy(input: $input) {
      ... on BaseError {
        message
      }
      ... on QuerySeasonOccupancySuccess {
        data {
          id
          occupancyId
          isActive
          lengthOfTheStay
          startDate
          lastNight
          checkIn
          checkInDays {
            daysAllowedMonth
            daysAllowedWeek
          }
          checkOut
          checkOutDays {
            daysAllowedMonth
            daysAllowedWeek
          }
        }
      }
    }
  }
`;

const getSeasonOccupancy = async ( seasonOccupancyId ) => {
  const { data } = await client.query( {
    query: GET_SEASON_OCCUPANCY,
    variables: {
      input: {
        seasonOccupancyId,
      },
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.seasonOccupancy.message ) {
    return {
      ok: false,
      message: data.seasonOccupancy.message,
    };
  }
  const seasonOccupancy = data.seasonOccupancy.data;
  return {
    ok: true,
    status: 200,
    data: { data: seasonOccupancy },
  };
};

export default getSeasonOccupancy;
