import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FieldZonesView from 'views/Team/New/FieldZones';
import CityService from 'api/Settings/City';
import SettingsActions from 'store/reducers/Settings';
import ZoneService from 'api/Settings/Zone';

import useFetch from 'hooks/useFetch';

const FieldZones = React.memo(
  ( {
    propertyManager,
    toggleErrorAlert,
    form,
    allowDelete,
    index = 0,
    onDelete,
    data,
    children,
  } ) => {
    const [slectedCity, setSelectedCity] = useState();
    useEffect( () => {
      const citiesId = _.get( data, `cities[${index}].value` );
      setSelectedCity( citiesId );
    }, [data, index] );

    const fetchCities = useCallback(
      () => CityService.getCities( {
        propertyManager: propertyManager.id,
      } ),
      [propertyManager.id],
    );
    const [zones, zonezLoading] = useFetch( {
      initialState: [],
      promise: () => ZoneService.getZones( slectedCity, {
        propertyManager: propertyManager.id,
      } ),
      format: ( dataToFormat ) => _.get( dataToFormat, 'data', [] ),
      conditional: !!slectedCity,
      deps: [slectedCity],
      toggleErrorAlert,
    } );

    const loadZones = ( { value } ) => {
      setSelectedCity( value );
    };

    return (
      <FieldZonesView
        index={index}
        form={form}
        fieldCity={`cities[${index}]`}
        fieldZone={`zones[${index}]`}
        onFetchCities={fetchCities}
        onChangeCity={loadZones}
        zones={zones}
        isZonesLoading={zonezLoading}
        allowDelete={allowDelete}
        onDelete={onDelete}
      >
        {children}
      </FieldZonesView>
    );
  },
);

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( FieldZones );
