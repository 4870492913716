import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import filterBuilder, { sortByBuilder } from './filterBuilder';
import getPropertyManager from '../utils/getPropertyManager';

const GET_EMAILS = gql`
query GetEmails($limit: Int!, $page: Int!, $propertyManager: String!, $orderBy: [EmailOrderByInput!]!, $filters: EmailFiltersInput!) {
  Emails(filters: $filters, orderBy: $orderBy, limit: $limit, propertyManager: $propertyManager, page: $page) {
    ... on BaseError {
      message
    }
    ... on QueryEmailsSuccess {
      data {
        total
        data {
          createdAt
          id
          status
          subject
          sender
          contactId
        }
      }
    }
  }
}
`;

const getEmails = async ( { page, elementsPerPage, ...rest } ) => {
  const filters = filterBuilder( rest, ['contactId', 'from', 'till', 'status', 'subject'] );
  const orderBy = sortByBuilder( rest );

  const { data } = await client.query( {
    query: GET_EMAILS,
    variables: {
      page: page || 1, // pageCurrent,
      limit: elementsPerPage || 10,
      filters,
      orderBy,
      propertyManager: getPropertyManager(),
    },
    fetchPolicy: 'no-cache',
  } );

  const emails = data.Emails.data.data || [];
  const elementsTotal = data.Emails.data.total || 0;

  if ( data.Emails.message ) {
    return {
      ok: false,
      message: data.Emails.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: { data: emails, elementsTotal, elementsPerPage },
  };
};

export default getEmails;
