import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import { Intl } from 'components';

export const Menu = ( menuList, selected, pathLayout, pathname, notTranslated ) => _.map(
  menuList, ( { path, name } ) => ( pathLayout ? (
    <Link
      key={name}
      to={pathLayout + path}
      className={classnames( 'menu-item-link', {
        active: pathLayout + path === pathname || ( selected === name ),
      } )}
    >
      {notTranslated ? name : ( <Intl id={name} /> )}
    </Link>
  ) : (
    <span
      key={name}
      className={classnames( 'menu-item-link',
        { active: selected === name } )}
    >
      {notTranslated ? name : ( <Intl id={name} /> )}
    </span>
  ) ),
);

const ArrowLeft = (
  <div className="arrow-prev">
    <i className="fa fa-chevron-left" />
  </div>
);
const ArrowRight = (
  <div className="arrow-next">
    <i className="fa fa-chevron-right" />
  </div>
);

export default React.memo(
  ( {

    tabs, selected, pathLayout, pathname, onChangeTab, notTranslated,
  } ) => (
    <ScrollMenu
      alignCenter={false}
      data={Menu( tabs, selected, pathLayout, pathname, notTranslated )}
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
      selected={selected}
      hideSingleArrow
      onSelect={( index ) => ( onChangeTab ? onChangeTab( index ) : {} )}
    />
  ),
);
