/* eslint-disable import/prefer-default-export */
const initializeType = ( data ) => {
  if ( !data ) {
    return 'text';
  }
  if ( !data.documentCheckInInstruction ) {
    return 'text';
  }
  if ( data.documentCheckInInstruction.indexOf( 'https://' ) < 0 ) {
    return 'text';
  }
  return 'document';
};

const initializeDocument = ( data ) => {
  if ( !data ) {
    return undefined;
  }
  if ( data.documentCheckInInstruction ) {
    const docsplit = data.documentCheckInInstruction.split( '/' );
    const docName = `${docsplit[docsplit.length - 1]}.pdf`;
    return { name: docName };
  }
  return undefined;
};

export { initializeType, initializeDocument };
