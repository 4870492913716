import React from 'react';
import {
  Row, Col, Container, Button,
} from 'reactstrap';

import {
  AsyncTable, DaterangePickerBtn, Intl, SimpleHeader, TableSelectFilter,
} from 'components';
import moment from 'moment';
import { getResourceName } from 'utils';

export default React.memo( ( {
  data, isLoading, defaultFilters, onFetchData, initialDates,
  onFetchProperties, onFetchAccommodations, onFetchCustomers, onTodaysAccessControl,
  onDateChanged,
} ) => (
  <>
    <SimpleHeader
      title="accessControl"
      rightContent={(
        <>
          <Button
            color="primary"
            className="mt-2 mt-md-0 ml-2 mr-0"
            onClick={onTodaysAccessControl}
          >
            <i className="th-icon-calendar mr-2" style={{ position: 'relative', top: 3 }} />
            <Intl id="today" />
          </Button>

          <DaterangePickerBtn
            position="right"
            initialValue={initialDates}
            key={initialDates}
            onChange={onDateChanged}
          />
        </>
        )}
    />
    <Container fluid>
      <AsyncTable
        id="accessControl"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        onFetchData={onFetchData}
        customFilters={( { filters, setFilter } ) => (
          <Row>
            <Col md={8} lg={3}>
              <TableSelectFilter
                name="property"
                label="property"
                placeholder="all"
                filters={filters}
                isClearable
                setFilter={setFilter}
                defaultOptions
                loadOptions={onFetchProperties}
              />
            </Col>
            <Col md={8} lg={3} className="pl-lg-0 pt-1 pt-lg-0">
              <TableSelectFilter
                name="accommodation"
                label="accommodation"
                placeholder="all"
                filters={filters}
                isClearable
                defaultOptions
                loadOptions={onFetchAccommodations}
                setFilter={setFilter}
              />
            </Col>
            <Col md={8} lg={3} className="pl-lg-0 pt-1 pt-lg-0">
              <TableSelectFilter
                name="customer"
                label="customer"
                placeholder="all"
                filters={filters}
                isClearable
                defaultOptions
                labelKey={getResourceName}
                loadOptions={onFetchCustomers}
                setFilter={setFilter}
              />
            </Col>
          </Row>
        )}
        columns={[
          {
            Header: 'date',
            accessor: 'created',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  moment.utc( cell.value ).format( 'YYYY-MM-DD HH:mm' )
                ) : ''
            ),
          },
          {
            Header: 'provider',
            accessor: 'provider',
          },
          {
            Header: 'accommodation',
            accessor: 'accommodation',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  cell.value.name
                ) : ''
            ),
          },
          {
            Header: 'property',
            accessor: 'property',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  cell.value.name
                ) : ''
            ),
          },
          {
            Header: 'lockerId',
            accessor: 'lockerId',
          },
          {
            Header: 'action',
            accessor: 'action',
          },
          {
            Header: 'response',
            accessor: 'response',
          },
          {
            Header: 'user',
            accessor: 'user',
            Cell: ( { cell } ) => (
              cell.value && cell.value.firstName
                ? (
                  `${cell.value.firstName} ${cell.value.lastName}`
                ) : ''
            ),
          },
        ]}
      />
    </Container>
  </>
) );
