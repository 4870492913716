import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const GET_OCCUPANCY = gql`
  query Occupancy($input: DetailOccupancyInput!) {
    occupancy(input: $input) {
      ... on BaseError {
        message
      }
      ... on QueryOccupancySuccess {
        data {
          accommodationsNumber
          id
          isActive
          lastModification
          minimunNights
          name
          propertyManagerId
          validUntil
        }
      }
    }
  }
`;

const getOccupancy = async ( { occupancyId } ) => {
  const { data } = await client.query( {
    query: GET_OCCUPANCY,
    variables: {
      input: {
        occupancyId,
      },
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.occupancy.message ) {
    return {
      ok: false,
      message: data.occupancy.message,
    };
  }
  const occupancy = data.occupancy.data;
  return {
    ok: true,
    status: 200,
    data: { data: occupancy },
  };
};

export default getOccupancy;
