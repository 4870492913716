import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { placeRoutes } from 'config/Routes';
import PlacesEditView from 'views/Places/Edit';
import SettingsActions from 'store/reducers/Settings';
import PlacesService from 'api/Places';
import useFetch from 'hooks/useFetch';

const PlacesEdit = ( {
  parentPath, match, location, toggleErrorAlert,
} ) => {
  const entityId = match.params.id;
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [data, loadingData] = useFetch( {
    promise: () => PlacesService.getItem( entityId ),
    toggleErrorAlert,
    conditional: !!entityId,
    deps: [entityId, dataSubmitted],
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  return (
    <PlacesEditView
      data={data}
      entityId={entityId}
      parentPath={parentPath}
      tabs={placeRoutes}
      pathLayout={match.url}
      pathname={location.pathname}
      isLoading={loadingData}
      onReloadData={reloadData}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( PlacesEdit );
