import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import StateDashboardView from 'views/Country/State/Dashboard';
import StateService from 'api/State';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import StateEdit from '../Edit';

const StateDashboard = ( { toggleErrorAlert, countryId, countryData } ) => {
  const toEditId = useRef( null );
  const [editModalOpened, setEditModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setEditModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setEditModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [closeEditModal] );

  const [data, dataLoading] = useFetch( {
    initialState: [],
    promise: () => StateService.getStates( countryId ),
    toggleErrorAlert,
    deps: [countryId, dataSubmitted],
  } );

  return (
    <>
      <StateDashboardView
        data={data}
        countryName={countryData.name}
        isLoading={dataLoading}
        countryId={countryId}
        onOpenEditModal={openEditModal}
      />

      <StateEdit
        stateId={toEditId.current}
        countryId={countryId}
        modalOpened={editModalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( StateDashboard );
