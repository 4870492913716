import React, { useCallback, useState } from 'react';
import { useDelete } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import OccupancySeasonsView from 'views/PropertyManager/Edit/Occupancies/EditDrawer/Seasons';
import { connect } from 'react-redux';
import { deleteSeasonOccupancy } from 'api/Occupancy/SeasonOccupancy';

const OccupancySeasons = ( {
  occupancyId, onReloadOccupancies, toggleErrorAlert, toggleLoading,
} ) => {
  const [modalOpen, setModalOpen] = useState( false );
  const [selectedId, setSelectedId] = useState();
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [initialData, setInitialData] = useState();
  const onOpenModal = ( id ) => {
    setSelectedId( id );
    setModalOpen( true );
  };

  const onOpenNewModal = ( data ) => {
    setInitialData( data );
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setSelectedId();
    setModalOpen( false );
  };

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
    onReloadOccupancies();
    onCloseModal();
  }, [onReloadOccupancies] );

  const onDeleteItem = useDelete( {
    promise: ( itemId ) => deleteSeasonOccupancy( itemId ),
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const viewTypes = [
    { id: 1, name: 'season', value: 'season' },
    { id: 2, name: 'month', value: 'month' },
    { id: 3, name: 'week', value: 'week' },
    { id: 4, name: 'day', value: 'day' },
  ];

  return (
    <>
      <OccupancySeasonsView
        occupancyId={occupancyId}
        initialData={initialData}
        seasonId={selectedId}
        viewTypes={viewTypes}
        open={modalOpen}
        dataSubmitted={dataSubmitted}
        onReloadData={reloadData}
        onDeleteItem={onDeleteItem}
        onOpenNewModal={onOpenNewModal}
        onCloseModal={onCloseModal}
        onOpenEditItemModal={onOpenModal}
        onClose={onCloseModal}
        onReloadOccupancies={onReloadOccupancies}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( OccupancySeasons );
