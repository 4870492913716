import React, { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Dropzone } from 'components';

const ImagesDropzone = React.memo( ( {
  form, onAddImage, onDeleteImage,
} ) => {
  const deleteLocalImage = useCallback( ( index ) => {
    const images = [...form.getFieldState( 'images' ).value];
    images.splice( index, 1 );
    form.change( 'images', images );
  }, [form] );

  return (

    <Row className="mb-2">
      {form.getFieldState( 'images' ) && form.getFieldState( 'images' ).value
        ? _.map( form.getFieldState( 'images' ).value, ( item, index ) => (
          <Col xs="auto" className="pr-0" key={index}>
            <span className="avatar avatar-xxl bg-transparent">
              <img src={item.fileDecoded ? item.fileDecoded : item} alt="..." className="dz-preview-img" />
            </span>
            <i
              className="app-icon-close dz-task-attachment-remove cursor-pointer"
              onClick={( e ) => {
                e.stopPropagation();
                if ( item.fileDecoded ) {
                  deleteLocalImage( index );
                } else {
                  onDeleteImage( index );
                }
              }}
            />
          </Col>
        ) ) : null}

      <Col xs="auto">
        <Dropzone
          field="images"
          multiple
          preview={false}
          placeholder={<i className="app-icon-plus text-xl" />}
          formGroupClass="dz-task-attachment"
          onChange={( files ) => {
            if ( onAddImage ) onAddImage( files );
          }}
        />
      </Col>
    </Row>
  );
} );

const mapStateToProps = ( { user } ) => ( {
  userId: _.get( user, 'user.id' ),
} );

export default connect( mapStateToProps, null )( ImagesDropzone );
