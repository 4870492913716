import React from 'react';
// import DashboardView from 'views/Property/RoomType/Accommodation/Edit/Discounts/Dashboard';
import { Calendar, AddBtn, EditCard } from 'components';
import classnames from 'classnames';
import { Row } from 'reactstrap';

const Dashboard = ( {
  data, onOpenEditItemModal, handleDatesSet, onOpenNewModal, isLoading, onOpenBlockedModal,
} ) => (
  <div className={classnames( { 'app-component-loading': isLoading } )}>
    <EditCard cardClass="card-sec-bottom">
      <Row className="justify-content-end">
        <div className="pt-1 pr-2"><AddBtn title="newBooking" onClick={onOpenNewModal} /></div>
        <div className="pt-1 pr-2"><AddBtn title="newBookingBlocked" onClick={onOpenBlockedModal} /></div>
      </Row>
      <Calendar
        events={data}
        onOpenEditItemModal={onOpenEditItemModal}
        handleDatesSet={handleDatesSet}
        onOpenModal={onOpenNewModal}
        onOpenBlockedModal={onOpenBlockedModal}
      />
    </EditCard>
  </div>
);

export default Dashboard;
