import api from 'config/Api/Api';

const baseEndpoint = '/travel-report-authority';

export default {
  getAlltravelReportAuthority( params ) {
    return api.get( baseEndpoint, params );
  },
  getTravelReportAuthority( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  saveTravelReportAuthority( data ) {
    return api.post( baseEndpoint, data );
  },
  updateTravelReportAuthorit( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  deleteTravelReportAuthority( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
