import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import classnames from 'classnames';
import _ from 'lodash';

import {
  SimpleHeader, DetailCard, ResourceAvatar, Intl,
} from 'components';
import moment from 'moment';

export default React.memo( ( { data, isLoading, parentPath } ) => (
  <>
    <SimpleHeader parentLinks={[{ name: 'community', href: parentPath }]} />

    <Container
      fluid
      className={classnames( { 'app-component-loading': isLoading } )}
    >
      <Row>
        <Col xl={6} md={8}>
          <DetailCard
            borderless
            header={(
              <Row>
                <Col md={9}>
                  <h4 className="mb-1 text-uppercase">{data.name}</h4>
                </Col>
                <Col
                  md={3}
                  className="text-right font-italic text-gray-2 text-sm"
                >
                  {data.date
                    ? moment.utc( data.date ).format( 'ddd DD/MM/YYYY HH:mm' )
                    : ''}
                </Col>
              </Row>
            )}
          >
            <p>{data.description}</p>
            <Row className="mb-5">
              <Col md={4}>
                <p className="mb-2 font-weight-bold">
                  <Intl id="creator" />
                </p>
                <ResourceAvatar data={data.owner} />
              </Col>
              <Col md={4}>
                <p className="mb-2 font-weight-bold">
                  <Intl id="capacity" />
                </p>
                {data.capacity}
              </Col>
              <Col md={4}>
                <p className="mb-2 font-weight-bold">
                  <Intl id="city" />
                </p>
                {_.get( data, 'city.name' )}
              </Col>

              {/* <Col className="pt-3 pt-lg-0">
                <p className="mb-2 font-weight-bold">
                  <Intl id="status" />
                </p>
                Published
              </Col> */}
            </Row>
            <Row>
              <Col md={6}>
                <p className="mb-1 font-weight-bold">
                  <Intl id="meetingPoint" />
                </p>
                <div className="small">{data.meetingPoint}</div>
              </Col>
              {_.get( data, 'properties' ) ? (
                <Col md={6}>
                  <p className="mb-1 font-weight-bold">
                    <Intl id="properties" />
                  </p>
                  {_.get( data, 'properties' ).map( ( p ) => (
                    <div key={p.id} className="small">
                      {p.name}
                    </div>
                  ) )}
                </Col>
              ) : null}
            </Row>
          </DetailCard>
        </Col>
        <Col xl={6} md={4} className="mt-3 mt-md-0">
          <DetailCard
            borderless
            classNames={{ header: 'text-uppercase' }}
            title="joinedCustomers"
          >
            <Row>
              {_.map( data.participants, ( participant ) => (
                <Col key={participant.id} xs="auto" className="pb-4">
                  <ResourceAvatar data={participant} />
                </Col>
              ) )}
            </Row>
          </DetailCard>
        </Col>
      </Row>
    </Container>
  </>
) );
