import React, { useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Intl, CanAccess, ResourceAvatar } from 'components';
import { Button, Col, Row } from 'reactstrap';
import { getSlotSchedule } from 'utils';

const ViewShift = ( { shiftToView, toggleModalView, setEdit } ) => {
  const data = useMemo( () => {
    if ( !shiftToView ) {
      return null;
    }

    return {
      id: shiftToView.id,
      name: _.get( shiftToView, 'team.name' ),
      shiftTemplateName: _.get( shiftToView, 'shiftTemplateName' ),
      date: shiftToView.date ? moment.utc( shiftToView.date ).format( 'DD/MMM/YYYY' ) : '',
      hour: getSlotSchedule( shiftToView ),
      manager: _.get( shiftToView, 'team.manager' ),
      admins: _.get( shiftToView, 'team.admins', [] ),
      location: shiftToView.location ? `${_.get( shiftToView, 'location.name' )} (${_.get( data, 'location.address' )})` : null,
    };
  }, [shiftToView] );

  return data ? (
    <div>
      <div className="">
        <Row className="justify-content-between">
          <Col>
            <h4 className="m-0">
              {data.shiftTemplateName}
              {' '}
              {data.date}
              {' - '}
              {data.hour}
            </h4>
          </Col>
          <CanAccess I="edit" a="shift">
            <Col xs="auto">
              <Button color="primary" size="app-sm" onClick={() => { setEdit( data.id ); }}>
                <Intl id="edit" />
              </Button>
            </Col>
          </CanAccess>
        </Row>
        <hr className="mb-3" />
        <Row>
          <Col>
            <p className="mb-0 font-weight-bold">
              <Intl id="team" />
            </p>
            <p>{data.name}</p>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4}>
            <p className="mb-2 font-weight-bold">
              <Intl id="manager" />
            </p>
            <ResourceAvatar data={data.manager} />
          </Col>
          <Col className="pt-3 pt-lg-0">
            <p className="mb-2 font-weight-bold">
              <Intl id="supervisors" />
            </p>
            <Row>
              {_.map( data.admins, ( supervisor ) => (
                <ResourceAvatar key={supervisor.id} data={supervisor} className="mr-2" />
              ) )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="mb-2 pt-3 font-weight-bold">
              <Intl id="starterLocation" />
            </p>
            {data.location ? (
              <p>
                {data.location}
              </p>
            ) : <Intl id="notDefined" />}
          </Col>
        </Row>
        <hr className="mb-3" />
        <div className="text-center">
          <Button color="primary" outline onClick={toggleModalView}>
            <Intl id="close" />
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ViewShift;
