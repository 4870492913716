import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount($discountId: ID!, $name: String!) {
    updateDiscount(discountId: $discountId, name: $name) {
      ... on BaseError {
        message
      }
      ... on MutationUpdateDiscountSuccess {
        data {
          success
        }
      }
    }
  }
`;

const updateDiscount = async ( { name, discountId } ) => {
  const { data } = await client.query( {
    query: UPDATE_DISCOUNT,
    variables: {
      name,
      discountId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateDiscount.message ) {
    return {
      ok: false,
      message: data.updateDiscount.message,
    };
  }
  const updateSuccessful = data.updateDiscount.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateDiscount;
