import api from 'config/Api/Api';

const baseEndpoint = '/property-managers';
const baseEndpointSettings = '/settings';

export default {
  getPropertyManagers( params ) {
    return api.get( baseEndpoint, params );
  },

  getPropertyManagerByWorkspace( id ) {
    return api.get( `${baseEndpoint}/${id}/id` );
  },

  getPropertyManager( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  savePropertyManager( data ) {
    return api.post( baseEndpoint, data );
  },

  updatePropertyManager( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  //
  // Fees
  getFeeList( id, params ) {
    return api.get( `${baseEndpoint}/${id}/fee-chosen`, params );
  },
  postFee( id, params ) {
    return api.post( `${baseEndpoint}/${id}/fee-chosen`, params );
  },
  putFee( id, feeId, params ) {
    return api.put( `${baseEndpoint}/${id}/fee-chosen/${feeId}`, params );
  },
  deleteFee( id, feeId ) {
    return api.delete( `${baseEndpoint}/${id}/fee-chosen/${feeId}` );
  },
  //
  // Settings
  getSettingPayment( id ) {
    return api.get( `${baseEndpointSettings}/payments/${id}` );
  },
  putSettingPayment( id, params ) {
    return api.put( `${baseEndpointSettings}/payments/${id}`, params );
  },
  getSettingPaymentGateways( params ) {
    return api.get( `${baseEndpointSettings}/payment-gateway`, params );
  },
  //
  // Charges
  getChargesList( id, params ) {
    return api.get( `${baseEndpoint}/${id}/payments`, params );
  },
  postCharge( id, params ) {
    return api.post( `${baseEndpoint}/${id}/payments`, params );
  },
  putCharge( id, chargeId, params ) {
    return api.put( `${baseEndpoint}/${id}/payments/${chargeId}`, params );
  },
  deleteCharge( id, chargeId ) {
    return api.delete( `${baseEndpoint}/${id}/payments/${chargeId}` );
  },
};
