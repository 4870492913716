import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import PropertyDashboardView from 'views/Property/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetch, useFetchTableData } from 'hooks';
import PropertyService from 'api/Property';
import FiltersActions from 'store/reducers/Filters';
import CityService from 'api/Settings/City';
// import CustomFieldService from 'api/Settings/CustomField';

const PropertyDashboard = ( {
  parentPath,
  defaultFilters,
  toggleLoading,
  toggleErrorAlert,
  updateFilters,
  setFiltersError,
  propertyManager,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );
  const [selectedCity, setSelectedCity] = useState();

  const [outerCitiesOptions] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( {
      elementsPerPage: 100,
      propertyManager: propertyManager.id,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        city: item.city.id,
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: PropertyService.getProperties,
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      setSelectedCity( _.get( params, 'city' ) );
      initialReloadError.current = false;
      updateFilters( 'properties', { keyword: params.keyword } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'properties' );
        initialReloadError.current = false;
      }
    },
  } );

  const deleteEntity = useDelete( {
    promise: PropertyService.deleteProperty,
    callback: () => {
      setDataSubmitted( new Date().valueOf() );
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const [cities, loadingCities] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( {
      propertyManager: propertyManager.id,
    } ),
    format: ( toFormat ) => {
      const formatedData = _.map( toFormat.data, ( city ) => ( {
        id: city.city.id,
        name: city.city.name,
      } ) );
      return formatedData;
    },
    toggleErrorAlert,
    conditional: !!propertyManager.id,
    deps: [propertyManager.id],
  } );

  const [zonesOptions] = useFetch( {
    initialState: [],
    promise: () => CityService.getZones( outerCitiesOptions.find(
      ( c ) => c.city === selectedCity,
    ).id, {
      elementsPerPage: 100,
      propertyManager: propertyManager.id,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        name: item.name,
      } ) );
      return dataFormated;
    },
    conditional: !!selectedCity,
    toggleErrorAlert,
    deps: [selectedCity],
  } );

  // const [customFields, loadingCustomFields] = useFetch( {
  //   initialState: [],
  //   promise: () => CustomFieldService.getCustomFields( {
  //     type: 'property',
  //     propertyManager: propertyManager.id,
  //     allowFilter: 'true',
  //   } ),
  //   format: ( toFormat ) => toFormat.data,
  //   toggleErrorAlert,
  //   conditional: !!propertyManager.id,
  //   deps: [propertyManager.id],
  // } );

  return (
    <PropertyDashboardView
      data={data}
      parentPath={parentPath}
      isLoading={dataLoading || loadingCities}
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
      onDelete={deleteEntity}
      optionCities={cities}
      // optionFields={customFields}
      optionZones={zonesOptions}
    />
  );
};

const mapStateToProps = ( { filters, user } ) => ( {
  defaultFilters: _.get( filters, 'filters.properties' ) || {},
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( PropertyDashboard );
