import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const CREATE_BUSINESS_OPPORTUNITY = gql`
mutation CreateBusinessOpportunity($businessName: String!, $contactId: String!, $createdBy: String!, $pipelineId: String!, $state: String!, $closeDate: String!, $propertyManager: String!) {
  createBusinessOpportunity(
    businessName: $businessName
    contactId: $contactId
    createdBy: $createdBy
    pipelineId: $pipelineId
    state: $state
    closeDate: $closeDate
    propertyManager: $propertyManager
  ) {
    ... on BaseError {
      message
    }
    ... on MutationCreateBusinessOpportunitySuccess {
      __typename
      data {
        success
      }
    }
  }
}
`;

const createBusinessOpportunity = async ( props ) => {
  const { data } = await client.query( {
    query: CREATE_BUSINESS_OPPORTUNITY,
    variables: { ...props, propertyManager: getPropertyManager() },
    fetchPolicy: 'no-cache',
  } );

  if ( data.createBusinessOpportunity.message ) {
    return {
      ok: false,
      message: data.createBusinessOpportunity.message,
    };
  }

  const createSuccessful = data.createBusinessOpportunity.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
    data: { ...data.createBusinessOpportunity.data },
  };
};

export default createBusinessOpportunity;
