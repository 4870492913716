import React, { useRef, useState } from 'react';
import {
  useTable,
  useTableState,
  usePagination,
  useSortBy,
  useFilters,
} from 'react-table';
import _ from 'lodash';
import { connect } from 'react-redux';

import TableUI from '../utils/ContainerTableUI';

const AsyncTable = React.memo(
  ( {
    data,
    id,
    columns,
    total,
    disableSorting = false,
    defaultPageSize = 10,
    useElementsPerPage = true,
    defaultFilters = {},
    Filters,
    onFetchData,
    getRowProps,
    filtersError,
    customFiltersOpened,
    sizeTableToggler,
    ...rest
  } ) => {
    const [filters, setFilters] = useState( defaultFilters );
    const filtersErrorRef = useRef( filtersError );
    const customFiltersOpenedRef = useRef( !!customFiltersOpened );
    const tableState = useTableState( { pageSize: defaultPageSize } );
    const [{ pageIndex, sortBy, pageSize }] = tableState;

    React.useEffect( () => {
      const apiParams = {
        page: pageIndex + 1,
        elementsPerPage: pageSize,
      };
      if ( sortBy && sortBy.length ) { apiParams[`sortby[${sortBy[0].id}]`] = sortBy[0].desc ? 'DESC' : 'ASC'; }
      if ( filters ) {
        _.map( filters, ( filter, key ) => {
          apiParams[key] = filter;
        } );
      }
      onFetchData( apiParams );
    }, [onFetchData, pageIndex, pageSize, sortBy, filters] );

    const {
      getTableProps,
      headerGroups,
      page,
      prepareRow,
      gotoPage,
      canPreviousPage,
      previousPage,
      canNextPage,
      nextPage,
      pageCount,
      setPageSize,
    } = useTable(
      {
        data,
        columns: React.useMemo( () => columns, [columns] ),
        disableSorting,
        state: tableState,
        manualFilters: true,
        manualPagination: true,
        manualSorting: true,
        pageCount: Math.ceil( total / pageSize ),
      },
      useFilters,
      useSortBy,
      usePagination,
    );

    const setFilter = ( column, value ) => {
      const newFilter = {};
      newFilter[column] = value;
      if ( pageIndex > 0 ) {
        gotoPage( 0 );
      }
      setFilters( { ...filters, ...newFilter } );
    };

    const setMultipleFilters = ( newFilters ) => {
      if ( pageIndex > 0 ) {
        gotoPage( 0 );
      }
      setFilters( { ...filters, ...newFilters } );
    };

    React.useEffect( () => {
      if (
        filtersError
        && filtersError.key === id
        && filtersError.date !== filtersErrorRef.current.date
      ) {
        setFilters( {} );
      }
      filtersErrorRef.current = filtersError;
    }, [id, filtersError] );

    return (
      <TableUI
        {...rest}
        getTableProps={getTableProps}
        getRowProps={getRowProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageCount={pageCount}
        setPageSize={setPageSize}
        pageSize={pageSize}
        filters={filters}
        totalElements={total}
        setFilter={setFilter}
        setMultipleFilters={setMultipleFilters}
        CustomFilters={Filters}
        customFiltersOpened={customFiltersOpenedRef.current}
        useElementsPerPage={useElementsPerPage}
        sizeTableToggler={sizeTableToggler}
      />
    );
  },
);

const mapStateToProps = ( { filters } ) => ( {
  filtersError: filters.error,
} );

export default connect( mapStateToProps )( AsyncTable );
