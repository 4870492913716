import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import {
  AsyncTable,
  AddBtn,
  SimpleHeader,
  TableGenericFilter,
  TableActionsBtns,
  TableEditBtn,
} from 'components';
import _get from 'lodash/get';

export default React.memo(
  ( {
    data,
    isLoading,
    onFetchData,
    onDelete,
    onOpenEditModal,
  } ) => (
    <>
      <SimpleHeader title="emergencyNumbers" />
      <Container fluid>
        <AsyncTable
          id="usefuls"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          rightContent={( <AddBtn title="newEmergencyNumber" onClick={onOpenEditModal} /> )}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter filters={filters} setFilter={setFilter} />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'name',
              accessor: 'title',
              Cell: ( { cell } ) => (
                <TableEditBtn
                  title={cell.value}
                  onClick={() => onOpenEditModal( cell.row.original )}
                />
              ),
            },
            {
              Header: 'phone',
              accessor: 'text',
            },
            {
              Header: 'country',
              accessor: 'country',
              Cell: ( { cell } ) => _get( cell, 'value.name', '-' ),
            },
            {
              Header: 'city',
              accessor: 'city',
              Cell: ( { cell } ) => _get( cell, 'value.name', '-' ),
            },
            {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => (
                <TableActionsBtns actions={[
                  {
                    label: 'edit',
                    onClick: () => onOpenEditModal( cell.value ),
                  },
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
                />
              ),
            },
          ]}
        />
      </Container>
    </>
  ),
);
