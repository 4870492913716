import React from 'react';
import { Field } from 'react-final-form';
import RichtTextInput from '../utils/RichTextInput';

const RichtTextField = React.memo(
  ( {
    field,
    validate,
    translateValues,
    inputOnChange,
    value,
    ...rest
  } ) => {
    if ( field ) {
      return (
        <Field name={field} validate={validate}>
          {( { input, meta } ) => {
            const isInvalid = ( !!meta.touched && !!meta.error )
              || ( !!meta.touched
                && !meta.dirtySinceLastSubmit
                && !!meta.submitError );
            return (
              <RichtTextInput
                {...input}
                {...rest}
                meta={meta}
                isInvalid={isInvalid}
                inputOnChange={inputOnChange}
              />
            );
          }}
        </Field>
      );
    }
    return (
      <RichtTextInput
        {...rest}
        value={value || ''}
        inputOnChange={inputOnChange}
      />
    );
  },
);

export default RichtTextField;
