import React, { useRef, useState } from 'react';
import {
  Container, Card,
} from 'reactstrap';
import classnames from 'classnames';
import {
  CustomerAppEditForm,
  HorizontalMenu,
  SimpleHeader,
  Button,
} from 'components';
import { canModify } from 'config/Ability';

import tabs from './tabs';

export default React.memo(
  ( {
    data,
    isLoading,
    onSubmit,
    formEnabled = true,
  } ) => {
    const [currentTab, setCurrentTab] = useState( tabs[0].name );
    const formRef = useRef( null );

    return (
      <>
        <SimpleHeader
          title="ownerApp.title"
          rightContent={
            formEnabled && canModify( 'settings' ) ? (
              <Button title="save" size="md" onClick={() => formRef.current.click()} />
            ) : null
          }
        />
        <Container
          fluid
          className={classnames( 'mt-1', { 'app-component-loading': isLoading } )}
        >
          <Card className="card-sec-top border-bottom">
            <HorizontalMenu
              tabs={tabs}
              selected={currentTab}
              onChangeTab={setCurrentTab}
            />
          </Card>
          <CustomerAppEditForm
            data={data}
            onSubmit={onSubmit}
            currentTab={currentTab}
            formRef={formRef}
            tabs={tabs}
          />
        </Container>
      </>
    );
  },
);
