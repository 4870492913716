import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import CityDashboardView from 'views/PropertyManager/Edit/City/Dashboard';
import CityService from 'api/Settings/City';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetch } from 'hooks';
import _ from 'lodash';
import { roles } from 'config/Constants';
import PropertyManagerService from 'api/PropertyManager';
import CityNew from '../New';
import CityZone from '../Zone';

const CityDashboard = ( {
  userRole, propertyManager, parentId, parentPath, pathLayout, location,
  toggleErrorAlert, toggleLoading,
} ) => {
  const cityId = useRef( null );
  const [newModalOpened, setNewModalOpened] = useState( false );
  const [zoneModalOpened, setZoneModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const pmId = userRole === roles.nomadsAdmin.id || userRole === roles.nomadsAdminReadOnly.id
    ? parentId : propertyManager.id;

  const [pmData, pmDataLoading] = useFetch( {
    initialState: {},
    promise: () => PropertyManagerService.getPropertyManager( pmId ),
    toggleErrorAlert,
    conditional: !!pmId,
    deps: [pmId],
  } );

  const toggleNewModal = useCallback( ( ) => {
    setNewModalOpened( !newModalOpened );
  }, [newModalOpened] );

  const toggleZoneModal = useCallback( ( { id } = {} ) => {
    cityId.current = id;
    setZoneModalOpened( !zoneModalOpened );
  }, [zoneModalOpened] );

  const reloadData = useCallback( () => {
    if ( newModalOpened ) toggleNewModal();
    setDataSubmitted( new Date().valueOf() );
  }, [newModalOpened, toggleNewModal] );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => CityService.getCities( { propertyManager: pmData.id, elementsPerPage: 1000 } ),
    toggleErrorAlert,
    deps: [dataSubmitted, pmData],
    conditional: !!pmData.id,
  } );

  const deleteEntity = useDelete( {
    promise: CityService.deleteCity,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <CityDashboardView
        data={data.data || []}
        pmData={pmData}
        userRole={userRole}
        isLoading={dataLoading || pmDataLoading}
        parentPath={parentPath}
        pathLayout={pathLayout}
        pathname={location.pathname}
        onOpenNewModal={toggleNewModal}
        onOpenZonesModal={toggleZoneModal}
        onDelete={deleteEntity}
      />

      <CityNew
        pmId={pmData.id}
        modalOpened={newModalOpened}
        onCloseModal={toggleNewModal}
        onReload={reloadData}
      />

      <CityZone
        pmId={pmData.id}
        cityId={cityId.current}
        modalOpened={zoneModalOpened}
        onCloseModal={toggleZoneModal}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( CityDashboard );
