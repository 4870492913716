import React from 'react';
import { Row, Col } from 'reactstrap';
import { FieldArray } from 'react-final-form-arrays';
import _ from 'lodash';

import { Checkbox, Datepicker } from 'components';

export default React.memo( ( { form } ) => (
  <FieldArray name="timetable">
    {( { fields } ) => (
      fields.map( ( day ) => {
        const dayData = _.get( form.getState().values, day );

        return (
          <Row key={day} className="mb-2">
            <Col md={4}>
              <Checkbox
                formGroupClass="mb-md-0"
                field={`${day}.active`}
                label={dayData.name}
              />
            </Col>
            <Col md={4} className="pr-0">
              <Datepicker
                dateFormat={false}
                timeFormat="H:mm"
                formGroupClass="mb-md-0"
                size="xsm"
                field={`${day}.from`}
                placeholder="from"
              />
            </Col>
            <Col md={4} className="pr-0">
              <Datepicker
                dateFormat={false}
                timeFormat="H:mm"
                formGroupClass="mb-md-0"
                size="xsm"
                field={`${day}.till`}
                placeholder="till"
              />
            </Col>
          </Row>
        );
      } )
    )}
  </FieldArray>
) );
