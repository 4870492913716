import { create } from 'apisauce';

const clientApi = create( {
  baseURL: process.env.REACT_APP_CLIENT_API_URL,
  timeout: 2 * 60000,
} );

clientApi.addResponseTransform( ( response ) => {
  const { ok } = response;

  if ( !ok ) {
    response.errors = 'apiError';
  }
} );

export default clientApi;
