import React from 'react';
import {
  Container, Row, Col, Card,
} from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router';
import classnames from 'classnames';

import { SimpleHeader, HorizontalMenu } from 'components';
import { bookingRoutes } from 'config/Routes';
import BookingMoreInfo from 'containers/Booking/Edit/MoreInfo';
// import { getAccomodationRoutes } from './Utils';

export default React.memo( ( {
  data, isLoading, onReloadData, pathLayout, parentPath, pathname,
} ) => {
  const getRoutes = ( routesList ) => routesList.map( ( route ) => {
    if ( route.component ) {
      return (
        <Route
          exact={route.exact}
          path={pathLayout + route.path}
          render={( props ) => (
            <route.component
              {...props}
              bookingData={data}
              onReloadData={onReloadData}
              pathLayout={pathLayout}
            />
          )}
          key={route.layout + route.path}
        />
      );
    }
    return null;
  } );

  return (
    <Row className="m-0 align-items-stretch">
      <Col xl={9} className="p-0 mb-xl-0 mb-3">
        <SimpleHeader
          parentLinks={[{ name: 'bookings', href: parentPath }]}
        />
        <Container
          fluid
          className={classnames( { 'app-component-loading': isLoading } )}
        >
          <Card className="card-sec-top border-bottom">
            <HorizontalMenu
              tabs={bookingRoutes}
              pathLayout={pathLayout}
              pathname={pathname}
            />
          </Card>
          <Switch>
            {getRoutes( bookingRoutes )}
            <Redirect from="*" to={pathLayout} />
          </Switch>
        </Container>
      </Col>
      <Col xl={3} className="p-0">
        <BookingMoreInfo data={data} />
      </Col>
    </Row>
  );
} );
