import React, {
  useCallback, useEffect, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CommunityRuleDashboardView from 'views/Property/RoomType/Accommodation/Edit/CommunityRule/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';
import AccommodationService from 'api/Accommodation';
import CommunityRuleNew from '../New';

const CommunityRuleDashboard = ( {
  entityId, data, parentIds, onReloadData, toggleErrorAlert, toggleLoading,
} ) => {
  const [communityRules, setCommunityRules] = useState( [] );
  const [newModalOpened, setNewModalOpened] = useState( false );

  useEffect( () => {
    setCommunityRules( _.get( data, 'entity.communityRules', [] ) );
  }, [data] );

  const toggleNewModal = useCallback( ( ) => {
    setNewModalOpened( !newModalOpened );
  }, [newModalOpened] );

  const reloadData = useCallback( async () => {
    if ( newModalOpened ) toggleNewModal();
    onReloadData();
  }, [newModalOpened, toggleNewModal, onReloadData] );

  const deleteEntity = useCallback( async ( id, cb ) => {
    toggleLoading( true );

    const filteredCommunityRules = _.filter( communityRules, ( item ) => item.id !== id );
    // const propertyId = _.find(
    //  data.entity.customFieldsValue, { customField: { name: 'homeselect_propertyId' } } );
    const [errors, response] = await handlePromise( AccommodationService.updateAccommodation(
      parentIds.property, parentIds.roomType, entityId, {
        // homeselect_propertyId: propertyId ? propertyId.value : null,
        communityRules: _.map( filteredCommunityRules, 'id' ),
      },
    ) );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );
    cb();
    reloadData();
  }, [entityId, communityRules, parentIds.property, parentIds.roomType,
    toggleErrorAlert, toggleLoading, reloadData] );

  return (
    <>
      <CommunityRuleDashboardView
        name={data.entity ? data.entity.name : ''}
        data={communityRules}
        onOpenNewModal={toggleNewModal}
        onDelete={deleteEntity}
      />

      <CommunityRuleNew
        data={data}
        entityCommunityRules={communityRules}
        entityId={entityId}
        parentIds={parentIds}
        modalOpened={newModalOpened}
        onCloseModal={toggleNewModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CommunityRuleDashboard );
