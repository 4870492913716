import api from 'config/Api/Api';

const baseEndpoint = '/bookings/blockedAccommodations';

export default {
  getBookingsBlocked( params ) {
    return api.get( baseEndpoint, params );
  },
  getBookingBlocked( bookingId ) {
    return api.get( `${baseEndpoint}/${bookingId}` );
  },

  saveBookingBlocked( data ) {
    return api.post( baseEndpoint, data );
  },

  updateBookingBlocked( bookingId, data ) {
    return api.put(
      `${baseEndpoint}/${bookingId}`,
      data,
    );
  },

  deleteBookingBlocked( bookingId ) {
    return api.delete( `${baseEndpoint}/${bookingId}` );
  },
};
