import Emailvariables from 'config/Constants/Emailvariables';

const formatEmail = ( body = '' ) => {
  let bodyFormated = body;
  Emailvariables.forEach( ( { key, exampleData } ) => {
    bodyFormated = bodyFormated.replaceAll( key, exampleData );
  } );
  return bodyFormated;
};

export default formatEmail;
