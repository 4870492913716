import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EmailSettingsView from 'views/Communications/Client/Settings';
import { useFetch } from 'hooks';
import EmailSettingsService from 'api/Settings/Email';
import TranslationService from 'api/Translation';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';

const EmailSettings = ( {
  propertyManager,
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => EmailSettingsService.getEmailSettings( propertyManager.id ),
    translationPromise: ( responsePromise ) => {
      const id = _.get( responsePromise, 'id', '' );
      return TranslationService.getEmailSettingsTranslations( id );
    },
    toggleErrorAlert,
    conditional: !!propertyManager.id,
    deps: [propertyManager.id],
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const { logo } = formData;
      const translation = true;
      const dataToSend = formatDataToSend( formData, form, translation );
      dataToSend.disableCustomerEmailNotifications = dataToSend.disableCustomerEmailNotifications ? 'true' : 'false';

      const [errors, response] = await handlePromise(
        EmailSettingsService.updateEmailSettings( propertyManager.id, dataToSend ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      if ( typeof logo === 'object' ) {
        const [imageErrors, imageResponse] = await handlePromise(
          EmailSettingsService.updateLogoEmailSettings( propertyManager.id, {
            logo,
            name: logo.name,
          } ),
        );
        if ( !imageResponse.ok ) {
          toggleLoading( false );
          return imageErrors;
        }
      }
      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [propertyManager.id, toggleInfoAlert, toggleLoading],
  );

  return (
    <EmailSettingsView
      data={data}
      isLoading={dataLoading}
      onSubmit={submitForm}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( mapStateToProps, mapDispatchToProps )( EmailSettings );
