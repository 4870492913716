import React from 'react';
import {
  Card, CardBody, Col, Row, Button, CardFooter,
} from 'reactstrap';
import _ from 'lodash';

import {
  Form, Select, CanAccess, Intl, PropertyManagerLayout,
} from 'components';
import placeholder from 'assets/img/brand/plugin-placeholder.png';

export default React.memo( ( {
  data, pmData, userRole, isLoading, parentPath, pathLayout, pathname,
  onFilterData, onInstallPlugin,
} ) => (
  <PropertyManagerLayout
    data={pmData}
    isLoading={isLoading}
    parentPath={parentPath}
    userRole={userRole}
    pathLayout={pathLayout}
    pathname={pathname}
  >
    <Card>
      <CardBody className="py-3">
        <Form
          onSubmit={onFilterData}
          showErrorsInFooter
          body={( form ) => (
            <>
              <Row>
                <Col md={5} lg={3}>
                  <Select
                    field="categories"
                    label="category"
                    multiple
                    isClearable
                    formGroupClass="d-flex align-items-baseline m-0"
                    labelClass="font-weight-bold mr-2"
                    options={[
                      { id: 'MAS', name: 'MAS' },
                      { id: 'CRS', name: 'CRS' },
                      { id: 'SCS', name: 'SCS' },
                    ]}
                    onChange={form.submit}
                  />
                </Col>
                <Col md={5} lg={3}>
                  <Select
                    field="status"
                    label="status"
                    isClearable
                    formGroupClass="d-flex align-items-baseline m-0"
                    labelClass="font-weight-bold mr-2"
                    translateOptions
                    options={[
                      { id: 'true', name: 'installed' },
                      { id: 'false', name: 'notInstalled' },
                    ]}
                    onChange={form.submit}
                  />
                </Col>
              </Row>
            </>
          )}
        />
      </CardBody>
    </Card>
    <Row>
      {_.map( data, ( plugin ) => (
        <Col md={6} lg={3} key={plugin.id} className="mb-3">
          <Card className="m-0 h-100">
            <CardBody className="pb-0">
              <Row>
                <Col className="text-center">
                  <img src={placeholder} style={{ height: 40 }} alt={plugin.name} />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <p className="text-primary text-sm m-0">{_.join( plugin.categories, ', ' )}</p>
                  <p className="font-weight-500 text-gray-800 m-0">{plugin.name}</p>
                  <p className="text-gray-9 text-sm lh-130">
                    {plugin.description}
                  </p>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="border-0 pt-0">
              <Row>
                <Col md>
                  <p className="text-sm text-dark-1 m-0 lh-120">
                    {_.get( plugin, 'owner.firstName', '' )}
                    {' '}
                    {_.get( plugin, 'owner.lastName', '' )}
                  </p>
                  <a
                    className="text-sm"
                    href={_.get( plugin, 'vendor.url', '' )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {_.get( plugin, 'vendor.name', '' )}
                  </a>
                </Col>
                {plugin.installed
                  ? (
                    <Col md="auto">
                      <Button color="gray-3" className="btn-app-sm text-white bg-gray-3">
                        <Intl id="installed" />
                      </Button>
                    </Col>
                  )
                  : (
                    <CanAccess I="add" a="plugin">
                      <Col md="auto">
                        <Button
                          color="primary"
                          className="btn-app-sm"
                          onClick={() => onInstallPlugin( plugin.id )}
                        >
                          <Intl id="install" />
                        </Button>
                      </Col>
                    </CanAccess>
                  )}
              </Row>
            </CardFooter>
          </Card>
        </Col>
      ) )}
    </Row>
  </PropertyManagerLayout>
) );
