import api from 'config/Api/Api';

const baseEndpoint = '/property-managers';

export default {
  getNotifications( pmId, params ) {
    return api.get( `${baseEndpoint}/${pmId}/all-notifications`, params );
  },

  getNotification( id ) {
    return api.get( `${baseEndpoint}/${id}/notifications` );
  },

  sendNotification( pmId, customerId, data ) {
    return api.post( `${baseEndpoint}/${pmId}/send-notification/${customerId}`, data );
  },

  sendOwnerNotification( pmId, accOwnerId, data ) {
    return api.post( `${baseEndpoint}/${pmId}/send-notification/${accOwnerId}/owner`, data );
  },
};
