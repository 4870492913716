import api from 'config/Api/Api';

const baseEndpoint = '/task-types';

export default {
  getTaskTypes( params ) {
    if ( params !== undefined ) {
      // eslint-disable-next-line no-param-reassign
      params.elementsPerPage = 500;
    } else {
      // eslint-disable-next-line no-param-reassign
      params = { elementsPerPage: 500 };
    }
    return api.get( baseEndpoint, params );
  },

  getTaskType( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveTaskType( data ) {
    return api.post( baseEndpoint, data );
  },

  updateTaskType( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteTaskType( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
