import React /* , { useMemo, useState } */ from 'react';
import { Container, Card } from 'reactstrap';
import { /* HorizontalMenu, */ SimpleHeader } from 'components';
import AdminRatesPropertyManagerFees from 'containers/AdminRates/PropertyManager/Fees';

/* const tabs = [
  {
    name: 'general',
    component: <AdminRatesPropertyManagerGeneral />,
  },
  {
    name: 'fees',
    component: <AdminRatesPropertyManagerFees />,
  },
];
 */
/*   const [currentTab, setCurrentTab] = useState( tabs[0].name );

  const currentSection = useMemo( () => tabs.filter( ( t ) => t.name === currentTab )[0]
    .component, [currentTab] ); */
const AdminRatesPropertyManagerView = () => (
  <>
    <SimpleHeader
      title="propertyManager"
    />
    <Container
      fluid
    >
      <Card className="card-sec-top border-bottom">
        {/* <HorizontalMenu
            tabs={tabs}
            selected={currentTab}
            onChangeTab={setCurrentTab}
          />
          <div className="border-top">{currentSection}</div> */}
        <AdminRatesPropertyManagerFees />
      </Card>

    </Container>
  </>
);
export default AdminRatesPropertyManagerView;
