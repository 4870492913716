import React from 'react';
import Drawer from 'rc-drawer';
import moment from 'moment';
import {
  Intl,
} from 'components';
import { Button } from 'reactstrap';
import classNames from 'classnames';

const EmailReaderView = ( {
  open, onClose, data, isLoading, toggleEmailEditModal,
} ) => (
  <Drawer
    open={open}
    placement="right"
    handler={false}
    maskClosable
    level={null}
    onClose={onClose}
    className="zindex-drawer"
    width={900}
  >
    <div className={classNames( 'p-4', {
      'app-component-loading': isLoading,
    } )}
    >
      <div className="mb-2">
        <strong>
          <Intl id="crm.emails.sender" />
          :
        </strong>
        {` ${data?.sender || ''}`}
      </div>
      <div className="mb-2">
        <strong>
          <Intl id="crm.emails.sentDate" />
          :
        </strong>
        {` ${moment( data?.createdAt ).format( 'YYYY-MM-DD' )}`}
      </div>
      <div>
        <strong>
          <Intl id="crm.emails.subject" />
          :
        </strong>
        {` ${data?.subject || ''}`}
      </div>
      <hr className="my-3" />
      {/* eslint-disable-next-line react/no-danger */}
      {isLoading ? null : <div dangerouslySetInnerHTML={{ __html: data?.html || data?.text || '' }} />}
      <hr className="mt-2" />
      <div className="d-flex justify-content-center align-items-center gap-2">
        <Button color="primary" outline onClick={onClose}>
          <Intl id="close" />
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onClose();
            toggleEmailEditModal();
          }}
        >
          <Intl id="crm.emails.new" />
        </Button>
      </div>
    </div>
  </Drawer>
);

export default EmailReaderView;
