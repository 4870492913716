import React, {
  useRef, useState,
} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import AccomodationOwnerDashboardView from 'views/AccomodationOwner/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import {
  useDelete,
  useFetchTableData,
} from 'hooks';
import AccomodationOwnerService from 'api/AccomodationOwner';
import { ConfirmationModal } from 'components';

const AccomodationOwnerDashboard = ( {
  parentPath,
  defaultFilters,
  toggleErrorAlert,
  toggleLoading,
  updateFilters,
  setFiltersError,
} ) => {
  const initialReloadError = useRef( true );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [custommerId, setCustommerId] = useState();
  const [openModalDisable, setOpenModalDisable] = useState( false );
  const toggleModalDisable = () => setOpenModalDisable( ( state ) => !state );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: AccomodationOwnerService.getAccomodationOwners,
    toggleErrorAlert,
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'accomodationOwners', {
        keyword: params.keyword,
      } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'accomodationOwners' );
        initialReloadError.current = false;
      }
    },
    deps: [dataSubmitted],
  } );

  const deleteResource = useDelete( {
    promise: AccomodationOwnerService.deleteAccomodationOwner,
    callback: ( id, response ) => {
      if ( response.data.deleted ) {
        setDataSubmitted( new Date().valueOf() );
      } else {
        setCustommerId( id );
        toggleModalDisable();
      }
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const disableResource = useDelete( {
    promise: AccomodationOwnerService.deleteAccomodationOwner,
    callback: () => {
      toggleModalDisable();
      setDataSubmitted( new Date().valueOf() );
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <AccomodationOwnerDashboardView
        data={data}
        parentPath={parentPath}
        isLoading={dataLoading}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
        onDelete={deleteResource}
      />
      <ConfirmationModal
        open={openModalDisable}
        title="disableModal.confirmation"
        onConfirm={() => disableResource( custommerId )}
        onClose={toggleModalDisable}
      />
    </>
  );
};

const mapStateToProps = ( { user, filters } ) => ( {
  userRole: user.mainRole,
  defaultFilters: _.get( filters, 'filters.accomodationOwners' ) || {},
} );
const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( AccomodationOwnerDashboard );
