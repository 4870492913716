import React from 'react';
import { Intl } from 'components';
import {
  Col, Row,
} from 'reactstrap';

const StatisticsView = ( { statistics, showDetail } ) => (
  <div>
    {showDetail && (
      <Row className="mt-4">
        <Col md={4}>
          <div>
            <strong>
              <Intl id="estimatedTimeH" />
            </strong>
            {`: ${statistics.estimated} `}
          </div>
        </Col>
        <Col md={4}>
          <div>
            <strong>
              <Intl id="elapsedTime" />
            </strong>
            {`: ${statistics.elapsed}`}
          </div>
        </Col>
        <Col md={4}>
          <div>
            <strong>
              <Intl id="deviation" />
            </strong>
            {`: ${statistics.deviation}`}
          </div>
        </Col>

      </Row>
    )}
  </div>
);

export default StatisticsView;
