import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = 'communication-templates';
const baseEndpointActions = '/settings/instant-notifications';

export default {
  getTemplates( params ) {
    return api.get( baseEndpoint, params );
  },
  getTemplate( id, params ) {
    return api.get( `${baseEndpoint}/${id}`, params );
  },
  saveTemplate( data ) {
    return api.post( baseEndpoint, data );
  },
  updateTemplate( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  updateBanner( id, data ) {
    return api.post( `${baseEndpoint}/${id}/banner`, formatFormDataBody( data ) );
  },
  deleteTemplate( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
  getActions( ) {
    return api.get( baseEndpointActions );
  },
};
