import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import classnames from 'classnames';

import {
  SimpleHeader,
  Form,
  TextField,
  FormActionsBtns,
  EditCard,
  Datepicker, CanAccess,
} from 'components';
import { required } from 'config/InputErrors';
import moment from 'moment';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  data, entityId, propertyName, roomTypeName, accommodationName, isLoading, paths, onSubmit,
} ) => {
  const breadcrumb = [];
  if ( paths.property ) {
    breadcrumb.push( { name: propertyName, translate: false, href: paths.property } );
  }
  if ( paths.property ) {
    breadcrumb.push( { name: roomTypeName, translate: false, href: paths.roomType } );
  }

  return (
    <>
      <SimpleHeader
        parentLinks={[
          ...breadcrumb,
          { name: accommodationName, translate: false, href: paths.accommodation },
          { name: 'contracts', href: paths.contract },
        ]}
      />

      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <EditCard title={entityId ? 'editContract' : 'newContract'}>
          <Form
            initialValues={data}
            onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
            showErrorsInFooter
            body={( form ) => (
              <>
                <Row>
                  <Col md={3}>
                    <TextField
                      field="cost"
                      label="cost"
                      type="number"
                      validate={required}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Datepicker
                      field="from"
                      label="startDate"
                      validate={required}
                      dateFormat="MMM DD, YYYY"
                      endDateField={form.getFieldState( 'till' )}
                      formChange={( value ) => {
                        form.change( 'till', moment( value ).add( '1', 'day' ) );
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <Datepicker
                      field="till"
                      label="endDate"
                      validate={required}
                      dateFormat="MMM DD, YYYY"
                      startDateField={form.getFieldState( 'from' )}
                    />
                  </Col>
                </Row>
              </>
            )}
            footer={<CanAccess I="edit" a="accommodation"><FormActionsBtns cancelTo={paths.contract} /></CanAccess>}
          />
        </EditCard>
      </Container>
    </>
  );
} );
