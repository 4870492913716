import api from 'config/Api/Api';
import { formatFormDataBody } from '../utils';

const baseEndpoint = '/channels';

export default {
  getChannels( params ) {
    return api.get( baseEndpoint, params );
  },

  getChannel( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  getChannelHistory( id, params ) {
    return api.get( `${baseEndpoint}/${id}/history`, params );
  },

  sendMessage( id, data ) {
    return api.post( `${baseEndpoint}/${id}/messages`, formatFormDataBody( data ) );
  },

  saveChannel( data ) {
    return api.post( baseEndpoint, data );
  },

  updateChannel( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  addAdmin( id, adminId ) {
    return api.post( `${baseEndpoint}/${id}/administrators/${adminId}` );
  },

  addParticipant( id, participantId ) {
    return api.post( `${baseEndpoint}/${id}/participants/${participantId}` );
  },

  removeParticipant( id, participantId ) {
    return api.delete( `${baseEndpoint}/${id}/participants/${participantId}` );
  },

  removeAdministrator( id, adminId ) {
    return api.delete( `${baseEndpoint}/${id}/administrators/${adminId}` );
  },
};
