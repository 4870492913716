import React from 'react';
import {
  Intl, DetailCard, LocalTable, AddBtn, TableEditBtn, TableActionsBtns,
} from 'components';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

export default React.memo( ( { data, handleOpen, onDelete } ) => (
  <>
    <DetailCard
      classNames={{ header: 'py-2 border-0', body: 'pt-0 pb-2 px-0', card: 'card-sec-bottom' }}
      header={(
        <Row className="justify-content-stretch align-items-center">
          <Col>
            <h3 className="m-0">
              <Intl id="notes" />
            </h3>
          </Col>
          <Col className="text-right">
            <AddBtn title="newNote" onClick={handleOpen} />
          </Col>
        </Row>
    )}
    >
      <LocalTable
        onlyTable
        manualPagination
        data={data.notes || []}
        columns={[
          {
            Header: '#',
            accessor: 'id',
            Cell: ( { cell } ) => ( cell.value

              ? (
                <TableEditBtn
                  className="text-uppercase"
                  title={`#${cell.value.slice( 0, 6 )}`}
                  onClick={() => handleOpen( cell.row.original )}
                />
              )
              : '' ),
          },
          {
            Header: 'notes',
            accessor: 'content',
            Cell: ( { cell } ) => cell.value,
          },
          {
            Header: 'createdAt',
            accessor: 'created',
            Cell: ( { cell } ) => moment( cell.value ).format( 'DD-MM-YYYY hh:mma' ),
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
              />
            ),
          },
        ]}
      />

    </DetailCard>

  </>
) );
