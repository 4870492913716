import moment from 'moment';

const colorTable = {
  primary: 'primary',
  secondary: 'secondary',
  default: 'creme',
  red: 'danger',
  yellow: 'warning',
  green: 'success',
  info: 'info',

  // By date
  past: 'past',
  present: 'present',
  future: 'future',
  blocked: 'blocked',
};

const statusColors = {
  tasks: {
    OPEN: colorTable.default,
    ASSIGNED: colorTable.red,
    IN_PROGRESS: colorTable.yellow,
    RESOLVED: colorTable.green,
    CLOSED: colorTable.green,
  },
  bookings: {
    PENDING: colorTable.red,
    PENDING_VALIDATION: colorTable.yellow,
    NOT_REQUIRED: colorTable.default,
    COMPLETED: colorTable.yellow,
    SUBMITED: colorTable.green,
    CHECKINCOMPLETENO: colorTable.red,
    CHECKINCOMPLETEYES: colorTable.green,
    // pay
    FULL_PAID: colorTable.green,
    HALF_PAID: colorTable.yellow,
    NOT_PAID: colorTable.red,
  },
  bookingGuests: {
    PENDING: colorTable.default,
    PENDING_VALIDATION: colorTable.yellow,
    APPROVED: colorTable.green,
    COMPLETE: colorTable.green,
    REJECTED: colorTable.red,
    APPMOBILEPENDING: colorTable.red,
    APPMOBILEDOWNLOADED: colorTable.green,
  },
};

const getStatusColor = ( type ) => {
  const colors = statusColors[type] || {};

  const o = ( status ) => colors[( status || '' ).toUpperCase()] || colorTable.default;

  o.getByDate = ( date, dateFormat ) => {
    const dateMoment = typeof date === 'string' ? moment( date, dateFormat || 'DD-MM-YYY' ) : date;
    const d = moment().diff( dateMoment, 'days' );

    // eslint-disable-next-line no-nested-ternary
    return d < 0
      ? colorTable.past
      : d > 0
        ? colorTable.future
        : colorTable.present;
  };

  o.colors = colorTable;

  return o;
};

export default getStatusColor;
