import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {
  Intl, Form, TextField, FormActionsBtns,
} from 'components';
import { email, required, composeValidators } from 'config/InputErrors';

const EmailSettings = React.memo( ( { data, onSubmit, isLoading } ) => (
  <Container
    fluid
    className={classnames( { 'app-component-loading': isLoading } )}
  >
    <div className="py-5">
      <Form
        initialValues={data}
        showErrorsInFooter
        onSubmit={onSubmit}
        body={() => (
          <div className="email-settings">
            <h3 className="mb-3">
              <Intl id="OperativeEmployee" />
            </h3>
            <Row className="mb-3">
              <Col md={3}>
                <TextField
                  field="defaultSenderMaintenanceName"
                  label="defaultSenderMaintenanceName"
                />
                <span className="help-text">
                  <Intl id="defaultSenderMaintenanceName.help" />
                </span>
              </Col>

              <Col md={3}>
                <TextField
                  field="defaultSenderMaintenanceEmail"
                  label="defaultSenderMaintenanceEmail"
                  validate={composeValidators( email )}
                />
                <span className="help-text">
                  <Intl id="defaultSenderMaintenanceEmail.help" />
                </span>
              </Col>
              <Col md={3}>
                <TextField
                  field="defaultEmailToMaintenance"
                  label="defaultEmailToMaintenance"
                  validate={composeValidators( required, email )}
                />
              </Col>
              <Col md={3}>
                <TextField
                  field="defaultNameToMaintenance"
                  label="defaultNameToMaintenance"
                />
              </Col>
            </Row>
            <hr />
            <h3 className="mb-3">
              <Intl id="OperativeClient" />
            </h3>
            <Row className="mb-3">
              <Col md={3}>
                <TextField
                  field="defaultSenderName"
                  label="defaultSenderName"
                />
                <span className="help-text">
                  <Intl id="defaultSenderName.help" />
                </span>
              </Col>

              <Col md={3}>
                <TextField
                  field="defaultSenderEmail"
                  label="defaultSenderEmail"
                  validate={composeValidators( required, email )}
                />
                <span className="help-text">
                  <Intl id="defaultSenderEmail.help" />
                </span>
              </Col>
              <Col md={3}>
                <TextField
                  field="defaultEmailToClient"
                  label="defaultEmailToClient"
                  validate={composeValidators( required, email )}
                />
              </Col>
              <Col md={3}>
                <TextField
                  field="defaultNameToClient"
                  label="defaultNameToClient"
                />
              </Col>
            </Row>
          </div>
        )}
        footer={<FormActionsBtns />}
      />
    </div>
  </Container>
) );

export default EmailSettings;
