import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import Fade from 'react-reveal/Fade';

export default React.memo( ( { header, msg, children } ) => (
  <Row className="justify-content-center pt-5">
    <Col lg="5" md="7">
      <Fade duration={1000}>
        <Card className="shadow-none border">
          <CardHeader className="text-center border-0 pb-0">
            {header}
          </CardHeader>
          <CardBody className="pt-2 card-body">
            <div className="text-center">
              <p className="text-gray-8">{msg}</p>
            </div>

            {children}
          </CardBody>
        </Card>
      </Fade>
    </Col>
  </Row>
) );
