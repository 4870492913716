import React from 'react';
import { Button } from 'reactstrap';
import { Intl } from 'components';
import useSendbutton from './useSendButton';

const SendButton = ( { defaultValue, clientId, id } ) => {
  const {
    value,
    loading,
    onClick,
  } = useSendbutton( { defaultValue, clientId, id } );

  return (
    <Button size="sm" outline={value} disabled={loading} onClick={onClick}>
      {loading && <i className="fa fa-spinner fa-pulse mr-2" />}
      <Intl
        id={value ? 'resendReport' : 'sendReport'}
      />
    </Button>
  );
};

export default SendButton;
