import {
  AddBtn, AsyncTable, Intl, SimpleHeader,
} from 'components';
import { canModify } from 'config/Ability';
import React from 'react';
import { Container, UncontrolledTooltip } from 'reactstrap';

const NotificationOwnersView = ( {
  dataTable, dataLoading, fetchData, onNewClick, onRespond,
} ) => {
  const canEditDelete = canModify( 'property_owner' );

  return (
    <>
      <SimpleHeader title="notificationOwners" />
      <Container fluid>
        <AsyncTable
          id="resourceShifts"
          data={dataTable.data}
          total={dataTable.elementsTotal}
          isLoading={dataLoading}
          rightContent={canEditDelete && <AddBtn title="notifyOwner" onClick={onNewClick} />}
          disableSorting
          onFetchData={fetchData}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <>
                  {canEditDelete ? (
                    <a
                      href="/"
                      id={`tooltip-shift-${cell.value}`}
                      onClick={( e ) => {
                        e.preventDefault();
                        onRespond( cell.row.original );
                      }}
                    >
                      {cell.value.slice( 0, 8 )}
                    </a>
                  ) : (
                    <div>
                      {cell.value.slice( 0, 8 )}
                    </div>
                  )}
                  <UncontrolledTooltip
                    delay={0}
                    target={`tooltip-shift-${cell.value}`}
                  >
                    <Intl id="reply" />
                  </UncontrolledTooltip>
                </>
              ),
            },
            {
              Header: 'customer',
              accessor: 'customer',
              Cell: ( { cell } ) => (
                cell.value.firstName
                  ? `${cell.value.firstName} ${cell.value.lastName}`
                  : cell.value.legalName
              ),
            },
            {
              Header: 'subject',
              accessor: 'case',
            },
          ]}
        />
      </Container>
    </>
  );
};

export default NotificationOwnersView;
