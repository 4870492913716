import api from 'config/Api/Api';

const baseEndpoint = '/extra-expense';

export default {
  getExtraExpenses( params ) {
    return api.get( baseEndpoint, params );
  },

  getExtraExpense( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveExtraExpense( data ) {
    return api.post( baseEndpoint, data );
  },

  updateExtraExpense( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteExtraExpense( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
