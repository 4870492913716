import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Intl } from 'components';

const hourListOptions = [];
const minuteListOptions = [];

let d = 0;
while ( d < 24 ) {
  hourListOptions.push( {
    text: d < 10 ? `0${d}` : d,
    value: d,
  } );
  // eslint-disable-next-line no-plusplus
  d++;
}
d = 0;
while ( d < 60 ) {
  minuteListOptions.push( {
    text: d < 10 ? `0${d}` : d,
    value: d,
  } );
  // eslint-disable-next-line no-plusplus
  d++;
}

const HourRow = ( {
  dataRow, index, onUpdate, onDelete,
} ) => {
  const [name, setName] = useState( dataRow.name );
  const [hour, setHour] = useState( dataRow.date.hour );
  const [minute, setMinute] = useState( dataRow.date.minute );

  return (
    <Row className="mb-3 mx--1">
      <Col xs={5} className="px-1">
        <div className="text-bold text-small"><Intl id="name" /></div>
        <input
          type="text"
          className="form-control-sm form-control"
          value={name}
          onChange={( e ) => {
            const v = e.target.value;
            setName( v );
            onUpdate( index, v, { hour, minute } );
          }}
        />
      </Col>
      <Col xs="auto" className="px-1">
        <div className="text-bold text-small">
          <Intl id="hour" />
          {' / '}
          <Intl id="minutes" />
        </div>
        <div className="input-group">
          <select
            className="custom-select custom-select-sm text-right"
            value={hour}
            onChange={( e ) => {
              const v = parseInt( e.target.value, 10 );
              setHour( v );
              onUpdate( index, name, { hour: v, minute } );
            }}
            style={{ paddingRight: 25, width: 55 }}
          >
            {hourListOptions.map( ( h ) => (
              <option
                value={h.value}
                key={h.value}
              >
                {h.text}
              </option>
            ) )}
          </select>
          <select
            className="custom-select custom-select-sm text-right"
            value={minute}
            onChange={( e ) => {
              const v = parseInt( e.target.value, 10 );
              setMinute( v );
              onUpdate( index, name, { hour, minute: v } );
            }}
            style={{ paddingRight: 25, width: 55 }}
          >
            {minuteListOptions.map( ( m ) => (
              <option
                value={m.value}
                key={m.value}
              >
                {m.text}
              </option>
            ) )}
          </select>

        </div>

      </Col>
      <Col xs={1} className="px-1 pt-3">
        <div
          className="p-1 text-danger d-inline-block cursor-pointer"
          onClick={() => {
            onDelete( index );
          }}
        >
          <i className="fa fa-times" />
        </div>
      </Col>
    </Row>
  );
};

/// ///////////////////////////////////////////////

const dateToHourMinuteObj = ( date ) => {
  const arr = date.split( ':' );
  const hour = parseInt( arr[0], 10 );
  const minute = parseInt( arr[1], 10 );
  return {
    hour, minute,
  };
};
const valueToDateData = ( value ) => value.map( ( dateObj ) => {
  const { name, date } = dateObj;
  const objDate = dateToHourMinuteObj( date );
  return {
    name,
    date: objDate,
  };
} );

const HourList = ( {
  value = [], label, onChange, showRequiredError,
} ) => {
  const [list, setList] = useState( [] );
  const [loaded, setLoaded] = useState( false );
  const [deleting, setDeleting] = useState( false );

  useEffect( () => {
    if ( value && value.length && !loaded ) {
      const valueDateData = valueToDateData( value );
      setList( valueDateData );
      onChange( valueDateData );
      setLoaded( true );
    }
  }, [value, loaded, onChange] );

  const onNew = useCallback( ( ) => {
    const listClone = [...list];
    listClone.push( { name: '', date: { hour: 0, minute: 0 } } );
    setList( listClone );
    onChange( listClone );
  }, [list, onChange] );

  const onUpdate = useCallback( ( index, name, date ) => {
    const listClone = [...list];
    listClone[index] = { name, date };
    setList( listClone );
    onChange( listClone );
  }, [list, onChange] );

  const onDelete = useCallback( ( index ) => {
    const listClone = [...list];
    listClone.splice( index, 1 );
    setDeleting( true );
    setList( listClone );
    onChange( listClone );
    setTimeout( () => {
      setDeleting( false );
    }, 100 );
  }, [list, onChange] );

  return (
    <>
      <div>

        {label
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        && (
        <label className="font-weight-bold form-control-label mb-2 d-block">
          <Intl id={label} />
        </label>
        )}
        {!deleting && list.map( ( dataRow, k ) => (
          <HourRow
            dataRow={dataRow}
            index={k}
            // eslint-disable-next-line react/no-array-index-key
            key={k}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        ) )}
        <Button
          size="sm"
          color={list.length === 0 && showRequiredError ? 'danger' : 'secondary'}
          onClick={onNew}
        >
          <Intl id="newHour" />
        </Button>
        {list.length === 0 && showRequiredError ? (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            <Intl id="fieldError.required" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default HourList;
