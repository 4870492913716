/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Col, Row,
} from 'reactstrap';

import {
  CanAccess,
  AsyncTable,
  Intl,
  TableActionsBtns,
  TableGenericFilter,
  TableToggler,
  Switch,
  DaterangePickerBtn,
  AddBtn,
  TableEditBtn,
} from 'components';
import { canModify } from 'config/Ability';
import moment from 'moment';

const filterColumnMd = 6;
const filterColumnLg = 2;
export default React.memo( ( {
  list, onFetchData, dataLoading, onOpenNewItemModal, onOpenEditItemModal,
  onDeleteItem,
} ) => (
  <>
    <h3>
      <Intl id="discounts" />
    </h3>
    <AsyncTable
      id="discounts"
      data={list.data}
      total={list.elementsTotal}
      isLoading={dataLoading}
      disableSorting
      onFetchData={onFetchData}
      customFilters={( { filters, setFilter } ) => (
        <Row>
          <Col md={8} lg={4}>
            <TableGenericFilter
              filters={filters}
              setFilter={setFilter}
            />
          </Col>

          <TableToggler />
        </Row>
      )}
      customToggleFilters={( { filters, setFilter, setMultipleFilters } ) => (
        <Row className="ml-0">

          <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
            <Switch
              label="filterByDate"
              value={filters.filterByDate}
              inputOnChange={( ) => {
                setFilter( 'filterByDate', filters.filterByDate === undefined ? 'true' : undefined );
              }}
            />
          </Col>
          <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
            <DaterangePickerBtn
              label="validBetween"
              position="left"
              input
              clearable
              initialValue={moment.range( filters.tillStart, filters.tillEnd )}
              initialValueSet={!!filters && !!filters.tillStart && !!filters.tillEnd}
              key={filters.tillStart + filters.tillEnd}
              onChange={( values ) => {
                setMultipleFilters( {
                  fromStart: values ? moment( values.start ).format( 'YYYY-MM-DD' ) : null,
                  fromEnd: values ? moment( values.end ).format( 'YYYY-MM-DD' ) : null,
                } );
              }}
            />
          </Col>
        </Row>
      )}
      rightContent={(
        <CanAccess I="edit" a="accommodation">
          <AddBtn title="newDiscount" onClick={() => onOpenNewItemModal()} />
        </CanAccess>
    )}
      columns={[
        {
          Header: '#',
          accessor: 'id',
          Cell: ( { cell } ) => (
            <TableEditBtn
              title={cell.value.substr( 0, 6 )}
              onClick={() => onOpenEditItemModal( cell.value )}
            />
          ),
        },
        {
          Header: 'name',
          accessor: 'name',
        },
        {
          Header: 'validUntil',
          accessor: 'validUntil',
          Cell: ( { cell } ) => (
            cell.value
              ? moment.utc( new Date( cell.value ) ).isAfter( new Date(), 'day' )
                ? moment.utc( new Date( cell.value ) ).format( 'DD/MM/YYYY' )
                : <Intl id="expired" />
              : <Intl id="expired" />
          ),
        },
        {
          Header: 'accommodationsNumber',
          accessor: 'accommodationsNumber',
        },
        {
          accessor: 'id',
          disableSorting: true,
          Cell: ( { cell } ) => (
            canModify( 'accommodation' ) ? (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDeleteItem( cell.value, cb ),
                  },
                ]}
              />
            ) : null
          ),
        },
      ]}
    />
  </>
) );
