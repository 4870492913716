import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const GET_SEASON = gql`
  query SeasonRate($input: DetailSeasonRateInput!) {
  seasonRate(input: $input) {
    ... on BaseError {
      message
    }
    ... on QuerySeasonRateSuccess {
      data {
        id
        lastNight
        priceOnlyAccommodation
        priceSupplementPerAdditionalPerson
        startDate
        rateId
      }
    }
  }
}
`;

const getSeason = async ( seasonRateId ) => {
  const { data } = await client.query( {
    query: GET_SEASON,
    variables: {
      input: {
        seasonRateId,
      },
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.seasonRate.message ) {
    return {
      ok: false,
      message: data.seasonRate.message,
    };
  }
  const seasonRate = data.seasonRate.data;
  return {
    ok: true,
    status: 200,
    data: { data: seasonRate },
  };
};

export default getSeason;
