/* eslint-disable import/prefer-default-export */
import _, { isNumber } from 'lodash';

const formatTasks = ( datatToFormat ) => {
  const dataToSend = _.map( datatToFormat.data, ( item ) => ( {
    ...item,
    estimatedTime: item.taskType.estimatedTime || 0,
    deviation: item.deviation || 0,
    hadDeviation: isNumber( item.deviation ),
  } ) );
  return {
    ...datatToFormat,
    data: dataToSend,
  };
};

const formatTimeString = ( hours, minutes, seconds ) => {
  const formattedHours = hours.toString().length === 1 ? `0${hours}` : hours;
  const formattedMinutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const formatTime = ( m ) => {
  const minutes = m % 60;
  const hours = ( m - minutes ) / 60;
  return formatTimeString( hours, minutes, 0 );
};

const getStatistics = ( data = [] ) => {
  const estimated = data.reduce( ( acc, curr ) => (
    acc + curr.estimatedTime ), 0 );
  const elapsed = data.reduce( ( acc, curr ) => (
    curr.hadDeviation ? acc + curr.estimatedTime - curr.deviation : acc ), 0 );
  const deviation = data.reduce( ( acc, curr ) => (
    acc + curr.deviation ), 0 );
  return {
    estimated: formatTime( estimated ),
    elapsed: formatTime( elapsed ),
    deviation: formatTime( deviation ),
  };
};

export { formatTasks, getStatistics };
