import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField,
  EditCard,
  TranslationLayout,
} from 'components';

export default React.memo( () => (
  <EditCard title="customerApp.review.title" cardClass="card-sec-bottom">
    <TranslationLayout
      translationFields={( locale ) => (
        <Row>
          <Col lg>
            <Row>
              <Col md={8}>
                <TextField
                  field={`translations.${locale}.reviewQ1`}
                  label="customerApp.review.q1"
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <TextField
                  field={`translations.${locale}.reviewQ2`}
                  label="customerApp.review.q2"
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <TextField
                  field={`translations.${locale}.reviewQ3`}
                  label="customerApp.review.q3"
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <TextField
                  field={`translations.${locale}.reviewQ4`}
                  label="customerApp.review.q4"
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <TextField
                  field={`translations.${locale}.reviewQ5`}
                  label="customerApp.review.q5"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    >
      <Row>
        <Col lg>
          <Row>
            <Col md={8}>
              <TextField field="reviewQ1" label="customerApp.review.q1" />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <TextField field="reviewQ2" label="customerApp.review.q2" />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <TextField field="reviewQ3" label="customerApp.review.q3" />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <TextField field="reviewQ4" label="customerApp.review.q4" />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <TextField field="reviewQ5" label="customerApp.review.q5" />
            </Col>
          </Row>
        </Col>
      </Row>
    </TranslationLayout>
  </EditCard>
) );
