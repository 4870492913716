export default [
  {
    locale: 'en',
    name: 'English',
  },
  {
    locale: 'es',
    name: 'Spanish',
  },
  {
    locale: 'it',
    name: 'Italian',
  },
  {
    locale: 'fr',
    name: 'French',
  },
];
