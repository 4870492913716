import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { filterBuilder } from './filterBuilder';

const GET_ALL_SEASONS_RATE = gql`
  query SeasonsRate($limit: Int!, $offset: Int!, $filters: [FilterInput!]!) {
  seasonsRate(limit: $limit, offset: $offset, filters: $filters) {
    ... on BaseError {
      message
    }
    ... on QuerySeasonsRateSuccess {
      data {
        results {
          id
          lastNight
          priceOnlyAccommodation
          priceSupplementPerAdditionalPerson
          rateId
          startDate
        }
        total
      }
    }
  }
}
`;

const getAllSeasonsRate = async ( { page, elementsPerPage, ...rest } ) => {
  const offset = ( page - 1 ) * elementsPerPage;
  const limit = elementsPerPage;
  const { data } = await client.query( {
    query: GET_ALL_SEASONS_RATE,
    variables: {
      offset,
      limit,
      filters: filterBuilder( rest ),
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.seasonsRate.message ) {
    return {
      ok: false,
      message: data.seasonsRate.message,
    };
  }
  const seasonsRate = data.seasonsRate.data.results || [];
  const elementsTotal = data.seasonsRate.data.total || 0;
  return {
    ok: true,
    status: 200,
    data: { data: seasonsRate, elementsTotal, elementsPerPage },
  };
};

export default getAllSeasonsRate;
