import React, { useCallback } from 'react';
import NewCustomerView from 'views/Booking/New/newCustomer';

import CustomerService from 'api/Customer';
import PropertyManagersService from 'api/PropertyManager';
import {
  formatDataToSend, handlePromise,
} from 'utils';

const NewCustomer = ( {
  modalOpened, onCloseModal, onReload, toggleLoading,
} ) => {
  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );
    const dataToSend = formatDataToSend( formData, form );
    const [errors, response, customerData] = await handlePromise(
      CustomerService.saveCustomer( dataToSend ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    toggleLoading( false );
    onReload( customerData );
    onCloseModal();
  }, [toggleLoading, onCloseModal, onReload] );

  const fetchPropertyManagers = useCallback(
    ( query ) => PropertyManagersService.getPropertyManagers( {
      elementsPerPage: 500,
      keyword: query,
    } ),
    [],
  );

  return (
    <NewCustomerView
      modalOpened={modalOpened}
      onCloseModal={onCloseModal}
      onSubmit={submitForm}
      onFetchPropertyManagers={fetchPropertyManagers}
    />
  );
};

export default NewCustomer;
