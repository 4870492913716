import { createSeasonRate, getAllSeasonsRate } from 'api/Rates/Seasons';
import { useFetch, useSubmit } from 'hooks';
import React, { useCallback, useState } from 'react';
import SettingsActions from 'store/reducers/Settings';
import DaysView from 'views/PropertyManager/Edit/Rates/EditDrawer/RateSeasons/Day';

import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import SeasonEdit from './Edit';

const DaysEdit = ( {
  visible,
  rateId,
  dataEdited,
  onReloadRates,
  onOpenEditItemModal,
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
} ) => {
  const [currentStartDate, setCurrentStartDate] = useState( moment().startOf( 'month' ) );
  const [currentEndDate, setCurrentEndDate] = useState( moment().endOf( 'month' ) );
  const [modalOpen, setModalOpen] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [clickedDate, setClickedDate] = useState( new Date() );

  const formatToEvents = ( responseData ) => {
    const { data: seasons } = responseData;
    return _.map( seasons, ( item ) => ( {
      ...item,
      start: item.startDate.replace( 'Z', '' ),
      end: item.lastNight.replace( 'Z', '' ),
      title: `Price: ${item.priceOnlyAccommodation}`,
      color: '#f9b586',
      eventTextColor: '#000',
    } ) );
  };

  const [data] = useFetch( {
    promise: () => getAllSeasonsRate( {
      rateId,
      page: 1,
      elementsPerPage: 30,
      // fromStart: currentStartDate,
      // fromEnd: currentEndDate,
      // tillStart: currentStartDate,
      // tillEnd: currentEndDate,
    } ),
    format: formatToEvents,
    toggleErrorAlert,
    conditional: visible,
    deps: [rateId, currentEndDate, currentStartDate, visible, dataSubmitted, dataEdited],
    reInit: true,
  } );

  const updateCurrentDates = ( startDate, endDate ) => {
    setCurrentStartDate( startDate );
    setCurrentEndDate( endDate );
  };

  const onOpenModal = ( date ) => {
    setClickedDate( date );
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
    onReloadRates();
    onCloseModal();
  }, [onReloadRates] );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( createSeasonRate( dataToSend ) ),
    format: ( dataToSend ) => ( {
      rateId,
      seasons: [
        {
          ...dataToSend,
          lastNight: moment( dataToSend.lastNight ).toISOString(),
          startDate: moment( dataToSend.startDate ).toISOString(),
        },
      ],
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: () => {
      reloadData();
    },
    deps: [rateId],
  } );

  return (
    <>
      <DaysView
        events={data || []}
        visible={visible}
        updateCurrentDates={updateCurrentDates}
        onSubmit={onSubmit}
        onClose={onCloseModal}
        onOpenEditItemModal={onOpenEditItemModal}
        onOpenModal={onOpenModal}
        isLoading={false}
      />
      <SeasonEdit
        rateId={rateId}
        data={{
          startDate: moment( clickedDate ),
          lastNight: moment( clickedDate ),
        }}
        events={data || []}
        open={modalOpen}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( DaysEdit );
