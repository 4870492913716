import React, { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { Dropzone } from 'components';

const ImagesDropzone = React.memo( ( {
  form, canDelete = true, canAdd = true, userId, onAddImage, onDeleteImage, localDelete = false,
} ) => {
  const deleteLocalImage = useCallback( ( index ) => {
    const images = [...form.getFieldState( 'images' ).value];
    images.splice( index, 1 );
    form.change( 'images', images );
  }, [form] );

  return (

    <Row className="mb-2">
      {form.getFieldState( 'images' ) && form.getFieldState( 'images' ).value
        ? _.map( form.getFieldState( 'images' ).value, ( item, index ) => (
          <Col xs="auto" className="pr-0" key={index}>
            <span className="avatar avatar-xxl bg-transparent">
              <a href={item.url ? item.url : item.fileDecoded} target="_blank" rel="noopener noreferrer">
                <img src={item.url ? item.url : item.fileDecoded} alt="..." className="dz-preview-img" />
              </a>
            </span>
            {( !item.id || ( canDelete && !item.owner )
              || ( canDelete && userId === item.owner.id ) )
            && (
              <i
                className="app-icon-close dz-task-attachment-remove cursor-pointer"
                onClick={( e ) => {
                  e.stopPropagation();
                  if ( item.id && !localDelete ) {
                    onDeleteImage( item.id );
                  } else {
                    deleteLocalImage( index );
                  }
                }}
              />
            )}
          </Col>
        ) ) : null}

      <Col xs="auto" className={classnames( { 'd-none': !canAdd } )}>
        <Dropzone
          field="images"
          multiple
          preview={false}
          placeholder={<i className="app-icon-plus text-xl" />}
          formGroupClass="dz-task-attachment"
          onChange={( files ) => {
            if ( onAddImage ) onAddImage( files );
          }}
        />
      </Col>
    </Row>
  );
} );

const mapStateToProps = ( { user } ) => ( {
  userId: _.get( user, 'user.id' ),
} );

export default connect( mapStateToProps, null )( ImagesDropzone );
