import React from 'react';
import { connect } from 'react-redux';

import CountryService from 'api/Country';
import CountryEditView from 'views/Country/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';

const CountryEdit = ( {
  countryId, modalOpened, onCloseModal, onReload, toggleErrorAlert, toggleLoading, toggleInfoAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => CountryService.getCountry( countryId ),
    toggleErrorAlert,
    conditional: !!countryId && modalOpened,
    deps: [countryId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( countryId ? CountryService.updateCountry( countryId, dataToSend )
      : CountryService.saveCountry( dataToSend ) ),
    toggleLoading,
    toggleInfoAlert,
    callback: onReload,
    deps: [countryId],
  } );

  return (
    <CountryEditView
      countryId={countryId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CountryEdit );
