import Regions from 'containers/Regions';
import Country from 'containers/Country';
import PropertyManager from 'containers/PropertyManager';
import Customer from 'containers/Customer';
import ChangePassword from 'containers/ChangePassword';
import UserProfile from 'containers/UserProfile';
import PointOfInterest from 'containers/PointOfInterest';
import CityAppImages from 'containers/CityAppImages';
import Advices from 'containers/Advices';
import Reminders from 'containers/Reminders';
import Usefuls from 'containers/Useful';
import EmergencyNumbers from 'containers/EmergencyNumbers';
import Tips from 'containers/Tips';
import LunchHours from 'containers/LunchHours';
import NightLeisures from 'containers/NightLeisures';
import CovidMeasures from 'containers/CovidMeasures';
import UniquePhrases from 'containers/UniquePhrases';
import Service from 'containers/Service';
import Post from 'containers/Post';
import Category from 'containers/Category';
import Event from 'containers/Event';
import AdminRatesPropertyManager from 'containers/AdminRates/PropertyManager';
import AdminRatesClient from 'containers/AdminRates/Client';

export default {
  headerLeft: [
    {
      name: 'propertyManagers',
      path: 'property-managers',
      icon: 'ni ni-world',
      component: PropertyManager,
      sidebar: true,
      layout: '/',
    },
    {
      name: 'customers',
      path: 'resources',
      icon: 'ni ni-single-02',
      component: Customer,
      sidebar: true,
      layout: '/',
    },
    {
      name: 'social',
      path: 'services',
      layout: '/social/',
      icon: 'ni ni-like-2',
      state: 'content',
      views: [
        {
          name: 'services',
          path: 'services',
          layout: '/social/',
          component: Service,
        },
        {
          name: 'posts',
          path: 'posts',
          layout: '/social/',
          component: Post,
        },
        {
          name: 'categories',
          path: 'categories',
          layout: '/social/',
          component: Category,
        },
        {
          name: 'community',
          path: 'community',
          layout: '/social/',
          component: Event,
        },
        {
          name: 'pointsOfInterest',
          path: 'point-of-interest',
          layout: '/social/',
          icon: 'fas fa-map-marker-alt',
          component: PointOfInterest,
        },
      ],
    },
  ],
  headerRight: [
    {
      name: 'settings',
      path: 'regions',
      layout: '/settings/',
      component: Regions,
      icon: 'th-icon-settings',
      state: 'settings',
      views: [
        {
          name: 'regions',
          path: 'regions',
          layout: '/settings/',
          component: Regions,
        },
        {
          name: 'countries',
          path: 'countries',
          layout: '/settings/',
          component: Country,
        },
        {
          name: 'app',
          path: 'advices',
          layout: '/settings/app/',
          icon: 'app-icon-operation',
          state: 'settings',
          views: [
            {
              name: 'advices',
              path: 'advices',
              layout: '/settings/app/',
              icon: 'fa fa-tags',
              component: Advices,
            },
            {
              name: 'reminders',
              path: 'reminders',
              layout: '/settings/app/',
              icon: 'fa fa-book',
              component: Reminders,
            },
            {
              name: 'usefulInformation',
              path: 'useful-information',
              layout: '/settings/app/',
              icon: 'fa fa-bookmark',
              component: Usefuls,
            },
            {
              name: 'emergencyNumbers',
              path: 'emergency-numbers',
              layout: '/settings/app/',
              icon: 'fas fa-phone',
              component: EmergencyNumbers,
            },
            {
              name: 'tips',
              path: 'tips',
              layout: '/settings/app/',
              icon: 'fa fa-tags',
              component: Tips,
            },
            {
              name: 'lunchHours',
              path: 'lunch-hours',
              layout: '/settings/app/',
              icon: 'fa fa-tags',
              component: LunchHours,
            },
            {
              name: 'nightLeisures',
              path: 'night-leisures',
              layout: '/settings/app/',
              icon: 'fa fa-tags',
              component: NightLeisures,
            },
            {
              name: 'covidMeasures',
              path: 'covid-measures',
              layout: '/settings/app/',
              icon: 'fa fa-tags',
              component: CovidMeasures,
            },
            {
              name: 'uniquePhrases',
              path: 'unique-phrases',
              layout: '/settings/app/',
              icon: 'fa fa-tags',
              component: UniquePhrases,
            },
            {
              name: 'cityAppImages',
              path: 'city-app-images',
              layout: '/settings/app/',
              icon: 'fas fa-images',
              component: CityAppImages,
            },
          ],
        },
        {
          name: 'rates',
          path: 'property-manager',
          layout: '/settings/rates/',
          icon: 'app-icon-operation',
          state: 'settings',
          views: [
            {
              name: 'propertyManager',
              path: 'property-manager',
              layout: '/settings/rates/',
              component: AdminRatesPropertyManager,
            },
            {
              name: 'client',
              path: 'client',
              layout: '/settings/rates/',
              component: AdminRatesClient,
            },
          ],
        },
      ],
    },
  ],
  userMenu: [
    {
      name: 'myProfile',
      path: 'profile',
      icon: 'ni ni-single-02',
      component: UserProfile,
      headerDropdown: true,
      layout: '/',
    },
    {
      name: 'changePassword',
      path: 'change-password',
      icon: 'fa fa-key',
      component: ChangePassword,
      headerDropdown: true,
      layout: '/',
    },
  ],
};
