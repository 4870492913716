import React from 'react';
import classnames from 'classnames';
import { ListGroupItem } from 'reactstrap';
import { Intl } from 'components';

export default React.memo( ( {
  color, id,
} ) => (
  <ListGroupItem>
    <div className="help-modal-item">
      <div
        className={classnames(
          'w-40',
        )}
      >
        <div
          className={classnames( `cal-booking-line color-${color}`, {
            'border_round-right': true,
            'border_round-left': true,
          } )}
        >
          padding
        </div>
      </div>
      <div className="ml-3">
        <Intl id={id} />
      </div>
    </div>
  </ListGroupItem>
) );
