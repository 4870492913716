/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import './tooltip.css';
import moment from 'moment';
import { Intl } from 'components';
import { typeOptionNamesOccupancy } from 'views/PropertyManager/Edit/Occupancies/EditDrawer/Seasons/Edit/constants';
import { typeOptionNamesDiscount } from 'views/PropertyManager/Edit/Discount/EditDrawer/Seasons/Edit/constants';
import { colorByStatus, colorTextByStatus } from '../Calendar/utils';

const TooltipItem = ( props ) => {
  const {
    event, id, status, title,
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState( false );
  const toggle = () => {
    setTooltipOpen( !tooltipOpen );
  };
  const startDate = event.startDate ? event.startDate.slice( 0, 10 ) : event.from
    ? moment( event.from ).format( 'YYYY-MM-DD' ) : null;
  const lastNight = event.lastNight ? event.lastNight.slice( 0, 10 ) : event.till
    ? moment( event.till ).format( 'YYYY-MM-DD' ) : null;

  return (
    <>
      <div
        style={{
          position: 'absolute',
          inset: 0,
        }}
        onMouseEnter={toggle}
        onMouseLeave={toggle}
      />
      <Tooltip
        placement="auto"
        isOpen={tooltipOpen}
        target={id}
        style={{
          backgroundColor: status ? colorByStatus[status] : title === 'Blocked' ? colorByStatus.blocked : '#f9b586',
          color: status ? colorTextByStatus[status] : title === 'Blocked' ? colorTextByStatus.blocked : '#000',
        }}
      >
        {event.rateId != null ? (
          <>
            <strong>
              <Intl id="startDate" />
              :
            </strong>
            {` ${startDate}`}
            <br />
            <strong>
              <Intl id="lastNight" />
              :
            </strong>
            {` ${lastNight}`}
            <br />
            <strong>
              <Intl id="priceOnlyAccommodation" />
              :
            </strong>
            {` ${event.priceOnlyAccommodation}`}
            <br />
            <strong>
              <Intl id="priceSupplementPerAdditionalPerson" />
              :
            </strong>
            {` ${event.priceSupplementPerAdditionalPerson}`}
          </>
        ) : event.discountId != null ? (
          <>
            <strong>
              <Intl id="startDate" />
              :
            </strong>
            {` ${startDate}`}
            <br />
            <strong>
              <Intl id="lastNight" />
              :
            </strong>
            {` ${lastNight}`}
            <br />
            <strong>
              <Intl id="discountType" />
              :
            </strong>
            {' '}
            <Intl id={event.discountType.toLowerCase()} />
            {/* {` ${event.discountType}`} */}
            <br />
            <strong>
              <Intl id="discountValue" />
              :
            </strong>
            {` ${event.discountValue}`}
            <br />
            <strong>
              <Intl id="type" />
              :
            </strong>
            {' '}
            <Intl id={typeOptionNamesDiscount[event.type]} />
          </>
        ) : event.occupancyId != null ? (
          <>
            <strong>
              <Intl id="startDate" />
              :
            </strong>
            {` ${startDate}`}
            <br />
            <strong>
              <Intl id="lastNight" />
              :
            </strong>
            {` ${lastNight}`}
            <br />
            <strong>
              <Intl id="lengthOfTheStay" />
              :
            </strong>
            {` ${event.lengthOfTheStay}`}
            <br />
            <strong>
              <Intl id="checkIn" />
              :
            </strong>
            {' '}
            <Intl id={typeOptionNamesOccupancy[event.checkIn]} />
            <br />
            <strong>
              <Intl id="checkOut" />
              :
            </strong>
            {' '}
            <Intl id={typeOptionNamesOccupancy[event.checkOut]} />
          </>
        ) : event.bookingId != null ? (
          <>
            <strong>
              <Intl id="from" />
              :
            </strong>
            {` ${startDate}`}
            <br />
            <strong>
              <Intl id="till" />
              :
            </strong>
            {` ${lastNight}`}
            <br />
            <strong>
              <Intl id="capacity" />
              :
            </strong>
            {` ${event.capacity}`}
            <br />
            <strong>
              <Intl id="status" />
              :
            </strong>
            {' '}
            <Intl id={event.status} />
            <br />
            <strong>
              <Intl id="price" />
              :
            </strong>
            {event.prince ? ` ${event.price}` : ' -----'}
            <br />
            <strong>
              <Intl id="blocked" />
              :
            </strong>
            {' '}
            <Intl id={`${event.blocked}`} />
            <br />
            <strong>
              <Intl id="checkIn" />
              :
            </strong>
            {` ${event.checkInTime}`}
            <br />
            <strong>
              <Intl id="checkOut" />
              :
            </strong>
            {` ${event.checkOutTime}`}
          </>
        )
          : title === 'Blocked' ? (
            <>
              <strong>
                <Intl id="category" />
                :
              </strong>
              {' '}
              <Intl id={event.category} />
              <br />
              <strong>
                <Intl id="description" />
                :
              </strong>
              {` ${event.description}`}
              <br />
            </>
          ) : null}
      </Tooltip>
    </>

  );
};

export default TooltipItem;
