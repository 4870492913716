import React from 'react';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import _ from 'lodash';
import { Intl } from 'components';

const RadioButtonInput = React.memo( ( {
  label, value, inputName, inputOnChange, onChange, formGroupClass, options, inline,
  translateValues, translateOptions = true, meta, isInvalid,
} ) => (
  <FormGroup className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}>
    {label
    && (
      <label className="form-control-label">
        <Intl id={label} />
      </label>
    )}

    {_.map( options, ( option ) => (
      <div
        className={classnames( 'custom-control custom-radio', {
          'd-inline-flex mr-5': inline,
        } )}
        key={option.id}
      >
        <input
          className="custom-control-input"
          name={inputName}
          defaultChecked={option.id === value}
          id={option.id}
          onChange={() => {
            if ( inputOnChange ) inputOnChange( option.id );
            if ( onChange ) onChange( option.id );
          }}
          type="radio"
          data-cy={`input-${inputName}-${option.id}`}
        />
        <label
          className="custom-control-label"
          htmlFor={option.id}
        >
          {translateOptions ? <Intl id={option.name} /> : option.name}
        </label>
      </div>
    ) )}

    {isInvalid
    && (
      <div className="invalid-feedback">
        <Intl id={meta.error || meta.submitError} values={translateValues} />
      </div>
    )}
  </FormGroup>
) );

export default RadioButtonInput;
