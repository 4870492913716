import React from 'react';
import { Modal, ListGroup } from 'reactstrap';
import { Intl } from 'components';
import HelpModalItem from './HelpModalItem';

const HelpModal = React.memo( ( {
  open, onClose,
} ) => (
  <Modal
    className="modal-dialog-centered app-modal"
    size="lg"
    isOpen={open}
    zIndex={10000}
  >
    <div className="modal-header border py-3">
      <span className="modal-title text-uppercase">
        <Intl id="legend" />
      </span>
      <button
        aria-label="Close"
        className="close text-sm"
        data-dismiss="modal"
        type="button"
        onClick={onClose}
      >
        <i className="app-icon-close" />
      </button>
    </div>
    <>
      <ListGroup>
        <HelpModalItem
          id="resources:role:admin:id"
          description="resources:role:admin:description"
        />
        <HelpModalItem
          id="resources:role:manager:id"
          description="resources:role:manager:description"
        />
        <HelpModalItem
          id="resources:role:managerr:id"
          description="resources:role:managerr:description"
        />
        <HelpModalItem
          id="resources:role:supervisor:id"
          description="resources:role:supervisor:description"
        />
        <HelpModalItem
          id="resources:role:employee:id"
          description="resources:role:employee:description"
        />
      </ListGroup>
    </>

  </Modal>
) );
export default HelpModal;
