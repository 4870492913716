import React from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import {
  Select,
  ModalForm,
  TextField,
} from 'components';
import { formatFloat } from 'utils';
import { required } from 'config/InputErrors';
import getPlatformOptionsDeposits from 'utils/plugins';

export default React.memo(
  ( {
    data,
    open,
    onClose,
    onSubmit,
    onChangeCountry,
    onChangeState,
    countries,
    isLoadingCountries,
    properties,
    isLoadingProperties,
    states,
    isLoadingStates,
    cities,
    isLoadingCities,
    isLoading,
    plugins,
  } ) => {
    const isEditting = !_.isEmpty( data );
    const initialData = {
      depositType: _.get( data, 'depositType', '' ),
      depositAmount: formatFloat( _.get( data, 'depositAmount', 0 ) ),
      gateway: _.get( data, 'gateway', '' ),
      country: _.get( data, 'country.id', '' ),
      state: _.get( data, 'state.id', '' ),
      city: _.get( data, 'city.id', '' ),
      property: _.get( data, 'property.id', '' ),
    };

    return (
      <ModalForm
        initialValues={initialData}
        title={!_.isEmpty( data ) ? 'editDeposit' : 'newDeposit'}
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        bodyClassName="overflow-visible"
        body={( form ) => (
          <>
            <Row>
              <Col md={4}>
                <Select
                  field="depositType"
                  label="depositType"
                  translateOptions
                  validate={required}
                  options={[
                    {
                      name: 'fix',
                      id: 'fix',
                    },
                    {
                      name: 'percentage',
                      id: 'percentage',
                    },
                  ]}
                />
              </Col>
              <Col md={4}>
                <TextField
                  field="depositAmount"
                  label="amount"
                  type="number"
                  validate={required}
                />
              </Col>
              <Col md={4}>
                <Select
                  field="gateway"
                  label="platform"
                  translateOptions
                  validate={required}
                  options={getPlatformOptionsDeposits( plugins )}
                />
              </Col>
            </Row>
            <p>¿A donde aplica este deposito?</p>
            <Row>
              <Col md={3}>
                <Select
                  field="country"
                  label="country"
                  options={countries}
                  isLoading={isLoadingCountries}
                  onChange={( id ) => {
                    form.change( 'country', id );
                    form.change( 'state', null );
                    form.change( 'city', null );
                    onChangeCountry( id );
                  }}
                  isDisabled={isEditting}
                />
              </Col>
              {!isEditting && (
              <Col md={3}>
                <Select
                  field="state"
                  label="state"
                  options={states}
                  isLoading={isLoadingStates}
                  onChange={( id ) => {
                    form.change( 'state', id );
                    form.change( 'city', null );
                    onChangeState( id );
                  }}
                />
              </Col>
              )}
              <Col md={isEditting ? 5 : 3}>
                {isEditting
                  ? (
                    <TextField
                      label="city"
                      value={_.get( data, 'city.name', '' )}
                      disabled
                    />
                  )
                  : (
                    <Select
                      field="city"
                      label="city"
                      options={cities}
                      isLoading={isLoadingCities}
                      onChange={( id ) => {
                        form.change( 'city', id );
                      }}
                    />
                  )}
              </Col>
              <Col md={3}>
                <Select
                  field="property"
                  label="property"
                  options={properties}
                  isLoading={isLoadingProperties}
                  isDisabled={isEditting}
                />
              </Col>
            </Row>
          </>
        )}
      />
    );
  },
);
