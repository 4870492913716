import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { filterBuilder } from './filterBuilder';

const GET_ALL_ACCOMODATIONS = gql`
  query AccomodationRates($limit: Int!, $offset: Int!, $filters: [FilterInput!]!) {
  accomodationRates(limit: $limit, offset: $offset, filters: $filters) {
    ... on BaseError {
      message
    }
    ... on QueryAccomodationRatesSuccess {
      data {
        total
        results {
          id
          accommodation {
            id
            name
            floor
            number
            maximumCapacity
            minimumCapacity
          }
        }
      }
    }
  }
}
`;

const getAllAccommodations = async ( { page, elementsPerPage, ...rest } ) => {
  const offset = ( page - 1 ) * elementsPerPage;
  const limit = elementsPerPage;
  const { data } = await client.query( {
    query: GET_ALL_ACCOMODATIONS,
    variables: {
      offset,
      limit,
      filters: filterBuilder( rest ),
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.accomodationRates.message ) {
    return {
      ok: false,
      message: data.accomodationRates.message,
    };
  }
  const accomodationRates = data.accomodationRates.data.results.map(
    ( item ) => ( { ...item.accommodation, accRateId: item.id } ),
  ) || [];
  const elementsTotal = data.accomodationRates.data.total || 0;
  return {
    ok: true,
    status: 200,
    data: { data: accomodationRates, elementsTotal, elementsPerPage },
  };
};

export default getAllAccommodations;
