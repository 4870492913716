/* eslint-disable jsx-a11y/control-has-associated-label */
import { Intl } from 'components';
import React, { useState } from 'react';
import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import { toValue } from './utils';

const ExtraService = ( { extraService, currency } ) => {
  const d = {
    totalPrice: ( extraService?.amount || 0 ) * ( extraService?.price || 0 ),
  };
  return (
    <tr>
      <td>{extraService?.name || ''}</td>
      <td>{toValue( extraService, 'price', '$', currency )}</td>
      <td>{extraService?.amount || 0}</td>
      <td>{toValue( extraService, 'appliedTaxPercentage' )}</td>
      <td />
      <td />
      <td>{toValue( d, 'totalPrice', '$', currency )}</td>
    </tr>
  );
};

// const data = {

//   rentalPrice: 240,
//   totalRentalPrice: 333,
//   appliedTaxPercentage: 45,
//   totalPrice: 890,
//   totalServicesPrice: 0,
//   amount: [1684454400000, 1684540800000],
//   commission: 21,
//   appliedOwnerCommission: 10,
//   currency: 'EUR',
//   extrasServices: [{
//     name: 'limpieza inicial',
//     amount: 1,
//     price: 20,
//     appliedTaxPercentage: 21,
//   },
//   {
//     name: 'limpieza final',
//     amount: 2,
//     price: 45,
//     appliedTaxPercentage: 21,
//   }],

// };

const PaymentInfoView = ( { data, isLoading } ) => {
  const [isOpen, setIsOpen] = useState( false );

  return (
    <div className="mt-3">
      <div onClick={() => {
        setIsOpen( !isOpen );
      }}
      >

        <h3 className="mb-0 p-3 cursor-pointer">
          <i className={classnames( 'fa fa-caret-right mr-2 transition-all', { 'rotate-down': isOpen } )} />
          <Intl id="infoPayment" />
        </h3>

      </div>

      <Collapse isOpen={isOpen}>
        <div
          className={classnames( 'payment-info pb-3', {
            'app-component-loading': isLoading,
          } )}
        >
          <table>
            <thead>
              <tr>
                <th>
                  <Intl id="infoPayment.item" />
                </th>
                <th style={{ width: 220 }}>
                  <Intl id="infoPayment.basePrice" />
                </th>
                <th>
                  <Intl id="infoPayment.amount" />
                </th>
                <th className="th-percent">
                  <Intl id="infoPayment.taxes" />
                </th>
                <th className="th-percent">
                  <Intl id="infoPayment.commision" />
                </th>
                <th className="th-percent" style={{ width: 220 }}>
                  <Intl id="infoPayment.ownerCommision" />
                </th>
                <th style={{ width: 120 }}>
                  <Intl id="infoPayment.total" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Intl id="infoPayment.accommodation" />
                </td>

                <td>{toValue( data, 'rentalPrice', '$', data?.currency )}</td>
                <td>
                  {data?.amount?.length || 0}
                  {' '}
                  <Intl id="infoPayment.nights" />
                </td>
                <td>{toValue( data, 'appliedTaxPercentage' )}</td>
                <td>{toValue( data, 'commission' )}</td>
                <td>{toValue( data, 'appliedOwnerCommission' )}</td>
                <td>{toValue( data, 'totalRentalPrice', '$', data?.currency )}</td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <div className="table-text-end">
                    <b>
                      <Intl id="infoPayment.subtotal.accommodation" />
                    </b>
                  </div>
                </td>
                <td>
                  <b>{toValue( data, 'totalRentalPrice', '$', data?.currency )}</b>
                </td>
              </tr>

              {data && data.extrasServices && data.extrasServices.length ? (
                <>
                  <tr>
                    <td colSpan={7}>
                      <div className="text-th">
                        <Intl id="infoPayment.extraServices" />
                      </div>
                    </td>
                  </tr>
                  {data.extrasServices.map( ( extraService, k ) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ExtraService
                      extraService={extraService}
                      currency={data?.currency}
                  // eslint-disable-next-line react/no-array-index-key
                      key={k}
                    />
                  ) )}
                  <tr>
                    <td colSpan={6} className="pt-3">
                      <div className="table-text-end">
                        <b>Subtotal de extras/servicios</b>
                      </div>
                    </td>
                    <td className="pt-3">
                      <b>
                        {toValue( data, 'totalServicesPrice', '$', data?.currency )}
                      </b>
                    </td>
                  </tr>
                </>
              ) : null}

              <tr>
                <td colSpan={6}>
                  <div className="table-text-end">
                    <b>
                      <Intl id="infoPayment.total" />
                    </b>
                  </div>
                </td>
                <td>
                  <b>{toValue( data, 'totalPrice', '$', data?.currency )}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
      <hr className="m-0" />
    </div>
  );
};
export default PaymentInfoView;
