import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
} from 'reactstrap';

import {
  Auth, Form, TextField, Intl,
} from 'components';
import { composeValidators, required, email } from 'config/InputErrors';

export default React.memo( ( { onSubmit } ) => (
  <Auth
    header={(
      <h1 className="mb-0 font-weight-500 text-dark-2 mt-2">
        <Intl id="forgotPasswordTitle" />
      </h1>
    )}
    msg={<Intl id="forgotPasswordMsg" />}
  >
    <Form
      onSubmit={onSubmit}
      showErrorsInFooter
      showSuccessMsgInFooter
      successMsg="forgotPasswordEmailSentMsg"
    >
      <Row>
        <Col md={9} className="m-auto">
          <TextField
            field="email"
            validate={composeValidators( required, email )}
            placeholder="email"
            size="md"
          />
        </Col>
      </Row>

      <Row>
        <Col md={9} className="mt-3 mx-auto">
          <Button color="primary" className="text-md" block>
            <Intl id="send" />
          </Button>

          <div className="text-center pt-3">
            <Link to="/auth/login" className="text-bold">
              <Intl id="goToLogin" />
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  </Auth>
) );
