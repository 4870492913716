export const getInitials = ( item ) => {
  if ( item && item.firstName ) {
    let text = item.firstName[0];
    if ( item.lastName ) text += item.lastName[0];
    return text;
  }
  return '';
};

export const getFromTimeString = ( data ) => {
  const from = data.from ? data.from.toString() : '';
  let formattedFrom = '';

  if ( from.length === 3 ) {
    formattedFrom = `${from.slice( 0, 1 )}:${from.slice( 1, 3 )}`;
  } else if ( from.length === 4 ) {
    formattedFrom = `${from.slice( 0, 2 )}:${from.slice( 2, 4 )}`;
  }
  return formattedFrom;
};

export const getTillTimeString = ( data ) => {
  const till = data.till ? data.till.toString() : '';
  let formattedTill = '';

  if ( till.length === 3 ) {
    formattedTill = ` ${till.slice( 0, 1 )}:${till.slice( 1, 3 )}`;
  } else if ( till.length === 4 ) {
    formattedTill = `${till.slice( 0, 2 )}:${till.slice( 2, 4 )}`;
  }
  return formattedTill;
};
