import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AccessControlDashboardView from 'views/AccessControl/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import AccessControlService from 'api/AccessControl';
import PropertyService from 'api/Property';
import AccommodationService from 'api/Accommodation';
import CustomerService from 'api/Customer';
import { useFetchTableData } from 'hooks';
import moment from 'moment';

const AccessControlDashboard = ( {
  defaultFilters, toggleErrorAlert,
  updateFilters, setFiltersError,
} ) => {
  const filterFromDate = defaultFilters.from || moment().startOf( 'day' );
  const filterToDate = defaultFilters.till || moment( filterFromDate ).clone().add( '1', 'day' );
  const dateRange = useRef( moment.range( filterFromDate, filterToDate ) );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const showLoading = useRef( true );
  const isInitialMount = useRef( true );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AccessControlService.getLogs( {
      ...params,
      from: dateRange.current.start ? dateRange.current.start.format( 'YYYY-MM-DD' ) : undefined,
      till: dateRange.current.end ? dateRange.current.end.format( 'YYYY-MM-DD' ) : undefined,
      property: ( _.get( params, 'property.value' ) ) ? params.property.value : undefined,
      accommodation: ( _.get( params, 'accommodation.value' ) ) ? params.accommodation.value : undefined,
      customer: ( _.get( params, 'customer.value' ) ) ? params.customer.value : undefined,
    } ),
    callback: ( params ) => {
      initialReloadError.current = false;
      showLoading.current = true;
      updateFilters(
        'accessControl', {
          keyword: params.keyword,
          property: params.property
            ? { value: params.property.value, label: params.property.label } : null,
          accommodation: params.accommodation
            ? { value: params.accommodation.value, label: params.accommodation.label } : null,
          customer: params.customer
            ? { value: params.customer.value, label: params.customer.label } : null,
          from: dateRange.current.start ? dateRange.current.start.format( 'YYYY-MM-DD' ) : undefined,
          till: dateRange.current.end ? dateRange.current.end.format( 'YYYY-MM-DD' ) : undefined,
        },
      );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        dateRange.current = moment.range( moment().startOf( 'day' ), moment().startOf( 'day' ).add( '1', 'day' ) );
        setFiltersError( 'accessControl' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      showLoading.current = false;
      setDataSubmitted( new Date().valueOf() );
    }
  }, [] );

  const updateDateRangeParams = useCallback( ( params ) => {
    dateRange.current = params;
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const getTodayAccessControl = useCallback( () => {
    dateRange.current = moment.range( moment().startOf( 'day' ), moment().startOf( 'day' ).add( '1', 'day' ) );
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const fetchProperties = useCallback( ( query ) => PropertyService.getProperties( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ), [],
  );

  const fetchCustomers = useCallback(
    ( query ) => CustomerService.getCustomers( {
      elementsPerPage: 500,
      keyword: query,
    } ), [],
  );

  return (
    <>
      <AccessControlDashboardView
        data={data}
        isLoading={dataLoading}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
        initialDates={dateRange.current}
        onFetchProperties={fetchProperties}
        onFetchAccommodations={fetchAccommodations}
        onFetchCustomers={fetchCustomers}
        onTodaysAccessControl={getTodayAccessControl}
        onDateChanged={updateDateRangeParams}
      />
    </>
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.accessControl' ) || {},
} );

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( AccessControlDashboard );
