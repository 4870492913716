import React from 'react';
import { connect } from 'react-redux';

import EventDetailView from 'views/Event/Detail';
import { useFetch } from 'hooks';
import EventService from 'api/Event';
import TranslationService from 'api/Translation';
import SettingsActions from 'store/reducers/Settings';

const EventDetail = ( { parentPath, match, toggleErrorAlert } ) => {
  const eventId = match.params.id;

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => EventService.getEvent( eventId ),
    translationPromise: () => TranslationService.getCmsTranslations( eventId, 'event' ),
    toggleErrorAlert,
    conditional: !!eventId,
    deps: [eventId],
  } );

  return (
    <EventDetailView
      data={data}
      isLoading={dataLoading}
      parentPath={parentPath}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( EventDetail );
