import React from 'react';
import RoomExpensesDashboardView from 'views/Property/RoomType/Accommodation/Edit/RoomExpenses/Dashboard';

const RoomExpensesDashboard = ( {
  entityId, data,
} ) => (
  <RoomExpensesDashboardView
    name={data.entity ? data.entity.name : ''}
    entityId={entityId}
  />
);

export default RoomExpensesDashboard;
