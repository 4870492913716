import React, { useCallback, useRef } from 'react';
import useFetchTableData from 'hooks/useFetchTableData';
import { connect } from 'react-redux';
import FiltersActions from 'store/reducers/Filters';
import moment from 'moment';
import _ from 'lodash';
import { history } from 'store';
import DashboardView from 'views/Communications/Client/Notifications/Dashboard';
import NotificationService from 'api/ScheduledNotifications';
import CustomerService from 'api/Customer';
import SettingsActions from 'store/reducers/Settings';

const Dashboard = ( {
  updateFilters,
  toggleErrorAlert,
  setFiltersError,
  searchParams,
  userBookingReference,
  parentPath,
} ) => {
  /*
  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => NotificationService.getScheduledNotification( params ),
    toggleErrorAlert,
  } );
  */

  const today = moment().startOf( 'day' );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => NotificationService.getScheduledNotification( {
      ...params,
      executed: _.get( params, 'executed' )
        ? ( params.executed )
        : undefined,
      to: _.get( params, 'to.value' )
        ? params.to.value
        : undefined,
      from: params.from
        ? params.from
        : today.clone().format( 'YYYY-MM-DD' ),
      till: params.till
        ? params.till
        : today.clone().add( 1, 'month' ).format( 'YYYY-MM-DD' ),
    } ),
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'notifications', {
        executed: params.executed,
        to: params.to
          ? {
            value: params.to.value,
            label: params.to.label,
          }
          : null,
        from: params.from,
        till: params.till,
      } );
      const search = new URLSearchParams(
        _.omitBy(
          {
            ...params,
            executed: _.get( params, 'executed' ),
            to_value: _.get( params, 'to.value' ),
            to_label: _.get( params, 'to.label' ),
            to: null,
            from: params.from
              ? params.from
              : today.clone().format( 'YYYY-MM-DD' ),
            till: params.till
              ? params.till
              : today.clone().add( 1, 'month' ).format( 'YYYY-MM-DD' ),
          },
          _.isNil,
        ),
      ).toString();
      if ( search ) {
        history.replace( { search: `?${search}` } );
      }
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'notifications' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
  //  deps: [dataSubmitted],
  } );

  const urlFilters = () => {
    const params = new URLSearchParams( searchParams );
    const filters = {
      executed: params.get( 'executed' ),
      to:
        params.get( 'to_value' ) && params.get( 'to_label' )
          ? {
            value: params.get( 'to_value' ),
            label: params.get( 'to_label' ),
          }
          : null,
      from: params.get( 'from' ),
      till: params.get( 'till' ),
    };
    return filters;
  };

  const fetchCustomers = useCallback(
    ( query ) => CustomerService.getCustomers( {
      elementsPerPage: 500,
      keyword: query,
    } ),
    [],
  );

  return (
    <DashboardView
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      defaultFilters={urlFilters()}
      onFetchCustomers={fetchCustomers}
      userBookingReference={userBookingReference}
      parentPath={parentPath}
    />
  );
};

const mapStateToProps = ( { router, user } ) => ( {
  searchParams: _.get( router, 'location.search', '' ),
  userBookingReference: _.get( user, 'settings.bookingReference' ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
