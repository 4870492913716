import api from 'config/Api/Api';

const baseEndpoint = '/countries';

export default {
  getCountries( params ) {
    return api.get( baseEndpoint, params );
  },

  getCountry( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveCountry( data ) {
    return api.post( baseEndpoint, data );
  },

  updateCountry( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
};
