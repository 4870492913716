import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import classnames from 'classnames';

import { SimpleHeader, SideMenu } from 'components';
import { roles } from 'config/Constants';

export default React.memo( ( {
  data, isLoading, parentPath, pathLayout, pathname, userRole, children,
} ) => (
  <>
    {( userRole === roles.nomadsAdmin.id || userRole === roles.nomadsAdminReadOnly.id )
      && (
        <SimpleHeader
          parentLinks={[{ name: 'propertyManagers', href: parentPath }]}
        />
      )}
    <Container
      fluid
      className={classnames( {
        'app-component-loading': isLoading,
        'mt-4': userRole !== roles.nomadsAdmin.id && userRole !== roles.nomadsAdminReadOnly.id,
      } )}
    >

      {( userRole === roles.nomadsAdmin.id || userRole === roles.nomadsAdminReadOnly.id )
        ? (
          <>
            <Row>
              <Col className="d-flex justify-content-between mb-2 align-items-center">
                <h3 data-cy="property-manager-edit-title" className="m-0">{data.name}</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={5} md={3} lg={2}>
                <SideMenu
                  pathLayout={pathLayout}
                  pathname={pathname}
                  routes={[
                    { name: 'general', path: '' },
                    { name: 'finance', path: '/finance' },
                    { name: 'cities', path: '/cities' },
                    { name: 'marketplace', path: '/marketplace' },
                  ]}
                />
              </Col>
              <Col sm={7} md={9} lg={10} className="pl-md-0 pt-2 pt-sm-0">
                {children}
              </Col>
            </Row>
          </>
        ) : children}
    </Container>
  </>
) );
