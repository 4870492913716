import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/amenities';

const baseEndpointImage = ( amenityId ) => `/amenities/${amenityId}/image`;

export default {
  getAmenities( params ) {
    return api.get( baseEndpoint, params );
  },

  getAmenity( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveAmenity( data ) {
    return api.post( baseEndpoint, data );
  },

  updateAmenity( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteAmenity( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
  getImage( id ) {
    return api.get( `/amenities/${id}` );
  },
  saveImage( amenityId, data ) {
    return api.post( baseEndpointImage( amenityId ), formatFormDataBody( data ) );
  },
};
