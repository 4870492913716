import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { FormGroup } from 'reactstrap';

import { Intl } from 'components';

const SwitchInput = React.memo( ( {
  label, value, inputOnChange, inputName, onChange, formGroupClass, disabled,
  intl, translateValues, isInvalid, meta, labelBottom, hideSideLabels, ...rest
} ) => (
  <FormGroup disabled className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}>
    {label && !labelBottom
      && (
        <label
          className={`${disabled ? 'form-control-label-disabled' : 'form-control-label'} d-block`}
        >
          <Intl id={label} />
        </label>
      )}
    <div className={classnames( 'custom-toggle-container', { 'hide-side-labels': hideSideLabels } )}>
      <label
        className="d-block custom-toggle mr-1"
        data-cy={`input-${inputName}`}
      >
        <input
          {...rest}
          disabled={disabled}
          // eslint-disable-next-line no-unneeded-ternary
          checked={!value ? false : true}
          type="checkbox"
          onChange={( val ) => {
            if ( inputOnChange ) inputOnChange( val );
            if ( onChange ) onChange( val );
          }}
        />
        <span
          className="custom-toggle-slider rounded-circle"
        />
        {hideSideLabels ? null : (
          <>
            <span className="custom-toggle-slider_label-left">
              <Intl id="no" />
            </span>

            <span className="custom-toggle-slider_label-right">
              <Intl id="yes" />
            </span>
          </>
        )}
      </label>
    </div>
    {label && labelBottom
      && (
        <label className="form-control-label mb-0 mt-2 d-block font-weight-normal">
          <Intl id={label} />
        </label>
      )}

    {isInvalid
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={translateValues} />
        </div>
      )}
  </FormGroup>
) );

export default injectIntl( SwitchInput );
