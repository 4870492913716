import React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';
import {
  Form,
  Datepicker,
  FormActionsBtns,
  Intl,
  Select,
  AsyncSelect,
} from 'components';
import { required } from 'config/InputErrors';

const NewContract = ( {
  isLoading, shiftId, shiftToEdit, submitForm, onClose, teams, onFetchProperties,
} ) => (
  <div style={{ position: 'relative' }}>
    <div
      className={classNames( {
        'app-component-loading-100': isLoading,
      } )}
    >
      <h3>
        <Intl id={shiftId ? 'editShift' : 'newShift'} />
      </h3>
      <hr className="mt-0" />
      <Form
        onSubmit={submitForm}
        initialValues={shiftToEdit}
        showErrorsInFooter
        body={() => (
          <Row>
            <Col md={6}>
              {' '}
              {shiftId ? (
                <>
                  <p className="mb-1 form-control-label">
                    <Intl id="startDate" />
                  </p>
                  <div className="py-3">
                    {shiftToEdit?.date || ''}
                  </div>
                </>

              ) : (
                <Datepicker
                  field="date"
                  label="startDate"
                  dateFormat="DD/MM/YYYY"
                  validate={required}
                  startDateField={{ value: moment().subtract( 1, 'day' ) }}
                />
              )}
            </Col>
            <Col md={3}>
              <Datepicker
                dateFormat={false}
                timeFormat="H:mm"
                field="from"
                label="from"
                validate={required}
              />
            </Col>
            <Col md={3}>
              <Datepicker
                dateFormat={false}
                timeFormat="H:mm"
                field="till"
                label="till"
                validate={required}
              />
            </Col>
            <Col md={6}>
              <Select
                field="team"
                label="team"
                validate={required}
                options={teams}
              />
            </Col>
            <Col md={6}>
              <AsyncSelect
                field="location"
                label="starterLocation"
                isClearable
                defaultOptions
                loadOptions={onFetchProperties}
                labelKey={( option ) => `${option.name} (${option.address})`}
              />
            </Col>
          </Row>
        )}
        footer={(
          <FormActionsBtns
            onClickCancel={onClose}
          />
          )}
      />
    </div>
  </div>
);

export default NewContract;
