import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import AmenityRecordEditView from 'views/Places/Edit/AmenityRecords/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import AmenityRecordService from 'api/AmenityRecord';
import {
  handlePromise,
} from 'utils';

const AmenityRecordsEdit = ( {
  placeId, amenityRecordId, modalOpened,
  onReload, toggleEditModal, toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [amenityRecord, isLoading] = useFetch( {
    initialState: { },
    promise: () => AmenityRecordService.getAmenityRecord( amenityRecordId ),
    toggleErrorAlert,
    conditional: modalOpened && amenityRecordId,
    reInit: true,
    deps: [modalOpened, amenityRecordId],
  } );

  const submitForm = useCallback( async ( dataToSend ) => {
    toggleLoading( true );
    const dataFormatted = {
      description: dataToSend.description,
    };

    const amenitiesFormatted = dataToSend.amenitiesGenerated
      ? dataToSend.amenitiesGenerated.map( ( am ) => ( {
        id: am.id,
        countMin: am.countMin,
        description: am.description,
        countReal: am.countReal,
      } ) ) : [];

    const [errors, response] = await handlePromise(
      amenityRecordId
        ? AmenityRecordService.updateAmenityRecord( amenityRecordId, dataFormatted )
        : AmenityRecordService.saveAmenityRecord( { ...dataFormatted, place: placeId } ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( amenityRecordId ) {
      try {
        await Promise.all(
          amenitiesFormatted.map(
            async ( am ) => {
              await AmenityRecordService.updateAmenityRecordTemplate(
                amenityRecordId,
                am.id, {
                  countMin: am.countMin,
                  countReal: am.countReal,
                  description: am.description,
                },
              );
              return { ok: 'ok' };
            },
          ),
        );
      } catch ( error ) {
        toggleLoading( false );
        return error;
      }
    }
    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
    return onReload();
  }, [amenityRecordId, onReload, toggleInfoAlert, toggleLoading, placeId] );

  return (
    <AmenityRecordEditView
      data={amenityRecord}
      amenityRecordId={amenityRecordId}
      open={modalOpened}
      isLoading={isLoading}
      onSubmit={submitForm}
      toggleEditModal={toggleEditModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( AmenityRecordsEdit );
