import React from 'react';
import { Route, Switch } from 'react-router';

import { canList } from 'config/Ability';
import Dashboard from './Dashboard';

const AutoassignTask = ( { match } ) => (
  <Switch>
    {canList( 'settings_task_auto_assignation' )
      && (
      <Route
        path={match.url}
        render={( props ) => <Dashboard {...props} parentPath={match.url} />}
      />
      )}
  </Switch>
);

export default AutoassignTask;
