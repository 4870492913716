import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import RoleEditView from 'views/Role/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useSubmit } from 'hooks';
import RoleService from 'api/Role';
import { handlePromise } from 'utils';
import { parallel } from 'utils/async';
// import { aclWhitelist } from 'config/Constants';

const RoleEdit = ( {
  parentPath, action, match, history, toggleLoading, toggleErrorAlert, toggleInfoAlert,
} ) => {
  const entityId = match.params.id;
  const [roles, setRoles] = useState( [] );
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( false );

  useEffect( () => {
    setDataLoading( true );

    parallel( {
      aclList: async ( cb ) => {
        const [errors, response, responseData] = await handlePromise(
          RoleService.getRolesAcls( { elementsPerPage: 1000 } ),
        );
        if ( !response.ok ) return cb( errors );
        return cb( null, responseData );
      },
      entity: async ( cb ) => {
        if ( !entityId ) return cb();
        const [errors, response, responseData] = await handlePromise(
          RoleService.getRole( entityId ),
        );
        if ( !response.ok ) return cb( errors );
        return cb( null, responseData );
      },
    }, ( error, results ) => {
      if ( error ) {
        setDataLoading( false );
        return toggleErrorAlert( error );
      }

      const formattedRoles = _.chain( results.aclList )
        .map( ( item, key ) => ( { id: key, name: key } ) )
        // .intersectionBy( aclWhitelist, 'id' )
        .groupBy( ( item ) => item.id.split( ':' )[0] )
        .map( ( item, key ) => {
          const rolesFormatted = _.map( item, ( it ) => ( {
            ...it,
            simpleName: it.name.split( ':' )[1],
          } ) );
          return { name: key, roles: rolesFormatted };
        } )
        .value();

      setRoles( formattedRoles );

      const formattedData = {
        ...action === 'clone' ? {} : results.entity,
        aclList: _.cloneDeep( formattedRoles ),
      };
      if ( entityId && results.entity ) {
        const aclList = _.chain( formattedData.aclList ).map( 'roles' ).flatten().value();
        _.map( results.entity.ACLs, ( item ) => {
          const role = _.find( aclList, { id: item } );
          if ( role ) role.checked = true;
        } );
      }

      setData( formattedData );

      setDataLoading( false );
    } );
  }, [entityId, action, toggleErrorAlert] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( entityId && action !== 'clone'
      ? RoleService.updateRole( entityId, dataToSend )
      : RoleService.saveRole( dataToSend ) ),
    format: ( dataToFormat ) => {
      const dataToSend = {
        name: dataToFormat.name,
        description: dataToFormat.description,
        notRestrictedByTeamRules: dataToFormat.notRestrictedByTeamRules ? 'true' : 'false',
      };
      dataToSend.ACLs = _.chain( dataToFormat.aclList )
        .map( 'roles' )
        .flatten()
        .filter( { checked: true } )
        .map( 'id' )
        .value();
      return dataToSend;
    },
    toggleLoading,
    toggleInfoAlert,
    callback: () => history.push( parentPath ),
  } );

  return (
    <RoleEditView
      data={data}
      roles={roles}
      action={action}
      isLoading={dataLoading}
      parentPath={parentPath}
      entityId={entityId}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( RoleEdit );
