import moment from 'moment';
import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import ShiftView from 'views/Team/Edit/Shifts';
import ShiftService from 'api/Shift';
import _ from 'lodash';
import { useDelete, useFetchTableData } from 'hooks';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import { formatShifts, getSummary } from 'containers/Resource/Edit/Shift/utils';

const Shifts = ( {
  entityId, defaultFilters, toggleErrorAlert, toggleLoading, updateFilters,
} ) => {
  const filterFromDate = moment().startOf( 'day' );
  const filterToDate = moment( filterFromDate ).clone().add( 30, 'day' );
  const initialDates = useRef( moment.range( filterFromDate, filterToDate ) );
  const initialReloadError = useRef( true );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [statistics, setStatistics] = useState( {
    programmed: '00:00:00',
    elapsed: '00:00:00',
    deviation: '00:00:00',
  } );

  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => ShiftService.getShifts( {
      ...params,
      teams: [entityId],
      from: params.from || initialDates.current.start.format( 'YYYY-MM-DD' ),
      till: params.till || initialDates.current.end.format( 'YYYY-MM-DD' ),
    } ),
    format: formatShifts,
    toggleErrorAlert,
    conditional: Boolean( entityId ),
    deps: [dataSubmitted, entityId],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters(
        'team-shifts', {
          teams: entityId ? [entityId] : [],
          from: params.from,
          till: params.till,
        },
      );
    },
  } );

  useEffect( () => {
    if ( dataTable.data ) {
      setStatistics( getSummary( dataTable.data ) );
    }
  }, [dataTable] );

  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deleteShift = useDelete( {
    promise: ShiftService.deleteShift,
    callback: reload,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <ShiftView
        statistics={statistics}
        defaultFilters={defaultFilters}
        fetchData={fetchData}
        dataTable={dataTable}
        dataLoading={dataLoading}
        onDelete={deleteShift}
      />
    </>
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.team-shifts' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( Shifts );
