import React from 'react';
import { Button } from 'reactstrap';

import { Intl } from 'components';

export default React.memo( ( {
  onSave, onClickCancel,
} ) => (
  <div>
    <Button data-cy="save-form-btn" color="primary" className="btn-sm px-3" onClick={onSave}>
      <Intl id="save" />
    </Button>
    {onClickCancel
      ? (
        <Button
          color="primary"
          outline
          className="mr-3 btn-sm px-3"
          data-cy="cancel-form-btn"
          onClick={onClickCancel}
        >
          <Intl id="cancel" />
        </Button>
      ) : null}
  </div>
) );
