import React from 'react';
import { Field } from 'react-final-form';

import SwitchInput from '../utils/SwitchInput';

const SwitchField = React.memo( ( {
  field, validate, value, name, hideSideLabels, ...rest
} ) => {
  if ( field ) {
    return (
      <Field name={field} validate={validate} type="checkbox">
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && meta.submitError );
          return (
            <SwitchInput
              {...input}
              {...rest}
              value={input.checked}
              inputOnChange={input.onChange}
              inputName={input.name}
              meta={meta}
              hideSideLabels={hideSideLabels}
              isInvalid={isInvalid}
            />
          );
        }}
      </Field>
    );
  }
  return (
    <SwitchInput
      {...rest}
      value={value}
      inputName={name}
      hideSideLabels={hideSideLabels}
    />
  );
} );

export default SwitchField;
