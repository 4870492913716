import api from 'config/Api/Api';

const baseEndpoint = '/properties';

export default {
  getProperties( params ) {
    return api.get( baseEndpoint, params );
  },

  getProperty( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveProperty( data ) {
    return api.post( baseEndpoint, data );
  },

  updateProperty( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteProperty( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
