import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import moment from 'moment';

import AccessRequestEditView from 'views/AccessRequest/Edit';
import AccessRequestService from 'api/AccessRequest';
import SettingsActions from 'store/reducers/Settings';
import useFetch from 'hooks/useFetch';
import useSubmit from 'hooks/useSubmit';

const AccessRequestEdit = ( {
  modalOpened,
  toggleErrorAlert,
  toEdit,
  onCloseModal,
  toggleLoading,
  toggleInfoAlert,
  onReload,
} ) => {
  const accessRequestId = _get( toEdit, 'id' );
  const taskId = _get( toEdit, 'taskId' );
  const [data, dataLoading] = useFetch( {
    promise: () => AccessRequestService.getAccessRequest( taskId, accessRequestId ),
    toggleErrorAlert,
    conditional: !!accessRequestId && !!taskId && modalOpened,
    deps: [accessRequestId, taskId, modalOpened],
    reInit: true,
  } );

  const submit = useSubmit( {
    promise: ( dataToSend ) => AccessRequestService.updateAccessRequest(
      taskId, accessRequestId, dataToSend,
    ),
    format: ( dataToSend ) => ( {
      ...dataToSend,
      date: dataToSend.date && _isObject( dataToSend.date ) ? dataToSend.date.format( 'YYYY-MM-DD HH:mm' )
        : moment.utc( dataToSend.date ).format( 'YYYY-MM-DD HH:mm' ),
    } ),
    toggleInfoAlert,
    toggleLoading,
    deps: [accessRequestId, taskId],
    callback: onReload,
    reInit: true,
  } );

  return (
    <AccessRequestEditView
      open={modalOpened}
      data={data}
      isLoading={dataLoading}
      onSubmit={submit}
      onClose={onCloseModal}
      accessRequestId={accessRequestId}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( AccessRequestEdit );
