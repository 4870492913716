import React from 'react';
import { connect } from 'react-redux';

import CategoryEditView from 'views/Category/Edit';
import CategoryService from 'api/Category';
import TranslationService from 'api/Translation';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';

const CategoryEdit = ( {
  categoryId, modalOpened, onReload, onCloseModal, toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => CategoryService.getCategory( categoryId ),
    translationPromise: () => TranslationService.getCmsTranslations( categoryId, 'postCategory' ),
    toggleErrorAlert,
    conditional: !!categoryId && modalOpened,
    deps: [categoryId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( categoryId
      ? CategoryService.updateCategory( categoryId, dataToSend )
      : CategoryService.saveCategory( dataToSend ) ),
    toggleLoading,
    toggleInfoAlert,
    deps: [categoryId],
    translations: true,
    callback: onReload,
  } );

  return (
    <CategoryEditView
      categoryId={categoryId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CategoryEdit );
