import React, {
  createContext, useState, useCallback,
} from 'react';

export const PipelinesCRMContext = createContext( {

  filters: { page: 1, elementsPerPage: 10 },
  setFilter: () => {},
  //
  reloadFlag: new Date().valueOf(),
  onReload: () => {},
  //
  pipelineToEdit: null,
  pipelineEditModalOpened: false,
  onEditPipeline: () => {},
  togglePipelineEditModal: () => {},
} );

const PipelinesCRMContextProvider = ( {
  children,
} ) => {
  const [filters, setFilterChanges] = useState( {
    page: 1,
    elementsPerPage: 10,
  } );

  const setFilter = useCallback( ( newFilters ) => {
    setFilterChanges( ( oldFilters ) => Object
      .entries( { ...oldFilters, ...newFilters } )
      .reduce( ( acc, [key, value] ) => {
        if ( !( typeof value === 'undefined' || value === null || value === '' ) ) {
          acc[key] = value;
        }
        return acc;
      }, {} ) );
  }, [] );

  const [
    reloadFlag,
    setReloadFlag,
  ] = useState( new Date().valueOf() );

  const onReload = useCallback( () => {
    setReloadFlag( new Date().valueOf() );
  }, [] );

  /// ////////////////

  const [pipelineToEdit, setPipelineToEdit] = useState( null );
  const [pipelineEditModalOpened, setPipelineEditModalOpened] = useState( false );

  const togglePipelineEditModal = useCallback( ( ) => {
    setPipelineEditModalOpened( ( v ) => !v );
  }, [] );

  const onEditPipeline = useCallback( ( dataPipeline ) => {
    setPipelineToEdit( dataPipeline );
    togglePipelineEditModal();
  }, [togglePipelineEditModal] );

  return (
    <PipelinesCRMContext.Provider
      value={{
        filters,
        setFilter,
        //
        reloadFlag,
        onReload,
        //
        pipelineToEdit,
        pipelineEditModalOpened,
        onEditPipeline,
        togglePipelineEditModal,
      }}
    >
      {children}
    </PipelinesCRMContext.Provider>
  );
};
export default PipelinesCRMContextProvider;
