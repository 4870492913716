import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  Form,
  TextField,
  FormActionsBtns,
  EditCard,
  Intl,
  CanAccess,
  Datepicker,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    name,
    data,
    paths,
    onSubmit,
  } ) => (
    <EditCard
      headerContent={(
        <>
          <h3 className="mb-1 mt-1">
            <Intl id={canModify( 'accommodation' ) ? 'editAccommodation' : 'accommodation'} />
            {` ${name}`}
          </h3>
          <hr className="mt-3 mb-4" />
          <h3 className="m-0"><Intl id="checkInOut" /></h3>
        </>
      )}
      cardClass="card-sec-bottom"
    >
      <Form
        initialValues={data}
        onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
        showErrorsInFooter
        body={() => (
          <>
            <Row>
              <Col md={6}>
                <Datepicker
                  field="checkInFrom"
                  label="checkInOut:checkInFrom"
                  validate={required}
                  dateFormat={false}
                  timeFormat="HH:mm"
                  utc
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="checkInTo"
                  label="checkInOut:checkInTo"
                  validate={required}
                  dateFormat={false}
                  timeFormat="HH:mm"
                  utc
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Datepicker
                  field="checkOutUntil"
                  label="checkInOut:checkOutUntil"
                  validate={required}
                  dateFormat={false}
                  timeFormat="HH:mm"
                  utc
                />
              </Col>
              <Col md={6}>
                <TextField
                  field="daysBeforeArrival"
                  label="checkInOut:daysBeforeArrival"
                  type="number"
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextField
                  field="place"
                  label="checkInOut:place"
                  type="textarea"
                  validate={required}
                />
              </Col>
            </Row>
          </>
        )}
        footer={(
          <CanAccess I="edit" a="accommodation">
            <FormActionsBtns cancelTo={paths.accommodation} />
          </CanAccess>
        )}
      />
    </EditCard>
  ),
);
