import React, { useState } from 'react';
import classnames from 'classnames';
import { FormGroup, InputGroup } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { Intl } from 'components';
import InputGroupAddon from '../InputGroupAddon';

const customStyles = {
  input: () => ( {
    margin: 0,
  } ),
  control: () => ( {} ),
};

const TagsInput = React.memo( ( {
  label, formGroupClass, isInvalid, inputName, prependIcon, prependIconClass, appendIcon,
  appendIconClass, placeholder, size = 'md', value, onChange, inputOnChange, labelKey,
  options, idKey, translateOptions, intl, meta, translateValues, ...rest
} ) => {
  const [focused, setFocused] = useState( false );
  const [inputValue, setInputValue] = useState( '' );

  return (
    <FormGroup className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}>
      {label
      && (
        <label className="form-control-label">
          <Intl id={label} />
        </label>
      )}
      <InputGroup
        data-cy={`input-${inputName}`}
        className={classnames( 'input-group-merge', {
          prepend: prependIcon || prependIconClass,
          append: appendIcon || appendIconClass,
          focused,
        } )}
      >
        {prependIcon || prependIconClass ? (
          <InputGroupAddon
            addonType="prepend"
            isInvalid={isInvalid}
            icon={prependIcon}
            iconClass={prependIconClass}
          />
        ) : null}

        <CreatableSelect
          {...rest}
          menuIsOpen={false}
          isMulti
          inputValue={inputValue}
          styles={customStyles}
          classNamePrefix="custom-select"
          className={classnames( 'custom-select-container custom-select-creatable', {
            'is-invalid': isInvalid,
            'custom-select-creatable-lg': size === 'lg',
            'custom-select-creatable-sm': size === 'sm',
          } )}
          placeholder={placeholder ? intl.formatMessage( { id: placeholder } ) : null}
          onInputChange={( val ) => setInputValue( val )}
          onKeyDown={( event ) => {
            if ( !inputValue ) return;
            if ( event.key === 'Enter' || event.key === 'Tab' ) {
              if ( inputOnChange ) inputOnChange( [...value, inputValue] );
              if ( onChange ) onChange( [...value, inputValue] );

              setInputValue( '' );
              event.preventDefault();
            }
          }}
          onChange={( val ) => {
            let formattedValue = val;
            if ( val ) formattedValue = _.map( val, 'value' );
            if ( inputOnChange ) inputOnChange( formattedValue );
            if ( onChange ) onChange( formattedValue );
          }}
          onFocus={() => setFocused( true )}
          onBlur={() => setFocused( false )}
          value={_.map( value, ( item ) => ( { value: item, label: item } ) )}
        />

        {appendIcon || appendIconClass ? (
          <InputGroupAddon
            addonType="append"
            isInvalid={isInvalid}
            icon={appendIcon}
            iconClass={appendIconClass}
          />
        ) : null}
      </InputGroup>
      {isInvalid
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={translateValues} />
        </div>
      )}
    </FormGroup>
  );
} );

export default injectIntl( TagsInput );
