import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { canList } from 'config/Ability';
import Dashboard from './Dashboard';

// TODO: change ACLS

const Actions = ( { baseUrl } ) => (
  <Switch>
    {canList( 'communications' ) && (
      <Route
        path={baseUrl}
        render={( props ) => <Dashboard {...props} parentPath={baseUrl} />}
      />
    )}
  </Switch>
);

export default Actions;
