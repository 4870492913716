import api from 'config/Api/Api';

const baseEndpoint = '/teams';

export default {
  getTeams( params ) {
    return api.get( baseEndpoint, params );
  },

  getEmployees( params ) {
    return api.get( '/customers/type/employees', params );
  },

  getMembers( teamId, params ) {
    return api.get( `${baseEndpoint}/${teamId}/members`, params );
  },

  getTasks( teamId, params ) {
    return api.get( `${baseEndpoint}/${teamId}/tasks`, params );
  },

  getPermits( teamId, params ) {
    return api.get( `${baseEndpoint}/${teamId}/permits`, params );
  },

  getTeam( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveTeam( data ) {
    return api.post( baseEndpoint, data );
  },

  updateTeam( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
};
