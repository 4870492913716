import React from 'react';
import { Button, Col } from 'reactstrap';

import { Intl } from 'components';

export default React.memo( ( { size = 'md' } ) => (
  <Col md="auto" className="pt-1 pt-lg-0 pl-lg-0 d-flex align-items-end">
    <Button color="primary" id="tableToggler" size={size} className="btn-table-toogler" outline>
      <i
        className="th-icon-filters mr-2"
        style={{
          fontSize: 24, lineHeight: '16px', verticalAlign: 'middle', position: 'relative', top: -2,
        }}
      />
      <Intl id="advancedSearch" />
    </Button>
  </Col>
) );
