import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { Intl } from 'components';

export default ( {
  open, onConfirm, onClose, title, confirmBtnText, translateValues, text, ...props
} ) => (
  <SweetAlert
    show={open}
    warning
    showCancel
    confirmBtnText={confirmBtnText
      ? <Intl id={confirmBtnText} /> : <Intl id="deleteModal.confirmBtn" />}
    confirmBtnBsStyle="danger"
    cancelBtnCssClass="btn-cy-cancel"
    confirmBtnCssClass="btn-cy-confirm"
    btnSize=""
    title={title ? <Intl id={title} values={translateValues} />
      : <Intl id="deleteModal.confirmation" values={translateValues} />}
    onConfirm={onConfirm}
    onCancel={onClose}
    {...props}
  >
    <div data-cy="modal-confirmation-delete" />
    {text}
  </SweetAlert>
);
