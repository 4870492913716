import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/bookings-items';

export default {
  getContracts( id ) {
    return api.get( `${baseEndpoint}/${id}/contracts` );
  },
  saveContract( id, data ) {
    return api.post( `${baseEndpoint}/${id}/contracts`, data );
  },
  updateContract( id, contractId, data ) {
    return api.put( `${baseEndpoint}/${id}/contracts/${contractId}`, data );
  },
  deleteContract( id, contractId ) {
    return api.delete( `${baseEndpoint}/${id}/contracts/${contractId}` );
  },
  updateContractFile( id, contractId, data ) {
    return api.post( `${baseEndpoint}/${id}/contracts/${contractId}/file`, formatFormDataBody( data ) );
  },
};
