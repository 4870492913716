import React from 'react';
import { Route, Switch } from 'react-router';

import Dashboard from './Dashboard';
import Edit from './Edit';

const Checklist = ( { match } ) => (
  <Switch>
    <Route
      path={`${match.url}/edit/:id`}
      render={( props ) => <Edit {...props} parentPath={match.url} />}
    />
    <Route
      path={match.url}
      render={( props ) => <Dashboard {...props} parentPath={match.url} />}
    />
  </Switch>
);

export default Checklist;
