import React from 'react';

import { DetailCard, ModalInfo } from 'components';

const Preview = ( {
  open,
  onClose,
  data,
  settings,
  isLoading,
} ) => (
  <ModalInfo
    title="emailPreview"
    open={open}
    onClose={onClose}
    isLoading={isLoading}
  >
    <div className="preview_container  m-1 m-md-3 p-1 p-md-4">
      <DetailCard>
        {( settings.logo ) && (
          <img
            src={settings.logo}
            className="preview_logo mb-3 mt-3"
            alt="Logo"
          />
        )}
        {data.banner && (
          <img
            src={data.banner}
            className="preview_banner mb-3"
            alt="Banner"
          />
        )}
        <div className="editor-preview">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: data.bodyEmail,
            }}
          />
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: settings.signature,
            }}
          />
        </div>
      </DetailCard>
      <div className="d-flex justify-content-center mt-2 ">
        {!!settings.socialWebsite && (
        <i className=" icon-worldwide m-2" />
        )}
        {!!settings.socialFacebook && (
        <i className="icon-facebook m-2" />
        )}
        {!!settings.socialTwitter && (
        <i className="icon-twitter m-2" />
        )}
        {!!settings.socialInstagram && (
        <i className=" icon-instagram-sketched m-2" />
        )}
        {!!settings.socialYoutube && (
        <i className=" icon-youtube m-2" />
        )}
      </div>

    </div>
  </ModalInfo>
);

export default React.memo( Preview );
