/* eslint-disable no-use-before-define */
const waterfall = ( tasks = [], callback ) => {
  if ( !Array.isArray( tasks ) ) return callback( new Error( 'First argument to waterfall must be an array of functions' ) );
  if ( !tasks.length ) return callback();
  let taskIndex = 0;

  const nextTask = ( args ) => {
    const task = tasks[taskIndex];
    taskIndex += 1;
    task( ...args, next );
  };

  const next = ( err, ...args ) => {
    if ( err === false ) return;
    if ( err || taskIndex === tasks.length ) {
      return callback( err, ...args );
    }
    nextTask( args );
  };

  nextTask( [] );
};

export default waterfall;
