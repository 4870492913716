import Settings from 'containers/Team/Edit/Settings';
import Members from 'containers/Team/Edit/Members';
import Shift from 'containers/Team/Edit/Shifts';
import Tasks from 'containers/Team/Edit/Tasks';
import ShiftTemplates from 'containers/Team/Edit/ShiftTemplates';
import Permits from 'containers/Team/Edit/Permits';
import Finance from 'containers/Team/Edit/Finance';

export default [
  {
    name: 'settings',
    path: '',
    component: Settings,
    exact: true,
  },
  {
    name: 'members',
    path: '/members',
    component: Members,
  },
  {
    name: 'shiftTemplates',
    path: '/shift-templates',
    component: ShiftTemplates,
  },
  {
    name: 'programmedShifts',
    path: '/shifts',
    component: Shift,
  },
  {
    name: 'programmedTasks',
    path: '/tasks',
    component: Tasks,
  },
  {
    name: 'permits',
    path: '/permits',
    component: Permits,
  },
  {
    name: 'finance',
    path: '/finance',
    component: Finance,
  },
];
