import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { handlePromise } from 'utils';
import _ from 'lodash';
import useDelete from 'hooks/useDelete';
import FiltersActions from 'store/reducers/Filters';
import { useFetchTableData } from 'hooks';
import RoomExpensesTableView from 'views/Property/RoomType/Accommodation/Edit/RoomExpenses/Dashboard/RoomExpensesTable';
import SettingsActions from 'store/reducers/Settings';
// import { history } from 'store';
import RoomExpensesService from 'api/RoomExpenses';
import { each } from 'utils/async';
import RoomExpensesEdit from '../Edit';

const RoomExpensesTable = ( {
  entityId,
  toggleErrorAlert,
  toggleLoading,
  /* updateFilters, */ setFiltersError,
} ) => {
  const today = moment().startOf( 'day' );
  const initialReloadError = useRef( true );
  // const isInitialMount = useRef( true );
  // const showLoading = useRef( true );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [editModalOpen, setEditModalOpen] = useState( false );
  const [roomExpenseId, setRoomExpenseId] = useState( null );
  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => RoomExpensesService.getRoomExpenses( {
      ...params,
      accommodation: entityId,
      from: params.from
        ? params.from
        : today.clone().subtract( 1, 'months' ).format( 'YYYY-MM-DD' ),
      till: params.till ? params.till : today.clone().format( 'YYYY-MM-DD' ),
    } ),
    callback: ( /* params */ ) => {
      initialReloadError.current = false;
      // updateFilters( 'roomExpenses', {
      //   from: params.from,
      //   till: params.till,
      // } );
      // const search = new URLSearchParams(
      //   _.omitBy(
      //     {
      //       ...params,
      //       from: params.from
      //         ? params.from
      //         : today.clone().subtract( 1, 'months' ).format( 'YYYY-MM-DD' ),
      //       till: params.till
      //         ? params.till
      //         : today.clone().format( 'YYYY-MM-DD' ),
      //     },
      //     _.isNil,
      //   ),
      // ).toString();
      // if ( search ) {
      //   history.replace( { search: `?${search}` } );
      // }
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'roomExpenses' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
    deps: [dataSubmitted, entityId],
  } );

  const closeEditModal = useCallback( () => {
    setRoomExpenseId( null );
    setEditModalOpen( false );
  }, [] );

  const onEditRoomExpense = useCallback( ( id ) => {
    setRoomExpenseId( id );
    setEditModalOpen( true );
  }, [] );

  const deleteRoomExpense = useDelete( {
    promise: ( id ) => RoomExpensesService.deleteRoomExpense( id ),
    callback: () => {
      setDataSubmitted( new Date().valueOf() );
    },
    toggleErrorAlert,
    toggleLoading,
  } );

  /// ///////////////////////////////////////////

  const selectedRows = useRef( {} );
  const [forceRender, setForceRender] = useState( true );
  const forceReload = useCallback(
    () => setDataSubmitted( new Date().valueOf() ),
    [],
  );

  useEffect( () => {
    selectedRows.current = {};
    if ( data && data.data && data.data.length ) {
      data.data.forEach( ( item ) => {
        selectedRows.current[item.id] = item.invoice || item.invoiced || false;
      } );
    }
    setForceRender( false );
    const timer = setTimeout( () => {
      setForceRender( true );
    }, 140 );
    return () => {
      clearTimeout( timer );
    };
  }, [data] );

  const setRoomExpenses = useCallback(
    async ( selectedItems ) => {
      if (
        !data
        || !data.data
        || !selectedItems
        || !Object.entries( selectedItems ).length
      ) {
        return false;
      }
      const selectedItemsList = [];

      Object.keys( selectedItems ).forEach( ( a ) => {
        selectedItemsList.push( { id: a, invoiced: selectedItems[a] } );
      } );
      toggleLoading();
      const errors = [];
      each(
        selectedItemsList,
        async ( item, cb ) => {
          const currentRoomExpenseOrig = data.data.find(
            ( re ) => re.id === item.id,
          );
          const currentRoomExpense = { ...currentRoomExpenseOrig };
          delete currentRoomExpense.propertyManager;
          delete currentRoomExpense.date;
          delete currentRoomExpense.updated;
          delete currentRoomExpense.created;
          delete currentRoomExpense.id;
          delete currentRoomExpense.invoice;
          const [, response] = await handlePromise(
            RoomExpensesService.updateRoomExpense( item.id, {
              ...currentRoomExpense,
              invoiced: `${item.invoiced}`,
              accommodation: currentRoomExpense.accommodation.id,
            } ),
          );
          if ( !response.ok ) {
            errors.push( item.id );
          }

          return cb();
        },
        () => {
          toggleLoading();
          if ( errors.length ) {
            toggleErrorAlert( 'dataSavedWithErrors' );
          } else {
            forceReload();
          }
        },
      );
    },
    [toggleErrorAlert, toggleLoading, forceReload, data],
  );

  const toggleRowChecked = useCallback(
    ( id, value ) => {
      selectedRows.current[id] = value;
      setRoomExpenses( { [id]: value } );
    },
    [setRoomExpenses],
  );
  const toggleAllRowsChecked = useCallback( () => {
    let flagForAll = true;
    // eslint-disable-next-line no-unused-vars, max-len
    if (
      data.data
      && Object.entries( selectedRows.current ).filter( ( [, value] ) => value )
        .length === data.data.length
    ) {
      flagForAll = false;
    }

    selectedRows.current = {};
    _.map( data.data, 'id' ).forEach( ( id ) => {
      selectedRows.current[id] = flagForAll;
    } );
    setRoomExpenses( selectedRows.current );
  }, [data, setRoomExpenses] );

  /// ///////////////////////////////////////////

  const applyToValues = [
    { name: 'owner', label: 'owner', id: 'owner' },
    {
      name: 'propertyManager',
      label: 'propertyManager',
      id: 'propertyManager',
    },
    { name: 'client', label: 'client', id: 'client' },
  ];

  const setApplyToProperty = useCallback(
    async ( value, id ) => {
      toggleLoading( true );
      const [error, response] = await handlePromise(
        value
          ? RoomExpensesService.updateRoomExpense( id, {
            applyTo: value,
          } )
          : RoomExpensesService.updateRoomExpense( id, {
            applyTo: 'null',
          } ),
      );
      if ( !response.ok ) {
        toggleErrorAlert( error );
      }
      forceReload();
      toggleLoading( false );
    },
    [toggleLoading, toggleErrorAlert, forceReload],
  );

  return (
    <>
      <RoomExpensesTableView
        data={forceRender ? data : { data: [] }}
        onFetchData={fetchData}
        isLoading={dataLoading}
        onEditRoomExpense={onEditRoomExpense}
        onDelete={deleteRoomExpense}
        selectedRows={selectedRows.current}
        onToggleRowChecked={toggleRowChecked}
        onToggleAllRowsChecked={toggleAllRowsChecked}
        applyToOptions={applyToValues}
        onApplyToChange={setApplyToProperty}
      />

      <RoomExpensesEdit
        roomExpenseId={roomExpenseId}
        accommodation={entityId}
        modalOpened={editModalOpen}
        onCloseModal={closeEditModal}
        onReload={() => {
          setDataSubmitted( new Date().valueOf() );
        }}
      />
    </>
  );
};

const mapStateToProps = ( { router } ) => ( {
  searchParams: _.get( router, 'location.search', '' ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  // updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( RoomExpensesTable );
