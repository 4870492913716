import React from 'react';

import { ModalForm, Select } from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  options, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    title="newCommunityRule"
    showErrorsInFooter
    isLoading={isLoading}
    size="sm"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
    bodyClassName="overflow-visible"
    body={() => (
      <Select
        field="communityRules"
        label="selectCommunityRules"
        options={options.communityRules}
        validate={required}
        multiple
      />
    )}
  />
) );
