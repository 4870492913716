import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';

import { HorizontalMenu } from 'components';

const TranslateLayout = React.memo( ( {
  children, translationFields, contentLocale = 'es', locales = ['en'],
} ) => {
  const [selected, setSelected] = useState( 'information' );
  const translationLocales = locales.filter( ( local ) => local !== contentLocale );
  const [selectedTranslate, setSelectedTranslate] = useState( translationLocales[0].toUpperCase() );

  return (
    <>

      <div className="mb-5 border-bottom">
        <HorizontalMenu
          selected={selected}
          tabs={[{ name: 'information' }, { name: 'translations' }]}
          onChangeTab={setSelected}
        />
      </div>

      <div className={classnames( { 'd-none': selected !== 'information' } )}>{children}</div>
      {selected === 'translations' ? (
        <div>
          <HorizontalMenu
            selected={selectedTranslate}
            tabs={_.map( translationLocales, ( name ) => ( { name: name.toUpperCase() } ) )}
            onChangeTab={setSelectedTranslate}
            notTranslated
          />

          {_.map( translationLocales, ( locale ) => (
            <div key={locale} className={locale.toUpperCase() === selectedTranslate ? 'd-block mt-3' : 'd-none'}>
              {translationFields( locale )}
            </div>
          ) )}
        </div>
      ) : null}
    </>
  );
} );

const mapStateToProps = ( { user } ) => ( {
  contentLocale: _.get( user, 'settings.contentLocale' ),
  locales: _.get( user, 'settings.locales' ),
} );

export default connect( mapStateToProps )( TranslateLayout );
