import React, {
  useCallback, useState, useRef,
} from 'react';
import { connect } from 'react-redux';
import { useFetch } from 'hooks';
import AmenityInventoryDashboardView from 'views/Property/RoomType/Accommodation/Edit/AmenityInventory/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';
import AmenityGeneratedService from 'api/AmenityGenerated';
import AmenityInventoryEdit from '../Edit';

const AmenityInventoryDashboard = ( {
  entityId, data, parentIds, toggleErrorAlert, toggleLoading,
} ) => {
  const [amenityGeneratedId, setAmenityGeneratedId] = useState( null );
  const [editModalOpened, setEditModalOpened] = useState( false );
  const [idReload, setIdReload] = useState( new Date().valueOf() );
  const [keyword, setKeyword] = useState( '' );

  const [amenitiesGenerated] = useFetch( {
    initialState: { data: [] },
    promise: ( ) => AmenityGeneratedService.getAmenitiesGenerated(
      { keyword, elementsPerPage: 1000, accommodation: entityId },
    ),
    toggleErrorAlert,
    toggleLoading,
    reInit: true,
    deps: [idReload, keyword],
  } );

  const toggleEditModal = useCallback( ( id ) => {
    setAmenityGeneratedId( id );
    setEditModalOpened( !editModalOpened );
  }, [editModalOpened] );

  const reloadData = useCallback( async () => {
    if ( editModalOpened ) toggleEditModal( null );
    setIdReload( new Date().valueOf() );
  }, [editModalOpened, toggleEditModal] );

  const deleteEntity = useCallback( async ( id, cb ) => {
    toggleLoading( true );

    // eslint-disable-next-line operator-linebreak
    const [errors, response] =
      await handlePromise( AmenityGeneratedService.deleteAmenityGenerated( id ) );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );
    cb();
    setIdReload( new Date().valueOf() );
  }, [toggleErrorAlert, toggleLoading] );

  const [urlDownload, setUrlDownload] = useState( '' );

  const linkDownloadRef = useRef( null );

  const onDownloadData = useCallback( async () => {
    toggleLoading( true );
    const [errors, response, responseData] = await handlePromise(
      AmenityGeneratedService.downloadInventory( entityId ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );
    setUrlDownload( responseData?.url || '' );

    setTimeout( () => {
      if ( linkDownloadRef && linkDownloadRef.current ) {
        linkDownloadRef.current.click();
      }
    }, 200 );
  }, [entityId, toggleLoading, toggleErrorAlert, linkDownloadRef] );

  return (
    <>
      <AmenityInventoryDashboardView
        name={data.entity ? data.entity.name : ''}
        data={amenitiesGenerated.data}
        toggleEditModal={toggleEditModal}
        onDelete={deleteEntity}
        onDownloadData={onDownloadData}
        urlDownload={urlDownload}
        linkDownloadRef={linkDownloadRef}
        keyword={keyword}
        setKeyword={setKeyword}
      />

      <AmenityInventoryEdit
        accommodationId={entityId}
        amenityGeneratedId={amenityGeneratedId}
        entityAmenities={amenitiesGenerated}
        parentIds={parentIds}
        modalOpened={editModalOpened}
        toggleEditModal={toggleEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( AmenityInventoryDashboard );
