import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const CREATE_CONTACT = gql`
mutation CreateContact($email: String!, $cycle: String!, $isActive: Boolean!, $name: String!, $owner: String!, $phone: String!, $propertyManager: String!) {
  createContact(
    cycle: $cycle
    email: $email
    isActive: $isActive
    name: $name
    owner: $owner
    phone: $phone
    propertyManager: $propertyManager
  ) {
    ... on BaseError {
      message
    }
    ... on MutationCreateContactSuccess {
      data {
        success
      }
    }
  }
}
`;

const createContact = async ( props ) => {
  const { data } = await client.query( {
    query: CREATE_CONTACT,
    variables: { ...props, propertyManager: getPropertyManager() },
    fetchPolicy: 'no-cache',
  } );

  if ( data.createContact.message ) {
    return {
      ok: false,
      message: data.createContact.message,
    };
  }

  const createSuccessful = data.createContact.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
    data: { ...data.createContact.data },
  };
};

export default createContact;
