import React from 'react';
import { Modal } from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';

import { Form, FormActionsBtns, Intl } from 'components';

export default React.memo( ( {
  title, customTitle, open, onClose, children, body, isLoading, size = 'lg', bodyClassName, bodyStyle, footer, formEnabled = true,
  zIndex, ...rest
} ) => (
  <Modal
    className="modal-dialog-centered app-modal"
    data-cy="modal-form-container"
    size={size}
    isOpen={open}
    toggle={onClose}
    zIndex={zIndex}
    contentClassName={classnames( {
      'app-component-loading': isLoading,
    } )}
  >
    <div className="modal-header" data-cy="modal-form-header">
      <h6 className="modal-title" id="modal-title-default">
        {customTitle || <Intl id={title} />}
      </h6>
      <button
        aria-label="Close"
        className="close text-sm"
        data-dismiss="modal"
        type="button"
        onClick={onClose}
      >
        <i className="app-icon-close" />
      </button>
    </div>
    <Form
      {...rest}
      body={( form ) => (
        <div
          data-cy="modal-form-body"
          className={classnames( 'modal-body py-0', bodyClassName )}
          style={bodyStyle}
        >
          {children || ( body && _.isFunction( body ) ? body( form ) : body )}
        </div>
      )}
      footer={(
        <div className="modal-footer justify-content-center">
          {formEnabled
            ? footer || <FormActionsBtns onClickCancel={onClose} marginContainer={false} /> : null}
        </div>
      )}
    />
  </Modal>
) );
