/* eslint-disable implicit-arrow-linebreak */
import React /* , { useEffect, useState } */ from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField,
  ModalForm,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    isLoading,
    preloadedData,
    open,
    onClose,
    onSubmit,
    zIndex,
  } ) =>
    (
      <ModalForm
        title="respondPM"
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        size="xl"
        bodyClassName="overflow-visible"
        bodyStyle={{ minHeight: 330 }}
        onSubmit={onSubmit}
        onClose={onClose}
        initialValues={preloadedData}
        zIndex={zIndex}
        body={() => (
          <>
            <Row>
              <Col md={6}>
                <TextField
                  field="case"
                  label="subject"
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  field="message"
                  label="message"
                  type="textarea"
                  labelClass="font-weight-bold"
                  rows={5}
                  validate={required}
                />
              </Col>
            </Row>
          </>
        )}
      />
    )
  ,
);
