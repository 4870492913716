import React from 'react';
import { connect } from 'react-redux';

import ContractEditView from 'views/Property/RoomType/Accommodation/Contract/Edit';
import SettingsActions from 'store/reducers/Settings';
import ContractService from 'api/Contract';
import { useFetch, useSubmit } from 'hooks';
import { formatFloat, formatFloatToSend } from 'utils';
import moment from 'moment';

const ContractEdit = ( {
  propertyData, roomTypeData, accommodationData, paths, parentIds, history, match,
  toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const entityId = match.params.id;

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ContractService.getContract(
      parentIds.property, parentIds.roomType, parentIds.accommodation, entityId,
    ),
    format: ( toFormat ) => ( {
      ...toFormat,
      cost: formatFloat( toFormat.cost ),
    } ),
    toggleErrorAlert,
    conditional: !!entityId && !!parentIds.property && !!parentIds.roomType,
    deps: [entityId, parentIds.property, parentIds.roomType],
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( entityId
      ? ContractService.updateContract(
        parentIds.property, parentIds.roomType, parentIds.accommodation, entityId, dataToSend,
      )
      : ContractService.saveContract(
        parentIds.property, parentIds.roomType, parentIds.accommodation, dataToSend,
      ) ),
    format: ( dataToSend ) => ( {
      ...dataToSend,
      from: moment.utc( dataToSend.from ).format( 'YYYY-MM-DD' ),
      till: moment.utc( dataToSend.till ).format( 'YYYY-MM-DD' ),
      cost: formatFloatToSend( dataToSend.cost ),
    } ),
    deps: [entityId, parentIds.property, parentIds.roomType],
    toggleLoading,
    toggleInfoAlert,
    callback: () => history.push( paths.contract ),
  } );

  return (
    <ContractEditView
      data={data}
      entityId={entityId}
      propertyName={propertyData ? propertyData.name : null}
      roomTypeName={roomTypeData ? roomTypeData.name : null}
      accommodationName={accommodationData.name}
      paths={paths}
      isLoading={dataLoading}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ContractEdit );
