import React from 'react';
import moment from 'moment';

import {
  AsyncTable, CanAccess, TableActionsBtns, AddBtn, Intl, ResourceAvatar,
} from 'components';
import { canModify } from 'config/Ability';
import _ from 'lodash';

export default React.memo( ( {
  data, isLoading, onFetchData, onOpenEditModal, onOpenNewModal, onDelete,
} ) => {
  const canEdit = canModify( 'task' );
  return (
    <AsyncTable
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      disableSorting
      containerClassName="shadow-none"
      rightContent={<CanAccess I="edit" a="task"><AddBtn title="newTask" onClick={onOpenNewModal} /></CanAccess>}
      onFetchData={onFetchData}
      columns={[
        {
          Header: 'scheduledFor',
          accessor: 'date',
          Cell: ( { cell } ) => (
            cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : ''
          ),
        },
        {
          Header: 'description',
          accessor: 'description',
        },
        {
          Header: 'category',
          accessor: 'taskType.name',
        },
        {
          Header: 'assignedTo',
          accessor: 'assigned',
          Cell: ( { cell } ) => (
            <ResourceAvatar
              data={_.get( cell.value, `[${cell.value.length - 1}].employee[0]` )}
            />
          ),
        },
        {
          Header: 'status',
          accessor: 'status',
          Cell: ( { cell } ) => ( cell.value ? <Intl id={cell.value} /> : '' ),
        },
        canEdit
          ? {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[{
                  onClick: () => onOpenEditModal( { id: cell.value } ),
                  label: 'edit',
                },
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                }]}
              />
            ),
          } : {
            id: '_id', Header: null, maxWidth: 1, disableSorting: true,
          },
      ]}
    />
  );
} );
