import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, ModalForm, TranslationLayout,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  communityRuleId, data, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    initialValues={data}
    title={communityRuleId ? 'editCommunityRule' : 'newCommunityRule'}
    showErrorsInFooter
    isLoading={isLoading}
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <TranslationLayout translationFields={( locale ) => (
      <>
        <Row>
          <Col md={6}>
            <TextField
              field={`translations.${locale}.name`}
              label="name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              field={`translations.${locale}.description`}
              label="description"
              type="textarea"
              rows={4}
            />
          </Col>
        </Row>
      </>
    )}
    >
      <Row>
        <Col md={6}>
          <TextField
            field="name"
            label="name"
            validate={required}
          />
        </Col>
        <Col md={6}>
          <TextField
            field="internalName"
            label="internalName"
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            field="description"
            label="description"
            type="textarea"
            rows={4}
            validate={required}
          />
        </Col>
      </Row>
    </TranslationLayout>
  </ModalForm>
) );
