import React from 'react';
import {
  Row, Col, Container, Card, CardHeader,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  AsyncTable, AddBtn, SimpleHeader, TableGenericFilter, TableActionsBtns, CanAccess,
} from 'components';
import { canView, canModify } from 'config/Ability';

export default React.memo( ( {
  data, isLoading, propertyName, paths = {}, defaultFilters, onFetchData, onDelete,
} ) => {
  const canViewProp = canView( 'room_type' );
  const canEditDelete = canModify( 'room_type' );

  return (
    <>
      <SimpleHeader
       // title="buildings"
        parentLinks={[
          { name: 'buildings', href: paths.property },
        ]}
      />
      <Container fluid>
        {propertyName ? (
          <Card className="card-sec-top">
            <CardHeader>
              <h3 className="m-0">{propertyName}</h3>
            </CardHeader>
          </Card>
        ) : null}

        <AsyncTable
          id="roomTypes"
          containerClassName={propertyName ? 'card-sec-bottom' : null}
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={<CanAccess I="add" a="room_type"><AddBtn title="newRoomType" route={`${paths.roomType}/new`} /></CanAccess>}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter
                  filters={filters}
                  setFilter={setFilter}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'name',
              accessor: 'name',
              Cell: ( { cell } ) => {
                if ( canEditDelete ) {
                  return (
                    <Link to={`${paths.roomType}/${cell.row.original.id}/accommodations`}>
                      {cell.value}
                    </Link>
                  );
                }
                return cell.value;
              },
            },
            {
              Header: 'commercialName',
              accessor: 'commercialName',
            },
            canEditDelete || canViewProp
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => {
                  const actions = [];
                  if ( canEditDelete ) {
                    actions.push( {
                      link: `${paths.roomType}/edit/${cell.row.original.id}`,
                      isEdit: true,
                    } );
                    actions.push( {
                      isDelete: true,
                      onClick: ( cb ) => onDelete( cell.value, cb ),
                    } );
                  } else if ( canViewProp ) {
                    actions.push( {
                      link: `${paths.roomType}/edit/${cell.row.original.id}`,
                      label: 'view',
                    } );
                  }
                  return (
                    <TableActionsBtns actions={actions} />
                  );
                },
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
}, ( prevProps, nextProps ) => _.isEqual( prevProps, nextProps ) );
