/* eslint-disable import/prefer-default-export */
import EmployeeService from 'api/Employee';
import _ from 'lodash';

const getPermitRegister = async ( { customerId, year, employeePermitsSettings } ) => {
  const { data: registerData } = await EmployeeService.getPermitRegisters( customerId, {
    page: 1,
    elementsPerPage: 10,
    year,
  } );
  const registers = registerData.data;
  let permitRegFound = _.find( registers,
    ( item ) => item.employeePermitsSettings.id === employeePermitsSettings );

  if ( !permitRegFound ) {
    const { data } = await
    EmployeeService.createPermitRegister( customerId, {
      year,
      employeePermitsSettings,
      previouslyAccumulatedDays: 0,
    } );
    permitRegFound = data.data;
  }
  return {
    ok: true,
    data: permitRegFound,
    status: 200,
  };
};

export { getPermitRegister };
