import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField,
  EditCard,
  Switch,
  TranslationLayout,
} from 'components';

export default React.memo( () => (
  <EditCard title="customerApp.communityRules.title" cardClass="card-sec-bottom">
    <TranslationLayout
      translationFields={( locale ) => (
        <Row>
          <Col lg>
            <Row>
              <Col md={3}>
                <TextField
                  field={`translations.${locale}.communityRules.title`}
                  label="customerApp.basicModule.title"
                />
              </Col>
              <Col md={3}>
                <TextField
                  field={`translations.${locale}.communityRules.subtitle`}
                  label="customerApp.basicModule.subtitle"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    >
      <Row>
        <Col lg>
          <Row>
            <Col md={3}>
              <Switch
                field="communityRules.enabled"
                label="customerApp.basicModule.enabled"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextField
                field="communityRules.title"
                label="customerApp.basicModule.title"
              />
            </Col>
            <Col md={3}>
              <TextField
                field="communityRules.subtitle"
                label="customerApp.basicModule.subtitle"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </TranslationLayout>
  </EditCard>
) );
