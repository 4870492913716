import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { canList, canView } from 'config/Ability';
import Dashboard from './Dashboard';
import Edit from './Edit';
import ServiceEdit from './Edit/Service/Edit';
import ServiceNew from './Edit/Service/New';

const Booking = ( { match } ) => (
  <Switch>
    <Route
      path={`${match.url}/:bookingId/services/new`}
      render={( props ) => <ServiceNew {...props} parentPath={match.url} />}
      pathLayout={match.url}
    />
    <Route
      path={`${match.url}/:bookingId/services/edit/:id`}
      render={( props ) => <ServiceEdit {...props} parentPath={match.url} />}
      pathLayout={match.url}
    />
    {canView( 'booking' ) && (
      <Route
        path={`${match.url}/:id`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
        pathLayout={match.url}
      />
    )}
    {canList( 'booking' ) && (
      <Route path={match.url} component={Dashboard} />
    )}
  </Switch>
);

export default Booking;
