import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const DELETE_ACCOMMODATIONS = gql`
  mutation DeleteAccomodationDiscount($accomodationDiscountId: [ID!]!) {
    deleteAccomodationDiscount(accomodationDiscountId: $accomodationDiscountId) {
      ... on BaseError {
        message
      }
      ... on MutationDeleteAccomodationDiscountSuccess {
        data {
          success
        }
      }
    }
  }
`;

const deleteAccomodationsDiscount = async ( accomodationDiscountId ) => {
  const { data } = await client.query( {
    query: DELETE_ACCOMMODATIONS,
    variables: {
      accomodationDiscountId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteAccomodationDiscount.message ) {
    return {
      ok: false,
      message: data.deleteAccomodationDiscount.message,
    };
  }
  const deleteSuccessful = data.deleteAccomodationDiscount.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteAccomodationsDiscount;
