import { useCallback } from 'react';

import { handlePromise } from 'utils';

export default ( {
  promise, deps = [], callback, toggleLoading, toggleErrorAlert,
} ) => useCallback( async ( id, deleteCallback ) => {
  toggleLoading( true );

  const [errors, response] = await handlePromise( promise( id ) );
  toggleLoading( false );
  if ( !response.ok && !errors ) return toggleErrorAlert( response.message );
  if ( !response.ok ) return toggleErrorAlert( errors );
  if ( deleteCallback ) deleteCallback( );
  if ( callback ) callback( id, response );
}, [...deps] ); //eslint-disable-line
