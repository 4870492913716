import React, { useCallback } from 'react';
import { useFetchTableData } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import OccupancySeasonsView from 'views/PropertyManager/Edit/Occupancies/EditDrawer/Seasons/List';
import { connect } from 'react-redux';
import { getAllSeasonsOccupancy } from 'api/Occupancy/SeasonOccupancy';
import SeasonEdit from './Edit';

const List = ( {
  open,
  occupancyId,
  seasonId,
  onOpenNewModal,
  onOpenEditItemModal,
  dataSubmitted,
  visible,
  onDeleteItem,
  onCloseModal,
  onReloadData,
  toggleErrorAlert,
} ) => {
  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getAllSeasonsOccupancy( {
      occupancyId,
      ...params,
    } ),
    conditional: !!occupancyId,
    toggleErrorAlert,
    deps: [occupancyId, dataSubmitted],
  } );

  const reloadData = useCallback( () => {
    onReloadData();
    onCloseModal();
  }, [onReloadData, onCloseModal] );

  return (
    <>
      <OccupancySeasonsView
        visible={visible}
        data={data}
        onFetchData={fetchData}
        isDataLoading={dataLoading}
        onDeleteItem={onDeleteItem}
        onOpenNewModal={onOpenNewModal}
        onOpenEditItemModal={onOpenEditItemModal}
        onClose={onCloseModal}
      />
      <SeasonEdit
        occupancyId={occupancyId}
        seasonId={seasonId}
        open={open}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( List );
