import React, {
  useCallback, useRef, useState, useMemo,
} from 'react';
import { connect } from 'react-redux';
import {
  handlePromise,
} from 'utils';
import TagsPredefinedDashboardView from 'views/TagsPredefined/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import TagsPredefinedService from 'api/TagsPredefined';
import { useFetch } from 'hooks';
import TagsPredefinedEdit from '../Edit';

const TagsPredefinedDashboard = ( {
  toggleInfoAlert, toggleErrorAlert, toggleLoading,
} ) => {
  const tagToEditRef = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [data, dataLoading] = useFetch( {
    promise: TagsPredefinedService.getTagsPredefined,
    toggleErrorAlert,
    deps: [dataSubmitted],
    reInit: true,
  } );

  const openEditModal = useCallback( ( { tag } = {} ) => {
    tagToEditRef.current = tag;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  const dataTable = useMemo( () => {
    if ( data && data && data.length ) {
      return data.map( ( tag ) => ( { tag } ) );
    }
    return [];
  }, [data] );

  /// ///////////////////////////
  const updateTags = useCallback(
    async ( newTags ) => {
      toggleLoading( true );

      const [errors, response] = await handlePromise(
        TagsPredefinedService.updateTagsPredefined( { tagsPredefined: newTags } ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        toggleErrorAlert( errors );
        return errors;
      }

      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      reloadData();
    },
    [toggleInfoAlert, toggleErrorAlert, toggleLoading, reloadData],
  );
  /// /////////////////////////////
  const deleteTag = useCallback( ( tagToDelete ) => {
    const newTags = data.filter( ( tag ) => tag !== tagToDelete );
    updateTags( newTags );
  }, [updateTags, data] );

  return (
    <>
      <TagsPredefinedDashboardView
        dataTable={dataTable}
        isLoading={dataLoading}
        onOpenEditModal={openEditModal}
        onDelete={deleteTag}
      />

      <TagsPredefinedEdit
        data={data}
        tagToEdit={tagToEditRef.current}
        updateTags={updateTags}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( TagsPredefinedDashboard );
