import {
  AsyncTable,
  TableSelectFilter,
  Intl,
} from 'components';
import { Col } from 'reactstrap';

import React from 'react';
import SendButton from './sendButton';

export default React.memo( ( {
  data,
  months,
  isLoading,
  defaultFilters,
  onFetchData,
  yearOptions,
  clientId,
} ) => (
  <>
    <AsyncTable
      id="bookings"
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      disableSorting
      defaultFilters={defaultFilters}
      onFetchData={onFetchData}
      customFilters={( { filters, setFilter } ) => (
        <>
          <Col md={8} lg={3} className="pb-3">
            <TableSelectFilter
              name="year"
              label="year"
              placeholder="all"
              filters={filters}
              isClearable
              options={yearOptions}
              setFilter={setFilter}
            />
          </Col>
        </>
      )}
      columns={[
        {
          Header: '#',
          accessor: 'id',
          Cell: ( { cell } ) => {
            const title = `${cell.row.original.id.slice(
              cell.row.original.id.length - 6,
              cell.row.original.id.length,
            )}`;
            return (
              <a href={cell.row.original.document}>{title}</a>
            );
          },
        },
        {
          Header: 'name',
          accessor: 'name',
        },
        {
          Header: 'month',
          accessor: 'month',
          Cell: ( { cell } ) => (
            <Intl
              id={months[cell.row.original.month]}
            />
          ),
        },
        {
          Header: 'year',
          accessor: 'year',
        },
        {
          Header: 'sendingReports',
          accessor: 'sent',
          Cell: ( { cell } ) => (
            <SendButton
              defaultValue={cell.value}
              clientId={clientId}
              id={cell.row.original.id}
            />
          ),
        },
        // {
        //   Header: 'checkOut',
        //   accessor: 'till',
        //   Cell: ( { cell } ) => ( cell.value
        //     ? `${moment.utc( cell.value ).format( 'MMM DD, YYYY' )} ${
        //       cell.row.original.checkOutTime
        //     }`
        //     : '' ),
        // },
      ]}
    />
  </>
) );
