import { useFetch, useSubmit } from 'hooks';
import React from 'react';
import SettingsActions from 'store/reducers/Settings';
import SeasonEditView from 'views/PropertyManager/Edit/Discount/EditDrawer/Seasons/Edit';

import { connect } from 'react-redux';
import _ from 'lodash';
import { createSeasonDiscount, getSeasonDiscount, updateSeasonDiscount } from 'api/Discounts/SeasonDiscount';
import { formatDataToSend } from '../../format';

const SeasonEdit = ( {
  discountId,
  seasonId,
  open,
  onClose,
  onReloadData,
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    promise: () => getSeasonDiscount( seasonId ),
    toggleErrorAlert,
    conditional: !!seasonId,
    deps: [seasonId],
    reInit: true,
  } );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( seasonId
      ? updateSeasonDiscount( dataToSend ) : createSeasonDiscount( dataToSend ) ),
    format: ( dataToSend ) => formatDataToSend( discountId, seasonId, dataToSend ),
    toggleLoading,
    toggleInfoAlert,
    callback: () => {
      onReloadData();
    },
    deps: [discountId, seasonId],
  } );

  return (
    <SeasonEditView
      data={data ? data.data : undefined}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      isLoading={dataLoading}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( SeasonEdit );
