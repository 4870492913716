import React from 'react';
import { Badge } from 'reactstrap';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import {
  Intl, ConfirmationModal, BookingOpenLockerBtns,
} from 'components';
import { currencyFormat, formatFloat } from 'utils';
import getStatusColor from 'config/Constants/StatusColor';
import getExtraKeyText from './extrasMap';

const statusColor = getStatusColor( 'bookings' );

export default React.memo( ( {
  data, syncModalOpen, onSyncBooking, onToggleSyncModal, onOpenDoorModal, userCurrency,
} ) => (
  <div className="aside">
    <h2><Intl id="moreInfo" /></h2>
    <ul className="aside-list d-xl-block d-flex flex-wrap justify-content-between">
      <li className="pr-4 pr-xl-0">
        <h3><Intl id="checkin" /></h3>
        <div>
          <Badge color={statusColor( data.checkinComplete ? 'checkinCompleteYES' : 'checkinCompleteNO' )} pill><Intl id={data.checkinComplete ? 'completed' : 'pending'} /></Badge>
        </div>
      </li>
      <li className="pr-4 pr-xl-0">
        <h3><Intl id="travelReport" /></h3>
        <div>
          {data.travelReport ? <Badge color={statusColor( data.travelReport )} pill><Intl id={data.travelReport.toLowerCase()} /></Badge> : <Badge color={statusColor( 'PENDING' )} pill><Intl id="pending" /></Badge>}
        </div>
      </li>
      {!!data.price && (
      <li className="pr-4 pr-xl-0">
        <h3><Intl id="cost" /></h3>
        <div>
          {numeral( formatFloat( data.price ) ).format( currencyFormat( userCurrency ) )}
        </div>
      </li>
      )}
      {/* <li className="pr-4 pr-xl-0">
        <h3><Intl id="status" /></h3>
        <Badge
          color={statusColor( data.status )}
          pill
        >
          {!!data.status && <Intl id={data.status} />}
        </Badge>
      </li> */}
      <li className="pr-4 pr-xl-0">
        <h3><Intl id="createdAt" /></h3>
        <div>
          {!!_.get( data, 'booking.created' ) && moment( data.booking.created ).format( 'YYYY-MM-DD, h:mm a' )}
        </div>
      </li>
      {data.expectedArrival && (
      <li className="pr-4 pr-xl-0">
        <h3><Intl id="expectedArrivalTime" /></h3>
        <div>
          { data.expectedArrival }
        </div>
      </li>
      )}
      {data.departureTime && (
      <li className="pr-4 pr-xl-0">
        <h3><Intl id="departureTimeETA" /></h3>
        <div>
          { data.departureTime }
        </div>
      </li>
      )}
      {data.arrivalTransport && (
      <li className="pr-4 pr-xl-0">
        <h3><Intl id="transportation" /></h3>
        <div>
          <Intl id={data.arrivalTransport} />
        </div>
      </li>
      )}
      <BookingOpenLockerBtns data={data} onOpenDoorModal={onOpenDoorModal} />
      {
        _.get( data, 'extras.length' ) > 0 && (
        <li className="pr-4 pr-xl-0">
          <h3><Intl id="extra" /></h3>
          {_.map( data.extras, ( key ) => (
            <div key={key}>
              <Intl id={getExtraKeyText( key )} />
            </div>
          ) )}
        </li>
        )
      }
    </ul>
    <ConfirmationModal
      open={syncModalOpen}
      title="confirmSyncBooking"
      onConfirm={onSyncBooking}
      onClose={onToggleSyncModal}
    />
  </div>
) );
