import React from 'react';

import { Intl } from 'components';
import getExtraKeyText from 'views/Booking/Edit/MoreInfo/extrasMap';

export default React.memo( ( {
  executionPoint, whenPeriod, target, targetReference, targetReferenceName, duration,
  period, extra, durationMax, owner,
} ) => (
  <>
    {target === 'task' && (
      ( executionPoint !== 'instant'
        ? (
          <Intl
            id="autogenerateTaskRuleTask"
            values={{
              whenPeriod,
              day: whenPeriod > 1 ? <Intl id="days" /> : <Intl id="day" />,
              executionPoint: <Intl id={executionPoint} />,
              targetReference: targetReferenceName,
              target: <Intl id={target} />,
            }}
          />
        ) : (
          <Intl
            id="autogenerateTaskRuleTaskInstant"
            values={{
              targetReference: targetReferenceName,
            }}
          />
        ) )
    )}

    {target === 'bookedAccommodation' && ( targetReference === 'period' ) && (
      (
      <Intl
        id={durationMax > 0 ? 'autogenerateTaskRuleBookingPeriodMax' : 'autogenerateTaskRuleBookingPeriod'}
        values={{
          duration,
          durationMax,
          period,
        }}
      />
      )
    )}

    {target === 'bookedAccommodation' && ( targetReference === 'checkin' || targetReference === 'checkout' ) && (
      ( executionPoint !== 'instant'
        ? (
          <Intl
            id="autogenerateTaskRuleBookingCheckinOut"
            values={{
              whenPeriod,
              day: whenPeriod > 1 ? <Intl id="days" /> : <Intl id="day" />,
              executionPoint: <Intl id={executionPoint} />,
              targetReference: <Intl id={targetReference} />,
            }}
          />
        ) : (
          <Intl
            id="autogenerateTaskRuleBookingCheckinOutInstant"
            values={{
              targetReference: <Intl id={targetReference} />,
            }}
          />
        ) )
    )}

    {target === 'bookedAccommodation' && targetReference === 'reservation' && (
      ( executionPoint !== 'instant'
        ? (
          <Intl
            id="autogenerateTaskRuleBookingCreation"
            values={{
              whenPeriod,
              day: whenPeriod > 1 ? <Intl id="days" /> : <Intl id="day" />,
              executionPoint: <Intl id={executionPoint} />,
            }}
          />
        ) : (
          <Intl id="autogenerateTaskRuleBookingCreationInstant" />
        ) )
    )}

    {target === 'bookedService' && (
      ( executionPoint !== 'instant'
        ? (
          <Intl
            id="autogenerateTaskRuleService"
            values={{
              whenPeriod,
              day: whenPeriod > 1 ? <Intl id="days" /> : <Intl id="day" />,
              executionPoint: <Intl id={executionPoint} />,
              targetReference: targetReferenceName,
            }}
          />
        ) : (
          <Intl
            id="autogenerateTaskRuleServiceInstant"
            values={{
              targetReference: targetReferenceName,
            }}
          />
        ) )
    )}

    {
      ( extra || owner ) && (
      <>
        {' '}
        (
        { extra && (
        <>
          <Intl id="extra" />
          :
          <Intl id={getExtraKeyText( extra )} />
        </>
        )}
        { extra && owner && ( ', ' )}
        { owner && <Intl id="owner" />}
        )
        {' '}
      </>
      )
    }
  </>
) );
