import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  EditCard,
  Switch,
} from 'components';

export default React.memo(
  ( ) => (
    <EditCard title="ownerApp.modules.title" cardClass="card-sec-bottom">
      <Row>
        <Col lg>
          <Row>
            <Col md={2}>
              <Switch field="contracts" label="ownerApp.contracts" />
            </Col>
            <Col md={2}>
              <Switch disabled field="payments" label="ownerApp.payments" />
            </Col>
            <Col md={2}>
              <Switch
                field="reportIssues"
                label="ownerApp.reportIssues"
              />
            </Col>
            <Col md={2}>
              <Switch
                disabled
                field="smartLockerShared"
                label="ownerApp.smartLockerShared"
              />
            </Col>
            <Col md={2}>
              <Switch
                field="showInventory"
                label="ownerApp.showInventory"
              />
            </Col>
            <Col md={2}>
              <Switch
                field="showTutorials"
                label="ownerApp.showTutorials"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </EditCard>
  ),
);
