import _ from 'lodash';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import storage from './storage';

export const formatDataPhone = ( data, fields ) => {
  const formattedData = { ...data };

  _.map( fields, ( field ) => {
    const value = _.get( data, field );
    if ( value && value['+'] ) _.set( formattedData, field, value.slice( 1 ) );
    if ( value && value.slice( 0, 2 ) === '00' ) {
      _.set( formattedData, field, `+${value.slice( 2 )}` );
    }
  } );
  return formattedData;
};

export const formatPhone = ( value ) => {
  let newValue = value;
  if ( value && value['+'] ) newValue.slice( 1 );
  if ( value && value.slice( 0, 2 ) === '00' ) newValue = `+${value.slice( 2 )}`;

  return newValue && parsePhoneNumberFromString( newValue )
    ? parsePhoneNumberFromString( newValue ).format( 'INTERNATIONAL' )
    : newValue || '';
};

export const formatDataPhoneToSend = ( data, fields ) => {
  const formattedData = { ...data };

  _.map( fields, ( field ) => {
    let value = _.get( data, field );
    if ( value ) {
      if ( value[0] === '+' ) value = value.slice( 1 );
      _.set( formattedData, field, `00${value.replace( /[^0-9]+/g, '' )}` );
    }
  } );
  return formattedData;
};

export const formatFloat = ( value ) => {
  let newValue = _.clone( value );
  if ( value ) newValue = value / 100;
  return newValue;
};

export const formatFloatToSend = ( value ) => {
  let newValue = _.clone( value );
  if ( value ) newValue = ( Math.round( value * 100 ) / 100 ) * 100;
  return newValue;
};

export const formatDataToSend = ( formData, form, translations ) => {
  const dataToSend = {};
  _.map( _.keys( form.getState().modified ), ( key ) => {
    if ( _.get( formData[key], 'id' ) ) {
      _.set( dataToSend, key, _.get( formData, `${key}.id` ) );
    } else if ( formData.id ) {
      if (
        _.isArray( formData[key] )
        && _.get( formData, `${key}.0.value` )
        && _.get( formData, `${key}.0.label` )
      ) {
        _.set( dataToSend, key, _.map( formData[key], 'value' ) );
      } else if (
        _.isObject( formData[key] )
        && formData[key].value
        && formData[key].label
      ) {
        _.set( dataToSend, key, formData[key].value );
      } else {
        _.set( dataToSend, key, _.get( formData, key, '' ) );
      }
    } else if (
      _.isArray( formData[key] )
      && _.get( formData, `${key}.0.value` )
      && _.get( formData, `${key}.0.label` )
    ) {
      _.set( dataToSend, key, _.map( formData[key], 'value' ) );
    } else if (
      _.isObject( formData[key] )
      && formData[key].value
      && formData[key].label
    ) {
      _.set( dataToSend, key, formData[key].value );
    } else {
      _.set( dataToSend, key, _.get( formData, key ) );
    }
  } );

  if ( translations && formData.translations ) {
    const contentLocale = storage.get( 'nomads:contentLocale' );

    dataToSend.translations = _.chain( formData.translations )
      .map( ( values, lang ) => ( {
        lang,
        data: _.map( values, ( trans, field ) => ( {
          field,
          trans,
        } ) ),
      } ) )
      .filter( ( item ) => item.lang !== contentLocale )
      .value();
  } else if ( dataToSend.translations ) delete dataToSend.translations;

  return dataToSend;
};

export const formatCustomValues = ( data, fields ) => {
  const newEntity = { ...data };
  _.map( fields, ( item ) => {
    const fieldValue = _.find( data.customFieldsValue, {
      customField: { id: item.id },
    } );
    if ( fieldValue ) {
      newEntity[item.name] = fieldValue.value;
    }
  } );

  return newEntity;
};

export const formatCustomValuesToSend = ( data, customFields ) => {
  const dataToSend = { ...data };
  if ( customFields && customFields.length ) {
    _.map( customFields, ( field ) => {
      switch ( field.dataType ) {
        case 'bool':
          dataToSend[field.name] = dataToSend[field.name] ? 'true' : 'false';
          break;
        case 'date':
          dataToSend[field.name] = dataToSend[field.name]
            ? dataToSend[field.name].format( 'YYYY-MM-DD' )
            : null;
          break;
        case 'datetime':
          dataToSend[field.name] = dataToSend[field.name]
            ? dataToSend[field.name].format( 'YYYY-MM-DD H:mm:00' )
            : null;
          break;
        default:
          dataToSend[field.name] = dataToSend[field.name];
      }
    } );
  }

  return dataToSend;
};

export const formatFormDataBody = ( data ) => {
  const formData = new FormData();

  _.forEach( data, ( value, key ) => {
    if ( value ) formData.append( key, value );
  } );

  return formData;
};

export const formatArrayToSelectValues = (
  dataArray,
  keyValue = 'id',
  keyLabel = 'name',
) => {
  if ( _.isArray( dataArray ) ) {
    return dataArray.map( ( item ) => ( {
      value: _.get( item, keyValue ),
      label: _.get( item, keyLabel ),
    } ) );
  }
  return [];
};

export const formatTimeSince = ( created ) => {
  const seconds = Math.floor( ( Date.now() - created ) / 1000 );

  let num = 1;

  let interval = seconds / 31536000;
  if ( interval > 1 ) {
    num = Math.floor( interval );
    return {
      num,
      term: `timeSinceYear${num > 1 ? 's' : ''}`,
    };
  }
  interval = seconds / 2592000;
  if ( interval > 1 ) {
    num = Math.floor( interval );
    return {
      num,
      term: `timeSinceMonth${num > 1 ? 's' : ''}`,
    };
  }
  interval = seconds / 86400;
  if ( interval > 1 ) {
    num = Math.floor( interval );
    return {
      num,
      term: `timeSinceDay${num > 1 ? 's' : ''}`,
    };
  }
  interval = seconds / 3600;
  if ( interval > 1 ) {
    num = Math.floor( interval );
    return {
      num,
      term: `timeSinceHour${num > 1 ? 's' : ''}`,
    };
  }
  interval = seconds / 60;
  if ( interval > 1 ) {
    num = Math.floor( interval );
    return {
      num,
      term: `timeSinceMinute${num > 1 ? 's' : ''}`,
    };
  }
  num = seconds;
  return {
    num,
    term: `timeSinceSecond${num > 1 ? 's' : ''}`,
  };
};
