import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable, SimpleHeader, TableGenericFilter, TableToggler, Intl,
  DaterangePickerBtn, Switch, TableSelectFilter,
} from 'components';
import { notificationTypes } from 'config/Constants/Notifications';
import LinkNotification from 'components/Pages/Notification/LinkNotification';

export default React.memo( ( {
  data, isLoading, initialDates, defaultFilters,
  onFetchData, onDateChanged, isAllProcessed,
  onUpdateNotification,
} ) => (
  <>
    <SimpleHeader
      title="Notifications"
    />

    <Container fluid>
      <AsyncTable
        id="roomExpenses"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        onFetchData={onFetchData}
        customFilters={() => (
          <Row>
            <Col xs="auto">
              <DaterangePickerBtn
                position="left"
                initialValue={initialDates}
                key={initialDates}
                onChange={onDateChanged}
              />
            </Col>
            <Col><TableToggler /></Col>
          </Row>
        )}
        customToggleFilters={( { filters, setFilter } ) => (
          <Row>
            <Col md={6} lg={3} className="pb-3">
              <TableGenericFilter
                label="search"
                input
                filters={filters}
                setFilter={setFilter}
              />

            </Col>
            <Col md={4} lg={3} className="pb-3">
              <TableSelectFilter
                name="targetType"
                label="type"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                options={notificationTypes}
                setFilter={setFilter}
              />
            </Col>
          </Row>
        )}
        getRowProps={( row ) => ( { className: row.original.type === 'issue' ? 'bg-light-red' : '' } )}
        columns={[
          {
            Header: (
              <div className="text-left">
                <span className="d-inline-block" style={{ position: 'relative', top: 2 }}>
                  <Switch
                    name="header"
                    formGroupClass="m-0 pl-3 d-inline-block"
                    checked={isAllProcessed}
                    hideSideLabels
                    onChange={() => {
                      onUpdateNotification( 'allVisibles' );
                    }}
                  />
                </span>
                <span className="align-top ml-3 d-inline-block"><Intl id="markAllAsProcessed" /></span>
              </div>
            ),
            id: 'check',
            accessor: 'id',
            maxWidth: 325,
            Cell: ( { cell } ) => (
              <div className="text-left">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <Switch
                      name={cell.value}
                      formGroupClass="m-0 pl-3 d-inline-block"
                      checked={cell.row.original.status === 'processed'}
                      hideSideLabels
                      onChange={() => {
                        if ( cell.row.original.status !== 'processed' ) {
                          onUpdateNotification( cell.value );
                        }
                      }}
                    />
                  </div>
                  <div className="col">
                    <div className="ml-3">
                      <b className="text-uppercase">{cell.row.original.title}</b>
                      <div><LinkNotification item={cell.row.original} /></div>
                    </div>
                  </div>
                </div>

              </div>
            ),
          },
          {
            Header: 'type',
            accessor: 'targetType',
            Cell: ( { cell } ) => (
              <>
                <Intl id={`notification.type.${cell?.value || ''}`} />
                {cell?.value ? (
                  <div>
                    (
                    <Intl id={`notification.subtype.${cell.value}.${cell.row.original?.type || ''}`} />
                    )
                  </div>
) : null}
              </>
            ),
          },
          {
            Header: 'date',
            accessor: 'created',
            Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY' ) : '' ),
          },
          {
            Header: 'description',
            accessor: 'body',
          },

        ]}
      />
    </Container>
  </>
) );
