import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { filterBuilder } from './filterBuilder';

const GET_ALL_ACCOMODATIONS = gql`
  query AccomodationOccupancies($limit: Int!, $offset: Int!, $filters: [FilterInput!]!) {
    accomodationOccupancies(limit: $limit, offset: $offset, filters: $filters) {
      ... on BaseError {
        message
      }
      ... on QueryAccomodationOccupanciesSuccess {
        data {
          results {
            accommodation {
              id
              floor
              maximumCapacity
              minimumCapacity
              mainImage
              name
            }
            accomodationId
            occupancyId
            id
          }
          total
        }
      }
    }
  }
`;

const getAllAccommodationsOccupancy = async ( {
  page,
  elementsPerPage,
  ...rest
} ) => {
  const offset = ( page - 1 ) * elementsPerPage;
  const limit = elementsPerPage;
  const { data } = await client.query( {
    query: GET_ALL_ACCOMODATIONS,
    variables: {
      offset,
      limit,
      filters: filterBuilder( rest ),
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.accomodationOccupancies.message ) {
    return {
      ok: false,
      message: data.accomodationOccupancies.message,
    };
  }
  const accomodationOccupancies = data.accomodationOccupancies.data.results.map( ( item ) => ( {
    ...item.accommodation,
    accOccId: item.id,
  } ) ) || [];
  const elementsTotal = data.accomodationOccupancies.data.total || 0;
  return {
    ok: true,
    status: 200,
    data: { data: accomodationOccupancies, elementsTotal, elementsPerPage },
  };
};

export default getAllAccommodationsOccupancy;
