import React from 'react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { Intl } from 'components';

export default ( { isOwner, bookings, inOut } ) => (
  <Card className="dashboard-info-card">
    <CardBody className="pt-3">
      {_.map( bookings, ( booking ) => (
        <Row key={booking.id} className="align-items-center mb-3">
          <Col>
            <p className="text-sm m-0">
              <b>
                {isOwner ? (
                  <div>
                    {booking.owner.firstName}
                    {' '}
                    {booking.owner.lastName}
                  </div>
                ) : (
                  <Link to={`/bookings/list/${booking.id}`} target="_blank">
                    {booking.owner.firstName}
                    {' '}
                    {booking.owner.lastName}
                  </Link>
                )}
              </b>
              {booking.customers.length > 1
              && (
                <span>
                  {' '}
                  <Intl id="and" />
                  {' '}
                  {booking.customers.length}
                  {' '}
                  <span className="text-lowercase"><Intl id="guests" /></span>
                </span>
              )}
              <span>
                {' '}
                {inOut === 'in' ? <Intl id="willCheckIn" /> : <Intl id="willCheckOut" />}
                {' '}
                <span>{booking.currentAccommodation.name}</span>
                {' '}
                <Intl id="at" />
                {' '}
                <span className="font-weight-bold">
                  {inOut === 'in' ? booking.checkInTime : booking.checkOutTime}
                </span>
              </span>
            </p>
          </Col>
        </Row>
      ) )}
    </CardBody>
  </Card>
);
