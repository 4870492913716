import React from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

import {
  Select,
  ModalForm,
  TextField,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    entityId,
    data,
    isLoading,
    open,
    onClose,
    onSubmit,
    onChangeCountry,
    onChangeState,
    countries,
    isLoadingCountries,
    states,
    isLoadingStates,
    cities,
    isLoadingCities,
    properties,
    isLoadingProperties,
  } ) => (
    <ModalForm
      initialValues={data}
      title={entityId ? 'editProvider' : 'newProvider'}
      showErrorsInFooter
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      bodyClassName="overflow-visible"
      body={( form ) => {
        const provider = _.get( form.getFieldState( 'provider' ), 'value' );
        return (
          <>
            <Row>
              <Col md={6}>
                <Select
                  field="provider"
                  label="provider"
                  validate={required}
                  options={[
                    {
                      name: 'Salto System',
                      id: 'salto',
                    },
                    {
                      name: 'Nuki',
                      id: 'nuki',
                    },
                  ]}
                />
              </Col>
              <Col md={6}>
                <Select
                  field="property"
                  label="property"
                  options={properties}
                  isLoading={isLoadingProperties}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={4}>
                <Select
                  field="country"
                  label="country"
                  options={countries}
                  isLoading={isLoadingCountries}
                  onChange={( id ) => {
                    form.change( 'state', null );
                    form.change( 'city', null );
                    onChangeCountry( id );
                  }}
                />
              </Col>
              <Col md={6} lg={4}>
                <Select
                  field="state"
                  label="state"
                  options={states}
                  isLoading={isLoadingStates}
                  onChange={( id ) => {
                    form.change( 'city', null );
                    onChangeState( id );
                  }}
                />
              </Col>
              <Col md={6} lg={4}>
                <Select
                  field="city"
                  label="city"
                  options={cities}
                  isLoading={isLoadingCities}
                />
              </Col>

            </Row>
            <Row>
              {provider === 'nuki' && (
                <Col md={6} lg={4}>
                  <TextField field="token" label="token" validate={required} />
                </Col>
              )}
              {provider === 'salto' && (
                <>
                  <Col md={6} lg={4}>
                    <TextField
                      field="serverAddress"
                      label="server"
                      validate={required}
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <TextField
                      field="serverPort"
                      label="port"
                      type="number"
                      validate={required}
                    />
                  </Col>
                </>
              )}
            </Row>
          </>
        );
      }}
    />
  ),
);
