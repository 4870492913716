import useFetchTableData from 'hooks/useFetchTableData';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import DashboardView from 'views/Communications/Client/PredefinedNotifications/Dashboard';
import NotificationService from 'api/PredefinedNotification';
import SettingsActions from 'store/reducers/Settings';
import useDelete from 'hooks/useDelete';

const Dashoard = ( { toggleErrorAlert, toggleLoading, parentPath } ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => NotificationService.getNotifications( params ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deleteNotification = useDelete( {
    promise: NotificationService.deleteNotification,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <DashboardView
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      parentPath={parentPath}
      onDelete={deleteNotification}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( Dashoard );
