import React, { useEffect, useState } from 'react';
import Drawer from 'rc-drawer';
import {
  Intl,
  Form, FormActionsBtns,
  TextField,
  RichTextField,
} from 'components';
import { Col, Row } from 'reactstrap';
import { required } from 'config/InputErrors';

const EmailEditorView = ( {
  data, open, onClose, onSubmit,
} ) => {
  const [showContent, setShowContent] = useState( false );

  useEffect( () => {
    let timer;
    if ( open ) {
      timer = setTimeout( () => {
        setShowContent( true );
      }, 150 );
    } else {
      setShowContent( false );
    }
    return () => {
      clearTimeout( timer );
    };
  }, [open] );

  return (
    <Drawer
      open={open}
      placement="right"
      handler={false}
      maskClosable
      level={null}
      onClose={onClose}
      className="zindex-drawer"
      width={900}
    >
      {showContent ? (
        <div className="p-4">
          <h2>
            <Intl id="crm.emails.new" />
          </h2>
          <hr className="mt-0" />
          <Form
            showErrorsInFooter
            initialValues={data}
            onSubmit={onSubmit}
            body={( ) => (
              <>
                <Row className="mb-3">
                  <Col md={9}>
                    <TextField
                      label="crm.emails.sender"
                      field="sender"
                      placeholder="crm.emails.sender"
                      validate={required}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={9}>
                    <TextField
                      label="crm.emails.subject"
                      field="subject"
                      placeholder="crm.emails.subject"
                      validate={required}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="form-control-label">
                      <Intl id="crm.emails.message" />
                    </label>
                    <RichTextField
                      field="html"
                      validate={required}
                    />
                  </Col>
                </Row>
              </>
            )}
            footer={<FormActionsBtns onClickCancel={onClose} savelabel="send" />}
          />
        </div>
      ) : null}
    </Drawer>
  );
};

export default EmailEditorView;
