import React, {
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import SettingsActions from 'store/reducers/Settings';
import AuthService from 'api/Auth';
import {
  handlePromise,
} from 'utils';

import ChangePasswordView from 'views/ChangePassword';

const ChangePassword = ( {
  user, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const submitForm = useCallback( async ( formData ) => {
    toggleLoading( true );

    const username = _.get( user, 'user.user.email', '' );
    const userId = _.get( user, 'user.id', '' );
    const roles = _.get( user, 'user.user.roles', [] );
    const state = new Date().valueOf().toString();

    const [errors, response, data] = await handlePromise(
      AuthService.requestAuthCode( {
        username,
        password: formData.oldPassword,
        state,
      } ),
    );
    if ( !response.ok || data.state !== state ) {
      toggleLoading( false );
      toggleErrorAlert( errors || 'apiError' );
      return errors || 'apiError';
    }

    const [errorsCh, responseCh] = await handlePromise(
      AuthService.changePassword( userId, {
        password: formData.password,
        roles,
      } ),
    );
    if ( !responseCh.ok ) {
      toggleLoading( false );
      toggleErrorAlert( errorsCh || 'apiError' );
      return errorsCh || 'apiError';
    }

    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
  }, [user, toggleInfoAlert, toggleErrorAlert, toggleLoading] );

  return ( <ChangePasswordView onSubmit={submitForm} /> );
};

const mapStateToProps = ( { user } ) => ( {
  user,
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ChangePassword );
