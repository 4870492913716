import React from 'react';

import {
  Form,
  FormActionsBtns,
  RichTextField,
  TranslationLayout,
  EditCard,
} from 'components';

const CheckInInstructions = ( {
  data,
  onSubmit,
  parentPath,
} ) => (
  <EditCard title="checkInInstruction" cardClass="card-sec-bottom">
    {/* <DetailCard> */}
    <Form
      initialValues={data}
      showErrorsInFooter
      onSubmit={onSubmit}
      body={() => (
        <TranslationLayout
          translationFields={( locale ) => (
            <RichTextField
              field={`translations.${locale}.checkinInstructions`}
            />
          )}
        >
          <RichTextField
            field="checkinInstructions"
          />
        </TranslationLayout>
      )}
      footer={<FormActionsBtns cancelTo={parentPath} />}
    />
    {/* </DetailCard> */}
  </EditCard>
);

export default CheckInInstructions;
