import React, { useState } from 'react';
import _ from 'lodash';
import {
  EmailClipVariables, ModalForm, RichTextField, Select, TextField, TranslationLayout,
} from 'components';
import { Col, Row } from 'reactstrap';
import { required } from 'config/InputErrors';

const getNameFileFromURL = ( url = '' ) => {
  const parts = url.split( '/' );
  return parts[parts.length - 1];
};

const NewContractModal = ( {
  selectedContract, data, modalOpened, handleClose, onSubmit,
} ) => {
  const [contractType, setContractType] = useState( 'standard' );
  const [labelTempFile, setLabelTempFile] = useState( '' );
  const labelContractFile = labelTempFile || getNameFileFromURL( _.get( data, 'file' ) ) || ' ';
  const [translabelTempFile, setTransLabelTempFile] = useState( '' );

  return (
    <ModalForm
      initialValues={{ type: 'standard' }}
      title={_.isEmpty( selectedContract ) ? 'newContract' : 'editContract'}
      showErrorsInFooter
      isLoading={false}
      open={modalOpened}
      onClose={handleClose}
      bodyClassName="overflow-visible"
      onSubmit={onSubmit}
      body={( form ) => (
        <Row>
          <Col lg={contractType === 'customized' ? 8 : 12}>
            <TranslationLayout
              translationFields={( locale ) => (
                <>
                  <Row>
                    <Col md={8} lg={8}>
                      <TextField
                        field={`translations.${locale}.name`}
                        label="name"
                      />
                    </Col>
                  </Row>
                  {contractType === 'customized' && (
                  <Row>
                    <Col>
                      <RichTextField
                        field={`translations.${locale}.contractContent`}
                      />
                    </Col>
                  </Row>
                  )}
                  {contractType === 'standard' && (
                  <Row>
                    <Col md={8} lg={8}>
                      <TextField
                        label={translabelTempFile || getNameFileFromURL( _.get( data, `translations.${locale}.file` ) ) || ' '}
                        type="file"
                        labelClass="custom-file-label"
                        inputClass="custom-file-input"
                        formGroupClass="custom-file"
                        accept="application/pdf"
                        onChange={async ( val, e ) => {
                          const file = e.currentTarget.files[0];
                          if ( file ) {
                            form.change( 'translationfile', { file, locale } );
                            setTransLabelTempFile( _.get( file, 'name', 'contractFile' ) );
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  )}
                </>
              )}
            >
              <Row>
                <Col md={8} lg={8}>
                  <Select
                    field="type"
                    label="type"
                    options={[
                      { id: 'standard', name: 'standard' },
                      { id: 'customized', name: 'customized' },
                    ]}
                    translateOptions
                    onChange={setContractType}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8} lg={8}>
                  <TextField
                    field="name"
                    label="name"
                    validate={required}
                  />
                </Col>
              </Row>
              {contractType === 'customized' && (
              <Row>
                <Col>
                  <RichTextField
                    field="contractContent"
                  />
                </Col>
              </Row>
              )}
              {contractType === 'standard' && (
              <Row>
                <Col md={8} lg={8}>
                  <TextField
                    label={labelContractFile}
                    type="file"
                    labelClass="custom-file-label"
                    inputClass="custom-file-input"
                    formGroupClass="custom-file"
                    accept="application/pdf"
                    onChange={async ( val, e ) => {
                      const file = e.currentTarget.files[0];
                      if ( file ) {
                        form.change( 'file', file );
                        setLabelTempFile( _.get( file, 'name', 'contractFile' ) );
                      }
                    }}
                  />
                </Col>
              </Row>
              )}
              {contractType === 'standard' && (
              <Row>
                <Col md={4} lg={3}>
                  <TextField
                    field="signatureX"
                    label="signatureX"
                    validate={required}
                  />
                </Col>
                <Col md={4} lg={3}>
                  <TextField
                    field="signatureY"
                    label="signatureY"
                    validate={required}
                  />
                </Col>
              </Row>
              )}
            </TranslationLayout>

          </Col>
          {contractType === 'customized' && (
          <Col lg={4} className="mt-4 mt-lg-0">
            <EmailClipVariables />
          </Col>
          )}
        </Row>
      )}
    />
  );
};

export default NewContractModal;
