import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import _ from 'lodash';
import TimeAgo from 'react-timeago';
import { connect } from 'react-redux';

import {
  TextField, Intl, UserAvatar,
} from 'components';
import { getInitials, getResourceName, timeagoFormatter } from 'utils';

const TaskComments = React.memo( ( {
  data, taskClosed, userId, user, onSubmit, onDelete,
} ) => (
  <>
    <p className="font-weight-bold mb-1 form-control-label text-md">
      <Intl id="comments" />
    </p>

    {_.map( data, ( comment ) => (
      <Row className="mb-4" key={comment.id}>
        <Col md="auto" className="pr-0">
          <UserAvatar
            avatar={comment.owner.profilePicture}
            initials={getInitials( comment.owner )}
            textClassName="font-weight-500 text-sm"
            avatarSize="sm"
          />
        </Col>
        <Col>
          <p className="font-weight-500 text-sm m-0">
            {getResourceName( comment.owner )}
            {' - '}
            <TimeAgo date={comment.created} formatter={timeagoFormatter} />
          </p>
          <p className="text-sm my-1 lh-150">
            {comment.content}
          </p>
          {userId === comment.owner.id
            && (
              <Button
                color="link"
                className="font-weight-500 text-sm text-body p-0"
                onClick={() => onDelete( comment.id )}
              >
                <Intl id="delete" />
              </Button>
            )}
        </Col>
      </Row>
    ) )}

    {!taskClosed
    && (
    <Row>
      <Col md="auto" className="pr-0">
        <UserAvatar
          avatar={user.profilePicture}
          initials={getInitials( user )}
          avatarSize="sm"
        />
      </Col>
      <Col>
        <TextField
          field="newComment"
          size="md"
          placeholder="writeYourComment"
          onKeyDown={( e ) => {
            if ( e.keyCode === 13 && e.shiftKey === false ) {
              e.preventDefault();
              onSubmit();
            }
          }}
        />
      </Col>
    </Row>
    )}
  </>
) );

const mapStateToProps = ( { user } ) => ( {
  userId: _.get( user, 'user.id' ),
  user: _.get( user, 'user.user', {} ),
} );

export default connect( mapStateToProps, null )( TaskComments );
