import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import EmergencyNumbersDashboardView from 'views/EmergencyNumbers/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
import AppSurvivalTipService from 'api/appSurvivalTip';
import Edit from '../Edit';

const type = 'emergency';

const EmergencyNumbersDashboard = ( {
  parentPath,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const [selectedItem, setSelectedItem] = useState();
  const [openModal, setOpenModal] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const reloadData = () => {
    setOpenModal( false );
    setDataSubmitted( new Date().valueOf() );
  };

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AppSurvivalTipService.getList( { ...params, type } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const deleteEntity = useDelete( {
    promise: AppSurvivalTipService.deleteItem,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const closeEditModal = useCallback( () => setOpenModal( false ), [] );
  const openEditModal = useCallback( ( item ) => {
    setSelectedItem( _get( item, 'id' ) );
    setOpenModal( true );
  }, [] );

  return (
    <>
      <EmergencyNumbersDashboardView
        data={data}
        parentPath={parentPath}
        isLoading={dataLoading}
        onFetchData={fetchData}
        onDelete={deleteEntity}
        onOpenEditModal={openEditModal}
      />
      <Edit
        entityId={selectedItem}
        openModal={openModal}
        onClose={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( EmergencyNumbersDashboard );
