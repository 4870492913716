import { createSeasonRate, getAllSeasonsRate } from 'api/Rates/Seasons';
import { useFetch, useSubmit } from 'hooks';
import React, { useCallback, useState } from 'react';
import SettingsActions from 'store/reducers/Settings';
import MonthView from 'views/PropertyManager/Edit/Rates/EditDrawer/RateSeasons/Month';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';

const SeasonEdit = ( {
  visible,
  rateId,
  open,
  onClose,
  onOpenEditItemModal,
  onReloadRates,
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
} ) => {
  const [startDate, setStartDate] = useState( null );
  const [lastNight, setLastNight] = useState( null );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const formatToEvents = ( responseData ) => {
    const { data: seasons } = responseData;
    return _.map( seasons, ( item ) => ( {
      ...item,
      start: item.startDate.replace( 'Z', '' ),
      end: item.lastNight.replace( 'Z', '' ),
      title: `Price: ${item.priceOnlyAccommodation}`,
      color: '#f9b586',
    } ) );
  };

  const [data] = useFetch( {
    promise: () => getAllSeasonsRate( {
      rateId,
      page: 1,
      elementsPerPage: 30,
      fromStart: startDate,
      fromEnd: lastNight,
      tillStart: startDate,
      tillEnd: lastNight,
    } ),
    format: formatToEvents,
    toggleErrorAlert,
    conditional: visible && !!rateId,
    deps: [rateId, visible, dataSubmitted],
    reInit: true,
  } );

  const updateInitialDates = ( start, last ) => {
    setStartDate( start );
    setLastNight( last );
  };

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
    onReloadRates();
  }, [onReloadRates] );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( createSeasonRate( dataToSend ) ),
    format: ( dataToSend ) => ( {
      rateId,
      seasons: [
        {
          ...dataToSend,
          lastNight: moment( lastNight ).subtract( 1, 'day' ).toISOString(),
          startDate: moment( startDate ).toISOString(),
        },
      ],
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: reloadData,
    deps: [rateId, startDate, lastNight],
  } );

  return (
    <MonthView
      events={data || []}
      visible={visible}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      isLoading={false}
      onOpenEditItemModal={onOpenEditItemModal}
      updateDates={updateInitialDates}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( SeasonEdit );
