import React from 'react';
import RoomExpenses from 'containers/AccomodationOwner/Edit/expenses/Room';
import TaskExpenses from 'containers/AccomodationOwner/Edit/expenses/Task';

const ExpensesClientEditView = ( {
  match,
  dateRange,
  setKeywordRoomExpenses,
  setKeywordTasksExpenses,
  setAccommodationRoomExpenses,
  setAccommodationTasksExpenses,
  dataSubmitted,
  updateDateRangeParams,
  onDownload,
  isDownloading,
  forceReload,
} ) => (
  <>
    <RoomExpenses
      forceReload={forceReload}
      isDownloading={isDownloading}
      match={match}
      dateRange={dateRange}
      dataSubmitted={dataSubmitted}
      onDownload={onDownload}
      updateDateRangeParams={updateDateRangeParams}
      setKeywordRoomExpenses={setKeywordRoomExpenses}
      setAccommodationRoomExpenses={setAccommodationRoomExpenses}
    />
    <TaskExpenses
      forceReload={forceReload}
      isDownloading={isDownloading}
      match={match}
      dateRange={dateRange}
      dataSubmitted={dataSubmitted}
      updateDateRangeParams={updateDateRangeParams}
      setKeywordTasksExpenses={setKeywordTasksExpenses}
      setAccommodationTasksExpenses={setAccommodationTasksExpenses}
    />
  </>
);

export default ExpensesClientEditView;
