import React, { useCallback, useState } from 'react';
import { useFetchTableData } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import AccommodationsView from 'views/PropertyManager/Edit/Rates/EditDrawer/Accomodations';
import { connect } from 'react-redux';
import { getAllAccommodations } from 'api/Rates/Accomodations';
import AccommodationsAddModal from './Edit';

const RateSeasons = ( { rateId, onReloadRates, toggleErrorAlert } ) => {
  const [modalOpen, setModalOpen] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const onOpenModal = () => {
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getAllAccommodations( {
      rateId,
      ...params,
    } ),
    toggleErrorAlert,
    deps: [rateId, dataSubmitted],
    conditional: Boolean( rateId ),
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
    onReloadRates();
    onCloseModal();
  }, [onReloadRates] );

  return (
    <>
      <AccommodationsView
        isDataLoading={dataLoading}
        data={data}
        onFetchData={fetchData}
        onDeleteItem={() => {}}
        onOpenEditItemModal={onOpenModal}
      />
      <AccommodationsAddModal
        rateId={rateId}
        data={data}
        open={modalOpen}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( RateSeasons );
