import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AccomodationOwnerUserEditView from 'views/AccomodationOwnerUser/Edit';
import SettingsActions from 'store/reducers/Settings';
import RoleService from 'api/Role';
import CustomerService from 'api/Customer';
import PropertyManagerService from 'api/PropertyManager';
// import CustomFieldService from 'api/Settings/CustomField';
import { roles } from 'config/Constants';
import {
  formatCustomValuesToSend, formatDataPhone,
  formatDataPhoneToSend, formatDataToSend, handlePromise,
} from 'utils';
import useDelete from 'hooks/useDelete';
import { ConfirmationModal } from 'components';
import { waterfall } from 'utils/async';

const AccomodationOwnerUserEdit = ( {
  parentPath, userRole, history, match,
  toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const entityId = match.params.id;
  const [pmOptions, setPmOptions] = useState( [] );
  const [roleOptions, setRoleOptions] = useState( [] );
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( true );

  const [openModalDisable, setOpenModalDisable] = useState( false );
  const toggleModalDisable = () => setOpenModalDisable( ( state ) => !state );

  const [openModalDelete, setOpenModalDelete] = useState( false );
  const toggleModalDelete = () => setOpenModalDelete( ( state ) => !state );

  const getEntityData = useCallback( async ( cb ) => {
    if ( !entityId ) return cb( null, {} );
    const [errors, response, responseData] = await handlePromise(
      CustomerService.getCustomer( entityId ),
    );

    if ( !response.ok ) return cb( errors );
    const formattedData = formatDataPhone( responseData, ['phoneNumber'] );
    if ( formattedData.settings && formattedData.settings.locale ) {
      formattedData.locale = formattedData.settings.locale;
    }
    if ( formattedData.roles ) {
      formattedData.roles = _.find( formattedData.roles, ( item ) => item !== 'ROLE_EMPLOYEE' && item !== 'ROLE_USER' );
    }
    return cb( null, { entity: formattedData } );
  }, [entityId] );

  // const getCustomFields = useCallback( async ( entityData, cb ) => {
  //   if ( userRole === roles.nomadsAdmin.id || userRole === roles.nomadsAdminReadOnly.id ) {
  //     return cb( null, { entity: entityData } );
  //   }
  //   if ( !propertyManager || !propertyManager.id ) return cb( 'apiError' );

  //   const [errors, response, responseData] = await handlePromise(
  //     CustomFieldService.getCustomFields( {
  //       type: 'employee',
  //       propertyManager: propertyManager.id,
  //       elementsPerPage: 1000,
  //     } ),
  //   );
  //   if ( !response.ok ) return cb( errors );

  //   return cb( null, {
  //     entity: formatCustomValues( entityData, responseData.data ),
  //     customFields: responseData.data,
  //   } );
  // }, [propertyManager, userRole] );

  useEffect( () => {
    setDataLoading( true );

    waterfall( [
      getEntityData,
      // getCustomFields,
    ], ( errors, results ) => {
      setDataLoading( false );
      if ( errors ) {
        return toggleErrorAlert( errors );
      }
      setData( results );
    } );
  }, [entityId, toggleErrorAlert, getEntityData] );

  useEffect( () => {
    const getOptions = async () => {
      const [errors, response, responseData] = await handlePromise(
        PropertyManagerService.getPropertyManagers(),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setPmOptions( responseData.data );
    };
    if ( !entityId && userRole === roles.nomadsAdmin.id ) getOptions();
  }, [entityId, userRole, toggleErrorAlert] );

  useEffect( () => {
    const getRoles = async () => {
      const [errors, response, responseData] = await handlePromise(
        RoleService.getRoles( {
          elementsPerPage: 1000,
        } ),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setRoleOptions( responseData.data );
    };
    if ( userRole === roles.nomadsAdmin.id || userRole === roles.admin.id ) getRoles();
  }, [entityId, userRole, toggleErrorAlert] );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    let dataToSend = formatDataToSend( formData, form );
    dataToSend = formatDataPhoneToSend( dataToSend, ['phoneNumber'] );
    dataToSend = formatCustomValuesToSend( dataToSend, data.customFields );
    if ( entityId ) {
      delete dataToSend.email;
      dataToSend.roles = ['ROLE_OWNER'];
    } else {
      dataToSend.email = dataToSend.email.toLowerCase();
      dataToSend.roles = ['ROLE_OWNER'];
    }
    delete dataToSend.profilePicture;

    const [errors, response] = await handlePromise( entityId
      ? CustomerService.updateCustomer( entityId, dataToSend )
      : CustomerService.saveCustomer( dataToSend ) );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( !entityId || !formData.profilePicture || _.isString( formData.profilePicture ) ) {
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      return history.push( parentPath );
    }

    const imageToSend = {
      picture: formData.profilePicture ? formData.profilePicture.file : null,
    };
    const [imageErrors, imageResponse] = await handlePromise(
      CustomerService.uploadProfilePicture( entityId, imageToSend ),
    );
    toggleLoading( false );
    if ( !imageResponse.ok ) return imageErrors;

    toggleInfoAlert( 'dataSaved' );
    history.push( parentPath );
  }, [entityId, data.customFields, history, parentPath, toggleInfoAlert, toggleLoading] );

  const deleteResource = useDelete( {
    promise: () => CustomerService.deleteCustomer( entityId ),
    callback: ( id, response ) => {
      if ( response.data.deleted ) {
        history.push( parentPath );
      } else {
        toggleModalDisable();
      }
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const disableResource = useDelete( {
    promise: () => CustomerService.disableCustomer( entityId ),
    callback: () => history.push( parentPath ),
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <AccomodationOwnerUserEditView
        data={data}
        options={{ pms: pmOptions, roles: roleOptions }}
        isNomadsAdmin={userRole === roles.nomadsAdmin.id}
        parentPath={parentPath}
        isLoading={dataLoading}
        entityId={entityId}
        onSubmit={submitForm}
        onDelete={toggleModalDelete}
      />
      <ConfirmationModal
        open={openModalDelete}
        title={openModalDisable ? 'disableModal.confirmation' : ''}
        onConfirm={openModalDisable ? disableResource : deleteResource}
        onClose={toggleModalDelete}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( AccomodationOwnerUserEdit );
