import api from 'config/Api/Api';

const baseEndpoint = '/autoassign-tasks';

export default {
  getTasks( params ) {
    return api.get( baseEndpoint, params );
  },

  getTask( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveTask( data ) {
    return api.post( baseEndpoint, data );
  },

  updateTask( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteTask( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
