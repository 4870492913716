import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useFetch } from 'hooks';
import CountryService from 'api/Country';
import Dashboard from './Dashboard';
import City from './City/Dashboard';

const State = ( { match } ) => {
  const [countryData] = useFetch( {
    initialState: {},
    promise: () => CountryService.getCountry( match.params.id ),
    conditional: !!match.params.id,
    deps: [match.params.id],
  } );

  return (
    <Switch>
      <Route
        path={`${match.url}/:id/cities`}
        render={( props ) => (
          <City
            {...props}
            countryId={match.params.id}
            countryData={countryData}
          />
        )}
      />
      <Route
        path={match.url}
        render={( props ) => (
          <Dashboard
            {...props}
            countryId={match.params.id}
            countryData={countryData}
          />
        )}
      />
    </Switch>
  );
};

export default State;
