import api from 'config/Api/Api';

const baseEndpoint = '/tasks';

export default {
  getTasks( params = {} ) {
    return api.get( baseEndpoint, { ...params } );
  },

  getTask( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveTask( data ) {
    return api.post( baseEndpoint, data );
  },

  updateTask( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  assignTask( id, resourceId, team ) {
    return api.post( `${baseEndpoint}/${id}/assign/${resourceId}`, { team } );
  },

  deleteTask( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
  saveBulk( data ) {
    return api.post( `${baseEndpoint}/assign-bulk`, data );
  },
  deleteBulkTasks( data ) {
    return api.post( `${baseEndpoint}/bulk`, data );
  },
};
