import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import ZoneView from 'views/PropertyManager/Edit/City/Zone';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import ZoneService from 'api/Settings/Zone';

const Zone = ( {
  pmId, cityId, modalOpened, onCloseModal,
  toggleErrorAlert, toggleLoading, toggleInfoAlert,
} ) => {
  const [editOpened, setEditOpened] = useState();
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const editData = useRef( {} );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ZoneService.getZones( cityId, {
      propertyManager: pmId,
      elementsPerPage: 1000,
    } ),
    toggleErrorAlert,
    conditional: !!cityId,
    deps: [cityId, dataSubmitted],
  } );

  const openEdit = useCallback( async ( entity ) => {
    editData.current = entity;
    setEditOpened( !editOpened );
  }, [editOpened] );

  const closeEdit = useCallback( () => {
    setEditOpened( false );
  }, [] );

  const closeModal = useCallback( () => {
    onCloseModal();
    setEditOpened( false );
  }, [onCloseModal] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( editData.current && editData.current.id
      ? ZoneService.updateZone( cityId, editData.current.id, dataToSend )
      : ZoneService.saveZone( cityId, dataToSend ) ),
    format: ( dataToSend ) => ( {
      ...dataToSend,
      description: dataToSend.name,
      slug: dataToSend.name.toLowerCase().split( ' ' ).join( '-' ),
      location: dataToSend.name,
      geo: {
        latitude: 1,
        longitude: 1,
      },
    } ),
    toggleLoading,
    toggleInfoAlert,
    deps: [cityId],
    callback: () => {
      setDataSubmitted( new Date().valueOf() );
      closeEdit();
    },
  } );

  return (
    <ZoneView
      data={data.data || []}
      editData={editData.current}
      open={modalOpened}
      editOpen={editOpened}
      isLoading={dataLoading}
      onOpenEdit={openEdit}
      onCloseEdit={closeEdit}
      onSubmit={submitForm}
      onClose={closeModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( Zone );
