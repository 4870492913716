import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  ModalForm, AsyncSelect, Select,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import { getResourceName } from 'utils';

export default React.memo( ( {
  taskId, data, isLoading, open, onClose, onSubmit, taskTypes,
  isLoadingTaskTypes, onFetchProperties, onFetchAccommodations, onFetchEmployees,
} ) => (
  <ModalForm
    initialValues={data}
    title={taskId ? 'editRule' : 'newRule'}
    showErrorsInFooter
    isLoading={isLoading}
    bodyClassName="overflow-visible"
    open={open}
    onClose={onClose}
    onSubmit={canModify( 'settings_task_auto_assignation' ) ? onSubmit : () => {}}
    formEnabled={canModify( 'task_type' )}
  >
    <Row>
      <Col md={6}>
        <Select
          field="taskType"
          label="taskType"
          options={taskTypes}
          isLoading={isLoadingTaskTypes}
          optgroup
          validate={required}
        />
      </Col>
      <Col md={6}>
        <AsyncSelect
          field="employee"
          label="resource"
          defaultOptions
          validate={required}
          labelKey={getResourceName}
          loadOptions={onFetchEmployees}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <AsyncSelect
          field="properties"
          label="properties"
          defaultOptions
          multiple
          loadOptions={onFetchProperties}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <AsyncSelect
          field="accommodations"
          label="accommodations"
          defaultOptions
          multiple
          loadOptions={onFetchAccommodations}
        />
      </Col>
    </Row>
  </ModalForm>
) );
