import React, {
  useState, useCallback, useContext, useEffect,
} from 'react';
import {
  Col, Row, Button, UncontrolledCollapse,
} from 'reactstrap';
import moment from 'moment';
import {
  Intl, Select,
  DaterangePickerBtn,
  TableGenericFilter,
  TableSelectFilter,
  TableToggler,
} from 'components';
import {
  contactBusinnesStatuses,
} from 'config/Constants/ContactBusinnesStages';
import { BusinnesCRMContext } from 'containers/CRM/Business/Dashboard/context';

const BusinnessFilters = () => {
  const {
    filters,
    setFilter,
    selectedView,
    onEditBusiness,
    //
    pipelines,
    pipelinesLoading,
    pipelineSelected,
  } = useContext( BusinnesCRMContext );

  const [visibleElementsPerPage, setVisibleElementsPerPage] = useState( false );

  useEffect( () => {
    if ( selectedView === 2 ) {
      setFilter( {
        page: 1,
        elementsPerPage: 999,
      } );
      setVisibleElementsPerPage( false );
    } else {
      setFilter( {
        page: 1,
        elementsPerPage: 10,
      } );
      setVisibleElementsPerPage( true );
    }
  }, [setFilter, selectedView] );

  const changeFilter = useCallback( ( key, value ) => {
    setFilter( { [key]: value } );
  }, [setFilter] );

  return (
    <div className="p-4">
      <Row className="justify-content-between align-items-end">
        <Col>
          <Row className="align-items-end">
            <Col md={4} lg={2}>
              <TableSelectFilter
                name="pipelineId"
                label="crm.businnesStatuses.pipeline"
                placeholder="all"
                filters={filters}
                isClearable
                options={pipelines}
                isLoading={pipelinesLoading}
                setFilter={( key, value ) => {
                  setFilter( { pipelineId: value, businessStage: null } );
                }}
              />
            </Col>
            <Col md={5} lg={5} className="pl-0">
              <TableGenericFilter label="crm.businnesStatuses.search" filters={filters} setFilter={changeFilter} />
            </Col>
            <Col md={3} lg={3} className="pl-0">
              <TableToggler />
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <Row className="align-items-center">
            <Col xs="auto">
              <Button
                color="primary"
                onClick={() => { onEditBusiness( null ); }}
              >
                <Intl id="crm.businness.createBusinness" />
              </Button>
            </Col>
            {visibleElementsPerPage && (
            <Col xs="auto">
              <div className="pagination d-flex flex-wrap align-items-center">
                <span className="text-right mr-3">
                  <Intl id="itemsPer" />
                  <br />
                  <Intl id="perPage" />
                </span>
                <Select
                  name="size"
                  size="md"
                  value={filters?.elementsPerPage || 10}
                  formGroupClass="m-0 pagination-select"
                  options={[
                    { id: 10, name: 10 },
                    { id: 20, name: 20 },
                  ]}
                  onChange={( elementsPerPage ) => {
                    setFilter( {
                      page: 1,
                      elementsPerPage,
                    } );
                  }}
                />
              </div>
            </Col>
            )}
          </Row>

        </Col>

      </Row>
      <UncontrolledCollapse
        toggler="#tableToggler"
      >
        <hr className="mt-4 mb-3 border-top" />
        <Row className="ml-0">
          <Col md={5} lg={2} className="pl-0 py-2">
            <TableSelectFilter
              name="businessStage"
              label="crm.businnesStatuses.stage"
              placeholder="all"
              filters={filters}
              isClearable
              options={pipelineSelected
                ? pipelineSelected.stages.map( ( stage ) => ( { id: stage, name: stage } ) ) : []}
              setFilter={changeFilter}
            />
          </Col>
          <Col md={5} lg={2} className="pl-0 py-2">
            <TableSelectFilter
              name="state"
              label="state"
              placeholder="all"
              filters={filters}
              isClearable
              options={contactBusinnesStatuses}
              translateOptions
              setFilter={changeFilter}
            />
          </Col>
          <Col md={6} lg={4} className="pl-0 py-2">
            <DaterangePickerBtn
              position="left"
              label="crm.businnesStatuses.dateBetween"
              initialValue={moment.range( filters.from || moment().subtract( 7, 'days' ), filters.till || moment().add( 7, 'days' ) )}
              key={filters.from + filters.till}
              input
              onChange={( values ) => {
                setFilter( {
                  from: moment( values.start ).format( 'YYYY-MM-DD' ),
                  till: moment( values.end ).format( 'YYYY-MM-DD' ),
                } );
              }}
            />
          </Col>
          <Col md={6} lg={4} className="pl-0 py-2">
            <DaterangePickerBtn
              position="left"
              label="crm.businnesStatuses.closeDateBetween"
              initialValue={moment.range( filters.closeDateFrom || moment().subtract( 7, 'days' ), filters.closeDateTill || moment().add( 7, 'days' ) )}
              key={filters.closeDateFrom + filters.closeDateTill}
              input
              onChange={( values ) => {
                setFilter( {
                  closeDateFrom: moment( values.start ).format( 'YYYY-MM-DD' ),
                  closeDateTill: moment( values.end ).format( 'YYYY-MM-DD' ),
                } );
              }}
            />
          </Col>
        </Row>
      </UncontrolledCollapse>
    </div>
  );
};

export default BusinnessFilters;
