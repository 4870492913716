import React from 'react';
import classnames from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  UncontrolledCollapse,
} from 'reactstrap';

import { Intl, Select } from 'components';
import Pagination from '../Pagination';
import TableUI from '../TableUI';

export default React.memo(
  ( {
    getTableProps,
    getRowProps,
    headerGroups,
    page,
    pageIndex,
    prepareRow,
    gotoPage,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    pageCount,
    setPageSize,
    pageSize,
    filters,
    customToggleFilters,
    setFilter,
    setMultipleFilters,
    isLoading,
    customFilters,
    titleContent,
    rightContent,
    endContent,
    containerClassName,
    cardHeaderClassName = 'border-0 p-4',
    cardFooterClassName = 'py-4 px-4 border-0',
    totalElements,
    customFiltersOpened,
    useElementsPerPage = true,
    sizeTableToggler = 'md',
    uncenterHeaders,
  } ) => (
    <Card
      data-cy="table-container"
      className={classnames( containerClassName, {
        'app-component-loading': isLoading,
      } )}
    >
      <CardHeader className={cardHeaderClassName}>
        <div className="d-flex flex-wrap align-items-end">
          <div className="flex-fill d-none d-lg-block">
            {titleContent}
            {customFilters && (
              customFilters( { filters, setFilter, setMultipleFilters } )
            )}
          </div>
          <div className="flex-fill d-block d-lg-none">
            {customFilters && (
              <Button color="primary" id="tableTogglerFilter" size={sizeTableToggler} className="btn-table-toogler" outline>
                <i className="fas fa-search mr-2" />
                <Intl id="search" />
              </Button>
            )}
          </div>
          {!!rightContent && (
            <div className="ml-2 mr-4 mt-0 p-0 d-block">
              {rightContent}
            </div>
          )}
          {useElementsPerPage && (
            <div className="pagination d-flex flex-wrap align-items-center">
              <span className="text-right mr-3">
                <Intl id="itemsPer" />
                <br />
                <Intl id="perPage" />
              </span>
              <Select
                name="size"
                size="md"
                value={pageSize}
                formGroupClass="m-0 pagination-select"
                options={[
                  { id: 10, name: 10 },
                  { id: 20, name: 20 },
                ]}
                onChange={( value ) => {
                  gotoPage( 0 );
                  setPageSize( value );
                }}
              />
            </div>
          ) }
          {!!endContent && (
            <div className="ml-1 mr-1 mt-0 p-0 w-100 d-block">
              {endContent}
            </div>
          )}
        </div>

        {customFilters && (
          <div className="d-lg-none">
            <UncontrolledCollapse
              toggler="#tableTogglerFilter"
              defaultOpen={customFiltersOpened}
            >
              <hr className="mt-4 mb-3 border-top" />
              {customFilters( { filters, setFilter, setMultipleFilters } )}
            </UncontrolledCollapse>
          </div>
        )}

        {customToggleFilters && (
          <UncontrolledCollapse
            toggler="#tableToggler"
            defaultOpen={customFiltersOpened}
          >
            <hr className="mt-4 mb-3 border-top" />
            {customToggleFilters( { filters, setFilter, setMultipleFilters } )}
          </UncontrolledCollapse>
        ) }
      </CardHeader>

      <CardBody className="p-0 border-0">
        <TableUI
          page={page}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          getRowProps={getRowProps}
          uncenterHeaders={uncenterHeaders}
        />
      </CardBody>

      <CardFooter className={cardFooterClassName}>
        <Pagination
          totalElements={totalElements}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
        />
      </CardFooter>
    </Card>
  ),
);
