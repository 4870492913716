/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import moment from 'moment';

const parseTime = ( text ) => ( text.length > 0 ? { hours: parseInt( _.split( text, ':' )[0], 10 ), minutes: parseInt( _.split( text, ':' )[1], 10 ) } : { hours: 0, minutes: 0 } );

const getTotalTime = ( till, from ) => {
  if ( till.length > 0 && from.length > 0 ) {
    const fromDate = moment().set( parseTime( from ) );
    const tillDate = moment().set( parseTime( till ) );
    return tillDate.diff( fromDate );
  }
  return 0;
};

const formatShifts = ( dataToformat ) => {
  const dataToSend = _.map( dataToformat.data, ( item ) => ( {
    ...item,
    programmedTime: getTotalTime( item.till, item.from ),
    elapsedTime: getTotalTime( item.realTill, item.realFrom ),
  } ) );
  return {
    ...dataToformat,
    data: dataToSend,
  };
};

const formatTimeString = ( hours, minutes, seconds ) => {
  const formattedHours = hours.toString().length === 1 ? `0${hours}` : hours;
  const formattedMinutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const breakDownTime = ( time ) => {
  const hours = Math.floor( moment.duration( time ).asHours() );
  const minutes = Math.floor( moment.duration( time ).minutes() );
  const seconds = Math.floor( moment.duration( time ).seconds() );
  return formatTimeString( hours, minutes, seconds );
};

const breakDownDeviation = ( h, m, s ) => {
  const seconds = s % 60;
  const totalMinutes = m + ( s - seconds ) / 60;
  const minutes = totalMinutes % 60;
  const hours = h + ( m - minutes ) / 60;
  return formatTimeString( hours, minutes, seconds );
};

const getSummary = ( data ) => {
  const programmed = data.reduce( ( acc, curr ) => (
    curr.programmedTime > 0 ? acc + curr.programmedTime : acc ), 0 );
  const elapsed = data.reduce( ( acc, curr ) => (
    curr.elapsedTime > 0 ? acc + curr.elapsedTime : acc ), 0 );
  const deviationHours = data.reduce( ( acc, curr ) => (
    curr.deviationHora > 0 ? acc + curr.deviationHora : acc ), 0 );
  const deviationMinutes = data.reduce( ( acc, curr ) => (
    curr.deviationMinute > 0 ? acc + curr.deviationMinute : acc ), 0 );
  const deviationSeconds = data.reduce( ( acc, curr ) => (
    curr.deviationSeg > 0 ? acc + curr.deviationSeg : acc ), 0 );
  return {
    programmed: breakDownTime( programmed ),
    elapsed: breakDownTime( elapsed ),
    deviation: breakDownDeviation( deviationHours, deviationMinutes, deviationSeconds ),
  };
};

export { formatShifts, getSummary };
