import React, { useState, useCallback } from 'react';
import {
  Row, Col, Button, Collapse,
} from 'reactstrap';
import moment from 'moment';

import {
  TextField,
  Select,
  ModalForm,
  AsyncSelect,
  Datepicker,
  Switch,
  Intl,
  AddBtn,
  ExtraServicesList,
} from 'components';
import { required } from 'config/InputErrors';
import { bookingStatuses, otaStatuses, currencies } from 'config/Constants';
import { getResourceName } from 'utils';
import NewCustomer from 'containers/Booking/New/newCustomer';
import classNames from 'classnames';

export default React.memo(
  ( {
    isLoading,
    toggleLoading,
    open,
    onClose,
    onSubmit,
    onFetchAccommodations,
    onFetchCustomers,
    onSetCurrentRoomType,
    onSetNewCustomerId,
  } ) => {
    const [modalNewCustomerOpen, setModalNewCustomerOpen] = useState( false );
    const [newOwnerName, setNewOwnerName] = useState( null );

    const [priceOpen, setPriceOpen] = useState( false );

    const [extraServices, setExtraServices] = useState( [] );

    const toggleModalNewCustomer = useCallback( () => {
      setModalNewCustomerOpen( ( v ) => !v );
    }, [setModalNewCustomerOpen] );

    const onReloadCustomer = useCallback(
      ( customer ) => {
        setNewOwnerName( `${customer.firstName} ${customer.lastName}` );
        onSetNewCustomerId( customer.id );
      },
      [setNewOwnerName, onSetNewCustomerId],
    );

    return !modalNewCustomerOpen ? (
      <ModalForm
        title="newBooking"
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        size="xl"
        bodyClassName="overflow-visible"
        bodyStyle={{ minHeight: 330 }}
        onSubmit={( formData, form ) => {
          onSubmit( formData, form, extraServices );
        }}
        onClose={onClose}
        initialValues={{ capacity: 1, ownerName: newOwnerName || '' }}
        body={() => (
          <>
            <Row>
              <Col md={12}>
                {newOwnerName ? (
                  <Row>
                    <Col md={9}>
                      <TextField
                        field="ownerName"
                        label="owner"
                        labelClass="font-weight-bold"
                        disabled
                      />
                    </Col>
                    <Col md={3}>
                      <Button
                        style={{ marginTop: '25px' }}
                        size="sm"
                        color="primary"
                        onClick={() => {
                          setNewOwnerName( null );
                          onSetNewCustomerId( null );
                        }}
                      >
                        <Intl id="orSelectAnotherOwner" />
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col md={9}>
                      <AsyncSelect
                        field="owner"
                        label="owner"
                        labelClass="font-weight-bold"
                        defaultOptions
                        validate={required}
                        selectValue={{
                          id: '60c8b4704c429d2a38132353',
                          name: 'pablo',
                        }}
                        loadOptions={onFetchCustomers}
                        labelKey={getResourceName}
                      />
                    </Col>
                    <Col md={3}>
                      <div className="text-right" style={{ paddingTop: 31 }}>
                        <AddBtn
                          title="newOwner"
                          onClick={toggleModalNewCustomer}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col md={7}>
                <AsyncSelect
                  field="accommodation"
                  label="accommodation"
                  labelClass="font-weight-bold"
                  defaultOptions
                  validate={required}
                  loadOptions={onFetchAccommodations}
                  labelKey={( option ) => `${option.name} (${option.roomType.property.propertyManagerCityZone.name} - ${option.roomType.property.propertyManagerCityZone.propertyManagerCity.city.name})`}
                  onChange={( v ) => {
                    if (
                      v.original
                      && v.original.roomType
                      && v.original.roomType.id
                    ) {
                      onSetCurrentRoomType( v.original.roomType.id );
                    }
                  }}
                />
              </Col>
              <Col md={2}>
                <TextField
                  field="capacity"
                  label="capacity"
                  type="number"
                  labelClass="font-weight-bold"
                  min={1}
                  max={8}
                  validate={required}
                />
              </Col>
              <Col md={3}>
                <Select
                  field="ota"
                  label="ota"
                  translateOptions
                  options={otaStatuses}
                  validate={required}
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="from"
                  label="from"
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  utc
                  labelClass="font-weight-bold"
                  validate={required}
                  startDateField={{ value: moment().subtract( '1', 'day' ) }}
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="till"
                  label="till"
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  utc
                  labelClass="font-weight-bold"
                  validate={required}
                  startDateField={{ value: moment().subtract( '1', 'day' ) }}
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="checkInTime"
                  label="checkIn"
                  dateFormat={false}
                  timeFormat="HH:mm"
                  utc
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="checkOutTime"
                  label="checkOut"
                  dateFormat={false}
                  timeFormat="HH:mm"
                  utc
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="departureTime"
                  label="departureTime"
                  dateFormat={false}
                  timeFormat="HH:mm"
                  utc
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="expectedArrival"
                  label="expectedArrivalTime"
                  dateFormat={false}
                  timeFormat="HH:mm"
                  utc
                />
              </Col>
              <Col md={3}>
                <Select
                  field="status"
                  label="status"
                  translateOptions
                  options={bookingStatuses}
                />
              </Col>
              <Col md={3}>
                <Switch field="checkinComplete" label="checkinComplete" />
              </Col>
              <Col md={3}>
                <TextField field="externalId" label="externalId" />
              </Col>
              <Col md={3}>
                <TextField field="localizator" label="localizator" />
              </Col>
            </Row>
            <div onClick={() => {
              setPriceOpen( !priceOpen );
            }}
            >
              <hr className="mb-0" />
              <h3
                className="mb-0 py-3 cursor-pointer"
              >
                <i className={classNames( 'fa fa-caret-right mr-2 transition-all', { 'rotate-down': priceOpen } )} />
                <Intl id="price" />
              </h3>
            </div>
            <Collapse isOpen={priceOpen}>
              <Row className="py-3">
                <Col md={3}>
                  <TextField field="price" label="price" type="number" />
                </Col>
                <Col md={3}>
                  <TextField
                    field="rentalPrice"
                    label="infoPayment.basePrice"
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <Select
                    field="currency"
                    label="currency"
                    options={currencies}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="commission"
                    label="infoPayment.commision.percentage"
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="ownerCommission"
                    label="infoPayment.ownerCommision.percentage"
                    type="number"
                  />
                </Col>
                <Col md={9}>
                  <ExtraServicesList
                    field="extraServices"
                    label="infoPayment.extraServices"
                    onChange={setExtraServices}
                  />
                </Col>
              </Row>
            </Collapse>
            <hr className="mt-0" />
            <Row>
              <Col md={12}>
                <TextField
                  field="observations"
                  label="observations"
                  type="textarea"
                  rows={3}
                />
              </Col>
            </Row>
          </>
        )}
      />
    ) : (
      <NewCustomer
        modalOpened
        onCloseModal={toggleModalNewCustomer}
        toggleLoading={toggleLoading}
        onReload={onReloadCustomer}
      />
    );
  },
);
