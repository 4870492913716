export default function formatActions( data ) {
  if ( !data ) {
    return [];
  }

  let owners = [];

  if ( data.owner ) {
    owners = Object.entries( data.owner ).map( ( owner ) => ( {
      title: owner[1],
      type: 'operative_owner',
    } ) );
  }

  return owners;
}
