import React from 'react';

import {
  LocalTable, AddBtn, TableActionsBtns, Intl, CanAccess, PropertyManagerLayout,
} from 'components';
import ability from 'config/Ability';

export default React.memo( ( {
  data, pmData, userRole, parentPath, pathLayout, pathname, isLoading,
  onOpenNewModal, onOpenZonesModal, onDelete,
} ) => {
  const canDelete = ability.can( 'delete', 'settings_city' );
  const canListZones = ability.can( 'lists', 'settings_city_zone' );

  return (
    <PropertyManagerLayout
      data={pmData}
      isLoading={isLoading}
      parentPath={parentPath}
      pathLayout={pathLayout}
      pathname={pathname}
      userRole={userRole}
    >
      <LocalTable
        data={data}
        titleContent={(
          <>
            <h4 className="m-0"><Intl id="cities" /></h4>
            <span className="font-italic text-sm text-muted"><Intl id="propertyManagerCityLabel" /></span>
          </>
      )}
        rightContent={<CanAccess I="add" a="settings_city"><AddBtn title="newCity" onClick={onOpenNewModal} /></CanAccess>}
        columns={[
          {
            Header: 'name',
            accessor: 'city.name',
            Cell: ( { cell } ) => {
              if ( canListZones ) {
                return (
                  <a
                    href="/"
                    onClick={( e ) => {
                      e.preventDefault();
                      onOpenZonesModal( { id: cell.row.original.id } );
                    }}
                  >
                    {cell.value}
                  </a>
                );
              }
              return cell.value;
            },
          },
          {
            Header: 'state',
            accessor: 'city.state.name',
          },
          {
            Header: 'country',
            accessor: 'city.state.country.name',
          },
          canListZones || canDelete
            ? {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => {
                const actions = [];
                if ( canListZones ) {
                  actions.push( {
                    onClick: () => onOpenZonesModal( { id: cell.value } ),
                    label: 'editZones',
                    'data-cy': 'table-zones-btn',
                  } );
                }
                if ( canDelete ) {
                  actions.push( {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  } );
                }
                return (
                  <TableActionsBtns actions={actions} />
                );
              },
            } : {
              id: 'id', Header: null, maxWidth: 1, disableSorting: true,
            },
        ]}
      />
    </PropertyManagerLayout>
  );
} );
