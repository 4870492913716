import React, { useCallback } from 'react';
import SettingsActions from 'store/reducers/Settings';
import NotificationsAccOwnerService from 'api/NotificationsAccOwner';
import { handlePromise } from 'utils';
import { connect } from 'react-redux';
import SendNotificationView from 'views/NotificationOwners/Send/index';
import AccomodationOwnerService from 'api/AccomodationOwner';
import { formatMessage } from './utils';

const SendNotification = ( {
  toggleLoading, toggleErrorAlert, toggleInfoAlert,
  open, onClose, messageToRespond,
} ) => {
  const fetchAccOwnerUsers = useCallback(
    ( query ) => AccomodationOwnerService.getAccomodationOwners( {
      elementsPerPage: 10,
      keyword: query,
    } ), [],
  );
  const submitPermission = useCallback( async ( values ) => {
    toggleLoading( true );
    const { owner, message } = values;
    const dataToSend = {
      case: values.case,
      message,
    };
    const [errors, response] = await handlePromise(
      NotificationsAccOwnerService.sendNotification( owner.value, dataToSend ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return toggleErrorAlert( errors );
    }
    toggleLoading( false );
    toggleInfoAlert( 'notifySuccesfull' );
    onClose();
  }, [onClose, toggleLoading, toggleInfoAlert, toggleErrorAlert] );
  return (
    <SendNotificationView
      preloadedData={formatMessage( messageToRespond )}
      open={open}
      onClose={onClose}
      fetchAccOwnerUsers={fetchAccOwnerUsers}
      onSubmit={submitPermission}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( SendNotification );
