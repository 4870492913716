import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TaskTypeEditView from 'views/TaskType/Children/Edit';
import TaskTypeService from 'api/TaskType';
import ChecklistService from 'api/Checklist';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import TranslationService from 'api/Translation';

const TaskTypeEdit = ( {
  taskTypeId,
  taskTypeParentId,
  modalOpened,
  onReload, onCloseModal,
  toggleErrorAlert,
  toggleInfoAlert,
  toggleLoading,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => TaskTypeService.getTaskType( taskTypeId ),
    translationPromise: () => TranslationService.getTaskTypeTranslations( taskTypeId, 'taskType' ),
    format: ( dataToFormat ) => {
      if ( !taskTypeId ) return dataToFormat;
      const formattedData = { ...dataToFormat };
      if ( formattedData.checkLists ) {
        formattedData.checkLists = _.map( formattedData.checkLists, ( item ) => ( {
          value: item.id,
          label: item.name,
        } ) );
      }
      return formattedData;
    },
    toggleErrorAlert,
    conditional: !!taskTypeId && modalOpened,
    deps: [taskTypeId, modalOpened],
    reInit: true,
  } );

  const fetchChecklists = useCallback( ( query ) => ChecklistService.getChecklists( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( taskTypeId
      ? TaskTypeService.updateTaskType( taskTypeId, dataToSend )
      : TaskTypeService.saveTaskType( dataToSend ) ),
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      enabled: 'true',
      parent: taskTypeParentId,
      forCustomers: dataToFormat.forCustomers ? 'true' : 'false',
      changeCleaningStatus: dataToFormat.changeCleaningStatus ? 'true' : 'false',
    } ),
    toggleLoading,
    toggleInfoAlert,
    translations: true,
    deps: [taskTypeId],
    callback: onReload,
  } );

  return (
    <TaskTypeEditView
      taskTypeId={taskTypeId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
      onFetchChecklists={fetchChecklists}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( TaskTypeEdit );
