import React, { useCallback } from 'react';
import { useFetch } from 'hooks';
import { connect } from 'react-redux';
import NewPermitView from 'views/Resource/Edit/Permits/New';
import EmployeeService from 'api/Employee';
import SettingsActions from 'store/reducers/Settings';
import moment from 'moment';
import { handlePromise } from 'utils';
import { getPermitRegister } from './utils';

const NewPermit = ( {
  customerId, permitId, open, permitOptions, reload, onClose, toggleErrorAlert,
  toggleInfoAlert, toggleLoading,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => EmployeeService.getPermitRequest( customerId, permitId ),
    toggleErrorAlert,
    format: ( {
      employeePermitsRegister, description, id, from, till,
    } ) => ( {
      employeePermitsSettings: employeePermitsRegister.setting.id,
      description,
      id,
      from,
      till,
    } ),
    conditional: Boolean( permitId ),
    deps: [permitId],
  } );

  const submitPermission = useCallback( async ( values ) => {
    toggleLoading( true );
    const {
      employeePermitsSettings, from, till, description,
    } = values;
    const difference = moment( till ).diff( moment( from ) );
    if ( difference < 0 ) {
      toggleLoading( false );
      return toggleErrorAlert( 'From date must be lower than till date' );
    }
    const fromDate = moment( from ).format( 'YYYY-MM-DD' );
    const fromYear = moment( from ).year();
    const tillDate = moment( till ).format( 'YYYY-MM-DD' );
    const tillYear = moment( till ).year();
    const [errorP, resp, permitRegFound] = await handlePromise(
      getPermitRegister( { customerId, year: fromYear, employeePermitsSettings } ),
    );
    if ( !resp.ok ) {
      toggleLoading( false );
      return toggleErrorAlert( errorP );
    }
    if ( fromYear === tillYear ) {
      const dataToSend = {
        description,
        from: fromDate,
        till: tillDate,
        employeePermitsRegister: permitRegFound.id,
      };
      const [errors, response] = await handlePromise(
        permitId
          ? EmployeeService.updatePermitRequest( customerId, permitId, dataToSend )
          : EmployeeService.createPermitRequest( customerId, dataToSend ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleErrorAlert( errors );
      }
    }
    if ( fromYear < tillYear ) {
      const dataToSend = {
        description,
        from: fromDate,
        till: moment( [fromYear] ).endOf( 'year' ).format( 'YYYY-MM-DD' ),
        employeePermitsRegister: permitRegFound.id,
      };
      const [errors, response] = await handlePromise(
        permitId
          ? EmployeeService.updatePermitRequest( customerId, permitId, dataToSend )
          : EmployeeService.createPermitRequest( customerId, dataToSend ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleErrorAlert( errors );
      }
      // Next year permission
      const [errorN, respN, permitRegFoundNx] = await handlePromise(
        getPermitRegister( { customerId, year: tillYear, employeePermitsSettings } ),
      );
      if ( !respN.ok ) {
        toggleLoading( false );
        return toggleErrorAlert( errorN );
      }
      const dataToSendNx = {
        description,
        from: moment( [tillYear] ).startOf( 'year' ).format( 'YYYY-MM-DD' ),
        till: tillDate,
        employeePermitsRegister: permitRegFoundNx.id,
      };
      const [errorsNx, responseNx] = await handlePromise(
        permitId
          ? EmployeeService.updatePermitRequest( customerId, permitId, dataToSendNx )
          : EmployeeService.createPermitRequest( customerId, dataToSendNx ),
      );
      if ( !responseNx.ok ) {
        toggleLoading( false );
        return toggleErrorAlert( errorsNx );
      }
    }
    toggleLoading( false );
    toggleInfoAlert( 'Permit created' );
    reload();
    onClose();
  },
  [
    customerId,
    permitId,
    onClose,
    reload,
    toggleErrorAlert,
    toggleInfoAlert,
    toggleLoading,
  ] );

  return (
    <NewPermitView
      entityId={permitId}
      open={open}
      preloadedData={data}
      permitOptions={permitOptions}
      isLoading={dataLoading}
      onClose={onClose}
      onSubmit={submitPermission}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( NewPermit );
