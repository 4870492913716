import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import {
  SimpleHeader,
  DetailCard,
  Intl,
  Form,
  TextField,
  FormActionsBtns,
  RichTextField,
  TranslationLayout,
} from 'components';

import {
  validUrl,
} from 'config/InputErrors';

import image from '../../../assets/img/theme/banner.png';

const EmailSettings = React.memo( ( { data, onSubmit, isLoading } ) => {
  const [previewData, setPreviewData] = useState( data );
  const updatePreview = ( field ) => ( value ) => setPreviewData( ( state ) => ( {
    ...state,
    [field]: value,
  } ) );
  useEffect( () => {
    setPreviewData( data );
  }, [data] );
  return (
    <>
      <SimpleHeader title="emailSettings" />
      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <Row>
          <Col md={7}>
            <DetailCard>
              <h2>
                <Intl id="emailSettings.title" />
              </h2>
              <p>
                <Intl id="emailSettings.description" />
              </p>
              <Form
                initialValues={data}
                showErrorsInFooter
                onSubmit={onSubmit}
                body={( form ) => (
                  <div className="email-settings">
                    <TranslationLayout
                      translationFields={( locale ) => (
                        <Row>
                          <Col>
                            <RichTextField
                              field={`translations.${locale}.signature`}
                            />
                          </Col>
                        </Row>
                      )}
                    >
                      <Row>
                        <Col md={6}>
                          <TextField
                            field="mainColor"
                            label="mainColor"
                            onChange={updatePreview( 'mainColor' )}
                          />
                          <span className="help-text">
                            <Intl id="mainColor.help" />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <TextField
                            label={previewData.logoName || 'headerLogo'}
                            type="file"
                            onChange={async ( val, e ) => {
                              const logo = e.currentTarget.files[0];
                              if ( logo ) {
                                updatePreview( 'logoURL' )(
                                  URL.createObjectURL( logo ),
                                );
                                updatePreview( 'logoName' )( logo.name );
                                form.change( 'logo', logo );
                              }
                            }}
                            labelClass="custom-file-label"
                            inputClass="custom-file-input"
                            formGroupClass="custom-file"
                            accept="image/x-png,image/jpeg"
                          />
                          <span className="mt-1 help-text">
                            <Intl id="headerLogo.help" />
                          </span>
                        </Col>
                        <Col md={6}>
                          {( !!previewData.logoURL || !!data.logo ) && (
                            <img
                              src={previewData.logoURL || data.logo}
                              className="email-settings_logo"
                              alt="Logo"
                            />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <RichTextField
                            field="signature"
                            inputOnChange={updatePreview( 'signature' )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <p>
                            <Intl id="emailSettings.mediaLinks" />
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={6}
                          lg={4}
                          className="d-flex align-items-center"
                        >
                          <i className="icon-facebook mr-2" />
                          <TextField
                            field="socialFacebook"
                            onChange={updatePreview( 'socialFacebook' )}
                            validate={validUrl}
                          />
                        </Col>
                        <Col
                          sm={6}
                          lg={4}
                          className="d-flex align-items-center"
                        >
                          <i className="icon-twitter mr-2" />
                          <TextField
                            field="socialTwitter"
                            onChange={updatePreview( 'socialTwitter' )}
                            validate={validUrl}
                          />
                        </Col>
                        <Col
                          sm={6}
                          lg={4}
                          className="d-flex align-items-center"
                        >
                          <i className=" icon-instagram-sketched mr-2" />
                          <TextField
                            field="socialInstagram"
                            onChange={updatePreview( 'socialInstagram' )}
                            validate={validUrl}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={6}
                          lg={4}
                          className="d-flex align-items-center"
                        >
                          <i className=" icon-youtube mr-2" />
                          <TextField
                            field="socialYoutube"
                            onChange={updatePreview( 'socialYoutube' )}
                            validate={validUrl}
                          />
                        </Col>
                        <Col
                          sm={6}
                          lg={4}
                          className="d-flex align-items-center"
                        >
                          <i className=" icon-worldwide mr-2" />
                          <TextField
                            field="socialWebsite"
                            onChange={updatePreview( 'socialWebsite' )}
                            validate={validUrl}
                          />
                        </Col>
                      </Row>
                    </TranslationLayout>
                  </div>
                )}
                footer={<FormActionsBtns />}
              />
            </DetailCard>
          </Col>
          <Col md={5} className="mt-3 mt-md-0">
            <DetailCard classNames={{ body: 'p-3' }}>
              <p className="text-center">Email preview</p>
              <div className="preview_container p-3">
                <DetailCard className={{ card: 'm-2' }}>
                  {( !!previewData.logoURL || !!data.logo ) && (
                    <img
                      src={previewData.logoURL || data.logo}
                      className="preview_logo mb-3 mt-3"
                      alt="Logo"
                    />
                  )}
                  <img
                    src={image}
                    className="preview_banner mb-3"
                    alt="Banner"
                  />
                  <div className="editor-preview">
                    <h1 style={{ color: previewData.mainColor }}>
                      Welcome to Nomads!
                    </h1>
                    <p>
                      Lorem ipsum pain sit amet, consectetur adipiscing elit.
                      Sed vulputate, lorem vel dapibus tincidunt, dui lacus
                      rutrum velit, quis dictum sem ante a est. Integer vitae
                      quam neque. Fusce nec tortor a ipsum condimentum rutrum at
                      non ipsum. Nulla vitae efficitur nulla, nec ultricies
                      nulla. Donec eget porttitor turpis, nec viverra ligula.
                      Nullam nec mollis nisl. Nam sit amet nisl commodo est easy
                      cmodo.
                    </p>
                    <p>
                      Nulla vitae efficitur nulla, nec ultricies nulla Donec
                      eget porttitor turpis, nec viverra ligula
                    </p>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: previewData.signature,
                      }}
                    />
                  </div>
                </DetailCard>
                <div className="d-flex justify-content-center mt-2 ">
                  {!!previewData.socialWebsite && (
                    <i className=" icon-worldwide m-2" />
                  )}
                  {!!previewData.socialFacebook && (
                    <i className="icon-facebook m-2" />
                  )}
                  {!!previewData.socialTwitter && (
                    <i className="icon-twitter m-2" />
                  )}
                  {!!previewData.socialInstagram && (
                    <i className=" icon-instagram-sketched m-2" />
                  )}
                  {!!previewData.socialYoutube && (
                    <i className=" icon-youtube m-2" />
                  )}
                </div>
              </div>
            </DetailCard>
          </Col>
        </Row>
      </Container>
    </>
  );
} );

export default EmailSettings;
