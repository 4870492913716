import React, { useState } from 'react';
import { Field } from 'react-final-form';

import TextFieldInput from '../utils/TextFieldInput';

const TextField = React.memo( ( {
  field, validate, value, name, ...rest
} ) => {
  const [focused, setFocused] = useState( false );
  if ( field ) {
    return (
      <Field name={field} validate={validate}>
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && meta.submitError );

          return (
            <TextFieldInput
              {...input}
              {...rest}
              value={input.value || value || ''}
              inputOnChange={input.onChange}
              inputName={input.name}
              focused={meta.active}
              meta={meta}
              isInvalid={isInvalid}
            />
          );
        }}
      </Field>
    );
  }

  return (
    <TextFieldInput
      {...rest}
      value={value || ''}
      inputName={name}
      focused={focused}
      onFocus={() => {
        setFocused( true );
        if ( rest.onFocus ) rest.onFocus();
      }}
      onBlur={() => setFocused( false )}
    />
  );
} );

export default TextField;
