import React from 'react';
import { Row, Col } from 'reactstrap';

import { TextField, ModalForm } from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  countryId, data, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    initialValues={data}
    title={countryId ? 'editCountry' : 'newCountry'}
    showErrorsInFooter
    isLoading={isLoading}
    open={open}
    onSubmit={onSubmit}
    onClose={onClose}
  >
    <Row>
      <Col md={6}>
        <TextField
          field="name"
          label="name"
          validate={required}
        />
      </Col>
    </Row>
    <Row>
      <Col sm={6}>
        <TextField
          field="iso"
          label="iso"
          validate={required}
        />
      </Col>
      <Col sm={6}>
        <TextField
          field="iso3"
          label="iso3"
          validate={required}
        />
      </Col>
    </Row>
  </ModalForm>
) );
