import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Intl } from 'components';

const targetTypes = {
  task: {
    href: '/operations/tasks/',
    text: 'linkNotification.task',
  },
  booking: {
    href: '/bookings/list/',
    text: 'linkNotification.booking',
  },
};

const LinkNotification = ( { item } ) => {
  const [href, setHref] = useState( null );
  const [text, setText] = useState( null );

  useEffect( () => {
    if ( item ) {
      if ( targetTypes[item?.targetType] && item?.targetId ) {
        const newHref = targetTypes[item.targetType].href + item.targetId;
        const newText = targetTypes[item.targetType].text;

        setHref( newHref );
        setText( newText );
      }
    }
  }, [item] );

  return href && text ? (
    <Link to={href} className="link-notification">
      <Intl id={text} />
    </Link>
  ) : null;
};

export default LinkNotification;
