import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, ModalForm, AsyncSelect, TranslationLayout, Switch,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  taskTypeId, data, isLoading, open, onClose, onSubmit, onFetchChecklists,
} ) => (
  <ModalForm
    initialValues={data}
    title={taskTypeId ? 'editTaskType' : 'newTaskType'}
    showErrorsInFooter
    isLoading={isLoading}
    open={open}
    onClose={onClose}
    onSubmit={canModify( 'task_type' ) ? onSubmit : () => {}}
    formEnabled={canModify( 'task_type' )}
  >
    <TranslationLayout translationFields={( locale ) => (
      <>
        <Row className="mb-3">
          <Col md={6}>
            <TextField
              field={`translations.${locale}.name`}
              label="name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              field={`translations.${locale}.description`}
              label="description"
              type="textarea"
              rows={4}
            />
          </Col>
        </Row>
      </>
    )}
    >
      <Row className="mb-3">
        <Col md={6}>
          <TextField
            field="name"
            label="name"
            validate={required}
          />
        </Col>
        <Col md={6}>
          <AsyncSelect
            field="checkLists"
            label="checklists"
            defaultOptions
            isClearable
            multiple
            loadOptions={onFetchChecklists}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <TextField
            field="description"
            label="description"
            type="textarea"
            rows={4}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={7}>
          <TextField
            field="externalId"
            label="externalId"
          />
        </Col>
        <Col md={5}>
          <TextField
            field="cost"
            label="costFixedService"
            type="number"
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={7} />
        <Col md={5}>
          <TextField
            field="estimatedTime"
            label="estimatedTime"
            type="number"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Switch field="forCustomers" label="showCustomers" />
        </Col>
        <Col>
          <Switch field="changeCleaningStatus" label="changeCleaningStatus" />
        </Col>
      </Row>
    </TranslationLayout>
  </ModalForm>
) );
