import React from 'react';
import { Intl } from 'components';
import {
  Col, Row,
} from 'reactstrap';

const StatisticsView = ( { statistics, showDetail } ) => (
  <div>
    {showDetail && (
      <Row className="mt-3">
        {statistics.perCategory.map( ( item ) => (
          <>
            <Col md={2}>
              <div>
                <strong>
                  <Intl id={item.name} />
                </strong>
              </div>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={4}>
                  <div>
                    <Intl id="consumed" />
                    {`: ${item.consumed} `}
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Intl id="left" />
                    {`: ${item.left}`}
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    {`Max: ${item.max}`}
                  </div>
                </Col>
              </Row>
            </Col>
          </>
        ) )}
      </Row>
    )}
  </div>
);

export default StatisticsView;
