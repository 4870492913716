import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import PropertyEditView from 'views/Property/Edit/General';
import SettingsActions from 'store/reducers/Settings';
import PropertyService from 'api/Property';
import { useSubmit } from 'hooks';
import { handlePromise, formatCustomValuesToSend } from 'utils';
import ZoneService from 'api/Settings/Zone';
import CityService from 'api/Settings/City';

const PropertyEdit = ( {
  data, entityId, parentPath, propertyManager, history,
  toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [zones, setZones] = useState( [] );
  const [cities, setCities] = useState( [] );
  const [zonesLoading, setZonesLoading] = useState( false );

  const getZones = useCallback( async ( cityId ) => {
    if ( !propertyManager || !propertyManager.id ) return toggleErrorAlert( 'apiError' );

    setZonesLoading( true );
    const [errors, response, responseData] = await handlePromise(
      ZoneService.getZones( cityId, {
        propertyManager: propertyManager.id,
        elementsPerPage: 1000,
      } ),
    );
    setZonesLoading( false );

    if ( !response.ok ) return toggleErrorAlert( errors );
    return setZones( responseData.data );
  }, [propertyManager, toggleErrorAlert] );

  useEffect( () => {
    if ( _.get( data, 'entity.city' ) ) getZones( data.entity.city );
  }, [data, toggleErrorAlert, getZones] );

  useEffect( () => {
    const getCities = async () => {
      const [errors, response, responseData] = await handlePromise(
        CityService.getCities( { propertyManager: propertyManager.id, elementsPerPage: 1000 } ),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setCities( _.map( responseData.data, ( city ) => ( {
        id: city.id,
        name: city.city.name,
      } ) ) );
    };
    if ( propertyManager && propertyManager.id ) getCities();
  }, [propertyManager, toggleErrorAlert] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( entityId
      ? PropertyService.updateProperty( entityId, dataToSend )
      : PropertyService.saveProperty( dataToSend ) ),
    format: ( dataToSend ) => {
      const formattedData = { ...dataToSend };
      if ( !entityId ) formattedData.enabled = 'true';
      else formattedData.enabled = data && data.entity && data.entity.enabled ? 'true' : 'false';

      formattedData.haveLift = dataToSend.haveLift ? 'true' : 'false';

      if ( formattedData.city ) delete formattedData.city;
      return formatCustomValuesToSend( formattedData );
    },
    toggleLoading,
    toggleInfoAlert,
    deps: [data],
    callback: ( formData, responseData ) => ( entityId ? history.push( parentPath )
      : history.push( `${parentPath}/edit/${responseData.id}` ) ),
  } );

  return (
    <PropertyEditView
      data={data}
      parentPath={parentPath}
      isZonesLoading={zonesLoading}
      options={{ cities, zones }}
      entityId={entityId}
      onSubmit={submitForm}
      onChangeCity={getZones}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( PropertyEdit );
