import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const GET_EMAIL_MESSAGE = gql`
query GetEmail($id: String!, $propertyManager: String!) {
  Email(id: $id, propertyManager: $propertyManager) {
    ... on BaseError {
      message
    }
    ... on QueryEmailSuccess {
      data {
        html
        text
      }
    }
  }
}
`;

const getEmailMessage = async ( id ) => {
  const { data } = await client.query( {
    query: GET_EMAIL_MESSAGE,
    variables: {
      id,
      propertyManager: getPropertyManager(),
    },
    fetchPolicy: 'no-cache',
  } );

  const dataEmail = data.Email.data || {};

  if ( data.Email.message ) {
    return {
      ok: false,
      message: data.Email.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: dataEmail,
  };
};

export default getEmailMessage;
