import React from 'react';
import RatesDashboardView from 'views/Property/RoomType/Accommodation/Edit/Rates/Dashboard';

const RatesDashboard = ( {
  entityId, data,
} ) => (
  <RatesDashboardView
    name={data.entity ? data.entity.name : ''}
    entityId={entityId}
  />
);

export default RatesDashboard;
