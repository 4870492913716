import api from 'config/Api/Api';

const baseEndpoint = ( propertyId, roomTypeId, accommodationId ) => `/properties/${propertyId}/room-types/${roomTypeId}/accommodations/${accommodationId}`;

export default {
  getContracts( propertyId, roomTypeId, accommodationId, params ) {
    return api.get( `${baseEndpoint( propertyId, roomTypeId, accommodationId )}/contracts`, params );
  },

  getContract( propertyId, roomTypeId, accommodationId, id ) {
    return api.get( `${baseEndpoint( propertyId, roomTypeId, accommodationId )}/contracts/${id}` );
  },

  saveContract( propertyId, roomTypeId, accommodationId, data ) {
    return api.post( `${baseEndpoint( propertyId, roomTypeId, accommodationId )}/contracts`, data );
  },

  updateContract( propertyId, roomTypeId, accommodationId, id, data ) {
    return api.put( `${baseEndpoint( propertyId, roomTypeId, accommodationId )}/contracts/${id}`, data );
  },

  deleteContract( propertyId, roomTypeId, accommodationId, id ) {
    return api.delete( `${baseEndpoint( propertyId, roomTypeId, accommodationId )}/contracts/${id}` );
  },
};
