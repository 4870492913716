import React from 'react';
import { Route, Switch } from 'react-router';

import { canModify, canView, canList } from 'config/Ability';
import Dashboard from './Dashboard';
import Edit from './Edit';

const Resource = ( { match } ) => (
  <Switch>
    {canModify( 'resources' )
    && (
      <Route
        path={`${match.url}/new`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
      />
    )}
    {canView( 'resources' )
    && (
      <Route
        path={`${match.url}/edit/:id`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
      />
    )}
    {canList( 'resources' )
    && (
      <Route
        path={match.url}
        render={( props ) => <Dashboard {...props} parentPath={match.url} />}
      />
    )}
  </Switch>
);

export default Resource;
