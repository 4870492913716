import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { required } from 'config/InputErrors';
import classnames from 'classnames';

import {
  SimpleHeader,
  EditCard,
  CanAccess,
  Form,
  FormActionsBtns, Select,
} from 'components';
import ability from 'config/Ability';

export default React.memo(
  ( {
    data,
    isLoading,
    onSubmit,
  } ) => (
    <>
      <SimpleHeader title="settings" />
      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <EditCard>
          <Form
            initialValues={data}
            onSubmit={ability.can( 'edit', 'settings_currency' ) ? onSubmit : () => {}}
            showErrorsInFooter
            body={() => (
              <Row>
                <Col md={5} lg={4}>
                  <Select
                    field="bookingReference"
                    label="bookingReference"
                    validate={required}
                    options={[
                      { id: 'external_id', name: 'externalId' },
                      { id: 'localizator', name: 'localizator' },
                    ]}
                    translateOptions
                  />
                </Col>
              </Row>
            )}
            //   TODO: UPDATE ACLs
            footer={(
              <CanAccess I="edit" a="settings_currency">
                <Row>
                  <Col md={5} lg={4}>
                    <FormActionsBtns />
                  </Col>
                </Row>
              </CanAccess>
            )}
          />
        </EditCard>
      </Container>
    </>
  ),
);
