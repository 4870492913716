import api from 'config/Api/Api';

const baseEndpoint = '/events';

export default {
  getEvents( params ) {
    return api.get( baseEndpoint, params );
  },

  getEvent( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveEvent( data ) {
    return api.post( baseEndpoint, data );
  },

  updateEvent( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
};
