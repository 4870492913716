import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { filterBuilder } from './filterBuilder';

const GET_ALL_SEASONS_OCCUPANCY = gql`
  query SeasonsOccupancy($limit: Int!, $offset: Int!, $filters: [FilterInput!]!) {
    seasonsOccupancy(limit: $limit, offset: $offset, filters: $filters) {
      ... on BaseError {
        message
      }
      ... on QuerySeasonsOccupancySuccess {
        data {
          results {
            checkIn
            checkInDays {
              daysAllowedMonth
              daysAllowedWeek
            }
            checkOut
            checkOutDays {
              daysAllowedMonth
              daysAllowedWeek
            }
            id
            isActive
            lastNight
            lengthOfTheStay
            occupancyId
            startDate
          }
          total
        }
      }
    }
  }
`;

const getAllSeasonsOccupancy = async ( { page, elementsPerPage, ...rest } ) => {
  const offset = ( page - 1 ) * elementsPerPage;
  const limit = elementsPerPage;
  const { data } = await client.query( {
    query: GET_ALL_SEASONS_OCCUPANCY,
    variables: {
      offset,
      limit,
      filters: filterBuilder( rest ),
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.seasonsOccupancy.message ) {
    return {
      ok: false,
      message: data.seasonsOccupancy.message,
    };
  }
  const seasonsOccupancy = data.seasonsOccupancy.data.results || [];
  const elementsTotal = data.seasonsOccupancy.data.total || 0;
  return {
    ok: true,
    status: 200,
    data: { data: seasonsOccupancy, elementsTotal, elementsPerPage },
  };
};

export default getAllSeasonsOccupancy;
