import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

import { Intl } from 'components';
import { taskPriorities, taskStatuses, taskTypes } from 'config/Constants';
import { getResourceName } from 'utils';

const TaskDownload = React.memo( ( {
  data, csvLinkRef, intl, onDownloadData,
} ) => {
  const [formattedData, setFormattedData] = useState( [] );

  useEffect( () => {
    const newData = _.map( data, ( item ) => {
      const priority = _.find( taskPriorities, { id: item.priority } );
      const status = _.find( taskStatuses, { id: item.status } );
      const type = _.find( taskTypes, { id: item.type } );
      const employee = _.get( item.assigned, `[${item.assigned.length - 1}].employee[0]` );
      return {
        ...item,
        date: item.date ? moment( item.date ).format( 'DD/MM/YYYY' ) : '',
        priority: priority ? intl.formatMessage( { id: priority.name } ) : '',
        status: status ? intl.formatMessage( { id: status.name } ) : '',
        accommodation: _.get( item, 'accommodation.name' ),
        property: _.get( item, 'accommodation.roomType.property.name' ),
        type: type ? intl.formatMessage( { id: type.name } ) : '',
        taskType: _.get( item, 'taskType.name' ),
        employee: getResourceName( employee ),
        created: item.created ? moment( item.created ).format( 'DD/MM/YYYY' ) : '',
      };
    } );
    setFormattedData( newData );
  }, [data] ); //eslint-disable-line

  return (
    <>
      <Button
        color="primary"
        className="mt-2 mt-md-0 ml-1 mr-0"
        onClick={onDownloadData}
      >
        <i className="fa fa-download mr-1" />
        <Intl id="download" />
      </Button>
      <CSVLink
        filename="tasks.csv"
        separator=";"
        ref={csvLinkRef}
        data={formattedData}
        headers={[
          { label: 'Id', key: 'id' },
          { label: intl.formatMessage( { id: 'name' } ), key: 'name' },
          { label: intl.formatMessage( { id: 'description' } ), key: 'description' },
          { label: intl.formatMessage( { id: 'scheduledFor' } ), key: 'date' },
          { label: intl.formatMessage( { id: 'priority' } ), key: 'priority' },
          { label: intl.formatMessage( { id: 'status' } ), key: 'status' },
          { label: intl.formatMessage( { id: 'accommodation' } ), key: 'accommodation' },
          { label: intl.formatMessage( { id: 'property' } ), key: 'property' },
          { label: intl.formatMessage( { id: 'type' } ), key: 'type' },
          { label: intl.formatMessage( { id: 'category' } ), key: 'taskType' },
          { label: intl.formatMessage( { id: 'assigned' } ), key: 'employee' },
          { label: intl.formatMessage( { id: 'created' } ), key: 'created' },
        ]}
        style={{ display: 'none' }}
      />
    </>
  );
} );

export default injectIntl( TaskDownload );
