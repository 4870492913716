import api from 'config/Api/Api';

const baseEndpoint = '/settings';

export default {
  getFeeList( params ) {
    return api.get( `${baseEndpoint}/fee`, params );
  },
  postFee( params ) {
    return api.post( `${baseEndpoint}/fee`, params );
  },
  putFee( feeId, params ) {
    return api.put( `${baseEndpoint}/fee/${feeId}`, params );
  },
  deleteFee( feeId ) {
    return api.delete( `${baseEndpoint}/fee/${feeId}` );
  },
};
