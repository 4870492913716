import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  filters: {},
};

const { Types, Creators } = createActions( {
  updateFilters: ['key', 'filters'],
  setFiltersError: ['key'],
} );

const updateFilters = ( state, { key, filters } ) => {
  const newState = { ...state };
  newState.filters[key] = filters;
  return newState;
};

const setFiltersError = ( state, { key } ) => {
  const newState = { ...state };
  newState.filters[key] = null;
  newState.error = { key, date: new Date().valueOf() };
  return newState;
};

export const reducer = createReducer( INITIAL_STATE, {
  [Types.UPDATE_FILTERS]: updateFilters,
  [Types.SET_FILTERS_ERROR]: setFiltersError,
} );

export default Creators;
