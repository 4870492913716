const variables = [
  { key: '{{name}}', exampleData: 'Antonio' },
  { key: '{{lastname}}', exampleData: 'Garcia' },
  { key: '{{email}}', exampleData: 'antonio@email.com' },
  { key: '{{unique_code}}', exampleData: '123456789' },
  { key: '{{temporal_password}}', exampleData: '123456789' },
  { key: '{{checkin_day}}', exampleData: '10/08/2021' },
  { key: '{{checkout_day}}', exampleData: '26/08/2021' },
  { key: '{{checkin_time}}', exampleData: '14:00' },
  { key: '{{checkout_time}}', exampleData: '10:00' },
  { key: '{{booking_cost}}', exampleData: '50' },
  { key: '{{guests_number}}', exampleData: '2' },
  { key: '{{localizator}}', exampleData: '123456789' },
  { key: '{{booking_id}}', exampleData: '123456789' },
  { key: '{{accommodation_name}}', exampleData: 'My accommodation' },
  { key: '{{accommodation_number}}', exampleData: '1234' },
  { key: '{{accommodation_floor}}', exampleData: '2' },
  { key: '{{accommodation_block}}', exampleData: '1' },
  { key: '{{roomtype_name}}', exampleData: 'My roomtype' },
  { key: '{{property_address}}', exampleData: 'Calle Monasterio de Oseira' },
  { key: '{{property_address_number}}', exampleData: '12' },
  { key: '{{property_postalcode}}', exampleData: '28034' },
  { key: '{{city}}', exampleData: 'Madrid' },
];

export default variables;
