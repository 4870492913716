/* eslint-disable import/prefer-default-export */
const filterBuilder = ( {
  keyword, occupancyId,
} ) => {
  const filters = [];
  if ( keyword ) {
    filters.push( {
      field: 'name',
      operator: 'CONTAINS',
      value: keyword,
    } );
  }
  if ( occupancyId ) {
    filters.push( {
      field: 'occupancyId',
      operator: 'EQUAL',
      value: occupancyId,
    } );
  }
  return filters;
};

export { filterBuilder };
