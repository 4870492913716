import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  AsyncTable, SimpleHeader, TableGenericFilter,
  ResourceAvatar,
} from 'components';
import { formatPhone } from 'utils';
import { canEditViewDelete } from 'config/Ability';

const ResourceDashboard = React.memo(
  ( {
    data,
    parentPath,
    isLoading,
    defaultFilters,
    onFetchData,
  } ) => {
    const { canView } = canEditViewDelete( 'resource' );

    return (
      <>
        <SimpleHeader title="customers" />
        <Container fluid>
          <AsyncTable
            id="resources"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            disableSorting
            defaultFilters={defaultFilters}
            onFetchData={onFetchData}
            customFilters={( { filters, setFilter } ) => (
              <Row>
                <Col md={8} lg={4}>
                  <TableGenericFilter filters={filters} setFilter={setFilter} />
                </Col>
              </Row>
            )}
            columns={[
              {
                Header: 'name',
                accessor: 'firstName',
                Cell: ( { cell } ) => ( canView ? (
                  <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                    <ResourceAvatar
                      data={cell.row.original}
                      avatarSize="sm"
                    />
                  </Link>
                ) : (
                  <span>
                    <ResourceAvatar
                      data={cell.row.original}
                      avatarSize="sm"
                    />
                  </span>
                ) ),
              },
              {
                Header: 'phoneNumber',
                accessor: 'phoneNumber',
                Cell: ( { cell } ) => formatPhone( cell.value ),
              },
              {
                Header: 'email',
                accessor: 'email',
                Cell: ( { cell } ) => <span>{cell.value}</span>,
              },
            ]}
          />
        </Container>
      </>
    );
  },
);

export default ResourceDashboard;
