import api from 'config/Api/Nuki';
import { storage } from 'utils';

const baseEndpoint = 'oc';

export default {
  openDoor( data ) {
    const accessToken = storage.get( 'nomads:token' );
    return api.post( `${baseEndpoint}`, { ...data, accessToken } );
  },

  getStatus( lockerId, propertyManager ) {
    return api.get( `${baseEndpoint}/smartlock/${lockerId}/status`, { propertyManager: propertyManager.id } );
  },
};
