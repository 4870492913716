import Overview from 'containers/Booking/Edit/Overview';
import Payment from 'containers/Booking/Edit/Payment';
import Contract from 'containers/Booking/Edit/Contract';
import Review from 'containers/Booking/Edit/Review';
import Service from 'containers/Booking/Edit/Service/Dashboard';
import Keys from 'containers/Booking/Edit/Keys';
import AccessControl from 'containers/Booking/Edit/AccessControl';
import Notes from 'containers/Booking/Edit/Notes';
import Observations from 'containers/Booking/Edit/Observations';
import Notifications from 'containers/Booking/Edit/Notifications';
import RegisterOfAuthorities from 'containers/Booking/Edit/RegisterOfAuthorities';

export default [
  {
    name: 'overview',
    icon: 'app-icon-flash',
    path: '',
    component: Overview,
    exact: true,
  },
  {
    name: 'services',
    icon: 'app-icon-rocket',
    path: '/services',
    component: Service,
  },
  {
    name: 'contracts',
    icon: 'app-icon-list',
    path: '/contracts',
    component: Contract,
  },
  {
    name: 'finance',
    icon: 'app-icon-dollar',
    path: '/payments',
    component: Payment,
  },
  {
    name: 'review',
    icon: 'app-icon-star',
    path: '/review',
    component: Review,
  },
  {
    name: 'issusedKeys',
    icon: 'fas fa-key',
    path: '/Keys',
    component: Keys,
  },
  {
    name: 'accessControl',
    icon: 'app-icon-icon-access-control',
    path: '/AccessControl',
    component: AccessControl,
  },
  {
    name: 'registerOfAuthorities',
    icon: 'app-icon-icon-access-control',
    path: '/RegisterOfAuthorities',
    component: RegisterOfAuthorities,
  },
  {
    name: 'notes',
    icon: 'fas fa-sticky-note',
    path: '/notes',
    component: Notes,
  },
  {
    name: 'observations',
    icon: 'fas fa-clipboard',
    path: '/observations',
    component: Observations,
  },
  {
    name: 'notifications',
    icon: 'fas fa-envelope-open-text',
    path: '/notifications',
    component: Notifications,
  },
];
