/* eslint-disable no-nested-ternary */
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './calendar.css';
import moment from 'moment';
import { Container } from 'reactstrap';
import { Intl, TooltipItem } from 'components';
import { colorTextByStatus, colorByStatus } from './utils';

export default React.memo( ( {
  events,
  onOpenEditItemModal,
  handleDatesSet,
  showForm,
  handleCustomButtonClick,
  onOpenModal,
  typeCalendar,
  onOpenBlockedModal,
} ) => {
  const handleClick = ( arg ) => {
    const dateValid = moment().isSameOrBefore( moment( arg.date ), 'day' );
    // console.log( 'dateValid', moment( arg.date ).format( 'YYYY/MM/DD' ) );
    const eventIn = events.some( ( item ) => ( moment( arg.date ).startOf( 'day' ).isBetween( new Date( item.start ), new Date( item.end ) ) ) );
    // console.log( 'eventIn', eventIn );
    if ( dateValid ) {
      if ( onOpenBlockedModal ) {
        if ( !eventIn ) {
          onOpenBlockedModal( );
        }
      } else if ( onOpenModal ) {
        onOpenModal( arg.date );
      }
    }
  };
  const handleEventClick = ( { event } ) => {
    const dateValid = moment().isSameOrBefore(
      moment( new Date( event.extendedProps.startDate ? event.extendedProps.startDate : event.extendedProps.from ) ), 'day',
    );
    if ( dateValid ) {
      if ( event.title === 'Blocked' ) {
        onOpenBlockedModal( event.id );
      } else if ( onOpenEditItemModal ) onOpenEditItemModal( event.id );
    }
  };
  return (
    <Container>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        firstDay={1}
        events={events}
        dateClick={typeCalendar !== 'month' ? handleClick : undefined}
        eventClick={handleEventClick}
        customButtons={
        // eslint-disable-next-line no-nested-ternary
        typeCalendar === 'monthDiscount' ? {
          customButton: {
            text: 'Add season for the month',
            click: handleClick,
          },
        }
          : typeCalendar === 'month' ? {
            customButton: {
              text: !showForm ? 'Add season for the month' : 'Cancel',
              click: handleCustomButtonClick,
            },
          }
            : {}
      }
        headerToolbar={
        typeCalendar === 'month' || typeCalendar === 'monthDiscount' ? {
          right: 'customButton today prev,next',
          left: 'title',
        } : {}
      }
        eventContent={( eventInfo ) => {
          const { status } = eventInfo.event.extendedProps;
          return (
            <>
              <div id={`season-${eventInfo.event.id}`} style={{ backgroundColor: status ? colorByStatus[status] : eventInfo.event.title === 'Blocked' ? colorByStatus.blocked : '#f9b586' }}>
                <b>{eventInfo.date}</b>
                <i style={{
                  marginTop: '10px', paddingLeft: '10px', color: status ? colorTextByStatus[status] : eventInfo.event.title === 'Blocked' ? colorTextByStatus.blocked : '#000',
                }}
                >
                  {eventInfo.event.title === 'Booking' ? (
                    <strong>
                      {eventInfo.event.title}
                      {' -> '}
                      <Intl id={status} />
                    </strong>
                  )
                    : <strong>{eventInfo.event.title}</strong>}
                </i>
              </div>
              <TooltipItem
                event={eventInfo.event.extendedProps}
                id={`season-${eventInfo.event.id}`}
                status={status}
                title={eventInfo.event.title}
              />
            </>
          );
        }}
        datesSet={handleDatesSet}
      />
    </Container>

  );
} );
