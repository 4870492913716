import React, { useCallback } from 'react';
import TagsPredefinedEditView from 'views/TagsPredefined/Edit';

const TagsPredefinedEditEdit = ( {
  data, tagToEdit, updateTags, modalOpened, onCloseModal,
} ) => {
  const onSubmit = useCallback( ( { tag } ) => {
    const newTags = ( data || [] ).filter( ( t ) => t !== tagToEdit );
    newTags.push( tag );
    updateTags( newTags );
  }, [data, tagToEdit, updateTags] );

  return (
    <TagsPredefinedEditView
      tagToEdit={tagToEdit}
      open={modalOpened}
      onSubmit={onSubmit}
      onClose={onCloseModal}
    />
  );
};

export default TagsPredefinedEditEdit;
