import React from 'react';
import numeral from 'numeral';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';
import { canModify } from 'config/Ability';
import {
  Intl, LocalTable, TableEditBtn, TableActionsBtns, AddBtn,
} from 'components';
import { currencyFormat, formatFloat } from 'utils';

export default React.memo( ( {
  data, isLoading, userCurrency, onOpenEditModal, onDelete,
} ) => (
  <div className={classnames( { 'app-component-loading': isLoading } )}>
    {canModify( 'services' ) ? (
      <div className="py-2 px-4">
        <Row>
          <Col className="text-right">
            <AddBtn title="newService" onClick={onOpenEditModal} />
          </Col>
        </Row>
      </div>
    ) : null}

    <LocalTable
      onlyTable
      manualPagination
      data={data.data}
      columns={[
        {
          Header: 'name',
          accessor: 'name',
          Cell: ( { cell } ) => (
            <TableEditBtn
              title={cell.value}
              onClick={() => onOpenEditModal( cell.row.original )}
            />
          ),
        },
        {
          Header: 'type',
          accessor: 'type',
          Cell: ( { cell } ) => (
            cell.value ? <Intl id={cell.value} /> : ''
          ),
        },
        {
          Header: 'category',
          accessor: 'serviceCategory.name',
        },
        {
          Header: 'unit',
          accessor: 'units',
          Cell: ( { cell } ) => (
            cell.value ? <Intl id={cell.value} /> : ''
          ),
        },
        {
          Header: 'hoursInAdvance',
          accessor: 'hoursInAdvance',
          // Cell: ( { cell } ) => (
          //   cell.value ? <Intl id={cell.value} /> : ''
          // ),
        },
        {
          Header: 'price',
          accessor: 'serviceItemPrice.price',
          id: 'price',
          Cell: ( { cell } ) => (
            cell.value ? numeral( formatFloat( cell.value ) ).format( currencyFormat( userCurrency ) ) : ''
          ),
        },
        {
          Header: 'priceUnit',
          accessor: 'serviceItemPrice.pricePer',
          id: 'priceUnit',
          Cell: ( { cell } ) => (
            cell.value ? <Intl id={cell.value} /> : ''
          ),
        },
        {
          Header: 'offer',
          accessor: 'serviceItemPrice.offerActive',
          id: 'offer',
          Cell: ( { cell } ) => (
            cell.value ? <Intl id="yes" /> : <Intl id="no" />
          ),
        },
        {
          Header: 'offerPrice',
          accessor: 'serviceItemPrice.offerPrice',
          id: 'offerPrice',
          Cell: ( { cell } ) => (
            cell.value ? numeral( formatFloat( cell.value ) ).format( currencyFormat( userCurrency ) ) : ''
          ),
        },
        {
          Header: 'paymentMethod',
          accessor: 'paymentMethod',
          Cell: ( { cell } ) => (
            cell.value ? <Intl id={cell.value} /> : ''
          ),
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ]}
            />
          ),
        },
      ]}
    />
  </div>
) );
