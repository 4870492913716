import React, { useState } from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import classnames from 'classnames';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';

import {
  SimpleHeader,
  EditCard,
  Form,
  TextField,
  Select,
  Switch,
  TranslationLayout,
  FormActionsBtns,
  Intl,
  ImagesDropzoneGeneric,
  ServiceCatalogList,
  ServiceSchedule,
  HorizontalMenu,
  DetailCard,
} from 'components';
import { required } from 'config/InputErrors';
import { serviceWeekdays, serviceTypes } from 'config/Constants';
import ServiceItem from 'containers/Service/Item/Dashboard';
import Category from 'containers/Service/Category/Dashboard';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  entityId, data, parentPath, isLoading, onSubmit, regions, regionsLoading,
} ) => {
  const [selected, setSelected] = useState( 'services' );

  return (
    <>
      <SimpleHeader
        parentLinks={[{ name: 'services', href: parentPath }]}
      />

      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <Form
          initialValues={entityId ? data : { timetable: serviceWeekdays, catalogs: [{}] }}
          onSubmit={canModify( 'services' ) ? onSubmit : () => {}}
          showErrorsInFooter
          mutators={{ ...arrayMutators }}
          validate={( values ) => {
            const errors = {};
            const timetableErrors = [];
            _.map( values.timetable, ( day, index ) => {
              if ( day.active && ( !day.from || !day.till ) ) timetableErrors[index] = {};
              if ( day.active && !day.from ) timetableErrors[index].from = required();
              if ( day.active && !day.till ) timetableErrors[index].till = required();
            } );

            if ( timetableErrors.length ) {
              errors.timetable = timetableErrors;
            }

            const catalogueErrors = [];
            _.map( values.catalogs, ( catalogue, index ) => {
              if ( ( catalogue.file && !catalogue.name )
                || ( catalogue.name && !catalogue.file ) ) {
                catalogueErrors[index] = {};
              }
              if ( catalogue.file && !catalogue.name ) catalogueErrors[index].name = required();
              if ( catalogue.name && !catalogue.file ) catalogueErrors[index].file = required();
            } );

            if ( catalogueErrors.length ) {
              errors.catalogs = catalogueErrors;
            }
            return errors;
          }}
          body={( form ) => (
            <>
              <Row>
                <Col lg={8}>
                  <EditCard title={entityId ? 'editService' : 'newService'}>
                    <TranslationLayout
                      translationFields={( locale ) => (
                        <>
                          <Row>
                            <Col md={6}>
                              <TextField
                                field={`translations.${locale}.name`}
                                label="name"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <TextField
                                field={`translations.${locale}.subtitle`}
                                label="subtitle"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <TextField
                                field={`translations.${locale}.description`}
                                label="description"
                                type="textarea"
                                rows={4}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <TextField
                                field={`translations.${locale}.notes`}
                                label="notes"
                                type="textarea"
                                rows={4}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    >
                      <Row>
                        <Col md={6}>
                          <TextField
                            field="name"
                            label="name"
                            validate={required}
                          />
                        </Col>
                        <Col md={6}>
                          <Select
                            field="type"
                            label="type"
                            validate={required}
                            options={serviceTypes}
                            translateOptions
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            field="subtitle"
                            label="subtitle"
                            validate={required}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Select
                            field="region"
                            label="region"
                            options={regions}
                            isLoading={regionsLoading}
                          />
                        </Col>
                        <Col md={4}>
                          <Switch
                            label="requiresTimeSlot"
                            field="requiresTimeSlot"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            field="description"
                            label="description"
                            type="textarea"
                            rows={4}
                            validate={required}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            field="notes"
                            label="notes"
                            type="textarea"
                            rows={4}
                          />
                        </Col>
                      </Row>

                      <p className="mb-1 form-control-label">
                        <Intl id="images" />
                      </p>
                      <ImagesDropzoneGeneric
                        form={form}
                        localDelete
                      />

                      <p className="mb-1 form-control-label">
                        <Intl id="catalogs" />
                      </p>
                      <ServiceCatalogList />
                    </TranslationLayout>
                  </EditCard>
                </Col>

                <Col lg={4}>
                  <EditCard title="schedule">
                    <ServiceSchedule
                      form={form}
                    />
                  </EditCard>
                </Col>
              </Row>

              {entityId
                ? (
                  <DetailCard
                    classNames={{ header: 'p-0', body: 'pt-0 pb-2 px-0' }}
                    header={(
                      <Row>
                        <Col>
                          <HorizontalMenu
                            selected={selected}
                            tabs={[{ name: 'services' }, { name: 'categories' }]}
                            onChangeTab={( key ) => setSelected( key )}
                          />
                        </Col>
                      </Row>
                    )}
                  >
                    <div className={classnames( { 'd-none': selected !== 'services' } )}>
                      <ServiceItem entityId={entityId} />
                    </div>
                    <div className={classnames( { 'd-none': selected !== 'categories' } )}>
                      <Category entityId={entityId} />
                    </div>
                  </DetailCard>
                ) : null}
            </>
          )}
          footer={<FormActionsBtns cancelTo={parentPath} />}
        />
      </Container>
    </>
  );
} );
