/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';

import {
  SimpleHeader,
  DetailCard,
  Form,
  TextField,
  FormActionsBtns,
  RichTextField,
  Button,
  EmailClipVariables,
} from 'components';
import { required } from 'config/InputErrors';

const Templates = ( {
  data,
  isLoading,
  onSubmit,
  parentPath,
  onPreview,
} ) => (
  <>
    <SimpleHeader title="editNotification" />
    <Container
      fluid
      className={classnames( { 'app-component-loading': isLoading } )}
    >
      <Row>
        <Col md={9}>
          <DetailCard>
            <Form
              initialValues={{
                type: 'email_push',
                ...data.communicationTemplate || {},
              }}
              showErrorsInFooter
              onSubmit={onSubmit}
              body={( form ) => {
                const type = _.get( form.getState(), 'values.type', '' );
                const isEmailType = type === 'email' || type === 'email_push';
                const isPushType = type === 'push' || type === 'email_push';

                return (
                  <>
                    { isEmailType && (
                      <Row>
                        <Col className="d-flex justify-content-end mt-3">
                          <Button
                            title="emailPreview"
                            onClick={() => onPreview( form.getState() )}
                          />
                        </Col>
                      </Row>
                    )}
                    {isEmailType && (
                      <>
                        <Row className="mt-3">
                          <Col>
                            <RichTextField
                              field="bodyEmail"
                              height="400"
                              validate={required}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    {isPushType && (
                      <Row>
                        <Col md={5}>
                          <TextField
                            field="bodyPush"
                            label="pushNotification"
                            labelClass="font-weight-bold"
                            validate={required}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                );
              }}
              footer={<FormActionsBtns cancelTo={parentPath} />}
            />
          </DetailCard>
        </Col>
        <Col md={3}>
          <EmailClipVariables />
        </Col>
      </Row>
    </Container>
  </>
);

export default Templates;
