export const pipelineToColumns = ( pipeline ) => {
  const { stages } = pipeline;
  return ( stages || [] ).map( ( stage ) => ( { name: stage, id: stage } ) );
};

export const dataToItems = ( data ) => {
  const { data: list } = data;
  return ( list || [] ).map( ( content ) => ( {
    id: content.id,
    content,
    columnId: content?.businessStage,
  } ) );
};

/*

const defcolumns = [
  { name: 'Todo', id: 'todo' },
  { name: 'In progress', id: 'inProgress' },
  { name: 'Done', id: 'done' },
];
const defdata = [
  {
    id: 1,
    content: {
      title: 'Aqua-man',
      description: 'Aqua-man is a fictional character from DC Comics.',
    },
    columnId: 'todo',
  },
  {
    id: 5,
    content: {
      title: 'Spiderman',
      description: 'Aqua-man is a fictional character from DC Comics.',
    },
    columnId: 'todo',
  },
  {
    id: 6,
    content: {
      title: 'Ironman',
      description: 'Aqua-man is a fictional character from DC Comics.',
    },
    columnId: 'todo',
  },
  {
    id: 7,
    content: {
      title: 'Wonder Woman',
      description: 'Aqua-man is a fictional character from DC Comics.',
    },
    columnId: 'todo',
  },
  {
    id: 8,
    content: {
      title: 'Catwoman',
      description: 'Aqua-man is a fictional character from DC Comics.',
    },
    columnId: 'inProgress',
  },
  {
    id: 9,
    content: {
      title: 'Robin',
      description: 'Aqua-man is a fictional character from DC Comics.',
    },
    columnId: 'inProgress',
  },
  {
    id: 10,
    content: {
      title: 'Dr Strange',
      description: 'Aqua-man is a fictional character from DC Comics.',
    },
    columnId: 'inProgress',
  },
  {
    id: 2,
    content: {
      title: 'Flash',
      description: 'Flash is a fictional character from DC Comics.',
    },
    columnId: 'inProgress',
  },
  {
    id: 3,
    content: {
      title: 'Green Lantern',
      description: 'Green Lantern is a fictional character from DC Comics.',
    },
    columnId: 'todo',
  },
  {
    id: 4,
    content: {
      title: 'Batman',
      description: 'Batman is a fictional character from DC Comics.',
    },
    columnId: 'done',
  },
];

*/
