import React from 'react';
import {
  Row, Col, UncontrolledTooltip,
} from 'reactstrap';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import {
  Intl, UserAvatar,
} from 'components';
import { taskPriorities, taskStatuses } from 'config/Constants';

export default ( {
  task, onOpenTaskEditModal,
} ) => {
  const priority = _.find( taskPriorities, { id: task.priority } );
  const status = _.find( taskStatuses, { id: task.status } );
  const employee = _.get( task.assigned, `[${task.assigned.length - 1}].employee[0]` );
  const handleOpen = () => onOpenTaskEditModal( task );
  return (
    <div>
      <hr className="m-0" />
      <Row className="align-items-center py-3">
        <Col md="auto" className="pr-3">
          <UserAvatar
            className="justify-content-center"
            avatarSize="md"
            avatar={employee ? employee.profilePicture : null}
          />
        </Col>
        <Col md="auto" className="pr-5">
          <div className="fz-lead text-bold">
            <Intl id={task.type} />
          </div>
          {!!status
          && (
            <div className="fz-lead text-gray-9"><Intl id={status.name} /></div>
          )}
        </Col>
        <Col className="pl-0">
          <div>
            {!!priority && (
              <span className="mr-1 align-center">
                <i
                  className={classnames( priority.iconClass )}
                  id={`tooltip-task-${task.id}`}
                />
                <UncontrolledTooltip delay={0} target={`tooltip-task-${task.id}`}>
                  <Intl id={priority.name} />
                </UncontrolledTooltip>
              </span>
            )}
            <span
              className="cursor-pointer fz-lead text-bold uppercase"
              onClick={handleOpen}
            >
              #
              {task.id.slice( task.id.length - 6, task.id.length )}
            </span>
          </div>
          <div className="text-gray-5 text-sm">
            <Intl id="type" />
            {': '}
            {_.get( task, 'taskType.name' )}
          </div>
          <div className="text-sm">
            {task.description}
          </div>
          <div className="text-gray-5 text-xsm">
            <Intl id="reportedAt" />
            {': '}
            {task.created ? moment( task.created ).format( 'DD-MM-YYYY hh:mma' ) : '-'}
          </div>
        </Col>
      </Row>
    </div>
  );
};
