import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  EditCard,
  Switch,
} from 'components';

export default React.memo(
  ( ) => (
    <EditCard title="customerApp.modules.title" cardClass="card-sec-bottom">
      <Row>
        <Col lg>
          <Row>
            <Col md={2}>
              <Switch field="contracts" label="customerApp.contracts" />
            </Col>
            <Col md={2}>
              <Switch field="payments" label="customerApp.payments" />
            </Col>
            <Col md={2}>
              <Switch
                field="smartLockerPin"
                label="customerApp.smartLockerPin"
              />
            </Col>
            <Col md={2}>
              <Switch
                field="reportIssues"
                label="customerApp.reportIssues"
              />
            </Col>
            <Col md={2}>
              <Switch
                field="precheckinRequired"
                label="customerApp.precheckinRequired"
              />
            </Col>
            <Col md={2}>
              <Switch
                field="travelReportRequired"
                label="customerApp.travelReportRequired"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </EditCard>
  ),
);
