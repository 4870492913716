const filterBuilder = ( params, paramNames ) => {
  const filters = {};

  paramNames.forEach( ( name ) => {
    if ( params[name] ) {
      if ( params[name] === 'true' || params[name] === 'false' ) {
        filters[name] = params[name] === 'true';
      } else {
        filters[name] = params[name];
      }
    }
  } );

  return filters;
};

export const sortByBuilder = ( params ) => {
  let orderBy = { field: 'createdAt', direction: 'desc' };
  Object.keys( params ).forEach( ( key ) => {
    if ( key.indexOf( 'sortby[' ) === 0 ) {
      const field = key.split( 'sortby[' )[1].split( ']' )[0];
      const direction = params[key].toLowerCase();
      orderBy = { field, direction };
    }
  } );

  return orderBy;
};

export default filterBuilder;
