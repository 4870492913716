import api from 'config/Api/Api';

const baseEndpoint = '/post-categories';

export default {
  getCategories( params ) {
    return api.get( baseEndpoint, params );
  },

  getCategory( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveCategory( data ) {
    return api.post( baseEndpoint, data );
  },

  updateCategory( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteCategory( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
