import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  bookingNewModalOpened: false,
  bookingId: null,
  reloadedAt: null,
};

const { Types, Creators } = createActions( {
  reloadBooking: ['id'],
  toggleBookingNewModal: [],
} );

const reloadBooking = ( state, { id } ) => ( {
  ...state,
  bookingId: id,
  reloadedAt: new Date().valueOf(),
} );

const toggleBookingNewModal = ( state ) => ( {
  ...state,
  bookingNewModalOpened: !state.bookingNewModalOpened,
} );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.RELOAD_BOOKING]: reloadBooking,
  [Types.TOGGLE_BOOKING_NEW_MODAL]: toggleBookingNewModal,
} );

export const BookingTypes = Types;
export default Creators;
