import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ExtraExpenseDashboardView from 'views/ExtraExpenses/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import ExtraEspensesService from 'api/ExtraExpenses';
import { useDelete, useFetchTableData } from 'hooks';
import ExtraExpenseEdit from '../Edit';

const ExtraExpensesDashboard = ( {
  defaultFilters, toggleErrorAlert, toggleLoading, updateFilters, setFiltersError, propertyManager,
} ) => {
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ExtraEspensesService.getExtraExpenses,
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'extraExpenses', { keyword: params.keyword } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'extraExpenses' );
        initialReloadError.current = false;
      }
    },
  } );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  const deleteEntity = useDelete( {
    promise: ExtraEspensesService.deleteExtraExpense,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <ExtraExpenseDashboardView
        data={data}
        isLoading={dataLoading}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteEntity}
      />

      <ExtraExpenseEdit
        propertyManager={propertyManager}
        extraExpenseId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { filters, user } ) => ( {
  defaultFilters: _.get( filters, 'filters.extraExpenses' ) || {},
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ExtraExpensesDashboard );
