import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  TextField,
  EditCard,
  // Select,
  Intl,
} from 'components';

// import { customerAppNaming } from 'config/Constants';

export default React.memo(
  () => (
    <EditCard title="customerApp.ownApp.title" cardClass="card-sec-bottom">
      <Row>
        <Col lg>
          <Row>
            <Col>
              <Row>
                <Col md={12}>
                  <h4 className="font-weight-500 text-uppercase">
                    <Intl id="customerApp.appearance.colors" />
                  </h4>
                </Col>
                <Col md={3}>
                  <TextField
                    field="mainColor"
                    label="customerApp.mainColor"
                    type="color"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="secondaryColor"
                    label="customerApp.secondaryColor"
                    type="color"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="mainButtonBg"
                    label="customerApp.mainButtonBg"
                    type="color"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="mainButtonText"
                    label="customerApp.mainButtonText"
                    type="color"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="lightTextColor"
                    label="customerApp.lightTextColor"
                    type="color"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="activeBottomNav"
                    label="customerApp.activeBottomNav"
                    type="color"
                  />
                </Col>
                {/* <Col md={3}>
                  <TextField
                    field="iconsColor"
                    label="customerApp.iconsColor"
                    type="color"
                  />
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </EditCard>
  ),
);
