import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form,
  FormActionsBtns,
  Select,
  TextField,
  Intl,
} from 'components';
import { required } from 'config/InputErrors';
import { billingDates } from 'config/Constants/Fees';

const EditFeeView = ( {
  elementToEdit, submitForm, onClose, feeOptions, feesLoading, feeChosen,
  setFeeChosenId,
} ) => (
  <>
    <h3>
      <Intl id={elementToEdit ? 'editFee' : 'newFee'} />
      {' '}
      {elementToEdit?.id || ''}
    </h3>
    <hr className="mt-0" />
    <Form
      onSubmit={submitForm}
      showErrorsInFooter
      initialValues={elementToEdit || feeChosen}
      body={() => (
        <>
          <Row className="mb-4">
            <Col md={5}>
              <Select
                field="fee"
                label="fee"
                options={feeOptions}
                isLoading={feesLoading}
                validate={required}
                translateOptions
                onChange={setFeeChosenId}
              />
            </Col>
            {feeChosen ? (
              <Col md={7}>
                <Select
                  field="billingDate"
                  label="fees.billingDate"
                  options={billingDates[feeChosen.periodicity]}
                  translateOptions
                />
              </Col>
            ) : null}
          </Row>
          <Row className="mb-4">
            <Col md={3}>
              <TextField
                field="cost"
                label="cost"
                type="number"
              />

            </Col>
            <Col md={6}>
              <TextField
                field="delayAllowedToPayInDays"
                label="fees.delayAllowedToPayInDays"
                type="number"
              />
            </Col>
          </Row>

        </>
      )}
      footer={<FormActionsBtns onClickCancel={onClose} />}
    />
  </>
);

export default EditFeeView;
