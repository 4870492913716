import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';

import { Intl } from 'components';
import { bookedServiceStatus } from 'config/Constants';
import numeral from 'numeral';
import { currencyFormat, formatFloat } from 'utils';
import { Link } from 'react-router-dom';

export default ( { service, userCurrency } ) => {
  const status = _.find( bookedServiceStatus, { id: service.status } );
  return (
    <>
      <Row>
        <Col>
          <Link
            target="_blank"
            to={`/bookings/services/${service.id}`}
            className="text-uppercase text-pink-1 text-sm cursor-pointer"
          >
            #
            {service.id.slice( service.id.length - 6, service.id.length )}
          </Link>
        </Col>
        {!!status
        && (
          <Col md="auto" className="text-xsm">
            <Intl id="status" />
            {': '}
            <span className={status.textClass}><Intl id={status.name} /></span>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <span className="text-gray-5 text-xsm">
            <Intl id="service" />
            {': '}
            {_.get( service, 'service.name' )}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="text-sm">
            <Intl id="cost" />
            {': '}
            {service.cost ? numeral( formatFloat( service.cost ) ).format( currencyFormat( userCurrency ) ) : '' }
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="text-gray-6 text-xsm">
            <Intl id="date" />
            {': '}
            {service.date ? moment( service.date ).format( 'DD-MM-YYYY hh:mma' ) : '-'}
          </span>
        </Col>
      </Row>
    </>
  );
};
