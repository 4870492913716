import React from 'react';
import { Route, Switch } from 'react-router';

import { canList, canView, canModify } from 'config/Ability';
import Dashboard from './Dashboard';
import Edit from './Edit';
import RoomType from './RoomType';

const Property = ( { match } ) => (
  <Switch>
    {canModify( 'property' )
    && (
      <Route
        path={`${match.url}/new`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
      />
    )}
    {( canView( 'property' ) )
    && (
      <Route
        path={`${match.url}/edit/:id`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
      />
    )}
    <Route
      path={`${match.url}/:id/room-types`}
      render={( props ) => <RoomType {...props} parentPath={match.url} />}
    />
    {canList( 'property' )
    && (
      <Route
        path={match.url}
        render={( props ) => <Dashboard {...props} parentPath={match.url} />}
      />
    )}
  </Switch>
);

export default Property;
