import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/contract-settings';

export default {
  getContractSettings( params ) {
    return api.get( baseEndpoint, params );
  },
  getContractSetting( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  saveContractSetting( data ) {
    return api.post( baseEndpoint, data );
  },
  updateContractSetting( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  updateContractFile( id, data ) {
    return api.post( `${baseEndpoint}/${id}/file`, formatFormDataBody( data ) );
  },
  deleteContractSetting( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
