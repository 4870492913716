import React from 'react';
import { connect } from 'react-redux';

import CityService from 'api/City';
import CityEditView from 'views/Country/State/City/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';

const CityEdit = ( {
  countryId, stateId, cityId, modalOpened,
  onCloseModal, onReload, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => CityService.getCity( countryId, stateId, cityId ),
    toggleErrorAlert,
    conditional: !!cityId && modalOpened,
    deps: [stateId, countryId, cityId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( cityId
      ? CityService.updateCity( countryId, stateId, cityId, dataToSend )
      : CityService.saveCity( countryId, stateId, dataToSend ) ),
    toggleLoading,
    toggleInfoAlert,
    callback: onReload,
    deps: [stateId, countryId, cityId],
  } );

  return (
    <CityEditView
      data={data}
      cityId={cityId}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CityEdit );
