import React from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';

import {
  AsyncTable,
  AddBtn,
  SimpleHeader,
  TableGenericFilter,
  TableActionsBtns,
  CanAccess,
} from 'components';
import { canView, canModify } from 'config/Ability';
import { Link } from 'react-router-dom';

export default React.memo(
  ( {
    data,
    isLoading,
    defaultFilters,
    onFetchData,
    onOpenNewModal,
    onDelete,
    parentPath,
  } ) => {
    const canViewProp = canView( 'task_type' );
    const canEditDelete = canModify( 'task_type' );

    return (
      <>
        <SimpleHeader title="taskCategories" />
        <Container fluid>
          <AsyncTable
            id="taskTypes"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            disableSorting
            defaultFilters={defaultFilters}
            rightContent={(
              <CanAccess I="add" a="task_type">
                <AddBtn title="newCategory" onClick={onOpenNewModal} />
              </CanAccess>
            )}
            onFetchData={onFetchData}
            customFilters={( { filters, setFilter } ) => (
              <Row>
                <Col md={8} lg={4}>
                  <TableGenericFilter filters={filters} setFilter={setFilter} />
                </Col>
              </Row>
            )}
            columns={[
              {
                Header: 'name',
                accessor: 'name',
                Cell: ( { cell } ) => ( canEditDelete || canViewProp ? (
                  <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                    {cell.value}
                  </Link>
                ) : ( cell.value ) ),
              },
              {
                Header: 'description',
                accessor: 'description',
              },
              canEditDelete
                ? {
                  accessor: 'id',
                  disableSorting: true,
                  Header: null,
                  maxWidth: 65,
                  Cell: ( { cell } ) => (
                    <TableActionsBtns
                      actions={[
                        {
                          isDelete: true,
                          onClick: ( cb ) => onDelete( cell.value, cb ),
                        },
                      ]}
                    />
                  ),
                }
                : {
                  id: 'id',
                  Header: null,
                  maxWidth: 1,
                  disableSorting: true,
                },
            ]}
          />
        </Container>
      </>
    );
  },
);
