import React, {
  useState, useContext, useRef,
  useCallback,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { Intl } from 'components';
import { BusinnesCRMContext } from 'containers/CRM/Business/Dashboard/context';
import KanbanItem from '../Item';
import { KanvasContext } from '../Context';

const KanbanColumn = ( {
  column, onDeleteBusiness, first,
} ) => {
  const {
    onEditBusiness,
  } = useContext( BusinnesCRMContext );

  const { items, changeItemOfColumn, pipelineId } = useContext( KanvasContext );

  const [minimized, setMinimized] = useState( false );

  const columnRef = useRef( null );

  const [isHovered, setIsHovered] = useState( false );

  const handleDragOver = useCallback( ( e ) => {
    e.preventDefault();
    if ( !isHovered ) {
      setIsHovered( true );
    }
  }, [isHovered] );

  const handleDragLeave = useCallback( ( e ) => {
    e.preventDefault();
    setIsHovered( false );
  }, [] );

  const handleDrop = useCallback( ( e ) => {
    const itemId = e.dataTransfer.getData( 'text/plain' );
    changeItemOfColumn( column.id, itemId );
    setIsHovered( false );
  }, [changeItemOfColumn, column] );

  const itemsOnColumn = useMemo( () => items
    .filter( ( item ) => item.columnId === column.id ), [items, column] );

  return (
    <div
      className={classNames( 'kanban-col', {
        'is-hovered': isHovered,
        minimized,
      } )}
      ref={columnRef}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <header className="kanban-col_header">
        <div className="kanban-col_header-title">
          <div>{column?.name || ''}</div>
          {itemsOnColumn.length ? <div className="kanban-col_header-num">{itemsOnColumn.length}</div> : null}
        </div>
        <button
          className="kanban-col_header-button"
          type="button"
          aria-label="Minimize"
          onClick={() => {
            setMinimized( ( v ) => !v );
          }}
        >
          <i className="fa fa-chevron-down" />
        </button>
      </header>
      <div className="kanban-col_body">
        {itemsOnColumn.map( ( item ) => (
          <KanbanItem
            item={item}
            key={item.id}
            onDeleteBusiness={onDeleteBusiness}
          />
        ) )}
        {first ? (
          <button
            className="kanban-col_create-button"
            type="button"
            aria-label="Create Business"
            onClick={() => { onEditBusiness( null, { pipelineId, businessStage: column.id } ); }}
          >
            <i className="fa fa-plus" />
            <Intl id="crm.businness.createBusinness" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default KanbanColumn;
