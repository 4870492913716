import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import {
  ModalForm, AsyncSelect, Datepicker,
} from 'components';
import { required } from 'config/InputErrors';
import ability from 'config/Ability';
import { getResourceName } from 'utils';

export default React.memo( ( {
  masterKeyId, data, isLoading, open, onClose, onSubmit,
  onFetchProperties, onFetchAccommodations, onFetchEmployees,
} ) => (
  <ModalForm
    initialValues={{
      ...data,
      customer: data && data.customer ? {
        id: data.customer.id,
        label: data.customer.name,
      } : null,
    }}
    title={masterKeyId ? 'editMasterKey' : 'newMasterKey'}
    showErrorsInFooter
    isLoading={isLoading}
    bodyClassName="overflow-visible"
    open={open}
    onClose={onClose}
    onSubmit={ability.can( 'edit', 'task_type' ) ? onSubmit : () => {}}
    formEnabled={ability.can( 'edit', 'task_type' )}
  >
    <Row>

      <Col md={6}>
        <AsyncSelect
          field="customer"
          label="resource"
          labelClass="font-weight-bold"
          defaultOptions
          validate={required}
          labelKey={getResourceName}
          loadOptions={onFetchEmployees}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <AsyncSelect
          field="properties"
          label="properties"
          defaultOptions
          multiple
          loadOptions={onFetchProperties}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <AsyncSelect
          field="accommodations"
          label="accommodations"
          defaultOptions
          multiple
          loadOptions={onFetchAccommodations}
        />
      </Col>
    </Row>
    <Row className="mb-3">
      <Col md={6}>
        <Datepicker
          field="from"
          label="from"
          dateFormat="DD/MM/YYYY"
          timeFormat="HH:mm"
          utc
          startDateField={{ value: moment().subtract( '1', 'day' ) }}
        />
      </Col>
      <Col md={6} className="pl-md-0">
        <Datepicker
          field="till"
          label="till"
          dateFormat="DD/MM/YYYY"
          timeFormat="HH:mm"
          utc
          startDateField={{ value: moment().subtract( '1', 'day' ) }}
        />
      </Col>
    </Row>
  </ModalForm>
) );
