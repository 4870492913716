import React from 'react';
import { Container } from 'reactstrap';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  AsyncTable, AddBtn, SimpleHeader, TableActionsBtns, CanAccess,
} from 'components';
import { currencyFormat, formatFloat } from 'utils';
import { canView, canModify } from 'config/Ability';

export default React.memo( ( {
  data, isLoading, propertyName, roomTypeName, accommodationName, paths = {}, userCurrency,
  onFetchData, onDelete,
} ) => {
  const canViewProp = canView( 'accommodation' );
  const canEditDelete = canModify( 'accommodation' );

  const breadcrumb = [];
  if ( paths.property ) {
    breadcrumb.push( { name: propertyName, translate: false, href: paths.property } );
  }
  if ( paths.property ) {
    breadcrumb.push( { name: roomTypeName, translate: false, href: paths.roomType } );
  }

  return (
    <>
      <SimpleHeader
        title="contracts"
        parentLinks={[
          ...breadcrumb,
          { name: accommodationName, translate: false, href: paths.accommodation },
        ]}
      />
      <Container fluid>
        <AsyncTable
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          rightContent={<CanAccess I="add" a="accommodation"><AddBtn title="newContract" route={`${paths.contract}/new`} /></CanAccess>}
          onFetchData={onFetchData}
          columns={[
            {
              Header: 'startDate',
              accessor: 'from',
              Cell: ( { cell } ) => {
                if ( !cell.value ) return '';
                return (
                  canEditDelete || canViewProp
                    ? (
                      <Link to={`${paths.contract}/edit/${cell.row.original.id}`}>
                        {moment.utc( cell.value ).format( 'MMM DD, YYYY' )}
                      </Link>
                    ) : moment.utc( cell.value ).format( 'MMM DD, YYYY' )
                );
              },
            },
            {
              Header: 'endDate',
              accessor: 'till',
              Cell: ( { cell } ) => ( cell.value ? ( moment.utc( cell.value ).format( 'MMM DD, YYYY' ) ) : '' ),
            },
            {
              Header: 'cost',
              accessor: 'cost',
              Cell: ( { cell } ) => ( cell.value ? numeral( formatFloat( cell.value ) ).format( currencyFormat( userCurrency ) ) : '' ),
            },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        isDelete: true,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      },
                    ]}
                  />
                ),
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
}, ( prevProps, nextProps ) => _.isEqual( prevProps, nextProps ) );
