export default [
  { id: 'every_day', name: 'every_day' },
  { id: 'every_week', name: 'every_week' },
  { id: 'every_half_month', name: 'every_half_month' },
  { id: 'every_month', name: 'every_month' },
  { id: 'every_trimester', name: 'every_trimester' },
  { id: 'every_semester', name: 'every_semester' },
  { id: 'every_year', name: 'every_year' },
  { id: 'customized', name: 'customized' },
];
