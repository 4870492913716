import _ from 'lodash';

/* eslint-disable no-plusplus */
const each = async ( list = [], iterator, callback ) => {
  let completed = 0;
  let canceled = false;
  function iteratorCallback( err, value ) {
    if ( err === false ) {
      canceled = true;
    }
    if ( canceled === true ) return;
    if ( err ) {
      callback( err );
    } else if ( ( ++completed === list.length ) || _.isEmpty( value ) ) {
      callback( null );
    }
  }
  for ( let index = 0; index < list.length; index++ ) {
    const element = list[index];
    // eslint-disable-next-line no-await-in-loop
    await iterator( element, iteratorCallback, index );
  }
};

export default each;
