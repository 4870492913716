import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import {
  TextField, Select, ModalForm,
} from 'components';
import { email, required, composeValidators } from 'config/InputErrors';
import { locales } from 'config/Constants';
import countries from 'country-list';

export default React.memo( ( {
  modalOpened,
  onCloseModal,
  onSubmit,
} ) => (
  <ModalForm
    title="newOwner"
    showErrorsInFooter
    open={modalOpened}
    size="lg"
    bodyClassName="overflow-visible"
    bodyStyle={{ paddingLeft: 45, paddingRight: 45 }}
    onSubmit={onSubmit}
    onClose={onCloseModal}
    initialValues={{ capacity: 1 }}
    body={( ) => (
      <Row>
        <Row>
          <Col md={7}>
            <TextField
              field="email"
              validate={composeValidators( required, email )}
              label="email"
            />
          </Col>
          <Col md={5}>
            <TextField
              field="password"
              label="password"
              type="password"
              validate={required}
            />
          </Col>
          <Col md={6}>
            <TextField
              field="firstName"
              label="firstName"
              validate={required}
            />
          </Col>
          <Col md={6}>
            <TextField
              field="lastName"
              label="lastname"
              validate={required}
            />
          </Col>
          <Col md={4}>
            <Select
              field="country"
              label="country"
              idKey="code"
              options={useMemo( countries.getData, [countries] )}
              validate={required}
            />
          </Col>
          <Col md={4}>
            <Select
              field="locale"
              label="language"
              idKey="locale"
              options={locales}
              validate={required}
            />
          </Col>
        </Row>
      </Row>
    )}
  />
) );
