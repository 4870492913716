import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { filterBuilder } from './filterBuilder';

const GET_ALL_OCCUPANCYS = gql`
  query Occupancys($limit: Int!, $offset: Int!, $filters: [FilterInput!]!) {
    occupancys(limit: $limit, offset: $offset, filters: $filters) {
      ... on BaseError {
        message
      }
      ... on QueryOccupancysSuccess {
        data {
          total
          results {
            accommodationsNumber
            id
            isActive
            lastModification
            minimunNights
            name
            propertyManagerId
            validUntil
          }        
        }
      }
    }
  }
`;

const getAllOccupancys = async ( { page, elementsPerPage, ...rest } ) => {
  const offset = ( page - 1 ) * elementsPerPage;
  const limit = elementsPerPage;
  const { data } = await client.query( {
    query: GET_ALL_OCCUPANCYS,
    variables: {
      offset,
      limit,
      filters: filterBuilder( rest ),
    },
    fetchPolicy: 'no-cache',
  } );
  const occupancys = data.occupancys.data.results || [];
  const elementsTotal = data.occupancys.data.total || 0;
  if ( data.occupancys.message ) {
    return {
      ok: false,
      message: data.occupancys.message,
    };
  }
  return {
    ok: true,
    status: 200,
    data: { data: occupancys, elementsTotal, elementsPerPage },
  };
};

export default getAllOccupancys;
