import { useState, useCallback } from 'react';
import _ from 'lodash';

import { handlePromise } from 'utils';

export default ( {
  initialState = { data: [], elementsTotal: 0 }, initialLoading = false,
  promise, conditional, loading = true, deps = [], callback, callbackError, toggleErrorAlert,
  format,
} ) => {
  const [data, setData] = useState( initialState );
  const [dataLoading, setDataLoading] = useState( initialLoading );

  const fetchData = useCallback( ( apiParams ) => {
    const getData = async ( params ) => {
      if ( loading ) setDataLoading( true );
      const [errors, response, responseData] = await handlePromise( promise( params ) );
      if ( loading ) setDataLoading( false );
      if ( !response.ok ) {
        if ( callbackError ) callbackError();
        return toggleErrorAlert
          ? toggleErrorAlert( errors ) : [initialState, false];
      }
      const formattedData = format ? await format( responseData ) : responseData;

      setData( _.isEmpty( formattedData ) ? initialState : formattedData );
      if ( callback ) callback( params );
    };
    if ( conditional === undefined ) getData( apiParams );
    else if ( conditional ) getData( apiParams );
  }, [...deps] ); //eslint-disable-line

  return [fetchData, data, dataLoading];
};
