import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default React.memo( ( { text, children } ) => {
  const showCopied = ( variable ) => {
    document.getElementById( `var-${variable}` ).innerHTML = 'copied!';
    setTimeout( () => {
      document.getElementById( `var-${variable}` ).innerHTML = '';
    }, 2000 );
  };

  return (
    <>
      <CopyToClipboard
        text={text}
        onCopy={() => showCopied( text )}
      >
        { children }
      </CopyToClipboard>
      <span id={`var-${text}`}> </span>
    </>
  );
} );
