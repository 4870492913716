import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { handlePromise, formatDataToSend } from 'utils';
import RoomExpensesEditView from 'views/Property/RoomType/Accommodation/Edit/RoomExpenses/Edit';
import SettingsActions from 'store/reducers/Settings';
import RoomExpensesService from 'api/RoomExpenses';
import { waterfall } from 'utils/async';

const RoomExpensesEdit = ( {
  accommodation, roomExpenseId, modalOpened, onCloseModal,
  onReload, toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( false );

  const getEntityData = useCallback( async ( cb ) => {
    if ( !roomExpenseId ) return cb( null, {} );
    const [errors, response, responseData] = await handlePromise(
      RoomExpensesService.getRoomExpense( roomExpenseId ),
    );

    if ( !response.ok ) return cb( errors );
    return cb( null, { ...responseData } );
  }, [roomExpenseId] );

  useEffect( () => {
    setDataLoading( true );

    waterfall( [
      getEntityData,
    ], ( errors, results ) => {
      setDataLoading( false );
      if ( errors ) return toggleErrorAlert( errors );
      setData( results );
    } );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomExpenseId, toggleErrorAlert] );

  /** ********************************** */

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );

    const formattedData = {
      ...dataToSend,
      accommodation,
      date: dataToSend.date && _.isObject( dataToSend.date ) ? dataToSend.date.format( 'YYYY-MM-DD' )
        : moment( dataToSend.date ).format( 'YYYY-MM-DD' ),
    };

    const [errors, response] = await handlePromise( roomExpenseId
      ? RoomExpensesService.updateRoomExpense( roomExpenseId, formattedData )
      : RoomExpensesService.saveRoomExpense( formattedData ) );

    toggleLoading( false );
    if ( !response.ok ) return errors;

    toggleInfoAlert( 'dataSaved' );
    onCloseModal();
    onReload();
  }, [accommodation, roomExpenseId, onCloseModal, onReload, toggleInfoAlert, toggleLoading] );

  return (
    <RoomExpensesEditView
      id={roomExpenseId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( RoomExpensesEdit );
