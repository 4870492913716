import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {
  Intl,
  Form,
  TextField,
  FormActionsBtns,
  Switch,
} from 'components';
import {
  email,
  required,
  composeValidators,
} from 'config/InputErrors';

const EmailSettings = React.memo( ( { data, onSubmit, isLoading } ) => (
  <Container
    fluid
    className={classnames( { 'app-component-loading': isLoading } )}
  >
    <Row>
      <Col md={9}>
        <div className="py-5">
          <Form
            initialValues={data}
            showErrorsInFooter
            onSubmit={onSubmit}
            body={( ) => (
              <div className="email-settings">
                <Row className="mb-3">
                  <Col md={5}>
                    <TextField
                      field="defaultSenderName"
                      label="defaultSenderName"
                    />
                    <span className="help-text">
                      <Intl id="defaultSenderName.help" />
                    </span>
                  </Col>

                  <Col md={7}>
                    <TextField
                      field="defaultSenderEmail"
                      label="defaultSenderEmail"
                      validate={composeValidators( required, email )}
                    />
                    <span className="help-text">
                      <Intl id="defaultSenderEmail.help" />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Switch
                      field="disableCustomerEmailNotifications"
                      label="disableCustomerEmailNotifications"
                    />
                  </Col>
                </Row>
              </div>
            )}
            footer={<FormActionsBtns />}
          />
        </div>
      </Col>
    </Row>
  </Container>

) );

export default EmailSettings;
