import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import UserProfileEditView from 'views/UserProfile';
import SettingsActions from 'store/reducers/Settings';
import EmployeeService from 'api/Employee';
// import CustomFieldService from 'api/Settings/CustomField';
import { roles, locales } from 'config/Constants';
import {
  formatDataPhone, formatDataPhoneToSend, formatDataToSend, handlePromise,
} from 'utils';
import CustomerService from 'api/Customer';
import { waterfall } from 'utils/async';

const UserProfileEdit = ( {
  userRole, userId, locale,
  toggleLoading, toggleInfoAlert, toggleErrorAlert, switchLanguage,
} ) => {
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( true );

  const getEntityData = useCallback( async ( cb ) => {
    const [errors, response, responseData] = await handlePromise(
      EmployeeService.getEmployee( userId ),
    );

    if ( !response.ok ) return cb( errors );
    const formattedData = formatDataPhone( responseData, ['phoneNumber'] );
    if ( formattedData.settings && formattedData.settings.locale ) {
      formattedData.locale = formattedData.settings.locale;
    }
    return cb( null, { entity: formattedData } );
  }, [userId] );

  // const getCustomFields = useCallback( async ( entityData, cb ) => {
  //   if ( userRole === roles.nomadsAdmin.id || userRole === roles.nomadsAdminReadOnly.id ) {
  //     return cb( null, { entity: entityData } );
  //   }
  //   if ( !propertyManager || !propertyManager.id ) return cb( 'apiError' );

  //   const [errors, response, responseData] = await handlePromise(
  //     CustomFieldService.getCustomFields( {
  //       type: 'employee',
  //       propertyManager: propertyManager.id,
  //       elementsPerPage: 1000,
  //     } ),
  //   );
  //   if ( !response.ok ) return cb( errors );

  //   return cb( null, {
  //     entity: formatCustomValues( entityData, responseData.data ),
  //     customFields: responseData.data,
  //   } );
  // }, [propertyManager, userRole] );

  useEffect( () => {
    setDataLoading( true );

    waterfall( [
      getEntityData,
      // getCustomFields,
    ], ( errors, results ) => {
      setDataLoading( false );
      if ( errors ) {
        return toggleErrorAlert( errors );
      }
      setData( results );
    } );
  }, [userId, toggleErrorAlert, getEntityData] );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    let dataToSend = formatDataToSend( formData, form );
    delete dataToSend.teams;
    dataToSend = formatDataPhoneToSend( dataToSend, ['phoneNumber'] );
    dataToSend.roles = userRole === 'ROLE_ADMIN' ? ['ROLE_ADMIN', 'ROLE_EMPLOYEE', 'ROLE_USER'] : ['ROLE_EMPLOYEE', 'ROLE_USER'];
    delete dataToSend.profilePicture;
    delete dataToSend.email;
    const [errors, response] = await handlePromise(
      EmployeeService.updateEmployee( userId, dataToSend ),
    );

    if ( !response.ok ) return errors;
    if ( !userId || !formData.profilePicture || _.isString( formData.profilePicture ) ) {
      toggleLoading( false );
      if ( locale && formData.locale !== locale.locale ) {
        const localeObj = _.find( locales, { locale: formData.locale } );
        if ( localeObj ) switchLanguage( localeObj );
      }
      return toggleInfoAlert( 'dataSaved' );
    }

    const imageToSend = {
      picture: formData.profilePicture ? formData.profilePicture.file : null,
    };
    const [imageErrors, imageResponse] = await handlePromise(
      CustomerService.uploadProfilePicture( userId, imageToSend ),
    );
    toggleLoading( false );
    if ( !imageResponse.ok ) return imageErrors;

    toggleInfoAlert( 'dataSaved' );
    if ( locale && formData.locale !== locale.locale ) {
      const localeObj = _.find( locales, { locale: formData.locale } );
      if ( localeObj ) switchLanguage( localeObj );
    }
  }, [userId, locale, toggleInfoAlert, toggleLoading, switchLanguage, userRole] );

  return (
    <UserProfileEditView
      data={data}
      isNomadsAdmin={userRole === roles.nomadsAdmin.id}
      isLoading={dataLoading}
      entityId={userId}
      onSubmit={submitForm}
    />
  );
};

const mapStateToProps = ( { user, settings } ) => ( {
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
  userId: _.get( user, 'user.id' ),
  locale: settings.locale,
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  switchLanguage: SettingsActions.switchLanguage,
} );

export default connect( mapStateToProps, mapDispatchToProps )( UserProfileEdit );
