import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import DashboardView from 'views/Dashboard';
import BookingService from 'api/Booking';
import TaskActions from 'store/reducers/Task';
import IncidenceActions from 'store/reducers/Incidence';
import { handlePromise } from 'utils';
import TaskService from 'api/Task';
import { parallel } from 'utils/async';
import { loadTasks } from './utils';

const Dashboard = ( {
  taskReloadedAt,
  toggleTaskEditModal,
  userRole,
} ) => {
  const isOwner = userRole === 'ROLE_OWNER';
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( true );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const showLoading = useRef( true );
  const isInitialMount = useRef( true );
  const [filter, setFilter] = useState( {
    today: moment().startOf( 'day' ).format( 'YYYY-MM-DD' ),
  } );

  const { getTasks } = TaskService;

  // useEffect( () => {
  //   const checkPageNavigation = () => {
  //     if ( window.performance ) {
  //       const { navigation } = window.performance;

  //       if ( navigation.type === navigation.TYPE_NAVIGATE ) {
  //         console.log( 'Page loaded from server' );
  //       }
  //       if ( navigation.type === navigation.TYPE_RELOAD ) {
  //         console.log( 'Page loaded from cache' );
  //       }
  //     }
  //   };

  //   checkPageNavigation();
  // }, [] );

  const getPromiseResult = useCallback( async ( promise ) => {
    const [, , responseData] = await handlePromise( promise );
    return responseData;
  }, [] );

  useEffect( () => {
    if ( showLoading.current ) setDataLoading( true );
    // const tomorrow = moment().add( 1, 'day' ).startOf( 'day' ).format( 'YYYY-MM-DD' );

    parallel( {
      tasks:
        () => getPromiseResult(
          getTasks( {
            page: 1,
            elementsPerPage: 5,
            from: filter.today,
            till: filter.today,
          } ),
        ),
      countTasks:
        () => getPromiseResult(
          loadTasks( {
            page: 1,
            elementsPerPage: 1,
            from: filter.today,
            till: filter.today,
          } ),
        ),
      countSolvedTasks:
        () => getPromiseResult(
          loadTasks( {
            page: 1,
            elementsPerPage: 1,
            from: filter.today,
            till: filter.today,
            status: 'resolved',
          } ),
        ),
      countClosedTasks:
        () => getPromiseResult(
          loadTasks( {
            page: 1,
            elementsPerPage: 1,
            from: filter.today,
            till: filter.today,
            status: 'closed',
          } ),
        ),
      countIssues:
        () => getPromiseResult(
          getTasks( {
            page: 1,
            elementsPerPage: 1,
            from: filter.today,
            till: filter.today,
            type: 'issue',
          } ),
        ),
      countSolvedIssues:
        () => getPromiseResult(
          getTasks( {
            page: 1,
            elementsPerPage: 1,
            from: filter.today,
            till: filter.today,
            type: 'issue',
            status: 'resolved',
          } ),
        ),
      countClosedIssues:
        () => getPromiseResult(
          getTasks( {
            page: 1,
            elementsPerPage: 1,
            from: filter.today,
            till: filter.today,
            type: 'issue',
            status: 'closed',
          } ),
        ),
      countReportedIssues:
        () => getPromiseResult(
          getTasks( {
            page: 1,
            elementsPerPage: 1,
            fromCreated: filter.today,
            tillCreated: filter.today,
            type: 'issue',
          } ),
        ),
      checksIn:
        () => getPromiseResult(
          BookingService.getBookings( {
            page: 1,
            elementsPerPage: 50,
            fromStart: filter.today,
            fromEnd: filter.today,
            checkinComplete: 'false',
          } ),
        ),
      checksOut:
        () => getPromiseResult(
          BookingService.getBookings( {
            page: 1,
            elementsPerPage: 50,
            tillStart: filter.today,
            tillEnd: filter.today,
          } ),
        ),
    }, ( error, results ) => {
      setData( {
        tasks: results.tasks.data ? results.tasks.data : [],
        countTasks: results.countTasks.data ? results.countTasks.elementsTotal : 0,
        countSolvedTasks: results.countSolvedTasks.elementsTotal
          ? results.countSolvedTasks.elementsTotal : 0,

        countClosedTasks: results.countClosedTasks
          ? results.countClosedTasks.elementsTotal : 0,

        countIssues: results.countIssues ? results.countIssues.elementsTotal : 0,
        countSolvedIssues: results.countSolvedIssues
          ? results.countSolvedIssues.elementsTotal : 0,
        countClosedIssues: results.countClosedIssues
          ? results.countClosedIssues.elementsTotal : 0,
        countReportedIssues: results.countReportedIssues
          ? results.countReportedIssues.elementsTotal : 0,
        checksOut: results.checksOut ? results.checksOut.data : [],
        checksIn: results.checksIn ? results.checksIn.data : [],

      } );
      if ( showLoading.current ) setDataLoading( false );
      showLoading.current = true;
    } );
  }, [dataSubmitted, getPromiseResult, filter.today, getTasks] );

  const openTaskEditModal = useCallback( ( { id } = {} ) => {
    toggleTaskEditModal( id );
  }, [toggleTaskEditModal] );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      showLoading.current = false;
      setDataSubmitted( new Date().valueOf() );
    }
  }, [taskReloadedAt] );

  return (
    <DashboardView
      isOwner={isOwner}
      data={data}
      isLoading={dataLoading}
      onOpenTaskEditModal={openTaskEditModal}
      initialValues={filter}
      onChangeFilter={setFilter}
    />
  );
};

const mapStateToProps = ( { user, task, incidence } ) => ( {
  userRole: user.mainRole,
  taskReloadedAt: task.reloadedAt,
  incidenceReloadedAt: incidence.reloadedAt,
} );

const mapDispatchToProps = ( {
  toggleTaskEditModal: TaskActions.toggleEditModal,
  toggleIncidenceEditModal: IncidenceActions.toggleIncidenceEditModal,
} );

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
