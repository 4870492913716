import React from 'react';
import { Calendar } from 'components';

export default React.memo(
  ( {
    visible, onOpenModal, onOpenEditItemModal, updateCurrentDates, events,
  } ) => {
    if ( !visible ) {
      return null;
    }
    const handleDatesSet = ( arg ) => {
      const currentStartDate = arg.view.activeStart;
      const currentEndDate = arg.view.activeEnd;
      updateCurrentDates( currentStartDate, currentEndDate );
    };

    return (
      <>
        <Calendar
          events={events}
          onOpenEditItemModal={onOpenEditItemModal}
          handleDatesSet={handleDatesSet}
          onOpenModal={onOpenModal}
          typeCalendar="day"
        />
      </>
    );
  },

);
