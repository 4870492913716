/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Collapse } from 'reactstrap';
import { FieldArray } from 'react-final-form-arrays';
import _ from 'lodash';

import { Intl, Checkbox } from 'components';

export default React.memo( ( {
  name, field, values, openedCollapses, collapsesToggle,
} ) => (
  <div className="mb-3">
    <label
      aria-expanded={openedCollapses.includes( name )}
      className="font-weight-500 text-uppercase text-sm cursor-pointer"
      onClick={() => collapsesToggle( name )}
    >
      <Intl id={name} />
    </label>

    <Collapse isOpen={openedCollapses.includes( name )}>
      <FieldArray name={`${field}.roles`}>
        {( { fields } ) => (
          fields.map( ( role, roleIndex ) => {
            const roleData = _.get( values, `roles[${roleIndex}]` );
            return (
              <Checkbox
                translate
                key={roleData.id}
                formGroupClass="m-0"
                field={`${role}.checked`}
                label={roleData.simpleName || roleData.name}
              />
            );
          } )
        )}
      </FieldArray>
    </Collapse>
  </div>
) );
