import _ from 'lodash';

const getPlatformOptionsDeposits = ( plugins ) => {
  const hasAddonPayments = _.some( plugins, ['addonpayments'] );
  if ( hasAddonPayments ) {
    return [[
      {
        name: 'addon_payments',
        id: 'addon_payments',
      },
      {
        name: 'external',
        id: 'external',
      },
    ]];
  }
  return [
    {
      name: 'external',
      id: 'external',
    },
  ];
};

export default getPlatformOptionsDeposits;
