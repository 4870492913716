import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const GET_CONTACT = gql`
query GetContact($id: String!, $propertyManager: String!) {
  Contact(id: $id, propertyManager: $propertyManager) {
    ... on BaseError {
      message
    }
    ... on QueryContactSuccess {
      data {
        phone
        owner
        name
        isActive
        email
        cycle
      }
    }
  }
}
`;

const getContacts = async ( id ) => {
  const { data } = await client.query( {
    query: GET_CONTACT,
    variables: {
      id,
      propertyManager: getPropertyManager(),
    },
    fetchPolicy: 'no-cache',
  } );

  const contact = data.Contact.data || {};

  if ( data.Contact.message ) {
    return {
      ok: false,
      message: data.Contact.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: contact,
  };
};

export default getContacts;
