import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import {
  TextField, Select, ModalForm, AsyncSelect, Datepicker,
} from 'components';
import { required } from 'config/InputErrors';
import { periodicity, taskTypes as typeOfTaskTypes } from 'config/Constants';

export default React.memo(
  ( {
    open,
    onClose,
    onSubmit,
    onFetchAccommodations,
    onFetchProperties,
    taskTypes,
    isLoadingTaskTypes,
  } ) => {
    const [periodicityType, setPeriodicityType] = useState( '' );

    return (
      <ModalForm
        title="newPeriodicTask"
        showErrorsInFooter
        open={open}
        size="xl"
        bodyClassName="overflow-visible"
        bodyStyle={{ minHeight: 330 }}
        onSubmit={onSubmit}
        onClose={onClose}
        initialValues={{}}
        body={( ) => (
          <>
            <Row>
              <Col lg={5} className="border-right">
                <Row>
                  <Col md={8}>
                    <Select
                      field="taskType"
                      label="category"
                      labelClass="font-weight-bold"
                      validate={required}
                      options={taskTypes}
                      isLoading={isLoadingTaskTypes}
                      optgroup
                    />
                  </Col>
                  <Col md={4} className="pl-md-0">
                    <Select
                      field="type"
                      label="type"
                      labelClass="font-weight-bold"
                      validate={required}
                      translateOptions
                      options={typeOfTaskTypes}
                    />
                  </Col>
                  <Col md={12}>
                    <AsyncSelect
                      field="properties"
                      label="property"
                      defaultOptions
                      multiple
                      loadOptions={onFetchProperties}
                    />
                  </Col>
                  <Col md={12}>
                    <AsyncSelect
                      field="accommodations"
                      label="accommodation"
                      defaultOptions
                      multiple
                      loadOptions={onFetchAccommodations}
                      labelKey={( option ) => `${option.name} (${option.roomType.property.propertyManagerCityZone.name} - ${option.roomType.property.propertyManagerCityZone.propertyManagerCity.city.name})`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={7}>
                <Row className="mb-3">
                  <Col md={6}>
                    <Datepicker
                      field="from"
                      label="from"
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      utc
                      labelClass="font-weight-bold"
                      validate={required}
                      startDateField={{ value: moment().subtract( '1', 'day' ) }}
                    />
                  </Col>
                  <Col md={6} className="pl-md-0">
                    <Datepicker
                      field="till"
                      label="till"
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      utc
                      labelClass="font-weight-bold"
                      validate={required}
                      startDateField={{ value: moment().subtract( '1', 'day' ) }}
                    />
                  </Col>
                  <Col md={7}>
                    <Select
                      field="periodicity"
                      label="periodicity"
                      labelClass="font-weight-bold"
                      validate={required}
                      translateOptions
                      options={periodicity}
                      onChange={( v ) => {
                        setPeriodicityType( v );
                      }}
                    />
                  </Col>
                  {periodicityType === 'customized' && (
                  <Col md={5}>
                    <TextField
                      field="periodicityCustom"
                      label="periodicity_custom"
                      type="number"
                      labelClass="font-weight-bold"
                      validate={required}
                    />
                  </Col>
                  )}
                </Row>

              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  field="description"
                  label="description"
                  type="textarea"
                  labelClass="font-weight-bold"
                  rows={3}
                  validate={required}
                />
              </Col>
            </Row>
          </>
        )}
      />
    );
  },
);
