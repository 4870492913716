import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Button } from 'reactstrap';
import {
  AsyncTable, EditCard, DaterangePickerBtn, Intl,
} from 'components';
import StatisticsView from './statistics';

const ContractView = ( {
  statistics,
  fetchData,
  dataTable,
  dataLoading,
  defaultFilters,
} ) => {
  const [showDetail, setShowDetail] = useState( false );
  const toggleDetail = () => setShowDetail( !showDetail );
  return (
    <>
      <EditCard cardClass="card-sec-bottom">
        <AsyncTable
          id="programmedTasks"
          data={dataTable.data}
          total={dataTable.elementsTotal}
          isLoading={dataLoading}
          disableSorting
          defaultFilters={defaultFilters}
          onFetchData={fetchData}
          rightContent={(
            <div className="d-flex align-items-center flex-wrap mt-1 mb-1">
              <Button
                color={showDetail ? 'secondaryColor' : 'primary'}
                className="mt-md-0 ml-2 mr-0"
                onClick={toggleDetail}
              >
                <i className="fas fa-file-invoice mr-1" />
                {showDetail
                  ? <Intl id="hideReport" />
                  : <Intl id="showReport" />}
              </Button>
            </div>
          )}
          endContent={(
            <StatisticsView
              statistics={statistics}
              showDetail={showDetail}
            />
          )}
          customFilters={( { filters, setMultipleFilters } ) => (
            <>
              <Row>
                <Col md={8} lg={4}>
                  <DaterangePickerBtn
                    position="left"
                    initialValue={moment.range( filters.from || moment().subtract( 180, 'days' ), filters.till || moment() )}
                    key={filters.from + filters.till}
                    input
                    onChange={( values ) => {
                      setMultipleFilters( {
                        from: moment( values.start ).format( 'YYYY-MM-DD' ),
                        till: moment( values.end ).format( 'YYYY-MM-DD' ),
                      } );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          columns={[
            {
              Header: '#',
              accessor: 'number',
              Cell: ( { cell } ) => {
                const title = `${cell.row.original.id.slice( cell.row.original.id.length - 6, cell.row.original.id.length )}`;
                return <strong className="text-uppercase">{title}</strong>;
              },
            },
            {
              Header: 'description',
              accessor: 'description',
            },
            {
              Header: 'category',
              accessor: 'taskType.name',
            },
            {
              Header: 'scheduledFor',
              accessor: 'date',
              Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
            },
          ]}
        />
      </EditCard>
    </>
  );
};

export default ContractView;
