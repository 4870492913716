import React from 'react';
import Drawer from 'rc-drawer';
import FormBusinessEdit from 'containers/CRM/Business/Edit/form';

const BusinessEditView = ( {
  businnesId, extraData, open, onClose, onReloadData,
} ) => (
  <Drawer
    open={open}
    placement="right"
    handler={false}
    maskClosable
    level={null}
    onClose={onClose}
    className="zindex-drawer"
    width={600}
  >
    {open && (
    <FormBusinessEdit
      businnesId={businnesId}
      extraData={extraData}
      open={open}
      onClose={onClose}
      onReloadData={onReloadData}
    />
    )}
  </Drawer>
);

export default BusinessEditView;
