import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  Form,
  TextField,
  FormActionsBtns,
  EditCard,
  Intl,
  CanAccess,
  LocalTable,
  AddBtn,
  TableActionsBtns,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    name,
    data,
    paths,
    onSubmit,
    cancellationPolicy,
    onDelete,
    toggleEditModal,
  } ) => (
    <EditCard
      headerContent={(
        <>
          <h3 className="mb-1 mt-1">
            <Intl id={canModify( 'accommodation' ) ? 'editAccommodation' : 'accommodation'} />
            {` ${name}`}
          </h3>
          <hr className="mt-3 mb-4" />
          <h3 className="m-0"><Intl id="cancellationPolicies" /></h3>
        </>
      )}
      cardClass="card-sec-bottom"
    >
      <Form
        initialValues={data}
        onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
        showErrorsInFooter
        body={() => (
          <>
            <Row>
              <Col md={6}>
                <TextField
                  field="policyText"
                  label="cancellationPolicies:text"
                  type="textarea"
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <LocalTable
                  data={cancellationPolicy}
                  isLoading={false}
                  titleContent={(
                    <>
                      <h3 className="mb-1">
                        <Intl id="cancellationPolicies:list" />
                      </h3>
                      <hr className="mt-3 mb-4" />
                    </>
                  )}
                  rightContent={canModify( 'accommodation' ) && <AddBtn title="cancellationPoliciesAdd" onClick={() => toggleEditModal( true )} />}
                  containerClassName="card-sec-bottom"
                  columns={[
                    {
                      Header: 'cancellationPolicies:validFrom',
                      accessor: 'validFrom',
                    },
                    {
                      Header: 'cancellationPolicies:validTo',
                      accessor: 'validTo',
                    },
                    {
                      Header: 'cancellationPolicies:percentage',
                      accessor: 'percentage',
                    },
                    {
                      accessor: 'id',
                      disableSorting: true,
                      Header: null,
                      maxWidth: 65,
                      Cell: ( { cell } ) => (
                        <TableActionsBtns
                          actions={[
                            {
                              isDelete: true,
                              onClick: ( cb ) => onDelete( cell.value, cb ),
                            },
                          ]}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
          </>
        )}
        footer={(
          <CanAccess I="edit" a="accommodation">
            <FormActionsBtns cancelTo={paths.accommodation} />
          </CanAccess>
        )}
      />
    </EditCard>
  ),
);
