import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Intl } from 'components';

export default React.memo( ( {
  title, route, onClick, size = 'md', color,
} ) => {
  const [tooltipOpen, setTooltipOpen] = useState( false );
  const toggle = () => setTooltipOpen( !tooltipOpen );

  if ( route ) {
    return (
      <Button
        size={size}
        color={color || 'primary'}
        data-cy="add-btn"
        tag={Link}
        to={route}
        id={title}
      >
        <i className="th-icon-plus mr-3 ml-1 ml-lg-0" style={{ fontSize: 12 }} />
        <div className="d-none d-lg-inline">
          <Intl id={title} />
        </div>
        <Tooltip placement="bottom" className="d-lg-none" target={title} isOpen={tooltipOpen} toggle={toggle}>
          <Intl id={title} />
        </Tooltip>
      </Button>
    );
  }
  return (
    <Button
      size={size}
      color={color || 'primary'}
      data-cy="add-btn"
      onClick={() => onClick()}
      id={title}
    >
      <i className="th-icon-plus mr-3 ml-1 ml-lg-0" style={{ fontSize: 12 }} />
      <div className="d-none d-lg-inline">
        <Intl id={title} />
      </div>
      <Tooltip placement="bottom" className="d-lg-none" target={title} isOpen={tooltipOpen} toggle={toggle}>
        <Intl id={title} />
      </Tooltip>
    </Button>
  );
} );
