import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import SettingsActions from 'store/reducers/Settings';
import PaymentsService from 'api/Payments';
import { formatDataToSend, handlePromise } from 'utils';
import useFetch from 'hooks/useFetch';
import PluginService from 'api/Plugin';
import NewPaymentView from 'views/Resource/Edit/Finance/Payment/new';

const NewPaymentContainer = ( {
  entityId,
  paymentToEdit,
  onClose,
  onReloadData,
  toggleInfoAlert,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const datesSend = formatDataToSend( formData, form );

      let date = '';
      if ( datesSend.date ) {
        date = ( typeof datesSend.date === 'number' ? moment.utc( datesSend.date ).format( 'YYYY-MM-DD' ) : datesSend.date.format( 'YYYY-MM-DD' ) );
      }

      let notificationDate = '';
      if ( datesSend.notificationDate ) {
        notificationDate = ( typeof datesSend.notificationDate === 'number' ? moment.utc( datesSend.notificationDate ).format( 'YYYY-MM-DD' ) : datesSend.notificationDate.format( 'YYYY-MM-DD' ) );
      }

      const dataToSend = {
        ...datesSend,
        date,
        notificationDate,
      };

      const [dataErrors, dataResponse] = await handlePromise(
        paymentToEdit
          ? PaymentsService.putPaymentDetail( paymentToEdit.id, dataToSend )
          : PaymentsService.postPaymentsResource( entityId, dataToSend ),
      );

      toggleLoading( false );
      if ( !dataResponse.ok ) return dataErrors;

      toggleInfoAlert( 'dataSaved' );
      onReloadData();
    },
    [entityId, paymentToEdit, onReloadData, toggleInfoAlert, toggleLoading],
  );

  const [plugins] = useFetch( {
    initialState: [],
    promise: () => PluginService.getPlugins( {
      installed: 'true',
    } ),
    toggleErrorAlert,
  } );

  const activePlugins = _.map( _.get( plugins, 'data', [] ), 'key' );

  return (
    <NewPaymentView
      paymentToEdit={paymentToEdit}
      submitForm={submitForm}
      onClose={onClose}
      plugins={activePlugins}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( NewPaymentContainer );
