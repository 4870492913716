import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  Intl, Badge,
} from 'components';

export default React.memo( ( {
  title, value, colors, children = null, size = 'md',
} ) => {
  const [open, setOpen] = useState( false );
  const toggle = () => setOpen( !open );

  return (
    <>
      <Badge
        value={value}
        onClick={toggle}
        colors={colors}
        className={children ? 'clickeable' : null}
      />
      {children && (
      <Modal
        className="modal-dialog-centered app-modal"
        size={size}
        isOpen={open}
        toggle={toggle}
      >
        <ModalHeader
          toggle={toggle}
        >
          <Intl id={title} />
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
      </Modal>
      )}
    </>
  );
} );
