import React from 'react';
import { connect } from 'react-redux';

import CommunityRuleEditView from 'views/CommunityRule/Edit';
import CommunityRuleService from 'api/CommunityRule';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import TranslationService from 'api/Translation';

const CommunityRuleEdit = ( {
  communityRuleId, modalOpened, onReload, onCloseModal,
  toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => CommunityRuleService.getCommunityRule( communityRuleId ),
    translationPromise: () => TranslationService.getCommunityRulesTranslations( communityRuleId, 'communityRule' ),
    toggleErrorAlert,
    conditional: !!communityRuleId && modalOpened,
    deps: [communityRuleId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( communityRuleId
      ? CommunityRuleService.updateCommunityRule( communityRuleId, dataToSend )
      : CommunityRuleService.saveCommunityRule( dataToSend ) ),
    toggleLoading,
    toggleInfoAlert,
    translations: true,
    deps: [communityRuleId],
    callback: onReload,
  } );

  return (
    <CommunityRuleEditView
      communityRuleId={communityRuleId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CommunityRuleEdit );
