import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EditView from 'views/PropertyManager/Edit/Deposits/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useSubmit } from 'hooks';
import useFetch from 'hooks/useFetch';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import PropertyService from 'api/Property';
import DepositsService from 'api/Deposits';
import PluginService from 'api/Plugin';
import { formatFloatToSend } from 'utils';

const Edit = ( {
  onClose,
  data,
  open,
  depositId,
  toggleErrorAlert,
  toggleInfoAlert,
  toggleLoading,
  onReloadData,
} ) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    conditional: open,
    deps: [open],
  } );

  const handleOnChangeCountry = useCallback( ( id ) => {
    setSelectedCountry( _.find( countries, { id } ) );
    setSelectedState();
  }, [countries] );

  const [cities, citiesLoading] = useFetch( {
    initialState: [],
    promise: () => ( selectedCountry && selectedState
      ? CityService.getCities( selectedCountry.iso, selectedState.iso )
      : new Promise( ( resolver ) => resolver( {
        ok: true,
        data: [],
      } ) ) ),
    toggleErrorAlert,
    conditional: open,
    deps: [open, selectedCountry, selectedState],
  } );

  const [states, statesLoading] = useFetch( {
    initialState: [],
    promise: () => StateService.getStates( selectedCountry.iso ),
    toggleErrorAlert,
    conditional: open && !!selectedCountry,
    deps: [open, selectedCountry],
  } );

  const handleOnChangeState = useCallback(
    ( id ) => {
      setSelectedState( _.find( states, { id } ) );
    },
    [states],
  );

  const [properties, propertiesLoading] = useFetch( {
    initialState: [],
    promise: PropertyService.getProperties,
    format: ( dataToFormat ) => _.get( dataToFormat, 'data', [] ),
    toggleErrorAlert,
    conditional: open,
    deps: [open],
  } );
  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( depositId
      ? DepositsService.updateDeposit( depositId,
        dataToSend ) : DepositsService.saveDeposit( dataToSend ) ),
    format: ( dataToSend ) => ( {
      city: dataToSend.city,
      country: dataToSend.country,
      depositAmount: formatFloatToSend( dataToSend.depositAmount ),
      depositType: dataToSend.depositType,
      gateway: dataToSend.gateway,
      property: dataToSend.property,
      propertyManager: dataToSend.propertyManager,
    } ),
    toggleLoading,
    toggleInfoAlert,
    deps: [depositId],
    callback: onReloadData,
  } );

  const [plugins] = useFetch( {
    initialState: [],
    promise: () => PluginService.getPlugins( {
      installed: 'true',
    } ),
    toggleErrorAlert,
  } );

  const activePlugins = _.map( _.get( plugins, 'data', [] ), 'key' );

  return (
    <>
      <EditView
        data={data}
        onSubmit={onSubmit}
        onClose={onClose}
        open={open}
        onChangeCountry={handleOnChangeCountry}
        onChangeState={handleOnChangeState}
        countries={countries}
        states={states}
        cities={cities}
        properties={properties}
        isLoadingCountries={countriesLoading}
        isLoadingCities={citiesLoading}
        isLoadingStates={statesLoading}
        isLoadingProperties={propertiesLoading}
        plugins={activePlugins}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( Edit );
