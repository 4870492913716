import api from 'config/Api/Api';

const baseEndpoint = '/dm-shift-schedule';

export default {
  async getDmShiftSchedules( params ) {
    const [responseEmplyes, responseShifts] = await Promise.all( [
      api.get( '/customers/type/employees', { elementsPerPage: 100 } ),
      api.get( baseEndpoint, params ),
    ] );

    return {
      ok: responseEmplyes.ok && responseShifts.ok,
      data: {
        employees: responseEmplyes.data,
        shifts: responseShifts.data,
      },
    };
  },
};
