import React from 'react';
import { Route, Switch } from 'react-router';

import Dashboard from './Dashboard';
import Detail from './Detail';
import { canView, canList } from '../../config/Ability';

const Shift = ( { match } ) => (
  <Switch>
    {canView( 'shift' )
    && (
      <Route
        path={`${match.url}/:id`}
        render={( props ) => <Detail {...props} parentPath={match.url} />}
      />
    )}
    {canList( 'shift' )
    && (
    <Route
      path={match.url}
      render={( props ) => <Dashboard {...props} parentPath={match.url} />}
    />
    )}
  </Switch>
);

export default Shift;
