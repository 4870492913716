import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Card, CardBody, CardHeader, Row, Col,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
} from 'reactstrap';

import {
  Intl, CanAccess, UserAvatar,
} from 'components';
import ability from 'config/Ability';
import { getInitials, getResourceName } from 'utils';

const ResourceAvatarRole = ( { data, roleText, onOpenDeleteParticipantModal } ) => (
  <Row className="chat-member mb-1 py-1 align-items-center">
    <Col>
      <UserAvatar
        avatarSize="sm"
        avatar={data.profilePicture}
        initials={getInitials( data )}
        name={(
          <>
            <p className="text-sm m-0">
              {getResourceName( data )}
            </p>
            <p
              className="text-2xsm m-0 text-gray-2"
            >
              <Intl id={roleText} />
            </p>
          </>
        )}
      />
    </Col>
    {roleText !== 'creator'
    && (
    <Col md="auto" className="pl-0 chat-member-delete d-none" onClick={onOpenDeleteParticipantModal}>
      <i className="app-icon-close text-2xsm" />
    </Col>
    )}
  </Row>
);

export default ( {
  data = {}, isLoading, onOpenAddParticipantModal, onOpenDeleteParticipantModal,
} ) => {
  const administrators = _.filter( data.administrators, ( item ) => item.id !== data.owner.id );
  const participants = _.filter( data.participants, ( item ) => item.id !== data.owner.id && _.map( administrators, 'id' ).indexOf( item.id ) === -1 );

  return (
    <Card
      className={classnames( 'chat-container chat-overview', {
        'app-component-loading': isLoading,
      } )}
    >
      <CardHeader className="d-flex align-items-center justify-content-between">
        <span className="font-weight-bold text-primary text-xsm text-uppercase">
          {data.participants.length}
          {' '}
          <Intl id="members" />
        </span>
        {ability.can( 'add_administrator', 'channel' ) || ability.can( 'add_participant', 'channel' )
          ? (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link mr-0 p-2" color="" tag="button">
                <i className="fa fa-ellipsis-h" />
              </DropdownToggle>

              <DropdownMenu right className="dropdown-actions text-center fadeInLeftBig">
                <CanAccess I="add_participant" a="channel">
                  <span className="cursor-pointer dropdown-item" onClick={() => onOpenAddParticipantModal( 'member' )}>
                    <Intl id="addMember" />
                  </span>
                </CanAccess>
                {ability.can( 'add_administrator', 'channel' ) && ability.can( 'add_participant', 'channel' )
                  ? <DropdownItem divider className="m-1" /> : null}
                <CanAccess I="add_administrator" a="channel">
                  <span className="cursor-pointer dropdown-item" onClick={() => onOpenAddParticipantModal( 'admin' )}>
                    <Intl id="addAdmin" />
                  </span>
                </CanAccess>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : null}
      </CardHeader>

      <CardBody className="p-0">
        <section className="p-4">
          <ResourceAvatarRole data={data.owner} roleText="creator" />
          {_.map( administrators, ( administrator ) => (
            <ResourceAvatarRole
              key={administrator.id}
              data={administrator}
              roleText="administrator"
              onOpenDeleteParticipantModal={() => onOpenDeleteParticipantModal( administrator, 'admin' )}
            />
          ) )}
          {_.map( participants, ( participant ) => (
            <ResourceAvatarRole
              key={participant.id}
              data={participant}
              roleText="participant"
              onOpenDeleteParticipantModal={() => onOpenDeleteParticipantModal( participant, 'participant' )}
            />
          ) )}
        </section>
      </CardBody>
    </Card>
  );
};
