import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import ContactActivityTypes from 'config/Constants/ContactActivityTypes';
import { Intl } from 'components';

const CardActivity = ( { data } ) => {
  const dateString = useMemo( () => {
    const d = new Date( data.updatedAt );
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    return `${date < 10 ? `0${date}` : date}/${month < 10 ? `0${month + 1}` : month + 1}/${year}`;
  }, [data] );

  const title = useMemo( () => ContactActivityTypes.find(
    ( type ) => type.id === data.type,
  )?.name, [data] );

  return (
    <div className="mb-4">
      <div className="bg-lighter p-3 rounded-xl">
        <Row className="align-items-center ">
          <Col><h4 className="m-0"><Intl id={title} /></h4></Col>
          <Col xs="auto">{dateString}</Col>
        </Row>
        <p className="m-0 text-small">
          {data?.data}
        </p>
        <p className="m-0">
          {data?.description}
        </p>
      </div>
    </div>
  );
};

export default CardActivity;
