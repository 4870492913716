import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PointOfInterestEditView from 'views/PointOfInterest/Edit';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import PointOfInterestService from 'api/PointOfInterest';
import PointOfInterestCategoryService from 'api/PointOfInterestCategory';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import { useFetch } from 'hooks';

const PointOfInterestEdit = ( {
  parentPath, history, match, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const entityId = match.params.id;

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => PointOfInterestService.getPointOfInterest( entityId ),
    format: ( dataToFormat ) => {
      if ( !entityId ) return dataToFormat;

      const formattedData = { ...dataToFormat };

      return formattedData;
    },
    toggleErrorAlert,
    conditional: !!entityId,
    deps: [entityId],
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );
    dataToSend.enabled = dataToSend.enabled ? 'true' : 'false';

    delete dataToSend.country;
    delete dataToSend.state;
    dataToSend.tags = dataToSend.tags ? dataToSend.tags.toString() : '';

    const [errors, response] = await handlePromise( entityId
      ? PointOfInterestService.updatePointOfInterest( entityId, dataToSend )
      : PointOfInterestService.savePointOfInterest( dataToSend ) );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
    history.push( parentPath );
  }, [entityId, parentPath, history, toggleInfoAlert, toggleLoading] );

  const fetchPoiCategories = useCallback(
    ( ) => PointOfInterestCategoryService.getCategories( {
      elementsPerPage: 500,
    } ),
    [],
  );

  // countries, states, cities
  const [states, setStates] = useState( [] );
  const [statesLoading, setStatesLoading] = useState( false );
  const [cities, setCities] = useState( [] );
  const [citiesLoading, setCitiesLoading] = useState( false );

  const [statesLoaded, setStatesLoaded] = useState( false );
  const [citiesLoaded, setCitiesLoaded] = useState( false );

  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    reInit: true,
    deps: [],
  } );
  const fetchStates = useCallback( async ( countryId ) => {
    const country = _.find( countries, { id: countryId } );
    if ( !country ) return toggleErrorAlert( 'error' );

    setStates( [] );
    setCities( [] );
    setStatesLoading( true );
    const [errors, response, responseData] = await handlePromise(
      StateService.getStates( country.iso ),
    );
    setStatesLoading( false );

    if ( !response.ok ) return toggleErrorAlert( errors );
    setStates( responseData );
    setCities( [] );
  }, [countries, toggleErrorAlert] );

  const fetchCities = useCallback( async ( stateId, countryId ) => {
    const country = _.find( countries, { id: countryId } );
    const state = _.find( states, { id: stateId } );

    if ( !country || !state ) return toggleErrorAlert( 'error' );

    setCities( [] );
    setCitiesLoading( true );
    const [errors, response, responseData] = await handlePromise(
      CityService.getCities( country.iso, state.iso ),
    );
    setCitiesLoading( false );

    if ( !response.ok ) return toggleErrorAlert( errors );
    setCities( responseData );
  }, [countries, states, toggleErrorAlert] );

  useEffect( () => {
    if ( data && data.country && countries.length > 1
      && !statesLoaded ) {
      setStatesLoaded( true );
      fetchStates( data.country.id );
    }
  }, [data, countries, fetchStates, statesLoaded] );

  useEffect( () => {
    if ( data && data.state && data.country
      && countries.length > 1 && states.length > 1
      && !citiesLoaded ) {
      setCitiesLoaded( true );
      fetchCities( data.state.id, data.country.id );
    }
  }, [data, countries, states, fetchCities, citiesLoaded] );

  return (
    <PointOfInterestEditView
      data={data}
      isLoading={dataLoading}
      parentPath={parentPath}
      entityId={entityId}
      onSubmit={submitForm}
      onFetchPoiCategories={fetchPoiCategories}
      options={{ countries, states, cities }}
      countriesLoading={countriesLoading}
      statesLoading={statesLoading}
      citiesLoading={citiesLoading}
      onChangeCountry={fetchStates}
      onChangeState={fetchCities}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( PointOfInterestEdit );
