import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';

import {
  AsyncTable, AddBtn, SimpleHeader, TableActionsBtns,
  ResourceAvatar, CanAccess, TableSelectFilter, DaterangePickerBtn, Intl,
} from 'components';
import { getResourceName, getSlotSchedule } from 'utils';
import ability, { canModify } from 'config/Ability';

export default React.memo( ( {
  data,
  isLoading,
  parentPath,
  initialDates,
  defaultFilters,
  onFetchData,
  onOpenEditModal,
  onDelete,
  onFetchTeams,
  onFetchEmployees,
  onDateChanged,
  onOpenBulkEditModal,
} ) => {
  const canEditDelete = canModify( 'shift' );

  return (
    <>
      <SimpleHeader
        title="shifts"
        rightContent={(
          <DaterangePickerBtn initialValue={initialDates} key={initialDates} position="right" onChange={onDateChanged} />
        )}
      />
      <Container fluid>
        <AsyncTable
          id="shifts"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={
            ability.can( 'manage', 'shift' ) ? (
              <>
                <AddBtn title="bulkAssigment" onClick={onOpenBulkEditModal} />
                <AddBtn title="newShift" onClick={onOpenEditModal} />
              </>
            ) : null
          }
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableSelectFilter
                  name="teams"
                  label="team"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  multiple
                  defaultOptions
                  setFilter={setFilter}
                  loadOptions={onFetchTeams}
                />
              </Col>
              <Col md={8} lg={4} className="pl-lg-0 pt-1 pt-lg-0">
                <TableSelectFilter
                  name="managers"
                  label="manager"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  multiple
                  defaultOptions
                  labelKey={getResourceName}
                  setFilter={setFilter}
                  loadOptions={onFetchEmployees}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'team',
              accessor: 'team.name',
              Cell: ( { cell } ) => (
                <CanAccess I="view" a="shift" passThrough>
                  {( can ) => ( can
                    ? (
                      <Link to={`${parentPath}/${cell.row.original.id}`}>
                        {cell.value}
                      </Link>
                    ) : cell.value )}
                </CanAccess>
              ),
            },
            {
              Header: 'day',
              accessor: 'date',
              Cell: ( { cell } ) => (
                cell.value ? moment.utc( cell.value ).format( 'ddd DD/MM/YYYY' ) : ''
              ),
            },
            {
              Header: 'shift',
              accessor: 'shiftTemplate',
              Cell: ( { cell } ) => {
                if ( _.get( cell, 'row.original.team.shiftTemplates', [] ).length
                    && cell.value ) {
                  const shift = _.find( cell.row.original.team.shiftTemplates,
                    { _id: cell.value } );
                  if ( shift ) return shift.name;
                }
                return '';
              },
            },
            {
              Header: 'schedule',
              accessor: 'from',
              Cell: ( { cell } ) => getSlotSchedule( cell.row.original ),
            },
            {
              Header: 'assigned',
              accessor: 'assigned',
              Cell: ( { cell } ) => (
                <ResourceAvatar data={cell.value} />
              ),
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => {
                if ( !cell.row.original.assigned ) {
                  return <span className="shift-status  shift-status-not-assigned"><Intl id="shiftPendingAssignment" /></span>;
                }

                if ( cell.value === 'accepted'
                ) {
                  return <span className="shift-status  shift-status-accepted"><Intl id="shiftAccepted" /></span>;
                }

                if ( cell.value === 'pending' ) {
                  return <span className="shift-status  shift-status-pending"><Intl id="shiftPending" /></span>;
                }

                if ( cell.value === 'rejected' ) {
                  return <span className="shift-status  shift-status-rejected"><Intl id="shiftRejected" /></span>;
                }

                return null;
              },
            },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => {
                  const actions = [];

                  actions.push( {
                    label: 'edit',
                    onClick: () => onOpenEditModal( { id: cell.value } ),
                    'data-cy': 'table-edit-btn',
                  } );

                  actions.push( {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  } );

                  return (
                    <TableActionsBtns actions={actions} />
                  );
                },
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );
