import React from 'react';
import { Route, Switch } from 'react-router';

import { canList, canModify } from 'config/Ability';
import Dashboard from './Dashboard';
import Edit from './Edit';

const Post = ( { match } ) => (
  <Switch>
    {canModify( 'cms' ) && (
      <Route
        path={`${match.url}/new`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
      />
    )}
    {canModify( 'cms' ) && (
      <Route
        path={`${match.url}/edit/:id`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
      />
    )}
    {canList( 'cms' ) && (
      <Route
        path={match.url}
        render={( props ) => <Dashboard {...props} parentPath={match.url} />}
      />
    )}
  </Switch>
);

export default Post;
