import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import {
  FormGroup, InputGroup, InputGroupText, InputGroupAddon as InputGroupAddonComp,
} from 'reactstrap';
import ReactDatetime from 'react-datetime';

import { Intl } from 'components';
import InputGroupAddon from '../InputGroupAddon';

const DatepickerInput = React.memo(
  ( {
    label,
    position,
    value,
    inputOnChange,
    inputName,
    size = 'md',
    onChange,
    placeholder,
    prependIcon,
    appendIcon,
    intl,
    translateValues,
    isInvalid,
    focused,
    meta,
    startDateField,
    endDateField,
    formChange,
    translate = true,
    formGroupClass,
    labelClass,
    prependIconClass,
    appendIconClass,
    clearable,
    customValidate,
    disabled,
    onBlur,
    ...rest
  } ) => (
    <FormGroup
      className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}
    >
      {label && (
        <label className={classnames( labelClass, 'form-control-label' )}>
          {translate ? <Intl id={label} /> : label}
        </label>
      )}

      <div>
        <InputGroup
          className={classnames( 'input-group-merge', { focused, clearable } )}
        >
          {prependIcon || prependIconClass ? (
            <InputGroupAddon
              addonType="prepend"
              isInvalid={isInvalid}
              icon={prependIcon}
              iconClass={prependIconClass}
            />
          ) : null}

          <ReactDatetime
            timeFormat={false}
            onBlur={onBlur}
            {...rest}
            className={classnames( { 'rdt-right': position === 'right' } )}
            inputProps={{
              disabled,
              readOnly: true,
              placeholder: placeholder
                ? intl.formatMessage( { id: placeholder } )
                : null,
              className: classnames( 'form-control', {
                'is-invalid': isInvalid,
                'form-control-lg': size === 'lg',
                'form-control-sm': size === 'sm',
                'form-control-xsm': size === 'xsm',
              } ),
              'data-cy': `input-${inputName}`,
            }}
            value={value || ''}
            onChange={( e ) => {
              if ( inputOnChange ) inputOnChange( e );
              if ( onChange ) onChange( e );
              if (
                e
                && endDateField
                && endDateField.value
                && e.isSameOrAfter( endDateField.value )
                && formChange
              ) {
                formChange( e );
              }
            }}
            isValidDate={( currentDate ) => {
              if ( typeof customValidate === 'function' ) {
                return customValidate( currentDate );
              }
              if ( startDateField && startDateField.value ) {
                return currentDate.isAfter( startDateField.value );
              }
              return true;
            }}
          />

          {!!clearable && value && (
            <InputGroupAddonComp
              addonType="append"
              onClick={() => {
                if ( inputOnChange ) inputOnChange( null );
                if ( onChange ) onChange( null );
              }}
              className={classnames( 'rdt-clear cursor-pointer', {
                'is-invalid': isInvalid,
              } )}
            >
              <InputGroupText>
                <i className="app-icon-close" style={{ fontSize: '.7rem' }} />
              </InputGroupText>
            </InputGroupAddonComp>
          )}

          {appendIcon || appendIconClass ? (
            <InputGroupAddon
              addonType="append"
              isInvalid={isInvalid}
              icon={appendIcon}
              iconClass={appendIconClass}
            />
          ) : null}
        </InputGroup>

        {isInvalid && (
          <div className="invalid-feedback">
            <Intl
              id={meta.error || meta.submitError}
              values={translateValues}
            />
          </div>
        )}
      </div>
    </FormGroup>
  ),
);

export default injectIntl( DatepickerInput );
