import React from 'react';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';

import { Intl } from 'components';
import CustomField from '../index';

export default React.memo( ( { customFields } ) => (
  customFields && customFields.length
    ? (
      <>
        <hr className="divider-dashed" />
        <h4 className="font-weight-normal text-uppercase"><Intl id="customFields" /></h4>

        {_.map( _.groupBy( customFields, 'category' ), ( fields, category ) => (
          <div key={category} data-cy={`category-${category}`}>
            <Row>
              <Col>
                <h5 className="font-weight-normal text-uppercase mt-2" data-cy="category-title">
                  {category === 'undefined' ? 'others' : category.split( '-' ).join( ' ' )}
                </h5>
              </Col>
            </Row>
            <Row>
              {_.map( fields, ( item ) => (
                <Col md={5} key={item.id}>
                  <CustomField field={item} />
                </Col>
              ) )}
            </Row>
          </div>
        ) )}
      </>
    ) : null
) );
