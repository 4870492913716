/* eslint-disable no-nested-ternary */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import classnames from 'classnames';

import {
  SimpleHeader,
  Form,
  TextField,
  FormActionsBtns,
  EditCard, CanAccess,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  data, entityId, propertyName, isLoading, paths, onSubmit,
} ) => (
  <>
    <SimpleHeader
      title={propertyName}
      // parentLinks={[
      //   { name: propertyName, translate: false, href: paths.property },
      //   { name: 'roomTypes', href: paths.roomType },
      // ]}
    />

    <Container
      fluid
      className={classnames( { 'app-component-loading': isLoading } )}
    >
      <EditCard title={entityId
        ? canModify( 'room_type' )
          ? 'editRoomType' : 'roomType' : 'newRoomType'}
      >
        <Form
          initialValues={data}
          onSubmit={canModify( 'room_type' ) ? onSubmit : () => {}}
          showErrorsInFooter
          body={() => (
            <>
              <Row>
                <Col md={5}>
                  <TextField
                    field="name"
                    label="name"
                    validate={required}
                  />
                </Col>
                <Col md={5}>
                  <TextField
                    field="commercialName"
                    label="commercialName"
                    validate={required}
                  />
                </Col>
              </Row>
            </>
          )}
          footer={<CanAccess I="edit" a="room_type"><FormActionsBtns cancelTo={paths.roomType} /></CanAccess>}
        />
      </EditCard>
    </Container>
  </>
) );
