import React, { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _get from 'lodash/get';

import { Intl } from 'components';
import _ from 'lodash';
import {
  ExtraBed, Cradle, Star, Info, Delete,
} from './Icons';

const priorities = {
  blocked: 'app-icon-warning text-red-2',
  high: 'app-icon-chevron-up text-green-1',
  highest: 'app-icon-double-chevron-up text-red-2',
  normal: 'app-icon-circle-o text-green-3',
  low: 'app-icon-chevron-down text-green-1',
  lowest: 'app-icon-double-chevron-down text-gray-1',
};

const getId = ( id, idx ) => `${id}-${idx}`;

const Accommodation = ( {
  data,
  dateRange,
  propertyId,
  onContextMenu,
  onClickBooking,
  onClickTask,
  onDeleteTask,
  onDragEnd,
  onDeleteBookingBlocked,
  onClickCell,
} ) => {
  const [isDraggingOver, setIsDragging] = useState( false );
  return (
    <DragDropContext
      onDragStart={() => setIsDragging( true )}
      onDragEnd={( response ) => {
        setIsDragging( false );
        const destinationDate = _get( response, 'destination.droppableId' );
        const sourceDate = _get( response, 'source.droppableId' );
        const taskId = _get( response, 'draggableId' );
        if ( destinationDate !== sourceDate ) {
          onDragEnd(
            propertyId,
            taskId,
            {
              date: moment( destinationDate, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD HH:mm:ss' ),
            },
          );
        }
      }}
    >
      <tr className={classnames( 'dc_data-row', {
        'bg-info': isDraggingOver,
      } )}
      >
        <th className="dc_sidebar_cell dc_bg-white">
          {data.name}
          <span>
            <i className={_.get( data, 'cleaningStatus' ) === 'pending' ? 'fas fa-broom text-danger ml-1' : 'fas fa-broom text-success ml-1'} />
          </span>
        </th>
        {dateRange.map( ( { date }, idx ) => (
          <Droppable droppableId={date} key={date}>
            {( providedB, snapshotB ) => {
              const dayWeek = moment( date, 'YYYY-MM-DD' ).day();
              return (
                <td
                  className={classnames( {
                    'bg-warning': snapshotB.isDraggingOver,
                    'dc_bg-white': dayWeek < 6 && dayWeek > 0,
                  } )}
                  {...providedB.droppableProps}
                  ref={providedB.innerRef}
                >
                  <div
                    onClick={( e ) => {
                      e.stopPropagation();
                      onClickCell( {
                        accommodationId:
                      data.accommodationId,
                        date,
                        roomTypeId: data.roomType.id,
                      } );
                    }}
                    onContextMenu={( e ) => {
                      e.preventDefault();
                      onContextMenu( data, date );
                    }}
                    className="h-100 d-flex flex-wrap align-content-start "
                  >
                    { data.cells[idx]
                      .map(
                        ( entity, index ) => (
                          entity.elementtType === 'booking' ? (
                            <BookingEntity
                              key={getId( entity.bookingId, idx )}
                              entity={entity}
                              onClickBooking={onClickBooking}
                              onDeleteBookingBlocked={onDeleteBookingBlocked}
                            />
                          ) : (
                            <Draggable
                              draggableId={entity.taskId}
                              index={index}
                              key={entity.taskId}
                            >
                              {( providedD ) => (
                                <div
                                  ref={providedD.innerRef}
                                  {...providedD.draggableProps}
                                  {...providedD.dragHandleProps}
                                  className="dc_entity d-inline-block"
                                >
                                  <div className="d-flex">
                                    <div
                                      onClick={( e ) => {
                                        e.stopPropagation();
                                        onClickTask( entity );
                                      }}
                                    >
                                      <span>
                                        <i
                                          className={classnames( priorities[entity.priority], 'text-2xsm' )}
                                          id={`tooltip-supervisor-${entity.taskId}`}
                                        />
                                        <UncontrolledTooltip
                                          delay={0}
                                          target={`tooltip-supervisor-${entity.taskId}`}
                                        >
                                          <Intl id={entity.priority} />
                                        </UncontrolledTooltip>
                                      </span>
                                      {entity.taskTypeName}
                                    </div>
                                    <Delete
                                      className="dc_delete_icon"
                                      onClick={( e ) => {
                                        e.stopPropagation();
                                        onDeleteTask( entity.taskId );
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        ),
                      )}

                    {providedB.placeholder}
                  </div>
                </td>
              );
            }}
          </Droppable>
        ) )}
      </tr>
    </DragDropContext>
  );
};

export default Accommodation;

const BookingEntity = ( { entity, onClickBooking, onDeleteBookingBlocked } ) => (
  <div
    className={classnames( 'dc_entity_no-draggable d-inline-block mb-3', {
      'w-50': entity.isEnd || entity.isStart,
      'ml-auto': entity.isStart,
    } )}
    key={entity.bookingId}
  >
    <div
      className={classnames( `cal-booking-line color-${entity.color}`, {
        'border_round-right': entity.isEnd,
        'border_round-left': entity.isStart,
      } )}
      onClick={( e ) => {
        e.stopPropagation();
        onClickBooking( entity );
      }}
    >
      {entity.showIcons && (
        <>
          {entity.type === 'blocked' && (
          <Info
            className="text-white"
            id={entity.bookingId}
            text={entity.blockedReason}
          />
          )}
          {entity.type === 'blocked' && (
          <Delete
            className="dc_delete_icon text-white"
            id={entity.bookingId}
            onClick={( e ) => {
              e.stopPropagation();
              onDeleteBookingBlocked( entity.bookingId );
            }}
          />
          )}
          {entity.cradle && (
          <Cradle
            className="text-white"
            id={entity.bookingId}
          />
          )}
          {entity.extraBed && (
          <ExtraBed
            className="text-white"
            id={entity.bookingId}
          />
          )}
          {entity.starred && (
          <Star
            className="text-white"
            id={entity.bookingId}
          />
          ) }
        </>
      ) }
    </div>
  </div>
);

// {data.cells[date]
