import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import TeamService from 'api/Team';
import TeamEditView from 'views/Team/Edit';
import SettingsActions from 'store/reducers/Settings';
import {
  formatDataPhone, handlePromise,
} from 'utils';
import { teamRoutes } from 'config/Routes';
import EmployeePermitsService from 'api/Settings/EmployeePermits';

const TeamEdit = ( {
  parentPath, match, location, toggleErrorAlert, history,
} ) => {
  const entityId = match.params.id;
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );
  /// /////////////////////////////////////
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( true );
  const [hasPermitsConfig, setHasPermitsConfig] = useState( false );

  useEffect( () => {
    const getEntityData = async ( ) => {
      if ( !entityId ) {
        setData( {} );
        setDataLoading( false );
        return;
      }
      setDataLoading( true );
      const [errors, response, responseData] = await handlePromise(
        TeamService.getTeam( entityId ),
      );

      setDataLoading( false );

      if ( !response.ok ) return toggleErrorAlert( errors );

      const [err, r, permits] = await handlePromise(
        EmployeePermitsService.getEmployeePermits( {
          page: 1,
          elementsPerPage: 10,
          enable: true,
        } ),
      );

      setHasPermitsConfig( permits.data.length > 0 );

      if ( !r.ok ) return toggleErrorAlert( err );

      const formattedData = formatDataPhone( responseData, ['phoneNumber'] );
      if ( formattedData.settings && formattedData.settings.locale ) {
        formattedData.locale = formattedData.settings.locale;
      }
      if ( formattedData.roles ) {
        formattedData.roles = _.find( formattedData.roles, ( item ) => item !== 'ROLE_EMPLOYEE' && item !== 'ROLE_USER' );
      }
      setData( { entity: formattedData } );
    };

    getEntityData();
  }, [entityId, toggleErrorAlert, dataSubmitted] );

  const getRoutes = () => {
    if ( hasPermitsConfig ) {
      return teamRoutes;
    }
    return teamRoutes.slice( 0, -1 );
  };

  return (
    <TeamEditView
      data={data}
      entityId={entityId}
      parentPath={parentPath}
      tabs={getRoutes()}
      pathLayout={match.url}
      pathname={location.pathname}
      isLoading={dataLoading}
      onReloadData={reloadData}
      history={history}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( TeamEdit );
