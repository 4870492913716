import api from 'config/Api/Api';

import { formatFormDataBody } from 'utils';

const baseEndpoint = 'city-app-images';

export default {
  getCityAppImages( params ) {
    return api.get( baseEndpoint, params );
  },

  getCityAppImage( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveImage( data ) {
    return api.post( baseEndpoint, formatFormDataBody( data ) );
  },

  deleteImage( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
