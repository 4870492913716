import React from 'react';

import {
  AddBtn,
  AsyncTable,
  SimpleHeader,
  TableActionsBtns,
  TableEditBtn, Intl,
} from 'components';
import Container from 'reactstrap/lib/Container';
import { formatFloat } from 'utils';

export default React.memo(
  ( {
    data, onFetchData, onDeleteItem, onOpenEditItemModal, isDataLoading,
  } ) => (
    <>
      <SimpleHeader
        title="deposits"
      />
      <Container fluid>
        <AsyncTable
          id="deposits"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isDataLoading}
          disableSorting
          onFetchData={onFetchData}
          rightContent={<AddBtn title="newDeposit" onClick={() => onOpenEditItemModal()} />}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <TableEditBtn
                  title={cell.value.substr( 0, 6 )}
                  onClick={() => onOpenEditItemModal( cell.value )}
                />
              ),
            },
            {
              Header: 'country',
              accessor: 'country.name',
            },
            {
              Header: 'city',
              accessor: 'city.name',
            },

            {
              Header: 'property',
              accessor: 'property.name',
            }, {
              Header: 'amount',
              accessor: 'depositAmount',
              Cell: ( { cell } ) => (
                formatFloat( cell.value )
              ),
            },
            {
              Header: 'gateway',
              accessor: 'gateway',
              Cell: ( { cell } ) => (
                <Intl id={cell.value} />
              ),
            },
            {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => (
                <TableActionsBtns
                  actions={[
                    {
                      isDelete: true,
                      onClick: ( cb ) => onDeleteItem( cell.value, cb ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </Container>

    </>
  ),
);
