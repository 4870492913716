import Country from 'containers/Country';
import PropertyManager from 'containers/PropertyManager';
import Resource from 'containers/Resource';
import ChangePassword from 'containers/ChangePassword';
import UserProfile from 'containers/UserProfile';

export default {
  headerLeft: [
    {
      name: 'countries',
      path: 'countries',
      icon: 'ni ni-world',
      component: Country,
      sidebar: true,
      layout: '/',
    },
    {
      name: 'propertyManagers',
      path: 'property-managers',
      icon: 'ni ni-world',
      component: PropertyManager,
      sidebar: true,
      layout: '/',
    },
    {
      name: 'resources',
      path: 'resources',
      icon: 'ni ni-world',
      component: Resource,
      sidebar: true,
      layout: '/',
    },
  ],
  headerRight: [],
  userMenu: [
    {
      name: 'myProfile',
      path: 'profile',
      icon: 'ni ni-single-02',
      component: UserProfile,
      headerDropdown: true,
      layout: '/',
    },
    {
      name: 'changePassword',
      path: 'change-password',
      icon: 'fa fa-key',
      component: ChangePassword,
      headerDropdown: true,
      layout: '/',
    },
  ],
};
