import React from 'react';
import { Container } from 'reactstrap';

import {
  SimpleHeader, AddBtn, TableActionsBtns, AsyncTable,
} from 'components';

import defaultImage from 'assets/img/defaults/accommodation.png';

export default React.memo( ( {
  data, isLoading, onOpenEditModal, onDelete, onFetchData,
} ) => (
  <>
    <SimpleHeader
      title="cityAppImages"
    />
    <Container fluid>
      <AsyncTable
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        onFetchData={onFetchData}
        disableSorting
        rightContent={<AddBtn title="newCityAppImage" onClick={onOpenEditModal} />}
        columns={[
          {
            Header: 'Image',
            accessor: 'file',
            Cell: ( { cell } ) => (
              cell.value
                ? <img src={cell.value} alt={cell.row.original.name} width="120" />
                : <img src={defaultImage} alt={cell.row.original.name} width="120" />
            ),
          },
          {
            Header: 'city',
            accessor: 'city.name',
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns actions={[
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ]}
              />
            ),
          },
        ]}
      />
    </Container>
  </>
) );
