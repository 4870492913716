import React from 'react';
import {
  LocalTable, Intl, Switch,
} from 'components';

const Dashboard = React.memo(
  ( {
    data, InstantNotificationTypes, isLoading, setAction,
  } ) => (
    <LocalTable
      data={data}
      manualPagination
     // onlyTable
      isLoading={isLoading}
      useElementsPerPage={false}
      cardHeaderClassName="p-0  border-0"
      cardFooterClassName="py-0 border-0"
      columns={[
        {
          Header: 'title',
          accessor: 'title',
          Cell: ( { cell } ) => ( <Intl id={cell.value} /> ),
        },
        {
          Header: 'type',
          accessor: 'type',
          Cell: ( { cell } ) => ( <Intl id={cell.value} /> ),
        },
        {
          Header: 'active',
          accessor: 'active',
          Cell: ( { cell } ) => {
            const value = InstantNotificationTypes.indexOf( cell.row.original.title ) >= 0;
            return (
              <Switch
                name={cell.row.original.title}
                        // formGroupClass="m-0 pl-3 d-inline-block"
                checked={value}
                hideSideLabels
                onClick={( ) => {
                  setAction( cell.row.original.title, !value );
                }}
              />
            );
          },

        },
      ]}
    />
  ),
);

export default Dashboard;
