import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';

import MapDashboardView from 'views/Map/Dashboard';
import TeamService from 'api/Team';
import ShiftService from 'api/Shift';
import PropertyManagerService from 'api/PropertyManager';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import { handlePromise } from 'utils';
import { ResourceAvatar } from 'components';

const MapDashboard = ( { propertyManager, toggleErrorAlert } ) => {
  const [team, setTeam] = useState( null );
  const [pmData, setPmData] = useState( null );
  const [pmCoordinates, setPmCoordinates] = useState( { lat: 34.240966, lng: -22.41203 } );
  const [openedMarker, setOpenedMarker] = useState( null );
  const infoWindow = useRef( null );
  const mapInstance = useRef( null );
  const mapsInstance = useRef( null );
  const [geocoder, setGeocoder] = useState( null );

  useEffect( () => {
    const getData = async () => {
      const [errors, response, responseData] = await handlePromise(
        PropertyManagerService.getPropertyManager( propertyManager.id ),
      );
      if ( response.ok && !errors ) {
        setPmData( responseData );
      }
    };
    getData();
  }, [propertyManager] );

  useEffect( () => {
    if ( geocoder && pmData ) {
      geocoder.geocode( {
        address: `${pmData.address} ${pmData.city || ''} ${pmData.postalCode || ''}`,
      }, ( results, status ) => {
        if ( status === 'OK' ) {
          setPmCoordinates( {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          } );
        }
      } );
    }
  }, [geocoder, pmData] );

  const [data, dataLoading] = useFetch( {
    initialState: [],
    promise: () => ShiftService.getShifts( {
      from: moment().format( 'YYYY-MM-DD' ),
      till: moment().format( 'YYYY-MM-DD' ),
      teams: team ? [team] : undefined,
      elementsPerPage: 1000,
    } ),
    format: ( dataToFormat ) => {
      const formattedData = [...dataToFormat.data];
      return _.chain( formattedData )
        .filter( ( slot ) => slot.status === 'accepted'
            && _.get( slot, 'assigned.employee.geoLocation.latitude' ) && _.get( slot, 'assigned.employee.geoLocation.longitude' ) )
        .groupBy( ( item ) => `${item.assigned.employee.geoLocation.latitude}/${item.assigned.employee.geoLocation.longitude}` )
        .value();
    },
    toggleErrorAlert,
    conditional: !!pmData && !!pmData.id,
    deps: [team, pmData],
  } );

  /* const [cities, setCities] = useState( [] );
  const [zones, setZones] = useState( [] );
  const [zonesLoading, setZonesLoading] = useState( false );

  useEffect( () => {
    const getData = async () => {
      const [errors, response, responseData] = await handlePromise(
        CityService.getCities( {
          propertyManager: propertyManager.id,
          elementsPerPage: 1000,
        } ),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setCities( responseData.data );
    };
    getData();
  }, [propertyManager, toggleErrorAlert] );

  const fetchZones = useCallback( async ( cityId ) => {
    if ( !cityId ) {
      setZones( [] );
      return;
    }
    setZonesLoading( true );
    const [errors, response, responseData] = await handlePromise(
      ZoneService.getZones( cityId, {
        propertyManager: propertyManager.id,
        elementsPerPage: 1000,
      } ),
    );
    setZonesLoading( false );

    if ( !response.ok ) return toggleErrorAlert( errors );
    return setZones( responseData.data );
  }, [propertyManager, toggleErrorAlert] ); */

  const fetchTeams = useCallback( ( query ) => TeamService.getTeams( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const submitFilters = useCallback( ( values ) => {
    if ( values.team ) setTeam( values.team.value );
    else setTeam( null );
  }, [] );

  const onGoogleApiLoaded = ( { map, maps } ) => {
    mapInstance.current = map;
    mapsInstance.current = maps;
    infoWindow.current = new maps.InfoWindow();
    setGeocoder( new maps.Geocoder() );

    mapsInstance.current.event.addListener( mapInstance.current, 'click', ( e ) => {
      if ( e.placeId ) setOpenedMarker( null );
    } );

    /* const flightPath = new maps.Polyline( {
      path: [{ lat: 40.4178273, lng: -3.7241361 }, { lat: 40.4409237, lng: -3.6919033 }],
      strokeColor: '#FB0000',
      strokeOpacity: 1,
      strokeWeight: 3,
    } );
    flightPath.setMap( map ); */
  };

  const openInfoWindow = ( marker, markersList ) => {
    setOpenedMarker( marker.id );

    infoWindow.current.setContent(
      ReactDOMServer.renderToString(
        _.map( markersList, ( slot, index ) => (
          <ResourceAvatar
            className={classnames( {
              'mb-2': index !== markersList.length - 1,
            } )}
            key={slot.id}
            data={slot.assigned.employee}
          />
        ) ),
      ),
    );
    infoWindow.current.setPosition( {
      lat: Number( marker.assigned.employee.geoLocation.latitude ),
      lng: Number( marker.assigned.employee.geoLocation.longitude ),
    } );
    infoWindow.current.open( mapInstance.current );
    mapsInstance.current.event.addListener( infoWindow.current, 'closeclick', () => {
      setOpenedMarker( null );
    } );
  };

  return (
    <MapDashboardView
      data={data}
      pmCoordinates={pmCoordinates}
      openedMarker={openedMarker}
      isLoading={dataLoading}
      onOpenInfoWindow={openInfoWindow}
      onFetchTeams={fetchTeams}
      onSubmitFilters={submitFilters}
      onGoogleApiLoaded={onGoogleApiLoaded}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( mapStateToProps, mapDispatchToProps )( MapDashboard );
