import React from 'react';
import { Button } from 'reactstrap';

import { Intl } from 'components';

export default React.memo( ( {
  title, onClick, icon, color, size, extraClass,
} ) => (
  <Button
    size={size || 'sm'}
    className={icon ? `btn-icon ${extraClass}` : extraClass}
    color={color || 'primary'}
    onClick={() => onClick()}
    type="button"
  >
    {icon && (
      <span className="btn-inner--icon mr-1">
        <i className={icon} />
      </span>
    )}
    <Intl id={title} />
  </Button>
) );
