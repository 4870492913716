const formatPlugins = ( pluginsData ) => {
  const pluginsFiltered = pluginsData.filter( ( plugin ) => plugin.categories.find( ( cat ) => cat === 'SML' ) );
  return pluginsFiltered.map( ( plugin ) => ( {
    id: plugin.key,
    value: plugin.key,
    name: plugin.name,
  } ) );
};

const formatConfiguredPlugins = ( cfgdPliginsData ) => {
  if ( cfgdPliginsData ) {
    return [];
  }
  return cfgdPliginsData.data;
};

export default { formatPlugins, formatConfiguredPlugins };
