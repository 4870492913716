/* eslint-disable import/prefer-default-export */
import { createSeasonDiscount, deleteSeasonDiscount, updateSeasonDiscount } from 'api/Discounts/SeasonDiscount';
import moment from 'moment';

const createWeekSeasonDiscount = async ( events = [], dataToSend ) => {
  const seasonToCheck = dataToSend.seasons[0];
  const dateStart = moment( seasonToCheck.startDate );
  const dateEnd = moment( seasonToCheck.lastNight );
  const filterInside = events.filter( ( item ) => dateStart.diff( moment( item.startDate ) ) <= 0
      && dateEnd.diff( moment( item.startDate ) ) >= 0 );

  await Promise.all( filterInside.map( async ( item ) => {
    if ( dateStart.diff( moment( item.startDate ) ) <= 0
    && dateEnd.diff( moment( item.lastNight ) ) >= 0 ) {
      await deleteSeasonDiscount( item.id );
      return;
    }
    if ( dateStart.diff( moment( item.startDate ) ) > 0
      && dateEnd.diff( moment( item.lastNight ) ) < 0 ) {
      await updateSeasonDiscount( {
        id: item.id,
        discountType: item.discountType,
        discountValue: item.discountValue,
        startDate: new Date( item.startDate ).toISOString(),
        lastNight: new Date( dateStart.add( -1, 'days' ) ).toISOString(),
        type: item.type,
        requirements: item.requirements,
      } );
      await createSeasonDiscount( {
        discountId: dataToSend.discountId,
        seasons: [
          {
            discountType: item.discountType,
            discountValue: item.discountValue,
            startDate: new Date( item.startDate ).toISOString(),
            lastNight: new Date( dateStart.add( -1, 'days' ) ).toISOString(),
            type: item.type,
            requirements: item.requirements,
          },
        ],
      } );
    }
    if ( dateStart.diff( moment( item.startDate ) ) < 0
      && dateEnd.diff( moment( item.startDate ) ) > 0 ) {
      await updateSeasonDiscount( {
        id: item.id,
        discountType: item.discountType,
        discountValue: item.discountValue,
        startDate: new Date( item.startDate ).toISOString(),
        lastNight: new Date( dateStart.add( -1, 'days' ) ).toISOString(),
        type: item.type,
        requirements: item.requirements,
      } );
    }
    if ( dateStart.diff( moment( item.lastNight ) ) < 0
      && dateEnd.diff( moment( item.lastNight ) ) > 0 ) {
      await updateSeasonDiscount( {
        id: item.id,
        discountType: item.discountType,
        discountValue: item.discountValue,
        startDate: new Date( item.startDate ).toISOString(),
        lastNight: new Date( dateStart.add( -1, 'days' ) ).toISOString(),
        type: item.type,
        requirements: item.requirements,
      } );
    }
  } ) );

  const { ok, message } = await createSeasonDiscount( dataToSend );
  if ( !ok ) {
    throw new Error( message );
  }
  return {
    ok, message,
  };
};

export { createWeekSeasonDiscount };
