import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import filterBuilder, { sortByBuilder } from './filterBuilder';
import getPropertyManager from '../utils/getPropertyManager';

const GET_PIPELINES = gql`
query GetPipelines($filters: PipelineFiltersInput!, $orderBy: [PipelineOrderByInput!]!, $limit: Int!, $propertyManager: String!, $page: Int!) {
  Pipelines(limit: $limit, page: $page, propertyManager: $propertyManager, filters: $filters, orderBy: $orderBy) {
    ... on BaseError {
      message
    }
    ... on QueryPipelinesSuccess {
      data {
        total
        elementsPerPage
        data {
          id
          isActive
          name
          stages
          updatedAt
          description
          createdAt
        }
      }
    }
  }
}
`;

const getPipelines = async ( { page, elementsPerPage, ...rest } ) => {
  const filters = filterBuilder( rest, ['name', 'isActive'] );
  const orderBy = sortByBuilder( rest );

  const { data } = await client.query( {
    query: GET_PIPELINES,
    variables: {
      page: page || 1, // pageCurrent,
      limit: elementsPerPage || 10,
      filters,
      orderBy,
      propertyManager: getPropertyManager(),
    },
    fetchPolicy: 'no-cache',
  } );

  const pipelines = data.Pipelines.data.data || [];
  const elementsTotal = data.Pipelines.data.total || 0;

  if ( data.Pipelines.message ) {
    return {
      ok: false,
      message: data.Pipelines.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: { data: pipelines, elementsTotal, elementsPerPage },
  };
};

export default getPipelines;
