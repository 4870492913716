import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { filterBuilder } from './filterBuilder';

const GET_ALL_RATES = gql`
  query Rates($limit: Int!, $offset: Int!, $filters: [FilterInput!]!) {
  rates(limit: $limit, offset: $offset, filters: $filters) {
    ... on BaseError {
      message
    }
    ... on QueryRatesSuccess {
      data {
        total
        results {
          accommodationsNumber
          id
          isActive
          maximumPriceSA
          maximumPriceSuplement
          minimalPriceSA
          minimalPriceSuplement
          name
          propertyManagerId
          updatedAt
          validUntil
        }
      }
    }
  }
}
`;

const getAllRates = async ( { page, elementsPerPage, ...rest } ) => {
  const offset = ( page - 1 ) * elementsPerPage;
  const limit = elementsPerPage;
  const { data } = await client.query( {
    query: GET_ALL_RATES,
    variables: {
      offset,
      limit,
      filters: filterBuilder( rest ),
    },
    fetchPolicy: 'no-cache',
  } );
  const rates = data.rates.data.results || [];
  const elementsTotal = data.rates.data.total || 0;
  if ( data.rates.message ) {
    return {
      ok: false,
      message: data.rates.message,
    };
  }
  return {
    ok: true,
    status: 200,
    data: { data: rates, elementsTotal, elementsPerPage },
  };
};

export default getAllRates;
