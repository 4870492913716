import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/property-managers';

export default {
  getEmailSettings( propertyManagerId ) {
    return api.get( `${baseEndpoint}/${propertyManagerId}/email-settings` );
  },
  updateEmailSettings( propertyManagerId, data ) {
    return api.put( `${baseEndpoint}/${propertyManagerId}/email-settings`, data );
  },
  updateLogoEmailSettings( propertyManagerId, data ) {
    return api.post( `${baseEndpoint}/${propertyManagerId}/email-settings/logo`, formatFormDataBody( data ) );
  },
};
