import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { canView } from 'config/Ability';
import { Container, Row, Col } from 'reactstrap';
import {
  SimpleHeader, AsyncTable, DaterangePickerBtn, Intl,
} from 'components';
import formatBookinglId from 'utils/bookingId';
import _get from 'lodash/get';

const RESPONSE_OK = 'OK';
const STATUS_OK = '200';

const renderResponse = ( item ) => {
  if ( _get( item, 'response' ) === RESPONSE_OK && _get( item, 'status' ) === STATUS_OK ) {
    return (
      <span className="badge badge-success badge-pill">
        <Intl id="sended" />
      </span>
    );
  }
  return (
    <span className="badge badge-danger badge-pill">
      <Intl id="errorSended" />
    </span>
  );
};

export default React.memo( ( {
  data, isLoading, defaultFilters, onFetchData, userBookingReference,
} ) => (
  <>
    <SimpleHeader
      title="registerOfAuthorities"
    />
    <Container fluid>
      <AsyncTable
        id="bookedServices"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        onFetchData={onFetchData}
        customFilters={( { filters, setMultipleFilters } ) => (
          <Row>
            <Col md={8} lg={4}>
              <DaterangePickerBtn
                position="left"
                label="date"
                initialValue={moment.range( filters.from || moment(), filters.till || moment().add( 1, 'month' ) )}
                key={filters.from + filters.till}
                input
                onChange={( values ) => {
                  setMultipleFilters( {
                    from: moment( values.start ).format( 'YYYY-MM-DD' ),
                    till: moment( values.end ).format( 'YYYY-MM-DD' ),
                  } );
                }}
              />
            </Col>
          </Row>
        )}
        columns={[
          {
            Header: 'booking',
            accessor: 'bookedAccommodation_id.id',
            Cell: ( { cell } ) => (
              canView( 'booking' )
                ? (
                  <Link to={`/bookings/list/${cell.value}`} className="text-uppercase">
                    {formatBookinglId( {
                      ...cell.row.original.bookedAccommodation_id,
                      userBookingReference,
                    } )}
                  </Link>
                ) : (
                  <span>
                    {formatBookinglId( {
                      ...cell.row.original.bookedAccommodation_id,
                      userBookingReference,
                    } )}
                  </span>
                )
            ),
          },
          {
            Header: 'from',
            accessor: 'bookedAccommodation_id.from',
            Cell: ( { cell } ) => ( moment( cell.value ).format( 'YYYY-MM-DD' ) ),
          },
          {
            Header: 'till',
            accessor: 'bookedAccommodation_id.till',
            Cell: ( { cell } ) => ( moment( cell.value ).format( 'YYYY-MM-DD' ) ),
          },
          {
            Header: 'type',
            accessor: 'police_type',
          },
          {
            Header: 'state',
            accessor: 'body',
            Cell: ( { cell } ) => renderResponse( cell.row.original ),
          },
          {
            Header: 'guests',
            accessor: 'bookedAccommodation_id.guests.length',
          },
        ]}
      />
    </Container>
  </>
) );
