import api from 'config/Api/Api';

const baseEndpoint = '/deposits';

export default {
  getDeposits( params ) {
    return api.get( baseEndpoint, params );
  },
  getDeposit( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  saveDeposit( params ) {
    return api.post( baseEndpoint, params );
  },
  updateDeposit( id, params ) {
    return api.put( `${baseEndpoint}/${id}`, params );
  },
  deleteDeposit( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
