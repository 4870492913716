import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import View from 'views/PropertyManager/Edit/Settings';
import SettingsActions from 'store/reducers/Settings';
import UserActions from 'store/reducers/User';
import { useFetch, useSubmit } from 'hooks';
import PropertyManagerService from 'api/PropertyManager';

const Dashboard = ( {
  toggleErrorAlert,
  propertyManager,
  toggleLoading,
  toggleInfoAlert,
  updateSettings,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const propertyManagerId = _.get( propertyManager, 'id' );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => PropertyManagerService.getPropertyManager( propertyManagerId ),
    toggleErrorAlert,
    conditional: !!propertyManagerId,
    deps: [propertyManagerId, dataSubmitted],
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => PropertyManagerService.updatePropertyManager(
      propertyManagerId, dataToSend,
    ),
    toggleLoading,
    toggleInfoAlert,
    deps: [propertyManagerId],
    callback: ( dataSaved ) => {
      updateSettings( {
        bookingReference: _.get( dataSaved, 'bookingReference', 'localizator' ),
      } );
      reloadData();
    },
  } );

  return (
    <View
      onSubmit={submitForm}
      data={data}
      isLoading={dataLoading}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  updateSettings: UserActions.updateSettings,

};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
