import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import AccomodationOwnerService from 'api/AccomodationOwner';
import AccomodationsClientEditView from 'views/AccomodationOwner/Edit/accomodations';

const AccomodationsClientEdit = ( { match, toggleErrorAlert } ) => {
  const clientId = match.params.id;

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: () => AccomodationOwnerService.getAccomodationOwner( clientId ),
    toggleErrorAlert,
  } );

  useEffect( () => {
    fetchData();
  }, [fetchData] );

  return (
    <AccomodationsClientEditView
      data={data?.accommodations || []}
      isLoading={dataLoading}
      onFetchData={fetchData}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( AccomodationsClientEdit );
