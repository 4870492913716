import TaskService from 'api/Task';
import IncidenceService from 'api/incidences';

const parseIncidences = ( incidences ) => incidences.map(
  ( item ) => ( { ...item, type: 'issue' } ),
);

const loadTasks = async ( params ) => {
  const { data: loadedTasks } = await TaskService.getTasks( params );
  const { data: loadedIncidences } = await IncidenceService.getIncidences( params );
  return {
    ok: true,
    data: {
      data: [...loadedTasks.data, ...parseIncidences( loadedIncidences.data )].sort(
        ( a, b ) => b.date - a.date,
      ),
      elementsInPage: loadedTasks.elementsInPage + loadedIncidences.elementsInPage,
      elementsTotal: loadedTasks.elementsTotal + loadedIncidences.elementsTotal,
    },
    status: 200,
  };
};

const loadTasksForDownload = async ( params ) => {
  const { data: loadedTasks } = await TaskService.getTasks( params );
  // const { data: loadedIncidences } = await IncidenceService.getIncidences( params );
  return {
    ok: true,
    data: [...loadedTasks].sort( ( a, b ) => b.date - a.date ),
    status: 200,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { loadTasks, loadTasksForDownload };
