/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import {
  Navbar,
  Nav,
} from 'reactstrap';
import { Intl } from 'components';

import logo from 'assets/img/brand/logo-threeangular-white.svg';

const AuthNavbar = React.memo( () => (
  <Navbar className="navbar-top main-navbar navbar-expand fixed-top navbar-horizontal auth-navbar">
    <a className="navbar-brand mr-0" href="/">
      <img alt="ThreeAngular" src={logo} style={{ height: 26 }} />
    </a>

    <Nav navbar className="mr-4">
      <a className="text-bold" href="mailto:support@nomadssoftware.com" target="_blank" rel="noopener noreferrer">
        <Intl id="support" />
      </a>
    </Nav>
  </Navbar>
) );

export default AuthNavbar;
