import Settings from 'containers/Resource/Edit/Settings';
import Shift from 'containers/Resource/Edit/Shift';
import Tasks from 'containers/Resource/Edit/Task';
import Contract from 'containers/Resource/Edit/Contract';
import Payroll from 'containers/Resource/Edit/Payroll';
import Permits from 'containers/Resource/Edit/Permits';
import Finance from 'containers/Resource/Edit/Finance';

export default [
  {
    name: 'settings',
    path: '',
    component: Settings,
    exact: true,
  },
  {
    name: 'contract',
    path: '/contract',
    component: Contract,
  },
  {
    name: 'payroll',
    path: '/payroll',
    component: Payroll,
  },
  {
    name: 'shifts',
    path: '/shifts',
    component: Shift,
  },
  {
    name: 'tasks',
    path: '/tasks',
    component: Tasks,
  },
  {
    name: 'permits',
    path: '/permits',
    component: Permits,
  },
  {
    name: 'finance',
    path: '/finance',
    component: Finance,
  },
];
