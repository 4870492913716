import React, { useState, useRef, useCallback } from 'react';
import DashboardShifView from 'views/DashboardShift';
import moment from 'moment';
import useFetch from 'hooks/useFetch';
import DMShiftScheduleService from 'api/DMShiftSchedule';
import DMShiftScheduleReportService from 'api/DMShiftScheduleReport';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import _get from 'lodash/get';
import TeamService from 'api/Team';
import EmpployeeService from 'api/Employee';
import useDelete from 'hooks/useDelete';
import ShiftService from 'api/Shift';
import EditShift from '../Shift/Edit';
import NewBulkShift from '../Shift/BulkEdit';
import { formatDataShifts, formatDataReports } from './utils/format';
import MultiShift from './MultiShift';

const DashboardShift = ( { toggleErrorAlert, toggleLoading } ) => {
  const dateRange = useRef( moment.range(
    moment().startOf( 'day' ),
    moment().startOf( 'day' ).add( 1, 'months' ),
  ) );
  const params = useRef( {} );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const reloadData = useCallback( () => setDataSubmitted( new Date().valueOf() ), [] );

  const [showReport, setShowReport] = useState( false );
  const toggleReport = () => setShowReport( ( state ) => !state );

  const [showMultiShift, setShowMultiShift] = useState( false );
  const [multiShift, setMultiShift] = useState( [] );

  const handleOpenMultiShif = ( value ) => {
    setShowMultiShift( true );
    setMultiShift( value );
  };

  const handleCloseMultiShif = ( ) => {
    setShowMultiShift( false );
    setMultiShift( [] );
  };

  const [data, dataLoading] = useFetch( {
    initialState: [],
    promise: () => DMShiftScheduleService.getDmShiftSchedules( {
      elementsPerPage: 3000,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
      ...params.current,
    } ),
    format: ( dataToFormat ) => formatDataShifts( {
      employees: _get( dataToFormat, 'employees.data', [] ),
      shifts: _get( dataToFormat, 'shifts.data', [] ),
    }, dateRange.current ),
    toggleErrorAlert,
    deps: [dataSubmitted],
    reInit: true,
  } );

  const [dataReports, dataReportsLoading] = useFetch( {
    initialState: [],
    promise: () => DMShiftScheduleReportService.getDmShiftScheduleReport( {
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
      elementsPerPage: 3000,
    } ),
    toggleErrorAlert,
    format: ( dataToFormat ) => formatDataReports( _get( dataToFormat, 'data', [] ), dateRange.current ),
    deps: [dataSubmitted],
    reInit: true,
  } );

  const [modalNewShiftOpened, setModalNewShiftOpened] = useState( false );
  const toggleNewShiftModal = () => setModalNewShiftOpened( ( state ) => !state );
  const [preloadedData, setPreloadedData] = useState( {} );

  const [modalNewBulkShiftOpened, setModalNewBulkShiftOpened] = useState( false );
  const toggleNewBulkShiftModal = () => setModalNewBulkShiftOpened( ( state ) => !state );

  const openModalEditShift = ( values ) => {
    const {
      dateInt, shiftId, assigned,
    } = values;

    setPreloadedData( {
      assigned,
      date: moment( dateInt, 'YYYYMMDD' ),
      shiftId,
    } );
    toggleNewShiftModal();
  };

  const openNewBulkShift = ( ) => {
    toggleNewBulkShiftModal();
  };

  const openNewShift = () => {
    setPreloadedData( { } );
    toggleNewShiftModal();
  };

  const updateDateRangeParams = useCallback( ( date ) => {
    dateRange.current = date;
    reloadData();
  }, [reloadData] );

  const selectToday = useCallback( () => {
    dateRange.current = moment.range(
      moment().startOf( 'day' ),
      moment().startOf( 'day' ).add( 1, 'day' ),
    );
    reloadData();
  }, [reloadData] );

  const selectThisWeek = useCallback( () => {
    dateRange.current = moment.range(
      moment().startOf( 'week' ),
      moment().startOf( 'week' ).add( 1, 'week' ),
    );
    reloadData();
  }, [reloadData] );

  const selectThisMonth = useCallback( () => {
    dateRange.current = moment.range(
      moment().startOf( 'month' ),
      moment().startOf( 'month' ).add( 1, 'month' ),
    );
    reloadData();
  }, [reloadData] );

  const fetchTeams = useCallback( ( query ) => TeamService.getTeams( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const fetchEmployees = useCallback(
    ( query ) => EmpployeeService.getEmployees( {
      elementsPerPage: 10,
      keyword: query,
    } ), [],
  );

  const setFilters = useCallback( ( filter, value ) => {
    params.current = { ...params.current, [filter]: value };
    reloadData();
  }, [reloadData] );

  const deleteShift = useDelete( {
    promise: ( { shiftId } ) => ShiftService.deleteShift(
      shiftId,
    ),
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const onReloadEditShift = useCallback( () => {
    toggleNewShiftModal();
    reloadData();
  }, [reloadData] );

  const onReloadBulkShift = useCallback( () => {
    toggleNewBulkShiftModal();
    reloadData();
  }, [reloadData] );

  const handleOnPressShift = ( values ) => {
    setShowMultiShift( false );
    setMultiShift( [] );
    openModalEditShift( values );
  };

  const onReloadMultiShift = () => {
    setShowMultiShift( false );
    reloadData();
  };

  return (
    <>
      <DashboardShifView
        dateRange={dateRange.current}
        data={data}
        dataReport={dataReports}
        isLoadingDataReports={dataReportsLoading}
        isLoading={dataLoading}
        showReport={showReport}
        onClickShowReport={toggleReport}
        onClickCell={openModalEditShift}
        onChangeRange={updateDateRangeParams}
        onSelectToday={selectToday}
        onSelectThisWeek={selectThisWeek}
        onSelectThisMonth={selectThisMonth}
        onFetchTeams={fetchTeams}
        onFetchEmployees={fetchEmployees}
        onChangeFilters={setFilters}
        onPressDelete={deleteShift}
        onPressMultiShift={handleOpenMultiShif}
        onAddBulkShift={openNewBulkShift}
        onAddShift={openNewShift}
      />
      <EditShift
        modalOpened={modalNewShiftOpened}
        onCloseModal={toggleNewShiftModal}
        shiftId={preloadedData.shiftId}
        preloadedData={preloadedData}
        onReload={onReloadEditShift}
      />
      <NewBulkShift
        modalOpened={modalNewBulkShiftOpened}
        onCloseModal={toggleNewBulkShiftModal}
        onReload={onReloadBulkShift}
      />
      <MultiShift
        modalOpened={showMultiShift}
        onCloseModal={handleCloseMultiShif}
        shifts={multiShift}
        onPressShift={handleOnPressShift}
        reloadData={onReloadMultiShift}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( DashboardShift );
