import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ResourceEditView from 'views/Resource/Edit/Settings';
import SettingsActions from 'store/reducers/Settings';
import EmployeeService from 'api/Employee';
import RoleService from 'api/Role';
import CustomerService from 'api/Customer';
import PropertyManagerService from 'api/PropertyManager';
import { roles } from 'config/Constants';
import {
  formatCustomValuesToSend,
  formatDataPhoneToSend, formatDataToSend, handlePromise,
} from 'utils';
import useDelete from 'hooks/useDelete';
import { ConfirmationModal } from 'components';

const ResourceEdit = ( {
  data, entityId, isLoading,
  parentPath, history, onReloadData, userRole,
  toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [pmOptions, setPmOptions] = useState( [] );
  const [roleOptions, setRoleOptions] = useState( [] );

  const [openModalDisable, setOpenModalDisable] = useState( false );
  const toggleModalDisable = () => setOpenModalDisable( ( state ) => !state );

  const [openModalDelete, setOpenModalDelete] = useState( false );
  const toggleModalDelete = () => setOpenModalDelete( ( state ) => !state );

  useEffect( () => {
    const getOptions = async () => {
      const [errors, response, responseData] = await handlePromise(
        PropertyManagerService.getPropertyManagers(),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setPmOptions( responseData.data );
    };
    if ( !entityId && userRole === roles.nomadsAdmin.id ) getOptions();
  }, [entityId, userRole, toggleErrorAlert] );

  useEffect( () => {
    const getRoles = async () => {
      const [errors, response, responseData] = await handlePromise(
        RoleService.getRoles( {
          elementsPerPage: 1000,
        } ),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setRoleOptions( responseData.data );
    };
    if ( userRole === roles.nomadsAdmin.id || userRole === roles.admin.id ) getRoles();
  }, [entityId, userRole, toggleErrorAlert] );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    let dataToSend = formatDataToSend( formData, form );
    delete dataToSend.teams;
    dataToSend = formatDataPhoneToSend( dataToSend, ['phoneNumber'] );
    dataToSend = formatCustomValuesToSend( dataToSend, data.customFields );
    if ( entityId ) {
      delete dataToSend.email;
      dataToSend.roles = [dataToSend.roles, 'ROLE_EMPLOYEE', 'ROLE_USER'];
    } else {
      dataToSend.email = dataToSend.email.toLowerCase();
      dataToSend.roles = [dataToSend.roles];
      dataToSend.employee = 'true';
    }
    delete dataToSend.profilePicture;
    const [errors, response] = await handlePromise( entityId
      ? EmployeeService.updateEmployee( entityId, dataToSend )
      : EmployeeService.saveEmployee( dataToSend ) );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( !entityId || !formData.profilePicture || _.isString( formData.profilePicture ) ) {
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      return onReloadData( );
    }

    const imageToSend = {
      picture: formData.profilePicture ? formData.profilePicture.file : null,
    };
    const [imageErrors, imageResponse] = await handlePromise(
      CustomerService.uploadProfilePicture( entityId, imageToSend ),
    );
    toggleLoading( false );
    if ( !imageResponse.ok ) return imageErrors;

    toggleInfoAlert( 'dataSaved' );
    onReloadData( );
  }, [entityId, data.customFields, onReloadData, toggleInfoAlert, toggleLoading] );

  const deleteResource = useDelete( {
    promise: () => EmployeeService.deleteEmployee( entityId ),
    callback: ( id, response ) => {
      if ( response.data.deleted ) {
        history.push( parentPath );
      } else {
        toggleModalDisable();
      }
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const disableResource = useDelete( {
    promise: () => EmployeeService.disableEmployee( entityId ),
    callback: () => history.push( parentPath ),
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <ResourceEditView
        data={data}
        options={{ pms: pmOptions, roles: roleOptions }}
        isNomadsAdmin={userRole === roles.nomadsAdmin.id}
        parentPath={parentPath}
        isLoading={isLoading}
        entityId={entityId}
        onSubmit={submitForm}
        onDelete={toggleModalDelete}
      />
      <ConfirmationModal
        open={openModalDelete}
        title={openModalDisable ? 'disableModal.confirmation' : ''}
        onConfirm={openModalDisable ? disableResource : deleteResource}
        onClose={toggleModalDelete}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ResourceEdit );
