import React from 'react';
import { Route, Switch } from 'react-router';

import { canModify, canView, canList } from 'config/Ability';
import Dashboard from './Dashboard';
import Detail from './Detail';
import Edit from './Edit';

const Event = ( { match } ) => (
  <Switch>
    {canModify( 'event' ) && (
      <Route
        path={`${match.url}/new`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
      />
    )}
    {canModify( 'event' ) && (
      <Route
        path={`${match.url}/edit/:id`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
      />
    )}
    {canView( 'event' ) && (
      <Route
        path={`${match.url}/:id`}
        render={( props ) => <Detail {...props} parentPath={match.url} />}
      />
    )}
    {canList( 'event' ) && (
      <Route
        path={match.url}
        render={( props ) => <Dashboard {...props} parentPath={match.url} />}
      />
    )}
  </Switch>
);

export default Event;
