import React from 'react';
import _ from 'lodash';

import { getInitials, getResourceName } from 'utils';
import UserAvatar from '../UserAvatar';

export default React.memo( ( {
  data, style, noName, ...rest
} ) => (
  data
    ? (
      <UserAvatar
        {...rest}
        avatar={_.get( data, 'profilePicture' )}
        initials={getInitials( data )}
        name={noName ? '' : getResourceName( data )}
        style={style}
      />
    ) : null
) );
