import React, {
  useCallback, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import ShiftDashboardView from 'views/Shift/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import ShiftService from 'api/Shift';
import { useDelete, useFetchTableData } from 'hooks';
import TeamService from 'api/Team';
import EmployeeService from 'api/Employee';
import ShitEditView from '../Edit';
import ShitBulkEditView from '../BulkEdit';

const ShiftDashboard = ( {
  parentPath, defaultFilters, updateFilters, toggleErrorAlert, toggleLoading, setFiltersError,
} ) => {
  const filterFromDate = defaultFilters.from || moment().startOf( 'day' );
  const filterToDate = defaultFilters.till || moment( filterFromDate ).startOf( 'day' ).clone().add( '1', 'month' );
  const dateRange = useRef( moment.range( filterFromDate, filterToDate ) );
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [modalBulkOpened, setModalBulkOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => ShiftService.getShifts( {
      ...params,
      teams: params.teams ? _.map( params.teams, 'value' ) : undefined,
      managers: params.managers ? _.map( params.managers, 'value' ) : undefined,
      from: dateRange.current.start ? dateRange.current.start.format( 'YYYY-MM-DD' ) : undefined,
      till: dateRange.current.end ? dateRange.current.end.format( 'YYYY-MM-DD' ) : undefined,
    } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters(
        'shifts', {
          teams: params.teams ? _.map( params.teams,
            ( item ) => ( { value: item.value, label: item.label } ) ) : [],
          managers: params.managers ? _.map( params.managers,
            ( item ) => ( { value: item.value, label: item.label } ) ) : [],
          from: dateRange.current.start ? dateRange.current.start.format( 'YYYY-MM-DD' ) : undefined,
          till: dateRange.current.end ? dateRange.current.end.format( 'YYYY-MM-DD' ) : undefined,
        },
      );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        dateRange.current = moment.range( moment().startOf( 'day' ), moment().startOf( 'day' ).add( '1', 'month' ) );
        setFiltersError( 'shifts' );
        initialReloadError.current = false;
      }
    },
  } );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const openBulkEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalBulkOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [setModalOpened] );

  const closeBulkModal = useCallback( () => {
    setModalBulkOpened( false );
  }, [setModalBulkOpened] );

  const reloadData = useCallback( () => {
    closeEditModal();
    closeBulkModal();
    setDataSubmitted( new Date().valueOf() );
  }, [closeEditModal, closeBulkModal] );

  const deleteShift = useDelete( {
    promise: ShiftService.deleteShift,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const fetchTeams = useCallback( ( query ) => TeamService.getTeams( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const fetchEmployees = useCallback( ( query ) => EmployeeService.getEmployees( {
    elementsPerPage: 10,
    keyword: query,
    employee: true,
  } ), [] );

  const updateDateRangeParams = useCallback( ( params ) => {
    dateRange.current = params;
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  return (
    <>
      <ShiftDashboardView
        data={data}
        isLoading={dataLoading}
        parentPath={parentPath}
        initialDates={dateRange.current}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onOpenBulkEditModal={openBulkEditModal}
        onDelete={deleteShift}
        onFetchTeams={fetchTeams}
        onFetchEmployees={fetchEmployees}
        onDateChanged={updateDateRangeParams}
      />
      <ShitEditView
        shiftId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
      <ShitBulkEditView
        shiftId={toEditId.current}
        modalOpened={modalBulkOpened}
        onCloseModal={closeBulkModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.shifts' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ShiftDashboard );
