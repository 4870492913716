import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { ConfirmationModal } from 'components';
import SettingsActions from 'store/reducers/Settings';
import TaskActions from 'store/reducers/Task';
import IncidenceActions from 'store/reducers/Incidence';
import FiltersActions from 'store/reducers/Filters';
import TaskService from 'api/Task';
import TaskTypeService from 'api/TaskType';
import PropertyService from 'api/Property';
import EmployeeService from 'api/Employee';
import TeamService from 'api/Team';
import TagsPredefinedService from 'api/TagsPredefined';
import { useDelete, useFetchTableData, useFetch } from 'hooks';
import AccommodationService from 'api/Accommodation';
import CityService from 'api/Settings/City';
import { handlePromise } from 'utils';
import { history } from 'store';
import ContactTasksView from 'views/CRM/Contact/Edit/Tasks';
import TaskBulkEdit from 'containers/Task/BulkEdit';
import { loadTasksForDownload } from 'containers/Task/Dashboard/utils';
import { ContactEditContext } from '../context';

const ContactTasks = ( {
  taskReloadedAt,
  // defaultFilters,
  toggleTaskEditModal,
  toggleLoading,
  toggleErrorAlert,
  updateFilters,
  setFiltersError,
  searchParams,
  propertyManager,
  toggleIncidenceModal,
  incidenceReloadedAt,
  userRole,
} ) => {
  const { contactId, data: contactCurrentData } = useContext( ContactEditContext );
  const { getTasks } = TaskService;
  const isOwner = userRole === 'ROLE_OWNER';
  const urlSearchParams = new URLSearchParams( searchParams );

  const filterFromDate = urlSearchParams.get( 'from' ) || moment().startOf( 'day' );
  const filterToDate = urlSearchParams.get( 'till' )
    || moment( filterFromDate ).clone().add( '1', 'day' );
  const dateRange = useRef( moment.range( filterFromDate, filterToDate ) );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [bulkEditModalOpened, setBulkEditModalOpened] = useState( false );
  const [bulkDeleteModalOpened, setBulkDeleteModalOpened] = useState( false );
  const [dataToExport, setDataToExport] = useState( [] );
  const showLoading = useRef( true );
  const isInitialMount = useRef( true );
  const initialReloadError = useRef( true );
  const selectedRows = useRef( [] );
  const [selectedRowsChange, setSelectedRowsChange] = useState(
    new Date().valueOf(),
  );
  const [bulkErrors, setBulkErrors] = useState( [] );
  const csvLinkRef = useRef();

  const urlFilters = () => {
    const filters = {
      keyword: urlSearchParams.get( 'keyword' ),
      priority: urlSearchParams.get( 'priority' ),
      status: urlSearchParams.get( 'status' ),
      type: urlSearchParams.get( 'type' ),
      taskType: urlSearchParams.get( 'taskType' ),
      property:
        urlSearchParams.get( 'property_value' )
        && urlSearchParams.get( 'property_label' )
          ? {
            value: urlSearchParams.get( 'property_value' ),
            label: urlSearchParams.get( 'property_label' ),
          }
          : null,
      accommodation:
        urlSearchParams.get( 'accommodation_value' )
        && urlSearchParams.get( 'accommodation_label' )
          ? {
            value: urlSearchParams.get( 'accommodation_value' ),
            label: urlSearchParams.get( 'accommodation_label' ),
          }
          : null,
      assigned:
        urlSearchParams.get( 'assigned_value' )
        && urlSearchParams.get( 'assigned_label' )
          ? {
            value: urlSearchParams.get( 'assigned_value' ),
            label: urlSearchParams.get( 'assigned_label' ),
          }
          : null,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
      zone: urlSearchParams.get( 'zoneIds' ),
      tags: urlSearchParams.get( 'tags' ),
    };
    return filters;
  };

  const [filtersValues, setFiltersValues] = useState( urlFilters() );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getTasks( {
      ...params,
      contactId,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
      taskType: _.get( params, 'taskType' ),
      tags: _.get( params, 'tags' ),
      property: _.get( params, 'property.value' )
        ? params.property.value
        : undefined,
      accommodation: _.get( params, 'accommodation.value' )
        ? params.accommodation.value
        : undefined,
      assigned: _.get( params, 'assigned.value' )
        ? params.assigned.value
        : undefined,
      team: _.get( params, 'team.value' )
        ? params.team.value
        : undefined,
    } ),
    callback: ( params ) => {
      initialReloadError.current = false;
      showLoading.current = true;
      updateFilters( 'tasks', {
        keyword: params.keyword,
        priority: params.priority,
        status: params.status,
        type: params.type,
        taskType: params.taskType,
        // tags: params.tags,
        property: params.property
          ? { value: params.property.value, label: params.property.label }
          : null,
        accommodation: params.accommodation
          ? {
            value: params.accommodation.value,
            label: params.accommodation.label,
          }
          : null,
        assigned: params.assigned
          ? { value: params.assigned.value, label: params.assigned.label }
          : null,
        from: dateRange.current.start
          ? dateRange.current.start.format( 'YYYY-MM-DD' )
          : undefined,
        till: dateRange.current.end
          ? dateRange.current.end.format( 'YYYY-MM-DD' )
          : undefined,
        tags: params.tags,
      } );

      const search = new URLSearchParams(
        _.omitBy(
          {
            ...params,
            from: dateRange.current.start
              ? dateRange.current.start.format( 'YYYY-MM-DD' )
              : undefined,
            till: dateRange.current.end
              ? dateRange.current.end.format( 'YYYY-MM-DD' )
              : undefined,
            taskType: _.get( params, 'taskType' ),
            property_value: _.get( params, 'property.value' ),
            property_label: _.get( params, 'property.label' ),
            property: null,
            accommodation_value: _.get( params, 'accommodation.value' ),
            accommodation_label: _.get( params, 'accommodation.label' ),
            accommodation: null,
            assigned_value: _.get( params, 'assigned.value' ),
            assigned_label: _.get( params, 'assigned.label' ),
            assigned: null,
          },
          _.isNil,
        ),
      ).toString();
      if ( search ) {
        history.replace( { search: `?${search}` } );
      }
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        dateRange.current = moment.range(
          moment().startOf( 'day' ),
          moment().startOf( 'day' ).add( '1', 'day' ),
        );
        setFiltersError( 'tasks' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
    deps: [dataSubmitted, contactId],
  } );

  const onDownloadData = useCallback( async () => {
    toggleLoading( true );
    const [errors, response, responseData] = await handlePromise(
      loadTasksForDownload( {
        page: -1,
        keyword: urlSearchParams.get( 'keyword' ),
        priority: urlSearchParams.get( 'priority' ),
        status: urlSearchParams.get( 'status' ),
        type: urlSearchParams.get( 'type' ),
        taskType: urlSearchParams.get( 'taskType' ),
        property: urlSearchParams.get( 'property_value' ),
        accommodation: urlSearchParams.get( 'accommodation_value' ),
        assigned: urlSearchParams.get( 'assigned_value' ),
        from: urlSearchParams.get( 'from' ),
        till: urlSearchParams.get( 'till' ),
      } ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );

    setDataToExport( responseData );
    setTimeout( () => {
      csvLinkRef.current.link.click();
    }, 200 );
  }, [urlSearchParams, toggleLoading, toggleErrorAlert] );

  useEffect( () => {
    const dataIds = _.map( data.data, 'id' );
    const selectedIds = _.filter(
      selectedRows.current,
      ( id ) => dataIds.indexOf( id ) !== -1,
    );
    if ( selectedIds.length !== selectedRows.current.length ) {
      selectedRows.current = selectedIds;
      setSelectedRowsChange( new Date().valueOf() );
    }
  }, [data] );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const openEditModal = useCallback(
    ( { id } = {} ) => {
      const contactData = {
        contactId,
        contactName: contactCurrentData?.name || '',
      };
      toggleTaskEditModal( id, { contactData } );
    },
    [toggleTaskEditModal, contactId, contactCurrentData],
  );

  const openIncidenceEditModal = useCallback(
    ( { id } = {} ) => {
      toggleIncidenceModal( id );
    },
    [toggleIncidenceModal],
  );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      showLoading.current = false;
      setDataSubmitted( new Date().valueOf() );
    }
  }, [taskReloadedAt, incidenceReloadedAt] );

  const updateDateRangeParams = useCallback( ( params ) => {
    dateRange.current = params;
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const getTodayTasks = useCallback( () => {
    dateRange.current = moment.range(
      moment().startOf( 'day' ),
      moment().startOf( 'day' ).add( '1', 'day' ),
    );
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deleteTask = useDelete( {
    promise: TaskService.deleteTask,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const fetchEmployees = useCallback(
    ( query ) => EmployeeService.getEmployees( {
      elementsPerPage: 10,
      keyword: query,
      employee: true,
    } ),
    [],
  );
  const fetchTeams = useCallback(
    ( ) => TeamService.getTeams( {
      elementsPerPage: 999,
    } ),
    [],
  );

  const fetchTagsPredefined = useCallback( ( ) => TagsPredefinedService.getTagsPredefined(), [] );

  const [CitiesOptions] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.city.id,
        name: item.city.name,
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const [outerCitiesOptions] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        city: item.city.id,
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const [ZonesOptions] = useFetch( {
    initialState: [],
    promise: () => CityService.getZones( outerCitiesOptions.find(
      ( c ) => c.city === urlSearchParams.get( 'city' ),
    ).id, {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        name: item.name,
      } ) );
      return dataFormated;
    },
    conditional: Boolean( urlSearchParams.get( 'city' ) ),
    toggleErrorAlert,
    deps: [urlSearchParams.get( 'city' )],
  } );

  const openBulkDeleteModal = useCallback( () => {
    setBulkDeleteModalOpened( true );
  }, [] );

  const closeBulkDeleteModal = useCallback( () => {
    setBulkDeleteModalOpened( false );
    selectedRows.current = [];
    setSelectedRowsChange( new Date().valueOf() );
  }, [] );

  const openBulkEditModal = useCallback( () => {
    setBulkEditModalOpened( true );
  }, [] );

  const closeBulkEditModal = useCallback( () => {
    setBulkEditModalOpened( false );
    selectedRows.current = [];
    setSelectedRowsChange( new Date().valueOf() );
  }, [] );

  const toggleRowChecked = useCallback( ( id, value ) => {
    if ( value ) selectedRows.current.push( id );
    else {
      const index = selectedRows.current.indexOf( id );
      if ( index !== -1 ) selectedRows.current.splice( index, 1 );
    }
    setSelectedRowsChange( new Date().valueOf() );
  }, [] );

  const toggleAllRowsChecked = useCallback( () => {
    if ( data.data && selectedRows.current.length === data.data.length ) {
      selectedRows.current = [];
    } else {
      selectedRows.current = _.map( data.data, 'id' );
    }
    setSelectedRowsChange( new Date().valueOf() );
  }, [data] );

  const reloadBulkData = useCallback(
    ( errors ) => {
      setBulkErrors( errors );
      if ( bulkEditModalOpened ) closeBulkEditModal();
      setDataSubmitted( new Date().valueOf() );
    },
    [bulkEditModalOpened, closeBulkEditModal],
  );

  const disabledBulkEdit = useMemo( () => {
    if ( !selectedRows.current.length ) {
      return true;
    }

    const { status, taskType, zone } = filtersValues;

    if ( status && status === 'open' && taskType && zone ) {
      return false;
    }

    return true;
    // eslint-disable-next-line
  }, [selectedRows.current.length, filtersValues] );

  /** ****************************************** */
  const deleteTasks = useCallback(
    async ( ) => {
      toggleLoading( true );

      const tasks = selectedRows.current;

      // eslint-disable-next-line no-unused-vars
      const [, response] = await handlePromise(
        TaskService.deleteBulkTasks( { tasks } ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleErrorAlert( 'apiError' );
      }

      toggleLoading( false );
      closeBulkDeleteModal();
      setDataSubmitted( new Date().valueOf() );
    },
    [
      closeBulkDeleteModal,
      toggleErrorAlert,
      toggleLoading,
    ],
  );

  return (
    <>
      <ContactTasksView
        isOwner={isOwner}
        data={data}
        dataToExport={dataToExport}
        csvLinkRef={csvLinkRef}
        isLoading={dataLoading && showLoading.current}
        initialDates={dateRange.current}
        defaultFilters={urlFilters()}
        setFiltersValues={setFiltersValues}
        selectedRows={selectedRows.current}
        selectedRowsChange={selectedRowsChange}
        bulkErrors={bulkErrors}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onOpenBulkEditModal={openBulkEditModal}
        onOpenBulkDeleteModal={openBulkDeleteModal}
        onOpenIncidenceEditModal={openIncidenceEditModal}
        onDelete={deleteTask}
        taskTypes={taskTypes}
        isLoadingTaskTypes={loadingTaskTypes}
        onFetchProperties={fetchProperties}
        onFetchAccommodations={fetchAccommodations}
        onFetchTagsPredefined={fetchTagsPredefined}
        onTodaysTasks={getTodayTasks}
        onDateChanged={updateDateRangeParams}
        onToggleRowChecked={toggleRowChecked}
        onToggleAllRowsChecked={toggleAllRowsChecked}
        onDownloadData={onDownloadData}
        onFetchEmployees={fetchEmployees}
        onFetchTeams={fetchTeams}
        Cities={CitiesOptions}
        zones={ZonesOptions}
        disabledBulkEdit={disabledBulkEdit}
      />
      {bulkEditModalOpened && (
      <TaskBulkEdit
        taskIds={selectedRows.current}
        modalOpened={bulkEditModalOpened}
        onCloseModal={closeBulkEditModal}
        onReload={reloadBulkData}
        filtersValues={filtersValues}
      />
      )}

      <ConfirmationModal
        open={bulkDeleteModalOpened}
        title="deleteModal.confirmation.tasks"
        onConfirm={deleteTasks}
        onClose={closeBulkDeleteModal}
      />
    </>

  );
};

const mapStateToProps = ( {
  task, router, user, incidence,
} ) => ( {
  userRole: user.mainRole,
  taskReloadedAt: task.reloadedAt,
  incidenceReloadedAt: incidence.reloadedAt,
  searchParams: _.get( router, 'location.search', '' ),
  propertyManager: _.get( user, 'user.propertyManager.id', {} ),
} );

const mapDispatchToProps = ( {
  toggleIncidenceModal: IncidenceActions.toggleIncidenceEditModal,
  toggleTaskEditModal: TaskActions.toggleEditModal,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ContactTasks );
