import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _values from 'lodash/values';
import _sortBy from 'lodash/sortBy';

const pipe = ( ...fns ) => ( x ) => fns.reduce( ( v, f ) => f( v ), x );

const getPropertyStatistics = ( accommodations ) => _reduce(
  accommodations,
  ( acc, accommodation ) => ( {
    tasksTotal: acc.tasksTotal + ( accommodation.tasksTotal || 0 ),
    tasksPending: acc.tasksPending + ( accommodation.tasksPending || 0 ),
    tasksInProgress: acc.tasksInProgress + ( accommodation.tasksInProgress || 0 ),
    tasksClosed: acc.tasksClosed + ( accommodation.tasksClosed || 0 ),
    checkout: acc.checkout + ( accommodation.checkout ? 1 : 0 ),
    checkin: acc.checkin + ( accommodation.checkin ? 1 : 0 ),
    checkInOut: acc.checkInOut + ( accommodation.checkin && accommodation.checkout ? 1 : 0 ),
    propertyName: accommodation.propertyName,
    roomTypeId: accommodation.roomTypeId,
    propertyId: accommodation.propertyId,
    empty: acc.empty + ( accommodation.empty ? 1 : 0 ),
    full: acc.full + ( accommodation.full ? 1 : 0 ),
  } ),
  ( {
    tasksTotal: 0,
    tasksPending: 0,
    tasksInProgress: 0,
    tasksClosed: 0,
    checkout: 0,
    checkin: 0,
    checkInOut: 0,
    empty: 0,
    propertyName: '',
    roomTypeId: '',
    propertyId: '',
    full: 0,
  } ),
);

const groupByRoomType = ( accommodations ) => _groupBy( accommodations, 'roomTypeId' );

const groupByPropertyId = ( accommodations ) => _groupBy( accommodations,
  ( item ) => `${_get( item, 'propertyName', '' )}-${_get( item, 'propertyPC', '' )}` );

const formatDataProperties = ( properties ) => ( properties.length ? _map( properties,
  ( accommodations ) => ( {
    ...getPropertyStatistics( accommodations ),
    cityId: _get( accommodations[0], 'cityId' ),
    data: pipe( groupByRoomType, _values )( accommodations ),
  } ) ) : [] );

export const getPMSStatistics = ( accommodations ) => _reduce(
  accommodations,
  ( acc, accommodation ) => ( {
    tasksTotal: acc.tasksTotal + accommodation.tasksTotal,
    tasksPending: acc.tasksPending + accommodation.tasksPending,
    tasksInProgress: acc.tasksInProgress + accommodation.tasksInProgress,
    tasksClosed: acc.tasksClosed + accommodation.tasksClosed,
    checkout: acc.checkout + accommodation.checkout,
    checkin: acc.checkin + accommodation.checkin,
    empty: acc.empty + accommodation.empty,
    full: acc.full + accommodation.full,
  } ),
  ( {
    tasksTotal: 0,
    tasksPending: 0,
    tasksInProgress: 0,
    tasksClosed: 0,
    checkout: 0,
    checkin: 0,
    empty: 0,
    full: 0,
  } ),
);

const flatAccommodation = ( accommodation ) => ( {
  accommodationId: accommodation._id,
  accommodationName: accommodation.name,
  propertyId: _get( accommodation, 'property.id' ),
  propertyPC: _get( accommodation, 'property.postalCode' ),
  propertyName: _get( accommodation, 'property.name' ),
  roomTypeId: _get( accommodation, 'roomType.id' ),
  roomTypeName: _get( accommodation, 'roomType.name' ),
  cityId: _get( accommodation, 'city.id' ),
  cleaningStatus: _get( accommodation, 'cleaningStatus' ),
} );

export const flatAccommodations = ( accommodations ) => accommodations.map( flatAccommodation );

const formatDataByProperties = ( data ) => pipe(
  groupByPropertyId,
  _values,
  formatDataProperties,
)( data );

// checkOutComplete, checkinCompleteDynamic son campos que se configuran deacuerdo a la
const formatDataPMS = ( data, properties ) => {
  const checkOutTimeData = [...data.checkOut.data, ...data.checkInOut.data]
    .reduce( ( acc, item ) => ( {
      ...acc,
      [item.currentAccommodation.id]: item.checkOutTime,
    } ), {} );

  const checkInTimeData = [...data.checkIn.data, ...data.checkInOut.data]
    .reduce( ( acc, item ) => ( {
      ...acc,
      [item.currentAccommodation.id]: item.checkInTime,
    } ), {} );

  const inisdeCheckIn = data.checkIn.data
    .filter( ( { checkinCompleteDynamic } ) => checkinCompleteDynamic === 'true' );

  const insideDataTofilter = inisdeCheckIn.length > 0
    ? [...data.inside.data, ...inisdeCheckIn]
    : data.inside.data;

  const insideData = insideDataTofilter
    .reduce( ( acc, item ) => ( {
      ...acc,
      [item.currentAccommodation.id]: true,
    } ), {} );

  const tasks = data.tasks.data
    .filter( ( { accommodation } ) => accommodation )
    .reduce( ( acc, { accommodation, status } ) => ( {
      ...acc,
      [accommodation.id]: {
        tasksClosed: _get( acc, `${accommodation.id}.tasksClosed`, 0 )
          + ( ( status === 'resolved' || status === 'closed' ) ? 1 : 0 ),
        tasksInProgress: _get( acc, `${accommodation.id}.tasksInProgress`, 0 )
          + ( status === 'in_progress' ? 1 : 0 ),
        tasksPending: _get( acc, `${accommodation.id}.tasksPending`, 0 )
          + ( ( status === 'open' || status === 'assigned' ) ? 1 : 0 ),
      },
    } ), {} );

  const formatedData = _sortBy( properties.map( ( item ) => ( {
    ...item,
    checkout: checkOutTimeData[item.accommodationId],
    checkin: checkInTimeData[item.accommodationId],
    empty: !insideData[item.accommodationId]
    && !checkOutTimeData[item.accommodationId]
    && !checkInTimeData[item.accommodationId],
    full: insideData[item.accommodationId]
    || checkOutTimeData[item.accommodationId]
    || checkInTimeData[item.accommodationId],
    ..._get( tasks, item.accommodationId, {
      tasksClosed: 0,
      tasksInProgress: 0,
      tasksPending: 0,
    } ),
  } ) ), ['propertyName', 'roomTypeName', 'accommodationName'] );

  return {
    properties: formatDataByProperties( formatedData ),
  };
};

export default formatDataPMS;
