import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import {
  Form,
  TextField,
  EditCard,
  Intl,
  FormActionsBtns,
  CanAccess,
} from 'components';
import { canModify } from 'config/Ability';
import { initializeDocument } from './utils';

export default React.memo(
  ( {
    data, name, paths, onSubmit,
  } ) => (
    <EditCard
      headerContent={(
        <>
          <h3 className="mb-1 mt-1">
            <Intl
              id={
                canModify( 'accommodation' )
                  ? 'editAccommodation'
                  : 'accommodation'
              }
            />
            {` ${name}`}
          </h3>
          <hr className="mt-3 mb-4" />
          <h3 className="m-0">
            <Intl id="arrivalInstructions" />
          </h3>
        </>
      )}
      cardClass="card-sec-bottom"
    >
      <Form
        initialValues={{
          documentCheckInInstruction: initializeDocument( data?.entity ),
          checkInInstructions: data.entity?.checkInInstructions,
        }}
        onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
        showErrorsInFooter
        body={( form ) => (
          <>
            <Row className="mt-3">
              <Col md={8}>
                <TextField
                  field="checkInInstructions"
                  label="checkInInstructions"
                  type="textarea"
                  rows={5}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={8}>
                <TextField
                  label={_.get(
                    form.getState(),
                    'values.documentCheckInInstruction.name',
                    'documentCheckInInstruction',
                  )}
                  type="file"
                  onChange={async ( val, e ) => {
                    const mainFile = e.currentTarget.files[0];
                    if ( mainFile ) {
                      form.change( 'documentCheckInInstruction', mainFile );
                    }
                  }}
                  // field="documentCheckInInstruction"
                  labelClass="custom-file-label"
                  inputClass="custom-file-input"
                  formGroupClass="custom-file"
                  accept="application/pdf"
                />
                <span className="mt-1 help-text">
                  <Intl id="accommodationImage.help" />
                </span>
              </Col>
            </Row>
          </>
        )}
        footer={(
          <CanAccess I="edit" a="accommodation">
            <FormActionsBtns cancelTo={paths.accommodation} />
          </CanAccess>
        )}
      />
    </EditCard>
  ),
);
