import React from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';

import {
  AsyncTable, AddBtn, SimpleHeader, TableGenericFilter, TableActionsBtns, TableEditBtn, CanAccess,
} from 'components';
import { canView, canModify } from 'config/Ability';

export default React.memo( ( {
  data, isLoading, defaultFilters, onFetchData, onOpenEditModal, onDelete,
} ) => {
  const canViewProp = canView( 'cms' );
  const canEditDelete = canModify( 'cms' );

  return (
    <>
      <SimpleHeader title="categories" />
      <Container fluid>
        <AsyncTable
          id="categories"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={<CanAccess I="add" a="cms"><AddBtn title="newCategory" onClick={onOpenEditModal} /></CanAccess>}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter
                  filters={filters}
                  setFilter={setFilter}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'name',
              accessor: 'name',
              Cell: ( { cell } ) => (
                canEditDelete || canViewProp ? (
                  <TableEditBtn
                    title={cell.value}
                    onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
                  />
                ) : cell.value
              ),
            },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        isDelete: true,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      },
                    ]}
                  />
                ),
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );
