import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';

class ErrorBoundary extends Component {
  state = { eventId: null };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch( error, errorInfo ) {
    Sentry.withScope( ( scope ) => {
      scope.setExtras( errorInfo );
      const eventId = Sentry.captureException( error );
      this.setState( { eventId } );
    } );
  }

  render() {
    const { hasError, eventId } = this.state;
    const { children } = this.props;
    return hasError ? (
      <Fade duration={1000}>
        <div className="text-center mt-5">
          <h1>Ops, there was an error!</h1>
          <Button color="primary" data-cy="form-submit-btn" onClick={() => Sentry.showReportDialog( { eventId } )}>
            Report feedback
          </Button>
        </div>
      </Fade>
    ) : children;
  }
}

export default ErrorBoundary;
