import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';

const NotificationCenterButton = ( {
  toggleNotificationCenter,
  unreadNotifications,
  className,
} ) => (
  <a
    href="/"
    className={classnames( 'notification-center-button', className )}
    onClick={( e ) => {
      e.preventDefault();
      toggleNotificationCenter();
    }}
  >
    <i
      className="th-icon-notification th-icon"
    />
    {unreadNotifications ? (
      <div className="badg-round">
        {`${unreadNotifications && unreadNotifications > 9 ? '+9' : unreadNotifications}`}
      </div>
    ) : null}
  </a>
);

const mapStateToProps = ( {
  settings,
} ) => ( {
  unreadNotifications: settings.unreadNotifications,
} );

const mapDispatchToProps = ( {
  toggleNotificationCenter: SettingsActions.toggleNotificationCenter,
} );

export default connect( mapStateToProps, mapDispatchToProps )( NotificationCenterButton );
