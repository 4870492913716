import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EditView from 'views/PropertyManager/Edit/Contract/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import ContractService from 'api/Settings/Contract';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import PropertyService from 'api/Property';
import TranslationService from 'api/Translation';

import { formatDataToSend, handlePromise } from 'utils';

const Dashboard = ( {
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
  match,
  parentPath,
  history,
} ) => {
  const contractSettingId = _.get( match, 'params.id' );

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
  } );

  const handleOnChangeCountry = useCallback(
    ( id ) => {
      setSelectedCountry( _.find( countries, { id } ) );
      setSelectedState();
    },
    [countries],
  );

  const [states, statesLoading] = useFetch( {
    initialState: [],
    promise: () => StateService.getStates( selectedCountry.iso ),
    toggleErrorAlert,
    conditional: !!selectedCountry,
    deps: [selectedCountry],
  } );

  const handleOnChangeState = useCallback(
    ( id ) => {
      setSelectedState( _.find( states, { id } ) );
    },
    [states],
  );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ContractService.getContractSetting( contractSettingId ),
    translationPromise: () => TranslationService
      .getContractSettingTranslations( contractSettingId ),
    reInit: true,
    toggleErrorAlert,
    conditional: !!contractSettingId,
    deps: [contractSettingId],
  } );

  const [cities, citiesLoading] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( selectedCountry.iso, selectedState.iso ),
    toggleErrorAlert,
    conditional: !!selectedCountry && !!selectedState,
    deps: [selectedCountry, selectedState],
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );
    const translation = true;
    const dataToSend = formatDataToSend( formData, form, translation );
    delete dataToSend.translationfile;
    delete dataToSend.file;
    delete dataToSend.state;

    const [errors, response] = await handlePromise(
      contractSettingId ? ContractService.updateContractSetting(
        contractSettingId,
        dataToSend,
      ) : ContractService.saveContractSetting(
        dataToSend,
      ),
    );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( !_.isString( _.get( formData, 'file' ) ) && _.get( formData, 'file' ) ) {
      const contractToSend = {
        file: formData.file,
        name: formData.file.name,
      };
      const [fileErrors, fileResponse] = await handlePromise(
        ContractService.updateContractFile( response.data.id, contractToSend ),
      );
      if ( !fileResponse.ok ) {
        toggleLoading( false );
        return fileErrors;
      }
    }
    if ( !_.isString( _.get( formData, 'translationfile' ) ) && _.get( formData, 'translationfile' ) ) {
      const contractToSend = {
        file: formData.translationfile.file,
        name: formData.translationfile.file.name,
        locale: formData.translationfile.locale,
      };
      const [fileErrors, fileResponse] = await handlePromise(
        ContractService.updateContractFile( response.data.id, contractToSend ),
      );
      if ( !fileResponse.ok ) {
        toggleLoading( false );
        return fileErrors;
      }
    }
    toggleLoading( false );
    history.push( parentPath );
    toggleInfoAlert( 'dataSaved' );
  }, [contractSettingId, toggleInfoAlert, toggleLoading, history, parentPath] );

  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 100,
      keyword: query,
    } ),
    [],
  );

  return (
    <EditView
      entityId={contractSettingId}
      data={data}
      isLoading={dataLoading}
      onSubmit={submitForm}
      countries={countries}
      isLoadingCountries={countriesLoading}
      onChangeCountry={handleOnChangeCountry}
      states={states}
      isLoadingStates={statesLoading}
      onChangeState={handleOnChangeState}
      cities={cities}
      isLoadingCities={citiesLoading}
      parentPath={parentPath}
      onFetchProperties={fetchProperties}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
