import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const UPDATE_SEASON_RATE = gql`
  mutation UpdateSeasonRate($input: SeasonRateUpdateInput!) {
    updateSeasonRate(input: $input) {
      ... on BaseError {
        message
      }
      ... on MutationUpdateSeasonRateSuccess {
        data {
          success
        }
      }
    }
  }
`;

const updateSeason = async ( input ) => {
  const { data } = await client.query( {
    query: UPDATE_SEASON_RATE,
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateSeasonRate.message ) {
    return {
      ok: false,
      message: data.updateSeasonRate.message,
    };
  }
  const updateSuccessful = data.updateSeasonRate.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateSeason;
