import React, {
  useRef,
} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import CustomerDashboardView from 'views/Customer/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import { useFetchTableData } from 'hooks';
import CustomerService from 'api/Customer';

const CustomerDashboard = ( {
  parentPath,
  defaultFilters,
  toggleErrorAlert,
  updateFilters,
  setFiltersError,
} ) => {
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: CustomerService.getCustomers,
    toggleErrorAlert,
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'customers', {
        keyword: params.keyword,
        roles: params.roles,
      } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'customers' );
        initialReloadError.current = false;
      }
    },
    deps: [/* dataSubmitted */],
  } );

  return (
    <>
      <CustomerDashboardView
        data={data}
        parentPath={parentPath}
        isLoading={dataLoading}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
      />
    </>
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.customers' ) || {},
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( CustomerDashboard );
