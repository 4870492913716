import React, { useCallback, useContext, useMemo } from 'react';
import { CardFooter } from 'reactstrap';
import Pagination from 'components/Table/utils/Pagination';
import { BusinnesCRMContext } from 'containers/CRM/Business/Dashboard/context';

const PaginationBusinnessAll = ( { data } ) => {
  const {
    filters,
    setFilter,
  } = useContext( BusinnesCRMContext );

  const { totalElements, pageIndex, pageCount } = useMemo( () => {
    const total = data?.elementsTotal || 0;
    const count = Math.ceil( total / ( filters?.elementsPerPage || 10 ) );

    return {
      totalElements: total,
      pageIndex: ( filters?.page || 1 ) - 1,
      pageCount: count,
    };
  }, [data, filters] );

  const changePage = useCallback( ( page ) => {
    setFilter( { page: page + 1 } );
  }, [setFilter] );

  return (
    <CardFooter>
      <Pagination
        totalElements={totalElements}
        pageIndex={pageIndex}
        gotoPage={changePage}
        previousPage={( ) => { changePage( pageIndex - 1 ); }}
        nextPage={( ) => { changePage( pageIndex + 1 ); }}
        canPreviousPage={pageIndex > 0}
        canNextPage={( pageIndex + 1 ) < pageCount}
        pageCount={pageCount}
      />
    </CardFooter>
  );
};

export default PaginationBusinnessAll;
