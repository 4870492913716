import React from 'react';
import { Field } from 'react-final-form';

import TagsInput from '../utils/TagsInput';

const TagsField = React.memo( ( {
  field, validate, value, name, ...rest
} ) => {
  if ( field ) {
    return (
      <Field name={field} validate={validate} type="select">
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && !!meta.submitError );

          return (
            <TagsInput
              {...input}
              {...rest}
              isInvalid={isInvalid}
              inputName={input.name}
              value={input.value}
              inputOnChange={input.onChange}
              meta={meta}
            />
          );
        }}
      </Field>
    );
  }

  return (
    <TagsInput
      {...rest}
      inputName={name}
      value={value}
    />
  );
} );

export default TagsField;
