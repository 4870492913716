import React from 'react';

import {
  ModalInfo, Intl, Button,
} from 'components';
import { Row, Col } from 'reactstrap';

const MaxGuestsModal = ( {
  modalOpened, handleClose,
} ) => (

  <ModalInfo
    title="max_guests_title"
    open={modalOpened}
    onClose={handleClose}
    translateTitle
    footer={(
      <Row>
        <Col>
          <Button title="accept" onClick={handleClose} />
        </Col>
      </Row>
    )}
  >
    <Intl id="max_guests" />
  </ModalInfo>

);

export default MaxGuestsModal;
