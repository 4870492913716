import React, {
  useCallback,
  useState,
} from 'react';
import ContactDashboardView from 'views/CRM/Contact/Dashboard';

const ContactDashboard = ( { path } ) => {
  const [onReloadContacts, setOnReloadContacts] = useState( new Date().valueOf() );
  const [modalNewContactOpened, setModalNewContactOpened] = useState( false );
  const toggleModalNewContact = () => setModalNewContactOpened( ( state ) => !state );

  const reloadContacts = useCallback( () => {
    setOnReloadContacts( new Date().valueOf() );
  }, [] );

  return (
    <ContactDashboardView
      path={path}
      modalNewContactOpened={modalNewContactOpened}
      toggleModalNewContact={toggleModalNewContact}
      onReloadContacts={onReloadContacts}
      reloadContacts={reloadContacts}
    />
  );
};

export default ContactDashboard;
