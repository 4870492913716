import React from 'react';
import { connect } from 'react-redux';
import BusinessView from 'views/CRM/Business/Dashboard';
import BusinnesCRMContextProvider from './context';

const Business = ( { currentCreatedBy } ) => (
  <BusinnesCRMContextProvider
    currentCreatedBy={currentCreatedBy}
  >
    <BusinessView />
  </BusinnesCRMContextProvider>
);

const mapStateToProps = ( { user } ) => ( { currentCreatedBy: user?.user?.user?.email || '' } );

export default connect( mapStateToProps, null )( Business );
