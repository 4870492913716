import React, {
  useState, useRef, useMemo,
} from 'react';
import { Row, Col } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import {
  TextField,
  Select,
  Intl,
  Form,
  TaskTemporalFormBtns,
  AsyncSelect,
  UserAvatar,
  Datepicker,
  // CustomField,
  BookingOpenLockerModal,
} from 'components';
import {
  taskPriorities,
  taskStatuses,
} from 'config/Constants';
import { getInitials, getResourceName } from 'utils';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    // customFields,
    onSubmit,
    taskTypes,
    isLoadingTaskTypes,
  } ) => {
    const [enabledField, setEnabledField] = useState( null );
    const onSubmitFn = canModify( 'task' ) ? onSubmit : () => {};
    const [openModalOpen, setOpenModal] = useState( false );
    const doorTypeToOpen = useRef( null );
    const doorIdToOpen = useRef( null );

    // const formatOptions = ( _options ) =>
    //   // eslint-disable-next-line implicit-arrow-linebreak
    //   _options.map( ( op ) => ( {
    //     id: op.id,
    //     name: `${op.firstName} ${op.lastName}`,
    //   } ) );

    const closeOpenDoorModal = () => {
      setOpenModal( false );
    };

    const dataInitial = useMemo( () => {
      const newData = {
        ...data,
        assignedTo: data.assignedTo
          ? {
            id: data.assignedTo?.value,
            label: data.assignedTo.label,
          }
          : {},
      };

      return newData;
    }, [data] );

    return (
      <>
        <Form
          onSubmit={onSubmitFn}
          initialValues={dataInitial}
          showErrorsInFooter
          body={( form ) => {
            const disabled = data.status !== 'open' && data.status !== 'assigned';
            const isClosed = data.status === 'closed';

            return (
              <Row className="pl-3">
                <Col lg={8} className="border-right">
                  <Row className="mb-4">
                    <Col md={8}>
                      <AsyncSelect
                        field="accommodation"
                        label="accommodation"
                        labelClass="font-weight-bold"
                        isDisabled
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col md={7}>
                      <Select
                        field="taskType"
                        label="category"
                        labelClass="font-weight-bold"
                        options={taskTypes}
                        isLoading={isLoadingTaskTypes}
                        optgroup
                        onChange={() => {
                          onSubmitFn(
                            form.getState().values,
                            'taskType',
                            'taskType',
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      {enabledField === 'description' ? (
                        <>
                          <TextField
                            field="description"
                            label="description"
                            type="textarea"
                            labelClass="font-weight-bold"
                            rows={3}
                          />
                          <TaskTemporalFormBtns
                            onSave={() => onSubmitFn(
                              form.getState().values,
                              'description',
                              'description',
                              () => setEnabledField( null ),
                            )}
                            onClickCancel={() => setEnabledField( null )}
                          />
                        </>
                      ) : (
                        <div
                          style={{ minHeight: '6rem' }}
                          className={classnames( {
                            'cursor-pointer': !disabled,
                          } )}
                          onClick={() => ( disabled ? {} : setEnabledField( 'description' ) )}
                        >
                          <label className="font-weight-bold m-0 form-control-label">
                            <Intl id="description" />
                          </label>
                          <p>{data.description}</p>
                        </div>
                      )}
                    </Col>
                  </Row>

                  {/* {!!customFields && !!customFields.length && (
                    <Row>
                      {_.map( customFields, ( item ) => (
                        <Col md={6} key={item.id}>
                          {enabledField === item.name ? (
                            <>
                              <CustomField
                                field={item}
                                labelClass="font-weight-bold"
                              />

                              <TaskTemporalFormBtns
                                onSave={() => onSubmitFn(
                                  form.getState().values,
                                  item.name,
                                  item.name,
                                  () => setEnabledField( null ),
                                )}
                                onClickCancel={() => setEnabledField( null )}
                              />
                            </>
                          ) : (
                            <div
                              className={classnames( {
                                'cursor-pointer': !disabled,
                              } )}
                              onClick={() => ( disabled ? {} : setEnabledField( item.name ) )}
                            >
                              <label className="font-weight-bold m-0 form-control-label">
                                {item.displayName}
                              </label>
                              <p>{data[item.name] || '-'}</p>
                            </div>
                          )}
                        </Col>
                      ) )}
                    </Row>
                  )} */}
                </Col>

                <Col lg={4}>
                  <Row className="mb-3">
                    <Col>
                      <Select
                        field="status"
                        label="status"
                        labelClass="font-weight-bold"
                        isDisabled={isClosed}
                        options={taskStatuses}
                        translateOptions
                        formGroupClass={classnames(
                          'm-0 task-status-select',
                          form.getFieldState( 'status' )
                            ? form.getFieldState( 'status' ).value
                            : '',
                        )}
                        onChange={() => onSubmitFn( form.getState().values, 'status', 'status' )}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <Datepicker
                        field="date"
                        label="scheduledFor"
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm"
                        utc
                        labelClass="font-weight-bold"
                        formGroupClass="m-0"
                        disabled={disabled}
                        startDateField={{
                          value: moment().subtract( '1', 'day' ),
                        }}
                        onBlur={() => onSubmitFn( form.getState().values, 'date', 'date' )}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <Select
                        field="priority"
                        label="priority"
                        labelClass="font-weight-bold"
                        translateOptions
                        isDisabled={isClosed}
                        options={_.map( taskPriorities, ( priority ) => ( {
                          id: priority.id,
                          name: priority.name,
                          icon: (
                            <i
                              className={classnames(
                                priority.iconClass,
                                'text-2xsm',
                              )}
                            />
                          ),
                        } ) )}
                        formGroupClass="m-0"
                        onChange={() => onSubmitFn(
                          form.getState().values,
                          'priority',
                          'priority',
                        )}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <p className="font-weight-bold text-sm mb-1 form-control-label">
                        <Intl id="reported" />
                      </p>
                      {!data.registered ? (
                        '-'
                      ) : (
                        <Row>
                          {!!data.registered && (
                            <Col xs="auto" className="pr-0">
                              <UserAvatar
                                avatar={_.get(
                                  data.registered.customer,
                                  'profilePicture',
                                )}
                                initials={getInitials( data.registered.customer )}
                              />
                            </Col>
                          )}
                          <Col className="pl-2">
                            {!!data.registered && (
                              <p className="m-0 text-sm">
                                {getResourceName( data.registered.customer )}
                              </p>
                            )}
                            {!!data.registered && (
                              <p className="m-0 text-sm text-gray-9 lh-100">
                                {moment( data.registered.date ).format(
                                  'DD-MM-YYYY hh:mma',
                                )}
                              </p>
                            )}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <p className="font-weight-bold text-sm mb-1 form-control-label">
                        <Intl id="solved" />
                      </p>
                      <p className="text-sm m-0">-</p>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <p className="font-weight-bold text-sm mb-1 form-control-label">
                        <Intl id="closed" />
                      </p>
                      {!data.closed ? (
                        '-'
                      ) : (
                        <Row>
                          {!!data.closed.closedBy && (
                            <Col xs="auto" className="pr-0">
                              <UserAvatar
                                avatar={_.get(
                                  data.closed.closedBy,
                                  'profilePicture',
                                )}
                                initials={getInitials( data.closed.closedBy )}
                              />
                            </Col>
                          )}
                          <Col className="pl-2">
                            {!!data.closed.closedBy && (
                              <p className="m-0 text-sm">
                                {getResourceName( data.closed.closedBy )}
                              </p>
                            )}
                            {!!data.closed.closedAt && (
                              <p className="m-0 text-sm text-gray-9 lh-100">
                                {moment( data.closed.closedAt ).format(
                                  'DD-MM-YYYY hh:mma',
                                )}
                              </p>
                            )}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  {/* <Row className="mb-3">
                    <Col>
                      <BookingOpenLockerBtns
                        data={data}
                        onOpenDoorModal={openOpenDoorModal}
                      />
                    </Col>
                  </Row> */}
                </Col>
              </Row>
            );
          }}
        />
        <BookingOpenLockerModal
          data={data}
          doorType={doorTypeToOpen.current}
          lockerId={doorIdToOpen.current}
          modalOpened={openModalOpen}
          onCloseModal={closeOpenDoorModal}
        />
      </>
    );
  },
);
