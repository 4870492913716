import React, { useRef } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import AccomodationOwnerService from 'api/AccomodationOwner';
import moment from 'moment';
import ReportsClientEditView from 'views/AccomodationOwner/Edit/monthlyReports';
import getYearArray from './utils';

const MonthlyReportsClientEdit = ( {
  match,
  toggleErrorAlert,
} ) => {
  const clientId = match.params.id;

  const filterFromDate = moment().startOf( 'day' );
  const filterToDate = moment( filterFromDate ).clone().add( 7, 'day' );
  const initialDates = useRef( moment.range( filterFromDate, filterToDate ) );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AccomodationOwnerService.getMonthlyReports( clientId, params ),
    toggleErrorAlert,
  } );

  const months = {
    1: 'january',
    2: 'february',
    3: 'march',
    4: 'april',
    5: 'may',
    6: 'june',
    7: 'july',
    8: 'august',
    9: 'september',
    10: 'october',
    11: 'november',
    12: 'december',
  };

  const yearOptions = getYearArray();

  return (
    <ReportsClientEditView
      data={data}
      months={months}
      onFetchData={fetchData}
      isLoading={dataLoading}
      initialDates={initialDates.current}
      defaultFilters={{}}
      yearOptions={yearOptions}
      clientId={clientId}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( MonthlyReportsClientEdit );
