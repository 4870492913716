import React from 'react';
import classnames from 'classnames';
import {
  Card, CardBody, CardHeader,
} from 'reactstrap';

import { Intl } from 'components';

export default React.memo( ( {
  children, cardClass, title, isLoading, titlePrefix,
  translateTitlePrefix = true, headerContent,
} ) => (
  <Card
    data-cy="form-card"
    className={classnames( cardClass, { 'app-component-loading': isLoading } )}
  >
    {title || headerContent
      ? (
        <CardHeader>
          {headerContent || (
          <h3 className="mb-0">
            {titlePrefix ? `${translateTitlePrefix ? <Intl id={titlePrefix} /> : titlePrefix} - ` : null}
            <Intl id={title} />
          </h3>
          )}
        </CardHeader>
      ) : null}

    <CardBody>
      {children}
    </CardBody>
  </Card>
) );
