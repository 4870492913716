import React, { useState, useEffect } from 'react';
import { Container, Card } from 'reactstrap';
import { HorizontalMenu, SimpleHeader } from 'components';
import { Redirect, Route, Switch } from 'react-router';
import Configuration from 'containers/AccomodationOwner/Edit/configuration';
import Accomodations from 'containers/AccomodationOwner/Edit/accomodations';
import Bookings from 'containers/AccomodationOwner/Edit/bookings';
import Tasks from 'containers/AccomodationOwner/Edit/tasks';
import Expenses from 'containers/AccomodationOwner/Edit/expenses';
import MonthlyReports from 'containers/AccomodationOwner/Edit/monthlyReports';
import Notifications from 'containers/AccomodationOwner/Edit/notifications';
import Finance from 'containers/AccomodationOwner/Edit/Finance';

const tabs = [
  {
    name: 'settings',
    path: 'settings',
    component: Configuration,
  },
  {
    name: 'accommodations',
    path: 'accommodations',
    component: Accomodations,
  },
  {
    name: 'bookings',
    path: 'bookings',
    component: Bookings,
  },
  {
    name: 'tasks',
    path: 'tasks',
    component: Tasks,
  },
  {
    name: 'expenses',
    path: 'expenses',
    component: Expenses,
  },
  {
    name: 'monthlyReports',
    path: 'monthly-reports',
    component: MonthlyReports,
  },
  {
    name: 'notifications',
    path: 'Notifications',
    component: Notifications,
  },
  {
    name: 'finance',
    path: 'finance',
    component: Finance,
  },
];

const PropertyOwnerEditor = ( { parentPath, history, match } ) => {
  const [currentTab, setCurrentTab] = useState( tabs[0].name );

  const getRoutes = ( routesList ) => routesList.map( ( route ) => {
    if ( route.component ) {
      return (
        <Route
          exact={route.exact}
          path={`${match.url}/${route.path}`}
          render={( props ) => (
            <route.component
              {...props}
              parentPath={parentPath}
              history={history}
              match={match}
              baseUrl={`${match.url}/${route.path}`}
            />
          )}
          key={route.layout + route.path}
        />
      );
    }
    return null;
  } );

  useEffect( () => {
    if ( typeof window !== 'undefined' ) {
      const { href } = window.location;

      tabs.forEach( ( t ) => {
        if ( href.toLowerCase().indexOf( t.path ) >= 0 ) {
          setCurrentTab( t.name );
        }
      } );
    }
  }, [] );

  return (
    <>
      <SimpleHeader parentLinks={[{ name: 'propertyOwners' }]} />
      <Container fluid>
        <Card className="card-sec-top border-bottom">
          <HorizontalMenu
            tabs={tabs}
            selected={currentTab}
            onChangeTab={setCurrentTab}
            pathLayout={`${match.url}/`}
          />

          <Switch>
            {getRoutes( tabs )}
            <Redirect from="*" to={`${match.url}/${tabs[0].path}`} />
          </Switch>
        </Card>
      </Container>
    </>
  );
};

export default PropertyOwnerEditor;
