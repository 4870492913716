import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form,
  Datepicker,
  FormActionsBtns,
  Dropzone,
  Intl,
} from 'components';
import { required } from 'config/InputErrors';

const NewContract = ( { submitForm, onClose } ) => (
  <>
    <h3>
      <Intl id="newContract" />
    </h3>
    <hr className="mt-0" />
    <Form
      onSubmit={submitForm}
      showErrorsInFooter
      body={() => (
        <Row>
          <Col md="auto">
            <Dropzone
              field="contract"
              formGroupClass="px-3 dz-profile-img"
              dropzoneFileLimitMsg="dropzoneFileLimitMsg.pdfOrImgs"
              accept=".pdf,image/*"
            />
          </Col>
          <Col>

            <Datepicker
              field="from"
              label="from"
              dateFormat="DD/MM/YYYY"
              timeFormat="HH:mm"
              utc
              labelClass="font-weight-bold"
              validate={required}
            />
            <Datepicker
              field="till"
              label="till"
              dateFormat="DD/MM/YYYY"
              timeFormat="HH:mm"
              utc
              labelClass="font-weight-bold"
              validate={required}
            />

          </Col>
        </Row>
      )}
      footer={(
        <FormActionsBtns
          onClickCancel={onClose}
        />
        )}
    />
  </>
);

export default NewContract;
