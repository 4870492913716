import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import NotificationEdit from 'views/Booking/Edit/Notifications/Edit';
import { useFetch } from 'hooks';
import NotificationService from 'api/ScheduledNotifications';
import CommunicationService from 'api/CommunicationTemplates';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import TranslationService from 'api/Translation';
import ModalPreview from '../Preview';

const EditNotification = ( {
  open = false,
  notificationId = null,
  onClose,
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
  bookingData,
  onReload,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => NotificationService.getScheduledNotificationById( notificationId ),
    toggleErrorAlert,
    conditional: !!notificationId,
    deps: [open, notificationId],
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const dataToSend = formatDataToSend( formData, form );

      const formattedData = {};

      if ( !notificationId && bookingData.id ) {
        formattedData.bookedAccommodation = bookingData.id;
      }
      if ( !notificationId && bookingData.owner ) {
        formattedData.to = bookingData.owner.id;
      }
      if ( !notificationId && dataToSend.communicationTemplate ) {
        formattedData.communicationTemplate = dataToSend.communicationTemplate;
      }
      if ( !notificationId && dataToSend.executeAfter && dataToSend.executeAfter.format ) {
        formattedData.executeAfter = dataToSend.executeAfter.format( 'YYYY-MM-DD HH:mm:ss' );
      }
      if ( dataToSend.bodyEmail ) {
        formattedData.bodyEmail = dataToSend.bodyEmail;
      }
      if ( dataToSend.bodyPush ) {
        formattedData.bodyPush = dataToSend.bodyPush;
      }

      const [errors, response] = await handlePromise(
        notificationId
          ? NotificationService.updateScheduledNotification( notificationId, formattedData )
          : NotificationService.saveScheduledNotification( formattedData ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }
      toggleLoading( false );
      onReload();
      onClose();
      return toggleInfoAlert( 'dataSaved' );
    },
    [notificationId, toggleInfoAlert, toggleLoading, bookingData, onReload, onClose],
  );

  const [templates, templatesLoading] = useFetch( {
    initialState: [],
    promise: () => CommunicationService.getTemplates( { elementsPerPage: 100 } ),
    format: ( dataToFormat ) => _.get( dataToFormat, 'data', [] ),
    toggleErrorAlert,
  } );

  const [templateId, setTemplateId] = useState();

  const [translations, tranlationsLoading] = useFetch( {
    initialState: {},
    promise: () => TranslationService.getCommunicationTemplateTranslations( templateId ),
    toggleErrorAlert,
    conditional: !!templateId,
    deps: [templateId],
  } );

  const [openModalPreview, setOpenModalPreview] = useState( false );
  const [dataTemplatePreview, setDataTempaltePreview] = useState( {} );
  const toggleModalPreview = () => setOpenModalPreview( ( state ) => !state );

  const showModalTemplate = ( { values } ) => {
    toggleModalPreview();
    setDataTempaltePreview( values );
  };

  return (
    <>
      <NotificationEdit
        open={open}
        data={data}
        isLoading={dataLoading}
        onSubmit={submitForm}
        onPreview={showModalTemplate}
        notificationId={notificationId}
        onClose={onClose}
        templates={templates}
        isLoadingTemplates={templatesLoading}
        onChangeTemplete={setTemplateId}
        translations={translations}
        tranlationsLoading={tranlationsLoading}
      />
      <ModalPreview
        open={openModalPreview}
        onClose={toggleModalPreview}
        dataTempalte={dataTemplatePreview}
      />
    </>
  );
};
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( null, mapDispatchToProps )( EditNotification );
