import api from 'config/Api/Api';

const baseEndpoint = '/booking-types';

export default {
  getTypes( ) {
    return api.get( `${baseEndpoint}` );
  },
  getType( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  saveType( data ) {
    return api.post( `${baseEndpoint}`, data );
  },
  updateType( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  deleteType( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
