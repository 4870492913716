import api from 'config/Api/Api';

const baseEndpoint = ( serviceId ) => `/services/${serviceId}/service-category`;

export default {
  getServiceCategories( serviceId, params ) {
    return api.get( baseEndpoint( serviceId ), params );
  },

  getServiceCategory( serviceId, id ) {
    return api.get( `${baseEndpoint( serviceId )}/${id}` );
  },

  saveServiceCategory( serviceId, data ) {
    return api.post( baseEndpoint( serviceId ), data );
  },

  updateServiceCategory( serviceId, id, data ) {
    return api.put( `${baseEndpoint( serviceId )}/${id}`, data );
  },

  deleteServiceCategory( serviceId, id ) {
    return api.delete( `${baseEndpoint( serviceId )}/${id}` );
  },
};
