import React, { useCallback, useState, useContext } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import ContactGeneralView from 'views/CRM/Contact/Edit/General';
import { useSubmit } from 'hooks';
import { handlePromise } from 'utils';
import { updateContact, toggleContactActivation } from 'api/CRM/Contact';
import { ContactEditContext } from '../context';

const ContactGeneral = ( {
  toggleLoading,
  toggleInfoAlert, toggleErrorAlert,
} ) => {
  const {
    contactId, data, reloadData,
  } = useContext( ContactEditContext );

  const [editing, setEditing] = useState( false );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( updateContact( {
      id: contactId,
      ...dataToSend,
    } ) ),
    toggleLoading,
    toggleInfoAlert,
    callback: ( ) => {
      setEditing( false );
      reloadData();
    },
    deps: [contactId],
  } );

  const toggleActivation = useCallback( async ( value ) => {
    toggleLoading( true );

    const [errors, response] = await handlePromise(
      toggleContactActivation( contactId, value ),
    );

    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );
    reloadData();
  }, [contactId, toggleErrorAlert, toggleLoading, reloadData] );

  return (
    <ContactGeneralView
      data={data}
      onSubmit={onSubmit}
      editing={editing}
      setEditing={setEditing}
      toggleActivation={toggleActivation}
    />
  );
};

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( ContactGeneral );
