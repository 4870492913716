import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import EmailReaderView from 'views/CRM/Contact/Edit/Email/Reader';
import { useFetch } from 'hooks';
import { getEmailMessage } from 'api/CRM/Contact';

const EmailReader = ( {
  dataEmail, open, onClose, toggleEmailEditModal, toggleErrorAlert,
} ) => {
  const emailId = useMemo( () => dataEmail?.id, [dataEmail] );

  const [dataMessage, dataMessageLoading] = useFetch( {
    initialState: {},
    promise: () => getEmailMessage( emailId ),
    toggleErrorAlert,
    conditional: !!emailId,
    deps: [emailId],
  } );

  return (
    <EmailReaderView
      open={open}
      onClose={onClose}
      data={{ ...dataEmail, ...dataMessage }}
      isLoading={dataMessageLoading}
      toggleEmailEditModal={toggleEmailEditModal}
    />
  );
};
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( EmailReader );
