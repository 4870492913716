import React from 'react';
import {
  Row, Col, CardHeader, CardBody, Card,
} from 'reactstrap';

import {
  Form, TextField, FormActionsBtns, Intl, Phone, CanAccess, PropertyManagerLayout,
} from 'components';
import { email, required, composeValidators } from 'config/InputErrors';
import { roles } from 'config/Constants';
import ability from 'config/Ability';

export default React.memo( ( {
  data, pmData, isLoading, userRole, parentPath, pathLayout, pathname, onSubmit,
} ) => (
  <PropertyManagerLayout
    data={pmData}
    isLoading={isLoading}
    parentPath={parentPath}
    pathname={pathname}
    userRole={userRole}
    pathLayout={pathLayout}
  >
    <Card data-cy="form-card">
      <CardHeader className="py-3 d-flex justify-content-between">
        <h3 className="m-0">
          <Intl id="customerNo" />
          <span className="uppercase">{data.id ? ` #${data.id.slice( data.id.length - 6, data.id.length )}` : ''}</span>
        </h3>
      </CardHeader>

      <CardBody className="pt-2">
        <Form
          initialValues={data}
          onSubmit={ability.can( 'edit', 'property_manager' ) ? onSubmit : () => {}}
          showErrorsInFooter
        >
          <>
            <Row>
              <Col md={5}>
                <TextField
                  field="name"
                  label="commercialName"
                  validate={required}
                />
              </Col>
              <Col md={5}>
                <TextField
                  field="legalName"
                  label="corporateName"
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <TextField
                  field="address"
                  label="address"
                  validate={required}
                />
              </Col>
              <Col md={5}>
                <TextField
                  field="city"
                  label="city"
                  validate={required}
                />
              </Col>
              <Col md={2}>
                <TextField
                  field="postalCode"
                  label="postalCode"
                  validate={required}
                />
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <TextField
                  field="email"
                  label="email"
                  validate={composeValidators( required, email )}
                />
              </Col>
              <Col md={5}>
                <Phone
                  field="phone"
                  label="phoneNumber"
                />
              </Col>
              <Col md={2}>
                <TextField
                  field="taxId"
                  label="taxNumber"
                  validate={required}
                />
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <TextField
                  field="keyword"
                  label="workspace"
                  disabled
                  validate={required}
                />
              </Col>
            </Row>

            <hr className="divider-dashed" />

            <h4 className="font-weight-normal text-uppercase"><Intl id="contactPerson" /></h4>

            <Row>
              <Col md={3}>
                <TextField
                  field="contactPerson.firstName"
                  label="name"
                  validate={required}
                />
              </Col>
              <Col md={3}>
                <TextField
                  field="contactPerson.lastName"
                  label="lastname"
                  validate={required}
                />
              </Col>
              <Col md={3}>
                <TextField
                  field="contactPerson.position"
                  label="position"
                  validate={required}
                />
              </Col>
              <Col md={3}>
                <Phone
                  field="contactPerson.phone"
                  label="phoneNumber"
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <TextField
                  field="contactPerson.email"
                  validate={composeValidators( required, email )}
                  label="email"
                />
              </Col>
            </Row>
          </>

          <CanAccess I="edit" a="property_manager">
            <FormActionsBtns cancelTo={userRole !== roles.admin.id ? parentPath : null} />
          </CanAccess>
        </Form>
      </CardBody>
    </Card>
  </PropertyManagerLayout>
) );
