import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = ( serviceId ) => `/services/${serviceId}/service-item`;

export default {
  getServiceItems( serviceId, params ) {
    return api.get( baseEndpoint( serviceId ), params );
  },

  getServiceItem( serviceId, id ) {
    return api.get( `${baseEndpoint( serviceId )}/${id}` );
  },

  saveServiceItem( serviceId, data ) {
    return api.post( baseEndpoint( serviceId ), data );
  },

  updateServiceItem( serviceId, id, data ) {
    return api.put( `${baseEndpoint( serviceId )}/${id}`, data );
  },

  updateServiceItemImage( serviceId, id, data ) {
    return api.post( `${baseEndpoint( serviceId )}/${id}/main-image`, formatFormDataBody( data ) );
  },

  deleteServiceItem( serviceId, id ) {
    return api.delete( `${baseEndpoint( serviceId )}/${id}` );
  },
};
