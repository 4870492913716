import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';

import { Intl } from 'components';
import { taskPriorities, taskStatuses } from 'config/Constants';

export default ( { task, onOpenTaskEditModal } ) => {
  const priority = _.find( taskPriorities, { id: task.priority } );
  const status = _.find( taskStatuses, { id: task.status } );
  return (
    <>
      <Row>
        <Col>
          {!!priority && (
            <span>
              <i
                className={classnames( priority.iconClass, 'text-2xsm mr-2' )}
                id={`tooltip-supervisor-${task.id}`}
              />
              <UncontrolledTooltip
                delay={0}
                target={`tooltip-supervisor-${task.id}`}
              >
                <Intl id={priority.name} />
              </UncontrolledTooltip>
            </span>
          )}
          <span
            className="text-uppercase text-pink-1 text-sm cursor-pointer"
            onClick={() => onOpenTaskEditModal( task )}
          >
            #
            {task.id.slice( task.id.length - 6, task.id.length )}
          </span>
        </Col>
        {!!status
        && (
          <Col md="auto" className="text-xsm">
            <Intl id="status" />
            {': '}
            <span className={status.textClass}><Intl id={status.name} /></span>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <span className="text-gray-5 text-xsm">
            <Intl id="type" />
            {': '}
            {_.get( task, 'taskType.name' )}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="text-sm">
            {task.description}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="text-gray-6 text-xsm">
            <Intl id="reportedAt" />
            {': '}
            {task.created ? moment( task.created ).format( 'DD-MM-YYYY hh:mma' ) : '-'}
          </span>
        </Col>
      </Row>
      {!!task.solvedAt
      && (
        <Row>
          <Col>
            <span className="text-gray-6 text-xsm">
              <Intl id="solvedAt" />
              {': '}
              {task.solvedAt ? moment( task.solvedAt ).format( 'DD-MM-YYYY hh:mma' ) : '-'}
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};
