import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import NotesView from 'views/Booking/Edit/Notes';
import NotesModalView from 'views/Booking/Edit/Notes/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useSubmit } from 'hooks';
import BookingItem from 'api/BookingItem';

const Observations = ( {
  bookingData = {}, toggleLoading, toggleInfoAlert, onReloadData, toggleErrorAlert,
} ) => {
  const [modalOpened, setModalOpened] = useState( false );
  const [selectedNote, setSelectedNote] = useState( {} );

  const handleOpen = ( observation ) => {
    if ( !_.isEmpty( observation ) ) {
      setSelectedNote( observation );
    }
    setModalOpened( true );
  };
  const handleClose = ( ) => {
    setModalOpened( false );
    setSelectedNote( {} );
  };

  const reloadData = useCallback( () => {
    handleClose();
    onReloadData();
  }, [onReloadData] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( _.isEmpty( selectedNote )
      ? BookingItem.saveNote( bookingData.id, { ...dataToSend, type: 'checkout', blocked: 'false' } )
      : BookingItem.updateNote( bookingData.id, selectedNote.id,
        { ...dataToSend, type: 'checkout', blocked: 'false' } ) ),
    toggleLoading,
    toggleInfoAlert,
    deps: [bookingData, selectedNote],
    callback: reloadData,
  } );

  const deleteNoteServices = useDelete( {
    promise: ( noteId ) => BookingItem.deleteNote( bookingData.id, noteId ),
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
    deps: [bookingData],
  } );
  return (
    <>
      <NotesView
        data={bookingData}
        handleOpen={handleOpen}
        onDelete={deleteNoteServices}
      />
      <NotesModalView
        modalOpened={modalOpened}
        handleClose={handleClose}
        onSubmit={submitForm}
        selectedNote={selectedNote}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );
export default connect( mapStateToProps, mapDispatchToProps )( Observations );
