import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import UsefulsEditView from 'views/Useful/Edit';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import AppSurvivalTipService from 'api/appSurvivalTip';
import { history } from 'store';
import TranslationService from 'api/Translation';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import useFetch from 'hooks/useFetch';
import { each } from 'utils/async';

const translations = true;
const type = 'useful'; // advices | reminders | useful

const UsefulsEdit = ( {
  parentPath, match, toggleErrorAlert,
  toggleInfoAlert,
} ) => {
  const entityId = match.params.id;
  const [loadingData, setLoadingData] = useState( false );

  const [data] = useFetch( {
    promise: () => {
      setLoadingData( true );
      return AppSurvivalTipService.getItem( entityId );
    },
    translationPromise: () => TranslationService.getContractSettingTranslations( entityId ),
    format: ( dataToFormat ) => {
      setLoadingData( false );
      return dataToFormat;
    },
    toggleErrorAlert: ( errors ) => {
      setLoadingData( false );
      toggleErrorAlert( errors );
    },
    conditional: !!entityId,
    deps: [entityId],
  } );

  const submitForm = useCallback( async ( formData, form, phoneData ) => {
    setLoadingData( true );

    const dataToSend = formatDataToSend( formData, form, translations );
    const formattedData = {
      ...dataToSend,
      phoneData,
      type,
    };
    delete formattedData.images;
    delete formattedData.pictureTitle;
    delete formattedData.state;

    const [errors, response, itemData] = await handlePromise(
      entityId ? AppSurvivalTipService.updateItem( entityId, formattedData )
        : AppSurvivalTipService.saveItem( formattedData ),
    );
    if ( !response.ok ) {
      setLoadingData( false );
      return errors;
    }

    if ( !dataToSend.images ) {
      setLoadingData( false );
      toggleInfoAlert( 'dataSaved' );
      return history.push( parentPath );
    }

    return new Promise( ( resolve, reject ) => {
      each( dataToSend.images, async ( image, eCb ) => {
        const [imageErrors, imageResponse] = await handlePromise(
          AppSurvivalTipService.saveImage( entityId || itemData.id, { name: 'pictureName', image: image.file } ),
        );
        if ( !imageResponse.ok ) return eCb( imageErrors );
        eCb();
      }, ( error ) => {
        if ( error ) {
          setLoadingData( false );
          return reject( error );
        }
        setLoadingData( false );
        toggleInfoAlert( 'dataSaved' );
        history.push( parentPath );
        resolve();
      } );
    } ).catch( ( error ) => Promise.resolve( error ) );
  }, [entityId, parentPath, toggleInfoAlert, setLoadingData] );

  /** countries, states, cities ******************* */
  const [states, setStates] = useState( [] );
  const [statesLoading, setStatesLoading] = useState( false );
  const [cities, setCities] = useState( [] );
  const [citiesLoading, setCitiesLoading] = useState( false );
  const [statesLoaded, setStatesLoaded] = useState( false );
  const [citiesLoaded, setCitiesLoaded] = useState( false );

  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    reInit: true,
    deps: [],
  } );
  const fetchStates = useCallback( async ( countryId ) => {
    const country = _.find( countries, { id: countryId } );
    if ( !country ) return toggleErrorAlert( 'error' );

    setStates( [] );
    setCities( [] );
    setStatesLoading( true );
    const [errors, response, responseData] = await handlePromise(
      StateService.getStates( country.iso ),
    );
    setStatesLoading( false );

    if ( !response.ok ) return toggleErrorAlert( errors );
    setStates( responseData );
    setCities( [] );
  }, [countries, toggleErrorAlert] );

  const fetchCities = useCallback( async ( stateId, countryId ) => {
    const country = _.find( countries, { id: countryId } );
    const state = _.find( states, { id: stateId } );

    if ( !country || !state ) return toggleErrorAlert( 'error' );

    setCities( [] );
    setCitiesLoading( true );
    const [errors, response, responseData] = await handlePromise(
      CityService.getCities( country.iso, state.iso ),
    );
    setCitiesLoading( false );

    if ( !response.ok ) return toggleErrorAlert( errors );
    setCities( responseData );
  }, [countries, states, toggleErrorAlert] );

  useEffect( () => {
    if ( data && data.country && countries.length > 1
    && !statesLoaded ) {
      setStatesLoaded( true );
      fetchStates( data.country.id );
    }
  }, [data, countries, fetchStates, statesLoaded] );

  useEffect( () => {
    if ( data && data.state && data.country
    && countries.length > 1 && states.length > 1
    && !citiesLoaded ) {
      setCitiesLoaded( true );
      fetchCities( data.state.id, data.country.id );
    }
  }, [data, countries, states, fetchCities, citiesLoaded] );

  /** END countries, states, cities ******************* */

  return (
    <UsefulsEditView
      data={data}
    //  dataImage={dataImage}
      entityId={entityId}
      parentPath={parentPath}
      isLoading={loadingData}
      onSubmit={submitForm}
      countriesLoading={countriesLoading}
      statesLoading={statesLoading}
      citiesLoading={citiesLoading}
      options={{ countries, states, cities }}
      onChangeCountry={fetchStates}
      onChangeState={fetchCities}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( mapStateToProps, mapDispatchToProps )( UsefulsEdit );
