import api from 'config/Api/Api';

const baseEndpoint = '/booking/travel-report';

export default {
  getRegistersOfAuthorities( params ) {
    return api.get( baseEndpoint, params );
  },
  getRegistersOfAuthority( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

};
