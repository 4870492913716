import React, { useState } from 'react';
// import classnames from 'classnames';
import { Container, Card, Button } from 'reactstrap';
import {
  SimpleHeader,
  // HorizontalMenu,
  Intl,
} from 'components';
import Contacts from 'containers/CRM/Contact/Dashboard/Contacts';
/* import Opportunities from 'containers/CRM/Contact/Dashboard/Opportunities';
import NeedFollowUp from 'containers/CRM/Contact/Dashboard/NeedFollowUp';
import InProgress from 'containers/CRM/Contact/Dashboard/InProgress'; */
import ContactNew from 'containers/CRM/Contact/New';

/* const tabsList = [
  { name: 'crm.contacts.allContacts' },
  { name: 'crm.contacts.openOpportunities' },
  { name: 'crm.contacts.needFollowUp' },
  { name: 'crm.contacts.inProgress' },
];
 */
const ContactDashboard = ( {
  path, modalNewContactOpened, toggleModalNewContact, onReloadContacts, reloadContacts,
} ) => {
  // const [selectedTab, setSelectedTab] = useState( 'crm.contacts.allContacts' );

  const [recordsCount, setRecordsCount] = useState( 0 );

  /*   let content = null;
  switch ( selectedTab ) {
    case 'crm.contacts.allContacts':
      content = (
        <Contacts
          path={path}
          onReloadContacts={onReloadContacts}
          reloadContacts={reloadContacts}
          setRecordsCount={setRecordsCount}
        />
      );
      break;
    case 'crm.contacts.openOpportunities':
      content = <Opportunities path={path} />;
      break;
    case 'crm.contacts.needFollowUp':
      content = <NeedFollowUp path={path} />;
      break;
    case 'crm.contacts.inProgress':
      content = <InProgress path={path} />;
      break;
    default:
      //
  } */

  return (
    <>
      <SimpleHeader
        title="crm.contacts"
        /* className={classnames( {
          'app-component-loading': isLoading,
        } )} */
        rightContent={(
          <>
            {/* <Button
              color="primary"
              outline
              // className="mt-2 mt-md-0 ml-2 mr-0"
            >
              <Intl id="crm.contacts.actions" />
            </Button>
            <Button
              color="primary"
              outline
              // className="mt-2 mt-md-0 ml-2 mr-0"
            >
              <Intl id="crm.contacts.import" />
            </Button> */}
            <Button
              color="primary"
              onClick={toggleModalNewContact}
              // className="mt-2 mt-md-0 ml-2 mr-0"
            >
              <Intl id="crm.contacts.createContact" />
            </Button>

          </>
      )}
      />

      <Container fluid>
        <div className="text-sm mb-2">
          {`${recordsCount} `}
          <Intl id="crm.contacts.records" />
        </div>
        <Card className="card-sec-top border-bottom">
          {/* <HorizontalMenu
            tabs={tabsList}
            selected={selectedTab}
            onChangeTab={( index ) => { setRecordsCount( 0 ); setSelectedTab( index ); }}
          /> */}
          {/* <div className="border-top"> */}
          {/* {content} */}
          <Contacts
            path={path}
            onReloadContacts={onReloadContacts}
            reloadContacts={reloadContacts}
            setRecordsCount={setRecordsCount}
          />
          {/* </div> */}
        </Card>
      </Container>
      <ContactNew
        modalNewContactOpened={modalNewContactOpened}
        toggleModalNewContact={toggleModalNewContact}
        reloadContacts={reloadContacts}
      />
    </>
  );
};

export default ContactDashboard;
