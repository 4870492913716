import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import RegionServices from 'api/Region';
import CountryService from 'api/Country';
import { useFetch } from 'hooks';
import { formatDataToSend, handlePromise } from 'utils';
import EditRegionView from 'views/Regions/edit';

const EditRegionContainer = ( {
  elementToEdit,
  onClose,
  onReloadData,
  toggleInfoAlert,
  toggleErrorAlert,
  toggleLoading,
} ) => {
  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    format: ( data ) => data.map( ( { id, name } ) => ( { id, name } ) ),
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const dataToSend = formatDataToSend( formData, form );

      const [dataErrors, dataResponse] = await handlePromise(
        elementToEdit
          ? RegionServices.putRegion( elementToEdit.id, dataToSend )
          : RegionServices.postRegion( dataToSend ),
      );

      toggleLoading( false );
      if ( !dataResponse.ok ) {
        toggleErrorAlert();
        return dataErrors;
      }

      toggleInfoAlert( 'dataSaved' );
      onReloadData();
    },
    [elementToEdit, onReloadData, toggleErrorAlert, toggleInfoAlert, toggleLoading],
  );

  return (
    <EditRegionView
      elementToEdit={elementToEdit}
      countries={countries}
      countriesLoading={countriesLoading}
      submitForm={submitForm}
      onClose={onClose}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( EditRegionContainer );
