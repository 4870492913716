import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const UPDATE_BUSINESS_OPPORTUNITY_STAGE = gql`
mutation UpdateBusinessOpportunityStage($businessStage: String!, $id: String!, $propertyManager: String!) {
  updateBusinessOpportunity(
    id: $id
    businessStage: $businessStage
    propertyManager: $propertyManager
  ) {
    ... on BaseError {
      message
    }
    ... on MutationUpdateBusinessOpportunitySuccess {
      data {
        success
      }
    }
  }
}
`;

const updateBusinessOpportunityStage = async ( vars ) => {
  const props = { ...vars };
  delete props.contact;
  delete props.contactId;

  const { data } = await client.query( {
    query: UPDATE_BUSINESS_OPPORTUNITY_STAGE,
    variables: { ...props, propertyManager: getPropertyManager() },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateBusinessOpportunity.message ) {
    return {
      ok: false,
      message: data.updateBusinessOpportunity.message,
    };
  }

  const updateSuccessful = data.updateBusinessOpportunity.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateBusinessOpportunityStage;
