import OwnerAppEditLegalForm from './Edit/Legal';
import OwnerAppEditModulesForm from './Edit/Modules';
import OwnerAppEditReviewForm from './Edit/Review';
import OwnerAppEditAppearanceForm from './Edit/Appearance';

export default [
  {
    name: 'ownerApp.ownApp.title',
    component: OwnerAppEditAppearanceForm,
  },
  {
    name: 'ownerApp.legal.title',
    component: OwnerAppEditLegalForm,
  },
  {
    name: 'ownerApp.modules.title',
    component: OwnerAppEditModulesForm,
  },
  {
    name: 'ownerApp.review.title',
    component: OwnerAppEditReviewForm,
  },
];
