import React from 'react';
import { Badge } from 'reactstrap';
import { Intl } from 'components';

export default ( {
  colors = {}, color, notTranslated, value = '', ...rest
} ) => {
  const col = color || colors[value] || 'dark';
  return (
    <Badge
      {...rest}
      color={col}
    >
      {notTranslated ? value : ( <Intl id={value} /> ) }
    </Badge>
  );
};
