import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  AsyncTable,
  AddBtn,
  SimpleHeader,
  TableGenericFilter,
  ResourceAvatar,
  Intl,
  TableActionsBtns,
  CanAccess,
  TableSelectFilter,
} from 'components';
import { canView, canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    isLoading,
    defaultFilters,
    onFetchData,
    onDelete,
    onFetchCategories,
    parentPath,
  } ) => {
    const canViewProp = canView( 'cms' );
    const canEditDelete = canModify( 'cms' );
    return (
      <>
        <SimpleHeader title="posts" />
        <Container fluid>
          <AsyncTable
            id="posts"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            disableSorting
            defaultFilters={defaultFilters}
            rightContent={(
              <CanAccess I="add" a="cms">
                <AddBtn title="newPost" route={`${parentPath}/new`} />
              </CanAccess>
            )}
            onFetchData={onFetchData}
            customFilters={( { filters, setFilter } ) => (
              <Row>
                <Col md={8} lg={4}>
                  <TableGenericFilter filters={filters} setFilter={setFilter} />
                </Col>
                <Col md={8} lg={4} className="pl-lg-0 pt-1 pt-lg-0">
                  <TableSelectFilter
                    name="category"
                    placeholder="category"
                    filters={filters}
                    isClearable
                    defaultOptions
                    loadOptions={onFetchCategories}
                    setFilter={setFilter}
                  />
                </Col>
              </Row>
            )}
            columns={[
              {
                Header: 'title',
                accessor: 'title',
                Cell: ( { cell } ) => ( canEditDelete || canViewProp ? (
                  <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                    {cell.value}
                  </Link>
                ) : (
                  cell.value
                ) ),
              },
              {
                Header: 'author',
                accessor: 'owner',
                Cell: ( { cell } ) => <ResourceAvatar data={cell.value} />,
              },
              {
                Header: 'category',
                accessor: 'category.name',
              },
              {
                Header: 'created',
                accessor: 'created',
                Cell: ( { cell } ) => ( cell.value ? moment( cell.value ).format( 'MMM DD, YYYY' ) : '' ),
              },
              {
                Header: 'status',
                accessor: 'status',
                Cell: ( { cell } ) => ( cell.value ? <Intl id={cell.value} /> : '' ),
              },
              canEditDelete
                ? {
                  accessor: 'id',
                  disableSorting: true,
                  Header: null,
                  maxWidth: 65,
                  Cell: ( { cell } ) => (
                    <TableActionsBtns
                      actions={[
                        {
                          isDelete: true,
                          onClick: ( cb ) => onDelete( cell.value, cb ),
                        },
                      ]}
                    />
                  ),
                }
                : {
                  id: 'id',
                  Header: null,
                  maxWidth: 1,
                  disableSorting: true,
                },
            ]}
          />
        </Container>
      </>
    );
  },
);
