import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const CREATE_PIPELINE = gql`
mutation CreatePipeline($description: String!, $name: String!, $stages: [String!]!, $propertyManager: String!) {
  createPipeline(description: $description, name: $name, stages: $stages, propertyManager: $propertyManager) {
    ... on BaseError {
      message
    }
    ... on MutationCreatePipelineSuccess {
      data {
        success
      }
    }
  }
}
`;

const createPipeline = async ( props ) => {
  const { data } = await client.query( {
    query: CREATE_PIPELINE,
    variables: { ...props, propertyManager: getPropertyManager() },
    fetchPolicy: 'no-cache',
  } );

  if ( data.createPipeline.message ) {
    return {
      ok: false,
      message: data.createPipeline.message,
    };
  }

  const createSuccessful = data.createPipeline.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
    data: { ...data.createPipeline.data },
  };
};

export default createPipeline;
