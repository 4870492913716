import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import storage from 'redux-persist/lib/storage';

import omit from 'lodash/omit';
import reducers from './reducers';
// process.env.NODE_ENV

const history = createBrowserHistory( {
  basename: process.env.PUBLIC_URL,
} );
const routeMiddleware = routerMiddleware( history );
const middlewares = [routeMiddleware];

const blacklistTransform = createTransform(
  ( inboundState, key ) => {
    if ( key === 'settings' ) {
      return omit( inboundState, ['isLoading'] );
    }
    return inboundState;
  },
);

const persistConfig = {
  key: 'nomads',
  whitelist: ['user', 'settings', 'filters'],
  storage,
  transforms: [blacklistTransform],
};
const persistedReducer = persistReducer( persistConfig, reducers( history ) );

export default function configureStore() {
  const store = createStore(
    persistedReducer,
    composeWithDevTools( applyMiddleware( ...middlewares ) ),
  );
  const persistor = persistStore( store );

  return { store, persistor };
}

export { history };
