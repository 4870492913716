import React, { useState, useCallback, useRef } from 'react';
import AccessRequestView from 'views/AccessRequest';
import { connect } from 'react-redux';
import moment from 'moment';

import _get from 'lodash/get';
import SettingsActions from 'store/reducers/Settings';
import useFetchTableData from 'hooks/useFetchTableData';
import AccessRequestService from 'api/AccessRequest';
import AccommodationService from 'api/Accommodation';
import useDelete from 'hooks/useDelete';
import EditModal from './Edit';

const AccessRequest = ( { toggleErrorAlert, toggleLoading } ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [modalOpened, setModalOpened] = useState( false );
  const toggleModal = () => setModalOpened( ( state ) => !state );
  const toEdit = useRef( null );

  const reload = () => {
    setModalOpened( false );
    setDataSubmitted( new Date().valueOf() );
  };

  const dateRange = useRef( moment.range(
    moment().startOf( 'day' ),
    moment().startOf( 'day' ).add( '1', 'day' ),
  ) );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AccessRequestService.getAccessRequests( {
      ...params,
      accommodationId: _get( params, 'accommodationId.value' )
        ? params.accommodationId.value
        : undefined,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
    } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const getTodaysAccessRequest = () => {
    dateRange.current = moment.range(
      moment().startOf( 'day' ),
      moment().startOf( 'day' ).add( '1', 'day' ),
    );
    reload();
  };

  const updateDateRangeParams = useCallback( ( params ) => {
    dateRange.current = params;
    reload();
  }, [] );

  const deleteAccessRequest = useDelete( {
    promise: ( { taskId, id } ) => AccessRequestService.deleteAccessRequest(
      taskId, id,
    ),
    callback: reload,
    toggleLoading,
    toggleErrorAlert,
  } );

  const openEditModal = useCallback( ( value ) => {
    toEdit.current = value;
    toggleModal( );
  }, [] );

  return (
    <>
      <AccessRequestView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        onFetchAccommodations={fetchAccommodations}
        onTodaysAccessRequest={getTodaysAccessRequest}
        initialDates={dateRange.current}
        onDateChanged={updateDateRangeParams}
        onDelete={deleteAccessRequest}
        onOpenEditModal={openEditModal}
      />
      <EditModal
        modalOpened={modalOpened}
        toEdit={toEdit.current}
        onCloseModal={toggleModal}
        onReload={reload}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );
export default connect( null, mapDispatchToProps )( AccessRequest );
