import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const UPDATE_OCCUPANCY = gql`
  mutation UpdateOccupancy($name: String!, $occupancyId: ID!) {
    updateOccupancy(name: $name, occupancyId: $occupancyId) {
      ... on BaseError {
        message
      }
      ... on MutationUpdateOccupancySuccess {
        data {
          success
        }
      }
    }
  }
`;

const updateOccupancy = async ( { name, occupancyId } ) => {
  const { data } = await client.query( {
    query: UPDATE_OCCUPANCY,
    variables: {
      name,
      occupancyId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateOccupancy.message ) {
    return {
      ok: false,
      message: data.updateOccupancy.message,
    };
  }
  const updateSuccessful = data.updateOccupancy.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateOccupancy;
