import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import BookingService from 'api/BookingItem';
import BookingEditView from 'views/Booking/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import _ from 'lodash';
import { formatFloat } from 'utils';

const BookingEdit = ( {
  toggleErrorAlert, parentPath, match, location,
} ) => {
  const bookingId = match.params.id;
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => BookingService.getBookingItem( bookingId ),
    toggleErrorAlert,
    conditional: !!bookingId,
    deps: [bookingId, dataSubmitted],
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      payments: _.map( _.get( dataToFormat, 'payments', [] ),
        ( item ) => ( { ...item, amount: formatFloat( item.amount ) } ) ),
    } ),

  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  return (
    <BookingEditView
      data={data}
      pathname={location.pathname}
      pathLayout={match.url}
      parentPath={parentPath}
      isLoading={dataLoading}
      onReloadData={reloadData}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( BookingEdit );
