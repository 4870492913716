import React from 'react';

import { ChatBookingOverview } from 'components';
import classnames from 'classnames';
import Drawer from 'rc-drawer';

export default ( {
  open,
  onClose,
  onOpenTaskEditModal,
  data,
  isLoading,
} ) => (
  <Drawer
    open={open}
    placement="right"
    handler={false}
    level={null}
    onClose={onClose}
    width={320}
    className={classnames( { 'app-component-loading': isLoading } )}
  >

    <ChatBookingOverview
      data={isLoading ? {} : data}
      onOpenTaskEditModal={onOpenTaskEditModal}
    />
  </Drawer>
);
