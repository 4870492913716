import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  reloadedAt: null,
  editModalOpened: false,
  taskId: null,
  taskToEditId: null,
  taskToEditData: null,
};

const { Types, Creators } = createActions( {
  reloadTaskPeriodic: ['id'],
  toggleEditModalTaskPeriodic: ['id', 'data'],
} );

const reloadTaskPeriodic = ( state, { id } ) => ( {
  ...state,
  taskId: id,
  reloadedAt: new Date().valueOf(),
} );

const toggleEditModalTaskPeriodic = ( state, { id, data } ) => ( {
  ...state,
  taskToEditId: id,
  taskToEditData: data,
  editModalOpened: !state.editModalOpened,
} );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.RELOAD_TASK_PERIODIC]: reloadTaskPeriodic,
  [Types.TOGGLE_EDIT_MODAL_TASK_PERIODIC]: toggleEditModalTaskPeriodic,
} );

export const TaskTypes = Types;
export default Creators;
