import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
} from 'reactstrap';

import {
  Auth, Form, TextField, Intl,
} from 'components';
import { composeValidators, required, equalTo } from 'config/InputErrors';

export default React.memo( ( { onSubmit } ) => (
  <Auth
    header={(
      <h1 className="mb-0 font-weight-500 text-dark-2 mt-2">
        <Intl id="resetPasswordTitle" />
      </h1>
    )}
    msg={<Intl id="resetPasswordMsg" />}
  >
    <Form
      onSubmit={onSubmit}
      showErrorsInFooter
    >
      <Row>
        <Col md={9} className="m-auto">
          <TextField
            field="password"
            type="password"
            validate={required}
            placeholder="password"
            size="md"
          />

          <TextField
            field="passwordConfirmation"
            type="password"
            validate={composeValidators( required, equalTo( 'password' ) )}
            translateValues={{ label: 'password' }}
            placeholder="repeatPassword"
            size="md"
          />
        </Col>
      </Row>

      <Row>
        <Col md={9} className="mt-3 mx-auto">
          <Button color="primary" className="text-md" block>
            <Intl id="reset" />
          </Button>

          <div className="text-center pt-3">
            <Link to="/auth/login" className="btn-outline-link p-2">
              <Intl id="goToLogin" />
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  </Auth>
) );
