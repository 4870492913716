import React from 'react';

import { Badge } from 'reactstrap';

import { DetailCard, Intl, CopyToClipboard } from 'components';

const BadgeClip = ( { text } ) => (
  <li>
    <CopyToClipboard text={text}>
      <Badge color="light" className="border mb-2 mr-2 text-darker">{text}</Badge>
    </CopyToClipboard>
  </li>
);

export default () => (
  <DetailCard>
    <h3>Variables</h3>
    <small>
      <Intl id="varTexts" />
    </small>
    <h4 className="mt-3"><Intl id="customers" /></h4>
    <ul className="badge-vars">
      <BadgeClip text="{{name}}" />
      <BadgeClip text="{{lastname}}" />
      <BadgeClip text="{{email}}" />
      <BadgeClip text="{{unique_code}}" />
      <BadgeClip text="{{temporal_password}}" />
    </ul>

    <h4 className="mt-3"><Intl id="booking" /></h4>
    <ul className="badge-vars">
      <BadgeClip text="{{checkin_day}}" />
      <BadgeClip text="{{checkout_day}}" />
      <BadgeClip text="{{checkin_time}}" />
      <BadgeClip text="{{checkout_time}}" />
      <BadgeClip text="{{booking_cost}}" />
      <BadgeClip text="{{guests_number}}" />
      <BadgeClip text="{{localizator}}" />
      <BadgeClip text="{{booking_id}}" />
    </ul>
    <h4 className="mt-3"><Intl id="accommodation" /></h4>
    <ul className="badge-vars">
      <BadgeClip text="{{accommodation_name}}" />
      <BadgeClip text="{{accommodation_number}}" />
      <BadgeClip text="{{accommodation_floor}}" />
      <BadgeClip text="{{accommodation_block}}" />
    </ul>

    <h4 className="mt-3"><Intl id="roomType" /></h4>
    <ul className="badge-vars">
      <BadgeClip text="{{roomtype_name}}" />
    </ul>

    <h4 className="mt-3"><Intl id="property" /></h4>
    <ul className="badge-vars">
      <BadgeClip text="{{property_name}}" />
      <BadgeClip text="{{property_address}}" />
      <BadgeClip text="{{property_address_number}}" />
      <BadgeClip text="{{property_postalCode}}" />
      <BadgeClip text="{{city}}" />
    </ul>

    <h4 className="mt-3"><Intl id="payments" /></h4>
    <ul className="badge-vars">
      <BadgeClip text="{{payment_link}}" />
    </ul>
  </DetailCard>
);
