import React from 'react';
import { Intl } from 'components';
import { Modal, ListGroup } from 'reactstrap';
import LegendItem from './components/LegendItem';

export default React.memo( ( {
  open, onClose,
} ) => (
  <Modal
    className="modal-dialog-centered app-modal"
    size="sm"
    isOpen={open}
    zIndex={10000}
  >
    <div className="modal-header border py-3">
      <span className="modal-title text-uppercase">
        <Intl id="legend" />
      </span>
      <button
        aria-label="Close"
        className="close text-sm"
        data-dismiss="modal"
        type="button"
        onClick={onClose}
      >
        <i className="app-icon-close" />
      </button>
    </div>
    <>
      <ListGroup>
        <LegendItem id="booking:future" color="future" />
        <LegendItem id="booking:current" color="present" />
        <LegendItem id="booking:past" color="past" />
        <LegendItem id="booking:blocked" color="blocked" />
      </ListGroup>
    </>

  </Modal>
) );
