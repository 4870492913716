/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { FormGroup, InputGroup } from 'reactstrap';
import Select from 'react-select';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { Intl } from 'components';
import InputGroupAddon from '../InputGroupAddon';
import {
  customStyles, IconOption, IconSingleValue, Clear,
} from '../SelectCustomComponents';

const SelectInput = React.memo( ( {
  label, formGroupClass, isInvalid, inputName, prependIcon, prependIconClass, appendIcon,
  appendIconClass, multiple, placeholder, size = 'md', value, onChange, inputOnChange, onInputChange, noFilterByInput, labelKey = 'name',
  options = [], idKey = 'id', translateOptions, intl, meta, translateValues, labelClass, optgroup, enableReinitialize = false, toggleHelpModal,
  overlap, ...rest
} ) => {
  const [focused, setFocused] = useState( false );

  const selectRef = useRef( null );

  useEffect( () => {
    if ( enableReinitialize ) {
      if ( options.length === 0 ) {
        if ( selectRef.current ) {
          selectRef.current.select.clearValue();
        }
      }
    }
  }, [options, enableReinitialize] );

  const primitiveValue = ( data ) => {
    const simpleValue = ( data && _.get( data, idKey ) ? _.get( data, idKey ) : data );
    if ( optgroup && _.isObject( simpleValue ) ) {
      return _.get( simpleValue, 'value' );
    }
    return simpleValue;
  };
  const findOption = ( val ) => {
    if ( optgroup ) {
      const optOptions = _.reduce( options, ( acu, opt ) => ( [...acu, ...opt.options] ), [] );
      return _.find( optOptions, ( o ) => _.get( o, idKey ) === val ) || {};
    }
    return _.find( options, ( o ) => _.get( o, idKey ) === val ) || {};
  };

  const formatMultipleValues = () => _.map( value, ( item ) => {
    const simpleValue = primitiveValue( item );
    const option = findOption( simpleValue );
    return {
      value: simpleValue,
      // eslint-disable-next-line no-nested-ternary
      label: translateOptions ? _.get( option, labelKey )
        ? intl.formatMessage( { id: _.get( option, labelKey ) } )
        : _.get( option, labelKey )
        : _.get( option, labelKey ),
      icon: option.icon,
    };
  } );

  const formatSingleValue = () => {
    const simpleValue = primitiveValue( value );
    const option = findOption( simpleValue );
    return {
      value: simpleValue,
      // eslint-disable-next-line no-nested-ternary
      label: translateOptions ? _.get( option, labelKey )
        ? intl.formatMessage( { id: _.get( option, labelKey ) } )
        : _.get( option, labelKey )
        : _.get( option, labelKey ),
      icon: option.icon,
    };
  };

  const getValue = ( val ) => ( multiple ? _.map( val, 'value' ) : val.value );

  const formatOption = ( opt ) => ( {
    value: _.get( opt, idKey ),
    // eslint-disable-next-line no-nested-ternary
    label: translateOptions
      ? _.get( opt, labelKey )
        ? intl.formatMessage( { id: _.get( opt, labelKey ) } )
        : _.get( opt, labelKey )
      : _.get( opt, labelKey ),
    icon: opt.icon,
  } );

  return (
    <FormGroup
      className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}
    >
      {label && (
        <div>
          <label className={classnames( labelClass, 'form-control-label' )}>
            <Intl id={label} />
          </label>
          {toggleHelpModal && (
          <button
            type="button"
            className="btn btn-primary-link mt-n1 pt-0 pb-0"
            onClick={toggleHelpModal}
          >
            <i className="fas fa-question-circle" />
          </button>
          )}
        </div>
      )}
      <InputGroup
        data-cy={`input-${inputName}`}
        className={classnames( 'input-group-merge', {
          prepend: prependIcon || prependIconClass,
          append: appendIcon || appendIconClass,
          focused,
        } )}
      >
        {prependIcon || prependIconClass ? (
          <InputGroupAddon
            addonType="prepend"
            isInvalid={isInvalid}
            icon={prependIcon}
            iconClass={prependIconClass}
          />
        ) : null}

        <Select
          ref={selectRef}
          {...rest}
          isMulti={multiple}
          placeholder={
            placeholder ? intl.formatMessage( { id: placeholder } ) : null
          }
          onFocus={() => setFocused( true )}
          onBlur={() => setFocused( false )}
          styles={customStyles}
          classNamePrefix="custom-select"
          className={classnames( 'custom-select-container form-control', {
            'custom-select-container-higher': overlap,
            'is-invalid': isInvalid,
            'form-control-lg': size === 'lg',
            'form-control-sm': size === 'sm',
          } )}
          onChange={( val ) => {
            if ( multiple || !val || val.value !== value ) {
              const valueVal = val ? getValue( val ) : null;
              if ( inputOnChange ) inputOnChange( valueVal );
              if ( onChange ) onChange( valueVal );
            }
          }}
          onInputChange={onInputChange}
          filterOption={noFilterByInput ? () => true : undefined}
          value={
            multiple ? formatMultipleValues() : value && formatSingleValue()
          }
          options={_.map( options,
            ( option ) => {
              if ( !_.isEmpty( option.options ) && optgroup ) {
                const { options: opts, ...restOpt } = option;
                return {
                  ...formatOption( restOpt ),
                  options: _.map( opts, formatOption ),
                };
              }
              return formatOption( option );
            } )}
          components={{
            Option: IconOption,
            SingleValue: IconSingleValue,
            ClearIndicator: Clear,
          }}
        />

        {appendIcon || appendIconClass ? (
          <InputGroupAddon
            addonType="append"
            isInvalid={isInvalid}
            icon={appendIcon}
            iconClass={appendIconClass}
          />
        ) : null}
      </InputGroup>
      {isInvalid && (
        <div className="invalid-feedback">
          <Intl
            id={meta.error || meta.submitError}
            values={translateValues}
          />
        </div>
      )}
    </FormGroup>
  );
} );

export default injectIntl( SelectInput );
