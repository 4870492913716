import React from 'react';
import { Button, Modal, Badge } from 'reactstrap';

import { Intl, Form, TextField } from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  type, lockerStatus, open, onConfirm, onClose, onLockDoor,
} ) => (
  <Modal
    className="modal-dialog-centered app-modal"
    size="sm"
    isOpen={open}
    zIndex={10000}
  >
    <div className="modal-header border py-3">
      <span className="modal-title text-gray-1 text-uppercase">
        <Intl id="door" />
        {': '}
        <Intl id={type} />
      </span>
      <button
        aria-label="Close"
        className="close text-sm"
        data-dismiss="modal"
        type="button"
        onClick={onClose}
      >
        <i className="app-icon-close" />
      </button>
    </div>
    <Form
      onSubmit={onConfirm}
      validate={( values ) => {
        const errors = {};
        if ( values.confirmWord && values.confirmWord !== 'OPEN' ) {
          errors.confirmWord = 'typeOpenConfirm';
        }
        return errors;
      }}
    >
      <div className="modal-body pt-2 pb-0">
        {lockerStatus.length > 0 && (
        <p className="font-weight-500 mb-0">
          <Intl id="currentStatus" />
        </p>
        )}
        {lockerStatus.length ? (
          <p className="font-weight-500 mb-2">
            { lockerStatus === 1 && <Badge color="success" className="px-2"><Intl id="locked" /></Badge>}
            { lockerStatus === 3 && <Badge color="warning" className="px-2"><Intl id="unlocked" /></Badge>}
            { lockerStatus === 5 && <Badge color="danger" className="px-2"><Intl id="opened" /></Badge>}
            { lockerStatus === 0 && <Badge color="danger" className="px-2"><Intl id="uncalibrated" /></Badge>}
            { lockerStatus === 254 && <Badge color="danger" className="px-2"><Intl id="motorBlocked" /></Badge>}
            { lockerStatus === 255 && <Badge color="danger" className="px-2"><Intl id="undefined" /></Badge>}
          </p>
        ) : null}
        <p className="text-gray-1 m-0">
          <Intl id="typeOpenConfirm" />
        </p>
        <TextField
          formGroupClass="m-0 input-text-uppercase"
          field="confirmWord"
          validate={required}
          placeholder="typeHere"
        />
      </div>

      <div className="modal-footer d-block text-right">
        <p className="font-weight-500 mb-2">
          {type === 'accommodation' && <Intl id="whichActionDoYouWannaPerform" />}
          {type === 'property' && <Intl id="buildingDoorWillUnlock" />}
          {type === 'parking' && <Intl id="parkingDoorWillUnlock" />}
        </p>
        <div className="d-flex justify-content-end">
          {type === 'accommodation' && (
            <Button
              color="success"
              size="app-sm"
              className="text-uppercase text-xsm"
              onClick={onLockDoor}
              disabled={lockerStatus === 1}
            >
              <Intl id="lockDoor" />
            </Button>
          )}
          <Button color="danger" size="app-sm" className="text-uppercase text-xsm">
            <Intl id="openDoor" />
          </Button>
        </div>
      </div>
    </Form>
  </Modal>
) );
