/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Row, Col } from 'reactstrap';

import { AsyncSelect, Select } from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    form,
    fieldCity,
    fieldZone,
    onFetchCities,
    onChangeCity,
    zones,
    isZonesLoading,
    allowDelete,
    onDelete,
    children,
  } ) => (
    <Row>
      <Col sm={5}>
        <AsyncSelect
          field={fieldCity}
          label="cities"
          defaultOptions
          loadOptions={onFetchCities}
          labelKey={( option ) => option.city.name}
          onChange={( cityId ) => {
            form.change( fieldZone, null );
            onChangeCity( cityId );
          }}
          validate={required}
        />
      </Col>
      <Col xs={10} sm={5}>
        <Select
          field={fieldZone}
          label="zones"
          isLoading={isZonesLoading}
          options={zones}
          multiple
        />
      </Col>
      {allowDelete && (
        <Col xs={2} sm={1} className="d-flex align-items-center">
          <button type="button" className="btn btn-link" onClick={onDelete}>
            <i className="fas fa-minus-circle text-lg" />
          </button>
        </Col>
      )}
      {children}
    </Row>
  ),
);
