import React, {
  useEffect, useRef, useState,
} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import ResourceDashboardView from 'views/Team/Edit/Members';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import { useFetchTableData } from 'hooks';
import { roles } from 'config/Constants';
import { handlePromise } from 'utils';
import RoleService from 'api/Role';
import TeamService from 'api/Team';

const ResourceDashboard = ( {
  // data,
  entityId,
  userRole,
  defaultFilters,
  toggleErrorAlert,
  updateFilters,
  setFiltersError,
} ) => {
  const [roleOptions, setRoleOptions] = useState( [] );
  const initialReloadError = useRef( true );
  // const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => TeamService.getMembers( entityId, params ),
    toggleErrorAlert,
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'team-members', {
        keyword: params.keyword,
        roles: params.roles,
      } );
    },
    conditional: Boolean( entityId ),
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'team-members' );
        initialReloadError.current = false;
      }
    },
    // deps: [dataSubmitted],
  } );

  useEffect( () => {
    const getRoles = async () => {
      const [errors, response, responseData] = await handlePromise(
        RoleService.getRoles( { elementsPerPage: 1000 } ),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setRoleOptions( responseData.data );
    };
    getRoles();
  }, [toggleErrorAlert] );

  return (
    <>
      <ResourceDashboardView
        data={dataTable}
        options={{ roles: roleOptions }}
        isNomadsAdmin={userRole === roles.nomadsAdmin.id}
        isLoading={dataLoading}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
      />
    </>
  );
};

const mapStateToProps = ( { user, filters } ) => ( {
  userRole: user.mainRole,
  defaultFilters: _.get( filters, 'filters.resources' ) || {},
} );
const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( ResourceDashboard );
