import React from 'react';
import { ModalInfo } from 'components';
import { canModify } from 'config/Ability';
import classNames from 'classnames';

const Shift = ( {
  start, ends, teamName,
  onPressDelete,
  onPressShift,
  shiftStatus,
  hasTasks,
} ) => (
  <div
    className={classNames( 'mshift position-relative rounded m-1 p-2', { [`mshift--${shiftStatus}`]: shiftStatus } )}
    style={{
      width: 200,
    }}
    onClick={onPressShift}
  >
    <div className="row justify-content-between align-items-center">
      <div className="col-auto">{`${start} - ${ends}`}</div>
      {hasTasks ? (
        <div className="col-auto">
          <div className="dshift-task-icon">T</div>
        </div>
      ) : null}
    </div>
    <div
      className="rounded p-1 mt-1 mb-1 d-flex justify-content-between"
      style={{
        background: '#f9b586',
      }}
    >
      {teamName}
      <span className="icon-delete">
        <div onClick={( e ) => {
          e.stopPropagation();
          onPressDelete();
        }}
        >
          <i className="fas fa-trash" />
        </div>
      </span>
    </div>
  </div>
);

export default React.memo( ( {
  data, shifts, open,
  onPressDelete,
  onClose, onPressShift,
} ) => (
  <ModalInfo
    title="shifts"
    open={open}
    size="lg"
    bodyClassName="overflow-visible"
    bodyStyle={{ minHeight: 150 }}
    onClose={onClose}
    showErrorsInFooter
    initialValues={data}
    onSubmit={() => {}}
    formEnabled={canModify( 'shift' )}
  >
    <h3>Seleccione un turno</h3>
    <div className="d-flex flex-wrap">
      {shifts.map(
        ( shift ) => (
          <Shift
            key={shift.id}
            {...shift}
            onPressDelete={() => onPressDelete( shift.shiftId )}
            onPressShift={() => onPressShift( shift )}
            hasTasks={shift?.task.length > 0}
          />
        ),
      )}
    </div>
  </ModalInfo>
) );
