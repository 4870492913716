import React, { useState, useEffect, useCallback } from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerPos from './marker';

export default React.memo( ( {
  lat = 40.41088379489902, lng = -3.680340691309252,
  zoom = 11, onChange = () => { },
} ) => {
  const [draggableMap, setDraggableMap] = useState( true );
  const [centerMap, setCenterMap] = useState( [lat, lng] );
  const [zoomMap, setZoomMap] = useState( zoom );
  const [latMarker, setLatMarker] = useState( lat );
  const [longMarker, setLongMarker] = useState( lng );

  useEffect( () => {
    const latNum = parseFloat( lat );
    const lngNum = parseFloat( lng );
    setCenterMap( [latNum, lngNum] );
    setLatMarker( latNum );
    setLongMarker( lngNum );
  }, [lat, lng] );

  useEffect( () => {
    setZoomMap( zoom );
  }, [zoom] );

  const onCircleMouseDown = useCallback( ( ) => {
    setDraggableMap( false );
  }, [] );

  const onCircleMouseMove = useCallback( ( childKey, childProps, mouse ) => {
    setLatMarker( mouse.lat );
    setLongMarker( mouse.lng );
  }, [] );

  const onCircleMouseUp = useCallback( ( childKey, childProps, mouse ) => {
    onChange( [mouse.lat, mouse.lng] );
    setDraggableMap( true );
  }, [onChange] );

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
        draggable={draggableMap}
        center={centerMap}
        zoom={zoomMap}
        onChildMouseDown={onCircleMouseDown}
        onChildMouseMove={onCircleMouseMove}
        onChildMouseUp={onCircleMouseUp}
      >
        <MarkerPos
          lat={latMarker}
          lng={longMarker}
        />
      </GoogleMapReact>
    </div>
  );
} );
