import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { AsyncTable, Intl } from 'components';
import formatBookinglId from 'utils/bookingId';
import ability from 'config/Ability';
import formatAccomodationHours from './utils';

const TaskBookingsLog = ( { data } ) => {
  const [hours, setHours] = useState( [] );
  useEffect( () => {
    if ( data.accommodationsHours ) {
      const _hours = formatAccomodationHours( data.accommodationsHours );
      setHours( _hours );
    }
  }, [data] );

  return (
    <AsyncTable
      id="plugins"
      data={hours}
      total={hours.length}
      isLoading={false}
      onFetchData={() => {}}
      disableSorting
      useElementsPerPage={false}
      columns={[
        {
          Header: '#',
          accessor: 'id',
          Cell: ( { cell } ) => (
            <>
              {ability.can( 'view', 'booking' )
                ? (
                  <Link to={`${'/bookings/list'}/${cell.row.original.id}`} className="text-uppercase">
                    {formatBookinglId( { id: cell.value } )}
                  </Link>
                ) : (
                  <span>
                    {formatBookinglId( { id: cell.value } )}
                  </span>
                )}
            </>
          ),
        },
        {
          Header: 'action',
          accessor: 'type',
          Cell: ( { cell } ) => <Intl id={cell.value} />,
        },
        {
          Header: 'date',
          accessor: 'date',
          Cell: ( { cell } ) => ( cell.value
            ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' )
            : '' ),
        },
      ]}
    />
  );
};

export default TaskBookingsLog;
