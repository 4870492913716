import React from 'react';
import { connect } from 'react-redux';

import StateService from 'api/State';
import StateEditView from 'views/Country/State/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';

const StateEdit = ( {
  countryId, stateId, modalOpened,
  onCloseModal, onReload, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => StateService.getState( countryId, stateId ),
    toggleErrorAlert,
    conditional: !!stateId && modalOpened,
    deps: [stateId, countryId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( stateId
      ? StateService.updateState( countryId, stateId, dataToSend )
      : StateService.saveState( countryId, dataToSend ) ),
    toggleLoading,
    toggleInfoAlert,
    callback: onReload,
    deps: [stateId, countryId],
  } );

  return (
    <StateEditView
      data={data}
      stateId={stateId}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( StateEdit );
