import React from 'react';
import { initializeApp } from 'firebase/app';
import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
  getString,
  getBoolean,
} from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: 'AIzaSyDkrdxxpYdNiFIU-Ab-NN4NR8PsMer7ukM',
  authDomain: 'nomads-software.firebaseapp.com',
  databaseURL: 'https://nomads-software.firebaseio.com',
  projectId: 'nomads-software',
  storageBucket: 'nomads-software.appspot.com',
  messagingSenderId: '195984189039',
  appId: '1:195984189039:web:f5a8de18e5b0c7452f8f9a',
  measurementId: 'G-CCGNL7EH34',
};

// Initialize Firebase
const app = initializeApp( firebaseConfig );
const remoteConfig = getRemoteConfig( app );

const FlagsContext = React.createContext( {} );

// For development only
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

const FlagsProvider = ( { defaults = {}, children } ) => {
  const [flags] = React.useState( defaults );
  const [isLoading, setIsLoading] = React.useState( true );

  React.useEffect( () => {
    remoteConfig.defaultConfig = defaults;
    setIsLoading( true );
    fetchAndActivate( remoteConfig )
      // eslint-disable-next-line no-console
      .then( () => getAll( remoteConfig ) ).catch( ( e ) => console.log( 'Error feature flag', e ) )
      .finally( () => {
        setIsLoading( false );
      } );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] );

  if ( isLoading ) {
    return null;
  }
  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};

export default FlagsProvider;

const formatFlags = ( flags = '' ) => flags.replaceAll( ' ', '' ).split( ',' );
export const getFlags = ( key = '' ) => {
  const enabled = getString( remoteConfig, `enabled_manager_${key}` );
  const disabled = getString( remoteConfig, `disabled_manager_${key}` );
  const active = getBoolean( remoteConfig, `active_manager_${key}` );
  return {
    enabled: formatFlags( enabled ),
    disabled: formatFlags( disabled ),
    active,
  };
};
