import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const UPDATE_SEASON_OCCUPANCY = gql`
  mutation UpdateSeasonOccupancy($input: UpdateSeasonOccupancyInput!) {
    updateSeasonOccupancy(input: $input) {
      ... on BaseError {
        message
      }
      ... on MutationUpdateSeasonOccupancySuccess {
        data {
          success
        }
      }
    }
  }
`;

const updateSeasonOccupancy = async ( input ) => {
  const { data } = await client.query( {
    query: UPDATE_SEASON_OCCUPANCY,
    variables: {
      input: {
        season: input,
      },
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateSeasonOccupancy.message ) {
    return {
      ok: false,
      message: data.updateSeasonOccupancy.message,
    };
  }
  const updateSuccessful = data.updateSeasonOccupancy.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateSeasonOccupancy;
