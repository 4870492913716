import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import classnames from 'classnames';

import {
  TextField,
  Form,
  Datepicker,
  ImagesDropzoneIndex,
  Intl,
  AsyncSelect,
  Select,
  TranslationLayout,
  SimpleHeader,
  EditCard,
  FormActionsBtns,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import moment from 'moment';
import { getResourceName } from 'utils';
import { eventStatuses } from 'config/Constants';

export default React.memo(
  ( {
    eventId,
    data,
    isLoading,
    onSubmit,
    onAddImage,
    onDeleteImage,
    onFetchCustomers,
    parentPath,
    onFetchCities,
    onFetchProperties,
    regions, regionsLoading,
  } ) => (
    <>
      <SimpleHeader parentLinks={[{ name: 'community', href: parentPath }]} />

      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <EditCard title="newEvent">
          <Form
            initialValues={data}
            showErrorsInFooter
            isLoading={isLoading}
            onSubmit={canModify( 'event' ) ? onSubmit : () => {}}
            body={( form ) => (
              <TranslationLayout
                translationFields={( locale ) => (
                  <>
                    <Row>
                      <Col md={6}>
                        <TextField
                          field={`translations.${locale}.name`}
                          label="name"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          field={`translations.${locale}.description`}
                          label="description"
                          type="textarea"
                          rows={5}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              >
                <Row>
                  <Col md={5}>
                    <TextField field="name" label="name" validate={required} />
                  </Col>
                  <Col md={3}>
                    <Datepicker
                      field="date"
                      label="date"
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      utc
                      validate={required}
                      startDateField={{ value: moment() }}
                    />
                  </Col>
                  <Col md={4}>
                    <Select
                      field="region"
                      label="region"
                      options={regions}
                      isLoading={regionsLoading}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={8}>
                    {!eventId ? (
                      <AsyncSelect
                        field="participants"
                        label="participants"
                        labelKey={getResourceName}
                        multiple
                        defaultOptions
                        loadOptions={onFetchCustomers}
                      />
                    ) : (
                      <Select
                        field="status"
                        label="status"
                        validate={required}
                        options={eventStatuses}
                        translateOptions
                      />
                    )}
                  </Col>
                  <Col md={3}>
                    <TextField
                      field="capacity"
                      label="capacity"
                      type="number"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <AsyncSelect
                      field="city"
                      label="city"
                      defaultOptions
                      isClearable
                      loadOptions={onFetchCities}
                      labelKey={( option ) => option.city.name}
                      idKey="city.id"
                    />
                  </Col>
                  <Col md={8}>
                    <AsyncSelect
                      field="properties"
                      label="property"
                      defaultOptions
                      multiple
                      loadOptions={onFetchProperties}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <TextField
                      field="meetingPoint"
                      label="meetingPoint"
                      type="textarea"
                      rows={2}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      field="description"
                      label="description"
                      type="textarea"
                      rows={5}
                      validate={required}
                    />
                  </Col>
                </Row>

                <p className="mb-1 form-control-label">
                  <Intl id="images" />
                </p>
                <ImagesDropzoneIndex
                  form={form}
                  onAddImage={onAddImage}
                  onDeleteImage={onDeleteImage}
                />
              </TranslationLayout>
            )}
            footer={<FormActionsBtns cancelTo={parentPath} />}
          />
        </EditCard>
      </Container>
    </>
  ),
);
