import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import PostDashboardView from 'views/Post/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import PostService from 'api/Post';
import { useDelete, useFetchTableData } from 'hooks';
import CategoryService from 'api/Category';

const PostDashboard = ( {
  defaultFilters,
  toggleErrorAlert,
  toggleLoading,
  updateFilters,
  setFiltersError,
  parentPath,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => PostService.getPosts( {
      ...params,
      category: _.get( params, 'category.value' )
        ? params.category.value
        : undefined,
    } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'posts', {
        keyword: params.keyword,
        category: params.category
          ? { value: params.category.value, label: params.category.label }
          : null,
      } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'posts' );
        initialReloadError.current = false;
      }
    },
  } );

  const deleteEntity = useDelete( {
    promise: PostService.deletePost,
    callback: () => {
      setDataSubmitted( new Date().valueOf() );
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const fetchCategories = useCallback(
    ( query ) => CategoryService.getCategories( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  return (
    <PostDashboardView
      data={data}
      isLoading={dataLoading}
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
      onDelete={deleteEntity}
      onFetchCategories={fetchCategories}
      parentPath={parentPath}
    />
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.posts' ) || {},
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( PostDashboard );
