import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import {
  FormGroup, InputGroup,
} from 'reactstrap';

import { Intl } from 'components';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import DatePicker from 'react-multi-date-picker';
import InputGroupAddon from '../InputGroupAddon';
import 'react-multi-date-picker/styles/colors/red.css';

const DatepickerInput = React.memo(
  ( {
    label,
    position,
    value,
    inputOnChange,
    inputName,
    size = 'md',
    onChange,
    placeholder,
    prependIcon,
    appendIcon,
    intl,
    translateValues,
    isInvalid,
    focused,
    meta,
    startDateField,
    endDateField,
    formChange,
    translate = true,
    formGroupClass,
    labelClass,
    prependIconClass,
    appendIconClass,
    clearable,
    customValidate,
    ...rest
  } ) => (
    <FormGroup
      className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}
    >
      {label && (
        <label className={classnames( labelClass, 'form-control-label' )}>
          {translate ? <Intl id={label} /> : label}
        </label>
      )}

      <div>
        <InputGroup
          className={classnames( { focused } )}
        >
          {prependIcon || prependIconClass ? (
            <InputGroupAddon
              addonType="prepend"
              isInvalid={isInvalid}
              icon={prependIcon}
              iconClass={prependIconClass}
            />
          ) : null}

          <DatePicker
            multiple
            zIndex={10000}
            range={false}
            {...rest}
            className="date-multipicker-calendar"
            containerClassName="date-multipicker-container"
            inputClass={classnames( 'form-control date-multipicker-input', {
              'is-invalid': isInvalid,
              'form-control-lg': size === 'lg',
              'form-control-sm': size === 'sm',
              'form-control-xsm': size === 'xsm',
            } )}
            value={value || ''}
            onChange={( e ) => {
              if ( inputOnChange ) inputOnChange( e );
              if ( onChange ) onChange( e );
              if (
                e
                  && formChange
              ) {
                formChange( e );
              }
            }}
            plugins={[
              <DatePanel />,
            ]}
            data-cy={`input-${inputName}`}
          />
        </InputGroup>

        {isInvalid && (
          <div className="invalid-feedback">
            <Intl
              id={meta.error || meta.submitError}
              values={translateValues}
            />
          </div>
        )}
      </div>
    </FormGroup>
  ),
);

export default injectIntl( DatepickerInput );
