/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  AddBtn,
  AsyncTable,
} from 'components';

export default React.memo(
  ( {
    data,
    onFetchData,
    // onDeleteItem,
    onOpenEditItemModal,
    isDataLoading,
  } ) => (
    <>
      <AsyncTable
        id="Accommodations"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isDataLoading}
        disableSorting
        onFetchData={onFetchData}
        rightContent={<AddBtn title="addAccommodations" onClick={() => onOpenEditItemModal()} />}
        columns={[
          {
            Header: 'name',
            accessor: 'name',
          },
          {
            Header: 'number',
            accessor: 'number',
          },
          {
            Header: 'floor',
            accessor: 'floor',
          },
          {
            Header: 'minimumCapacity',
            accessor: 'minimumCapacity',
          },
          {
            Header: 'maximumCapacity',
            accessor: 'maximumCapacity',
          },
        ]}
      />
    </>
  ),
);
