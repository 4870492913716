import React from 'react';
import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';

import {
  EditCard, Switch, TextField, TranslationLayout,
} from 'components';

export default React.memo( ( { form } ) => {
  const wtRequestTravelInfo = _get( form.getFieldState( 'wtRequestTravelInfo' ), 'value' );
  return (
    <EditCard title="customerApp.walkthrough.title" cardClass="card-sec-bottom">
      <TranslationLayout
        translationFields={( locale ) => {
          if ( !wtRequestTravelInfo ) {
            return null;
          }
          return (
            <Row>
              <Col>
                <TextField
                  field={`translations.${locale}.additionalInfo`}
                  label="customerApp.additionalInfo"
                  type="textarea"
                  rows={4}
                />
              </Col>
            </Row>
          );
        }}

      >
        <Row>
          <Col md={3}>
            <Switch
              field="wtRequestTravelInfo"
              label="customerApp.wtRequestTravelInfo"
            />
          </Col>
        </Row>
        {
        wtRequestTravelInfo && (
          <>
            <Row>
              <Col md={3}>
                <Switch
                  field="wtArrivalTime"
                  label="customerApp.wtArrivalTime"
                />
              </Col>
              <Col md={3}>
                <Switch
                  field="wtDepartureTime"
                  label="customerApp.wtDepartureTime"
                />
              </Col>
              <Col md={3}>
                <Switch
                  field="wtArrivalTransport"
                  label="customerApp.wtArrivalTransport"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  field="additionalInfo"
                  label="customerApp.additionalInfo"
                  type="textarea"
                  rows={4}
                />
              </Col>
            </Row>
          </>
        )
      }
      </TranslationLayout>
    </EditCard>
  );
} );
