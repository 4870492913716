import api from 'config/Api/Api';

const baseEndpoint = '/bookings';

export default {
  getBookings( params ) {
    return api.get( baseEndpoint, params );
  },
  updateBooking( bookingId, accommodationId, data ) {
    return api.put(
      `${baseEndpoint}/${bookingId}/accommodations/${accommodationId}`,
      data,
    );
  },
  saveBooking( data ) {
    return api.post( baseEndpoint, data );
  },
};
