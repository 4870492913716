import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { filterBuilder } from './filterBuilder';

const GET_ALL_ACCOMODATIONS = gql`
  query AccomodationDiscounts($limit: Int!, $offset: Int!, $filters: [FilterInput!]!) {
    accomodationDiscounts(limit: $limit, offset: $offset, filters: $filters) {
      ... on BaseError {
        message
      }
      ... on QueryAccomodationDiscountsSuccess {
        data {
          results {
            accommodation {
              id
              floor
              maximumCapacity
              minimumCapacity
              mainImage
              name
            }
            accomodationId
            discountId
            id
          }
          total
        }
      }
    }
  }
`;

const getAllAccommodationsDiscount = async ( { page, elementsPerPage, ...rest } ) => {
  const offset = ( page - 1 ) * elementsPerPage;
  const limit = elementsPerPage;
  const { data } = await client.query( {
    query: GET_ALL_ACCOMODATIONS,
    variables: {
      offset,
      limit,
      filters: filterBuilder( rest ),
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.accomodationDiscounts.message ) {
    return {
      ok: false,
      message: data.accomodationDiscounts.message,
    };
  }
  const accomodationDiscounts = data.accomodationDiscounts.data.results.map(
    ( item ) => ( { ...item.accommodation, accDiscId: item.id } ),
  ) || [];
  const elementsTotal = data.accomodationDiscounts.data.total || 0;
  return {
    ok: true,
    status: 200,
    data: { data: accomodationDiscounts, elementsTotal, elementsPerPage },
  };
};

export default getAllAccommodationsDiscount;
