import Login from 'containers/Login';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import Lock from 'containers/Lock';

export default [
  {
    component: Login,
    path: '/login',
    layout: '/auth',
  },
  {
    component: ForgotPassword,
    path: '/forgot-password',
    layout: '/auth',
  },
  {
    component: ResetPassword,
    path: '/reset-password',
    layout: '/auth',
  },
  {
    component: Lock,
    path: '/lock',
    layout: '/auth',
  },
];
