import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  ChatChannelList, ChatMessageList, ChatBookingOverview, ChatMembersOverview,
} from 'components';

export default React.memo( ( {
  messages, messagesToSend, hasMoreMessages, messagesReloadedAt, onFetchMessages, onSendMessage,
  channels, hasMoreChannels, operationChannels, hasMoreOperationChannels, selectedChannelId,
  channelsReloadedAt, selectedChannelData, onFetchBookingChannels, onFetchOperationChannels,
  onChangeChannel, onFilterChannels, onOpenAddParticipantModal, onOpenCreateChannelModal,
  onOpenTaskEditModal, onOpenDeleteParticipantModal, userCurrency, userBookingReference,
} ) => (
  <Row className="chat">
    <Col md={3} className="p-0">
      <ChatChannelList
        channels={channels}
        operationChannels={operationChannels}
        selectedChannelId={selectedChannelId}
        hasMoreChannels={hasMoreChannels}
        hasMoreOperationChannels={hasMoreOperationChannels}
        date={channelsReloadedAt}
        onFetchBookingChannels={onFetchBookingChannels}
        onFetchOperationChannels={onFetchOperationChannels}
        onFilterData={onFilterChannels}
        onChangeChannel={onChangeChannel}
        onOpenCreateChannelModal={onOpenCreateChannelModal}
        userBookingReference={userBookingReference}
      />
    </Col>
    <Col md={6} className="p-0">
      <ChatMessageList
        data={messages}
        messagesToSend={messagesToSend}
        hasMore={hasMoreMessages}
        selectedChannelId={selectedChannelId}
        date={messagesReloadedAt}
        onFetchData={onFetchMessages}
        onSendMessage={onSendMessage}
      />
    </Col>
    <Col md={3} className="p-0">
      {selectedChannelData.type === 'operations'
        ? (
          <ChatMembersOverview
            data={selectedChannelData}
            onOpenAddParticipantModal={onOpenAddParticipantModal}
            onOpenDeleteParticipantModal={onOpenDeleteParticipantModal}
          />
        )
        : (
          <ChatBookingOverview
            data={selectedChannelData}
            onOpenTaskEditModal={onOpenTaskEditModal}
            userCurrency={userCurrency}
            userBookingReference={userBookingReference}
          />
        )}
    </Col>
  </Row>
) );
