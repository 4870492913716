import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const UPDATE_SEASON_DISCOUNT = gql`
  mutation UpdateSeasonDiscount($input: UpdateSeasonDiscountInput!) {
    updateSeasonDiscount(input: $input) {
      ... on BaseError {
        message
      }
      ... on MutationUpdateSeasonDiscountSuccess {
        data {
          success
        }
      }
    }
  }
`;

const updateSeasonDiscount = async ( input ) => {
  const { data } = await client.query( {
    query: UPDATE_SEASON_DISCOUNT,
    variables: {
      input: {
        season: input,
      },
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateSeasonDiscount.message ) {
    return {
      ok: false,
      message: data.updateSeasonDiscount.message,
    };
  }
  const updateSuccessful = data.updateSeasonDiscount.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateSeasonDiscount;
