import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import SettingsActions from 'store/reducers/Settings';
import ReservationService from 'api/Client/Reservation';
import MoreInfoView from 'views/Booking/Edit/MoreInfo';
import { BookingOpenLockerModal } from 'components';

const MoreInfo = React.memo( ( {
  data, toggleLoading, toggleInfoAlert, toggleErrorAlert, userCurrency,
} ) => {
  const [syncModalOpen, setSyncModal] = useState( false );
  const [openModalOpen, setOpenModal] = useState( false );
  const doorTypeToOpen = useRef( null );
  const doorIdToOpen = useRef( null );

  const internalId = data.externalId;

  const toggleSyncModal = () => {
    setSyncModal( ( state ) => !state );
  };

  const openOpenDoorModal = ( { type, lockerId } ) => {
    doorTypeToOpen.current = type;
    doorIdToOpen.current = lockerId;
    setOpenModal( true );
  };

  const closeOpenDoorModal = () => {
    setOpenModal( false );
  };

  const syncBooking = useCallback( () => {
    if ( internalId && internalId.value ) {
      toggleLoading( true );

      ReservationService.syncBooking( internalId.value ).then( ( response ) => {
        toggleLoading( false );
        toggleSyncModal();
        if ( response.data && response.data.status === 'ko' ) return toggleErrorAlert( 'bookingNotSynced' );
        if ( !response.ok ) return toggleErrorAlert( response.errors );
        toggleInfoAlert( 'bookingSynced' );
      } );
    }
  }, [internalId, toggleLoading, toggleInfoAlert, toggleErrorAlert] );

  return (
    <>
      <MoreInfoView
        data={data}
        syncModalOpen={syncModalOpen}
        onSyncBooking={syncBooking}
        onToggleSyncModal={toggleSyncModal}
        onOpenDoorModal={openOpenDoorModal}
        userCurrency={userCurrency}
      />
      <BookingOpenLockerModal
        data={data}
        doorType={doorTypeToOpen.current}
        lockerId={doorIdToOpen.current}
        modalOpened={openModalOpen}
        onCloseModal={closeOpenDoorModal}
      />
    </>
  );
} );

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( MoreInfo );
