import React from 'react';
import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';

import {
  TextField,
  EditCard,
  Switch,
  Intl,
  Dropzone,
} from 'components';

export default React.memo( ( { form } ) => {
  const showDefaultAccommodation = _get( form.getFieldState( 'showDefaultAccommodation' ), 'value' );
  return (
    <EditCard title="customerApp.defaultAccommodation.title" cardClass="card-sec-bottom">
      <Row>
        <Col>
          <Switch
            field="showDefaultAccommodation"
            label="customerApp.showDefaultAccommodation"
          />
        </Col>
      </Row>
      { showDefaultAccommodation && (
        <Row>
          <Col md="auto">
            <Dropzone
              field="defaultAccommodationPicture"
              formGroupClass="dz-profile-img"
              label="customerApp.defaultAccommodationPicture"
              placeholder={(
                <>
                  <i className="fa fa-file fa-4x mb-4" />
                  <p>
                    <Intl id="dropzoneFileLimitMsg" />
                  </p>
                </>
            )}
            />
          </Col>
          <Col>
            <Row>
              <Col lg={4}>
                <TextField
                  field="showDefaultAccommodationUntil"
                  label="customerApp.showDefaultAccommodationUntil"
                  type="number"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <TextField
                  field="defaultAccommodationName"
                  label="customerApp.defaultAccommodationName"
                />
              </Col>
              <Col lg={7}>
                <TextField
                  field="defaultAccommodationAddress"
                  label="customerApp.defaultAccommodationAddress"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </EditCard>
  );
} );
