import React, {
  useState, useCallback, useMemo,
} from 'react';
import DashboardPMSView from 'views/DashboardPMS';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import { useFetch } from 'hooks';
import PropertyService from 'api/Property';
import CityService from 'api/Settings/City';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import RoomTypeService from 'api/RoomType';
import moment from 'moment';
import AccommodationService from 'api/Accommodation';
import BookingService from 'api/Booking';
import TaskService from 'api/Task';
import TagsPredefinedService from 'api/TagsPredefined';
import AccommodationOverview from './AccommodationOverview';
import formatDataPMS, { flatAccommodations, getPMSStatistics } from './utils/format';

const DashboardPMS = ( { toggleErrorAlert, propertyManager, incidenceReloadedAt } ) => {
  const [openModal, setOpenModal] = useState( false );
  const toggleModal = () => setOpenModal( ( state ) => !state );
  const { getTasks } = TaskService;

  const [date, setDate] = useState( moment().utc().startOf( 'day' ) );
  const [params, setParams] = useState( {} );
  const [cleaningStatus, setCleaningStatus] = useState( null );

  const [selectedPropertyId, setSelectedPropertyId] = useState( );
  const [selectedDMAccomodationId, setSelectedDMAccomodationId] = useState();

  const [accommodations, loadingAccommodations] = useFetch( {
    initialState: [],
    promise: () => AccommodationService.getAllAccommodationsAll( {
      elementsPerPage: 500,
      page: 1,
      cleaningStatus,
      tags: params.tags ? params.tags.map( ( item ) => item.value ) : null,
    } ),
    format: ( dataToFormat ) => flatAccommodations( _get( dataToFormat, 'data', [] ) ),
    toggleErrorAlert,
    reInit: true,
    deps: [cleaningStatus, params.tags],
  } );

  const fetchData = !loadingAccommodations;

  const [data, dataLoading] = useFetch( {
    initialState: { properties: [] },
    promise: async () => {
      const [checkIn, checkOut, checkInOut, inside, tasks] = await Promise.all( [
        BookingService.getBookings( {
          elementsPerPage: 1000,
          fromStart: date.format( 'YYYY-MM-DD' ),
          fromEnd: date.format( 'YYYY-MM-DD' ),
          checkinComplete: 'false',
          cleaningStatus,
        } ),
        BookingService.getBookings( {
          elementsPerPage: 1000,
          tillStart: date.format( 'YYYY-MM-DD' ),
          tillEnd: date.format( 'YYYY-MM-DD' ),
          justOut: 'true',
          cleaningStatus,
        } ),
        BookingService.getBookings( {
          elementsPerPage: 1000,
          tillStart: date.format( 'YYYY-MM-DD' ),
          tillEnd: date.format( 'YYYY-MM-DD' ),
          outIn: 'true',
          cleaningStatus,
        } ),
        BookingService.getBookings( {
          elementsPerPage: 100,
          tillStart: moment( date ).add( 1, 'days' ).format( 'YYYY-MM-DD' ),
          fromEnd: date.format( 'YYYY-MM-DD' ), // date.subtract( 2, 'days' ).format( 'YYYY-MM-DD' ),
          checkinComplete: 'true',
          cleaningStatus,
        } ),
        getTasks( {
          elementsPerPage: 1000,
          from: date.format( 'YYYY-MM-DD' ),
          till: date.format( 'YYYY-MM-DD' ),
        } ),
      ] );
      if ( !checkIn.ok ) {
        return checkIn;
      }
      if ( !checkOut.ok ) {
        return checkOut;
      }

      if ( !inside.ok ) {
        return inside;
      }

      if ( !tasks.ok ) {
        return tasks;
      }

      const response = {
        ok: true,
        data: {
          checkOut: checkOut.data,
          checkIn: checkIn.data,
          checkInOut: checkInOut.data,
          inside: inside.data,
          tasks: tasks.data,
        },
      };
      return response;
    },
    format: ( dataToFormat ) => formatDataPMS( dataToFormat, accommodations ),
    toggleErrorAlert,
    deps: [fetchData, date, cleaningStatus, accommodations, incidenceReloadedAt],
    reInit: true,
    conditional: fetchData,
  } );

  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 10,
      keyword: query,
    } ), [],
  );

  const fetchCities = useCallback(
    ( query ) => CityService.getCities( {
      elementsPerPage: 10,
      keyword: query,
      propertyManager,
    } ), [propertyManager],
  );

  const fetchRoomTypes = useCallback(
    ( query ) => RoomTypeService.getRoomTypes( selectedPropertyId, {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [selectedPropertyId],
  );

  const setFilter = ( filter, value ) => {
    if ( filter === 'propertyId' ) {
      setSelectedPropertyId( value );
    }
    if ( filter === 'propertyId' && value === undefined ) {
      setParams( ( current ) => ( { ...current, [filter]: value, roomTypeId: undefined } ) );
      return;
    }
    setParams( ( current ) => ( { ...current, [filter]: value } ) );
  };

  const handleOnChangeDate = ( value ) => {
    setDate( value );
  };

  const handleOnClickAccomodation = ( id ) => {
    setSelectedDMAccomodationId( id );
    toggleModal();
  };

  const filterApartments = ( apartments, type ) => apartments.map( ( item ) => {
    if ( type === 'checkin' ) {
      if ( item.checkout || item.empty ) {
        return {
          ...item,
          hide: true,
        };
      }
    }
    if ( type === 'checkout' ) {
      if ( item.checkin || item.empty ) {
        return {
          ...item,
          hide: true,
        };
      }
    }
    if ( type === 'checkInOut' ) {
      if ( !item.checkin || !item.checkout ) {
        return {
          ...item,
          hide: true,
        };
      }
    }
    if ( type === 'empty' ) {
      if ( !item.empty ) {
        return {
          ...item,
          hide: true,
        };
      }
    }
    if ( type === 'full' ) {
      if ( item.empty ) {
        return {
          ...item,
          hide: true,
        };
      }
    }
    return item;
  } );

  const filterData = useMemo( () => {
    const { type, tags, ...filters } = params;
    let propertiesFiltered = _filter( data.properties, _omitBy( filters, _isUndefined ) );
    if ( type ) {
      propertiesFiltered = _filter(
        propertiesFiltered,
        ( item ) => {
          if ( type === 'checkin' || type === 'checkout' ) {
            return item[type] > 0;
          }
          return item[type] > 0;
        },
      ).map( ( item ) => {
        if ( type === 'checkin' ) {
          return {
            ...item,
            checkInOut: 0,
            checkout: 0,
            checkin: item.checkin - item.checkInOut,
            data: [filterApartments( item.data[0], type )],
          };
        }
        if ( type === 'checkout' ) {
          return {
            ...item,
            checkInOut: 0,
            checkin: 0,
            checkout: item.checkout - item.checkInOut,
            data: [filterApartments( item.data[0], type )],
          };
        }
        if ( type === 'checkInOut' ) {
          return {
            ...item,
            checkin: item.checkInOut,
            checkout: item.checkInOut,
            data: [filterApartments( item.data[0], type )],
          };
        }
        return { ...item, data: [filterApartments( item.data[0], type )] };
      } );
    }

    const statistics = getPMSStatistics( propertiesFiltered );
    const total = statistics.empty + statistics.full;
    const occupancy = ( Math.round( ( statistics.full * 100 ) / total ) ) || 0;
    return {
      ...statistics,
      occupancy,
      properties: propertiesFiltered,
    };
  }, [data, params] );

  const fetchTagsPredefined = useCallback(
    ( ) => TagsPredefinedService.getTagsPredefined(), [],
  );

  return (
    <>
      <DashboardPMSView
        onClickAccomodation={handleOnClickAccomodation}
        data={filterData}
        isLoading={dataLoading || loadingAccommodations}
        onFetchProperties={fetchProperties}
        onFetchCities={fetchCities}
        onfetchRoomTypes={fetchRoomTypes}
        selectedPropertyId={selectedPropertyId}
        onFetchTagsPredefined={fetchTagsPredefined}
        onChangeFilter={setFilter}
        date={date}
        onChangeDate={handleOnChangeDate}
        setCleaningStatus={setCleaningStatus}
      />
      <AccommodationOverview
        openModal={openModal}
        onClose={toggleModal}
        DMAccomodationId={selectedDMAccomodationId}
        currentDate={date}
      />
    </>
  );
};

const mapStateToProps = ( { user, incidence } ) => ( {
  propertyManager: _get( user, 'user.propertyManager.id', {} ),
  incidenceReloadedAt: incidence.reloadedAt,
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  // toggleLoading: SettingsActions.toggleLoading,
  // toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( DashboardPMS );
