import React from 'react';
import _get from 'lodash/get';
import { Intl, DetailCard } from 'components';

const Guests = ( { guests } ) => (
  <ul>
    {
      guests.map( ( guest, index ) => (
        <li key={_get( guest, 'id', index )}>
          {`${guest.name} ${guest.firstLastname}`}
        </li>
      ) )
    }
  </ul>
);

const RESPONSE_OK = 'OK';
const STATUS_OK = '200';

const renderResponse = ( item ) => {
  if ( _get( item, 'response' ) === RESPONSE_OK && _get( item, 'status' ) === STATUS_OK ) {
    return <Intl id="sended" />;
  }
  return <Intl id="errorSended" />;
};

export default React.memo( (
  { data, isLoading },
) => (
  <DetailCard
    classNames={{
      header: 'py-3', body: 'pt-4', card: 'card-sec-bottom', 'app-component-loading': isLoading,
    }}
    header={(
      <h3 className="m-0"><Intl id="registerOfAuthorities" /></h3>
    )}
  >
    {
    data.map( ( item ) => (
      <div key={_get( item, '_id.oid' )}>
        <Intl id={_get( item, 'police_type' )} />
        <ul>
          <li>
            <Intl id="response" />
            {' : '}
            {renderResponse( item )}
          </li>
          <li>
            <Intl id="guests" />
            {' : '}
            <Guests guests={_get( item, 'bookedAccommodation_id.guests' )} />
          </li>
        </ul>
        <p />
      </div>
    ) )
  }
  </DetailCard>
) );
