import React, { useCallback } from 'react';
import SettingsActions from 'store/reducers/Settings';
import NotificationsAccOwnerService from 'api/NotificationsAccOwner';
import _ from 'lodash';
import { handlePromise } from 'utils';
import { connect } from 'react-redux';
import SendNotificationView from 'views/AccomodationOwner/Edit/notifications/Send/index';

const SendNotification = ( {
  customerId, toggleLoading, toggleErrorAlert, toggleInfoAlert,
  open, onClose, reload,
} ) => {
  const submitNotification = useCallback( async ( values ) => {
    toggleLoading( true );
    const [errors, response] = await handlePromise(
      NotificationsAccOwnerService.sendNotification( customerId, values ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return toggleErrorAlert( errors );
    }
    toggleLoading( false );
    toggleInfoAlert( 'notifySuccesfull' );
    reload();
    onClose();
  }, [toggleLoading, customerId, onClose, reload, toggleErrorAlert, toggleInfoAlert] );
  return (
    <SendNotificationView
      open={open}
      onClose={onClose}
      onSubmit={submitNotification}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( SendNotification );
