import React, { useMemo, useEffect, useState } from 'react';
import {
  Row, Col, Card, CardHeader, CardBody,
} from 'reactstrap';
import {
  Form, Intl,
  ResourceAvatar,
  FormActionsBtns,
  Select,
} from 'components';
import { getResourceName } from 'utils';
import {
  locales, currencies, sizes,
} from 'config/Constants';
import countries from 'country-list';
import ability from 'config/Ability';

export default React.memo(
  ( {
    data,
    entityId,
    parentPath,
    onSubmit,
  } ) => {
    const [dataForm, setDataForm] = useState( {} );
    const [country, setCountry] = useState( '-' );
    const countryList = useMemo( countries.getData, [countries] );
    const formEnabled = ability.can( 'edit', 'resource' );
    const onSubmitForm = ability.can( 'edit', 'resource' ) ? onSubmit : () => {};

    useEffect( () => {
      if ( data ) {
        const df = { ...data };
        if ( data.country ) {
          const c = countryList.filter(
            ( a ) => a.code.toUpperCase() === data.country.toUpperCase(),
          );
          if ( c[0] ) {
            setCountry( c[0].name );
          } else {
            setCountry( data.country.toUpperCase() );
          }
        }
        if ( data.settings ) {
          if ( data.settings.currency ) {
            df.currency = data.settings.currency;
          }
          if ( data.settings.locale ) {
            df.locale = data.settings.locale;
          }
          if ( data.settings.size ) {
            df.size = data.settings.size;
          }
        }
        setDataForm( df );
      }
    }, [data, setDataForm, countryList, setCountry] );

    return (
      <Row>
        <Col xl={9}>
          <Card>
            <CardHeader>
              <h3 className="m-0">
                <Intl id="customer" />
                {' '}
                {entityId}
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={3}>
                  <ResourceAvatar
                    data={dataForm}
                    avatarSize="lg"
                    style={{ width: 170, height: 170 }}
                    noName
                  />
                </Col>
                <Col md={9}>
                  <Row className="mb-4">
                    <Col md={4}>
                      <h4><Intl id="name" /></h4>
                      <div>{getResourceName( dataForm )}</div>
                    </Col>
                    <Col>
                      <h4><Intl id="phone" /></h4>
                      <div>{dataForm.phoneNumber || ''}</div>
                    </Col>
                    <Col>
                      <h4><Intl id="email" /></h4>
                      <div>{dataForm.email || ''}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <h4><Intl id="country" /></h4>
                      <div>{country}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card>
            <CardBody>
              <Form
                initialValues={dataForm}
                onSubmit={onSubmitForm}
                showErrorsInFooter
                body={( ) => (
                  <>
                    <h4 className="text-uppercase mb-4">Settings</h4>
                    <Row>
                      <Col md={12}>
                        <Select
                          field="currency"
                          label="currency"
                          options={currencies}
                        />
                      </Col>
                      <Col md={12}>
                        <Select
                          field="locale"
                          label="language"
                          idKey="locale"
                          options={locales}
                        />
                      </Col>
                      <Col md={12}>
                        <Select
                          field="size"
                          label="measure"
                          options={sizes}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                footer={
                  formEnabled ? <FormActionsBtns cancelTo={parentPath} /> : null
                }
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

    );
  },
);
