import api from 'config/Api/Api';

const baseEndpoint = '/poi';

const searchEndpoint = ( keywords ) => `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${encodeURI(
  keywords,
)}&key=${process.env.REACT_APP_MAP_KEY}`;

export default {
  getPointsOfInterest( params ) {
    return api.get( baseEndpoint, params );
  },
  getPointOfInterest( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  savePointOfInterest( data ) {
    return api.post( baseEndpoint, data );
  },

  updatePointOfInterest( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deletePointOfInterest( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
  searchPlace( keywords ) {
    return api.get( searchEndpoint( keywords ) );
  },
};
