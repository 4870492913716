import api from 'config/Api/Api';

import { formatFormDataBody } from 'utils';

const baseEndpoint = ( taskId ) => `/events/${taskId}/images`;

export default {
  saveImage( postId, data ) {
    return api.post( baseEndpoint( postId ), formatFormDataBody( data ) );
  },

  deleteImage( postId, index ) {
    return api.delete( `${baseEndpoint( postId )}/${index}` );
  },
};
