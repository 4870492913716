import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  Dropzone, ModalForm, Select, Intl, TextField,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    data,
    isLoading,
    open,
    onClose,
    onSubmit,
    countries,
    isLoadingCountries,
    onChangeCountry,
    states,
    isLoadingStates,
    onChangeState,
    cities,
    isLoadingCities,
  } ) => (
    <ModalForm
      initialValues={data}
      title="newCityAppImage"
      showErrorsInFooter
      isLoading={isLoading}
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Row>
        <Col lg={8}>
          <Row>
            <Col sm={12}>
              <TextField field="name" label="name" validate={required} />
            </Col>
            <Col lg={6}>
              <Select
                label="country"
                validate={required}
                options={countries}
                isLoading={isLoadingCountries}
                onChange={onChangeCountry}
                idKey="iso"
              />
            </Col>
            <Col lg={6}>
              <Select
                label="state"
                validate={required}
                options={states}
                isLoading={isLoadingStates}
                onChange={onChangeState}
                idKey="iso"
              />
            </Col>
            <Col lg={6}>
              <Select
                field="city"
                label="city"
                validate={required}
                options={cities}
                isLoading={isLoadingCities}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={4}>
          <Dropzone
            field="file"
            formGroupClass="dz-profile-img"
            label="image"
            placeholder={(
              <>
                <i className="fa fa-file fa-4x mb-4" />
                <p>
                  <Intl
                    id="upload.maxMesureSizeAndHeight"
                    values={{ size: '100Kb', height: '300px' }}
                  />
                </p>
              </>
)}
            validate={required}
          />
        </Col>
      </Row>
    </ModalForm>
  ),
);
