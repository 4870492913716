import React, { useContext } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData, useDelete } from 'hooks';
import { deletePipeline, getPipelines } from 'api/CRM/Contact';
import DashboardPipelinesView from 'views/CRM/Pipelines/Dashboard';
import { PipelinesCRMContext } from '../context';

const DashboardPipelines = ( {
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const {
    reloadFlag,
    onReload,
  } = useContext( PipelinesCRMContext );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => {
      const searchTerm = params.keyword ? { name: params.keyword } : {};
      return getPipelines( { ...params, ...searchTerm } );
    },
    toggleErrorAlert,
    deps: [reloadFlag],
  } );

  const onDeletePipeline = useDelete( {
    promise: deletePipeline,
    callback: () => {
      onReload();
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <DashboardPipelinesView
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      onDeletePipeline={onDeletePipeline}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( DashboardPipelines );
