import { Intl } from 'components';
import React from 'react';
import {
  Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap';

const ManageView = ( {
  open, onClose, onClick, initialPermitStatus,
} ) => (
  <Modal
    className="modal-dialog-centered"
    isOpen={open}
    toggle={onClose}
  >
    <ModalHeader
      toggle={onClose}
    >
      <Intl id="changePermitStatus" />
    </ModalHeader>
    <ModalBody className="text-center">
      {initialPermitStatus !== 'accepted' && (
      <Button data-cy="save-form-btn" color="success" onClick={() => onClick( 'accepted' )}>
        <Intl id="approve" />
      </Button>
      )}
      {initialPermitStatus !== 'in_demand' && (
      <Button data-cy="save-form-btn" color="primary" onClick={() => onClick( 'in_demand' )}>
        <Intl id="shiftPending" />
      </Button>
      )}
      {initialPermitStatus !== 'rejected' && (
      <Button data-cy="save-form-btn" color="danger" onClick={() => onClick( 'rejected' )}>
        <Intl id="reject" />
      </Button>
      )}
    </ModalBody>
  </Modal>
);

export default ManageView;
