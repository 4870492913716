/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import classnames from 'classnames';
import {
  Table,
} from 'reactstrap';
import _ from 'lodash';

import { Intl } from 'components';

export default React.memo(
  ( {
    uncenterHeaders,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    getRowProps = () => ( {} ),
  } ) => (
    <div className="table-responsive">
      <Table className="align-items-center table-flush" {...getTableProps()}>
        <thead className="thead-light">
          {headerGroups.map( ( headerGroup, trKey ) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={trKey.toString()}>
              {headerGroup.headers.map( ( column, thKey ) => (
                <th
                  {...( column.canSort
                    ? column.getHeaderProps( {
                      ...column.getSortByToggleProps(),
                      ...{
                        style: column.maxWidth
                          ? {
                            flex: `${column.maxWidth} 0 auto`,
                            width: column.maxWidth,
                            maxWidth: column.maxWidth,
                            paddingLeft: 0,
                            paddingRight: 0,
                            textAlign: 'center',
                          }
                          : {},
                      },
                    } )
                    : column.getHeaderProps( {
                      style: column.maxWidth
                        ? {
                          flex: `${column.maxWidth} 0 auto`,
                          width: column.maxWidth,
                          maxWidth: column.maxWidth,
                          paddingLeft: 0,
                          paddingRight: 0,
                          textAlign: uncenterHeaders ? 'left' : 'center',
                        }
                        : {},
                    } ) )}
                  key={thKey.toString()}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {column.render( 'Header' ) ? (
                    _.isString( column.render( 'Header' ) ) ? (
                      <Intl id={column.render( 'Header' )} />
                    ) : (
                      column.render( 'Header' )
                    )
                  ) : (
                    ''
                  )}
                  {column.canSort && (
                    <span
                      data-cy="table-sort"
                      className={classnames( 'fas ml-1', {
                        'fa-sort': !column.isSorted,
                        'fa-sort-up': column.isSorted && !column.isSortedDesc,
                        'fa-sort-down': column.isSorted && column.isSortedDesc,
                      } )}
                    />
                  )}
                </th>
              ) )}
            </tr>
          ) )}
        </thead>
        <tbody className="list">
          {page.length ? (
            page.map(
              ( row, rowKey ) => prepareRow( row ) || (
              <tr
                {...row.getRowProps( getRowProps( row ) )}
                key={rowKey.toString()}
              >
                {row.cells.map( ( cell, cellKey ) => (
                  <td
                    data-cy={`table-column-${cell.column.id}`}
                    {...cell.getCellProps(
                      cell.column.maxWidth
                        ? {
                          style: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            verticalAlign: 'middle',
                            flex: `${cell.column.maxWidth} 0 auto`,
                            width: cell.column.maxWidth,
                            maxWidth: cell.column.maxWidth,
                            textAlign: 'center',
                          },
                        }
                        : {},
                    )}
                    key={cellKey.toString()}
                  >
                    {cell.render( 'Cell' )}
                  </td>
                ) )}
              </tr>
              ),
            )
          ) : (
            <tr>
              <td colSpan={100} className="text-center" data-cy="table-noData">
                <Intl id="noTableData" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  ),
);
