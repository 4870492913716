import moment from 'moment';
import defaultFormatter from 'react-timeago/lib/defaultFormatter';

export default ( value, unit, suffix, rawTime ) => {
  if ( moment().local().diff( moment( rawTime ).local(), 'day' ) > 7 && moment( rawTime ).local().year() === moment().local().year() ) {
    return moment( rawTime ).local().format( 'MMM DD' );
  }
  if ( moment( rawTime ).local().year() !== moment().local().year() ) {
    return moment( rawTime ).local().format( 'MMM DD, YYYY' );
  }
  if ( unit === 'second' ) return 'seconds ago';

  let abbr = '';
  switch ( unit ) {
    case 'second':
      abbr = 'sec';
      break;
    case 'minute':
      abbr = 'min';
      break;
    default:
      abbr = unit;
      break;
  }
  return defaultFormatter( value, abbr, suffix );
};
