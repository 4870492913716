import React, {
  useCallback, useMemo, useState, useRef, useContext,
} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Badge, Col, Row } from 'reactstrap';
import { ConfirmationModal, Intl } from 'components';
import { BusinnesCRMContext } from 'containers/CRM/Business/Dashboard/context';

const KanbanItem = ( {
  item, onDeleteBusiness,
} ) => {
  const {
    onEditBusiness,
  } = useContext( BusinnesCRMContext );

  const [deleteModal, setDeleteModal] = useState( false );
  const deleteCb = useRef( null );
  const toggleDeleteModal = useCallback( ( cb ) => {
    deleteCb.current = cb;
    setDeleteModal( ( state ) => !state );
  }, [] );
  //

  const [isDragging, setIsDragging] = useState( false );

  const onDragStart = useCallback( ( e ) => {
    e.dataTransfer.setData( 'text/plain', `${item.id}` );
    setIsDragging( true );
  }, [item] );

  const onDragEnd = useCallback( () => {
    setIsDragging( false );
  }, [] );

  const data = useMemo( () => {
    const {
      businessName: name, closeDate, contact, state,
    } = item.content;
    return {
      name,
      contactName: contact?.name || '',
      closeDate: moment( closeDate ).format( 'YYYY-MM-DD' ),
      state,
    };
  }, [item] );

  return (
    <>
      <div
        className={classNames( 'kanban-item', {
          'is-dragging': isDragging,
        } )}
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <Row className="justify-content-between">
          <Col xs="auto">
            <a
              href="/"
              className="kanban-item-title"
              onClick={( e ) => {
                e.preventDefault();
                onEditBusiness( item.id );
              }}
            >
              {data.name}
            </a>
            <div className="kanban-item-description text-small">{data.contactName}</div>
          </Col>
          <Col xs="auto">
            <button
              aria-label="Delete"
              type="button"
              onClick={() => toggleDeleteModal( () => { onDeleteBusiness( item.id ); } )}
              className="kanban-item-delete"
            >
              <i className="app-icon-close" />
            </button>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-between">
          <Col xs="auto"><div className="kanban-item-description text-small">{data.closeDate}</div></Col>
          <Col xs="auto">
            <Badge color={data.state === 'active' ? 'success' : 'danger'} pill className="py-1 px-3">
              <div className="text-small">
                <Intl id={`crm.businnesStatuses.${data.state}`} />
              </div>
            </Badge>
          </Col>
        </Row>
        <ConfirmationModal
         // title={modalTitle}
          open={deleteModal}
          onConfirm={() => deleteCb.current( toggleDeleteModal )}
          onClose={toggleDeleteModal}
        />
      </div>
    </>
  );
};

export default KanbanItem;
