import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import TaskView from 'views/Resource/Edit/Task';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import EmployeeService from 'api/Employee';
import FiltersActions from 'store/reducers/Filters';
import { formatTasks, getStatistics } from './utils';

const Tasks = ( {
  entityId,
  updateFilters,
  toggleErrorAlert,
} ) => {
  const filterFromDate = moment().startOf( 'day' );
  const filterToDate = moment( filterFromDate ).clone().add( 30, 'day' );
  const initialDates = useRef( moment.range( filterFromDate, filterToDate ) );
  const [statistics, setStatistics] = useState( {
    estimated: '00:00:00',
    elapsed: '00:00:00',
    deviation: '00:00:00',
  } );

  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => EmployeeService.getTasks( entityId, params ),
    toggleErrorAlert,
    format: formatTasks,
    callback: ( params ) => {
      updateFilters( 'resourceTasks', {
        from: params.from,
        till: params.till,
      } );
    },
    deps: [entityId],
  } );

  useEffect( () => {
    if ( dataTable.data ) {
      setStatistics( getStatistics( dataTable.data ) );
    }
  }, [dataTable] );

  return (
    <>
      <TaskView
        statistics={statistics}
        fetchData={fetchData}
        dataTable={dataTable}
        dataLoading={dataLoading}
        defaultFilters={{
          from: initialDates.current.start
            ? initialDates.current.start.format( 'YYYY-MM-DD' )
            : undefined,
          till: initialDates.current.end
            ? initialDates.current.end.format( 'YYYY-MM-DD' )
            : undefined,
        }}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateFilters: FiltersActions.updateFilters,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( Tasks );
