import React, { useRef } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import classnames from 'classnames';
import {
  SimpleHeader,
  Button,
  EditCard,
  Switch,
  Form,
  Intl,
  TextField,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    isLoading,
    onSubmit,
    formEnabled = true,
  } ) => {
    const formRef = useRef( null );

    return (
      <>
        <SimpleHeader
          title="employeePermitSettings"
          rightContent={
            formEnabled && canModify( 'settings' ) ? (
              <Button title="save" size="md" onClick={() => formRef.current.click()} />
            ) : null
          }
        />
        <Container
          fluid
          className={classnames( 'mt-1', { 'app-component-loading': isLoading } )}
        >
          <Form
            initialValues={data}
            onSubmit={onSubmit}
            showErrorsInFooter
            body={() => (
              <EditCard cardClass="card-sec-bottom">
                {data.permissions.map( ( item, index ) => (
                  <>
                    <Row className="mb-3 align-items-center justify-content-center">
                      <Col md={3}>
                        <label className="form-control-label">
                          <Intl id={item.name} />
                        </label>
                      </Col>
                      <Col md={3}>
                        <Switch field={`permissions[${index}].enable`} label="enable" />
                      </Col>
                      <Col md={3}>
                        <Switch field={`permissions[${index}].allowedToAccumulate`} label="allowedToAccumulate" />
                      </Col>
                      <Col md={3}>
                        {item.daysPerYear && (
                        <TextField
                          field={`permissions[${index}].daysPerYear`}
                          label="daysPerYear"
                          type="number"
                        />
                        )}
                      </Col>
                    </Row>
                  </>
                ) )}
              </EditCard>
            )}
            footer={(
              <button ref={formRef} className="invisible" type="submit">
                hidden
              </button>
      )}
          />
        </Container>
      </>
    );
  },
);
