import React from 'react';
import classnames from 'classnames';
import { Intl } from 'components';
import { formatTimeSince } from 'utils';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import LinkNotification from 'components/Pages/Notification/LinkNotification';

const statusProcessed = 'processed';

const Notification = ( { item, onReadNotification } ) => {
  const {
    status, body, title, created, id,
  } = item;
  const timeAgo = formatTimeSince( created );
  return (
    <div className="notification-center__item">
      <div
        className="notification-center__item-content"
      >
        <Row className="flex-nowrap no-gutters">
          <Col xs="auto">
            <div
              className={classnames( 'notification-center__item-mark-as-read', { unread: status !== statusProcessed } )}
              onClick={() => {
                if ( status !== statusProcessed ) {
                  onReadNotification( id );
                }
              }}
              id={`bell-notif-${id}`}
            >
              <div className="notification-center__item-mark-as-read-icon">
                <i
                  className="fa fa-check"
                />
              </div>
            </div>
            <UncontrolledTooltip delay={0} target={`bell-notif-${id}`}>
              <Intl id={`markProcessed.${status !== statusProcessed ? 'no' : 'yes'}`} />
            </UncontrolledTooltip>
          </Col>
          <Col>
            <div className="notification-center__item-title">

              {title ? ( <span>{title}</span> ) : ( <Intl id="untitledNotification" /> )}
            </div>
            <div className="notification-center__item-description">
              <div>{body ? ( <span>{body}</span> ) : ( <Intl id="noCommentsNotification" /> )}</div>

              <LinkNotification item={item} />
            </div>
            <div className="notification-center__item-date">
              {`${timeAgo.num} `}
              <Intl id={timeAgo.term} />
              {' '}
              <Intl id="timeSinceAgo" />
            </div>
          </Col>
        </Row>

      </div>

    </div>
  );
};

export default Notification;
