import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ChatSettingsView from 'views/Communications/ChatSettings';
import { useFetch } from 'hooks';
import ChatSettingsService from 'api/Settings/Chat';
import TranslationService from 'api/Translation';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';

const EmailSettings = ( {
  propertyManager,
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ChatSettingsService.getChatSettings( propertyManager.id ),
    translationPromise: ( responsePromise ) => {
      const id = _.get( responsePromise, 'id', '' );
      return TranslationService.getChatSettingsTranslations( id );
    },
    toggleErrorAlert,
    conditional: !!propertyManager.id,
    deps: [propertyManager.id],
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const translation = true;
      const dataToSend = formatDataToSend( formData, form, translation );

      const [errors, response] = await handlePromise(
        ChatSettingsService.updateChatSettings( propertyManager.id, dataToSend ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [propertyManager.id, toggleInfoAlert, toggleLoading],
  );

  return (
    <ChatSettingsView
      data={data}
      isLoading={dataLoading}
      onSubmit={submitForm}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( mapStateToProps, mapDispatchToProps )( EmailSettings );
