import React from 'react';
import _ from 'lodash';
import {
  Intl,
  ModalForm, Select, TextField,
} from 'components';
import { Row, Col, Button } from 'reactstrap';
import { required } from 'config/InputErrors';
import NewContractModal from '../New';

const EditView = ( {
  modalOpened, handleClose, onSubmit, selectedContract,
} ) => {
  const contractFile = _.get( selectedContract, 'contract', null );
  return (
    <ModalForm
      initialValues={selectedContract}
      title={_.isEmpty( selectedContract ) ? 'newContract' : 'editContract'}
      showErrorsInFooter
      isLoading={false}
      open={modalOpened}
      onClose={handleClose}
      bodyClassName="overflow-visible"
      onSubmit={onSubmit}
      body={( form ) => (
        <>
          <Row>
            <Col md={8}>
              <TextField
                field="description"
                label="description"
                translate
              />
            </Col>
            <Col md={4}>
              <Select
                field="status"
                label="status"
                options={[{
                  name: 'pending',
                  id: 'pending',
                }, {
                  name: 'signed',
                  id: 'signed',
                }]}
                validate={required}
                translateOptions
              />
            </Col>
          </Row>
          <Row>
            {( !_.isEmpty( selectedContract ) && _.get( selectedContract, 'external' ) ) && (
            <Col md={8}>
              <TextField
                field="link"
                label="link"
                validate={required}
                translate
              />
            </Col>

            )}
          </Row>
          <Row>
            {/* <Col md={8} lg={8}>
              <TextField
                label={_.get( form.getState( ), 'values.contract.name', 'contractFile' )}
                type="file"
                labelClass="custom-file-label"
                inputClass="custom-file-input"
                formGroupClass="custom-file"
                accept="application/pdf"
                onChange={async ( val, e ) => {
                  const file = e.currentTarget.files[0];
                  if ( file ) {
                    form.change( 'contract', file );
                  }
                }}
              />
            </Col> */}
            {contractFile && !_.get( form.getState( ), 'values.contract.name', null ) && (
            <Col md={4} lg={4}>
              <Button color="primary" size="sm" className="mt-2" onClick={() => window.open( contractFile, '_blank' )}>
                <Intl id="viewContract" />
              </Button>
            </Col>
            )}
          </Row>
        </>
      )}
    />
  );
};

const EditContractModal = ( {
  modalOpened, handleClose, onSubmit, selectedContract,
} ) => {
  const isEditting = !_.isEmpty( selectedContract );
  return (
    <>
      {isEditting ? (
        <EditView
          selectedContract={selectedContract}
          onSubmit={onSubmit}
          modalOpened={modalOpened}
          handleClose={handleClose}
        />
      ) : (
        <NewContractModal
          selectedContract={selectedContract}
          onSubmit={onSubmit}
          modalOpened={modalOpened}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default EditContractModal;
