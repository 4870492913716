/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  AddBtn,
  AsyncTable,
  DaterangePickerBtn,
  Intl,
  SimpleHeader,
  Switch,
  TableActionsBtns,
  TableEditBtn,
  TableGenericFilter,
  TableSelectFilter,
  TableToggler,
} from 'components';
import Container from 'reactstrap/lib/Container';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

const filterColumnMd = 6;
const filterColumnLg = 2;

export default React.memo(
  ( {
    data,
    onFetchData,
    onFetchAccommodations,
    onDeleteItem,
    onOpenNewItemModal,
    onOpenEditItemModal,
    isDataLoading,
  } ) => (
    <>
      <SimpleHeader
        title="occupancies"
      />
      <Container fluid>
        <AsyncTable
          id="occupancies"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isDataLoading}
          disableSorting
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter
                  filters={filters}
                  setFilter={setFilter}
                />
              </Col>

              <TableToggler />
            </Row>
          )}
          customToggleFilters={( { filters, setFilter, setMultipleFilters } ) => (
            <Row className="ml-0">
              <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
                <TableSelectFilter
                  name="accommodation"
                  label="accommodation"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  defaultOptions
                  loadOptions={onFetchAccommodations}
                  setFilter={setFilter}
                />
              </Col>
              <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
                <Switch
                  label="filterByDate"
                  value={filters.filterByDate}
                  inputOnChange={( ) => {
                    setFilter( 'filterByDate', filters.filterByDate === undefined ? 'true' : undefined );
                  }}
                />
              </Col>
              <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
                <DaterangePickerBtn
                  label="validBetween"
                  position="left"
                  input
                  clearable
                  initialValue={moment.range( filters.tillStart, filters.tillEnd )}
                  initialValueSet={!!filters && !!filters.tillStart && !!filters.tillEnd}
                  key={filters.tillStart + filters.tillEnd}
                  onChange={( values ) => {
                    setMultipleFilters( {
                      fromStart: values ? moment( values.start ).format( 'YYYY-MM-DD' ) : null,
                      fromEnd: values ? moment( values.end ).format( 'YYYY-MM-DD' ) : null,
                    } );
                  }}
                />
              </Col>
            </Row>
          )}
          rightContent={<AddBtn title="newOccupancy" onClick={() => onOpenNewItemModal()} />}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <TableEditBtn
                  title={cell.value.substr( 0, 6 )}
                  onClick={() => onOpenEditItemModal( cell.value )}
                />
              ),
            },
            {
              Header: 'name',
              accessor: 'name',
            },
            {
              Header: 'validUntil',
              accessor: 'validUntil',
              Cell: ( { cell } ) => (
                cell.value
                  ? moment.utc( new Date( cell.value ) ).isAfter( new Date(), 'day' )
                    ? moment.utc( new Date( cell.value ) ).format( 'DD/MM/YYYY' )
                    : <Intl id="expired" />
                  : <Intl id="expired" />
              ),
            },
            {
              Header: 'accommodationsNumber',
              accessor: 'accommodationsNumber',
            },
            {
              Header: 'minimunNights',
              accessor: 'minimunNights',
            },
            {
              accessor: 'id',
              disableSorting: true,
              Cell: ( { cell } ) => (
                <TableActionsBtns
                  actions={[
                    {
                      isDelete: true,
                      onClick: ( cb ) => onDeleteItem( cell.value, cb ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </Container>

    </>
  ),
);
