import React from 'react';

export default ( { size = 30 } ) => (
  <div
    style={{
      position: 'absolute',
      left: `-${size / 2}px`,
      top: `-${size * 1.4}px`,
      width: `${size}px`,
      height: `${size * 1.4}px`,
      cursor: 'crosshair',
    }}
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 28"
      version="1.1"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
    >
      <g id="Artboard1" transform="matrix(0.025,0,0,0.0466667,0,0)">
        <rect x="0" y="0" width="800" height="600" style={{ fill: 'none' }} />
        <clipPath id="_clip1">
          <rect x="0" y="0" width="800" height="600" />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <g transform="matrix(10.0178,0,0,5.19355,-3148.44,-876.498)">
            <path
              d="M354.215,168.767C376.252,168.767 394.144,186.658 394.144,208.696C394.144,230.733 354.215,284.295 354.215,284.295C354.215,284.295 314.286,230.733 314.286,208.696C314.286,186.658 332.177,168.767 354.215,168.767ZM354.215,187.6C365.858,187.6 375.31,197.053 375.31,208.696C375.31,220.339 365.858,229.791 354.215,229.791C342.572,229.791 333.119,220.339 333.119,208.696C333.119,197.053 342.572,187.6 354.215,187.6Z"
              style={{
                fill: 'rgb(255,13,187)',
              }}
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);
