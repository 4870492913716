import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import BookingItemService from 'api/BookingItem';
import SettingsActions from 'store/reducers/Settings';

import BookingOverviewView from 'views/DashboardCalendar/BookingOverview';
import useFetch from 'hooks/useFetch';
import TaskService from 'api/Task';

const BookingOverview = ( {
  bookingId, toggleErrorAlert, open, onClose, onOpenTaskEditModal, reloadDataBookingTask,
} ) => {
  const { getTasks } = TaskService;
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => BookingItemService.getBookingItem( bookingId ),
    toggleErrorAlert,
    conditional: !!bookingId && open,
    deps: [open, bookingId],
    reInit: true,
  } );

  const [tasks] = useFetch( {
    initialState: [],
    promise: () => getTasks( { bookedAccommodation: bookingId } ),
    format: ( dataToFormat ) => _get( dataToFormat, 'data', [] ),
    toggleErrorAlert,
    conditional: !!bookingId && open,
    deps: [open, bookingId, reloadDataBookingTask],
    reInit: true,
  } );

  return (
    <BookingOverviewView
      data={{ ...data, tasks }}
      isLoading={dataLoading}
      open={open}
      onClose={onClose}
      onOpenTaskEditModal={( task ) => onOpenTaskEditModal( task, data )}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
} );
export default connect( null, mapDispatchToProps )( BookingOverview );
