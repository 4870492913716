/* eslint-disable import/prefer-default-export */
const formatDataToSend = ( discountId, seasonId, dataToSend ) => ( seasonId ? {
  ...dataToSend,
  id: seasonId,
  lastNight: new Date( dataToSend.lastNight ).toISOString(),
  startDate: new Date( dataToSend.startDate ).toISOString(),
} : {
  discountId,
  seasons: [
    dataToSend.requirements && dataToSend.requirements.earlyBookingDiscountBefore ? {
      ...dataToSend,
      requirements: {
        earlyBookingDiscountBefore: {
          ...dataToSend.requirements.earlyBookingDiscountBefore,
          bookingsMadeBefore: new Date(
            dataToSend.requirements.earlyBookingDiscountBefore.bookingsMadeBefore,
          ).toISOString(),
        },
      },
      lastNight: new Date( dataToSend.lastNight ).toISOString(),
      startDate: new Date( dataToSend.startDate ).toISOString(),
    } : {
      ...dataToSend,
      lastNight: new Date( dataToSend.lastNight ).toISOString(),
      startDate: new Date( dataToSend.startDate ).toISOString(),
    },
  ],
} );

export { formatDataToSend };
