import IncidenceService from 'api/incidences';
import TaskService from 'api/Task';

const loadIncidenceTasks = async ( id, params ) => {
  const { getIncidenceTasks } = IncidenceService;
  const { data } = await getIncidenceTasks( id, params );
  return {
    ok: true,
    data: {
      data: [...data],
      elementsInPage: data.length,
      elementsTotal: data.length,
    },
    status: 200,
  };
};

const deleteIncidenceTask = async ( id, taskId ) => {
  try {
    await IncidenceService.removeIncidenceTask( id, taskId );
    await TaskService.deleteTask( taskId );
    return {
      status: 200,
      ok: true,
    };
  } catch ( error ) {
    return {
      status: 500,
      ok: false,
      errors: error,
    };
  }
};

// eslint-disable-next-line import/prefer-default-export
export { loadIncidenceTasks, deleteIncidenceTask };
