import React from 'react';
import {
  Col, Row,
} from 'reactstrap';
import { FieldArray } from 'react-final-form-arrays';

import {
  TextField, Checkbox, Datepicker,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( { form, formId } ) => (
  <>
    <Row className="mb-2">
      <FieldArray name="activeDays">
        {( { fields } ) => (
          fields.map( ( day, index ) => {
            const dayData = form.getState().values.activeDays[index];
            return (
              <Col xs="auto" key={dayData.id}>
                <Checkbox
                  formGroupClass="m-0"
                  field={`${day}.active`}
                  label={dayData.name}
                  formId={formId}
                />
              </Col>
            );
          } )
        )}
      </FieldArray>
    </Row>
    <Row className="pl-3">
      <Col lg={4} md={6} className="pt-1 pl-0">
        <Datepicker
          dateFormat={false}
          timeFormat="H:mm"
          size="xsm"
          formGroupClass="d-flex align-items-baseline m-0"
          labelClass="font-italic mr-2 text-lowercase"
          field="from"
          label="from"
          validate={required}
        />
      </Col>
      <Col lg={4} md={6} className="pt-1 pl-0">
        <Datepicker
          dateFormat={false}
          timeFormat="H:mm"
          size="xsm"
          formGroupClass="d-flex align-items-baseline m-0"
          labelClass="font-italic mr-2 text-lowercase"
          field="till"
          label="till"
          validate={required}
        />
      </Col>
      <Col lg={4} md={6} className="pt-1 pl-0">
        <TextField
          size="xsm"
          formGroupClass="d-flex align-items-baseline m-0"
          labelClass="font-italic mr-2 text-lowercase"
          field="resources"
          type="number"
          label="resources"
          validate={required}
        />
      </Col>
    </Row>
  </>
) );
