import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import CovidMeasuresDashboardView from 'views/CovidMeasures/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
import AppSurvivalTipService from 'api/appSurvivalTip';
import FiltersActions from 'store/reducers/Filters';

const type = 'covidMeasures'; // advices | reminders | useful

const CovidMeasuresDashboard = ( {
  parentPath,
  defaultFilters,
  toggleLoading,
  toggleErrorAlert,
  updateFilters,
  setFiltersError,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AppSurvivalTipService.getList( { ...params, type } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( type, { keyword: params.keyword } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( type );
        initialReloadError.current = false;
      }
    },
  } );

  const deleteEntity = useDelete( {
    promise: AppSurvivalTipService.deleteItem,
    callback: () => {
      setDataSubmitted( new Date().valueOf() );
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <CovidMeasuresDashboardView
      data={data}
      parentPath={parentPath}
      isLoading={dataLoading}
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
      onDelete={deleteEntity}
      optionCities={[]}
      optionFields={[]}
    />
  );
};

const mapStateToProps = ( { filters/* , user */ } ) => ( {
  defaultFilters: _.get( filters, `filters.${type}` ) || {},
} );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( CovidMeasuresDashboard );
