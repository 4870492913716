import React, { useCallback, useRef, useState } from 'react';
import {
  Card, CardBody, CardHeader, CardFooter, Row, Col, Spinner,
} from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { animateScroll } from 'react-scroll';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';

import Message from '../Message';
import SendMessage from '../SendMessage';

const MessageList = React.memo( ( {
  data = [], messagesToSend, hasMore, userId, selectedChannelId, onFetchData, onSendMessage,
} ) => {
  const [imageModal, setImageModal] = useState( false );
  const imageToShow = useRef( null );

  const toggleImageModal = ( src ) => {
    imageToShow.current = src;
    setImageModal( !imageModal );
  };

  const onSend = useCallback( ( message, cb ) => {
    if ( selectedChannelId ) {
      const newMessage = {
        id: Math.random().toString(),
        created: Number( moment.utc().format( 'x' ) ),
        status: 'PENDING',
        owner: {
          id: userId,
        },
        ...message,
      };
      onSendMessage( newMessage );
      setTimeout( () => {
        animateScroll.scrollToBottom( {
          containerId: 'messagesContainer',
          duration: 100,
        } );
      } );
      cb();
    }
  }, [userId, selectedChannelId, onSendMessage] );

  const isSameDay = useCallback( ( currentMessage = {}, diffMessage = {} ) => {
    if ( !diffMessage.created ) return false;

    const currentCreatedAt = moment.utc( new Date( currentMessage.created ) );
    const diffCreatedAt = moment.utc( new Date( diffMessage.created ) );

    if ( !currentCreatedAt.isValid() || !diffCreatedAt.isValid() ) return false;

    return currentCreatedAt.isSame( diffCreatedAt, 'day' );
  }, [] );

  return (
    <Card className="chat-container chat-messages my-2 my-md-0">
      <CardHeader />
      <CardBody className="p-2" id="messagesContainer" key={selectedChannelId}>
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={() => onFetchData()}
          hasMore={hasMore}
          loader={<div key="loading" className="text-center py-2"><Spinner color="primary" size="sm" /></div>}
          useWindow={false}
          isReverse
        >
          {_.map( data, ( item, index ) => {
            const toShow = _.get( messagesToSend, item.id ) || item;
            const previous = messagesToSend ? messagesToSend[_.get( data, `${[index - 1]}.id` )] || data[index - 1] : data[index - 1];
            return (
              <div key={toShow.id}>
                {!isSameDay( toShow, previous )
                && (
                  <Row noGutters className="chat-messages-message-date">
                    <Col>
                      <span>{moment.utc( new Date( toShow.created ) ).local().format( 'MMM DD, YYYY' )}</span>
                    </Col>
                  </Row>
                )}
                <Message
                  message={toShow}
                  currentUserId={userId}
                  previousMessage={previous}
                  onToggleImageModal={toggleImageModal}
                  isSameDay={() => isSameDay( toShow, previous )}
                />
              </div>
            );
          } )}
        </InfiniteScroll>
      </CardBody>

      <CardFooter className="p-0" id="footer">
        <SendMessage onSend={onSend} disabled={!selectedChannelId} />
      </CardFooter>

      {imageModal && (
        <Lightbox
          mainSrc={imageToShow.current}
          nextSrc={null}
          prevSrc={null}
          onCloseRequest={toggleImageModal}
        />
      )}
    </Card>
  );
} );

const mapStateToProps = ( { user } ) => ( { userId: _.get( user, 'user.id' ) } );

export default connect( mapStateToProps, null )( MessageList );
