import React from 'react';
import classnames from 'classnames';
import {
  Card, CardBody, CardHeader,
} from 'reactstrap';

import { Intl } from 'components';

export default React.memo( ( {
  children, header, title, translateTitle = true, isLoading, classNames = {}, borderless,
} ) => (
  <Card
    data-cy="detail-card"
    className={classnames( 'm-0', classNames.card, { 'app-component-loading': isLoading } )}
  >
    {header || title
      ? (
        <CardHeader className={classnames( classNames.header, { 'border-0': borderless } )}>
          {header || (
          <h4 className="mb-0">
            {translateTitle ? <Intl id={title} /> : title }
          </h4>
          )}
        </CardHeader>
      ) : null}

    <CardBody className={borderless ? classnames( classNames.body, 'pt-0' ) : classNames.body || 'pt-2'}>
      {children}
    </CardBody>
  </Card>
) );
