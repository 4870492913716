import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CommunityRuleNewView from 'views/Property/Edit/CommunityRule/New';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import CommunityRuleService from 'api/CommunityRule';
import PropertyService from 'api/Property';

const CommunityRuleNew = ( {
  entityId, entityCommunityRules, data, modalOpened,
  onReload, onCloseModal, toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [communityRules, communityRulesLoading] = useFetch( {
    initialState: { data: [] },
    promise: () => CommunityRuleService.getCommunityRules( { elementsPerPage: 1000 } ),
    format: ( dataToFormat ) => {
      const addedCommunityRules = data && data.entity ? _.map( entityCommunityRules, 'id' ) : [];
      return _.filter( dataToFormat.data, ( item ) => _.indexOf(
        addedCommunityRules, item.id,
      ) === -1 );
    },
    toggleErrorAlert,
    conditional: modalOpened,
    reInit: true,
    deps: [data, modalOpened],
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => PropertyService.updateProperty( entityId, dataToSend ),
    format: ( dataToFormat ) => ( {
      communityRules: [..._.map( entityCommunityRules, 'id' ), ...dataToFormat.communityRules],
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: onReload,
    deps: [data, entityCommunityRules, entityId, onReload],
  } );

  return (
    <CommunityRuleNewView
      options={{ communityRules }}
      open={modalOpened}
      isLoading={communityRulesLoading}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CommunityRuleNew );
