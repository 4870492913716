import React from 'react';
import { connect } from 'react-redux';
import CustomerEditView from 'views/Customer/Edit/OverView';
import SettingsActions from 'store/reducers/Settings';
import EmployeeService from 'api/Employee';

import { useSubmit } from 'hooks';

const CustomerEdit = ( {
  data, isLoading, parentPath, toggleLoading, toggleInfoAlert, entityId, onReload,
} ) => {
  const submitForm = useSubmit( {
    promise: ( dataToSend ) => EmployeeService.updateEmployee( entityId, dataToSend ),
    toggleInfoAlert,
    toggleLoading,
    deps: [entityId],
    callback: onReload,
    reInit: true,
  } );

  return (
    <CustomerEditView
      data={data}
      parentPath={parentPath}
      isLoading={isLoading}
      entityId={entityId}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CustomerEdit );
