import React from 'react';
import { Badge, Row, Col } from 'reactstrap';
import moment from 'moment';
import numeral from 'numeral';
import PaymentInfo from 'containers/Booking/Edit/PaymentInfo';

import {
  Intl, DetailCard, LocalTable, AddBtn, TableEditBtn, TableActionsBtns,
} from 'components';
import { currencyFormat } from 'utils';

export default React.memo( ( {
  data, userCurrency, handleOpen, onDelete,
} ) => (
  <DetailCard
    classNames={{ body: 'pt-0 pb-2 px-0', card: 'card-sec-bottom' }}
  >

    <div className="py-2 px-3 border-0">
      <Row className="justify-content-stretch align-items-center">
        <Col>
          <h3 className="m-0">
            <Intl id="payments" />
          </h3>
        </Col>
        <Col className="text-right">
          <AddBtn title="newPayment" onClick={handleOpen} />
        </Col>
      </Row>
    </div>

    <LocalTable
      onlyTable
      manualPagination
      data={data.payments || []}
      columns={[
        {
          Header: 'description',
          accessor: 'description',
          Cell: ( { cell } ) => ( cell.row.original.id
            ? (
              <TableEditBtn
                className="text-uppercase"
                title={cell.value}
                onClick={() => handleOpen( cell.row.original )}
              />
            )
            : '' ),
        },
        {
          Header: 'amount',
          accessor: 'amount',
          Cell: ( { cell } ) => ( cell.value ? numeral( ( cell.value ) ).format( currencyFormat( userCurrency ) ) : '' ),
        },
        {
          Header: 'gateway',
          accessor: 'gateway',
          Cell: ( { cell } ) => {
            if ( cell.value === 'addon_payments' ) return <Badge color="green-2"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'external' ) return <Badge color="red-1"><Intl id={cell.value} /></Badge>;
            return '';
          },
        },
        {
          Header: 'date',
          accessor: 'date',
          Cell: ( { cell } ) => ( cell.value

            ? moment( cell.value ).format( 'YYYY-MM-DD' )
            : '' ),
        },
        {
          Header: 'notificationDate',
          accessor: 'notificationDate',
          Cell: ( { cell } ) => ( cell.value

            ? moment( cell.value ).format( 'YYYY-MM-DD' )
            : '' ),
        },
        {
          Header: 'status',
          accessor: 'status',
          Cell: ( { cell } ) => {
            if ( cell.value === 'pending' ) return <Badge color="green-3"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'paid' ) return <Badge color="green-2"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'denied' ) return <Badge color="red-1"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'partial_refunded' ) return <Badge color="warning"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'refunded' ) return <Badge color="info"><Intl id={cell.value} /></Badge>;
            return '';
          },
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ]}
            />
          ),
        },
      ]}
    />
    <PaymentInfo bookingData={data} />
  </DetailCard>
) );
