import React from 'react';
import { useDelete } from 'hooks';
import ShiftService from 'api/Shift';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import MultiShiftView from 'views/DashboardShift/MultiShift';

const MultiShift = ( {
  modalOpened, onCloseModal, shifts, onPressShift, reloadData, toggleLoading, toggleErrorAlert,
} ) => {
  const deleteShift = useDelete( {
    promise: ShiftService.deleteShift,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <MultiShiftView
      open={modalOpened}
      onClose={onCloseModal}
      shifts={shifts}
      onPressShift={onPressShift}
      onPressDelete={deleteShift}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( MultiShift );
