/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import {
  Intl,
} from 'components';

export default ( { list, limit = 9999, render } ) => {
  const [page, setPage] = useState( 1 );

  if ( !list || !list.length ) {
    return '-';
  }
  return (
    <>
      {list.map( ( p, i ) => {
        if ( i >= ( page * limit ) ) {
          return null;
        }
        return render ? render( p, i ) : <div className="small" key={p.id}>{p.name}</div>;
      } )}

      {list.length > limit
        ? (
          <>
            {list.length > ( page * limit ) ? (
              <a
                className="small"
                href="/"
                onClick={( e ) => {
                  e.preventDefault();
                  setPage( ( v ) => v + 1 );
                }}
              >
                {`+${list.length - ( page * limit )} `}
                <Intl id="more" />
              </a>
            ) : null }
            {page > 1 ? (
              <>
                {' | '}
                <a
                  className="small"
                  href="/"
                  onClick={( e ) => {
                    e.preventDefault();
                    setPage( ( v ) => v - 1 );
                  }}
                >

                  <Intl id="less" />
                </a>
              </>
            ) : null}
          </>
        ) : null}
    </>
  );
};
