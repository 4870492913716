/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  Select,
} from 'components';
import ListView from 'containers/PropertyManager/Edit/Discounts/EditDrawer/Seasons/List';
import MonthView from 'containers/PropertyManager/Edit/Discounts/EditDrawer/Seasons/Month';
import WeekView from 'containers/PropertyManager/Edit/Discounts/EditDrawer/Seasons/Week';
import DaysView from 'containers/PropertyManager/Edit/Discounts/EditDrawer/Seasons/Day';
import { Container } from 'reactstrap';

export default React.memo(
  ( {
    open,
    discountId,
    seasonId,
    dataSubmitted,
    initialData,
    viewTypes,
    onFetchData,
    onDeleteItem,
    onReloadData,
    onOpenEditItemModal,
    onCloseModal,
    onOpenNewModal,
  } ) => {
    const [selectedView, setSelectedView] = useState( 1 );
    return (
      <>
        <Container>
          <Select
            overlap
            label="viewBy"
            options={viewTypes}
            defaultValue={viewTypes[0]}
            value={selectedView}
            onChange={( item ) => {
              setSelectedView( item );
            }}
            translateOptions
          />
        </Container>
        <ListView
          open={open}
          discountId={discountId}
          seasonId={seasonId}
          visible={selectedView === 1}
          initialData={initialData}
          dataSubmitted={dataSubmitted}
          onFetchData={onFetchData}
          onDeleteItem={onDeleteItem}
          onReloadData={onReloadData}
          onOpenNewModal={onOpenNewModal}
          onCloseModal={onCloseModal}
          onOpenEditItemModal={onOpenEditItemModal}
        />
        <MonthView
          visible={selectedView === 2}
          discountId={discountId}
          onReloadDiscounts={onReloadData}
          onOpenEditItemModal={onOpenEditItemModal}
        />
        <WeekView
          discountId={discountId}
          visible={selectedView === 3}
          dataEdited={dataSubmitted}
          onReloadDiscounts={onReloadData}
          onOpenEditItemModal={onOpenEditItemModal}
        />
        <DaysView
          discountId={discountId}
          visible={selectedView === 4}
          dataEdited={dataSubmitted}
          onReloadDiscounts={onReloadData}
          onOpenEditItemModal={onOpenEditItemModal}
        />
      </>
    );
  },
);
