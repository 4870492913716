import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import EventEditView from 'views/Event/Edit';
import EventService from 'api/Event';
import EventImageService from 'api/EventImage';
import CustomerService from 'api/Customer';
import PropertyService from 'api/Property';
import RegionServices from 'api/Region';
import TranslationService from 'api/Translation';
import CityService from 'api/Settings/City';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import { formatDataToSend, formatArrayToSelectValues, handlePromise } from 'utils';
import { each } from 'utils/async';

const EventEdit = ( {
  propertyManager,
  toggleErrorAlert,
  toggleInfoAlert,
  toggleLoading,
  parentPath,
  history,
  match,
} ) => {
  const eventId = match.params.id;
  const [data, dataLoading, setData] = useFetch( {
    initialState: {},
    promise: () => EventService.getEvent( eventId ),
    format: ( dataToFormat ) => {
      if ( !eventId ) return dataToFormat;
      const formattedData = { ...dataToFormat };
      if ( _.get( formattedData, 'city.id' ) ) {
        formattedData.city = {
          value: formattedData.city.id,
          label: formattedData.city.name,
        };
      }
      formattedData.properties = formatArrayToSelectValues(
        dataToFormat.properties,
      );
      return formattedData;
    },
    translationPromise: () => TranslationService.getCmsTranslations( eventId, 'event' ),
    toggleErrorAlert,
    conditional: !!eventId,
    deps: [eventId],
    reInit: true,
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const dataToSend = formatDataToSend( formData, form, true );
      const formattedData = {
        ...dataToSend,
        images: undefined,
        date:
          dataToSend.date && _.isObject( dataToSend.date )
            ? dataToSend.date.format( 'YYYY-MM-DD HH:mm:00' )
            : moment( dataToSend.date ).format( 'YYYY-MM-DD HH:mm:00' ),
      };
      const [errors, response, responseData] = await handlePromise(
        eventId
          ? EventService.updateEvent( eventId, formattedData )
          : EventService.saveEvent( formattedData ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      return new Promise( ( resolve, reject ) => {
        each(
          formData.images,
          async ( image, eCb ) => {
            if ( !image.file ) return eCb();

            const [imageErrors, imageResponse] = await handlePromise(
              EventImageService.saveImage( eventId || responseData.id, {
                image: image.file,
              } ),
            );
            if ( !imageResponse.ok ) return eCb( imageErrors );
            eCb();
          },
          ( error ) => {
            if ( error ) {
              toggleLoading( false );
              return reject( error );
            }
            toggleLoading( false );
            toggleInfoAlert( 'dataSaved' );
            resolve();
            history.push( parentPath );
          },
        );
      } ).catch( ( error ) => Promise.resolve( error ) );
    },
    [eventId, history, parentPath, toggleInfoAlert, toggleLoading],
  );

  const saveImage = useCallback(
    async ( images ) => {
      if ( !eventId ) return;
      toggleLoading( true );

      const newImages = _.cloneDeep( images );
      let newData = {};
      each(
        newImages,
        async ( image, eCb ) => {
          if ( !image.file ) return eCb();

          const [errors, response, responseData] = await handlePromise(
            EventImageService.saveImage( eventId, { image: image.file } ),
          );
          if ( !response.ok ) return eCb( errors );
          // eslint-disable-next-line no-param-reassign
          newData = responseData;
          eCb();
        },
        ( error ) => {
          toggleLoading( false );
          if ( error ) return toggleErrorAlert( error );
          setData( { ...data, images: newData.images } );
        },
      );
    },
    [eventId, data, setData, toggleErrorAlert, toggleLoading],
  );

  const deleteImage = useCallback(
    async ( index ) => {
      toggleLoading( true );
      const [imageErrors, imageResponse] = await handlePromise(
        EventImageService.deleteImage( eventId, index + 1 ),
      );
      toggleLoading( false );
      if ( !imageResponse.ok ) return toggleErrorAlert( imageErrors );
      const newImages = _.cloneDeep( data.images );
      newImages.splice( index, 1 );
      setData( { ...data, images: newImages } );
    },
    [eventId, data, setData, toggleErrorAlert, toggleLoading],
  );

  const fetchCustomers = useCallback(
    ( query ) => CustomerService.getCustomers( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );
  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 500,
      keyword: query,
    } ),
    [],
  );

  const fetchCities = useCallback(
    () => CityService.getCities( {
      propertyManager: propertyManager.id,
    } ),
    [propertyManager.id],
  );

  /* REGIONS ******************************** */
  const [regions, regionsLoading] = useFetch( {
    initialState: [],
    promise: () => RegionServices.getRegions( { page: 1, elementsPerPage: 999 } ),
    toggleErrorAlert,
    format: ( results ) => {
      if ( results && results.data ) {
        return results.data.map( ( { id, name } ) => ( { id, name } ) );
      }
      return [];
    },
  } );
    /* end REGIONS ******************************** */

  return (
    <EventEditView
      parentPath={parentPath}
      eventId={eventId}
      data={data}
      isLoading={dataLoading}
      onSubmit={submitForm}
      onAddImage={saveImage}
      onDeleteImage={deleteImage}
      onFetchCustomers={fetchCustomers}
      onFetchCities={fetchCities}
      onFetchProperties={fetchProperties}
      regions={regions}
      regionsLoading={regionsLoading}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( EventEdit );
