import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import {
  TextField, ModalForm, Datepicker, Select,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import { accessRequestStatuses } from 'config/Constants';

export default React.memo( ( {
  accessRequestId, data, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    initialValues={data}
    title={accessRequestId ? 'editRequest' : 'newRequest'}
    showErrorsInFooter
    isLoading={isLoading}
    bodyClassName="overflow-visible"
    open={open}
    onClose={onClose}
    zIndex={9999}
    onSubmit={canModify( 'task' ) ? onSubmit : () => {}}
    formEnabled={canModify( 'task' )}
    body={() => (
      <>
        <Row>
          <Col md={3}>
            <Datepicker
              field="date"
              label="date"
              dateFormat="DD/MM/YYYY"
              timeFormat="HH:mm"
              utc
              validate={required}
              startDateField={{ value: moment() }}
            />
          </Col>
          <Col md={{ size: 3, offset: 3 }}>
            <Select
              field="status"
              label="status"
              validate={required}
              options={accessRequestStatuses}
              translateOptions
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextField
              field="comments"
              placeholder="comments"
              type="textarea"
              rows={5}
              validate={required}
            />
          </Col>
        </Row>
      </>
    )}
  />
) );
