import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import classnames from 'classnames';
import _ from 'lodash';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { canModify } from 'config/Ability';

import {
  SimpleHeader,
  Form,
  TextField,
  FormActionsBtns,
  EditCard,
  Intl,
  RoleGroup,
  Switch,
} from 'components';
import { required } from 'config/InputErrors';
import { aclCategories } from 'config/Constants/Roles';

export default React.memo( ( {
  entityId, data, roles, isLoading, parentPath, action, onSubmit,
} ) => {
  const [openedCollapses, setOpenedCollapses] = useState( _.map( roles, 'name' ) );
  const [allExpanded, setAllExpanded] = useState( true );
  const editLabel = entityId ? 'editRole' : 'newRole';

  // console.log( data );

  useEffect( () => {
    setOpenedCollapses( _.map( roles, 'name' ) );
  }, [roles] );

  const collapsesToggle = ( collapse ) => {
    const openedCollapsesCopy = [...openedCollapses];
    const index = openedCollapsesCopy.indexOf( collapse );

    if ( index !== -1 ) {
      openedCollapsesCopy.splice( index, 1 );
    } else {
      openedCollapsesCopy.push( collapse );
    }
    setOpenedCollapses( openedCollapsesCopy );
  };

  const closeAll = () => {
    setOpenedCollapses( [] );
    setAllExpanded( false );
  };

  const openAll = () => {
    setOpenedCollapses( _.map( roles, 'name' ) );
    setAllExpanded( true );
  };

  return (
    <>
      <SimpleHeader
        parentLinks={[{ name: 'roles', href: parentPath }]}
      />

      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <EditCard title={action === 'clone' ? 'cloneRole' : editLabel}>
          <Form
            initialValues={data}
            onSubmit={canModify( 'settings' ) ? onSubmit : () => {}}
            showErrorsInFooter
            mutators={{ ...arrayMutators }}
            body={( form ) => (
              <>
                <Row>
                  <Col md={3}>
                    <TextField
                      field="name"
                      label="name"
                      validate={required}
                    />
                  </Col>
                  <Col md={5}>
                    <TextField
                      field="description"
                      label="description"
                      validate={required}
                    />
                  </Col>
                  <Col md={4}>
                    <Switch
                      field="notRestrictedByTeamRules"
                      label="notRestrictedByTeamRules"
                    />
                  </Col>
                </Row>

                <Row className="my-2">
                  <Col>
                    <span className="font-weight-500 mr-2">
                      <Intl id="permissions" />
                    </span>
                    (
                    <span className="font-weight-500 cursor-pointer text-sm" onClick={allExpanded ? closeAll : openAll}>
                      <Intl id={allExpanded ? 'closeAll' : 'openAll'} />
                    </span>
                    )
                  </Col>
                </Row>

                {aclCategories.map( ( aclCat ) => {
                  const { title, acls } = aclCat;
                  return (
                    <div key={title}>
                      <hr />
                      <h3><Intl id={title} /></h3>
                      <Row>
                        <FieldArray name="aclList">
                          {( { fields } ) => (
                            fields.map( ( aclItem, index ) => {
                              const aclItemData = _.get( form.getState().values, `aclList[${index}]` );
                              if ( acls.indexOf( aclItemData.name ) >= 0 ) {
                                return (
                                  <Col md={4} key={aclItemData.name}>
                                    <RoleGroup
                                      name={aclItemData.name}
                                      values={_.get( form.getState().values, `aclList[${index}]` )}
                                      field={aclItem}
                                      openedCollapses={openedCollapses}
                                      collapsesToggle={collapsesToggle}
                                    />
                                  </Col>
                                );
                              }
                              return null;
                            } )
                          )}
                        </FieldArray>
                      </Row>
                    </div>
                  );
                } )}
              </>
            )}
            footer={<FormActionsBtns cancelTo={parentPath} />}
          />
        </EditCard>
      </Container>
    </>
  );
} );
