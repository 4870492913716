import React from 'react';
import classnames from 'classnames';

export default ( { leftIsActive = true, rightIsActive = false } ) => (
  <span className="app-separator">
    <span className={classnames( {
      'circle-primary-small': leftIsActive,
      'circle-outline-primary-small': !leftIsActive,
    } )}
    />
    <div className="app-separator-line" />
    <div className="app-separator-line" />
    <span className={classnames( {
      'circle-primary-small': rightIsActive,
      'circle-outline-primary-small': !rightIsActive,
    } )}
    />
  </span>
);
