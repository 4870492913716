import api from 'config/Api/RUApi';

export default {
  getTokens( body ) {
    return api.post(
      '/whitepms/oauth2/token',
      body,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
    );
  },
};
