import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/scheduled-notification';

export default {
  getScheduledNotification( params ) {
    return api.get( baseEndpoint, params );
  },
  getScheduledNotificationById( id, params ) {
    return api.get( `${baseEndpoint}/${id}`, params );
  },
  saveScheduledNotification( data ) {
    return api.post( baseEndpoint, data );
  },
  updateScheduledNotification( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  updateBanner( id, data ) {
    return api.post( `${baseEndpoint}/${id}/banner`, formatFormDataBody( data ) );
  },
};
