import React, { useCallback, useMemo } from 'react';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Col,
} from 'reactstrap';
import { Intl } from 'components';

const PaginationComponent = ( {
  page = 1, setPage, elementsPerPage = 10, elementsTotal = 0,
} ) => {
  const gotoPage = useCallback( ( newPage ) => {
    if ( setPage ) { setPage( newPage ); }
  }, [setPage] );

  const { canPreviousPage, canNextPage, totalPages } = useMemo( () => {
    const total = Math.ceil( elementsTotal / elementsPerPage );
    return {
      canPreviousPage: page > 1,
      canNextPage: page < total,
      totalPages: total,
    };
  }, [page, elementsTotal, elementsPerPage] );

  return (
    <nav aria-label="...">
      <Row className="justify-content-end align-items-center">
        <Col md="auto">
          <span className="text-sm text-muted">
            <Intl id="tablePaginationLabel" values={{ totalElements: elementsTotal, page, totalPages }} />
          </span>
        </Col>
        <Col md="auto" className="pt-2 pt-md-0">
          <Pagination
            data-cy="pagination"
            className="pagination justify-content-end mb-0"
            listClassName="justify-content-end mb-0"
          >
            <PaginationItem
              onClick={() => canPreviousPage && gotoPage( 1 )}
              disabled={!canPreviousPage}
            >
              <PaginationLink type="button">
                <i className="fas fa-angle-double-left" />
                <span className="sr-only">First</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem
              onClick={() => canPreviousPage && gotoPage( page - 1 )}
              disabled={!canPreviousPage}
            >
              <PaginationLink type="button" tabIndex="-1">
                <i className="fas fa-angle-left" />
                <span className="sr-only">Previous</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink type="button" tabIndex="-1">
                {page}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem
              onClick={() => canNextPage && gotoPage( page + 1 )}
              disabled={!canNextPage}
            >
              <PaginationLink type="button">
                <i className="fas fa-angle-right" />
                <span className="sr-only">Next</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem
              onClick={() => canNextPage && gotoPage( totalPages )}
              disabled={!canNextPage}
            >
              <PaginationLink tabIndex="-1" type="button">
                <i className="fas fa-angle-double-right" />
                <span className="sr-only">Last</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </nav>
  );
};

export default PaginationComponent;
