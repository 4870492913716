import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const GET_ACTIVITIES = gql`
query GetActivities($contactId: String!, $page: Int!, $limit: Int!, $propertyManager: String!) {
  Activities(limit: $limit, page: $page, filters: {contactId: $contactId}, propertyManager: $propertyManager) {
    ... on BaseError {
      message
    }
    ... on QueryActivitiesSuccess {
      __typename
      data {
        elementsPerPage
        total
        data {
          contactId
          createdAt
          data
          description
          id
          type
          updatedAt
        }
      }
    }
  }
}
`;

const getActivities = async ( contactId, page, elementsPerPage ) => {
  const { data } = await client.query( {
    query: GET_ACTIVITIES,
    variables: {
      page: page || 1, // pageCurrent,
      limit: elementsPerPage || 10,
      contactId: contactId || '',
      propertyManager: getPropertyManager(),
    },
    fetchPolicy: 'no-cache',
  } );

  const activities = data.Activities.data.data || [];
  const elementsTotal = data.Activities.data.total || 0;

  if ( data.Activities.message ) {
    return {
      ok: false,
      message: data.Activities.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: { data: activities, elementsTotal },
  };
};

export default getActivities;
