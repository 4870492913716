export const notificationTypes = [
  { id: 'task', name: 'notification.type.task' },
  { id: 'booking', name: 'notification.type.booking' },
];

const notificationStatusColors = {
  executed: 'info',
  processed: 'green',
  dropped: 'danger',
  deferred: 'orange',
  bounce: 'warning',
  blocked: 'red',
  open: 'success',
  click: 'success',
  spamreport: 'danger',
  spanreport: 'danger',
  unsubscribe: 'info',
  group_unsubscribe: 'info',
  group_resubscribe: 'info',
  delivered: 'green',
};

export default notificationStatusColors;
