import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EditView from 'views/Property/RoomType/Accommodation/Edit/Discounts/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import { createDiscount, getDiscount } from 'api/Discounts';
import updateDiscount from 'api/Discounts/updateDiscount';

const Edit = ( {
  onClose,
  discountId,
  accId,
  open,
  openDrawer,
  propertyManagerId,
  toggleInfoAlert,
  toggleLoading,
  toggleErrorAlert,
  onReloadData,
  isLoading,
} ) => {
  const [data] = useFetch( {
    promise: () => getDiscount( { discountId } ),
    toggleErrorAlert,
    conditional: !!discountId,
    deps: [discountId],
    reInit: true,
  } );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( discountId
      ? updateDiscount( dataToSend ) : createDiscount( dataToSend ) ),
    format: ( dataToSend ) => ( discountId ? {
      discountId,
      name: dataToSend.name,
    } : {
      ...dataToSend,
      accommodationsId: [accId],
      validateAccommodations: true,
      propertyManagerId,
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: ( __, response ) => {
      if ( response ) {
        openDrawer( response.id );
      }
      onReloadData();
    },
    deps: [discountId],
  } );

  return (
    <>
      <EditView
        data={data}
        onSubmit={onSubmit}
        onClose={onClose}
        open={open}
        isLoading={isLoading}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManagerId: _.get( user, 'user.propertyManager.id', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( Edit );
