import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EditView from 'views/PropertyManager/Edit/Occupancies/New';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import updateOccupancy from 'api/Occupancy/updateOccupancy';
import { createOccupancy, getOccupancy } from 'api/Occupancy';

const Edit = ( {
  onClose,
  occupancyId,
  open,
  openDrawer,
  propertyManager,
  onFetchAccommodations,
  toggleInfoAlert,
  toggleLoading,
  toggleErrorAlert,
  onReloadData,
  isLoading,
} ) => {
  const [data] = useFetch( {
    promise: () => getOccupancy( { occupancyId } ),
    toggleErrorAlert,
    conditional: !!occupancyId,
    deps: [occupancyId],
    reInit: true,
  } );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( occupancyId
      ? updateOccupancy( dataToSend ) : createOccupancy( dataToSend ) ),
    format: ( dataToSend ) => ( occupancyId ? {
      occupancyId,
      name: dataToSend.name,
    } : {
      ...dataToSend,
      validateAccommodations: true,
      propertyManagerId: propertyManager.id,
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: ( __, response ) => {
      if ( response ) {
        openDrawer( response.id );
      }
      onReloadData();
    },
    deps: [occupancyId],
  } );

  return (
    <>
      <EditView
        data={data}
        onSubmit={onSubmit}
        onFetchAccommodations={onFetchAccommodations}
        onClose={onClose}
        open={open}
        isLoading={isLoading}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( Edit );
