import React from 'react';
import {
  Container, Row, Col, Badge, UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import {
  SimpleHeader, AsyncTable, TableToggler, DaterangePickerBtn, TableSelectFilter, Intl, TextField,
  AddBtn, Switch,
} from 'components';
import ability from 'config/Ability';
import { bookingStatuses, otaStatuses, cleaningStatus } from 'config/Constants';
import formatBookinglId from 'utils/bookingId';
// import { getResourceName } from 'utils';
import getStatusColor from 'config/Constants/StatusColor';

const statusColor = getStatusColor( 'bookings' );

const filterColumnMd = 6;
const filterColumnLg = 2;

export default React.memo( ( {
  data, parentPath, isLoading, defaultFilters,
  onFetchData, onFetchAccommodations, /* onFetchCustomers, */
  onOpenNewModal, onFetchProperties, onFetchTagsPredefined,
  userBookingReference, Cities, zones,
} ) => (
  <>
    <SimpleHeader
      title="bookings"
    />
    <Container fluid>
      <AsyncTable
        id="bookings"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        customFiltersOpened={defaultFilters && ( defaultFilters.tillStart || defaultFilters.tillEnd
          || defaultFilters.accommodation || defaultFilters.status )}
        onFetchData={onFetchData}
        customFilters={( { filters, setMultipleFilters } ) => (
          <Row>
            <Col md={8} lg={4}>
              <DaterangePickerBtn
                position="left"
                label="checkInBetween"
                initialValue={moment.range( filters.fromStart || moment(), filters.fromEnd || moment().add( 7, 'days' ) )}
                key={filters.fromStart + filters.fromEnd}
                input
                onChange={( values ) => {
                  setMultipleFilters( {
                    fromStart: moment( values.start ).format( 'YYYY-MM-DD' ),
                    fromEnd: moment( values.end ).format( 'YYYY-MM-DD' ),
                  } );
                }}
              />
            </Col>
            <TableToggler />
          </Row>
        )}
        customToggleFilters={( { filters, setFilter, setMultipleFilters } ) => (
          <Row className="ml-0">
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <DaterangePickerBtn
                label="checkOutBetween"
                position="left"
                input
                clearable
                initialValue={moment.range( filters.tillStart, filters.tillEnd )}
                initialValueSet={!!filters && !!filters.tillStart && !!filters.tillEnd}
                key={filters.tillStart + filters.tillEnd}
                onChange={( values ) => {
                  setMultipleFilters( {
                    tillStart: values ? moment( values.start ).format( 'YYYY-MM-DD' ) : null,
                    tillEnd: values ? moment( values.end ).format( 'YYYY-MM-DD' ) : null,
                  } );
                }}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="property"
                label="property"
                placeholder="all"
                filters={filters}
                isClearable
                defaultOptions
                loadOptions={onFetchProperties}
                setFilter={setFilter}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="accommodation"
                label="accommodation"
                placeholder="all"
                filters={filters}
                isClearable
                defaultOptions
                loadOptions={onFetchAccommodations}
                setFilter={setFilter}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="status"
                label="status"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                options={bookingStatuses}
                setFilter={setFilter}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="checkinComplete"
                label="checkinComplete"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                setFilter={setFilter}
                options={[
                  {
                    name: 'pending',
                    id: 'false',
                  },
                  {
                    name: 'completed',
                    id: 'true',
                  },
                ]}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="cleaningStatus"
                label="cleaningStatus"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                options={cleaningStatus}
                setFilter={setFilter}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TextField
                name="externalId"
                label="externalId"
                placeholder="searchByExternalId"
                formGroupClass="m-0"
                value={filters.externalId}
                onChange={( value ) => setFilter( 'externalId', value )}
              />
            </Col>
            {/* <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="owner"
                label="owner"
                placeholder="all"
                filters={filters}
                isClearable
                defaultOptions
                labelKey={getResourceName}
                loadOptions={onFetchCustomers}
                setFilter={setFilter}
              />
            </Col> */}
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TextField
                name="localizator"
                label="localizator"
                placeholder="searchBylocalizator"
                formGroupClass="m-0"
                value={filters.localizator}
                onChange={( value ) => setFilter( 'localizator', value )}
              />
            </Col>

            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="travelReportStatus"
                label="travelReportStatus"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                setFilter={setFilter}
                options={[
                  {
                    name: 'pending',
                    id: 'PENDING',
                  },
                  {
                    name: 'completed',
                    id: 'COMPLETE',
                  },
                  {
                    name: 'submited',
                    id: 'SUBMITED',
                  },
                  {
                    name: 'not_required',
                    id: 'NOT_REQUIRED',
                  },
                ]}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="ota"
                label="ota"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                options={otaStatuses}
                setFilter={setFilter}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="appLogin"
                label="appDownloaded"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                setFilter={setFilter}
                options={[
                  {
                    name: 'yes',
                    id: 'true',
                  },
                  {
                    name: 'no',
                    id: 'false',
                  },
                ]}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="notRealEmail"
                label="customerEmail"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                setFilter={setFilter}
                options={[
                  {
                    name: 'yes',
                    id: 'false',
                  },
                  {
                    name: 'no',
                    id: 'true',
                  },
                ]}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="city"
                label="city"
                placeholder="all"
                filters={filters}
                isClearable
                defaultOptions
                setFilter={setFilter}
                options={Cities}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                enableReinitialize
                isDisabled={zones.length === 0}
                name="zoneIds"
                label="zones"
                placeholder="all"
                filters={filters}
                multiple
                isClearable
                options={zones}
                setFilter={setFilter}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="tags"
                label="tags"
                placeholder="all"
                filters={{
                  ...filters,
                  tags: filters.tags ? filters.tags.map(
                    ( tag ) => ( { value: tag, label: tag } ),
                  ) : [],
                }}
                multiple
                isClearable
                setFilter={( key, value ) => {
                  setFilter( key, ( value || [] ).map( ( t ) => t.value ) );
                }}
                defaultOptions
                loadOptions={onFetchTagsPredefined}
                formatFn={( dataTagsPredefined ) => (
                  ( dataTagsPredefined || [] ).map(
                    ( item ) => ( { id: item, name: item } ),
                  ) )}
              />
            </Col>

            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <TableSelectFilter
                name="checkoutCompleteChecked"
                label="checkoutComplete"
                placeholder="all"
                filters={filters}
                translateOptions
                isClearable
                setFilter={setFilter}
                options={[
                  {
                    name: 'yes',
                    id: 'false',
                  },
                  {
                    name: 'no',
                    id: 'true',
                  },
                ]}
              />
            </Col>
            <Col md={filterColumnMd} lg={filterColumnLg} className="pl-0 py-2">
              <Switch
                label="observations"
                value={filters.observations}
                inputOnChange={( ) => {
                  setFilter( 'observations', filters.observations === undefined ? 'true' : undefined );
                }}
              />
            </Col>
          </Row>
        )}
        rightContent={ability.can( 'manage', 'booking' ) ? ( <div className="pt-3 pr-2"><AddBtn title="newBooking" onClick={onOpenNewModal} /></div> ) : null}
        columns={[
          {
            Header: '#',
            accessor: 'id',
            Cell: ( { cell } ) => (
              <>
                {cell.row.original.not_real_email && (
                <span>
                  <i
                    className="fas fa-exclamation-circle text-warning m-2"
                    id={`tooltip-reported-${cell.row.original.id}`}
                  />
                  <UncontrolledTooltip
                    delay={0}
                    target={`tooltip-reported-${cell.row.original.id}`}
                  >
                    <Intl id="notRealEmail" />
                  </UncontrolledTooltip>
                </span>
                )}
                {ability.can( 'view', 'booking' )
                  ? (
                    <Link to={`${parentPath}/${cell.row.original.id}`} className="text-uppercase">
                      {formatBookinglId( { ...cell.row.original, userBookingReference } )}
                    </Link>
                  ) : (
                    <span>
                      {formatBookinglId( { ...cell.row.original, userBookingReference } )}
                    </span>
                  )}
              </>
            ),
          },
          {
            Header: 'customer',
            accessor: 'owner',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  <>
                    <div className="owner-table-name">
                      {cell.value.firstName}
                      {' '}
                      {cell.value.lastName}
                    </div>
                    {cell.value.lastLogin
                    || ( cell.row.original.issuedKeys && cell.row.original.issuedKeys > 0 )
                      ? (
                        <div className="owner-table-icons">
                          {cell.value.lastLogin && (
                          <span className="owner-table-icon text-sm">
                            <i className="app-icon-phone-app text-primary" />
                          </span>
                          )}
                          {cell.row.original.issuedKeys && cell.row.original.issuedKeys > 0 && (
                          <span className="owner-table-icon text-sm">
                            <i className="fas fa-key text-primary icon-key" />
                            {` ${cell.row.original.issuedKeys}`}
                          </span>
                          )}

                          {cell.row.original.doorOpen && (
                          <span className="owner-table-icon text-sm">
                            <i className="fas fa-door-closed text-success" />
                          </span>
                          )}

                        </div>
                      ) : null}
                  </>
                ) : ''
            ),
          },
          {
            Header: 'guests',
            accessor: 'capacity',
          },
          {
            Header: 'checkIn',
            accessor: 'from',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  `${moment.utc( cell.value ).format( 'MMM DD, YYYY' )} ${cell.row.original.checkInTime}`
                ) : ''
            ),
          },
          {
            Header: 'checkOut',
            accessor: 'till',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  `${moment.utc( cell.value ).format( 'MMM DD, YYYY' )} ${cell.row.original.checkOutTime}`
                ) : ''
            ),
          },
          {
            Header: 'createdAt',
            accessor: 'booking.created',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  moment( cell.value ).format( 'MMM DD, YYYY' )
                ) : ''
            ),
          },
          {
            Header: 'travelReport',
            accessor: 'travelReport',
            Cell: ( { cell } ) => (
              <div className="text-center">
                {cell.value ? <Badge color={statusColor( cell.value )} pill><Intl id={cell.value.toLowerCase()} /></Badge> : <Badge color={statusColor( 'PENDING' )} pill><Intl id="pending" /></Badge>}
              </div>
            ),
          },
          {
            Header: 'checkin',
            accessor: 'checkinComplete',
            Cell: ( { cell } ) => (
              <div className="text-center">
                <Badge color={statusColor( cell.value ? 'checkinCompleteYES' : 'checkinCompleteNO' )} pill><Intl id={cell.value ? 'completed' : 'pending'} /></Badge>
              </div>
            ),
          },
          {
            Header: 'accommodation',
            accessor: 'currentAccommodation',
            Cell: ( { cell } ) => {
              const country = _.get( cell.value, 'roomType.property.propertyManagerCityZone.propertyManagerCity.city.state.country.name' );
              const city = _.get( cell.value, 'roomType.property.propertyManagerCityZone.propertyManagerCity.city.name' );
              return (
                <>

                  <span>
                    {_.get( cell.value, 'name' )}
                  </span>
                  <span className="d-block text-2xsm">
                    {_.get( cell.value, 'roomType.property.address' )}
                    {city ? ', ' : ' '}
                    {city}
                    {city && country ? ' - ' : ''}
                    {country}
                    {' '}
                    <span>
                      <i className={_.get( cell.value, 'cleaningStatus' ) === 'pending' ? 'fas fa-broom text-danger' : 'fas fa-broom text-success'} />
                    </span>
                  </span>
                </>
              );
            },
          },

        ]}
      />
    </Container>
  </>
) );
