import React from 'react';
import { Row, Col } from 'reactstrap';

import { Select, ModalForm } from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  data, isLoading, open, options, onClose, onSubmit, onChangeCountry, onChangeState,
} ) => (
  <ModalForm
    initialValues={data}
    title="newCity"
    showErrorsInFooter
    isLoading={isLoading}
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
    bodyClassName="overflow-visible"
    body={( form ) => (
      <>
        <Row>
          <Col md={6}>
            <Select
              field="country"
              label="country"
              options={options.countries}
              validate={required}
              onChange={( countryId ) => {
                form.change( 'state', null );
                form.change( 'city', null );
                onChangeCountry( countryId );
              }}
            />
          </Col>
          <Col md={6}>
            <Select
              field="state"
              label="state"
              options={options.states}
              validate={required}
              onChange={( stateId ) => {
                form.change( 'city', null );
                if ( form.getFieldState( 'country' ) ) {
                  onChangeState( stateId, form.getFieldState( 'country' ).value );
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Select
              field="city"
              label="city"
              options={options.cities}
              validate={required}
            />
          </Col>
        </Row>
      </>
    )}
  />
) );
