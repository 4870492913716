import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import TaskBulkEditView from 'views/Task/BulkEdit';
import SettingsActions from 'store/reducers/Settings';
import TaskService from 'api/Task';
import TeamService from 'api/Team';
import { formatDataToSend, handlePromise } from 'utils';
import moment from 'moment';

const loadTeams = async ( params ) => {
  const _params = { ...params };
  delete _params.date;
  const { data: loadedTeams } = await TeamService.getTeams( _params );

  const loadedTeamShifts = await Promise.all(
    loadedTeams.data.map( async ( item ) => {
      try {
        const { data: employeesOnShift } = await TeamService.getEmployees( {
          date: moment( params.date ).format( 'YYYY-MM-DD' ),
          team: item.id,
          page: 1,
          elementsPerPage: 20,
        } );
        return {
          ...item,
          members: employeesOnShift.data,
        };
      } catch ( _ ) {
        return item;
      }
    } ),
  );
  return {
    ...loadedTeams,
    data: loadedTeamShifts,
  };
};

const formatOptions = ( _options ) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  _options.map( ( op ) => ( {
    id: op.id,
    name: `${op.firstName} ${op.lastName}`,
  } ) );

const TaskBulkEdit = ( {
  taskIds, modalOpened, onReload, onCloseModal, toggleLoading, toggleInfoAlert,
  filtersValues,
} ) => {
  const [availableTeams, setAvailableTeams] = useState( [] );
  const [selectedTeam, setSelectedTeam] = useState( '' );
  const [availableEmployees, setAvailableEmployees] = useState( [] );

  useEffect( () => {
    const { zone, taskType } = filtersValues;
    const getTeams = async () => {
      toggleLoading( true );
      const { data: teamData } = await loadTeams( {
        elementsPerPage: 100,
        zone,
        taskTypes: [taskType],
      } );
      setAvailableTeams( teamData );
      toggleLoading( false );
    };
    if ( zone && taskType ) {
      getTeams();
    }
  }, [filtersValues, toggleLoading] );

  useEffect( () => {
    if ( availableTeams.length && selectedTeam ) {
      const selectedTeamData = availableTeams.filter( ( team ) => team.id === selectedTeam );
      setAvailableEmployees( formatOptions( selectedTeamData[0]?.members ) );
    }
  }, [availableTeams, selectedTeam] );

  const submitForm = useCallback( async ( formData, form ) => {
    if ( !taskIds || !taskIds.length ) return onCloseModal();
    toggleLoading( true );
    const dataToSend = formatDataToSend( formData, form );
    const formattedData = { ...dataToSend, tasks: taskIds };

    const [errors, response] = await handlePromise(
      TaskService.saveBulk( formattedData ),
    );

    toggleLoading( false );

    if ( !response.ok ) {
      return errors;
    }
    onCloseModal();
    toggleInfoAlert( 'dataSaved' );
    return onReload();
  }, [taskIds, onReload, onCloseModal, toggleInfoAlert, toggleLoading] );

  return (
    <TaskBulkEditView
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
      availableTeams={availableTeams}
      setSelectedTeam={setSelectedTeam}
      availableEmployees={availableEmployees}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( TaskBulkEdit );
