import api from 'config/Api/Api';

const baseEndpoint = '/properties';

export default {
  getRoomTypes( propertyId, params ) {
    return api.get( `${baseEndpoint}/${propertyId}/room-types`, params );
  },

  getRoomType( propertyId, id ) {
    return api.get( `${baseEndpoint}/${propertyId}/room-types/${id}` );
  },

  saveRoomType( propertyId, data ) {
    return api.post( `${baseEndpoint}/${propertyId}/room-types`, data );
  },

  updateRoomType( propertyId, id, data ) {
    return api.put( `${baseEndpoint}/${propertyId}/room-types/${id}`, data );
  },

  deleteRoomType( propertyId, id ) {
    return api.delete( `${baseEndpoint}/${propertyId}/room-types/${id}` );
  },
};
