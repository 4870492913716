/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

const Sumary = ( { data } ) => {
  const [isOpen, setIsOpen] = useState( true );
  const toggle = () => setIsOpen( ( state ) => !state );
  return (
    <>
      <tbody>
        <tr>
          <th onClick={toggle} className="dc_sidebar_cell dc_title dc_bg-white">
            Sumary
            {isOpen && <i className="fas fa-chevron-up chevron" />}
            {!isOpen && <i className="fas fa-chevron-down chevron" />}
          </th>
        </tr>
      </tbody>
      <Collapse isOpen={isOpen} tag="tbody">
        <tr className="dc_data-row">
          <th className="dc_sidebar_cell">Checkins</th>
          {data.map( ( { checkin }, idx ) => (
            <td
              key={idx}
              className="text-center"
            >
              {checkin}
            </td>
          ) )}
        </tr>
        <tr className="dc_data-row">
          <th className="dc_sidebar_cell">Checkouts</th>
          {data.map( ( { checkout }, idx ) => (
            <td
              key={idx}
              className="text-center"
            >
              {checkout}
            </td>
          ) )}
        </tr>
        <tr className="dc_data-row">
          <th className="dc_sidebar_cell">Empty Accom.</th>
          {data.map( ( { empty }, idx ) => (
            <td
              key={idx}
              className="text-center"
            >
              {empty}
            </td>
          ) )}
        </tr>
      </Collapse>
    </>
  );
};

export default Sumary;
