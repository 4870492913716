import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col, Button,
} from 'reactstrap';

import { Intl, Logo, TextField } from 'components';
import tema1img from 'assets/img/theme/team-1.jpg';
import UserActions from 'store/reducers/User';
import { required } from 'config/InputErrors';

class Lock extends Component {
  changeAccount = () => {
    const { logoutUser, history } = this.props;
    logoutUser();
    history.push( '/auth/login' );
  };

  render() {
    return (
      <>
        <Container className="mt-5 pb-2">
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col className="px-5" lg="6" md="8" xl="5">
                <Logo className="mb-3" />
              </Col>
            </Row>
          </div>
        </Container>
        <Container>
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="card-profile bg-secondary">
                <div className="card-profile-image">
                  <img
                    alt="..."
                    className="rounded-circle border-secondary"
                    src={tema1img}
                  />
                </div>
                <CardBody className="mt-5 px-5">
                  <div className="text-center">
                    <h3 className="mb-1">User name</h3>
                  </div>
                  <div className="text-center text-muted mb-4">
                    <small>Session locked. Enter your password.</small>
                  </div>
                  <Form
                    onSubmit={() => {}}
                    render={( { handleSubmit } ) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <TextField
                          field="password"
                          type="password"
                          validate={required}
                          placeholder="password"
                          prependIconClass="fa-key"
                          formGroupClass="m-0"
                        />

                        <div className="text-center mt-4">
                          <Button color="primary" type="button">
                            <Intl id="unlockSession" />
                          </Button>
                        </div>
                      </form>
                    )}
                  />
                  <div className="text-center mt-3">
                    <span className="btn-link cursor-pointer" onClick={this.changeAccount}>
                      <small>Log in with another account.</small>
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = ( {
  logoutUser: UserActions.logoutUser,
} );

export default connect( mapStateToProps, mapDispatchToProps )( Lock );
