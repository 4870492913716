import React from 'react';
import { Container } from 'reactstrap';
import {
  SimpleHeader,
} from 'components';
import Room from 'containers/RoomExpenses/Room';
import Task from 'containers/RoomExpenses/Task';

const RoomExpensesView = () => (
  <>
    <SimpleHeader
      title="roomExpenses"
    />
    <Container fluid>
      <Room />
      <Task />
    </Container>
  </>
);
export default RoomExpensesView;
