import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  AsyncTable, AddBtn, SimpleHeader, TableGenericFilter,
  TableSelectFilter, CanAccess, ResourceAvatar, TableActionsBtns,
} from 'components';
import { formatPhone } from 'utils';
import { roles } from 'config/Constants';
import { canModify } from 'config/Ability';

const ResourceDashboard = React.memo(
  ( {
    data,
    options,
    parentPath,
    isLoading,
    isNomadsAdmin,
    defaultFilters,
    onFetchData,
    onDelete,
    onSendTemporaryPassword,
  } ) => {
    const nomadsAdminRolesArray = [
      roles.nomadsAdmin,
      roles.nomadsAdminReadOnly,
      roles.admin,
      roles.adminReadOnly,
      ...options.roles,
    ];
    const adminRolesArray = [
      roles.admin,
      roles.adminReadOnly,
      ...options.roles,
    ];
    const getRoles = ( rolesArray ) => _.chain( rolesArray )
      .filter(
        ( role ) => role.name !== 'ROLE_USER' && role.name !== 'ROLE_EMPLOYEE',
      )
      .map( 'name' )
      .join( ', ' )
      .value();

    const canViewDelete = canModify( 'resources' );

    return (
      <>
        <SimpleHeader title="resources" />
        <Container fluid>
          <AsyncTable
            id="resources"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            disableSorting
            defaultFilters={defaultFilters}
            rightContent={(
              <CanAccess I="add" a="resource">
                <AddBtn title="newResource" route={`${parentPath}/new`} />
              </CanAccess>
            )}
            onFetchData={onFetchData}
            customFilters={( { filters, setFilter } ) => (
              <Row>
                <Col md={8} lg={4}>
                  <TableGenericFilter filters={filters} setFilter={setFilter} />
                </Col>
                <Col md={8} lg={4} className="pl-lg-0 pt-1 pt-lg-0">
                  <TableSelectFilter
                    name="roles"
                    placeholder="roles"
                    multiple
                    filters={filters}
                    setFilter={setFilter}
                    options={
                      isNomadsAdmin ? nomadsAdminRolesArray : adminRolesArray
                    }
                  />
                </Col>
              </Row>
            )}
            columns={[
              {
                Header: 'name',
                accessor: 'firstName',
                Cell: ( { cell } ) => ( canViewDelete ? (
                  <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                    <ResourceAvatar
                      data={cell.row.original}
                      avatarSize="sm"
                    />
                  </Link>
                ) : (
                  <span>
                    <ResourceAvatar
                      data={cell.row.original}
                      avatarSize="sm"
                    />
                  </span>
                ) ),
              },
              {
                Header: 'phoneNumber',
                accessor: 'phoneNumber',
                Cell: ( { cell } ) => formatPhone( cell.value ),
              },
              {
                Header: 'email',
                accessor: 'email',
                Cell: ( { cell } ) => <span>{cell.value}</span>,
              },
              {
                Header: 'role',
                accessor: 'roles',
                Cell: ( { cell } ) => getRoles( cell.value ),
              },
              canViewDelete ? {
                accessor: 'id',
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        onClick: () => onSendTemporaryPassword( cell.row.original ),
                        label: 'sendTemporaryPassword',
                      },
                      {
                        isDelete: true,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      },
                    ]}
                  />
                ),
              } : { accessor: 'id', Cell: ( ) => '-' },
            ]}
          />
        </Container>
      </>
    );
  },
);

export default ResourceDashboard;
