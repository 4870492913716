import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';

import { Intl, DetailCard, RatingStar } from 'components';

const Rating = ( { title, rating } ) => (
  <Row>
    <Col md={8}>
      <p className="text-sm"><Intl id={title} /></p>
    </Col>
    <Col>
      <RatingStar rating={rating} />
    </Col>
  </Row>
);

export default React.memo( ( { data } ) => (
  <DetailCard
    classNames={{ header: 'py-3', body: 'pt-4', card: 'card-sec-bottom' }}
    header={(
      <h3 className="m-0"><Intl id="review" /></h3>
    )}
  >
    {data && data.id && !_.get( data, 'rating' ) && <p className="m-0 text-sm"><Intl id="notRatedYet" /></p>}
    {data && data.id && _.get( data, 'rating' ) && (
    <>
      <Rating title="rateApartmentCleaning" rating={_.get( data, 'rating.cleaning' )} />
      <Rating title="rateApartmentCommunication" rating={_.get( data, 'rating.communication' )} />
      <Rating title="rateApartmentPersonal" rating={_.get( data, 'rating.personal' )} />
      <Rating title="rateApartmentLocation" rating={_.get( data, 'rating.location' )} />
      <Rating title="rateApartmentEquipment" rating={_.get( data, 'rating.equipment' )} />

      <p className="text-sm m-0"><Intl id="rateApartmentComment" /></p>
      <p className="text-sm font-weight-300 lh-130">
        {_.get( data, 'rating.comment' )}
      </p>
    </>
    )}
  </DetailCard>
) );
