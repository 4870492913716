import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import NewContractView from 'views/Resource/Edit/Contract/new';
import SettingsActions from 'store/reducers/Settings';
import EmployeeService from 'api/Employee';
import { formatDataToSend, handlePromise } from 'utils';

const NewContractContainer = ( {
  entityId,
  onClose,
  onReloadData,
  toggleInfoAlert,
  toggleLoading,
} ) => {
  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const datesSend = formatDataToSend( formData, form );
      delete datesSend.contract;

      const dataToSend = {
        contract: formData.contract ? formData.contract.file : null,
        from: datesSend.from ? datesSend.from.format( 'YYYY-MM-DD' ) : '',
        till: datesSend.till ? datesSend.till.format( 'YYYY-MM-DD' ) : '',
      };

      const [dataErrors, dataResponse] = await handlePromise(
        EmployeeService.createContract( entityId, dataToSend ),
      );
      toggleLoading( false );
      if ( !dataResponse.ok ) return dataErrors;

      toggleInfoAlert( 'dataSaved' );
      onReloadData();
    },
    [entityId, onReloadData, toggleInfoAlert, toggleLoading],
  );

  return <NewContractView submitForm={submitForm} onClose={onClose} />;
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  // toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( NewContractContainer );
