import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import CityDashboardView from 'views/Country/State/City/Dashboard';
import CityService from 'api/City';
import StateService from 'api/State';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import CityEdit from '../Edit';

const CityDashboard = ( {
  toggleErrorAlert, countryId, countryData, match,
} ) => {
  const stateId = match.params.id;
  const toEditId = useRef( null );
  const [editModalOpened, setEditModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setEditModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setEditModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [closeEditModal] );

  const [stateData] = useFetch( {
    initialState: {},
    promise: () => StateService.getState( countryId, stateId ),
    conditional: !!match.params.id,
    deps: [match.params.id, countryId],
  } );

  const [data, dataLoading] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( countryId, stateId ),
    toggleErrorAlert,
    deps: [countryId, stateId, dataSubmitted],
  } );

  return (
    <>
      <CityDashboardView
        data={data}
        countryName={countryData.name}
        stateName={stateData.name}
        isLoading={dataLoading}
        countryId={countryId}
        onOpenEditModal={openEditModal}
      />

      <CityEdit
        cityId={toEditId.current}
        countryId={countryId}
        stateId={stateId}
        modalOpened={editModalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( CityDashboard );
