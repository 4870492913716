import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';
import { Intl } from 'components';

const SimpleHeader = React.memo( ( {
  title, translateTitle = true, parentLinks = [], rightContent, containerFluid = true,
  classNameTitle = '', backLink, noBreadcrumbs, styleTitle,
} ) => {
  const formattedName = ( link ) => ( link.translate === false
    ? link.name : <Intl id={link.name} /> );
  return (
    <div className="header" data-cy="page-header">
      <Container fluid={containerFluid}>
        <div className="header-body">
          <Row className="align-items-center pt-4 pb-3">
            {backLink && (
            <Col sm={12} className="pb-1">
              <Link to={backLink.href} className="d-flex align-items-center text-secondary text-sm text-bold">
                <i className="th-icon-arrow-right mr-1" style={{ transform: 'scale(-1,1)', fontSize: '1em' }} />
                <Intl id={backLink.name} />
              </Link>
            </Col>
            )}
            <Col md>

              {title
              && (
                <h1 className={classnames( 'font-weight-normal d-inline-block mb-0  mr-md-3', classNameTitle )} style={styleTitle}>
                  {translateTitle ? <Intl id={title} /> : title}
                </h1>
              )}
              {!noBreadcrumbs && parentLinks.length ? (
                <h1 className={classnames( 'font-weight-normal d-inline-block mb-0  mr-md-3', classNameTitle )} style={styleTitle}>
                  {parentLinks.map( ( link, index ) => (

                    link.href
                      // eslint-disable-next-line react/no-array-index-key
                      ? <Link to={link.href} key={index}>{formattedName( link )}</Link>
                      // eslint-disable-next-line react/no-array-index-key
                      : <span key={index}>{formattedName( link )}</span>
                  ) )}
                </h1>
              ) : null}
            </Col>
            <Col md="auto" className="text-right">
              {rightContent}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
} );

export default SimpleHeader;
