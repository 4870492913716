import React from 'react';

import {
  Calendar,
} from 'components';

export default React.memo( ( {
  visible, events, onOpenEditItemModal, updateDates, onOpenModal,
} ) => {
  if ( !visible ) {
    return null;
  }

  const handleDatesSet = ( arg ) => {
    const currentStartDate = arg.view.currentStart;
    const currentEndDate = arg.view.currentEnd;
    updateDates( currentStartDate, currentEndDate );
  };

  return (
    <>
      <Calendar
        events={events}
        onOpenEditItemModal={onOpenEditItemModal}
        handleDatesSet={handleDatesSet}
        onOpenModal={onOpenModal}
        typeCalendar="monthDiscount"
      />
    </>
  );
} );
