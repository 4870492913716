import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';

import { Intl } from 'components';

export default React.memo( ( { routes, pathLayout, pathname } ) => (
  <ListGroup className="sidemenu">
    {_.map( routes, ( route ) => (
      <ListGroupItem
        key={pathLayout + route.path}
        className="py-2"
        active={pathLayout + route.path === pathname}
        tag={NavLinkRRD}
        activeClassName=""
        to={pathLayout + route.path}
        data-cy="sidemenu-link"
      >
        {!!route.icon
        && (
        <div className="d-inline-flex justify-content-start" style={{ width: '1.3rem' }}>
          <i className={classnames( route.icon, 'text-sm' )} />
        </div>
        )}
        <Intl id={route.name} />
      </ListGroupItem>
    ) )}
  </ListGroup>
) );
