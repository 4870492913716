import React from 'react';
import { Route, Switch } from 'react-router';

import { canView, canList } from 'config/Ability';
import Dashboard from './Dashboard';
import Edit from './Edit';

const Team = ( { match } ) => (
  <Switch>
    {/* {canView( 'team' )
    && (
    <Route
      path={`${match.url}/:id`}
      render={( props ) => <Detail {...props} parentPath={match.url} />}
    />
    )} */}
    {canView( 'team' )
    && (
      <Route
        path={`${match.url}/edit/:id`}
        render={( props ) => <Edit {...props} parentPath={match.url} />}
      />
    )}
    {canList( 'team' )
    && (
    <Route
      path={match.url}
      render={( props ) => <Dashboard {...props} parentPath={match.url} />}
    />
    )}
  </Switch>
);

export default Team;
