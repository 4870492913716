import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  MainFooter, MainNavbar, Sidebar,
} from 'components';
import SettingsActions from 'store/reducers/Settings';
import TaskActions from 'store/reducers/Task';
import IncidenceActions from 'store/reducers/Incidence';
import TaskPeriodicActions from 'store/reducers/TaskPeriodic';
import BookingActions from 'store/reducers/Booking';
import {
  nomadsAdminRoutes, nomadsAdminReadOnlyRoutes, adminRoutes as _adminRoutes,
  adminReadOnlyRoutes, noCustomerAppRoutes,
} from 'config/Routes';
import { minutesToLock, roles } from 'config/Constants';
import { updateRules } from 'config/Ability';
import TaskEdit from 'containers/Task/Edit';
import IncidenceEdit from 'containers/Incidences/Edit';
import TaskPeriodicEdit from 'containers/TaskPeriodic/Edit';
import BookingNew from 'containers/Booking/New';
import Dashboard from 'containers/Dashboard';
import PropertyManager from 'containers/PropertyManager';
import NotificationCenter from 'containers/NotificationCenter';
import { getFlags } from './FlagsProvider';

const timeToLock = 1000 * 60 * minutesToLock;

class Private extends React.Component {
  componentWillMount() {
    const { sidebarCollapsed } = this.props;

    if ( sidebarCollapsed ) {
      document.body.classList.remove( 'g-sidenav-pinned' );
      document.body.classList.remove( 'g-sidenav-show' );
      document.body.classList.add( 'g-sidenav-hidden' );
    }
    this.userRoutes = this.getUserRoutes();
    this.routes = this.getRoutes( _.chain( this.userRoutes ).values().flatten().value() );
  }

  componentDidMount() {
    const { switchActiveMenu, location } = this.props;
    const url = location.pathname.split( '/' );
    if ( url && _.get( url, '[1]' ) ) switchActiveMenu( url[1] );
    else switchActiveMenu( null );
  }

  componentDidUpdate( prevProps ) {
    if ( prevProps.history.pathname !== prevProps.location.pathname ) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainContent.scrollTop = 0;
    }
  }

  getRoutes = ( routesList ) => routesList.map( ( prop ) => {
    if ( prop.views ) {
      return this.getRoutes( prop.views );
    }
    if ( prop.component ) {
      if ( prop.extraData ) {
        return (
          <Route
            exact={prop.exact}
            path={prop.layout + prop.path}
            render={( props ) => <prop.component {...props} extraData={prop.extraData} />}
            key={prop.layout + prop.path}
          />
        );
      }
      return (
        <Route
          exact={prop.exact}
          path={prop.layout + prop.path}
          component={prop.component}
          key={prop.layout + prop.path}
        />
      );
    }
    return null;
  } )

  toggleSidenav = () => {
    const { sidebarCollapsed, toggleSidebar } = this.props;
    if ( sidebarCollapsed ) {
      toggleSidebar();
      document.body.classList.add( 'g-sidenav-pinned' );
      document.body.classList.remove( 'g-sidenav-hidden' );
    } else {
      toggleSidebar();
      document.body.classList.remove( 'g-sidenav-pinned' );
      document.body.classList.add( 'g-sidenav-hidden' );
    }
  };

  getUserRoutes = () => {
    const { userRole, haveCustomerApp, ACLs } = this.props;
    const idPM = _.get( this.props, 'propertyManager.id', '' );
    const featureFlag = ( item ) => {
      if ( !item.flag ) {
        return true;
      }
      const flags = getFlags( item.flag );
      if ( flags.enabled.includes( idPM ) ) {
        return true;
      }
      if ( flags.disabled.includes( idPM ) ) {
        return false;
      }
      return flags.active;
    };
    const adminRoutes = {
      headerLeft: _adminRoutes.headerLeft.filter( featureFlag ),
      headerRight: _adminRoutes.headerRight.filter( featureFlag ),
      userMenu: _adminRoutes.userMenu.filter( featureFlag ),
    };

    // console.log( 'ACLs', ACLs );
    if (
      userRole === roles.nomadsAdmin.id || userRole === roles.nomadsAdmin.id
      || userRole === roles.admin.id || userRole === roles.adminReadOnly.id
    ) {
      updateRules( [{
        subject: 'all',
        action: 'manage',
      },
      ] );
      if ( userRole === roles.nomadsAdmin.id ) return nomadsAdminRoutes;
      if ( userRole === roles.nomadsAdminReadOnly.id ) return nomadsAdminReadOnlyRoutes;
      if ( !haveCustomerApp ) return noCustomerAppRoutes;
      if ( userRole === roles.admin.id ) return adminRoutes;
      if ( userRole === roles.adminReadOnly.id ) return adminReadOnlyRoutes;
    } else {
      //   const ACLs = ['shift:read_only'];
      const rules = _.map( ACLs, ( rule ) => {
        const ruleArray = rule.split( ':' );
        return {
          subject: ruleArray[0],
          action: ruleArray[1],
        };
      } );

      updateRules( rules );

      const filteredSections = {};

      filteredSections.headerLeft = _.filter( adminRoutes.headerLeft,
        ( route ) => !route.ACLs || _.intersection( route.ACLs, ACLs ).length )
        .map( ( route ) => {
          if ( !route.views ) return route;
          return {
            ...route,
            views: _.filter( route.views,
              ( view ) => !view.ACLs || _.intersection( view.ACLs, ACLs ).length ),
          };
        } );

      filteredSections.headerRight = _.filter( adminRoutes.headerRight,
        ( route ) => !route.ACLs || _.intersection( route.ACLs, ACLs ).length )
        .map( ( route ) => {
          if ( !route.views ) return route;
          return {
            ...route,
            views: _.filter( route.views,
              ( view ) => !view.ACLs || _.intersection( view.ACLs, ACLs ).length ),
          };
        } );

      filteredSections.userMenu = _.filter( adminRoutes.userMenu,
        ( route ) => !route.ACLs || _.intersection( route.ACLs, ACLs ).length )
        .map( ( route ) => {
          if ( !route.views ) return route;
          return {
            ...route,
            views: _.filter( route.views,
              ( view ) => !view.ACLs || _.intersection( view.ACLs, ACLs ).length ),
          };
        } );

      return filteredSections;
    }
  };

  render() {
    const {
      history, location, sidebarCollapsed, taskToEditId, taskPeriodicToEditId,
      taskToEditData, taskPeriodicToEditData, taskEditModalOpened, taskPeriodicEditModalOpened,
      reloadTask, reloadTaskPeriodic, toggleTaskEditModal, toggleTaskPeriodicEditModal, activeMenu,
      userRole, bookingNewModalOpened, reloadBooking, toggleBookingNewModal,
      incidenceToEditId, incidenceToEditData, incidenceEditModalOpened,
      reloadIncidence, toggleIncidenceEditModal,
    } = this.props;

    const sidebarMenu = [...this.userRoutes.headerLeft || [], ...this.userRoutes.headerRight || []];

    return (
      <IdleTimer
        element={document}
        onIdle={() => history.push( '/auth/lock' )}
        timeout={timeToLock * 15}
      >
        <Sidebar
          routes={sidebarMenu}
          toggleSidenav={this.toggleSidenav}
          sidebarCollapsed={sidebarCollapsed}
          activeMenu={activeMenu}
          pathname={location.pathname}
        />
        <MainNavbar
          routes={this.userRoutes.headerLeft}
          rightRoutes={this.userRoutes.headerRight}
          userDropdown={this.userRoutes.userMenu}
          toggleSidenav={this.toggleSidenav}
          sidebarCollapsed={sidebarCollapsed}
          location={location}
        />

        <div
          className="main-content main-content-wrapper"
          ref={( el ) => { this.mainContent = el; }}
          onClick={this.closeSidenav}
        >
          {!!taskEditModalOpened
          && (
            <TaskEdit
              taskId={taskToEditId}
              preloadedData={taskToEditData}
              modalOpened={taskEditModalOpened}
              onCloseModal={() => {
                if ( taskToEditId ) {
                  reloadTask();
                }
                toggleTaskEditModal( null );
              }}
              onReload={() => {
                reloadTask();
                toggleTaskEditModal( null );
              }}
            />
          )}
          {!!incidenceEditModalOpened
          && (
            <IncidenceEdit
              incidenceId={incidenceToEditId}
              preloadedData={incidenceToEditData}
              modalOpened={incidenceEditModalOpened}
              onCloseModal={() => {
                if ( incidenceToEditId ) {
                  reloadIncidence();
                }
                toggleIncidenceEditModal( null );
              }}
              onReload={() => {
                reloadIncidence();
                toggleIncidenceEditModal( null );
              }}
            />
          )}
          {!!taskPeriodicEditModalOpened
          && (
            <TaskPeriodicEdit
              taskId={taskPeriodicToEditId}
              preloadedData={taskPeriodicToEditData}
              modalOpened={taskPeriodicEditModalOpened}
              onCloseModal={() => {
                if ( taskPeriodicToEditId ) {
                  reloadTaskPeriodic();
                }
                toggleTaskPeriodicEditModal( null );
              }}
              onReload={() => {
                reloadTaskPeriodic();
                toggleTaskPeriodicEditModal( null );
              }}
            />
          )}

          {!!bookingNewModalOpened
          && (
            <BookingNew
              modalOpened={bookingNewModalOpened}
              onCloseModal={() => {
                toggleBookingNewModal();
              }}
              onReload={() => {
                reloadBooking();
                toggleBookingNewModal();
              }}
            />
          )}

          <Switch>
            {this.routes}
            <Route
              exact
              path="/"
              component={( userRole === roles.nomadsAdmin.id
                || userRole === roles.nomadsAdminReadOnly.id )
                ? PropertyManager : Dashboard}
            />
            <Redirect from="*" to="/" />
          </Switch>
          <MainFooter />
        </div>
        {!sidebarCollapsed ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}

        <NotificationCenter />
      </IdleTimer>
    );
  }
}

const mapStateToProps = ( {
  settings, user, task, taskPeriodic, booking, incidence,
} ) => ( {
  haveCustomerApp: _.get( user, 'settings.haveCustomerApp' ),
  sidebarCollapsed: settings.sidebarCollapsed,
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
  ACLs: _.get( user, 'user.ACLs' ),
  incidenceToEditId: incidence.incidenceToEditId,
  incidenceToEditData: incidence.incidenceToEditData,
  incidenceEditModalOpened: incidence.editIncidenceModalOpened,
  taskToEditId: task.taskToEditId,
  taskToEditData: task.taskToEditData,
  taskEditModalOpened: task.editModalOpened,
  taskPeriodicToEditId: taskPeriodic.taskToEditId,
  taskPeriodicToEditData: taskPeriodic.taskToEditData,
  taskPeriodicEditModalOpened: taskPeriodic.editModalOpened,
  bookingNewModalOpened: booking.bookingNewModalOpened,
  activeMenu: settings.activeMenu,
} );

const mapDispatchToProps = ( {
  toggleSidebar: SettingsActions.toggleSidebar,
  reloadTask: TaskActions.reloadTask,
  toggleTaskEditModal: TaskActions.toggleEditModal,
  reloadIncidence: IncidenceActions.reloadIncidence,
  toggleIncidenceEditModal: IncidenceActions.toggleIncidenceEditModal,
  reloadTaskPeriodic: TaskPeriodicActions.reloadTaskPeriodic,
  toggleTaskPeriodicEditModal: TaskPeriodicActions.toggleEditModalTaskPeriodic,
  reloadBooking: BookingActions.reloadBooking,
  toggleBookingNewModal: BookingActions.toggleBookingNewModal,
  switchActiveMenu: SettingsActions.switchActiveMenu,
} );

export default connect( mapStateToProps, mapDispatchToProps )( Private );
