import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import ManagePermitView from 'views/Resource/Edit/Permits/Manage';
import EmployeeService from 'api/Employee';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';

const ManagePermit = ( {
  customerId, permitId, open, reload, onClose, toggleErrorAlert, toggleInfoAlert, toggleLoading,
  initialPermitStatus,
} ) => {
  const managePermit = useCallback( async ( status ) => {
    toggleLoading( true );
    const [errors, response] = await handlePromise(
      EmployeeService.patchPermitRegister( customerId, permitId, {
        status,
      } ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return toggleErrorAlert( errors );
    }
    toggleInfoAlert( 'Permit status changed' );
    toggleLoading( false );
    reload();
    onClose();
  }, [customerId, onClose, permitId, reload, toggleErrorAlert, toggleInfoAlert, toggleLoading] );

  return (
    <ManagePermitView
      open={open}
      onClose={onClose}
      onClick={managePermit}
      initialPermitStatus={initialPermitStatus}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( ManagePermit );
