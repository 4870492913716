import React from 'react';
import { Route, Switch } from 'react-router';

import { useFetch } from 'hooks';
import PropertyService from 'api/Property';
import Dashboard from './Dashboard';
import Edit from './Edit';
import Accommodation from './Accommodation';
import { canModify, canView, canList } from '../../../config/Ability';

const RoomType = ( { match, parentPath } ) => {
  const [propertyData] = useFetch( {
    initialState: {},
    promise: () => PropertyService.getProperty( match.params.id ),
    conditional: !!match.params.id,
    deps: [match.params.id],
  } );

  return (
    <Switch>
      {canModify( 'room_type' )
      && (
        <Route
          path={`${match.url}/new`}
          render={( props ) => (
            <Edit
              {...props}
              propertyData={propertyData}
              parentIds={{ property: match.params.id }}
              paths={{ property: parentPath, roomType: match.url }}
            />
          )}
        />
      )}
      {canView( 'room_type' )
      && (
        <Route
          path={`${match.url}/edit/:id`}
          render={( props ) => (
            <Edit
              {...props}
              propertyData={propertyData}
              parentIds={{ property: match.params.id }}
              paths={{ property: parentPath, roomType: match.url }}
            />
          )}
        />
      )}
      <Route
        path={`${match.url}/:id/accommodations`}
        render={( props ) => (
          <Accommodation
            {...props}
            propertyData={propertyData}
            parentIds={{ property: match.params.id }}
            paths={{ property: parentPath, roomType: match.url }}
          />
        )}
      />
      {canList( 'room_type' )
      && (
        <Route
          path={match.url}
          render={( props ) => (
            <Dashboard
              {...props}
              propertyData={propertyData}
              parentIds={{ property: match.params.id }}
              paths={{ property: parentPath, roomType: match.url }}
            />
          )}
        />
      )}
    </Switch>
  );
};

export default RoomType;
