/* eslint-disable import/prefer-default-export */
const typeOptionNames = {
  EARLY_BOOKING_DISCOUNT: 'earlyBookingDiscountBefore',
  EARLY_BOOKING_DISCOUNT_NUMNIGTHS: 'earlyBookingDiscountBeforeNumNight',
  LAST_MINUTE_DISCOUNT: 'lastMinuteDiscount',
  LONG_STAY_DISCOUNT: 'longStayDiscount',
  SHORT_STAY_SUPLEMENT: 'shortStaySuplement',
  SIMPLE_DISCOUNT: 'simpleDiscount',
  SIMPLE_SUPLEMENT: 'simpleSuplement',
};

const typeOptionBookingDays = {
  EARLY_BOOKING_DISCOUNT_NUMNIGTHS: 'bookingsMadeUntilDaysBefore',
  LAST_MINUTE_DISCOUNT: 'bookingsMadeWithLessThan',
};

const typeOptionDates = {
  EARLY_BOOKING_DISCOUNT: 'bookingsMadeBefore',
};

const enableRequirementsOptions = [
  'EARLY_BOOKING_DISCOUNT',
  'EARLY_BOOKING_DISCOUNT_NUMNIGTHS',
  'LAST_MINUTE_DISCOUNT',
  'LONG_STAY_DISCOUNT',
  'SHORT_STAY_SUPLEMENT',
];

const typeOptions = [
  'SIMPLE_DISCOUNT',
  'LONG_STAY_DISCOUNT',
  'LAST_MINUTE_DISCOUNT',
  'EARLY_BOOKING_DISCOUNT',
  'EARLY_BOOKING_DISCOUNT_NUMNIGTHS',
  'SIMPLE_SUPLEMENT',
  'SHORT_STAY_SUPLEMENT',
].map( ( item ) => ( {
  id: item,
  name: typeOptionNames[item],
} ) );

const discountypeOptions = [
  'NOMINAL',
  'PERCENTAGE',
].map( ( item ) => ( {
  id: item,
  name: item.toLocaleLowerCase(),
} ) );

export {
  typeOptions,
  discountypeOptions,
  enableRequirementsOptions,
  typeOptionDates,
  typeOptionBookingDays,
  typeOptionNames as typeOptionNamesDiscount,
};
