import React from 'react';
import {
  Container, Row, Col, Card,
} from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router';
import classnames from 'classnames';

import { SimpleHeader, HorizontalMenu, Button } from 'components';

export default React.memo(
  ( {
    data,
    entityId,
    parentPath,
    isLoading,
    tabs,
    pathLayout,
    pathname,
    onReloadData,
    onListTasks,
  } ) => {
    const getRoutes = ( routesList ) => routesList.map( ( route ) => {
      if ( route.component ) {
        return (
          <Route
            exact={route.exact}
            path={pathLayout + route.path}
            render={( props ) => (
              <route.component
                {...props}
                data={data}
                extraData={route.extraData}
                entityId={entityId}
                parentPath={parentPath}
                onReloadData={onReloadData}
              />
            )}
            key={route.layout + route.path}
          />
        );
      }
      return null;
    } );

    return (
      <>
        <SimpleHeader parentLinks={[{ name: 'buildings', href: parentPath }]} />
        <Container
          fluid
          className={classnames( {
            'app-component-loading': isLoading,
          } )}
        >
          {!!entityId && (

          <Card className="card-sec-top border-bottom">
            <Row className="align-items-center pr-3">
              <Col xs={10}>
                <HorizontalMenu
                  tabs={tabs}
                  pathLayout={pathLayout}
                  pathname={pathname}
                />
              </Col>
              <Col xs={2} className="text-right">
                <Button title="taskList" onClick={onListTasks} />
              </Col>
            </Row>

          </Card>
          )}
          {!!tabs && (
          <Row>
            <Col>
              <Switch>
                {getRoutes( tabs )}
                <Redirect from="*" to={parentPath} />
              </Switch>
            </Col>
          </Row>
          )}
        </Container>
      </>
    );
  },
);
