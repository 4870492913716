import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { Row, Col, Button } from 'reactstrap';
import { Intl } from 'components';
import { phoneNumber } from 'config/InputErrors';

const PhoneRow = ( {
  dataRow, index, onUpdate, onDelete,
} ) => {
  const [name, setName] = useState( dataRow.name );
  const [phone, setPhone] = useState( dataRow.phone );
  const [phoneError, setPhoneError] = useState( null );

  return (
    <Row className="mb-3 mx--1">
      <Col xs={5} className="px-1">
        <div className="text-bold text-small"><Intl id="name" /></div>
        <input
          type="text"
          className="form-control-sm form-control"
          value={name}
          onChange={( e ) => {
            const v = e.target.value;
            setName( v );
            onUpdate( index, v, phone );
          }}
        />
      </Col>
      <Col xs={6} className="px-1">
        <div className="text-bold text-small"><Intl id="phone" /></div>
        <input
          type="text"
          className={classnames( 'form-control-sm form-control', { 'is-invalid': phoneError } )}
          value={phone}
          onChange={( e ) => {
            const v = e.target.value;
            const err = phoneNumber( v );
            setPhoneError( err );
            setPhone( v );
            if ( !err ) {
              onUpdate( index, name, v );
            }
          }}
        />
        {phoneError ? <div className="invalid-feedback d-block"><Intl id={phoneError} /></div> : null}
      </Col>
      <Col xs={1} className="px-1 pt-3">
        <div
          className="p-1 text-danger d-inline-block cursor-pointer"
          onClick={() => {
            onDelete( index );
          }}
        >
          <i className="fa fa-times" />
        </div>
      </Col>
    </Row>
  );
};

const PhoneList = ( {
  value = [], label, onChange,
} ) => {
  const [list, setList] = useState( [] );
  const [loaded, setLoaded] = useState( false );
  const [deleting, setDeleting] = useState( false );

  useEffect( () => {
    if ( value && value.length && !loaded ) {
      setList( value );
      onChange( value );
      setLoaded( true );
    }
  }, [value, loaded, onChange] );

  const onNew = useCallback( ( ) => {
    const listClone = [...list];
    listClone.push( { name: '', phone: '' } );
    setList( listClone );
    onChange( listClone );
  }, [list, onChange] );

  const onUpdate = useCallback( ( index, name, phone ) => {
    const listClone = [...list];
    listClone[index] = { name, phone };
    setList( listClone );
    onChange( listClone );
  }, [list, onChange] );

  const onDelete = useCallback( ( index ) => {
    const listClone = [...list];
    listClone.splice( index, 1 );
    setDeleting( true );
    setList( listClone );
    onChange( listClone );
    setTimeout( () => {
      setDeleting( false );
    }, 100 );
  }, [list, onChange] );

  return (
    <>
      <div>

        {label
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        && (
        <label className="font-weight-bold form-control-label mb-2 d-block">
          <Intl id={label} />
        </label>
        )}
        {!deleting && list.map( ( dataRow, k ) => (
          <PhoneRow
            dataRow={dataRow}
            index={k}
            // eslint-disable-next-line react/no-array-index-key
            key={k}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        ) )}
        <Button
          size="sm"
          onClick={onNew}
        >
          <Intl id="newPhone" />
        </Button>
      </div>
    </>
  );
};

export default PhoneList;
