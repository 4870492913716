const formatAccomodationHours = ( hours ) => {
  const { bookingBefore, bookingInProgress, bookingNext } = hours;
  const hoursFormated = [];
  if ( !Array.isArray( bookingNext ) ) {
    hoursFormated.push( {
      id: bookingNext.id,
      type: 'nextCheckin',
      date: bookingNext.checkin,
    } );
  }
  if ( !Array.isArray( bookingInProgress ) ) {
    const { checkout } = bookingInProgress;
    hoursFormated.push( {
      id: bookingInProgress.id,
      type: 'nextCheckout',
      date: checkout,
    } );
  }
  if ( !Array.isArray( bookingBefore ) ) {
    const { checkout } = bookingBefore;
    hoursFormated.push( {
      id: bookingBefore.id,
      type: 'lastCheckout',
      date: checkout,
    } );
  }
  return hoursFormated;
};

export default formatAccomodationHours;
