import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  AsyncTable,
  AddBtn,
  SimpleHeader, TableGenericFilter,
  CanAccess,
  ResourceAvatar, TableActionsBtns,
} from 'components';
import { formatPhone } from 'utils';
import { canModify, canView } from 'config/Ability';
import countries from 'country-list';

const PropertyOwnerUserDashboard = React.memo(
  ( {
    data,
    parentPath,
    defaultFilters,
    onFetchData,
    onDelete,
    isLoading,
  } ) => {
    const canViewProp = canView( 'property_owner' );
    const canModifyProp = canModify( 'property_owner' );

    return (
      <>
        <SimpleHeader title="propertyOwnerUsers" />
        <Container fluid>
          <AsyncTable
            id="resources"
            data={data.data}
            total={data.elementsTotal}
            disableSorting
            isLoading={isLoading}
            defaultFilters={{
              ...defaultFilters,
              roles: ['ROLE_OWNER'],
            }}
            rightContent={(
              <CanAccess I="add" a="property_owner">
                <AddBtn title="newPropertyOwnerUser" route={`${parentPath}/new`} />
              </CanAccess>
            )}
            onFetchData={onFetchData}
            params={{ roles: ['ROLE_OWNER'] }}
            customFilters={( { filters, setFilter } ) => (
              <Row>
                <Col md={8} lg={4}>
                  <TableGenericFilter filters={filters} setFilter={setFilter} />
                </Col>
              </Row>
            )}
            columns={[
              {
                Header: 'name',
                accessor: 'firstName',
                Cell: ( { cell } ) => ( canViewProp || canModifyProp ? (
                  <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                    <ResourceAvatar
                      data={cell.row.original}
                      avatarSize="sm"
                    />
                  </Link>
                ) : (
                  <span>
                    <ResourceAvatar
                      data={cell.row.original}
                      avatarSize="sm"
                    />
                  </span>
                ) ),
              },
              {
                Header: 'phoneNumber',
                accessor: 'phoneNumber',
                Cell: ( { cell } ) => formatPhone( cell.value ),
              },
              {
                Header: 'email',
                accessor: 'email',
                Cell: ( { cell } ) => <span>{cell.value}</span>,
              },
              {
                Header: 'country',
                accessor: 'country',
                Cell: ( { cell } ) => ( cell.value && typeof cell.value === 'string' ? countries.getName( cell.value.toUpperCase() ) : '-' ),
              },

              canModifyProp ? {
                accessor: 'id',
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      // {
                      //   onClick: () => onSendTemporaryPassword( cell.row.original ),
                      //   label: 'sendTemporaryPassword',
                      // },
                      {
                        isDelete: true,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      },
                    ]}
                  />
                ),
              } : { accessor: 'id', Cell: ( ) => '-' },
            ]}
          />
        </Container>
      </>
    );
  },
);

export default PropertyOwnerUserDashboard;
