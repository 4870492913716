import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import {
  Select,
  Form,
  SimpleHeader,
  EditCard,
  Datepicker,
  Intl,
  CanAccess,
  FormActionsBtns,
} from 'components';
import { required } from 'config/InputErrors';
import { currencyFormat, formatFloat } from 'utils';
import formatBookinglId from 'utils/bookingId';
import DashboardItems from '../Item/Dashboard';

export default React.memo(
  ( {
    data,
    isLoading,
    serviceId,
    onSubmit,
    onClose,
    onAddNewItem,
    onEditItem,
    onDeleteItem,
    parentPath,
    userCurrency,
    bookingId,
    userBookingReference,
  } ) => {
    const bookedServiceItems = _.get( data, 'bookedServiceItems', [] );
    return (
      <>
        <SimpleHeader
          parentLinks={[
            { name: 'booking', href: `${parentPath}/${bookingId}/services` },
          ]}
        />
        <Container
          fluid
          className={classnames( { 'app-component-loading': isLoading } )}
        >
          <Form
            initialValues={data}
            title="editService"
            showErrorsInFooter
            isLoading={isLoading}
            onClose={onClose}
            onSubmit={onSubmit}
            body={() => (
              <>
                <EditCard title="editService">
                  <Row>
                    <Col md={4}>
                      <p className="font-weight-500 m-0">
                        <Intl id="service" />
                      </p>
                      <p className="text-gray-2 text-uppercase m-0">
                        {data.service ? data.service.name : ''}
                      </p>

                      <p className="font-weight-500 m-0 mt-2">
                        <Intl id="booking" />
                      </p>
                      <p className="text-gray-2">
                        {formatBookinglId( { ...data.booking, userBookingReference } )}
                      </p>

                      <p className="font-weight-500 m-0 mt-2">
                        <Intl id="customer" />
                      </p>
                      <p className="text-gray-2">
                        {data.owner ? data.owner.name : ''}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p className="font-weight-500 m-0">
                        <Intl id="date" />
                      </p>
                      <Datepicker
                        field="date"
                        validate={required}
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm"
                        formGroupClass="m-0"
                        utc
                        startDateField={{ value: moment().subtract( '1', 'day' ) }}
                      />
                      <p className="font-weight-500 m-0 mt-2">
                        <Intl id="bookedAt" />
                      </p>
                      <p className="text-gray-2 m-0">
                        {data.date
                          ? `${moment
                            .utc( data.created )
                            .format( 'ddd DD MMM YYYY' )}`
                          : ''}
                      </p>

                      <p className="font-weight-500 m-0 mt-2">
                        <Intl id="cost" />
                      </p>
                      <p className="text-gray-2">
                        {data.cost
                          ? numeral( formatFloat( data.cost ) ).format(
                            currencyFormat( userCurrency ),
                          )
                          : ''}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p className="font-weight-500 m-0">
                        <Intl id="status" />
                      </p>
                      <Select
                        field="status"
                        options={[
                          {
                            name: 'pending',
                            id: 'pending',
                          },
                          {
                            name: 'confirmed',
                            id: 'confirmed',
                          },
                          {
                            name: 'paid',
                            id: 'paid',
                          },
                          {
                            name: 'cancelled',
                            id: 'cancelled',
                          },
                        ]}
                      />
                    </Col>
                  </Row>

                  <CanAccess I="edit" a="service">
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <FormActionsBtns />
                      </Col>
                    </Row>
                  </CanAccess>
                </EditCard>
                <DashboardItems
                  data={bookedServiceItems}
                  onAddNewItem={onAddNewItem}
                  serviceId={serviceId}
                  onEditItem={onEditItem}
                  onDeleteItem={onDeleteItem}
                  userCurrency={userCurrency}
                />
              </>
            )}
          />
        </Container>
      </>
    );
  }
  ,
);
