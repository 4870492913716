import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  SimpleHeader, AsyncTable, AddBtn, TableGenericFilter, CanAccess,
} from 'components';
import { canEditViewDelete } from 'config/Ability';

export default React.memo( ( {
  data, isLoading, onFetchData, onOpenNewModal,
} ) => {
  const { canView, canEdit } = canEditViewDelete( 'property_manager' );

  return (
    <>
      <SimpleHeader
        title="propertyManagers"
      />
      <Container fluid>
        <AsyncTable
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          rightContent={<CanAccess I="add" a="property_manager"><AddBtn title="newPropertyManager" onClick={onOpenNewModal} /></CanAccess>}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter
                  filters={filters}
                  setFilter={setFilter}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'commercialName',
              accessor: 'name',
              Cell: ( { cell } ) => (
                canView || canEdit ? (
                  <Link to={`/property-managers/edit/${cell.row.original.id}`}>
                    {cell.value}
                  </Link>
                ) : cell.value ),
            },
            {
              Header: 'corporateName',
              accessor: 'legalName',
            },
            {
              Header: 'city',
              accessor: 'city',
            },
            {
              Header: 'contactPerson',
              accessor: 'contactPerson',
              Cell: ( { cell } ) => ( cell.value ? `${cell.value.firstName || ''} ${cell.value.lastName || ''}` : '' ),
            },
          ]}
        />
      </Container>
    </>
  );
} );
