import React from 'react';
import Drawer from 'rc-drawer';
import {
  Intl,
  Form, FormActionsBtns,
  TextField,
} from 'components';
import { Col, Row } from 'reactstrap';
import { required } from 'config/InputErrors';

const ContactNoteEditView = ( {
  open, onClose, data, onSubmit,
} ) => (
  <Drawer
    open={open}
    placement="right"
    handler={false}
    maskClosable
    level={null}
    onClose={onClose}
    className="zindex-drawer"
    width={600}
  >
    <div className="p-4">
      <h2>
        <Intl id={data ? 'crm.note.edit' : 'crm.note.new'} />
      </h2>
      <hr className="mt-0" />
      <Form
        initialValues={data}
        showErrorsInFooter
        onSubmit={onSubmit}
        body={( ) => (
          <>
            <Row className="mb-3">
              <Col md={12}>
                <TextField
                  field="text"
                  label="text"
                  type="textarea"
                  rows={6}
                  validate={required}
                />
              </Col>
            </Row>
          </>
        )}
        footer={<FormActionsBtns onClickCancel={onClose} />}
      />
    </div>
  </Drawer>
);

export default ContactNoteEditView;
