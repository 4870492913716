import React from 'react';
import { connect } from 'react-redux';

import ItemEditView from 'views/Checklist/ItemEdit';
import ChecklistItemService from 'api/ChecklistItem';
import TranslationService from 'api/Translation';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';

const ItemEdit = ( {
  checklistId, itemId, modalOpened, onReload, onCloseModal,
  toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ChecklistItemService.getChecklistItem( checklistId, itemId ),
    translationPromise: () => TranslationService.getTaskTypeTranslations( itemId, 'item' ),
    toggleErrorAlert,
    conditional: !!itemId && modalOpened,
    deps: [itemId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( itemId
      ? ChecklistItemService.updateChecklistItem( checklistId, itemId, dataToSend )
      : ChecklistItemService.saveChecklistItem( checklistId, dataToSend ) ),
    toggleLoading,
    toggleInfoAlert,
    deps: [checklistId, itemId],
    translations: true,
    callback: onReload,
  } );

  return (
    <ItemEditView
      itemId={itemId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ItemEdit );
