import React from 'react';
import {
  Container, Card,
} from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router';
import classnames from 'classnames';
import {
  SimpleHeader, HorizontalMenu,

} from 'components';

export default React.memo(
  ( {
    data,
    entityId,
    parentPath,
    tabs,
    parentIds,
    paths,
    pathLayout,
    pathname,
    isLoading,
    onReloadData,
  } ) => {
    const getRoutes = ( routesList ) => routesList.map( ( route ) => {
      if ( route.component ) {
        return (
          <Route
            exact={route.exact}
            path={pathLayout + route.path}
            render={( props ) => (
              <route.component
                {...props}
                data={data}
                parentPath={parentPath}
                entityId={entityId}
                parentIds={parentIds}
                paths={paths}
                extraData={route.extraData}
                onReloadData={onReloadData}
              />
            )}
            key={route.layout + route.path}
          />
        );
      }
      return null;
    } );

    return (
      <>
        <SimpleHeader parentLinks={[{ name: 'places', href: parentPath }]} />
        <Container
          fluid
          className={classnames( {
            'app-component-loading': isLoading,
          } )}
        >
          {!!entityId
        && (
          <Card className="card-sec-top border-bottom">
            <HorizontalMenu
              tabs={tabs}
              pathLayout={pathLayout}
              pathname={pathname}
            />
          </Card>
        )}
          <div>
            <Switch>
              {getRoutes( tabs )}
              <Redirect from="*" to={tabs[0].path} />
            </Switch>
          </div>
        </Container>
      </>
    );
  },
);
