import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';

import {
  Datepicker,
  ModalForm,
  AsyncSelect,
  Select,
  Button,
  Intl,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import { deleteArrayElement } from 'utils';

export default React.memo(
  ( {
    data,
    open,
    onSubmit,
    onClose,
    onFetchTeams,
    templates,
    onChangeTeam,
    isLoadingTemplates,
  } ) => {
    const [slots, setSlots] = useState( 0 );
    const [extraSlots, setExtraSlots] = useState( 0 );
    const [members, setMembers] = useState( [] );
    const [allowedDays, setAllowedDays] = useState( [] );

    const handleDeleteExtraSlots = ( index, form ) => {
      const { values } = form.getState();
      const extraResourcesUpdated = deleteArrayElement(
        _.get( values, 'extraResources', [] ),
        index,
      );
      form.change( 'extraResources', extraResourcesUpdated );
      setExtraSlots( extraResourcesUpdated.length );
    };

    const handleChangeTeam = ( team, form ) => {
      setMembers(
        _.map(
          _.get( team, 'original.members', [] ),
          ( { id, firstName, lastName } ) => ( {
            id,
            name: `${firstName} ${lastName}`,
          } ),
        ),
      );
      form.change( 'resources', [] );
      form.change( 'extraResources', [] );
      form.change( 'endDate', '' );
      form.change( 'initDate', '' );
      setSlots( 0 );
      setExtraSlots( 0 );
      onChangeTeam( team );
    };

    const handleChangeTemplate = ( id, form ) => {
      const template = _.find( templates, { id } );
      setSlots( _.get( template, 'resources' ) );
      setAllowedDays( _.get( template, 'weekDays' ) );
      form.change( 'endDate', '' );
      form.change( 'initDate', '' );
    };

    const validateDate = ( date ) => {
      const days = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
      if ( date.isAfter( moment().subtract( 1, 'day' ) ) ) {
        return allowedDays.includes( days[date.day()] );
      }
      return false;
    };

    const renderSlots = ( num ) => {
      const elements = [];
      // eslint-disable-next-line no-plusplus
      for ( let index = 0; index < num; index++ ) {
        elements.push( index );
      }
      return (
        <Row>
          {elements.map( ( key ) => (
            <React.Fragment key={key}>
              <Col xs={2}>
                <Intl id="resource" />
                {` ${key + 1}`}

              </Col>
              <Col xs={10}>
                <Select
                  field={`resources[${key}]`}
                  options={members}
                  isClearable
                />
              </Col>
            </React.Fragment>
          ) )}
        </Row>
      );
    };
    const renderExtraSlots = ( num, form ) => {
      const elements = [];
      // eslint-disable-next-line no-plusplus
      for ( let index = 0; index < num; index++ ) {
        elements.push( index );
      }
      return (
        <>
          <Row>
            {elements.map( ( key ) => (
              <React.Fragment key={key}>
                <Col xs={12} lg={2}>{`Extra slot ${key + 1}`}</Col>
                <Col xs={10} lg={8}>
                  <Select
                    field={`extraResources[${key}]`}
                    validate={required}
                    options={members}
                  />
                </Col>
                <Col xs={2} className="d-flex align-items-center">
                  <Button
                    color="link"
                    icon="fas fa-minus-circle text-lg"
                    title=" "
                    onClick={() => handleDeleteExtraSlots( key, form )}
                  />
                </Col>
              </React.Fragment>
            ) )}
            <Col xs={12}>
              <Button
                color="link"
                icon="fa fa-plus"
                title="addExtraResource"
                onClick={() => setExtraSlots( ( state ) => state + 1 )}
              />
            </Col>
          </Row>
        </>
      );
    };

    useEffect( () => {
      if ( open ) {
        setSlots( 0 );
        setExtraSlots( 0 );
        setMembers( [] );
        setAllowedDays( [] );
      }
    }, [open] );

    return (
      <ModalForm
        title="bulkAssignation"
        open={open}
        bodyClassName="overflow-visible"
        bodyStyle={{ minHeight: 150 }}
        onClose={onClose}
        showErrorsInFooter
        initialValues={data}
        onSubmit={canModify( 'shift' ) ? onSubmit : () => {}}
        formEnabled={canModify( 'shift' )}
        body={( form ) => (
          <>
            <Row>
              <Col sm={6}>
                <AsyncSelect
                  label="team"
                  defaultOptions
                  validate={required}
                  loadOptions={onFetchTeams}
                  onChange={( team ) => handleChangeTeam( team, form )}
                />
              </Col>
              <Col sm={6}>
                <Select
                  label="templates"
                  field="shiftTemplateId"
                  validate={required}
                  options={templates}
                  isLoading={isLoadingTemplates}
                  onChange={( id ) => handleChangeTemplate( id, form )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Datepicker
                  field="initDate"
                  label="from"
                  dateFormat="DD/MM/YYYY"
                  validate={required}
                  customValidate={validateDate}
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="endDate"
                  label="till"
                  dateFormat="DD/MM/YYYY"
                  validate={required}
                  customValidate={validateDate}
                />
              </Col>
            </Row>
            {renderSlots( slots )}
            {renderExtraSlots( extraSlots, form )}
          </>
        )}
      />
    );
  },
);
