import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ContractView from 'views/Resource/Edit/Contract';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
import EmployeeService from 'api/Employee';
import { ConfirmationModal } from 'components';
import FiltersActions from 'store/reducers/Filters';

const Contracts = ( {
  entityId,
  updateFilters,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const filterToDate = moment().startOf( 'day' );
  const filterFromDate = moment( filterToDate ).clone().subtract( 180, 'day' );
  const initialDates = useRef( moment.range( filterFromDate, filterToDate ) );

  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => EmployeeService.getContracts( entityId, params ),
    toggleErrorAlert,
    callback: ( params ) => {
      updateFilters( 'resourceContracts', {
        from: params.from,
        till: params.till,
      } );
    },
    deps: [entityId, dataSubmitted],
  } );

  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  /// //////////////////
  const [contractIdToDelete, setContractIdToDelete] = useState( null );

  const [openModalDelete, setOpenModalDelete] = useState( false );
  const toggleModalDelete = () => setOpenModalDelete( ( state ) => !state );

  const deleteContract = useDelete( {
    promise: () => EmployeeService.deleteContract( entityId, contractIdToDelete ),
    callback: ( ) => {
      setOpenModalDelete( false );
      reload();
    },
    toggleLoading,
    toggleErrorAlert,
    deps: [entityId, contractIdToDelete],
  } );

  return (
    <>
      <ContractView
        entityId={entityId}
        fetchData={fetchData}
        dataTable={dataTable}
        dataLoading={dataLoading}
        defaultFilters={{
          from: initialDates.current.start
            ? initialDates.current.start.format( 'YYYY-MM-DD' )
            : undefined,
          till: initialDates.current.end
            ? initialDates.current.end.format( 'YYYY-MM-DD' )
            : undefined,
        }}
        reload={reload}
        setContractIdToDelete={setContractIdToDelete}
        toggleModalDelete={toggleModalDelete}
      />
      <ConfirmationModal
        open={openModalDelete}
        // title={openModalDisable ? 'disableModal.confirmation' : ''}
        onConfirm={deleteContract}
        onClose={toggleModalDelete}
      />
    </>
  );
};

/* const mapStateToProps = ( { user, filters } ) => ( {
 // userRole: user.mainRole,
  defaultFilters: _.get( filters, 'filters.resources' ) || {},
} ); */
const mapDispatchToProps = {
  updateFilters: FiltersActions.updateFilters,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( Contracts );
