import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import RSSPostView from 'views/Post/RSS';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import CityService from 'api/Settings/City';
import RSSService from 'api/RSS';
import { useFetch } from 'hooks';
import useFetchTableData from 'hooks/useFetchTableData';

const RSSPost = ( {
  modalOpened,
  onCloseModal,
  onNewPost,
  propertyManager,
  toggleErrorAlert,
} ) => {
  const [cities, citiesLoading] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( {
      propertyManager: propertyManager.id,
    } ),
    format: ( dataToFormat ) => _.map( _.get( dataToFormat, 'data', [] ), ( { city } ) => ( {
      id: city.id,
      name: city.name,
    } ) ),
    conditional: !!propertyManager.id && !!modalOpened,
    deps: [propertyManager, modalOpened],
    toggleErrorAlert,
  } );

  const [selectedCity, setSelectedCity] = useState();

  useEffect( () => {
    if ( cities.length ) setSelectedCity( cities[0].id );
  }, [cities] );

  const [RSSSources, RSSSourcesLoading] = useFetch( {
    initialState: [],
    promise: () => RSSService.getRSSsources( {
      city: selectedCity,
    } ),
    format: ( dataToFormat ) => _.get( dataToFormat, 'data', [] ),
    conditional: !!selectedCity && !!modalOpened,
    deps: [selectedCity, modalOpened],
  } );

  const [selectedRSSSource, setSelectedRSSSource] = useState();
  useEffect( () => {
    if ( RSSSources.length ) {
      setSelectedRSSSource( RSSSources[0].id );
    }
  }, [RSSSources] );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => RSSService.getRSSsource( selectedRSSSource, params ),
    toggleErrorAlert,
    conditional: !!selectedRSSSource && !!modalOpened,
    deps: [selectedRSSSource, modalOpened],
  } );

  return (
    <RSSPostView
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      modalOpened={modalOpened}
      onCloseModal={onCloseModal}
      onNewPost={onNewPost}
      cities={cities}
      selectedCity={selectedCity}
      isLoadinggCities={citiesLoading}
      onChangeCity={setSelectedCity}
      RSSSources={RSSSources}
      selectedRSSSource={selectedRSSSource}
      isLoadingRSSSources={RSSSourcesLoading}
      onChangeSource={setSelectedRSSSource}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( RSSPost );
