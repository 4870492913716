/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import ContractService from 'api/Settings/Contract';
import BookingContractsService from 'api/BookingContracts';
import { formatDataToSend } from 'utils';

const createNewContract = async ( form, formData, bookingData ) => {
  try {
    // const accomodationId = _.get( bookingData, 'currentAccommodation.id' );
    const cityId = _.get( bookingData, 'city.id' );
    const countryId = _.get( bookingData, 'city.state.country.id' );
    const propertyId = _.get( bookingData, 'currentAccommodation.roomType.property.id' );
    const bookingId = _.get( bookingData, 'id' );
    const translation = true;
    const dataToSend = formatDataToSend( formData, form, translation );
    delete dataToSend.translationfile;
    delete dataToSend.file;
    delete dataToSend.state;

    const respCS = await ContractService.saveContractSetting(
      {
        ...dataToSend,
        property: propertyId,
        country: countryId,
        city: cityId,
        bookedAccommodation: bookingId,
      },
    );

    if ( !_.isString( _.get( formData, 'file' ) ) && _.get( formData, 'file' ) ) {
      const contractToSend = {
        file: formData.file,
        name: formData.file.name,
      };
      await ContractService.updateContractFile( respCS.data.id, contractToSend );
    }
    if ( !_.isString( _.get( formData, 'translationfile' ) ) && _.get( formData, 'translationfile' ) ) {
      const contractToSend = {
        file: formData.translationfile.file,
        name: formData.translationfile.file.name,
        locale: formData.translationfile.locale,
      };
      await ContractService.updateContractFile( respCS.data.id, contractToSend );
    }

    const response = await BookingContractsService.saveContract( bookingId, {
      contractSetting: respCS.data.id,
      manual: true,
    } );

    return response;
  } catch ( error ) {
    return [error, { ok: false }, null];
  }
};

export { createNewContract };
