import { createSeasonDiscount, getAllSeasonsDiscount } from 'api/Discounts/SeasonDiscount';
import { useFetch, useSubmit } from 'hooks';
import React, { useCallback, useState } from 'react';
import SettingsActions from 'store/reducers/Settings';
import MonthView from 'views/PropertyManager/Edit/Discount/EditDrawer/Seasons/Month';

import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import SeasonEdit from './Edit';

const MonthEdit = ( {
  visible,
  discountId,
  open,
  onOpenEditItemModal,
  onReloadDiscounts,
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
} ) => {
  const [startDate, setStartDate] = useState( null );
  const [lastNight, setLastNight] = useState( null );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [modalOpen, setModalOpen] = useState( false );

  const formatToEvents = ( responseData ) => {
    const { data: seasons } = responseData;
    return _.map( seasons, ( item ) => ( {
      ...item,
      start: item.startDate.replace( 'Z', '' ),
      end: item.lastNight.replace( 'Z', '' ),
      title: item.discountType === 'NOMINAL' ? `Discount: ${item.discountValue}` : `Discount: ${item.discountValue} %`,
      color: '#f9b586',
    } ) );
  };

  const [data] = useFetch( {
    promise: () => getAllSeasonsDiscount( {
      discountId,
      page: 1,
      elementsPerPage: 30,
      fromStart: startDate,
      fromEnd: lastNight,
      tillStart: startDate,
      tillEnd: lastNight,
    } ),
    format: formatToEvents,
    toggleErrorAlert,
    conditional: visible && !!discountId,
    deps: [discountId, visible, dataSubmitted],
    reInit: true,
  } );
  const onOpenModal = ( ) => {
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };
  const updateInitialDates = ( start, last ) => {
    setStartDate( start );
    setLastNight( last );
  };

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
    onReloadDiscounts();
    onCloseModal();
  }, [onReloadDiscounts] );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( createSeasonDiscount( dataToSend ) ),
    format: ( dataToSend ) => ( {
      discountId,
      seasons: [
        {
          ...dataToSend,
          lastNight: new Date( lastNight ).toISOString(),
          startDate: new Date( startDate ).toISOString(),
        },
      ],
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: reloadData,
    deps: [discountId, startDate, lastNight],
  } );

  return (
    <>
      <MonthView
        events={data || []}
        visible={visible}
        onSubmit={onSubmit}
        onClose={onCloseModal}
        open={open}
        onOpenModal={onOpenModal}
        isLoading={false}
        onOpenEditItemModal={onOpenEditItemModal}
        updateDates={updateInitialDates}
      />
      <SeasonEdit
        discountId={discountId}
        data={{
          startDate: moment( startDate ).startOf( 'month' ),
          lastNight: moment( startDate ).endOf( 'month' ).subtract( 1, 'days' ),
        }}
        events={data || []}
        open={modalOpen}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>

  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( MonthEdit );
