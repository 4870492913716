import React from 'react';

import { TextField, ModalForm } from 'components';
import { email, required, composeValidators } from 'config/InputErrors';

export default React.memo( ( { open, onClose, onSubmit } ) => (
  <ModalForm
    title="newPropertyManager"
    showErrorsInFooter
    open={open}
    size="md"
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <>
      <TextField
        field="name"
        validate={required}
        label="commercialName"
      />
      <TextField
        field="email"
        validate={composeValidators( required, email )}
        label="email"
      />
      <TextField
        field="keyword"
        validate={required}
        label="workspace"
      />
    </>
  </ModalForm>
) );
