import { create } from 'apisauce';
import _ from 'lodash';
import { storage } from 'utils';

const api = create( {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 2 * 60000,
} );

api.addRequestTransform( ( request ) => {
  const accessToken = storage.get( 'nomads:token' );
  if ( accessToken ) request.headers.Authorization = `Bearer ${accessToken}`;

  const contentLocale = storage.get( 'nomads:contentLocale' );
  if ( contentLocale ) request.headers['x-locale'] = contentLocale;
} );

api.addResponseTransform( ( response ) => {
  const { data, ok } = response;

  if ( !ok ) {
    let errors = '';

    if ( response && response.status === 401 && data && data.message === 'Authentication Required' ) {
      storage.clear( 'nomads:token' );
      storage.clear( 'nomads:contentLocale' );
      storage.clear( 'persist:nomads' );
      return window.location.replace( '/auth/login' );
    }

    if ( data ) {
      if ( data.form && data.form.children ) {
        errors = {};
        _.map( data.form.children, ( error, key ) => {
          if ( !_.isEmpty( error ) && error.errors ) {
            errors[key] = error.errors.join( '\n' );
          }
        } );
      } else if ( data.errors ) {
        if ( _.isObject( data.errors ) && !_.isArray( data.errors ) ) {
          errors = [];
          _.map( data.errors, ( error ) => {
            if ( _.isString( error ) ) errors.push( error );
            else if ( _.isArray( errors ) ) errors.push( error.join( '\n' ) );
          } );
          errors = errors.join( '\n' );
        } else if ( _.isArray( data.errors ) ) {
          errors = data.errors.join( '\n' );
        } else {
          errors = data.errors;
        }
      } else if ( data.message ) errors = data.message;
      else errors = data;
    }

    if ( !errors || _.isEmpty( errors ) ) errors = 'apiError';
    if ( response.config.method === 'get' && !_.isString( errors ) ) response.errors = 'apiError';
    else response.errors = errors;
  }
} );

export default api;
