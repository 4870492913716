import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import DashboardView from 'views/TravelReport/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import TravelReportAuthorityService from 'api/TravelReportAuthority';
import { useDelete, useFetchTableData } from 'hooks';
import ModalEdit from '../Edit';

const Dashboard = ( {
  toggleErrorAlert,
  toggleLoading,
} ) => {
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: () => TravelReportAuthorityService.getAlltravelReportAuthority( {
      level: 'property_manager',
    } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  const deleteEntity = useDelete( {
    promise: TravelReportAuthorityService.deleteTravelReportAuthority,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <DashboardView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteEntity}
      />

      <ModalEdit
        travelReportId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.communityRules' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
