import React from 'react';
import { connect } from 'react-redux';

import ExtraExpenseEditView from 'views/ExtraExpenses/Edit';
import ExtraExpenseService from 'api/ExtraExpenses';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';

const ExtraExpenseEdit = ( {
  extraExpenseId, modalOpened, onReload, onCloseModal,
  toggleErrorAlert, toggleInfoAlert, toggleLoading, propertyManager,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ExtraExpenseService.getExtraExpense( extraExpenseId ),
    toggleErrorAlert,
    conditional: !!extraExpenseId && modalOpened,
    deps: [extraExpenseId, modalOpened],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => {
      const _dataToSend = {
        ...dataToSend,
        propertyManager: propertyManager.id,
      };
      return extraExpenseId
        ? ExtraExpenseService.updateExtraExpense( extraExpenseId, _dataToSend )
        : ExtraExpenseService.saveExtraExpense( _dataToSend );
    },
    toggleLoading,
    toggleInfoAlert,
    translations: true,
    deps: [extraExpenseId],
    callback: onReload,
  } );

  return (
    <ExtraExpenseEditView
      extraExpenseId={extraExpenseId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ExtraExpenseEdit );
