import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/customers';

export default {
  getEmployees( params ) {
    return api.get( baseEndpoint, { ...params, employee: true } );
  },

  getEmployeesForTask( params ) {
    return api.get( `${baseEndpoint}/type/employees-zone`, params );
  },

  getEmployee( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveEmployee( data ) {
    return api.post( baseEndpoint, data );
  },

  updateEmployee( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteEmployee( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },

  disableEmployee( id ) {
    return api.delete( `${baseEndpoint}/${id}?disabled=true` );
  },
  //
  getContracts( customerId, params ) {
    return api.get( `${baseEndpoint}/${customerId}/employment-contract`, params );
  },
  createContract( customerId, params ) {
    return api.post(
      `${baseEndpoint}/${customerId}/employment-contract`,
      formatFormDataBody( params ),
    );
  },
  deleteContract( customerId, contractId ) {
    return api.delete(
      `${baseEndpoint}/${customerId}/employment-contract/${contractId}`,
    );
  },
  //
  getShifts( customerId, params ) {
    return api.get( `/shifts/assigned/${customerId}`, params );
  },
  getTasks( customerId, params ) {
    return api.get( `/tasks/assigned/${customerId}`, params );
  },
  getPayrolls( customerId, params ) {
    return api.get( `${baseEndpoint}/${customerId}/payroll`, params );
  },
  createPayroll( customerId, params ) {
    return api.post(
      `${baseEndpoint}/${customerId}/payroll`,
      formatFormDataBody( params ),
    );
  },
  deletePayroll( customerId, payrollId ) {
    return api.delete( `${baseEndpoint}/${customerId}/payroll/${payrollId}` );
  },
  getPermitRequests( customerId, params ) {
    return api.get( `${baseEndpoint}/${customerId}/employee-permits-application`, params );
  },
  getPermitRegisters( customerId, params ) {
    return api.get( `${baseEndpoint}/${customerId}/employee-permits-register`, params );
  },
  getAllEmployeePermitRegisters( params ) {
    return api.get( '/customers/all/employee-permits-register', params );
  },
  createPermitRegister( customerId, params ) {
    return api.post( `${baseEndpoint}/${customerId}/employee-permits-register`, params );
  },
  createPermitRequest( customerId, params ) {
    return api.post( `${baseEndpoint}/${customerId}/employee-permits-application`, params );
  },
  updatePermitRequest( customerId, permitId, params ) {
    return api.put( `${baseEndpoint}/${customerId}/employee-permits-application/${permitId}`, params );
  },
  patchPermitRegister( customerId, permitId, params ) {
    return api.patch( `${baseEndpoint}/${customerId}/employee-permits-application/${permitId}`, params );
  },
  deletePermitRequest( customerId, permitId ) {
    return api.delete( `${baseEndpoint}/${customerId}/employee-permits-application/${permitId}` );
  },
  getPermitRequest( customerId, permitId ) {
    return api.get( `${baseEndpoint}/${customerId}/employee-permits-application/${permitId}` );
  },
  getAllEmployeePermitRequests( params ) {
    return api.get( `${baseEndpoint}/all/employee-permits-application`, params );
  },
};
