import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/settings/ownerApp';

export default {
  getOwnerApp( params ) {
    return api.get( baseEndpoint, params );
  },

  updateOwnerApp( data ) {
    return api.put( baseEndpoint, data );
  },

  updateOwnerAppImage( data ) {
    return api.post( `${baseEndpoint}/image`, formatFormDataBody( data ) );
  },

  getTranslation( id ) {
    return api.get( `/property-managers/translation/ownerApp/${id}` );
  },
};
