import React, { useContext } from 'react';
import {
  Row, Col, Badge, Card, Container,
} from 'reactstrap';
import moment from 'moment';
import {
  SimpleHeader, AsyncTable,
  TableActionsBtns,
  TableGenericFilter,
  TableSelectFilter,
  Intl,
  AddBtn,
} from 'components';
import { PipelinesCRMContext } from 'containers/CRM/Pipelines/context';
import PipelineEdit from 'containers/CRM/Pipelines/Edit';

const Pipelines = ( {
  data, isLoading, onFetchData, onDeletePipeline,
} ) => {
  const { onEditPipeline } = useContext( PipelinesCRMContext );

  return (
    <>
      <SimpleHeader
        title="crm.pipelines"
      />
      <Container fluid>
        <Card>
          <AsyncTable
            id="Pipelines"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            onFetchData={onFetchData}
            rightContent={(
              <AddBtn
                title="crm_pipelines_new"
                onClick={() => {
                  onEditPipeline( null );
                }}
              />
          )}
            customFilters={( { filters, setFilter } ) => (
              <Row>
                <Col md={8} lg={4}>
                  <TableGenericFilter label="search" filters={filters} setFilter={setFilter} />
                </Col>
                <Col md={2} lg={2}>
                  <TableSelectFilter
                    name="isActive"
                    label="active"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    translateOptions
                    options={[
                      { id: 'true', name: 'yes' },
                      { id: 'false', name: 'no' },
                    ]}
                    setFilter={setFilter}
                  />
                </Col>
              </Row>
            )}
            columns={[
              {
                Header: 'name',
                accessor: 'name',
                Cell: ( { cell } ) => (
                  <div style={{ maxWidth: 700 }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={( e ) => {
                        e.preventDefault();
                        onEditPipeline( cell.row.original );
                      }}
                    >
                      {cell.value}
                    </a>
                  </div>

                ),
              },
              {
                Header: 'crm.pipelines.description',
                accessor: 'description',
                Cell: ( { cell } ) => (
                  <div className="text-small">
                    {cell.value}
                  </div>
                ),
              },
              {
                Header: 'crm.pipelines.stages',
                accessor: 'stages',
                Cell: ( { cell } ) => (
                  <ul className="text-small">
                    {cell.value.map( ( val ) => <li key={val}>{val}</li> )}
                  </ul>
                ),
              },
              {
                Header: 'crm.pipelines.isActive',
                accessor: 'isActive',
                Cell: ( { cell } ) => (
                  <Badge color={cell.value ? 'success' : 'danger'} pill>
                    <Intl id={`crm.pipelines.isActive.${cell.value ? 'yes' : 'no'}`} />
                  </Badge>
                ),
              },
              {
                Header: 'date',
                accessor: 'createdAt',
                Cell: ( { cell } ) => ( cell.value
                  ? moment( cell.value ).format( 'YYYY-MM-DD' )
                  : '-' ),
              },
              {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        isDelete: true,
                        onClick: () => {
                          onDeletePipeline( cell.row.original.id );
                        },
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </Card>

      </Container>
      <PipelineEdit />
    </>
  );
};

export default Pipelines;
