import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TaskTypeDashboardView from 'views/TaskType/Parent/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import TaskTypeService from 'api/TaskType';
import { useDelete, useFetchTableData } from 'hooks';
import TaskTypeNew from '../New';

const TaskTypeDashboard = ( {
  defaultFilters,
  toggleErrorAlert,
  toggleLoading,
  updateFilters,
  setFiltersError,
  parentPath,
} ) => {
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => TaskTypeService.getTaskTypes( {
      ...params,
      root: 'true',
    } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'taskTypes', { keyword: params.keyword } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'taskTypes' );
        initialReloadError.current = false;
      }
    },
  } );

  const openNewModal = useCallback( ( ) => {
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [closeEditModal] );

  const deleteEntity = useDelete( {
    promise: TaskTypeService.deleteTaskType,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <TaskTypeDashboardView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        defaultFilters={defaultFilters}
        onOpenNewModal={openNewModal}
        onDelete={deleteEntity}
        parentPath={parentPath}
      />
      <TaskTypeNew
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.taskTypes' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( TaskTypeDashboard );
