import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ServiceEditView from 'views/Service/Edit';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import ServiceService from 'api/Service';
import ServiceCatalogueService from 'api/ServiceCatalogue';
import ServiceImageService from 'api/ServiceImage';
import { useFetch } from 'hooks';
import TranslationService from 'api/Translation';
import RegionServices from 'api/Region';
import { serviceWeekdays } from 'config/Constants';
import { each, parallel } from 'utils/async';

const ServiceEdit = ( {
  parentPath, match, history, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const entityId = match.params.id;

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ServiceService.getService( entityId ),
    translationPromise: () => TranslationService.getServiceTranslations( entityId, 'service' ),
    format: ( dataToFormat ) => {
      if ( !entityId ) return dataToFormat;

      const formattedData = { ...dataToFormat };
      if ( formattedData.timetable ) {
        formattedData.timetable = _.map( serviceWeekdays, ( item ) => {
          const day = _.find( formattedData.timetable, { day: item.id } );
          if ( !day ) return item;

          return {
            ...item,
            from: day.from,
            till: day.till,
            active: day.active,
          };
        } );
      }
      if ( formattedData.images ) {
        formattedData.images = _.map( formattedData.images, ( item ) => ( {
          id: item.id,
          url: item.file,
        } ) );
      }
      if ( formattedData.catalogue ) {
        formattedData.catalogs = _.map( formattedData.catalogue, ( item ) => ( {
          id: item.id,
          name: item.name,
          file: {
            id: item.id,
            file: item.file,
            name: item.name,
          },
        } ) );
      }
      return formattedData;
    },
    toggleErrorAlert,
    conditional: !!entityId,
    deps: [entityId],
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form, true );
    dataToSend.timetable = _.chain( dataToSend.timetable )
      .filter( ( item ) => item.from && item.till )
      .map( ( day ) => ( {
        day: day.id,
        from: _.isString( day.from ) ? day.from : day.from.format( 'H:mm' ),
        till: _.isString( day.till ) ? day.till : day.till.format( 'H:mm' ),
        active: day.active,
      } ) ).value();
    delete dataToSend.catalogs;
    delete dataToSend.images;

    dataToSend.requiresTimeSlot = dataToSend.requiresTimeSlot ? 'true' : 'false';

    const [errors, response, responseData] = await handlePromise( entityId
      ? ServiceService.updateService( entityId, dataToSend )
      : ServiceService.saveService( dataToSend ) );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( !formData.catalogs && !formData.images ) {
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      return;
    }

    const imagesToDelete = _.differenceBy( data.images, formData.images, 'id' );
    const catalogsToDelete = _.differenceBy( data.catalogs, formData.catalogs, 'id' );
    // eslint-disable-next-line no-return-await
    return await new Promise( ( resolve, reject ) => {
      parallel( [
        ( cb ) => {
          each( formData.catalogs, async ( catalogue, eCb ) => {
            if ( catalogue.id || !catalogue.file ) return eCb();
            const [catalogueErrors, catalogueResponse] = await handlePromise(
              ServiceCatalogueService.saveCatalogue( responseData.id, {
                catalogue: catalogue.file,
                name: catalogue.name,
              } ),
            );
            if ( !catalogueResponse.ok ) return eCb( catalogueErrors );
            eCb();
          }, ( error ) => cb( error ) );
        },
        ( cb ) => {
          each( formData.images, async ( image, eCb ) => {
            if ( image.id ) return eCb();
            const [imageErrors, imageResponse] = await handlePromise(
              ServiceImageService.saveImage( responseData.id, {
                image: image.file,
              } ),
            );
            if ( !imageResponse.ok ) return eCb( imageErrors );
            eCb();
          }, ( error ) => cb( error ) );
        },
        ( cb ) => {
          each( imagesToDelete, async ( image, eCb ) => {
            const [imageErrors, imageResponse] = await handlePromise(
              ServiceImageService.deleteImage( responseData.id, image.id ),
            );
            if ( !imageResponse.ok ) return eCb( imageErrors );
            eCb();
          }, ( error ) => cb( error ) );
        },
        ( cb ) => {
          each( catalogsToDelete, async ( catalogue, eCb ) => {
            const [catalogueErrors, catalogueResponse] = await handlePromise(
              ServiceCatalogueService.deleteCatalogue( responseData.id, catalogue.id ),
            );
            if ( !catalogueResponse.ok ) return eCb( catalogueErrors );
            eCb();
          }, ( error ) => cb( error ) );
        },
      ], ( error ) => {
        toggleLoading( false );
        if ( error ) {
          return reject( error );
        }
        toggleInfoAlert( 'dataSaved' );
        resolve();
        if ( entityId ) history.push( parentPath );
        else history.push( `${parentPath}/edit/${responseData.id}` );
      } );
    } )
      .catch( ( error ) => Promise.resolve( error ) );
  }, [entityId, data, history, parentPath, toggleInfoAlert, toggleLoading] );

  /* REGIONS ******************************** */
  const [regions, regionsLoading] = useFetch( {
    initialState: [],
    promise: () => RegionServices.getRegions( { page: 1, elementsPerPage: 999 } ),
    toggleErrorAlert,
    format: ( results ) => {
      if ( results && results.data ) {
        return results.data.map( ( { id, name } ) => ( { id, name } ) );
      }
      return [];
    },
  } );
  /* end REGIONS ******************************** */

  return (
    <ServiceEditView
      data={data}
      isLoading={dataLoading}
      parentPath={parentPath}
      entityId={entityId}
      onSubmit={submitForm}
      regions={regions}
      regionsLoading={regionsLoading}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ServiceEdit );
