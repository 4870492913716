export const periodicities = [
  { id: 'once', name: 'fees.once' },
  { id: 'every_week', name: 'every_week' },
  { id: 'every_month', name: 'every_month' },
];

export const types = [
  { id: 'first', name: 'fees.first' },
  { id: 'bookings', name: 'bookings' },
  { id: 'accommodations', name: 'accommodations' },
];

export const billingDates = {
  once: [
    { id: 'first', name: 'fees.first' },
  ],
  every_week: [
    { id: 'monday', name: 'monday' },
    { id: 'tuesday', name: 'tuesday' },
    { id: 'wednesday', name: 'wednesday' },
    { id: 'thursday', name: 'thursday' },
    { id: 'friday', name: 'friday' },
    { id: 'saturday', name: 'saturday' },
    { id: 'sunday', name: 'sunday' },
  ],

  every_month: [
    { id: 'first_day_of_month', name: 'first_day_of_month' },
    { id: '15th_of_month', name: '15th_of_month' },
    { id: 'last_day_of_month', name: 'last_day_of_month' },
  ],
};

export const methodsOfPayment = [
  { id: 'credit_card', name: 'credit_card' },
  { id: 'paypal', name: 'paypal' },
  { id: 'bank_transfer', name: 'bank_transfer' },
  { id: 'cash', name: 'cash' },
  { id: 'other', name: 'other' },
];
