import React from 'react';

import TextField from '../../TextField';

const TableGenericFilter = React.memo( ( {
  filters, setFilter, label,
} ) => (
  <TextField
    name="keyword"
    label={label}
    placeholder="typeSearch"
    formGroupClass="m-0"
    value={filters.keyword}
    onChange={( value ) => setFilter( 'keyword', value )}
  />
) );

export default TableGenericFilter;
