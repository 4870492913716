import React, {
  useCallback, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TeamDashboardView from 'views/Team/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import EmployeeService from 'api/Employee';
import TeamService from 'api/Team';
import ZoneService from 'api/Settings/Zone';
import { useDelete, useFetchTableData } from 'hooks';
import TeamEdit from '../New';

const TeamDashboard = ( {
  propertyManager, parentPath, defaultFilters,
  toggleErrorAlert, toggleLoading, updateFilters, setFiltersError,
} ) => {
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => TeamService.getTeams( {
      ...params,
      manager: params.manager ? params.manager.value : null,
    } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'teams', { keyword: params.keyword } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'resources' );
        initialReloadError.current = false;
      }
    },
  } );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  const fetchZones = useCallback( ( query ) => ZoneService.getZones( '5ddd179b953fe742b02663b2', {
    propertyManager: propertyManager.id,
    elementsPerPage: 10,
    keyword: query,
  } ), [propertyManager] );

  const fetchManagers = useCallback( ( query ) => EmployeeService.getEmployees( {
    propertyManager: propertyManager.id,
    elementsPerPage: 10,
    keyword: query,
    employee: true,
  } ), [propertyManager] );

  const deleteTeam = useDelete( {
    promise: ( id ) => TeamService.updateTeam( id, { enabled: 'false' } ),
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <TeamDashboardView
        data={data}
        isLoading={dataLoading}
        parentPath={parentPath}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onFetchZones={fetchZones}
        onFetchManagers={fetchManagers}
        onDelete={deleteTeam}
      />

      <TeamEdit
        teamId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { user, filters } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
  defaultFilters: _.get( filters, 'filters.teams' ) || {},
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( TeamDashboard );
