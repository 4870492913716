import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const DELETE_ACCOMMODATIONS = gql`
  mutation DeleteAccomodationRate($accomodationRateId: [ID!]!) {
    deleteAccomodationRate(accomodationRateId: $accomodationRateId) {
      ... on BaseError {
        message
      }
      ... on MutationDeleteAccomodationRateSuccess {
        data {
          success
        }
      }
    }
  }
`;

const deleteAccomodations = async ( accomodationRateId ) => {
  const { data } = await client.query( {
    query: DELETE_ACCOMMODATIONS,
    variables: {
      accomodationRateId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteAccomodationRate.message ) {
    return {
      ok: false,
      message: data.deleteAccomodationRate.message,
    };
  }
  const deleteSuccessful = data.deleteAccomodationRate.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteAccomodations;
