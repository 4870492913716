/* eslint-disable import/prefer-default-export */
const formatMessage = ( selectedMessage ) => {
  if ( !selectedMessage ) {
    return {};
  }
  const { accommodationOwner, message, case: subject } = selectedMessage;
  const initialMessage = `\n\n${accommodationOwner.legalName} ---\n${message}`;
  return {
    owner: {
      original: accommodationOwner,
      value: accommodationOwner.id,
      label: accommodationOwner.legalName,
    },
    message: initialMessage,
    case: `Re: ${subject}`,
  };
};

export { formatMessage };
