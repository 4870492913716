import api from 'config/Api/Api';

const baseEndpoint = '/community-rules';

export default {
  getCommunityRules( params ) {
    return api.get( baseEndpoint, params );
  },

  getCommunityRule( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveCommunityRule( data ) {
    return api.post( baseEndpoint, data );
  },

  updateCommunityRule( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteCommunityRule( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
