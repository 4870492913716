import React, { useState } from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import classnames from 'classnames';
import Drawer from 'rc-drawer';

import {
  Intl, HorizontalMenu, DetailCard,
} from 'components';
import IncidenceOverview from 'containers/Incidences/Edit/Overview';
import IncidencesRelatedTasks from 'containers/Incidences/Edit/RelatedTasks/Dashboard';

const tabsList = [
  { name: 'overview' },
  { name: 'relatedTasks' },
];

export default React.memo( ( {
  incidenceId, data, isLoading, open, backgroundReloaded,
  onClose, setData, onReload, onUpdateFieldIncidence, inEditor,
} ) => {
  const [selectedTab, setSelectedTab] = useState( 'overview' );

  const content = (
    <div className={classnames( 'p-4', { 'app-component-loading': isLoading } )}>
      <Row className="align-items-center">
        <Col>
          <span className="text-uppercase font-weight-bold ml-3">
            {data.id ? <Intl id="task" /> : null}
            {' '}
            {data.id ? `#${data.id.slice( data.id.length - 6, data.id.length )}` : ''}
            {backgroundReloaded && !!data && incidenceId === data.id && '*'}
          </span>
          {backgroundReloaded && !!data && incidenceId === data.id
      && (
        <span
          className="text-sm font-weight-normal ml-1 text-underline cursor-pointer"
          onClick={onReload}
        >
          (
          <Intl id="incidenceUpdatedReload" />
          )
        </span>
      )}
        </Col>
        {inEditor ? null : (
          <Col xs={2} className="text-right">
            <i className="app-icon-close cursor-pointer py-2 pl-2" onClick={onClose} />
          </Col>
        )}
      </Row>

      <Row className="mt-2 mb-4">
        <Col>
          <HorizontalMenu
            tabs={tabsList}
            selected={selectedTab}
            onChangeTab={( index ) => setSelectedTab( index )}
          />
        </Col>
      </Row>

      <div className="mt-3">
        <div className={classnames( {
          'd-none': selectedTab !== 'overview',
        } )}
        >
          <IncidenceOverview
            data={data}
            incidenceId={incidenceId}
            setData={setData}
            onUpdateField={onUpdateFieldIncidence}
          />
        </div>

        <div className={classnames( {
          'd-none': selectedTab !== 'relatedTasks',
        } )}
        >
          <IncidencesRelatedTasks
            incidenceId={incidenceId}
            data={data}
          />
        </div>

      </div>
    </div>
  );

  return inEditor ? (
    <Container fluid className="pt-4">
      <DetailCard>{content}</DetailCard>

    </Container>
  ) : (
    <Drawer
      open={open}
      placement="right"
      handler={false}
      maskClosable
      level={null}
      onClose={onClose}
      className="zindex-tooltip"
    >
      {content}
    </Drawer>
  );
} );
