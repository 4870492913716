import api from 'config/Api/Api';

const baseEndpoint = '/employee-permits-settings';

export default {
  getEmployeePermits( params ) {
    return api.get( baseEndpoint, params );
  },

  getEmployeePermit( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveEmployeePermit( data ) {
    return api.post( baseEndpoint, data );
  },

  updateEmployeePermit( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteEmployeePermit( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
