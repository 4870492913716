/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Modal, Row, Col } from 'reactstrap';
import {
  Intl,
  AsyncTable,
  Select,
  TableActionsBtns,
} from 'components';

const RSSPost = ( {
  data,
  isLoading,
  onFetchData,
  modalOpened,
  onCloseModal,
  onNewPost,
  cities,
  selectedCity,
  isLoadinggCities,
  onChangeCity,
  RSSSources,
  selectedRSSSource,
  isLoadingRSSSources,
  onChangeSource,
} ) => (
  <Modal
    className="modal-dialog-centered app-modal modal-wide"
    toggle={onCloseModal}
    isOpen={modalOpened}
  >
    <div className="modal-header">
      <h6 className="modal-title">
        <Intl id="loadFromRSS" />
      </h6>
      <button
        aria-label="Close"
        className="close text-sm"
        data-dismiss="modal"
        type="button"
        onClick={onCloseModal}
      >
        <i className="app-icon-close" />
      </button>
    </div>

    <AsyncTable
      id="rss-posts"
      containerClassName="m-0"
      data={data.data}
      disableSorting
      isLoading={isLoading}
      onFetchData={onFetchData}
      customFilters={() => (
        <div className="px-2">
          <Row>
            <Col md={3}>
              <Select
                inputName="rss-city"
                formGroupClass="m-0"
                label="city"
                placeholder="city"
                value={selectedCity}
                options={cities}
                onChange={onChangeCity}
                isLoading={isLoadinggCities}
              />
            </Col>
            <Col md={5}>
              <Select
                inputName="rss-source"
                formGroupClass="m-0"
                label="RSSsource"
                placeholder="RSSsource"
                value={selectedRSSSource}
                isLoading={isLoadingRSSSources}
                options={RSSSources}
                onChange={onChangeSource}
              />
            </Col>
            {/* {errorMessage && (
              <Col xs={12}>
                <div className="text-center text-danger pt-2">
                  {errorMessage}
                </div>
              </Col>
            )} */}
          </Row>
        </div>
      )}
      useElementsPerPage={false}
      columns={[
        {
          Header: 'image',
          accessor: 'image',
          Cell: ( { cell } ) => ( cell.value ? (
            <div
              className="table-thumbnail-container"
              style={{
                backgroundImage:
                    'url('
                    + '//cnnespanol.cnn.com/wp-content/uploads/2019/09/190917115210-weather-hurricane-humberto-satellite-20190917-11a-super-tease.jpg?quality=100&strip=info&w=940&h=530&crop=1'
                    + ')',
              }}
            />
          ) : (
            'none'
          ) ),
        },
        {
          Header: 'title',
          accessor: 'title',
        },
        {
          Header: 'description',
          accessor: 'description',
        },
        {
          Header: 'link',
          accessor: 'link',
          Cell: ( { cell } ) => ( cell.value ? (
            <a href={cell.value} target="_blank" rel="noopener noreferrer">
              <Intl id="link" />
            </a>
          ) : null ),
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  label: 'newPost',
                  onClick: () => {
                    onNewPost( cell.row.original );
                  },
                },
              ]}
            />
          ),
        },
      ]}
    />
  </Modal>
);

export default RSSPost;
