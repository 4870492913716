export const colorByStatus = {
  prebooking: '#ff6600',
  owner: '#4040d9',
  full_paid: '#bfe6bf',
  not_paid: '#FF0000',
  half_paid: '#FFFF00',
  over_paid: '#008000',
  blocked: '#808080',
  conflicted: '#800080',
};
export const colorTextByStatus = {
  prebooking: '#000',
  owner: '#ffffff',
  full_paid: '#000',
  not_paid: '#ffffff',
  half_paid: '#000',
  over_paid: '#ffffff',
  blocked: '#ffffff',
  conflicted: '#ffffff',
};
