import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ChannelDrawerView from 'views/Support/ChannelDrawer';
import SettingsActions from 'store/reducers/Settings';
import EmployeeService from 'api/Employee';
import ChannelService from 'api/Channel';
import { formatDataToSend, getResourceName, handlePromise } from 'utils';
import { useFetchTableData } from 'hooks';
import RoleService from 'api/Role';
import { roles } from 'config/Constants';

const ChannelDrawer = ( {
  drawerOpened, propertyManager, user, userRole, onCloseDrawer, onReload,
  toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [roleOptions, setRoleOptions] = useState( [] );

  useEffect( () => {
    const getRoles = async () => {
      const [errors, response, responseData] = await handlePromise(
        RoleService.getRoles( { elementsPerPage: 1000 } ),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setRoleOptions( responseData.data );
    };
    getRoles();
  }, [toggleErrorAlert] );

  const fetchEmployees = useCallback( ( query ) => EmployeeService.getEmployees( {
    propertyManager: propertyManager.id,
    elementsPerPage: 10,
    keyword: query,
    employee: true,
  } ), [propertyManager] );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => EmployeeService.getEmployees( { ...params, employee: true } ),
    toggleErrorAlert,
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );
    const [errors, response, responseData] = await handlePromise( ChannelService.saveChannel( {
      ...dataToSend,
      propertyManager: propertyManager.id,
    } ) );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }
    const [msgError, msgResponse, msgResponseData] = await handlePromise(
      ChannelService.sendMessage( responseData.id, {
        body: `${getResourceName( user )} has created the channel ${dataToSend.name}`,
      } ),
    );
    toggleLoading( false );
    if ( !msgResponse.ok ) {
      return msgError;
    }
    toggleInfoAlert( 'dataSaved' );
    onReload( responseData, msgResponseData );
  }, [user, propertyManager, onReload, toggleInfoAlert, toggleLoading] );

  return (
    <ChannelDrawerView
      open={drawerOpened}
      dataTable={data}
      tableLoading={dataLoading}
      options={{ roles: roleOptions }}
      isNomadsAdmin={userRole === roles.nomadsAdmin.id}
      onSubmit={submitForm}
      onClose={onCloseDrawer}
      onFetchEmployees={fetchEmployees}
      onFetchEmployeesTable={fetchData}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  user: _.get( user, 'user.user', {} ),
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ChannelDrawer );
