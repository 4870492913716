import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { canList, canView } from 'config/Ability';
import Marketplace from './Marketplace';
import General from './General';
import Finance from './Finance';
import City from './City/Dashboard';

const PropertyManager = ( { match, parentPath } ) => (
  <Switch>
    {canView( 'settings' )
    && (
      <Route
        path={`${match.url}/finance`}
        render={( props ) => (
          <Finance
            {...props}
            parentId={match.params.id}
            parentPath={parentPath}
            pathLayout={match.url}
          />
        )}
      />
    )}
    {canList( 'settings_city' )
    && (
      <Route
        path={`${match.url}/cities`}
        render={( props ) => (
          <City
            {...props}
            parentId={match.params.id}
            parentPath={parentPath}
            pathLayout={match.url}
          />
        )}
      />
    )}
    {canList( 'plugin' )
    && (
      <Route
        path={`${match.url}/marketplace`}
        render={( props ) => (
          <Marketplace
            {...props}
            parentId={match.params.id}
            parentPath={parentPath}
            pathLayout={match.url}
          />
        )}
      />
    )}
    {canView( 'property_manager' )
    && (
      <Route
        path={match.url}
        render={( props ) => (
          <General
            {...props}
            parentId={match.params.id}
            parentPath={parentPath}
            pathLayout={match.url}
          />
        )}
      />
    )}
  </Switch>
);

export default PropertyManager;
