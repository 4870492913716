/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';

import {
  SimpleHeader,
  Intl,
  Form,
  TextField,
  FormActionsBtns,
  RichTextField,
  Select,
  Button,
  TranslationLayout,
  EmailClipVariables,
} from 'components';
import { required } from 'config/InputErrors';

const Templates = ( {
  data,
  isLoading,
  onSubmit,
  parentPath,
  onPreview,
  entityId,
} ) => {
  const [previewData, setPreviewData] = useState( data );
  const updatePreview = ( field ) => ( value ) => setPreviewData( ( state ) => ( {
    ...state,
    [field]: value,
  } ) );

  return (
    <>
      <SimpleHeader title={entityId ? 'editTemplate' : 'newTemplate'} />
      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <Row>
          <Col md={9}>
            <Form
              initialValues={{
                type: 'email_push',
                ...data,
              }}
              showErrorsInFooter
              onSubmit={onSubmit}
              body={( form ) => {
                const type = _.get( form.getState(), 'values.type', '' );
                const isEmailType = type === 'email' || type === 'email_push';
                const isPushType = type === 'push' || type === 'email_push';

                return (
                  <>
                    <TranslationLayout
                      translationFields={( locale ) => (
                        <>
                          {isEmailType && (
                          <>
                            <Row>
                              <Col md={5}>
                                <TextField
                                  field={`translations.${locale}.subject`}
                                  label="templates.subject"
                                  labelClass="font-weight-bold"
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <RichTextField
                                  field={`translations.${locale}.bodyEmail`}
                                />
                              </Col>
                            </Row>
                          </>
                          )}
                          {isPushType && (
                          <Row>
                            <Col md={5}>
                              <TextField
                                field={`translations.${locale}.bodyPush`}
                                label="pushNotification"
                                labelClass="font-weight-bold"
                              />
                            </Col>
                          </Row>
                          )}
                        </>
                      )}
                    >
                      { isEmailType && (
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <Button
                            title="emailPreview"
                            onClick={() => onPreview( form.getState() )}
                          />
                        </Col>
                      </Row>
                      )}
                      <Row>
                        <Col md={4}>
                          <Select
                            field="type"
                            label="type"
                            labelClass="font-weight-bold"
                            options={[
                              {
                                id: 'email',
                                name: 'Email',
                              },
                              {
                                id: 'push',
                                name: 'Push notification',
                              },
                              {
                                id: 'email_push',
                                name: 'Email and push notification',
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5}>
                          <TextField
                            field="title"
                            label="title"
                            labelClass="font-weight-bold"
                            validate={required}
                          />
                        </Col>
                        <Col md={7}>
                          <TextField
                            field="description"
                            label="description"
                            labelClass="font-weight-bold"
                          />
                        </Col>
                      </Row>
                      {isEmailType && (
                      <>
                        <Row>
                          <Col md={5}>
                            <TextField
                              field="subject"
                              label="templates.subject"
                              labelClass="font-weight-bold"
                              validate={required}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={5}>
                            <label className="font-weight-bold form-control-label">
                              <Intl id="templates.banner" />
                            </label>
                            <TextField
                              label={
                                    previewData.bannerName || 'templates.banner.image'
                                  }
                              type="file"
                              onChange={async ( val, e ) => {
                                const banner = e.currentTarget.files[0];
                                if ( banner ) {
                                  updatePreview( 'bannerURL' )(
                                    URL.createObjectURL( banner ),
                                  );
                                  updatePreview( 'bannerName' )( banner.name );
                                  form.change( 'banner', banner );
                                }
                              }}
                              labelClass="custom-file-label"
                              inputClass="custom-file-input"
                              formGroupClass="custom-file"
                              accept="image/x-png,image/jpeg"
                            />
                            <span className="mt-1 help-text">
                              <Intl id="templates.banner.help" />
                            </span>
                          </Col>
                          <Col md={7}>
                            {( !!previewData.bannerURL || !!data.banner ) && (
                            <img
                              src={previewData.bannerURL || data.banner}
                              className="templates_banner"
                              alt="banner"
                            />
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <RichTextField
                              field="bodyEmail"
                              height="400"
                              validate={required}
                            />
                          </Col>
                        </Row>
                      </>
                      )}
                      {isPushType && (
                      <Row>
                        <Col md={5}>
                          <TextField
                            field="bodyPush"
                            label="pushNotification"
                            labelClass="font-weight-bold"
                            validate={required}
                          />
                        </Col>
                      </Row>
                      )}
                    </TranslationLayout>
                  </>
                );
              }}
              footer={<FormActionsBtns cancelTo={parentPath} />}
            />
          </Col>
          <Col md={3}>
            <EmailClipVariables />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Templates;
