import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import PropertyEditView from 'views/Property/Edit';
import SettingsActions from 'store/reducers/Settings';
// import { formatCustomValues } from 'utils';
// import CustomFieldService from 'api/Settings/CustomField';
import { propertyRoutes } from 'config/Routes';
import PropertyService from 'api/Property';
import { history } from 'store';
import TranslationService from 'api/Translation';
import useFetch from 'hooks/useFetch';

const PropertyEdit = ( {
  parentPath, match, location, toggleErrorAlert,
} ) => {
  const entityId = match.params.id;
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [routesList, setRoutesList] = useState( [propertyRoutes[0]] );
  // const [loadingData, setLoadingData] = useState( false );
  const [data, dataLoading] = useFetch( {
    // promise: () => {
    //   // setLoadingData( true );
    //   return PropertyService.getProperty( entityId );
    // },
    promise: () => PropertyService.getProperty( entityId ),
    translationPromise: () => TranslationService.getPropertyTranslations( entityId ),
    format: ( dataToFormat ) => ( {
      entity: {
        ...dataToFormat,
        haveLift: dataToFormat.lift,
        propertyManagerCityZoneId: _.get( dataToFormat, 'propertyManagerCityZone.id' ),
        city: _.get( dataToFormat, 'propertyManagerCityZone.propertyManagerCity.id' ),
      },
    } ),
    toggleErrorAlert: ( errors ) => {
      // setLoadingData( false );
      toggleErrorAlert( errors );
    },
    conditional: !!entityId,
    deps: [entityId, dataSubmitted],
  } );

  // const [data] = useFetch( {
  //   initialState: {},
  //   promise: () => CustomFieldService.getCustomFields( {
  //     type: 'property',
  //     propertyManager: propertyManager.id,
  //     elementsPerPage: 1000,
  //   } ),
  //   format: ( dataToFormat ) => {
  //     setLoadingData( false );
  //     return ( {
  //       entity: formatCustomValues( entityData, dataToFormat ),
  //       customFields: dataToFormat && dataToFormat.data ? dataToFormat.data : [],
  //     } );
  //   },
  //   toggleErrorAlert: ( errors ) => {
  //     setLoadingData( false );
  //     toggleErrorAlert( errors );
  //   },
  //   conditional: !!propertyManager.id && !!entityData,
  //   deps: [entityData, propertyManager],
  // } );

  useEffect( () => {
    if ( !_.isEmpty( data ) ) {
      const routeListFiltered = [...propertyRoutes];
      _.map( propertyRoutes, ( item ) => {
        if (
          _.get( item, 'extraData.type' ) === 'customFields'
            && _.get( item, 'extraData.filter' )
        ) {
          // if ( !_.filter( data.customFields, item.extraData.filter ).length ) {
          //   routeListFiltered = _.filter(
          //     routeListFiltered,
          //     ( route ) => route.name !== item.name,
          //   );
          // }
        }
      } );
      if ( routeListFiltered.length !== routesList.length ) setRoutesList( routeListFiltered );
    }
  }, [data, routesList.length] );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );
  const goToListTasks = () => {
    const fromDate = moment().startOf( 'day' );
    const toDate = moment( fromDate ).clone().add( '1', 'day' );
    history.push(
      `/operations/tasks?page=1&elementsPerPage=10&from=${fromDate.format(
        'YYYY-MM-DD',
      )}&till=${toDate.format( 'YYYY-MM-DD' )}&property_value=${data.entity.id}&property_label=${data.entity.name}`,
    );
  };

  // if ( !data ) {
  //   return null;
  // }

  return (
    <PropertyEditView
      data={data}
      entityId={entityId}
      parentPath={parentPath}
      tabs={routesList}
      pathLayout={match.url}
      pathname={location.pathname}
      isLoading={dataLoading}
      onReloadData={reloadData}
      onListTasks={goToListTasks}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( mapStateToProps, mapDispatchToProps )( PropertyEdit );
