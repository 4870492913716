import React from 'react';
import { Col, Row } from 'reactstrap';
import {
  TextField,
  ModalForm,
  Intl,
  AsyncSelect,
} from 'components';
import _ from 'lodash';

import { required } from 'config/InputErrors';
import { formatFloat } from 'utils';

export default React.memo(
  ( {
    data,
    itemId,
    isLoading,
    open,
    onSubmit,
    onClose,
    onFetchServiceItems,
  } ) => (
    <ModalForm
      initialValues={data}
      title={itemId ? 'editItem' : 'newItem'}
      showErrorsInFooter
      isLoading={isLoading}
      bodyClassName="overflow-visible"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      body={( form ) => (
        <>
          <Row>
            <Col>
              <AsyncSelect
                field="item"
                label="service"
                labelClass="font-weight-bold"
                defaultOptions
                loadOptions={onFetchServiceItems}
                onChange={( { original } ) => {
                  const price = formatFloat(
                    _.get( original, 'serviceItemPrice.price', 0 ),
                  );
                  const unit = _.get( original, 'units' );
                  form.change( 'price', price );
                  form.change( 'quantity', 1 );
                  form.change( 'amount', price );
                  form.change( 'unit', unit );
                }}
              />
            </Col>
            <Col className="d-none">
              <TextField field="unit" label="unit" disabled />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <TextField
                field="price"
                label="price"
                type="number"
                disabled
                validate={required}
              />
            </Col>
            <Col md={4}>
              <TextField
                field="quantity"
                label="quantity"
                type="number"
                validate={required}
                onChange={( value ) => {
                  form.change(
                    'amount',
                    form.getFieldState( 'price' ).value * value,
                  );
                }}
              />
            </Col>
            <Col md={4}>
              <TextField
                field="amount"
                label="amount"
                type="number"
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField field="notesInternal" label="notesInternal" rows={3} />
            </Col>
          </Row>
          {data.notesCustomer && (
            <Row>
              <Col>
                <p className="m-0 mt-2">
                  <Intl id="notesCustomer" />
                </p>
                <p className="text-gray-2 m-0">{data.notesCustomer}</p>
              </Col>
            </Row>
          )}
        </>
      )}
    />
  ),
);
