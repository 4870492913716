import { useFetchTableData } from 'hooks';
import React, { useState } from 'react';
import NotificationOwnersView from 'views/NotificationOwners';
import SettingsActions from 'store/reducers/Settings';
import NotificationsPMService from 'api/NotificationsPM';

import { connect } from 'react-redux';
import _ from 'lodash';
import SendNotification from './Send';

const NotificationOwners = ( { propertyManager, toggleErrorAlert } ) => {
  const [showSendModal, setShowSendModal] = useState( false );
  const [messageToRespond, setMessageToRespond] = useState( );

  const openSendModal = () => setShowSendModal( true );
  const closeSendModal = () => setShowSendModal( false );
  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => NotificationsPMService.getNotifications( propertyManager.id, params ),
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      data: dataToFormat.data.map( ( item ) => ( {
        ...item,
        customer: item.customer || item.accommodationOwner,
      } ) ),
    } ),
    toggleErrorAlert,
  } );

  const handleRespond = ( message ) => {
    setMessageToRespond( message );
    openSendModal();
  };

  const handleNew = () => {
    setMessageToRespond();
    openSendModal();
  };

  return (
    <>
      <NotificationOwnersView
        fetchData={fetchData}
        dataTable={dataTable}
        dataLoading={dataLoading}
        onNewClick={handleNew}
        onRespond={handleRespond}
      />
      <SendNotification
        messageToRespond={messageToRespond}
        open={showSendModal}
        onClose={closeSendModal}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( NotificationOwners );
