export const priorities = [
  { id: 'blocked', name: 'blocked', iconClass: 'app-icon-warning text-red-2' },
  { id: 'high', name: 'high', iconClass: 'app-icon-chevron-up text-success' },
  {
    id: 'highest',
    name: 'highest',
    iconClass: 'app-icon-double-chevron-up text-red-2',
  },
  { id: 'normal', name: 'normal', iconClass: 'th-icon-ellipse text-success' },
  { id: 'low', name: 'low', iconClass: 'app-icon-chevron-down text-success' },
  {
    id: 'lowest',
    name: 'lowest',
    iconClass: 'app-icon-double-chevron-down text-gray-1',
  },
];

export const statuses = [
  { id: 'open', name: 'open', textClass: 'text-red-1' },
  { id: 'assigned', name: 'assigned', textClass: 'text-gray-1' },
  { id: 'in_progress', name: 'in_progress', textClass: 'text-blue-1' },
  { id: 'resolved', name: 'resolved', textClass: 'text-success' },
  { id: 'closed', name: 'closed', textClass: 'text-success' },
];

export const types = [
  { id: 'task', name: 'task' },
  { id: 'issue', name: 'issue' },
  { id: 'preventive', name: 'preventive' },
];
