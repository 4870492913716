import React, { useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PointOfInterestDashboardView from 'views/PointOfInterest/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import PointOfInterestService from 'api/PointOfInterest';
import PointOfInterestCategoryService from 'api/PointOfInterestCategory';
import { useDelete, useFetchTableData } from 'hooks';

const PointOfInterestDashboard = ( {
  defaultFilters, parentPath, toggleErrorAlert,
  toggleLoading, updateFilters, setFiltersError,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => {
      const paramsFormatted = {
        ...params,
        category: params.category ? params.category.value : null,
      };
      return PointOfInterestService.getPointsOfInterest( paramsFormatted );
    },
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'pointsOfInterest', {
        keyword: params.keyword,
        category: params.category,
        enabled: params.enabled,
      } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'pointsOfInterest' );
        initialReloadError.current = false;
      }
    },
  } );

  const fetchPoiCategories = useCallback(
    ( ) => PointOfInterestCategoryService.getCategories( {
      elementsPerPage: 500,
    } ),
    [],
  );

  const deleteEntity = useDelete( {
    promise: PointOfInterestService.deletePointOfInterest,
    callback: () => { setDataSubmitted( new Date().valueOf() ); },
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <PointOfInterestDashboardView
      data={data}
      isLoading={dataLoading}
      defaultFilters={defaultFilters}
      parentPath={parentPath}
      onFetchData={fetchData}
      onFetchPoiCategories={fetchPoiCategories}
      onDelete={deleteEntity}
    />
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.pointsOfInterest' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( PointOfInterestDashboard );
