import React, { useState } from 'react';
import moment from 'moment';
import {
  UncontrolledTooltip, Button,
} from 'reactstrap';
import {
  AsyncTable, EditCard, AddBtn, Intl, TableActionsBtns,
} from 'components';
import { canModify } from 'config/Ability';
import Statistics from 'containers/Resource/Edit/Permits/Statistics';
// import ViewShift from './view';

const PermitsView = ( {
  registers,
  settings,
  fetchData,
  dataTable,
  dataLoading,
  onNewClick,
  onPermitClick,
  onEdit,
  onDelete,
} ) => {
  const canEditDelete = canModify( 'resources' );
  const [showDetail, setShowDetail] = useState( false );
  const toggleDetail = () => setShowDetail( !showDetail );
  return (
    <>
      <EditCard cardClass="card-sec-bottom">
        <AsyncTable
          id="resourceShifts"
          data={dataTable.data}
          total={dataTable.elementsTotal}
          isLoading={dataLoading}
          rightContent={(
            <div className="d-flex align-items-center flex-wrap mt-1 mb-1">
              <Button
                color={showDetail ? 'secondaryColor' : 'primary'}
                className="mt-md-0 ml-2 mr-0"
                onClick={toggleDetail}
              >
                <i className="fas fa-file-invoice mr-1" />
                {showDetail
                  ? <Intl id="hideReport" />
                  : <Intl id="showReport" />}
              </Button>
              {canEditDelete && <AddBtn title="newPermit" onClick={onNewClick} />}
            </div>
          )}
          endContent={(
            <Statistics
              showDetail={showDetail}
              registers={registers}
              settings={settings}
            />
          )}
          disableSorting
         // defaultFilters={defaultFilters}
          onFetchData={fetchData}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <>
                  {canEditDelete ? (
                    <a
                      href="/"
                      id={`tooltip-shift-${cell.value}`}
                      onClick={( e ) => {
                        e.preventDefault();
                        onPermitClick( cell.value, cell.row.original.status );
                      }}
                    >
                      {cell.value.slice( 0, 8 )}
                    </a>
                  ) : (
                    <div>
                      {cell.value.slice( 0, 8 )}
                    </div>
                  )}
                  <UncontrolledTooltip
                    delay={0}
                    target={`tooltip-shift-${cell.value}`}
                  >
                    <Intl id="view" />
                  </UncontrolledTooltip>
                </>
              ),
            },
            {
              Header: 'from',
              accessor: 'from',
              Cell: ( { cell } ) => (
                cell.value ? moment.utc( cell.value ).format( 'ddd DD/MM/YYYY' ) : ''
              ),
            },
            {
              Header: 'till',
              accessor: 'till',
              Cell: ( { cell } ) => (
                cell.value ? moment.utc( cell.value ).format( 'ddd DD/MM/YYYY' ) : ''
              ),
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => {
                if ( cell.value === 'accepted'
                ) {
                  return <span className="shift-status  shift-status-accepted"><Intl id="shiftAccepted" /></span>;
                }

                if ( cell.value === 'in_demand' ) {
                  return <span className="shift-status  shift-status-pending"><Intl id="shiftPending" /></span>;
                }

                if ( cell.value === 'rejected' ) {
                  return <span className="shift-status  shift-status-rejected"><Intl id="shiftRejected" /></span>;
                }

                return null;
              },
            },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => {
                  const actions = [];
                  const { status } = cell.row.original;

                  actions.push( {
                    label: 'edit',
                    onClick: () => onEdit( cell.value, status ),
                    'data-cy': 'table-edit-btn',
                  } );

                  actions.push( {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  } );

                  return (
                    <TableActionsBtns actions={actions} />
                  );
                },
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </EditCard>
    </>
  );
};

export default PermitsView;
