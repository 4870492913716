import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import EditDrawerView from 'views/Property/RoomType/Accommodation/Edit/Rates/EditDrawer';

const EditDrawer = ( {
  rateId, open, onClose, onReloadRates, onOpenModal,
} ) => (
  <EditDrawerView
    data={{ id: rateId }}
    open={open}
    onOpenModal={onOpenModal}
    onClose={onClose}
    onReloadRates={onReloadRates}
  />
);

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( EditDrawer );
