import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router';

import { SimpleHeader, HorizontalMenu } from 'components';
import { customerRoutes } from 'config/Routes';
import _filter from 'lodash/filter';
import classnames from 'classnames';

export default React.memo( ( {
  data, isLoading, pathLayout, parentPath, pathname, entityId, onReload,
} ) => {
  const getRoutes = ( routesList ) => routesList.map( ( route ) => {
    if ( route.component ) {
      return (
        <Route
          exact={route.exact}
          path={pathLayout + route.path}
          render={( props ) => (
            <route.component
              {...props}
              data={data}
              isLoading={isLoading}
              pathLayout={pathLayout}
              entityId={entityId}
              onReload={onReload}
            />
          )}
          key={route.layout + route.path}
        />
      );
    }
    return null;
  } );

  const filterRoutes = ( routesList, params ) => _filter(
    routesList,
    ( item ) => item.filter( params ),
  );

  return (
    <>
      <SimpleHeader
        parentLinks={[{ name: 'customers', href: parentPath }]}
      />
      <Container fluid className={classnames( { 'app-component-loading': isLoading } )}>
        <Row style={{ marginBottom: '10px' }}>
          <Col>
            <HorizontalMenu
              tabs={filterRoutes( customerRoutes, data )}
              pathLayout={pathLayout}
              pathname={pathname}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Switch>
              {getRoutes( customerRoutes )}
              <Redirect from="*" to={pathLayout} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </>
  );
} );
