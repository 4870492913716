import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';

import {
  TextField,
  Form,
  SimpleHeader,
  EditCard,
  Datepicker,
  CanAccess,
  FormActionsBtns,
  Intl,
  AsyncSelect,
} from 'components';
import { required } from 'config/InputErrors';
import DashboardItems from '../Item/Dashboard';

export default React.memo( ( {
  data,
  isLoading,
  bookedServiceItems,
  onChangeService,
  bookingId,
  serviceId,
  onSubmit,
  onAddNewItem,
  onEditItem,
  onDeleteItem,
  parentPath,
  price,
  userCurrency,
  onFetchServices,
} ) => (
  <>
    <SimpleHeader
      parentLinks={[
        { name: 'booking', href: `${parentPath}/${bookingId}/services` },
      ]}
    />
    <Container
      fluid
      className={classnames( { 'app-component-loading': isLoading } )}
    >
      <Form
        initialValues={data}
        title="newService"
        showErrorsInFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        validate={() => {
          const errors = {};
          if ( !bookedServiceItems.length ) {
            errors.bookedServiceItemsIsEmpty = true;
          }
          return errors;
        }}
        body={( form ) => (
          <>
            <EditCard title="newService">
              <Row>
                <Col md={4}>
                  <AsyncSelect
                    field="service"
                    label="service"
                    defaultOptions
                    loadOptions={onFetchServices}
                    onChange={( { value } ) => {
                      onChangeService( value );
                    }}
                  />
                </Col>
                <Col md={4}>
                  <TextField
                    label="price"
                    type="number"
                    disabled
                    validate={required}
                    value={price}
                  />
                </Col>
                <Col md={4}>
                  <Datepicker
                    field="date"
                    label="date"
                    validate={required}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    formGroupClass="m-0"
                    utc
                    startDateField={{ value: moment().subtract( '1', 'day' ) }}
                  />
                </Col>
              </Row>
            </EditCard>
            <DashboardItems
              data={bookedServiceItems}
              onAddNewItem={onAddNewItem}
              serviceId={serviceId}
              onEditItem={onEditItem}
              onDeleteItem={onDeleteItem}
              userCurrency={userCurrency}
            />
            {form.getState().submitFailed
              && form.getState().errors
              && form.getState().errors.bookedServiceItemsIsEmpty && (
                <Row>
                  <Col>
                    <div className="invalid-feedback d-block text-center">
                      <Intl id="errorBookedServiceItemsIsEmpty" />
                    </div>
                  </Col>
                </Row>
            )}
          </>
        )}
        footer={(
          <CanAccess I="edit" a="service">
            <Row>
              <Col>
                <FormActionsBtns
                  cancelTo={`${parentPath}/${bookingId}/services`}
                />
              </Col>
            </Row>
          </CanAccess>
        )}
      />
    </Container>
  </>
) );
