import React from 'react';
import moment from 'moment';

import { Intl, AsyncTable } from 'components';

export default React.memo( ( { data, onFetchData, isLoading } ) => (

  <AsyncTable
    titleContent={(
      <h3 className="m-0 pb-2">
        <Intl id="accessControl" />
      </h3>
)}
    containerClassName="card-sec-bottom"
    cardHeaderClassName="border-0 py-2"
    onFetchData={onFetchData}
    data={data.data}
    total={data.elementsTotal}
    isLoading={isLoading}
    columns={[
      {
        Header: 'date',
        accessor: 'created',
        Cell: ( { cell } ) => (
          cell.value
            ? (
              moment( cell.value ).format( 'YYYY-MM-DD HH:mm' )
            ) : ''
        ),
      },
      {
        Header: 'action',
        accessor: 'action',
      },
      {
        Header: 'response',
        accessor: 'response',
      },
      {
        Header: 'user',
        accessor: 'user',
        Cell: ( { cell } ) => (
          cell.value && cell.value.firstName
            ? (
              `${cell.value.firstName} ${cell.value.lastName}`
            ) : ''
        ),
      },
    ]}
  />
) );
