import React from 'react';

import { TextField, ModalForm, TranslationLayout } from 'components';
import { required } from 'config/InputErrors';
import { Col, Row } from 'reactstrap';

export default React.memo( ( {
  isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    title="newChecklist"
    showErrorsInFooter
    isLoading={isLoading}
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <TranslationLayout
      translationFields={( locale ) => (
        <>
          <Row>
            <Col md={6}>
              <TextField
                field={`translations.${locale}.name`}
                label="name"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                field={`translations.${locale}.description`}
                label="description"
                type="textarea"
                rows={4}
              />
            </Col>
          </Row>
        </>
      )}
    >
      <Row>
        <Col md={6}>
          <TextField
            field="name"
            label="name"
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            field="description"
            label="description"
            type="textarea"
            rows={4}
            validate={required}
          />
        </Col>
      </Row>
    </TranslationLayout>
  </ModalForm>
) );
