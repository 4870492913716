import React, { useEffect, useState } from 'react';
import StatisticsView from 'views/Resource/Edit/Permits/Statistics';

const Statistics = ( { settings = [], registers = [], showDetail } ) => {
  const [statistics, setStatistics] = useState( {
    totalUsed: 0,
    totalLeft: 0,
    total: 0,
    perCategory: [],
  } );
  useEffect( () => {
    const total = settings.reduce( ( acc, curr ) => (
      curr.daysPerYear > 0 ? acc + curr.daysPerYear : acc ), 0 );
    const totalUsed = registers.reduce( ( acc, curr ) => (
      curr.daysPerYear > 0 ? acc + curr.daysConsumed : acc ), 0 );
    const totalLeft = total - totalUsed;
    const perCategory = settings.map( ( item ) => {
      const registerFound = registers.find(
        ( reg ) => reg.employeePermitsSettings.id === item.id,
      );
      const consumed = registerFound ? registerFound.daysConsumed : 0;
      const max = item.daysPerYear > 0 ? item.daysPerYear : 'N/A';
      return {
        id: item.id,
        name: item.name,
        consumed,
        left: max > 0 ? max - consumed : 'N/A',
        max,
      };
    } );
    setStatistics( {
      total, totalLeft, totalUsed, perCategory,
    } );
  }, [settings, registers] );

  return (
    <StatisticsView showDetail={showDetail} statistics={statistics} />
  );
};

export default Statistics;
