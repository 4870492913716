import api from 'config/Api/Api';

const baseEndpoint = '/accommodation-owners';

export default {
  getAccomodationOwners( params ) {
    return api.get( baseEndpoint, params );
  },
  getAccomodationOwner( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  saveAccomodationOwner( data ) {
    return api.post( baseEndpoint, data );
  },
  updateAccomodationOwner( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  deleteAccomodationOwner( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
  //
  addOwnerUser( id, customerId, data ) {
    return api.put( `${baseEndpoint}/${id}/add-owners/${customerId}`, data );
  },
  deleteOwnerUser( id, customerId ) {
    return api.delete( `${baseEndpoint}/${id}/delete-owners/${customerId}` );
  },
  //
  addAccomodation( id, accomodationId, data ) {
    return api.put(
      `${baseEndpoint}/${id}/add-accomodation/${accomodationId}`,
      data,
    );
  },
  deleteAccomodation( id, accomodationId ) {
    return api.delete(
      `${baseEndpoint}/${id}/delete-accomodation/${accomodationId}`,
    );
  },
  getTasksOwner( id, params ) {
    return api.get( `${baseEndpoint}/${id}/tasks`, params );
  },
  downloadTasksOwner( id, params ) {
    return api.get( `${baseEndpoint}/${id}/tasks`, { ...params, download: true } );
  },
  getBookingsOwner( id, params ) {
    return api.get( `${baseEndpoint}/${id}/bookings`, params );
  },
  getMonthlyReports( id, params ) {
    return api.get( `${baseEndpoint}/${id}/report`, params );
  },
  sendMonthlyReport( id, reportId, params ) {
    return api.get( `${baseEndpoint}/${id}/send-report/${reportId}`, params );
  },
  downloadBookingsOwner( id, params ) {
    return api.get( `${baseEndpoint}/${id}/bookings`, { ...params, download: true } );
  },
  downloadExpensesOwner( id, params ) {
    return api.get( `${baseEndpoint}/${id}/expenses`, { ...params } );
  },
  getAccommodationsOwner( id, params ) {
    return api.get( `${baseEndpoint}/${id}/accommodations`, params );
  },
};
