import React from 'react';
import { Route, Switch } from 'react-router';

import { useFetch } from 'hooks';
import RoomTypeService from 'api/RoomType';
import { canModify, canView, canList } from 'config/Ability';
import Dashboard from './Dashboard';
import Edit from './Edit';
import Contract from './Contract';

const Accommodation = ( {
  propertyData, paths, parentIds, match,
} ) => {
  const [roomTypeData] = useFetch( {
    initialState: {},
    promise: () => RoomTypeService.getRoomType( parentIds.property, match.params.id ),
    conditional: !!match.params.id,
    deps: [match.params.id],
  } );

  return (
    <Switch>
      {canModify( 'accommodation' )
      && (
        <Route
          path={`${match.url}/new`}
          render={( props ) => (
            <Edit
              {...props}
              roomTypeData={roomTypeData}
              propertyData={propertyData}
              parentIds={{ ...parentIds, roomType: match.params.id }}
              paths={{ ...paths, accommodation: match.url }}
            />
          )}
        />
      )}
      {canView( 'accommodation' )
      && (
        <Route
          path={`${match.url}/edit/:id`}
          render={( props ) => (
            <Edit
              {...props}
              roomTypeData={roomTypeData}
              propertyData={propertyData}
              parentIds={{ ...parentIds, roomType: match.params.id }}
              paths={{ ...paths, accommodation: match.url }}
            />
          )}
        />
      )}
      {canList( 'accommodation' )
      && (
        <Route
          path={`${match.url}/:id/contracts`}
          render={( props ) => (
            <Contract
              {...props}
              roomTypeData={roomTypeData}
              propertyData={propertyData}
              parentIds={{ ...parentIds, roomType: match.params.id }}
              paths={{ ...paths, accommodation: match.url }}
            />
          )}
        />
      )}
      {canList( 'accommodation' )
      && (
        <Route
          path={match.url}
          render={( props ) => (
            <Dashboard
              {...props}
              roomTypeData={roomTypeData}
              propertyData={propertyData}
              parentIds={{ ...parentIds, roomType: match.params.id }}
              paths={{ ...paths, accommodation: match.url }}
            />
          )}
        />
      )}
    </Switch>
  );
};

export default Accommodation;
