import api from 'config/Api/Api';

const baseEndpoint = ( teamId ) => `/teams/${teamId}/shift-templates`;

export default {
  getTemplates( teamId, params ) {
    return api.get( baseEndpoint( teamId ), params );
  },
  saveTemplate( teamId, data ) {
    return api.post( baseEndpoint( teamId ), data );
  },
  updateTemplate( teamId, id, data ) {
    return api.put( `${baseEndpoint( teamId )}/${id}`, data );
  },
  deleteTemplate( teamId, id, data ) {
    return api.delete( `${baseEndpoint( teamId )}/${id}`, data );
  },
};
