import React from 'react';
import _ from 'lodash';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

import { formatPhone } from 'utils';
import {
  Intl, DetailCard, BookingGuestList, Switch, Form, Datepicker, Select, Button,
} from 'components';
import { canModify } from 'config/Ability';
import formatBookinglId from 'utils/bookingId';

export default React.memo( ( {
  data, onReloadData, onSubmit, userBookingReference, handleOpenModalGuest, onEditGuest,
  isAvantioActive, onClickSync,
} ) => {
  const country = _.get( data, 'currentAccommodation.roomType.property.propertyManagerCityZone.propertyManagerCity.city.state.country.name' );
  const city = _.get( data, 'currentAccommodation.roomType.property.propertyManagerCityZone.propertyManagerCity.city.name' );
  // TODO: actualizar ACLs
  const onSubmitFn = canModify( 'booking' ) ? onSubmit : () => {};

  return (
    <>
      <DetailCard
        classNames={{
          header: 'py-4',
          body: 'p-0',
          card: 'card-sec-bottom',
        }}
        header={(
          <>
            <h3 className="m-0">
              <div className="d-flex justify-content-between">
                <div>
                  <Intl id="booking" />
                  {' '}
                  <span className="small text-bold">
                    {formatBookinglId( { ...data, userBookingReference } )}
                  </span>
                </div>
                {
                  isAvantioActive
                && (
                  <Button title="syncWithAvantio" onClick={onClickSync} />
                )
                }
              </div>
              {data && data.not_real_email && (
              <span>
                <i
                  className="fas fa-exclamation-circle text-warning m-2"
                  id={`tooltip-reported-${data.id || 'booking-id'}`}
                />
                <UncontrolledTooltip
                  delay={0}
                  target={`tooltip-reported-${data.id || 'booking-id'}`}
                >
                  <Intl id="notRealEmail" />
                </UncontrolledTooltip>
              </span>
              )}
            </h3>
            {data.checkinComplete === 'true' && <span className="text-sm text-primary"><Intl id="checkedIn" /></span>}
          </>
      )}
      >
        <Form
          initialValues={data}
          onSubmit={onSubmitFn}
          body={( form ) => (
            <>
              <Row className="m-0 align-items-stretch b-bottom">
                <Col md={2} className="px-4 py-3 b-right">
                  <h4><Intl id="accommodation" /></h4>
                  <div>{_.get( data, 'currentAccommodation.name' )}</div>
                  <div>
                    {data && data.id ? <i className="app-icon-map-pin mr-1" /> : ''}
                    {_.get( data, 'currentAccommodation.roomType.property.address' )}
                    {city ? ', ' : ' '}
                    {city}
                    {city && country ? ' - ' : ''}
                    {country}
                  </div>
                </Col>
                <Col md={2} className="px-4 py-3 b-right">
                  <h4><Intl id="customer" /></h4>
                  <div>
                    {_.get( data, 'owner.firstName' )}
                    {' '}
                    {_.get( data, 'owner.lastName' )}
                  </div>
                  {data.owner && data.owner.uniqueCode && (
                  <div>
                    {`(${data.owner.uniqueCode})`}
                  </div>
                  )}
                  <div>{formatPhone( _.get( data, 'owner.phoneNumber' ) )}</div>
                  <div>{formatPhone( _.get( data, 'owner.email' ) )}</div>
                </Col>
                <Col md={3} className="px-4 py-3 b-right">
                  <Row>
                    <Col md={6}>
                      <h4><Intl id="guests" /></h4>
                      <div>{data.capacity || '-'}</div>
                    </Col>
                    <Col md={6}>
                      <h4><Intl id="nights" /></h4>
                      <div>{_.get( data, 'lapse', [] ).length || ''}</div>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="px-4 py-3">
                  <Row>
                    <Col md={6}>
                      <h4>
                        <i className="th-icon-in text-success mr-2" />
                        <Intl id="checkIn" />
                      </h4>
                      <div>
                        {data.from ? `${moment.utc( data.from ).format( 'DD/MMM/YY' )}, ` : ''}
                        <div style={{ display: 'inline-block', maxWidth: 60 }}>
                          <Datepicker
                            field="checkInTime"
                            dateFormat={false}
                            timeFormat="HH:mm"
                            size="xsm"
                            onBlur={() => onSubmitFn( form.getState().values, form )}
                          />
                        </div>
                        {' hs.'}
                      </div>
                    </Col>
                    <Col md={6}>
                      <h4>
                        <i className="th-icon-out text-danger mr-2" />
                        <Intl id="checkOut" />
                      </h4>
                      <div>
                        {data.till ? `${moment.utc( data.till ).format( 'DD/MMM/YY' )}, ` : ''}
                        <div style={{ display: 'inline-block', maxWidth: 60 }}>
                          <Datepicker
                            field="checkOutTime"
                            dateFormat={false}
                            timeFormat="HH:mm"
                            size="xsm"
                            onBlur={() => onSubmitFn( form.getState().values, form )}
                          />
                        </div>
                        {' hs.'}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="p-4">
                <Row>
                  <Col md={2}>
                    <Switch
                      formGroupClass="text-center"
                      field="withIssues"
                      label="withIssues"
                      labelBottom
                      onChange={() => onSubmitFn( form.getState().values, form )}
                    />
                  </Col>
                  <Col md={3}>
                    <Switch
                      formGroupClass="text-center"
                      field="allowCheckinWithoutPaymentComplete"
                      label="allowCheckinWithoutPaymentComplete"
                      labelBottom
                      onChange={() => onSubmitFn( form.getState().values, form )}
                    />
                  </Col>
                  <Col md={2}>
                    <Switch
                      formGroupClass="text-center"
                      field="notPaymentNeeded"
                      label="notPaymentNeeded"
                      labelBottom
                      onChange={() => onSubmitFn( form.getState().values, form )}
                    />
                  </Col>
                  <Col md={2}>
                    <Switch
                      formGroupClass="text-center"
                      field="checkoutCompleteChecked"
                      label="checkoutComplete"
                      labelBottom
                      onChange={() => onSubmitFn( form.getState().values, form )}
                    />
                  </Col>
                  <Col md={3}>
                    <Select
                      field="status"
                      label="status"
                      translateOptions
                      options={[
                        {
                          name: 'not_paid',
                          id: 'not_paid',
                        },
                        {
                          name: 'half_paid',
                          id: 'half_paid',
                        },
                        {
                          name: 'full_paid',
                          id: 'full_paid',
                        }, {
                          name: 'over_paid',
                          id: 'over_paid',
                        },
                        {
                          name: 'cancelled',
                          id: 'cancelled',
                        },
                        {
                          name: 'conflicted',
                          id: 'conflicted',
                        },
                      ]}
                      onChange={() => onSubmitFn( form.getState().values, form )}
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
        />
      </DetailCard>

      <BookingGuestList
        data={data}
        onReloadData={onReloadData}
        handleOpen={handleOpenModalGuest}
        onEdit={onEditGuest}
      />
    </>
  );
} );
