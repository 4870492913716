import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { FormGroup, Input, InputGroup } from 'reactstrap';

import { Intl } from 'components';
import InputGroupAddon from '../InputGroupAddon';

const TextFieldInput = React.memo(
  ( {
    label,
    value,
    inputOnChange,
    inputName,
    size = 'md',
    onChange,
    prependIconClass,
    prependIcon,
    appendIconClass,
    appendIcon,
    formGroupClass,
    intl,
    translateValues,
    isInvalid,
    focused,
    meta,
    placeholder,
    translate = true,
    labelClass,
    inputClass,
    ...rest
  } ) => (
    <FormGroup
      className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}
    >
      {label && (
        <label className={classnames( labelClass, 'form-control-label' )}>
          {translate ? <Intl id={label} /> : label}
        </label>
      )}

      <div>
        <InputGroup className={classnames( 'input-group-merge', { focused } )}>
          {prependIcon || prependIconClass ? (
            <InputGroupAddon
              addonType="prepend"
              isInvalid={isInvalid}
              icon={prependIcon}
              iconClass={prependIconClass}
            />
          ) : null}

          <Input
            {...rest}
            placeholder={
              placeholder ? intl.formatMessage( { id: placeholder } ) : null
            }
            className={classnames( inputClass, {
              'is-invalid': isInvalid,
              'form-control-lg': size === 'lg',
              'form-control-sm': size === 'sm',
              'form-control-xsm': size === 'xsm',
            } )}
            value={value || ''}
            onChange={( e ) => {
              let val = e.target.value;
              if ( rest && rest.type === 'number' ) {
                val = Number( val );
              }
              if ( inputOnChange ) inputOnChange( val );
              if ( onChange ) onChange( val, e );
            }}
            data-cy={`input-${inputName}`}
          />

          {appendIcon || appendIconClass ? (
            <InputGroupAddon
              addonType="append"
              isInvalid={isInvalid}
              icon={appendIcon}
              iconClass={appendIconClass}
            />
          ) : null}
        </InputGroup>

        {isInvalid && (
          <div className="invalid-feedback">
            <Intl
              id={meta.error || meta.submitError}
              values={translateValues}
            />
          </div>
        )}
      </div>
    </FormGroup>
  ),
);

export default injectIntl( TextFieldInput );
