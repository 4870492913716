import React from 'react';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import numeral from 'numeral';

import { currencyFormat } from 'utils';
import { bookedServiceItemStatus } from 'config/Constants';

import {
  DetailCard,
  LocalTable,
  AddBtn,
  Intl,
  TableEditBtn,
  TableActionsBtns,
} from 'components';

export default ( {
  data,
  userCurrency,
  onAddNewItem,
  serviceId,
  onEditItem,
  onDeleteItem,
} ) => (
  <DetailCard
    classNames={{ header: 'py-2', body: 'pt-4', card: 'mt-4' }}
    className="mt-4"
    header={(
      <Row className="align-items-center">
        <Col md={9}>
          <span className="text-uppercase text-gray-1">
            <Intl id="items" />
          </span>
        </Col>
        <Col md={3} className="text-right">
          {serviceId && <AddBtn title="newItem" onClick={onAddNewItem} />}
        </Col>
      </Row>
    )}
  >
    <LocalTable
      onlyTable
      manualPagination
      data={data}
      columns={[
        {
          Header: 'name',
          accessor: 'item.name',
          Cell: ( { cell } ) => (
            <TableEditBtn
              title={cell.value}
              onClick={() => onEditItem( cell.row.original )}
            />
          ),
        },
        {
          Header: 'price',
          accessor: 'price',
          Cell: ( { cell } ) => ( cell.value
            ? numeral( cell.value ).format(
              currencyFormat( userCurrency ),
            )
            : '' ),
        },
        {
          Header: 'quantity',
          accessor: 'quantity',
          Cell: ( { cell } ) => ( cell.value ? cell.value : '' ),
        },
        {
          Header: 'total',
          accessor: 'amount',
          Cell: ( { cell } ) => ( cell.value
            ? numeral( cell.value ).format(
              currencyFormat( userCurrency ),
            )
            : '' ),
        },
        {
          Header: 'notesCustomer',
          accessor: 'notesCustomer',
          Cell: ( { cell } ) => ( cell.value ? cell.value : '' ),
        },
        {
          Header: 'notesInternal',
          accessor: 'notesInternal',
          Cell: ( { cell } ) => ( cell.value ? cell.value : '' ),
        },
        {
          Header: 'status',
          accessor: 'status',
          Cell: ( { cell } ) => {
            const status = _.find( bookedServiceItemStatus, {
              id: cell.value,
            } );
            return status ? (
              <span className={status.textClass}>
                <Intl id={status.name} />
              </span>
            ) : null;
          },
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  isDelete: true,
                  onClick: ( cb ) => onDeleteItem( cell.value || cell.row.original.index, cb ),
                },
              ]}
            />
          ),
        },
      ]}
    />
  </DetailCard>
);
