export const aclCategories = [
  {
    title: 'operations',
    acls: ['task', 'task_type', 'access_control', 'geolocation'],
  },
  {
    title: 'bookings',
    acls: ['booking', 'services', 'customer', 'payment'],
  },
  {
    title: 'pms',
    acls: ['accommodation', 'property', 'property_owner', 'room_type'],
  },
  {
    title: 'content',
    acls: ['cms', 'event'],
  },
  {
    title: 'resources',
    acls: ['shift', 'team', 'resources'],
  },
  {
    title: 'communications',
    acls: ['communications', 'chat'],
  },
  {
    title: 'support',
    acls: [
      'amenity',
      'settings',
      'settings_autogenerated_task',
      'settings_booking_channel_roles',
      'settings_city',
      'settings_currency',
      'settings_custom_fields',
      'settings_plugins',
      'settings_task_auto_assignation',
    ],
  },
];

export default {
  nomadsAdmin: {
    id: 'ROLE_NOMADS_ADMIN',
    name: 'Nomads Admin',
  },
  nomadsAdminReadOnly: {
    id: 'ROLE_NOMADS_READ_ONLY_ADMIN',
    name: 'Nomads Admin Read Only',
  },
  admin: {
    id: 'ROLE_ADMIN',
    name: 'Admin',
  },
  adminReadOnly: {
    id: 'ROLE_READ_ONLY_ADMIN',
    name: 'Support',
  },
  owner: {
    id: 'ROLE_OWNER',
    name: 'Owner',
  },
};
