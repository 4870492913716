import React from 'react';
import classnames from 'classnames';
import {
  Row, Col,
} from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';

export default React.memo( ( {
  message, previousMessage, currentUserId, isSameDay, onToggleImageModal,
} ) => {
  const userId = currentUserId;
  const showUserNameImg = message.owner.id !== userId && (
    ( message.owner.id !== _.get( previousMessage, 'owner.id' ) ) || !isSameDay()
  );
  const showCurrentUserImg = message.owner.id === userId && (
    ( message.owner.id !== _.get( previousMessage, 'owner.id' ) ) || !isSameDay()
  );

  const getInitials = () => {
    if ( message && message.owner && message.owner.firstName ) {
      let text = message.owner.firstName[0];
      if ( message.owner.lastName ) text += message.owner.lastName[0];
      return text;
    }
    return '';
  };

  return (
    <div
      className={classnames( {
        'chat-messages-message-right': message.owner.id === userId,
        'chat-messages-message-left': message.owner.id !== userId,
        'chat-messages-message-left-margin': message.owner.id !== userId && !showUserNameImg,
        'chat-messages-message-right-margin': message.owner.id === userId && !showCurrentUserImg,
      } )}
    >
      <div className="chat-messages-message-container">
        <Row noGutters className="align-items-center">
          {( showUserNameImg && message.type === 'REGULAR' )
          && (
            <Col sm="auto" className="pr-2">
              <div className="avatar rounded-circle">
                {message.image
                  ? (
                    <img
                      alt="..."
                      src={message.image}
                    />
                  ) : getInitials()}
              </div>
            </Col>
          )}

          {( showCurrentUserImg && message.type === 'REGULAR' )
          && (
            <Col sm="auto" className="order-0 order-sm-1 pl-0 pl-sm-2 pb-2 pb-sm-0">
              <div className="avatar rounded-circle">
                {message.image
                  ? (
                    <img
                      alt="..."
                      src={message.image}
                    />
                  ) : getInitials()}
              </div>
            </Col>
          )}

          <Col className="order-1 order-sm-0">
            {( showUserNameImg && message.type === 'REGULAR' )
              ? (
                <Row noGutters>
                  <Col className="p-0 chat-messages-message-username">
                    <span>
                      {message.owner.firstName}
                      {' '}
                      {message.owner.lastName}
                    </span>
                  </Col>
                </Row>
              ) : null}
            <div
              key={message.id}
              className={classnames( 'chat-messages-message-content', { row: !!message.body, bot: message.type === 'BOT' } )}
            >
              {( !!message.resource || !!message.fileDecoded )
              && (
                <Col xs={12} className="chat-messages-message-image">
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    alt="..."
                    src={message.resource || message.fileDecoded}
                    onClick={() => onToggleImageModal( message.resource || message.fileDecoded )}
                  />
                </Col>
              )}
              {!!message.body
              && (
                <Col xs={12} className="chat-messages-message-text">
                  <span>{message.body}</span>
                </Col>
              )}
              <Col xs={12} className="chat-messages-message-time">
                <span>{moment( message.created ).format( 'h:mm A' )}</span>
                {message.status === 'ERROR' && <i className="fa fa-exclamation-circle text-danger ml-1" />}
                {/*
                {message.status === 'SENT' && <i className="fa fa-check text-success ml-1" />}
                 */}
              </Col>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
} );
