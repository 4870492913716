import React from 'react';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';
import { canModify } from 'config/Ability';
import {
  LocalTable, TableEditBtn, TableActionsBtns, AddBtn,
} from 'components';

export default React.memo( ( {
  data, isLoading, onOpenEditModal, onDelete,
} ) => (
  <div className={classnames( { 'app-component-loading': isLoading } )}>
    {canModify( 'services' ) ? (
      <div className="py-2 px-4">
        <Row>
          <Col className="text-right">
            <AddBtn title="newCategory" onClick={onOpenEditModal} />
          </Col>
        </Row>
      </div>
    ) : null}

    <LocalTable
      onlyTable
      manualPagination
      data={data.data}
      columns={[
        {
          Header: 'name',
          accessor: 'name',
          Cell: ( { cell } ) => (
            <TableEditBtn
              title={cell.value}
              onClick={() => onOpenEditModal( cell.row.original )}
            />
          ),
        },
        {
          Header: 'description',
          accessor: 'description',
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ]}
            />
          ),
        },
      ]}
    />
  </div>
) );
