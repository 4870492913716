/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import {
  TextField, Select, ModalForm, Switch, Intl, Dropzone,
} from 'components';
import { required } from 'config/InputErrors';

const belongToOptions = [
  {
    id: 'propertyManager',
    name: <Intl id="propertyManager" />,
  },
  {
    id: 'owner',
    name: <Intl id="owner" />,
  },
];

export default React.memo(
  ( {
    accommodationId,
    amenityGeneratedId,
    data,
    isLoading,
    open,
    toggleEditModal,
    onSubmit,
    options,
    setAmenitiesKeyword,
  } ) => {
    const [cost, setCost] = useState( data.cost || 0 );

    return (
      <ModalForm
        initialValues={data}
        title={amenityGeneratedId ? 'editInventory' : 'newInventory'}
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        onSubmit={( d ) => {
          const dataTosend = {
            ...d,
            activeClient: `${d?.activeClient || false}`,
            activeEmployee: `${d?.activeEmployee || false}`,
            quantity: `${d?.quantity || 1}`,
            cost,
            accommodation: accommodationId,
          };
          onSubmit( dataTosend );
        }}
        onClose={() => {
          toggleEditModal( null );
        }}
        bodyClassName="overflow-visible"
        body={() => (
          <>
            {amenityGeneratedId ? null : (
              <Row className="mb-3">
                <Col md={6}>
                  <Select
                    field="amenity"
                    label="selectInventory"
                    options={options.amenities}
                    validate={required}
                    onInputChange={setAmenitiesKeyword}
                    noFilterByInput
                    onChange={( v ) => {
                      const newCost =
                        options.amenities.filter(
                          ( amenity ) =>
                            // eslint-disable-next-line implicit-arrow-linebreak
                            amenity.id === v,
                        )[0]?.cost || 0;
                      setCost( newCost );
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row className="mb-3">
              <Col md={4}>
                <Select
                  field="belongTo"
                  label="belongTo"
                  options={belongToOptions}
                  validate={required}
                />
              </Col>
              <Col md={4}>
                <TextField field="section" label="section" />
              </Col>
              <Col md={4}>
                <TextField
                  value={cost}
                  field="cost"
                  label="cost"
                  type="number"
                  onChange={( v ) => {
                    setCost( v );
                  }}
                />
              </Col>
              <Col md={4}>
                <TextField
                  value={data.quantity || 1}
                  field="quantity"
                  label="quantity"
                  type="number"
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Row className="mb-3">
                  <Col md={4}>
                    <Switch field="activeClient" label="activeClient" />
                  </Col>
                  <Col md={4}>
                    <Switch field="activeEmployee" label="activeEmployee" />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <TextField field="notes" label="notes" type="textarea" />
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Dropzone
                  disableDelete
                  field="image"
                  formGroupClass="dz-profile-img"
                  label="image"
                  placeholder={(
                    <>
                      <i className="fa fa-file fa-4x mb-4" />
                      <p>
                        <Intl
                          id="upload.maxMesureSizeAndHeight"
                          values={{ size: '100Kb', height: '90px' }}
                        />
                      </p>
                    </>
                )}
                />
              </Col>
            </Row>

          </>
        )}
      />
    );
  },
);
