import React, { useState } from 'react';
import moment from 'moment';
import {
  Row, Col, Button,
} from 'reactstrap';
import {
  AsyncTable, EditCard, DaterangePickerBtn, Intl, ResourceAvatar,
} from 'components';
import { getSlotSchedule } from 'utils';
import _ from 'lodash';
import StatisticsView from './statistics';

const ShiftView = ( {
  defaultFilters,
  fetchData,
  dataTable,
  dataLoading,
  statistics,
} ) => {
  const [showDetail, setShowDetail] = useState( false );
  const toggleDetail = () => setShowDetail( !showDetail );

  return (
    <>
      <EditCard cardClass="card-sec-bottom">
        <AsyncTable
          id="programmedShifts"
          data={dataTable.data}
          total={dataTable.elementsTotal}
          isLoading={dataLoading}
          rightContent={(
            <div className="d-flex align-items-center flex-wrap mt-1 mb-1">
              <Button
                color={showDetail ? 'secondaryColor' : 'primary'}
                className="mt-md-0 ml-2 mr-0"
                onClick={toggleDetail}
              >
                <i className="fas fa-file-invoice mr-1" />
                {showDetail
                  ? <Intl id="hideReport" />
                  : <Intl id="showReport" />}
              </Button>
            </div>
          )}
          endContent={(
            <StatisticsView
              statistics={statistics}
              showDetail={showDetail}
            />
          )}
          disableSorting
          defaultFilters={defaultFilters}
          onFetchData={fetchData}
          customFilters={( { filters, setMultipleFilters } ) => (
            <>
              <Row>
                <Col md={8} lg={4}>
                  <DaterangePickerBtn
                    position="left"
                    initialValue={moment.range( filters.from || moment(), filters.till || moment().add( 30, 'days' ) )}
                    key={filters.from + filters.till}
                    input
                    onChange={( values ) => {
                      setMultipleFilters( {
                        from: moment( values.start ).format( 'YYYY-MM-DD' ),
                        till: moment( values.end ).format( 'YYYY-MM-DD' ),
                      } );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <div>
                  {cell.value.slice( 0, 8 )}
                </div>

              ),
            },
            {
              Header: 'day',
              accessor: 'date',
              Cell: ( { cell } ) => (
                cell.value ? moment.utc( cell.value ).format( 'ddd DD/MM/YYYY' ) : ''
              ),
            },
            {
              Header: 'shift',
              accessor: 'shiftTemplate',
              Cell: ( { cell } ) => {
                if ( _.get( cell, 'row.original.team.shiftTemplates', [] ).length
                    && cell.value ) {
                  const shift = _.find( cell.row.original.team.shiftTemplates,
                    { _id: cell.value } );
                  if ( shift ) return shift.name;
                }
                return '';
              },
            },
            {
              Header: 'schedule',
              accessor: 'from',
              Cell: ( { cell } ) => getSlotSchedule( cell.row.original ),
            },
            {
              Header: 'assigned',
              accessor: 'assigned',
              Cell: ( { cell } ) => (
                <ResourceAvatar data={cell.value} />
              ),
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => {
                if ( !cell.row.original.assigned ) {
                  return <span className="shift-status  shift-status-not-assigned"><Intl id="shiftPendingAssignment" /></span>;
                }

                if ( cell.value === 'accepted'
                ) {
                  return <span className="shift-status  shift-status-accepted"><Intl id="shiftAccepted" /></span>;
                }

                if ( cell.value === 'pending' ) {
                  return <span className="shift-status  shift-status-pending"><Intl id="shiftPending" /></span>;
                }

                if ( cell.value === 'rejected' ) {
                  return <span className="shift-status  shift-status-rejected"><Intl id="shiftRejected" /></span>;
                }

                return null;
              },
            },
          ]}
        />
      </EditCard>
    </>
  );
};

export default ShiftView;
