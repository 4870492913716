/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
} from 'reactstrap';
import _ from 'lodash';

import { Intl } from 'components';
import logo from 'assets/img/brand/logo-threeangular.svg';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import NotificationCenterButton from './NotificationCenterButton';

const MainNavbar = React.memo( ( {
  sidebarCollapsed, toggleSidenav, logoutUser, routes, rightRoutes, userDropdown, user,
  location, switchActiveMenu,
} ) => {
  const getInitials = () => {
    if ( _.get( user, 'user.user.firstName' ) ) {
      let text = user.user.user.firstName[0];
      if ( user.user.user.lastName ) text += user.user.user.lastName[0];
      return text;
    }
    return '';
  };
  const parentUrl = location.pathname.split( '/' );

  // Intercom
  // if ( window.location.hostname.indexOf( 'staging' ) !== -1 ) {
  window.Intercom( 'boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'cs4ygw66',
    name: user.user.user.firstName, // Full name
    email: user.user.user.email, // Email address
    user_id: user.user.user.id,
    created_at: '<?php echo strtotime($current_user->created_at) ?>', // Signup date as a Unix timestamp
  } );
  // }

  return (
    <>
      <Navbar
        className="navbar-top main-navbar navbar-expand fixed-top navbar-horizontal bg-dark-1"
        data-cy="navbar-user"
      >
        <Nav>
          <NavItem className="d-lg-none d-block">
            { !!rightRoutes
              && (
              <div
                className={classnames( 'pr-3 sidenav-toggler', {
                  active: !sidebarCollapsed,
                } )}
                onClick={toggleSidenav}
              >
                <div className="sidenav-toggler-inner ml-2">
                  <i className="sidenav-toggler-line bg-white" />
                  <i className="sidenav-toggler-line bg-white" />
                  <i className="sidenav-toggler-line bg-white" />
                </div>
              </div>
              )}
          </NavItem>
        </Nav>
        <NavLinkRRD
          className="navbar-brand"
          to={process.env.PUBLIC_URL || '/'}
          onClick={() => switchActiveMenu( null )}
        >
          <img alt="ThreeAngular" src={logo} className="logo" />
        </NavLinkRRD>
        <Nav className="align-items-center d-lg-flex d-none" navbar>
          {_.map( routes, ( route ) => (
            <NavItem key={route.path} className="text-center px-4 m-0">
              <NavLinkRRD
                to={route.layout + route.path}
                className="text-creme font-weight-500 d-block"
                onClick={() => switchActiveMenu( route.state )}
              >

                <i
                  style={{
                    position: 'relative',
                    top: 1,
                  }}
                  className={classnames( route.icon, 'text-sm', {
                    'text-primary':
                      location.pathname !== '/'
                      && ( route.layout + route.path ).indexOf(
                        _.get( parentUrl, '[1]' ),
                      ) > -1,
                  } )}
                />
                <span className={classnames( 'lh-120 text-bold d-inline pl-2', {
                  'text-primary':
                      location.pathname !== '/'
                      && ( route.layout + route.path ).indexOf(
                        _.get( parentUrl, '[1]' ),
                      ) > -1,
                } )}
                >
                  <Intl id={route.name} />
                </span>
              </NavLinkRRD>
            </NavItem>
          ) )}
          ;
          {window.location.hostname.indexOf( 'staging' ) !== -1
            ? (
              <NavItem className="pt-1 text-center px-4 m-0">
                <a
                  key="id_help"
                  className="lh-120 text-bold d-inline text-creme"
                  href="https://intercom.help/threeangular"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    style={{
                      position: 'relative',
                      top: 1,
                    }}
                    className=" text-creme th-icon-cross text-sm font-weight-500 m-2"
                  />
                  <Intl id="help" />
                </a>
              </NavItem>
            ) : ''}
        </Nav>
        <Nav className="align-items-center ml-auto pr-0 pr-4" navbar>
          {_.map( rightRoutes, ( route ) => (
            <NavItem
              key={route.path}
              className={classnames( 'd-lg-block px-3 m-0', {
                'd-none': route.path !== 'support',
              } )}
            >
              {route.forNotifications ? ( <NotificationCenterButton className="text-creme" /> ) : (
                <NavLinkRRD
                  to={route.layout + route.path}
                  className="text-creme"
                  onClick={() => switchActiveMenu( route.state )}
                >
                  <i
                    style={{
                      fontSize: 26,
                    }}
                    className={classnames( route.icon, {
                      'text-primary':
                      location.pathname !== '/'
                      && ( route.layout + route.path ).indexOf(
                        _.get( parentUrl, '[1]' ),
                      ) > -1,
                    } )}
                  />
                </NavLinkRRD>
              )}
            </NavItem>
          ) )}
          <NavItem className="nav-divisor" />
          <UncontrolledDropdown nav>
            <DropdownToggle
              className="nav-link pr-0"
              color=""
              tag="a"
              data-cy="main-header-user-icon"
            >
              <Media className="align-items-center cursor-pointer">
                <span className="avatar avatar-mi rounded-circle text-uppercase">
                  {_.get( user, 'user.user.profilePicture' ) ? (
                    <img src={user.user.user.profilePicture} alt="" />
                  ) : (
                    getInitials()
                  )}
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu
              right
              className="navbar-dropdown"
              data-cy="main-header-user-dropdown"
            >
              <div className="dropdown-title">
                <span data-cy="main-header-user-dropdown-username">
                  {_.get( user, 'user.user.username', '' )}
                </span>
              </div>

              {_.map( userDropdown, ( route, index ) => (
                <div key={route.path}>
                  {index > 0 && <DropdownItem divider />}
                  <DropdownItem
                    tag={NavLinkRRD}
                    to={route.layout + route.path}
                    data-cy="main-header-user-dropdown-link"
                  >
                    <i className={route.icon} />
                    <span>
                      <Intl id={route.name} />
                    </span>
                  </DropdownItem>
                </div>
              ) )}

              <DropdownItem divider />
              <DropdownItem
                className="cursor-pointer"
                onClick={logoutUser}
                data-cy="main-header-user-dropdown-action"
              >
                <i className="ni ni-user-run" />
                <span>
                  <Intl id="logout" />
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    </>
  );
} );

MainNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidebarCollapsed: false,
};

MainNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidebarCollapsed: PropTypes.bool,
};

const mapStateToProps = ( { user } ) => ( {
  user,
} );

const mapDispatchToProps = ( {
  logoutUser: UserActions.logoutUser,
  switchActiveMenu: SettingsActions.switchActiveMenu,
} );

export default connect( mapStateToProps, mapDispatchToProps )( MainNavbar );
