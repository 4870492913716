import { useState, useCallback } from 'react';
import AccomodationOwnerService from 'api/AccomodationOwner';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';

const useSendbutton = ( { defaultValue, clientId, id } ) => {
  const [value, setValue] = useState( defaultValue );
  const [loading, setLoading] = useState( false );

  const sendMonthlyReport = useCallback( async ( ) => {
    setLoading( true );

    const [error, response] = await handlePromise(
      AccomodationOwnerService.sendMonthlyReport( clientId, id, value ? { force: true } : null ),
    );

    setLoading( false );

    if ( !response.ok ) return SettingsActions.toggleErrorAlert( error );

    if ( response.data && response.data?.sent ) {
      setValue( true );
    }
  }, [clientId, id, value] );

  return {
    value,
    loading,
    onClick: sendMonthlyReport,
  };
};
export default useSendbutton;
