/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { ResourceAvatar, Intl } from 'components';
import classnames from 'classnames';

const ShiftSlot = ( {
  id, start, ends, teamName, onPressDelete, color, shiftStatus, hasTasks,
} ) => (
  <div
    className={classnames( 'dshift slot', { 'slot--assigned': id, [`slot--${shiftStatus}`]: shiftStatus } )}
  >
    <div className="w-100 position-relative">
      <div className="row justify-content-between align-items-center">
        <div className="col-auto">{`${start} - ${ends}`}</div>
        {hasTasks ? (
          <div className="col-auto">
            <div className="dshift-task-icon">T</div>
          </div>
        ) : null}
      </div>

      <div
        className={classnames( 'team d-flex justify-content-between' )}
        style={{ backgroundColor: color, color: 'white' }}
      >
        {teamName}
        <span className="icon-delete">
          <div
            onClick={( e ) => {
              e.stopPropagation();
              onPressDelete();
            }}
          >
            <i className="fas fa-trash" />
          </div>
        </span>
      </div>
    </div>
  </div>
);

const reduceMultiShift = ( shifts ) => {
  const shiftsObjs = shifts.reduce(
    ( acc, { teamName } ) => ( {
      ...acc,
      [teamName]: _get( acc, teamName, 0 ) + 1,
    } ),
    {},
  );

  return Object.keys( shiftsObjs ).map( ( teamName ) => ( {
    teamName,
    total: shiftsObjs[teamName],
  } ) );
};

const getMultiShiftColor = ( shifts ) => {
  const rejected = _find( shifts, { shiftStatus: 'rejected' } );
  if ( rejected ) {
    return { 'slot--rejected': true };
  }
  const pending = _find( shifts, { shiftStatus: 'pending' } );
  if ( pending ) {
    return { 'slot--pending': true };
  }
  const accepted = _find( shifts, { shiftStatus: 'accepted' } );
  if ( accepted ) {
    return { 'slot--accepted': true };
  }
  return {};
};

const MutliShifts = ( {
  len, onPress, shifts, color, hasTasks,
} ) => (
  <div className={classnames( 'dshift slot slot--assigned', getMultiShiftColor( shifts ) )} onClick={onPress}>
    <div className="w-100 position-relative">

      <div className="row justify-content-between align-items-center">
        <div className="col-auto">
          {`${len} - `}
          <Intl id="shifts" />
        </div>
        {hasTasks ? (
          <div className="col-auto">
            <div className="dshift-task-icon">T</div>
          </div>
        ) : null}
      </div>

      <div className="d-flex flex-column">
        {reduceMultiShift( shifts ).map( ( { teamName, total } ) => (
          <div
            className="team mt-1"
            key={teamName}
            style={{ backgroundColor: color, color: 'white' }}
          >
            {total}
            {' - '}
            {teamName}
          </div>
        ) )}
      </div>
    </div>
  </div>
);

const ShiftRow = ( {
  onClickCell,
  data,
  onPressDelete,
  onPressMultiShift,
  color,
} ) => {
  const renderShits = ( shifts, dateInt, assigned ) => {
    if ( shifts.length === 0 ) {
      return (
        <div
          className="h-100 d-flex align-items-center justify-content-center"
          onClick={() => onClickCell( { dateInt, assigned } )}
        >
          <i className="fas fa-plus" />
        </div>
      );
    }

    const { hasTasks } = shifts.reduce( ( obj, shift ) => {
      const objClone = { ...obj };
      if ( shift?.task.length ) {
        objClone.hasTasks = true;
      }
      return objClone;
    }, { hasTasks: false } );

    if ( shifts.length > 1 ) {
      return (
        <div className="d-flex flex-wrap align-content-start">
          <MutliShifts
            shifts={shifts}
            len={shifts.length}
            onPress={() => onPressMultiShift( shifts )}
            color={color}
            hasTasks={hasTasks}
          />
        </div>
      );
    }

    const {
      id, start, ends, teamName, shiftId, slotId,
      shiftStatus,
    } = shifts[0];

    return (
      <div
        onClick={() => onClickCell( {
          shiftId,
          assigned: data.customer,
          dateInt,
        } )}
        className="h-100 d-flex flex-wrap align-content-start "
      >
        <ShiftSlot
          id={id}
          start={start}
          ends={ends}
          teamName={teamName}
          onPressDelete={() => onPressDelete( { shiftId, slotId } )}
          color={color}
          shiftStatus={shiftStatus}
          hasTasks={hasTasks}
        />
      </div>
    );
  };

  return (
    <tr className="dc_data-row">
      <th className="dc_sidebar_cell dc_sidebar_cell_shift dc_bg-white dc-border-top d-flex large">
        <ResourceAvatar data={data.customer} avatarSize="md" />
      </th>

      {data.shifts.map( ( { dateInt, shifts } ) => (
        <td key={dateInt} className={classnames( 'dshift cell cell--pointer' )}>
          {renderShits( shifts, dateInt, data.customer )}
        </td>
      ) )}
    </tr>
  );
};

export default ShiftRow;
