import React from 'react';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import RegisterOfAuthoritiesView from 'views/Booking/Edit/RegisterOfAuthorities';
import { useFetch } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import { connect } from 'react-redux';
import RegisterOfAuthoritiesService from 'api/RegisterOfAuthoritiesService';

const RegisterOfAuthorities = ( { bookingData, toggleErrorAlert, userBookingReference } ) => {
  const bookingId = _get( bookingData, 'id' );

  const [data, dataLoading] = useFetch( {
    initialState: [],
    promise: () => RegisterOfAuthoritiesService.getRegistersOfAuthority( bookingId ),
    format: ( dataToformat ) => ( _isArray( dataToformat ) ? dataToformat : [] ),
    toggleErrorAlert,
    conditional: !!bookingId,
    deps: [bookingId],
    reInit: true,
  } );

  return (
    <RegisterOfAuthoritiesView
      data={data}
      isLoading={dataLoading}
      userBookingReference={userBookingReference}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( RegisterOfAuthorities );
