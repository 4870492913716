import React, { useEffect, useState } from 'react';
import { AsyncTable, Intl, Select } from 'components';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

const TaskChecklists = ( { data } ) => {
  const [checkListOptions, setCheckListOptions] = useState( [] );
  const [selectedChecklist, setselectedChecklist] = useState( '' );
  const [selectedChecklistItems, setSelectedChecklistItems] = useState( [] );
  useEffect( () => {
    if ( data.checkListsToTask ) {
      const _options = data.checkListsToTask.map( ( chk ) => ( {
        id: chk.checkList.id,
        name: chk.checkList.name,
      } ) );
      setselectedChecklist( _options[0].id );
      setCheckListOptions( _options );
      setSelectedChecklistItems( data.checkListsToTask[0].itemsToTask );
    }
  }, [data] );

  const handleChecklistChange = ( value ) => {
    const _checklistFound = data.checkListsToTask.find(
      ( chk ) => chk.checkList.id === value,
    );
    if ( _checklistFound ) {
      setselectedChecklist( _checklistFound.checkList.id );
      setSelectedChecklistItems( _checklistFound.itemsToTask );
    }
  };

  return (
    <>
      <Select
        label="selectChecklist"
        value={selectedChecklist}
        options={checkListOptions}
        onChange={handleChecklistChange}
      />
      <AsyncTable
        id="plugins"
        data={selectedChecklistItems}
        total={selectedChecklistItems.length}
        isLoading={false}
        onFetchData={() => {}}
        disableSorting
        useElementsPerPage={false}
        columns={[
          {
            Header: 'action',
            accessor: 'title',
            Cell: ( { cell } ) => <Intl id={cell.value} />,
          },
          {
            Header: 'status',
            accessor: 'checked',
            Cell: ( { cell } ) => <Intl id={cell.value ? 'completed' : 'pending'} />,
          },
          {
            Header: 'images',
            accessor: 'images',
            Cell: ( { cell } ) => (
              <Row className="mb-2">
                {cell.value
                  ? _.map( cell.value, ( item, index ) => (
                    <Col xs="auto" className="pr-0" key={index}>
                      <span className="avatar avatar-xxl bg-transparent">
                        <a
                          href={item.url ? item.url : item.fileDecoded}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={item.url ? item.url : item.fileDecoded}
                            alt="..."
                            className="dz-preview-img"
                          />
                        </a>
                      </span>
                    </Col>
                  ) ) : null}
              </Row>
            ),
          },
        ]}
      />
    </>
  );
};

export default TaskChecklists;
