import React from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import {
  Datepicker,
  ModalForm,
  TextField,
} from 'components';
import { required } from 'config/InputErrors';
import moment from 'moment';

export default React.memo(
  ( {
    data,
    fixDates,
    open,
    onClose,
    onSubmit,
    isLoading,
  } ) => {
    const initialData = data ? {
      priceOnlyAccommodation: _.get( data, 'priceOnlyAccommodation', 0 ),
      priceSupplementPerAdditionalPerson: _.get( data, 'priceSupplementPerAdditionalPerson', 0 ),
      startDate: moment.utc( _.get( data, 'startDate', new Date() ) ),
      lastNight: moment.utc( _.get( data, 'lastNight', new Date() ) ),
    } : undefined;

    return (
      <ModalForm
        initialValues={initialData}
        title={!_.isEmpty( data ? data.id : data ) ? 'editSeason' : 'newSeason'}
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        bodyClassName="overflow-visible"
        zIndex={9999}
        body={( ) => (
          <>
            <Row>
              <Col md={6}>
                <TextField
                  field="priceOnlyAccommodation"
                  label="priceOnlyAccommodation"
                  type="number"
                  validate={required}
                />
              </Col>
              <Col md={6}>
                <TextField
                  field="priceSupplementPerAdditionalPerson"
                  label="priceSupplementPerAdditionalPerson"
                  type="number"
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="startDate"
                  label="from"
                  dateFormat="DD/MM/YYYY"
                  disabled={fixDates}
                  utc
                  labelClass="font-weight-bold"
                  validate={required}
                  startDateField={{ value: moment().subtract( '1', 'day' ) }}
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  field="lastNight"
                  label="till"
                  dateFormat="DD/MM/YYYY"
                  disabled={fixDates}
                  utc
                  labelClass="font-weight-bold"
                  validate={required}
                  startDateField={{ value: moment().subtract( '1', 'day' ) }}
                />
              </Col>
            </Row>
          </>
        )}
      />
    );
  },
);
