import React from 'react';

import {
  Form, FormActionsBtns, Intl, Select,
} from 'components';
import { useSubmit } from 'hooks';
import KeysService from 'api/Keys';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { canModify } from 'config/Ability';
import useFetch from 'hooks/useFetch';
import formatKeys from './utils';

const OpenLockerBtn = ( {
  data = {},
  toggleLoading,
  toggleInfoAlert,
  // toggleErrorAlert,
  onOpenDoorModal,
} ) => {
  const { id, type, assigned } = data;

  const isTask = type === 'task';

  const [keys] = useFetch( {
    promise: () => ( isTask ? KeysService.getTaskKeys( id ) : KeysService.getBookingKeys( id ) ),
    format: ( dataToFormat ) => formatKeys( dataToFormat.data ),
    conditional: isTask ? !!id && !!assigned.length : !!id,
    deps: [id, type],
  } );

  const openDoorModal = async ( dataToSend ) => {
    onOpenDoorModal( { ...dataToSend, type: isTask ? 'task' : 'booking' } );
    return true;
  };

  // const submitForm = () => {};
  const submitForm = useSubmit( {
    promise: ( dataToSend ) => openDoorModal( dataToSend ),
    format: ( dataToSend ) => {
      const { keyId } = dataToSend;
      const formattedData = {
        lockerId: keyId,
      };
      return formattedData;
    },
    toggleLoading,
    toggleInfoAlert,
    deps: [data],
  } );

  if ( !keys || !keys.length ) {
    return null;
  }

  const renderScene = () => (
    <>
      <h3>
        <Intl id="smartLockers" />
      </h3>
      <Form
        initialValues={{ type }}
        onSubmit={canModify( 'accommodation' ) ? submitForm : () => {}}
        showErrorsInFooter
        body={( form ) => {
          const selectedKeyId = form.getFieldState( 'keyId' ) && form.getFieldState( 'keyId' ).value;
          const selectedKey = selectedKeyId
            ? keys.filter( ( _k ) => _k.id === selectedKeyId )[0] : {};
          return (
            <>
              <Select
                required
                field="keyId"
                className="form-control"
                placeholder="select"
                options={keys}
              />
              {selectedKey.code && selectedKeyId ? (
                <>
                  <b>
                    <Intl id="booking.codeKeypad" />
                  </b>
                  <br />
                  {selectedKey.code}
                </>
              ) : null}
            </>
          );
        }}
        footer={<FormActionsBtns savelabel="openLocker" />}
      />
    </>
  );

  if ( isTask ) {
    return renderScene();
  }

  return (
    <li>
      {renderScene()}
    </li>
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( OpenLockerBtn );
