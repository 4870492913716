import React, { useState } from 'react';
import moment from 'moment';
import {
  Row, Col, Modal, Button, UncontrolledTooltip,
} from 'reactstrap';
import {
  AsyncTable, EditCard, AddBtn, Intl,
} from 'components';
import NewPayroll from 'containers/Resource/Edit/Payroll/new';
import DatepickerInput from 'components/Form/utils/DatepickerInput';
import ViewPayroll from './view';

const PayrollView = ( {
  entityId,
  fetchData,
  dataTable,
  dataLoading,
  reload,
  setPayrollIdToDelete,
  toggleModalDelete,
} ) => {
  const [modalAddOpen, setModalAddOpen] = useState( false );

  const toggleModalAdd = () => {
    setModalAddOpen( ( v ) => !v );
  };

  const [payrollToView, setPayrollToView] = useState( null );
  const [modalAddView, setModalAddView] = useState( false );

  const toggleModalView = () => {
    setModalAddView( ( v ) => !v );
  };

  return (
    <>
      <EditCard cardClass="card-sec-bottom">
        <AsyncTable
          id="resourcePayrolls"
          data={dataTable.data}
          total={dataTable.elementsTotal}
          isLoading={dataLoading}
          rightContent={<AddBtn title="newPayroll" onClick={toggleModalAdd} />}
          disableSorting
          onFetchData={fetchData}
          customFilters={( { setMultipleFilters } ) => (
            <>
              <Row>
                <Col md={8} lg={4}>
                  <DatepickerInput
                    dateFormat="MM/YYYY"
                    placeholder="filterByMonthYear"
                    onChange={( v ) => {
                      const m = moment( v );
                      setMultipleFilters( {
                        month: m.format( 'M' ),
                        year: m.format( 'YYYY' ),
                      } );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <>
                  <a
                    href="/"
                    id={`tooltip-payroll-${cell.value}`}
                    onClick={( e ) => {
                      e.preventDefault();
                      setPayrollToView( cell.row.original );
                      toggleModalView();
                    }}
                  >
                    {cell.value.slice( 0, 8 )}
                  </a>
                  <UncontrolledTooltip
                    delay={0}
                    target={`tooltip-payroll-${cell.value}`}
                  >
                    <Intl id="view" />
                  </UncontrolledTooltip>
                </>
              ),
            },
            {
              Header: 'date',
              accessor: 'month',
              Cell: ( { cell } ) => ( `${cell.value < 10 ? `0${cell.value}` : cell.value}/${cell.row.original.year}` ),
            },
            {
              Header: '',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <Button
                  size="sm"
                  color="danger"
                  outline
                  onClick={() => {
                    setPayrollIdToDelete( cell.value );
                    toggleModalDelete();
                  }}
                >
                  Eliminar
                </Button>
              ),
            },
          ]}
        />
      </EditCard>

      <Modal
        isOpen={modalAddOpen}
        toggle={toggleModalAdd}
        className="modal-dialog-centered"
        size="lg"
      >
        <div className="modal-body">
          <NewPayroll
            entityId={entityId}
            onClose={() => {
              setModalAddOpen( false );
            }}
            onReloadData={() => {
              setModalAddOpen( false );
              reload();
            }}
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalAddView}
        toggle={toggleModalView}
        className="modal-dialog-centered"
        size="xl"
      >
        <div className="modal-body">
          <ViewPayroll payrollToView={payrollToView} toggleModalView={toggleModalView} />
        </div>
      </Modal>
    </>
  );
};

export default PayrollView;
