import React from 'react';
import {
  Row, Col, Container, Card, CardBody,
} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import {
  Intl, SimpleHeader, DashboardBookingInfo,
  DashboardTaskInfo, Datepicker,
} from 'components';
import Button from 'reactstrap/lib/Button';

export default ( {
  isOwner, data, isLoading, onOpenTaskEditModal, initialValues, onChangeFilter,
} ) => {
  const today = moment().startOf( 'day' ).format( 'YYYY-MM-DD' );

  return (
    <div className="dashboard">
      <SimpleHeader
        title="overview"
        classNameTitle="text-center d-block"
        styleTitle={{
          marginLeft: 400,
        }}
        noBreadcrumbs
        rightContent={(
          <div style={{ width: 400 }}>
            <div className="d-flex align-items-center justify-content-end">
              <Button
                className="mr-0 ml-0 mt-1 mb-1"
                color="primary"
                onClick={() => onChangeFilter( ( bef ) => ( { ...bef, today } ) )}
              >
                <i className="th-icon-calendar mr-1" style={{ position: 'relative', top: 4 }} />
                <Intl id="today" />
              </Button>
              <Datepicker
                position="right"
                label="date"
                dateFormat="ddd, D MMM YYYY"
                formGroupClass="btn-datepicker-custom nobordered"
                labelClass="fc-header-toolbar"
                placeholder="from"
                value={moment( initialValues.today )}
                onChange={( val ) => onChangeFilter( ( bef ) => ( { ...bef, today: val.startOf( 'day' ).format( 'YYYY-MM-DD' ) } ) )}
                startDateField={{ value: moment().subtract( '1', 'day' ) }}
                appendIcon={<i className="th-icon-calendar" />}
              />
            </div>
          </div>
        )}
      />
      <Container fluid className={classnames( { 'app-component-loading': isLoading } )}>
        <Row className="mt-4">
          <Col md={4}>
            <Card className="dashboard-card-title in">
              <CardBody>
                <Row className="align-items-center justify-content-between h-100">
                  <Col>
                    <div className="tit">
                      <Intl id="checksIn" />
                      {' '}
                      <Intl id="today" />
                      <i className="th-icon th-icon-in text-danger" />
                    </div>
                  </Col>
                  <Col className="text-right">
                    <div className="num">{data.checksIn ? data.checksIn.length : 0}</div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <DashboardBookingInfo
              isOwner={isOwner}
              bookings={data.checksIn}
              inOut="in"
            />
          </Col>
          <Col md={4}>
            <Card className="dashboard-card-title out">
              <CardBody>
                <Row className="align-items-center justify-content-between h-100">
                  <Col>
                    <div className="tit">
                      <Intl id="checksOut" />
                      {' '}
                      <Intl id="today" />
                      <i className="th-icon th-icon-out text-success" />
                    </div>
                  </Col>
                  <Col className="text-right">
                    <div className="num">{data.checksOut ? data.checksOut.length : 0}</div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <DashboardBookingInfo
              isOwner={isOwner}
              bookings={data.checksOut}
              inOut="out"
            />
          </Col>
          <Col md={4}>
            <Card className="dashboard-card-title tasks">
              <CardBody>
                <Row className="align-items-center m-0 h-100">
                  <Col md={4} className="c-task p-0">
                    <div className="task-box">
                      <div className="task-box-title">
                        <Intl id="solvedTasks" />
                        <br />
                        <Intl id="today" />
                      </div>
                      <div className="task-box-value">
                        {( data.countSolvedTasks || 0 ) + ( data.countClosedTasks || 0 )}
                        /
                        {data.countTasks}
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="c-task p-0">
                    <div className="task-box">
                      <div className="task-box-title">
                        <Intl id="solvedIssues" />
                        <br />
                        <Intl id="today" />
                      </div>
                      <div className="task-box-value">
                        {( data.countSolvedIssues || 0 ) + ( data.countClosedIssues || 0 )}
                        /
                        {data.countIssues}
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="c-task p-0">
                    <div className="task-box">
                      <div className="task-box-title">
                        <Intl id="reportedIssues" />
                        <br />
                        <Intl id="today" />
                      </div>
                      <div className="task-box-value">{data.countReportedIssues}</div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <DashboardTaskInfo
              data={data}
              onOpenTaskEditModal={isOwner ? () => {} : onOpenTaskEditModal}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
