import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TaskTypeParentEditView from 'views/TaskType/Parent/Edit';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import TaskTypeService from 'api/TaskType';
import TranslationService from 'api/Translation';
import {
  useDelete,
  useFetch,
  useFetchTableData,
  useSubmit,
} from 'hooks';
import TaskTypeChildrenEdit from '../../Children/Edit';

const TaskTypeEdit = ( {
  defaultFilters,
  toggleErrorAlert,
  toggleInfoAlert,
  toggleLoading,
  updateFilters,
  setFiltersError,
  parentPath,
  match,
} ) => {
  const taskTypeParentId = match.params.id;
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );
  const taskTypeId = useRef();

  const [dataParent, dataParentLoading] = useFetch( {
    initialState: {},
    promise: () => TaskTypeService.getTaskType( taskTypeParentId ),
    translationPromise: () => TranslationService
      .getTaskTypeTranslations( taskTypeParentId, 'taskType' ),
    format: ( dataToFormat ) => {
      if ( !taskTypeParentId ) return dataToFormat;
      const formattedData = { ...dataToFormat };
      if ( formattedData.checkLists ) {
        formattedData.checkLists = _.map(
          formattedData.checkLists,
          ( item ) => ( {
            value: item.id,
            label: item.name,
          } ),
        );
      }
      return formattedData;
    },
    toggleErrorAlert,
    conditional: !!taskTypeParentId,
    deps: [taskTypeParentId],
    reInit: true,
  } );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => TaskTypeService.getTaskTypes( {
      ...params,
      parentId: taskTypeParentId,
    } ),
    toggleErrorAlert,
    deps: [dataSubmitted, taskTypeParentId],
    conditional: !!taskTypeParentId,
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'taskTypes', {
        keyword: params.keyword,
      } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'taskTypes' );
        initialReloadError.current = false;
      }
    },
  } );

  const openEditModal = useCallback( ( childrenTaskType ) => {
    const id = _.get( childrenTaskType, 'id', '' );
    taskTypeId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [closeEditModal] );

  const deleteChildrenTask = useDelete( {
    promise: TaskTypeService.deleteTaskType,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const submitParent = useSubmit( {
    promise: ( dataToSend ) => TaskTypeService
      .updateTaskType( taskTypeParentId, dataToSend ),
    toggleLoading,
    toggleInfoAlert,
    translations: true,
    callback: reloadData,
  } );

  return (
    <>
      <TaskTypeParentEditView
        dataParent={dataParent}
        isLoadingdataParent={dataParentLoading}
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        defaultFilters={defaultFilters}
        onOpenEditModal={openEditModal}
        onDeleteChildren={deleteChildrenTask}
        parentPath={parentPath}
        onSubmit={submitParent}
      />
      <TaskTypeChildrenEdit
        taskTypeParentId={taskTypeParentId}
        taskTypeId={taskTypeId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.taskTypes' ) || {},
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( TaskTypeEdit );
