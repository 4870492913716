import classNames from 'classnames';
import { Button, HorizontalMenu, Intl } from 'components';
import RateSeasons from 'containers/PropertyManager/Edit/Rates/EditDrawer/RateSeasons';
import Accommodations from 'containers/PropertyManager/Edit/Rates/EditDrawer/Accommodations';
import Drawer from 'rc-drawer';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

export default React.memo( ( {
  data, open, onClose, isLoading, onReloadRates, onOpenModal,
} ) => {
  const [selectedTab, setSelectedTab] = useState( 'seasons' );

  const tabsList = [
    { name: 'seasons' },
    { name: 'accommodations' },
  ];

  return (
    <Drawer
      open={open}
      placement="right"
      handler={false}
      maskClosable
      level={null}
      onClose={onClose}
      className="zindex-drawer"
    >
      <div className={classNames( 'p-4', { 'app-component-loading': isLoading } )}>
        {/* Header */}
        <Row className="align-items-center">
          <Col>
            <span className="text-uppercase font-weight-bold ml-3">
              {data.id ? <Intl id="rate" /> : null}
              {' '}
              {data.id ? `#${data.id.slice( data.id.length - 6, data.id.length )}` : ''}
              {' '}
            </span>
          </Col>
          <Col xs={1}>
            <Button title="editRate" onClick={onOpenModal} />
          </Col>
          <Col xs={1} className="text-right">
            <i className="app-icon-close cursor-pointer py-2 pl-2" onClick={onClose} />
          </Col>
        </Row>
        {/* Tabs */}
        <Row className="mt-2 mb-4">
          <Col>
            <HorizontalMenu
              tabs={tabsList}
              selected={selectedTab}
              onChangeTab={( index ) => setSelectedTab( index )}
            />
          </Col>
        </Row>
        <div className="mt-3">
          <div className={classNames( {
            'd-none': selectedTab !== 'seasons',
          } )}
          >
            <RateSeasons
              data={data}
              rateId={data.id}
              onReloadRates={onReloadRates}
            />
          </div>
          <div className={classNames( {
            'd-none': selectedTab !== 'accommodations',
          } )}
          >
            <Accommodations
              data={data}
              rateId={data.id}
              onReloadRates={onReloadRates}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
} );
