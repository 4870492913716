import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ServiceItemEditView from 'views/Service/Item/Edit';
import SettingsActions from 'store/reducers/Settings';
import ServiceCategoryService from 'api/ServiceCategory';
import ServiceItemService from 'api/ServiceItem';
import TranslationService from 'api/Translation';
import { useFetch } from 'hooks';
import {
  formatFloat, formatDataToSend, handlePromise, formatFloatToSend,
} from 'utils';

const ServiceItemEdit = ( {
  serviceId, entityId, modalOpened, onReload, onCloseModal,
  toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ServiceItemService.getServiceItem( serviceId, entityId ),
    translationPromise: () => TranslationService.getServiceTranslations( entityId, 'serviceItem' ),
    format: ( toFormat ) => {
      const formattedData = { ...toFormat };
      if ( formattedData.serviceItemPrice ) {
        formattedData.serviceItemPrice = {
          ...formattedData.serviceItemPrice,
          price: formatFloat( formattedData.serviceItemPrice.price ),
          priceInternal: formatFloat( formattedData.serviceItemPrice.priceInternal ),
          offerPrice: formatFloat( formattedData.serviceItemPrice.offerPrice ),
        };
      }
      return formattedData;
    },
    toggleErrorAlert,
    conditional: !!entityId && modalOpened,
    deps: [entityId, modalOpened],
    reInit: true,
  } );

  const [categories, categoriesLoading] = useFetch( {
    initialState: { data: [] },
    promise: () => ServiceCategoryService.getServiceCategories(
      serviceId, { elementsPerPage: 1000 },
    ),
    toggleErrorAlert,
    conditional: !!modalOpened,
    deps: [modalOpened],
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form, true );
    delete dataToSend.mainImage;

    dataToSend.type = 'multi_selection';
    dataToSend.paymentMethod = 'addon_payment';

    if ( dataToSend.serviceItemPrice ) {
      dataToSend.serviceItemPrice = {
        ...dataToSend.serviceItemPrice,
        price: formatFloatToSend( dataToSend.serviceItemPrice.price ),
        priceInternal: formatFloatToSend( dataToSend.serviceItemPrice.priceInternal ),
        offerPrice: formatFloatToSend( dataToSend.serviceItemPrice.offerPrice ),
        pricePer: 'unit',
      };
    }
    const [errors, response] = await handlePromise(
      entityId ? ServiceItemService.updateServiceItem( serviceId, entityId, dataToSend )
        : ServiceItemService.saveServiceItem( serviceId, dataToSend ),
    );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( !formData.mainImage || _.isString( formData.mainImage ) ) {
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      return onReload();
    }

    const imageToSend = {
      mainImage: formData.mainImage ? formData.mainImage.file : null,
    };
    const [imageErrors, imageResponse] = await handlePromise(
      ServiceItemService.updateServiceItemImage( serviceId, entityId, imageToSend ),
    );
    toggleLoading( false );
    if ( !imageResponse.ok ) return imageErrors;

    toggleInfoAlert( 'dataSaved' );
    return onReload();
  }, [entityId, serviceId, onReload, toggleInfoAlert, toggleLoading] );

  return (
    <ServiceItemEditView
      data={data}
      isLoading={dataLoading}
      categories={categories}
      categoriesLoading={categoriesLoading}
      entityId={entityId}
      open={modalOpened}
      onClose={onCloseModal}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ServiceItemEdit );
