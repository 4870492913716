import api from 'config/Api/Api';

const baseEndpoint = '/region';

export default {
  getRegions( params ) {
    return api.get( `${baseEndpoint}`, params );
  },
  getRegion( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  postRegion( params ) {
    return api.post( `${baseEndpoint}`, params );
  },
  putRegion( id, params ) {
    return api.put( `${baseEndpoint}/${id}`, params );
  },
  deleteRegion( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
