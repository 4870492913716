import React from 'react';
import { Row, Col, Badge } from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable,
  DaterangePickerBtn,
  TableActionsBtns,
  TableGenericFilter,
  TableSelectFilter,
  TableToggler,
  Intl,
  AddBtn,
} from 'components';
import ContactNotesStatus from 'config/Constants/ContactNotesStatus';
import ContactNotesEdit from 'containers/CRM/Contact/Edit/Notes/Edit';

const ContactNotesView = ( {
  data,
  isLoading,
  onFetchData,
  onDeleteContactNotes,
  contactNotesEditModalOpened,
  toggleContactNotesEditModal,
  contactNoteToEdit,
  onEditContactNotes,
  reloadContactNotes,
} ) => (
  <>
    <AsyncTable
      id="ContactNotes"
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      onFetchData={onFetchData}
      rightContent={(
        <AddBtn title="newNote" onClick={() => { onEditContactNotes( null ); }} />
        )}
      customFilters={( { filters, setFilter } ) => (
        <Row>
          <Col md={8} lg={4}>
            <TableGenericFilter filters={filters} setFilter={setFilter} />
          </Col>
          <TableToggler />
        </Row>
      )}
      customToggleFilters={( { filters, setFilter, setMultipleFilters } ) => (
        <Row className="ml-0">

          <Col md={5} lg={2} className="pl-0 py-2">
            <TableSelectFilter
              name="status"
              label="state"
              placeholder="all"
              filters={filters}
              isClearable
              options={ContactNotesStatus}
              translateOptions
              setFilter={setFilter}
            />
          </Col>
          <Col md={6} lg={4} className="pl-0 py-2">
            <DaterangePickerBtn
              position="left"
              label="crm.notes.dateBetween"
              initialValue={moment.range( filters.from || moment().subtract( 30, 'days' ), filters.till || moment().add( 7, 'days' ) )}
              key={filters.from + filters.till}
              input
              onChange={( values ) => {
                setMultipleFilters( {
                  from: moment( values.start ).format( 'YYYY-MM-DD' ),
                  till: moment( values.end ).format( 'YYYY-MM-DD' ),
                } );
              }}
            />
          </Col>
        </Row>
      )}
      columns={[

        {
          Header: 'text',
          accessor: 'text',
          Cell: ( { cell } ) => (
            <div style={{ maxWidth: 700 }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={( e ) => {
                  e.preventDefault();
                  onEditContactNotes( cell.row.original );
                }}
              >
                {cell.value}
              </a>
            </div>

          ),
        },
        {
          Header: 'state',
          accessor: 'status',
          Cell: ( { cell } ) => (
            <Badge color={cell.value === 'created' ? 'danger' : 'success'} pill>
              <Intl id={`crm.notesStatuses.${cell.value}`} />
            </Badge>
          ),
        },
        {
          Header: 'crm.notes.date',
          accessor: 'createdAt',
          Cell: ( { cell } ) => ( cell.value

            ? moment( cell.value ).format( 'YYYY-MM-DD' )
            : '-' ),
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  isDelete: true,
                  onClick: () => {
                    onDeleteContactNotes( cell.row.original.id );
                  },
                },
              ]}
            />
          ),
        },
      ]}
    />
    <ContactNotesEdit
      open={contactNotesEditModalOpened}
      onClose={toggleContactNotesEditModal}
      contactNoteData={contactNoteToEdit}
      onReloadData={reloadContactNotes}
    />
  </>
);

export default ContactNotesView;
