import React from 'react';
import { Container } from 'reactstrap';

import {
  SimpleHeader, LocalTable, AddBtn, TableActionsBtns, TableEditBtn,
} from 'components';

export default React.memo( ( {
  countryId, countryName, data, isLoading, onOpenEditModal,
} ) => (
  <>
    <SimpleHeader
      title="states"
      parentLinks={[
        { name: countryName, translate: false, href: '/countries' },
      ]}
    />
    <Container fluid>
      <LocalTable
        data={data}
        isLoading={isLoading}
        rightContent={<AddBtn title="newState" onClick={onOpenEditModal} />}
        columns={[
          {
            Header: 'name',
            accessor: 'name',
            Cell: ( { cell } ) => (
              <TableEditBtn
                title={cell.value}
                onClick={() => onOpenEditModal( { id: cell.row.original.iso } )}
              />
            ),
          },
          {
            Header: 'iso',
            accessor: 'iso',
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    link: `/countries/${countryId}/states/${cell.row.original.iso}/cities`,
                    label: 'cities',
                    'data-cy': 'table-cities-btn',
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </Container>
  </>
) );
