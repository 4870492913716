import React, { useContext } from 'react';
import {
  Container, Card, Row, Col, ButtonGroup, Button,
} from 'reactstrap';
import {
  SimpleHeader, HorizontalMenu,
} from 'components';
import { BusinnesCRMContext } from 'containers/CRM/Business/Dashboard/context';
import BusinessAll from 'containers/CRM/Business/Dashboard/BusinnessAll';
import BusinessEdit from 'containers/CRM/Business/Edit';

const BusinessView = () => {
  const {
    tabsList, selectedTab, setSelectedTab,
    viewList,
    selectedView,
    setSelectedView,
    //
    onReload,
    //
    businnessToEdit,
    businnessToEditExtraData,
    businessEditModalOpened,
    toggleBusinessEditModal,
  } = useContext( BusinnesCRMContext );

  // const content = null;

  /*   switch ( selectedTab ) {
    case 'crm.business.allContacts':
      content = (
        <BusinessAllContacts />
      );
      break;
    case 'crm.business.myBusiness':
      content = <MyBusiness />;
      break;
    default:
      //
  } */

  return (
    <>
      <SimpleHeader
        title="crm.business"
      />
      <Container fluid>
        <Card className="card-sec-top border-bottom">
          <Row className="align-items-center">
            <Col>
              <HorizontalMenu
                tabs={tabsList}
                selected={selectedTab}
                onChangeTab={( index ) => { setSelectedTab( index ); }}
              />
            </Col>
            <Col xs="auto">
              <div className="pr-4">
                <ButtonGroup size="sm">
                  {viewList.map( ( { icon, id } ) => (
                    <Button
                      color="primary"
                      outline={id !== selectedView}
                      key={id}
                      className="py-1 px-2"
                      onClick={() => { setSelectedView( id ); }}
                    >
                      <i className={icon} />
                    </Button>
                  ) )}

                </ButtonGroup>
              </div>
            </Col>
          </Row>

          <div className="border-top">
            <BusinessAll />
          </div>
        </Card>
      </Container>
      <BusinessEdit
        open={businessEditModalOpened}
        extraData={businnessToEditExtraData}
        onClose={toggleBusinessEditModal}
        businnesId={businnessToEdit}
        onReloadData={onReload}
      />
    </>
  );
};

export default BusinessView;
