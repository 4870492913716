import React, { useState, useCallback, useRef } from 'react';
import ExpensesClientEditView from 'views/AccomodationOwner/Edit/expenses';
import { handlePromise } from 'utils';
import { connect } from 'react-redux';
import AccomodationOwnerService from 'api/AccomodationOwner';
import SettingsActions from 'store/reducers/Settings';
import moment from 'moment';

const ExpensesClientEdit = ( { match, toggleErrorAlert } ) => {
  const clientId = match.params.id;
  const filterFromDate = moment().startOf( 'day' );
  const filterToDate = moment( filterFromDate ).clone().add( '1', 'day' );
  const [filters, setFilters] = useState( {} );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [isDownloading, setisDownloading] = useState( false );
  const dateRange = useRef( moment.range( filterFromDate, filterToDate ) );

  const setKeywordRoomExpenses = ( keyword ) => setFilters(
    ( prev ) => ( { ...prev, keywordRoomExpenses: keyword } ),
  );

  const setKeywordTasksExpenses = ( keyword ) => setFilters(
    ( prev ) => ( { ...prev, keywordRoomExpenses: keyword } ),
  );

  const setAccommodationRoomExpenses = ( keyword ) => setFilters(
    ( prev ) => ( { ...prev, accommodationRoomExpenses: keyword } ),
  );

  const setAccommodationTasksExpenses = ( keyword ) => setFilters(
    ( prev ) => ( { ...prev, accommodationTasksExpenses: keyword } ),
  );

  const openInNewTab = async ( url, id ) => {
    const link = document.createElement( 'a' );
    link.href = url;
    link.setAttribute(
      'download',
      `expenses${id}.xlsx`,
    );
    document.body.appendChild( link );
    link.click();
    link.parentNode.removeChild( link );
  };

  const downloadRecord = useCallback(
    async ( ) => {
      setisDownloading( true );
      // eslint-disable-next-line operator-linebreak
      const [errors, response, responseData] = await handlePromise(
        AccomodationOwnerService.downloadExpensesOwner( clientId, {
          ...filters,
          from: dateRange.current.start
            ? dateRange.current.start.format( 'YYYY-MM-DD' )
            : undefined,
          till: dateRange.current.end
            ? dateRange.current.end.format( 'YYYY-MM-DD' )
            : undefined,
        } ),
      );
      setisDownloading( false );
      if ( !response.ok ) return toggleErrorAlert( errors );
      await openInNewTab( responseData?.url || '', clientId );
    },
    [toggleErrorAlert, setisDownloading, clientId, filters],
  );

  const updateDateRangeParams = useCallback( ( params ) => {
    dateRange.current = params;
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const forceReload = () => setDataSubmitted( new Date().valueOf() );

  return (
    <ExpensesClientEditView
      isDownloading={isDownloading}
      setKeywordRoomExpenses={setKeywordRoomExpenses}
      setKeywordTasksExpenses={setKeywordTasksExpenses}
      setAccommodationRoomExpenses={setAccommodationRoomExpenses}
      setAccommodationTasksExpenses={setAccommodationTasksExpenses}
      onDownload={downloadRecord}
      match={match}
      dateRange={dateRange}
      dataSubmitted={dataSubmitted}
      updateDateRangeParams={updateDateRangeParams}
      forceReload={forceReload}
    />
  );
};

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( ExpensesClientEdit );
