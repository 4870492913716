import React from 'react';
import {
  Row, Col, Card, CardBody, CardHeader,
} from 'reactstrap';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  Intl,
} from 'components';
import TaskItem from './task';

export default ( {
  data, onOpenTaskEditModal,
} ) => (
  <Card className="dashboard-info-card">
    <CardHeader className="border-0">
      <Row className="align-items-center justify-content-between">
        <Col>
          <div className="dashboard-info-card_book-tit m-0">
            <Intl id="tasksStatus" />
          </div>
        </Col>
        <Col className="text-right">

          <Link to="/operations/tasks" style={{ fontSize: 20 }}>
            <Intl id="seeAll" />
          </Link>
        </Col>
      </Row>
    </CardHeader>
    <CardBody className="pt-0">
      {_.map( data.tasks, ( task ) => (
        <TaskItem
          task={task}
          onOpenTaskEditModal={onOpenTaskEditModal}
          key={task.id}
        />
      ) )}
    </CardBody>
  </Card>
);
