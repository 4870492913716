import { createSeasonOccupancy, getAllSeasonsOccupancy } from 'api/Occupancy/SeasonOccupancy';
import { useFetch, useSubmit } from 'hooks';
import React, { useCallback, useState } from 'react';
import SettingsActions from 'store/reducers/Settings';
import WeekView from 'views/PropertyManager/Edit/Occupancies/EditDrawer/Seasons/Week';

import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import SeasonEdit from './Edit';

const WeekEdit = ( {
  visible,
  occupancyId,
  dataEdited,
  onReloadOccupancies,
  onOpenEditItemModal,
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
} ) => {
  const [currentStartDate, setCurrentStartDate] = useState( moment().startOf( 'month' ) );
  const [currentEndDate, setCurrentEndDate] = useState( moment().endOf( 'month' ) );
  const [modalOpen, setModalOpen] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [clickedDate, setClickedDate] = useState( new Date() );

  const formatToEvents = ( responseData ) => {
    const { data: seasons } = responseData;
    return _.map( seasons, ( item ) => ( {
      ...item,
      start: item.startDate.replace( 'Z', '' ),
      end: item.lastNight.replace( 'Z', '' ),
      title: `Occupancy: ${item.lengthOfTheStay}`,
      color: '#f9b586',
      eventTextColor: '#000',
    } ) );
  };

  const [data] = useFetch( {
    promise: () => getAllSeasonsOccupancy( {
      occupancyId,
      page: 1,
      elementsPerPage: 30,
      fromStart: currentStartDate,
      fromEnd: currentEndDate,
      tillStart: currentStartDate,
      tillEnd: currentEndDate,
    } ),
    format: formatToEvents,
    toggleErrorAlert,
    conditional: visible,
    deps: [occupancyId, currentEndDate, currentStartDate, visible, dataSubmitted, dataEdited],
    reInit: true,
  } );

  const updateCurrentDates = ( startDate, endDate ) => {
    setCurrentStartDate( startDate );
    setCurrentEndDate( endDate );
  };

  const onOpenModal = ( date ) => {
    setClickedDate( date );
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
    onReloadOccupancies();
    onCloseModal();
  }, [onReloadOccupancies] );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( createSeasonOccupancy( dataToSend ) ),
    format: ( dataToSend ) => ( {
      occupancyId,
      seasons: [
        {
          ...dataToSend,
          lastNight: new Date( dataToSend.lastNight ).toISOString(),
          startDate: new Date( dataToSend.startDate ).toISOString(),
        },
      ],
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: () => {
      reloadData();
    },
    deps: [occupancyId],
  } );

  return (
    <>
      <WeekView
        events={data || []}
        visible={visible}
        updateCurrentDates={updateCurrentDates}
        onSubmit={onSubmit}
        onClose={onCloseModal}
        onOpenEditItemModal={onOpenEditItemModal}
        onOpenModal={onOpenModal}
        isLoading={false}
      />
      <SeasonEdit
        occupancyId={occupancyId}
        data={{
          startDate: moment( clickedDate ).startOf( 'week' ).add( 1, 'days' ),
          lastNight: moment( clickedDate ).endOf( 'week' ),
        }}
        events={data || []}
        open={modalOpen}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( WeekEdit );
