import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  TextField, ModalForm, Intl, Select,
} from 'components';
import _ from 'lodash';
import { required } from 'config/InputErrors';
import { formatFloat } from 'utils';

export default React.memo( ( {
  itemId, data, isLoading, open, onClose, onSubmit, serviceItem, serviceItemLoading,
} ) => (
  <ModalForm
    initialValues={data}
    title={itemId ? 'editItem' : 'newItem'}
    showErrorsInFooter
    isLoading={isLoading}
    bodyClassName="overflow-visible"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
    body={( form ) => (
      <>
        <Row>
          {itemId && (
            <Col md={6}>
              <p className="m-0 mt-2"><Intl id="service" /></p>
              <p className="text-gray-2 m-0">
                {data.item ? data.item.name : ''}
              </p>
            </Col>
          )}

          {!itemId && (
            <Col md={6}>
              <Select
                field="item"
                label="service"
                labelClass="font-weight-bold"
                options={serviceItem.data}
                isLoading={serviceItemLoading}
                onChange={( value ) => {
                  const item = _.find( serviceItem.data, { id: value } );
                  form.change( 'price', formatFloat( item.serviceItemPrice.price ) );
                  form.change( 'quantity', 1 );
                  form.change( 'amount', formatFloat( item.serviceItemPrice.price ) );
                }}
              />
            </Col>
          )}

        </Row>
        <Row>
          <Col md={3}>
            <TextField
              field="price"
              label="price"
              type="number"
              disabled
              validate={required}
            />
          </Col>
          <Col md={3}>
            <TextField
              field="quantity"
              label="quantity"
              type="number"
              validate={required}
              onChange={( value ) => {
                form.change( 'amount', form.getFieldState( 'price' ).value * value );
              }}
            />
          </Col>
          <Col md={3}>
            <TextField
              field="amount"
              label="amount"
              type="number"
              validate={required}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              field="notesInternal"
              label="notesInternal"
              rows={3}
            />
          </Col>
        </Row>
        {data.notesCustomer && (
          <Row>
            <Col>
              <p className="m-0 mt-2"><Intl id="notesCustomer" /></p>
              <p className="text-gray-2 m-0">
                {data.notesCustomer}
              </p>
            </Col>
          </Row>
        )}
      </>
    )}
  />
) );
