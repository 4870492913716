import api from 'config/Api/Api';

const baseEndpoint = ( taskId ) => `/tasks/${taskId}/notes`;

export default {
  saveNote( taskId, data ) {
    return api.post( baseEndpoint( taskId ), data );
  },

  deleteNote( taskId, id ) {
    return api.delete( `${baseEndpoint( taskId )}/${id}` );
  },
};
