import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import BookingDashboardView from 'views/Booking/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import BookingActions from 'store/reducers/Booking';
import { useFetchTableData, useFetch } from 'hooks';
import BookingService from 'api/Booking';
import AccommodationService from 'api/Accommodation';
import CustomerService from 'api/Customer';
import PropertyService from 'api/Property';
import TagsPredefinedService from 'api/TagsPredefined';
import CityService from 'api/Settings/City';

import { history } from 'store';

const BookingDashboard = ( {
  bookingReloadedAt,
  match,
  updateFilters,
  setFiltersError,
  toggleErrorAlert,
  searchParams,
  userBookingReference,
  toggleBookingNewModal,
  propertyManager,
} ) => {
  const today = moment().startOf( 'day' );
  const initialReloadError = useRef( true );
  const isInitialMount = useRef( true );
  const showLoading = useRef( true );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [selectedCity, setSelectedCity] = useState();

  const [outerCitiesOptions] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        city: item.city.id,
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      showLoading.current = false;
      setDataSubmitted( new Date().valueOf() );
    }
  }, [bookingReloadedAt] );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => BookingService.getBookings( {
      ...params,
      accommodation: _.get( params, 'accommodation.value' )
        ? params.accommodation.value
        : undefined,
      owner: _.get( params, 'owner.value' )
        ? params.owner.value
        : undefined,
      property: _.get( params, 'property.value' )
        ? params.property.value
        : undefined,
      fromStart: params.fromStart
        ? params.fromStart
        : today.clone().format( 'YYYY-MM-DD' ),
      fromEnd: params.fromEnd
        ? params.fromEnd
        : today.clone().add( 7, 'days' ).format( 'YYYY-MM-DD' ),
      tags: _.get( params, 'tags' ),
    } ),
    callback: ( params ) => {
      if ( params.city ) {
        setSelectedCity( _.get( params, 'city' ) );
      }
      initialReloadError.current = false;
      updateFilters( 'bookings', {
        status: params.status,
        accommodation: params.accommodation
          ? {
            value: params.accommodation.value,
            label: params.accommodation.label,
          }
          : null,
        owner: params.owner
          ? {
            value: params.owner.value,
            label: params.owner.label,
          }
          : null,
        property: params.property
          ? {
            value: params.property.value,
            label: params.property.label,
          }
          : null,
        fromStart: params.fromStart,
        fromEnd: params.fromEnd,
        tillStart: params.tillStart,
        tillEnd: params.tillEnd,
      } );
      const search = new URLSearchParams(
        _.omitBy(
          {
            ...params,
            accommodation_value: _.get( params, 'accommodation.value' ),
            accommodation_label: _.get( params, 'accommodation.label' ),
            accommodation: null,
            owner_value: _.get( params, 'owner.value' ),
            owner_label: _.get( params, 'owner.label' ),
            owner: null,
            property_value: _.get( params, 'property.value' ),
            property_label: _.get( params, 'property.label' ),
            property: null,
            fromStart: params.fromStart
              ? params.fromStart
              : today.clone().format( 'YYYY-MM-DD' ),
            fromEnd: params.fromEnd
              ? params.fromEnd
              : today.clone().add( 7, 'days' ).format( 'YYYY-MM-DD' ),
          },
          _.isNil,
        ),
      ).toString();
      if ( search ) {
        history.replace( { search: `?${search}` } );
      }
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'bookings' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const [CitiesOptions] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.city.id,
        name: item.city.name,
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );
  const fetchCustomers = useCallback(
    ( query ) => CustomerService.getCustomers( {
      elementsPerPage: 500,
      keyword: query,
    } ),
    [],
  );
  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 500,
      keyword: query,
    } ),
    [],
  );

  const fetchTagsPredefined = useCallback( ( ) => TagsPredefinedService.getTagsPredefined(), [] );

  const openNewModal = useCallback(
    ( ) => {
      toggleBookingNewModal();
    },
    [toggleBookingNewModal],
  );

  const urlFilters = () => {
    const params = new URLSearchParams( searchParams );
    const filters = {
      status: params.get( 'status' ),
      accommodation:
        params.get( 'accommodation_value' ) && params.get( 'accommodation_label' )
          ? {
            value: params.get( 'accommodation_value' ),
            label: params.get( 'accommodation_label' ),
          }
          : null,
      owner:
        params.get( 'owner_value' ) && params.get( 'owner_label' )
          ? {
            value: params.get( 'owner_value' ),
            label: params.get( 'owner_label' ),
          }
          : null,
      property:
          params.get( 'property_value' ) && params.get( 'property_label' )
            ? {
              value: params.get( 'property_value' ),
              label: params.get( 'property_label' ),
            }
            : null,
      fromStart: params.get( 'fromStart' ),
      fromEnd: params.get( 'fromEnd' ),
      tillStart: params.get( 'tillStart' ),
      tillEnd: params.get( 'tillEnd' ),
      tags: params.get( 'tags' ),
    };
    return filters;
  };

  const [zonesOptions] = useFetch( {
    initialState: [],
    promise: () => CityService.getZones( outerCitiesOptions.find(
      ( c ) => c.city === selectedCity,
    ).id, {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        name: item.name,
      } ) );
      return dataFormated;
    },
    conditional: !!selectedCity,
    toggleErrorAlert,
    deps: [selectedCity],
  } );

  return (
    <BookingDashboardView
      data={data}
      parentPath={match.url}
      isLoading={dataLoading}
      defaultFilters={urlFilters()}
      onFetchData={fetchData}
      onFetchAccommodations={fetchAccommodations}
      onFetchCustomers={fetchCustomers}
      onFetchProperties={fetchProperties}
      onFetchTagsPredefined={fetchTagsPredefined}
      userBookingReference={userBookingReference}
      onOpenNewModal={openNewModal}
      Cities={CitiesOptions}
      zones={zonesOptions}
    />
  );
};

const mapStateToProps = ( { router, user, booking } ) => ( {
  searchParams: _.get( router, 'location.search', '' ),
  userBookingReference: _.get( user, 'settings.bookingReference' ),
  bookingReloadedAt: booking.reloadedAt,
  propertyManager: _.get( user, 'user.propertyManager.id', {} ),
} );

const mapDispatchToProps = ( {
  toggleBookingNewModal: BookingActions.toggleBookingNewModal,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( BookingDashboard );
