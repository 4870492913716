import api from 'config/Api/Api';

const baseEndpoint = '/settings/cities';

export default {
  getZones( cityId, params ) {
    return api.get( `${baseEndpoint}/${cityId}/zones`, params );
  },

  saveZone( cityId, data ) {
    return api.post( `${baseEndpoint}/${cityId}/zones`, data );
  },

  updateZone( cityId, id, data ) {
    return api.put( `${baseEndpoint}/${cityId}/zones/${id}`, data );
  },
};
