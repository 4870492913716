import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useFetch } from 'hooks';
import AmenityRecordDashboardView from 'views/Property/RoomType/Accommodation/Edit/AmenityRecords/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';
import AmenityRecordService from 'api/AmenityRecord';
import AmenityRecordsEdit from '../Edit';

const AmenityRecordsDashboard = ( {
  data,
  entityId,
  parentIds,
  toggleErrorAlert,
  toggleLoading,
} ) => {
  const [amenityRecordId, setAmenityRecordId] = useState( null );
  const [editModalOpened, setEditModalOpened] = useState( false );
  const [idReload, setIdReload] = useState( new Date().valueOf() );

  const [amenityRecords] = useFetch( {
    initialState: { data: [] },
    promise: () => AmenityRecordService.getAmenityRecords( {
      elementsPerPage: 1000,
      accommodation: entityId,
    } ),
    toggleErrorAlert,
    toggleLoading,
    reInit: true,
    deps: [idReload],
  } );

  const toggleEditModal = useCallback(
    ( id ) => {
      setAmenityRecordId( id );
      setEditModalOpened( !editModalOpened );
    },
    [editModalOpened],
  );

  const reloadData = useCallback( async () => {
    if ( editModalOpened ) toggleEditModal( null );
    setIdReload( new Date().valueOf() );
  }, [editModalOpened, toggleEditModal] );

  const deleteEntity = useCallback(
    async ( id, cb ) => {
      toggleLoading( true );

      // eslint-disable-next-line operator-linebreak
      const [errors, response] = await handlePromise(
        AmenityRecordService.deleteAmenityRecord( id ),
      );
      toggleLoading( false );
      if ( !response.ok ) return toggleErrorAlert( errors );
      cb();
      setIdReload( new Date().valueOf() );
    },
    [toggleErrorAlert, toggleLoading],
  );

  const openInNewTab = async ( url, id ) => {
    const link = document.createElement( 'a' );
    link.href = url;
    link.setAttribute(
      'download',
      `inv${id}.csv`,
    );
    document.body.appendChild( link );
    link.click();
    link.parentNode.removeChild( link );
  };

  const downloadRecord = useCallback(
    async ( id ) => {
      toggleLoading( true );
      // eslint-disable-next-line operator-linebreak
      const [errors, response, responseData] = await handlePromise(
        AmenityRecordService.downloadAmenityRecord( id ),
      );
      toggleLoading( false );
      if ( !response.ok ) return toggleErrorAlert( errors );
      await openInNewTab( responseData?.url || '', id );
      setIdReload( new Date().valueOf() );
    },
    [toggleErrorAlert, toggleLoading],
  );

  return (
    <>
      <AmenityRecordDashboardView
        name={data.entity ? data.entity.name : ''}
        data={amenityRecords.data}
        toggleEditModal={toggleEditModal}
        onDelete={deleteEntity}
        onDownload={downloadRecord}
      />

      <AmenityRecordsEdit
        accommodationId={entityId}
        amenityRecordId={amenityRecordId}
        entityAmenities={amenityRecords}
        parentIds={parentIds}
        modalOpened={editModalOpened}
        toggleEditModal={toggleEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( AmenityRecordsDashboard );
