import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TravelReportEditView from 'views/Property/TravelReport';
import SettingsActions from 'store/reducers/Settings';
import TravelReportAuthority from 'api/TravelReportAuthority';
import { useSubmit } from 'hooks';

const TravelReportEdit = ( {
  data,
  entityId,
  parentPath,
  toggleLoading,
  toggleInfoAlert,
} ) => {
  const travelReportAuthority = _.get( data, 'entity.travelReportAuthority', {} );
  const travelReportAuthorityId = _.get( travelReportAuthority, 'id' );

  const submitForm = useSubmit( {
    promise: travelReportAuthorityId
      ? ( dataToSend ) => TravelReportAuthority.updateTravelReportAuthorit(
        travelReportAuthorityId,
        dataToSend,
      )
      : TravelReportAuthority.saveTravelReportAuthority,
    format: ( toFormat ) => {
      const formattedData = { ...toFormat };
      if ( !travelReportAuthorityId ) {
        formattedData.level = 'accommodation';
        formattedData.accommodation = entityId;
      }
      return formattedData;
    },
    toggleLoading,
    toggleInfoAlert,
    deps: [data, travelReportAuthorityId],
  } );

  return (
    <TravelReportEditView
      name={data.entity ? data.entity.name : ''}
      data={travelReportAuthority}
      parentPath={parentPath}
      onSubmit={submitForm}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( TravelReportEdit );
