import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import useFetch from 'hooks/useFetch';
import PropertyManagerService from 'api/PropertyManager';
import PropertyManagerFinanceSettingsView from 'views/PropertyManager/Edit/Finance/Settings';

const PropertyManagerFinanceSettings = ( {
  pmData,
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
} ) => {
  /* GET DATA */
  const [data] = useFetch( {
    promise: () => PropertyManagerService.getSettingPayment( pmData.id ),
    toggleErrorAlert,
    toggleLoading,
    conditional: !!pmData.id,
    deps: [pmData.id],
  } );

  const [paymentGatewaysList, paymentGatewaysListLoading] = useFetch( {
    promise: () => PropertyManagerService.getSettingPaymentGateways( {
      page: 1,
      elementsPerPage: 999,
      enabled: true,
    } ),
    format: ( d ) => {
      if ( d && d.data && d.data.length ) {
        return d.data.map( ( elem ) => {
          const { id, name } = elem;
          return { id, name };
        } );
      }
      return [];
    },
    toggleErrorAlert,
  } );
  /* end GET DATA */

  /* PUSH FORM DATA */
  const [booleanData, setBooleanData] = useState( {
    manualPayment: false,
    notifications: false,
    notificationsUncompletedPaymentsAndSystemSuspension: false,
  } );

  useEffect( () => {
    if ( data ) {
      setBooleanData( {
        manualPayments: data?.manualPayments || false,
        notifications: data?.notifications || false,
        notificationsUncompletedPaymentsAndSystemSuspension:
        data?.notificationsUncompletedPaymentsAndSystemSuspension || false,
      } );
    }
  }, [data] );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const datesSend = formatDataToSend( formData, form );

      const {
        manualPayments, notifications,
        notificationsUncompletedPaymentsAndSystemSuspension,
      } = booleanData;

      const dataToSend = {
        ...datesSend,
        manualPayments: manualPayments ? 'true' : 'false',
        notifications: notifications ? 'true' : 'false',
        notificationsUncompletedPaymentsAndSystemSuspension: notificationsUncompletedPaymentsAndSystemSuspension ? 'true' : 'false',
      };

      const [dataErrors, dataResponse] = await handlePromise(
        PropertyManagerService.putSettingPayment( pmData.id, dataToSend ),
      );

      toggleLoading( false );
      if ( !dataResponse.ok ) {
        toggleErrorAlert();
        return dataErrors;
      }

      toggleInfoAlert( 'dataSaved' );
    },
    [pmData, booleanData, toggleErrorAlert, toggleInfoAlert, toggleLoading],
  );
  /* end PUSH FORM DATA */

  return (
    <PropertyManagerFinanceSettingsView
      data={data}
      paymentGatewaysList={paymentGatewaysList}
      paymentGatewaysListLoading={paymentGatewaysListLoading}
      submitForm={submitForm}
      booleanData={booleanData}
      setBooleanData={setBooleanData}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( PropertyManagerFinanceSettings );
