import React from 'react';

import {
  LocalTable, AddBtn, TableActionsBtns, Intl,
} from 'components';

export default React.memo( ( {
  data, isLoading, onOpenNewModal, onDelete,
} ) => (
  <LocalTable
    data={data}
    isLoading={isLoading}
    titleContent={( <h3 className="m-0"><Intl id="communityRules" /></h3> )}
    rightContent={<AddBtn title="newCommunityRule" onClick={onOpenNewModal} />}
    containerClassName="card-sec-bottom"
    columns={[
      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'description',
        accessor: 'description',
      },
      {
        accessor: 'id',
        disableSorting: true,
        Header: null,
        maxWidth: 65,
        Cell: ( { cell } ) => (
          <TableActionsBtns
            actions={[
              {
                isDelete: true,
                onClick: ( cb ) => onDelete( cell.value, cb ),
              },
            ]}
          />
        ),
      },
    ]}
  />
) );
