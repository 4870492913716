import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const DELETE_SEASON = gql`
  mutation DeleteSeasonRate($seasonRateId: ID!) {
    deleteSeasonRate(seasonRateId: $seasonRateId) {
      ... on MutationDeleteSeasonRateSuccess {
        data {
          success
        }
      }
      ... on BaseError {
        message
      }
    }
  }
`;

const deleteSeason = async ( seasonRateId ) => {
  const { data } = await client.query( {
    query: DELETE_SEASON,
    variables: {
      seasonRateId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteSeasonRate.message ) {
    return {
      ok: false,
      message: data.deleteSeasonRate.message,
    };
  }
  const deleteSuccessful = data.deleteSeasonRate.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteSeason;
