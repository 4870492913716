import React from 'react';
import {
  Navbar,
  NavItem,
  NavLink,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';

import { Intl } from 'components';

const Subnavbar = React.memo( ( { routes, pathname } ) => {
  const isActive = ( route ) => {
    if ( route.views ) {
      return !!_.find( route.views, ( item ) => pathname.indexOf( item.layout + item.path ) === 0 );
    }
    return route.layout + route.path === '/' ? pathname === '/' : pathname.indexOf( route.layout + route.path ) === 0;
  };

  return (
    <>
      <Navbar
        className="navbar-top subnavbar fixed-top d-none d-sm-block"
      >
        <Nav>
          {_.map( routes, ( route ) => (
            <NavItem
              className={classnames( { active: isActive( route ) } )}
              key={route.name}
            >
              {route.views
                ? (
                  <UncontrolledDropdown className="h-100 cursor-pointer" data-cy="main-subnavbar-dropdown">
                    <DropdownToggle
                      tag="a"
                      caret
                      className={classnames( 'nav-link', { active: isActive( route ) } )}
                    >
                      <span className="nav-link-text"><Intl id={route.name} /></span>
                    </DropdownToggle>
                    <DropdownMenu className="navbar-dropdown">
                      {_.map( route.views, ( childRoute, index ) => ( [
                        index > 0 && <DropdownItem divider key={`${childRoute.path}-divider`} />,
                        <DropdownItem
                          key={childRoute.path}
                          tag={NavLinkRRD}
                          to={childRoute.layout + childRoute.path}
                        >
                          <span><Intl id={childRoute.name} /></span>
                        </DropdownItem>,
                      ] ) )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
                : (
                  <NavLink
                    to={route.layout + route.path}
                    tag={NavLinkRRD}
                    data-cy="main-subnavbar-link"
                  >
                    <span className="nav-link-text"><Intl id={route.name} /></span>
                  </NavLink>
                )}
            </NavItem>
          ) )}
        </Nav>
      </Navbar>
    </>
  );
} );

export default Subnavbar;
