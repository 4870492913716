import React from 'react';
import { Col, Row } from 'reactstrap';
import { canModify } from 'config/Ability';
import {
  TextField, ModalForm, TranslationLayout, Select, Dropzone,
  // Checkbox,
} from 'components';
import { composeValidators, integer, required } from 'config/InputErrors';
import {
  // serviceItemPaymentMethod, serviceItemPricePer, serviceItemType,
  serviceItemUnits,
} from 'config/Constants';

export default React.memo(
  ( {
    entityId,
    data,
    isLoading,
    categories,
    categoriesLoading,
    open,
    onSubmit,
    onClose,
  } ) => (
    <ModalForm
      initialValues={data}
      title={entityId ? 'editItem' : 'newItem'}
      showErrorsInFooter
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      bodyClassName="overflow-visible"
      onSubmit={canModify( 'services' ) ? onSubmit : () => {}}
      formEnabled={canModify( 'services' )}
    >
      <TranslationLayout
        translationFields={( locale ) => (
          <>
            <Row>
              <Col md={6}>
                <TextField field={`translations.${locale}.name`} label="name" />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  field={`translations.${locale}.description`}
                  label="description"
                  type="textarea"
                  rows={4}
                />
              </Col>
            </Row>
          </>
        )}
      >
        <Row>
          <Col md="auto">
            <Dropzone
              field="mainImage"
              formGroupClass="dz-profile-img"
              label="mainImage"
            />
          </Col>
          <Col md>
            <Row>
              <Col>
                <TextField field="name" label="name" validate={required} />
              </Col>
              {/* <Col md={6}>
                <Select
                  field="type"
                  label="type"
                  validate={required}
                  options={serviceItemType}
                  translateOptions
                />
              </Col> */}
            </Row>

            <Row>
              <Col>
                <TextField
                  field="description"
                  label="description"
                  type="textarea"
                  rows={4}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Select
                  field="serviceCategory"
                  label="category"
                  options={categories.data}
                  isLoading={categoriesLoading}
                  validate={required}
                />
              </Col>
              {/* <Col md={6}>
                <Select
                  field="paymentMethod"
                  label="paymentMethod"
                  options={serviceItemPaymentMethod}
                  translateOptions
                  validate={required}
                />
              </Col> */}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <TextField
              field="quantity"
              label="quantity"
              type="number"
              validate={composeValidators( required, integer )}
            />
          </Col>
          <Col md={6}>
            <Select
              field="units"
              label="unit"
              options={serviceItemUnits}
              translateOptions
              validate={required}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <TextField
              field="serviceItemPrice.price"
              label="price"
              type="number"
              validate={required}
            />
          </Col>
          {/* <Col md={4}>
            <Select
              field="serviceItemPrice.pricePer"
              label="priceUnit"
              options={serviceItemPricePer}
              translateOptions
              validate={required}
            />
          </Col> */}
          <Col md={6}>
            <TextField
              field="serviceItemPrice.priceInternal"
              label="priceInternal"
              type="number"
            />
          </Col>
          <Col md={6}>
            <TextField
              field="externalId"
              label="externalId"
            />
          </Col>
          <Col md={6}>
            <TextField
              label="hoursInAdvance"
              field="hoursInAdvance"
              type="number"
            />
          </Col>
        </Row>
        {/*
        <Row>
          <Col md={12}>
            <TextField
              field="serviceItemPrice.description"
              label="description"
              type="textarea"
              rows={2}
            />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <TextField field="serviceItemPrice.offerTitle" label="offerTitle" />
          </Col>
          <Col md={4}>
            <TextField
              field="serviceItemPrice.offerPrice"
              label="offerPrice"
              type="number"
            />
          </Col>
          <Col md={4} className="mt-4">
            <Checkbox
              field="serviceItemPrice.offerActive"
              label="offerActive"
            />
          </Col>
        </Row>
       */}
      </TranslationLayout>
    </ModalForm>
  ),
);
