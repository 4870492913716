/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import NewBooking from 'containers/Property/RoomType/Accommodation/Edit/Bookings/New';
import BookingDashboardView from 'views/Property/RoomType/Accommodation/Edit/Bookings/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import BookingActions from 'store/reducers/Booking';
import { useFetch } from 'hooks';
import BookingService from 'api/Booking';
import BlockedBooking from 'containers/Property/RoomType/Accommodation/Edit/Bookings/BookingBlocked';
import BookingBlockedService from 'api/BookingBlocked';

const BookingDashboard = ( {
  bookingReloadedAt,
  match,
  toggleErrorAlert,
  userBookingReference,
  propertyManager,
  entityId,
  parentIds,
} ) => {
  const isInitialMount = useRef( true );
  const showLoading = useRef( true );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [modalOpen, setModalOpen] = useState( false );
  const [modalBlockedOpen, setModalBlockedOpen] = useState( false );
  const [bookingBlockedId, setBookingBlockedId] = useState( );
  const onOpenModal = () => {
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };
  const onOpenBlockedModal = ( bookingBlocked ) => {
    setBookingBlockedId( bookingBlocked );
    setModalBlockedOpen( true );
  };

  const onCloseBlockedModal = () => {
    setModalBlockedOpen( false );
  };
  const formatToEvents = ( dataToFormat ) => {
    const orignalData = _.get( dataToFormat, 'data', [] );
    const formatData = _.map( orignalData, ( item ) => ( {
      ...item,
      start: item.category ? moment( item.from ).format( 'YYYY-MM-DD' ) : moment( item.from ).format( 'YYYY-MM-DDTHH:mm:ss.SSS' ),
      end: item.category ? moment( item.till ).format( 'YYYY-MM-DD' ) : moment( item.till ).format( 'YYYY-MM-DDTHH:mm:ss.SSS' ),
      title: item.category ? 'Blocked' : 'Booking',
      bookingId: item.category ? null : item.id,
    } ) );
    return formatData;
  };

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      showLoading.current = false;
      setDataSubmitted( new Date().valueOf() );
    }
  }, [bookingReloadedAt] );
  const [data, dataLoading] = useFetch( {
    initialState: [],
    promise: () => BookingService.getBookings( {
      page: 1,
      elementsPerPage: 100,
      propertyManager,
      accommodation: entityId,
    } ),
    format: formatToEvents,
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const [bookingBlocked/* , dataLoadingBookingBloked */] = useFetch( {
    initialState: [],
    promise: () => BookingBlockedService.getBookingsBlocked( {
      page: 1,
      elementsPerPage: 100,
    } ),
    format: formatToEvents,
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );
  const dataBlockedFilter = _.filter( bookingBlocked, ( item ) => (
    item.accommodation.id === entityId
    && item.propertyManager.id === propertyManager ) );
  const finalData = _.concat( data, dataBlockedFilter );
  const reloadData = useCallback( () => {
    onCloseModal();
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const reloadDataBlocked = useCallback( () => {
    onCloseBlockedModal();
    setDataSubmitted( new Date().valueOf() );
  }, [] );
  return (
    <>
      <BookingDashboardView
        data={finalData}
        parentPath={match.url}
        userBookingReference={userBookingReference}
        onOpenNewModal={onOpenModal}
        onOpenBlockedModal={onOpenBlockedModal}
        propertyManager={propertyManager}
        isLoading={dataLoading}
      />
      <NewBooking
        accommodationId={entityId}
        onCloseModal={onCloseModal}
        onReloadData={reloadData}
        modalOpen={modalOpen}
      />
      <BlockedBooking
        onReload={reloadDataBlocked}
        accommodationId={entityId}
        onClose={onCloseBlockedModal}
        open={modalBlockedOpen}
        roomTypeId={parentIds.roomType}
        bookingId={bookingBlockedId}
      />
    </>
  );
};

const mapStateToProps = ( { router, user, booking } ) => ( {
  searchParams: _.get( router, 'location.search', '' ),
  userBookingReference: _.get( user, 'settings.bookingReference' ),
  bookingReloadedAt: booking.reloadedAt,
  propertyManager: _.get( user, 'user.propertyManager.id', {} ),
} );

const mapDispatchToProps = ( {
  toggleBookingNewModal: BookingActions.toggleBookingNewModal,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( BookingDashboard );
