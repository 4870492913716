import React from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import {
  ModalForm,
  TextField,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    data,
    open,
    onClose,
    onSubmit,
    isLoading,
  } ) => {
    const initialData = data ? {
      name: _.get( data, 'data.name', '' ),
    } : undefined;

    return (
      <ModalForm
        initialValues={initialData}
        title={!_.isEmpty( data ) ? 'editRate' : 'newRate'}
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        zIndex={9999}
        bodyClassName="overflow-visible"
        body={() => (
          <>
            <Row>
              <Col md={6}>
                <TextField
                  field="name"
                  label="name"
                  validate={required}
                />
              </Col>
            </Row>
          </>
        )}
      />
    );
  },
);
