import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField,
  EditCard,
  TranslationLayout,
  Datepicker,
  Intl,
} from 'components';

export default React.memo(
  () => (
    <EditCard title="ownerApp.legal.title" cardClass="card-sec-bottom">
      <TranslationLayout
        translationFields={( locale ) => (
          <>
            <Row>
              <Col md={6}>
                <TextField
                  field={`translations.${locale}.termsUrl`}
                  label="ownerApp.termsUrl"
                />
              </Col>
              <Col md={6}>
                <TextField
                  field={`translations.${locale}.legalAdviceUrl`}
                  label="ownerApp.legalAdviceUrl"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextField
                  field={`translations.${locale}.officeHours`}
                  label="ownerApp.officeHours"
                />
              </Col>
              <Col md={3}>
                <TextField
                  field={`translations.${locale}.officeHoursPhone`}
                  label="ownerApp.officeHoursPhone"
                />
              </Col>
              <Col md={6}>
                <TextField
                  field={`translations.${locale}.emergencies`}
                  label="ownerApp.emergencies"
                />
              </Col>
              <Col md={3}>
                <TextField
                  field={`translations.${locale}.emergenciesPhone`}
                  label="ownerApp.emergenciesPhone"
                />
              </Col>
              <Col sm={12}>
                <TextField
                  field={`translations.${locale}.attendance`}
                  label="ownerApp.attendance"
                  type="textarea"
                  rows={4}
                />
              </Col>
            </Row>
          </>
        )}
      >
        <Row>
          <Col lg>
            <Row>
              <Col md={6}>
                <TextField field="termsUrl" label="ownerApp.termsUrl" />
              </Col>
              <Col md={6}>
                <TextField
                  field="legalAdviceUrl"
                  label="ownerApp.legalAdviceUrl"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextField field="officeHours" label="ownerApp.officeHours" />
              </Col>
              <Col md={3}>
                <TextField field="officeHoursPhone" label="ownerApp.officeHoursPhone" />
              </Col>
              <Col md={6}>
                <TextField field="emergencies" label="ownerApp.emergencies" />
              </Col>
              <Col md={3}>
                <TextField field="emergenciesPhone" label="ownerApp.emergenciesPhone" />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <label>
              <Intl id="attendanceHoursWeekday" />
            </label>
          </Col>
          <Col md={3}>
            <Datepicker
              field="weekFrom"
              label="ownerApp.weekFrom"
              dateFormat={false}
              timeFormat="HH:mm"
              size="xsm"
            />
          </Col>
          <Col md={3}>
            <Datepicker
              field="weekTill"
              label="ownerApp.weekTill"
              dateFormat={false}
              timeFormat="HH:mm"
              size="xsm"
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <label>
              <Intl id="attendanceHoursWeekend" />
            </label>
          </Col>
          <Col md={3}>
            <Datepicker
              field="weekendFrom"
              label="ownerApp.weekendFrom"
              dateFormat={false}
              timeFormat="HH:mm"
              size="xsm"
            />
          </Col>
          <Col md={3}>
            <Datepicker
              field="weekendTill"
              label="ownerApp.weekendTill"
              dateFormat={false}
              timeFormat="HH:mm"
              size="xsm"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              field="attendance"
              label="ownerApp.attendance"
              type="textarea"
              rows={4}
            />
          </Col>
        </Row>
      </TranslationLayout>
    </EditCard>
  ),
);
