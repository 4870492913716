import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  AsyncTable,
  AddBtn,
  SimpleHeader,
  TableGenericFilter,
  TableActionsBtns,
} from 'components';

export default React.memo(
  ( {
    data,
    isLoading,
    parentPath,
    defaultFilters,
    onFetchData,
    onDelete,
  } ) => (
    <>
      <SimpleHeader title="places" />
      <Container fluid>
        <AsyncTable
          id="Places"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={( <AddBtn title="newPlace" route={`${parentPath}/new`} /> )}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter filters={filters} setFilter={setFilter} />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'name',
              accessor: 'name',
              Cell: ( { cell } ) => (
                <Link
                  to={`${parentPath}/edit/${cell.row.original._id}`}
                >
                  {cell.value}
                </Link>
              ),
            },
            {
              Header: 'description',
              accessor: 'description',
              Cell: ( { cell } ) => ( cell.value || '-' ),
            },

            {
              Header: 'zone',
              accessor: 'zone',
              Cell: ( { cell } ) => ( cell.value.name ? cell.value.name : '-' ),
            },
            {
              Header: 'accommodation',
              accessor: 'property',
              Cell: ( { cell } ) => ( cell.value?.name || '-' ),
            },
            {
              accessor: '_id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => {
                const actions = [];

                actions.push( {
                  link: `${parentPath}/edit/${cell.row.original._id}`,
                  isEdit: true,
                } );
                actions.push( {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                } );
                return <TableActionsBtns actions={actions} />;
              },
            },
          ]}
        />
      </Container>
    </>
  ),
);
