import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {
  Intl, Form, TextField, FormActionsBtns,
} from 'components';
import { email, composeValidators } from 'config/InputErrors';

const EmailSettings = React.memo( ( { data, onSubmit, isLoading } ) => (
  <Container
    fluid
    className={classnames( { 'app-component-loading': isLoading } )}
  >
    <div className="py-5">
      <Form
        initialValues={data}
        showErrorsInFooter
        onSubmit={onSubmit}
        body={() => (
          <div className="email-settings">
            <h3 className="mb-3">
              <Intl id="OperativeOwner" />
            </h3>
            <Row className="mb-3">
              <Col md={3}>
                <TextField
                  field="defaultSenderToOwnerName"
                  label="defaultSenderToOwnerName"
                />
                <span className="help-text">
                  <Intl id="defaultSenderToOwnerName.help" />
                </span>
              </Col>

              <Col md={3}>
                <TextField
                  field="defaultSenderToOwnerEmail"
                  label="defaultSenderToOwnerEmail"
                  validate={composeValidators( email )}
                />
                <span className="help-text">
                  <Intl id="defaultSenderToOwnerEmail.help" />
                </span>
              </Col>
            </Row>
          </div>
        )}
        footer={<FormActionsBtns />}
      />
    </div>
  </Container>
) );

export default EmailSettings;
