import { Container, Row, Col } from 'reactstrap';
import React from 'react';

import { Intl } from 'components';
import wip from 'assets/img/illustrations/wip.svg';

const ComingSoon = () => (
  <Container fluid>
    <Row>
      <Col className="coming-soon">
        <img src={wip} alt={<Intl id="comingSoon" />} />
        <h1><Intl id="comingSoon" /></h1>
        <p><Intl id="comingSoonText" /></p>
      </Col>
    </Row>
  </Container>
);

export default ComingSoon;
