import React from 'react';
import { Route, Switch } from 'react-router';
import Dashboard from './Dashboard';

const Property = ( { match } ) => (
  <Switch>
    <Route
      path={match.url}
      render={( props ) => <Dashboard {...props} parentPath={match.url} />}
    />
  </Switch>
);

export default Property;
