import React from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import _ from 'lodash';

import {
  AsyncTable, AddBtn, SimpleHeader, TableActionsBtns, TableEditBtn,
  CanAccess, AutogenerateTaskRule, TableSelectFilter,
} from 'components';
import { canView, canModify } from 'config/Ability';

export default React.memo( ( {
  data, isLoading, defaultFilters, onFetchData,
  onOpenEditModal, onDelete, taskTypes, isLoadingTaskTypes,
} ) => {
  const canViewProp = canView( 'task_type' );
  const canEditDelete = canModify( 'task_type' );

  return (
    <>
      <SimpleHeader title="autogenerationRules" />
      <Container fluid>
        <AsyncTable
          id="autogenerateTasks"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={(
            <CanAccess I="add" a="task_type">
              <AddBtn
                title="newRule"
                onClick={onOpenEditModal}
              />
            </CanAccess>
)}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableSelectFilter
                  name="taskType"
                  label="taskType"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  setFilter={setFilter}
                  options={taskTypes}
                  optgroup
                  isLoading={isLoadingTaskTypes}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'taskToGenerate',
              accessor: 'taskType.name',
              Cell: ( { cell } ) => (
                canEditDelete || canViewProp ? (
                  <TableEditBtn
                    title={cell.value}
                    onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
                  />
                ) : cell.value
              ),
            },
            {
              Header: 'rule',
              accessor: 'executionPoint',
              Cell: ( { cell } ) => (
                <AutogenerateTaskRule
                  executionPoint={cell.value}
                  whenPeriod={cell.row.original.whenPeriod}
                  target={cell.row.original.target}
                  targetReference={cell.row.original.targetReference}
                  targetReferenceName={
                      cell.row.original.targetReferenceName
                        ? cell.row.original.targetReferenceName
                        : null
                      }
                  duration={cell.row.original.duration}
                  durationMax={cell.row.original.durationMax}
                  period={cell.row.original.period}
                  extra={cell.row.original.extra}
                  owner={cell.row.original.owner}
                />
              ),
            },
            {
              Header: 'cities',
              accessor: 'zones',
              Cell: ( { cell } ) => {
                const citiesGroup = _.groupBy(
                  cell.value,
                  'propertyManagerCity.id',
                );
                const cities = _.map( _.values( citiesGroup ), ( cityGroup ) => {
                  const propertyManagerCity = _.get(
                    cityGroup,
                    '[0].propertyManagerCity',
                  );
                  return {
                    value: propertyManagerCity.id,
                    label: propertyManagerCity.city.name,
                  };
                } );
                return _.map( cities, 'label' ).join( ', ' );
              },
            },
            {
              Header: 'zones',
              accessor: 'zones',
              Cell: ( { cell } ) => _.map( cell.value, 'name' ).join( ', ' ),
            },
            {
              Header: 'properties',
              accessor: 'properties',
              Cell: ( { cell } ) => _.map( cell.value, 'name' ).join( ', ' ),
            },
            {
              Header: 'accommodations',
              accessor: 'accommodations',
              Cell: ( { cell } ) => _.map( cell.value, 'name' ).join( ', ' ),
            },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        isDelete: true,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      },
                    ]}
                    modalTitle="autoGentask:delete"
                  />
                ),
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );
