import api from 'config/Api/Api';

const baseEndpoint = '/payments';

export default {
  savePayment( data ) {
    return api.post( baseEndpoint, data );
  },
  updatePayment( paymentId, data ) {
    return api.put( `${baseEndpoint}/${paymentId}`, data );
  },
  deletePayment( paymentId ) {
    return api.delete( `${baseEndpoint}/${paymentId}` );
  },
  getPaymentsCustomer( customerId, params ) {
    return api.get( `${baseEndpoint}/${customerId}`, params );
  },
  /** ******************** */
  getPaymentsResource( resourceId, params ) {
    return api.get( `${baseEndpoint}/resource/${resourceId}`, params );
  },
  getPaymentsTeam( teamId, params ) {
    return api.get( `${baseEndpoint}/team/${teamId}`, params );
  },
  getPaymentsAccommodationOwner( accommodationOwnerId, params ) {
    return api.get( `${baseEndpoint}/accommodation-owner/${accommodationOwnerId}`, params );
  },
  //
  postPaymentsResource( resourceId, params ) {
    return api.post( `${baseEndpoint}/resource/${resourceId}`, params );
  },
  postPaymentsTeam( teamId, params ) {
    return api.post( `${baseEndpoint}/team/${teamId}`, params );
  },
  postPaymentsAccommodationOwner( accommodationOwnerId, params ) {
    return api.post( `${baseEndpoint}/accommodation-owner/${accommodationOwnerId}`, params );
  },
  //
  getPaymentDetail( paymentId ) {
    return api.get( `${baseEndpoint}/payment-customer/${paymentId}` );
  },
  putPaymentDetail( paymentId, params ) {
    return api.put( `${baseEndpoint}/payment-customer/${paymentId}`, params );
  },
  deletePaymentDetail( paymentId ) {
    return api.delete( `${baseEndpoint}/payment-customer/${paymentId}` );
  },
  /** ******************** */
  createPaymentCustomer( data ) {
    return api.post( 'paymentsCustomer', data );
  },
};
