import React from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import _ from 'lodash';

import {
  AsyncTable, AddBtn, SimpleHeader, TableActionsBtns, TableEditBtn,
  CanAccess, TableSelectFilter,
} from 'components';
import { canView, canModify } from 'config/Ability';
import { getResourceName } from 'utils';

export default React.memo( ( {
  data, isLoading, defaultFilters, onFetchData,
  onOpenEditModal, onDelete, taskTypes, isLoadingTaskTypes,
  onFetchEmployees,
} ) => {
  const canViewProp = canView( 'settings_task_auto_assignation' );
  const canEditDelete = canModify( 'settings_task_auto_assignation' );

  return (
    <>
      <SimpleHeader title="autoasignationRules" />
      <Container fluid>
        <AsyncTable
          id="autoassignTasks"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={(
            <CanAccess I="add" a="settings_task_auto_assignation">
              <AddBtn
                title="newRule"
                onClick={onOpenEditModal}
              />
            </CanAccess>
)}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={3} lg={3}>
                <TableSelectFilter
                  name="taskType"
                  label="taskType"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  setFilter={setFilter}
                  options={taskTypes}
                  optgroup
                  isLoading={isLoadingTaskTypes}
                />
              </Col>
              {/* <Col md={3} lg={3}>
                <TableSelectFilter
                  name="properties"
                  label="properties"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  defaultOptions
                  setFilter={setFilter}
                  loadOptions={onFetchProperties}
                />
              </Col>
              <Col md={3} lg={3}>
                <TableSelectFilter
                  name="accommodations"
                  label="accommodations"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  defaultOptions
                  setFilter={setFilter}
                  loadOptions={onFetchAccommodations}
                />
              </Col> */}
              <Col md={3} lg={3}>
                <TableSelectFilter
                  name="employee"
                  label="resources"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  defaultOptions
                  labelKey={getResourceName}
                  loadOptions={onFetchEmployees}
                  setFilter={setFilter}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'taskType',
              accessor: 'taskType.name',
              Cell: ( { cell } ) => (
                canEditDelete || canViewProp ? (
                  <TableEditBtn
                    title={cell.value}
                    onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
                  />
                ) : cell.value
              ),
            },
            {
              Header: 'properties',
              accessor: 'properties',
              Cell: ( { cell } ) => _.map( cell.value, 'name' ).join( ', ' ),
            },
            {
              Header: 'accommodations',
              accessor: 'accommodations',
              Cell: ( { cell } ) => _.map( cell.value, 'name' ).join( ', ' ),
            },
            {
              Header: 'employee',
              accessor: 'employee',
              Cell: ( { cell } ) => (
                cell.value.name
              ),
            },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        isDelete: true,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      },
                    ]}
                  />
                ),
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );
