import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import State from './State';

const Country = ( { match } ) => (
  <Switch>
    <Route
      path={`${match.url}/:id/states`}
      component={State}
    />
    <Route
      path={match.url}
      component={Dashboard}
    />
  </Switch>
);

export default Country;
