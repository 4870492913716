import React from 'react';
import { connect } from 'react-redux';

import BookingBlockedView from 'views/Property/RoomType/Accommodation/Edit/Bookings/BookingBlocked';
import useSubmit from 'hooks/useSubmit';
import BookingBlockedService from 'api/BookingBlocked';
import moment from 'moment';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useDelete } from 'hooks';

const BookingBlocked = ( {
  open,
  onClose,
  bookingId,
  toggleInfoAlert,
  toggleErrorAlert,
  toggleLoading,
  accommodationId,
  roomTypeId,
  onReload,
  preloadedData,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: { ...preloadedData },
    promise: () => BookingBlockedService.getBookingBlocked( bookingId ),
    toggleErrorAlert,
    conditional: !!bookingId && open,
    deps: [bookingId, open],
    reInit: true,
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( bookingId
      ? BookingBlockedService.updateBookingBlocked( bookingId, {
        ...dataToSend,
        from: typeof dataToSend.from === 'number'
          ? new Date( dataToSend.from ) : dataToSend.from,
        till: typeof dataToSend.till === 'number'
          ? new Date( dataToSend.till ) : dataToSend.till,
      } )
      : BookingBlockedService.saveBookingBlocked( {
        ...dataToSend,
        from: moment( dataToSend.from ).format( 'YYYY/MM/DD' ),
        till: moment( dataToSend.till ).format( 'YYYY/MM/DD' ),
        ota: 'nomads',
        requestSource: 'web',
        accommodation: accommodationId,
        roomType: roomTypeId,
      } ) ),
    toggleLoading,
    toggleInfoAlert,
    callback: onReload,
    translations: true,
    deps: [bookingId, accommodationId, roomTypeId],
  } );

  const deleteBookingBlocked = useDelete( {
    promise: BookingBlockedService.deleteBookingBlocked,
    callback: onReload,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <BookingBlockedView
      data={data}
      isLoading={dataLoading}
      open={open}
      onClose={onClose}
      onSubmit={submitForm}
      bookingId={bookingId}
      onDelete={deleteBookingBlocked}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( BookingBlocked );
