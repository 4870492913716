import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const DELETE_PIPELINE = gql`
mutation DeletePipeline($force: Boolean!, $id: String!, $propertyManager: String!) {
  deletePipeline(id: $id, force: $force, propertyManager: $propertyManager) {
    ... on BaseError {
      message
    }
    ... on MutationDeletePipelineSuccess {
      data {
        success
      }
    }
  }
}
`;

const deletePipeline = async ( id ) => {
  const { data } = await client.query( {
    query: DELETE_PIPELINE,
    variables: {
      id,
      force: true,
      propertyManager: getPropertyManager(),
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deletePipeline.message ) {
    return {
      ok: false,
      message: data.deletePipeline.message,
    };
  }
  const deleteSuccessful = data.deletePipeline.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deletePipeline;
