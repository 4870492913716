import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import {
  SimpleHeader, AsyncTable, DaterangePickerBtn, Intl,
} from 'components';
import { canView } from 'config/Ability';
import { bookedServiceStatus } from 'config/Constants';
import numeral from 'numeral';
import { currencyFormat, formatFloat } from 'utils';

export default React.memo( ( {
  data, parentPath, isLoading, defaultFilters, onFetchData, userCurrency,
} ) => (
  <>
    <SimpleHeader
      title="services"
    />
    <Container fluid>
      <AsyncTable
        id="bookedServices"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        onFetchData={onFetchData}
        customFilters={( { filters, setMultipleFilters } ) => (
          <Row>
            <Col md={8} lg={4}>
              <DaterangePickerBtn
                position="left"
                label="date"
                initialValue={moment.range( filters.from || moment(), filters.till || moment().add( 1, 'month' ) )}
                key={filters.from + filters.till}
                input
                onChange={( values ) => {
                  setMultipleFilters( {
                    from: moment( values.start ).format( 'YYYY-MM-DD' ),
                    till: moment( values.end ).format( 'YYYY-MM-DD' ),
                  } );
                }}
              />
            </Col>
          </Row>
        )}
        columns={[
          {
            Header: '#',
            accessor: 'id',
            Cell: ( { cell } ) => (
              canView( 'booking' )
                ? (
                  <Link to={`${parentPath}/${cell.row.original.id}`} className="text-uppercase">
                    {cell.value ? ` #${cell.value.slice( cell.value.length - 6, cell.value.length )}` : ''}
                  </Link>
                ) : (
                  <span>
                    {cell.value ? ` #${cell.value.slice( cell.value.length - 6, cell.value.length )}` : ''}
                  </span>
                )
            ),
          },
          {
            Header: 'date',
            accessor: 'date',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  `${moment.utc( cell.value ).format( 'MMM DD, YYYY' )}`
                ) : ''
            ),
          },
          {
            Header: 'service',
            accessor: 'service',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  <span>
                    {cell.value.name}
                  </span>
                ) : ''
            ),
          },
          {
            Header: 'customer',
            accessor: 'owner',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  <span>
                    {cell.value.name}
                  </span>
                ) : ''
            ),
          },
          {
            Header: 'cost',
            accessor: 'cost',
            Cell: ( { cell } ) => ( cell.value ? numeral( formatFloat( cell.value ) ).format( currencyFormat( userCurrency ) ) : '' ),
          },
          {
            Header: 'createdAt',
            accessor: 'created',
            Cell: ( { cell } ) => (
              cell.value
                ? (
                  moment( cell.value ).format( 'MMM DD, YYYY' )
                ) : ''
            ),
          },
          {
            Header: 'status',
            accessor: 'status',
            Cell: ( { cell } ) => {
              const status = _.find( bookedServiceStatus, { id: cell.value } );
              return status
                ? <span className={status.textClass}><Intl id={status.name} /></span> : null;
            },
          },
        ]}
      />
    </Container>
  </>
) );
