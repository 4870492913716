import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  TextField,
  EditCard,
  // Select,
  Intl,
  Dropzone,
} from 'components';

export default React.memo(
  () => (
    <EditCard title="ownerApp.ownApp.title" cardClass="card-sec-bottom">
      <Row>
        <Col lg>
          <Row>
            <Col md="auto">
              {/* <Dropzone
                field="logo"
                formGroupClass="dz-profile-img"
                label="ownerApp.logoHeader"
                placeholder={(
                  <>
                    <i className="fa fa-file fa-4x mb-4" />
                    <p>
                      <Intl
                        id="upload.maxMesureSizeAndHeight"
                        values={{ size: '100Kb', height: '90px' }}
                      />
                    </p>
                  </>
                )}
              /> */}
              <Dropzone
                field="logoBig"
                formGroupClass="dz-profile-img"
                label="ownerApp.logoHeader"
                placeholder={(
                  <>
                    <i className="fa fa-file fa-4x mb-4" />
                    <p>
                      <Intl
                        id="upload.maxMesureSizeAndHeight"
                        values={{ size: '100Kb', height: '200px' }}
                      />
                    </p>
                  </>
                )}
              />
            </Col>

            <Col>
              <Row>
                <Col>
                  <TextField
                    field="appName"
                    label="ownerApp.ownApp.appName"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    field="introPhrase"
                    label="description"
                    type="textarea"
                    rows={4}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h4 className="font-weight-500 text-uppercase">
                    <Intl id="ownerApp.ownApp.storesLinks" />
                  </h4>
                </Col>
              </Row>
              <Row>

                <Col md={6}>
                  <TextField
                    field="iosUrl"
                    label="ownerApp.ownApp.iosUrl"
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    field="androidUrl"
                    label="ownerApp.ownApp.androidUrl"
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col md={5}>
                  <Select
                    field="accommodationName"
                    label="ownerApp.accommodationName"
                    translateOptions
                    options={ownerAppNaming}
                  />
                </Col>
              </Row> */}
              <Row>
                {/* <Col md={12}>
                  <h4 className="font-weight-500 text-uppercase">
                    <Intl id="ownerApp.appearance.colors" />
                  </h4>
                </Col>
                <Col md={3}>
                  <TextField
                    field="mainColor"
                    label="ownerApp.mainColor"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="secondaryColor"
                    label="ownerApp.secondaryColor"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="lightTextColor"
                    label="ownerApp.lightTextColor"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="mainButtonBg"
                    label="ownerApp.mainButtonBg"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="mainButtonText"
                    label="ownerApp.mainButtonText"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="activeBottomNav"
                    label="ownerApp.activeBottomNav"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="iconsColor"
                    label="ownerApp.iconsColor"
                  />
                </Col> */}
                <Col md={12}>
                  <h4 className="font-weight-500 text-uppercase">
                    <Intl id="ownerApp.video" />
                  </h4>
                </Col>
                <Col md={6}>
                  <TextField
                    field="introVideo"
                    label="ownerApp.introVideo"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </EditCard>
  ),
);
