import React from 'react';

import TimeLine from './components/TimeLine';
import RoomTypes from './components/RoomTypes';
import Sumary from './components/Sumary';
import Operations from './components/Operations';

const Calendar = ( {
  data,
  dataReports,
  isLoadingDataReports,
  onContextMenu,
  onClickBooking,
  onClickTask,
  showReport,
  onDeleteTask,
  onDragEnd,
  onDeleteBookingBlocked,
  onClickCell,
  onClickAccomodation,
  resetAccomodation,
} ) => (
  <div
    id="calendarxd"
    className="dc_container"
    style={{ overflow: 'visible' }}
  >
    <table>
      <div className="timeline-sticky ">
        <TimeLine dateRange={data.dateRange} />
      </div>

      { showReport && !isLoadingDataReports && (
        <>
          <Sumary data={dataReports} />
          <Operations data={dataReports} />
        </>
      )}
      {
          data.roomTypes.map( ( roomType, index ) => (
            <RoomTypes
              index={index}
              key={roomType.roomTypeId}
              data={roomType}
              dateRange={data.dateRange}
              onContextMenu={onContextMenu}
              onClickBooking={onClickBooking}
              onClickTask={onClickTask}
              onDeleteTask={onDeleteTask}
              onDragEnd={onDragEnd}
              onDeleteBookingBlocked={onDeleteBookingBlocked}
              onClickCell={onClickCell}
              roomTypeId={roomType.roomTypeId}
              onClickAccomodation={onClickAccomodation}
              resetAccomodation={resetAccomodation}
            />
          ) )
        }
    </table>
  </div>
);

export default Calendar;
