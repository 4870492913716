import React, { useCallback, useState } from 'react';
import { useFetchTableData } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import AccommodationsView from 'views/PropertyManager/Edit/Discount/EditDrawer/Accomodations';
import { connect } from 'react-redux';
import { getAllAccommodationsDiscount } from 'api/Discounts/Accomodations';
import AccommodationsAddModal from './Edit';

const DiscountSeasons = ( { discountId, onReloadDiscounts, toggleErrorAlert } ) => {
  const [modalOpen, setModalOpen] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const onOpenModal = () => {
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getAllAccommodationsDiscount( {
      discountId,
      ...params,
    } ),
    toggleErrorAlert,
    deps: [discountId, dataSubmitted],
    conditional: Boolean( discountId ),
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
    onReloadDiscounts();
    onCloseModal();
  }, [onReloadDiscounts] );

  return (
    <>
      <AccommodationsView
        isDataLoading={dataLoading}
        data={data}
        onFetchData={fetchData}
        onDeleteItem={() => {}}
        onOpenEditItemModal={onOpenModal}
      />
      <AccommodationsAddModal
        discountId={discountId}
        data={data}
        open={modalOpen}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( DiscountSeasons );
