import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form,
  FormActionsBtns,
  Datepicker,
  Select,
  TextField,
  Intl,
} from 'components';
import { required } from 'config/InputErrors';
import { statusPaid } from 'config/Constants';
import getPlatformOptionsDeposits from 'utils/plugins';

const NewPaymentView = ( {
  paymentToEdit, submitForm, onClose, plugins,
} ) => (
  <>
    <h3>
      <Intl id={paymentToEdit ? 'editPayment' : 'newPayment'} />
      {' '}
      {paymentToEdit?.id || ''}
    </h3>
    <hr className="mt-0" />
    <Form
      onSubmit={submitForm}
      showErrorsInFooter
      initialValues={paymentToEdit}
      body={() => (
        <>
          <Row>
            {/* {_.isEmpty( selectedPayment ) && ( */}
            <Col md={3}>
              <Datepicker
                field="date"
                label="date"
                dateFormat="DD/MM/YYYY"
                formGroupClass="m-0"
                validate={required}
              />
            </Col>
            {/* )} */}
            <Col md={3}>
              <TextField
                field="amount"
                label="amount"
                type="number"
                validate={required}
                translate
              />
            </Col>
            <Col md={3}>
              <Select
                field="status"
                label="status"
                options={statusPaid}
                validate={required}
                translateOptions
              />
            </Col>

          </Row>
          <Row>
            <Col md={8}>
              <TextField
                field="description"
                label="description"
                type="text"
                validate={required}
              />
            </Col>
            <Col md={4}>
              <Select
                field="gateway"
                label="gateway"
                options={getPlatformOptionsDeposits( plugins )}
                validate={required}
                translateOptions
              />
            </Col>
          </Row>
          {/* {selectedPayment.gateway === 'external' && ( */}
          <Row>
            <Col>
              <TextField field="link" label="paymentLink" type="text" />
            </Col>
          </Row>
          {/* )} */}
          <Row>
            <Col>
              <h4>
                <Intl id="futurePaymentDate" />
              </h4>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Datepicker
                field="notificationDate"
                label="notificationDate"
                dateFormat="DD/MM/YYYY"
                formGroupClass="m-0"
              />
            </Col>
          </Row>
        </>
      )}
      footer={<FormActionsBtns onClickCancel={onClose} />}
    />
  </>
);

export default NewPaymentView;
