/* eslint-disable no-nested-ternary */
import React from 'react';
import { Container } from 'reactstrap';
import classnames from 'classnames';

import { SimpleHeader, AccomodationOwnerUserEditForm } from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    entityId,
    isLoading,
    parentPath,
    onSubmit,
    onDelete,
  } ) => {
    const canEdit = canModify( 'property_owner' );

    return (
      <>
        <SimpleHeader parentLinks={[{ name: 'propertyOwnerUsers', href: parentPath }]} />

        <Container
          fluid
          className={classnames( { 'app-component-loading': isLoading } )}
        >
          <AccomodationOwnerUserEditForm
            entityId={entityId}
            data={data}
            title={entityId
              ? canEdit
                ? 'editAccomodationOwnerUser' : 'accomodationOwnerUser' : 'newAccomodationOwnerUser'}
            parentPath={parentPath}
            formEnabled={canEdit}
            onSubmit={canEdit ? onSubmit : () => {}}
            enableDelete={canEdit}
            onDelete={onDelete}
          />
        </Container>
      </>
    );
  },
);
