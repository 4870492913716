import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import MasterKeysDashboardView from 'views/MasterKeys/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import MasterKeyService from 'api/MasterKeys';
import { useDelete, useFetchTableData, useFetch } from 'hooks';
import TaskTypeService from 'api/TaskType';
import PropertyService from 'api/Property';
import AccommodationService from 'api/Accommodation';
import EmployeeService from 'api/Employee';
import AutoassignTaskEdit from '../Edit';

const AutoassignTaskDashboard = ( {
  toggleErrorAlert, toggleLoading, searchParams,
} ) => {
  const urlSearchParams = new URLSearchParams( searchParams );

  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => MasterKeyService.getMasterKeys( {
      ...params,
      customer: _.get( params, 'customer.value' )
        ? params.customer.value
        : undefined,
      properties: _.get( params, 'properties.value' )
        ? [params.properties.value]
        : undefined,
      accommodations: _.get( params, 'accommodations.value' )
        ? [params.accommodations.value]
        : undefined,
    } ),
    deps: [dataSubmitted],
    callback: () => {
      initialReloadError.current = false;
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        initialReloadError.current = false;
      }
    },
  } );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const fetchProperties = useCallback( ( query ) => PropertyService.getProperties( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ), [],
  );

  const fetchEmployees = useCallback( ( query ) => EmployeeService.getEmployees( {
    elementsPerPage: 10,
    keyword: query,
    employee: true,
  } ), [] );

  const deleteEntity = useDelete( {
    promise: MasterKeyService.deleteMasterKey,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const urlFilters = () => {
    const filters = {
      customer:
        urlSearchParams.get( 'customer_value' )
        && urlSearchParams.get( 'customer_label' )
          ? {
            value: urlSearchParams.get( 'customer_value' ),
            label: urlSearchParams.get( 'customer_label' ),
          }
          : null,
      properties:
        urlSearchParams.get( 'properties_value' )
        && urlSearchParams.get( 'properties_label' )
          ? {
            value: urlSearchParams.get( 'properties_value' ),
            label: urlSearchParams.get( 'properties_label' ),
          }
          : null,
      accommodations:
        urlSearchParams.get( 'accommodations_value' )
        && urlSearchParams.get( 'accommodations_label' )
          ? {
            value: urlSearchParams.get( 'accommodations_value' ),
            label: urlSearchParams.get( 'accommodations_label' ),
          }
          : null,
    };
    return filters;
  };

  return (
    <>
      <MasterKeysDashboardView
        data={data}
        isLoading={dataLoading}
        taskTypes={taskTypes}
        isLoadingTaskTypes={loadingTaskTypes}
        onFetchProperties={fetchProperties}
        onFetchAccommodations={fetchAccommodations}
        onFetchEmployees={fetchEmployees}
        defaultFilters={urlFilters()}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteEntity}
      />

      <AutoassignTaskEdit
        masterKeyId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { router } ) => ( {
  searchParams: _.get( router, 'location.search', '' ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( AutoassignTaskDashboard );
