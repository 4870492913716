import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import filterBuilder, { sortByBuilder } from './filterBuilder';
import getPropertyManager from '../utils/getPropertyManager';

const GET_CONTACT_NOTES = gql`
query getContactNotes($filters: NoteFiltersInput!, $orderBy: [NoteOrderByInput!]!, $page: Int!, $limit: Int!, $propertyManager: String!) {
  Notes(filters: $filters, limit: $limit, orderBy: $orderBy, page: $page, propertyManager: $propertyManager) {
    ... on BaseError {
      message
    }
    ... on QueryNotesSuccess {
      data {
        elementsPerPage
        total
        data {
          createdAt
          id
          status
          text        
        }
      }
    }
  }
}
`;

const getContactNotes = async ( { page, elementsPerPage, ...rest } ) => {
  const filters = filterBuilder( rest, [
    'status',
    'text',
    'state',
    'from',
    'till',
    'contactId',
  ] );
  const orderBy = sortByBuilder( rest );

  const { data } = await client.query( {
    query: GET_CONTACT_NOTES,
    variables: {
      page: page || 1, // pageCurrent,
      limit: elementsPerPage || 10,
      filters,
      orderBy,
      propertyManager: getPropertyManager(),
    },
    fetchPolicy: 'no-cache',
  } );

  const notes = data.Notes.data.data || [];
  const elementsTotal = data.Notes.data.total || 0;

  if ( data.Notes.message ) {
    return {
      ok: false,
      message: data.Notes.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: { data: notes, elementsTotal, elementsPerPage },
  };
};

export default getContactNotes;
