import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import TaskIncidentDashboardView from 'views/Incidence/EditDrawer/RelatedTasks/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
import NewTaskIncident from 'containers/Incidences/Edit/RelatedTasks/New';
import TaskActions from 'store/reducers/Task';
import { loadIncidenceTasks, deleteIncidenceTask } from './utils';

const RelatedTasksDashboard = ( {
  incidenceId,
  toggleErrorAlert,
  data: incidenceData,
  toggleTaskEditModal,
  toggleLoading,
} ) => {
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => loadIncidenceTasks(
      incidenceId,
      { ...params },
    ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const openNewModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const openEditModal = useCallback(
    ( { id } = {} ) => {
      setTimeout( () => {
        toggleTaskEditModal( id );
      }, 500 );
    },
    [toggleTaskEditModal],
  );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  const deleteTask = useDelete( {
    promise: ( id ) => deleteIncidenceTask( incidenceId, id ),
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <TaskIncidentDashboardView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        onDelete={deleteTask}
        onOpenEditModal={openEditModal}
        onOpenNewModal={openNewModal}
      />

      <NewTaskIncident
        incidenceId={incidenceId}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
        onOpenEditModal={openEditModal}
        preloadedData={{ accommodation: incidenceData.accommodation }}
      />
    </>
  );
};

const mapStateToProps = ( { task } ) => ( {
  taskReloadedAt: task.reloadedAt,
  taskReloadedId: task.taskId,
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleTaskEditModal: TaskActions.toggleEditModal,
} );

export default connect( mapStateToProps, mapDispatchToProps )( RelatedTasksDashboard );
