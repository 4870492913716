import React, { useCallback, useState } from 'react';
import {
  Col, Row, Button, Collapse, Card, CardHeader, CardBody,
} from 'reactstrap';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';

import {
  Intl, Form, ShiftDeleteConfirmation, CanAccess,
} from 'components';
import ability from 'config/Ability';
import ScheduleForm from '../ScheduleForm';

export default React.memo( ( {
  data, collapsed = true, formId, onSubmit, onDelete,
} ) => {
  const [collapsedState, setCollapsed] = useState( collapsed );
  const [deleteModalOpened, setDeleteModalOpened] = useState( collapsed );

  const toggleDeleteModalOpened = useCallback( () => {
    setDeleteModalOpened( !deleteModalOpened );
  }, [deleteModalOpened] );

  return (
    <Card className="card-plain card-app-default card-sm mb-2">
      <CardHeader
        role="tab"
        onClick={() => setCollapsed( !collapsedState )}
        aria-expanded={!collapsedState}
      >
        <h5 className="mb-0 text-uppercase font-weight-normal">{data.name}</h5>
      </CardHeader>
      <Collapse role="tabpanel" isOpen={!collapsedState}>
        <CardBody>
          <Form
            initialValues={data}
            onSubmit={ability.can( 'edit_shift_template', 'team' ) ? onSubmit : () => {}}
            showErrorsInFooter
            mutators={{ ...arrayMutators }}
            body={( form ) => (
              <>
                <Row className="mb-3">
                  <Col className="text-right">
                    <span className="font-italic text-gray-2 text-sm">
                      <Intl id="startedOn" />
                      {' '}
                      {moment( data.created ).format( 'DD/MMM/YYYY HH:mm' )}
                    </span>
                  </Col>
                </Row>

                <ScheduleForm formId={formId} form={form} />
              </>
            )}
            footer={(
              <Row className="mt-2">
                <CanAccess I="delete" a="team">
                  <Col>
                    <Button
                      size="sm"
                      color="link"
                      className="text-red-1 pl-0"
                      type="button"
                      onClick={toggleDeleteModalOpened}
                    >
                      <i className="far fa-trash-alt mr-1" />
                      <Intl id="cancelShift" />
                    </Button>
                  </Col>
                </CanAccess>
                <CanAccess I="edit" a="team">
                  <Col className="text-right">
                    <Button size="app-sm" color="gray">
                      <Intl id="saveChanges" />
                    </Button>
                  </Col>
                </CanAccess>
              </Row>
            )}
          />
        </CardBody>
      </Collapse>

      <ShiftDeleteConfirmation
        open={deleteModalOpened}
        onSubmit={( values ) => onDelete( data.id, values, toggleDeleteModalOpened )}
        onClose={toggleDeleteModalOpened}
      />
    </Card>
  );
} );
