import React, { useRef, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import AccomodationOwnerService from 'api/AccomodationOwner';
import moment from 'moment';
import BookingsClientEditView from 'views/AccomodationOwner/Edit/bookings';
import { handlePromise } from 'utils';

const BookingsClientEdit = ( {
  match,
  toggleErrorAlert,
  userRole,
} ) => {
  const isOwner = userRole === 'ROLE_OWNER';
  const clientId = match.params.id;

  const filterFromDate = moment().startOf( 'day' );
  const filterToDate = moment( filterFromDate ).clone().add( 7, 'day' );
  const initialDates = useRef( moment.range( filterFromDate, filterToDate ) );
  const [isDownloading, setisDownloading] = useState( false );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( {
      page, elementsPerPage, from, till,
    } ) => AccomodationOwnerService.getBookingsOwner( clientId, {
      page,
      elementsPerPage,
      fromStart: from,
      fromEnd: till,
    } ),
    toggleErrorAlert,
  } );

  const openInNewTab = async ( url, id ) => {
    const link = document.createElement( 'a' );
    link.href = url;
    link.setAttribute(
      'download',
      `inv${id}.xlsx`,
    );
    document.body.appendChild( link );
    link.click();
    link.parentNode.removeChild( link );
  };

  const downloadRecord = useCallback(
    async ( params ) => {
      setisDownloading( true );
      // eslint-disable-next-line operator-linebreak
      const [errors, response, responseData] = await handlePromise(
        AccomodationOwnerService.downloadBookingsOwner( clientId, params ),
      );
      setisDownloading( false );
      if ( !response.ok ) return toggleErrorAlert( errors );
      await openInNewTab( responseData?.url || '', clientId );
    },
    [toggleErrorAlert, setisDownloading, clientId],
  );

  return (
    <BookingsClientEditView
      isOwner={isOwner}
      data={data}
      onFetchData={fetchData}
      onDownload={downloadRecord}
      isLoading={dataLoading || isDownloading}
      initialDates={initialDates.current}
      defaultFilters={{
        from: initialDates.current.start
          ? initialDates.current.start.format( 'YYYY-MM-DD' )
          : undefined,
        till: initialDates.current.end
          ? initialDates.current.end.format( 'YYYY-MM-DD' )
          : undefined,
      }}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  userRole: user.mainRole,
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( BookingsClientEdit );
