import React, { useContext } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import ContactNoteEditView from 'views/CRM/Contact/Edit/Notes/Edit';
import { useSubmit } from 'hooks';
import { createContactNote, updateContactNote } from 'api/CRM/Contact';
import { ContactEditContext } from '../../context';

const ContactNoteEdit = ( {
  user, contactNoteData, open, onClose, toggleLoading,
  toggleInfoAlert, onReloadData,
} ) => {
  const {
    contactId,
  } = useContext( ContactEditContext );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => {
      if ( contactNoteData ) {
        return updateContactNote( { ...dataToSend, id: contactNoteData?.id } );
      }
      return createContactNote( {
        ...dataToSend,
        userId: user?.user?.id,
        userName: user?.user?.user?.username,
        status: 'created',
        contactId,
      } );
    },

    toggleLoading,
    toggleInfoAlert,
    callback: ( ) => {
      onClose( );
      if ( onReloadData ) onReloadData();
    },
    deps: [contactNoteData, contactId, user, onReloadData],
  } );

  return (
    <ContactNoteEditView
      open={open}
      onClose={onClose}
      data={contactNoteData}
      onSubmit={onSubmit}
    />
  );
};

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( ContactNoteEdit );
