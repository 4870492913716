import React, { useEffect, useState } from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import classnames from 'classnames';

import {
  Select,
  Form,
  TextField,
  Intl,
  SimpleHeader,
  DetailCard,
  FormActionsBtns,
  RichTextField,
  TranslationLayout,
  EmailClipVariables,
  AsyncSelect,
} from 'components';
import { required } from 'config/InputErrors';
import _ from 'lodash';

const getNameFileFromURL = ( url = '' ) => {
  const parts = url.split( '/' );
  return parts[parts.length - 1];
};

export default React.memo(
  ( {
    entityId,
    data,
    isLoading,
    onSubmit,
    onChangeCountry,
    onChangeState,
    countries,
    isLoadingCountries,
    states,
    isLoadingStates,
    cities,
    isLoadingCities,
    parentPath,
    onFetchProperties,
  } ) => {
    const [showSelectStateAndCity, setShowSelectStateAndCity] = useState( !entityId );
    const showCityAsTextField = !showSelectStateAndCity && _.get( data, 'city.name' );

    const [labelTempFile, setLabelTempFile] = useState( '' );
    const labelContractFile = labelTempFile || getNameFileFromURL( _.get( data, 'file' ) ) || ' ';

    const [translabelTempFile, setTransLabelTempFile] = useState( '' );

    const [contractType, setContractType] = useState( 'standard' );
    useEffect( () => {
      if ( data.type ) {
        setContractType( data.type );
      }
    }, [data] );

    return (
      <>
        <SimpleHeader title={entityId ? 'editContract' : 'newContract'} />
        <Container
          fluid
          className={classnames( { 'app-component-loading': isLoading } )}
        >
          <Row>
            <Col lg={contractType === 'customized' ? 9 : 12}>
              <DetailCard>
                <Form
                  initialValues={{ type: 'standard', ...data }}
                  showErrorsInFooter
                  onSubmit={onSubmit}
                  body={( form ) => (
                    <TranslationLayout
                      translationFields={( locale ) => (
                        <>
                          <Row>
                            <Col md={8} lg={8}>
                              <TextField
                                field={`translations.${locale}.name`}
                                label="name"
                              />
                            </Col>
                          </Row>
                          {contractType === 'customized' && (
                          <Row>
                            <Col>
                              <RichTextField
                                field={`translations.${locale}.contractContent`}
                              />
                            </Col>
                          </Row>
                          )}
                          {contractType === 'standard' && (
                          <Row>
                            <Col md={8} lg={8}>
                              <TextField
                                label={translabelTempFile || getNameFileFromURL( _.get( data, `translations.${locale}.file` ) ) || ' '}
                                type="file"
                                labelClass="custom-file-label"
                                inputClass="custom-file-input"
                                formGroupClass="custom-file"
                                accept="application/pdf"
                                onChange={async ( val, e ) => {
                                  const file = e.currentTarget.files[0];
                                  if ( file ) {
                                    form.change( 'translationfile', { file, locale } );
                                    setTransLabelTempFile( _.get( file, 'name', 'contractFile' ) );
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                          )}
                        </>
                      )}
                    >
                      <Row>
                        <Col md={6} lg={3}>
                          <Select
                            field="type"
                            label="type"
                            options={[
                              { id: 'standard', name: 'standard' },
                              { id: 'customized', name: 'customized' },
                            ]}
                            translateOptions
                            onChange={setContractType}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8} lg={8}>
                          <TextField
                            field="name"
                            label="name"
                            validate={required}
                          />
                        </Col>
                      </Row>
                      {contractType === 'customized' && (
                        <Row>
                          <Col>
                            <RichTextField
                              field="contractContent"
                            />
                          </Col>
                        </Row>
                      )}
                      {contractType === 'standard' && (
                        <Row>
                          <Col md={8} lg={8}>
                            <TextField
                              label={labelContractFile}
                              type="file"
                              labelClass="custom-file-label"
                              inputClass="custom-file-input"
                              formGroupClass="custom-file"
                              accept="application/pdf"
                              onChange={async ( val, e ) => {
                                const file = e.currentTarget.files[0];
                                if ( file ) {
                                  form.change( 'file', file );
                                  setLabelTempFile( _.get( file, 'name', 'contractFile' ) );
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col className="mt-4">
                          <h4><Intl id="contractApplyTo" /></h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} lg={3}>
                          <Select
                            field="country"
                            label="country"
                            options={countries}
                            isLoading={isLoadingCountries}
                            onChange={( id ) => {
                              form.change( 'state', null );
                              form.change( 'city', null );
                              setShowSelectStateAndCity( true );
                              onChangeCountry( id );
                            }}
                          />
                        </Col>
                        {showSelectStateAndCity && (
                          <>
                            <Col md={6} lg={3}>
                              <Select
                                field="state"
                                label="state"
                                options={states}
                                isLoading={isLoadingStates}
                                onChange={( id ) => {
                                  form.change( 'city', null );
                                  onChangeState( id );
                                }}
                              />
                            </Col>
                            <Col md={6} lg={3}>
                              <Select
                                field="city"
                                label="city"
                                options={cities}
                                isLoading={isLoadingCities}
                              />
                            </Col>
                          </>
                        ) }
                        {showCityAsTextField && (
                          <Col md={6} lg={3}>
                            <TextField
                              label="city"
                              value={_.get( data, 'city.name' )}
                              disabled
                            />
                          </Col>
                        )}
                        <Col md={6} lg={3}>
                          <AsyncSelect
                            field="property"
                            label="property"
                            defaultOptions
                            loadOptions={onFetchProperties}
                          />
                        </Col>
                      </Row>
                      {contractType === 'standard' && (
                        <Row>
                          <Col md={4} lg={3}>
                            <TextField
                              field="signatureX"
                              label="signatureX"
                              validate={required}
                            />
                          </Col>
                          <Col md={4} lg={3}>
                            <TextField
                              field="signatureY"
                              label="signatureY"
                              validate={required}
                            />
                          </Col>
                        </Row>
                      )}
                    </TranslationLayout>

                  )}
                  footer={<FormActionsBtns cancelTo={parentPath} />}
                />
              </DetailCard>
            </Col>
            {contractType === 'customized' && (
              <Col lg={3} className="mt-4 mt-lg-0">
                <EmailClipVariables />
              </Col>
            )}
          </Row>
        </Container>
      </>
    );
  },
);
