import React from 'react';
import classnames from 'classnames';

import { ResourceEditForm } from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    entityId,
    isLoading,
    options,
    parentPath,
    isNomadsAdmin,
    onSubmit,
    onDelete,
  } ) => {
    const canEdit = canModify( 'resources' );

    return (
      <div
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <ResourceEditForm
          entityId={entityId}
          data={data}
          options={options}
          // title={entityId ? "editResource" : "newResource"}
          parentPath={parentPath}
          isNomadsAdmin={isNomadsAdmin}
          formEnabled={canEdit}
          onSubmit={canEdit ? onSubmit : () => {}}
          enableDelete={canEdit}
          onDelete={onDelete}
        />
      </div>
    );
  },
);
