import React, { useState, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
// import { handlePromise } from 'utils';
import { getContactNotes, deleteContactNote } from 'api/CRM/Contact';
import ContactNotesView from 'views/CRM/Contact/Edit/Notes';
import { ContactEditContext } from '../context';

const ContactNotes = ( {
  toggleLoading, toggleErrorAlert,
} ) => {
  const {
    contactId,
  } = useContext( ContactEditContext );

  const [contactNoteToEdit, setContactNoteToEdit] = useState( null );

  const [contactNotesEditModalOpened, setContactNotesEditModalOpened] = useState( false );

  const [
    onReloadContactNotes,
    setOnReloadContactNotes,
  ] = useState( new Date().valueOf() );
  const reloadContactNotes = useCallback( () => {
    setContactNoteToEdit( null );
    setOnReloadContactNotes( new Date().valueOf() );
  }, [] );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => {
      const s = params.keyword ? { text: params.keyword } : {};
      return getContactNotes( { ...params, ...s, contactId } );
    },
    toggleErrorAlert,
    conditional: !!contactId,
    deps: [contactId, onReloadContactNotes],
    // deps: [onReloadContacts],
  } );

  const onDeleteContactNote = useDelete( {
    promise: deleteContactNote,
    callback: () => {
      reloadContactNotes();
    },
    toggleLoading,
    toggleErrorAlert,
  } );
  //

  const toggleContactNotesEditModal = useCallback( ( ) => {
    setContactNotesEditModalOpened( ( v ) => !v );
  }, [] );
  const onEditContactNotes = useCallback( ( dataNote ) => {
    setContactNoteToEdit( dataNote );
    toggleContactNotesEditModal();
  }, [toggleContactNotesEditModal] );

  return (
    <ContactNotesView
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      onDeleteContactNotes={onDeleteContactNote}
      contactNotesEditModalOpened={contactNotesEditModalOpened}
      toggleContactNotesEditModal={toggleContactNotesEditModal}
      contactNoteToEdit={contactNoteToEdit}
      onEditContactNotes={onEditContactNotes}
      reloadContactNotes={reloadContactNotes}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( ContactNotes );
