import api from 'config/Api/Api';
import { localeData } from 'moment';

const baseEndpoint = '/amenities-record';

export default {
  getAmenityRecords( params ) {
    return api.get( baseEndpoint, params );
  },

  getAmenityRecord( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  getAmenityRecordTemplats( id, data ) {
    return api.get( `${baseEndpoint}/${id}/template`, data );
  },

  saveAmenityRecord( data ) {
    return api.post( baseEndpoint, data );
  },

  updateAmenityRecord( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  updateAmenityRecordTemplate( id, templateId, data ) {
    return api.post( `${baseEndpoint}/${id}/template/${templateId}`, data );
  },

  deleteAmenityRecord( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },

  downloadAmenityRecord( id ) {
    return api.get(
      `${baseEndpoint}/${id}/download?locale=${localeData()._abbr}`,
    );
  },
};
