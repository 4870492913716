import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AutogenerateTaskEditView from 'views/AutogenerateTask/Edit';
import TaskTypeService from 'api/TaskType';
import AutogenerateTaskService from 'api/AutogenerateTasks';
import ServiceService from 'api/Service';
import ServiceItemService from 'api/ServiceItem';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import { formatDataToSend, handlePromise } from 'utils';
import PluginService from 'api/Plugin';
import getExtraKeyText from 'views/Booking/Edit/MoreInfo/extrasMap';
import { useIntl } from 'react-intl';
import { ConfirmationModal } from 'components';

const AutogenerateTaskEdit = ( {
  propertyManager, taskId, modalOpened, onReload, onCloseModal,
  toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const intl = useIntl();
  const [targetActive, setTargetActive] = useState( null );
  const [openConfirm, setOpenConfirm] = useState( false );
  const [newTaskRuleId, setNewTaskRuleId] = useState( '' );
  const [forTheDayOf, setForTheDayOf] = useState( null );
  const [limitByLocation, setLimitByLocation] = useState( {
    cities: [],
    zones: [],
    properties: [],
    accommodations: [],
  } );

  const toggleConfirmModal = useCallback( () => {
    setOpenConfirm( ( state ) => !state );
  }, [] );

  const [whenActive, setWhenActive] = useState( null );
  const [services, setServices] = useState( null );

  const [isAvantioActive] = useFetch( {
    initialState: {},
    promise: () => PluginService.getPlugins( {
      installed: 'true',
    } ),
    format: ( dataToFormat ) => {
      const avantio = Object.values( _.get( dataToFormat, 'data', {} ) ).find( ( { key } ) => key === 'avantio' );
      return !!avantio;
    },
    toggleErrorAlert,
    reInit: true,
  } );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => AutogenerateTaskService.getTask( taskId ),
    format: async ( dataToFormat ) => {
      if ( !taskId ) return dataToFormat;

      const formattedData = { ...dataToFormat };
      setWhenActive( formattedData.executionPoint );
      setTargetActive( formattedData.target );
      if ( _.get( formattedData, 'taskType.id' ) ) {
        formattedData.taskType = {
          value: formattedData.taskType.id,
          label: formattedData.taskType.name,
        };
      }

      if ( formattedData.extra ) {
        formattedData.extra = {
          extraId: formattedData.extra,
          label: intl.formatMessage( { id: getExtraKeyText( formattedData.extra ) } ),
        };
      }

      if ( formattedData.targetReference ) {
        if ( formattedData.target !== 'bookedAccommodation' ) {
          const { data: _taskType } = await TaskTypeService.getTaskType(
            dataToFormat.targetReference,
          );
          formattedData.targetReference = {
            value: _taskType.id,
            label: _taskType.name,
          };
        }
        setForTheDayOf( formattedData.targetReference );
      }
      return formattedData;
    },
    toggleErrorAlert,
    conditional: !!taskId && modalOpened,
    deps: [taskId, modalOpened],
    reInit: true,
  } );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const fetchServices = useCallback( ( query ) => ServiceService.getServices( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const fetchServiceItems = useCallback( ( serviceId ) => ServiceItemService.getServiceItems(
    serviceId,
    {
      elementsPerPage: 1000,
    },
  ), [] );

  const fetchExtras = useCallback( ( ) => AutogenerateTaskService.getExtras(), [] );

  const changeTarget = ( event ) => {
    setTargetActive( event );
    setForTheDayOf( null );
    setServices( null );
  };

  const changeWhen = ( event ) => {
    setWhenActive( event );
  };

  const changeService = async ( service ) => {
    if ( service ) {
      const [errors, response, responseData] = await handlePromise(
        fetchServiceItems( service.value ),
      );

      if ( !response.ok ) return errors;

      const serviceItemsMap = _.map( responseData.data, ( item ) => ( {
        id: item.id,
        name: item.name,
      } ) );
      setServices( serviceItemsMap );
    }
  };

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );
    delete dataToSend.service;

    Object.keys( dataToSend ).forEach( ( a ) => {
      if ( a.indexOf( 'fieldCity' ) >= 0 || a.indexOf( 'fieldZone' ) >= 0 ) {
        delete dataToSend[a];
      }
    } );

    delete dataToSend.fieldProperties;
    delete dataToSend.fieldAccommodations;

    const dataFormated = {
      ...dataToSend,
      ...limitByLocation,
      extra: dataToSend.extra?.extraId || dataToSend.extra,
    };

    const [errors, response] = await handlePromise( taskId
      ? AutogenerateTaskService.updateTask( taskId, dataFormated )
      : AutogenerateTaskService.saveTask( dataFormated ) );

    toggleLoading( false );
    toggleConfirmModal();
    setNewTaskRuleId( response.data.id );
    if ( !response.ok ) return errors;
    if ( !taskId || !formData.profilePicture || _.isString( formData.profilePicture ) ) {
      onReload();
      return toggleInfoAlert( 'dataSaved' );
    }
  }, [taskId, toggleInfoAlert, toggleLoading, limitByLocation, onReload, toggleConfirmModal] );

  const syncTaskRule = async () => {
    toggleLoading( true );
    const [errors, response] = await handlePromise(
      AutogenerateTaskService.syncTasks( newTaskRuleId ),
    );

    if ( !response.ok ) {
      toggleConfirmModal();
      toggleLoading( false );
      toggleErrorAlert( errors );
      return errors;
    }

    toggleConfirmModal();
    toggleLoading( false );
    return toggleInfoAlert( 'dataSaved' );
  };

  return (
    <>
      <AutogenerateTaskEditView
        propertyManager={propertyManager}
        taskId={taskId}
        data={data}
        isLoading={dataLoading}
        open={modalOpened}
        onSubmit={submitForm}
        onClose={onCloseModal}
        onFetchServices={fetchServices}
        onFetchExtras={fetchExtras}
        taskTypes={taskTypes}
        isLoadingTaskTypes={loadingTaskTypes}
        onChangeTarget={changeTarget}
        targetActive={targetActive}
        onChangeWhen={changeWhen}
        whenActive={whenActive}
        onChangeService={changeService}
        services={services}
        isAvantioActive={isAvantioActive}
        onForTheDayOf={setForTheDayOf}
        forTheDayOf={forTheDayOf}
        setLimitByLocation={setLimitByLocation}
      />
      <ConfirmationModal
        title="autoGentask:sync"
        open={openConfirm}
        onConfirm={syncTaskRule}
        onClose={toggleConfirmModal}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( AutogenerateTaskEdit );
