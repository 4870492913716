import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Button,
} from 'reactstrap';
import classnames from 'classnames';
import {
  SimpleHeader, Select, DetailCard,
  Intl, Form, FormActionsBtns, TranslationLayout,
  TextField, PhoneList, ImagesDropzoneGeneric,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    data,
    entityId,
    parentPath,
    isLoading,
    onSubmit,
    countriesLoading,
    statesLoading,
    citiesLoading,
    options,
    onChangeCountry, onChangeState,
  } ) => {
    const [itemImageSrc, setItemImageSrc] = useState( null );
    const [changeImageOption, setChangeImageOption] = useState( false );

    useEffect( () => {
      if ( data && data.image ) {
        setItemImageSrc( data.image );
      }
    }, [data] );

    const [phoneData, setPhoneData] = useState( [] );

    return (
      <>
        <SimpleHeader parentLinks={[{ name: 'usefulInformation', href: parentPath }]} />
        <Container
          fluid
          className={classnames( {
            'app-component-loading': isLoading,
          } )}
        >
          <DetailCard
            classNames={{
              header: 'py-3',
            }}
            header={(
              <h3 className="m-0">
                <Intl id={entityId ? 'editUseful' : 'newUseful'} />
                {entityId ? ` ${entityId}` : '' }
              </h3>
 )}
          >
            <Form
              initialValues={data}
              showErrorsInFooter
              onSubmit={( d, f ) => {
                onSubmit( d, f, phoneData );
              }}
              body={( form ) => (
                <TranslationLayout
                  translationFields={( locale ) => (
                    <>
                      <Row>
                        <Col md={6}>
                          <Row>
                            <Col md={12}>
                              <TextField
                                field={`translations.${locale}.title`}
                                label="title"
                              />
                            </Col>
                            <Col md={12}>
                              <TextField
                                field={`translations.${locale}.text`}
                                label="description"
                                type="textarea"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                >
                  <Row>
                    <Col sm={2}>
                      <p className="mb-1 form-control-label font-weight-bold">
                        <Intl id="picture" />
                      </p>
                      {itemImageSrc && !changeImageOption && (
                      <Row>
                        <div className="pr-0 col-auto">
                          <div className="avatar avatar-xxl bg-transparent d-block">
                            <img src={itemImageSrc} alt="..." className="dz-preview-img" />
                          </div>
                          <Button
                            size="sm"
                            onClick={() => {
                              setChangeImageOption( true );
                            }}
                          >
                            <Intl id="changePicture" />
                          </Button>
                        </div>
                      </Row>
                      )}
                      {( !itemImageSrc || changeImageOption ) && (
                      <>
                        <div>
                          <ImagesDropzoneGeneric
                            form={form}
                          />
                        </div>
                        {itemImageSrc && (
                        <Button
                          size="sm"
                          onClick={() => {
                            setChangeImageOption( false );
                          }}
                        >
                          <Intl id="cancelChanges" />
                        </Button>
                        )}
                      </>
                      )}
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={8}>
                          <TextField
                            field="title"
                            label="title"
                            labelClass="font-weight-bold"
                            validate={entityId ? () => null : required}
                          />
                        </Col>
                        <Col md={12}>
                          <TextField
                            field="text"
                            label="description"
                            type="textarea"
                            labelClass="font-weight-bold"
                            validate={entityId ? () => null : required}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Select
                            field="country"
                            label="country"
                            isLoading={countriesLoading}
                            options={options.countries}
                            validate={entityId ? () => null : required}
                            onChange={( countryId ) => {
                              form.change( 'state', null );
                              form.change( 'city', null );
                              onChangeCountry( countryId );
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <Select
                            field="state"
                            label="state"
                            isLoading={statesLoading}
                            options={options.states}
                            validate={entityId ? () => null : required}
                            onChange={( stateId ) => {
                              form.change( 'city', null );
                              if ( form.getFieldState( 'country' ) ) {
                                const countryId = form.getFieldState( 'country' ).value.id
                                  ? form.getFieldState( 'country' ).value.id
                                  : form.getFieldState( 'country' ).value;
                                onChangeState( stateId, countryId );
                              }
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <Select
                            field="city"
                            label="city"
                            isLoading={citiesLoading}
                            options={options.cities}
                            validate={entityId ? () => null : required}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <PhoneList
                        field="phoneData"
                        label="phones"
                        value={data && data.phoneData ? data.phoneData : null}
                        onChange={setPhoneData}
                      />
                    </Col>
                  </Row>
                </TranslationLayout>
              )}
              footer={<FormActionsBtns cancelTo={parentPath} />}
            />
          </DetailCard>

        </Container>
      </>
    );
  },
);
