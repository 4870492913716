import React from 'react';

import {
  LocalTable, AddBtn, TableActionsBtns, Intl,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  data, name, isLoading, onOpenNewModal, onDelete,
} ) => (
  <LocalTable
    data={data}
    isLoading={isLoading}
    titleContent={(
      <>
        <h3 className="mb-1">
          <Intl id={canModify( 'accommodation' ) ? 'editAccommodation' : 'accommodation'} />
          {` ${name}`}
        </h3>
        <hr className="mt-3 mb-4" />
        <h3 className="m-0"><Intl id="communityRules" /></h3>
      </>
     )}
    rightContent={canModify( 'accommodation' ) && <AddBtn title="newCommunityRule" onClick={onOpenNewModal} />}
    containerClassName="card-sec-bottom"
    columns={[
      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'description',
        accessor: 'description',
      },
      {
        accessor: 'id',
        disableSorting: true,
        Header: null,
        maxWidth: 65,
        Cell: ( { cell } ) => (
          <TableActionsBtns
            actions={[
              {
                isDelete: true,
                onClick: ( cb ) => onDelete( cell.value, cb ),
              },
            ]}
          />
        ),
      },
    ]}
  />
) );
