import React from 'react';
import { Container } from 'reactstrap';

import {
  SimpleHeader,
  AsyncTable,
  AddBtn,
  TableActionsBtns,
  TableEditBtn,
  CanAccess,
} from 'components';
import { canEditViewDelete } from 'config/Ability';

export default React.memo(
  ( {
    data,
    isLoading,
    defaultFilters,
    onFetchData,
    onOpenEditModal,
    onDelete,
  } ) => {
    const { canView, canEdit, canDelete } = canEditViewDelete( 'amenity' );

    return (
      <>
        <SimpleHeader title="smartlockers" />
        <Container fluid>
          <AsyncTable
            id="smartlockers"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            disableSorting
            defaultFilters={defaultFilters}
            onFetchData={onFetchData}
            rightContent={(
              <CanAccess I="add" a="amenity">
                <AddBtn title="newProvider" onClick={onOpenEditModal} />
              </CanAccess>
            )}
            columns={[
              {
                Header: 'provider',
                accessor: 'provider',
                Cell: ( { cell } ) => ( canEdit || canView ? (
                  <TableEditBtn
                    title={cell.value}
                    onClick={() => onOpenEditModal( cell.row.original.id )}
                  />
                ) : (
                  cell.value
                ) ),
              },
              {
                Header: 'country',
                accessor: 'country.name',
              },
              {
                Header: 'city',
                accessor: 'city.name',
              },
              {
                Header: 'property',
                accessor: 'property.name',
              },
              {
                Header: 'server',
                accessor: 'serverAddress',
              },
              {
                Header: 'port',
                accessor: 'serverPort',
              },
              {
                Header: 'token',
                accessor: 'token',
              },
              canDelete
                ? {
                  accessor: 'id',
                  disableSorting: true,
                  Header: null,
                  maxWidth: 65,
                  Cell: ( { cell } ) => (
                    <TableActionsBtns
                      actions={[
                        {
                          isDelete: true,
                          onClick: ( cb ) => onDelete( cell.value, cb ),
                        },
                      ]}
                    />
                  ),
                }
                : {
                  id: 'id',
                  Header: null,
                  maxWidth: 1,
                  disableSorting: true,
                },
            ]}
          />
        </Container>
      </>
    );
  },
);
