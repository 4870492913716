import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const ADD_ACCOMMODATIONS_DISCOUNT = gql`
  mutation AddAccommodationsDiscount($input: AddAccommodationsDiscountInput!) {
    addAccommodationsDiscount(input: $input) {
      ... on BaseError {
        message
      }
      ... on MutationAddAccommodationsDiscountSuccess {
        data {
          success
        }
      }
    }
  }
`;

const addAccommodationsDiscount = async ( input ) => {
  const { data } = await client.query( {
    query: ADD_ACCOMMODATIONS_DISCOUNT,
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.addAccommodationsDiscount.message ) {
    return {
      ok: false,
      message: data.addAccommodationsDiscount.message,
    };
  }
  const createSuccessful = data.addAccommodationsDiscount.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
  };
};

export default addAccommodationsDiscount;
