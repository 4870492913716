import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import ContactNewView from 'views/CRM/Contact/New';
import { useSubmit } from 'hooks';
import { createContact } from 'api/CRM/Contact';

const ContactNew = ( {
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert, modalNewContactOpened, toggleModalNewContact, reloadContacts,
} ) => {
  const onSubmit = useSubmit( {
    promise: createContact,
    toggleErrorAlert,
    toggleLoading,
    toggleInfoAlert,
    callback: ( ) => {
      toggleModalNewContact();
      reloadContacts();
    },
  } );

  return (
    <ContactNewView
      modalNewContactOpened={modalNewContactOpened}
      toggleModalNewContact={toggleModalNewContact}
      isLoading={false}
      onSubmit={onSubmit}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( ContactNew );
