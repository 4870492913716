import React from 'react';
import { Row, Col } from 'reactstrap';
import { ModalForm, TextField, Datepicker } from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  id, data, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    initialValues={data}
    title={id ? 'editExpense' : 'newExpense'}
    showErrorsInFooter
    isLoading={isLoading}
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
    bodyClassName="overflow-visible"
    body={() => (
      <>
        <Row>
          <Col md={6}>
            <TextField
              field="name"
              label="name"
              validate={required}
            />
          </Col>
          <Col md={6}>
            <Datepicker
              field="date"
              label="date"
              dateFormat="DD/MM/YYYY"
              utc
              validate={required}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextField
              field="description"
              label="description"
              type="textarea"
              rows={2}
              validate={required}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <TextField
              field="cost"
              label="cost"
              type="number"
              validate={required}
            />
          </Col>
        </Row>
      </>
    )}
  />
) );
