import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import PropertyManagerService from 'api/PropertyManager';
import PropertyManagerGeneralView from 'views/PropertyManager/Edit/General';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import { formatDataPhoneToSend, formatDataPhone } from 'utils';
import { roles } from 'config/Constants';

const PropertyManagerGeneral = ( {
  userRole, propertyManager, parentPath, history, parentId, pathLayout, location,
  toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const pmId = userRole === roles.nomadsAdmin.id || userRole === roles.nomadsAdminReadOnly.id
    ? parentId : propertyManager.id;

  const [pmData, pmDataLoading] = useFetch( {
    initialState: {},
    promise: () => PropertyManagerService.getPropertyManager( pmId ),
    toggleErrorAlert,
    conditional: !!pmId,
    deps: [pmId],
  } );

  const submitForm = useSubmit( {
    promise: ( data ) => PropertyManagerService.updatePropertyManager( pmData.id, data ),
    format: ( data ) => {
      const dataToSend = formatDataPhoneToSend( data, ['phone', 'contactPerson.phone'] );
      dataToSend.keyword = undefined;
      return dataToSend;
    },
    deps: [pmData.id],
    toggleLoading,
    toggleInfoAlert,
    callback: () => history.push( parentPath ),
  } );

  const formattedData = formatDataPhone( pmData, ['phone', 'contactPerson.phone'] );

  return (
    <PropertyManagerGeneralView
      userRole={userRole}
      data={formattedData}
      pathname={location.pathname}
      parentPath={parentPath}
      pathLayout={pathLayout}
      pmData={pmData}
      isLoading={pmDataLoading}
      onSubmit={submitForm}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( PropertyManagerGeneral );
