import React, { useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import BookedServicesDashboardView from 'views/BookedServices/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import { useFetchTableData } from 'hooks';
import BookedServiceService from 'api/BookedService';

const BookedServicesDashboard = ( {
  defaultFilters, match, updateFilters, setFiltersError, toggleErrorAlert, userCurrency,
} ) => {
  const today = moment().startOf( 'day' );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => BookedServiceService.getBookedServices( {
      ...params,
      from: params.from ? params.from : today.clone().format( 'YYYY-MM-DD' ),
      till: params.till ? params.till : today.clone().add( 1, 'month' ).format( 'YYYY-MM-DD' ),
    } ),
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters(
        'bookedServices', {
          status: params.status,
          from: params.from,
          till: params.till,
        },
      );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'bookedServices' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
  } );

  return (
    <BookedServicesDashboardView
      data={data}
      parentPath={match.url}
      isLoading={dataLoading}
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
      userCurrency={userCurrency}
    />
  );
};

const mapStateToProps = ( { filters, user } ) => ( {
  defaultFilters: _.get( filters, 'filters.bookedServices' ) || {},
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( BookedServicesDashboard );
