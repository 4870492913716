import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import ItemEditView from 'views/BookedServices/ItemEdit';
import BookedServiceItemsService from 'api/BookedServiceItem';
import ServiceItemService from 'api/ServiceItem';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import {
  formatDataToSend, formatFloat, formatFloatToSend, handlePromise,
} from 'utils';

const ItemEdit = ( {
  bookedServiceId, itemId, modalOpened, onReload, onCloseModal,
  toggleErrorAlert, toggleInfoAlert, toggleLoading, serviceId,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => BookedServiceItemsService.getBookedServiceItem( bookedServiceId, itemId ),
    format: ( toFormat ) => {
      const formattedData = { ...toFormat };
      formattedData.price = formatFloat( formattedData.price );
      formattedData.amount = formatFloat( formattedData.amount );

      return formattedData;
    },
    toggleErrorAlert,
    conditional: !!itemId && modalOpened,
    deps: [itemId, modalOpened],
    reInit: true,
  } );

  const [serviceItem, serviceItemLoading] = useFetch( {
    initialState: { data: [] },
    promise: () => ServiceItemService.getServiceItems(
      serviceId, { elementsPerPage: 1000 },
    ),
    toggleErrorAlert,
    conditional: !!modalOpened,
    deps: [modalOpened],
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );
    dataToSend.price = formatFloatToSend( dataToSend.price );
    dataToSend.amount = formatFloatToSend( dataToSend.amount );
    dataToSend.unit = 'unit';

    const [errors, response] = await handlePromise(
      itemId
        ? BookedServiceItemsService.updateBookedServiceItem( bookedServiceId, itemId, dataToSend )
        : BookedServiceItemsService.saveBookedServiceItem( bookedServiceId, dataToSend ),
    );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }
    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
    return onReload();
  }, [itemId, bookedServiceId, onReload, toggleInfoAlert, toggleLoading] );

  return (
    <ItemEditView
      itemId={itemId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
      serviceItem={serviceItem}
      serviceItemLoading={serviceItemLoading}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ItemEdit );
