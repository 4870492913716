import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  AsyncTable,
  AddBtn,
  TableActionsBtns,
  CanAccess,
  Intl,
} from 'components';
import { canView, canModify } from 'config/Ability';

// TODO: change ACLS

const Dashboard = React.memo(
  ( {
    data,
    isLoading,
    parentPath,
    onFetchData,
    onDelete,
  } ) => {
    const canViewProp = canView( 'communications' );
    const canEditDelete = canModify( 'communications' );
    return (
      <AsyncTable
        id="predefinedNotifications"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        rightContent={(
          <CanAccess I="add" a="communications">
            <AddBtn title="newNotification" route={`${parentPath}/new`} />
          </CanAccess>
            )}
        onFetchData={onFetchData}
        columns={[
          {
            Header: 'title',
            accessor: 'title',
            Cell: ( { cell } ) => ( canEditDelete || canViewProp ? (
              <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                {cell.value}
              </Link>
            ) : (
              cell.value
            ) ),
          },
          {
            Header: 'template',
            accessor: 'communicationTemplate',
            Cell: ( { cell } ) => _.get( cell, 'value.name' ),
          },
          {
            Header: 'reminder',
            accessor: 'reminder',
            Cell: ( { cell } ) => {
              const reminder = _.get( cell, 'value', [] );
              return (
                <>
                  {reminder.sort( ( a, b ) => a - b ).join( ', ' )}
                  {' '}
                  <Intl id="days" />
                </>
              );
            },
          },
          {
            Header: 'executionPoint',
            Cell: ( { cell } ) => {
              const rowValues = _.get( cell, 'row.original', {} );
              return (
                <>
                  <Intl id={`${rowValues.executionPoint}`} />
                  {' '}
                  <Intl id={`${rowValues.process}`} />
                </>
              );
            },
          },
          {
            Header: 'rules',
            accessor: 'extraRules',
            Cell: ( { cell } ) => {
              const extraRules = _.get( cell, 'value', [] );
              return _.map( extraRules, ( { rule, days, bookingType = {} }, id ) => ( days ? (
                <div key={id}>
                  {`${id} `}
                  <Intl id={rule} />
                  {` ${days} `}
                  <Intl id="days" />
                </div>
              ) : (
                <div key={id}>
                  {/* {`${id} `} */}
                  <Intl id={rule} />
                  {bookingType.name && ` - ${bookingType.name}`}
                </div>
              ) ) );
            },
          },
          {
            Header: 'restrictions',
            Cell: ( { cell } ) => {
              const rowValues = _.get( cell, 'row.original', {} );
              const {
                cities = [],
                zones = [],
                properties = [],
                accommodations = [],
              } = rowValues;
              const totalRestrictions = cities.length
                    + zones.length
                    + properties.length
                    + accommodations.length;
              if ( totalRestrictions === 0 ) return <Intl id="noRestrictions" />;
              return (
                <>
                  {cities.length > 0 && (
                  <div>
                    <Intl id="cities" />
                    {': '}
                    {_.map( cities, ( { name } ) => name ).join()}
                  </div>
                  )}
                  {zones.length > 0 && (
                  <div>
                    <Intl id="zones" />
                    {': '}
                    {_.map( zones, ( { name } ) => name ).join()}
                  </div>
                  )}
                  {properties.length > 0 && (
                  <div>
                    <Intl id="properties" />
                    {': '}
                    {_.map( properties, ( { name } ) => name ).join()}
                  </div>
                  )}
                  {accommodations.length > 0 && (
                  <div>
                    <Intl id="accommodations" />
                    {': '}
                    {_.map( accommodations, ( { name } ) => name ).join()}
                  </div>
                  )}
                </>
              );
            },
          },
          canEditDelete
            ? {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => (
                <TableActionsBtns
                  actions={[
                    {
                      isDelete: true,
                      onClick: ( cb ) => onDelete( cell.value, cb ),
                    },
                  ]}
                />
              ),
            }
            : {
              id: 'id',
              Header: null,
              maxWidth: 1,
              disableSorting: true,
            },
        ]}
      />
    );
  },
);

export default Dashboard;
