import React from 'react';
import { Row, Col } from 'reactstrap';
import { TextField, ModalForm, Select } from 'components';

import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    travelReportId,
    data,
    isLoading,
    open,
    onClose,
    onSubmit,
    onChangeCountry,
    onChangeState,
    countries,
    isLoadingCountries,
    states,
    isLoadingStates,
    cities,
    isLoadingCities,
  } ) => (
    <ModalForm
      initialValues={data}
      title={travelReportId ? 'editTravelReport' : 'newTravelReport'}
      showErrorsInFooter
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      body={( form ) => (
        <>
          <Row>
            <Col md={4}>
              <Select
                field="country"
                label="country"
                options={countries}
                isLoading={isLoadingCountries}
                onChange={( countryId ) => {
                  form.change( 'state', null );
                  form.change( 'city', null );
                  onChangeCountry( countryId );
                }}
              />
            </Col>
            <Col md={4}>
              <Select
                field="state"
                label="state"
                options={states}
                isLoading={isLoadingStates}
                onChange={( stateId ) => {
                  form.change( 'city', null );
                  if ( form.getFieldState( 'country' ) ) {
                    onChangeState( stateId, form.getFieldState( 'country' ).value );
                  }
                }}
              />
            </Col>
            <Col md={4}>
              <Select
                field="city"
                label="city"
                options={cities}
                isLoading={isLoadingCities}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Select
                field="type"
                label="type"
                options={[
                  {
                    id: 'esPoliciaNacional',
                    name: 'PoliciaNacional',
                  },
                  {
                    id: 'esGuardiaCivil',
                    name: 'GuardiaCivil',
                  },
                  {
                    id: 'esMozosEscuadra',
                    name: 'MozosEscuadra',
                  },
                  {
                    id: 'esErtzainta',
                    name: 'Ertzainta',
                  },
                ]}
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextField
                field="username"
                label="username"
                validate={required}
              />
            </Col>
            <Col md={6}>
              <TextField
                field="password"
                label="password"
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextField
                field="propertyCode"
                label="propertyCode"
                validate={required}
              />
            </Col>
            <Col md={6}>
              <TextField
                field="propertyName"
                label="propertyName"
                validate={required}
              />
            </Col>
            <Col md={12}>
              <TextField
                field="reportFileName"
                label="reportFileName"
                validate={required}
              />
            </Col>
          </Row>
        </>
      )}
    />
  ),
);
