/* eslint-disable operator-linebreak */
import React from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import {
  Datepicker,
  DateMultiPicker,
  ModalForm,
  Select,
  TextField,
} from 'components';
import { required } from 'config/InputErrors';
import moment from 'moment';
import {
  daysAllowedWeekOptions, typeOptionweekDays, enableCheckInOutOptions, typeOptionDates, typeOptions,
} from './constants';

export default React.memo(
  ( {
    data,
    fixDates,
    open,
    onClose,
    onSubmit,
    isLoading,
  } ) => {
    const initialData = data ? {
      ...data,
      startDate: moment.utc( _.get( data, 'startDate', new Date() ) ),
      lastNight: moment.utc( _.get( data, 'lastNight', new Date() ) ),
    } : undefined;

    return (
      <ModalForm
        initialValues={initialData}
        title={!_.isEmpty( data ? data.id : data ) ? 'editSeason' : 'newSeason'}
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        bodyClassName="overflow-visible"
        zIndex={9999}
        body={( form ) => {
          const showCheckInDays = form.getFieldState( 'checkIn' ) && enableCheckInOutOptions.find(
            ( item ) => item === form.getFieldState( 'checkIn' ).value,
          );
          const typeCheckInFieldValue = form.getFieldState( 'checkIn' ) && typeOptions.find(
            ( item ) => item.id === form.getFieldState( 'checkIn' ).value,
          );
          const showCheckOutDays = form.getFieldState( 'checkOut' ) && enableCheckInOutOptions.find(
            ( item ) => item === form.getFieldState( 'checkOut' ).value,
          );
          const typeCheckOutFieldValue = form.getFieldState( 'checkOut' ) && typeOptions.find(
            ( item ) => item.id === form.getFieldState( 'checkOut' ).value,
          );
          const startDateLimit = form.getFieldState( 'startDate' ) && new Date(
            moment( form.getFieldState( 'startDate' ).value ).add( 1, 'day' ),
          );
          const endDateLimit = form.getFieldState( 'lastNight' ) && new Date(
            moment( form.getFieldState( 'lastNight' ).value ).add( 1, 'day' ),
          );
          return (
            <>
              <Row>
                <Col md={6}>
                  <Datepicker
                    field="startDate"
                    label="from"
                    dateFormat="DD/MM/YYYY"
                    disabled={fixDates}
                    utc
                    labelClass="font-weight-bold"
                    validate={required}
                    startDateField={{ value: moment().subtract( '1', 'day' ) }}
                  />
                </Col>
                <Col md={6}>
                  <Datepicker
                    field="lastNight"
                    label="till"
                    dateFormat="DD/MM/YYYY"
                    disabled={fixDates}
                    utc
                    labelClass="font-weight-bold"
                    validate={required}
                    startDateField={{ value: moment().subtract( '1', 'day' ) }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    field="lengthOfTheStay"
                    label="lengthOfTheStay"
                    type="number"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Select
                    overlap
                    field="checkIn"
                    label="checkIn"
                    isClearable
                    labelClass="font-weight-bold mr-2"
                    translateOptions
                    options={typeOptions}
                    validate={required}
                    onChange={() => {
                      form.change( 'checkInDays', null );
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Select
                    field="checkOut"
                    label="checkOut"
                    isClearable
                    labelClass="font-weight-bold mr-2"
                    translateOptions
                    options={typeOptions}
                    validate={required}
                    onChange={() => {
                      form.change( 'checkOutDays', null );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                {showCheckInDays && (
                  <Col md={6}>
                    {typeOptionDates[typeCheckInFieldValue.id] && (

                    <DateMultiPicker
                      minDate={startDateLimit}
                      maxDate={endDateLimit}
                      field={
                        `checkInDays.[${typeCheckInFieldValue.name}]`
                      }
                      label={`${typeOptionDates[typeCheckInFieldValue.id]}`}
                      dateFormat="DD/MM/YYYY"
                      disabled={fixDates}
                      utc
                      labelClass="font-weight-bold"
                      validate={required}
                      startDateField={{ value: moment().subtract( '1', 'day' ) }}
                    />

                    )}
                    {typeOptionweekDays[typeCheckInFieldValue.id] && (
                    <Select
                      field={`checkInDays.[${typeCheckInFieldValue.name}]`}
                      label="daysAllowedWeek"
                      isClearable
                      labelClass="font-weight-bold mr-2"
                      translateOptions
                      multiple
                      options={daysAllowedWeekOptions}
                      validate={required}
                    />
                    )}
                  </Col>
                )}
                {showCheckOutDays && (
                  <Col md={6}>
                    {typeOptionDates[typeCheckOutFieldValue.id] && (
                    <DateMultiPicker
                      minDate={startDateLimit}
                      maxDate={endDateLimit}
                      field={`checkOutDays.[${typeCheckOutFieldValue.name}]`}
                      label={`${typeOptionDates[typeCheckOutFieldValue.id]}`}
                      dateFormat="DD"
                      disabled={fixDates}
                      utc
                      inline
                      multidate
                      labelClass="font-weight-bold"
                      validate={required}
                      startDateField={{ value: moment().subtract( '1', 'day' ) }}
                    />
                    )}
                    {typeOptionweekDays[typeCheckOutFieldValue.id] && (
                    <Select
                      field={`checkOutDays.[${typeCheckOutFieldValue.name}]`}
                      label="daysAllowedWeek"
                      isClearable
                      labelClass="font-weight-bold mr-2"
                      translateOptions
                      multiple
                      options={daysAllowedWeekOptions}
                      validate={required}
                    />
                    )}
                  </Col>
                )}
              </Row>

            </>
          );
        }}
      />
    );
  },
);
