import React, { useState } from 'react';
import { Button } from 'reactstrap';
import DateRangePicker from 'react-daterange-picker';
import originalMoment from 'moment';
import classnames from 'classnames';
import { extendMoment } from 'moment-range';
import 'react-daterange-picker/dist/css/react-calendar.css';

import { TextField } from 'components';

const moment = extendMoment( originalMoment );

export default React.memo( ( {
  initialValue, position = 'left', input, label, size = 'md', initialValueSet = true,
  clearable = false, onChange, sideBarOptions = [], style,
} ) => {
  const today = moment().startOf( 'day' );
  const [dates, setDates] = useState(
    initialValueSet ? initialValue || moment.range( today.clone(), today.clone() ) : null,
  );
  const [opened, setOpened] = useState( false );

  return (
    <>
      {input ? (
        <TextField
          label={label}
          size={size}
          value={dates ? `${dates.start.format( 'ddd, D MMM YYYY' )} - ${dates.end.format( 'ddd, D MMM YYYY' )}` : null}
          onFocus={() => setOpened( !opened )}
          onChange={clearable ? () => {
            onChange( null );
            setDates( null );
          } : () => {}}
          formGroupClass="m-0"
          appendIcon={<i className="th-icon-calendar" />}
        />
      ) : (
        <Button onClick={() => setOpened( !opened )} className="mr-0 btn-daterange" style={style}>
          {dates ? `${dates.start.format( 'ddd, D MMM YYYY' )} - ${dates.end.format( 'ddd, D MMM YYYY' )}` : 'dates'}
          <i className="th-icon-calendar" />
        </Button>
      )}
      {opened
        ? (
          <div className={classnames( 'daterange-picker', {
            left: position === 'left',
            right: position === 'right',
          } )}
          >
            <div className="daterange-picker-overlay" onClick={() => setOpened( !opened )} />
            <div className="d-flex">
              {sideBarOptions.length > 0 && (
                <div className="position-relative daterange-picker-options">
                  <ul>
                    {sideBarOptions.map( ( { id, text, onClick } ) => (
                      <li key={id}>
                        <div type="button" onClick={onClick}>
                          {text}
                        </div>
                      </li>
                    ) )}
                  </ul>
                </div>
              )}
              <div
                className="container-calendar"
              >
                <DateRangePicker
                  style={style}
                  onSelect={( value ) => {
                    setDates( value );
                    onChange( value );
                  }}
                  value={dates}
                  numberOfCalendars={2}
                  locale={moment().locale()}
                  singleDateRange
                />
              </div>
            </div>
          </div>
        ) : null }
    </>
  );
} );
