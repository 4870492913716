import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AddParticipantView from 'views/Support/AddParticipant';
import SettingsActions from 'store/reducers/Settings';
import EmployeeService from 'api/Employee';
import ChannelService from 'api/Channel';
import { useSubmit } from 'hooks';

const AddParticipant = ( {
  selectedChannelId, participantType, propertyManager, modalOpened,
  onCloseModal, onReload, toggleLoading, toggleInfoAlert,
} ) => {
  const fetchEmployees = useCallback( ( query ) => EmployeeService.getEmployees( {
    propertyManager: propertyManager.id,
    elementsPerPage: 10,
    keyword: query,
    employee: true,
  } ), [propertyManager] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( participantType === 'admin'
      ? ChannelService.addAdmin( selectedChannelId, dataToSend.participant )
      : ChannelService.addParticipant( selectedChannelId, dataToSend.participant ) ),
    toggleLoading,
    toggleInfoAlert,
    deps: [selectedChannelId, participantType, onReload],
    callback: onReload,
  } );

  return (
    <AddParticipantView
      participantType={participantType}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
      onFetchEmployees={fetchEmployees}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( AddParticipant );
