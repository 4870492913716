import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as Settings } from './Settings';
import { reducer as User } from './User';
import { reducer as Messenger } from './Messenger';
import { reducer as Task } from './Task';
import { reducer as Incidence } from './Incidence';
import { reducer as TaskPeriodic } from './TaskPeriodic';
import { reducer as Booking } from './Booking';
import { reducer as Filters } from './Filters';

export default ( history ) => combineReducers( {
  router: connectRouter( history ),
  settings: Settings,
  user: User,
  messenger: Messenger,
  task: Task,
  incidence: Incidence,
  taskPeriodic: TaskPeriodic,
  booking: Booking,
  filters: Filters,
} );
