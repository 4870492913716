import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const DELETE_SEASON_DISCOUNT = gql`
  mutation DeleteSeasonDiscount($seasonDiscountId: ID!) {
    deleteSeasonDiscount(seasonDiscountId: $seasonDiscountId) {
      ... on BaseError {
        message
      }
      ... on MutationDeleteSeasonDiscountSuccess {
        data {
          success
        }
      }
    }
  }
`;

const deleteSeasonDiscount = async ( seasonDiscountId ) => {
  const { data } = await client.query( {
    query: DELETE_SEASON_DISCOUNT,
    variables: {
      seasonDiscountId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteSeasonDiscount.message ) {
    return {
      ok: false,
      message: data.deleteSeasonDiscount.message,
    };
  }
  const deleteSuccessful = data.deleteSeasonDiscount.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteSeasonDiscount;
