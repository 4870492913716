import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AccomodationOwnerConfigurationView from 'views/AccomodationOwner/Edit/configuration';
import SettingsActions from 'store/reducers/Settings';
import RoleService from 'api/Role';
import AccomodationOwnerService from 'api/AccomodationOwner';
import PropertyManagerService from 'api/PropertyManager';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import AccommodationService from 'api/Accommodation';
import CustomerService from 'api/Customer';
import useFetch from 'hooks/useFetch';
import { roles } from 'config/Constants';
import {
  formatDataToSend,
  handlePromise,
  formatArrayToSelectValues,
} from 'utils';
import useDelete from 'hooks/useDelete';
import { ConfirmationModal } from 'components';
import { waterfall } from 'utils/async';

const AccomodationOwnerConfiguration = ( {
  parentPath,
  history,
  match,
  userRole,
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
} ) => {
  const entityId = match.params.id;
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( true );

  const [openModalDisable, setOpenModalDisable] = useState( false );
  const toggleModalDisable = () => setOpenModalDisable( ( state ) => !state );

  const [openModalDelete, setOpenModalDelete] = useState( false );
  const toggleModalDelete = () => setOpenModalDelete( ( state ) => !state );

  const getEntityData = useCallback(
    async ( cb ) => {
      if ( !entityId ) return cb( null, {} );
      const [errors, response, responseData] = await handlePromise(
        AccomodationOwnerService.getAccomodationOwner( entityId ),
      );

      if ( !response.ok ) return cb( errors );
      return cb( null, responseData );
    },
    [entityId],
  );

  useEffect( () => {
    setDataLoading( true );

    waterfall( [getEntityData], ( errors, results ) => {
      setDataLoading( false );
      if ( errors ) {
        return toggleErrorAlert( errors );
      }
      const formattedData = { ...results };
      if ( formattedData.accommodations ) {
        formattedData.accommodations = formatArrayToSelectValues(
          formattedData.accommodations,
        );
      }
      if ( formattedData.customers ) {
        formattedData.customers = formattedData.customers.map( ( cust ) => ( {
          value: cust.id,
          label: `${cust.firstName} ${cust.lastName}`,
        } ) );
      }
      setData( formattedData );
    } );
  }, [entityId, toggleErrorAlert, getEntityData] );

  useEffect( () => {
    const getOptions = async () => {
      const [errors, response] = await handlePromise(
        PropertyManagerService.getPropertyManagers(),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
    };
    if ( !entityId && userRole === roles.nomadsAdmin.id ) getOptions();
  }, [entityId, userRole, toggleErrorAlert] );

  useEffect( () => {
    const getRoles = async () => {
      const [errors, response] = await handlePromise(
        RoleService.getRoles( {
          elementsPerPage: 1000,
        } ),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
    };
    if ( userRole === roles.nomadsAdmin.id || userRole === roles.admin.id ) { getRoles(); }
  }, [entityId, userRole, toggleErrorAlert] );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const dataFormatted = formatDataToSend( formData, form );
      const dataToSend = {
        ...dataFormatted,
        customers: dataFormatted.customers || [],
        accommodations: dataFormatted.accommodations || [],
        activeNotifications: dataFormatted.activeNotifications
          ? 'true'
          : 'false',
        autoReport: dataFormatted.autoReport
          ? 'true'
          : 'false',
        legalPerson: dataFormatted.legalPerson,
        allowIssuingInvoices: dataFormatted.allowIssuingInvoices
          ? 'true'
          : 'false',
      };
      delete dataToSend.state;

      const [errors, response] = await handlePromise(
        entityId
          ? AccomodationOwnerService.updateAccomodationOwner(
            entityId,
            dataToSend,
          )
          : AccomodationOwnerService.saveAccomodationOwner( dataToSend ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      history.push( parentPath );
    },
    [entityId, history, parentPath, toggleInfoAlert, toggleLoading],
  );

  const deleteResource = useDelete( {
    promise: () => AccomodationOwnerService.deleteAccomodationOwner( entityId ),
    callback: ( id, response ) => {
      if ( response.data.deleted ) {
        history.push( parentPath );
      } else {
        toggleModalDisable();
      }
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const disableResource = useDelete( {
    promise: () => AccomodationOwnerService.deleteAccomodationOwner( entityId ),
    callback: () => history.push( parentPath ),
    toggleLoading,
    toggleErrorAlert,
  } );

  /** Properties ******************* */

  const fetchAccommodations = useCallback(
    () => AccommodationService.getAllAccommodations( {
      elementsPerPage: 999,
    } ),
    [],
  );
  /** Property Users ******************* */

  const fetchPropertyUsers = useCallback(
    () => CustomerService.getCustomers( {
      elementsPerPage: 999,
      roles: ['ROLE_OWNER'],
    } ),
    [],
  );

  /** countries, states, cities ******************* */
  const [states, setStates] = useState( [] );
  const [statesLoading, setStatesLoading] = useState( false );
  const [cities, setCities] = useState( [] );
  const [citiesLoading, setCitiesLoading] = useState( false );
  const [statesLoaded, setStatesLoaded] = useState( false );
  const [citiesLoaded, setCitiesLoaded] = useState( false );

  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    reInit: true,
    deps: [],
  } );
  const fetchStates = useCallback(
    async ( countryId ) => {
      const country = _.find( countries, { id: countryId } );
      if ( !country ) return toggleErrorAlert( 'error' );

      setStates( [] );
      setCities( [] );
      setStatesLoading( true );
      const [errors, response, responseData] = await handlePromise(
        StateService.getStates( country.iso ),
      );
      setStatesLoading( false );

      if ( !response.ok ) return toggleErrorAlert( errors );
      setStates( responseData );
      setCities( [] );
    },
    [countries, toggleErrorAlert],
  );

  const fetchCities = useCallback(
    async ( stateId, countryId ) => {
      const country = _.find( countries, { id: countryId } );
      const state = _.find( states, { id: stateId } );

      if ( !country || !state ) return toggleErrorAlert( 'error' );

      setCities( [] );
      setCitiesLoading( true );
      const [errors, response, responseData] = await handlePromise(
        CityService.getCities( country.iso, state.iso ),
      );
      setCitiesLoading( false );

      if ( !response.ok ) return toggleErrorAlert( errors );
      setCities( responseData );
    },
    [countries, states, toggleErrorAlert],
  );

  useEffect( () => {
    if ( data && data.country && countries.length > 1 && !statesLoaded ) {
      setStatesLoaded( true );
      fetchStates( data.country.id );
    }
  }, [data, countries, fetchStates, statesLoaded] );

  useEffect( () => {
    if (
      data
      && data.state
      && data.country
      && countries.length > 1
      && states.length > 1
      && !citiesLoaded
    ) {
      setCitiesLoaded( true );
      fetchCities( data.state.id, data.country.id );
    }
  }, [data, countries, states, fetchCities, citiesLoaded] );

  return (
    <>
      <AccomodationOwnerConfigurationView
        data={data}
        isNomadsAdmin={userRole === roles.nomadsAdmin.id}
        parentPath={parentPath}
        isLoading={dataLoading}
        entityId={entityId}
        onSubmit={submitForm}
        onDelete={toggleModalDelete}
        countriesLoading={countriesLoading}
        statesLoading={statesLoading}
        citiesLoading={citiesLoading}
        options={{ countries, states, cities }}
        onChangeCountry={fetchStates}
        onChangeState={fetchCities}
        onFetchAccommodations={fetchAccommodations}
        onFetchPropertyUsers={fetchPropertyUsers}
      />
      <ConfirmationModal
        open={openModalDelete}
        title={openModalDisable ? 'disableModal.confirmation' : ''}
        onConfirm={openModalDisable ? disableResource : deleteResource}
        onClose={toggleModalDelete}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( AccomodationOwnerConfiguration );
