/* eslint-disable implicit-arrow-linebreak */
import React /* , { useEffect, useState } */ from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';

import {
  TextField,
  Select,
  ModalForm,
  AsyncSelect,
  Datepicker,
} from 'components';
import { required } from 'config/InputErrors';
// import EmployeeService from 'api/Employee';
import { taskPriorities } from 'config/Constants';

export default React.memo(
  ( {
    // customFields,
    isLoading,
    preloadedData,
    open,
    onClose,
    onSubmit,
    onFetchAccommodations,
    taskTypes,
    isLoadingTaskTypes,
    zIndex,
  } ) =>
    (
      <ModalForm
        title="newIncidence"
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        size="xl"
        bodyClassName="overflow-visible"
        bodyStyle={{ minHeight: 330 }}
        onSubmit={onSubmit}
        onClose={onClose}
        initialValues={preloadedData}
        zIndex={zIndex}
        body={() => (
          <>
            <Row>
              <Col lg={8} className="border-right">
                <Row>
                  <Col md={9}>
                    <AsyncSelect
                      field="accommodation"
                      label="accommodation"
                      defaultOptions
                      isDisabled={preloadedData.accommodation}
                      loadOptions={onFetchAccommodations}
                     // onChange={( val ) => setAccommodation( val.value )}
                      labelKey={( option ) => `${option.name} (${option.roomType.property.propertyManagerCityZone.name} - ${option.roomType.property.propertyManagerCityZone.propertyManagerCity.city.name})`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Select
                      field="taskType"
                      label="category"
                      labelClass="font-weight-bold"
                      validate={required}
                      options={taskTypes}
                      isLoading={isLoadingTaskTypes}
                     // onChange={( val ) => setTaskType( val )}
                      optgroup
                    />
                  </Col>
                  {/* <Col md={6}>
                    <Select
                      field="type"
                      label="type"
                      labelClass="font-weight-bold"
                      validate={required}
                      translateOptions
                      value="issue"
                      isDisabled
                      options={typeOfTaskTypes}
                    />
                  </Col> */}
                </Row>

                <Row>
                  <Col>
                    <TextField
                      field="description"
                      label="description"
                      type="textarea"
                      labelClass="font-weight-bold"
                      rows={3}
                      validate={required}
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={4}>
                <Row className="mb-3">
                  <Col>
                    <Select
                      field="priority"
                      label="priority"
                      labelClass="font-weight-bold"
                      translateOptions
                      options={_.map( taskPriorities, ( priority ) => ( {
                        id: priority.id,
                        name: priority.name,
                        icon: (
                          <i
                            className={classnames(
                              priority.iconClass,
                              'text-2xsm',
                            )}
                          />
                        ),
                      } ) )}
                      validate={required}
                      formGroupClass="m-0"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Datepicker
                      field="date"
                      label="scheduledFor"
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      utc
                      labelClass="font-weight-bold"
                      validate={required}
                      startDateField={{ value: moment().subtract( '1', 'day' ) }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      />
    )
  ,
);
