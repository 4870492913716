import React from 'react';
import _ from 'lodash';

import {
  AsyncTable,
  ResourceAvatar, EditCard,
} from 'components';
import { formatPhone } from 'utils';

const ResourceDashboard = React.memo(
  ( {
    data,
    // options,
    isLoading,
    // isNomadsAdmin,
    defaultFilters,
    onFetchData,
  } ) => {
    // const nomadsAdminRolesArray = [
    //   roles.nomadsAdmin,
    //   roles.nomadsAdminReadOnly,
    //   roles.admin,
    //   roles.adminReadOnly,
    //   ...options.roles,
    // ];
    // const adminRolesArray = [
    //   roles.admin,
    //   roles.adminReadOnly,
    //   ...options.roles,
    // ];
    const getRoles = ( rolesArray ) => _.chain( rolesArray )
      .filter(
        ( role ) => role.name !== 'ROLE_USER' && role.name !== 'ROLE_EMPLOYEE',
      )
      .map( 'name' )
      .join( ', ' )
      .value();

    return (
      <>
        <EditCard cardClass="card-sec-bottom">
          <AsyncTable
            id="resources"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            disableSorting
            defaultFilters={defaultFilters}
            onFetchData={onFetchData}
            columns={[
              {
                Header: 'name',
                accessor: 'firstName',
                Cell: ( { cell } ) => (
                  <span>
                    <ResourceAvatar
                      data={cell.row.original}
                      avatarSize="sm"
                    />
                  </span>
                )
                ,
              },
              {
                Header: 'phoneNumber',
                accessor: 'phoneNumber',
                Cell: ( { cell } ) => formatPhone( cell.value ),
              },
              {
                Header: 'email',
                accessor: 'email',
                Cell: ( { cell } ) => <span>{cell.value}</span>,
              },
              {
                Header: 'role',
                accessor: 'roles',
                Cell: ( { cell } ) => getRoles( cell.value ),
              },
            ]}
          />
        </EditCard>

      </>
    );
  },
);

export default ResourceDashboard;
