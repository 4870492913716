import api from 'config/Api/Api';

const baseEndpoint = '/shifts';

export default {
  getShifts( params ) {
    return api.get( baseEndpoint, params );
  },

  getShift( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveShift( data ) {
    return api.post( baseEndpoint, data );
  },

  updateShift( id, data ) {
    return api.put( `${baseEndpoint}/${id}/edit`, data );
  },

  deleteShift( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },

  bulkAssignation( data ) {
    return api.post( `${baseEndpoint}/bulk-assignation`, data );
  },
};
