import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import BookingContractView from 'views/Booking/Edit/Contract';
import ModalBookingContractView from 'views/Booking/Edit/Contract/Edit';
import BookingContractsService from 'api/BookingContracts';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';
import moment from 'moment';
import { useDelete } from 'hooks';
import useFetch from 'hooks/useFetch';
import { createNewContract } from './utils';

const BookingContract = ( {
  bookingData = {}, toggleErrorAlert, toggleLoading, toggleInfoAlert,
} ) => {
  const bookingId = _.get( bookingData, 'id' );
  const [modalOpened, setModalOpened] = useState( false );
  const [selectedContract, setSelectedContract] = useState( {} );
  const [reloadContract, setReloadContract] = useState( false );
  const onReloadContract = () => setReloadContract( ( bef ) => !bef );
  const closeModal = () => setModalOpened( false );

  const openEditModal = ( contract = {} ) => {
    setSelectedContract( contract );
    setModalOpened( true );
  };

  const onReload = useCallback( () => {
    closeModal();
    onReloadContract();
  }, [] );

  const [data, dataLoading] = useFetch( {
    initialState: { data: [] },
    promise: () => BookingContractsService.getContracts( bookingId ),
    conditional: !!bookingId,
    deps: [bookingId, reloadContract],
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    const isEditting = !_.isEmpty( formData.id );
    const {
      link, status, id, description, signedDate, external,
    } = formData;
    toggleLoading( true );
    const date = signedDate ? moment( signedDate ) : moment();
    const dataToSend = {
      link,
      status,
      signedDate: status === 'signed' && ( date.format( 'YYYY-MM-DD' ) ),
      description: description || '',
      external: external ? 'true' : 'false',
    };

    const [errors, response] = await handlePromise(
      isEditting ? BookingContractsService.updateContract(
        bookingId, id,
        dataToSend,
      ) : createNewContract( form, formData, bookingData ),
    );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( isEditting ) {
      const _file = _.get( formData, 'contract', undefined );

      if ( typeof _file !== 'string' && _.get( _file, 'name' ) ) {
        const contractToSend = {
          file: _file,
          name: _file.name.split( '.' )[0],
        };

        const [fileErrors, fileResponse] = await handlePromise(
          BookingContractsService.updateContractFile(
            bookingId,
            id || response.data.id, contractToSend,
          ),
        );

        if ( !fileResponse.ok ) {
          toggleLoading( false );
          return fileErrors;
        }
      }
    }

    toggleLoading( false );
    onReload();
    toggleInfoAlert( 'dataSaved' );
  }, [bookingId, bookingData, toggleInfoAlert, toggleLoading, onReload] );

  const onDeleteItem = useDelete( {
    promise: ( itemId ) => BookingContractsService.deleteContract( bookingId, itemId ),
    callback: () => {
      onReloadContract();
    },
    toggleLoading,
    toggleErrorAlert,
    deps: [bookingId],
  } );

  return (
    <>
      <BookingContractView
        isDataLoading={dataLoading}
        data={data}
        onOpenEditItemModal={openEditModal}
        onDeleteItem={onDeleteItem}
      />
      <ModalBookingContractView
        selectedContract={selectedContract}
        onSubmit={submitForm}
        modalOpened={modalOpened}
        handleClose={closeModal}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( BookingContract );
