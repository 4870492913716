import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { Intl } from 'components';
import classnames from 'classnames';

const Report = ( { data, isLoading, show } ) => {
  const [isOpen, setIsOpen] = useState( true );
  const toggle = () => setIsOpen( ( state ) => !state );

  if ( !show || isLoading ) return null;

  return (
    <>
      <tbody>
        <tr>
          <th onClick={toggle} className="dc_sidebar_cell dc_title dc_bg-white large">
            #
            <Intl id="shifts" />
            {isOpen && <i className="fas fa-chevron-up chevron" />}
            {!isOpen && <i className="fas fa-chevron-down chevron" />}
          </th>
        </tr>
      </tbody>
      <Collapse isOpen={isOpen} tag="tbody">
        {data.map( ( { teamName, teamId, shifts } ) => (
          <tr
            key={teamId}
            className={classnames(
              'dc_data-row',
              { 'font-weight-bold': teamName === 'Total' },
            )}
          >
            <th
              className={classnames(
                'dc_sidebar_cell large',
                { 'font-weight-bold': teamName === 'Total' },
              )}
            >
              {teamName}
            </th>
            {shifts.map( ( { shifts: totalShifts, dateInt } ) => (
              <td key={dateInt} className="text-center">
                {totalShifts}
              </td>
            ) )}
          </tr>
        ) )}
      </Collapse>
    </>
  );
};

export default Report;
