import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AutogenerateTaskDashboardView from 'views/AutogenerateTask/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import AutogenerateTaskService from 'api/AutogenerateTasks';
import { useDelete, useFetchTableData, useFetch } from 'hooks';
import AutogenerateTaskEdit from '../Edit';
import TaskTypeService from '../../../api/TaskType';

const AutogenerateTaskDashboard = ( {
  defaultFilters, toggleErrorAlert, toggleLoading, updateFilters, setFiltersError,
} ) => {
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AutogenerateTaskService.getTasks( {
      ...params,
      taskType: ( _.get( params, 'taskType' ) ),
    } ),
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'AutogenerateTasks', {
        taskType: params.taskType,
      } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'AutogenerateTasks' );
        initialReloadError.current = false;
      }
    },
  } );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const deleteTasks = async ( id ) => {
    await AutogenerateTaskService.deleteAutogeneratedTasks( id );
    await AutogenerateTaskService.deleteTaskRule( id );
    return {
      ok: true,
    };
  };

  const deleteEntity = useDelete( {
    promise: deleteTasks,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <AutogenerateTaskDashboardView
        data={data}
        isLoading={dataLoading}
        taskTypes={taskTypes}
        isLoadingTaskTypes={loadingTaskTypes}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteEntity}
      />

      <AutogenerateTaskEdit
        taskId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.AutogenerateTasks' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( AutogenerateTaskDashboard );
