import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import BookingServiceView from 'views/Booking/Edit/Service/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import BookedServiceService from 'api/BookedService';
import { useFetch, useDelete } from 'hooks';

const BookingServices = ( {
  bookingData = {},
  userCurrency,
  pathLayout,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [data, dataLoading] = useFetch( {
    initialState: { data: [] },
    promise: ( params ) => BookedServiceService
      .getBookedServices( {
        ...params,
        booking: bookingData.id,
        elementsPerPage: 1000,
      } ),
    conditional: !!bookingData.id,
    deps: [dataSubmitted, bookingData.id],
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deleteBookedServices = useDelete( {
    promise: BookedServiceService.deleteBookedService,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <BookingServiceView
      data={data}
      isLoading={dataLoading}
      userCurrency={userCurrency}
      pathLayout={pathLayout}
      onDelete={deleteBookedServices}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( BookingServices );
