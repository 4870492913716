import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const DELETE_OCCUPANCY = gql`
  mutation DeleteOccupancy($occupancyId: ID!) {
    deleteOccupancy(occupancyId: $occupancyId) {
      ... on BaseError {
        message
      }
      ... on MutationDeleteOccupancySuccess {
        data {
          success
        }
      }
    }
  }
`;

const deleteOccupancy = async ( occupancyId ) => {
  const { data } = await client.query( {
    query: DELETE_OCCUPANCY,
    variables: {
      occupancyId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteOccupancy.message ) {
    return {
      ok: false,
      message: data.deleteOccupancy.message,
    };
  }
  const deleteSuccessful = data.deleteOccupancy.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteOccupancy;
