import api from 'config/Api/Api';

const baseEndpoint = '/settings/currency';

export default {
  getCurrency( params ) {
    return api.get( baseEndpoint, params );
  },

  updateCurrency( params ) {
    return api.put( baseEndpoint, params );
  },
};
