import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { Intl } from 'components';

const hourListOptions = [];
const minuteListOptions = [];

let d = 0;
while ( d < 24 ) {
  hourListOptions.push( {
    text: d < 10 ? `0${d}` : d,
    value: d,
  } );
  // eslint-disable-next-line no-plusplus
  d++;
}
d = 0;
while ( d < 60 ) {
  minuteListOptions.push( {
    text: d < 10 ? `0${d}` : d,
    value: d,
  } );
  // eslint-disable-next-line no-plusplus
  d++;
}

const ExtraServiceRow = ( {
  dataRow, index, onUpdate, onDelete,
} ) => {
  const [name, setName] = useState( dataRow.name );
  const [amount, setAmount] = useState( dataRow.amount );
  const [price, setPrice] = useState( dataRow.price );
  const [appliedTaxPercentage, setAppliedTaxPercentage] = useState( dataRow.appliedTaxPercentage );

  return (
    <Row className="mb-3 mx--1">
      <Col className="px-1">
        <div className="text-bold text-small"><Intl id="name" /></div>
        <input
          type="text"
          className="form-control-sm form-control"
          value={name}
          onChange={( e ) => {
            const v = e.target.value;
            setName( v );
            onUpdate( index, v, amount, price, appliedTaxPercentage );
          }}
        />
      </Col>
      <Col xs="auto" className="px-1">
        <div style={{ width: 100 }}>
          <div className="text-bold text-small"><Intl id="count" /></div>
          <input
            type="number"
            className="form-control-sm form-control"
            value={amount}
            onChange={( e ) => {
              const v = parseInt( e.target.value, 10 );
              setAmount( v );
              onUpdate( index, name, v, price, appliedTaxPercentage );
            }}
          />
        </div>
      </Col>

      <Col xs="auto" className="px-1">
        <div style={{ width: 100 }}>
          <div className="text-bold text-small"><Intl id="price" /></div>
          <input
            type="number"
            className="form-control-sm form-control"
            value={price}
            onChange={( e ) => {
              const v = parseInt( e.target.value, 10 );
              setPrice( v );
              onUpdate( index, name, amount, v, appliedTaxPercentage );
            }}
          />
        </div>
      </Col>

      <Col xs="auto" className="px-1">
        <div style={{ width: 100 }}>
          <div className="text-bold text-small"><Intl id="taxes" /></div>
          <input
            type="number"
            className="form-control-sm form-control"
            value={appliedTaxPercentage}
            onChange={( e ) => {
              const v = parseInt( e.target.value, 10 );
              setAppliedTaxPercentage( v );
              onUpdate( index, name, amount, price, v );
            }}
          />
        </div>
      </Col>

      <Col xs={1} className="px-1 pt-3">
        <div
          className="p-1 text-danger d-inline-block cursor-pointer"
          onClick={() => {
            onDelete( index );
          }}
        >
          <i className="fa fa-times" />
        </div>
      </Col>
    </Row>
  );
};

/// ///////////////////////////////////////////////

const ExtraServicesList = ( {
  value = [], label, onChange = () => {}, showRequiredError,
} ) => {
  const [list, setList] = useState( [] );
  const [loaded, setLoaded] = useState( false );
  const [deleting, setDeleting] = useState( false );

  useEffect( () => {
    if ( value && value.length && !loaded ) {
      setList( value );
      onChange( value );
      setLoaded( true );
    }
  }, [value, loaded, onChange] );

  const onNew = useCallback( ( ) => {
    const listClone = [...list];
    listClone.push( {
      name: '', amount: 0, price: 0, appliedTaxPercentage: 0,
    } );
    setList( listClone );
    onChange( listClone );
  }, [list, onChange] );

  const onUpdate = useCallback( ( index, name, amount, price, appliedTaxPercentage ) => {
    const listClone = [...list];
    listClone[index] = {
      name, amount, price, appliedTaxPercentage,
    };
    setList( listClone );
    onChange( listClone );
  }, [list, onChange] );

  const onDelete = useCallback( ( index ) => {
    const listClone = [...list];
    listClone.splice( index, 1 );
    setDeleting( true );
    setList( listClone );
    onChange( listClone );
    setTimeout( () => {
      setDeleting( false );
    }, 100 );
  }, [list, onChange] );

  return (
    <>
      <div>

        {label
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        && (
        <label className="font-weight-bold form-control-label mb-2 d-block">
          <Intl id={label} />
        </label>
        )}
        {!deleting && list.map( ( dataRow, k ) => (
          <ExtraServiceRow
            dataRow={dataRow}
            index={k}
            // eslint-disable-next-line react/no-array-index-key
            key={k}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        ) )}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
        <a
          href="#"
          className="text-small text-bold d-block mb-3"
          onClick={( e ) => {
            e.preventDefault();
            onNew();
          }}
        >
          +
          {' '}
          <Intl id="addExtraService" />
        </a>
        {/* {enabledUpdate && (
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            setEnabledUpdate( false );
            onSubmit( list );
          }}
        >
          <Intl id="updateExtraCosts" />
        </Button>
        )} */}
        {list.length === 0 && showRequiredError ? (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            <Intl id="fieldError.required" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ExtraServicesList;
