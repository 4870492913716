import React, { useMemo } from 'react';

import { Row, Col } from 'reactstrap';

import {
  Form,
  TextField,
  EditCard,
  FormActionsBtns,
  Select,
  Dropzone,
  Phone,
  // CustomFieldsContainer,
  Button,
} from 'components';
import { email, required, composeValidators } from 'config/InputErrors';
import { locales } from 'config/Constants';
import countries from 'country-list';

export default React.memo(
  ( {
    data,
    entityId,
    parentPath,
    title,
    formEnabled = true,
    onSubmit,
    onDelete,
    enableDelete = false,
  } ) => (
    <EditCard title={title}>
      {entityId && enableDelete && (
      <div className="d-flex justify-content-end mb-3">
        <Button title="delete" color="danger" onClick={onDelete} />
      </div>
      )}
      <Form
        initialValues={data.entity}
        onSubmit={onSubmit}
        showErrorsInFooter
        body={() => (
          <Row>
            {!!entityId && (
            <Dropzone
              field="profilePicture"
              formGroupClass="px-3 dz-profile-img"
            />
            )}

            <Col lg>
              <h4 className="font-weight-normal text-uppercase">
                Personal Data
              </h4>
              {!entityId && (
              <Row>
                <Col md={6}>
                  <TextField
                    field="email"
                    validate={composeValidators( required, email )}
                    label="email"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="password"
                    label="password"
                    type="password"
                    validate={required}
                  />
                </Col>
              </Row>
              )}

              <Row>
                <Col md={3}>
                  <TextField
                    field="firstName"
                    label="firstName"
                    validate={required}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="lastName"
                    label="lastname"
                    validate={required}
                  />
                </Col>
                <Col md={3}>
                  <Phone
                    field="phoneNumber"
                    label="phone"
                    validate={required}
                  />
                </Col>
                {entityId && (
                <Col md={3}>
                  <TextField field="email" label="email" disabled />
                </Col>
                )}
              </Row>

              {/* <CustomFieldsContainer customFields={data.customFields} /> */}

              <hr className="divider-dashed" />
              <h4 className="font-weight-normal text-uppercase">Settings</h4>

              <Row>

                <Col md={entityId ? 4 : 3}>
                  <Select
                    field="country"
                    label="country"
                    idKey="code"
                    options={useMemo( countries.getData, [countries] )}
                    validate={required}
                  />
                </Col>
                <Col md={entityId ? 4 : 3}>
                  <Select
                    field="locale"
                    label="language"
                    idKey="locale"
                    options={locales}
                    validate={required}
                  />
                </Col>
              </Row>
              {/* <Row>
                  <Col md={4}>
                    <TextField
                      field="externalId"
                      label="externalId"
                    />
                  </Col>
                </Row> */}
            </Col>
          </Row>
        )}
        footer={
            formEnabled ? <FormActionsBtns cancelTo={parentPath} /> : null
          }
      />
    </EditCard>
  ),
);
