import React from 'react';
import { Route, Switch } from 'react-router';

import { canList } from 'config/Ability';
import Dashboard from './Dashboard';

const Category = ( { match } ) => (
  <Switch>
    {canList( 'cms' )
    && (
      <Route
        path={match.url}
        render={( props ) => <Dashboard {...props} parentPath={match.url} />}
      />
    )}
  </Switch>
);

export default Category;
