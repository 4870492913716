import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import PostEditView from 'views/Post/Edit';
import SettingsActions from 'store/reducers/Settings';
import PostService from 'api/Post';
import RegionServices from 'api/Region';
import PostImageService from 'api/PostImage';
import CategoryService from 'api/Category';
import TranslationService from 'api/Translation';
import CityService from 'api/Settings/City';
import { useFetch } from 'hooks';
import { formatDataToSend, handlePromise } from 'utils';
import { each } from 'utils/async';
import RSSPost from '../RSS';

const PostEdit = ( {
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
  propertyManager,
  match,
  parentPath,
  history,
} ) => {
  const [dataFromRSS, setDataFromRSS] = useState( {} );
  const [RSSmodalOpened, setRSSmodalOpened] = useState( false );
  const toggleRSSModal = () => setRSSmodalOpened( ( state ) => !state );

  const loadPostFromRSS = ( itemRSS ) => {
    const { description, title } = itemRSS;
    setDataFromRSS( { title, body: description } );
    toggleRSSModal();
  };

  const postId = match.params.id;
  const [data, dataLoading, setData] = useFetch( {
    initialState: {},
    promise: () => PostService.getPost( postId ),
    translationPromise: () => TranslationService.getCmsTranslations( postId, 'post' ),
    format: ( dataToFormat ) => {
      if ( !postId ) return dataToFormat;

      const formattedData = { ...dataToFormat };
      if ( _.get( formattedData, 'category.id' ) ) {
        formattedData.category = {
          value: formattedData.category.id,
          label: formattedData.category.name,
        };
      }
      if ( _.get( formattedData, 'city.id' ) ) {
        formattedData.city = {
          value: formattedData.city.id,
          label: formattedData.city.name,
        };
      }
      return formattedData;
    },
    toggleErrorAlert,
    conditional: !!postId,
    deps: [postId],
    reInit: true,
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const dataToSend = formatDataToSend( formData, form, true );
      dataToSend.images = undefined;
      const [errors, response, responseData] = await handlePromise(
        postId
          ? PostService.updatePost( postId, dataToSend )
          : PostService.savePost( dataToSend ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      return new Promise( ( resolve, reject ) => {
        each(
          formData.images,
          async ( image, eCb ) => {
            if ( !image.file ) return eCb();

            const [imageErrors, imageResponse] = await handlePromise(
              PostImageService.saveImage( postId || responseData.id, {
                image: image.file,
              } ),
            );
            if ( !imageResponse.ok ) return eCb( imageErrors );
            eCb();
          },
          ( error ) => {
            if ( error ) {
              toggleLoading( false );
              return reject( error );
            }
            toggleLoading( false );
            toggleInfoAlert( 'dataSaved' );
            resolve();
            history.push( parentPath );
          },
        );
      } ).catch( ( error ) => Promise.resolve( error ) );
    },
    [postId, history, toggleInfoAlert, toggleLoading, parentPath],
  );

  const saveImage = useCallback(
    async ( images ) => {
      if ( !postId ) return;
      toggleLoading( true );

      const newImages = _.cloneDeep( images );
      let newData = {};
      await each(
        newImages,
        async ( image, eCb ) => {
          if ( !image.file ) return eCb();

          const [errors, response, responseData] = await handlePromise(
            PostImageService.saveImage( postId, { image: image.file } ),
          );
          if ( !response.ok ) return eCb( errors );
          // eslint-disable-next-line no-param-reassign
          newData = responseData;
          eCb();
        },
        ( error ) => {
          toggleLoading( false );
          if ( error ) return toggleErrorAlert( error );
          setData( { ...data, images: newData.images } );
        },
      );
    },
    [postId, data, setData, toggleErrorAlert, toggleLoading],
  );

  const deleteImage = useCallback(
    async ( index ) => {
      toggleLoading( true );
      const [imageErrors, imageResponse] = await handlePromise(
        PostImageService.deleteImage( postId, index + 1 ),
      );
      toggleLoading( false );
      if ( !imageResponse.ok ) return toggleErrorAlert( imageErrors );
      const newImages = _.cloneDeep( data.images );
      newImages.splice( index, 1 );
      setData( { ...data, images: newImages } );
    },
    [postId, data, setData, toggleErrorAlert, toggleLoading],
  );

  const fetchCategories = useCallback(
    ( query ) => CategoryService.getCategories( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const fetchCities = useCallback(
    () => CityService.getCities( {
      propertyManager: propertyManager.id,
    } ),
    [propertyManager.id],
  );

  /* REGIONS ******************************** */
  const [regions, regionsLoading] = useFetch( {
    initialState: [],
    promise: () => RegionServices.getRegions( { page: 1, elementsPerPage: 999 } ),
    toggleErrorAlert,
    format: ( results ) => {
      if ( results && results.data ) {
        return results.data.map( ( { id, name } ) => ( { id, name } ) );
      }
      return [];
    },
  } );
    /* end REGIONS ******************************** */

  return (
    <>
      <PostEditView
        data={data}
        dataFromRSS={dataFromRSS}
        entityId={postId}
        isLoading={dataLoading}
        onSubmit={submitForm}
        onAddImage={saveImage}
        onDeleteImage={deleteImage}
        onFetchCategories={fetchCategories}
        onFetchCities={fetchCities}
        parentPath={parentPath}
        onNewPostFromRSS={toggleRSSModal}
        regions={regions}
        regionsLoading={regionsLoading}
      />
      <RSSPost
        modalOpened={RSSmodalOpened}
        onCloseModal={toggleRSSModal}
        onNewPost={loadPostFromRSS}
      />
    </>
  );
};
const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( PostEdit );
