/* eslint-disable import/prefer-default-export */
const filterBuilder = ( {
  keyword,
  accommodation,
  filterByDate,
  fromStart,
  fromEnd,
  propertyManagerId,
} ) => {
  const filters = [];
  if ( keyword ) {
    filters.push( {
      field: 'name',
      operator: 'CONTAINS',
      value: keyword,
    } );
  }
  if ( accommodation ) {
    filters.push( {
      field: 'Accommodation.id',
      operator: 'EQUAL',
      value: accommodation.value,
    } );
  }
  if ( propertyManagerId ) {
    filters.push( {
      field: 'propertyManagerId',
      operator: 'EQUAL',
      value: propertyManagerId,
    } );
  }
  if ( filterByDate ) {
    if ( fromStart ) {
      filters.push( {
        field: 'validUntil',
        operator: 'GTE',
        value: new Date( fromStart ).toISOString(),
      } );
      filters.push( {
        field: 'validUntil',
        operator: 'LTE',
        value: new Date( fromEnd ).toISOString(),
      } );
    }
  }
  return filters;
};

export { filterBuilder };
