import React from 'react';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import classnames from 'classnames';

import {
  MapMarker, AsyncSelect, Form,
} from 'components';

export default ( {
  data, pmCoordinates, openedMarker, isLoading,
  onOpenInfoWindow, onGoogleApiLoaded, onFetchTeams, onSubmitFilters,
} ) => (
  <>
    <Form
      onSubmit={onSubmitFilters}
      body={( form ) => (
        <Row className="mt-2 mx-0">
          {/* <Col md={{ size: 3, offset: 1 }}>
                <Select
                  field="city"
                  label="city"
                  placeholder="chooseCity"
                  isClearable
                  labelKey="city.name"
                  formGroupClass="d-flex align-items-baseline"
                  labelClass="mr-2"
                  options={options.cities}
                  onChange={( cityId ) => {
                    form.change( 'zone', null );
                    form.change( 'team', null );
                    onChangeCity( cityId );
                  }}
                />
              </Col>
              <Col md={3}>
                <Select
                  field="zone"
                  label="zone"
                  placeholder="chooseZone"
                  options={options.zones}
                  isClearable
                  formGroupClass="d-flex align-items-baseline"
                  labelClass="mr-2"
                  isLoading={isZonesLoading}
                  onChange={() => {
                    form.change( 'team', null );
                  }}
                />
              </Col> */}
          <Col xl={4} lg={6} md={6}>
            <AsyncSelect
              field="team"
              label="team"
              placeholder="chooseTeam"
              formGroupClass="d-flex align-items-baseline"
              labelClass="mr-2"
              isClearable
              defaultOptions
              loadOptions={onFetchTeams}
              onChange={form.submit}
            />
          </Col>
        </Row>
      )}
    />

    <div className={classnames( 'map-container', { 'app-component-loading': isLoading } )}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
        yesIWantToUseGoogleMapApiInternals
        center={_.keys( data )[0]
          ? {
            lat: Number( _.keys( data )[0].split( '/' )[0] ),
            lng: Number( _.keys( data )[0].split( '/' )[1] ),
          }
          : pmCoordinates}
        defaultZoom={13}
        onGoogleApiLoaded={onGoogleApiLoaded}
      >
        {_.map( data, ( items ) => (
          <MapMarker
            key={items[0].id}
            lat={Number( items[0].assigned.employee.geoLocation.latitude )}
            lng={Number( items[0].assigned.employee.geoLocation.longitude )}
            data={items}
            opened={openedMarker === items[0].id}
            onClick={() => onOpenInfoWindow( items[0], items )}
          />
        ) )}
      </GoogleMapReact>
    </div>
  </>
);
