import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const UPDATE_PIPELINE = gql`
mutation UpdatePipeline($description: String!, $name: String!, $stages: [String!]!, $id: String!, $isActive: Boolean!, $propertyManager: String!, $force: Boolean!) {
  updatePipeline(
    id: $id
    description: $description
    isActive: $isActive
    name: $name
    stages: $stages
    propertyManager: $propertyManager
    force: $force
  ) {
    ... on BaseError {
      message
    }
    ... on MutationUpdatePipelineSuccess {
      data {
        success
      }
    }
  }
}
`;

const updatePipeline = async ( props ) => {
  const { data } = await client.query( {
    query: UPDATE_PIPELINE,
    variables: { ...props, propertyManager: getPropertyManager() },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updatePipeline.message ) {
    return {
      ok: false,
      message: data.updatePipeline.message,
    };
  }

  const updateSuccessful = data.updatePipeline.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updatePipeline;
