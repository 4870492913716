import React from 'react';
import {
  Button,
  Row,
  Col, Container,
} from 'reactstrap';

import {
  Form, TextField, Intl, EditCard,
} from 'components';
import { composeValidators, required, equalTo } from 'config/InputErrors';

export default React.memo( ( { onSubmit } ) => (
  <Container fluid className="mt-5">
    <Row>
      <Col sm={8} md={6} lg={4} className="m-auto">
        <EditCard title="changePassword">
          <Form
            onSubmit={onSubmit}
            showErrorsInFooter
          >
            <>
              <TextField
                field="oldPassword"
                type="password"
                validate={required}
                label="oldPassword"
              />

              <TextField
                field="password"
                type="password"
                validate={required}
                label="newPassword"
              />

              <TextField
                field="passwordConfirmation"
                type="password"
                validate={composeValidators( required, equalTo( 'password' ) )}
                translateValues={{ label: 'password' }}
                label="repeatNewPassword"
              />
            </>

            <Row className="mt-4">
              <Col className="text-center">
                <Button color="primary" data-cy="form-submit-btn">
                  <Intl id="changePassword" />
                </Button>
              </Col>
            </Row>
          </Form>
        </EditCard>
      </Col>
    </Row>
  </Container>
) );
