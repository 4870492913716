/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { NavLink as NavLinkRRD, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from 'reactstrap';
import { Intl } from 'components';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';

const getParentRoute = ( routes ) => routes && routes.views.map( ( route ) => {
  if ( route.views ) return getParentRoute( route );
  return ( {
    ...route,
    ref: route.layout + route.path,
    parent: routes.state,
  } );
} );

const flattenRoutes = ( routes ) => _.flatten( getParentRoute( routes ) );

const getCollapseStates = ( routes, pathname ) => {
  const state = _.find( flattenRoutes( routes ), { ref: pathname } ) || {};
  const { parent = '', path = '', layout = '' } = state;
  return {
    parent,
    path,
    layout: layout.replaceAll( '/', '' ),
  };
};

const Sidebar = ( {
  routes,
  activeMenu,
  toggleSidenav,
  pathname,
} ) => {
  const sidebarMenu = _.find( routes, { state: activeMenu } );
  const [collapseStates, setCollapseStates] = useState(
    getCollapseStates( sidebarMenu, pathname ),
  );
  const isMobile = useMediaQuery( { query: '(max-width: 992px)' } );
  const [showMainMenu, setShowMainMenu] = useState( false );
  const dispatch = useDispatch();

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    setCollapseStates( [] );
    if ( window.innerWidth < 1200 ) {
      toggleSidenav();
    }
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = ( links, parent = '', icon ) => links.map( ( route ) => {
    if ( route.redirect ) {
      return null;
    }
    if ( route.views ) {
      return (
        <NavItem key={route.name}>
          <NavLink
            data-toggle="collapse"
            aria-expanded={collapseStates.parent === route.state}
            className={classnames( 'cursor-pointer pl-0', { 'is-open': collapseStates.parent === route.state } )}
            onClick={() => {
              if ( collapseStates.parent === route.state ) {
                setCollapseStates( ( state ) => ( { ...state, parent: '' } ) );
              } else {
                setCollapseStates( ( state ) => ( { ...state, parent: route.state } ) );
              }
            }}
          >
            {/* {route.icon ? <i className={route.icon} /> : null} */}
            <div className="sidebar-graph-container">
              <div className="sidebar-graph-container__line" />
              {icon ? <i className={icon} /> : null}
            </div>
            <span className="nav-link-text"><Intl id={route.name} /></span>
          </NavLink>
          <Collapse isOpen={collapseStates.parent === route.state}>
            <Nav className="flex-column sidebar-subnav">
              {createLinks( route.views, route.state )}
            </Nav>
          </Collapse>
        </NavItem>
      );
    }
    return (
      <NavItem
        className={classnames( { active: collapseStates.path === route.path } )}
        key={route.layout + route.path}
      >
        <NavLink
          to={route.layout + route.path}
          activeClassName="text-secondary sec-active"
          className="pl-0"
          onClick={() => {
            closeSidenav();
            setCollapseStates( {
              layout: route.layout.replaceAll( '/', '' ),
              path: route.path,
              parent,
            } );
          }}
          tag={NavLinkRRD}
        >
          {/* {route.icon !== undefined ? (
            <>
              <i className={route.icon} />
              <span className="nav-link-text"><Intl id={route.name} /></span>
            </>
          ) : ( */}
          <div className="sidebar-graph-container">
            <div className="sidebar-graph-container__line" />
            {icon ? <i className={icon} /> : null}
          </div>
          <span><Intl id={route.name} /></span>
          {/* )} */}
        </NavLink>
      </NavItem>
    );
  } );

  const createMainMenu = ( menu ) => {
    if ( showMainMenu ) {
      const help_ = (
        <NavItem key="id_support">
          <NavLink
            className="cursor-pointer pl-0"
            href="https://intercom.help/threeangular"
            target="_blank"
          >
            {/* {route.icon ? <i className={route.icon} /> : null} */}
            <span className="nav-link-text"><Intl id="help" /></span>
          </NavLink>

        </NavItem>
      );

      const menu_ = menu.map( ( route ) => {
        const handleonClick = () => {
          setShowMainMenu( false );
          dispatch( SettingsActions.switchActiveMenu( route.state ) );
          toggleSidenav();
        };
        return (
          <NavItem key={route.name}>
            <NavLink
              aria-expanded={collapseStates.layout === route.state}
              className="cursor-pointer pl-0"
              onClick={handleonClick}
              tag={NavLinkRRD}
              to={route.layout + route.path}
            >
              {/* {route.icon ? <i className={route.icon} /> : null} */}
              <span className="nav-link-text">
                <Intl id={route.name} />
                {' '}
              </span>
            </NavLink>
          </NavItem>
        );
      } );

      // Only for staging
      if ( window.location.hostname.indexOf( 'staging' ) !== -1 ) {
        return menu_.concat( help_ );
      }
      return menu_;
    }
    return (
      <NavItem key="back">
        <NavLink
          className="cursor-pointer pl-0"
          onClick={() => setShowMainMenu( true )}
        >
          <i className="fas fa-arrow-left" />
          <span className="nav-link-text"><Intl id="mainMenu" /></span>
        </NavLink>
      </NavItem>
    );
  };

  useEffect( () => {
    if ( !isMobile ) {
      setShowMainMenu( false );
    }
    if ( isMobile && !activeMenu ) {
      setShowMainMenu( true );
    }
  }, [isMobile, activeMenu] );

  if ( !isMobile && !sidebarMenu ) {
    return null;
  }

  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="navbar-inner">
        <Collapse navbar isOpen>
          {
            isMobile
            && (
            <Nav navbar>
              {createMainMenu( routes )}
            </Nav>
            )
          }
          {
            ( !showMainMenu )
            && (
            <Nav navbar>
              {createLinks( sidebarMenu ? sidebarMenu.views : [], '', sidebarMenu ? sidebarMenu.icon : null )}
            </Nav>
            )
          }
        </Collapse>
      </div>
    </div>
  );

  return (
    <Navbar className="sidenav navbar-vertical navbar-expand-xs navbar-light fixed-left bg-white">
      <div className="sidenav-wrapper-items">
        {navigator.platform.indexOf( 'Win' ) > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </div>
      <div className="sidenav-collapse">
        <button type="button" className="btn btn-darker p-0" onClick={toggleSidenav}>
          <span className="arrow-right-with-border" />
        </button>
      </div>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
  logo: null,
  toggleSidenav: () => { },
  activeMenu: '',
  pathname: '',
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // links that will be displayed inside the component
  // eslint-disable-next-line react/forbid-prop-types
  routes: PropTypes.arrayOf( PropTypes.object ),
  // logo
  logo: PropTypes.shape( {
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  } ),
  activeMenu: PropTypes.string,
  pathname: PropTypes.string,
};

export default withRouter( Sidebar );
