import React, { useCallback, useState } from 'react';
import { useDelete, useFetchTableData } from 'hooks';
import getAllSeasonsRate from 'api/Rates/Seasons/getAllSeasonsRate';
import SettingsActions from 'store/reducers/Settings';
import RateSeasonsView from 'views/PropertyManager/Edit/Rates/EditDrawer/RateSeasons';
import SeasonEdit from 'containers/PropertyManager/Edit/Rates/EditDrawer/RateSeasons/Edit';
import { connect } from 'react-redux';
import { deleteSeason } from 'api/Rates/Seasons';

const RateSeasons = ( {
  rateId, onReloadRates, toggleErrorAlert, toggleLoading,
} ) => {
  const [modalOpen, setModalOpen] = useState( false );
  const [selectedId, setSelectedId] = useState();
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const onOpenModal = ( id ) => {
    setSelectedId( id );
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setSelectedId();
    setModalOpen( false );
  };

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getAllSeasonsRate( {
      rateId,
      ...params,
    } ),
    conditional: !!rateId,
    toggleErrorAlert,
    deps: [rateId, dataSubmitted],
  } );
  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
    onReloadRates();
    onCloseModal();
  }, [onReloadRates] );

  const onDeleteItem = useDelete( {
    promise: ( itemId ) => deleteSeason( itemId ),
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const viewTypes = [
    { id: 1, name: 'season', value: 'season' },
    { id: 2, name: 'month', value: 'month' },
    { id: 3, name: 'week', value: 'week' },
    { id: 4, name: 'day', value: 'day' },
  ];

  return (
    <>
      <RateSeasonsView
        rateId={rateId}
        viewTypes={viewTypes}
        isDataLoading={dataLoading}
        data={data}
        onReloadRates={onReloadRates}
        onFetchData={fetchData}
        dataSubmitted={dataSubmitted}
        onDeleteItem={onDeleteItem}
        onOpenEditItemModal={onOpenModal}
        onClose={onCloseModal}
      />
      <SeasonEdit
        rateId={rateId}
        seasonId={selectedId}
        open={modalOpen}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( RateSeasons );
