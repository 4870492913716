import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import IncidenceOverviewView from 'views/Incidence/EditDrawer/Overview';
import SettingsActions from 'store/reducers/Settings';
import TaskTypeService from 'api/TaskType';
import IncidenceService from 'api/incidences';
import { handlePromise } from 'utils';
import useFetch from 'hooks/useFetch';

const IncidenceOverview = ( {
  incidenceId,
  data,
  // customFields,
  setData,
  toggleLoading,
  toggleErrorAlert,
  onUpdateField,
  toggleInfoAlert,
} ) => {
  const { updateIncidence } = IncidenceService;
  const submitFieldForm = useCallback(
    async ( formData, field, dataKey, cb ) => {
      if ( _.get( formData, dataKey ) !== _.get( data, dataKey ) ) {
        toggleLoading( true );

        const formattedData = {};
        // const customField = _.find( customFields, { name: field } );
        // if ( customField ) {
        //   const newData = {};
        //   newData[field] = formData[field];
        //   formattedData = formatCustomValuesToSend( newData, [customField] );
        // } else {
        //   formattedData[field] = _.get( formData, dataKey );
        // }
        formattedData[field] = _.get( formData, dataKey );

        // eslint-disable-next-line no-unused-vars
        const [errors, response] = await handlePromise(
          updateIncidence( incidenceId, formattedData ),
        );
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleErrorAlert( 'apiError' );
        }

        const newData = _.cloneDeep( data );
        _.set( newData, field, formData[field] );
        setData( newData );
        if ( cb ) cb();
        toggleLoading( false );
        toggleInfoAlert( 'dataSaved' );
        if ( typeof onUpdateField === 'function' ) onUpdateField();
      }
    },
    [
      incidenceId,
      updateIncidence,
      data,
      // customFields,
      setData,
      toggleErrorAlert,
      toggleLoading,
      onUpdateField,
      toggleInfoAlert,
    ],
  );

  // const assignTask = useCallback(
  //   async ( formData, team ) => {
  //     toggleLoading( true );

  //     // eslint-disable-next-line no-unused-vars
  //     const [errors, response, responseData] = await handlePromise(
  //       TaskService.assignTask( taskId, formData?.assignedTo, team ),
  //     );
  //     if ( !response.ok ) {
  //       toggleLoading( false );
  //       return toggleErrorAlert( 'apiError' );
  //     }
  //     setData( onFormatData( responseData ) );
  //     toggleLoading( false );
  //     toggleInfoAlert( 'dataSaved' );
  //   },
  //   [
  //     taskId,
  //     setData,
  //     onFormatData,
  //     toggleErrorAlert,
  //     toggleLoading,
  //     toggleInfoAlert,
  //   ],
  // );
  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  return (
    <IncidenceOverviewView
      data={data}
      onSubmit={submitFieldForm}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( IncidenceOverview );
