import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

export default React.memo( ( { rating } ) => (
  _.map( new Array( 5 ), ( item, index ) => (
    <i
      key={index}
      className={classnames( 'app-icon-star text-gray-3 text-lg', {
        'text-yellow-3': index < rating,
      } )}
    />
  ) )
) );
