import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import SettingsActions from 'store/reducers/Settings';
import DashboardView from 'views/Communications/Client/Templates/Dashboard';
import CommunicationService from 'api/CommunicationTemplates';
import { useFetchTableData, useDelete } from 'hooks';

const Dashoard = ( {
  toggleErrorAlert,
  toggleLoading,
  parentPath,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => CommunicationService.getTemplates( params ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deleteTemplate = useDelete( {
    promise: CommunicationService.deleteTemplate,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <DashboardView
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      parentPath={parentPath}
      onDelete={deleteTemplate}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( Dashoard );
