import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AutoassignTaskDashboardView from 'views/AutoassignTask/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import AutoassignTaskService from 'api/AutoassignTasks';
import { useDelete, useFetchTableData, useFetch } from 'hooks';
import TaskTypeService from 'api/TaskType';
import PropertyService from 'api/Property';
import AccommodationService from 'api/Accommodation';
import EmployeeService from 'api/Employee';
import AutoassignTaskEdit from '../Edit';

const AutoassignTaskDashboard = ( {
  defaultFilters, toggleErrorAlert, toggleLoading, updateFilters, setFiltersError,
} ) => {
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AutoassignTaskService.getTasks( {
      ...params,
      taskType: ( _.get( params, 'taskType' ) ),
      employee: ( _.get( params, 'employee.value' ) ) ? params.employee.value : undefined,
    } ),
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'AutoassignTasks', {
        taskType: params.taskType,
        employee: params.employee
          ? { value: params.employee.value, label: params.employee.label } : null,
      } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'AutoassignTasks' );
        initialReloadError.current = false;
      }
    },
  } );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const fetchProperties = useCallback( ( query ) => PropertyService.getProperties( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ), [],
  );

  const fetchEmployees = useCallback( ( query ) => EmployeeService.getEmployees( {
    elementsPerPage: 10,
    keyword: query,
    employee: true,
  } ), [] );

  const deleteEntity = useDelete( {
    promise: AutoassignTaskService.deleteTask,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <AutoassignTaskDashboardView
        data={data}
        isLoading={dataLoading}
        taskTypes={taskTypes}
        isLoadingTaskTypes={loadingTaskTypes}
        onFetchProperties={fetchProperties}
        onFetchAccommodations={fetchAccommodations}
        onFetchEmployees={fetchEmployees}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteEntity}
      />

      <AutoassignTaskEdit
        taskId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.AutoassignTasks' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( AutoassignTaskDashboard );
