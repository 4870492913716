import React from 'react';
import { Row, Col, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  AsyncTable,
  DaterangePickerBtn,
  Intl,
  Button,
} from 'components';
import getStatusColor from 'config/Constants/StatusColor';

const statusColor = getStatusColor( 'bookings' );

const BookingsClientEditView = ( {
  isOwner,
  data,
  isLoading,
  defaultFilters,
  initialDates,
  onFetchData,
  onDownload,
} ) => (
  <>
    <AsyncTable
      id="bookings"
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      disableSorting
      defaultFilters={defaultFilters}
      onFetchData={onFetchData}
      customFilters={( { filters, setMultipleFilters } ) => (
        <>
          <Row>
            <Col md={8} lg={4}>
              <DaterangePickerBtn
                position="left"
                label="checkInBetween"
                initialValue={initialDates}
                key={filters.from + filters.till}
                input
                onChange={( values ) => {
                  setMultipleFilters( {
                    from: moment( values.start ).format( 'YYYY-MM-DD' ),
                    till: moment( values.end ).format( 'YYYY-MM-DD' ),
                  } );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              lg={12}
              className="d-flex justify-content-end position-absolute top-0"
            >
              <Button
                extraClass="mr-4"
                title="download"
                onClick={() => onDownload( filters )}
              />
            </Col>
          </Row>
        </>
      )}
      columns={[
        {
          Header: '#',
          accessor: 'id',
          Cell: ( { cell } ) => {
            const title = `${cell.row.original.id.slice(
              cell.row.original.id.length - 6,
              cell.row.original.id.length,
            )}`;
            return (
              isOwner ? title : (
                <Link to={`/bookings/list/${cell.row.original.id}`}>
                  {title}
                </Link>
              )
            );
          },
        },
        {
          Header: 'accommodation',
          accessor: 'accommodation',
          Cell: ( { cell } ) => cell.value?.name || '-',
        },
        {
          Header: 'checkIn',
          accessor: 'from',
          Cell: ( { cell } ) => ( cell.value
            ? `${moment.utc( cell.value ).format( 'MMM DD, YYYY' )} ${
              cell.row.original.checkInTime
            }`
            : '' ),
        },
        {
          Header: 'checkOut',
          accessor: 'till',
          Cell: ( { cell } ) => ( cell.value
            ? `${moment.utc( cell.value ).format( 'MMM DD, YYYY' )} ${
              cell.row.original.checkOutTime
            }`
            : '' ),
        },
        {
          Header: 'travelReport',
          accessor: 'travelReport',
          Cell: ( { cell } ) => (
            <div className="text-center">
              {cell.value ? (
                <Badge color={statusColor( cell.value )} pill>
                  <Intl id={cell.value.toLowerCase()} />
                </Badge>
              ) : (
                <Badge color={statusColor( 'PENDING' )} pill>
                  <Intl id="pending" />
                </Badge>
              )}
            </div>
          ),
        },
        {

          Header: 'checkin',
          accessor: 'checkinComplete',
          Cell: ( { cell } ) => (
            <div className="text-center">
              <Badge
                color={statusColor(
                  cell.value ? 'checkinCompleteYES' : 'checkinCompleteNO',
                )}
                pill
              >
                <Intl id={cell.value ? 'completed' : 'pending'} />
              </Badge>
            </div>
          ),
        },
      ]}
    />
  </>
);

export default BookingsClientEditView;
