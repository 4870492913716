import React from 'react';
import { connect } from 'react-redux';

import CheckInInstructionEditView from 'views/Property/Edit/CheckInInstructions';
import SettingsActions from 'store/reducers/Settings';
import PropertyService from 'api/Property';
import { useSubmit } from 'hooks';

const CheckInInstructionEdit = ( {
  parentPath, data, toggleLoading, toggleInfoAlert, onReloadData,
} ) => {
  const { entity } = data;

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => (
      PropertyService.updateProperty( entity.id, dataToSend ) ),
    toggleLoading,
    toggleInfoAlert,
    translations: true,
    callback: onReloadData,
    deps: [entity.id],
  } );

  return (
    <CheckInInstructionEditView
      data={entity}
      onSubmit={submitForm}
      parentPath={parentPath}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( null, mapDispatchToProps )( CheckInInstructionEdit );
