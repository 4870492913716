import { useSubmit } from 'hooks';
import React from 'react';
import ObservationsView from 'views/Booking/Edit/Observations';
import BookingService from 'api/Booking';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';

const Observations = React.memo( ( { bookingData = {}, toggleLoading, toggleInfoAlert } ) => {
  const { id: accommodationId, booking = {} } = bookingData;
  const bookingId = booking.id;
  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( BookingService.updateBooking(
      bookingId, accommodationId, dataToSend,
    ) ),
    format: ( dataToFormat ) => ( {
      observations: dataToFormat.observations,
    } ),
    toggleLoading,
    toggleInfoAlert,
    deps: [bookingData],
  } );
  return <ObservationsView data={bookingData} onSubmit={submitForm} />;
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );
export default connect( null, mapDispatchToProps )( Observations );
