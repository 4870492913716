import React, { useCallback } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { LocalTable, Intl } from 'components';
import { getResourceName } from 'utils';

export default React.memo( ( { data } ) => {
  const formattedData = _.flatten( _.map( data.assigned, ( assigned ) => _.map(
    assigned.executionPeriods, ( period ) => ( {
      ...period,
      employee: assigned.employee,
      deviation: data.deviation,
      estimatedTime: data.taskType.estimatedTime,
    } ),
  ) ) );

  const getTotalTime = useCallback( ( periods ) => {
    const totalDiff = _.sumBy( periods, ( item ) => moment( item.stop )
      .diff( moment( item.start ) ) );
    const totalHours = Math.floor( moment.duration( totalDiff ).asHours() );
    const totalMinutes = Math.floor( moment.duration( totalDiff ).minutes() );
    const formattedTotalHours = totalHours.toString().length === 1 ? `0${totalHours}` : totalHours;
    const formattedTotalMinutes = totalMinutes.toString().length === 1 ? `0${totalMinutes}` : totalMinutes;

    return `${formattedTotalHours}:${formattedTotalMinutes} h`;
  }, [] );

  return (
    formattedData.length
      ? (
        <>
          <div className="text-right text-primary mb-3">
            <Intl id="totalTime" />
            {': '}
            <span>{getTotalTime( formattedData )}</span>
          </div>
          <LocalTable
            data={formattedData}
            onlyTable
            manualPagination
            columns={[
              {
                Header: 'resource',
                accessor: 'employee',
                Cell: ( { cell } ) => getResourceName( cell.value ),
              },
              {
                Header: 'estimatedTime',
                accessor: 'estimatedTime',
              },
              {
                Header: 'start',
                accessor: 'start',
                Cell: ( { cell } ) => moment( cell.value ).format( 'DD-MM-YYYY hh:mma' ),
              },
              {
                Header: 'end',
                accessor: 'stop',
                Cell: ( { cell } ) => moment( cell.value ).format( 'DD-MM-YYYY hh:mma' ),
              },
              {
                Header: 'total',
                accessor: 'employee.id',
                Cell: ( { cell } ) => {
                  const diff = moment( cell.row.original.stop )
                    .diff( moment( cell.row.original.start ) );
                  const hours = Math.floor( moment.duration( diff ).asHours() );
                  const minutes = Math.floor( moment.duration( diff ).minutes() );
                  const formattedHours = hours.toString().length === 1 ? `0${hours}` : hours;
                  const formattedMinutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;

                  return `${formattedHours}:${formattedMinutes} h`;
                },
              },
              {
                Header: 'deviation',
                accessor: 'deviation',
              },
            ]}
          />
        </>
      ) : <div className="text-center pt-5"><Intl id="noWorkLog" /></div>
  );
} );
