import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable,
  TableGenericFilter,
  TableToggler,
  Intl,
  TableSelectFilter,
  DaterangePickerBtn,
  Select,
  Switch,
  Button,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    isLoading,
    initialDates,
    defaultFilters,
    selectedRows,
    onFetchData,
    onDateChanged,
    onFetchAccommodations,
    onToggleRowChecked,
    onToggleAllRowsChecked,
    setKeywordRoomExpenses,
    setAccommodationRoomExpenses,
    onDownload,
    applyToOptions,
    onApplyToChange,
  } ) => (
    <AsyncTable
      id="apartmentExpenses"
      uncenterHeaders
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      disableSorting
      defaultFilters={defaultFilters}
      onFetchData={onFetchData}
      containerClassName="no-shadow mb-0 radius-0"
      customFilters={() => (
        <>
          <Row>
            <Col xs="auto">
              <DaterangePickerBtn
                position="left"
                initialValue={initialDates}
                key={initialDates}
                onChange={onDateChanged}
              />
            </Col>
            <Col>
              <TableToggler />
            </Col>
            <Col
              // lg={12}
              className="d-flex justify-content-end position-absolute top-0"
            >
              <Button
                extraClass="mr-4"
                title="download"
                onClick={() => onDownload()}
              />
            </Col>
          </Row>
          <h3 className="mb-3 mt-3">
            <Intl id="apartmentExpenses" />
          </h3>
        </>
      )}
      customToggleFilters={( { filters, setFilter } ) => (
        <Row>
          <Col md={8} lg={3} className="pb-3">
            <TableGenericFilter
              label="search"
              input
              filters={filters}
              setFilter={( name, value ) => {
                setKeywordRoomExpenses( value );
                setFilter( name, value );
              }}
            />
          </Col>
          <Col md={8} lg={3} className="pb-3">
            <TableSelectFilter
              name="accommodation"
              label="accommodation"
              placeholder="all"
              filters={filters}
              isClearable
              defaultOptions
              loadOptions={onFetchAccommodations}
              formatFn={( dataClient ) => dataClient?.accommodations || []}
              setFilter={( name, value ) => {
                if ( value ) {
                  setAccommodationRoomExpenses( value.value );
                } else {
                  setAccommodationRoomExpenses( null );
                }
                setFilter( name, value );
              }}
            />
          </Col>
        </Row>
      )}
      getRowProps={( row ) => ( {
        className: row.original.type === 'issue' ? 'bg-light-red' : '',
      } )}
      columns={[
        {
          Header: (
            <div className="text-left">
              <span
                className="d-inline-block"
                style={{ position: 'relative', top: 2 }}
              >
                <Switch
                  name="header"
                  formGroupClass="m-0 pl-3 d-inline-block"
                  disabled={!data.data.length || !canModify( 'accommodation' )}
                  checked={
                    data.data
                    && data.data.length
                    && data.data.length
                      === Object.entries( selectedRows ).filter( ( [, value] ) => value )
                        .length
                  }
                  hideSideLabels
                  onChange={onToggleAllRowsChecked}
                />
              </span>
              <span className="align-top ml-1 d-inline-block">
                <Intl id="invoiced" />
              </span>
            </div>
          ),
          id: 'check',
          accessor: 'id',
          maxWidth: 155,
          Cell: ( { cell } ) => {
            const title = `${cell.row.original.id.slice(
              cell.row.original.id.length - 6,
              cell.row.original.id.length,
            )}`;
            return (
              <div className="text-left">
                <Switch
                  name={cell.value}
                  disabled={!cell.row.original.applyTo || !canModify( 'accommodation' )}
                  formGroupClass="m-0 pl-3 d-inline-block"
                  checked={selectedRows[cell.value]}
                  hideSideLabels
                  onChange={( e ) => {
                    onToggleRowChecked( cell.value, e.target.checked );
                  }}
                />
                <b className="text-uppercase pl-2">{title}</b>
              </div>
            );
          },
        },
        {
          Header: 'name',
          accessor: 'name',
        },
        {
          Header: 'description',
          accessor: 'description',
        },
        {
          Header: 'accommodation',
          accessor: 'accommodation',
          Cell: ( { cell } ) => cell.value.name,
        },
        {
          Header: 'date',
          accessor: 'date',
          Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY' ) : '' ),
        },
        {
          Header: 'applyTo',
          accessor: 'applyTo',
          maxWidth: 200,
          Cell: ( { cell } ) => (
            <div className="text-left">
              <Select
                placeholder="none"
                isDisabled={cell.row.original.invoiced}
                translateOptions
                value={cell.value}
                isClearable
                options={applyToOptions}
                onChange={( value ) => {
                  onApplyToChange( value, cell.row.values.check );
                }}
              />
            </div>
          ),
        },
        {
          Header: 'cost',
          accessor: 'cost',
        },
      ]}
    />
  ),
);
