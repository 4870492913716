import React from 'react';
import { connect } from 'react-redux';
import PaymentInfoView from 'views/Booking/Edit/PaymentInfo';
import { useFetch } from 'hooks';
import BookingService from 'api/BookingItem';
import SettingsActions from 'store/reducers/Settings';

const PaymentInfo = ( { bookingData, toggleErrorAlert } ) => {
  const bookingDataId = bookingData?.id || null;

  const [data, dataLoading] = useFetch( {
    initialState: null,
    promise: () => BookingService.getBookingInfoPayment( bookingDataId ),
    toggleErrorAlert,
    conditional: bookingDataId !== null,
    deps: [bookingDataId],
  } );
  return <PaymentInfoView isLoading={dataLoading} data={data?.data || null} />;
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( PaymentInfo );
