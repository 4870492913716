export const billingTypes = [
  { id: 'FI', name: 'billingType.FI' },
  { id: 'FM', name: 'billingType.FM' },
  { id: 'FP', name: 'billingType.FP' },
];

export const taxesTypes = [
  { id: 'I', name: 'taxesType.I' },
  { id: 'R', name: 'taxesType.R' },
  { id: 'E', name: 'taxesType.E' },
];

export const transactionCodes = [
  { id: '1', name: 'transactionCode.1' },
  { id: '15', name: 'transactionCode.15' },
];
