import { createReducer, createActions } from 'reduxsauce';

const rltLocale = ['ar'];
export const INITIAL_STATE = {
  notificationCenterCollapsed: true,
  reloadNotificationsFlag: 0,
  unreadNotifications: 0,
  sidebarCollapsed: true,
  isDirectionRTL: false,
  isLoading: false,
  locale: {
    locale: 'en',
    name: 'English',
    icon: 'gb',
  },
  appVersion: undefined,
};

const { Types, Creators } = createActions( {
  toggleNotificationCenter: null,
  setUnreadNotifications: ['unreadNotifications'],
  setReloadNotificationsFlag: ['reloadNotificationsFlag'],
  toggleSidebar: null,
  toggleAlert: ['msg', 'alertType'],
  toggleInfoAlert: ['msg'],
  toggleErrorAlert: ['msg'],
  toggleLoading: ['isLoading'],
  switchLanguage: ['locale'],
  switchActiveMenu: ['menu'],
  updateAppVersion: ['version'],
  resetSettings: null,
} );

const toggleNotificationCenter = ( state ) => ( {
  ...state,
  notificationCenterCollapsed: !state.notificationCenterCollapsed,
} );
const setUnreadNotifications = ( state, { unreadNotifications } ) => ( {
  ...state,
  unreadNotifications,
} );
const setReloadNotificationsFlag = ( state, { reloadNotificationsFlag } ) => ( {
  ...state,
  reloadNotificationsFlag,
} );
const toggleSidebar = ( state ) => ( {
  ...state,
  sidebarCollapsed: !state.sidebarCollapsed,
} );
const switchLanguage = ( state, { locale } ) => ( {
  ...state,
  locale,
  isDirectionRTL: rltLocale.includes( locale.locale ),
} );
const toggleAlert = ( state, { msg, alertType } ) => ( {
  ...state,
  alert: { date: new Date().valueOf(), msg, alertType },
} );
const toggleErrorAlert = ( state, { msg } ) => ( {
  ...state,
  alert: { date: new Date().valueOf(), msg, alertType: 'danger' },
} );
const toggleInfoAlert = ( state, { msg } ) => ( {
  ...state,
  alert: { date: new Date().valueOf(), msg, alertType: 'info-nomads' },
} );
const toggleLoading = ( state, { isLoading } ) => ( { ...state, isLoading } );

const switchActiveMenu = ( state, { menu } ) => ( {
  ...state,
  activeMenu: menu,
} );

const updateAppVersion = ( state, { version } ) => ( {
  ...state,
  appVersion: version.appVersion,
} );

const resetSettings = ( state ) => ( {
  ...state,
  ...INITIAL_STATE,
} );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.TOGGLE_NOTIFICATION_CENTER]: toggleNotificationCenter,
  [Types.SET_UNREAD_NOTIFICATIONS]: setUnreadNotifications,
  [Types.SET_RELOAD_NOTIFICATIONS_FLAG]: setReloadNotificationsFlag,
  [Types.TOGGLE_SIDEBAR]: toggleSidebar,
  [Types.SWITCH_LANGUAGE]: switchLanguage,
  [Types.TOGGLE_ALERT]: toggleAlert,
  [Types.TOGGLE_INFO_ALERT]: toggleInfoAlert,
  [Types.TOGGLE_ERROR_ALERT]: toggleErrorAlert,
  [Types.TOGGLE_LOADING]: toggleLoading,
  [Types.SWITCH_ACTIVE_MENU]: switchActiveMenu,
  [Types.UPDATE_APP_VERSION]: updateAppVersion,
  [Types.RESET_SETTINGS]: resetSettings,
} );

export const SettingTypes = Types;
export default Creators;
