import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  AsyncTable,
  TableActionsBtns,
  TableGenericFilter,
  TableSelectFilter,
  TableToggler,
  TextField,
  Switch,
} from 'components';
import { Link } from 'react-router-dom';
import contactCycleOptions from 'config/Constants/ContactCycles';

const filterColumnMd = 6;
const filterColumnLg = 2;

const ContactsView = ( {
  path,
  data,
  isLoading,
  onFetchData,
  onDeleteContact,
  toggleActivation,
} ) => (
  <>
    <AsyncTable
      id="accessControl"
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      // disableSorting
      // defaultFilters={defaultFilters}
      onFetchData={onFetchData}
      customFilters={( { filters, setFilter } ) => (
        <Row>
          <Col md={8} lg={4}>
            <TableGenericFilter filters={filters} setFilter={setFilter} />
          </Col>
          <TableToggler />
        </Row>
      )}
      customToggleFilters={( { filters, setFilter } ) => (
        <Row className="ml-0">
          <Col xmd={filterColumnMd} xlg={filterColumnLg} className="pl-0 py-2">
            <TextField
              name="email"
              label="email"
              type="email"
              placeholder="all"
              formGroupClass="m-0"
              value={filters.email}
              onChange={( value ) => setFilter( 'email', value )}
            />
          </Col>
          <Col md={5} lg={2} className="pl-0 py-2">
            <TableSelectFilter
              name="cycle"
              label="crm.contacts.cycle"
              placeholder="all"
              filters={filters}
              isClearable
              options={contactCycleOptions}
              // defaultOptions
              // loadOptions={onFetchProperties}
              setFilter={setFilter}
            />
          </Col>
          <Col md={3} lg={2} className="pl-0 py-2">
            <TableSelectFilter
              name="isActive"
              label="active"
              placeholder="all"
              filters={filters}
              isClearable
              translateOptions
              options={[
                { id: 'true', name: 'yes' },
                { id: 'false', name: 'no' },
              ]}
              // defaultOptions
              // loadOptions={onFetchProperties}
              setFilter={setFilter}
            />
          </Col>
          <Col xmd={filterColumnMd} xlg={filterColumnLg} className="pl-0 py-2">
            <TextField
              name="owner"
              label="owner"
              placeholder="all"
              formGroupClass="m-0"
              value={filters.owner}
              onChange={( value ) => setFilter( 'owner', value )}
            />
          </Col>
        </Row>
      )}
      columns={[
        {
          Header: 'active',
          accessor: 'isActive',
          maxWidth: 80,
          Cell: ( { cell } ) => (
            <Switch
              name="isActive"
              formGroupClass="m-0 p-0 d-inline-block"
              value={cell.value}
              hideSideLabels
              onChange={( e ) => toggleActivation( cell.row.original.id, e.target.checked )}
            />
          ),
        },
        {
          Header: 'name',
          accessor: 'name',
          Cell: ( { cell } ) => (
            <Link to={`${path}/${cell.row.original.id}`}>{cell.value}</Link>
          ),
        },
        {
          Header: 'email',
          accessor: 'email',
          Cell: ( { cell } ) => (
            <a
              href={`mailto:${cell.value}`}
              className="text-blue text-underline"
            >
              {cell.value}
            </a>
          ),
        },
        {
          Header: 'phone',
          accessor: 'phone',
          Cell: ( { cell } ) => (
            <a href={`tel:${cell.value}`} className="text-blue text-underline">
              {cell.value}
            </a>
          ),
        },
        {
          Header: 'owner',
          accessor: 'owner',
        },
        {
          Header: 'crm.contacts.cycle',
          accessor: 'cycle',
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  isDelete: true,
                  onClick: () => {
                    onDeleteContact( cell.row.original.id );
                  },
                },
              ]}
            />
          ),
        },
      ]}
    />
  </>
);

export default ContactsView;
