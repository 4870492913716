import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SmartlockersView from 'views/Property/RoomType/Accommodation/Edit/Smartlocker';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData, useSubmit } from 'hooks';
import KeysService from 'api/Keys';
import format from './utils';

const SmartLockers = ( {
  data,
  entityId,
  paths,
  extraData,
  onReloadData,
  toggleInfoAlert,
  toggleLoading,
  // toggleErrorAlert,
} ) => {
  const [plugins, setPlugins] = useState( [] );
  // const [configuredPlugins, setConfiguredPlugins] = useState( [] );
  const { formatPlugins } = format;

  const roomtypeId = data.entity ? data.entity.roomType.id : '';
  const propertyId = data.entity ? data.entity.roomType.property.id : '';

  useEffect( () => {
    try {
      const getPlugins = async () => {
        const { data: pluginsData } = await KeysService.getPlugins();
        // eslint-disable-next-line operator-linebreak
        // const { data: configuredpluginsData } =
        //   await KeysService.getConfiguredPlugins( entityId );
        setPlugins( formatPlugins( pluginsData.data ) );
        // setConfiguredPlugins( formatConfiguredPlugins( configuredpluginsData ) );
      };
      getPlugins();
    } catch ( error ) {
      // eslint-disable-next-line no-console
      console.error( error );
    }
  }, [formatPlugins] );

  const [fetchData, _data, dataLoading] = useFetchTableData( {
    promise: () => KeysService.getConfiguredPlugins( entityId ),
    deps: [entityId],
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      KeysService.configurePlugin( propertyId, roomtypeId, entityId, dataToSend ),
    format: ( dataToSend ) => {
      const { plugin, lockerId } = dataToSend;
      const formattedData = {
        lockerId,
        provider: plugin,
        lockerName: plugin,
      };
      return formattedData;
    },
    toggleLoading,
    toggleInfoAlert,
    callback: () => {
      onReloadData();
      fetchData();
    },
    deps: [data],
  } );

  return (
    <SmartlockersView
      name={data.entity ? data.entity.name : ''}
      data={{
        ...data,
        customFields: [
          {
            type: 'accommodation',
            dataType: 'text',
            name: 'lockerId',
            displayName: 'Locker Id',
            required: true,
            category: 'smartlockers',
            allowFilter: false,
            options: [],
          },
        ],
      }}
      configuredPlugins={_data}
      onFetchData={fetchData}
      dataLoading={dataLoading}
      paths={paths}
      extraData={{ ...extraData, plugins }}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( SmartLockers );
