import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import {
  SimpleHeader,
  DetailCard,
  Form,
  TextField,
  FormActionsBtns,
  TranslationLayout,
} from 'components';

const ChatSettings = React.memo( ( { data, onSubmit, isLoading } ) => (
  <>
    <SimpleHeader title="chatSettings" />
    <Container
      fluid
      className={classnames( { 'app-component-loading': isLoading } )}
    >
      <Row>
        <Col>
          <DetailCard>
            <Form
              initialValues={data}
              showErrorsInFooter
              onSubmit={onSubmit}
              body={( ) => (
                <TranslationLayout
                  translationFields={( locale ) => (
                    <Row>
                      <Col>
                        <TextField
                          field={`translations.${locale}.supportChatTitle`}
                          label="supportChatTitle"
                        />
                      </Col>
                    </Row>
                  )}
                >
                  <Row>
                    <Col md={6}>
                      <TextField
                        field="supportChatTitle"
                        label="supportChatTitle"
                      />
                    </Col>
                  </Row>
                </TranslationLayout>
              )}
              footer={<FormActionsBtns />}
            />
          </DetailCard>
        </Col>
      </Row>
    </Container>
  </>
) );

export default ChatSettings;
