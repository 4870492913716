import React from 'react';
import { Container } from 'reactstrap';
import {
  SimpleHeader,
} from 'components';

const Communications = () => (
  <>
    <SimpleHeader
      title="crm.communications"
    />
    <Container fluid>Communications Dashboard (TO DO)</Container>
  </>
);

export default Communications;
