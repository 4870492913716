import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import SettingsActions from 'store/reducers/Settings';
import ShiftEditView from 'views/Shift/Edit';
import TeamService from 'api/Team';
import { useFetch } from 'hooks';
import ShiftService from 'api/Shift';
import PropertyService from 'api/Property';
import { formatDataToSend, handlePromise } from 'utils';

const ShiftEdit = ( {
  shiftId,
  modalOpened,
  onCloseModal,
  onReload,
  toggleErrorAlert,
  toggleInfoAlert,
  toggleLoading,
  preloadedData,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ShiftService.getShift( shiftId ),
    format: ( dataToFormat ) => {
      if ( !shiftId ) return dataToFormat;
      let location = '';
      if ( dataToFormat.location ) {
        location = {
          value: dataToFormat.location.id,
          label: `${dataToFormat.location.name} (${dataToFormat.location.address})`,
        };
      }

      return {
        ...dataToFormat,
        location,
      };
    },
    toggleErrorAlert,
    conditional: !!shiftId && modalOpened,
    deps: [shiftId, modalOpened],
    reInit: true,
  } );
  const currentTeamId = _.get( data, 'team.id' );

  const fetchTeams = useCallback( ( query ) => TeamService.getTeams( {
    page: -1,
    keyword: query,
  } ), [] );

  const fetchProperties = useCallback( ( query ) => PropertyService.getProperties( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );

    if ( shiftId ) {
      const formattedData = {
        from: dataToSend.from && _.isObject( dataToSend.from ) ? dataToSend.from.format( 'H:mm' ) : dataToSend.from,
        till: dataToSend.till && _.isObject( dataToSend.till ) ? dataToSend.till.format( 'H:mm' ) : dataToSend.till,
        location: dataToSend.location,
        assigned: dataToSend.assigned,
        team: currentTeamId,
      };
      const [errors, response] = await handlePromise(
        ShiftService.updateShift( shiftId, formattedData ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      return onReload();
    }

    const asignedId = _.get( preloadedData, 'assigned.employeeId' );
    const teamId = _.get( preloadedData, 'assigned.teamId' );
    let date = _.get( preloadedData, 'date' );
    date = date ? date.format( 'YYYY-MM-DD' ) : date;

    const formattedData = {
      team: dataToSend.team || teamId,
      date: dataToSend.date ? dataToSend.date.format( 'YYYY-MM-DD' ) : date,
      from: dataToSend.from ? dataToSend.from.format( 'H:mm' ) : dataToSend.from,
      till: dataToSend.till ? dataToSend.till.format( 'H:mm' ) : dataToSend.till,
      assigned: dataToSend.assigned || asignedId,
      location: dataToSend.location,
    };

    const [errors, response] = await handlePromise(
      ShiftService.saveShift( formattedData ),
    );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
    return onReload();
  }, [shiftId, onReload, toggleInfoAlert, toggleLoading, preloadedData, currentTeamId] );

  return (
    <ShiftEditView
      shiftId={shiftId}
      data={data}
      preloadedData={preloadedData}
      isLoading={dataLoading}
      onFetchProperties={fetchProperties}
      open={modalOpened}
      onClose={onCloseModal}
      onSubmit={submitForm}
      onFetchTeams={fetchTeams}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ShiftEdit );
