import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import IncidenceEditView from 'views/Incidence/EditDrawer';
import IncidenceNewView from 'views/Incidence/New';
import SettingsActions from 'store/reducers/Settings';
import IncidenceActions from 'store/reducers/Incidence';
import AccommodationService from 'api/Accommodation';
import TaskTypeService from 'api/TaskType';
import IncidenceService from 'api/incidences';
import {
  formatDataToSend,
  getResourceName,
  handlePromise,
} from 'utils';
import useFetch from 'hooks/useFetch';
import { waterfall } from 'utils/async';

const IncidenceEdit = ( {
  incidenceId,
  preloadedData = {},
  modalOpened,
  toggleIncidenceEditModal,
  incidenceReloadedAt,
  incidenceReloadedId,
  onReload,
  onCloseModal,
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
  zIndex,
  onUpdateFieldIncidence,
  inEditor,
} ) => {
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( false );
  const isInitialMount = useRef( true );
  const [backgroundReloaded, setBackgroundReloaded] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const openEditModal = useCallback(
    ( { id } = {} ) => {
      setTimeout( () => {
        toggleIncidenceEditModal( id );
      }, 500 );
    },
    [toggleIncidenceEditModal],
  );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else if (
      modalOpened
      && incidenceId
      && incidenceReloadedId
      && incidenceReloadedAt
      && incidenceReloadedId === incidenceId
    ) {
      setBackgroundReloaded( true );
    }
  }, [incidenceReloadedAt, incidenceReloadedId, incidenceId, modalOpened] );

  const getEntityData = useCallback(
    async ( cb ) => {
      if ( !incidenceId ) return cb( null, {} );
      const [errors, response, responseData] = await handlePromise(
        IncidenceService.getIncidence( incidenceId ),
      );

      if ( !response.ok ) return cb( errors );
      return cb( null, { entity: responseData } );
    },
    [incidenceId],
  );

  const getAccommodation = useCallback( async ( accommodationId, cb ) => {
    const [errors, response, responseData] = await handlePromise(
      AccommodationService.getGenericAccommodation( accommodationId ),
    );
    if ( !response.ok ) return cb( errors );
    return cb( null, responseData );
  }, [] );

  useEffect( () => {
    if ( !modalOpened ) return;
    setDataLoading( true );

    waterfall(
      [
        getEntityData,
        // getCustomFields,
        ( entityData, cb ) => {
          if ( !_.get( entityData, 'entity.accommodation' ) ) { return cb( null, entityData ); }
          getAccommodation(
            entityData.entity.accommodation,
            ( error, accommodationResponse ) => {
              if ( error ) return cb( error );
              return cb( null, {
                ...entityData,
                accommodation: accommodationResponse,
              } );
            },
          );
        },
      ],
      ( errors, results ) => {
        setDataLoading( false );
        if ( errors ) {
          return toggleErrorAlert( errors );
        }
        const formattedData = { ...results.entity };
        if ( results.entity && results.entity.id ) {
          if ( results.entity.taskType ) {
            formattedData.taskType = {
              value: results.entity.taskType.id,
              label: results.entity.taskType.name,
              estimatedTime: results.entity.taskType.estimatedTime,
            };
          }
          if ( results.entity.assigned && results.entity.assigned.length ) {
            const { assigned } = results.entity;
            formattedData.assignedTo = {
              value: assigned[assigned.length - 1].employee.id,
              label: getResourceName( assigned[assigned.length - 1].employee ),
            };
          }
          if ( results.accommodation ) {
            formattedData.accommodation = {
              value: results.accommodation.id,
              label: `${results.accommodation.name} (${results.accommodation.roomType.property.propertyManagerCityZone.name} - ${results.accommodation.roomType.property.propertyManagerCityZone.propertyManagerCity.city.name})`,
              zone: results.accommodation.roomType.property
                .propertyManagerCityZone.id,
            };
          }
        }
        setData( formattedData );
        // setCustomFields( results.customFields );
        setBackgroundReloaded( false );
      },
    );
  }, [
    modalOpened,
    dataSubmitted,
    getEntityData,
    // getCustomFields,
    getAccommodation,
    toggleErrorAlert,
  ] );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const dataToSend = formatDataToSend( formData, form );
      let formattedData = {};

      if ( formData.bookedAccommodation ) {
        formattedData.bookedAccommodation = formData.bookedAccommodation;
      }
      // if ( customFields && customFields.length ) {
      //   formattedData = formatCustomValuesToSend( formData, customFields );
      // }
      formattedData = {
        ...formattedData,
        accommodation: dataToSend.accommodation,
        name: dataToSend.description,
        description: dataToSend.description,
        priority: dataToSend.priority,
        taskType: dataToSend.taskType,
        date: dataToSend.date
          ? dataToSend.date.format( 'YYYY-MM-DD HH:mm:ss' )
          : dataToSend.date,
      };

      const [errors, response, taskData] = await handlePromise(
        IncidenceService.saveIncidence( formattedData ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      openEditModal( { id: taskData.id } );
      return onReload();
    },
    [openEditModal, onReload, toggleInfoAlert, toggleLoading],
  );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  return !incidenceId ? (
    <IncidenceNewView
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
      onFetchAccommodations={fetchAccommodations}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
      preloadedData={preloadedData}
      zIndex={zIndex || 9999}
    />
  ) : (
    <IncidenceEditView
      inEditor={inEditor}
      incidenceId={incidenceId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onClose={onCloseModal}
      setData={setData}
      onReload={reloadData}
      backgroundReloaded={backgroundReloaded}
      onUpdateFieldIncidence={onUpdateFieldIncidence}
    />
  );
};

const mapStateToProps = ( { user, incidence } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
  incidenceReloadedAt: incidence.reloadedAt,
  incidenceReloadedId: incidence.incidenceId,
} );

const mapDispatchToProps = {
  toggleIncidenceEditModal: IncidenceActions.toggleIncidenceEditModal,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( IncidenceEdit );
