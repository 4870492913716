import React from 'react';
import {
  LocalTable,
  AddBtn,
  TableActionsBtns,
  Intl,
  CanAccess,
  TableEditBtn,
  TableGenericFilter,
} from 'components';

import { canModify } from 'config/Ability';
import { Row, Col, Button } from 'reactstrap';

export default React.memo( ( {
  data, isLoading, toggleEditModal, onDelete, onDownloadData, urlDownload,
  linkDownloadRef, keyword, setKeyword,
} ) => (
  <LocalTable
    data={data}
    isLoading={isLoading}
    customFilters={( ) => (
      <Row>
        <Col md={8} lg={4}>
          <TableGenericFilter
            filters={{ keyword }}
            setFilter={( k, value ) => {
              setKeyword( value );
            }}
          />
        </Col>
      </Row>
    )}
    rightContent={(
      <>
        <Button color="primary" onClick={onDownloadData}>
          <i className="fa fa-download mr-1" />
          <Intl id="download" />
        </Button>
        <a
          href={urlDownload}
          download
          ref={linkDownloadRef}
          style={{ display: 'none' }}
        >
          download
        </a>
        <CanAccess I="edit" a="accommodation">
          <AddBtn
            title="newInventory"
            onClick={() => {
              toggleEditModal( null );
            }}
          />
        </CanAccess>
      </>
    )}
    containerClassName="card-sec-bottom"
    columns={[
      {
        Header: 'name',
        accessor: 'amenity',
        Cell: ( { cell } ) => (
          <TableEditBtn
            title={cell.value.name}
            onClick={() => {
              toggleEditModal( cell.row.original._id );
            }}
          />
        ),
      },
      {
        Header: 'type',
        accessor: 'amenity',
        Cell: ( { cell } ) => <Intl id={cell.value.type} />,
      },

      {
        Header: 'belongTo',
        accessor: 'belongTo',
        Cell: ( { cell } ) => <Intl id={cell.value} />,
      },
      {
        Header: 'estimatedValue',
        accessor: 'cost',
      },
      {
        Header: 'section',
        accessor: 'section',
      },
      {
        Header: 'quantity',
        accessor: 'quantity',
      },
      {
        Header: 'activeClient',
        accessor: 'activeClient',
        Cell: ( { cell } ) => ( cell.value ? <Intl id="yes" /> : <Intl id="no" /> ),
      },
      {
        Header: 'activeEmployee',
        accessor: 'activeEmployee',
        Cell: ( { cell } ) => ( cell.value ? <Intl id="yes" /> : <Intl id="no" /> ),
      },
      canModify( 'accommodation' )
        ? {
          accessor: '_id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ]}
            />
          ),
        }
        : {
          id: 'id',
          Header: null,
          maxWidth: 1,
          disableSorting: true,
        },
    ]}
  />
) );
