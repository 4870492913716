import React from 'react';
import numeral from 'numeral';
import {
  Modal,
} from 'reactstrap';
import {
  AsyncTable, AddBtn, Intl, TableActionsBtns,
} from 'components';
import EditFeeContainer from 'containers/PropertyManager/Edit/Finance/Fees/edit';
import { currencyFormat } from 'utils';

const PropertyManagerFeesView = ( {
  pmData,
  fetchData,
  dataTable,
  dataLoading,
  defaultFilters,
  userCurrency,
  reload,
  onDelete,
  toggleModalEditor,
  modalAddOpen,
  elementToEdit,
} ) => (
  <>
    <AsyncTable
      id="feesTable"
      data={dataTable.data}
      total={dataTable.elementsTotal}
      isLoading={dataLoading}
      rightContent={(
        <AddBtn
          title="newFee"
          onClick={() => {
            toggleModalEditor( true, null );
          }}
        />
      )}
      disableSorting
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
      columns={[
        {
          Header: '#',
          accessor: 'id',
          Cell: ( { cell } ) => ( <a href="/" onClick={( e ) => { e.preventDefault(); toggleModalEditor( true, cell.row.original ); }}>{cell.value.slice( 0, 6 )}</a> ),
        },
        {
          Header: 'name',
          accessor: 'name',
        },
        {
          Header: 'cost',
          accessor: 'cost',
          Cell: ( { cell } ) => ( cell.value ? numeral( ( cell.value ) ).format( currencyFormat( userCurrency ) ) : '' ),
        },
        {
          Header: 'periodicity',
          accessor: 'periodicity',
          Cell: ( { cell } ) => (
            <Intl id={cell.value || '-'} />
          ),
        },
        {
          Header: 'fees.billingDate',
          accessor: 'billingDate',
          Cell: ( { cell } ) => ( <Intl id={cell.value || ''} /> ),
        },
        {
          Header: 'fees.table.delayAllowedToPayInDays',
          accessor: 'delayAllowedToPayInDays',
          Cell: ( { cell } ) => (
            <>
              {`${cell.value} `}
              <Intl id={cell.value === 1 ? 'day' : 'days'} />
            </>
          ),
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  label: 'edit',
                  // onClick: ( ) => onEdit( data ),
                  onClick: () => { toggleModalEditor( true, cell.row.original ); },
                },
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ]}
            />
          ),
        },
      ]}
    />

    <Modal
      isOpen={modalAddOpen}
      toggle={() => {
        toggleModalEditor( false, null );
      }}
      className="modal-dialog-centered"
      size="lg"
    >
      <div className="modal-body">
        <EditFeeContainer
          pmData={pmData}
          elementToEdit={elementToEdit}
          onClose={() => {
            toggleModalEditor( false, null );
          }}
          onReloadData={() => {
            toggleModalEditor( false, null );
            reload();
          }}
        />
      </div>
    </Modal>
  </>
);

export default PropertyManagerFeesView;
