import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import EditDrawerView from 'views/PropertyManager/Edit/Discount/EditDrawer';

const EditDrawer = ( {
  discountId, open, onClose, onReloadDiscounts, onOpenModal,
} ) => (
  <EditDrawerView
    data={{ id: discountId }}
    open={open}
    onOpenModal={onOpenModal}
    onClose={onClose}
    onReloadDiscounts={onReloadDiscounts}
  />
);

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( EditDrawer );
