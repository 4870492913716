import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import AccessRequestDashboardView from 'views/Task/EditDrawer/AccessRequest/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import AccessRequestService from 'api/AccessRequest';
import { useFetchTableData } from 'hooks';
import AccessRequestEdit from '../Edit';

const AccessRequestDashboard = ( { taskId, toggleErrorAlert } ) => {
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AccessRequestService.getAccessRequests(
      { ...params, taskId },
    ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [modalOpened, closeEditModal] );

  return (
    <>
      <AccessRequestDashboardView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
      />

      <AccessRequestEdit
        accessRequestId={toEditId.current}
        taskId={taskId}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( AccessRequestDashboard );
