import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ShiftView from 'views/Resource/Edit/Shift';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
import EmployeeService from 'api/Employee';
import ShiftService from 'api/Shift';
import FiltersActions from 'store/reducers/Filters';
import { formatShifts, getSummary } from './utils';

const Shifts = ( {
  data,
  updateFilters,
  entityId,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const filterFromDate = moment().startOf( 'day' );
  const filterToDate = moment( filterFromDate ).clone().add( 30, 'day' );
  const initialDates = useRef( moment.range( filterFromDate, filterToDate ) );
  const [statistics, setStatistics] = useState( {
    programmed: '00:00:00',
    elapsed: '00:00:00',
    deviation: '00:00:00',
  } );

  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => EmployeeService.getShifts( entityId, params ),
    toggleErrorAlert,
    format: formatShifts,
    callback: ( params ) => {
      updateFilters( 'resourceShifts', {
        from: params.from,
        till: params.till,
      } );
    },
    deps: [entityId, dataSubmitted],
  } );

  useEffect( () => {
    if ( dataTable.data ) {
      setStatistics( getSummary( dataTable.data ) );
    }
  }, [dataTable] );

  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deleteShift = useDelete( {
    promise: ShiftService.deleteShift,
    callback: reload,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <ShiftView
        entityId={entityId}
        data={data}
        statistics={statistics}
        fetchData={fetchData}
        dataTable={dataTable}
        dataLoading={dataLoading}
        defaultFilters={{
          from: initialDates.current.start
            ? initialDates.current.start.format( 'YYYY-MM-DD' )
            : moment(),
          till: initialDates.current.end
            ? initialDates.current.end.format( 'YYYY-MM-DD' )
            : moment().add( 30, 'days' ),
        }}
        reload={reload}
        onDelete={deleteShift}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateFilters: FiltersActions.updateFilters,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( Shifts );
