import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import FeeServices from 'api/Fees';
import useDelete from 'hooks/useDelete';
import AdminRatesPropertyManagerFeesView from 'views/AdminRates/PropertyManager/Fees';

const AdminRatesPropertyManagerFees = ( {
  userCurrency,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  /* FETCH TABLE ********************************** */
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => FeeServices.getFeeList( params ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );
  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );
  /* end FETCH TABLE ********************************** */

  const deleteFee = useDelete( {
    promise: FeeServices.deleteFee,
    callback: reload,
    toggleLoading,
    toggleErrorAlert,
  } );

  /** **************************************** */
  const [modalAddOpen, setModalAddOpen] = useState( false );
  const [elementToEdit, setElementToEdit] = useState( null );

  const toggleModalEditor = useCallback( ( status, elementData ) => {
    setElementToEdit( elementData );
    setModalAddOpen( status );
  }, [] );

  return (
    <AdminRatesPropertyManagerFeesView
      fetchData={fetchData}
      dataTable={dataTable}
      dataLoading={dataLoading}
      userCurrency={userCurrency || 'EUR'}
      reload={reload}
      onDelete={deleteFee}
      toggleModalEditor={toggleModalEditor}
      modalAddOpen={modalAddOpen}
      elementToEdit={elementToEdit}
    />
  );
};
//

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( AdminRatesPropertyManagerFees );
