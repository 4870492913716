import React from 'react';
import { Route, Switch } from 'react-router';

import { canList } from 'config/Ability';
import Dashboard from './Dashboard';
import TaskEditor from './Editor';

const Task = ( { match } ) => (
  <Switch>

    {canList( 'task' )
    && (
      <Route
        path={`${match.url}/:taskId`}
        render={( props ) => <TaskEditor {...props} />}
        pathLayout={match.url}
      />
    )}
    {canList( 'task' )
    && (
      <Route
        path={match.url}
        component={Dashboard}
      />
    )}
  </Switch>
);

export default Task;
