import React from 'react';
import { getInitials } from 'utils';

export default React.memo( ( {
  data, alt = '...', opened, onClick,
} ) => (
  <div className="marker" onClick={onClick}>
    <div className="marker-circle" id={data[0]._id} />
    {!opened
    && (
      <div className="marker-avatar">
        {data[0].assigned.employee.profilePicture
          ? <img alt={alt} src={data[0].assigned.employee.profilePicture} />
          : <div className="marker-avatar-initials">{getInitials( data[0].assigned.employee )}</div>}
        {data.length > 1 && (
          <span className="marker-icon">
            +
            {data.length - 1}
          </span>
        )}
      </div>
    )}
  </div>
) );
