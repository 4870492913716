import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  TextField, ModalForm, Phone, Select, Switch,
} from 'components';
import { required, email, composeValidators } from 'config/InputErrors';
import contactCycleOptions from 'config/Constants/ContactCycles';

const ContactNewView = ( {
  isLoading,
  modalNewContactOpened,
  toggleModalNewContact,
  onSubmit,
} ) => (
  <ModalForm
    initialValues={{ isActive: true }}
    title="crm.contacts.new"
    showErrorsInFooter
    isLoading={isLoading}
    bodyClassName="overflow-visible"
    open={modalNewContactOpened}
    onClose={toggleModalNewContact}
    onSubmit={onSubmit}
    body={() => (
      <>
        <Row className="mb-3">
          <Col md={9}>
            <TextField
              label="name"
              field="name"
              placeholder="name"
              validate={required}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={7}>
            <TextField
              label="email"
              type="email"
              field="email"
              placeholder="email"
              validate={composeValidators( required, email )}
            />
          </Col>
          <Col md={5}>
            <Phone
              field="phone"
              label="phone"
              validate={required}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label="owner"
              field="owner"
              placeholder="owner"
              validate={required}
            />
          </Col>
          <Col>
            <Select
              label="crm.contacts.cycle"
              field="cycle"
              placeholder="crm.contacts.cycle"
              options={contactCycleOptions}
              validate={required}
            />
          </Col>
          <Col>
            <Switch field="isActive" label="active" />
          </Col>
        </Row>
      </>
    )}
  />
);

export default ContactNewView;
