import React from 'react';
import CKEditor from 'ckeditor4-react';
import classnames from 'classnames';
// Config CKEditor
// http://cdn.ckeditor.com/4.13.0/full-all/samples/toolbarconfigurator/index.html#advanced
import { Intl } from 'components';

const RichtTextInput = React.memo(
  ( {
    translateValues,
    onChange,
    inputOnChange,
    value,
    isInvalid,
    meta,
    height,
    ...rest
  } ) => (
    <div
      className={classnames( 'form-group', {
        'is-invalid': isInvalid,
      } )}
    >
      <CKEditor
        data={value}
        config={{
          toolbar: [
            ['Bold', 'Italic', 'Strike'],
            ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
            ['TextColor', 'BGColor'],
            ['NumberedList', 'BulletedList'],
            ['Link', 'Unlink', 'Anchor', 'Image', 'Table'],
            ['FontSize', 'Format'],
            ['Maximize'],
          ],
          format_tags: 'p;h1;h2;h3;h4;pre',
          height: height || 300,
          extraPlugins: 'justify,font,colorbutton',
        }}
        onChange={( event ) => {
          const bodyEmail = event.editor.getData();
          if ( typeof onChange === 'function' ) onChange( bodyEmail );
          if ( typeof inputOnChange === 'function' ) inputOnChange( bodyEmail );
        }}
        {...rest}
      />
      {isInvalid && (
      <div className="invalid-feedback">
        <Intl
          id={meta.error || meta.submitError}
          values={translateValues}
        />
      </div>
      )}
    </div>
  ),
);
export default RichtTextInput;
