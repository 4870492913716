import api from 'config/Api/Api';

const baseEndpoint = '/posts';

export default {
  getPosts( params ) {
    return api.get( baseEndpoint, params );
  },

  getPost( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  savePost( data ) {
    return api.post( baseEndpoint, data );
  },

  updatePost( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deletePost( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
