import React from 'react';
import {
  TextField, ModalForm,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  tagToEdit, open, onSubmit, onClose,
} ) => (
  <ModalForm
    initialValues={{ tag: tagToEdit || '' }}
    title={tagToEdit ? 'editTagPredefined' : 'newTagPredefined'}
    showErrorsInFooter
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
    size="md"
  >
    <TextField
      field="tag"
      label="tag"
      validate={required}
    />
  </ModalForm>
) );
