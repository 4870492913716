import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, ModalForm,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  extraExpenseId, data, isLoading, open, onClose, onSubmit,
} ) => (
  <ModalForm
    initialValues={data}
    title={extraExpenseId ? 'editExtraExpense' : 'newExtraExpense'}
    showErrorsInFooter
    isLoading={isLoading}
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <Row>
      <Col md={6}>
        <TextField
          field="name"
          label="name"
          validate={required}
        />
      </Col>
      <Col md={6}>
        <TextField
          field="cost"
          label="cost"
          type="number"
          validate={required}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <TextField
          field="description"
          label="description"
          type="textarea"
          rows={4}
          validate={required}
        />
      </Col>
    </Row>

  </ModalForm>
) );
