import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import moment from 'moment';
import { required } from 'config/InputErrors';
import {
  Datepicker, Intl, ModalForm,
} from 'components';

export default ( { open, onClose, onSubmit } ) => (
  <ModalForm
    title="cancelShift"
    open={open}
    size="md"
    bodyClassName="overflow-visible"
    bodyStyle={{ minHeight: 133 }}
    onClose={onClose}
    showErrorsInFooter
    onSubmit={onSubmit}
    body={() => (
      <>
        <p>
          <Intl id="shiftTemplateCancelInfo" />
        </p>
        <p className="m-0">
          <Intl id="shiftTemplateCancelDateLabel" />
        </p>
        <Row>
          <Col md={5}>
            <Datepicker
              field="date"
              dateFormat="DD/MM/YYYY"
              validate={required}
              startDateField={{ value: moment() }}
            />
          </Col>
        </Row>
      </>
    )}
    footer={(
      <>
        <Button
          color="primary"
          outline
          className="mr-3"
          onClick={onClose}
        >
          <Intl id="cancel" />
        </Button>
        <Button color="primary">
          <Intl id="yes" />
        </Button>
      </>
    )}
  />
);
