import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import {
  Row, Col, Modal, Badge,
} from 'reactstrap';
import {
  AsyncTable, DaterangePickerBtn, AddBtn, Intl, TableActionsBtns,
} from 'components';
import NewPaymentContainer from 'containers/Team/Edit/Finance/Payment/new';
import { currencyFormat } from 'utils';

const FinancePaymentView = ( {
  entityId,
  fetchData,
  dataTable,
  dataLoading,
  defaultFilters,
  userCurrency,
  reload,
  onDelete,
  toggleModalEditor,
  modalAddOpen,
  paymentToEdit,
} ) => (
  <>
    <h3>
      <Intl id="payments" />
    </h3>
    <AsyncTable
      id="resourcePayments"
      data={dataTable.data}
      total={dataTable.elementsTotal}
      isLoading={dataLoading}
      rightContent={(
        <AddBtn
          title="newPayment"
          onClick={() => {
            toggleModalEditor( true, null );
          }}
        />
)}
      disableSorting
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
      customFilters={( { filters, setMultipleFilters } ) => (
        <>
          <Row>
            <Col md={8} lg={4}>
              <DaterangePickerBtn
                position="left"
                initialValue={moment.range( filters.from || moment().subtract( 180, 'days' ), filters.till || moment() )}
                key={filters.from + filters.till}
                input
                onChange={( values ) => {
                  setMultipleFilters( {
                    from: moment( values.start ).format( 'YYYY-MM-DD' ),
                    till: moment( values.end ).format( 'YYYY-MM-DD' ),
                  } );
                }}
              />
            </Col>
          </Row>
        </>
      )}
      columns={[
        {
          Header: 'id',
          accessor: 'id',
          Cell: ( { cell } ) => ( <a href="/" onClick={( e ) => { e.preventDefault(); toggleModalEditor( true, cell.row.original ); }}>{cell.value.slice( 0, 6 )}</a> ),

        },
        {
          Header: 'description',
          accessor: 'description',
        },
        {
          Header: 'amount',
          accessor: 'amount',
          Cell: ( { cell } ) => ( cell.value ? numeral( ( cell.value ) ).format( currencyFormat( userCurrency ) ) : '' ),
        },
        {
          Header: 'gateway',
          accessor: 'gateway',
          Cell: ( { cell } ) => {
            if ( cell.value === 'addon_payments' ) return <Badge color="green-2"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'external' ) return <Badge color="red-1"><Intl id={cell.value} /></Badge>;
            return '';
          },
        },
        {
          Header: 'date',
          accessor: 'date',
          Cell: ( { cell } ) => ( cell.value

            ? moment( cell.value ).format( 'YYYY-MM-DD' )
            : '' ),
        },
        {
          Header: 'notificationDate',
          accessor: 'notificationDate',
          Cell: ( { cell } ) => ( cell.value

            ? moment( cell.value ).format( 'YYYY-MM-DD' )
            : '' ),
        },

        {
          Header: 'status',
          accessor: 'status',
          Cell: ( { cell } ) => {
            if ( cell.value === 'pending' ) return <Badge color="green-3"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'paid' ) return <Badge color="green-2"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'denied' ) return <Badge color="red-1"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'partial_refunded' ) return <Badge color="warning"><Intl id={cell.value} /></Badge>;
            if ( cell.value === 'refunded' ) return <Badge color="info"><Intl id={cell.value} /></Badge>;
            return '';
          },
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  label: 'edit',
                  // onClick: ( ) => onEdit( data ),
                  onClick: () => { toggleModalEditor( true, cell.row.original ); },
                },
                {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                },
              ]}
            />
          ),
        },
      ]}
    />

    <Modal
      isOpen={modalAddOpen}
      toggle={() => {
        toggleModalEditor( false, null );
      }}
      className="modal-dialog-centered"
      size="lg"
    >
      <div className="modal-body">
        <NewPaymentContainer
          entityId={entityId}
          paymentToEdit={paymentToEdit}
          onClose={() => {
            toggleModalEditor( false, null );
          }}
          onReloadData={() => {
            toggleModalEditor( false, null );
            reload();
          }}
        />
      </div>
    </Modal>
  </>
);

export default FinancePaymentView;
