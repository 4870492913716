import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import AccessRequestEditView from 'views/Task/EditDrawer/AccessRequest/Edit';
import AccessRequestService from 'api/AccessRequest';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import { formatDataToSend, handlePromise } from 'utils';

const AccessRequestEdit = ( {
  accessRequestId, taskId, modalOpened, onReload, onCloseModal,
  toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => AccessRequestService.getAccessRequest( taskId, accessRequestId ),
    toggleErrorAlert,
    conditional: !!accessRequestId && modalOpened,
    deps: [accessRequestId, modalOpened],
    reInit: true,
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );
    delete dataToSend.time;
    const formattedData = {
      ...dataToSend,
      date: dataToSend.date && _.isObject( dataToSend.date ) ? dataToSend.date.format( 'YYYY-MM-DD HH:mm' )
        : moment( dataToSend.date ).format( 'YYYY-MM-DD HH:mm' ),
    };
    const [errors, response] = await handlePromise( accessRequestId
      ? AccessRequestService.updateAccessRequest( taskId, accessRequestId, formattedData )
      : AccessRequestService.saveAccessRequest( taskId, formattedData ) );

    toggleLoading( false );
    if ( !response.ok ) return errors;

    toggleInfoAlert( 'dataSaved' );
    onReload();
  }, [accessRequestId, taskId, onReload, toggleInfoAlert, toggleLoading] );

  return (
    <AccessRequestEditView
      accessRequestId={accessRequestId}
      data={data}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( AccessRequestEdit );
