import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import TaskExpensesDashboardView from 'views/RoomExpenses/Task';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import TaskService from 'api/Task';
import { useFetchTableData } from 'hooks';
import AccommodationService from 'api/Accommodation';
import { handlePromise } from 'utils';
import { each } from 'utils/async';
// import { history } from 'store';

const TaskExpensesDashboard = ( {
  taskReloadedAt,
  toggleErrorAlert,
  toggleLoading,
  updateFilters,
  setFiltersError,
  searchParams,
} ) => {
  const urlSearchParams = new URLSearchParams( searchParams );
  const { getTasks } = TaskService;
  const filterFromDate = urlSearchParams.get( 'from' ) || moment().startOf( 'day' );
  const filterToDate = urlSearchParams.get( 'till' )
    || moment( filterFromDate ).clone().add( '1', 'day' );
  const dateRange = useRef( moment.range( filterFromDate, filterToDate ) );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const showLoading = useRef( true );
  const isInitialMount = useRef( true );
  const initialReloadError = useRef( true );
  const selectedRows = useRef( {} );
  const [selectedRowsChange, setSelectedRowsChange] = useState(
    new Date().valueOf(),
  );

  const forceReload = useCallback(
    () => setDataSubmitted( new Date().valueOf() ),
    [],
  );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getTasks( {
      ...params,
      expenses: true,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
      accommodation: _.get( params, 'accommodation.value' )
        ? params.accommodation.value
        : undefined,
    } ),
    callback: ( params ) => {
      initialReloadError.current = false;
      showLoading.current = true;
      updateFilters( 'roomExpenses', {
        keyword: params.keyword,
        accommodation: params.accommodation
          ? {
            value: params.accommodation.value,
            label: params.accommodation.label,
          }
          : null,
        from: dateRange.current.start
          ? dateRange.current.start.format( 'YYYY-MM-DD' )
          : undefined,
        till: dateRange.current.end
          ? dateRange.current.end.format( 'YYYY-MM-DD' )
          : undefined,
      } );

      // const search = new URLSearchParams(
      //   _.omitBy(
      //     {
      //       ...params,
      //       from: dateRange.current.start
      //         ? dateRange.current.start.format( 'YYYY-MM-DD' )
      //         : undefined,
      //       till: dateRange.current.end
      //         ? dateRange.current.end.format( 'YYYY-MM-DD' )
      //         : undefined,
      //       accommodation_value: _.get( params, 'accommodation.value' ),
      //       accommodation_label: _.get( params, 'accommodation.label' ),
      //       accommodation: null,
      //     },
      //     _.isNil,
      //   ),
      // ).toString();
      // if ( search ) {
      //   history.replace( { search: `?${search}` } );
      // }
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        dateRange.current = moment.range(
          moment().startOf( 'day' ),
          moment().startOf( 'day' ).add( '1', 'day' ),
        );
        setFiltersError( 'tasks' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  useEffect( () => {
    selectedRows.current = {};
    if ( data && data.data && data.data.length ) {
      data.data.forEach( ( item ) => {
        selectedRows.current[item.id] = item.invoice || item.invoiced || false;
      } );
    }
    setSelectedRowsChange( new Date().valueOf() );
  }, [data] );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      showLoading.current = false;
      setDataSubmitted( new Date().valueOf() );
    }
  }, [taskReloadedAt] );

  const updateDateRangeParams = useCallback( ( params ) => {
    dateRange.current = params;
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  /// ///////////////////////////////

  const setRoomExpenses = useCallback(
    async ( selectedItems ) => {
      if (
        !data
        || !data.data
        || !selectedItems
        || !Object.entries( selectedItems ).length
      ) {
        return false;
      }
      const selectedItemsList = [];

      Object.keys( selectedItems ).forEach( ( a ) => {
        selectedItemsList.push( { id: a, invoiced: selectedItems[a] } );
      } );

      const errors = [];
      each(
        selectedItemsList,
        async ( item, cb ) => {
          const currentRoomExpenseOrig = data.data.find(
            ( re ) => re.id === item.id,
          );
          const currentRoomExpense = { ...currentRoomExpenseOrig };
          delete currentRoomExpense.propertyManager;
          delete currentRoomExpense.date;
          delete currentRoomExpense.updated;
          delete currentRoomExpense.created;
          delete currentRoomExpense.id;
          delete currentRoomExpense.invoice;
          delete currentRoomExpense.bookedAccommodation;
          delete currentRoomExpense.taskType;
          const [, response] = await handlePromise(
            TaskService.updateTask( item.id, {
              invoiced: `${item.invoiced}`,
              extraCosts: [null],
            } ),
          );
          if ( !response.ok ) {
            errors.push( item.id );
          }
          return cb();
        },
        () => {
          if ( errors.length ) {
            toggleErrorAlert( 'dataSavedWithErrors' );
          } else {
            forceReload();
          }
        },
      );
    },
    [toggleErrorAlert, forceReload, data],
  );

  /// //////////////////////////////

  const setApplyToProperty = useCallback(
    async ( value, id ) => {
      toggleLoading( true );
      const [error, response] = await handlePromise(
        value
          ? TaskService.updateTask( id, { applyTo: value, extraCosts: [null] } )
          : TaskService.updateTask( id, { applyTo: 'null', extraCosts: [null] } ),
      );
      if ( !response.ok ) {
        toggleErrorAlert( error );
      }
      forceReload();
      toggleLoading( false );
    },
    [toggleLoading, toggleErrorAlert, forceReload],
  );

  const toggleRowChecked = useCallback(
    ( id, value ) => {
      selectedRows.current[id] = value;
      setSelectedRowsChange( new Date().valueOf() );
      setRoomExpenses( { [id]: value } );
    },
    [setRoomExpenses],
  );

  const toggleAllRowsChecked = useCallback( () => {
    let flagForAll = true;
    // eslint-disable-next-line no-unused-vars, max-len
    if (
      data.data
      && Object.entries( selectedRows.current ).filter( ( [, value] ) => value )
        .length === data.data.length
    ) {
      flagForAll = false;
    }

    selectedRows.current = {};
    _.map( data.data, 'id' ).forEach( ( id ) => {
      selectedRows.current[id] = flagForAll;
    } );
    setSelectedRowsChange( new Date().valueOf() );
    setRoomExpenses( selectedRows.current );
  }, [data, setRoomExpenses] );

  const urlFilters = () => {
    const filters = {
      keyword: urlSearchParams.get( 'keyword' ),
      priority: urlSearchParams.get( 'priority' ),
      status: urlSearchParams.get( 'status' ),
      type: urlSearchParams.get( 'type' ),
      taskType: urlSearchParams.get( 'taskType' ),
      property:
        urlSearchParams.get( 'property_value' )
        && urlSearchParams.get( 'property_label' )
          ? {
            value: urlSearchParams.get( 'property_value' ),
            label: urlSearchParams.get( 'property_label' ),
          }
          : null,
      accommodation:
        urlSearchParams.get( 'accommodation_value' )
        && urlSearchParams.get( 'accommodation_label' )
          ? {
            value: urlSearchParams.get( 'accommodation_value' ),
            label: urlSearchParams.get( 'accommodation_label' ),
          }
          : null,
      assigned:
        urlSearchParams.get( 'assigned_value' )
        && urlSearchParams.get( 'assigned_label' )
          ? {
            value: urlSearchParams.get( 'assigned_value' ),
            label: urlSearchParams.get( 'assigned_label' ),
          }
          : null,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
    };
    return filters;
  };

  const applyToValues = [
    { name: 'owner', label: 'owner', id: 'owner' },
    {
      name: 'propertyManager',
      label: 'propertyManager',
      id: 'propertyManager',
    },
    { name: 'client', label: 'client', id: 'client' },
  ];

  return (
    <TaskExpensesDashboardView
      data={data}
      isLoading={dataLoading && showLoading.current}
      initialDates={dateRange.current}
      defaultFilters={urlFilters()}
      selectedRows={selectedRows.current}
      selectedRowsChange={selectedRowsChange}
      onFetchData={fetchData}
      onFetchAccommodations={fetchAccommodations}
      onDateChanged={updateDateRangeParams}
      onToggleRowChecked={toggleRowChecked}
      onToggleAllRowsChecked={toggleAllRowsChecked}
      applyToOptions={applyToValues}
      onApplyToChange={setApplyToProperty}
    />
  );
};

const mapStateToProps = ( { task, router, user } ) => ( {
  taskReloadedAt: task.reloadedAt,
  searchParams: _.get( router, 'location.search', '' ),
  propertyManager: _.get( user, 'user.propertyManager.id', {} ),
} );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( TaskExpensesDashboard );
