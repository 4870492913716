import React from 'react';

import {
  TableActionsBtns,
  TableEditBtn, Intl, DetailCard, LocalTable, AddBtn,
} from 'components';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

export default React.memo(
  ( {
    data, onDeleteItem, onOpenEditItemModal, isDataLoading,
  } ) => (
    <>
      <DetailCard
        classNames={{ header: 'py-3 border-0', body: 'pt-0 pb-2 px-0', card: 'card-sec-bottom' }}
        header={(

          <Row className="justify-content-stretch align-items-center">
            <Col>
              <h3 className="m-0">
                <Intl id="contracts" />
              </h3>
            </Col>
            <Col className="text-right">
              <AddBtn title="newContract" onClick={() => onOpenEditItemModal()} />
            </Col>
          </Row>
      )}
        isLoading={isDataLoading}
      >

        <LocalTable
          data={data.data}
          manualPagination
          onlyTable
          columns={[
            {
              Header: 'name',
              accessor: 'description',
              Cell: ( { cell } ) => (
                <TableEditBtn
                  title={cell.value}
                  onClick={() => onOpenEditItemModal( cell.row.original )}
                />
              ),
            },
            {
              Header: 'created',
              accessor: 'created',
              Cell: ( { cell } ) => (
                moment( cell.value ).format( 'DD/MM/YYYY' )
              ),
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => (
                <Intl id={cell.value} />
              ),
            },
            {
              Header: 'signedDate',
              accessor: 'signedDate',
              Cell: ( { cell } ) => (
                cell.row.original.status === 'signed' ? moment( cell.value ).format( 'DD/MM/YYYY' ) : ''
              ),
            },
            {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => (
                <TableActionsBtns
                  actions={[
                    {
                      isDelete: true,
                      onClick: ( cb ) => onDeleteItem( cell.value, cb ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </DetailCard>
    </>
  ),
);
