/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';
import {
  Intl,
} from 'components';
import {
  Label, Row, Col,
} from 'reactstrap';

const changeOrder = ( stages, index, direction ) => {
  const newStages = [...stages];
  const indexDestiny = index + direction;

  if ( indexDestiny >= 0 && indexDestiny < stages.length ) {
    [newStages[index], newStages[indexDestiny]] = [newStages[indexDestiny], newStages[index]];
  }
  return newStages;
};

const StageRow = ( {
  stage, stages, setStages, index,
} ) => {
  const inputRef = useRef( null );

  useEffect( () => {
    inputRef.current.focus();
  }, [] );

  return (
    <Row className="align-items-center mb-2">
      <Col xs="auto" className="pr-2">
        {index > 0 ? (
          <div
            className="cursor-pointer pb-1"
            style={{
              lineHeight: 1,
              fontSize: 12,
            }}
            onClick={() => {
              const newStages = changeOrder( stages, index, -1 );
              setStages( newStages );
            }}
            title="Move up"
          >
            <i className="fa fa-chevron-up" />
          </div>
        ) : null}
        {index < stages.length - 1 ? (
          <div
            className="cursor-pointer pt-1"
            style={{
              lineHeight: 1,
              fontSize: 12,
            }}
            onClick={() => {
              const newStages = changeOrder( stages, index, 1 );
              setStages( newStages );
            }}
            title="Move down"
          >
            <i className="fa fa-chevron-down" />
          </div>
        ) : null}
      </Col>
      <Col className="px-0">
        <input
          type="text"
          value={stage}
          className="form-control-sm form-control"
          onChange={( e ) => {
            const newStages = [...stages];
            newStages[index] = e.target.value;
            setStages( newStages );
          }}
          ref={inputRef}
        />
      </Col>
      <Col xs="auto">
        <div
          className="text-danger cursor-pointer"
          onClick={() => {
            const newStages = [...stages];
            newStages.splice( index, 1 );
            setStages( newStages );
          }}
          title="Remove"
        >
          <i className="fa fa-trash" />
        </div>
      </Col>
    </Row>
  );
};

const StagesEditor = ( { stages, setStages } ) => (
  <div className="mb-3">
    <Label className="form-control-label">
      <Intl id="crm.pipelines.stages" />
    </Label>
    {stages.map( ( stage, index ) => (
      <StageRow
        stage={stage}
        stages={stages}
        setStages={setStages}
        index={index}
        key={index}
      />
    ) )}
    <div className="mt-3">
      <div
        className="btn btn-secondary btn-sm"
        onClick={() => {
          const newStages = [...stages];
          newStages.push( '' );
          setStages( newStages );
        }}
      >
        <i className="fa fa-plus mr-2" />
        <Intl id="crm.pipelines.stages.add" />
      </div>
    </div>

  </div>
);

export default StagesEditor;
