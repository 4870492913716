import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField, EditCard, Switch, TranslationLayout,
} from 'components';

export default React.memo( () => (
  <EditCard title="customerApp.events.title" cardClass="card-sec-bottom">
    <TranslationLayout
      translationFields={( locale ) => (
        <>
          <Row>
            <Col md={3}>
              <TextField
                field={`translations.${locale}.events.title`}
                label="customerApp.basicModule.title"
              />
            </Col>
            <Col md={3}>
              <TextField
                field={`translations.${locale}.events.subtitle`}
                label="customerApp.basicModule.subtitle"
              />
            </Col>
            <Col md={6}>
              <TextField
                field={`translations.${locale}.events.extract`}
                label="customerApp.basicModule.extract"
              />
            </Col>
          </Row>
        </>
      )}
    >
      <Row>
        <Col lg>
          <Row>
            <Col md={3}>
              <Switch
                field="events.enabled"
                label="customerApp.basicModule.enabled"
              />
            </Col>
            <Col md={3}>
              <Switch
                field="events.customerPublish"
                label="customerApp.events.customerPublish"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextField
                field="events.title"
                label="customerApp.basicModule.title"
              />
            </Col>
            <Col md={3}>
              <TextField
                field="events.subtitle"
                label="customerApp.basicModule.subtitle"
              />
            </Col>
            <Col md={6}>
              <TextField
                field="events.extract"
                label="customerApp.basicModule.extract"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </TranslationLayout>
  </EditCard>
) );
