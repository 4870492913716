import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import NewPayrollView from 'views/Resource/Edit/Payroll/new';
import SettingsActions from 'store/reducers/Settings';
import EmployeeService from 'api/Employee';
import { formatDataToSend, handlePromise } from 'utils';

const NewPayrollContainer = ( {
  entityId,
  onClose,
  onReloadData,
  toggleInfoAlert,
  toggleLoading,
} ) => {
  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const datesSend = formatDataToSend( formData, form );
      delete datesSend.payroll;

      const dataToSend = {
        document: formData.payroll ? formData.payroll.file : null,
        month: datesSend.monthYear ? datesSend.monthYear.format( 'M' ) : '',
        year: datesSend.monthYear ? datesSend.monthYear.format( 'YYYY' ) : '',
      };

      const [dataErrors, dataResponse] = await handlePromise(
        EmployeeService.createPayroll( entityId, dataToSend ),
      );
      toggleLoading( false );
      if ( !dataResponse.ok ) return dataErrors;

      toggleInfoAlert( 'dataSaved' );
      onReloadData();
    },
    [entityId, onReloadData, toggleInfoAlert, toggleLoading],
  );

  return <NewPayrollView submitForm={submitForm} onClose={onClose} />;
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  // toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( NewPayrollContainer );
