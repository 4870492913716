import api from 'config/Api/Api';

const baseEndpoint = '/accommodation-landlords';

export default {
  getAccomodationLandlords( params ) {
    return api.get( baseEndpoint, params );
  },
  getAccomodationLandlord( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  saveAccomodationLandlord( data ) {
    return api.post( baseEndpoint, data );
  },
  updateAccomodationLandlord( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  deleteAccomodationLandlord( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
  //
  addAccomodation( id, accomodationId, data ) {
    return api.put(
      `${baseEndpoint}/${id}/add-accomodation/${accomodationId}`,
      data,
    );
  },
  deleteAccomodation( id, accomodationId ) {
    return api.delete(
      `${baseEndpoint}/${id}/delete-accomodation/${accomodationId}`,
    );
  },
};
