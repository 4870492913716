import locales from './Locale';
import roles from './Roles';
import acl from './Acl';
import {
  priorities as taskPriorities,
  statuses as taskStatuses,
  types as taskTypes,
} from './Task';
import { executionPoint, target, bookingType } from './AutogenerateTask';
import { types as customFieldTypes } from './CustomFields';
import bookingStatuses from './Booking';
import otaStatuses from './Ota';
import bookedServiceStatus from './BookedServices';
import bookedServiceItemStatus from './BookedServiceItems';
import eventStatuses from './Event';
import accessRequestStatuses from './AccessRequest';
import aclWhitelist from './AclWhitelist';
import customerAppNaming from './CustomerAppNaming';
import statusPaid from './StatusPaid';
import paymentGateways from './PaymentGateways';
import cleaningStatus from './Accommodations';
import periodicity from './Periodicity';
import notificationStatusColors from './Notifications';
import currencies from './Currency';
import sizes from './Size';
import amenityTypes from './AmenityTypes';

import {
  serviceWeekdays,
  serviceTypes,
  serviceItemPaymentMethod,
  serviceItemPricePer,
  serviceItemType,
  serviceItemUnits,
} from './Service';

export const minutesToLock = 60;
export const noCustomerAppPM = ['5e413f53e49dc8011e766408'];

export {
  currencies,
  sizes,
  locales,
  roles,
  acl,
  taskPriorities,
  taskStatuses,
  taskTypes,
  bookingStatuses,
  otaStatuses,
  eventStatuses,
  accessRequestStatuses,
  customFieldTypes,
  aclWhitelist,
  customerAppNaming,
  serviceWeekdays,
  serviceTypes,
  bookedServiceStatus,
  bookedServiceItemStatus,
  serviceItemPaymentMethod,
  serviceItemPricePer,
  serviceItemType,
  serviceItemUnits,
  executionPoint,
  target,
  bookingType,
  statusPaid,
  paymentGateways,
  cleaningStatus,
  periodicity,
  notificationStatusColors,
  amenityTypes,
};
