import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import ContactEditorView from 'views/CRM/Contact/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import { getContact } from 'api/CRM/Contact';
import ContactEditContextProvider from './context';

const ContactEditor = ( { match, toggleErrorAlert } ) => {
  const contactId = match.params.id;
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => getContact( contactId ),
    toggleErrorAlert,
    conditional: !!contactId,
    deps: [contactId, dataSubmitted],
  } );

  return (
    <ContactEditContextProvider
      contactId={contactId}
      path={match.path.split( '/:' )[0]}
      data={data}
      loading={dataLoading}
      reloadData={reloadData}
    >
      <ContactEditorView />
    </ContactEditContextProvider>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( ContactEditor );
