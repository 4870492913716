import React from 'react';
import classnames from 'classnames';
import { Button, FormGroup } from 'reactstrap';
import Dropzone from 'react-dropzone';

import { Intl } from 'components';

const DropzoneInput = React.memo( ( {
  value, formGroupClass, translateValues, isInvalid, meta,
  multiple = false, label, labelClass, inputOnChange, onChange, ...rest
} ) => (
  <FormGroup className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}>
    {label
    && (
      <label className={classnames( labelClass, 'form-control-label' )}>
        <Intl id={label} />
      </label>
    )}
    <Dropzone
      {...rest}
      onDrop={( files ) => {
        if ( files && files[0] ) {
          if ( multiple ) {
            if ( inputOnChange ) inputOnChange( files );
            if ( onChange ) onChange( files );
          } else {
            if ( inputOnChange ) inputOnChange( files[0] );
            if ( onChange ) onChange( files[0] );
          }
        }
      }}
    >
      {( { getRootProps, getInputProps } ) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />

          <div className="d-flex align-items-center">
            <Button size="sm" color="primary" outline type="button" className="px-4 py-2">
              <Intl id="uploadFile" />
            </Button>
            {!!value
            && (
              <span className="form-control-label">
                {value.name}
                {/* <i
                  className="app-icon-close dz-remove-single ml-2 cursor-pointer"
                  onClick={( e ) => {
                    e.stopPropagation();
                    if ( inputOnChange ) inputOnChange( null );
                    if ( onChange ) onChange( null );
                  }}
                /> */}
              </span>
            )}
          </div>
        </div>
      )}
    </Dropzone>

    {isInvalid
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={translateValues} />
        </div>
      )}
  </FormGroup>
) );

export default DropzoneInput;
