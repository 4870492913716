import React, { useContext } from 'react';
import moment from 'moment';
import { Badge } from 'reactstrap';
import { Intl, LocalTable, TableActionsBtns } from 'components';
import { BusinnesCRMContext } from 'containers/CRM/Business/Dashboard/context';
import PaginationBusinnessAll from './pagination';

const ViewTableBusiness = ( { data, onDeleteBusiness } ) => {
  const {
    onEditBusiness,
  } = useContext( BusinnesCRMContext );

  return (
    <>
      <LocalTable
        data={data.data}
        manualPagination
        disableSorting
        onlyTable
        columns={[
          {
            Header: 'name',
            accessor: 'businessName',
            Cell: ( { cell } ) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href="#"
                onClick={( e ) => {
                  e.preventDefault();
                  onEditBusiness( cell.row.original.id );
                }}
              >
                {cell.value}
              </a>
            ),
          },
          {
            Header: 'crm.businnesStatuses.pipeline',
            accessor: 'pipeline',
            Cell: ( { cell } ) => (
              cell.value.name
            ),
          },
          {
            Header: 'crm.businnesStatuses.stage',
            accessor: 'businessStage',
          },
          {
            Header: 'state',
            accessor: 'state',
            Cell: ( { cell } ) => (
              <Badge color={cell.value === 'active' ? 'success' : 'danger'} pill>
                <Intl id={`crm.businnesStatuses.${cell.value}`} />
              </Badge>
            ),
          },
          {
            Header: 'crm.businnesStatuses.closeDate',
            accessor: 'closeDate',
            Cell: ( { cell } ) => ( cell.value

              ? moment( cell.value ).format( 'YYYY-MM-DD' )
              : '-' ),
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: () => {
                      onDeleteBusiness( cell.row.original.id );
                    },
                  },
                ]}
              />
            ),
          },
        ]}
      />
      <PaginationBusinnessAll data={data} />
    </>
  );
};

export default ViewTableBusiness;
