import api from 'config/Api/Api';

const baseEndpoint = '/countries';
const stateEndpoint = 'states';

export default {
  getStates( countryId, params ) {
    return api.get( `${baseEndpoint}/${countryId}/${stateEndpoint}`, params );
  },

  getState( countryId, id ) {
    return api.get( `${baseEndpoint}/${countryId}/${stateEndpoint}/${id}` );
  },

  saveState( countryId, data ) {
    return api.post( `${baseEndpoint}/${countryId}/${stateEndpoint}`, data );
  },

  updateState( countryId, id, data ) {
    return api.put( `${baseEndpoint}/${countryId}/${stateEndpoint}/${id}`, data );
  },
};
