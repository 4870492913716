import React from 'react';
import { Link } from 'react-router-dom';

import {
  AsyncTable,
  AddBtn,
  TableActionsBtns,
  CanAccess,
} from 'components';
import { canView, canModify } from 'config/Ability';

// TODO: change ACLS

const Dashboard = React.memo(
  ( {
    data, isLoading, defaultFilters, parentPath, onFetchData, onDelete,
  } ) => {
    const canViewProp = canView( 'communications' );
    const canEditDelete = canModify( 'communications' );
    return (
      <AsyncTable
        id="templates"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        rightContent={(
          <CanAccess I="add" a="communications">
            <AddBtn title="newTemplate" route={`${parentPath}/new`} />
          </CanAccess>
            )}
        onFetchData={onFetchData}
        columns={[
          {
            Header: 'title',
            accessor: 'title',
            Cell: ( { cell } ) => ( canEditDelete || canViewProp ? (
              <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                {cell.value}
              </Link>
            ) : (
              cell.value
            ) ),
          },
          {
            Header: 'description',
            accessor: 'description',
          },
          {
            Header: 'type',
            accessor: 'type',
          },
          canEditDelete
            ? {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => ( cell.row.original.internal ? '' : (
                <TableActionsBtns
                  actions={[
                    {
                      isDelete: true,
                      onClick: ( cb ) => onDelete( cell.value, cb ),
                    },
                  ]}
                />
              ) ),
            }
            : {
              id: 'id',
              Header: null,
              maxWidth: 1,
              disableSorting: true,
            },
        ]}
      />
    );
  },
);

export default Dashboard;
