import React, { useState } from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import { SketchPicker } from 'react-color';

import { Intl } from 'components';
import InputGroupAddon from '../InputGroupAddon';

const ColorpickerInput = React.memo( ( {
  label, value, inputOnChange, inputName, size = 'md', onChange, prependIconClass, prependIcon,
  appendIconClass, appendIcon, formGroupClass, intl, translateValues, isInvalid,
  focused, meta, placeholder, translate = true, ...rest
} ) => {
  const [displayColorPicker, setDisplayColorPicker] = useState( false );

  const handleClick = () => {
    setDisplayColorPicker( !displayColorPicker );
  };

  const handleClose = () => {
    setDisplayColorPicker( false );
  };

  return (
    <FormGroup className={classnames( 'color-picker', formGroupClass, {
      'is-invalid': isInvalid,
      'form-group-lg': size === 'lg',
      'form-group-sm': size === 'sm',
    } )}
    >
      {label
      && (
        <label className="form-control-label">
          {translate ? <Intl id={label} /> : label}
        </label>
      )}
      <InputGroup className={classnames( 'input-group-merge', { focused } )}>
        {prependIcon || prependIconClass ? (
          <InputGroupAddon
            addonType="prepend"
            isInvalid={isInvalid}
            icon={prependIcon}
            iconClass={prependIconClass}
          />
        ) : null}

        <Input
          {...rest}
          autoComplete="off"
          placeholder={placeholder ? intl.formatMessage( { id: placeholder } ) : null}
          className={classnames( {
            'is-invalid': isInvalid,
            'form-control-lg': size === 'lg',
            'form-control-sm': size === 'sm',
          } )}
          value={value || ''}
          onChange={() => {}}
          data-cy={`input-${inputName}`}
          onClick={handleClick}
        />

        {appendIcon || appendIconClass ? (
          <InputGroupAddon
            addonType="append"
            isInvalid={isInvalid}
            icon={appendIcon}
            iconClass={appendIconClass}
          />
        ) : null}
      </InputGroup>

      {isInvalid
      && (
        <div className="invalid-feedback">
          <Intl id={meta.error || meta.submitError} values={translateValues} />
        </div>
      )}

      { displayColorPicker ? (
        <div className="color-picker-container" data-cy="color-picker">
          <div className="color-picker-overlay" onClick={handleClose} />
          <SketchPicker
            width={220}
            color={value}
            onChange={( color ) => {
              if ( inputOnChange ) inputOnChange( color.hex );
              if ( onChange ) onChange( color.hex );
            }}
          />
        </div>
      ) : null }
    </FormGroup>
  );
} );

export default injectIntl( ColorpickerInput );
