import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EmployeeSettingsView from 'views/EmployeePermissions';
import SettingsActions from 'store/reducers/Settings';
import EmployeePermitsService from 'api/Settings/EmployeePermits';
import { useFetch } from 'hooks';
import { handlePromise } from 'utils';
import permissions from './permissions';
import { submitEmployeePermits } from './utils';

const EmployeePermissionsSettingsEdit = ( {
  propertyManager, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => EmployeePermitsService.getEmployeePermits( {} ),
    toggleErrorAlert,
    conditional: !!propertyManager.id,
    deps: [propertyManager.id],
  } );

  const isLoadingData = () => {
    if ( dataLoading === undefined ) return undefined;
    if ( dataLoading ) return true;
    return false;
  };

  const submitForm = useCallback( async ( formData ) => {
    toggleLoading( true );

    const [errors, response] = await handlePromise(
      submitEmployeePermits( formData ),
    );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
  }, [toggleInfoAlert, toggleLoading] );

  return (
    <EmployeeSettingsView
      data={_.isEmpty( data ) || _.isEmpty( data.data )
        ? { permissions } : { permissions: data.data }}
      isLoading={isLoadingData()}
      onSubmit={submitForm}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( EmployeePermissionsSettingsEdit );
