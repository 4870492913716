import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { v4 as uuidv4 } from 'uuid';

const CREATE_OCCUPANCY = gql`
  mutation CreateOccupancy($input: CreateOccupancyInput!) {
    createOccupancy(input: $input) {
      ... on BaseError {
        message
      }
      ... on MutationCreateOccupancySuccess {
        data {
          newOccupancy {
            id
          }
          success
        }
      }
    }
  }
`;

const createOccupancy = async ( input ) => {
  const id = uuidv4();
  const { data } = await client.query( {
    query: CREATE_OCCUPANCY,
    variables: {
      input: { ...input, id },
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.createOccupancy.message ) {
    return {
      ok: false,
      message: data.createOccupancy.message,
    };
  }
  const createSuccessful = data.createOccupancy.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
    data: { ...data.createOccupancy.data.newOccupancy },
  };
};

export default createOccupancy;
