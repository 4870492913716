import React, { useState, useEffect } from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import classnames from 'classnames';
import Drawer from 'rc-drawer';

import {
  Intl, HorizontalMenu, DetailCard,
} from 'components';
import TaskOverview from 'containers/Task/Edit/Overview';
import TaskAccessRequest from 'containers/Task/Edit/AccessRequest/Dashboard';
import TaskWorkingLog from './WorkingLog';
import TaskBookingsLog from './BookingsLog';
import TaskChecklists from './Checklist';

let tabsList = [
  { name: 'overview' },
  { name: 'accessRequest' },
  { name: 'workingLog' },
  { name: 'bookingsLog' },
];

export default React.memo( ( {
  taskId, data, isLoading, open, backgroundReloaded,
  onClose, setData, onFormatData, onReload, onUpdateFieldTask, inEditor,
} ) => {
  const [selectedTab, setSelectedTab] = useState( 'overview' );
  const [hasChecklist, setHasChecklist] = useState( false );

  useEffect( () => {
    if ( data.checkListsToTask ) {
      if ( data.checkListsToTask.length > 0 ) {
        setHasChecklist( true );
      }
    }
  }, [data.checkListsToTask] );

  if ( hasChecklist ) {
    if ( !tabsList.find( ( t ) => t.name === 'checklists' ) ) {
      tabsList.push( { name: 'checklists' } );
    }
  } else {
    tabsList = tabsList.filter( ( t ) => t.name !== 'checklists' );
  }

  const content = (
    <div className={classnames( 'p-4', { 'app-component-loading': isLoading } )}>
      <Row className="align-items-center">
        <Col>
          <span className="text-uppercase font-weight-bold ml-3">
            {data.id ? <Intl id="task" /> : null}
            {' '}
            {data.id ? `#${data.id.slice( data.id.length - 6, data.id.length )}` : ''}
            {backgroundReloaded && !!data && taskId === data.id && '*'}
          </span>
          {backgroundReloaded && !!data && taskId === data.id
      && (
        <span
          className="text-sm font-weight-normal ml-1 text-underline cursor-pointer"
          onClick={onReload}
        >
          (
          <Intl id="taskUpdatedReload" />
          )
        </span>
      )}
        </Col>
        {inEditor ? null : (
          <Col xs={2} className="text-right">
            <i className="app-icon-close cursor-pointer py-2 pl-2" onClick={onClose} />
          </Col>
        )}
      </Row>

      <Row className="mt-2 mb-4">
        <Col>
          <HorizontalMenu
            tabs={tabsList}
            selected={selectedTab}
            onChangeTab={( index ) => setSelectedTab( index )}
          />
        </Col>
      </Row>

      <div className="mt-3">
        <div className={classnames( {
          'd-none': selectedTab !== 'overview',
        } )}
        >
          <TaskOverview
            data={data}
            // customFields={customFields}
            taskId={taskId}
            onFormatData={onFormatData}
            onReload={onReload}
            setData={setData}
            onUpdateField={onUpdateFieldTask}
          />
        </div>

        <div className={classnames( {
          'd-none': selectedTab !== 'workingLog',
        } )}
        >
          <TaskWorkingLog data={data} />
        </div>

        <div className={classnames( {
          'd-none': selectedTab !== 'accessRequest',
        } )}
        >
          <TaskAccessRequest
            taskData={data}
            taskId={taskId}
          />
        </div>

        <div className={classnames( {
          'd-none': selectedTab !== 'bookingsLog',
        } )}
        >
          <TaskBookingsLog
            data={data}
          />
        </div>
        {hasChecklist && (
        <div className={classnames( {
          'd-none': selectedTab !== 'checklists',
        } )}
        >
          <TaskChecklists
            data={data}
          />
        </div>
        )}
      </div>
    </div>
  );

  return inEditor ? (
    <Container fluid className="pt-4">
      <DetailCard>{content}</DetailCard>

    </Container>
  ) : (
    <Drawer
      open={open}
      placement="right"
      handler={false}
      maskClosable
      level={null}
      onClose={onClose}
      className="zindex-drawer"
    >
      {content}
    </Drawer>
  );
} );
