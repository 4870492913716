import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TaskEditView from 'views/TaskPeriodic/Edit';
import TaskNewView from 'views/TaskPeriodic/New';
import SettingsActions from 'store/reducers/Settings';
import AccommodationService from 'api/Accommodation';
import PropertyService from 'api/Property';
import TaskTypeService from 'api/TaskType';
import TaskPeriodicService from 'api/TaskPeriodic';
// import CustomFieldService from 'api/Settings/CustomField';
import {
  formatArrayToSelectValues,
  formatDataToSend, handlePromise,
} from 'utils';
import useFetch from 'hooks/useFetch';
import { waterfall } from 'utils/async';

const TaskEdit = ( {
  taskId, modalOpened, taskReloadedAt, taskReloadedId,
  onReload, onCloseModal, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [data, setData] = useState( {} );

  const isInitialMount = useRef( true );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    }
  }, [taskReloadedAt, taskReloadedId, taskId, modalOpened] );

  const getEntityData = useCallback( async ( cb ) => {
    if ( !taskId ) return cb( null, {} );
    const [errors, response, responseData] = await handlePromise(
      TaskPeriodicService.getTask( taskId ),
    );

    if ( !response.ok ) return cb( errors );
    return cb( null, { entity: responseData } );
  }, [taskId] );

  // const getCustomFields = useCallback( async ( entityData, cb ) => {
  //   if ( !propertyManager || !propertyManager.id ) return cb( 'apiError' );

  //   const [errors, response, responseData] = await handlePromise(
  //     CustomFieldService.getCustomFields( {
  //       type: 'task',
  //       propertyManager: propertyManager.id,
  //       elementsPerPage: 1000,
  //     } ),
  //   );
  //   if ( !response.ok ) return cb( errors );

  //   return cb( null, {
  //     entity: formatCustomValues( entityData, responseData.data ),
  //     customFields: responseData.data,
  //   } );
  // }, [propertyManager] );

  const getAccommodation = useCallback( async ( accommodationId, cb ) => {
    const [errors, response, responseData] = await handlePromise(
      AccommodationService.getGenericAccommodation( accommodationId ),
    );
    if ( !response.ok ) return cb( errors );
    return cb( null, responseData );
  }, [] );

  useEffect( () => {
    if ( !modalOpened ) return;

    waterfall( [
      getEntityData,
      ( entityData, cb ) => {
        if ( !_.get( entityData, 'entity.accommodation' ) ) return cb( null, entityData );
        getAccommodation( entityData.entity.accommodation, ( error, accommodationResponse ) => {
          if ( error ) return cb( error );
          return cb( null, { ...entityData, accommodation: accommodationResponse } );
        } );
      },
    ], ( errors, results ) => {
      if ( errors ) {
        return toggleErrorAlert( errors );
      }
      const formattedData = { ...results.entity };
      if ( results.entity && results.entity.id ) {
        if ( results.entity.taskType ) {
          formattedData.taskType = {
            value: results.entity.taskType.id,
            label: results.entity.taskType.name,
          };
        }
        // if ( results.entity.assigned && results.entity.assigned.length ) {
        //   const { assigned } = results.entity;
        //   formattedData.assignedTo = {
        //     value: assigned[assigned.length - 1].employee.id,
        //     label: getResourceName( assigned[assigned.length - 1].employee ),
        //   };
        // }

        if ( formattedData.accommodations ) {
          formattedData.accommodations = formatArrayToSelectValues(
            formattedData.accommodations,
          );
        }
        if ( formattedData.properties ) {
          formattedData.properties = formatArrayToSelectValues(
            formattedData.properties,
          );
        }
      }
      setData( formattedData );
    } );
  }, [modalOpened, getEntityData,
    getAccommodation, toggleErrorAlert] );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ), [],
  );

  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const submitNewForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );
    const formattedData = {
      accommodations: dataToSend.accommodations ? dataToSend.accommodations : undefined,
      properties: dataToSend.properties ? dataToSend.properties : undefined,
      taskType: dataToSend.taskType,
      description: dataToSend.description,
      type: dataToSend.type,
      from: dataToSend.from ? dataToSend.from.format( 'YYYY-MM-DD HH:mm:ss' ) : undefined,
      till: dataToSend.till ? dataToSend.till.format( 'YYYY-MM-DD HH:mm:ss' ) : undefined,
      periodicity: dataToSend.periodicity ? dataToSend.periodicity : undefined,
      periodicityCustom: dataToSend.periodicityCustom ? dataToSend.periodicityCustom : undefined,
    };

    const [errors, response] = await handlePromise(
      TaskPeriodicService.saveTask( formattedData ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }
    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
    return onReload();
  }, [onReload, toggleInfoAlert, toggleLoading] );

  const submitEditForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );

    const formattedData = {
      taskType: dataToSend.taskType,
      description: dataToSend.description,
      type: dataToSend.type,
      periodicity: dataToSend.periodicity,
      periodicityCustom: dataToSend.periodicityCustom ? dataToSend.periodicityCustom : undefined,
    };
    if ( dataToSend.accommodations.length ) {
      formattedData.accommodations = _.map(
        dataToSend.accommodations, ( a ) => a.id || a.value || a,
      );
    }
    if ( dataToSend.properties.length ) {
      formattedData.properties = _.map( dataToSend.properties, ( a ) => a.id || a.value || a );
    }
    if ( dataToSend.from._isAMomentObject ) {
      formattedData.from = dataToSend.from.format( 'YYYY-MM-DD HH:mm:ss' );
    }
    if ( dataToSend.till._isAMomentObject ) {
      formattedData.till = dataToSend.till.format( 'YYYY-MM-DD HH:mm:ss' );
    }

    const [errors, response] = await handlePromise(
      TaskPeriodicService.updateTask( taskId, formattedData ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }
    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
    return onReload();
  }, [taskId, onReload, toggleInfoAlert, toggleLoading] );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  return !taskId ? (
    <TaskNewView
      open={modalOpened}
      onSubmit={submitNewForm}
      onClose={onCloseModal}
      onFetchAccommodations={fetchAccommodations}
      onFetchProperties={fetchProperties}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
    />
  ) : (
    <TaskEditView
      taskId={taskId}
      data={data}
      open={modalOpened}
      onSubmit={submitEditForm}
      onClose={onCloseModal}
      onFetchAccommodations={fetchAccommodations}
      onFetchProperties={fetchProperties}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
    />
  );
};

const mapStateToProps = ( { user, task } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
  taskReloadedAt: task.reloadedAt,
  taskReloadedId: task.taskId,
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( TaskEdit );
