import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import ServiceNewView from 'views/Booking/Edit/Service/New';
import ServiceService from 'api/Service';
import BookedService from 'api/BookedService';
import SettingsActions from 'store/reducers/Settings';
import { useSubmit, useFetch } from 'hooks';
import { formatFloatToSend } from 'utils';
import ItemEdit from '../Item/Edit';

const ServiceNew = ( {
  toggleLoading,
  toggleInfoAlert,
  match,
  parentPath,
  history,
  userCurrency,
} ) => {
  const bookingId = _.get( match, 'params.bookingId' );

  const fetchServices = useCallback(
    ( query ) => ServiceService.getServices( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const [serviceId, setServiceId] = useState();
  const [bookedServiceItems, setBookedServiceItems] = useState( [] );
  useEffect(
    () => setBookedServiceItems( [] ),
    [serviceId],
  );

  const [serviceData] = useFetch( {
    promise: () => ServiceService.getService( serviceId ),
    conditional: Boolean( serviceId ),
  } );

  const [modalOpened, setModalOpened] = useState( false );
  const toggleModal = useCallback(
    () => setModalOpened(
      ( state ) => !state,
    ), [],
  );

  const [selectedItem, setSelectedItem] = useState( {} );
  const itemId = _.get( selectedItem, 'item.value' );
  const onEditItem = useCallback(
    ( item ) => {
      setSelectedItem( {
        ...item,
        item: {
          value: item.item.id,
          label: item.item.name,
        },
      } );
      toggleModal();
    },
    [toggleModal],
  );

  const addItem = useCallback(
    ( value ) => {
      const itemFormated = {
        ...value,
        item: {
          id: value.item.value,
          name: value.item.label,
        },
      };
      if ( _.isEmpty( selectedItem ) ) {
        setBookedServiceItems( ( state ) => [
          ...state,
          { ...itemFormated, index: state.length },
        ] );
      } else {
        setBookedServiceItems( ( state ) => [
          ...state.slice( 0, selectedItem.index ),
          { ...itemFormated, index: selectedItem.index },
          ...state.slice( selectedItem.index + 1, state.length ),
        ] );
      }
      toggleModal();
    },
    [selectedItem, toggleModal],
  );

  const onAddNewItem = useCallback( () => {
    setSelectedItem( {} );
    toggleModal();
  }, [toggleModal] );

  const onDeleteItem = useCallback(
    ( index, cb ) => {
      setBookedServiceItems( ( state ) => [
        ...state.slice( 0, index ),
        ...state.slice( index + 1, state.length ),
      ].map( ( item, idx ) => ( { ...item, index: idx } ) ) );
      cb();
    }, [],
  );

  const [price, setPrice] = useState( 0 );
  useEffect( () => {
    setPrice(
      _.reduce(
        bookedServiceItems,
        ( acu, { amount } ) => acu + amount,
        0,
      ),
    );
  }, [bookedServiceItems] );

  const submitForm = useSubmit( {
    promise: BookedService.saveBookedService,
    format: ( toFormat ) => {
      const { requiresTimeSlot } = serviceData;
      const date = moment( toFormat.date ).format( 'YYYY-MM-DD HH:mm' );
      const serviceItems = bookedServiceItems.map(
        ( {
          item, amount, price: _price, index, ...rest
        } ) => ( requiresTimeSlot ? {
          ...rest,
          item: item.id,
          datetime: date,
          amount: formatFloatToSend( amount ),
          price: formatFloatToSend( _price ),
        } : {
          ...rest,
          item: item.id,
          amount: formatFloatToSend( amount ),
          price: formatFloatToSend( _price ),
        } ),
      );
      const dataToSend = {
        ...toFormat,
        bookedServiceItems: serviceItems,
        booking: bookingId,
        service: serviceId,
        cost: formatFloatToSend( price ),
        date,
      };
      return dataToSend;
    },
    toggleLoading,
    toggleInfoAlert,
    callback: () => history.goBack(),
    translations: true,
    deps: [bookedServiceItems, price, serviceId],
  } );

  return (
    <>
      <ServiceNewView
        data={{}}
        isLoading={false}
        bookedServiceItems={bookedServiceItems}
        onChangeService={setServiceId}
        bookingId={bookingId}
        serviceId={serviceId}
        onSubmit={submitForm}
        onAddNewItem={onAddNewItem}
        onEditItem={onEditItem}
        onDeleteItem={onDeleteItem}
        parentPath={parentPath}
        price={price}
        userCurrency={userCurrency}
        onFetchServices={fetchServices}
      />
      <ItemEdit
        data={selectedItem}
        itemId={itemId}
        modalOpened={modalOpened}
        onCloseModal={toggleModal}
        serviceId={serviceId}
        onSubmit={addItem}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( ServiceNew );
