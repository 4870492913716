import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import ServiceEditView from 'views/Booking/Edit/Service/Edit';
import BookedService from 'api/BookedService';
import BookedServiceItemService from 'api/BookedServiceItem';
import SettingsActions from 'store/reducers/Settings';
import useFetch from 'hooks/useFetch';
import useSubmit from 'hooks/useSubmit';
import useDelete from 'hooks/useDelete';
import { formatFloat, formatFloatToSend, handlePromise } from 'utils';
import ItemEdit from '../Item/Edit';

const ServiceEdit = ( {
  onClose,
  toggleLoading,
  toggleErrorAlert,
  match,
  parentPath,
  toggleInfoAlert,
  userCurrency,
  userBookingReference,
} ) => {
  const bookedServiceId = _.get( match, 'params.id' );
  const bookingId = _.get( match, 'params.bookingId' );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [modalOpened, setModalOpened] = useState( false );
  const toggleModal = useCallback(
    () => setModalOpened(
      ( state ) => !state,
    ),
    [],
  );

  const updateCost = useCallback(
    async ( dataToSend ) => {
      toggleLoading( true );
      const [error, response] = await handlePromise(
        BookedService.updateBookedService( bookedServiceId, dataToSend ),
      );
      toggleLoading( false );
      if ( !response.ok ) return toggleErrorAlert( error );
    },
    [bookedServiceId, toggleErrorAlert, toggleLoading],
  );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => BookedService.getBookedService( bookedServiceId ),
    format: ( dataToFormat ) => {
      const serviceItems = _.get( dataToFormat, 'bookedServiceItems', [] );
      const cost = _.reduce( serviceItems, ( total, { amount } ) => total + amount, 0 );
      if ( dataToFormat.cost !== cost ) {
        updateCost( { cost } );
      }
      return {
        ...dataToFormat,
        cost,
        bookedServiceItems: _.map( dataToFormat.bookedServiceItems, ( item ) => ( {
          ...item,
          price: formatFloat( item.price ),
          amount: formatFloat( item.amount ),
        } ) ),
      };
    },
    toggleErrorAlert,
    conditional: !!bookedServiceId,
    deps: [bookedServiceId, dataSubmitted],
  } );
  const serviceId = _.get( data, 'service.id' );

  const submit = useSubmit( {
    promise: ( dataToSend ) => BookedService
      .updateBookedService( bookedServiceId, dataToSend ),
    format: ( dataToFormat ) => ( {
      date: moment( dataToFormat.date ).format( 'YYYY-MM-DD' ),
      status: dataToFormat.status,
    } ),
    toggleLoading,
    toggleInfoAlert,
  } );

  const [selectedItem, setSelectedItem] = useState( {} );
  const onEditItem = useCallback(
    ( item ) => {
      setSelectedItem( {
        ...item,
        item: {
          value: item.item.id,
          label: item.item.name,
        },
      } );
      toggleModal();
    },
    [toggleModal],
  );

  const onAddNewItem = useCallback( () => {
    setSelectedItem( {} );
    toggleModal();
  }, [toggleModal] );

  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deleteItem = useDelete( {
    promise: ( itemId ) => BookedServiceItemService
      .deleteBookedServiceItem( bookedServiceId, itemId ),
    callback: reload,
    deps: [bookedServiceId],
    toggleLoading,
    toggleErrorAlert,
  } );

  const submitItem = useSubmit( {
    promise: ( dataToSend ) => ( _.isEmpty( selectedItem )
      ? BookedServiceItemService
        .saveBookedServiceItem(
          bookedServiceId,
          dataToSend,
        )
      : BookedServiceItemService
        .updateBookedServiceItem(
          bookedServiceId,
          selectedItem.id,
          dataToSend,
        ) ),
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      amount: formatFloatToSend( dataToFormat.amount ),
      price: formatFloatToSend( dataToFormat.price ),
    } ),
    callback: () => {
      toggleModal();
      reload();
    },
    deps: [bookedServiceId, selectedItem],
    toggleLoading,
    toggleInfoAlert,
  } );

  return (
    <>
      <ServiceEditView
        data={data}
        isLoading={dataLoading}
        serviceId={serviceId}
        onSubmit={submit}
        onClose={onClose}
        onAddNewItem={onAddNewItem}
        onEditItem={onEditItem}
        onDeleteItem={deleteItem}
        parentPath={parentPath}
        userCurrency={userCurrency}
        userBookingReference={userBookingReference}
        bookingId={bookingId}
      />
      <ItemEdit
        data={selectedItem}
        itemId={selectedItem.id}
        modalOpened={modalOpened}
        onCloseModal={toggleModal}
        serviceId={serviceId}
        onSubmit={submitItem}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
  userBookingReference: _.get( user, 'settings.bookingReference' ),
} );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( ServiceEdit );
