import React, { useMemo } from 'react';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import Dropzone from 'react-dropzone';

import { Intl } from 'components';
import { each } from 'utils/async';

const DropzoneInput = React.memo(
  ( {
    value,
    inputName,
    formGroupClass,
    translateValues,
    isInvalid,
    meta,
    disableDelete,
    preview = true,
    multiple = false,
    placeholder,
    label,
    labelClass,
    inputOnChange,
    onChange,
    dropzoneFileLimitMsg = 'dropzoneFileLimitMsg.imgs',
    accept = 'image/*',
  } ) => {
    const valToPreview = useMemo( () => {
      if ( !value || !preview ) {
        return null;
      }

      const src = value && value.fileDecoded ? value.fileDecoded : value;
      const type = src.toLowerCase().indexOf( 'application/pdf' ) >= 0 ? 'pdf' : 'img';

      return {
        src,
        type,
      };
    }, [preview, value] );

    return (
      <FormGroup
        className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}
      >
        {label && (
          <label className={classnames( labelClass, 'form-control-label' )}>
            <Intl id={label} />
          </label>
        )}
        <Dropzone
          accept={accept}
          onDrop={( files ) => {
            if ( files && files[0] ) {
              if ( multiple ) {
                const formattedFiles = [...value] || [];
                each(
                  files,
                  ( file, cb ) => {
                    const reader = new FileReader();
                    reader.readAsDataURL( file );
                    reader.onload = ( event ) => {
                      formattedFiles.push( {
                        file,
                        fileDecoded: event.target.result,
                      } );
                      cb();
                    };
                  },
                  ( error ) => {
                    if ( !error ) {
                      if ( inputOnChange ) inputOnChange( formattedFiles );
                      if ( onChange ) onChange( formattedFiles );
                    }
                  },
                );
              } else {
                const reader = new FileReader();
                const file = files[0];
                reader.readAsDataURL( file );
                reader.onload = ( event ) => {
                  if ( inputOnChange ) {
                    inputOnChange( { file, fileDecoded: event.target.result } );
                  }
                  if ( onChange ) {
                    onChange( { file, fileDecoded: event.target.result } );
                  }
                };
              }
            }
          }}
        >
          {( { getRootProps, getInputProps } ) => (
            <div
              className="dropzone dropzone-single dz-clickable"
              data-cy={`input-${inputName}`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              {valToPreview ? (
                <div className="dz-preview dz-preview-single">
                  <div className="dz-preview-cover dz-image-preview">
                    {!disableDelete && (
                      <i
                        className="app-icon-close dz-remove-single"
                        onClick={( e ) => {
                          e.stopPropagation();
                          if ( inputOnChange ) inputOnChange( null );
                          if ( onChange ) onChange( null );
                        }}
                      />
                    )}
                    {valToPreview.type === 'img' ? (
                      <img
                        className="dz-preview-img"
                        src={valToPreview.src}
                        alt=""
                      />
                    ) : (
                      <div className="dz-preview-pdf">
                        <div className="dz-preview-pdf_inner">PDF</div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="dz-message">
                  {placeholder || (
                    <>
                      <i className="fa fa-file fa-4x mb-3" />
                      <p className="mb-2">
                        <Intl id="dropzoneMsg" />
                      </p>
                      <p className="mb-1">
                        <Intl id={dropzoneFileLimitMsg} />
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </Dropzone>

        {isInvalid && (
          <div className="invalid-feedback">
            <Intl id={meta.error || meta.submitError} values={translateValues} />
          </div>
        )}
      </FormGroup>
    );
  },
);

export default DropzoneInput;
