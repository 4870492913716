import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import defaultAvatar from 'assets/img/defaults/avatar-default.png';

export default React.memo( ( {
  avatar, name, tooltipContent, alt = '...', target, style,
  hasTooltip, className, inline = false, avatarSize = 'xsm', textClassName,
} ) => (
  <div className={classnames( 'align-items-center', className, {
    'd-inline-flex': inline,
    'd-flex': !inline,
  } )}
  >
    <span
      className={classnames( 'avatar rounded-circle text-sm', {
        'avatar-xsm': avatarSize === 'xsm',
        'avatar-sm': avatarSize === 'sm',
      } )}
      id={target}
      style={style}
    >
      <img alt={alt} src={avatar || defaultAvatar} />
    </span>
    {!!name && <span className={classnames( 'ml-2', textClassName )}>{name}</span>}
    {hasTooltip
    && (
      <UncontrolledTooltip
        delay={0}
        target={target}
      >
        {tooltipContent || name}
      </UncontrolledTooltip>
    )}
  </div>
) );
