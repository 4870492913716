import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import DashboardView from 'views/PropertyManager/Edit/SmartLockers/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import SmartLockerService from 'api/Settings/SmartLocker';
import useDelete from 'hooks/useDelete';
import EditModal from '../Edit';

const Dashboard = ( {
  toggleErrorAlert, propertyManager, toggleLoading,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const toEditId = useRef( null );

  const [modalOpened, setModalOpened] = useState( false );
  const toggleModal = () => setModalOpened( ( state ) => !state );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: () => SmartLockerService.getSmartLockerSettings( {
      propertyManager: propertyManager.id,
    } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const reloadData = useCallback( () => {
    toggleModal();
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deleteSmartLockerSetting = useDelete( {
    promise: SmartLockerService.deleteSmartLockerSetting,
    callback: () => {
      setDataSubmitted( new Date().valueOf() );
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const openEditModal = ( id ) => {
    toEditId.current = id;
    toggleModal();
  };

  return (
    <>
      <DashboardView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteSmartLockerSetting}
      />
      <EditModal
        smartLockerSettingId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={toggleModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
