import React, { useState } from 'react';
import moment from 'moment';
import {
  Row, Col, Modal, Button, UncontrolledTooltip,
} from 'reactstrap';
import {
  AsyncTable, EditCard, DaterangePickerBtn, AddBtn, Intl,
} from 'components';
import NewContract from 'containers/Resource/Edit/Contract/new';
import ViewContract from './view';

const ContractView = ( {
  entityId,
  fetchData,
  dataTable,
  dataLoading,
  defaultFilters,
  reload,
  setContractIdToDelete,
  toggleModalDelete,
} ) => {
  const [modalAddOpen, setModalAddOpen] = useState( false );

  const toggleModalAdd = () => {
    setModalAddOpen( ( v ) => !v );
  };

  const [contractToView, setContractToView] = useState( null );
  const [modalAddView, setModalAddView] = useState( false );

  const toggleModalView = () => {
    setModalAddView( ( v ) => !v );
  };

  return (
    <>
      <EditCard cardClass="card-sec-bottom">
        <AsyncTable
          id="resourceContracts"
          data={dataTable.data}
          total={dataTable.elementsTotal}
          isLoading={dataLoading}
          rightContent={<AddBtn title="newContract" onClick={toggleModalAdd} />}
          disableSorting
          defaultFilters={defaultFilters}
          onFetchData={fetchData}
          customFilters={( { filters, setMultipleFilters } ) => (
            <>
              <Row>
                <Col md={8} lg={4}>
                  <DaterangePickerBtn
                    position="left"
                    initialValue={moment.range( filters.from || moment().subtract( 180, 'days' ), filters.till || moment() )}
                    key={filters.from + filters.till}
                    input
                    onChange={( values ) => {
                      setMultipleFilters( {
                        from: moment( values.start ).format( 'YYYY-MM-DD' ),
                        till: moment( values.end ).format( 'YYYY-MM-DD' ),
                      } );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          columns={[
            {
              Header: 'id',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <>
                  <a
                    href="/"
                    id={`tooltip-contract-${cell.value}`}
                    onClick={( e ) => {
                      e.preventDefault();
                      setContractToView( cell.row.original );
                      toggleModalView();
                    }}
                  >
                    {cell.value.slice( 0, 8 )}
                  </a>
                  <UncontrolledTooltip
                    delay={0}
                    target={`tooltip-contract-${cell.value}`}
                  >
                    <Intl id="view" />
                  </UncontrolledTooltip>
                </>
              ),
            },
            {
              Header: 'from',
              accessor: 'dateFrom',
              Cell: ( { cell } ) => moment( cell.value ).format( 'DD/MM/YYYY' ),
            },
            {
              Header: 'till',
              accessor: 'dateTill',
              Cell: ( { cell } ) => moment( cell.value ).format( 'DD/MM/YYYY' ),
            },
            {
              Header: '',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <Button
                  size="sm"
                  color="danger"
                  outline
                  onClick={() => {
                    setContractIdToDelete( cell.value );
                    toggleModalDelete();
                  }}
                >
                  Eliminar
                </Button>
              ),
            },
          ]}
        />
      </EditCard>

      <Modal
        isOpen={modalAddOpen}
        toggle={toggleModalAdd}
        className="modal-dialog-centered"
        size="lg"
      >
        <div className="modal-body">
          <NewContract
            entityId={entityId}
            onClose={() => {
              setModalAddOpen( false );
            }}
            onReloadData={() => {
              setModalAddOpen( false );
              reload();
            }}
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalAddView}
        toggle={toggleModalView}
        className="modal-dialog-centered"
        size="xl"
      >
        <div className="modal-body">
          <ViewContract contractToView={contractToView} toggleModalView={toggleModalView} />
        </div>
      </Modal>
    </>
  );
};

export default ContractView;
