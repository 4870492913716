import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import EditDrawerView from 'views/PropertyManager/Edit/Rates/EditDrawer';

const EditDrawer = ( {
  rateId, open, onClose, onReloadRates, onOpenModal,
} ) => (
  <EditDrawerView
    data={{ id: rateId }}
    open={open}
    onOpenModal={onOpenModal}
    onClose={onClose}
    onReloadRates={onReloadRates}
  />
);

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( EditDrawer );
