import api from 'config/Api/Api';

const baseEndpoint = '/checklist';

export default {
  getChecklistsTaks( params ) {
    return api.get( `${baseEndpoint}-task`, params );
  },
  getChecklists( params ) {
    return api.get( baseEndpoint, params );
  },

  getChecklist( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveChecklist( data ) {
    return api.post( baseEndpoint, data );
  },

  updateChecklist( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteChecklist( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
