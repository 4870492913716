import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import classnames from 'classnames';

import {
  SimpleHeader,
  Form,
  TextField,
  FormActionsBtns,
  EditCard,
  TranslationLayout,
  Intl,
  LocalTable,
  DetailCard,
  TableEditBtn,
  AddBtn, TableActionsBtns,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  data, items, isLoading, parentPath, onSubmit, onOpenEditItemModal, onDeleteItem,
} ) => (
  <>
    <SimpleHeader
      parentLinks={[{ name: 'checklists', href: parentPath }]}
    />

    <Container
      fluid
      className={classnames( { 'app-component-loading': isLoading } )}
    >

      <Form
        initialValues={data}
        onSubmit={onSubmit}
        showErrorsInFooter
        body={() => (
          <>
            <EditCard title="editChecklist">
              <TranslationLayout
                translationFields={( locale ) => (
                  <>
                    <Row>
                      <Col md={6}>
                        <TextField
                          field={`translations.${locale}.name`}
                          label="name"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          field={`translations.${locale}.description`}
                          label="description"
                          type="textarea"
                          rows={4}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              >
                <Row>
                  <Col md={5}>
                    <TextField
                      field="name"
                      label="name"
                      validate={required}
                    />
                  </Col>
                  <Col md={7}>
                    <TextField
                      field="description"
                      label="description"
                      type="textarea"
                      rows={4}
                      validate={required}
                    />
                  </Col>
                </Row>
              </TranslationLayout>
            </EditCard>

            <DetailCard
              classNames={{ header: 'py-2', body: 'pt-0 pb-2 px-0' }}
              header={(
                <Row className="align-items-center">
                  <Col md={9}>
                    <h3 className="m-0">
                      <Intl id="items" />
                    </h3>
                  </Col>
                  <Col md={3} className="text-right">
                    <AddBtn title="newItem" onClick={onOpenEditItemModal} />
                  </Col>
                </Row>
              )}
            >
              <LocalTable
                onlyTable
                manualPagination
                data={items}
                columns={[
                  {
                    Header: 'name',
                    accessor: 'title',
                    Cell: ( { cell } ) => (
                      <TableEditBtn
                        title={cell.value}
                        onClick={() => onOpenEditItemModal( cell.row.original )}
                      />
                    ),
                  },
                  {
                    Header: 'description',
                    accessor: 'description',
                  },
                  {
                    accessor: 'id',
                    disableSorting: true,
                    Header: null,
                    maxWidth: 65,
                    Cell: ( { cell } ) => (
                      <TableActionsBtns
                        actions={[
                          {
                            isDelete: true,
                            onClick: ( cb ) => onDeleteItem( cell.value, cb ),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
              />
            </DetailCard>

            <FormActionsBtns cancelTo={parentPath} />
          </>
        )}
      />
    </Container>
  </>
) );
