import React from 'react';
import {
  Intl, Form, FormActionsBtns,
  TextField, Phone, Select, Switch,
} from 'components';
import { Button, Col, Row } from 'reactstrap';
import { required, email, composeValidators } from 'config/InputErrors';
import contactCycleOptions from 'config/Constants/ContactCycles';

const ContactGeneralView = ( {
  data, onSubmit, editing, setEditing, toggleActivation,
} ) => (
  <div className="p-4" style={{ maxWidth: 760 }}>
    {editing ? (
      <Form
        initialValues={data}
        showErrorsInFooter
        onSubmit={onSubmit}
        body={( ) => (
          <>
            <Row className="mb-3">
              <Col md={9}>
                <TextField
                  label="name"
                  field="name"
                  placeholder="name"
                  validate={required}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={7}>
                <TextField
                  label="email"
                  type="email"
                  field="email"
                  placeholder="email"
                  validate={composeValidators( required, email )}
                />
              </Col>
              <Col md={5}>
                <Phone
                  field="phone"
                  label="phone"
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  label="owner"
                  field="owner"
                  placeholder="owner"
                  validate={required}
                />
              </Col>
              <Col>
                <Select
                  label="crm.contacts.cycle"
                  field="cycle"
                  placeholder="crm.contacts.cycle"
                  options={contactCycleOptions}
                  validate={required}
                />
              </Col>
              <Col>
                <Switch field="isActive" label="active" />
              </Col>
            </Row>
          </>
        )}
        footer={<FormActionsBtns onClickCancel={() => { setEditing( false ); }} />}
      />
    ) : (
      <>
        <Row className="pb-2 mb-4 border-bottom">
          <Col>
            <h2 className="">
              {data?.name || '-'}
            </h2>
          </Col>
          <Col xs="auto">
            <label className="form-control-label">
              <Intl id="active" />
            </label>
            <div className="">
              <Switch
                name="isActive"
                formGroupClass="m-0 p-0 d-inline-block"
                value={data?.isActive}
                hideSideLabels
                onChange={( e ) => toggleActivation( e.target.checked )}
              />
            </div>
          </Col>
          <Col xs="auto" className="text-right">
            <Button color="primary" onClick={() => setEditing( true )}>
              <Intl id="edit" />
            </Button>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <label className="form-control-label">
              <Intl id="email" />
            </label>
            <div className="">
              <a href={`mailto:${data?.email}`} className="text-bold text-blue text-underline">
                {data?.email || '-'}
              </a>
            </div>
          </Col>
          <Col xs="auto">
            <label className="form-control-label">
              <Intl id="phone" />
            </label>
            <div className="">
              <a href={`tel:${data?.phone}`} className="text-bold text-blue text-underline">
                {data?.phone || '-'}
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="form-control-label">
              <Intl id="crm.contacts.cycle" />
            </label>
            <div className="">
              {data?.cycle || '-'}
            </div>
          </Col>
          <Col>
            <label className="form-control-label">
              <Intl id="owner" />
            </label>
            <div className="">
              {data?.owner || '-'}
            </div>
          </Col>

        </Row>
      </>
    )}
  </div>

);

export default ContactGeneralView;
