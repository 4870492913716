import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form,
  Datepicker,
  FormActionsBtns,
  Dropzone,
  Intl,
} from 'components';
import { required } from 'config/InputErrors';

const NewPayroll = ( { submitForm, onClose } ) => (
  <>
    <h3>
      <Intl id="newPayroll" />
    </h3>
    <hr className="mt-0" />
    <Form
      onSubmit={submitForm}
      showErrorsInFooter
      body={() => (
        <Row>
          <Col md="auto">
            <Dropzone
              field="payroll"
              formGroupClass="px-3 dz-profile-img"
              dropzoneFileLimitMsg="dropzoneFileLimitMsg.pdfOrImgs"
              accept=".pdf,image/*"
            />
          </Col>
          <Col>
            <Datepicker
              field="monthYear"
              label="monthYear"
              dateFormat="MM/YYYY"
              utc
              labelClass="font-weight-bold"
              validate={required}
            />
          </Col>
        </Row>
      )}
      footer={(
        <FormActionsBtns
          onClickCancel={onClose}
        />
        )}
    />
  </>
);

export default NewPayroll;
