import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Col, Container, Row, Button,
} from 'reactstrap';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import {
  SimpleHeader, DetailCard, Intl, ResourceAvatar, CanAccess,
} from 'components';
import { getInitials, getSlotSchedule } from 'utils';

const DROPPABLE_ASSIGNED_ID = 'droppableAssigned';
const DROPPABLE_RESOURCES_ID = '"droppableResources"';

export default React.memo( ( {
  data, isLoading, parentPath, onToggleEdit,
  onAssignSlot,
} ) => {
  const getItemStyle = ( style, snapshot ) => {
    if ( !snapshot.isDropAnimating ) return style;

    const { curve, duration } = snapshot.dropAnimation;
    return {
      ...style,
      transition: `transform 0.01s ${curve}, opacity ${duration}s ${curve}`,
    };
  };

  const onDragEnd = ( result ) => {
    const isDroppableAssigned = _.get( result, 'destination.droppableId' ) === DROPPABLE_ASSIGNED_ID;

    if ( isDroppableAssigned ) {
      onAssignSlot( result.draggableId );
    }
  };

  const assignedId = _.get( data, 'assigned.id', '' );

  return (
    <>
      <SimpleHeader
        parentLinks={[{ name: 'shifts', href: parentPath }]}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Container
          fluid
          className={classnames( { 'app-component-loading': isLoading } )}
        >
          <Row>
            <Col lg={6}>
              <DetailCard
                borderless
                header={(
                  <Row>
                    <Col md={9}>
                      <h4 className="m-0">
                        {data.shiftTemplateName}
                        {' '}
                        {data.date ? moment.utc( data.date ).format( 'DD/MMM/YYYY' ) : ''}
                        {' - '}
                        {getSlotSchedule( data )}
                      </h4>
                    </Col>
                    <CanAccess I="edit" a="shift">
                      <Col md={3} className="text-right">
                        <Button color="primary" size="app-sm" onClick={onToggleEdit}>
                          <Intl id="edit" />
                        </Button>
                      </Col>
                    </CanAccess>
                  </Row>
                )}
              >
                <Row>
                  <Col>
                    <p className="mb-0 font-weight-bold">
                      <Intl id="team" />
                    </p>
                    <p>{_.get( data, 'team.name' )}</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={4}>
                    <p className="mb-2 font-weight-bold">
                      <Intl id="manager" />
                    </p>
                    <ResourceAvatar data={_.get( data, 'team.manager' )} />
                  </Col>
                  <Col className="pt-3 pt-lg-0">
                    <p className="mb-2 font-weight-bold">
                      <Intl id="supervisors" />
                    </p>
                    <Row>
                      {_.map( _.get( data, 'team.admins', [] ), ( supervisor ) => (
                        <ResourceAvatar key={supervisor.id} data={supervisor} className="mr-2" />
                      ) )}
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p className="mb-2 pt-3 font-weight-bold">
                      <Intl id="starterLocation" />
                    </p>
                    {data.location ? (
                      <p>
                        {_.get( data, 'location.name' )}
                        {' '}
                        (
                        {_.get( data, 'location.address' )}
                        )
                      </p>
                    ) : <Intl id="notDefined" />}
                  </Col>
                </Row>

              </DetailCard>

              <Droppable
                droppableId={DROPPABLE_RESOURCES_ID}
                isDropDisabled
                direction="horizontal"
              >
                {( provided ) => (
                  <DetailCard
                    borderless
                    classNames={{ card: 'mt-3', header: 'text-uppercase' }}
                    title="availableResources"
                  >
                    <div className="row" ref={provided.innerRef}>
                      {_.map( _.filter( _.get( data, 'team.employees', [] ),
                        ( [id] ) => id !== assignedId ),
                      ( [id, name, photo], index ) => (
                        <Draggable
                          draggableId={id}
                          key={id}
                          index={index}
                        >
                          {( providedD, snapshotD ) => (
                            <div
                              className="col-md-3 text-center"
                              ref={providedD.innerRef}
                              {...providedD.draggableProps}
                              {...providedD.dragHandleProps}
                              style={getItemStyle(
                                providedD.draggableProps.style, snapshotD,
                              )}
                            >
                              <span className="avatar shift-resource-avatar">
                                { photo
                                  ? <img alt="..." src={photo} />
                                  : (
                                    <h2 className="text-white m-0 font-weight-normal">
                                      {getInitials( name )}
                                    </h2>
                                  )}
                              </span>
                              <p className="lh-100 mt-1">
                                {name}
                              </p>
                            </div>
                          )}
                        </Draggable>
                      ) )}

                      {provided.placeholder}
                    </div>
                  </DetailCard>
                )}
              </Droppable>
            </Col>

            <Col lg={6}>
              <DetailCard
                borderless
                header={(
                  <h4 className="m-0 text-uppercase">
                    <Intl id="resources" />
                  </h4>
                )}
              >
                <Row>
                  {
                    data.assigned
                      ? (
                        <Col
                          md={3}
                          className={classnames( 'text-center pb-3 shift-resource-status', {
                            'shift-resource-status-pending': data.status === 'pending',
                            'shift-resource-status-accepted': data.status === 'accepted',
                            'shift-resource-status-rejected': data.status === 'rejected',
                          } )}
                        >
                          <span className="avatar shift-resource-avatar">
                            {_.get( data, 'assigned.profilePicture' )
                              ? <img alt="..." src={data.assigned.profilePicture} />
                              : (
                                <h3 className="text-white m-0 font-weight-normal">
                                  {data.assigned.firstName}
                                  {' '}
                                  {data.assigned.lastName ? `${data.assigned.lastName[0]}.` : ''}
                                </h3>
                              )}
                          </span>
                          <p className="lh-100 mt-1 mb-0">
                            {data.assigned.firstName}
                            {' '}
                            {data.assigned.lastName}
                          </p>
                        </Col>
                      )
                      : (
                        <Droppable
                          droppableId={DROPPABLE_ASSIGNED_ID}
                        >
                          {( provided ) => (
                            <div className="col-md-3 text-center pb-3" ref={provided.innerRef}>
                              <span className="shift-slot-dnd avatar shift-resource-avatar">
                                <Intl id="dragDropResource" />
                              </span>
                            </div>
                          )}
                        </Droppable>
                      )
                  }
                </Row>
              </DetailCard>
            </Col>
          </Row>
        </Container>
      </DragDropContext>

    </>
  );
} );
