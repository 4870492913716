import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ServiceDashboardView from 'views/Service/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import ServiceService from 'api/Service';
import { useDelete, useFetchTableData } from 'hooks';

const ServiceDashboard = ( {
  defaultFilters, parentPath, toggleErrorAlert, toggleLoading, updateFilters, setFiltersError,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ServiceService.getServices,
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'services', {
        keyword: params.keyword,
      } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'services' );
        initialReloadError.current = false;
      }
    },
  } );

  const deleteEntity = useDelete( {
    promise: ServiceService.deleteService,
    callback: () => { setDataSubmitted( new Date().valueOf() ); },
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <ServiceDashboardView
      data={data}
      isLoading={dataLoading}
      defaultFilters={defaultFilters}
      parentPath={parentPath}
      onFetchData={fetchData}
      onDelete={deleteEntity}
    />
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.services' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ServiceDashboard );
