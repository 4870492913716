import _filter from 'lodash/filter';
import _groupBy from 'lodash/groupBy';
import _values from 'lodash/values';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import moment from 'moment';

const pipe = ( ...fns ) => ( x ) => fns.reduce( ( v, f ) => f( v ), x );

const getCustomerInfo = ( shifts ) => ( {
  employeeId: _get( shifts, '0.employeeId' ),
  firstName: _get( shifts, '0.employeeName', 'No asignado' ),
  profilePicture: _get( shifts, '0.employeeAvatar' ),
  teamName: _get( shifts, '0.teamName' ),
  teamId: _get( shifts, '0.teamId' ),
  teamColor: _get( shifts, '0.teamColor' ),
} );

const fillDateRangeShifts = ( shifts, dateRangeInt ) => pipe(
  ( value ) => _reduce( value, ( acc, e ) => (
    {
      ...acc,
      [e.dateInt]: [..._get( acc, e.dateInt, [] ), e],
    } ), {} ),
  ( value ) => _map( dateRangeInt, ( e ) => (
    value[e] ? { dateInt: e, shifts: value[e] }
      : { dateInt: e, shifts: [] }
  ) ),
)( shifts );

const getDateRangeIntArray = ( dateRange ) => {
  const array = [];
  const currentDate = moment( dateRange.start );
  while ( currentDate.isSameOrBefore( dateRange.end ) ) {
    array.push( parseInt( currentDate.format( 'YYYYMMDD' ), 10 ) );
    currentDate.add( '1', 'day' );
  }
  return array;
};

export const formatDataShifts = ( {
  employees,
  shifts,
}, dateRange ) => {
  const employeesFormated = employees.filter( ( { team } ) => team ).map( ( {
    firstName = '',
    lastName = '',
    id,
    team,
  } ) => ( {
    teamId: _get( team, 'id' ),
    teamName: _get( team, 'name' ),
    teamColor: _get( team, 'color' ),
    employeeId: id,
    employeeName: `${firstName} ${lastName}`,
  } ) );
  const dataToFormat = [...employeesFormated, ...shifts];

  const dateRangeInt = getDateRangeIntArray( dateRange );

  const formatShiftsByUsers = ( data ) => pipe(
    ( value ) => _groupBy( value, 'employeeId' ),
    _values,
    ( value ) => _map( value, ( e ) => ( {
      customer: getCustomerInfo( e ),
      shifts: fillDateRangeShifts( e, dateRangeInt ),
    } ) ),
  )( data );

  const assignedShifts = pipe(
    ( value ) => _filter( value, ( e ) => e.employeeId ),
    formatShiftsByUsers,
  )( dataToFormat );

  const notAssignedShifts = pipe(
    ( value ) => _filter( value, ( e ) => !e.employeeId ),
    formatShiftsByUsers,
  )( dataToFormat );

  const allShifts = [...notAssignedShifts, ...assignedShifts];

  return Object.values( _groupBy( allShifts, 'customer.teamId' ) ).map( ( item ) => ( ( {
    team: {
      teamId: _get( item, '[0].customer.teamId' ),
      teamName: _get( item, '[0].customer.teamName' ),
      teamColor: _get( item, '[0].customer.teamColor' ),
    },
    shifts: item,
  } ) ) );
};

const geShiftInfo = ( shifts ) => ( {
  teamId: _get( shifts, '[0].teamId' ),
  teamName: _get( shifts, '[0].teamName' ),
  teamColor: _get( shifts, '[0].teamColor' ),
} );

const getTotalReports = ( data ) => {
  const totalShifts = {};
  _forEach( data, ( shift ) => {
    totalShifts[shift.dateInt] = {
      shifts: totalShifts[shift.dateInt]
        ? totalShifts[shift.dateInt].shifts + shift.shifts
        : shift.shifts,
      dateInt: shift.dateInt,
    };
  } );
  return _values( totalShifts );
};

export const formatDataReports = ( dataToFormat, dateRange ) => {
  const dateRangeInt = getDateRangeIntArray( dateRange );

  const totalReports = pipe(
    getTotalReports,
    ( value ) => fillDateRangeShifts( value, dateRangeInt ),
    ( value ) => ( { teamName: 'Total', shifts: value, teamId: 'total' } ),
  )( dataToFormat );

  const formattedData = pipe(
    ( value ) => _groupBy( value, 'teamName' ),
    _values,
    ( value ) => _map( value, ( e ) => ( {
      ...geShiftInfo( e ),
      shifts: fillDateRangeShifts( e, dateRangeInt ),
    } ) ),
    ( value ) => [...value, totalReports],
  )( dataToFormat );

  return formattedData;
};
