import React from 'react';
import { Col, Row } from 'reactstrap';
import arrayMutators from 'final-form-arrays';

import {
  TextField, ModalForm,
} from 'components';
import { required } from 'config/InputErrors';
import ScheduleForm from '../ScheduleForm';

export default React.memo( ( {
  data, open, onSubmit, onClose,
} ) => (
  <ModalForm
    title="newShiftTemplate"
    open={open}
    size="lg"
    bodyClassName="overflow-visible"
    bodyStyle={{ minHeight: 160 }}
    onClose={onClose}
    showErrorsInFooter
    onSubmit={onSubmit}
    initialValues={data}
    mutators={{ ...arrayMutators }}
    body={( form ) => (
      <>
        <Row>
          <Col md={7}>
            <TextField
              field="name"
              label="name"
              validate={required}
            />
          </Col>
        </Row>

        <p className="text-sm mt-2 mb-1">Choose the schedule configuration for the shift</p>
        <ScheduleForm form={form} formId="new" />
      </>
    )}
  />
) );
