import api from 'config/Api/Api';

export default {
  requestAuthCode( data ) {
    return api.get( '/authorize', {
      ...data,
      clientId: process.env.REACT_APP_CLIENT_ID,
      responseType: 'code',
      scope: 'all',
      approved: true,
      responseFormat: 'json',
    } );
  },

  loginUser( data ) {
    return api.post( '/access-token', {
      ...data,
      clientId: process.env.REACT_APP_CLIENT_ID,
      grantType: 'authorization_code',
    } );
  },

  changePassword( id, data ) {
    return api.put( `/customers/${id}`, data );
  },
};
