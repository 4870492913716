/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  AddBtn,
  AsyncTable,
  TableActionsBtns,
  TableEditBtn,
  DaterangePickerBtn,
  TableToggler,
  Switch,
} from 'components';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import Container from 'reactstrap/lib/Container';

export default React.memo(
  ( {
    data,
    visible,
    onFetchData,
    onDeleteItem,
    onOpenNewModal,
    onOpenEditItemModal,
    isDataLoading,
  } ) => {
    if ( !visible ) {
      return null;
    }

    return (
      <Container fluid>
        <AsyncTable
          id="seasons"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isDataLoading}
          disableSorting
          onFetchData={onFetchData}
          customFilters={( ) => (
            <Row>

              <TableToggler />
            </Row>
          )}
          customToggleFilters={( { filters, setFilter, setMultipleFilters } ) => (
            <>
              <Row className="ml-0">
                <Col className="pl-0 py-2">
                  <Switch
                    label="filterByDate"
                    value={filters.filterByDate}
                    inputOnChange={( ) => {
                      setFilter( 'filterByDate', filters.filterByDate === undefined ? 'true' : undefined );
                    }}
                  />
                </Col>
              </Row>
              <Row className="ml-0">
                <Col className="pl-0 py-2">
                  <DaterangePickerBtn
                    label="startDate"
                    position="left"
                    input
                    clearable
                    initialValue={moment.range( filters.fromStart, filters.fromEnd )}
                    initialValueSet={!!filters && !!filters.fromStart && !!filters.fromEnd}
                    key={filters.tillStart + filters.tillEnd}
                    onChange={( values ) => {
                      setMultipleFilters( {
                        fromStart: values ? moment( values.start ).format( 'YYYY-MM-DD' ) : null,
                        fromEnd: values ? moment( values.end ).format( 'YYYY-MM-DD' ) : null,
                        tillStart: null,
                        tillEnd: null,
                      } );
                    }}
                  />
                </Col>
                <Col className="pl-0 py-2">
                  <DaterangePickerBtn
                    label="lastNight"
                    position="rigth"
                    input
                    clearable
                    initialValue={moment.range( filters.fromStart, filters.fromEnd )}
                    initialValueSet={!!filters && !!filters.fromStart && !!filters.fromEnd}
                    key={filters.tillStart + filters.tillEnd}
                    onChange={( values ) => {
                      setMultipleFilters( {
                        tillStart: values ? moment( values.start ).format( 'YYYY-MM-DD' ) : null,
                        tillEnd: values ? moment( values.end ).format( 'YYYY-MM-DD' ) : null,
                        fromStart: null,
                        fromEnd: null,
                      } );
                    }}
                  />
                </Col>
                <Col className="pl-0 py-2">
                  <DaterangePickerBtn
                    label="seasonBetween"
                    position="rigth"
                    input
                    clearable
                    visible={false}
                    initialValue={moment.range( filters.fromStart, filters.fromEnd )}
                    initialValueSet={!!filters && !!filters.fromStart && !!filters.fromEnd}
                    key={filters.tillStart + filters.tillEnd}
                    onChange={( values ) => {
                      setMultipleFilters( {
                        fromStart: values ? moment( values.start ).format( 'YYYY-MM-DD' ) : null,
                        tillEnd: values ? moment( values.end ).format( 'YYYY-MM-DD' ) : null,
                        fromEnd: null,
                        tillStart: null,
                      } );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          rightContent={<AddBtn title="newSeason" onClick={() => onOpenNewModal()} />}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <TableEditBtn
                  title={cell.value.substr( 0, 6 )}
                  onClick={() => onOpenEditItemModal( cell.value )}
                />
              ),
            },
            {
              Header: 'discountType',
              accessor: 'discountType',
            },
            {
              Header: 'discountValue',
              accessor: 'discountValue',
              Cell: ( { cell } ) => (
                cell.value
                || 0
              ),
            },
            {
              Header: 'startDate',
              accessor: 'startDate',
              Cell: ( { cell } ) => (
                cell.value
                  ? moment.utc( new Date( cell.value ) ).format( 'DD/MM/YYYY' )
                  : ''
              ),
            },
            {
              Header: 'lastNight',
              accessor: 'lastNight',
              Cell: ( { cell } ) => (
                cell.value
                  ? moment.utc( new Date( cell.value ) ).format( 'DD/MM/YYYY' )
                  : ''
              ),
            },
            {
              accessor: 'id',
              disableSorting: true,
              Cell: ( { cell } ) => (
                <TableActionsBtns
                  actions={[
                    {
                      isDelete: true,
                      onClick: ( cb ) => onDeleteItem( cell.value, cb ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </Container>

    );
  },
);
