import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  AsyncTable,
  AddBtn,
  SimpleHeader,
  TableGenericFilter,
  TableActionsBtns,
} from 'components';

export default React.memo(
  ( {
    data,
    isLoading,
    parentPath,
    defaultFilters,
    onFetchData,
    onDelete,
  } ) => (
    <>
      <SimpleHeader title="uniquePhrases" />
      <Container fluid>
        <AsyncTable
          id="uniquePhrases"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={( <AddBtn title="newUniquePhrase" route={`${parentPath}/new`} /> )}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter filters={filters} setFilter={setFilter} />
              </Col>
              {/* <TableToggler /> */}
            </Row>
          )}
            // customToggleFilters={( {
            //   filters,
            //   setFilter,
            //   setMultipleFilters,
            // } ) => (
            //   <>
            //     <Row>
            //       <Col md={8} lg={3}>
            //         <TableSelectFilter
            //           name="city"
            //           label="city"
            //           placeholder="all"
            //           filters={filters}
            //           isClearable
            //           defaultOptions
            //           setFilter={setFilter}
            //           options={optionCities}
            //         />
            //       </Col>
            //       {optionFields.map( ( item ) => (
            //         <Col key={item.id} md={8} lg={3}>
            //           <CustomField
            //             filters={filters}
            //             setFilter={setFilter}
            //             field={item}
            //             setMultipleFilters={setMultipleFilters}
            //           />
            //         </Col>
            //       ) )}
            //     </Row>
            //   </>
            // )}
          columns={[
            {
              Header: 'title',
              accessor: 'title',
              Cell: ( { cell } ) => (
                <Link
                  to={`${parentPath}/edit/${cell.row.original.id}`}
                >
                  {cell.value}
                </Link>
              ),
            },
            {
              Header: 'description',
              accessor: 'text',
            },
            {
              Header: 'country',
              accessor: 'country',
              Cell: ( { cell } ) => ( cell.value.name ? cell.value.name : '-' ),
            },
            {
              Header: 'city',
              accessor: 'city',
              Cell: ( { cell } ) => ( cell.value.name ? cell.value.name : '-' ),
            },
            {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => {
                const actions = [];

                actions.push( {
                  link: `${parentPath}/edit/${cell.row.original.id}`,
                  isEdit: true,
                } );
                actions.push( {
                  isDelete: true,
                  onClick: ( cb ) => onDelete( cell.value, cb ),
                } );
                return <TableActionsBtns actions={actions} />;
              },
            },

          ]}
        />
      </Container>
    </>
  ),
);
