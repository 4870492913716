import { storage } from 'utils';

const getPropertyManager = () => {
  const dataStringPersist = storage.get( 'persist:nomads' );

  if ( !dataStringPersist ) {
    return '';
  }

  const dataJSONPersist = JSON.parse( dataStringPersist );
  const dataJSONUser = JSON.parse( dataJSONPersist?.user ?? null );

  if ( !dataJSONUser ) {
    return '';
  }

  return dataJSONUser?.user?.propertyManager?.id ?? '';
};

export default getPropertyManager;
