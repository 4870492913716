import React from 'react';
import {
  Intl,
  DetailCard,
  LocalTable,
  AddBtn,
  TableActionsBtns,
} from 'components';
import { Row, Col } from 'reactstrap';

import { canView } from 'config/Ability';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import { currencyFormat, formatFloat } from 'utils';
import _ from 'lodash';
import { bookedServiceStatus } from 'config/Constants';

export default React.memo(
  ( {
    data, isLoading, userCurrency, pathLayout, onDelete,
  } ) => (
    <DetailCard
      classNames={{ header: 'py-2 border-0', body: 'pt-0 pb-2 px-0', card: 'card-sec-bottom' }}
      header={(
        <Row className="justify-content-stretch align-items-center">
          <Col>
            <h3 className="m-0">
              <Intl id="services" />
            </h3>
          </Col>
          <Col className="text-right">
            <AddBtn title="newService" route={`${pathLayout}/services/new`} />
          </Col>
        </Row>
      )}
      isLoading={isLoading}
    >

      <LocalTable
        onlyTable
        manualPagination
        data={data.data}
        columns={[
          {
            Header: '#',
            accessor: 'id',
            Cell: ( { cell } ) => ( canView( 'booking' ) ? (
              <Link
                to={`${pathLayout}/services/edit/${cell.row.original.id}`}
                className="text-uppercase"
              >
                {cell.value
                  ? ` #${cell.value.slice(
                    cell.value.length - 6,
                    cell.value.length,
                  )}`
                  : ''}
              </Link>
            ) : (
              <span>
                {cell.value
                  ? ` #${cell.value.slice(
                    cell.value.length - 6,
                    cell.value.length,
                  )}`
                  : ''}
              </span>
            ) ),
          },
          {
            Header: 'date',
            accessor: 'date',
            Cell: ( { cell } ) => ( cell.value
              ? `${moment.utc( cell.value ).format( 'MMM DD, YYYY' )}`
              : '' ),
          },
          {
            Header: 'service',
            accessor: 'service',
            Cell: ( { cell } ) => ( cell.value ? <span>{cell.value.name}</span> : '' ),
          },
          {
            Header: 'cost',
            accessor: 'cost',
            Cell: ( { cell } ) => ( cell.value
              ? numeral( formatFloat( cell.value ) ).format(
                currencyFormat( userCurrency ),
              )
              : '' ),
          },
          {
            Header: 'createdAt',
            accessor: 'created',
            Cell: ( { cell } ) => ( cell.value ? moment( cell.value ).format( 'MMM DD, YYYY' ) : '' ),
          },
          {
            Header: 'status',
            accessor: 'status',
            Cell: ( { cell } ) => {
              const status = _.find( bookedServiceStatus, { id: cell.value } );
              return status ? (
                <span className={status.textClass}>
                  <Intl id={status.name} />
                </span>
              ) : null;
            },
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </DetailCard>
  ),
);
