import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import AmenityService from 'api/Amenity';
import AmenityGeneratedService from 'api/AmenityGenerated';
import { handlePromise } from 'utils';
import AmenityInventoryEditView from 'views/Places/Edit/AmenityInventory/Edit';

const AmenityInventoryEdit = ( {
  placeId,
  amenityGeneratedId,
  modalOpened,
  onReload,
  toggleEditModal,
  toggleErrorAlert,
  toggleInfoAlert,
  toggleLoading,
} ) => {
  const [amenitiesKeyword, setAmenitiesKeyword] = useState( '' );

  const [amenities, amenitiesLoading] = useFetch( {
    initialState: { data: [] },
    promise: () => AmenityService.getAmenities(
      { elementsPerPage: 10, keyword: amenitiesKeyword },
    ),
    format: ( dataToFormat ) => dataToFormat.data,
    toggleErrorAlert,
    conditional: modalOpened && !amenityGeneratedId,
    reInit: true,
    deps: [modalOpened, amenitiesKeyword],
  } );

  const [amenityGenerated, amenityGeneratedLoading] = useFetch( {
    initialState: {},
    promise: () => AmenityGeneratedService.getAmenityGenerated( amenityGeneratedId ),
    toggleErrorAlert,
    conditional: modalOpened && amenityGeneratedId,
    reInit: true,
    deps: [modalOpened, amenityGeneratedId],
  } );

  const submitForm = useCallback(
    async ( dataToSend ) => {
      toggleLoading( true );

      const dateFormatted = {
        ...dataToSend,
        activeClient: dataToSend.activeClient.toString(),
        activeEmployee: dataToSend.activeEmployee.toString(),
      };

      if ( amenityGeneratedId ) {
        delete dateFormatted.amenity;
        delete dateFormatted.propertyManager;
        delete dateFormatted.created;
        delete dateFormatted.updated;
        delete dateFormatted.accommodation;
        delete dateFormatted.id;
      }

      delete dateFormatted.image;

      const [errors, response, amenityData] = await handlePromise(
        amenityGeneratedId
          ? AmenityGeneratedService.updateAmenityGenerated(
            amenityGeneratedId,
            dateFormatted,
          )
          : AmenityGeneratedService.saveAmenityGenerated( dateFormatted ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      if ( !dataToSend.image || !dataToSend.image.file ) {
        toggleLoading( false );
        toggleInfoAlert( 'dataSaved' );
        return onReload();
      }

      const [imageErrors, imageResponse] = await handlePromise(
        AmenityGeneratedService.saveImage( amenityGeneratedId || amenityData.id, {
          image: dataToSend.image.file,
        } ),
      );

      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      return onReload();
    },
    [amenityGeneratedId, onReload, toggleInfoAlert, toggleLoading],
  );

  return (
    <AmenityInventoryEditView
      data={amenityGenerated}
      amenityGeneratedId={amenityGeneratedId}
      placeId={placeId}
      options={{ amenities }}
      open={modalOpened}
      isLoading={amenitiesLoading || amenityGeneratedLoading}
      onSubmit={submitForm}
      toggleEditModal={toggleEditModal}
      setAmenitiesKeyword={setAmenitiesKeyword}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( AmenityInventoryEdit );
