import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import NotificationService from 'api/NotificationCenter';
import SettingsActions from 'store/reducers/Settings';
import NotificationCenterView from 'views/NotificationCenter';
import {
  handlePromise,
} from 'utils';

const NotificationCenter = ( {
  notificationCenterCollapsed, unreadNotifications, setUnreadNotifications,
  toggleNotificationCenter, toggleErrorAlert,
} ) => {
  const [page, setPage] = useState( 1 );
  const [loadingUpdate, setLoadingUpdate] = useState( false );
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( false );

  const getNotificationsCount = useCallback( async () => {
    setDataLoading( true );
    const [errors, response, notificationsCount] = await handlePromise(
      NotificationService.getNotificationsCount(),
    );
    setDataLoading( false );
    if ( !response.ok ) {
      toggleErrorAlert();
      return errors;
    }
    setUnreadNotifications( notificationsCount?.total || 0 );
  }, [setUnreadNotifications, toggleErrorAlert] );

  const getNotifications = useCallback( async () => {
    setDataLoading( true );
    const [errors, response, notificationsData] = await handlePromise(
      NotificationService.getNotifications(),
    );
    setDataLoading( false );
    if ( !response.ok ) {
      toggleErrorAlert();
      return errors;
    }

    if ( notificationsData.data ) {
      const unread = notificationsData.data.filter( ( item ) => !item.read ).length;
      setUnreadNotifications( unread );
    }

    setData( notificationsData );
  }, [setUnreadNotifications, toggleErrorAlert] );

  const submitReadNotification = useCallback( async ( id ) => {
    setLoadingUpdate( true );

    const [errors, response] = await handlePromise(
      NotificationService.updateProcessedNotifications( [id] ),
    );
    if ( !response.ok ) {
      setLoadingUpdate( false );
      toggleErrorAlert( errors );
      return errors;
    }
    setLoadingUpdate( false );
    return getNotifications();
  }, [getNotifications, toggleErrorAlert, setLoadingUpdate] );

  const submitReadAll = useCallback( async ( ) => {
    if ( data && data.data ) {
      const instantNotifications = data.data.map( ( n ) => n.id );

      setLoadingUpdate( true );

      const [errors, response] = await handlePromise(
        NotificationService.updateProcessedNotifications( instantNotifications ),
      );
      if ( !response.ok ) {
        setLoadingUpdate( false );
        toggleErrorAlert( errors );
        return errors;
      }
      setLoadingUpdate( false );
      return getNotifications();
    }
  }, [getNotifications, toggleErrorAlert, setLoadingUpdate, data] );

  useEffect( () => {
    if ( notificationCenterCollapsed ) {
      setPage( 1 );
      getNotifications();
    } else {
      getNotificationsCount();
    }
  }, [notificationCenterCollapsed, getNotifications, getNotificationsCount] );

  useEffect( () => {
    const timer = setInterval( getNotificationsCount, 180000 );

    return () => {
      clearInterval( timer );
    };
  }, [getNotificationsCount] );

  return (
    <NotificationCenterView
      open={notificationCenterCollapsed}
      onClose={toggleNotificationCenter}
      data={data}
      loading={dataLoading || loadingUpdate}
      pageIndex={page}
      gotoPage={setPage}
      unreadNotifications={unreadNotifications}
      onReadAll={submitReadAll}
      onReadNotification={submitReadNotification}
    />
  );
};

const mapStateToProps = ( {
  settings, // user,
} ) => ( {
//  user: user.user,
  notificationCenterCollapsed: settings.notificationCenterCollapsed,
  unreadNotifications: settings.unreadNotifications,
  // reloadNotificationsFlag: settings.reloadNotificationsFlag,
} );

const mapDispatchToProps = ( {
  toggleNotificationCenter: SettingsActions.toggleNotificationCenter,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  setUnreadNotifications: SettingsActions.setUnreadNotifications,
} );

export default connect( mapStateToProps, mapDispatchToProps )( NotificationCenter );
