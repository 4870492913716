import _ from 'lodash';

// eslint-disable-next-line no-nested-ternary
const email = ( val ) => ( val ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( val ) ? null : 'fieldError.email' : null );

// eslint-disable-next-line no-nested-ternary
const phoneNumber = ( val ) => ( val ? /^\+?[0-9]+$/i.test( val ) ? null : 'fieldError.phoneNumber' : null );

const equalTo = ( fieldToValue ) => ( fieldFromValue, values ) => ( fieldFromValue === values[fieldToValue] ? null : 'fieldError.equalTo' );

const required = ( val ) => {
  if ( _.isBoolean( val ) ) {
    return val ? null : 'fieldError.required';
  }
  return val && val.toString().length ? null : 'fieldError.required';
};

// eslint-disable-next-line no-nested-ternary
const integer = ( val ) => ( val ? /^\d+$/i.test( val ) ? null : 'fieldError.integer' : null );

const validUrl = ( val ) => {
  if ( val ) {
    return val.match( /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ ) ? null : 'fieldError.url';
  }
  return null;
};

const composeValidators = ( ...validators ) => ( value, values ) => (
  validators.reduce( ( error, validator ) => error || validator( value, values ), undefined )
);

export {
  email,
  phoneNumber,
  equalTo,
  required,
  integer,
  validUrl,
  composeValidators,
};
