import CustomerAppEditAppearanceForm from 'components/Pages/CustomerApp/Edit/Appearance';
import CustomerAppEditThemeForm from 'components/Pages/CustomerApp/Edit/Theme';
import CustomerAppEditAppliancesTutorialsForm from 'components/Pages/CustomerApp/Edit/AppliancesTutorials';
import CustomerAppEditCommunityRulesForm from 'components/Pages/CustomerApp/Edit/CommunityRules';
import CustomerAppEditEventsForm from 'components/Pages/CustomerApp/Edit/Events';
import CustomerAppEditLegalForm from 'components/Pages/CustomerApp/Edit/Legal';
import CustomerAppEditModulesForm from 'components/Pages/CustomerApp/Edit/Modules';
import CustomerAppEditReviewForm from 'components/Pages/CustomerApp/Edit/Review';
import CustomerAppEditServicesForm from 'components/Pages/CustomerApp/Edit/Services';
// import CustomerAppEditOwnAppForm from 'components/Pages/CustomerApp/Edit/OwnApp';
import CustomerAppEditWalkthroughForm from 'components/Pages/CustomerApp/Edit/Walkthrough';
import CustomerAppEditDefaultAccommodationForm from 'components/Pages/CustomerApp/Edit/DefaultAccommodation';

export default [
  {
    // name: 'customerApp.appearance.title',
    name: 'customerApp.ownApp.title',
    component: CustomerAppEditAppearanceForm,
  },
  {
    // name: 'customerApp.appearance.title',
    name: 'customerApp.theme.title',
    component: CustomerAppEditThemeForm,
  },
  {
    name: 'customerApp.walkthrough.title',
    component: CustomerAppEditWalkthroughForm,
  },
  {
    name: 'customerApp.defaultAccommodation.title',
    component: CustomerAppEditDefaultAccommodationForm,
  },
  {
    name: 'customerApp.legal.title',
    component: CustomerAppEditLegalForm,
  },
  {
    name: 'customerApp.modules.title',
    component: CustomerAppEditModulesForm,
  },
  {
    name: 'customerApp.services.title',
    component: CustomerAppEditServicesForm,
  },
  {
    name: 'customerApp.events.title',
    component: CustomerAppEditEventsForm,
  },
  {
    name: 'customerApp.appliancesTutorials.title',
    component: CustomerAppEditAppliancesTutorialsForm,
  },
  {
    name: 'customerApp.communityRules.title',
    component: CustomerAppEditCommunityRulesForm,
  },
  {
    name: 'customerApp.review.title',
    component: CustomerAppEditReviewForm,
  },
  // {
  //   name: 'customerApp.ownApp.title',
  //   component: CustomerAppEditOwnAppForm,
  // },
];
