import React from 'react';
import BusinessEditView from 'views/CRM/Business/Edit';

const BusinessEdit = ( {
  businnesId, extraData, open, onClose, onReloadData,
} ) => (
  <BusinessEditView
    businnesId={businnesId}
    extraData={extraData}
    open={open}
    onClose={onClose}
    onReloadData={onReloadData}
  />
);

export default BusinessEdit;
