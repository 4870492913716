import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
// import async from 'async';
import _ from 'lodash';

import ChecklistEditView from 'views/Checklist/Edit';
import SettingsActions from 'store/reducers/Settings';
import ChecklistService from 'api/Checklist';
import ChecklistItemService from 'api/ChecklistItem';
import { useDelete, useSubmit } from 'hooks';
import { handlePromise } from 'utils';
import TranslationService from 'api/Translation';
import { parallel } from 'utils/async';
import ItemEdit from '../ItemEdit';

const ChecklistEdit = ( {
  parentPath, history, match, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const entityId = match.params.id;
  const toEditItemId = useRef( null );
  const [data, setData] = useState( {} );
  const [items, setItems] = useState( [] );
  const [dataLoading, setDataLoading] = useState( true );
  const [editItemModalOpened, setEditItemModalOpened] = useState( false );

  /* const getEntityData = useCallback( async ( cb ) => {
    if ( !entityId ) return cb( null, {} );
    const [errors, response, responseData] = await handlePromise(
      ChecklistService.getChecklist( entityId ),
    );
    if ( !response.ok ) return cb( errors );

    const [translationErrors, translationResponse, translationResponseData] = await
    handlePromise( TranslationService.getTaskTypeTranslations( entityId, 'checkList' ) );

    if ( !translationResponse.ok ) return cb( translationErrors );

    if ( !_.isArray( translationResponseData ) ) {
      responseData.translations = translationResponseData;
    } else responseData.translations = {};

    return cb( null, responseData );
  }, [entityId] );

  const getItems = useCallback( async ( cb ) => {
    if ( !entityId ) return cb( null, [] );
    const [errors, response, responseData] = await handlePromise(
      ChecklistItemService.getChecklistItems( entityId, { elementsPerPage: 1000 } ),
    );
    if ( !response.ok ) return cb( errors );

    return cb( null, responseData.data );
  }, [entityId] ); */

  const getEntityData = useCallback( async () => {
    try {
      if ( !entityId ) return {};

      const [errors, response, responseData] = await handlePromise(
        ChecklistService.getChecklist( entityId ),
      );
      if ( !response.ok ) throw errors;

      const [translationErrors, translationResponse, translationResponseData] = await handlePromise(
        TranslationService.getTaskTypeTranslations( entityId, 'checkList' ),
      );
      if ( !translationResponse.ok ) throw translationErrors;

      if ( !_.isArray( translationResponseData ) ) {
        responseData.translations = translationResponseData;
      } else {
        responseData.translations = {};
      }

      return responseData;
    } catch ( error ) {
      return Promise.reject( error );
    }
  }, [entityId] );

  const getItems = useCallback( async () => {
    try {
      if ( !entityId ) return [];

      const [errors, response, responseData] = await handlePromise(
        ChecklistItemService.getChecklistItems( entityId, { elementsPerPage: 1000 } ),
      );
      if ( !response.ok ) throw errors;

      return responseData.data;
    } catch ( error ) {
      return Promise.reject( error );
    }
  }, [entityId] );

  useEffect( () => {
    setDataLoading( true );

    parallel( {
      entityData: getEntityData,
      items: getItems,
    }, ( errors, results ) => {
      setDataLoading( false );
      if ( errors ) return toggleErrorAlert( errors );

      if ( results && results.entityData ) {
        setData( results.entityData );
      }
      if ( results && results.items ) {
        setItems( results.items );
      }
    } );
  }, [entityId, toggleErrorAlert, getEntityData, getItems] );

  const openEditItemModal = useCallback( ( { id } = {} ) => {
    toEditItemId.current = id;
    setEditItemModalOpened( true );
  }, [] );

  const closeEditItemModal = useCallback( () => {
    setEditItemModalOpened( false );
  }, [] );

  const reloadItems = useCallback( () => {
    if ( editItemModalOpened ) closeEditItemModal();

    setDataLoading( true );
    getItems( ( errors, results ) => {
      setDataLoading( false );
      if ( errors ) return toggleErrorAlert( errors );
      setItems( results );
    } );
  }, [editItemModalOpened, closeEditItemModal, getItems, toggleErrorAlert] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ChecklistService.updateChecklist( entityId, dataToSend ),
    toggleLoading,
    toggleInfoAlert,
    deps: [entityId],
    translations: true,
    callback: () => history.push( parentPath ),
  } );

  const deleteItem = useDelete( {
    promise: ( itemId ) => ChecklistItemService.deleteChecklistItem( entityId, itemId ),
    callback: reloadItems,
    deps: [entityId],
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <ChecklistEditView
        data={data}
        items={items}
        parentPath={parentPath}
        isLoading={dataLoading}
        onSubmit={submitForm}
        onOpenEditItemModal={openEditItemModal}
        onDeleteItem={deleteItem}
      />

      <ItemEdit
        checklistId={entityId}
        itemId={toEditItemId.current}
        modalOpened={editItemModalOpened}
        onCloseModal={closeEditItemModal}
        onReload={reloadItems}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ChecklistEdit );
