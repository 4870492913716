import React from 'react';
import AccomodationOwnerEditView from 'views/AccomodationOwner/Edit';

const AccomodationOwnerEdit = ( { parentPath, history, match } ) => (
  <AccomodationOwnerEditView
    parentPath={parentPath}
    history={history}
    match={match}
  />
);

export default AccomodationOwnerEdit;
