export const serviceWeekdays = [
  { id: 'monday', name: 'monday' },
  { id: 'tuesday', name: 'tuesday' },
  { id: 'wednesday', name: 'wednesday' },
  { id: 'thursday', name: 'thursday' },
  { id: 'friday', name: 'friday' },
  { id: 'saturday', name: 'saturday' },
  { id: 'sunday', name: 'sunday' },
];

export const serviceTypes = [
  { id: 'reservation', name: 'reservation' },
  { id: 'payment', name: 'payment' },
];

export const serviceItemPaymentMethod = [
  { id: 'addon_payment', name: 'addon_payment' },
];

export const serviceItemPricePer = [
  { id: 'unit', name: 'unit' },
];

export const serviceItemType = [
  { id: 'multi_selection', name: 'multi_selection' },
];

export const serviceItemUnits = [
  { id: 'hours', name: 'hours' },
  { id: 'unit', name: 'unit' },
];
