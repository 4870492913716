import React, { useState } from 'react';
import {
  Button,
} from 'reactstrap';
import { TextField, CanAccess } from 'components';
import Dropzone from 'react-dropzone';

export default ( { disabled, onSend } ) => {
  const [message, setMessage] = useState();

  const sendTextMessage = () => {
    if ( message && message.trim() ) onSend( { body: message }, () => setMessage( null ) );
  };

  return (
    <CanAccess I="edit" a="chat">
      <TextField
        name="chat-send-message"
        value={message}
        onChange={setMessage}
        size="lg"
        formGroupClass="m-0"
        prependIcon={(
          <Dropzone
            accept="image/*"
            onDrop={( files ) => {
              if ( files && files[0] ) {
                const reader = new FileReader();
                reader.onload = ( event ) => {
                  onSend( {
                    file: files[0],
                    fileDecoded: event.target.result,
                  }, () => setMessage( null ) );
                };
                reader.readAsDataURL( files[0] );
              }
            }}
          >
            {( { getRootProps, getInputProps } ) => (
              <>
                <i className="app-icon-attachment cursor-pointer text-primary" {...getRootProps()} />
                <input {...getInputProps()} />
              </>
            )}
          </Dropzone>
        )}
        rows="1"
        type="textarea"
        resize="none"
        placeholder="typeMessage"
        onKeyDown={( e ) => {
          if ( e.keyCode === 13 && e.shiftKey === false ) {
            e.preventDefault();
            sendTextMessage();
          }
        }}
        appendIcon={(
          <Button
            className="btn-icon-only rounded-circle"
            color="primary"
            type="button"
            disabled={disabled}
            onClick={sendTextMessage}
          >
            <i className="fa fa-paper-plane" />
          </Button>
        )}
      />
    </CanAccess>
  );
};
