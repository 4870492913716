import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ShiftTemplatesView from 'views/Team/Edit/ShiftTemplates';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import TeamService from 'api/Team';
import ShiftTemplateService from 'api/ShiftTemplate';
import {
  formatDataToSend, getFromTimeString, getTillTimeString, handlePromise,
} from 'utils';

const days = [
  { id: 'mo', name: 'mondayAbbr' },
  { id: 'tu', name: 'tuesdayAbbr' },
  { id: 'we', name: 'wednesdayAbbr' },
  { id: 'th', name: 'thursdayAbbr' },
  { id: 'fr', name: 'fridayAbbr' },
  { id: 'sa', name: 'saturdayAbbr' },
  { id: 'su', name: 'sundayAbbr' },
];

const ShiftTemplates = ( {
  entityId: teamId, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [modalOpened, setModalOpened] = useState( false );
  const [dataReloaded, setDataReloaded] = useState( new Date().valueOf() );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => TeamService.getTeam( teamId ),
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      shiftTemplates: _.map( dataToFormat.shiftTemplates, ( template ) => ( {
        ...template,
        from: getFromTimeString( template ),
        till: getTillTimeString( template ),
        activeDays: _.map( days, ( day ) => {
          const storedDay = _.indexOf( template.weekDays, day.id );
          if ( storedDay !== -1 ) return { ...day, active: true };
          return day;
        } ),
      } ) ),

    } ),
    toggleErrorAlert,
    conditional: !!teamId,
    deps: [teamId, dataReloaded],
  } );

  const toggleModal = useCallback( () => {
    setModalOpened( !modalOpened );
  }, [modalOpened] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) toggleModal();
    setDataReloaded( new Date().valueOf() );
  }, [modalOpened, toggleModal] );

  const submitTemplateForm = useCallback( async ( formData, form, cb ) => {
    toggleLoading( true );

    const dataToSend = formatDataToSend( formData, form );
    const formattedData = {
      defaultSlots: dataToSend.resources,
      weekDays: _.chain( dataToSend.activeDays ).filter( { active: true } ).map( 'id' ).value(),
      from: dataToSend.from && _.isObject( dataToSend.from ) ? dataToSend.from.format( 'H:mm' ) : dataToSend.from,
      till: dataToSend.till && _.isObject( dataToSend.till ) ? dataToSend.till.format( 'H:mm' ) : dataToSend.till,
    };
    if ( !formData.id ) {
      formattedData.name = dataToSend.name;
    }

    const [errors, response] = await handlePromise(
      formData.id ? ShiftTemplateService.updateTemplate( teamId, formData.id, formattedData )
        : ShiftTemplateService.saveTemplate( teamId, formattedData ),
    );
    toggleLoading( false );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    toggleInfoAlert( 'dataSaved' );
    if ( cb ) cb();
    setDataReloaded( new Date().valueOf() );
  }, [teamId, toggleInfoAlert, toggleLoading] );

  const deleteTemplate = useCallback( async ( id, formData, cb ) => {
    toggleLoading( true );

    const [errors, response] = await handlePromise(
      ShiftTemplateService.deleteTemplate( teamId, id, {
        date: formData.date.format( 'YYYY-MM-DD' ),
      } ),
    );
    toggleLoading( false );
    if ( !response.ok ) return errors;
    reloadData();
    cb();
  }, [teamId, reloadData, toggleLoading] );

  return (
    <>
      <ShiftTemplatesView
        data={data}
        templateInitialData={{ activeDays: days }}
        isLoading={dataLoading}
        onSubmitTemplate={submitTemplateForm}
        onDeleteTemplate={deleteTemplate}
        onToggleEdit={toggleModal}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ShiftTemplates );
