import React, { useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EventDashboardView from 'views/Event/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import EventService from 'api/Event';
import { useFetchTableData } from 'hooks';

const EventDashboard = ( {
  parentPath, defaultFilters, toggleErrorAlert, updateFilters, setFiltersError,
} ) => {
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: EventService.getEvents,
    toggleErrorAlert,
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'events', { keyword: params.keyword, status: params.status } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'events' );
        initialReloadError.current = false;
      }
    },
  } );

  return (
    <EventDashboardView
      data={data}
      isLoading={dataLoading}
      parentPath={parentPath}
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
    />
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.events' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( EventDashboard );
