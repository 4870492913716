import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const UPDATE_CONTACT = gql`
mutation UpdateContact($id: String!, $email: String!, $cycle: String!, $isActive: Boolean!, $name: String!, $owner: String!, $phone: String!, $propertyManager: String!) {
  updateContact(
    id: $id
    email: $email
    cycle: $cycle
    isActive: $isActive
    name: $name
    owner: $owner
    phone: $phone
    propertyManager: $propertyManager
  ) {
    ... on BaseError {
      message
    }
    ... on MutationUpdateContactSuccess {
      __typename
      data {
        success
      }
    }
  }
}
`;

const updateContact = async ( props ) => {
  const { data } = await client.query( {
    query: UPDATE_CONTACT,
    variables: {
      ...props,
      propertyManager: getPropertyManager(),
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateContact.message ) {
    return {
      ok: false,
      message: data.updateContact.message,
    };
  }

  const updateSuccessful = data.updateContact.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateContact;
