import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import BookingPaymentView from 'views/Booking/Edit/Payment';
import SettingsActions from 'store/reducers/Settings';
import PaymentsService from 'api/Payments';
import { useSubmit } from 'hooks';
import moment from 'moment';
import { formatFloatToSend } from 'utils';
import NewPaymentModalView from 'views/Booking/Edit/Payment/New';
import useDelete from 'hooks/useDelete';
import useFetch from 'hooks/useFetch';
import PluginService from 'api/Plugin';

const BookingPayment = ( {
  bookingData = {}, userCurrency, toggleLoading, toggleInfoAlert, onReloadData, toggleErrorAlert,
} ) => {
  const [modalOpened, setModalOpened] = useState( false );
  const [selectedPayment, setSelectedPayment] = useState( {} );
  const handleClose = ( ) => {
    setModalOpened( false );
    setSelectedPayment( {} );
  };
  const handleOpen = ( payment ) => {
    if ( !_.isEmpty( payment ) ) {
      setSelectedPayment( payment );
    }
    setModalOpened( true );
  };

  const reloadData = useCallback( () => {
    handleClose();
    onReloadData();
  }, [onReloadData] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( _.isEmpty( selectedPayment ) ? PaymentsService.savePayment( {
      ...dataToSend,
      bookedAccommodation: bookingData.id,
      owner: bookingData.owner.id,
    } ) : PaymentsService.updatePayment( selectedPayment.id, {
      ...dataToSend,
      bookedAccommodation: bookingData.id,
      owner: bookingData.owner.id,
    } ) ),
    format: ( dataToFormat ) => ( _.isEmpty( selectedPayment ) ? {
      ...dataToFormat,
      amount: formatFloatToSend( dataToFormat.amount ),
      notificationDate: moment( dataToFormat.notificationDate ).format( 'YYYY-MM-DD' ),
      date: moment( dataToFormat.notificationDate ).format( 'YYYY-MM-DD' ),
      deposit: dataToFormat.deposit ? 'true' : 'false',
      // mandatory: dataToFormat.mandatory ? 'true' : 'false',
    } : {
      ...dataToFormat,
      amount: formatFloatToSend( dataToFormat.amount ),
      notificationDate: moment( dataToFormat.notificationDate ).format( 'YYYY-MM-DD' ),
    } ),
    toggleLoading,
    toggleInfoAlert,
    deps: [selectedPayment, bookingData],
    callback: reloadData,
  } );

  const deletePaymentServices = useDelete( {
    promise: PaymentsService.deletePayment,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const [plugins] = useFetch( {
    initialState: [],
    promise: () => PluginService.getPlugins( {
      installed: 'true',
    } ),
    toggleErrorAlert,
  } );

  const activePlugins = _.map( _.get( plugins, 'data', [] ), 'key' );

  return (
    <>
      <BookingPaymentView
        data={bookingData}
        userCurrency={userCurrency}
        handleOpen={handleOpen}
        handleClose={handleClose}
        modalOpened={modalOpened}
        onDelete={deletePaymentServices}
      />
      <NewPaymentModalView
        modalOpened={modalOpened}
        handleClose={handleClose}
        onSubmit={submitForm}
        selectedPayment={selectedPayment}
        plugins={activePlugins}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( BookingPayment );
