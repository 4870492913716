import React from 'react';
import {
  Container,
} from 'reactstrap';

import {
  AddBtn, SimpleHeader, TableActionsBtns, TableEditBtn, LocalTable,
} from 'components';

export default React.memo( ( {
  dataTable, isLoading, onOpenEditModal, onDelete,
} ) => (
  <>
    <SimpleHeader title="tagsPredefined" />
    <Container fluid>
      <LocalTable
        id="tagsPredefined"
        data={dataTable}
        total={dataTable.length}
        isLoading={isLoading}
        disableSorting
        useElementsPerPage={false}
        rightContent={<AddBtn title="newTagPredefined" onClick={onOpenEditModal} />}
        columns={[
          {
            Header: 'tag',
            accessor: 'tag',
            Cell: ( { cell } ) => (
              <TableEditBtn
                title={cell.value}
                onClick={() => onOpenEditModal( { tag: cell.value } )}
              />
            ),
          },
          {
            accessor: 'tag',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( ) => onDelete( cell.value ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </Container>
  </>
) );
