import React from 'react';
import {
  Row, Col, Container, Card, CardHeader,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { canView, canModify } from 'config/Ability';
import {
  AsyncTable, AddBtn, SimpleHeader, TableGenericFilter,
  TableActionsBtns, CanAccess, Intl,
} from 'components';

import defaultAccommodation from 'assets/img/defaults/accommodation.png';

export default React.memo( ( {
  data, isLoading, propertyName, roomTypeName, paths = {}, defaultFilters, onFetchData, onDelete,
} ) => {
  const canViewProp = canView( 'accommodation' );
  const canEditDelete = canModify( 'accommodation' );

  return (
    <>
      <SimpleHeader
        // title="accommodations"
        // parentLinks={[
        //   { name: `| ${propertyName} `, translate: false, href: paths.property },
        //   { name: `| ${roomTypeName}`, translate: false, href: paths.roomType },
        // ]}
        parentLinks={[
          { name: 'buildings', href: paths.property },
        ]}
      />
      <Container fluid>
        {propertyName ? (
          <Card className="card-sec-top">
            <CardHeader>
              <h3 className="m-0">
                <Link to={paths.roomType}>
                  {propertyName}
                </Link>
                {roomTypeName ? ` - ${roomTypeName}` : null}
              </h3>
            </CardHeader>
          </Card>
        ) : null}
        <AsyncTable
          id="propertyAccommodations"
          containerClassName={propertyName ? 'card-sec-bottom' : null}
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={(
            <CanAccess I="add" a="accommodation">
              <AddBtn title="newAccommodation" route={`${paths.accommodation}/new`} />
            </CanAccess>
          )}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter
                  filters={filters}
                  setFilter={setFilter}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: ' ',
              accessor: 'mainImage',
              Cell: ( { cell } ) => (
                <Link to={`${paths.accommodation}/edit/${cell.row.original.id}`}>
                  { cell.value
                    ? <img src={cell.value} alt={cell.row.original.name} width="120" />
                    : <img src={defaultAccommodation} alt={cell.row.original.name} width="120" /> }
                </Link>
              ),
            },
            {
              Header: 'name',
              accessor: 'name',
              Cell: ( { cell } ) => (
                canEditDelete || canViewProp
                  ? (
                    <Link to={`${paths.accommodation}/edit/${cell.row.original.id}`}>
                      {cell.value}
                    </Link>
                  )
                  : cell.value
              ),
            },
            {
              Header: 'type',
              accessor: 'roomType.name',
            },
            {
              Header: 'building',
              accessor: 'roomType.property.name',
            },
            {
              Header: 'address',
              accessor: 'roomType',
              Cell: ( { cell } ) => (
                `${cell.row.original.roomType.property.address} ${cell.row.original.roomType.property.addressNumber},
                ${cell.row.original.roomType.property.postalCode}
                (${cell.row.original.roomType.property.propertyManagerCityZone.propertyManagerCity.city.name})`
              ),
            },
            {
              Header: 'accommodation.block',
              accessor: 'block',
            },
            {
              Header: 'floor',
              accessor: 'floor',
            },
            {
              Header: 'door',
              accessor: 'number',
            },
            {
              Header: 'bedrooms',
              accessor: 'rooms',
            },
            {
              Header: 'bathrooms',
              accessor: 'bathrooms',
            },
            {
              Header: 'travelReport',
              accessor: 'travelReportRequired',
              Cell: ( { cell } ) => (
                <div className="text-center">
                  {cell.value
                    ? <Intl id="yes" />
                    : <Intl id="no" />}
                </div>
              ),
            },
            {
              Header: 'cleaning',
              accessor: 'cleaningStatus',
              Cell: ( { cell } ) => (
                <div className="text-center">
                  {cell.value === 'pending' && <Intl id="pending" /> }
                  {cell.value === 'in_progress' && <Intl id="in_progress" /> }
                  {cell.value === 'clean' && <Intl id="clean" /> }
                </div>
              ),
            },
            canEditDelete || canViewProp
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => {
                  const actions = [];
                  if ( canEditDelete ) {
                    actions.push( {
                      link: `${paths.accommodation}/edit/${cell.row.original.id}`,
                      isEdit: true,
                    } );
                    actions.push( {
                      isDelete: true,
                      onClick: ( cb ) => onDelete( cell.value, cb ),
                    } );
                  } else if ( canViewProp ) {
                    actions.push( {
                      link: `${paths.accommodation}/edit/${cell.row.original.id}`,
                      label: 'view',
                    } );
                  }
                  return (
                    <TableActionsBtns actions={actions} />
                  );
                },
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
}, ( prevProps, nextProps ) => _.isEqual( prevProps, nextProps ) );
