import _ from 'lodash';

export const formatResult = ( dataPlace ) => {
  if ( !dataPlace || !dataPlace.results || dataPlace.results.length === 0 ) {
    return null;
  }

  const place = dataPlace.results[0];
  const name = _.get( place, 'name', '' );
  const address = _.get( place, 'formatted_address', '' );
  const lat = _.get( place, 'geometry.location.lat', '' );
  const lng = _.get( place, 'geometry.location.lng', '' );

  return {
    name,
    address,
    lat,
    lng,
  };
};

export default {};
