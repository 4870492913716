import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const GET_RATE = gql`
  query Rate($input: DetailRateInput!) {
  rate(input: $input) {
    ... on BaseError {
      message
    }
    ... on QueryRateSuccess {
      data {
        name
        id
        accommodationsNumber
        maximumPriceSA
        isActive
        maximumPriceSuplement
        minimalPriceSA
        minimalPriceSuplement
        propertyManagerId
        validUntil
        updatedAt
      }
    }
  }
}
`;

const getRate = async ( { rateId } ) => {
  const { data } = await client.query( {
    query: GET_RATE,
    variables: {
      input: {
        rateId,
      },
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.rate.message ) {
    return {
      ok: false,
      message: data.rate.message,
    };
  }
  const rate = data.rate.data;
  return {
    ok: true,
    status: 200,
    data: { data: rate },
  };
};

export default getRate;
