/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import _get from 'lodash/get';
import _map from 'lodash/map';

import {
  Datepicker, ModalForm, AsyncSelect, Select, ResourceAvatar, Intl,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import getStatusColor from 'config/Constants/StatusColor';

const statusColor = getStatusColor( 'tasks' );

export default React.memo( ( {
  data, shiftId, open, onSubmit, onClose, onFetchTeams, onFetchProperties, preloadedData,
} ) => {
  const [resources, setResources] = useState( [] );

  const onSelectTeam = ( value ) => {
    setResources(
      _map( _get( value, 'original.members', [] ), ( member ) => ( {
        id: member.id,
        name: `${member.firstName} ${member.lastName}`,
      } ) ),
    );
  };

  const teamResources = _map( _get( data, 'team.employees', [] ), ( [id, name] ) => ( {
    id,
    name,
  } ) );

  const asigned = _get( preloadedData, 'assigned' );
  const date = _get( preloadedData, 'date' );

  return (
    <ModalForm
      title={shiftId ? 'editShift' : 'newShift'}
      open={open}
      size={shiftId ? 'md' : 'lg'}
      bodyClassName="overflow-visible"
      bodyStyle={{ minHeight: 150 }}
      onClose={onClose}
      showErrorsInFooter
      initialValues={data}
      onSubmit={canModify( 'shift' ) ? onSubmit : () => {}}
      formEnabled={canModify( 'shift' )}
      body={( form ) => (
        shiftId
          ? (
            <>
              <Row>
                <Col md={6}>
                  {asigned && (
                  <div className="mb-4">
                    <p className="mb-1 form-control-label">
                      <Intl id="resource" />
                    </p>
                    <ResourceAvatar data={asigned} avatarSize="md" />
                  </div>
                  )}
                </Col>
                <Col>
                  {
                    date
                    && (
                    <div className="mb-4">
                      <p className="mb-1 form-control-label">
                        <Intl id="startDate" />
                      </p>
                      {date.format( 'DD/MM/YYYY' )}
                    </div>
                    )
                   }
                </Col>
                <Col md={6}>
                  <Datepicker
                    dateFormat={false}
                    timeFormat="H:mm"
                    field="from"
                    label="from"
                    validate={required}
                  />
                </Col>
                <Col md={6}>
                  <Datepicker
                    dateFormat={false}
                    timeFormat="H:mm"
                    field="till"
                    label="till"
                    validate={required}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Select
                    field="assigned"
                    label="resource"
                    options={teamResources}
                  />
                </Col>
                <Col md={6}>
                  <AsyncSelect
                    field="location"
                    label="starterLocation"
                    isClearable
                    defaultOptions
                    loadOptions={onFetchProperties}
                    labelKey={( option ) => `${option.name} (${option.address})`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />

                  {( data?.tasks || [] ).length ? (
                    <>
                      <h4>
                        <Intl id="tasks" />
                      </h4>
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th>#</th>
                            <th>
                              <Intl id="category" />
                            </th>
                            <th>
                              <Intl id="status" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {( data?.tasks || [] ).map( ( t ) => (
                            <tr key={t.id}>
                              <td>
                                <strong>{`${t.id.slice( t.id.length - 6, t.id.length )}`}</strong>
                              </td>
                              <td>{t?.taskType?.name || '-'}</td>
                              <td>
                                <span className={`badge badge-${statusColor( t?.status )} badge-pill`}><Intl id={t?.status} /></span>
                              </td>
                            </tr>
                          ) ) }
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <div className="text-center">
                      <Intl id="noTasksAssigned" />
                    </div>
                  )}

                </Col>
              </Row>
            </>
          )
          : (
            <>
              <Row>
                <Col sm={6}>

                  {asigned ? (
                    <div className="mb-4">
                      <p className="mb-1 form-control-label">
                        <Intl id="resource" />
                      </p>
                      <ResourceAvatar data={asigned} avatarSize="md" />
                    </div>
                  )
                    : (
                      <AsyncSelect
                        field="team"
                        label="team"
                        defaultOptions
                        validate={required}
                        loadOptions={onFetchTeams}
                        onChange={onSelectTeam}
                      />
                    )}
                </Col>

                <Col md={6}>
                  {
                  !date ? (
                    <Datepicker
                      field="date"
                      label="startDate"
                      dateFormat="DD/MM/YYYY"
                      validate={required}
                      startDateField={{ value: moment().subtract( 1, 'day' ) }}
                    />
                  ) : (
                    <div className="mb-4">
                      <p className="mb-1 form-control-label">
                        <Intl id="startDate" />
                      </p>
                      {date.format( 'DD/MM/YYYY' )}
                    </div>
                  )
                  }
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Datepicker
                    dateFormat={false}
                    timeFormat="H:mm"
                    field="from"
                    label="from"
                    validate={required}
                  />
                </Col>
                <Col md={6}>
                  <Datepicker
                    dateFormat={false}
                    timeFormat="H:mm"
                    field="till"
                    label="till"
                    validate={required}
                  />
                </Col>
                {
                    !asigned && (
                      <Col md={6}>
                        <Select
                          field="assigned"
                          label="resource"
                          options={resources}
                        />
                      </Col>
                    )
                  }
                <Col md={6}>
                  <AsyncSelect
                    field="location"
                    label="starterLocation"
                    isClearable
                    defaultOptions
                    loadOptions={onFetchProperties}
                    labelKey={( option ) => `${option.name} (${option.address})`}
                  />
                </Col>
              </Row>
              {
              date
              && (
              <div
                className="text-danger text-center"
              >
                { _get( form.getState(), 'submitErrors.date' )}
              </div>
              )
              }
            </>
          )
      )}
    />
  );
} );
