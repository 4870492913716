import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  AsyncTable,
  AddBtn,
  SimpleHeader,
  TableGenericFilter,
  TableActionsBtns,
  CanAccess,
  TableToggler,
  TableSelectFilter,
} from 'components';
import { canView, canModify } from 'config/Ability';
// import CustomField from 'components/Form/TableFilters/CustomField';

export default React.memo(
  ( {
    data,
    isLoading,
    parentPath,
    defaultFilters,
    onFetchData,
    onDelete,
    optionCities,
    // optionFields,
    optionZones,
  } ) => {
    const canViewProp = canView( 'property' );
    const canEditDelete = canModify( 'property' );

    return (
      <>
        <SimpleHeader title="buildings" />
        <Container fluid>
          <AsyncTable
            id="properties"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            disableSorting
            defaultFilters={defaultFilters}
            rightContent={(
              <CanAccess I="add" a="property">
                <AddBtn title="newBuilding" route={`${parentPath}/new`} />
              </CanAccess>
)}
            onFetchData={onFetchData}
            customFilters={( { filters, setFilter } ) => (
              <Row>
                <Col md={8} lg={4}>
                  <TableGenericFilter filters={filters} setFilter={setFilter} />
                </Col>
                <TableToggler />
              </Row>
            )}
            customToggleFilters={( {
              filters,
              setFilter,
              // setMultipleFilters,
            } ) => (
              <>
                <Row>
                  <Col md={8} lg={3}>
                    <TableSelectFilter
                      name="city"
                      label="city"
                      placeholder="all"
                      filters={filters}
                      isClearable
                      setFilter={setFilter}
                      options={optionCities}
                    />
                  </Col>
                  <Col md={8} lg={3}>
                    <TableSelectFilter
                      enableReinitialize
                      isDisabled={optionZones.length === 0}
                      name="zone"
                      label="zone"
                      placeholder="all"
                      filters={filters}
                      isClearable
                      defaultOptions
                      setFilter={setFilter}
                      options={optionZones}
                    />
                  </Col>
                  {/* {optionFields.map( ( item ) => (
                    <Col key={item.id} md={8} lg={3}>
                      <CustomField
                        filters={filters}
                        setFilter={setFilter}
                        field={item}
                        setMultipleFilters={setMultipleFilters}
                      />
                    </Col>
                  ) )} */}
                </Row>
              </>
            )}
            columns={[
              {
                Header: 'name',
                accessor: 'name',
                Cell: ( { cell } ) => {
                  if ( canViewProp || canEditDelete ) {
                    return (
                      <Link
                        to={`${parentPath}/${cell.row.original.id}/room-types`}
                      >
                        {cell.value}
                      </Link>
                    );
                  }
                  return cell.value;
                },
              },
              {
                Header: 'address',
                accessor: 'address',
                Cell: ( { cell } ) => (
                  `${cell.value} ${cell.row.original.addressNumber}, ${cell.row.original.postalCode}`
                ),
              },
              {
                Header: 'city',
                accessor: 'address',
                Cell: ( { cell } ) => (
                  `${cell.row.original.propertyManagerCityZone.propertyManagerCity.city.name}`
                ),
              },
              canEditDelete || canViewProp
                ? {
                  accessor: 'id',
                  disableSorting: true,
                  Header: null,
                  maxWidth: 65,
                  Cell: ( { cell } ) => {
                    const actions = [];
                    if ( canEditDelete ) {
                      actions.push( {
                        link: `${parentPath}/edit/${cell.row.original.id}`,
                        isEdit: true,
                      } );
                      actions.push( {
                        isDelete: true,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      } );
                    } else if ( canViewProp ) {
                      actions.push( {
                        link: `${parentPath}/edit/${cell.row.original.id}`,
                        label: 'view',
                      } );
                    }
                    return <TableActionsBtns actions={actions} />;
                  },
                }
                : {
                  id: 'id',
                  Header: null,
                  maxWidth: 1,
                  disableSorting: true,
                },
            ]}
          />
        </Container>
      </>
    );
  },
);
