/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
const formatDataToSend = ( occupancyId, seasonId, dataToSend ) => ( seasonId ? {
  id: seasonId,
  ...formatSeason( dataToSend ),
} : {
  occupancyId,
  seasons: [{
    ...formatSeason( dataToSend ),
  },
  ],
} );

const formatSeason = ( dataToSend ) => {
  let season = {
    ...dataToSend,
    lastNight: new Date( dataToSend.lastNight ).toISOString(),
    startDate: new Date( dataToSend.startDate ).toISOString(),
  };
  if ( season.checkInDays && season.checkInDays.daysAllowedMonth ) {
    season = {
      ...season,
      checkInDays: {
        ...season.checkInDays,
        daysAllowedMonth: season.checkInDays.daysAllowedMonth.map(
          ( item ) => new Date( item ).toISOString(),
        ),
      },
    };
  }
  if ( season.checkOutDays && season.checkOutDays.daysAllowedMonth ) {
    season = {
      ...season,
      checkOutDays: {
        ...season.checkOutDays,
        daysAllowedMonth: season.checkOutDays.daysAllowedMonth.map(
          ( item ) => new Date( item ).toISOString(),
        ),
      },
    };
  }
  return season;
};

export { formatDataToSend };
