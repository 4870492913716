import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import TaskDashboardView from 'views/TaskPeriodic/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import TaskPeriodicActions from 'store/reducers/TaskPeriodic';
import FiltersActions from 'store/reducers/Filters';
import TaskPeriodicService from 'api/TaskPeriodic';
import TaskTypeService from 'api/TaskType';
import PropertyService from 'api/Property';
import { useDelete, useFetchTableData, useFetch } from 'hooks';
import AccommodationService from 'api/Accommodation';
import { history } from 'store';

const TaskDashboard = ( {
  taskReloadedAt,
  toggleTaskEditModal,
  toggleLoading,
  toggleErrorAlert,
  updateFilters,
  setFiltersError,
  searchParams,
} ) => {
  const urlSearchParams = new URLSearchParams( searchParams );

  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const showLoading = useRef( true );
  const isInitialMount = useRef( true );
  const initialReloadError = useRef( true );
  const selectedRows = useRef( [] );
  const [selectedRowsChange, setSelectedRowsChange] = useState(
    new Date().valueOf(),
  );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => TaskPeriodicService.getTasks( {
      ...params,
      taskType: _.get( params, 'taskType' ),
      properties: _.get( params, 'properties.value' )
        ? [params.properties.value]
        : undefined,
      accommodations: _.get( params, 'accommodations.value' )
        ? [params.accommodations.value]
        : undefined,
      periodicity: _.get( params, 'periodicity.value' )
        ? params.periodicity.value
        : undefined,
    } ),
    callback: ( params ) => {
      initialReloadError.current = false;
      showLoading.current = true;
      updateFilters( 'tasks', {
        keyword: params.keyword,
        taskType: params.taskType,
        properties: params.properties
          ? { value: params.properties.value, label: params.properties.label }
          : null,
        accommodations: params.accommodations
          ? {
            value: params.accommodations.value,
            label: params.accommodations.label,
          }
          : null,
        periodicity: params.periodicity
          ? { value: params.periodicity.value, label: params.periodicity.label }
          : null,
      } );

      const search = new URLSearchParams(
        _.omitBy(
          {
            ...params,
            taskType: _.get( params, 'taskType' ),
            properties_value: _.get( params, 'properties.value' ),
            properties_label: _.get( params, 'properties.label' ),
            properties: null,
            accommodations_value: _.get( params, 'accommodations.value' ),
            accommodations_label: _.get( params, 'accommodations.label' ),
            accommodations: null,
            periodicity_value: _.get( params, 'periodicity.value' ),
            periodicity_label: _.get( params, 'periodicity.label' ),
            periodicity: null,
          },
          _.isNil,
        ),
      ).toString();
      if ( search ) {
        history.replace( { search: `?${search}` } );
      }
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'tasks' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  useEffect( () => {
    const dataIds = _.map( data.data, 'id' );
    const selectedIds = _.filter(
      selectedRows.current,
      ( id ) => dataIds.indexOf( id ) !== -1,
    );
    if ( selectedIds.length !== selectedRows.current.length ) {
      selectedRows.current = selectedIds;
      setSelectedRowsChange( new Date().valueOf() );
    }
  }, [data] );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const openEditModal = useCallback(
    ( { id } = {} ) => {
      toggleTaskEditModal( id );
    },
    [toggleTaskEditModal],
  );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      showLoading.current = false;
      setDataSubmitted( new Date().valueOf() );
    }
  }, [taskReloadedAt] );

  const deleteTask = useDelete( {
    promise: TaskPeriodicService.deleteTask,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const urlFilters = () => {
    const filters = {
      keyword: urlSearchParams.get( 'keyword' ),
      taskType: urlSearchParams.get( 'taskType' ),
      properties:
        urlSearchParams.get( 'properties_value' )
        && urlSearchParams.get( 'properties_label' )
          ? {
            value: urlSearchParams.get( 'properties_value' ),
            label: urlSearchParams.get( 'properties_label' ),
          }
          : null,
      accommodations:
        urlSearchParams.get( 'accommodations_value' )
        && urlSearchParams.get( 'accommodations_label' )
          ? {
            value: urlSearchParams.get( 'accommodations_value' ),
            label: urlSearchParams.get( 'accommodations_label' ),
          }
          : null,
      periodicity:
        urlSearchParams.get( 'periodicity_value' )
        && urlSearchParams.get( 'periodicity_label' )
          ? {
            value: urlSearchParams.get( 'periodicity_value' ),
            label: urlSearchParams.get( 'periodicity_label' ),
          }
          : null,
    };
    return filters;
  };
  return (
    <>
      <TaskDashboardView
        data={data}
        isLoading={dataLoading && showLoading.current}
        defaultFilters={urlFilters()}
        selectedRowsChange={selectedRowsChange}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteTask}
        taskTypes={taskTypes}
        isLoadingTaskTypes={loadingTaskTypes}
        onFetchProperties={fetchProperties}
        onFetchAccommodations={fetchAccommodations}
      />
    </>
  );
};

const mapStateToProps = ( { taskPeriodic, router } ) => ( {
  taskReloadedAt: taskPeriodic.reloadedAt,
  searchParams: _.get( router, 'location.search', '' ),
} );

const mapDispatchToProps = ( {
  toggleTaskEditModal: TaskPeriodicActions.toggleEditModalTaskPeriodic,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( TaskDashboard );
