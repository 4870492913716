import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import PropertyManagerServices from 'api/PropertyManager';
import useDelete from 'hooks/useDelete';
import PropertyManagerChargesView from 'views/PropertyManager/Edit/Finance/Charges';

const PropertyManagerCharges = ( {
  pmData,
  userCurrency,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  /* FETCH TABLE ********************************** */
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => PropertyManagerServices.getChargesList( pmData.id, params ),
    toggleErrorAlert,
    conditional: !!pmData.id,
    deps: [dataSubmitted, pmData.id],
  } );
  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );
  /* end FETCH TABLE ********************************** */

  const deleteFee = useDelete( {
    promise: ( feeId ) => PropertyManagerServices.deleteCharge( pmData.id, feeId ),
    callback: reload,
    toggleLoading,
    toggleErrorAlert,
    deps: [pmData.id],
  } );

  /** **************************************** */
  const [modalAddOpen, setModalAddOpen] = useState( false );
  const [elementToEdit, setElementToEdit] = useState( null );

  const toggleModalEditor = useCallback( ( status, elementData ) => {
    setElementToEdit( elementData );
    setModalAddOpen( status );
  }, [] );

  return (
    <PropertyManagerChargesView
      pmData={pmData}
      fetchData={fetchData}
      dataTable={dataTable}
      dataLoading={dataLoading}
      userCurrency={userCurrency || 'EUR'}
      reload={reload}
      onDelete={deleteFee}
      toggleModalEditor={toggleModalEditor}
      modalAddOpen={modalAddOpen}
      elementToEdit={elementToEdit}
    />
  );
};
//

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( PropertyManagerCharges );
