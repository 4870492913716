/* eslint-disable import/prefer-default-export */
const formatMessage = ( selectedMessage ) => {
  if ( !selectedMessage ) {
    return undefined;
  }
  const { message, case: subject } = selectedMessage;
  const initialMessage = `\n\nPM ---\n${message}`;
  return {
    message: initialMessage,
    case: `Re: ${subject}`,
  };
};

export { formatMessage };
