import Overview from 'containers/Customer/Edit/Overview';
import GuestData from 'containers/Customer/Edit/GuestData';
import _get from 'lodash/get';

export default [
  {
    name: 'overview',
    icon: 'fas fa-user',
    path: '',
    component: Overview,
    exact: true,
    filter: () => true,
  },
  {
    name: 'guestData',
    icon: 'fas fa-file-alt',
    path: '/guest-data',
    component: GuestData,
    exact: true,
    filter: ( data ) => _get( data, 'lastGuestData' ),
  },
];
