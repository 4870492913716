import React from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  AsyncTable, SimpleHeader, TableGenericFilter, ResourceAvatar, CanAccess,
  TableSelectFilter, TableActionsBtns, AddBtn, Intl,
} from 'components';
import { canView, canModify } from 'config/Ability';
import { eventStatuses } from 'config/Constants';

export default React.memo( ( {
  data, isLoading, parentPath, defaultFilters, onFetchData,
} ) => {
  const canViewProp = canView( 'event' );
  const canEditDelete = canModify( 'event' );

  return (
    <>
      <SimpleHeader title="community" />
      <Container fluid>
        <AsyncTable
          id="events"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          rightContent={(
            <CanAccess I="add" a="event">
              <AddBtn title="newEvent" route={`${parentPath}/new`} />
            </CanAccess>
            )}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter filters={filters} setFilter={setFilter} />
              </Col>
              <Col md={8} lg={4} className="pl-lg-0 pt-1 pt-lg-0">
                <TableSelectFilter
                  name="status"
                  placeholder="status"
                  filters={filters}
                  isClearable
                  translateOptions
                  options={eventStatuses}
                  setFilter={setFilter}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'name',
              accessor: 'name',
              Cell: ( { cell } ) => (
                canEditDelete || canViewProp
                  ? (
                    <Link to={`${parentPath}/${cell.row.original._id}`}>
                      {cell.value}
                    </Link>
                  )
                  : cell.value
              ),
            },
            {
              Header: 'date',
              accessor: 'date',
              Cell: ( { cell } ) => ( cell.value
                ? moment.utc( cell.value ).format( 'ddd DD/MM/YYYY - HH:mm' )
                : '' ),
            },
            {
              Header: 'capacity',
              accessor: 'capacity',
              Cell: ( { cell } ) => ( cell.value
                ? `${cell.value} (Apuntados: ${cell.row.original.participants.length})`
                : `Apuntados: ${cell.row.original.participants.length}` ),
            },
            {
              Header: 'creator',
              accessor: 'owner',
              Cell: ( { cell } ) => <ResourceAvatar data={cell.value} />,
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => ( cell.value ? <Intl id={cell.value} /> : '' ),
            },
            canEditDelete || canViewProp
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        link: `${parentPath}/edit/${cell.row.original._id}`,
                        label: 'edit',
                      },
                    ]}
                  />
                ),
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );
