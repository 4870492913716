import api from 'config/Api/Api';

const baseEndpoint = '/settings/tags-predefined';

export default {
  getTagsPredefined( ) {
    return api.get( baseEndpoint );
  },
  updateTagsPredefined( data ) {
    return api.put( baseEndpoint, data );
  },
};
