export const currencyFormat = ( currency ) => {
  switch ( currency ) {
    case 'EUR':
      return '0,0.[00] $';
    case 'USD':
      return '$0,0.[00]';
    default:
      return '0,0.[00]';
  }
};

export const currencyLocale = ( currency ) => {
  if ( currency === 'EUR' ) {
    return 'es';
  }
  return 'en';
};
