import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form,
  FormActionsBtns,
  Select,
  TextField,
  Switch,
} from 'components';
import { required } from 'config/InputErrors';
import { methodsOfPayment } from 'config/Constants/Fees';

const PropertyManagerFinanceSettingsView = ( {
  data, paymentGatewaysList, paymentGatewaysListLoading, submitForm, booleanData, setBooleanData,
} ) => (
  <div className="p-4" style={{ maxWidth: 900 }}>
    <Form
      onSubmit={submitForm}
      showErrorsInFooter
      initialValues={data}
      body={() => (
        <>
          <Row className="mb-4">
            <Col md={4}>
              <Select
                field="paymentGateway"
                label="paymentGateway"
                options={paymentGatewaysList}
                isLoading={paymentGatewaysListLoading}
                validate={required}
              />
            </Col>
            <Col md={8}>
              <Select
                field="methodsOfPayment"
                label="methodsOfPayment"
                options={methodsOfPayment}
                multiple
          //  validate={required}
                translateOptions
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={4}>
              <Select
                field="currency"
                label="currency"
                validate={required}
                options={[
                  { id: 'USD', name: 'USD' },
                  { id: 'EUR', name: 'EUR' },
                ]}
              />
            </Col>
            <Col md={3}>
              <TextField
                field="taxes"
                label="taxes"
                type="number"
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={3}>
              <Switch
                label="notifications"
                value={booleanData.notifications}
                onChange={( v ) => {
                  const notifications = v.target.checked;
                  setBooleanData( ( oldValue ) => ( {
                    ...oldValue,
                    notifications,
                  } ) );
                }}
              />
            </Col>
            <Col md={9}>
              <Switch
                label="notificationsUncompletedPaymentsAndSystemSuspension"
                value={booleanData.notificationsUncompletedPaymentsAndSystemSuspension}
                onChange={( v ) => {
                  const notificationsUncompletedPaymentsAndSystemSuspension = v.target.checked;
                  setBooleanData( ( oldValue ) => ( {
                    ...oldValue,
                    notificationsUncompletedPaymentsAndSystemSuspension,
                  } ) );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Switch
                label="manualPayments"
                value={booleanData.manualPayments}
                onChange={( v ) => {
                  const manualPayments = v.target.checked;
                  setBooleanData( ( oldValue ) => ( {
                    ...oldValue,
                    manualPayments,
                  } ) );
                }}
              />
            </Col>
          </Row>

        </>
      )}
      footer={<FormActionsBtns />}
    />
  </div>
);

export default PropertyManagerFinanceSettingsView;
