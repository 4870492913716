import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import EditDrawerView from 'views/Property/RoomType/Accommodation/Edit/Discounts/EditDrawer';

const EditDrawer = ( {
  discountId, open, onClose, onReloadDiscounts, onOpenModal,
} ) => (
  <EditDrawerView
    data={{ id: discountId }}
    open={open}
    onOpenModal={onOpenModal}
    onClose={onClose}
    onReloadDiscounts={onReloadDiscounts}
  />
);

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( EditDrawer );
