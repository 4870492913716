import React from 'react';
import {
  Row, Col, Card, CardHeader, CardBody,
} from 'reactstrap';
import { Intl } from 'components';

import moment from 'moment';

export default React.memo(
  ( {
    data,
    entityId,
  } ) => (

    <Row>
      <Col>
        <Card>
          <CardHeader>
            <h3 className="m-0">
              <Intl id="customer" />
              {' '}
              {entityId}
            </h3>
          </CardHeader>
          <CardBody>

            <Row>

              <Col md={4}>
                <Row>
                  <Col className="font-weight-bold"><Intl id="name" /></Col>
                  <Col>{data.name}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-bold"><Intl id="firstLastname" /></Col>
                  <Col>{data.firstLastname || ''}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-bold"><Intl id="secondLastname" /></Col>
                  <Col>{data.secondLastname || ''}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-bold"><Intl id="birthdate" /></Col>
                  <Col>{moment.utc( data.birthdate ).format( 'DD/MM/YYYY' )}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-bold"><Intl id="sex" /></Col>
                  <Col>{data.sex === 'M' ? <Intl id="male" /> : <Intl id="female" /> }</Col>
                </Row>
                <Row>
                  <Col className="font-weight-bold"><Intl id="nationality" /></Col>
                  <Col className="uppercase">{data.nationality}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className="font-weight-bold"><Intl id="documentType" /></Col>
                  <Col>{data.documentType}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-bold"><Intl id="documentNumber" /></Col>
                  <Col>{data.documentNumber}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-bold"><Intl id="expeditionDate" /></Col>
                  <Col>{moment.utc( data.expeditionDate ).format( 'DD/MM/YYYY' )}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-bold">
                    <Intl id="signature" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!!data.signature && <img src={data.signature} width="100%" alt="signature" style={{ maxWidth: 400 }} />}
                  </Col>
                </Row>

              </Col>

              <Col md={8}>
                <div className="d-flex flex-wrap">
                  {!!data.frontalId
                  && (
                    <img src={data.frontalId} width="100%" alt="front" className="flex-1 m-2" style={{ maxWidth: 400 }} />
                  )}
                  {!!data.reverseId
                  && (
                    <img src={data.reverseId} width="100%" alt="reverse" className="flex-1 m-2" style={{ maxWidth: 400 }} />
                  )}
                  {!!data.selfie
                  && (
                    <img src={data.selfie} width="100%" alt="selfie" className="flex-1 m-2" style={{ maxWidth: 400 }} />
                  )}
                </div>
              </Col>

            </Row>

          </CardBody>
        </Card>
      </Col>
    </Row>
  ),
);
