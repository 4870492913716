import React, { useMemo } from 'react';
import { Intl } from 'components';
import { Button } from 'reactstrap';

const prefixToViewPDF = 'https://docs.google.com/viewer?url=';

const ViewPayroll = ( { payrollToView, toggleModalView } ) => {
  const data = useMemo( () => {
    if ( !payrollToView ) {
      return null;
    }

    const {
      id, document: src, month, year,
    } = payrollToView;
    const type = src.toLowerCase().indexOf( '.pdf' ) > 0 ? 'pdf' : 'img';

    return {
      id,
      src,
      type,
      date: `${( month < 10 ? '0' : '' ) + month}/${year}`,
    };
  }, [payrollToView] );

  return data ? (
    <div>
      <h3 className="">
        <Intl id="payroll" />
        {' '}
        {data.id}
      </h3>
      <hr className="mt-0" />
      <p>
        <Intl id="date" />
        :
        <strong>{data.date}</strong>
        .
      </p>
      <div className="text-center">
        <div className="mb-3">
          {data.type === 'pdf' ? (
            <div className="dz-preview-pdf">
              <div className="dz-preview-pdf_inner">PDF</div>
            </div>
          ) : (
            <img
              className="dz-preview-img"
              src={data.src}
              alt=""
            />
          )}
        </div>
        <Button href={( data.type === 'pdf' ? prefixToViewPDF : '' ) + data.src} target="_blank" color="primary" size="sm" className="px-5">
          <Intl id="openInNewTab" />
        </Button>
      </div>
      <div className="text-center">
        <hr className="mb-3" />
        <Button color="primary" outline onClick={toggleModalView}>
          <Intl id="close" />
        </Button>
      </div>
    </div>
  ) : null;
};

export default ViewPayroll;
