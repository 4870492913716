import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import PaymentsService from 'api/Payments';
import useDelete from 'hooks/useDelete';
import FiltersActions from 'store/reducers/Filters';
import FinancePaymentView from 'views/AccomodationOwner/Edit/Finance/Charge';

const FinancePaymentContainer = ( {
  entityId,
  userCurrency,
  // updateFilters,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const defaultFilters = useMemo( () => {
    const today = moment().startOf( 'day' );

    return {
      from: today.clone().format( 'YYYY-MM-DD' ),
      till: today.clone().add( 1, 'month' ).format( 'YYYY-MM-DD' ),
    };
  },

  [] );

  /* FETCH TABLE ********************************** */
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => PaymentsService.getPaymentsAccommodationOwner( entityId,
      { ...params, charge: true } ),
    toggleErrorAlert,
    deps: [entityId, dataSubmitted],
  } );

  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );
  /* end FETCH TABLE ********************************** */

  const deletePayment = useDelete( {
    promise: PaymentsService.deletePaymentDetail,
    callback: reload,
    toggleLoading,
    toggleErrorAlert,
  } );

  /** **************************************** */
  const [modalAddOpen, setModalAddOpen] = useState( false );
  const [paymentToEdit, setPaymentToEdit] = useState( null );

  const toggleModalEditor = useCallback( ( status, paymentData ) => {
    setPaymentToEdit( paymentData );
    setModalAddOpen( status );
  }, [] );

  return (
    <FinancePaymentView
      entityId={entityId}
      fetchData={fetchData}
      dataTable={dataTable}
      dataLoading={dataLoading}
      defaultFilters={defaultFilters}
      userCurrency={userCurrency}
      reload={reload}
      onDelete={deletePayment}
      toggleModalEditor={toggleModalEditor}
      modalAddOpen={modalAddOpen}
      paymentToEdit={paymentToEdit}
    />
  );
};
//

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = {
  updateFilters: FiltersActions.updateFilters,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( FinancePaymentContainer );
