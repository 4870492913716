import React from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import classnames from 'classnames';

import {
  AsyncTable,
  AddBtn,
  SimpleHeader,
  TableGenericFilter,
  TableActionsBtns,
  TableEditBtn,
  CanAccess,
  Form,
  TranslationLayout,
  TextField,
  EditCard,
  FormActionsBtns,
  Intl,
} from 'components';
import { canView, canModify } from 'config/Ability';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    dataParent,
    isLoadingdataParent,
    data,
    isLoading,
    defaultFilters,
    onFetchData,
    onOpenEditModal,
    onDeleteChildren,
    parentPath,
    onSubmit,
  } ) => {
    const canViewProp = canView( 'task_type' );
    const canEditDelete = canModify( 'task_type' );

    return (
      <>
        <SimpleHeader
          parentLinks={[{ name: 'taskCategories', href: parentPath }]}
        />
        <Container
          fluid
          className={classnames( {
            'app-component-loading': isLoadingdataParent,
          } )}
        >
          <EditCard title="parentTask">
            <Form
              initialValues={dataParent}
              onSubmit={onSubmit}
              showErrorsInFooter
              body={() => (
                <TranslationLayout
                  translationFields={( locale ) => (
                    <>
                      <Row>
                        <Col md={6}>
                          <TextField
                            field={`translations.${locale}.name`}
                            label="name"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            field={`translations.${locale}.description`}
                            label="description"
                            type="textarea"
                            rows={4}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                >
                  <Row>
                    <Col md={6}>
                      <TextField
                        field="name"
                        label="name"
                        validate={required}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextField
                        field="description"
                        label="description"
                        type="textarea"
                        rows={4}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <TextField
                        field="externalId"
                        label="externalId"
                      />
                    </Col>
                  </Row>
                </TranslationLayout>
              )}
              footer={(
                <CanAccess I="edit" a="service">
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <FormActionsBtns />
                    </Col>
                  </Row>
                </CanAccess>
              )}
            />
          </EditCard>
          <AsyncTable
            id="taskTypes"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            disableSorting
            defaultFilters={defaultFilters}
            rightContent={(
              <CanAccess I="add" a="task_type">
                <AddBtn title="newCategory" onClick={onOpenEditModal} />
              </CanAccess>
            )}
            onFetchData={onFetchData}
            customFilters={( { filters, setFilter } ) => (
              <Row>
                <Col md={8} lg={4}>
                  <TableGenericFilter filters={filters} setFilter={setFilter} />
                </Col>
              </Row>
            )}
            columns={[
              {
                Header: 'name',
                accessor: 'name',
                Cell: ( { cell } ) => ( canEditDelete || canViewProp ? (
                  <TableEditBtn
                    title={cell.value}
                    onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
                  />
                ) : (
                  cell.value
                ) ),
              },
              {
                Header: 'description',
                accessor: 'description',
              },
              {
                Header: 'costFixedService',
                accessor: 'cost',
              },
              {
                Header: 'showCustomers',
                accessor: 'forCustomers',
                Cell: ( { cell } ) => (
                  cell.value ? <Intl id="yes" /> : <Intl id="no" />
                ),
              },
              canEditDelete
                ? {
                  accessor: 'id',
                  disableSorting: true,
                  Header: null,
                  maxWidth: 65,
                  Cell: ( { cell } ) => (
                    <TableActionsBtns
                      actions={[
                        {
                          isDelete: true,
                          onClick: ( cb ) => onDeleteChildren( cell.value, cb ),
                        },
                      ]}
                    />
                  ),
                }
                : {
                  id: 'id',
                  Header: null,
                  maxWidth: 1,
                  disableSorting: true,
                },
            ]}
          />
        </Container>
      </>
    );
  },
);
