import React from 'react';
import { ListGroupItem } from 'reactstrap';
import { Intl } from 'components';

export default React.memo( ( {
  id, description,
} ) => (
  <ListGroupItem>
    <div className="help-modal-item">
      <div className="w-25">
        <div className="form-control-label ml-3">
          <Intl id={id} />
        </div>
      </div>
      <div className="w-75">
        <div className="ml-3">
          <Intl id={description} />
        </div>
      </div>
    </div>
  </ListGroupItem>
) );
