import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import DashboardView from 'views/PropertyManager/Edit/Deposits/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import DepositsService from 'api/Deposits';
import { useDelete, useFetchTableData } from 'hooks';
import useFetch from 'hooks/useFetch';
import EditModal from '../Edit';

const Dashboard = ( {
  toggleErrorAlert, toggleLoading,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [modalOpen, setModalOpen] = useState( false );
  const [selectedId, setSelectedId] = useState();

  const onOpenModal = ( id ) => {
    setSelectedId( id );
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: () => DepositsService.getDeposits( ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const [currenDeposit, currenDepositLoading] = useFetch( {
    initialState: {},
    promise: () => DepositsService.getDeposit( selectedId ),
    reInit: true,
    toggleErrorAlert,
    conditional: modalOpen && !!selectedId,
    deps: [modalOpen, selectedId],
  } );

  const reloadData = useCallback( () => {
    onCloseModal();
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const onDeleteItem = useDelete( {
    promise: ( itemId ) => DepositsService.deleteDeposit( itemId ),
    callback: () => {
      setDataSubmitted( new Date().valueOf() );
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <DashboardView
        isDataLoading={dataLoading}
        data={data}
        onFetchData={fetchData}
        onOpenEditItemModal={onOpenModal}
        onDeleteItem={onDeleteItem}
      />
      <EditModal
        depositId={selectedId}
        isLoading={currenDepositLoading}
        data={currenDeposit}
        open={modalOpen}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
