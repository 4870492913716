import api from 'config/Api/Api';

const baseEndpoint = '/autogenerate-tasks';

export default {
  getTasks( params ) {
    return api.get( baseEndpoint, params );
  },

  getTask( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveTask( data ) {
    return api.post( baseEndpoint, data );
  },

  syncTasks( id ) {
    return api.post( `${baseEndpoint}/sync/${id}`, {} );
  },

  updateTask( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteTaskRule( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },

  deleteAutogeneratedTasks( id ) {
    return api.delete( `${baseEndpoint}/delete/${id}` );
  },

  getExtras() {
    return api.get( `${baseEndpoint}/extras` );
  },
};
