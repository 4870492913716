import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropertyManagerFinanceView from 'views/PropertyManager/Edit/Finance';
import SettingsActions from 'store/reducers/Settings';
import UserActions from 'store/reducers/User';
import { useFetch } from 'hooks';
import { roles } from 'config/Constants';
import PropertyManagerService from 'api/PropertyManager';

const PropertyManagerFinance = ( {
  userRole, propertyManager, parentPath, parentId, pathLayout, location,
  toggleErrorAlert,
} ) => {
  const pmId = userRole === roles.nomadsAdmin.id || userRole === roles.nomadsAdminReadOnly.id
    ? parentId : propertyManager.id;

  const [pmData, isLoading] = useFetch( {
    initialState: {},
    promise: () => PropertyManagerService.getPropertyManager( pmId ),
    toggleErrorAlert,
    conditional: !!pmId,
    deps: [pmId],
  } );

  return (
    <PropertyManagerFinanceView
      pmData={pmData}
      isLoading={isLoading}
      userRole={userRole}
      parentPath={parentPath}
      pathLayout={pathLayout}
      pathname={location.pathname}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  user,
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateSettings: UserActions.updateSettings,
} );

export default connect( mapStateToProps, mapDispatchToProps )( PropertyManagerFinance );
