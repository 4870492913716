import React, { useState } from 'react';

import TimeLine from './components/TimeLine';
import Shift from './components/Shift';
import Report from './components/Report';

const Calendar = ( {
  dateRange,
  data,
  dataReport,
  onContextMenu,
  onClickCell,
  showReport,
  isLoadingDataReports,
  onPressDelete,
  onPressMultiShift,
} ) => (
  <div className="dc_container">
    <table>
      <TimeLine dateRange={dateRange} />
      <Report
        data={dataReport}
        isLoading={isLoadingDataReports}
        show={showReport}
      />
      { data.map( ( { team, shifts }, index ) => (
        <TeamShifts
          isOpenDefault={index < 4}
          key={team.teamId}
          team={team}
          shifts={shifts}
          onContextMenu={onContextMenu}
          onClickCell={onClickCell}
          onPressDelete={onPressDelete}
          onPressMultiShift={onPressMultiShift}
        />
      ) )}
    </table>
  </div>
);

const TeamShifts = ( {
  team, shifts, isOpenDefault, onContextMenu, onClickCell, onPressDelete, onPressMultiShift,
} ) => {
  const [isOpen, setIsOpen] = useState( isOpenDefault );
  const toggle = () => setIsOpen( ( current ) => !current );

  return (
    <>
      <tbody>
        <tr>
          <th
            onClick={toggle}
            className="dc_sidebar_cell dc_title large"
          >
            {`${team.teamName}`}
            {isOpen && <i className="fas fa-chevron-up chevron" />}
            {!isOpen && <i className="fas fa-chevron-down chevron" />}
          </th>
        </tr>

        { isOpen && shifts.map( ( shift ) => (
          <Shift
            key={shift.customer.employeeId}
            data={shift}
            onContextMenu={onContextMenu}
            onClickCell={onClickCell}
            onPressDelete={onPressDelete}
            onPressMultiShift={onPressMultiShift}
            color={team.teamColor}
          />
        ) )}
      </tbody>

    </>

  );
};
export default Calendar;
