import React, { useState } from 'react';
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
} from 'reactstrap';
import {
  Intl,
} from 'components';

export default React.memo( ( {
  onSearch, loading,
} ) => {
  const [keywords, setKeywords] = useState( '' );

  return (
    <div>
      <h4><Intl id="searchPointOfInterest" /></h4>
      <InputGroup>
        <Input
          type="search"
          name="searchPlace"
          placeholder="..."
          value={keywords}
          onChange={( e ) => {
            setKeywords( e.target.value );
          }}
        />
        <InputGroupAddon addonType="append">
          <Button
            color="primary"
            onClick={( e ) => {
              e.preventDefault();
              onSearch( keywords );
            }}
          >
            <i className={loading ? 'fa fa-spinner fa-pulse fa-fw' : 'fa fa-search'} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
} );
