import { useDelete, useFetchTableData } from 'hooks';
import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import DashboardView from 'views/Property/RoomType/Accommodation/Edit/Occupancies/Table';
import EditDrawer from 'containers/Property/RoomType/Accommodation/Edit/Occupancies/EditDrawer';
import SettingsActions from 'store/reducers/Settings';
import { connect } from 'react-redux';
import { deleteOccupancy, getAllOccupancys } from 'api/Occupancy';
import EditOccupancyModal from '../Edit';

const Dashboard = ( {
  entityId, propertyManagerId, toggleErrorAlert, toggleLoading,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [modalOpen, setModalOpen] = useState( false );
  const [selectedId, setSelectedId] = useState();

  const [drawerOpen, setDrawerOpen] = useState( false );

  const onOpenModal = () => {
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };

  const onOpenDrawer = ( id ) => {
    setSelectedId( id );
    setDrawerOpen( true );
  };

  const onCloseDrawer = () => {
    setSelectedId();
    setDrawerOpen( false );
  };

  const [fetchData, _data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getAllOccupancys( {
      ...params,
      propertyManagerId,
      accommodation: {
        value: entityId,
      },
    } ),
    toggleErrorAlert,
    conditional: !!entityId,
    deps: [dataSubmitted],
  } );

  const reloadData = useCallback( () => {
    onCloseModal();
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const onDeleteItem = useDelete( {
    promise: ( itemId ) => deleteOccupancy( itemId ),
    callback: () => {
      setDataSubmitted( new Date().valueOf() );
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <DashboardView
        list={_data}
        dataLoading={dataLoading}
        onFetchData={fetchData}
        onOpenNewItemModal={onOpenModal}
        onOpenEditItemModal={onOpenDrawer}
        onDeleteItem={onDeleteItem}
      />
      <EditOccupancyModal
        occupancyId={selectedId}
        accId={entityId}
        open={modalOpen}
        openDrawer={onOpenDrawer}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
      <EditDrawer
        onOpenModal={onOpenModal}
        occupancyId={selectedId}
        open={drawerOpen}
        onClose={onCloseDrawer}
        onReloadOccupancies={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManagerId: _.get( user, 'user.propertyManager.id', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
