import React from 'react';
import { Route, Switch } from 'react-router';

import ability from 'config/Ability';
import Dashboard from './Dashboard';

const AutoassignTask = ( { match } ) => (
  <Switch>
    {ability.can( 'lists', 'task_type' )
      && (
      <Route
        path={match.url}
        render={( props ) => <Dashboard {...props} parentPath={match.url} />}
      />
      )}
  </Switch>
);

export default AutoassignTask;
