export const target = [
  { id: 'task', name: 'task' },
  { id: 'bookedAccommodation', name: 'booking' },
  { id: 'bookedService', name: 'service' },
];

export const executionPoint = [
  { id: 'before', name: 'before' },
  { id: 'after', name: 'after' },
  { id: 'instant', name: 'instant' },
];

export const bookingType = [
  { id: 'checkin', name: 'checkin' },
  { id: 'checkout', name: 'checkout' },
  { id: 'reservation', name: 'creationDate' },
];

export const bookingTypeAvantio = [
  { id: 'checkin', name: 'checkin' },
  { id: 'checkout', name: 'checkout' },
  { id: 'reservation', name: 'creationDate' },
  { id: 'period', name: 'period' },
];
