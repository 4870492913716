import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default React.memo( () => (
  <>
    <Container fluid>
      <footer className="footer pt-0 mt-4">
        <Row className="align-items-center justify-content-xl-between">
          <Col xs="12">
            <div className="copyright text-center text-muted">
              ©
              {' '}
              {new Date().getFullYear()}
              {' '}
              <a
                className="ml-1"
                href="https://www.nomadssoftware.es/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ThreeAngular Software
              </a>
            </div>
          </Col>
        </Row>
      </footer>
    </Container>
  </>
) );
