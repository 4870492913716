/* eslint-disable import/prefer-default-export */
const typeOptionNames = {
  ALL_ALLOWED: 'allAllowed',
  DAYS_ALLOWED_MONTH: 'daysAllowedMonth',
  DAYS_ALLOWED_WEEK: 'daysAllowedWeek',
};

const typeOptionDates = {
  DAYS_ALLOWED_MONTH: 'daysAllowedMonth',
};

const enableCheckInOutOptions = ['DAYS_ALLOWED_MONTH', 'DAYS_ALLOWED_WEEK'];
const typeOptionweekDays = {
  DAYS_ALLOWED_WEEK: 'daysAllowedWeek',
};
const typeOptions = [
  'ALL_ALLOWED',
  'DAYS_ALLOWED_MONTH',
  'DAYS_ALLOWED_WEEK',
].map( ( item ) => ( {
  id: item,
  name: typeOptionNames[item],
} ) );

const daysAllowedWeekOptions = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map( ( item ) => ( {
  id: item,
  name: item.toLocaleLowerCase(),
} ) );

export {
  typeOptions,
  daysAllowedWeekOptions,
  enableCheckInOutOptions,
  typeOptionDates,
  typeOptionweekDays,
  typeOptionNames as typeOptionNamesOccupancy,
};
