import React, { useState, useCallback } from 'react';
import useFetch from 'hooks/useFetch';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import NotificationsView from 'views/Booking/Edit/Notifications';
import ScheduledNotificationsService from 'api/ScheduledNotifications';
import SettingsActions from 'store/reducers/Settings';

const Notifications = ( { toggleErrorAlert, bookingData } ) => {
  const [dataReloaded, setDataReloaded] = useState( new Date().valueOf() );

  const bookedAccommodation = _get( bookingData, 'id' );
  const [data, dataLoading] = useFetch( {
    initialState: { data: [] },
    promise: () => ScheduledNotificationsService.getScheduledNotification(
      { bookedAccommodation },
    ),
    toggleErrorAlert,
    conditional: !!bookedAccommodation,
    deps: [bookedAccommodation, dataReloaded],
  } );

  const onReload = useCallback( () => {
    setDataReloaded( new Date().valueOf() );
  }, [] );

  return (
    <NotificationsView
      data={data}
      bookingData={bookingData}
      isLoading={dataLoading}
      onReload={onReload}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( Notifications );
