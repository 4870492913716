import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import TaskNewView from 'views/Task/New';
import AccommodationService from 'api/Accommodation';
import TaskTypeService from 'api/TaskType';
import TaskService from 'api/Task';
import TaskImageService from 'api/TaskImage';
import IncidenceService from 'api/incidences';
import { useFetch } from 'hooks';
import _ from 'lodash';
import { formatDataToSend, handlePromise } from 'utils';
import { each } from 'utils/async';

const NewIncidentTaskForm = ( {
  incidenceId,
  modalOpened,
  onCloseModal,
  preloadedData,
  onReload,
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
  onOpenEditModal,
} ) => {
  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const dataToSend = formatDataToSend( formData, form );
      let formattedData = {};

      if ( formData.bookedAccommodation ) {
        formattedData.bookedAccommodation = formData.bookedAccommodation;
      }

      formattedData = {
        ...formattedData,
        accommodation: dataToSend.accommodation,
        name: dataToSend.description,
        description: dataToSend.description,
        priority: dataToSend.priority,
        taskType: dataToSend.taskType,
        type: dataToSend.type,
        date: dataToSend.date
          ? dataToSend.date.format( 'YYYY-MM-DD HH:mm:ss' )
          : dataToSend.date,
      };

      const [errors, response, taskData] = await handlePromise(
        TaskService.saveTask( formattedData ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      const [errorsInc, responseInc] = await handlePromise(
        IncidenceService.addIncidenceTask( incidenceId, taskData.id ),
      );

      if ( !responseInc.ok ) {
        toggleLoading( false );
        return errorsInc;
      }

      if ( !dataToSend.images ) {
        toggleLoading( false );
        toggleInfoAlert( 'dataSaved' );
        onOpenEditModal( { id: taskData.id } );
        return onReload();
      }

      return new Promise( ( resolve, reject ) => {
        each(
          dataToSend.images,
          async ( image, eCb ) => {
            const [imageErrors, imageResponse] = await handlePromise(
              TaskImageService.saveImage( taskData.id, { image: image.file } ),
            );
            if ( !imageResponse.ok ) return eCb( imageErrors );
            eCb();
          },
          ( error ) => {
            if ( error ) {
              toggleLoading( false );
              return reject( error );
            }
            toggleLoading( false );
            toggleInfoAlert( 'dataSaved' );
            onOpenEditModal( { id: taskData.id } );
            onReload();
            resolve();
          },
        );
      } ).catch( ( error ) => Promise.resolve( error ) );
    },
    [onReload, toggleInfoAlert, toggleLoading, incidenceId, onOpenEditModal],
  );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  return (
    <TaskNewView
      open={modalOpened}
      // customFields={customFields}
      onSubmit={submitForm}
      onClose={onCloseModal}
      onFetchAccommodations={fetchAccommodations}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
      preloadedData={preloadedData}
      zIndex={10000}
    />
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
} );

export default connect( null, mapDispatchToProps )( NewIncidentTaskForm );
