import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { filterBuilder } from './filterBuilder';

const GET_ALL_SEASONS_DISCOUNT = gql`
  query SeasonsDiscount($limit: Int!, $offset: Int!, $filters: [FilterInput!]!) {
    seasonsDiscount(limit: $limit, offset: $offset, filters: $filters) {
      ... on BaseError {
        message
      }
      ... on QuerySeasonsDiscountSuccess {
        data {
          total
          results {
            discountId
            discountType
            discountValue
            id
            isActive
            lastNight
            requirements {
              earlyBookingDiscountBefore {
                bookingsMadeBefore
                numberOfNights
                onlyBookings
              }
              earlyBookinhDiscountBeforeNumNight {
                bookingsMadeUntilDaysBefore
                numberOfNights
                onlyBookings
              }
              lastMinuteDiscount {
                bookingsMadeWithLessThan
                numberOfNights
                onlyBookings
              }
              longStayDiscount {
                numberOfNights
                onlyBookings
              }
              shortStaySuplement {
                numberOfNights
                onlyBookings
              }
            }
            startDate
            type
          }
        }
      }
    }
  }
`;

const getAllSeasonsDiscount = async ( { page, elementsPerPage, ...rest } ) => {
  const offset = ( page - 1 ) * elementsPerPage;
  const limit = elementsPerPage;
  const { data } = await client.query( {
    query: GET_ALL_SEASONS_DISCOUNT,
    variables: {
      offset,
      limit,
      filters: filterBuilder( rest ),
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.seasonsDiscount.message ) {
    return {
      ok: false,
      message: data.seasonsDiscount.message,
    };
  }
  const seasonsDiscount = data.seasonsDiscount.data.results || [];
  const elementsTotal = data.seasonsDiscount.data.total || 0;
  return {
    ok: true,
    status: 200,
    data: { data: seasonsDiscount, elementsTotal, elementsPerPage },
  };
};

export default getAllSeasonsDiscount;
