import React, {
  createContext, useCallback, useMemo, useEffect, useState, useContext,
} from 'react';
import {
  handlePromise,
} from 'utils';
import {
  updateBusinessOpportunityStage,
} from 'api/CRM/Contact';
import { BusinnesCRMContext } from 'containers/CRM/Business/Dashboard/context';
import { pipelineToColumns, dataToItems } from './utils';

export const KanvasContext = createContext( {
  columns: [], items: [], changeItemOfColumn: () => {}, description: null, pipelineId: null,
} );

const KanvasContextProvider = ( {
  children, pipelineSelected, data,
} ) => {
  const columns = useMemo( () => pipelineToColumns( pipelineSelected ), [pipelineSelected] );

  //
  const [items, setItems] = useState( [] );
  useEffect( () => {
    setItems( dataToItems( data ) );
  }, [data] );

  // CHANGE ITEM OF COLUMN
  const {
    onReload,
  } = useContext( BusinnesCRMContext );

  const onSubmit = useCallback( async ( dataToSend ) => {
    await handlePromise( updateBusinessOpportunityStage( dataToSend ) );
    if ( onReload ) onReload();
  }, [onReload] );

  const changeItemOfColumn = useCallback( ( newColumnId, itemId ) => {
    const itemToChange = items.find( ( item ) => `${item.id}` === `${itemId}` );
    const itemToChangeCopy = { ...itemToChange };
    itemToChangeCopy.columnId = newColumnId;
    setItems( ( oldItems ) => [itemToChangeCopy, ...oldItems.filter( ( item ) => `${item.id}` !== `${itemToChangeCopy.id}` )], [] );
    //
    onSubmit( {
      id: itemId,
      businessStage: newColumnId,
    } );
  }, [items, onSubmit] );

  return (
    <KanvasContext.Provider
      value={{
        columns,
        items,
        changeItemOfColumn,
        description: pipelineSelected?.description,
        pipelineId: pipelineSelected?.id,
      }}
    >
      {children}
    </KanvasContext.Provider>
  );
};
export default KanvasContextProvider;
