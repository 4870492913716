import React, { useState } from 'react';
import { connect } from 'react-redux';

import CountryEditView from 'views/CityAppImages/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import _get from 'lodash/get';

import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import CityAppImagesService from 'api/cityAppImages';

const CityAppImageEdit = ( {
  modalOpened,
  onCloseModal,
  onReload,
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
} ) => {
  const [selectedCounty, setSelectedCounty] = useState();
  const [selectedState, setSelectedState] = useState();

  const [countries, loadingCountries] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    conditional: !!modalOpened,
    deps: [modalOpened],
    reInit: true,
  } );

  const [states, loadingStates] = useFetch( {
    initialState: [],
    promise: () => StateService.getStates( selectedCounty ),
    toggleErrorAlert,
    conditional: modalOpened && !!selectedCounty,
    deps: [modalOpened, selectedCounty],
    reInit: true,
  } );

  const [cities, loadingCities] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( selectedCounty, selectedState ),
    toggleErrorAlert,
    conditional: modalOpened && !!selectedCounty && !!selectedState,
    deps: [selectedCounty, selectedState],
  } );

  const handleOnChangeCountry = ( country ) => setSelectedCounty( country );

  const handleOnChangeState = ( state ) => setSelectedState( state );

  const submitForm = useSubmit( {
    promise: CityAppImagesService.saveImage,
    format: ( dataToFormat ) => ( {
      ...dataToFormat,
      file: _get( dataToFormat, 'file.file' ),
    } ),
    toggleLoading,
    toggleInfoAlert,
    deps: [],
    callback: onReload,
  } );

  return (
    <CountryEditView
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
      countries={countries}
      isLoadingCountries={loadingCountries}
      onChangeCountry={handleOnChangeCountry}
      states={states}
      isLoadingStates={loadingStates}
      onChangeState={handleOnChangeState}
      cities={cities}
      isLoadingCities={loadingCities}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( CityAppImageEdit );
