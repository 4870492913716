import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/guests';

export default {

  getGuests( params ) {
    return api.get( baseEndpoint, params );
  },

  getGuest( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  updateGuest( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  saveGuest( data ) {
    return api.post( `${baseEndpoint}`, data );
  },
  reInviteGuest( id, data ) {
    return api.post( `${baseEndpoint}/${id}/customer/re-invite`, data );
  },
  convertToCustomer( id, data ) {
    return api.post( `${baseEndpoint}/${id}/customer`, data );
  },

  updateGuestImage( id, data ) {
    return api.post( `${baseEndpoint}/${id}/image`, formatFormDataBody( data ) );
  },

  updateEmail( id, data ) {
    return api.put( `${baseEndpoint}/change-email/${id}`, data );
  },
};
