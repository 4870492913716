import React from 'react';

import { TextField, ModalForm } from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  data, stateId, open, isLoading, onClose, onSubmit,
} ) => (
  <ModalForm
    title={stateId ? 'editState' : 'newState'}
    initialValues={data}
    isLoading={isLoading}
    open={open}
    size="md"
    showErrorsInFooter
    onSubmit={onSubmit}
    onClose={onClose}
  >
    <TextField
      field="name"
      label="name"
      validate={required}
    />
    <TextField
      field="iso"
      label="iso"
      validate={required}
    />
  </ModalForm>
) );
