const processActivities = ( activities, contentLocale ) => {
  const { data } = activities;
  const months = {};

  data.forEach( ( activity ) => {
    const { updatedAt } = activity;

    const date = new Date( updatedAt );
    const year = date.getFullYear();
    const month = date.getMonth();

    const label = new Intl.DateTimeFormat( contentLocale || 'en', { month: 'long', year: 'numeric' } ).format( date );

    if ( !months[`${year}/${month}`] ) months[`${year}/${month}`] = { label, list: [] };

    months[`${year}/${month}`].list.push( activity );
  } );

  return {
    ...activities,
    data: Object.values( months ),
  };
};
export default processActivities;
