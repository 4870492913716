import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  Datepicker, ModalForm, Select, TextField, Switch, Intl,
} from 'components';
import { Row, Col } from 'reactstrap';
import { statusPaid } from 'config/Constants';
import { required } from 'config/InputErrors';
import numeral from 'numeral';
import { formatFloat } from 'utils';
import getPlatformOptionsDeposits from 'utils/plugins';

const NewPaymentModal = ( {
  modalOpened, handleClose, onSubmit, selectedPayment, plugins,
} ) => (
  <ModalForm
    initialValues={selectedPayment}
    title={_.isEmpty( selectedPayment ) ? 'newPayment' : 'editPayment'}
    showErrorsInFooter
    isLoading={false}
    open={modalOpened}
    onClose={handleClose}
    bodyClassName="overflow-visible"
    onSubmit={onSubmit}
  >
    <Row>
      {_.isEmpty( selectedPayment ) && (
      <Col md={3}>
        <Datepicker
          field="date"
          label="date"
          dateFormat="DD/MM/YYYY"
          formGroupClass="m-0"
          validate={required}
        />
      </Col>
      )}
      <Col md={3}>
        <TextField
          field="amount"
          label="amount"
          type="number"
          validate={required}
          translate
          readOnly={selectedPayment.status === 'paid'}
          value={numeral( formatFloat( selectedPayment.amount ) )}
        />
      </Col>
      <Col md={3}>
        <Select
          field="status"
          label="status"
          options={statusPaid}
          validate={required}
          translateOptions
        />
      </Col>
      {_.isEmpty( selectedPayment ) && (
        <Col>
          <Switch
            field="deposit"
            label="deposit"
          />
        </Col>
      )}
    </Row>
    <Row>
      <Col md={8}>
        <TextField
          field="description"
          label="description"
          type="text"
          validate={required}
        />
      </Col>
      <Col md={4}>
        <Select
          field="gateway"
          label="gateway"
          options={getPlatformOptionsDeposits( plugins )}
          validate={required}
          translateOptions
        />
      </Col>
    </Row>
    {/* <Row>
      <Col>
        <Col>
          <Switch
            field="mandatory"
            label="mandatory"
          />
        </Col>
      </Col>
    </Row> */}
    {selectedPayment.gateway === 'external' && (
      <Row>
        <Col>
          <TextField
            field="link"
            label="paymentLink"
            type="text"
          />
        </Col>
      </Row>
    )}
    <Row>
      <Col>
        <h4><Intl id="futurePaymentDate" /></h4>
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <Datepicker
          field="notificationDate"
          label="notificationDate"
          dateFormat="DD/MM/YYYY"
          formGroupClass="m-0"
          readOnly={selectedPayment.status === 'paid'}
        />
      </Col>
    </Row>
  </ModalForm>
);

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

export default connect( mapStateToProps, null )( NewPaymentModal );
