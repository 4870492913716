import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CityNewView from 'views/PropertyManager/Edit/City/New';
import SettingsActions from 'store/reducers/Settings';
import { useFetch, useSubmit } from 'hooks';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import SettingsCityService from 'api/Settings/City';
import { handlePromise } from 'utils';

const CityNew = ( {
  pmId, modalOpened, onReload, onCloseModal, toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const [states, setStates] = useState( [] );
  const [statesLoading, setStatesLoading] = useState( false );
  const [cities, setCities] = useState( [] );
  const [citiesLoading, setCitiesLoading] = useState( false );

  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    conditional: modalOpened,
    reInit: true,
    deps: [modalOpened],
  } );

  const closeModal = useCallback( () => {
    onCloseModal();
    setStates( [] );
    setCities( [] );
  }, [onCloseModal] );

  const fetchStates = useCallback( async ( countryId ) => {
    const country = _.find( countries, { id: countryId } );
    if ( !country ) return toggleErrorAlert( 'error' );

    setStatesLoading( true );
    const [errors, response, responseData] = await handlePromise(
      StateService.getStates( country.iso ),
    );
    setStatesLoading( false );

    if ( !response.ok ) return toggleErrorAlert( errors );
    setStates( responseData );
    setCities( [] );
  }, [countries, toggleErrorAlert] );

  const fetchCities = useCallback( async ( stateId, countryId ) => {
    const country = _.find( countries, { id: countryId } );
    const state = _.find( states, { id: stateId } );
    if ( !country || !state ) return toggleErrorAlert( 'error' );

    setCitiesLoading( true );
    const [errors, response, responseData] = await handlePromise(
      CityService.getCities( country.iso, state.iso ),
    );
    setCitiesLoading( false );

    if ( !response.ok ) return toggleErrorAlert( errors );
    setCities( responseData );
  }, [countries, states, toggleErrorAlert] );

  const submitForm = useSubmit( {
    promise: SettingsCityService.saveCity,
    format: ( dataToSend ) => ( { city: dataToSend.city, propertyManager: pmId } ),
    toggleLoading,
    toggleInfoAlert,
    callback: onReload,
    deps: [pmId, onReload],
  } );

  return (
    <CityNewView
      open={modalOpened}
      isLoading={countriesLoading || statesLoading || citiesLoading}
      options={{ countries, states, cities }}
      onSubmit={submitForm}
      onClose={closeModal}
      onChangeCountry={fetchStates}
      onChangeState={fetchCities}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CityNew );
