import classNames from 'classnames';
import { Button, Intl } from 'components';
import Seasons from 'containers/PropertyManager/Edit/Discounts/EditDrawer/Seasons';
import Drawer from 'rc-drawer';
import React from 'react';
import { Col, Row } from 'reactstrap';

export default React.memo( ( {
  data, open, onClose, isLoading, onReloadDiscounts, onOpenModal,
} ) => (
  <Drawer
    open={open}
    placement="right"
    handler={false}
    maskClosable
    level={null}
    onClose={onClose}
    className="zindex-drawer"
  >
    <div className={classNames( 'p-4', { 'app-component-loading': isLoading } )}>
      {/* Header */}
      <Row className="align-items-center">
        <Col>
          <span className="text-uppercase font-weight-bold ml-3">
            {data.id ? <Intl id="discount" /> : null}
            {' '}
            {data.id ? `#${data.id.slice( data.id.length - 6, data.id.length )}` : ''}
            {' '}
          </span>
        </Col>
        <Col xs={1}>
          <Button title="editDiscount" onClick={onOpenModal} />
        </Col>
        <Col xs={1} className="text-right">
          <i className="app-icon-close cursor-pointer py-2 pl-2" onClick={onClose} />
        </Col>
      </Row>
      <Seasons
        discountId={data.id}
        onReloadDiscounts={onReloadDiscounts}
      />
    </div>
  </Drawer>
) );
