import api from 'config/Api/Api';

import { formatFormDataBody } from 'utils';

const baseEndpoint = '/customers';

export default {
  getCustomers( params ) {
    return api.get( baseEndpoint, { ...params, employee: false } );
  },
  getCustomer( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  uploadProfilePicture( id, data ) {
    return api.post( `${baseEndpoint}/${id}/pictures`, formatFormDataBody( data ) );
  },

  saveCustomer( data ) {
    return api.post( baseEndpoint, data );
  },
  updateCustomer( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
  deleteCustomer( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
  disableCustomer( id ) {
    return api.delete( `${baseEndpoint}/${id}?disabled=true` );
  },
};
