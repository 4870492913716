import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import getPropertyManager from '../utils/getPropertyManager';

const CREATE_EMAIL = gql`
mutation CreateEmail($html: String!, $subject: String!, $sender: String!, $contactId: String!, $propertyManager: String!) {
  createEmail(contactId: $contactId, subject: $subject, sender: $sender, html: $html, propertyManager: $propertyManager) {
    ... on BaseError {
      message
    }
    ... on MutationCreateEmailSuccess {
      data {
        success
      }
    }
  }
}
`;

const createEmail = async ( props ) => {
  const { data } = await client.query( {
    query: CREATE_EMAIL,
    variables: { ...props, propertyManager: getPropertyManager() },
    fetchPolicy: 'no-cache',
  } );

  if ( data.createEmail.message ) {
    return {
      ok: false,
      message: data.createEmail.message,
    };
  }

  const createSuccessful = data.createEmail.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
    data: { ...data.createEmail.data },
  };
};

export default createEmail;
