import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PayrollView from 'views/Resource/Edit/Payroll';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
import EmployeeService from 'api/Employee';
import { ConfirmationModal } from 'components';

const Payrolls = ( {
  entityId,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => EmployeeService.getPayrolls( entityId, params ),
    toggleErrorAlert,
    deps: [entityId, dataSubmitted],
  } );

  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  /// //////////////////
  const [payrollIdToDelete, setPayrollIdToDelete] = useState( null );

  const [openModalDelete, setOpenModalDelete] = useState( false );
  const toggleModalDelete = () => setOpenModalDelete( ( state ) => !state );

  const deletePayroll = useDelete( {
    promise: () => EmployeeService.deletePayroll( entityId, payrollIdToDelete ),
    callback: ( ) => {
      setOpenModalDelete( false );
      reload();
    },
    toggleLoading,
    toggleErrorAlert,
    deps: [entityId, payrollIdToDelete],
  } );

  return (
    <>
      <PayrollView
        entityId={entityId}
        fetchData={fetchData}
        dataTable={dataTable}
        dataLoading={dataLoading}
        reload={reload}
        setPayrollIdToDelete={setPayrollIdToDelete}
        toggleModalDelete={toggleModalDelete}
      />
      <ConfirmationModal
        open={openModalDelete}
        // title={openModalDisable ? 'disableModal.confirmation' : ''}
        onConfirm={deletePayroll}
        onClose={toggleModalDelete}
      />
    </>
  );
};

/* const mapStateToProps = ( { user, filters } ) => ( {
 // userRole: user.mainRole,
  defaultFilters: _.get( filters, 'filters.resources' ) || {},
} ); */
const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( Payrolls );
