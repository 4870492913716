import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';

import ChatView from 'views/Support/Chat';
import ChannelService from 'api/Channel';
import BookingItemService from 'api/BookingItem';
import BookedServiceService from 'api/BookedService';
import { getResourceName, handlePromise } from 'utils';
import SettingsActions from 'store/reducers/Settings';
import TaskActions from 'store/reducers/Task';
import IncidenceActions from 'store/reducers/Incidence';
import MessengerActions from 'store/reducers/Messenger';
import { ConfirmationModal } from 'components';
import TaskService from 'api/Task';
import { each, parallel, waterfall } from 'utils/async';
import AddParticipant from '../AddParticipant';
import ChannelDrawer from '../ChannelDrawer';

const Chat = ( {
  newMessage, taskReloadedAt, toggleErrorAlert, toggleLoading, toggleTaskEditModal,
  setSelectedChannelInStore, userCurrency, userBookingReference,
} ) => {
  const { getTasks: _getTasks } = TaskService;
  const channels = useRef( {
    data: [],
    hasMore: true,
  } );
  const operationChannels = useRef( {
    data: [],
    hasMore: true,
  } );
  const messages = useRef( {
    data: [],
    hasMore: true,
  } );
  const currentMessagesPage = useRef( 1 );
  const messagesToSend = useRef( {} );
  const selectedChannelData = useRef( {} );
  const participantToAddType = useRef( null );
  const participantToDelete = useRef( {} );
  const taskToEditData = useRef( null );
  const isInitialMount = useRef( true );
  const [selectedChannelId, setSelectedChannelId] = useState( null );
  const [messageDate, setMessageDate] = useState( null );
  const [channelDate, setChannelDate] = useState( null );
  const [addParticipantModalOpened, setAddParticipantModalOpened] = useState( false );
  const [channelDrawerOpened, setChannelDrawerOpenedOpened] = useState( false );
  const [deleteParticipantModalOpened, setDeleteParticipantModalOpened] = useState( false );

  /**
   * Get booking
   */
  const getBooking = useCallback( async ( bookingId, cb ) => {
    // cb( null, {id: 'hols'} );
    // return;

    const [error, response, responseData] = await handlePromise(
      BookingItemService.getBookingItem( bookingId ),
    );
    if ( !response.ok ) return cb( error );
    cb( null, responseData );
  }, [] );

  /**
   * Get channel
   */
  const getChannel = useCallback( async ( id, cb ) => {
    const [error, response, responseData] = await handlePromise(
      ChannelService.getChannel( id ),
    );
    if ( !response.ok ) return cb( error );
    cb( null, responseData );
  }, [] );

  /**
   * Get tasks
   */
  const getTasks = useCallback( async ( id, cb ) => {
    const [error, response, responseData] = await handlePromise(
      _getTasks( { bookedAccommodation: id } ),
    );
    if ( !response.ok ) return cb( error );
    cb( null, responseData );
  }, [_getTasks] );

  /**
   * Get services
   */
  const getServices = useCallback( async ( id, cb ) => {
    const [error, response, responseData] = await handlePromise(
      BookedServiceService.getBookedServices( { booking: id } ),
    );
    if ( !response.ok ) return cb( error );
    cb( null, responseData );
  }, [] );

  /**
   * Get bookings list
   */
  const getBookings = useCallback( ( channelsData, cb ) => {
    const newChannels = [...channelsData];
    cb( null, newChannels );

    each( newChannels, ( channel, eCb ) => {
      if ( !channel.bookingId ) return eCb();
      // eslint-disable-next-line no-unused-vars
      /* getBooking( channel.bookingId, ( error, booking ) => {
        if ( error ) return eCb( error );
        // eslint-disable-next-line no-param-reassign
        // channel.booking = booking;
        eCb();
      } ); */
    }, ( error ) => {
      if ( error ) return cb( error );
      cb( null, newChannels );
    } );
  }, [] ); // [getBooking] );

  /**
   * Get channels list
   */
  const getBookingChannels = useCallback( async ( params, cb ) => {
    if ( channels.current.hasMore ) {
      let channelsToExclude = [];
      const lastUpdate = _.get( channels.current.data, `[${channels.current.data.length - 1}].lastUpdate` );
      if ( lastUpdate ) {
        channelsToExclude = _.filter( channels.current.data,
          ( item ) => item.lastUpdate === lastUpdate );
      }
      const formattedParams = params.page === 1
        ? {
          ...params, page: params.page, elementsPerPage: 10, support: true,
        }
        : {
          ...params,
          page: undefined,
          from: lastUpdate,
          channels: _.map( channelsToExclude, '_id' ),
          elementsPerPage: 10,
          support: true,
        };
      const [error, response, data] = await handlePromise(
        ChannelService.getChannels( formattedParams ),
      );
      if ( !response.ok ) {
        if ( cb ) return cb( error );

        if ( params && params.page === 1 ) {
          messages.current = { hasMore: false, data: [] };
          setMessageDate( new Date().valueOf() );
        }
        channels.current = { hasMore: false, data: channels.current.data };
        setChannelDate( new Date().valueOf() );
        return toggleErrorAlert( error );
      }
      getBookings( data.data, ( bookingError ) => {
        if ( bookingError ) {
          if ( cb ) return cb( bookingError );
          channels.current = { hasMore: false, data: channels.current.data };
          setChannelDate( new Date().valueOf() );
          return toggleErrorAlert( bookingError );
        }

        const channelsData = [...channels.current.data, ...data.data];
        const newChannels = {
          hasMore: data.data.length >= 10,
          data: channelsData,
        };
        if ( cb ) return cb( null, newChannels );
        channels.current = newChannels;
        setChannelDate( new Date().valueOf() );
      } );
    }
  }, [toggleErrorAlert, getBookings] );

  /**
   * Get operation channels list
   */
  const getOperationChannels = useCallback( async ( params, cb ) => {
    if ( operationChannels.current.hasMore ) {
      let channelsToExclude = [];
      const lastUpdate = _.get( operationChannels.current.data, `[${operationChannels.current.data.length - 1}].lastUpdate` );
      if ( lastUpdate ) {
        channelsToExclude = _.filter( operationChannels.current.data,
          ( item ) => item.lastUpdate === lastUpdate );
      }
      const formattedParams = params.page === 1
        ? {
          ...params, page: params.page, elementsPerPage: 10, support: false,
        }
        : {
          ...params,
          page: undefined,
          from: lastUpdate,
          channels: _.map( channelsToExclude, '_id' ),
          elementsPerPage: 10,
          support: false,
        };
      const [error, response, data] = await handlePromise(
        ChannelService.getChannels( formattedParams ),
      );
      if ( !response.ok ) {
        if ( cb ) return cb( error );

        if ( params && params.page === 1 ) {
          messages.current = { hasMore: false, data: [] };
          setMessageDate( new Date().valueOf() );
        }
        operationChannels.current = { hasMore: false, data: operationChannels.current.data };
        setChannelDate( new Date().valueOf() );
        return cb ? cb( error ) : toggleErrorAlert( error );
      }

      const channelsData = [...operationChannels.current.data, ...data.data];
      const newChannels = {
        hasMore: data.data.length >= 10,
        data: channelsData,
      };
      if ( cb ) return cb( null, newChannels );
      operationChannels.current = newChannels;
      setChannelDate( new Date().valueOf() );
    }
  }, [toggleErrorAlert] );

  /**
   * Get messages list
   */
  const getMessages = useCallback( async ( channelId, cb ) => {
    if ( messages.current.hasMore ) {
      const params = currentMessagesPage.current === 1
        ? { page: currentMessagesPage.current, elementsPerPage: 20 }
        : { till: moment.utc( messages.current.data[0].created ).format( 'YYYY-MM-DD HH:mm:ss' ), elementsPerPage: 20 };
      const [error, response, data] = await handlePromise(
        ChannelService.getChannelHistory( channelId, params ),
      );
      if ( !response.ok ) {
        if ( cb ) return cb( null, { error } );
        messages.current = { data: messages.current.data, hasMore: false };
        setMessageDate( new Date().valueOf() );
        return toggleErrorAlert( error );
      }

      const messagesData = [..._.reverse( data ), ...messages.current.data];
      const newMessages = {
        data: messagesData,
        hasMore: data.length >= 20,
      };
      if ( cb ) return cb( null, newMessages );
      messages.current = newMessages;
      setMessageDate( new Date().valueOf() );
    }
  }, [toggleErrorAlert] );

  /**
   * Initialize data
   */
  const getInitialData = useCallback( ( params ) => {
    waterfall( [
      ( cb ) => {
        parallel( {
          bookingChannels: ( pCb ) => getBookingChannels( params, pCb ),
          operationChannels: ( pCb ) => getOperationChannels( params, pCb ),
        }, cb );
      },
      ( channelsData, cb ) => {
        const firstBookingChannel = _.get( channelsData, 'bookingChannels.data[0]' );
        if ( !_.get( channelsData, 'bookingChannels.data[0]' ) ) return cb( null, channelsData );
        parallel( {
          messages: ( pCb ) => getMessages( firstBookingChannel._id, pCb ),
          tasks: ( pCb ) => getTasks( firstBookingChannel.bookingId, pCb ),
          services: ( pCb ) => getServices( firstBookingChannel.bookingId, pCb ),
        }, ( error, results ) => {
          if ( error ) return cb( error );
          cb( null, { ...channelsData, ...results } );
        } );
      },
    ], ( error, results ) => {
      if ( error ) {
        channels.current = { hasMore: false, data: channels.current.data };
        operationChannels.current = { hasMore: false, data: operationChannels.current.data };
        messages.current = { hasMore: false, data: [] };
        return toggleErrorAlert( error );
      }

      const firstBookingChannel = _.get( results, 'bookingChannels.data[0]' );
      if ( firstBookingChannel ) {
        setSelectedChannelId( firstBookingChannel._id );
        if ( selectedChannelData.current.type !== 'operations' ) {
          selectedChannelData.current = {
            tasks: results.tasks ? results.tasks.data : [],
            services: results.services ? results.services.data : [],
            ...firstBookingChannel.booking,
          };
        }
        if ( results.messages ) {
          if ( results.messages.error ) {
            toggleErrorAlert( results.messages.error );
            messages.current = { hasMore: false, data: [] };
            setMessageDate( new Date().valueOf() );
          } else {
            messages.current = results.messages;
            setMessageDate( new Date().valueOf() );
          }
        }
      } else {
        messages.current = { hasMore: false, data: [] };
        setMessageDate( new Date().valueOf() );
      }
      if ( results.bookingChannels ) {
        channels.current = results.bookingChannels;
      }
      if ( results.operationChannels ) {
        operationChannels.current = results.operationChannels;
      }
      setChannelDate( new Date().valueOf() );
    } );
  }, [getBookingChannels, getOperationChannels, getMessages,
    getTasks, getServices, toggleErrorAlert] );

  useEffect( () => getInitialData( { page: 1 } ), [getInitialData] );

  /**
   * Change selected channel
   */
  const changeChannel = useCallback( ( id ) => {
    if ( id !== selectedChannelId ) {
      const channel = _.find( channels.current.data, { _id: id } );
      if ( channel ) {
        if ( channel.booking ) selectedChannelData.current = channel.booking;
        else selectedChannelData.current = { type: 'bookedAccommodation' };
      } else {
        const operationChannel = _.find( operationChannels.current.data, { _id: id } );
        if ( operationChannel ) selectedChannelData.current = { ...operationChannel, type: 'operations' };
        else selectedChannelData.current = { type: null };
      }

      if ( channel && channel.booking ) {
        getTasks( channel.booking.id, ( error, response ) => {
          if ( !error ) {
            selectedChannelData.current.tasks = response.data;
            setChannelDate( new Date().valueOf() );
          }
        } );
        getServices( channel.booking.id, ( error, response ) => {
          if ( !error ) {
            selectedChannelData.current.services = response.data;
            setChannelDate( new Date().valueOf() );
          }
        } );
      }

      messages.current = { data: [], hasMore: true };
      currentMessagesPage.current = 1;
      setSelectedChannelId( id );
      getMessages( id );
    }
  }, [selectedChannelId, getMessages, getTasks, getServices] );

  /**
   * Send message
   */
  const sendMessage = useCallback( async ( message ) => {
    const toSend = {};
    if ( message.body ) {
      toSend.body = message.body;
    }
    if ( message.file ) {
      toSend.file = message.file;
    }
    const [error, response, responseData] = await handlePromise(
      ChannelService.sendMessage( selectedChannelId, toSend ),
    );
    if ( !response.ok ) {
      messagesToSend.current[message.id] = {
        ...message,
        post: true,
        status: 'ERROR',
        error,
      };
    } else {
      messagesToSend.current[message.id] = {
        ...responseData,
        post: true,
        status: 'SENT',
        error,
      };
    }

    setMessageDate( new Date().valueOf() );
  }, [selectedChannelId] );

  /**
   * Set message locally
   */
  const sendMessageLocal = useCallback( async ( message ) => {
    _.map( _.keys( messagesToSend.current ), ( key ) => {
      const item = _.findIndex( messages.current.data, { id: key } );
      if ( item > -1 ) {
        messages.current.data[item] = messagesToSend.current[key];
        delete messagesToSend.current[key];
      }
    } );

    messages.current = {
      hasMore: messages.current.hasMore,
      data: [...messages.current.data, message],
    };
    setMessageDate( new Date().valueOf() );
    sendMessage( message );
  }, [sendMessage] );

  /**
   * Load more messages
   */
  const loadMoreMessages = useCallback( () => {
    currentMessagesPage.current += 1;
    getMessages( selectedChannelId );
  }, [selectedChannelId, getMessages] );

  useEffect( () => {
    if ( newMessage ) {
      if ( newMessage.channel.id === selectedChannelId ) {
        messages.current.data = [...messages.current.data, newMessage];
        setMessageDate( new Date().valueOf() );
      } else {
        const channelsList = newMessage.channel.bookingId
          ? channels.current.data : operationChannels.current.data;
        const channelInListIndex = _.findIndex( channelsList, {
          _id: newMessage.channel.id,
        } );
        const newChannelsList = [...channelsList];

        if ( channelInListIndex !== -1 ) {
          const channelInList = { ...channelsList[channelInListIndex] };
          channelInList.lastUpdate = Number( `${moment.utc().unix()}000` );
          newChannelsList.splice( channelInListIndex, 1 );
          newChannelsList.splice( 0, 0, channelInList );

          if ( newMessage.channel.bookingId ) channels.current.data = newChannelsList;
          else operationChannels.current.data = newChannelsList;
          setChannelDate( new Date().valueOf() );
        } else {
          let newChannel = {
            _id: newMessage.channel.id,
            name: newMessage.channel.name,
            bookingId: newMessage.channel.bookingId,
            lastUpdate: Number( `${moment.utc().unix()}000` ),
          };
          if ( newMessage.channel.bookingId ) {
            // eslint-disable-next-line no-unused-vars
            getBooking( newMessage.channel.bookingId, ( error, booking ) => {
              // if ( !error ) newChannel.booking = booking;

              newChannelsList.splice( 0, 0, newChannel );
              channels.current.data = newChannelsList;
              setChannelDate( new Date().valueOf() );
            } );
          } else {
            getChannel( newMessage.channel.id, ( error, channel ) => {
              if ( !error ) newChannel = { ...newChannel, ...channel };

              newChannelsList.splice( 0, 0, newChannel );
              operationChannels.current.data = newChannelsList;
              setChannelDate( new Date().valueOf() );
            } );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage] );

  /**
   * Filter channels by keyword
   */
  const filterChannels = useCallback( ( keyword ) => {
    channels.current = {
      data: [],
      hasMore: true,
    };
    operationChannels.current = {
      data: [],
      hasMore: true,
    };
    messages.current = {
      data: [],
      hasMore: true,
    };
    currentMessagesPage.current = 1;
    selectedChannelData.current = {};
    setSelectedChannelId( null );
    getInitialData( { page: 1, keyword } );
  }, [getInitialData] );

  const openAddParticipantModal = useCallback( ( type ) => {
    participantToAddType.current = type;
    setAddParticipantModalOpened( true );
  }, [] );

  const closeAddParticipantModal = useCallback( () => {
    setAddParticipantModalOpened( false );
  }, [] );

  const openChannelDrawerModal = useCallback( () => {
    setChannelDrawerOpenedOpened( true );
  }, [] );

  const closeChannelDrawerModal = useCallback( () => {
    setChannelDrawerOpenedOpened( false );
  }, [] );

  const reloadOperationChannel = useCallback( () => {
    getChannel( selectedChannelId, ( error, channel ) => {
      if ( error ) return toggleErrorAlert( error );

      const newChannelsList = [...operationChannels.current.data];
      const channelIndex = _.findIndex( newChannelsList, { _id: selectedChannelId } );
      if ( channelIndex !== -1 ) {
        newChannelsList[channelIndex] = {
          ...newChannelsList[channelIndex],
          participants: channel.participants,
          administrators: channel.administrators,
          owner: channel.owner,
        };
        operationChannels.current.data = newChannelsList;
        selectedChannelData.current = {
          ...newChannelsList[channelIndex],
          type: 'operations',
        };
        setChannelDate( new Date().valueOf() );
      }
    } );
  }, [selectedChannelId, getChannel, toggleErrorAlert] );

  const addLocalOperationChannel = useCallback( ( createdChannel, createdMsg ) => {
    closeChannelDrawerModal();
    const channel = {
      ...createdChannel,
      _id: createdChannel.id,
      lastUpdate: createdMsg.created,
    };
    operationChannels.current = {
      data: [channel, ...operationChannels.current.data],
    };
    setSelectedChannelId( createdChannel.id );
    selectedChannelData.current = {
      ...channel,
      type: 'operations',
    };
    setChannelDate( new Date().valueOf() );

    messages.current = {
      data: [createdMsg],
      hasMore: false,
    };
    setMessageDate( new Date().valueOf() );
  }, [closeChannelDrawerModal] );

  const openTaskEditModal = useCallback( ( { id } = {} ) => {
    if ( selectedChannelData.current.id && !id ) {
      const accommodation = selectedChannelData.current.currentAccommodation;
      if ( accommodation ) {
        taskToEditData.current = selectedChannelData.current.id ? {
          accommodation: {
            value: accommodation.id,
            label: accommodation.name,
          },
          bookedAccommodation: selectedChannelData.current.id,
        } : null;
      }
      toggleTaskEditModal( null, taskToEditData.current );
    } else {
      toggleTaskEditModal( id );
    }
  }, [toggleTaskEditModal] );

  const reloadTasks = useCallback( () => {
    getTasks( selectedChannelData.current.id, ( error, response ) => {
      if ( !error ) {
        selectedChannelData.current.tasks = response.data;
        setChannelDate( new Date().valueOf() );
      }
    } );
  }, [getTasks] );

  const reloadServices = useCallback( () => {
    getServices( selectedChannelData.current.id, ( error, response ) => {
      if ( !error ) {
        selectedChannelData.current.service = response.data;
        setChannelDate( new Date().valueOf() );
      }
    } );
  }, [getServices] );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      reloadTasks();
      reloadServices();
    }
  }, [taskReloadedAt, reloadTasks, reloadServices] );

  const openDeleteParticipantModal = useCallback( ( participant, type ) => {
    participantToDelete.current = { ...participant, type };
    setDeleteParticipantModalOpened( true );
  }, [] );

  const closeDeleteParticipantModal = useCallback( () => {
    setDeleteParticipantModalOpened( false );
  }, [] );

  const deleteParticipant = useCallback( async () => {
    toggleLoading( true );
    const [error, response] = await handlePromise(
      participantToDelete.current.type === 'admin'
        ? ChannelService.removeAdministrator( selectedChannelId, participantToDelete.current.id )
        : ChannelService.removeParticipant( selectedChannelId, participantToDelete.current.id ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( error );
    reloadOperationChannel();
    closeDeleteParticipantModal();
  }, [selectedChannelId, reloadOperationChannel, closeDeleteParticipantModal,
    toggleErrorAlert, toggleLoading] );

  useEffect( () => {
    setSelectedChannelInStore( selectedChannelId );
  }, [selectedChannelId, setSelectedChannelInStore] );

  return (
    <>
      <ChatView
        messages={messages.current.data}
        messagesToSend={messagesToSend.current}
        messagesReloadedAt={messageDate}
        channelsReloadedAt={channelDate}
        hasMoreMessages={messages.current.hasMore}
        onFetchMessages={loadMoreMessages}
        onSendMessage={sendMessageLocal}
        channels={channels.current.data}
        operationChannels={operationChannels.current.data}
        hasMoreChannels={channels.current.hasMore}
        hasMoreOperationChannels={operationChannels.current.hasMore}
        selectedChannelId={selectedChannelId}
        selectedChannelData={selectedChannelData.current}
        onFetchBookingChannels={getBookingChannels}
        onFetchOperationChannels={getOperationChannels}
        onFilterChannels={filterChannels}
        onChangeChannel={changeChannel}
        onOpenAddParticipantModal={openAddParticipantModal}
        onOpenCreateChannelModal={openChannelDrawerModal}
        onOpenTaskEditModal={openTaskEditModal}
        onOpenDeleteParticipantModal={openDeleteParticipantModal}
        userCurrency={userCurrency}
        userBookingReference={userBookingReference}
      />

      {addParticipantModalOpened
      && (
        <AddParticipant
          participantType={participantToAddType.current}
          selectedChannelId={selectedChannelId}
          modalOpened={addParticipantModalOpened}
          onCloseModal={closeAddParticipantModal}
          onReload={() => {
            closeAddParticipantModal();
            reloadOperationChannel();
          }}
        />
      )}

      {channelDrawerOpened
      && (
        <ChannelDrawer
          drawerOpened={channelDrawerOpened}
          onCloseDrawer={closeChannelDrawerModal}
          onReload={addLocalOperationChannel}
        />
      )}

      {deleteParticipantModalOpened
      && (
        <ConfirmationModal
          open={deleteParticipantModalOpened}
          onConfirm={deleteParticipant}
          onClose={closeDeleteParticipantModal}
          title="confirmationDeleteChatMember"
          translateValues={{ memberName: getResourceName( participantToDelete.current ) }}
        />
      )}
    </>
  );
};

const mapStateToProps = ( {
  messenger, task, user, incidence,
} ) => ( {
  newMessage: messenger.newMessage,
  taskReloadedAt: task.reloadedAt,
  incidenceReloadedAt: incidence.reloadedAt,
  userCurrency: _.get( user, 'settings.currency' ),
  userBookingReference: _.get( user, 'settings.bookingReference' ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleTaskEditModal: TaskActions.toggleEditModal,
  toggleIncidentModal: IncidenceActions.toggleIncidenceEditModal,
  setSelectedChannelInStore: MessengerActions.setSelectedChannel,
} );

export default connect( mapStateToProps, mapDispatchToProps )( Chat );
