import api from 'config/Api/Api';

const baseEndpoint = '/instant-notifications';

export default {
  getNotifications( params ) {
    return api.get( baseEndpoint, params );
  },
  getNotificationsCount() {
    return api.get( baseEndpoint, { count: 1 } );
  },
  updateProcessedNotifications( instantNotifications, toPending ) {
    return api.put(
      `${baseEndpoint}/status/${toPending ? 'pending' : 'processed'}`,
      { instantNotifications },
    );
  },
};
