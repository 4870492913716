import React from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import {
  Datepicker,
  ModalForm,
  Select,
  TextField,
} from 'components';
import { required } from 'config/InputErrors';
import moment from 'moment';
import {
  discountypeOptions,
  enableRequirementsOptions,
  typeOptionBookingDays,
  typeOptionDates,
  typeOptions,
} from './constants';

export default React.memo(
  ( {
    data,
    fixDates,
    open,
    onClose,
    onSubmit,
    isLoading,
  } ) => {
    const initialData = data ? {
      ...data,
      requirements: data.requirements && data.requirements.earlyBookingDiscountBefore
        ? {
          earlyBookingDiscountBefore: {
            ...data.requirements.earlyBookingDiscountBefore,
            bookingsMadeBefore: moment.utc( new Date(
              data.requirements.earlyBookingDiscountBefore.bookingsMadeBefore,
            ) ),
          },
        } : data.requirements,
      startDate: moment.utc( _.get( data, 'startDate', new Date() ) ),
      lastNight: moment.utc( _.get( data, 'lastNight', new Date() ) ),
    } : undefined;

    return (
      <ModalForm
        initialValues={initialData}
        title={!_.isEmpty( data ? data.id : data ) ? 'editSeason' : 'newSeason'}
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        bodyClassName="overflow-visible"
        zIndex={9999}
        body={( form ) => {
          const showDiscountOptions = form.getFieldState( 'type' ) && form.getFieldState( 'type' ).value;
          const showRequirements = form.getFieldState( 'type' ) && enableRequirementsOptions.find(
            ( item ) => item === form.getFieldState( 'type' ).value,
          );
          const typeFieldValue = form.getFieldState( 'type' ) && typeOptions.find(
            ( item ) => item.id === form.getFieldState( 'type' ).value,
          );
          return (
            <>
              <Row>
                <Col md={6}>
                  <Datepicker
                    field="startDate"
                    label="from"
                    dateFormat="DD/MM/YYYY"
                    disabled={fixDates}
                    utc
                    labelClass="font-weight-bold"
                    validate={required}
                    startDateField={{ value: moment().subtract( '1', 'day' ) }}
                  />
                </Col>
                <Col md={6}>
                  <Datepicker
                    field="lastNight"
                    label="till"
                    dateFormat="DD/MM/YYYY"
                    disabled={fixDates}
                    utc
                    labelClass="font-weight-bold"
                    validate={required}
                    startDateField={{ value: moment().subtract( '1', 'day' ) }}
                  />
                </Col>
                <Col md={6}>
                  <Select
                    overlap
                    field="type"
                    label="type"
                    isClearable
                    labelClass="font-weight-bold mr-2"
                    translateOptions
                    options={typeOptions}
                    validate={required}
                    onChange={() => {
                      form.change( 'requirements', null );
                    }}
                  />
                </Col>
              </Row>
              {showDiscountOptions && (
              <Row>
                <Col md={6}>
                  <Select
                    field="discountType"
                    label="discountType"
                    isClearable
                    labelClass="font-weight-bold mr-2"
                    translateOptions
                    options={discountypeOptions}
                    validate={required}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    field="discountValue"
                    label="discountValue"
                    type="number"
                  />
                </Col>
              </Row>
              )}
              {showRequirements && (
              <Row>
                {typeOptionDates[typeFieldValue.id] && (
                <Col md={6}>
                  <Datepicker
                    field={
                      `requirements.[${typeFieldValue.name}].[${typeOptionDates[typeFieldValue.id]}]`
                    }
                    label={`${typeOptionDates[typeFieldValue.id]}`}
                    dateFormat="DD/MM/YYYY"
                    disabled={fixDates}
                    utc
                    labelClass="font-weight-bold"
                    validate={required}
                    startDateField={{ value: moment().subtract( '1', 'day' ) }}
                  />
                </Col>
                )}
                {typeOptionBookingDays[typeFieldValue.id] && (
                <Col md={6}>
                  <TextField
                    field={
                      `requirements.[${typeFieldValue.name}].[${typeOptionBookingDays[typeFieldValue.id]}]`
                    }
                    label={`${typeOptionBookingDays[typeFieldValue.id]}`}
                    type="number"
                    validate={required}
                  />
                </Col>
                )}
                {typeOptionBookingDays[typeFieldValue.id] && (
                <Col md={6} />
                )}
                <Col md={6}>
                  <Select
                    field={`requirements.[${typeFieldValue.name}].onlyBookings`}
                    label="onlyBookings"
                    isClearable
                    labelClass="font-weight-bold mr-2"
                    translateOptions
                    options={typeFieldValue.name === 'shortStaySuplement' ? [
                      { id: 'OF', name: 'of' },
                      { id: 'UNDER', name: 'under' },
                    ]
                      : [
                        { id: 'OVER', name: 'over' },
                        { id: 'OF', name: 'of' },
                      ]}
                    validate={required}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    field={`requirements.[${typeFieldValue.name}].numberOfNights`}
                    label="numberOfNights"
                    type="number"
                    validate={required}
                  />
                </Col>
              </Row>
              )}
            </>
          );
        }}
      />
    );
  },
);
