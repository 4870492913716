import enLang from './locales/en_US.json';
import esLang from './locales/es_ES.json';
import itLang from './locales/it_IT.json';
import frLang from './locales/fr_FR.json';

const AppLocale = {
  en: enLang,
  es: esLang,
  it: itLang,
  fr: frLang,
};

export default AppLocale;
