import React from 'react';
import {
  Container, Row, Col, Card,
} from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router';
import classnames from 'classnames';

import { SimpleHeader, HorizontalMenu } from 'components';

export default React.memo(
  ( {
    data,
    entityId,
    parentPath,
    isLoading,
    tabs,
    pathLayout,
    pathname,
    onReloadData,
    history,
  } ) => {
    const getRoutes = ( routesList ) => routesList.map( ( route ) => {
      if ( route.component ) {
        return (
          <Route
            exact={route.exact}
            path={pathLayout + route.path}
            render={( props ) => (
              <route.component
                {...props}
                data={data}
                isLoading={isLoading}
                entityId={entityId}
                parentPath={parentPath}
                history={history}
                onReloadData={onReloadData}
              />
            )}
            key={route.layout + route.path}
          />
        );
      }
      return null;
    } );

    return (
      <>
        <SimpleHeader parentLinks={[{ name: 'resources', href: parentPath }]} />
        <Container
          fluid
          className={classnames( {
            'app-component-loading': isLoading,
          } )}
        >
          {!!entityId && (
            <Card className="card-sec-top border-bottom">
              <HorizontalMenu
                tabs={tabs}
                pathLayout={pathLayout}
                pathname={pathname}
              />
            </Card>
          )}
          <Row>
            <Col>
              <Switch>
                {getRoutes( tabs )}
                <Redirect from="*" to={parentPath} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </>
    );
  },
);
