import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  newMessage: null,
  selectedChannelId: null,
};

const { Types, Creators } = createActions( {
  updateNewMessage: ['message'],
  setSelectedChannel: ['channelId'],
} );

const updateNewMessage = ( state, { message } ) => {
  if ( !message ) return { ...state, newMessage: null };
  const newMessage = {
    id: message.extra.messageId,
    body: message.body,
    resource: message.extra.messageImageURL,
    created: Number( message.extra.messageDate ),
    channel: {
      id: message.extra.id,
      name: message.extra.name,
      description: message.extra.description,
      bookingId: message.extra.bookingId,
    },
    owner: {
      id: message.sender.id,
      firstName: message.sender.firstName,
      lastName: message.sender.lastName,
      profilePicture: message.sender.profilePicture,
    },
  };
  return { ...state, newMessage };
};

const setSelectedChannel = ( state, { channelId } ) => ( {
  ...state,
  selectedChannelId: channelId,
} );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.UPDATE_NEW_MESSAGE]: updateNewMessage,
  [Types.SET_SELECTED_CHANNEL]: setSelectedChannel,
} );

export default Creators;
