import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// eslint-disable-next-line no-unused-vars
import { ApolloProvider } from '@apollo/client';

import client from 'config/Api/NewApi';
import App from './App';
import * as store from './store';

/* Sentry.init( {
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_SENTRY_ENV,
} ); */

const storeMain = store.default();
const render = () => {
  ReactDOM.render(
    <Provider store={storeMain.store}>
      <ConnectedRouter history={store.history}>
        <PersistGate loading={null} persistor={storeMain.persistor}>
          <ApolloProvider client={client}>
            <Switch>
              <Route path="/" component={App} />
              <Redirect from="*" to="/" />
            </Switch>
          </ApolloProvider>
        </PersistGate>
      </ConnectedRouter>
    </Provider>,
    document.getElementById( 'root' ),
  );
};

// expose store when run in Cypress
if ( window.Cypress ) {
  window.store = storeMain.store;
}

render();
