import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { canModify } from 'config/Ability';
import {
  AsyncTable, AddBtn, SimpleHeader, TableGenericFilter, TableActionsBtns,
} from 'components';

const RoleDashboard = React.memo( ( {
  data, parentPath, defaultFilters, isLoading, onFetchData,
} ) => (
  <>
    <SimpleHeader
      title="roles"
    />
    <Container fluid>
      <AsyncTable
        id="roles"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        rightContent={canModify( 'settings' ) ? <AddBtn title="newRole" route={`${parentPath}/new`} /> : null}
        onFetchData={onFetchData}
        customFilters={( { filters, setFilter } ) => (
          <Row>
            <Col md={8} lg={4}>
              <TableGenericFilter
                filters={filters}
                setFilter={setFilter}
              />
            </Col>
          </Row>
        )}
        columns={[
          {
            Header: 'name',
            accessor: 'name',
            Cell: ( { cell } ) => (
              <Link to={`${parentPath}/edit/${cell.row.original.id}`}>
                {cell.value}
              </Link>
            ),
          },
          {
            Header: 'description',
            accessor: 'description',
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns actions={[{
                link: `${parentPath}/clone/${cell.row.original.id}`,
                label: 'clone',
              }]}
              />
            ),
          },
        ]}
      />
    </Container>
  </>
) );

export default RoleDashboard;
