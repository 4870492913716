import moment from 'moment';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import getStatusColor from 'config/Constants/StatusColor';
// import dd from '../datados.json';

const statusColors = getStatusColor();

const pipe = ( ...fns ) => ( x ) => fns.reduce( ( v, f ) => f( v ), x );

const FUTURE = statusColors.colors.future;
const PRESENT = statusColors.colors.present;
const PAST = statusColors.colors.past;
const BLOCKED = statusColors.colors.blocked;

const formatDateRange = ( dateRangeToFormat ) => {
  const totalDays = dateRangeToFormat.end.diff( dateRangeToFormat.start, 'days' ) + 1;

  const startDay = dateRangeToFormat.start;
  const dateRange = Array.from( new Array( totalDays ).keys() ).map( ( next ) => {
    const day = moment( startDay ).add( next, 'day' );
    return {
      dayOfWeek: day.format( 'ddd' ),
      day: day.format( 'DD' ),
      date: day.format( 'YYYY-MM-DD' ),
      isWeekend: day.day() === 0 || day.day() === 6,
      isStartMonth: day.date() === 1,
      month: day.format( 'MMMM' ),
      year: day.format( 'YY' ),
      dateTime: day,
      value: day.valueOf(),
    };
  } );
  return dateRange;
};

const getDayIndex = ( dateRangeToFormat ) => {
  const totalDays = dateRangeToFormat.end.diff( dateRangeToFormat.start, 'days' ) + 1;
  const arrayCount = Array.from( new Array( totalDays ).keys() );
  const startDay = moment( dateRangeToFormat.start, 'YYYY-MM-DD' )
    .utc( true )
    .valueOf();

  const dateRange = arrayCount.reduce( ( acc, next, index ) => {
    const day = moment( startDay ).add( next, 'day' );
    return {
      ...acc,
      [day.valueOf()]: index,
    };
  }, {} );

  return { index: dateRange, total: arrayCount.length };
};

export const groupByPropertyName = ( data ) => groupBy(
  data,
  ( item ) => `${get( item, 'name', '' )}-${get( item, 'postalCode', '' )}`,
);

const groupByPropertyRoomType = ( data ) => groupBy(
  data,
  ( item ) => `${get( item, 'property.id', '' )}-${get( item, 'roomType.id', '' )}`,
);

const condensePropertyRoomTypeData = ( data ) => data.map( ( property ) => ( {
  propertyId: get( property, '0.id', '' ),
  roomTypeId: get( property, '0.id', '' ),
  propertyName: `${get( property, '0.name', '' )}`,
  roomTypeName: `${get( property, '0.postalCode', '' )}`,
  propertyIds: property.map( ( item ) => item.id ),
  accommodationsIds: property.map( ( item ) => item.accommodationId ).filter( ( item ) => typeof item !== 'undefined' ),
  accommodations: [],
} ) );

const orderAlphabetically = ( arr ) => arr.sort(
  ( a, b ) => a.propertyName.toLowerCase().localeCompare(
    b.propertyName.toLowerCase(),
  ),
);

export const formatProperties = ( properties, dateRangeToFormat ) => {
  const dateRange = formatDateRange( dateRangeToFormat );

  const groupedProperties = pipe(
    groupByPropertyName,
    Object.values,
    condensePropertyRoomTypeData,
    orderAlphabetically,
  )( properties );

  return {
    roomTypes: groupedProperties,
    dateRange,
  };
};

const extractPropertyRoomTypeData = ( data ) => data.map( ( property ) => ( {
  propertyId: get( property, '0.property.id', '' ),
  roomTypeId: get( property, '0.roomType.id', '' ),
  propertyName: `${get( property, '0.property.name', '' )}`,
  roomTypeName: `${get( property, '0.roomType.name', '' )}`,
  accommodations: property,
} ) );

const filterBookingsByAccommodation = (
  bookings, accommodationId,
) => bookings.filter(
  ( { accommodation } ) => accommodation.id === accommodationId,
);

const filterTasksByAccommodation = (
  tasks, accommodationId,
) => tasks.filter(
  ( { accommodation } ) => accommodation.id === accommodationId,
);

const getBookingsAccommodationIds = ( bookings ) => bookings.reduce(
  ( acc, { accommodation } ) => ( { ...acc, [accommodation.id]: true } ),
  {},
);

const getTasksAccommodationIds = ( tasks ) => tasks.reduce(
  ( acc, { accommodation } ) => ( { ...acc, [accommodation.id]: true } ),
  {},
);

const hasBookings = (
  bookingsAccommodationIds,
) => ( accommodationId ) => bookingsAccommodationIds[accommodationId];

const hasTasks = (
  taskAccommodationIds,
) => ( accommodationId ) => taskAccommodationIds[accommodationId];

const getBookingsByAccommotadionId = ( bookings, hasBookingsCallback ) => ( accommodationId ) => {
  if ( hasBookingsCallback( accommodationId ) ) {
    return filterBookingsByAccommodation( bookings, accommodationId );
  }

  return [];
};

const getTasksByAccommotadionId = ( tasks, hasTasksCallback ) => ( accommodationId ) => {
  if ( hasTasksCallback( accommodationId ) ) {
    return filterTasksByAccommodation( tasks, accommodationId );
  }

  return [];
};

const addCellsEmpty = ( numCells ) => ( data ) => data.map( ( accommodation ) => ( {
  ...accommodation,
  cells: new Array( numCells ).fill( [] ),
} ) );

const addColorBooking = ( bookings ) => {
  const today = moment().utc().valueOf();
  return bookings.map( ( booking ) => {
    if ( today > booking.till ) {
      return {
        ...booking,
        color: PAST,
      };
    }

    if ( today < booking.from ) {
      return {
        ...booking,
        color: FUTURE,
      };
    }

    return {
      ...booking,
      color: PRESENT,
    };
  } );
};

const addColorBookingBlocked = ( bookings ) => bookings.map( ( booking ) => ( {
  ...booking,
  color: BLOCKED,
} ) );

const formatAccommodation = ( accommodations ) => accommodations.map( ( accomodation ) => ( {
  ...accomodation,
  accommodationId: accomodation._id || accomodation.id,
} ) );

export const formatAcc = ( properties, dataToFormat, dateRangeToFormat ) => {
  // eslint-disable-next-line no-use-before-define
  const accommodations = removeDuplicates(
    dataToFormat.accommodations.data,
  );

  const { bookedAccommodations, tasks, blockedAccommodations } = dataToFormat;

  const dateRange = formatDateRange( dateRangeToFormat );

  const { index: daysIdx, total } = getDayIndex( dateRangeToFormat );

  const addTotalCellsEmpty = addCellsEmpty( total );

  const bookingsColors = addColorBooking( bookedAccommodations );

  const bookingsBlockedColors = addColorBookingBlocked( blockedAccommodations );

  const allBookings = [...bookingsColors, ...bookingsBlockedColors].sort(
    ( a, b ) => a.from - b.from,
  );

  const bookingsAccommodationIds = getBookingsAccommodationIds( allBookings );

  const hasBookingsCallback = hasBookings( bookingsAccommodationIds );

  const addBookingsByAccommodationId = getBookingsByAccommotadionId(
    allBookings,
    hasBookingsCallback,
  );

  const addBookingsCells = ( data ) => data.map( ( accommodation ) => {
    const bookings = addBookingsByAccommodationId(
      accommodation._id || accommodation.id,
    );

    bookings.forEach( ( booking ) => {
      const bookingFrom = new Date( new Date( booking.from ).setUTCHours( 0 ) );
      const bookingTill = new Date( new Date( booking.till ).setUTCHours( 0 ) );
      const startIdx = daysIdx[bookingFrom.getTime()];
      const endIdx = daysIdx[bookingTill.getTime()];

      const idxStart = startIdx || 0;
      const idxEnd = endIdx >= 0 ? endIdx : total - 1;

      Array.from( new Array( idxEnd - idxStart + 1 ).keys() ).forEach( ( i ) => {
        const idx = i + idxStart;
        // eslint-disable-next-line no-param-reassign
        accommodation.cells[idx] = [
          ...accommodation.cells[idx],
          {
            ...booking,
            elementtType: 'booking',
            bookingId: booking.id,
            isStart: startIdx === idx,
            isEnd: endIdx === idx,
          },
        ];
      } );
    } );

    return accommodation;
  } );

  const tasksAccommodationIds = getTasksAccommodationIds( tasks );

  const hasTasksCallback = hasTasks( tasksAccommodationIds );

  const addTasksByAccommodationId = getTasksByAccommotadionId(
    tasks,
    hasTasksCallback,
  );

  const addTasksCells = ( data ) => data.map( ( accommodation ) => {
    const _tasks = addTasksByAccommodationId(
      accommodation._id || accommodation.id,
    );

    _tasks.forEach( ( task ) => {
      const idx = daysIdx[moment.utc( task.date ).startOf( 'day' ).valueOf()];
      if ( idx >= 0 ) {
        // eslint-disable-next-line no-param-reassign
        accommodation.cells[idx] = [
          ...accommodation.cells[idx],
          {
            ...task,
            taskId: task.id,
            taskTypeName: task.taskType.name,
          },
        ];
      }
    } );
    return accommodation;
  } );

  const accommodationsGroup = pipe(
    formatAccommodation,
    addTotalCellsEmpty,
    addBookingsCells,
    addTasksCells,
  )( accommodations );

  const accPIds = accommodationsGroup.map( ( item ) => item.property.id );

  const isEqual = ( obj1, obj2 ) => obj1 === obj2;

  const _properties = properties.map( ( item ) => {
    if ( accommodationsGroup.length ) {
      const hasCommonElement = accPIds.some(
        ( obj1 ) => item.propertyIds.some( ( obj2 ) => isEqual( obj1, obj2 ) ),
      );

      if ( hasCommonElement ) {
        return {
          ...item,
          // eslint-disable-next-line no-use-before-define
          accommodations: removeDuplicatedAccomodations(
            // eslint-disable-next-line no-use-before-define
            extractAccPerProp( [...accommodationsGroup], item.propertyIds ),
          ),
        };
      }
    }
    return item;
  } );

  return {
    roomTypes: _properties,
    dateRange,
  };
};

const format = ( dataToFormat, dateRangeToFormat ) => {
  const accommodations = dataToFormat.accommodations.data;

  const { bookedAccommodations, tasks, blockedAccommodations } = dataToFormat;

  const dateRange = formatDateRange( dateRangeToFormat );

  const { index: daysIdx, total } = getDayIndex( dateRangeToFormat );

  const addTotalCellsEmpty = addCellsEmpty( total );

  const bookingsColors = addColorBooking( bookedAccommodations );

  const bookingsBlockedColors = addColorBookingBlocked( blockedAccommodations );

  const allBookings = [...bookingsColors, ...bookingsBlockedColors].sort(
    ( a, b ) => a.from - b.from,
  );

  const bookingsAccommodationIds = getBookingsAccommodationIds( allBookings );

  const hasBookingsCallback = hasBookings( bookingsAccommodationIds );

  const addBookingsByAccommodationId = getBookingsByAccommotadionId(
    allBookings,
    hasBookingsCallback,
  );

  const addBookingsCells = ( data ) => data.map( ( accommodation ) => {
    const bookings = addBookingsByAccommodationId(
      accommodation._id || accommodation.id,
    );

    bookings.forEach( ( booking ) => {
      const bookingFrom = new Date( new Date( booking.from ).setUTCHours( 0 ) );
      const bookingTill = new Date( new Date( booking.till ).setUTCHours( 0 ) );
      const startIdx = daysIdx[bookingFrom.getTime()];
      const endIdx = daysIdx[bookingTill.getTime()];

      const idxStart = startIdx || 0;
      const idxEnd = endIdx >= 0 ? endIdx : total - 1;

      Array.from( new Array( idxEnd - idxStart + 1 ).keys() ).forEach( ( i ) => {
        const idx = i + idxStart;
        // eslint-disable-next-line no-param-reassign
        accommodation.cells[idx] = [
          ...accommodation.cells[idx],
          {
            ...booking,
            elementtType: 'booking',
            bookingId: booking.id,
            isStart: startIdx === idx,
            isEnd: endIdx === idx,
          },
        ];
      } );
    } );

    return accommodation;
  } );

  const tasksAccommodationIds = getTasksAccommodationIds( tasks );

  const hasTasksCallback = hasTasks( tasksAccommodationIds );

  const addTasksByAccommodationId = getTasksByAccommotadionId(
    tasks,
    hasTasksCallback,
  );

  const addTasksCells = ( data ) => data.map( ( accommodation ) => {
    const _tasks = addTasksByAccommodationId(
      accommodation._id || accommodation.id,
    );

    _tasks.forEach( ( task ) => {
      const idx = daysIdx[moment.utc( task.date ).startOf( 'day' ).valueOf()];
      if ( idx >= 0 ) {
        // eslint-disable-next-line no-param-reassign
        accommodation.cells[idx] = [
          ...accommodation.cells[idx],
          {
            ...task,
            taskId: task.id,
            taskTypeName: task.taskType.name,
          },
        ];
      }
    } );
    return accommodation;
  } );

  const accommodationsGroupByProperty = pipe(
    formatAccommodation,
    addTotalCellsEmpty,
    addBookingsCells,
    addTasksCells,
    groupByPropertyRoomType,
    Object.values,
    extractPropertyRoomTypeData,
  )( accommodations );

  return {
    roomTypes: accommodationsGroupByProperty,
    dateRange,
  };
};

export const formatReports = ( reportsToFormat, dateRangeToFormat ) => {
  const dateRange = formatDateRange( dateRangeToFormat );
  const reports = dateRange.map( ( date ) => {
    const dateInt = parseInt( date.date.replaceAll( '-', '' ), 10 );
    const reportFound = reportsToFormat.find(
      ( report ) => report.dateInt === dateInt,
    );
    if ( reportFound ) {
      return reportFound;
    }
    return {
      id: date.value,
      date: date.value,
      dateInt: date.date.replace( '-', '' ),
      checkin: 0,
      checkout: 0,
      empty: 0,
      task: 0,
      issues: 0,
      preventives: 0,
    };
  } );
  return { data: reports };
};

export const removeDuplicates = ( array ) => Array.from( new Set(
  array.map( ( obj ) => JSON.stringify( obj ) ),
) ).map( ( str ) => JSON.parse( str ) );

const extractAccPerProp = ( accs, propts ) => {
  const classified = propts.map( ( p ) => accs.filter(
    ( a ) => a.property.id === p,
  ) );

  const flattenedArray = classified.reduce(
    ( accumulator, currentValue ) => accumulator.concat( currentValue ), [],
  );

  return flattenedArray;
};

export const removeDuplicatedAccomodations = ( array ) => {
  const uniqueIds = {};

  const accomodations = array.filter( ( accomodation ) => {
    if ( !uniqueIds[accomodation._id || accomodation.id] ) {
      uniqueIds[accomodation._id || accomodation.id] = true;
      return true;
    }
    return false;
  } );

  return accomodations;
};

export default format;
