import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PermitsView from 'views/Team/Edit/Permits';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetch, useFetchTableData } from 'hooks';
import EmployeeService from 'api/Employee';
import EmployeePermitsService from 'api/Settings/EmployeePermits';
import FiltersActions from 'store/reducers/Filters';
import moment from 'moment';

const Permits = ( {
  data,
  entityId,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const currentYear = moment().year();

  const [permitOptions, permitOptionsLoading] = useFetch( {
    initialState: { data: [] },
    promise: () => EmployeePermitsService.getEmployeePermits( {
      page: 1,
      elementsPerPage: 10,
      enable: true,
    } ),
    toggleErrorAlert,
  } );

  const [registers, registersLoading] = useFetch( {
    initialState: {},
    promise: () => EmployeeService.getAllEmployeePermitRegisters( {
      page: 1,
      elementsPerPage: 1000,
      year: currentYear,
    } ),
    toggleErrorAlert,
  } );

  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => EmployeeService.getAllEmployeePermitRequests( {
      ...params,
      from: `${currentYear}-01-01`,
      till: `${currentYear}-12-31`,
    } ),
    toggleErrorAlert,
    deps: [entityId, dataSubmitted],
  } );

  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deletePermitRequest = useDelete( {
    promise: EmployeeService.deletePermitRequest,
    callback: reload,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <PermitsView
        data={data}
        registers={registers.data}
        settings={permitOptions.data}
        fetchData={fetchData}
        dataTable={dataTable}
        dataLoading={dataLoading
          || permitOptionsLoading
          || registersLoading}
        onDelete={deletePermitRequest}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateFilters: FiltersActions.updateFilters,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( Permits );
