import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  reloadedAt: null,
  editIncidenceModalOpened: false,
  incidenceId: null,
  incidenceToEditId: null,
  incidenceToEditData: null,
};

const { Types, Creators } = createActions( {
  reloadIncidence: ['id'],
  toggleIncidenceEditModal: ['id', 'data'],
} );

const reloadIncidence = ( state, { id } ) => ( {
  ...state,
  incidenceId: id,
  reloadedAt: new Date().valueOf(),
} );

const toggleIncidenceEditModal = ( state, { id, data } ) => ( {
  ...state,
  incidenceToEditId: id,
  incidenceToEditData: data,
  editIncidenceModalOpened: !state.editIncidenceModalOpened,
} );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.RELOAD_INCIDENCE]: reloadIncidence,
  [Types.TOGGLE_INCIDENCE_EDIT_MODAL]: toggleIncidenceEditModal,
} );

export const TaskTypes = Types;
export default Creators;
