import React from 'react';
import useFetchTableData from 'hooks/useFetchTableData';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import KeysView from 'views/Booking/Edit/Keys';
import IssuedKeysService from 'api/IssuedKeys';
import SettingsActions from 'store/reducers/Settings';

const Keys = ( { toggleErrorAlert, bookingData } ) => {
  const accommodation = _.get( bookingData, 'currentAccommodation.id' );
  const from = moment.utc( _.get( bookingData, 'from' ) ).format( 'YYYY-MM-DD' );
  const till = moment.utc( _.get( bookingData, 'till' ) ).format( 'YYYY-MM-DD' );

  const [onFetchData, data, dataLoading] = useFetchTableData( {
    promise: () => IssuedKeysService.getIssuedKeys( {
      accommodation,
      from,
      till,
    } ),
    toggleErrorAlert,
    conditional: !!accommodation && !!from && !!till,
    deps: [accommodation, from, till],
  } );

  return (
    <KeysView data={data} onFetchData={onFetchData} isLoading={dataLoading} />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( Keys );
