import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  Select, TranslationLayout, TextField, ModalForm,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    data,
    isLoading,
    entityId,
    open,
    onClose,
    countries,
    loadingCountries,
    onChangeCountry,
    states,
    loadingStates,
    onChangeState,
    cities,
    loadingCities,
    onSubmit,
  } ) => (
    <ModalForm
      title={entityId ? 'editEmergencyNumber' : 'newEmergencyNumber'}
      open={open}
      isLoading={isLoading}
      size="md"
      bodyClassName="overflow-visible"
      onClose={onClose}
      showErrorsInFooter
      onSubmit={onSubmit}
      initialValues={data}
      body={( form ) => (
        <TranslationLayout
          translationFields={( locale ) => (
            <Row>
              <Col sm={12}>
                <TextField
                  field={`translations.${locale}.title`}
                  label="name"
                  labelClass="font-weight-bold"
                />
              </Col>
              <Col sm={12}>
                <TextField
                  field={`translations.${locale}.text`}
                  label="phone"
                  labelClass="font-weight-bold"
                />
              </Col>
            </Row>
          )}
        >
          <Row>
            <Col sm={12}>
              <TextField
                field="title"
                label="name"
                labelClass="font-weight-bold"
                validate={required}
              />
            </Col>
            <Col sm={12}>
              <TextField
                field="text"
                label="phone"
                labelClass="font-weight-bold"
                validate={required}
              />
            </Col>
            <Col lg={4}>
              <Select
                field="country"
                label="country"
                isLoading={loadingCountries}
                options={countries}
                validate={required}
                onChange={( countryId ) => {
                  form.change( 'state', null );
                  form.change( 'city', null );
                  onChangeCountry( countryId );
                }}
              />
            </Col>

            <Col lg={4}>
              <Select
                field="state"
                label="state"
                isLoading={loadingStates}
                options={states}
                onChange={( stateId ) => {
                  form.change( 'city', null );
                  onChangeState( stateId );
                }}
              />
            </Col>
            <Col lg={4}>
              <Select
                field="city"
                label="city"
                isLoading={loadingCities}
                options={cities}
              />
            </Col>
          </Row>
        </TranslationLayout>
      )}
    />
  ),
);
