import React, { useState } from 'react';
import { Field } from 'react-final-form';

import DatepickerInput from '../utils/DatepickerInput';

const Datepicker = React.memo( ( {
  field, validate, value, name, position, onBlur, ...rest
} ) => {
  const [focused, setFocused] = useState( false );

  if ( field ) {
    return (
      <Field name={field} validate={validate}>
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && meta.submitError );
          return (
            <DatepickerInput
              {...input}
              {...rest}
              position={position}
              value={input.value || ''}
              inputOnChange={input.onChange}
              inputName={input.name}
              focused={meta.active}
              meta={meta}
              isInvalid={isInvalid}
            />
          );
        }}
      </Field>
    );
  }
  return (
    <DatepickerInput
      {...rest}
      position={position}
      value={value || ''}
      inputName={name}
      focused={focused}
      onFocus={() => setFocused( true )}
      onBlur={onBlur ? () => {
        setFocused( false );
        onBlur();
      } : ( () => setFocused( false ) )}
    />
  );
} );

export default Datepicker;
