import React from 'react';
import {
  ModalForm, Select,
} from 'components';

export default React.memo( ( {
  isLoading, open, onClose, onSubmit, availableTeams, availableEmployees,
  setSelectedTeam,
} ) => (
  <ModalForm
    title="bulkAssigment"
    showErrorsInFooter
    isLoading={isLoading}
    open={open}
    size="md"
    bodyClassName="overflow-visible"
    bodyStyle={{ minHeight: 145 }}
    onSubmit={onSubmit}
    onClose={onClose}
    body={() => (
      <>

        <Select
          placeholder="all"
          field="team"
          label="team"
          labelClass="font-weight-bold"
          isSearchable
          options={availableTeams}
          onChange={setSelectedTeam}
        />

        <Select
          placeholder="assignTeamFirst"
          field="employee"
          label="assignedTo"
          labelClass="font-weight-bold"
         // labelKey={getResourceName}
          options={availableEmployees}
        />
      </>
    )}
  />
) );
