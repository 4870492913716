const formatBookinglId = ( {
  externalId = '',
  localizator = '',
  id = '',
  userBookingReference = 'localizator',
} = {} ) => {
  if ( localizator && userBookingReference === 'localizator' ) { return `#${localizator}`; }
  if ( externalId && userBookingReference === 'external_id' ) { return `${externalId}`; }
  if ( id ) { return `#${id.slice( 0, 6 )}`; }
  return '';
};

export default formatBookinglId;
