import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const DELETE_ACCOMMODATIONS = gql`
mutation DeleteAccomodationOccupancy($accomodationOccupancyId: [ID!]!) {
    deleteAccomodationOccupancy(accomodationOccupancyId: $accomodationOccupancyId) {
      ... on BaseError {
        message
      }
      ... on MutationDeleteAccomodationOccupancySuccess {
        data {
          success
        }
      }
    }
  }
`;

const deleteAccomodationsOccupancy = async ( accomodationOccupancyId ) => {
  const { data } = await client.query( {
    query: DELETE_ACCOMMODATIONS,
    variables: {
      accomodationOccupancyId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteAccomodationOccupancy.message ) {
    return {
      ok: false,
      message: data.deleteAccomodationOccupancy.message,
    };
  }
  const deleteSuccessful = data.deleteAccomodationOccupancy.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteAccomodationsOccupancy;
