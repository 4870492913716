import React, {
  useState, useContext, useMemo, useEffect,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import PipelineEditView from 'views/CRM/Pipelines/Edit';
import {
  handlePromise,
} from 'utils';
import { createPipeline, updatePipeline } from 'api/CRM/Contact';
import { PipelinesCRMContext } from '../context';

const PipelineEdit = ( {
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
} ) => {
  const {
    pipelineToEdit,
    pipelineEditModalOpened,
    togglePipelineEditModal,
    onReload,
  } = useContext( PipelinesCRMContext );

  const [stages, setStages] = useState( [] );

  useEffect( () => {
    if ( pipelineToEdit ) {
      setStages( pipelineToEdit?.stages ?? [] );
    }
  }, [pipelineToEdit] );

  const [confirmationModalOpened, setConfirmationModalOpened] = useState( false );
  const [confirmationModalTitle, setConfirmationModalTitle] = useState( '' );

  const [dataToSend, setDataToSend] = useState( {} );

  const onSubmit = useCallback(
    async ( d ) => {
      toggleLoading( true );

      const newDataToSend = pipelineToEdit ? {
        ...d,
        stages,
        id: pipelineToEdit?.id,
        force: false,
      } : {
        ...d,
        stages,
      };

      setDataToSend( newDataToSend );

      const [errors, response] = await handlePromise(
        pipelineToEdit
          ? updatePipeline( newDataToSend )
          : createPipeline( newDataToSend ),
      );

      if ( !response.ok ) {
        if ( response.message && response.message.toLowerCase().indexOf( 'the pipeline has associated business' ) >= 0 ) {
          setConfirmationModalTitle( response.message );
          setConfirmationModalOpened( true );
        } else {
          toggleErrorAlert( errors || response?.message );
        }

        toggleLoading( false );
        return errors;
      }
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      togglePipelineEditModal( );
      if ( onReload ) onReload();
    }, [pipelineToEdit, onReload, togglePipelineEditModal,
      stages, toggleInfoAlert, toggleLoading, toggleErrorAlert],
  );

  const forceSubmit = useCallback(
    async ( ) => {
      toggleLoading( true );

      const [errors, response] = await handlePromise(
        updatePipeline( { ...dataToSend, force: true } ),
      );

      if ( !response.ok ) {
        toggleErrorAlert( errors || response?.message );
        toggleLoading( false );
        return errors;
      }
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      setConfirmationModalOpened( false );
      togglePipelineEditModal( );
      if ( onReload ) onReload();
    }, [dataToSend, onReload, togglePipelineEditModal,
      toggleInfoAlert, toggleLoading, toggleErrorAlert],
  );

  const isDefault = useMemo( () => {
    if ( pipelineToEdit && pipelineToEdit?.name === 'Default' ) {
      return true;
    }
    return false;
  }, [pipelineToEdit] );

  return (
    <PipelineEditView
      open={pipelineEditModalOpened}
      onClose={togglePipelineEditModal}
      data={pipelineToEdit}
      stages={stages}
      setStages={setStages}
      onSubmit={onSubmit}
      isDefault={isDefault}
      confirmationModalOpened={confirmationModalOpened}
      setConfirmationModalOpened={setConfirmationModalOpened}
      confirmationModalTitle={confirmationModalTitle}
      forceSubmit={forceSubmit}
    />
  );
};

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( PipelineEdit );
