import api from 'config/Api/Api';

const baseEndpoint = '/settings/cities';

export default {
  getCities( params ) {
    return api.get( baseEndpoint, params );
  },

  saveCity( data ) {
    return api.post( baseEndpoint, data );
  },

  deleteCity( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },

  getZones( id, params ) {
    return api.get( `${baseEndpoint}/${id}/zones`, params );
  },
};
