import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SettingsActions from 'store/reducers/Settings';
import DashboardView from 'views/Communications/Operative/Actions/Dashboard';
import CommunicationService from 'api/CommunicationTemplates';
import EmailSettingsService from 'api/Settings/Email';
import { handlePromise } from 'utils';
import { waterfall } from 'utils/async';
import formatActions from './utils';

const Dashoard = ( { propertyManager, toggleErrorAlert } ) => {
  // const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  // const [data, dataLoading] = useFetch( {
  //   initialState: [],
  //   promise: () => CommunicationService.getActions( ),
  //   format: formatActions,
  //   toggleErrorAlert,
  //   // deps: [dataSubmitted],
  // } );

  // const reloadData = useCallback( () => {
  //   setDataSubmitted( new Date().valueOf() );
  // }, [] );

  // const deleteTemplate = useDelete( {
  //   promise: CommunicationService.deleteTemplate,
  //   callback: reloadData,
  //   toggleLoading,
  //   toggleErrorAlert,
  // } );

  const [data, setData] = useState( [] );
  const [InstantNotificationTypes, setInstantNotificationTypes] = useState( [] );
  const [dataLoading, setDataLoading] = useState( true );

  const getActions = useCallback( async ( cb ) => {
    const [errors, response, actions] = await handlePromise(
      CommunicationService.getActions(),
    );
    if ( !response.ok ) return cb( errors );
    return cb( null, { actions } );
  }, [] );

  const getEmailSetting = useCallback(
    async ( actions, cb ) => {
      if ( !propertyManager || !propertyManager.id ) return cb( 'apiError' );
      const [errors, response, settings] = await handlePromise(
        EmailSettingsService.getEmailSettings( propertyManager.id ),
      );
      if ( !response.ok ) return cb( errors );
      return cb( null, { ...actions, settings } );
    },
    [propertyManager],
  );

  useEffect( () => {
    setDataLoading( true );

    waterfall( [getActions, getEmailSetting], ( errors, results ) => {
      setDataLoading( false );
      if ( errors ) return toggleErrorAlert( errors );

      const newInstantNotificationTypes = results?.settings?.InstantNotificationTypes || [];

      const newData = formatActions( results?.actions );
      setData( newData );
      setInstantNotificationTypes( newInstantNotificationTypes );
    } );
  }, [toggleErrorAlert, getActions, getEmailSetting] );

  const setAction = useCallback(
    async ( title, add ) => {
      const newInstantNotificationTypes = [...InstantNotificationTypes];
      if ( add ) {
        newInstantNotificationTypes.push( title );
      } else {
        const index = newInstantNotificationTypes.indexOf( title );
        newInstantNotificationTypes.splice( index, 1 );
      }
      setInstantNotificationTypes( newInstantNotificationTypes );

      setDataLoading( true );

      const [errors, response] = await handlePromise(
        EmailSettingsService.updateEmailSettings( propertyManager.id, {
          instantNotificationTypes: newInstantNotificationTypes,
        } ),
      );
      setDataLoading( false );
      if ( !response.ok ) {
        if ( errors ) return toggleErrorAlert( errors );
      }
    },
    [propertyManager, toggleErrorAlert, InstantNotificationTypes],
  );

  return (
    <DashboardView
      data={data}
      InstantNotificationTypes={InstantNotificationTypes}
      isLoading={dataLoading}
      setAction={setAction}
    />
  );
};
const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashoard );
