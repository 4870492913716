/* eslint-disable operator-linebreak */
import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  TextField, ModalForm,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    open,
    toggleEditModal,
    onSubmit,
  } ) => (
    <ModalForm
      title="cancellationPoliciesAdd"
      showErrorsInFooter
      open={open}
      onSubmit={( d ) => {
        onSubmit( d );
      }}
      onClose={() => {
        toggleEditModal( null );
      }}
      bodyClassName="overflow-visible"
      body={() => (
        <>
          <Row className="mb-3">
            <Col md={4}>
              <TextField
                field="validFrom"
                label="cancellationPolicies:validFrom"
                type="number"
                validate={required}
              />
            </Col>
            <Col md={4}>
              <TextField
                field="validTo"
                label="cancellationPolicies:validTo"
                type="number"
                validate={required}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <TextField
                field="percentage"
                label="cancellationPolicies:percentage"
                type="number"
                validate={required}
              />
            </Col>
          </Row>
        </>
      )}
    />
  ),
);
