import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TemplatesEditView from 'views/Communications/Client/Notifications/Edit';
import { useFetch } from 'hooks';
import NotificationService from 'api/ScheduledNotifications';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import ModalPreview from '../Preview';

const Templates = ( {
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
  parentPath,
  match,
  history,
} ) => {
  const notificationId = _.get( match, 'params.id' );
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => NotificationService.getScheduledNotificationById( notificationId ),
    toggleErrorAlert,
    conditional: !!notificationId,
    deps: [notificationId],
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const dataToSend = formatDataToSend( formData, form );

      const formattedData = {};
      if ( dataToSend.bodyEmail ) {
        formattedData.bodyEmail = dataToSend.bodyEmail;
      }
      if ( dataToSend.bodyPush ) {
        formattedData.bodyPush = dataToSend.bodyPush;
      }

      const [errors, response] = await handlePromise(
        NotificationService.updateScheduledNotification( notificationId, formattedData ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }
      toggleLoading( false );
      history.push( parentPath );
      return toggleInfoAlert( 'dataSaved' );
    },
    [notificationId, toggleInfoAlert, toggleLoading, history, parentPath],
  );

  const [openModal, setOpenModal] = useState( false );
  const toggleModal = () => setOpenModal( ( state ) => !state );

  const [dataTempalte, setDataTempalte] = useState( {} );
  const showModalTemplate = ( { values } ) => {
    toggleModal();
    setDataTempalte( values );
  };

  return (
    <>
      <TemplatesEditView
        data={data}
        isLoading={dataLoading}
        onSubmit={submitForm}
        parentPath={parentPath}
        onPreview={showModalTemplate}
        entityId={notificationId}
      />
      <ModalPreview
        open={openModal}
        onClose={toggleModal}
        dataTempalte={dataTempalte}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( null, mapDispatchToProps )( Templates );
