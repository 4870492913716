import api from 'config/Api/Api';

const baseEndpoint = ( propertyId, roomTypeId ) => `/properties/${propertyId}/room-types/${roomTypeId}/accommodations`;

export default {

  saveCheckInOut( propertyId, roomTypeId, id, data ) {
    return api.post( `${baseEndpoint( propertyId, roomTypeId )}/${id}/checkinout`, data );
  },
  updateCheckInOut( propertyId, roomTypeId, id, data ) {
    return api.put( `${baseEndpoint( propertyId, roomTypeId )}/${id}/checkinout`, data );
  },

};
