/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState, useCallback, useRef,
} from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import classnames from 'classnames';
import _map from 'lodash/map';
import _get from 'lodash/get';

import {
  SimpleHeader, Intl, DaterangePickerBtn, Select, EditCard, AsyncSelect, ConfirmationModal,
} from 'components';
import { taskPriorities, taskStatuses, taskTypes as typeOfTaskTypes } from 'config/Constants';
import Calendar from './Calendar';
import ModalHelp from './ModalHelp';

const optionsPriority = _map( taskPriorities, ( priority ) => ( {
  id: priority.id,
  name: priority.name,
  icon: <i className={classnames( priority.iconClass, 'text-2xsm' )} />,
} ) );

const ContainerCalendar = ( {
  data,
  isLoading,
  dataReports,
  isLoadingDataReports,
  dateRange,
  taskTypes,
  isTaskTypesLoading,
  onFetchProperties,
  onfetchRoomTypes,
  onContextMenu,
  onClickBooking,
  onClickTask,
  showReport,
  // onClickShowReport,
  onChangeRange,
  onChangeFilters,
  onDeleteTask,
  onDragEnd,
  onSelectToday,
  onSelectThisWeek,
  onSelectThisMonth,
  selectedPropertyId,
  onDeleteBookingBlocked,
  onClickCell,
  nextPage,
  onClickAccomodation,
  resetAccomodation,
  onFetchTagsPredefined,
  countriesLoading,
  onChangeCountry,
  statesLoading,
  onChangeState,
  citiesLoading,
  optionsGeo,
  onChangeTag,
} ) => {
  const [deleteModal, setDeleteModal] = useState( false );
  const [openHelpModal, setOpenHelpModal] = useState( false );
  const deleteCb = useRef( null );

  const toggleDeleteModal = useCallback( ( cb ) => {
    deleteCb.current = cb;
    setDeleteModal( ( state ) => !state );
  }, [] );

  const toggleHelpModal = useCallback( ( cb ) => {
    deleteCb.current = cb;
    setOpenHelpModal( ( state ) => !state );
  }, [] );

  return (
    <>
      <SimpleHeader
        title="calendarForPlanningTask"
        rightContent={(
          <>
            {/* <Button
             // size="sm"
              color={showReport ? 'secondaryColor' : 'primary'}
              className="mt-2 mt-md-0 mx-2"
              onClick={onClickShowReport}
              visible={false}
            >
              <i className="fas fa-file-invoice mr-1" />
              {showReport
                ? <Intl id="hideReport" />
                : <Intl id="showReport" />}
            </Button> */}

            <DaterangePickerBtn
              position="right"
              initialValue={dateRange}
              key={dateRange}
              onChange={onChangeRange}
              sideBarOptions={[
                {
                  text: <Intl id="today" />,
                  id: 'today',
                  onClick: onSelectToday,
                },
                {
                  text: <Intl id="thisWeek" />,
                  id: 'thisWeek',
                  onClick: onSelectThisWeek,
                },
                {
                  text: <Intl id="thisMonth" />,
                  id: 'thisMonth',
                  onClick: onSelectThisMonth,
                },
              ]}
            />

            <button
              type="button"
              className="btn btn-primary-link"
              onClick={toggleHelpModal}
            >
              <i className="fas fa-question-circle" />
            </button>
          </>
          )}
      />

      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <EditCard>
          <Row className="dc_toolbar">
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <Select
                label="country"
                isClearable
                isLoading={countriesLoading}
                options={optionsGeo.countries}
                onChange={onChangeCountry}
              />
            </Col>
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <Select
                label="state"
                isClearable
                isLoading={statesLoading}
                options={optionsGeo.states}
                onChange={onChangeState}
              />
            </Col>
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <Select
                label="city"
                isClearable
                isLoading={citiesLoading}
                options={optionsGeo.cities}
                onChange={( value ) => onChangeFilters( 'city', value )}
              />
            </Col>
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <AsyncSelect
                label="property"
                defaultOptions
                loadOptions={onFetchProperties}
                isClearable
                placeholder="all"
                onChange={( value ) => onChangeFilters( 'property', _get( value, 'value' ) )}
              />
            </Col>

            {selectedPropertyId && (
              <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
                <AsyncSelect
                  label="roomType"
                  defaultOptions
                  loadOptions={onfetchRoomTypes}
                  isClearable
                  placeholder="all"
                  key="commercialName"
                  onChange={( value ) => onChangeFilters( 'roomType', _get( value, 'value' ) )}
                />
              </Col>
            )}
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <Select
                label="priority"
                options={optionsPriority}
                isClearable
                placeholder="all"
                onChange={( value ) => onChangeFilters( 'priority', value )}
              />
            </Col>
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <Select
                label="status"
                options={taskStatuses}
                isClearable
                placeholder="all"
                onChange={( value ) => onChangeFilters( 'status', value )}
              />
            </Col>
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <Select
                label="category"
                options={taskTypes}
                isLoading={isTaskTypesLoading}
                isClearable
                placeholder="all"
                optgroup
                onChange={( value ) => onChangeFilters( 'taskType', value )}
              />
            </Col>
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <Select
                label="type"
                options={typeOfTaskTypes}
                isClearable
                placeholder="all"
                onChange={( value ) => onChangeFilters( 'type', value )}
              />
            </Col>
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <Select
                label="autogenerated"
                options={[
                  {
                    id: 'true',
                    name: <Intl id="yes" />,
                  },
                  {
                    id: 'false',
                    name: <Intl id="no" />,
                  },
                ]}
                isClearable
                placeholder="all"
                onChange={( value ) => onChangeFilters( 'autogenerated', value )}
              />
            </Col>
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <Select
                label="autoassigned"
                options={[
                  {
                    id: 'true',
                    name: <Intl id="yes" />,
                  },
                  {
                    id: 'false',
                    name: <Intl id="no" />,
                  },
                ]}
                isClearable
                placeholder="all"
                onChange={( value ) => onChangeFilters( 'autoassigned', value )}
              />
            </Col>
            <Col xl lg={3} md={4} sm={6} className="dc_toolbar_item">
              <AsyncSelect
                label="tags"
                defaultOptions
                isClearable
                placeholder="all"
                onChange={( value ) => onChangeTag( value )}
                multiple
                loadOptions={onFetchTagsPredefined}
                formatFn={( dataTagsPredefined ) => (
                  ( dataTagsPredefined || [] ).map(
                    ( item ) => ( { id: item, name: item } ),
                  ) )}
              />
            </Col>

          </Row>
        </EditCard>
        <div
          className="dashboard-calendar-sticky"
          onScroll={( e ) => {
            const { scrollTop, clientHeight, scrollHeight } = e.target;
            const scrollPercentage = ( scrollTop + clientHeight ) / scrollHeight;
            if ( scrollPercentage >= 0.7 ) {
              nextPage();
            }
          }}
        >
          <Calendar
            data={data}
            dataReports={_get( dataReports, 'data', [] )}
            isLoadingDataReports={isLoadingDataReports}
            onContextMenu={onContextMenu}
            onClickBooking={onClickBooking}
            onClickTask={onClickTask}
            showReport={showReport}
            onDeleteTask={( id ) => toggleDeleteModal( () => onDeleteTask( id ) )}
            onDragEnd={onDragEnd}
            onDeleteBookingBlocked={( id ) => toggleDeleteModal(
              () => onDeleteBookingBlocked( id ),
            )}
            onClickCell={onClickCell}
            onClickAccomodation={onClickAccomodation}
            resetAccomodation={resetAccomodation}
          />
          <div className="spacer" />
        </div>
        <div style={{ height: '100vh' }} />
        <ConfirmationModal
          open={deleteModal}
          onConfirm={() => {
            deleteCb.current();
            toggleDeleteModal();
          }}
          onClose={toggleDeleteModal}
        />
        <ModalHelp open={openHelpModal} onClose={toggleHelpModal} />
      </Container>
    </>
  );
};
export default ContainerCalendar;
