import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { canModify, canList } from 'config/Ability';
import Dashboard from './Dashboard';
import Edit from './Edit';

// TODO: change ACLS

const PredefinedNotifications = ( { baseUrl } ) => (
  <Switch>
    {canModify( 'communications' ) && (
      <Route
        path={`${baseUrl}/new`}
        render={( props ) => <Edit {...props} parentPath={baseUrl} />}
      />
    )}
    {canModify( 'communications' ) && (
      <Route
        path={`${baseUrl}/edit/:id`}
        render={( props ) => <Edit {...props} parentPath={baseUrl} />}
      />
    )}
    {canList( 'communications' ) && (
      <Route
        path={baseUrl}
        render={( props ) => <Dashboard {...props} parentPath={baseUrl} />}
      />
    )}
  </Switch>
);

export default PredefinedNotifications;
