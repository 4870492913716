import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import PropertyManagerService from 'api/PropertyManager';
import FeeServices from 'api/Fees';
import { formatDataToSend, handlePromise } from 'utils';
import EditFeeView from 'views/PropertyManager/Edit/Finance/Fees/edit';
import { useFetch } from 'hooks';

const EditFeeContainer = ( {
  pmData,
  elementToEdit,
  onClose,
  onReloadData,
  toggleInfoAlert,
  toggleErrorAlert,
  toggleLoading,
} ) => {
  const [fees, feesLoading] = useFetch( {
    promise: () => FeeServices.getFeeList( {
      page: 1, elementsPerPage: 10,
    } ),
    format: ( dataToFormat ) => dataToFormat?.data || [],
  } );

  const feeOptions = useMemo( () => {
    if ( !fees ) {
      return [];
    }
    return fees.map( ( key ) => ( { id: key.id, name: key.periodicity } ) );
  }, [fees] );

  const [feeChosenId, setFeeChosenId] = useState( elementToEdit?.fee?.id || null );

  const feeChosen = useMemo( () => {
    if ( !fees || !fees.length || !feeChosenId ) {
      return null;
    }
    const fc = fees.filter( ( { id } ) => id === feeChosenId )[0];
    if ( !fc ) {
      return null;
    }
    return {
      ...fc,
      fee: fc.id,
    };
  }, [fees, feeChosenId] );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const datesSend = formatDataToSend( formData, form );

      if ( elementToEdit ) {
        delete datesSend.fee;
      }

      const dataToSend = {
        ...datesSend,
        periodicity: feeChosen?.periodicity || '',
      };

      const [dataErrors, dataResponse] = await handlePromise(
        elementToEdit
          ? PropertyManagerService.putFee( pmData.id, elementToEdit.id, dataToSend )
          : PropertyManagerService.postFee( pmData.id, dataToSend ),
      );

      toggleLoading( false );

      if ( !dataResponse.ok ) {
        toggleErrorAlert();
        return dataErrors;
      }

      toggleInfoAlert( 'dataSaved' );
      onReloadData();
    },
    [pmData, feeChosen, elementToEdit, onReloadData, toggleErrorAlert,
      toggleInfoAlert, toggleLoading],
  );

  return (
    <EditFeeView
      elementToEdit={elementToEdit}
      submitForm={submitForm}
      onClose={onClose}
      feeOptions={feeOptions}
      feesLoading={feesLoading}
      feeChosen={feeChosen}
      setFeeChosenId={setFeeChosenId}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( EditFeeContainer );
