/* eslint-disable operator-linebreak */
import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  TextField, ModalForm, Intl,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    amenityRecordId,
    data,
    isLoading,
    open,
    toggleEditModal,
    onSubmit,
  } ) => (
    <ModalForm
      initialValues={data}
      title={amenityRecordId ? 'editRecord' : 'newRecord'}
      showErrorsInFooter
      isLoading={isLoading}
      open={open}
      onSubmit={onSubmit}
      onClose={() => {
        toggleEditModal( null );
      }}
      bodyClassName="modal-wide overflow-visible"
      body={() => (
        <>
          <Row className="mb-2">
            <Col md={4}>
              <TextField
                field="description"
                label="description"
                type="text"
              />
            </Col>
          </Row>
          {data.amenitiesGenerated && (
            data.amenitiesGenerated.map( ( aG, index ) => (
              <>
                <hr />
                <Row className="mb-3 align-items-center justify-content-center">
                  <Col md={3}>
                    <label className="form-control-label">
                      <Intl id={aG.name} />
                    </label>
                  </Col>
                  <Col md={3}>
                    <TextField
                      field={`amenitiesGenerated[${index}].description`}
                      label="description"
                      type="text"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      field={`amenitiesGenerated[${index}].countMin`}
                      label="minQuantity"
                      type="number"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      field={`amenitiesGenerated[${index}].countReal`}
                      label="quantity"
                      type="number"
                      validate={required}
                    />
                  </Col>
                </Row>
              </>
            ) ) )}
        </>
      )}
    />
  ),
);
