import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthFooter, AuthNavbar } from 'components';

import { publicRoutes } from 'config/Routes';

class Auth extends React.Component {
  componentDidUpdate( e ) {
    if ( e.history.pathname !== e.location.pathname ) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainContent.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    document.body.classList.remove( 'bg-default' );
  }

  getRoutes = ( routes ) => routes.map( ( prop ) => (
    <Route
      path={prop.layout + prop.path}
      component={prop.component}
      key={prop.layout + prop.path}
    />
  ) );

  render() {
    return (
      <>
        <AuthNavbar />
        <div className="main-content main-content-auth" ref={( el ) => { this.mainContent = el; }}>
          <Switch>{this.getRoutes( publicRoutes )}</Switch>

          <AuthFooter />
        </div>
      </>
    );
  }
}

export default Auth;
