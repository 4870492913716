import React from 'react';
import { Route, Switch } from 'react-router';

import { useFetch } from 'hooks';
import AccommodationService from 'api/Accommodation';
import { canModify, canView, canList } from 'config/Ability';
import _ from 'lodash';
import Dashboard from './Dashboard';
import Edit from './Edit';

const Contract = ( {
  propertyData, roomTypeData, paths, match,
} ) => {
  const [accommodationData] = useFetch( {
    initialState: {},
    promise: () => AccommodationService.getGenericAccommodation( match.params.id ),
    conditional: !!match.params.id,
    deps: [match.params.id],
  } );

  const parentIds = accommodationData && accommodationData.id
    ? {
      roomType: _.get( accommodationData, 'roomType.id' ),
      property: _.get( accommodationData, 'roomType.property.id' ),
    } : {};

  return (
    <Switch>
      {canModify( 'accommodation' )
      && (
        <Route
          path={`${match.url}/new`}
          render={( props ) => (
            <Edit
              {...props}
              propertyData={propertyData}
              roomTypeData={roomTypeData}
              accommodationData={accommodationData}
              parentIds={{ ...parentIds, accommodation: match.params.id }}
              paths={{ ...paths, contract: match.url }}
            />
          )}
        />
      )}
      {canView( 'accommodation' )
      && (
        <Route
          path={`${match.url}/edit/:id`}
          render={( props ) => (
            <Edit
              {...props}
              propertyData={propertyData}
              roomTypeData={roomTypeData}
              accommodationData={accommodationData}
              parentIds={{ ...parentIds, accommodation: match.params.id }}
              paths={{ ...paths, contract: match.url }}
            />
          )}
        />
      )}
      {canList( 'accommodation' )
      && (
        <Route
          path={match.url}
          render={( props ) => (
            <Dashboard
              {...props}
              propertyData={propertyData}
              roomTypeData={roomTypeData}
              accommodationData={accommodationData}
              parentIds={{ ...parentIds, accommodation: match.params.id }}
              paths={{ ...paths, contract: match.url }}
            />
          )}
        />
      )}
    </Switch>
  );
};

export default Contract;
