import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { canList, canModify } from 'config/Ability';
import Dashboard from './Dashboard';
import Edit from './Edit';

// TODO: change ACLS

const Notifications = ( { baseUrl } ) => (
  <Switch>
    {canModify( 'communications' ) && (
      <Route
        path={`${baseUrl}/edit/:id`}
        render={( props ) => <Edit {...props} parentPath={baseUrl} />}
      />
    )}
    {canList( 'communications' ) && (
      <Route
        path={baseUrl}
        render={( props ) => <Dashboard {...props} parentPath={baseUrl} />}
      />
    )}

  </Switch>
);

export default Notifications;
