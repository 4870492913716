import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const UPDATE_RATE = gql`
  mutation UpdateRate($name: String!, $rateId: ID!) {
    updateRate(name: $name, rateId: $rateId) {
      ... on BaseError {
        message
      }
      ... on MutationUpdateRateSuccess {
        data {
          success
        }
      }
    }
  }
`;

const updateRate = async ( { name, rateId } ) => {
  const { data } = await client.query( {
    query: UPDATE_RATE,
    variables: {
      name,
      rateId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateRate.message ) {
    return {
      ok: false,
      message: data.updateRate.message,
    };
  }
  const updateSuccessful = data.updateRate.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateRate;
