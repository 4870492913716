import { createReducer, createActions } from 'reduxsauce';
import { storage } from '../../utils';
import { roles } from '../../config/Constants';

export const INITIAL_STATE = {};

const { Types, Creators } = createActions( {
  loginUser: ['data'],
  updateSettings: ['data'],
  logoutUser: null,
} );

const loginUser = ( state, { data } ) => {
  let mainRole = '';
  if ( data.user && data.user.roles.length ) {
    if ( data.user.roles.indexOf( roles.nomadsAdmin.id ) !== -1 ) {
      mainRole = roles.nomadsAdmin.id;
    } else if ( data.user.roles.indexOf( roles.admin.id ) !== -1 ) {
      mainRole = roles.admin.id;
    } else if ( data.user.roles.indexOf( roles.adminReadOnly.id ) !== -1 ) {
      mainRole = roles.adminReadOnly.id;
    } else if ( data.user.roles.indexOf( roles.owner.id ) !== -1 ) {
      mainRole = roles.owner.id;
    }
  }
  return { ...state, ...data, mainRole };
};

const updateSettings = ( state, { data } ) => ( {
  ...state,
  settings: { ...state.settings, ...data },
} );

const logoutUser = () => {
  storage.clear( 'nomads:token' );
  storage.clear( 'nomads:contentLocale' );
  storage.clear( 'persist:nomads' );

  // Only for staging
  if ( window.location.hostname.indexOf( 'staging' ) !== -1 ) {
    window.Intercom( 'shutdown' );
  }

  return {};
};

export const reducer = createReducer( INITIAL_STATE, {
  [Types.LOGIN_USER]: loginUser,
  [Types.UPDATE_SETTINGS]: updateSettings,
  [Types.LOGOUT_USER]: logoutUser,
} );

export const UserTypes = Types;
export default Creators;
