import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import ResourceDashboardView from 'views/Resource/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import { useDelete, useFetchTableData } from 'hooks';
import EmployeeService from 'api/Employee';
import { roles } from 'config/Constants';
import { handlePromise } from 'utils';
import RoleService from 'api/Role';
import UserService from 'api/User';
import { ConfirmationModal, Intl } from 'components';

const mapCodes = {
  1: 'disableModal.confirmation.channels',
  2: 'disableModal.confirmation.tasks',
  3: 'disableModal.confirmation.teams',
  4: 'disableModal.confirmation.shifts',
  5: 'disableModal.confirmation.adminChanel',
  6: 'disableModal.confirmation.requesterTask',
  7: 'disableModal.confirmation.adminTeam',
  8: 'disableModal.confirmation.requesterShift',
  9: 'disableModal.confirmation.bookings',
  10: 'disableModal.confirmation.owner',
};

const ResourceDashboard = ( {
  parentPath,
  userRole,
  defaultFilters,
  toggleErrorAlert,
  toggleLoading,
  updateFilters,
  setFiltersError,
  toggleInfoAlert,
} ) => {
  const [roleOptions, setRoleOptions] = useState( [] );
  const [errorText, setErrorText] = useState( [] );
  const initialReloadError = useRef( true );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [custommerId, setCustommerId] = useState();
  const [openModalDisable, setOpenModalDisable] = useState( false );
  const toggleModalDisable = () => setOpenModalDisable( ( state ) => !state );

  const [errorValues, setErrorValues] = useState( [] );

  const [openModalError, setOpenModalError] = useState( false );
  const toggleModalError = () => setOpenModalError( ( state ) => !state );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: EmployeeService.getEmployees,
    toggleErrorAlert,
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'resources', {
        keyword: params.keyword,
        roles: params.roles,
      } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'resources' );
        initialReloadError.current = false;
      }
    },
    deps: [dataSubmitted],
  } );

  useEffect( () => {
    const getRoles = async () => {
      const [errors, response, responseData] = await handlePromise(
        RoleService.getRoles( { elementsPerPage: 1000 } ),
      );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setRoleOptions( responseData.data );
    };
    getRoles();
  }, [toggleErrorAlert] );

  const deleteResource = useDelete( {
    promise: EmployeeService.deleteEmployee,
    callback: ( id, response ) => {
      if ( response.data.deleted ) {
        setDataSubmitted( new Date().valueOf() );
      } else {
        const errorCodes = _.map( _.get( response, 'data.error', [] ), 'code' );
        const _errorText = _.filter( _.map( errorCodes, ( code ) => mapCodes[code] ),
          ( code ) => Boolean( code ) );
        setErrorText( _errorText );
        setCustommerId( id );
        toggleModalDisable();
      }
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const deleteResourceForce = useDelete( {
    promise: EmployeeService.deleteEmployee,
    callback: ( id, response ) => {
      if ( response.data.updated ) {
        setDataSubmitted( new Date().valueOf() );
      } else {
        const errorCodes = _.map( _.get( response, 'data.error', [] ), ( { code, entities } ) => ( {
          code,
          values: {
            names: _.map( entities, ( entity ) => _.get( entity, 'name' ) ).join(),
            ids: _.map( entities, ( entity ) => _.get( entity, 'id' ) ).join(),
          },
        } ) );
        const _errorValues = _.filter( _.map( errorCodes, ( { code, values } ) => ( {
          text: mapCodes[code],
          values,
        } ) ), ( { text } ) => Boolean( text ) );
        setErrorValues( _errorValues );
        toggleModalError();
        toggleModalDisable();
      }
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  const sendTemporaryPassword = useCallback( async ( { email } ) => {
    toggleLoading( true );

    const [errors, response] = await handlePromise(
      UserService.requestResetPassword( email ),
    );

    if ( !response.ok ) {
      toggleLoading( false );

      return toggleErrorAlert( errors );
    }
    toggleLoading( false );
    toggleInfoAlert( 'successSendTemporaryPassword' );
  }, [toggleInfoAlert, toggleLoading, toggleErrorAlert] );

  return (
    <>
      <ResourceDashboardView
        data={data}
        parentPath={parentPath}
        options={{ roles: roleOptions }}
        isNomadsAdmin={userRole === roles.nomadsAdmin.id}
        isLoading={dataLoading}
        defaultFilters={defaultFilters}
        onFetchData={fetchData}
        onDelete={deleteResource}
        onSendTemporaryPassword={sendTemporaryPassword}
      />
      <ConfirmationModal
        open={openModalDisable}
        title="pleaseNote"
        text={(
          <div style={{ textAlign: 'left', fontSize: 16 }}>
            <Intl id="thisResource" />
            <ul>
              {errorText?.map( ( key ) => (
                <li key={key}>
                  <Intl id={key} />
                </li>
              ) )}
            </ul>
            <div style={{ textAlign: 'center' }}>
              <Intl id="deleteItAnyway" />
            </div>
          </div>
        )}
        onConfirm={() => deleteResourceForce( `${custommerId}?force=true` )}
        onClose={toggleModalDisable}
      />

      <ConfirmationModal
        open={openModalError}
        title="unableDelete"
        text={(
          <div style={{ textAlign: 'left', fontSize: 16 }}>
            <Intl id="cannotBeDeleted" />
            <ul>
              {errorValues?.map( ( { text, values } ) => (
                <li key={text}>
                  <Intl id={text} values={values} />
                </li>
              ) )}
            </ul>
          </div>
        )}
        onConfirm={toggleModalError}
        onClose={toggleModalError}
        warning={false}
        error
        confirmBtnText="accept"
      />

    </>
  );
};

const mapStateToProps = ( { user, filters } ) => ( {
  userRole: user.mainRole,
  defaultFilters: _.get( filters, 'filters.resources' ) || {},
} );
const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( ResourceDashboard );
