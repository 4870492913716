/* eslint-disable import/prefer-default-export */
import { createSeasonRate, deleteSeason, updateSeason } from 'api/Rates/Seasons';
import moment from 'moment';

const createWeekSeasonRate = async ( events = [], dataToSend ) => {
  const seasonToCheck = dataToSend.seasons[0];
  const dateStart = moment( seasonToCheck.startDate );
  const dateEnd = moment( seasonToCheck.lastNight );
  const filterInside = events.filter( ( item ) => dateStart.diff( moment( item.startDate ) ) <= 0
      && dateEnd.diff( moment( item.startDate ) ) >= 0 );

  await Promise.all( filterInside.map( async ( item ) => {
    if ( dateStart.diff( moment( item.startDate ) ) <= 0
    && dateEnd.diff( moment( item.lastNight ) ) >= 0 ) {
      await deleteSeason( item.id );
      return;
    }
    if ( dateStart.diff( moment( item.startDate ) ) > 0
      && dateEnd.diff( moment( item.lastNight ) ) < 0 ) {
      await updateSeason( {
        id: item.id,
        priceOnlyAccommodation: item.priceOnlyAccommodation,
        priceSupplementPerAdditionalPerson: item.priceSupplementPerAdditionalPerson,
        startDate: new Date( item.startDate ).toISOString(),
        lastNight: new Date( dateStart.add( -1, 'days' ) ).toISOString(),
      } );
      await createSeasonRate( {
        rateId: dataToSend.rateId,
        seasons: [
          {
            priceOnlyAccommodation: item.priceOnlyAccommodation,
            priceSupplementPerAdditionalPerson: item.priceSupplementPerAdditionalPerson,
            startDate: new Date( dateEnd.add( 1, 'days' ) ).toISOString(),
            lastNight: new Date( dataToSend.lastNight ).toISOString(),
          },
        ],
      } );
    }
    if ( dateStart.diff( moment( item.startDate ) ) < 0
      && dateEnd.diff( moment( item.startDate ) ) > 0 ) {
      await updateSeason( {
        id: item.id,
        priceOnlyAccommodation: item.priceOnlyAccommodation,
        priceSupplementPerAdditionalPerson: item.priceSupplementPerAdditionalPerson,
        startDate: new Date( item.startDate ).toISOString(),
        lastNight: new Date( dateStart.add( -1, 'days' ) ).toISOString(),
      } );
    }
    if ( dateStart.diff( moment( item.lastNight ) ) < 0
      && dateEnd.diff( moment( item.lastNight ) ) > 0 ) {
      await updateSeason( {
        id: item.id,
        priceOnlyAccommodation: item.priceOnlyAccommodation,
        priceSupplementPerAdditionalPerson: item.priceSupplementPerAdditionalPerson,
        startDate: new Date( dateStart.add( -1, 'days' ) ).toISOString(),
        lastNight: new Date( dataToSend.lastNight ).toISOString(),
      } );
    }
  } ) );

  const { ok, message } = await createSeasonRate( dataToSend );
  if ( !ok ) {
    throw new Error( message );
  }
  return {
    ok, message,
  };
};

export { createWeekSeasonRate };
