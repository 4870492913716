/* eslint-disable import/prefer-default-export */
import { addAccommodationsDiscount, deleteAccomodationsDiscount } from 'api/Discounts/Accomodations';

const updateAccomodationsDiscount = async ( oldIds, dataToSend ) => {
  if ( oldIds.length ) {
    const { ok: okDelete, message: error } = await deleteAccomodationsDiscount( oldIds );
    if ( !okDelete ) {
      throw new Error( error );
    }
  }
  const { ok, message } = await addAccommodationsDiscount( dataToSend );
  if ( !ok ) {
    throw new Error( message );
  }
  return {
    ok, message,
  };
};

export { updateAccomodationsDiscount };
