import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _find from 'lodash/find';
import _get from 'lodash/get';

import EmergencyNumberEditView from 'views/EmergencyNumbers/Edit';
import SettingsActions from 'store/reducers/Settings';
import AppSurvivalTipService from 'api/appSurvivalTip';
import TranslationService from 'api/Translation';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import useFetch from 'hooks/useFetch';
import useSubmit from 'hooks/useSubmit';

const translations = true;
const type = 'emergency';

const EmergencyNumberEdit = ( {
  toggleLoading,
  toggleErrorAlert,
  toggleInfoAlert,
  entityId,
  openModal,
  onClose,
  onReload,
} ) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  const [countries, isLoadingCountries] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    reInit: true,
    conditional: openModal,
    deps: [openModal],
  } );

  const countryIso = _get( _find( countries, { id: selectedCountry } ), 'iso' );

  const [states, isLoadingStates] = useFetch( {
    initialState: [],
    promise: () => StateService.getStates( countryIso ),
    toggleErrorAlert,
    reInit: true,
    conditional: openModal && !!countryIso,
    deps: [openModal, countryIso],
  } );

  const stateIso = _get( _find( states, { id: selectedState } ), 'iso' );

  const [cities, isLoadingCities] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( countryIso, stateIso ),
    toggleErrorAlert,
    reInit: true,
    conditional: openModal && !!countryIso && !!stateIso,
    deps: [openModal, countryIso, stateIso],
  } );

  const [data, isLoading] = useFetch( {
    initialState: {},
    promise: () => AppSurvivalTipService.getItem( entityId ),
    translationPromise: () => TranslationService.getAppSurvivalTips( entityId ),
    format: ( dataToFormat ) => {
      const countryId = _get( dataToFormat, 'country.id' );
      const stateId = _get( dataToFormat, 'state.id' );
      setSelectedState( stateId );
      setSelectedCountry( countryId );
      return dataToFormat;
    },
    toggleErrorAlert,
    reInit: true,
    conditional: openModal && !!entityId,
    deps: [openModal, entityId],
  } );

  const handleOnChangeCountry = useCallback( ( id ) => {
    setSelectedState();
    setSelectedCountry( id );
  }, [] );

  const handleOnChangeState = useCallback( ( id ) => {
    setSelectedState( id );
  }, [] );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( entityId
      ? AppSurvivalTipService.updateItem( entityId, dataToSend )
      : AppSurvivalTipService.saveItem( dataToSend ) ),
    format: ( dataToSend ) => ( {
      ...dataToSend,
      state: undefined,
      type,
    } ),
    translations,
    toggleInfoAlert,
    toggleLoading,
    callback: onReload,
    deps: [openModal, entityId],
  } );

  const handleOnClose = useCallback( () => {
    setSelectedState();
    setSelectedCountry();
    onClose();
  }, [onClose] );

  return (
    <EmergencyNumberEditView
      entityId={entityId}
      data={data}
      open={openModal}
      isLoading={isLoading}
      onClose={handleOnClose}
      onSubmit={submitForm}
      countries={countries}
      loadingCountries={isLoadingCountries}
      onChangeCountry={handleOnChangeCountry}
      states={states}
      loadingStates={isLoadingStates}
      onChangeState={handleOnChangeState}
      cities={cities}
      loadingCities={isLoadingCities}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( EmergencyNumberEdit );
