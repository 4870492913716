// import _ from 'lodash';

import General from 'containers/Property/Edit/General';
import CommunityRule from 'containers/Property/Edit/CommunityRule/Dashboard';
// import CustomField from 'containers/Property/Edit/CustomField';
import TravelReport from 'containers/Property/Edit/TravelReport';
import CheckInInstruction from 'containers/Property/Edit/CheckInInstructions';

export default [
  {
    name: 'general',
    path: '',
    component: General,
    exact: true,
  },
  {
    name: 'communityRules',
    path: '/community-rules',
    component: CommunityRule,
  },
  // {
  //   name: 'smartLocker',
  //   path: '/smart-locker',
  //   component: CustomField,
  //   extraData: {
  //     type: 'customFields',
  //     filter: ( { category } ) => category === 'smartlockers',
  //     title: 'smartLocker',
  //   },
  // },
  // {
  //   name: 'travelPlugin',
  //   path: '/travel-plugin',
  //   component: CustomField,
  //   extraData: {
  //     type: 'customFields',
  //     filter: { category: 'estr' },
  //     title: 'travelPlugin',
  //   },
  // },
  {
    name: 'travelReport',
    path: '/travel-report',
    component: TravelReport,
  },
  // {
  //   name: 'customFields',
  //   path: '/custom-fields',
  //   component: CustomField,
  //   extraData: {
  //     type: 'customFields',
  //     filter: ( item ) => !_.startsWith( item.name, 'homeselect_communityRule' )
  //       && item.category !== 'estr'
  //       && item.category !== 'smartlockers',
  //     title: 'customFields',
  //   },
  // },
  {
    name: 'checkInInstruction',
    path: '/check-in-instructions',
    component: CheckInInstruction,
  },
];
