import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ContractDashboardView from 'views/Property/RoomType/Accommodation/Contract/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
import ContractService from 'api/Contract';

const ContractDashboard = ( {
  parentIds, propertyData, roomTypeData, accommodationData, paths, userCurrency,
  toggleErrorAlert, toggleLoading,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => ContractService.getContracts(
      parentIds.property, parentIds.roomType, parentIds.accommodation, params,
    ),
    toggleErrorAlert,
    initialLoading: true,
    conditional: !!parentIds.property && !!parentIds.roomType,
    deps: [dataSubmitted, parentIds.property, parentIds.roomType],
  } );

  const deleteEntity = useDelete( {
    promise: ( id ) => ContractService.deleteContract(
      parentIds.property, parentIds.roomType, parentIds.accommodation, id,
    ),
    callback: () => { setDataSubmitted( new Date().valueOf() ); },
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <ContractDashboardView
      data={data}
      paths={paths}
      propertyName={propertyData ? propertyData.name : null}
      roomTypeName={roomTypeData ? roomTypeData.name : null}
      accommodationName={accommodationData.name}
      userCurrency={userCurrency}
      isLoading={dataLoading}
      onFetchData={fetchData}
      onDelete={deleteEntity}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ContractDashboard );
