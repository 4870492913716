import React from 'react';
import {
  Container, Button, Row, Col,
} from 'reactstrap';
import classnames from 'classnames';
import _get from 'lodash/get';

import {
  SimpleHeader, Intl, DaterangePickerBtn, EditCard, AsyncSelect, TextField, AddBtn,
} from 'components';
import { getResourceName } from 'utils';
import Calendar from './Calendar';

const ContainerCalendar = ( {
  data,
  dataReport,
  isLoading,
  isLoadingDataReports,
  dateRange,
  onContextMenu,
  showReport,
  onClickShowReport,
  onChangeFilters,
  onChangeRange,
  onSelectToday,
  onSelectThisWeek,
  onSelectThisMonth,
  onClickCell,
  onAddBulkShift,
  onAddShift,
  onFetchTeams,
  onFetchEmployees,
  onPressDelete,
  onPressMultiShift,
} ) => (
  <>
    <SimpleHeader
      title="shiftSchedule"
      rightContent={(
        <div className="d-flex align-items-center flex-wrap">
          <div className="d-flex align-items-center flex-wrap mt-1 mb-1">
            <TextField
              formGroupClass="m-0"
              placeholder="Search"
              appendIconClass="fa-search"
            />
            <Button
              color={showReport ? 'secondaryColor' : 'primary'}
              className="mt-md-0 ml-2 mr-0"
              onClick={onClickShowReport}
            >
              <i className="fas fa-file-invoice mr-1" />
              {showReport
                ? <Intl id="hideReport" />
                : <Intl id="showReport" />}
            </Button>

          </div>

          <DaterangePickerBtn
            style={{
              height: 47,
            }}
            position="right"
            initialValue={dateRange}
            key={dateRange}
            onChange={onChangeRange}
            sideBarOptions={[
              {
                text: <Intl id="today" />,
                id: 'today',
                onClick: onSelectToday,
              },
              {
                text: <Intl id="thisWeek" />,
                id: 'thisWeek',
                onClick: onSelectThisWeek,
              },
              {
                text: <Intl id="thisMonth" />,
                id: 'thisMonth',
                onClick: onSelectThisMonth,
              },
            ]}
          />
        </div>
          )}
    />

    <Container
      fluid
      className={classnames( { 'app-component-loading': isLoading || isLoadingDataReports } )}
    >
      <EditCard>
        <Row className="d-flex align-items-center ">
          <Col md={3}>
            <AsyncSelect
              label="team"
              defaultOptions
              loadOptions={onFetchTeams}
              isClearable
              onChange={( value ) => onChangeFilters( 'teamId', _get( value, 'value' ) )}
            />
          </Col>
          <Col md={3}>
            <AsyncSelect
              label="resource"
              defaultOptions
              loadOptions={onFetchEmployees}
              isClearable
              labelKey={getResourceName}
              onChange={( value ) => onChangeFilters( 'employeeId', _get( value, 'value' ) )}
            />
          </Col>
          <Col md={6} className="d-flex  justify-content-end">
            <AddBtn title="bulkAssigment" onClick={onAddBulkShift} />
            <AddBtn title="newShift" onClick={onAddShift} />
          </Col>
        </Row>
      </EditCard>
      <Calendar
        dateRange={dateRange}
        data={data}
        dataReport={dataReport}
        onContextMenu={onContextMenu}
        showReport={showReport}
        onClickCell={onClickCell}
        onPressDelete={onPressDelete}
        onPressMultiShift={onPressMultiShift}
      />
    </Container>
  </>
);
export default ContainerCalendar;
