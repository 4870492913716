import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import {
  SimpleHeader, AsyncTable, AddBtn, TableActionsBtns,
  TableEditBtn, TableGenericFilter, CanAccess, Intl,
} from 'components';
import { canView, canModify } from 'config/Ability';

export default React.memo( ( {
  data, isLoading, defaultFilters, onFetchData, onOpenEditModal, onDelete,
} ) => {
  const canViewProp = canView( 'amenity' );
  const canEditDelete = canModify( 'amenity' );

  return (
    <>
      <SimpleHeader
        title="amenitiesSetting"
      />
      <Container fluid>
        <AsyncTable
          id="amenities"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          onFetchData={onFetchData}
          rightContent={<CanAccess I="add" a="amenity"><AddBtn title="newAmenitySetting" onClick={onOpenEditModal} /></CanAccess>}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter
                  filters={filters}
                  setFilter={setFilter}
                />
              </Col>
            </Row>
          )}
          columns={[
            {
              Header: 'name',
              accessor: 'name',
              Cell: ( { cell } ) => (
                canEditDelete || canViewProp ? (
                  <TableEditBtn
                    title={cell.value}
                    onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
                  />
                ) : cell.value
              ),
            },
            {
              Header: 'type',
              accessor: 'type',
              Cell: ( { cell } ) => <Intl id={cell.row.original.type} />,
            },
            {
              Header: 'description',
              accessor: 'description',
            },
            {
              Header: 'estimatedValue',
              accessor: 'cost',
            },

            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns actions={[
                    {
                      isDelete: true,
                      onClick: ( cb ) => onDelete( cell.value, cb ),
                    },
                  ]}
                  />
                ),
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );
