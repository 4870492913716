import React from 'react';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import _ from 'lodash';
import {
  AsyncTable,
  TableGenericFilter,
  TableToggler,
  Intl,
  Button,
  TableSelectFilter,
  DaterangePickerBtn,
} from 'components';
import {
  taskPriorities,
  taskStatuses,
  taskTypes as typeOfTaskTypes,
} from 'config/Constants';
import { getResourceName } from 'utils';
import getStatusColor from 'config/Constants/StatusColor';

const statusColor = getStatusColor( 'tasks' );

const TasksClientEditView = ( {
  isOwner,
  data,
  defaultFilters,
  isLoading,
  initialDates,
  onFetchData,
  onDownload,
  onDateChanged,
  onFetchProperties,
  onFetchTeams,
  onFetchAccommodations,
  onFetchEmployees,
  taskTypes,
  isLoadingTaskTypes,
} ) => (
  <AsyncTable
    id="tasks"
    data={data.data}
    total={data.elementsTotal}
    isLoading={isLoading}
    defaultFilters={defaultFilters}
    disableSorting
    onFetchData={onFetchData}
    customFilters={( { filters, setFilter } ) => (
      <>
        <Row className="mt-4">
          <Col md={8} lg={4}>
            <TableGenericFilter filters={filters} setFilter={setFilter} />
          </Col>
          <Col xs="auto" className="pl-0">
            <DaterangePickerBtn
              position="right"
              initialValue={initialDates}
              key={initialDates}
              onChange={onDateChanged}
            />
          </Col>

          <TableToggler />
        </Row>
        <Row>
          <Col
            lg={12}
            className="d-flex justify-content-end position-absolute top-0"
          >
            <Button
              extraClass="mr-4"
              title="download"
              onClick={() => onDownload( filters )}
            />
          </Col>
        </Row>
      </>
    )}
    customToggleFilters={( { filters, setFilter } ) => (
      <>
        <Row>
          <Col md={8} lg={3} className="pb-3">
            <TableSelectFilter
              name="priority"
              label="priority"
              placeholder="all"
              filters={filters}
              translateOptions
              isClearable
              options={_.map( taskPriorities, ( priority ) => ( {
                id: priority.id,
                name: priority.name,
                icon: (
                  <i
                    className={classnames( priority.iconClass, 'text-2xsm' )}
                  />
                ),
              } ) )}
              setFilter={setFilter}
            />
          </Col>
          <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
            <TableSelectFilter
              name="status"
              label="status"
              placeholder="all"
              filters={filters}
              translateOptions
              isClearable
              options={taskStatuses}
              setFilter={setFilter}
            />
          </Col>
          <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
            <TableSelectFilter
              name="taskType"
              label="category"
              placeholder="all"
              filters={filters}
              isClearable
              setFilter={setFilter}
              options={taskTypes}
              optgroup
              isLoading={isLoadingTaskTypes}
            />
          </Col>
          <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
            <TableSelectFilter
              name="type"
              label="type"
              placeholder="all"
              filters={filters}
              translateOptions
              isClearable
              options={typeOfTaskTypes}
              setFilter={setFilter}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={8} lg={3}>
            <TableSelectFilter
              name="property"
              label="property"
              placeholder="all"
              filters={filters}
              isClearable
              setFilter={setFilter}
              defaultOptions
              loadOptions={onFetchProperties}
            />
          </Col>
          <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
            <TableSelectFilter
              name="accommodation"
              label="accommodation"
              placeholder="all"
              filters={filters}
              isClearable
              defaultOptions
              loadOptions={onFetchAccommodations}
              setFilter={setFilter}
            />
          </Col>
          <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
            <TableSelectFilter
              name="team"
              label="team"
              placeholder="all"
              filters={filters}
              isClearable
              defaultOptions
                // labelKey={getResourceName}
              loadOptions={onFetchTeams}
              setFilter={setFilter}
            />
          </Col>
          <Col md={8} lg={3} className="pl-lg-0 pt-1 pb-3 pt-lg-0">
            <TableSelectFilter
              name="assigned"
              label="assigned"
              placeholder="all"
              filters={filters}
              isClearable
              defaultOptions
              labelKey={getResourceName}
              loadOptions={onFetchEmployees}
              setFilter={setFilter}
            />
          </Col>
        </Row>
      </>
    )}
    getRowProps={( row ) => ( {
      className: row.original.type === 'issue' ? 'bg-light-red' : '',
    } )}
    columns={[
      {
        Header: null,
        accessor: 'priority',
        maxWidth: 30,
        Cell: ( { cell } ) => {
          const priority = _.find( taskPriorities, { id: cell.value } );
          return priority ? (
            <span>
              <i
                className={classnames( priority.iconClass, 'text-2xsm' )}
                id={`tooltip-supervisor-${cell.row.original.id}`}
              />
              <UncontrolledTooltip
                delay={0}
                target={`tooltip-supervisor-${cell.row.original.id}`}
              >
                <Intl id={priority.name} />
              </UncontrolledTooltip>
            </span>
          ) : null;
        },
      },
      {
        Header: '#',
        accessor: 'number',
        Cell: ( { cell } ) => {
          const title = `${cell.row.original.id.slice(
            cell.row.original.id.length - 6,
            cell.row.original.id.length,
          )}`;
          return (
            isOwner ? title : (
              <Link to={`/operations/tasks/${cell.row.original.id}`}>
                {title}
              </Link>
            )
          );
        },
      },
      {
        Header: 'accommodation',
        accessor: 'accommodation',
        Cell: ( { cell } ) => cell.value?.name || '-',
      },
      {
        Header: 'category',
        accessor: 'taskType.name',
      },
      {
        Header: 'scheduledFor',
        accessor: 'date',
        Cell: ( { cell } ) => ( cell.value
          ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' )
          : '' ),
      },
      {
        Header: 'status',
        accessor: 'status',
        Cell: ( { cell } ) => {
          const status = _.find( taskStatuses, { id: cell.value } );
          return status && status.name ? (
            <span
              className={`badge badge-${statusColor( status.name )} badge-pill`}
            >
              <Intl id={status.name} />
            </span>
          ) : null;
        },
      },
    ]}
  />
);

export default TasksClientEditView;
