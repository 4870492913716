import React, { useState } from 'react';

import {
  TextField, Form, FormActionsBtns, Calendar,
} from 'components';
import { required } from 'config/InputErrors';
import '../../../calendar.css';

export default React.memo( ( {
  visible, events, onOpenEditItemModal, onSubmit, updateDates,
} ) => {
  if ( !visible ) {
    return null;
  }
  const [showForm, setShowForm] = useState( false );

  const handleCustomButtonClick = () => {
    setShowForm( !showForm );
  };

  const handleSubmit = ( formData, form ) => {
    onSubmit( formData, form );
    handleCustomButtonClick();
  };

  const handleDatesSet = ( arg ) => {
    const currentStartDate = arg.view.currentStart;
    const currentEndDate = arg.view.currentEnd;
    updateDates( currentStartDate, currentEndDate );
  };

  return (
    <>
      {showForm && (
      <Form
        onSubmit={handleSubmit}
        showErrorsInFooter
        body={() => (
          <>
            <TextField
              type="number"
              label="priceOnlyAccommodation"
              field="priceOnlyAccommodation"
              validate={required}
            />
            <TextField
              type="number"
              label="priceSupplementPerAdditionalPerson"
              field="priceSupplementPerAdditionalPerson"
            />
            <FormActionsBtns />
          </>
        )}
      />
      )}
      <Calendar
        events={events}
        onOpenEditItemModal={onOpenEditItemModal}
        handleDatesSet={handleDatesSet}
        showForm={showForm}
        handleCustomButtonClick={handleCustomButtonClick}
        typeCalendar="month"
      />
    </>
  );
} );
