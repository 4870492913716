import React from 'react';
import {
  EditCard, Intl,
} from 'components';
import RoomExpensesTable from 'containers/Property/RoomType/Accommodation/Edit/RoomExpenses/Dashboard/RoomExpensesTable';
import TaskExpensesTable from 'containers/Property/RoomType/Accommodation/Edit/RoomExpenses/Dashboard/TaskExpensesTable';
import { canModify } from 'config/Ability';

export default React.memo( ( { entityId, name } ) => (
  <EditCard cardClass="card-sec-bottom">
    <>
      <h3 className="mb-1">
        <Intl id={canModify( 'accommodation' ) ? 'editAccommodation' : 'accommodation'} />
        {` ${name}`}
      </h3>
      <hr className="mt-3 mb-4" />
      <h3><Intl id="apartmentExpenses" /></h3>
    </>
    <RoomExpensesTable entityId={entityId} />
    <TaskExpensesTable entityId={entityId} />
  </EditCard>
) );
