import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import EmailSettingsService from 'api/Settings/Email';
import TranslationService from 'api/Translation';
import formatEmail from 'utils/email';
import PreviewView from 'views/Booking/Edit/Notifications/Preview';
import SettingsActions from 'store/reducers/Settings';
import useFetch from 'hooks/useFetch';

const Preview = ( {
  open,
  onClose,
  dataTempalte,
  propertyManager,
  toggleErrorAlert,
} ) => {
  const [settings, settingsLoading] = useFetch( {
    initialState: {},
    promise: () => EmailSettingsService.getEmailSettings( propertyManager.id ),
    translationPromise: ( responsePromise ) => {
      const id = _get( responsePromise, 'id', '' );
      return TranslationService.getEmailSettingsTranslations( id );
    },
    toggleErrorAlert,
    conditional: !!propertyManager.id,
    deps: [propertyManager.id],
  } );

  const formatData = ( data ) => {
    const formattedData = { ...data };
    if ( typeof data.banner === 'object' ) {
      formattedData.banner = URL.createObjectURL( data.banner );
    }
    if ( data.bodyEmail ) {
      formattedData.bodyEmail = formatEmail( data.bodyEmail );
    }
    return formattedData;
  };

  return (
    <PreviewView
      open={open}
      onClose={onClose}
      isLoading={settingsLoading}
      data={formatData( dataTempalte )}
      settings={settings}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( mapStateToProps, mapDispatchToProps )( Preview );
