import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const GET_DISCOUNT = gql`
  query Discount($input: DetailDiscountInput!) {
    discount(input: $input) {
      ... on BaseError {
        message
      }
      ... on QueryDiscountSuccess {
        data {
          accommodationsNumber
          id
          isActive
          lastModification
          name
          validUntil
        }
      }
    }
  }
`;

const getDiscount = async ( { discountId } ) => {
  const { data } = await client.query( {
    query: GET_DISCOUNT,
    variables: {
      input: {
        discountId,
      },
    },
    fetchPolicy: 'no-cache',
  } );
  if ( data.discount.message ) {
    return {
      ok: false,
      message: data.discount.message,
    };
  }
  const discount = data.discount.data;
  return {
    ok: true,
    status: 200,
    data: { data: discount },
  };
};

export default getDiscount;
