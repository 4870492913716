import React from 'react';

import { TextField, ModalForm } from 'components';
import { required } from 'config/InputErrors';

export default React.memo( ( {
  data, cityId, isLoading, open, onSubmit, onClose,
} ) => (
  <>
    <ModalForm
      title={cityId ? 'editCity' : 'newCity'}
      initialValues={data}
      isLoading={isLoading}
      open={open}
      size="md"
      showErrorsInFooter
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <TextField
        field="name"
        validate={required}
        label="name"
      />
    </ModalForm>
  </>
) );
