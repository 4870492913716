/* eslint-disable import/prefer-default-export */
const filterBuilder = ( {
  keyword, rateId,
} ) => {
  const filters = [];
  if ( keyword ) {
    filters.push( {
      field: 'name',
      operator: 'CONTAINS',
      value: keyword,
    } );
  }
  if ( rateId ) {
    filters.push( {
      field: 'rateId',
      operator: 'EQUAL',
      value: rateId,
    } );
  }
  return filters;
};

export { filterBuilder };
