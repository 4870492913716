export default function formatActions( data ) {
  if ( !data ) {
    return [];
  }

  let tasks = [];
  let clients = [];

  if ( data.task ) {
    tasks = Object.entries( data.task ).map( ( task ) => ( {
      title: task[1],
      type: 'operative_employee',
    } ) );
  }

  if ( data.client ) {
    clients = Object.entries( data.client ).map( ( client ) => ( {
      title: client[1],
      type: 'operative_client',
    } ) );
  }

  return [...tasks, ...clients];
}
