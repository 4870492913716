/* eslint-disable import/prefer-default-export */
const filterBuilder = ( {
  keyword, discountId,
} ) => {
  const filters = [];
  if ( keyword ) {
    filters.push( {
      field: 'name',
      operator: 'CONTAINS',
      value: keyword,
    } );
  }
  if ( discountId ) {
    filters.push( {
      field: 'discountId',
      operator: 'EQUAL',
      value: discountId,
    } );
  }
  return filters;
};

export { filterBuilder };
