import React from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable, AddBtn, SimpleHeader, TableGenericFilter, TableToggler, Intl,
  TableEditBtn, TableActionsBtns, TableSelectFilter, CanAccess, TableCellList,
} from 'components';
import {
  periodicity,
} from 'config/Constants';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  data, isLoading, defaultFilters,
  onFetchData, onOpenEditModal, onDelete, onFetchProperties,
  onFetchAccommodations, taskTypes, isLoadingTaskTypes,
} ) => {
  const canViewEditDelete = canModify( 'task' );

  return (
    <>
      <SimpleHeader
        title="periodicTasks"
      />

      <Container fluid>

        <AsyncTable
          id="tasks"
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          customFiltersOpened={defaultFilters && ( defaultFilters.priority || defaultFilters.status
            || defaultFilters.taskType || defaultFilters.type || defaultFilters.property
            || defaultFilters.accommodation )}
          rightContent={<CanAccess I="add" a="task"><AddBtn title="newPeriodicTask" onClick={onOpenEditModal} /></CanAccess>}
          onFetchData={onFetchData}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={8} lg={4}>
                <TableGenericFilter
                  filters={filters}
                  setFilter={setFilter}
                />
              </Col>

              <TableToggler />
            </Row>
          )}
          customToggleFilters={( { filters, setFilter } ) => (
            <>
              <Row>
                <Col md={8} lg={3}>
                  <TableSelectFilter
                    name="properties"
                    label="property"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    setFilter={setFilter}
                    defaultOptions
                    loadOptions={onFetchProperties}
                  />
                </Col>
                <Col md={8} lg={3} className="pl-lg-0 pt-1 pt-lg-0">
                  <TableSelectFilter
                    name="accommodations"
                    label="accommodation"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    defaultOptions
                    loadOptions={onFetchAccommodations}
                    setFilter={setFilter}
                  />
                </Col>
                <Col md={8} lg={3} className="pl-lg-0 pt-1 pt-lg-0">
                  <TableSelectFilter
                    name="taskType"
                    label="category"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    setFilter={setFilter}
                    options={taskTypes}
                    optgroup
                    isLoading={isLoadingTaskTypes}
                  />
                </Col>
                <Col md={8} lg={3} className="pl-lg-0 pt-1 pt-lg-0">
                  <TableSelectFilter
                    name="periodicity"
                    label="periodicity"
                    placeholder="all"
                    filters={filters}
                    isClearable
                    setFilter={setFilter}
                    options={periodicity}
                    translateOptions
                  />
                </Col>
              </Row>
            </>
          )}
          getRowProps={( row ) => ( { className: row.original.type === 'issue' ? 'bg-light-red' : '' } )}
          columns={[
            {
              Header: '#',
              accessor: 'number',
              Cell: ( { cell } ) => {
                const title = `${cell.row.original.id.slice( cell.row.original.id.length - 6, cell.row.original.id.length )}`;
                return (
                  canViewEditDelete ? (
                    <TableEditBtn
                      className="text-uppercase"
                      title={title}
                      onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
                    />
                  ) : <span className="text-uppercase">{title}</span>
                );
              },
            },
            {
              Header: 'category',
              accessor: 'taskType.name',
            },
            {
              Header: 'periodicity',
              accessor: 'periodicity',
              Cell: ( { cell } ) => <Intl id={cell.value} />,
            },
            {
              Header: 'properties',
              accessor: 'properties',
              Cell: ( { cell } ) => <TableCellList list={cell.value} limit={5} />,
            },
            {
              Header: 'accommodations',
              accessor: 'accommodations',
              Cell: ( { cell } ) => <TableCellList list={cell.value} limit={5} />,
            },

            {
              Header: 'from',
              accessor: 'from',
              Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
            },
            {
              Header: 'till',
              accessor: 'till',
              Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
            },
            canViewEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => {
                  const actions = [];

                  actions.push( {
                    label: 'edit',
                    onClick: () => onOpenEditModal( { id: cell.value } ),
                  } );

                  actions.push( {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  } );
                  return (
                    <TableActionsBtns actions={actions} />
                  );
                },
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );
