import { create } from 'apisauce';

const nukiApi = create( {
  baseURL: process.env.REACT_APP_NUKI_API_URL,
  timeout: 60000,
} );

nukiApi.addResponseTransform( ( response ) => {
  const { ok } = response;

  if ( !ok ) {
    response.errors = 'apiError';
  }
} );

export default nukiApi;
