import api from 'config/Api/Api';

const baseEndpoint = '/bookings-items';

export default {
  getBookingItem( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  saveNote( id, data ) {
    return api.post( `${baseEndpoint}/${id}/notes`, data );
  },
  updateNote( id, noteId, data ) {
    return api.put( `${baseEndpoint}/${id}/notes/${noteId}`, data );
  },
  deleteNote( id, noteId ) {
    return api.delete( `${baseEndpoint}/${id}/notes/${noteId}` );
  },
  getBookingInfoPayment( id ) {
    return api.get( `${baseEndpoint}/${id}/info-payments` );
  },
};
