import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { ModalForm, TextField } from 'components';
import { required } from 'config/InputErrors';

const NotesDetailsModal = ( {
  modalOpened, handleClose, onSubmit, selectedNote,
} ) => (
  <ModalForm
    initialValues={selectedNote}
    title={_.isEmpty( selectedNote ) ? 'newNote' : 'editNote'}
    showErrorsInFooter
    isLoading={false}
    open={modalOpened}
    onClose={handleClose}
    bodyClassName="overflow-visible"
    onSubmit={onSubmit}
  >
    <TextField
      label="notes"
      field="content"
      type="textarea"
      rows={4}
      validate={required}
    />
  </ModalForm>
);

const mapStateToProps = ( { user } ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
} );

export default connect( mapStateToProps, null )( NotesDetailsModal );
