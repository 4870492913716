import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = ( propertyId, roomTypeId ) => `/properties/${propertyId}/room-types/${roomTypeId}/accommodations`;
const baseEndpointAll = '/properties/all/room-types/all/accommodations';

export default {

  getAllAccommodationsAll( params ) {
    return api.get( `${baseEndpointAll}/all`, params );
  },

  getAllAccommodationsByProperty( propertyId, params ) {
    return api.get( `/properties/${propertyId}/room-types/all/accommodations`, params );
  },

  getAccommodations( propertyId, roomTypeId, params ) {
    return api.get( baseEndpoint( propertyId, roomTypeId ), params );
  },

  getAllAccommodations( params ) {
    return api.get( baseEndpointAll, params );
  },

  getAccommodation( propertyId, roomTypeId, id ) {
    return api.get( `${baseEndpoint( propertyId, roomTypeId )}/${id}` );
  },

  getGenericAccommodation( id ) {
    return api.get( `${baseEndpointAll}/${id}` );
  },

  saveAccommodation( propertyId, roomTypeId, data ) {
    return api.post( `${baseEndpoint( propertyId, roomTypeId )}`, data );
  },

  updateAccommodation( propertyId, roomTypeId, id, data ) {
    return api.put( `${baseEndpoint( propertyId, roomTypeId )}/${id}`, data );
  },

  deleteAccommodation( propertyId, roomTypeId, id ) {
    return api.delete( `${baseEndpoint( propertyId, roomTypeId )}/${id}` );
  },
  uploadMainImage( propertyId, roomTypeId, id, data ) {
    return api.post( `${baseEndpoint( propertyId, roomTypeId )}/${id}/main-image`, formatFormDataBody( data ) );
  },
  uploadInstructionsFile( propertyId, roomTypeId, id, data ) {
    return api.post( `${baseEndpoint( propertyId, roomTypeId )}/${id}/checkin-instruction`,
      formatFormDataBody( data ) );
  },

};
