import api from 'config/Api/Api';

import { formatFormDataBody } from 'utils';

const baseEndpoint = ( serviceId ) => `/services/${serviceId}/image`;

export default {
  saveImage( serviceId, data ) {
    return api.post( baseEndpoint( serviceId ), formatFormDataBody( data ) );
  },

  deleteImage( serviceId, id ) {
    return api.delete( `${baseEndpoint( serviceId )}/${id}` );
  },
};
