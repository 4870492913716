import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  SimpleHeader,
  AsyncTable,
  AddBtn,
  TableActionsBtns,
  CanAccess,
} from 'components';
import { canEditViewDelete } from 'config/Ability';

//  TODO: Update ACLs
export default React.memo(
  ( {
    data,
    isLoading,
    defaultFilters,
    onFetchData,
    onDelete,
    parentPath,
  } ) => {
    const { canView, canEdit, canDelete } = canEditViewDelete( 'settings_custom_fields' );
    return (
      <>
        <SimpleHeader title="contracts" />
        <Container fluid>
          <AsyncTable
            id="contracts"
            data={data.data}
            total={data.elementsTotal}
            isLoading={isLoading}
            disableSorting
            defaultFilters={defaultFilters}
            onFetchData={onFetchData}
            rightContent={(
              <CanAccess I="add" a="settings_custom_fields">
                <AddBtn title="newContract" route={`${parentPath}/new`} />
              </CanAccess>
          )}
            columns={[
              {
                Header: 'id',
                accessor: 'id',
                Cell: ( { cell } ) => ( canEdit || canView ? (
                  <Link to={`${parentPath}/edit/${cell.value}`}>
                    {cell.value.slice( -6 )}
                  </Link>
                )
                  : cell.value
                ),
              },
              {
                Header: 'country',
                accessor: 'country.name',
              },
              {
                Header: 'city',
                accessor: 'city.name',
              },
              {
                Header: 'property',
                accessor: 'property.name',
              },
              {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns
                    actions={[
                      {
                        isDelete: canDelete,
                        onClick: ( cb ) => onDelete( cell.value, cb ),
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </Container>
      </>
    );
  },
);
