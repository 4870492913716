import React from 'react';
import { Container } from 'reactstrap';

import {
  SimpleHeader, LocalTable, AddBtn, TableEditBtn,
} from 'components';

export default React.memo( ( {
  countryId, stateName, countryName, data, isLoading, onOpenEditModal,
} ) => (
  <>
    <SimpleHeader
      title="cities"
      parentLinks={[
        { name: countryName, translate: false, href: '/countries' },
        { name: stateName, translate: false, href: `/countries/${countryId}/states` },
      ]}
    />
    <Container fluid>
      <LocalTable
        data={data}
        isLoading={isLoading}
        rightContent={<AddBtn title="newCity" onClick={onOpenEditModal} />}
        columns={[
          {
            Header: 'name',
            accessor: 'name',
            Cell: ( { cell } ) => (
              <TableEditBtn
                title={cell.value}
                onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
              />
            ),
          },
        ]}
      />
    </Container>
  </>
) );
