import React from 'react';
import {
  Row, Col, Container, Button,
} from 'reactstrap';
import moment from 'moment';
import _get from 'lodash/get';

import {
  AsyncTable, SimpleHeader, Intl,
  TableEditBtn, TableActionsBtns, TableSelectFilter, DaterangePickerBtn,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  data, isLoading, initialDates, defaultFilters,
  onFetchData, onOpenEditModal, onDelete, onDateChanged,
  onFetchAccommodations,
  onTodaysAccessRequest,
} ) => {
  const canEditDelete = canModify( 'task' );

  return (
    <>
      <SimpleHeader
        title="accessRequest"
        rightContent={(
          <>
            <Button
              color="primary"
              className="mt-2 mt-md-0 ml-2 mr-0"
              onClick={onTodaysAccessRequest}
            >
              <i className="th-icon-calendar mr-2" style={{ position: 'relative', top: 3 }} />
              <Intl id="today" />
            </Button>

            <DaterangePickerBtn
              position="right"
              initialValue={initialDates}
              key={initialDates}
              onChange={onDateChanged}
            />
          </>
        )}
      />

      <Container fluid>
        <AsyncTable
          data={data.data}
          total={data.elementsTotal}
          isLoading={isLoading}
          disableSorting
          defaultFilters={defaultFilters}
          customFilters={( { filters, setFilter } ) => (
            <Row>
              <Col md={4}>
                <TableSelectFilter
                  name="accommodationId"
                  label="accommodation"
                  placeholder="all"
                  filters={filters}
                  isClearable
                  defaultOptions
                  loadOptions={onFetchAccommodations}
                  setFilter={setFilter}
                />
              </Col>
            </Row>
          )}
          containerClassName="shadow-none"
          onFetchData={onFetchData}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => {
                const title = `${cell.value.slice( 0, 6 )}`;
                return (
                  canEditDelete ? (
                    <TableEditBtn
                      className="text-uppercase"
                      title={title}
                      onClick={() => onOpenEditModal(
                        {
                          taskId: _get( cell, 'row.original.task.id' ),
                          id: _get( cell, 'value' ),
                        },
                      )}
                    />
                  ) : <span className="text-uppercase">{title}</span>
                );
              },
            },
            {
              Header: 'date',
              accessor: 'date',
              Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' ) : '' ),
            },
            {
              Header: <span className="text-transform-none"><Intl id="customerComments" /></span>,
              accessor: 'customerComments',
            }, {
              Header: <span className="text-transform-none"><Intl id="comments" /></span>,
              accessor: 'comments',
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => ( cell.value ? <Intl id={cell.value} /> : '' ),
            },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => (
                  <TableActionsBtns actions={[{
                    isDelete: true,
                    onClick: () => {
                      onDelete(
                        {
                          taskId: _get( cell, 'row.original.task.id' ),
                          id: _get( cell, 'value' ),
                        },
                      );
                    }
                    ,
                  }]}
                  />
                ),
              } : {
                id: '_id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </Container>
    </>
  );
} );
