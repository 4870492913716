import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import CityService from 'api/Settings/City';
import ZoneService from 'api/Settings/Zone';
import useFetch from 'hooks/useFetch';

const useLimitByLocationRow = ( propertyManager, originalData ) => {
  const [selectedCity, setSelectedCity] = useState( null );
  const [defaultCity, setDefaultCity] = useState( undefined );
  const [defaultZones, setDefaultZones] = useState( undefined );

  const fetchCities = useCallback(
    () => CityService.getCities( {
      propertyManager: propertyManager.id,
    } ),
    [propertyManager.id],
  );

  const [zones, isZonesLoading] = useFetch( {
    initialState: [],
    promise: () => ZoneService.getZones( selectedCity, {
      propertyManager: propertyManager.id,
    } ),
    format: ( dataToFormat ) => _.get( dataToFormat, 'data', [] ),
    conditional: !!selectedCity,
    deps: [selectedCity],
  } );

  useEffect( () => {
    if ( originalData ) {
      setDefaultCity( originalData.city );
      setDefaultZones( originalData.zones );
      setSelectedCity( originalData.city.id );
    }
  }, [originalData] );

  return {
    fetchCities,
    setSelectedCity,
    zones,
    isZonesLoading,
    defaultCity,
    defaultZones,
  };
};

export default useLimitByLocationRow;
