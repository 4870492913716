import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

const Operations = ( { data } ) => {
  const [isOpen, setIsOpen] = useState( true );
  const toggle = () => setIsOpen( ( state ) => !state );
  return (
    <>
      <tbody>
        <tr>
          <th onClick={toggle} className="dc_sidebar_cell bg-white font-weight-bold">
            Operations
            {isOpen && <i className="fas fa-chevron-up chevron" />}
            {!isOpen && <i className="fas fa-chevron-down chevron" />}
          </th>
        </tr>
      </tbody>
      <Collapse isOpen={isOpen} tag="tbody">
        <tr className="dc_data-row">
          <th className="dc_sidebar_cell">Tasks</th>
          {data.map( ( { task, id } ) => (
            <td
              key={id}
              className="text-center"
            >
              {task}
            </td>
          ) )}
        </tr>
        <tr className="dc_data-row">
          <th className="dc_sidebar_cell">Issuess</th>
          {data.map( ( { issues, id } ) => (
            <td
              key={id}
              className="text-center"
            >
              {issues}
            </td>
          ) )}
        </tr>
        <tr className="dc_data-row">
          <th className="dc_sidebar_cell">Preventives</th>
          {data.map( ( { preventives, id } ) => (
            <td
              key={id}
              className="text-center"
            >
              {preventives}
            </td>
          ) )}
        </tr>
      </Collapse>
    </>
  );
};

export default Operations;
