import React, { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useFetchTableData, useFetch } from 'hooks';
import SettingsActions from 'store/reducers/Settings';
import AccommodationService from 'api/Accommodation';
import AccomodationOwnerService from 'api/AccomodationOwner';
import TaskTypeService from 'api/TaskType';
import PropertyService from 'api/Property';
import EmployeeService from 'api/Employee';
import TeamService from 'api/Team';
import moment from 'moment';
import TasksClientEditView from 'views/AccomodationOwner/Edit/tasks';
import { handlePromise } from 'utils';

const TasksClientEdit = ( {
  match,
  toggleErrorAlert,
  userRole,
} ) => {
  const isOwner = userRole === 'ROLE_OWNER';
  const clientId = match.params.id;

  const filterFromDate = moment().startOf( 'day' );
  const filterToDate = moment( filterFromDate ).clone().add( '1', 'day' );
  const dateRange = useRef( moment.range( filterFromDate, filterToDate ) );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [isDownloading, setisDownloading] = useState( false );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AccomodationOwnerService.getTasksOwner( clientId, {
      ...params,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
    } ),
    deps: [dataSubmitted],
  } );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const fetchEmployees = useCallback(
    ( query ) => EmployeeService.getEmployees( {
      elementsPerPage: 10,
      keyword: query,
      employee: true,
    } ),
    [],
  );
  const fetchTeams = useCallback(
    () => TeamService.getTeams( {
      elementsPerPage: 999,
    } ),
    [],
  );

  const updateDateRangeParams = useCallback( ( params ) => {
    dateRange.current = params;
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const getTodayTasks = useCallback( () => {
    dateRange.current = moment.range(
      moment().startOf( 'day' ),
      moment().startOf( 'day' ).add( '1', 'day' ),
    );
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const openInNewTab = async ( url, id ) => {
    const link = document.createElement( 'a' );
    link.href = url;
    link.setAttribute(
      'download',
      `inv${id}.xlsx`,
    );
    document.body.appendChild( link );
    link.click();
    link.parentNode.removeChild( link );
  };

  const downloadRecord = useCallback(
    async ( params ) => {
      setisDownloading( true );
      // eslint-disable-next-line operator-linebreak
      const [errors, response, responseData] = await handlePromise(
        AccomodationOwnerService.downloadTasksOwner( clientId, params ),
      );
      setisDownloading( false );
      if ( !response.ok ) return toggleErrorAlert( errors );
      await openInNewTab( responseData?.url || '', clientId );
    },
    [toggleErrorAlert, setisDownloading, clientId],
  );

  return (
    <TasksClientEditView
      isOwner={isOwner}
      data={data}
      isLoading={dataLoading || isDownloading}
      initialDates={dateRange.current}
      defaultFilters={{
        from: dateRange.current.start
          ? dateRange.current.start.format( 'YYYY-MM-DD' )
          : undefined,
        till: dateRange.current.end
          ? dateRange.current.end.format( 'YYYY-MM-DD' )
          : undefined,
      }}
      onFetchData={fetchData}
      onDownload={downloadRecord}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
      onFetchProperties={fetchProperties}
      onFetchAccommodations={fetchAccommodations}
      onTodaysTasks={getTodayTasks}
      onDateChanged={updateDateRangeParams}
      onFetchEmployees={fetchEmployees}
      onFetchTeams={fetchTeams}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  userRole: user.mainRole,
} );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( TasksClientEdit );
