import React from 'react';
import useLimitByLocation from 'containers/AutogenerateTask/Edit/useLimitByLocation';
import useLimitByLocationRow from 'containers/AutogenerateTask/Edit/useLimitByLocationRow';
import { AsyncSelect, Select, Intl } from 'components';
import { Button, Col, Row } from 'reactstrap';

const LimitByLocationsRow = ( {
  data,
  onChangeRow,
  deleteRow,
  propertyManager,
  setZonesSelected,
} ) => {
  const {
    fetchCities,
    setSelectedCity,
    zones,
    isZonesLoading,
    defaultCity,
    defaultZones,
  } = useLimitByLocationRow( propertyManager, data?.originalData || null );

  return (
    <Row className="mb-2 flex-nowrap">
      <Col xs={4}>
        <AsyncSelect
          field={`fieldCity-${data.idKey}`}
          label="city"
          defaultValue={defaultCity}
          defaultOptions
          loadOptions={fetchCities}
          labelKey={( option ) => option.city.name}
          onChange={( city ) => {
            setSelectedCity( city.value );
            onChangeRow( data.idKey, 'city', city );
          }}
        />
      </Col>
      <Col>
        <Select
          field={`fieldZone-${data.idKey}`}
          label="zones"
          isLoading={isZonesLoading}
          options={zones}
          multiple
          defaultValue={defaultZones}
          onChange={( v ) => {
            setZonesSelected( v );
            onChangeRow( data.idKey, 'zones', v );
          }}
        />
      </Col>
      <Col xs="auto" style={{ paddingTop: 40 }}>
        <Button
          size="sm"
          color="danger"
          outline
          style={{ lineHeight: 0.5 }}
          onClick={() => {
            deleteRow( data.idKey );
          }}
        >
          <i className="fa fa-times" />
        </Button>
      </Col>
    </Row>
  );
};

const LimitByLocations = ( { propertyManager, onChange, dataTask } ) => {
  const {
    list,
    addRow,
    onChangeRow,
    deleteRow,
    isLoadingProperties,
    setZonesSelected,
    properties,
    onChangeProperties,
    setPropertiesKeyword,
    isLoadingAccommodations,
    accommodations,
    onChangeAccommodations,
    setAccommodationsKeyword,
  } = useLimitByLocation( onChange, dataTask );

  return (
    <>
      <hr />
      <h3 className="mb-3">
        <Intl id="limitByLocation.title" />
      </h3>
      {list.map( ( data ) => (
        <LimitByLocationsRow
          key={data.idKey}
          data={data}
          deleteRow={deleteRow}
          onChangeRow={onChangeRow}
          propertyManager={propertyManager}
          setZonesSelected={setZonesSelected}
        />
      ) )}
      <Button onClick={addRow} size="sm" className="mb-4">
        <i className="fa fa-plus mr-2" />
        <Intl id="limitByLocation.addBtn" />
      </Button>

      {list.length ? (
        <>
          <Row className="mb-3">
            <Col>
              <Select
                field="fieldProperties"
                label="properties"
                isLoading={isLoadingProperties}
                options={properties}
                multiple
                onChange={onChangeProperties}
                onInputChange={( k ) => {
                  setPropertiesKeyword( k );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                field="fieldAccommodations"
                label="accommodations"
                isLoading={isLoadingAccommodations}
                options={accommodations}
                multiple
                idKey="_id"
                onChange={onChangeAccommodations}
                onInputChange={( k ) => {
                  setAccommodationsKeyword( k );
                }}
              />
            </Col>
          </Row>
        </>
      ) : null}
      <hr />
    </>
  );
};

export default LimitByLocations;
