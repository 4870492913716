import api from 'config/Api/Api';

const baseEndpoint = '/roles';

export default {
  getRoles( params ) {
    return api.get( baseEndpoint, params );
  },

  getRolesAcls() {
    return api.get( `${baseEndpoint}/acls` );
  },

  getRole( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveRole( data ) {
    return api.post( baseEndpoint, data );
  },

  updateRole( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },
};
