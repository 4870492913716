import React, { useState } from 'react';
import {
  Col, Row, Button,
} from 'reactstrap';
import _ from 'lodash';

import {
  DetailCard, Intl, TeamShiftCollapse,
  ShiftTemplateEditModal, CanAccess, EditCard,
} from 'components';

export default React.memo( ( {
  data, templateInitialData, isLoading,
  onSubmitTemplate, onDeleteTemplate,
} ) => {
  const [addModalOpened, setAddModalOpened] = useState( false );

  const toggleModal = () => {
    setAddModalOpened( !addModalOpened );
  };

  return (
    <>
      <EditCard cardClass="card-sec-bottom" isLoading={isLoading}>
        <Row>
          <Col xl={12} md={8}>
            <DetailCard
              borderless
              classNames={{ header: 'text-uppercase' }}
              translateTitle
            >
              <div className="accordion">
                {_.map( data.shiftTemplates, ( template ) => (
                  <TeamShiftCollapse
                    key={template.id}
                    collapsed={false}
                    data={template}
                    formId={template.id}
                    onSubmit={onSubmitTemplate}
                    onDelete={onDeleteTemplate}
                  />
                ) )}
              </div>

              <CanAccess I="add" a="team">
                <Row>
                  <Col className="text-center">
                    <Button
                      size="sm"
                      color="link"
                      onClick={toggleModal}
                    >
                      <i className="fa fa-plus mr-1" />
                      <Intl id="addShiftTemplate" />
                    </Button>
                  </Col>
                </Row>
              </CanAccess>
            </DetailCard>
          </Col>
        </Row>

      </EditCard>

      <ShiftTemplateEditModal
        data={templateInitialData}
        open={addModalOpened}
        onClose={toggleModal}
        onSubmit={( formData, form ) => onSubmitTemplate( formData, form, toggleModal )}
      />
    </>
  );
} );
