import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  ModalInfo, AddBtn, LocalTable, TextField, Form, FormActionsBtns, TableEditBtn, CanAccess,
} from 'components';
import { required } from 'config/InputErrors';
import ability from 'config/Ability';

export default React.memo( ( {
  data, editData, isLoading, open, editOpen, onClose, onSubmit, onOpenEdit, onCloseEdit,
} ) => (
  <ModalInfo
    title="zones"
    isLoading={isLoading}
    open={open}
    onClose={onClose}
    bodyClassName={editOpen ? '' : 'px-0'}
    name="zones"
  >
    {editOpen
      ? (
        <Form
          initialValues={editData}
          onSubmit={ability.can( 'edit', 'settings_city_zone' ) ? onSubmit : () => {}}
        >
          <>
            <Row>
              <Col md={12}>
                <TextField
                  field="name"
                  label="name"
                  validate={required}
                />
              </Col>
            </Row>
          </>
          <div className="modal-footer justify-content-center">
            <CanAccess I="edit" a="settings_city_zone">
              <FormActionsBtns onClickCancel={onCloseEdit} marginContainer={false} />
            </CanAccess>
          </div>
        </Form>
      )
      : (
        <LocalTable
          data={data}
          containerClassName="shadow-none m-0"
          rightContent={<CanAccess I="add" a="settings_city_zone"><AddBtn title="newZone" onClick={() => onOpenEdit()} /></CanAccess>}
          columns={[
            {
              Header: 'name',
              accessor: 'name',
              Cell: ( { cell } ) => (
                <TableEditBtn
                  title={cell.value}
                  onClick={() => onOpenEdit( cell.row.original )}
                />
              ),
            },
            {
              Header: 'slug',
              accessor: 'slug',
            },
          ]}
        />
      )}
  </ModalInfo>
) );
