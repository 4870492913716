import React from 'react';
import { Route, Switch } from 'react-router';

import { canModify, canView, canList } from 'config/Ability';
import Contract from 'containers/Property/RoomType/Accommodation/Contract';
import Edit from 'containers/Property/RoomType/Accommodation/Edit';
import Dashboard from './Dashboard';

const Accommodation = ( { match } ) => (
  <Switch>
    {canModify( 'accommodation' )
      && (
        <Route
          path={`${match.url}/new`}
          render={( props ) => <Edit {...props} paths={{ accommodation: match.url }} />}
        />
      )}
    {canView( 'accommodation' )
      && (
        <Route
          path={`${match.url}/edit/:id`}
          render={( props ) => <Edit {...props} paths={{ accommodation: match.url }} />}
        />
      )}
    {canList( 'accommodation_contract' )
      && (
        <Route
          path={`${match.url}/:id/contracts`}
          render={( props ) => <Contract {...props} paths={{ accommodation: match.url }} />}
        />
      )}
    {canList( 'accommodation' )
      && (
        <Route
          path={match.url}
          render={( props ) => <Dashboard {...props} parentPath={match.url} />}
        />
      )}
  </Switch>
);

export default Accommodation;
