/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';

import {
  SimpleHeader,
  Intl,
  Form,
  TextField,
  FormActionsBtns,
  Select,
  Button,
  AsyncSelect,
} from 'components';
import { required } from 'config/InputErrors';

const deleteArrayElement = ( array, id ) => [
  ...array.slice( 0, id ),
  ...array.slice( id + 1, array.length ),
];

const ExtraRule = ( {
  id, onChange, rule, onDeleteExtraRule, bookingTypesOptions,
} ) => (
  <Row>
    <Col xs={4}>
      <Select
        translateOptions
        label="rule"
        field={`extraRules[${id}].rule`}
        options={[
          {
            id: 'stay_greater_or_equal_than',
            name: 'stay_greater_or_equal_than',
          },
          {
            id: 'stay_less_or_equal_than',
            name: 'stay_less_or_equal_than',
          },
          {
            id: 'missing_travel_report_info',
            name: 'missing_travel_report_info',
          },
          {
            id: 'payments_pending',
            name: 'payments_pending',
          },
          {
            id: 'review_pending',
            name: 'review_pending',
          },
          {
            id: 'issues_during_stay',
            name: 'issues_during_stay',
          },
          {
            id: 'not_issues_during_stay',
            name: 'not_issues_during_stay',
          },
          {
            id: 'origin_ota',
            name: 'origin_ota',
          },
          {
            id: 'origin_pm',
            name: 'origin_pm',
          },
          {
            id: 'booking_type',
            name: 'booking_type',
          },
          {
            id: 'no_download_app',
            name: 'no_download_app',
          },
          {
            id: 'downloaded_app',
            name: 'downloaded_app',
          },
          {
            id: 'no_show',
            name: 'no_show',
          },
        ]}
        onChange={onChange}
        validate={required}
      />
    </Col>
    {( rule === 'stay_greater_or_equal_than'
      || rule === 'stay_less_or_equal_than' ) && (
      <Col xs={2}>
        <TextField
          type="number"
          field={`extraRules[${id}].days`}
          label="days"
          validate={required}
        />
      </Col>
    )}
    {( rule === 'booking_type'
      || rule === 'booking_type' ) && (
      <Col xs={2}>
        <Select
          translateOptions
          label="type"
          field={`extraRules[${id}].bookingType`}
          options={bookingTypesOptions}
          validate={required}
        />
      </Col>
    )}
    <Col xs={1} className="d-flex align-items-center">
      <button
        type="button"
        className="btn btn-link"
        onClick={onDeleteExtraRule}
      >
        <i className="fas fa-minus-circle text-lg" />
      </button>
    </Col>
  </Row>
);
const PredefinedNotification = ( {
  data,
  isLoading,
  onSubmit,
  parentPath,
  onDelete,
  entityId,
  onFetchProperties,
  onFetchAccommodations,
  onChangeCities,
  zones,
  cities,
  isLoadingCities,
  templates,
  isLoadingTemplates,
  bookingTypesOptions,
} ) => {
  const [extraRules, setExtraRules] = useState( [] );
  const [reminder, setReminder] = useState( [{}] );

  useEffect( () => {
    if ( data.extraRules && data.extraRules.length ) {
      setExtraRules( data.extraRules );
    }
    if ( data.reminder && data.reminder.length ) {
      setReminder( data.reminder );
    }
  }, [data.extraRules, data.reminder] );

  return (
    <>
      <SimpleHeader
        title={
          entityId ? 'editPredefinedNotification' : 'newPredefinedNotification'
        }
      />
      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <Form
          initialValues={{
            type: 'email_push',
            ...data,
          }}
          showErrorsInFooter
          onSubmit={onSubmit}
          body={( form ) => (
            <>
              {!!entityId && (
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button
                    title="delete"
                    color="danger"
                    onClick={() => onDelete( entityId )}
                  />
                </Col>
              </Row>
              )}
              <Row>
                <Col md={4}>
                  <TextField
                    field="title"
                    label="title"
                    labelClass="font-weight-bold"
                    validate={required}
                  />
                </Col>
                <Col md={4}>
                  <Select
                    field="communicationTemplate"
                    label="template"
                    labelKey="title"
                    options={templates}
                    isLoading={isLoadingTemplates}
                    validate={required}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p className="form-control-label font-weight-bold">
                    <Intl id="rules" />
                  </p>
                </Col>
                <Col xs={6} md={3}>
                  <Select
                    field="executionPoint"
                    label="executionPoint"
                    translateOptions
                    validate={required}
                    options={[
                      {
                        id: 'after',
                        name: 'after',
                      },
                      {
                        id: 'before',
                        name: 'before',
                      },
                      {
                        id: 'instant',
                        name: 'instant',
                      },
                    ]}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <Select
                    field="process"
                    label="process"
                    translateOptions
                    validate={required}
                    options={[
                      {
                        id: 'checkout',
                        name: 'checkout',
                      },
                      {
                        id: 'checkin',
                        name: 'checkin',
                      },
                      {
                        id: 'reservation',
                        name: 'reservation',
                      },
                    ]}
                  />
                </Col>
              </Row>
              {extraRules
                  && _.map( extraRules, ( { rule }, id ) => (
                    <ExtraRule
                      id={id}
                      key={id}
                      rule={rule}
                      bookingTypesOptions={bookingTypesOptions}
                      onChange={( val ) => {
                        setExtraRules( ( state ) => [
                          ...state.slice( 0, id ),
                          {
                            rule: val,
                          },
                          ...state.slice( id + 1, state.length ),
                        ] );
                      }}
                      onDeleteExtraRule={() => {
                        const { values } = form.getState();
                        const extraRulesUpdated = deleteArrayElement(
                          _.get( values, 'extraRules', [] ),
                          id,
                        );
                        form.change( 'extraRules', extraRulesUpdated );
                        setExtraRules( extraRulesUpdated );
                      }}
                    />
                  ) )}
              <Row>
                <Col>
                  <Button
                    color="link"
                    icon="fa fa-plus"
                    title="addRule"
                    onClick={() => setExtraRules( ( state ) => [...state, {}] )}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p className="form-control-label font-weight-bold">
                    <Intl id="reminder" />
                  </p>
                  <p>
                    <Intl id="reminder.description" />
                  </p>
                </Col>
                {reminder.map( ( _v, index ) => {
                  const key = `reminder_${index}`;
                  return (
                    <React.Fragment key={key}>
                      <Col xs={3} sm={2}>
                        <TextField
                          field={`reminder[${index}]`}
                          type="number"
                          validate={required}
                        />
                      </Col>
                      <Col xs={1} sm={2}>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            const { values } = form.getState();
                            const reminderUpdated = deleteArrayElement(
                              _.get( values, 'reminder', [] ),
                              index,
                            );
                            form.change( 'reminder', reminderUpdated );
                            setReminder( ( state ) => deleteArrayElement( state, index ) );
                          }}
                        >
                          <i className="fas fa-minus-circle text-lg" />
                        </button>
                      </Col>
                    </React.Fragment>
                  );
                } )}
                <Col xs={3} sm={2}>
                  <Button
                    color="link"
                    icon="fa fa-plus"
                    title="addDays"
                    onClick={() => setReminder( ( state ) => [...state, {}] )}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p className="form-control-label font-weight-bold">
                    <Intl id="restrictions" />
                  </p>
                  <p>
                    <Intl id="restrictions.description" />
                  </p>
                </Col>
                <Col md={6}>
                  <Select
                    field="cities"
                    label="cities"
                    options={cities}
                    isLoading={isLoadingCities}
                    multiple
                    onChange={( value ) => {
                      onChangeCities( value );
                      form.change( 'zones', [] );
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Select
                    field="zones"
                    label="zones"
                    options={zones}
                    multiple
                  />
                </Col>
                <Col xs={12}>
                  <AsyncSelect
                    field="properties"
                    label="properties"
                    defaultOptions
                    loadOptions={onFetchProperties}
                    multiple
                  />
                </Col>
                <Col xs={12}>
                  <AsyncSelect
                    field="accommodations"
                    label="accommodations"
                    defaultOptions
                    loadOptions={onFetchAccommodations}
                    multiple
                  />
                </Col>
              </Row>
            </>
          )}
          footer={<FormActionsBtns cancelTo={parentPath} />}
        />
      </Container>
    </>
  );
};

export default PredefinedNotification;
