/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';

import {
  TextField,
  Select,
  ModalForm,
  AsyncSelect,
  Datepicker,
  Intl,
  ImagesDropzoneGeneric,
  // CustomField,
} from 'components';
import { required } from 'config/InputErrors';
// import EmployeeService from 'api/Employee';
import { taskPriorities, taskTypes as typeOfTaskTypes } from 'config/Constants';

export default React.memo(
  ( {
    // customFields,
    isLoading,
    preloadedData,
    open,
    onClose,
    onSubmit,
    onFetchAccommodations,
    onFetchPlaces,
    taskTypes,
    isLoadingTaskTypes,
    zIndex,
    setAccommodationSelected,
    setPlaceSelected,
    isDisabledAccommodation,
    isDisabledPlace,
  } ) => {
    // const [accommodationValue, setAccommodation] = useState();
    // const [taskTypeValue, setTaskType] = useState();
    // const formatOptions = ( _options ) =>
    //   // eslint-disable-next-line implicit-arrow-linebreak
    //   _options.map( ( op ) => ( {
    //     id: op.id,
    //     name: `${op.firstName} ${op.lastName}`,
    //   } ) );
    // const [availableEmployees, setavailableEmployees] = useState( [] );
    // useEffect( () => {
    //   const getEmployees = async () => {
    //     const { data } = await EmployeeService.getEmployeesForTask( {
    //       elementsPerPage: 100,
    //       accommodation: accommodationValue,
    //       taskType: taskTypeValue,
    //     } );
    //     setavailableEmployees( formatOptions( data.data ) );
    //   };
    //   getEmployees();
    // }, [accommodationValue, taskTypeValue] );

    const [taskEstimatedTime, setTaskEstimatedTime] = useState( null );
    const [selectedTaskType, setSelectedTaskType] = useState( null );

    useEffect( () => {
      let newTaskEstimatedTime = null;
      taskTypes.forEach( ( { options } ) => {
        if ( options ) {
          const [opSelected] = options.filter( ( { id } ) => id === selectedTaskType );
          if ( opSelected ) {
            newTaskEstimatedTime = opSelected?.estimatedTime || null;
          }
        }
      } );
      setTaskEstimatedTime( newTaskEstimatedTime );
    }, [selectedTaskType, taskTypes] );

    return (
      <ModalForm
        title="newTask"
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        size="xl"
        bodyClassName="overflow-visible"
        bodyStyle={{ minHeight: 330 }}
        onSubmit={onSubmit}
        onClose={onClose}
        initialValues={preloadedData}
        zIndex={zIndex}
        body={( form ) => (
          <>
            <Row>
              <Col lg={8} className="border-right">
                <Row className="mb-1">
                  <Col md={12}>
                    <AsyncSelect
                      field="accommodation"
                      label="accommodation"
                      defaultOptions
                      isClearable
                      isDisabled={isDisabledAccommodation}
                      loadOptions={onFetchAccommodations}
                      onChange={( val ) => setAccommodationSelected( val?.value || null )}
                      labelKey={( option ) => `${option.name} (${option.roomType.property.propertyManagerCityZone.name} - ${option.roomType.property.propertyManagerCityZone.propertyManagerCity.city.name})`}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <AsyncSelect
                      field="place"
                      label="place"
                      defaultOptions
                      isClearable
                      isDisabled={isDisabledPlace}
                      loadOptions={onFetchPlaces}
                      onChange={( val ) => setPlaceSelected( val?.value || null )}
                      idKey="_id"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={7}>
                    <Select
                      field="taskType"
                      label="category"
                      labelClass="font-weight-bold"
                      validate={required}
                      options={taskTypes}
                      isLoading={isLoadingTaskTypes}
                      onChange={setSelectedTaskType}
                      optgroup
                    />
                  </Col>
                  <Col md={5}>
                    <TextField
                      field="taskEstimatedTime"
                      label="estimatedTime"
                      type="number"
                      value={taskEstimatedTime}
                      onChange={setTaskEstimatedTime}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <TextField
                      field="description"
                      label="description"
                      type="textarea"
                      labelClass="font-weight-bold"
                      rows={3}
                      validate={required}
                    />
                  </Col>
                </Row>

                {/* {!!customFields && !!customFields.length && (
                <Row>
                  {_.map( customFields, ( item ) => (
                    <Col md={6} key={item.id}>
                      <CustomField
                        field={item}
                        labelClass="font-weight-bold"
                      />
                    </Col>
                  ) )}
                </Row>
                )} */}

                <p className="font-weight-bold mb-1 form-control-label text-md">
                  <Intl id="attachments" />
                </p>
                <ImagesDropzoneGeneric form={form} />
              </Col>

              <Col lg={4}>
                <Row className="mb-3">
                  <Col>
                    <Select
                      field="type"
                      label="type"
                      labelClass="font-weight-bold"
                      validate={required}
                      translateOptions
                      options={typeOfTaskTypes}
                    />
                    <Select
                      field="priority"
                      label="priority"
                      labelClass="font-weight-bold"
                      translateOptions
                      options={_.map( taskPriorities, ( priority ) => ( {
                        id: priority.id,
                        name: priority.name,
                        icon: (
                          <i
                            className={classnames(
                              priority.iconClass,
                              'text-2xsm',
                            )}
                          />
                        ),
                      } ) )}
                      validate={required}
                      formGroupClass="m-0"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Datepicker
                      field="date"
                      label="scheduledFor"
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      utc
                      labelClass="font-weight-bold"
                      validate={required}
                      startDateField={{ value: moment().subtract( '1', 'day' ) }}
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <Select
                      required={false}
                      field="assignedTo"
                      label="assignedTo"
                      labelClass="font-weight-bold"
                      options={availableEmployees}
                    />
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </>
        )}
      />
    );
  },
);
