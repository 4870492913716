/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

import {
  AsyncSelect,
  TextField,
  Select,
  ModalForm,
  Switch,
  Intl,
  Checkbox,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import FieldZones from 'containers/Team/New/FieldZones';
import { deleteArrayElement } from 'utils';

const employeeName = ( item ) => `${item.firstName || ''} ${item.lastName || ''}`;

export default React.memo(
  ( {
    teamId,
    data,
    isLoading,
    open,
    onClose,
    onSubmit,
    onFetchEmployees,
    onFetchProperties,
    taskTypes,
    isLoadingTaskTypes,
  } ) => {
    const [extraCities, setExtraCities] = useState( [] );
    useEffect( () => {
      const cities = _.get( data, 'cities', [] ).slice( 1 );
      setExtraCities( cities );
    }, [data] );

    return (
      <ModalForm
        initialValues={data}
        title={teamId ? 'editTeam' : 'newTeam'}
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        onClose={onClose}
        bodyStyle={{ minHeight: 460 }}
        bodyClassName="overflow-visible"
        onSubmit={canModify( 'team' ) ? onSubmit : () => {}}
        formEnabled={canModify( 'team' )}
        validate={( values ) => {
          const errors = {};
          const propertyZones = _.map(
            values.properties,
            'original.propertyManagerCityZone.id',
          );
          if ( _.intersection( values.zones, propertyZones ).length ) {
            errors.sameZone = true;
          }
          return errors;
        }}
        body={( form ) => (
          <>
            <Row>
              <Col md={6}>
                <TextField field="name" label="name" validate={required} />
              </Col>
              <Col md={6}>
                <TextField field="color" label="color" validate={required} type="color" />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Switch field="taskInsideShift" label="taskInsideShift" />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <TextField
                  field="description"
                  label="description"
                  type="textarea"
                  rows={4}
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  field="taskTypes"
                  label="taskTypes"
                  labelClass="font-weight-bold"
                  options={taskTypes}
                  isLoading={isLoadingTaskTypes}
                  optgroup
                  multiple
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5}>
                <AsyncSelect
                  field="manager"
                  label="manager"
                  labelKey={employeeName}
                  defaultOptions
                  validate={required}
                  loadOptions={onFetchEmployees}
                />
              </Col>
              <Col sm={7}>
                <AsyncSelect
                  field="admins"
                  label="supervisors"
                  labelKey={employeeName}
                  multiple
                  defaultOptions
                  validate={required}
                  loadOptions={onFetchEmployees}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <AsyncSelect
                  field="members"
                  label="members"
                  labelKey={employeeName}
                  multiple
                  defaultOptions
                  validate={required}
                  loadOptions={onFetchEmployees}
                />
              </Col>
            </Row>
            <FieldZones index={0} form={form} data={data}>
              <Col xs={2} sm={1} className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-primary-link"
                  onClick={() => setExtraCities( ( state ) => [...state, {}] )}
                >
                  <i className="fas fa-plus-circle text-lg" />
                </button>
              </Col>
            </FieldZones>
            {_.map( extraCities, ( zone, id ) => (
              <FieldZones
                data={data}
                key={id}
                index={id + 1}
                form={form}
                allowDelete
                onDelete={() => {
                  const { values } = form.getState();
                  const zonesUpdated = deleteArrayElement(
                    _.get( values, 'zones', [] ),
                    id + 1,
                  );
                  const citiesUpdated = deleteArrayElement(
                    _.get( values, 'cities', [] ),
                    id + 1,
                  );
                  form.change( 'zones', zonesUpdated );
                  form.change( 'cities', citiesUpdated );
                  setExtraCities( zonesUpdated.slice( 1 ) );
                }}
              />
            ) )}
            <Row>
              <Col>
                <AsyncSelect
                  field="properties"
                  label="properties"
                  multiple
                  defaultOptions
                  loadOptions={onFetchProperties}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox field="master" label="teamMasterField" />
              </Col>
            </Row>
            {form.getState().submitFailed
            && form.getState().errors
            && form.getState().errors.sameZone ? (
              <Row>
                <Col>
                  <div className="invalid-feedback d-block">
                    <Intl id="teamErrorEqualZoneProperty" />
                  </div>
                </Col>
              </Row>
              ) : null}
          </>
        )}
      />
    );
  },
);
