import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import SettingsActions from 'store/reducers/Settings';
import ShiftBulkEditView from 'views/Shift/BulkEdit';
import TeamService from 'api/Team';
import { useFetch } from 'hooks';
import ShiftService from 'api/Shift';
import ShiftTemplateService from 'api/ShiftTemplate';
import useSubmit from 'hooks/useSubmit';

const ShiftEdit = ( {
  modalOpened, onCloseModal, onReload, toggleErrorAlert, toggleInfoAlert, toggleLoading,
} ) => {
  const fetchTeams = useCallback( ( query ) => TeamService.getTeams( {
    page: -1,
    keyword: query,
  } ), [] );

  const submitForm = useSubmit( {
    promise: ShiftService.bulkAssignation,
    format: ( dataToSend ) => ( {
      ...dataToSend,
      initDate: dataToSend.initDate.format( 'YYYY-MM-DD' ),
      endDate: dataToSend.endDate.format( 'YYYY-MM-DD' ),
      type: 'shift_template',
      autoAccept: true,
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: () => onReload(),
  } );

  const [teamId, seatTeamId] = useState();
  const onChangeTeam = ( { value } ) => {
    seatTeamId( value );
  };

  const [templates, loadingTemplates] = useFetch( {
    initialState: {},
    promise: () => ShiftTemplateService.getTemplates( teamId ),
    toggleErrorAlert,
    conditional: !!teamId && modalOpened,
    deps: [teamId],
  } );

  return (
    <ShiftBulkEditView
      open={modalOpened}
      onClose={onCloseModal}
      onSubmit={submitForm}
      onFetchTeams={fetchTeams}
      templates={templates}
      onChangeTeam={onChangeTeam}
      isLoadingTemplates={loadingTemplates}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ShiftEdit );
