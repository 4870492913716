import api from 'config/Api/Api';

const baseEndpoint = '/room-expenses';

export default {
  getRoomExpenses( params ) {
    return api.get( baseEndpoint, params );
  },
  getRoomExpense( roomExpenseId ) {
    return api.get( `${baseEndpoint}/${roomExpenseId}` );
  },
  updateRoomExpense( roomExpenseId, data ) {
    return api.put( `${baseEndpoint}/${roomExpenseId}`, data );
  },
  saveRoomExpense( data ) {
    return api.post( baseEndpoint, data );
  },
  deleteRoomExpense( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
