import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const DELETE_RATE = gql`
  mutation DeleteRate($rateId: ID!) {
  deleteRate(rateId: $rateId) {
    ... on MutationDeleteRateSuccess {
      data {
        success
      }
    }
    ... on BaseError {
      message
    }
  }
}
`;

const deleteRate = async ( rateId ) => {
  const { data } = await client.query( {
    query: DELETE_RATE,
    variables: {
      rateId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteRate.message ) {
    return {
      ok: false,
      message: data.deleteRate.message,
    };
  }
  const deleteSuccessful = data.deleteRate.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteRate;
