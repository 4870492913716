import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';

import Accommodation from './Accommodation';

const RoomTypes = ( {
  data,
  dateRange,
  onContextMenu,
  onClickBooking,
  onClickTask,
  onDeleteTask,
  onDragEnd,
  onDeleteBookingBlocked,
  onClickCell,
  roomTypeId,
  onClickAccomodation,
  resetAccomodation,
} ) => {
  const [isOpen, setIsOpen] = useState( false );
  const toggle = async () => {
    if ( !isOpen ) {
      await onClickAccomodation( data.propertyIds, data.accommodationsIds );
    }
    setIsOpen( ( state ) => !state );
  };

  useEffect( () => {
    setIsOpen( false );
  }, [resetAccomodation] );

  const renderAccommodations = ( accommodations ) => accommodations.map(
    ( accommodation ) => (
      <Accommodation
        key={accommodation.accommodationId}
        data={accommodation}
        dateRange={dateRange}
        propertyId={data.propertyId}
        onContextMenu={onContextMenu}
        onClickBooking={onClickBooking}
        onClickTask={onClickTask}
        onDeleteTask={onDeleteTask}
        onDragEnd={onDragEnd}
        onDeleteBookingBlocked={onDeleteBookingBlocked}
        onClickCell={onClickCell}
        roomTypeId={roomTypeId}
      />
    ),
  );

  return (
    <>
      <tbody>
        <tr>
          <th onClick={toggle} className="dc_sidebar_cell dc_title">
            {`${data.propertyName} - ${data.roomTypeName}`}
            {isOpen && <i className="fas fa-chevron-up chevron" />}
            {!isOpen && <i className="fas fa-chevron-down chevron" />}
          </th>
        </tr>
      </tbody>
      <Collapse isOpen={isOpen} tag="tbody">
        {isOpen
          ? renderAccommodations( data.accommodations )
          : null}
      </Collapse>
    </>
  );
};

export default RoomTypes;
