/* eslint-disable import/prefer-default-export */
const filterBuilder = ( {
  discountId, fromStart, fromEnd, tillStart, tillEnd, filterByDate,
} ) => {
  const filters = [];
  if ( discountId ) {
    filters.push( {
      field: 'discountId',
      operator: 'EQUAL',
      value: discountId,
    } );
  }

  if ( filterByDate ) {
    if ( fromStart && !tillStart && !tillEnd ) {
      filters.push( {
        field: 'startDate',
        operator: 'GTE',
        value: new Date( fromStart ).toISOString(),
      } );
      filters.push( {
        field: 'startDate',
        operator: 'LTE',
        value: new Date( fromEnd ).toISOString(),
      } );
    }

    if ( tillStart && !fromStart && !fromEnd ) {
      filters.push( {
        field: 'lastNight',
        operator: 'GTE',
        value: new Date( tillStart ).toISOString(),
      } );
      filters.push( {
        field: 'lastNight',
        operator: 'LTE',
        value: new Date( tillEnd ).toISOString(),
      } );
    }
    if ( fromStart && tillEnd && !fromEnd && !tillStart ) {
      filters.push( {
        field: 'startDate',
        operator: 'GTE',
        value: new Date( fromStart ).toISOString(),
      } );
      filters.push( {
        field: 'lastNight',
        operator: 'LTE',
        value: new Date( tillEnd ).toISOString(),
      } );
    }
  }
  return filters;
};

export { filterBuilder };
