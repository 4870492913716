import React, { useState, useCallback } from 'react';
import { handlePromise } from 'utils';
import PointOfInterestService from 'api/PointOfInterest';
import SearchView from 'views/PointOfInterest/Search';
import { formatResult } from './utils';

export default ( {
  onResult,
} ) => {
  const [loading, setLoading] = useState( false );

  const searchPlace = useCallback( async ( keywords ) => {
    setLoading( true );
    const [error, response, dataPlace] = await handlePromise(
      PointOfInterestService.searchPlace( keywords ),
    );

    if ( !response.ok ) {
      setLoading( false );
      onResult( null );
      return error;
    }
    const formattedDataPlace = formatResult( dataPlace );
    setLoading( false );
    onResult( formattedDataPlace );
  }, [onResult, setLoading] );

  return (
    <SearchView
      onSearch={searchPlace}
      loading={loading}
    />
  );
};
