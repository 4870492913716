import React from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import {
  AsyncSelect,
  ModalForm,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    data,
    open,
    onFetchAccommodations,
    onClose,
    onSubmit,
    isLoading,
  } ) => {
    const initialData = {
      accommodationsId: _.map(
        _.get( data, 'data', [] ), ( item ) => ( {
          ...item,
          label: item.name,
          value: item.id,
        } ),
      ),
    };

    return (
      <ModalForm
        initialValues={initialData}
        title="addAccommodations"
        showErrorsInFooter
        isLoading={isLoading}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        bodyClassName="overflow-visible"
        zIndex={9999}
        body={( ) => (
          <>
            <Row>
              <Col
                md={12}
              >
                <AsyncSelect
                  field="accommodationsId"
                  label="properties"
                  multiple
                  defaultOptions
                  validate={required}
                  loadOptions={onFetchAccommodations}
                />
              </Col>
            </Row>
          </>
        )}
      />
    );
  },
);
