import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import FeesService from 'api/Fees';
import { formatDataToSend, handlePromise } from 'utils';
import EditFeeView from 'views/AdminRates/PropertyManager/Fees/edit';

const EditFeeContainer = ( {
  elementToEdit,
  onClose,
  onReloadData,
  toggleInfoAlert,
  toggleErrorAlert,
  toggleLoading,
} ) => {
  const [enabled, setEnabled] = useState( elementToEdit?.enabled );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const datesSend = formatDataToSend( formData, form );

      const dataToSend = {
        ...datesSend,
        enabled: enabled ? 'true' : 'false',
      };

      const [dataErrors, dataResponse] = await handlePromise(
        elementToEdit
          ? FeesService.putFee( elementToEdit.id, dataToSend )
          : FeesService.postFee( dataToSend ),
      );

      toggleLoading( false );
      if ( !dataResponse.ok ) {
        toggleErrorAlert();
        return dataErrors;
      }

      toggleInfoAlert( 'dataSaved' );
      onReloadData();
    },
    [elementToEdit, onReloadData, toggleErrorAlert, toggleInfoAlert, toggleLoading, enabled],
  );

  return (
    <EditFeeView
      elementToEdit={elementToEdit}
      submitForm={submitForm}
      onClose={onClose}
      enabled={enabled}
      setEnabled={setEnabled}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( EditFeeContainer );
