import api from 'config/Api/Api';

const baseEndpoint = ( countryId, stateId ) => `/countries/${countryId}/states/${stateId}/cities`;

export default {
  getCities( countryId, stateId, params ) {
    return api.get( baseEndpoint( countryId, stateId ), params );
  },

  getCity( countryId, stateId, id ) {
    return api.get( `${baseEndpoint( countryId, stateId )}/${id}` );
  },

  saveCity( countryId, stateId, data ) {
    return api.post( baseEndpoint( countryId, stateId ), data );
  },

  updateCity( countryId, stateId, id, data ) {
    return api.put( `${baseEndpoint( countryId, stateId )}/${id}`, data );
  },
};
