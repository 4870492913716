import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';

const DELETE_SEASON_OCCUPANCY = gql`
  mutation DeleteSeasonOccupancy($seasonOccupancyId: ID!) {
    deleteSeasonOccupancy(seasonOccupancyId: $seasonOccupancyId) {
      ... on BaseError {
        message
      }
      ... on MutationDeleteSeasonOccupancySuccess {
        data {
          success
        }
      }
    }
  }
`;

const deleteSeasonOccupancy = async ( seasonOccupancyId ) => {
  const { data } = await client.query( {
    query: DELETE_SEASON_OCCUPANCY,
    variables: {
      seasonOccupancyId,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteSeasonOccupancy.message ) {
    return {
      ok: false,
      message: data.deleteSeasonOccupancy.message,
    };
  }
  const deleteSuccessful = data.deleteSeasonOccupancy.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteSeasonOccupancy;
