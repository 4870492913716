import React from 'react';
import Drawer from 'rc-drawer';
import {
  Intl,
  Form, FormActionsBtns,
  TextField, Switch, ConfirmationModal,
} from 'components';
import { Col, Row } from 'reactstrap';
import { required } from 'config/InputErrors';
import StagesEditor from './stagesEditor';

const PipelineEditView = ( {
  open, onClose, data, stages, setStages, onSubmit, isDefault,
  confirmationModalOpened, setConfirmationModalOpened, confirmationModalTitle, forceSubmit,
} ) => (
  <>

    <Drawer
      open={open}
      placement="right"
      handler={false}
      maskClosable
      level={null}
      onClose={onClose}
      className="zindex-drawer"
      width={600}
    >
      {open ? (
        <div className="p-4">
          <h2>
            <Intl id={data ? 'crm_pipelines_edit' : 'crm_pipelines_new'} />
          </h2>
          <hr className="mt-0" />
          <Form
            initialValues={data}
            showErrorsInFooter
            onSubmit={onSubmit}
            body={( ) => (
              <>
                <Row className="mb-3">
                  <Col md={9}>
                    <TextField
                      label="name"
                      field="name"
                      placeholder="name"
                      disabled={isDefault}
                      validate={!isDefault ? required : null}
                    />
                  </Col>
                  {data ? (
                    <Col md={3}>
                      <Switch field="isActive" label="active" disabled={isDefault} />
                    </Col>
                  ) : null}
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <TextField
                      field="description"
                      label="description"
                      type="textarea"
                      rows={6}
                      validate={required}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <StagesEditor
                      stages={stages}
                      setStages={setStages}
                    />
                    {/*  <TextField
                      field="stages"
                      label="crm.pipelines.stages"
                      type="textarea"
                      rows={6}
                      validate={required}
                    />
                    <span className="mt-1 help-text">
                      <Intl id="crm.pipelines.stages.help" />
                    </span> */}
                  </Col>
                </Row>
              </>
            )}
            footer={<FormActionsBtns onClickCancel={onClose} />}
          />
        </div>
      ) : null}
      <ConfirmationModal
        open={confirmationModalOpened}
        title={confirmationModalTitle}
        onConfirm={forceSubmit}
        onClose={() => setConfirmationModalOpened( false )}
      />
    </Drawer>

  </>
);

export default PipelineEditView;
