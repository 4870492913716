import React, { useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AccommodationDashboardView from 'views/Accommodation/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import FiltersActions from 'store/reducers/Filters';
import { useDelete, useFetchTableData } from 'hooks';
import AccommodationService from 'api/Accommodation';
import { handlePromise } from 'utils';

const AccommodationDashboard = ( {
  parentPath, defaultFilters, toggleErrorAlert, toggleLoading, updateFilters, setFiltersError,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const initialReloadError = useRef( true );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: AccommodationService.getAllAccommodations,
    toggleErrorAlert,
    deps: [dataSubmitted],
    callback: ( params ) => {
      initialReloadError.current = false;
      updateFilters( 'accommodations', { keyword: params.keyword } );
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'accommodations' );
        initialReloadError.current = false;
      }
    },
  } );

  const deleteEntity = useDelete( {
    promise: ( { propertyId, roomTypeId, id } ) => AccommodationService.deleteAccommodation(
      propertyId, roomTypeId, id,
    ),
    callback: () => { setDataSubmitted( new Date().valueOf() ); },
    toggleLoading,
    toggleErrorAlert,
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const handleChangeCleaningStatus = useCallback( async ( propertyId, roomTypeId, accommodationId,
    cleaningStatusSelected ) => {
    toggleLoading( true );

    const [errors, response] = await handlePromise(
      AccommodationService.updateAccommodation( propertyId, roomTypeId, accommodationId,
        { cleaningStatus: cleaningStatusSelected } ),
    );

    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( errors );
    reloadData();
  }, [toggleErrorAlert, toggleLoading, reloadData] );

  return (
    <AccommodationDashboardView
      data={data}
      parentPath={parentPath}
      isLoading={dataLoading}
      defaultFilters={defaultFilters}
      onFetchData={fetchData}
      onDelete={deleteEntity}
      onChangeCleaningStatus={handleChangeCleaningStatus}
    />
  );
};

const mapStateToProps = ( { filters } ) => ( {
  defaultFilters: _.get( filters, 'filters.accommodations' ) || {},
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateFilters: FiltersActions.updateFilters,
  setFiltersError: FiltersActions.setFiltersError,
} );

export default connect( mapStateToProps, mapDispatchToProps )( AccommodationDashboard );
