import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const IconTooltip = ( {
  icon, text, id, type, onClick, className,
} ) => (
  <span onClick={onClick} className={className}>
    <i className={`${icon} ml-3 ic-calendar`} id={`${type}-${id}`} />
    <UncontrolledTooltip delay={0} target={`${type}-${id}`}>
      {text}
    </UncontrolledTooltip>
  </span>
);

export const ExtraBed = ( { id, className } ) => (
  <IconTooltip
    icon="fas fa-bed"
    text="extra bed"
    type="extra_bed"
    id={id}
    className={className}
  />
);

export const Star = ( { id, className } ) => (
  <IconTooltip
    icon="fas fa-star"
    text="starred"
    type="star"
    id={id}
    className={className}
  />
);

export const Cradle = ( { id, className } ) => (
  <IconTooltip
    icon="fas fa-dolly-flatbed"
    text="Cradle"
    type="cradle"
    id={id}
    className={className}
  />
);

export const Info = ( { id, text, className } ) => (
  <IconTooltip
    icon="fas fa-info-circle"
    text={text}
    type="info"
    id={id}
    className={className}
  />
);

export const Delete = ( { id, onClick, className } ) => (
  <IconTooltip
    icon="fas fa-trash"
    text="Delete"
    type="delete"
    id={id}
    onClick={onClick}
    className={className}
  />
);
