import React, { useContext } from 'react';
import { BusinnesCRMContext } from 'containers/CRM/Business/Dashboard/context';
import { Intl } from 'components';
import KanvasContextProvider, { KanvasContext } from './Context';
import KanvanColumn from './Column';

const KanvanBoard = ( { onDeleteBusiness } ) => {
  const {
    columns,
    description,
  } = useContext( KanvasContext );

  return (
    <>
      {description ? <div className="kanban-description mb-4">{description}</div> : null}
      <div className="kanban-board">
        <div className="kanban-row">
          {columns.map( ( column, k ) => (
            <KanvanColumn
              key={column.id}
              column={column}
              onDeleteBusiness={onDeleteBusiness}
              first={k === 0}
            />
          ) )}
        </div>
      </div>
    </>
  );
};

const ViewKanbanBusiness = ( { data, onDeleteBusiness } ) => {
  const {
    pipelineSelected,
  } = useContext( BusinnesCRMContext );

  return (
    <div className="p-4 border-top">
      {pipelineSelected ? (
        <KanvasContextProvider pipelineSelected={pipelineSelected} data={data}>
          <KanvanBoard onDeleteBusiness={onDeleteBusiness} />
        </KanvasContextProvider>
      ) : (
        <h3 className="text-center font-italic opacity-8 font-weight-normal p-4">
          <Intl id="crm.business.noPipelineSelected" />
        </h3>
      )}
    </div>
  );
};
export default ViewKanbanBusiness;
