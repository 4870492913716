import React from 'react';
import { connect } from 'react-redux';

import RoomTypeEditView from 'views/Property/RoomType/Edit';
import SettingsActions from 'store/reducers/Settings';
import RoomTypeService from 'api/RoomType';
import { useFetch, useSubmit } from 'hooks';

const RoomTypeEdit = ( {
  propertyData, paths, parentIds, history, match,
  toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const entityId = match.params.id;

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => RoomTypeService.getRoomType( parentIds.property, entityId ),
    toggleErrorAlert,
    conditional: !!entityId,
    deps: [entityId],
  } );

  const submitForm = useSubmit( {
    promise: ( dataToSend ) => ( entityId
      ? RoomTypeService.updateRoomType( parentIds.property, entityId, dataToSend )
      : RoomTypeService.saveRoomType( parentIds.property, dataToSend ) ),
    toggleLoading,
    toggleInfoAlert,
    callback: () => history.push( paths.roomType ),
  } );

  return (
    <RoomTypeEditView
      data={data}
      entityId={entityId}
      propertyName={propertyData.name}
      paths={paths}
      isLoading={dataLoading}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( RoomTypeEdit );
