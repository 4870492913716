import React, { useState, useEffect } from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';
import classnames from 'classnames';
import {
  SimpleHeader,
  EditCard,
  Form,
  TextField,
  Select,
  Switch,
  FormActionsBtns,
  Tags,
  Intl,
  AsyncSelect,
} from 'components';
import { required, validUrl } from 'config/InputErrors';
import SearchPlace from 'containers/PointOfInterest/Search';
import { setValForm, defaultLocation } from '../utils';
import Map from '../Map';

export default React.memo( ( {
  entityId, data, parentPath, isLoading, onSubmit,
  onFetchPoiCategories, options, onChangeCountry, onChangeState,
  countriesLoading, statesLoading, citiesLoading,
} ) => {
  const [dataForm, setDataForm] = useState( {} );
  const [lat, setLat] = useState( defaultLocation.lat );
  const [lng, setLng] = useState( defaultLocation.lng );

  useEffect( () => {
    if ( data && data.latitude ) {
      setLat( data.latitude );
      setLng( data.longitude );

      const df = {
        ...data,
        category: data && data.category
          ? { value: data.category.id, label: data.category.name }
          : {},
        tags: data && data.tags ? data.tags.split( ',' ) : [],
      };
      setDataForm( df );
    }
  }, [data] );

  return (
    <>
      <SimpleHeader
        parentLinks={[{ name: 'pointsOfInterest', href: parentPath }]}
      />
      <Container
        fluid
        className={classnames( { 'app-component-loading': isLoading } )}
      >
        <EditCard title={entityId ? 'editPointOfInterest' : 'newPointOfInterest'}>
          <Form
            initialValues={dataForm}
            onSubmit={onSubmit}
            showErrorsInFooter
            mutators={{ setValForm }}
            body={( form ) => (
              <Row className="justify-content-stretch">
                <Col md={4}>
                  <div style={{ minHeight: '450px' }}>
                    <Row>
                      <Col md={12}>
                        <SearchPlace
                          onResult={( res ) => {
                            if ( res ) {
                              form.mutators.setValForm( 'name', res.name );
                              form.mutators.setValForm( 'address', res.address );
                              form.mutators.setValForm( 'latitude', res.lat );
                              form.mutators.setValForm( 'longitude', res.lng );
                              setLat( res.lat );
                              setLng( res.lng );
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={12}>
                        <TextField
                          field="name"
                          label="name"
                          validate={required}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          field="address"
                          label="address"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <TextField
                          field="latitude"
                          label="latitude"
                          validate={required}
                          onChange={( v ) => {
                            setLat( v );
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          field="longitude"
                          label="longitude"
                          validate={required}
                          onChange={( v ) => {
                            setLng( v );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          field="website"
                          label="website"
                          type="url"
                          validate={validUrl}
                        />
                      </Col>
                      <Col md={12}>
                        <AsyncSelect
                          field="category"
                          label="category"
                          validate={required}
                          defaultOptions
                          loadOptions={onFetchPoiCategories}
                        />
                      </Col>
                      <Col md={12}>
                        <Tags
                          field="tags"
                          label="tags"
                          formGroupClass="mb-0"
                        />
                        <small className="form-text text-muted mb-3">
                          <Intl id="inputTagsHelpLabel" />
                        </small>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Select
                          field="country"
                          label="country"
                          isLoading={countriesLoading}
                          options={options.countries}
                          validate={entityId ? () => null : required}
                          onChange={( countryId ) => {
                            form.change( 'state', null );
                            form.change( 'city', null );
                            onChangeCountry( countryId );
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Select
                          field="state"
                          label="state"
                          isLoading={statesLoading}
                          options={options.states}
                          validate={entityId ? () => null : required}
                          onChange={( stateId ) => {
                            form.change( 'city', null );
                            if ( form.getFieldState( 'country' ) ) {
                              const countryId = form.getFieldState( 'country' ).value.id
                                ? form.getFieldState( 'country' ).value.id
                                : form.getFieldState( 'country' ).value;
                              onChangeState( stateId, countryId );
                            }
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Select
                          field="city"
                          label="city"
                          isLoading={citiesLoading}
                          options={options.cities}
                          validate={entityId ? () => null : required}
                        />
                      </Col>
                      <Col md={6}>
                        <Switch
                          field="enabled"
                          label="enabled"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={8}>
                  <Map
                    lat={lat}
                    lng={lng}
                    onChange={( position ) => {
                      form.mutators.setValForm( 'latitude', position[0] );
                      form.mutators.setValForm( 'longitude', position[1] );
                    }}
                  />
                </Col>
              </Row>
            )}
            footer={<FormActionsBtns cancelTo={parentPath} />}
          />

        </EditCard>
      </Container>
    </>
  );
} );
