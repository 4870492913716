/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  Button,
  Row,
  Col,
} from 'reactstrap';

import {
  Form, TextField, Intl, Auth,
} from 'components';
import { composeValidators, required, email } from 'config/InputErrors';

export default React.memo( ( {
  pmData, uiVisible, isLoading, workspaceVisible, onSubmit, onSubmitWorkspace,
} ) => (
  // eslint-disable-next-line no-nested-ternary
  uiVisible
    ? (
      workspaceVisible
        ? (
          <Auth
            header={(
              <h1 className="mb-0 font-weight-500 text-dark-2 mt-2">
                <Intl id="connectWorkspace" />
              </h1>
            )}
            msg={<Intl id="typeWorkspaceName" />}
          >
            <Form
              onSubmit={onSubmitWorkspace}
              showErrorsInFooter
              body={() => (
                <>
                  <Row className={classnames( { 'app-component-loading': isLoading } )}>
                    <Col md={9} className="m-auto">
                      <TextField
                        field="workspace"
                        validate={required}
                        placeholder="yourWorkspacePlaceholder"
                        size="md"
                        formGroupClass="m-0"
                      />
                    </Col>
                  </Row>
                  {/* <p className="text-center text-sm">
                    <span className="text-gray-8">your-workspace</span>
                    <span className="text-dark-1">.threeangular.com</span>
                  </p> */}
                </>
              )}
              footer={(
                <Row>
                  <Col md={9} className="mt-3 mx-auto">
                    <Button color="primary" className="text-md" block>
                      <Intl id="connect" />
                    </Button>

                    <div className="mt-4 text-center">
                      <p className="text-gray-8 m-0">
                        <Intl id="forgetWorkspaceName" />
                      </p>
                      <a href="mailto:support@nomadssoftware.com" target="_blank" rel="noopener noreferrer" className="text-bold">
                        <Intl id="contactSupport" />
                      </a>
                    </div>
                  </Col>
                </Row>
              )}
            />
          </Auth>
        )
        : (
          <Auth
            header={(
              <>
                <h1 className="mb-0 font-weight-500 text-dark-2 mt-2">
                  <Intl id="connectWorkspace" />
                  <p className="m-0">
                    <Intl id="workspace" />
                    {': '}
                    {pmData.name}
                  </p>
                </h1>
              </>
            )}
            msg={<Intl id="typeEmailPassword" />}
          >
            <Form
              onSubmit={onSubmit}
              showErrorsInFooter
              body={() => (
                <>
                  <Row>
                    <Col md={9} className="m-auto">
                      <TextField
                        field="email"
                        validate={composeValidators( required, email )}
                        placeholder="typeYourEmail"
                        size="md"
                      />
                      <TextField
                        field="password"
                        type="password"
                        validate={required}
                        placeholder="typeYourPassword"
                        size="md"
                        formGroupClass="m-0"
                      />
                    </Col>
                  </Row>
                </>
              )}
              footer={(
                <Row>
                  <Col md={9} className="mt-3 mx-auto">
                    <Button color="primary" className="text-md" block>
                      <Intl id="connect" />
                    </Button>

                    <div className="text-center pt-3">
                      <Link to="/auth/forgot-password" className="text-bold text-sm">
                        <Intl id="forgetPassword" />
                      </Link>
                    </div>
                  </Col>
                </Row>
              )}
            />
          </Auth>
        )
    )
    : null
) );
