import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import CountryDashboardView from 'views/Country/Dashboard';
import CountryService from 'api/Country';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import CountryEdit from '../Edit';

const CountryDashboard = ( { toggleErrorAlert } ) => {
  const toEditId = useRef( null );
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const openEditModal = useCallback( ( { id } = {} ) => {
    toEditId.current = id;
    setModalOpened( true );
  }, [] );

  const closeEditModal = useCallback( () => {
    setModalOpened( false );
  }, [] );

  const reloadData = useCallback( () => {
    closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [closeEditModal] );

  const [data, dataLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  return (
    <>
      <CountryDashboardView
        isLoading={dataLoading}
        data={data}
        onOpenEditModal={openEditModal}
      />

      <CountryEdit
        countryId={toEditId.current}
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( CountryDashboard );
