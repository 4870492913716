import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import EditShiftView from 'views/Resource/Edit/Shift/edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import ShiftService from 'api/Shift';
import PropertyService from 'api/Property';
import TeamService from 'api/Team';
import { formatDataToSend, handlePromise } from 'utils';

const EditShiftContainer = ( {
  entityId,
  entityData,
  shiftId,
  modalOpened,
  onClose,
  onReloadData,
  toggleErrorAlert,
  toggleInfoAlert,
  toggleLoading,
} ) => {
  const [shiftToEdit, shiftToEditLoading] = useFetch( {
    initialState: {},
    promise: () => ShiftService.getShift( shiftId ),
    format: ( dataToFormat ) => {
      if ( !shiftId ) return dataToFormat;
      let location = '';
      if ( dataToFormat.location ) {
        location = {
          value: dataToFormat.location.id,
          label: `${dataToFormat.location.name} (${dataToFormat.location.address})`,
        };
      }

      return {
        ...dataToFormat,
        location,
      };
    },
    toggleErrorAlert,
    conditional: !!shiftId && modalOpened,
    deps: [shiftId, modalOpened],
    reInit: true,
  } );

  const [teamsListComplete, teamsLoading] = useFetch( {
    initialState: {},
    promise: () => TeamService.getTeams( {
      elementsPerPage: 10,
      keyword: '',
    } ),
    toggleErrorAlert,
    conditional: modalOpened,
    deps: [modalOpened],
  } );

  const fetchProperties = useCallback( ( query ) => PropertyService.getProperties( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const teams = useMemo( () => {
    if ( Object.keys( teamsListComplete ).length <= 0 ) {
      return [];
    }
    if ( teamsListComplete?.data && teamsListComplete.data.length ) {
      const teamsSelected = teamsListComplete.data.filter( ( team ) => {
        if ( !team?.members || !team.members.length ) {
          return false;
        }

        const members = team.members.filter( ( member ) => member.id === entityData.entity.id );

        return members.length > 0;
      } );
      return teamsSelected;
    }

    return teamsListComplete?.data;
  }, [teamsListComplete, entityData] );

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );
    const dataToSend = formatDataToSend( formData, form );

    const formattedData = { ...dataToSend, assigned: entityId };

    if ( shiftToEdit?.date ) {
      formattedData.date = shiftToEdit?.date;
    } else {
      formattedData.date = dataToSend.date.format( 'YYYY-MM-DD' );
      formattedData.from = dataToSend.from.format( 'H:mm' );
      formattedData.till = dataToSend.from.format( 'H:mm' );
    }

    const [errors, response] = await handlePromise(
      shiftId
        ? ShiftService.updateShift( shiftId, formattedData )
        : ShiftService.saveShift( formattedData ),
    );

    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
    return onReloadData();
  }, [entityId, shiftId, shiftToEdit, onReloadData, toggleInfoAlert, toggleLoading] );

  return (
    <EditShiftView
      isLoading={shiftToEditLoading || teamsLoading}
      shiftId={shiftId}
      shiftToEdit={shiftToEdit}
      submitForm={submitForm}
      onClose={onClose}
      teams={teams}
      onFetchProperties={fetchProperties}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( EditShiftContainer );
