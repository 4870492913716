import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import CityAppImageDashboardView from 'views/CityAppImages/Dashboard';
import cityAppImagesService from 'api/cityAppImages';
import SettingsActions from 'store/reducers/Settings';

import { useDelete } from 'hooks';
import useFetchTableData from 'hooks/useFetchTableData';
import CityAppImageEdit from '../Edit';

const CityAppImageDashboard = ( { toggleErrorAlert, toggleLoading } ) => {
  const [modalOpened, setModalOpened] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const closeEditModal = useCallback( () => setModalOpened( false ), [] );

  const reloadData = useCallback( () => {
    closeEditModal();
    setDataSubmitted( new Date().valueOf() );
  }, [closeEditModal] );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: cityAppImagesService.getCityAppImages,
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const openEditModal = useCallback( () => {
    setModalOpened( true );
  }, [] );

  const deleteCityAppImage = useDelete( {
    promise: cityAppImagesService.deleteImage,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <>
      <CityAppImageDashboardView
        data={data}
        isLoading={dataLoading}
        onFetchData={fetchData}
        onOpenEditModal={openEditModal}
        onDelete={deleteCityAppImage}
      />

      <CityAppImageEdit
        modalOpened={modalOpened}
        onCloseModal={closeEditModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( CityAppImageDashboard );
