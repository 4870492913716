import React, { useEffect, useState } from 'react';
import { useFetch } from 'hooks';
import { connect } from 'react-redux';
import _ from 'lodash';
import RUSettings from 'api/Settings/RUSettings';
import api from 'api/RentalsUnited';

const RU_URL = 'https://new.rentalsunited.com/white-pms-client/script';

const formatToRUSetting = ( responseData ) => {
  const { data: RUSettingsData } = responseData;
  return {
    username: RUSettingsData[0].apiUsername,
    password: RUSettingsData[0].apiPassword,
  };
};

const ChannelManager = ( { propertyManager } ) => {
  const [accessToken, setAccessToken] = useState();
  const [refreshToken, setRefreshToken] = useState();

  const [data] = useFetch( {
    promise: () => RUSettings.getRUSettings( propertyManager.id ),
    format: formatToRUSetting,
    deps: [propertyManager],
  } );

  useEffect( () => {
    const script = document.createElement( 'script' );
    const jqueryCDN = document.createElement( 'script' );
    jqueryCDN.src = 'https://code.jquery.com/jquery-3.7.1.js';
    jqueryCDN.crossOrigin = 'anonymous';

    script.src = accessToken && refreshToken
      ? `${RU_URL}?token=${accessToken}&refreshToken=${refreshToken}&languageId=5`
      : '';

    document.head.appendChild( jqueryCDN );
    document.head.appendChild( script );

    return () => {
      document.head.removeChild( script );
      document.head.removeChild( jqueryCDN );
    };
  }, [accessToken, refreshToken] );

  useEffect( () => {
    if ( data ) {
      api.getTokens(
        `grant_type=password&username=${data.username}&password=${data.password}`,
      ).then( ( response ) => {
        setAccessToken( response.data.access_token );
        setRefreshToken( response.data.refresh_token );
      } );
    }
  }, [data] );

  return (
    <div id="ruApp"> </div>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager' ),
} );

export default connect( mapStateToProps )( ChannelManager );
