/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  Select,
} from 'components';
import MonthView from 'containers/PropertyManager/Edit/Rates/EditDrawer/RateSeasons/Month';
import WeekView from 'containers/PropertyManager/Edit/Rates/EditDrawer/RateSeasons/Week';
import DaysView from 'containers/PropertyManager/Edit/Rates/EditDrawer/RateSeasons/Day';
import { Container } from 'reactstrap';
import SeasonsView from './Seasons';

export default React.memo(
  ( {
    rateId,
    data,
    dataSubmitted,
    viewTypes,
    onFetchData,
    onDeleteItem,
    onReloadRates,
    onOpenEditItemModal,
    isDataLoading,
  } ) => {
    const [selectedView, setSelectedView] = useState( 1 );
    return (
      <>
        <Container>
          <Select
            overlap
            label="viewBy"
            options={viewTypes}
            defaultValue={viewTypes[0]}
            value={selectedView}
            onChange={( item ) => {
              setSelectedView( item );
            }}
            translateOptions
          />
        </Container>
        <SeasonsView
          visible={selectedView === 1}
          isDataLoading={isDataLoading}
          data={data}
          onFetchData={onFetchData}
          onDeleteItem={onDeleteItem}
          onOpenEditItemModal={onOpenEditItemModal}
        />
        <MonthView
          visible={selectedView === 2}
          rateId={rateId}
          onReloadRates={onReloadRates}
          onOpenEditItemModal={onOpenEditItemModal}
        />
        <WeekView
          rateId={rateId}
          visible={selectedView === 3}
          dataEdited={dataSubmitted}
          onReloadRates={onReloadRates}
          onOpenEditItemModal={onOpenEditItemModal}
        />
        <DaysView
          rateId={rateId}
          visible={selectedView === 4}
          dataEdited={dataSubmitted}
          onReloadRates={onReloadRates}
          onOpenEditItemModal={onOpenEditItemModal}
        />
      </>
    );
  },
);
