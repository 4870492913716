import { useCallback } from 'react';

import { formatDataToSend, handlePromise } from 'utils';

export default ( {
  promise, callback, format, deps = [], translations, toggleLoading, toggleInfoAlert,
} ) => useCallback( async ( formData, form ) => {
  toggleLoading( true );

  let dataToSend = formatDataToSend( formData, form, !!translations );
  if ( format ) dataToSend = format( dataToSend );
  const [errors, response, responseData] = await handlePromise( promise( dataToSend ) );
  toggleLoading( false );

  if ( !response.ok ) return errors || response.message;
  toggleInfoAlert( 'dataSaved' );
  if ( callback ) callback( formData, responseData );
}, [...deps] ); //eslint-disable-line
