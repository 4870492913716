import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { required } from 'config/InputErrors';
import {
  TextField, Intl, ModalForm,
} from 'components';

export default ( { open, onClose, onSubmit } ) => (
  <ModalForm
    title="confirmTravelReportReject"
    open={open}
    size="md"
    bodyClassName="overflow-visible"
    // bodyStyle={{ minHeight: 133 }}
    onClose={onClose}
    showErrorsInFooter
    onSubmit={onSubmit}
    body={() => (
      <>
        <Row>
          <Col>
            <TextField
              field="rejectedReason"
              label="reasonForRejection"
              type="textarea"
              validate={required}
              rows={4}
            />
          </Col>
        </Row>
      </>
    )}
    footer={(
      <>
        <Button
          color="default"
          outline
          className="mr-3"
          onClick={onClose}
        >
          <Intl id="cancel" />
        </Button>
        <Button color="danger">
          <Intl id="reject" />
        </Button>
      </>
    )}
  />
);
