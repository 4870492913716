import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import WifiView from 'views/Property/RoomType/Accommodation/Edit/Wifi';
import SettingsActions from 'store/reducers/Settings';
import wifiService from 'api/Wifi';
import { formatDataToSend, handlePromise } from 'utils';
import _get from 'lodash/get';

const Wifi = ( {
  data, entityId, paths, parentIds,
  toggleLoading, toggleInfoAlert,
} ) => {
  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const dataToSend = formatDataToSend( formData, form );
      const formattedData = { ...dataToSend };
      const propertyId = parentIds ? parentIds.property : formattedData.property;
      const roomTypeId = parentIds ? parentIds.roomType : formattedData.roomType;

      formattedData.enabled = dataToSend.enabled ? 'true' : 'false';

      const [errors, response] = await handlePromise(
        _get( data, 'entity.wifi.id' )
          ? wifiService.updateWifi(
            propertyId, roomTypeId, entityId, formattedData,
          )
          : wifiService.saveWifi(
            propertyId, roomTypeId, entityId, formattedData,
          )
        ,
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [
      toggleInfoAlert, toggleLoading, data, entityId, parentIds,
    ],
  );

  return (
    <WifiView
      name={data.entity ? data.entity.name : ''}
      data={_get( data, 'entity.wifi', {} )}
      paths={paths}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( Wifi );
