import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CustomerAppEditView from 'views/OwnerApp';
import SettingsActions from 'store/reducers/Settings';
import OwnerAppService from 'api/Settings/OwnerApp';
import { useFetch } from 'hooks';
import { formatDataToSend, handlePromise } from 'utils';

// const formatTranslation = ( _data, field ) => {
//   const translationFieldData = _.filter( _data, ( o ) => o.field === field );
//   if ( translationFieldData[0] ) {
//     return _.keys( translationFieldData[0].trans ).map( ( k ) => ( {
//       field: k,
//       trans: translationFieldData[0].trans[k],
//     } ) );
//   }
//   return [];
// };

const formatTranlationWitoutModules = ( allTranslationData ) => allTranslationData.reduce(
  ( acc, { lang, data: translationsData } ) => ( [
    ...acc,
    {
      lang,
      data: translationsData,
    },
  ] ), [],
);

const CustomerAppSettingsEdit = ( {
  propertyManager, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => OwnerAppService.getOwnerApp( { propertyManager: propertyManager.id } ),
    toggleErrorAlert,
    conditional: !!propertyManager.id,
    deps: [propertyManager.id, dataSubmitted],
  } );

  const customerAppId = _.get( data, 'id', false );
  const [dataTranslation, dataTranslationLoading] = useFetch( {
    initialState: {},
    promise: () => OwnerAppService.getTranslation( customerAppId ),
    toggleErrorAlert,
    conditional: !!customerAppId,
    deps: [customerAppId, dataSubmitted],
  } );

  const isLoadingData = () => {
    if ( dataLoading === undefined ) return undefined;
    if ( dataTranslationLoading === undefined ) return undefined;
    if ( dataLoading ) return true;
    if ( dataTranslationLoading ) return true;
    return false;
  };

  const formatData = () => {
    let translations = _.merge(
      dataTranslation,
    );
    translations = _.isArray( translations ) ? {} : translations;
    const _data = {
      ...data,
      translations,
    };
    return _data;
  };

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );
    const translation = true;
    const dataToSend = formatDataToSend( formData, form, translation );
    dataToSend.contracts = dataToSend.contracts ? 'true' : 'false';
    dataToSend.payments = dataToSend.payments ? 'true' : 'false';
    dataToSend.smartLockerShared = dataToSend.smartLockerShared ? 'true' : 'false';
    dataToSend.reportIssues = dataToSend.reportIssues ? 'true' : 'false';
    dataToSend.showInventory = dataToSend.showInventory ? 'true' : 'false';
    dataToSend.showTutorials = dataToSend.showTutorials ? 'true' : 'false';

    if ( dataToSend.weekFrom && dataToSend.weekFrom.format ) {
      dataToSend.weekFrom = dataToSend.weekFrom.format( 'HH:mm' );
    }

    if ( dataToSend.weekTill && dataToSend.weekTill.format ) {
      dataToSend.weekTill = dataToSend.weekTill.format( 'HH:mm' );
    }

    if ( dataToSend.weekendFrom && dataToSend.weekendFrom.format ) {
      dataToSend.weekendFrom = dataToSend.weekendFrom.format( 'HH:mm' );
    }

    if ( dataToSend.weekendTill && dataToSend.weekendTill.format ) {
      dataToSend.weekendTill = dataToSend.weekendTill.format( 'HH:mm' );
    }

    const allTranslationData = _.get( dataToSend, 'translations', [] );

    if ( allTranslationData.length > 0 ) {
      dataToSend.translations = formatTranlationWitoutModules( allTranslationData );
    }

    delete dataToSend.logo;
    delete dataToSend.logoBig;
    delete dataToSend.appIcon;
    delete dataToSend.defaultAccommodationPicture;
    delete dataToSend.bannerAppInvite;

    const [errors, response] = await handlePromise(
      OwnerAppService.updateOwnerApp( dataToSend ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( _.get( formData.services, 'banner.file' ) ) {
      const serviceBannerToSend = {
        image: formData.services.banner.file,
        propertyName: 'services',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        OwnerAppService.updateOwnerAppImage( serviceBannerToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'logo.file' ) ) {
      const logoToSend = {
        image: formData.logo.file,
        propertyName: 'logo',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        OwnerAppService.updateOwnerAppImage( logoToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'appIcon.file' ) ) {
      const appIconToSend = {
        image: formData.appIcon.file,
        propertyName: 'appIcon',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        OwnerAppService.updateOwnerAppImage( appIconToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'bannerAppInvite.file' ) ) {
      const bannerAppInviteToSend = {
        image: formData.bannerAppInvite.file,
        propertyName: 'bannerAppInvite',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        OwnerAppService.updateOwnerAppImage( bannerAppInviteToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'logoBig.file' ) ) {
      const logoBigToSend = {
        image: formData.logoBig.file,
        propertyName: 'logoBig',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        OwnerAppService.updateOwnerAppImage( logoBigToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'defaultAccommodationPicture.file' ) ) {
      const defaultAccommodationPictureToSend = {
        image: formData.defaultAccommodationPicture.file,
        propertyName: 'defaultAccommodationPicture',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        OwnerAppService.updateOwnerAppImage( defaultAccommodationPictureToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }
    setDataSubmitted( new Date().valueOf() );
    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
  }, [propertyManager.id, toggleInfoAlert, toggleLoading] );

  return (
    <CustomerAppEditView
      data={formatData()}
      isLoading={isLoadingData()}
      onSubmit={submitForm}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );
export default connect( mapStateToProps, mapDispatchToProps )( CustomerAppSettingsEdit );
