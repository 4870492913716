import React from 'react';
import { connect } from 'react-redux';

import PropertyManagerService from 'api/PropertyManager';
import PropertyManagerNewView from 'views/PropertyManager/New';
import SettingsActions from 'store/reducers/Settings';
import { useSubmit } from 'hooks';

const PropertyManagerNew = ( {
  modalOpened, onCloseModal, onReload, toggleLoading, toggleInfoAlert,
} ) => {
  const submitForm = useSubmit( {
    promise: PropertyManagerService.savePropertyManager,
    toggleLoading,
    toggleInfoAlert,
    callback: onReload,
  } );

  return (
    <PropertyManagerNewView
      open={modalOpened}
      onClose={onCloseModal}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( PropertyManagerNew );
