import React from 'react';

import Select from '../../Select';
import AsyncSelect from '../../AsyncSelect';

const TableSelectFilter = React.memo( ( {
  name, filters, setFilter, ...rest
} ) => {
  const SelectComponent = rest.loadOptions ? AsyncSelect : Select;
  return (
    <SelectComponent
      {...rest}
      name={name}
      formGroupClass="m-0"
      value={filters[name] || null}
      onChange={( value ) => setFilter( name, value )}
    />
  );
} );

export default TableSelectFilter;
