import moment from 'moment';

const getDateRangeLine = ( dateRange ) => {
  const totalDays = dateRange.end.diff( dateRange.start, 'days' ) + 1;
  const dateRangeLine = Array.from( new Array( totalDays ).keys() )
    .map(
      ( next ) => {
        const day = moment( dateRange.start, 'YYYY-MM-DD' )
          .add( next, 'day' );
        return {
          dayOfWeek: day.format( 'ddd' ),
          day: day.format( 'DD' ),
          date: day.format( 'YYYY-MM-DD' ),
          isWeekend: day.day() === 0 || day.day() === 6,
          isStartMonth: day.date() === 1,
          month: day.format( 'MMMM' ),
          year: day.format( 'YY' ),
          dateTime: day,
          value: day.valueOf(),
        };
      },
    );
  return dateRangeLine;
};

export default getDateRangeLine;
