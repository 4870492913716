import { gql } from '@apollo/client';
import client from 'config/Api/NewApi';
import { v4 as uuidv4 } from 'uuid';

const CREATE_DISCOUNT = gql`
  mutation CreateDiscount($input: CreateDiscountInput!) {
    createDiscount(input: $input) {
      ... on BaseError {
        message
      }
      ... on MutationCreateDiscountSuccess {
        data {
          newDiscount {
            id
          }
          success
        }
      }
    }
  }
`;

const createDiscount = async ( input ) => {
  const id = uuidv4();
  const { data } = await client.query( {
    query: CREATE_DISCOUNT,
    variables: {
      input: { ...input, id },
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.createDiscount.message ) {
    return {
      ok: false,
      message: data.createDiscount.message,
    };
  }
  const createSuccessful = data.createDiscount.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
    data: { ...data.createDiscount.data.newDiscount },
  };
};

export default createDiscount;
