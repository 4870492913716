import {
  AddBtn, AsyncTable, Intl, TableActionsBtns,
} from 'components';
import { canModify } from 'config/Ability';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const NotificationOwnersView = ( {
  isAdmin, dataTable, dataLoading, fetchData, onAddButton, onRespondButton,
} ) => {
  const canEditDelete = canModify( 'property_owner' );
  return (
    <>
      <AsyncTable
        id="resourceShifts"
        data={dataTable.data}
        total={dataTable.elementsTotal}
        isLoading={dataLoading}
        rightContent={canEditDelete && isAdmin && <AddBtn title="sendNotification" onClick={onAddButton} />}
        disableSorting
        onFetchData={fetchData}
        columns={[
          {
            Header: '#',
            accessor: 'id',
            Cell: ( { cell } ) => (
              <>
                {canEditDelete && !isAdmin ? (
                  <>
                    <a
                      href="/"
                      id={`tooltip-shift-${cell.value}`}
                      onClick={( e ) => {
                        e.preventDefault();
                        onRespondButton( cell.row.original );
                      }}
                    >
                      {cell.value.slice( 0, 8 )}
                    </a>
                    <UncontrolledTooltip
                      delay={0}
                      target={`tooltip-shift-${cell.value}`}
                    >
                      <Intl id="reply" />
                    </UncontrolledTooltip>
                  </>
                ) : (
                  <div>
                    {cell.row.original.id.slice( 0, 8 )}
                  </div>
                )}
              </>
            ),
          },
          {
            Header: 'Subject',
            accessor: 'case',
          },
          canEditDelete && !isAdmin
            ? {
              accessor: 'id',
              disableSorting: true,
              Header: null,
              maxWidth: 65,
              Cell: ( { cell } ) => {
                const actions = [];
                actions.push( {
                  label: 'reply',
                  onClick: () => onRespondButton( cell.row.original ),
                  'data-cy': 'table-edit-btn',
                } );

                return (
                  <TableActionsBtns actions={actions} />
                );
              },
            } : {
              id: 'id', Header: null, maxWidth: 1, disableSorting: true,
            },
        ]}
      />
    </>
  );
};

export default NotificationOwnersView;
