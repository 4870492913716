import React from 'react';
import { EditCard } from 'components';
import FinancePaymentContainer from 'containers/Resource/Edit/Finance/Payment';

const FinanceView = ( { entityId } ) => (
  <EditCard cardClass="card-sec-bottom px-0">
    <FinancePaymentContainer entityId={entityId} />
  </EditCard>
);

export default FinanceView;
