import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form,
  FormActionsBtns,
  Select,
  TextField,
  Intl,
  Datepicker,
} from 'components';
import { required } from 'config/InputErrors';

const EditFeeView = ( {
  elementToEdit, submitForm, onClose, feeOptions, feesLoading,
} ) => (
  <>
    <h3>
      <Intl id={elementToEdit ? 'editCharge' : 'newCharge'} />
      {' '}
      {elementToEdit?.id || ''}
    </h3>
    <hr className="mt-0" />
    <Form
      onSubmit={submitForm}
      showErrorsInFooter
      initialValues={elementToEdit}
      body={() => (
        <>
          <Row className="mb-4">
            <Col md={6}>
              <Select
                field="feeChosen"
                label="fee"
                options={feeOptions}
                isLoading={feesLoading}
                // validate={required}
                translateOptions
              />
            </Col>
            <Col md={5}>
              <Datepicker
                field="deadline"
                label="date"
                dateFormat="DD/MM/YYYY"
                formGroupClass="m-0"
                validate={required}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={3}>
              <TextField
                field="amount"
                label="amount"
                type="number"
                validate={required}
              />
            </Col>
            <Col md={3}>
              <Select
                field="currency"
                label="currency"
                validate={required}
                options={[
                  { id: 'USD', name: 'USD' },
                  { id: 'EUR', name: 'EUR' },
                ]}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <TextField
                field="description"
                label="description"
                type="text"
                validate={required}
              />
            </Col>
          </Row>
        </>
      )}
      footer={<FormActionsBtns onClickCancel={onClose} />}
    />
  </>
);

export default EditFeeView;
