import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import ForgotPasswordView from 'views/ForgotPassword';
import UserService from 'api/User';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';

const ForgotPassword = ( { toggleLoading } ) => {
  const submitForm = async ( formData, form ) => {
    toggleLoading( true );

    const [errors, response] = await handlePromise(
      UserService.requestResetPassword( formData.email ),
    );
    toggleLoading( false );
    if ( !response.ok ) return errors;
    form.initialize( {} );
  };
  const submitFormCallback = useCallback( submitForm, [] );

  return ( <ForgotPasswordView onSubmit={submitFormCallback} /> );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ForgotPassword );
