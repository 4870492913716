import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SettingsActions from 'store/reducers/Settings';
import { getActivities } from 'api/CRM/Contact';
import { useFetch } from 'hooks';
import ContactActivityView from 'views/CRM/Contact/Edit/Activity';
import { ContactEditContext } from '../context';
import processActivities from './processActivities';

const elementsPerPage = 20;

const ContactActivity = ( {
  toggleErrorAlert, contentLocale,
} ) => {
  const {
    contactId,
  } = useContext( ContactEditContext );

  const [page, setPage] = useState( 1 );

  const [data, dataLoading] = useFetch( {
    initialState: { data: [], elementsTotal: 0 },
    promise: () => getActivities( contactId, page, elementsPerPage ),
    format: ( activities ) => processActivities( activities, contentLocale ),
    toggleErrorAlert,
    conditional: !!contactId,
    deps: [contactId, page, contentLocale],
    reInit: true,
  } );

  return (
    <ContactActivityView
      data={data}
      isLoading={dataLoading}
      page={page}
      setPage={setPage}
      elementsPerPage={elementsPerPage}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  contentLocale: _.get( user, 'settings.contentLocale' ),
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ContactActivity );
