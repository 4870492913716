const arrayRange = ( start, stop, step ) => Array.from(
  { length: ( stop - start ) / step + 1 },
  ( _, index ) => start + index * step,
);

const getYearArray = () => {
  const years = arrayRange( 2021, new Date().getFullYear(), 1 );
  return years.map( ( year ) => ( {
    name: year, value: year, id: year,
  } ) ).reverse();
};

export default getYearArray;
