/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { FormGroup, InputGroup } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import debounce from 'debounce-promise';

import { Intl } from 'components';
import { handlePromise } from 'utils';
import InputGroupAddon from '../InputGroupAddon';
import {
  customStyles,
  IconOption,
  IconSingleValue,
  Clear,
} from '../SelectCustomComponents';

const AsyncSelectInput = React.memo(
  ( {
    label,
    formGroupClass,
    isInvalid,
    inputName,
    prependIcon,
    prependIconClass,
    appendIcon,
    appendIconClass,
    multiple,
    placeholder,
    size = 'md',
    value,
    onChange,
    inputOnChange,
    labelKey = 'name',
    idKey = 'id',
    translateOptions,
    intl,
    meta,
    translateValues,
    labelClass,
    loadOptions,
    filterFn,
    formatFn,
    ...rest
  } ) => {
    const [focused, setFocused] = useState( false );

    const load = useCallback(
      async ( query ) => {
        const [errors, response, responseData] = await handlePromise(
          loadOptions( query ),
        );

        return new Promise( ( resolve, reject ) => {
          if ( !response.ok ) return reject( errors );
          const options = filterFn
            ? filterFn( responseData.data )
            : formatFn
              ? formatFn( responseData )
              : responseData.data;

          resolve(
            _.map( options, ( option ) => ( {
              value: _.get( option, idKey ),
              // eslint-disable-next-line no-nested-ternary
              label: _.isFunction( labelKey )
                ? labelKey( option )
                : translateOptions
                  ? option[labelKey]
                    ? intl.formatMessage( { id: option[labelKey] } )
                    : option[labelKey]
                  : option[labelKey],
              icon: option.icon,
              original: option,
            } ) ),
          );
        } );
      },
      [idKey, labelKey, loadOptions, translateOptions, intl, filterFn, formatFn],
    );

    const debouncedLoadOptions = debounce( load, 250 );

    return (
      <FormGroup
        className={classnames( formGroupClass, { 'is-invalid': isInvalid } )}
      >
        {label && (
          <label className={classnames( labelClass, 'form-control-label' )}>
            <Intl id={label} />
          </label>
        )}
        <InputGroup
          data-cy={`input-${inputName}`}
          className={classnames( 'input-group-merge', {
            prepend: prependIcon || prependIconClass,
            append: appendIcon || appendIconClass,
            focused,
          } )}
        >
          {prependIcon || prependIconClass ? (
            <InputGroupAddon
              addonType="prepend"
              isInvalid={isInvalid}
              icon={prependIcon}
              iconClass={prependIconClass}
            />
          ) : null}

          <AsyncSelect
            {...rest}
            loadOptions={debouncedLoadOptions}
            isMulti={multiple}
            placeholder={
              placeholder ? intl.formatMessage( { id: placeholder } ) : null
            }
            onFocus={() => setFocused( true )}
            onBlur={() => setFocused( false )}
            styles={customStyles}
            classNamePrefix="custom-select"
            className={classnames( 'custom-select-container form-control', {
              'is-invalid': isInvalid,
              'form-control-lg': size === 'lg',
              'form-control-sm': size === 'sm',
            } )}
            onChange={( val ) => {
              if ( multiple || !val || val.value !== value ) {
                if ( inputOnChange ) inputOnChange( val );
                if ( onChange ) onChange( val );
              }
            }}
            value={value}
            components={{
              Option: IconOption,
              SingleValue: IconSingleValue,
              ClearIndicator: Clear,
            }}
          />

          {appendIcon || appendIconClass ? (
            <InputGroupAddon
              addonType="append"
              isInvalid={isInvalid}
              icon={appendIcon}
              iconClass={appendIconClass}
            />
          ) : null}
        </InputGroup>
        {isInvalid && (
          <div className="invalid-feedback">
            <Intl
              id={meta.error || meta.submitError}
              values={translateValues}
            />
          </div>
        )}
      </FormGroup>
    );
  },
);

export default injectIntl( AsyncSelectInput );
