import React from 'react';
import { connect } from 'react-redux';

import ChecklistNewView from 'views/Checklist/New';
import ChecklistService from 'api/Checklist';
import SettingsActions from 'store/reducers/Settings';
import { useSubmit } from 'hooks';

const ChecklistNew = ( {
  modalOpened, parentPath, history, onCloseModal, toggleInfoAlert, toggleLoading,
} ) => {
  const submitForm = useSubmit( {
    promise: ChecklistService.saveChecklist,
    toggleLoading,
    toggleInfoAlert,
    translations: true,
    callback: ( formData, responseData ) => history.push( `${parentPath}/edit/${responseData.id}` ),
  } );

  return (
    <ChecklistNewView
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ChecklistNew );
