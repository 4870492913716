import { useSubmit } from 'hooks';
import React from 'react';
import SettingsActions from 'store/reducers/Settings';
import SeasonEditView from 'views/PropertyManager/Edit/Discount/EditDrawer/Seasons/Edit';

import { connect } from 'react-redux';
import _ from 'lodash';
import { createDaySeasonDiscount } from './utils';
import { formatDataToSend } from '../../format';

const SeasonEdit = ( {
  discountId,
  data,
  events,
  open,
  onClose,
  onReloadData,
  toggleLoading,
  toggleInfoAlert,
} ) => {
  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => ( createDaySeasonDiscount( events, dataToSend ) ),
    format: ( dataToSend ) => formatDataToSend( discountId, null, dataToSend ),
    toggleLoading,
    toggleInfoAlert,
    callback: () => {
      onReloadData();
    },
    deps: [discountId, events],
  } );

  return (
    <SeasonEditView
      fixDates
      data={data}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( SeasonEdit );
