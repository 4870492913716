import React, { useMemo, useState } from 'react';
import { Container, Card } from 'reactstrap';
import {
  PropertyManagerLayout, HorizontalMenu,
} from 'components';
import PropertyManagerFinanceSettings from 'containers/PropertyManager/Edit/Finance/Settings';
import PropertyManagerFinanceFees from 'containers/PropertyManager/Edit/Finance/Fees';
import PropertyManagerFinanceCharges from 'containers/PropertyManager/Edit/Finance/Charges';
import PropertyManagerFinanceIssues from 'containers/PropertyManager/Edit/Finance/Issues';

export default React.memo( ( {
  pmData, isLoading, userRole, parentPath, pathLayout, pathname,
} ) => {
  const [currentTab, setCurrentTab] = useState( 'settings' );

  const tabs = useMemo( () => [
    {
      name: 'settings',
      component: <PropertyManagerFinanceSettings pmData={pmData} />,
    },
    {
      name: 'fees',
      component: <PropertyManagerFinanceFees pmData={pmData} />,
    },
    {
      name: 'charges',
      component: <PropertyManagerFinanceCharges pmData={pmData} />,
    },
    {
      name: 'issues',
      component: <PropertyManagerFinanceIssues />,
    },
  ], [pmData] );

  const currentSection = useMemo( () => tabs.filter( ( t ) => t.name === currentTab )[0]
    .component, [currentTab, tabs] );

  return (
    <PropertyManagerLayout
      data={pmData}
      isLoading={isLoading}
      parentPath={parentPath}
      pathLayout={pathLayout}
      pathname={pathname}
      userRole={userRole}
    >
      <Container
        fluid
      >
        <Card className="card-sec-top border-bottom">
          <HorizontalMenu
            tabs={tabs}
            selected={currentTab}
            onChangeTab={setCurrentTab}
          />
          <div className="border-top">{currentSection}</div>
        </Card>
      </Container>
    </PropertyManagerLayout>
  );
} );
