import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import TaskExpensesDashboardView from 'views/AccomodationOwner/Edit/expenses/Task';
import AccomodationOwnerService from 'api/AccomodationOwner';
import SettingsActions from 'store/reducers/Settings';
import TaskService from 'api/Task';
import { useFetchTableData } from 'hooks';
import { handlePromise } from 'utils';

const TaskExpensesDashboard = ( {
  toggleLoading,
  match,
  toggleErrorAlert,
  dateRange,
  dataSubmitted,
  updateDateRangeParams,
  setKeywordTasksExpenses,
  setAccommodationTasksExpenses,
  isDownloading,
  forceReload,
} ) => {
  const clientId = match.params.id;

  const selectedRows = useRef( {} );
  const [selectedRowsChange, setSelectedRowsChange] = useState(
    new Date().valueOf(),
  );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => AccomodationOwnerService.getTasksOwner( clientId, {
      ...params,
      expenses: true,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
    } ),
    deps: [dataSubmitted],
  } );

  useEffect( () => {
    selectedRows.current = {};
    if ( data && data.data && data.data.length ) {
      data.data.forEach( ( item ) => {
        selectedRows.current[item.id] = item.invoiced || false;
      } );
    }
    setSelectedRowsChange( new Date().valueOf() );
  }, [data] );

  const fetchAccommodations = useCallback(
    () => AccomodationOwnerService.getAccomodationOwner( clientId ), [clientId],
  );

  /// ///////////////////////////////

  const setApplyToProperty = useCallback(
    async ( value, id ) => {
      toggleLoading( true );
      const [error, response] = await handlePromise(
        value ? TaskService.updateTask( id, { applyTo: value } )
          : TaskService.updateTask( id, { applyTo: 'null' } ),
      );
      if ( !response.ok ) {
        toggleErrorAlert( error );
      }
      forceReload();
      toggleLoading( false );
    },
    [toggleLoading, toggleErrorAlert, forceReload],
  );

  const setRoomExpenses = useCallback(
    async ( selectedItems ) => {
      toggleLoading( true );

      if (
        !data
        || !data.data
        || !selectedItems
        || !Object.entries( selectedItems ).length
      ) {
        return false;
      }
      const selectedItemsList = [];

      Object.keys( selectedItems ).forEach( ( a ) => {
        selectedItemsList.push( { id: a, invoiced: selectedItems[a] } );
      } );

      const errors = [];
      await Promise.all( selectedItemsList.map( async ( item ) => {
        const currentRoomExpenseOrig = data.data.find(
          ( re ) => re.id === item.id,
        );
        const currentRoomExpense = { ...currentRoomExpenseOrig };
        delete currentRoomExpense.propertyManager;
        delete currentRoomExpense.date;
        delete currentRoomExpense.updated;
        delete currentRoomExpense.created;
        delete currentRoomExpense.id;
        delete currentRoomExpense.invoice;
        const [, response] = await handlePromise(
          TaskService.updateTask( item.id, { invoiced: `${item.invoiced}` } ),
        );
        if ( !response.ok ) {
          errors.push( item.id );
        }
        if ( errors.length ) {
          toggleErrorAlert( 'dataSavedWithErrors' );
        }
      } ) );
      toggleLoading( false );

      forceReload();
    },
    [toggleErrorAlert, toggleLoading, data, forceReload],
  );

  /// //////////////////////////////

  const toggleRowChecked = useCallback(
    ( id, value ) => {
      selectedRows.current[id] = value;
      setSelectedRowsChange( new Date().valueOf() );
      setRoomExpenses( { [id]: value } );
    },
    [setRoomExpenses],
  );

  const toggleAllRowsChecked = useCallback( () => {
    let flagForAll = true;
    // eslint-disable-next-line no-unused-vars, max-len
    if (
      data.data
      // eslint-disable-next-line no-unused-vars
      && Object.entries( selectedRows.current ).filter( ( [key, value] ) => value )
        .length === data.data.length
    ) {
      flagForAll = false;
    }

    selectedRows.current = {};
    _.map( data.data, 'id' ).forEach( ( id ) => {
      selectedRows.current[id] = flagForAll;
    } );
    setSelectedRowsChange( new Date().valueOf() );
    setRoomExpenses( selectedRows.current );
  }, [data, setRoomExpenses] );

  const urlFilters = () => {
    const filters = {
      keyword: undefined,
      accommodation: undefined,
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
    };
    return filters;
  };

  const applyToValues = [
    { name: 'owner', label: 'owner', id: 'owner' },
    { name: 'propertyManager', label: 'propertyManager', id: 'propertyManager' },
    { name: 'client', label: 'client', id: 'client' },
  ];

  return (
    <TaskExpensesDashboardView
      data={data}
      isLoading={dataLoading || isDownloading}
      initialDates={dateRange.current}
      defaultFilters={urlFilters()}
      setKeywordTasksExpenses={setKeywordTasksExpenses}
      setAccommodationTasksExpenses={setAccommodationTasksExpenses}
      selectedRows={selectedRows.current}
      selectedRowsChange={selectedRowsChange}
      onFetchData={fetchData}
      onFetchAccommodations={fetchAccommodations}
      onDateChanged={updateDateRangeParams}
      onToggleRowChecked={toggleRowChecked}
      onToggleAllRowsChecked={toggleAllRowsChecked}
      onApplyToChange={setApplyToProperty}
      applyToOptions={applyToValues}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( TaskExpensesDashboard );
