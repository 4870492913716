import React from 'react';
import moment from 'moment';

import { Intl, AsyncTable } from 'components';

export default React.memo( ( { data, onFetchData, isLoading } ) => (
  <AsyncTable
    titleContent={(
      <h3 className="m-0 pb-2">
        <Intl id="issusedKeys" />
      </h3>
)}
    containerClassName="card-sec-bottom"
    cardHeaderClassName="border-0 py-2"
    onFetchData={onFetchData}
    data={data.data}
    total={data.elementsTotal}
    isLoading={isLoading}
    columns={[
      {
        Header: 'customerNo',
        accessor: 'owner.name',
      },
      {
        Header: 'createdAt',
        accessor: 'created',
        Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'YYYY-MM-DD HH:mm' ) : '' ),
      },
      {
        Header: 'expirationDate',
        accessor: 'expirationDate',
        Cell: ( { cell } ) => ( cell.value ? moment.utc( cell.value ).format( 'YYYY-MM-DD HH:mm' ) : '' ),
      },
      {
        Header: 'provider',
        accessor: 'provider',
      },
    ]}
  />
) );
