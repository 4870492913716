import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ShiftDetailView from 'views/Shift/Detail';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import { handlePromise } from 'utils';
import ShiftService from 'api/Shift';
import ShiftEdit from '../Edit';

const ShiftDetail = ( {
  parentPath, match, toggleLoading,
  // toggleInfoAlert,
  toggleErrorAlert,
} ) => {
  const shiftId = match.params.id;
  const [modalOpened, setModalOpened] = useState( false );
  const [dataReloaded, setDataReloaded] = useState( new Date().valueOf() );

  const getNotAssignedMembers = useCallback( ( dataToFormat ) => _.differenceWith(
    dataToFormat.team.members,
    dataToFormat.slots, ( member, slot ) => {
      if ( !slot.assigned ) return false;
      return member.id === slot.assigned.employee.id;
    },
  ), [] );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => ShiftService.getShift( shiftId ),
    format: ( dataToFormat ) => {
      let shiftTemplateName = '';
      if ( _.get( dataToFormat, 'team.shiftTemplates', [] ).length && dataToFormat.shiftTemplate ) {
        const shift = _.find( dataToFormat.team.shiftTemplates, {
          id: dataToFormat.shiftTemplate,
        } );
        if ( shift ) shiftTemplateName = shift.name;
      }

      return {
        ...dataToFormat,
        shiftTemplateName,
        notAssignedMembers: getNotAssignedMembers( dataToFormat ),
      };
    },
    toggleErrorAlert,
    conditional: !!shiftId,
    deps: [shiftId, dataReloaded],
  } );

  const toggleModal = useCallback( () => {
    setModalOpened( !modalOpened );
  }, [modalOpened] );

  const assignSlot = useCallback( async ( assigned ) => {
    toggleLoading( true );
    const [error, response] = await handlePromise(
      ShiftService.updateShift(
        shiftId, { assigned },
      ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( error );
    setDataReloaded( new Date().valueOf() );
  }, [shiftId, toggleLoading, toggleErrorAlert] );

  const reloadData = useCallback( () => {
    if ( modalOpened ) toggleModal();
    setDataReloaded( new Date().valueOf() );
  }, [modalOpened, toggleModal] );

  return (
    <>
      <ShiftDetailView
        data={data}
        isLoading={dataLoading}
        parentPath={parentPath}
        onToggleEdit={toggleModal}
        onAssignSlot={assignSlot}
      />

      <ShiftEdit
        shiftId={shiftId}
        modalOpened={modalOpened}
        onCloseModal={toggleModal}
        onReload={reloadData}
      />
    </>
  );
};

const mapDispatchToProps = ( {
  // toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ShiftDetail );
