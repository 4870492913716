import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import EditDrawerView from 'views/PropertyManager/Edit/Occupancies/EditDrawer';

const EditDrawer = ( {
  occupancyId, open, onClose, onReloadOccupancies, onOpenModal,
} ) => (
  <EditDrawerView
    data={{ id: occupancyId }}
    open={open}
    onOpenModal={onOpenModal}
    onClose={onClose}
    onReloadOccupancies={onReloadOccupancies}
  />
);

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( EditDrawer );
