import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EditView from 'views/PropertyManager/Edit/Discount/EditDrawer/Accomodations/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useSubmit } from 'hooks';
import AccommodationService from 'api/Accommodation';
import { updateAccomodationsDiscount } from './utils';

const Edit = ( {
  onClose,
  discountId,
  data,
  open,
  toggleInfoAlert,
  toggleLoading,
  onReloadData,
  isLoading,
} ) => {
  const initialIds = _.map(
    _.get( data, 'data', [] ), ( item ) => item.accDiscId,
  );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );
  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => updateAccomodationsDiscount( initialIds, dataToSend ),
    format: ( dataToSend ) => ( {
      ...dataToSend,
      discountId,
      validateAccommodations: true,
    } ),
    toggleLoading,
    toggleInfoAlert,
    callback: onReloadData,
    deps: [discountId, initialIds],
  } );

  return (
    <>
      <EditView
        data={data}
        onSubmit={onSubmit}
        onFetchAccommodations={fetchAccommodations}
        onClose={onClose}
        open={open}
        isLoading={isLoading}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( Edit );
