import React, { useState } from 'react';
import moment from 'moment';
import {
  Row, Col, Modal, UncontrolledTooltip, Button,
} from 'reactstrap';
import {
  AsyncTable, EditCard, DaterangePickerBtn, AddBtn, Intl, TableActionsBtns,
} from 'components';
import EditShift from 'containers/Resource/Edit/Shift/edit';
import { getSlotSchedule } from 'utils';
import { canModify } from 'config/Ability';
import ViewShift from './view';
import StatisticsView from './statistics';

const ShiftView = ( {
  entityId,
  fetchData,
  dataTable,
  dataLoading,
  defaultFilters,
  data,
  reload,
  onDelete,
  statistics,
} ) => {
  const canEditDelete = canModify( 'shift' );

  const [modalEditOpen, setModalEditOpen] = useState( false );

  const [shiftId, setShiftId] = useState( null );

  const [showDetail, setShowDetail] = useState( false );
  const toggleDetail = () => setShowDetail( !showDetail );

  const toggleModalEdit = () => {
    setModalEditOpen( ( v ) => !v );
  };

  const openModalEdit = ( id ) => {
    setShiftId( id );
    setModalEditOpen( true );
  };

  const [shiftToView, setShiftToView] = useState( null );
  const [modalAddView, setModalAddView] = useState( false );

  const toggleModalView = () => {
    setModalAddView( ( v ) => !v );
  };

  return (
    <>
      <EditCard cardClass="card-sec-bottom">
        <AsyncTable
          id="resourceShifts"
          data={dataTable.data}
          total={dataTable.elementsTotal}
          isLoading={dataLoading}
          rightContent={(
            <div className="d-flex align-items-center flex-wrap mt-1 mb-1">
              <Button
                color={showDetail ? 'secondaryColor' : 'primary'}
                className="mt-md-0 ml-2 mr-0"
                onClick={toggleDetail}
              >
                <i className="fas fa-file-invoice mr-1" />
                {showDetail
                  ? <Intl id="hideReport" />
                  : <Intl id="showReport" />}
              </Button>
              {canEditDelete && <AddBtn title="newShift" onClick={() => { openModalEdit( null ); }} />}
            </div>
          )}
          endContent={(
            <StatisticsView
              statistics={statistics}
              showDetail={showDetail}
            />
          )}
          disableSorting
          defaultFilters={defaultFilters}
          onFetchData={fetchData}
          customFilters={( { filters, setMultipleFilters } ) => (
            <>
              <Row>
                <Col md={10} lg={5}>
                  <DaterangePickerBtn
                    position="left"
                    initialValue={moment.range( filters.from || moment(), filters.till || moment().add( 30, 'days' ) )}
                    key={filters.from + filters.till}
                    input
                    onChange={( values ) => {
                      setMultipleFilters( {
                        from: moment( values.start ).format( 'YYYY-MM-DD' ),
                        till: moment( values.end ).format( 'YYYY-MM-DD' ),
                      } );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          columns={[
            {
              Header: '#',
              accessor: 'id',
              Cell: ( { cell } ) => (
                <>
                  <a
                    href="/"
                    id={`tooltip-shift-${cell.value}`}
                    onClick={( e ) => {
                      e.preventDefault();
                      setShiftToView( cell.row.original );
                      toggleModalView();
                    }}
                  >
                    {cell.value.slice( 0, 8 )}
                  </a>
                  <UncontrolledTooltip
                    delay={0}
                    target={`tooltip-shift-${cell.value}`}
                  >
                    <Intl id="view" />
                  </UncontrolledTooltip>
                </>
              ),
            },
            {
              Header: 'team',
              accessor: 'team.name',
            },
            {
              Header: 'startDate',
              accessor: 'date',
              Cell: ( { cell } ) => (
                cell.value ? moment.utc( cell.value ).format( 'ddd DD/MM/YYYY' ) : ''
              ),
            },

            {
              Header: 'schedule',
              accessor: 'from',
              Cell: ( { cell } ) => getSlotSchedule( cell.row.original ),
            },
            {
              Header: 'status',
              accessor: 'status',
              Cell: ( { cell } ) => {
                if ( !cell.row.original.assigned ) {
                  return <span className="shift-status  shift-status-not-assigned"><Intl id="shiftPendingAssignment" /></span>;
                }

                if ( cell.value === 'accepted'
                ) {
                  return <span className="shift-status  shift-status-accepted"><Intl id="shiftAccepted" /></span>;
                }

                if ( cell.value === 'pending' ) {
                  return <span className="shift-status  shift-status-pending"><Intl id="shiftPending" /></span>;
                }

                if ( cell.value === 'rejected' ) {
                  return <span className="shift-status  shift-status-rejected"><Intl id="shiftRejected" /></span>;
                }

                return null;
              },
            },
            canEditDelete
              ? {
                accessor: 'id',
                disableSorting: true,
                Header: null,
                maxWidth: 65,
                Cell: ( { cell } ) => {
                  const actions = [];

                  actions.push( {
                    label: 'edit',
                    onClick: () => openModalEdit( cell.value ),
                    'data-cy': 'table-edit-btn',
                  } );

                  actions.push( {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  } );

                  return (
                    <TableActionsBtns actions={actions} />
                  );
                },
              } : {
                id: 'id', Header: null, maxWidth: 1, disableSorting: true,
              },
          ]}
        />
      </EditCard>

      <Modal
        isOpen={modalEditOpen}
        toggle={toggleModalEdit}
        className="modal-dialog-centered"
        size="lg"
      >
        <div className="modal-body">
          <EditShift
            entityId={entityId}
            entityData={data}
            shiftId={shiftId}
            modalOpened={modalEditOpen}
            onClose={() => {
              setModalEditOpen( false );
            }}
            onReloadData={() => {
              setModalEditOpen( false );
              reload();
            }}
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalAddView}
        toggle={toggleModalView}
        className="modal-dialog-centered"
        size="md"
      >
        <div className="modal-body">
          <ViewShift
            shiftToView={shiftToView}
            toggleModalView={toggleModalView}
            setEdit={( newShiftId ) => {
              setModalAddView( false );
              openModalEdit( newShiftId );
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default ShiftView;
