import EmployeePermitsService from 'api/Settings/EmployeePermits';

const submitEmployeePermits = async ( params ) => {
  await Promise.all( params.permissions.map( async ( item ) => {
    const _item = {
      ...item,
      enable: item.enable ? 'true' : 'false',
      allowedToAccumulate: item.allowedToAccumulate ? 'true' : 'false',
    };
    delete _item.propertyManager;
    delete _item.createdAt;
    delete _item.id;
    if ( item.id ) {
      await EmployeePermitsService.updateEmployeePermit( item.id, _item );
      return;
    }
    await EmployeePermitsService.saveEmployeePermit( _item );
  } ) );
  return {
    ok: true,
    status: 200,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { submitEmployeePermits };
