import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { canList } from 'config/Ability';
import Dashboard from './Dashboard';

const Amenity = ( { match } ) => (
  <Switch>
    {canList( 'amenity' )
    && (
      <Route
        path={match.url}
        component={Dashboard}
      />
    )}
  </Switch>
);

export default Amenity;
