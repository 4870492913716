import api from 'config/Api/Api';

const baseEndpoint = '/services';

export default {
  getServices( params ) {
    return api.get( '/services', params );
  },

  getService( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveService( data ) {
    return api.post( baseEndpoint, data );
  },

  updateService( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteService( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
};
