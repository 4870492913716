const extrasMap = {
  1: 'heating',
  2: 'airConditioning',
  3: 'parking',
  4: 'extraBeds',
  5: 'heatedPool',
  6: 'bedding',
  7: 'towels',
  8: 'internetAccess',
  9: 'pet',
  10: 'finalCleaning',
  11: 'refundableDeposit',
  18: 'crib',
  109: 'lateArrival',
  110: 'welcomePack',
  112: 'airportTransferSevilla',
  113: 'airportTransferFaro',
  120: 'jacuzzi',
  833: 'homaCarRental',
  834: 'beachTowels',
  1404: 'unlimitedIslaCanelaGolfVoucher',
  1537: 'babyChair',
  4611: 'entranceCleaningBedLinenAndTowels',
  10764: 'changeOfBedLinenAndTowels',
  11250: 'intermediateCleaning',
  11600: 'taxiService',
  14098: 'cribAndHighChair',
  15460: 'firewoodPack',
  15505: 'basicBreakfastPack',
  15506: 'lateCheckoutRequest13',
  18030: 'cleaningService',
  18106: 'dailyCleaningRequest',
  18108: 'extraParkingEVRB',
  18109: 'extraGuardEVRB',
  18485: 'contractDailyCleaning',
  18468: 'extraParkingGuardEVRB',
  19263: 'extra',
  20129: 'disinfectionCleaning',
  20131: 'disinfectionCertificate',
  20160: 'cleaningChange11Nights',
  20190: 'disinfection',
  20407: 'lateCheckOut',
  20990: 'cradleBabyClothesReservation',
  21105: 'contractCheck',
  21523: 'startSeasonCleaning',
  21524: 'cleaningByRequest',
  21610: 'sauna',
  22123: 'barbecue',
  22272: 'cancelationfee',
  22650: 'bond',
  22525: 'buffetBreakfast',
  24201: 'parking',
  24747: 'premiumCleaning',
  24748: 'maximumCleaning',
  24749: 'basicCleaning',
  24868: 'courtesyCleaning',
  28330: 'entryCheckVR',
  31735: 'garage',
  32215: 'skiRentDiscountRequest',
  33273: 'deepCleaning',
  35817: 'welcomBasketAran',
  37120: 'firewoodChipsPack',
  37121: 'chipsPack',
};

const getExtraKeyText = ( id ) => extrasMap[id] || 'extra';

export default getExtraKeyText;
