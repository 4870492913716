import React from 'react';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Col,
} from 'reactstrap';

import { Intl } from 'components';

export default React.memo( ( {
  pageIndex, gotoPage, canPreviousPage, previousPage,
  canNextPage, nextPage, pageCount, totalElements,
} ) => (
  ( canNextPage || canPreviousPage )
    ? (
      <nav aria-label="...">
        <Row className="justify-content-end align-items-center">
          <Col md="auto">
            <span className="text-sm text-muted">
              <Intl id="tablePaginationLabel" values={{ totalElements, page: pageIndex + 1, totalPages: pageCount }} />
            </span>
          </Col>
          <Col md="auto" className="pt-2 pt-md-0">
            <Pagination
              data-cy="table-pagination"
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem
                onClick={() => gotoPage( 0 )}
                disabled={!canPreviousPage}
              >
                <PaginationLink type="button">
                  <i className="fas fa-angle-double-left" />
                  <span className="sr-only">First</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <PaginationLink type="button" tabIndex="-1">
                  <i className="fas fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>

              <PaginationItem active>
                <PaginationLink type="button" tabIndex="-1">
                  {pageIndex + 1}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <PaginationLink type="button">
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem
                onClick={() => gotoPage( pageCount - 1 )}
                disabled={!canNextPage}
              >
                <PaginationLink tabIndex="-1" type="button">
                  <i className="fas fa-angle-double-right" />
                  <span className="sr-only">Last</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </nav>
    ) : null
) );
