import React from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Intl } from 'components';

export default React.memo( ( {
  cancelTo, onClickCancel, marginContainer = true,
  centerButtons = true,
  hiddenSave,
  savelabel,
} ) => (
  <div className={classnames( {
    'mt-4': marginContainer,
    'text-center': centerButtons,
  } )}
  >
    {cancelTo
      ? (
        <Button
          color="primary"
          outline
          className="mr-3"
          data-cy="cancel-form-btn"
          tag={Link}
          to={cancelTo}
        >
          <Intl id="cancel" />
        </Button>
      ) : null}

    {onClickCancel && !cancelTo
      ? (
        <Button
          color="primary"
          outline
          className="mr-3"
          data-cy="cancel-form-btn"
          onClick={onClickCancel}
        >
          <Intl id="cancel" />
        </Button>
      ) : null}
    {hiddenSave ? null : (
      <Button data-cy="save-form-btn" color="primary">
        <Intl id={savelabel || 'save'} />
      </Button>
    )}
  </div>
) );
