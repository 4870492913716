import { Ability } from '@casl/ability';

const ability = new Ability( [] );
export default ability;

export const canList = ( subject ) => ability.can( 'manage', subject )
  || ability.can( 'standard', subject )
  || ability.can( 'read_only_acl', subject );

export const canView = ( subject ) => ability.can( 'manage', subject )
  || ability.can( 'standard', subject )
  || ability.can( 'read_only_acl', subject );

export const canModify = ( subject ) => ability.can( 'manage', subject )
  || ability.can( 'standard', subject );

export const can = ( action, subject ) => ability.can( action, subject );

export const canEditViewDelete = ( subject ) => ( {
  canView: canView( subject ),
  canEdit: canModify( subject ),
  canDelete: canModify( subject ),
  can,
} );

export const updateRules = ( rules ) => {
  ability.update( rules );
};
